import { Dropdown } from 'react-bootstrap'
import React from 'react'
import styled from 'styled-components'


const StyledSizePerPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  label {
    margin-right: 10px;
    font-weight: normal;
  }
  select {
    width: auto;
  }
`

const SizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange
}) => (
    <StyledSizePerPageWrapper>
      <div>

        <div>&nbsp;</div>
        <Dropdown onSelect={(eventKey) => onSizePerPageChange(eventKey)}>
          <Dropdown.Toggle variant="link" id="dropdown-page-size">
            {(options.find(option => option.page === Number(currSizePerPage)) || {}).text}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {options.map(option => (
              <Dropdown.Item key={option.text} eventKey={option.page}>{option.text}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

      </div>
    </StyledSizePerPageWrapper>
  )

export default SizePerPageRenderer
