import styled from 'styled-components'

export const footerHeight = '118px'

const StyledPageWrapper = styled.div`
    background: white;
    box-shadow: 0 0 100px 0 rgba(0,0,0,0.8);
    min-height: 100vh;
    position: relative;
`

export default StyledPageWrapper
