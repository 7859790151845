import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import ProductCard from '../components/ProductCard/ProductCard'
import { formatedPriceObject } from '../utils/formatedPrice'
import { PageLoadingLayer } from '../components/Loading/PageLoadingLayer'
import { Alert, Form, Table } from 'react-bootstrap'
import SearchCriteriaCatalogCategories from '../components/SearchCriteriaCatalogCategoriesFilter/SearchCriteriaCatalogCategoriesFilter'
import { Subheader } from '../components/Page/logix2020/Subheader'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../components/Page/logix2020/SidebarLeft'
import { FlexWrapper } from '../components/Page/logix2020/FlexWrapper'
import BigFooter from '../components/Footer/BigFooter'
import { useTranslation } from 'react-i18next'
import useStores from '../stores/useStore'
import CatalogProductsSearch from '../components/ProductsOverview/CatalogProductsSearch'
import CatalogCategories from '../components/OffCanvasArea/CatalogCategories'
import ProductListItem from '../components/ProductListView/ProductListItem'
import MediaQuery from 'react-responsive'
import { useCookie } from '../utils/cookieHelper'
import LazyLoad from '../components/Forms/LazyLoad'

const StyledProductGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;

  @media screen and (min-width: 460px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (min-width: 1330px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`
const StyledCatalogViewSwitch = styled.div`
  margin-bottom: 25px;
  font-weight: bold;
`

const CatalogProductsOverviewPage = () => {
  const { t } = useTranslation()
  const { productDetailsStore, teamOrderStore } = useStores()

  useEffect(() => {
    if (!teamOrderStore.catalogOnly) {
      teamOrderStore.setCatalogOnly()
      teamOrderStore.loadArticleCatalogs()
    }
  }, [teamOrderStore])
  const [cookie, updateCookie] = useCookie("showListView", "false");
  const showListView = useMemo(() => cookie === "true", [cookie])

  const renderShoppingBasketArticle = (catalogueArticle) => {
    const articleAvailability = catalogueArticle.articleAvailability || {}
    return (showListView ?
      <ProductListItem
        key={catalogueArticle.article.id}
        shoppingBasketArticle={catalogueArticle}
        amountToOrder={articleAvailability.amountToOrder}
        iconType={null}
        smallImage
        catalogOnly
        to={`/articleCatalog/article/${catalogueArticle.article.id}`}
        columns={['article', 'price']}
      />
      :
      <ProductCard
        key={catalogueArticle.article.id}
        id={catalogueArticle.article.id}
        name={catalogueArticle.article.displayName}
        articleNumber={catalogueArticle.article.articleNumber}
        subDescription=''
        price={formatedPriceObject(catalogueArticle.pricePerItem || {})}
        amountToOrder={articleAvailability.amountToOrder}
        current={articleAvailability.currentAmount}
        max={articleAvailability.maxAmount}
        image={catalogueArticle.article.image}
        isInactive={false}
        isAmountMaxedOut={false}
        badge={null}
        to={`/articleCatalog/article/${catalogueArticle.article.id}`}
        iconType={null}
        onClick={() => { productDetailsStore.setScrollRef(catalogueArticle.article.id) }}
      />
    )
  }

  const CatalogArticlesOverview = () => useObserver(() => (
    <React.Fragment>
      <div className='mt-4'>
        {teamOrderStore.orderableArticlesError ?
          <Alert variant='danger'>{teamOrderStore.orderableArticlesError}</Alert>
          :
          <React.Fragment>
            {teamOrderStore.noArticlesAvailable ?
              <Alert variant='info'>{t('productsOverview:noProductsAvailable')}</Alert>
              :
              <PageLoadingLayer isLoading={teamOrderStore.orderableArticles.length === 0}>
                {showListView ?
                  <>
                    <MediaQuery minWidth={993}>
                      <Table borderless>
                        <thead>
                          <tr>
                            <th>{t('productsOverview:article')}</th>
                            <th>{t('price')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {teamOrderStore.orderableArticles.map(shoppingBasketArticle => renderShoppingBasketArticle(shoppingBasketArticle))}
                        </tbody>
                      </Table>
                    </MediaQuery>
                    <MediaQuery maxWidth={992}>
                      {teamOrderStore.orderableArticles.map(shoppingBasketArticle => renderShoppingBasketArticle(shoppingBasketArticle))}
                    </MediaQuery>
                  </>
                  :
                  <StyledProductGrid>
                    {teamOrderStore.orderableArticles.map(shoppingBasketArticle => renderShoppingBasketArticle(shoppingBasketArticle))}
                  </StyledProductGrid>
                }
                {((teamOrderStore.orderableArticlesAmount > teamOrderStore.orderableArticlesOffset) && !teamOrderStore.orderableArticlesIsLoading) ?
                  (<LazyLoad
                    fetchData={() => teamOrderStore.fetchOrderableArticles()}
                  />) : ''}
              </PageLoadingLayer>
            }
          </React.Fragment>
        }
      </div>
    </React.Fragment>
  ))


  return (
    <React.Fragment>
      <Subheader className="subHeader-v1">
        <div className="subheader-title">
          {t('productsOverview:articleCatalog')}
        </div>
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <StyledCatalogViewSwitch>
            <Form.Check
              type="switch"
              label={t('productsOverview:listView')}
              onChange={(e) => updateCookie((e.target.checked).toString(), 30)}
              id="list-view-switch"
              checked={showListView}
            />
          </StyledCatalogViewSwitch>
          <CatalogProductsSearch />
          <SearchCriteriaCatalogCategories />
          <CatalogCategories />
        </SidebarLeft>
        <ContentArea>
          <CatalogArticlesOverview />
        </ContentArea>
      </FlexWrapper>
      <BigFooter />
    </React.Fragment >
  )
}


export default CatalogProductsOverviewPage
