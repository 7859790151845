import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { StyledCircleCheckIconWrapper } from './StyledCircleCheckIconWrapper'
import { CircleCheckIcon } from '../../../Icons/CircleCheckIcon'


const AssignedGroupsCheck = ({ assignedGroups }) => {
  const [isValid, setIsValid] = useState(false)
  useEffect(() => {
    setIsValid(!!assignedGroups.length)
  }, [assignedGroups])

  return (
    <>
      <StyledCircleCheckIconWrapper>
        <CircleCheckIcon variant={isValid ? 'optional' : 'none'} />
      </StyledCircleCheckIconWrapper>
      <span>{t('EmployeeFormMainData:groupMembership')}</span>
    </>
  )
}

export default AssignedGroupsCheck