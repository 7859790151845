import React from 'react'
import styled from 'styled-components'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { TiInfoLarge } from 'react-icons/ti'


const StyledIcon = styled.div`
    color: ${props => props.theme.primaryColor};   
    display: inline-block;
    opacity: 0.5;
    margin:  0 0.5rem 0 0.5rem;
    svg {
      fill: white;
      background-color: ${props => props.theme.primaryColor};
      border-radius: 20px;
    }
`

const InformationPopover = ({ header, content, position }) => {

  const popover = (
    <Popover id="popover-basic" >
      <Popover.Header as="h3">{header}</Popover.Header>
      <Popover.Body>
        {content}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger={['hover', 'focus']} placement={position} overlay={popover}>
      <StyledIcon>
        <TiInfoLarge size={24} />
      </StyledIcon>
    </OverlayTrigger>

  )
}

export default InformationPopover;
