import React, { useState } from "react";
import styled from "styled-components";
import { useParams } from 'react-router-dom'
import { ArrowBigIcon } from "../../Icons/ArrowBigIcon";
import ExpandedArticle from "./ExpandedArticle";
import useStores from "../../../stores/useStore";
import useMountEffect from "../../../utils/hooks/useMountEffect";

const StyledTr = styled.tr`
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  border-top: ${(props) => (props.borderTop ? "1px solid #e0e0e0" : "")};
`;

const SelectedAmountsRow = ({ article, position }) => {
  const { inventoryStore } = useStores()
  const [open, setOpen] = useState(true);
  const { actionType } = useParams()
  const [instances, setInstances] = useState([])

  const init = async () => {
    if (actionType === 'inventoryBookings' && !position.negativeAmount) {
      return null
    }
    let newInstances = await inventoryStore.getInstances(article.article.id, position.articleVariant)
    if (newInstances.length === position.rows.length) {
      for (let [index, instance] of Object.entries(newInstances)) {
        inventoryStore.updateInstances(article, position.articleVariant, instance, index)
      }
    }
    setInstances(newInstances)
  }

  useMountEffect(() => {
    init()
  })


  return (
    <>
      <StyledTr
        borderTop
        onClick={() => setOpen((prev) => !prev)}
        aria-controls="example-collapse-text"
        role="button"
        aria-expanded={open}
      >
        <td>
          {(actionType === 'returnBookings' || actionType === "articleTransfer" || actionType === 'inventoryBookings')
            ? <ArrowBigIcon variant={open ? "down" : "right"} />
            : null}
          {article.article.displayName}
        </td>
        <td>{article.article.articleNumber}</td>
        <td>
          {position.articleVariant !== null &&
            position.articleVariant.displayName}
        </td>
        <td>{position.negativeAmount ? "-" : "+"}{position.rows.length}</td>
      </StyledTr>
      {open
        ? <ExpandedArticle position={position} article={article} instances={instances} />
        : null}
    </>
  );
};

export default SelectedAmountsRow