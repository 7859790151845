import React from 'react'
import DynamicFormElements from '../Form/DynamicFormElements'


const CatalogImport = ({ formData, setFormData, formErrors }) => {

  const formConfigTemplate = [
    {
      type: 'checkbox',
      name: 'replaceStructure',
      label: 'replaceCatalogStructure'
    },
    {
      type: 'checkbox',
      name: 'checkImportDataOnly',
      label: 'checkImportDataOnly'
    },
    {
      type: 'text',
      name: 'mailAddress',
      label: 'emailAddress'
    },
    {
      type: 'select',
      name: 'catalogId',
      label: 'catalog',
      defaultIsEmptyGuid: true,
      defaultName: 'select',
      options: [],
      isMandatoryField: true
    }
  ]

  return (
    <>
      <DynamicFormElements formConfigTemplate={formConfigTemplate} setFormData={setFormData} formData={formData} formErrors={formErrors} />
    </>
  )
}

export default CatalogImport