import React, { Component } from 'react'
import styled from 'styled-components'
import ProductDetailBlock from './ProductDetailBlock'
import SizeDescriptionBlock from './SizeDescriptionBlock'
import AliasesBlock from './AliasesBlock'
import LogisticBlock from './LogisticBlock'
import DocumentsBlock from './DocumentsBlock'
import WashDescriptionBlock from './WashDescriptionBlock'
import OrderHistoryBlock from './OrderHistoryBlock'

const StyledContainer = styled.div`
  padding-top: 40px;

   @media screen and (min-width: 993px) {
    padding-right: 30px;
  }
`

class ProductDetail extends Component {
  render() {
    const { article, processType } = this.props
    const additionalImages = article.detailImages ? article.detailImages.slice(4) : []


    return (
      <StyledContainer className='col'>
        <ProductDetailBlock article={article} additionalImages={additionalImages} withTitle />
        <SizeDescriptionBlock article={article} withTitle />
        <WashDescriptionBlock article={article} withTitle />
        <AliasesBlock article={article} withTitle />
        <LogisticBlock article={article} withTitle />
        <DocumentsBlock article={article} withTitle />
        {processType === "costCenter" ? <OrderHistoryBlock /> : null}
      </StyledContainer>
    )
  }
}

export default ProductDetail
