import React, { useState, useEffect } from 'react'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import { useTranslation } from 'react-i18next'
import { Alert, Spinner } from 'react-bootstrap'
import EditOfficesTree from '../../components/Configuration/Office/EditOfficesTree'
import CreateNewExportOverlay from '../../components/Files/CreateNewExportOverlay'
import CreateNewImportOverlay from '../../components/Files/CreateNewImportOverlay'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import { Link } from 'react-router-dom'
import AdministrationSidebarNavigation from '../../components/Configuration/AdministrationSidebarNavigation'
import useStores from '../../stores/useStore'
import { ExcelButton } from '../../components/Buttons/ExcelButton'
import { MdLibraryAdd } from 'react-icons/md'
import getThemeValue from '../../utils/getThemeValue'
import { navigationSideLeft } from '../../pages/Configuration/AdministrationPage'
import AdministrationSidebar from '../../components/Configuration/AdministrationSidebar'

const OfficeAdministrationPageContent = () => {
  const { t } = useTranslation()
  const [showExportOverlay, setShowExportOverlay] = useState(false)
  const [showImportOverlay, setShowImportOverlay] = useState(false)

  const { uiStore } = useStores()

  const onOpenOverlayClick = (overlay, office) => {
    switch (overlay) {
      case "export":
        setShowExportOverlay(prev => !prev)
        break;
      case "import":
        setShowImportOverlay(prev => !prev)
        break;
      default:
    }
  }
  const onCloseOverlayClick = () => {
    setShowExportOverlay(false)
    setShowImportOverlay(false)
  }
  return (
    <div>
      <Subheader className="subHeader-v1 justify-content-between">
        <div className="subheader-title">{getThemeValue('officeAdmin', 'officeHeader', t('officeAdmin:officeHeader'))}</div>
        <div className="subheader__buttonWrapper">
          {uiStore.allowImportOffices &&
            <ExcelButton onClick={() => onOpenOverlayClick("import")} className="subheader__button" variant="secondary" buttonText={t('import')} />
          }
          <ExcelButton onClick={() => onOpenOverlayClick("export")} className="subheader__button" variant="secondary" buttonText={t('export')} />
          <Link to="/officeadministration/create#general" className="subheader__button btn btn-primary text-white"><MdLibraryAdd className="me-3" />{t('officeAdmin:createNewButton')}</Link>
        </div>
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <AdministrationSidebarNavigation active="officeadministration" />
        </SidebarLeft>
        <ContentArea>
          <EditOfficesTree onOpen={onOpenOverlayClick} />
        </ContentArea>
      </FlexWrapper>
      <BigFooter />
      <CreateNewExportOverlay exportName="officeExport" visible={showExportOverlay} onClose={onCloseOverlayClick} />
      <CreateNewImportOverlay importName="officeImport" visible={showImportOverlay} onClose={onCloseOverlayClick} />
    </div >
  )
}

const OfficeAdministrationPage = () => {

  const { t } = useTranslation()
  const { uiStore } = useStores()
  const [error, setError] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setError(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {!uiStore.allowImportOffices ? (
        <>
          <Subheader className="subHeader-v1 justify-content-between">
            <div className="subheader-title">{getThemeValue('officeAdmin', 'officeHeader', t('officeAdmin:officeHeader'))}</div>
          </Subheader>
          <FlexWrapper>
            <SidebarLeft>
              {/* <AdministrationSidebarNavigation active="officeadministration" /> */}
              <AdministrationSidebar options={navigationSideLeft()} active="officeadministration"/>
       
            </SidebarLeft>
            <ContentArea>
              {error
                ? <Alert variant="danger">{t('accessDenied')}</Alert>
                : <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
              }
            </ContentArea>
          </FlexWrapper>
          <BigFooter />
        </>
      ) : <OfficeAdministrationPageContent />
      }
    </>
  )
}
export default OfficeAdministrationPage
