import React from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const MandatoryField = ({ color }) => {
    const { t } = useTranslation()

    return (
        <>
            <Form.Text style={{ color }} className={color ? "" : "text-muted"}>
                {t('mandatoryFields')}
            </Form.Text>
        </>
    )

}

export default MandatoryField;



