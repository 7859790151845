import React, { useEffect, useState } from 'react'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import SubheaderTitle from '../../components/Page/logix2020/SubheaderTitle'
import { useLocation } from 'react-router-dom'
import OfficeGeneralForm from '../../components/Configuration/Office/OfficeGeneralForm'
import OfficeAddressesForm from '../../components/Configuration/Office/OfficeAddressesForm'
import { useParams } from 'react-router-dom'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import OfficeAccessRights from '../../components/Configuration/Office/OfficeAccessRights'
import { StyledOfficeComponents } from '../../components/Configuration/Office/officeHelper'
import OfficeProvider from '../../stores/Configuration/OfficeProvider'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import { useTranslation } from 'react-i18next'
import OfficeSidebarNavigation from '../../components/Configuration/Office/OfficeSidebarNavigation'


const CopyOfficePage = () => {
  const { t } = useTranslation()
  let currentLocation = useLocation()
  const [activeNav, setActiveNav] = useState('')
  const { officeId } = useParams()
  const [officeDetails, setOfficeDetails] = useState("")


  useEffect(() => {
    setActiveNav(currentLocation.hash.substr(1))
  }, [currentLocation.hash])


  useEffect(() => {
    apiClient.getJson(APIEndpoints.offices(officeId).details)
      .then(response => {
        setOfficeDetails(response)
      })
      .catch(error => console.log(error))
  }, [officeId])


  if (officeDetails === "") {
    return null
  }
  return (
    <OfficeProvider>
      <Subheader className="subHeader-v1">
        <SubheaderTitle
          withBackButton
          smallTitle={t('officeAdmin:copyOffice')}
          goBackLabel={t('officeAdmin:copyOffice')}
          defaultBackTo="/officeadministration"
        />
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <OfficeSidebarNavigation active={activeNav} />
        </SidebarLeft>
        <ContentArea>
          <StyledOfficeComponents>
            {activeNav === 'general' && <OfficeGeneralForm mode="copy" officeDetails={officeDetails} />}
            {activeNav === 'addresses' && <OfficeAddressesForm />}
            {activeNav === 'access-rights' && <OfficeAccessRights />}
          </StyledOfficeComponents>
        </ContentArea>
      </FlexWrapper>
      <BigFooter />
    </OfficeProvider >
  )
}

export default CopyOfficePage
