import React from 'react'
import SidebarNavigation from '../../Page/logix2020/SidebarNavigation'
import i18n from '../../../translation/i18n'

const navigationLeft = () => [
    {
        key: '#general',
        to: '#general',
        name: i18n.t("costCenterAdmin:general"),
        disabled: false

    },
    {
        key: '#addresses',
        to: '#addresses',
        name: i18n.t("costCenterAdmin:addresses"),
        disabled: false

    },
    {
        key: '#access-rights',
        to: '#access-rights',
        name: i18n.t("costCenterAdmin:accessRights"),
        disabled: false

    }
]

const OfficeSidebarNavigation = ({ active }) => {
    return (
        <SidebarNavigation options={navigationLeft()} active={active} />
    )
}

export default OfficeSidebarNavigation