import React from 'react'

export const CartIcon = ({ color, height, width, ...rest }) => {
    const useColor = color ? color : '#fff'
    const useHeight = height ? height : '20px'
    const useWidth = width ? width : '20px'
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={useWidth} height={useHeight} viewBox="0 0 30.251 26.662" fill={useColor} {...rest}>
            <g id="noun_order_2448918_000000" transform="translate(0 -2.867)" >
                <g id="Gruppe_149" data-name="Gruppe 149" className="cart">
                    <path id="Pfad_195" data-name="Pfad 195" d="M31.584,16.873a1.537,1.537,0,0,0-1.848,1.147L27.06,29.137H13.681L9.858,13.879a1.533,1.533,0,0,0-1.5-1.179H4.029A1.529,1.529,0,0,0,2.5,14.229a1.55,1.55,0,0,0,1.529,1.529h3.09l3.823,15.259a1.533,1.533,0,0,0,1.5,1.179h15.8a1.533,1.533,0,0,0,1.5-1.179l2.963-12.3A1.522,1.522,0,0,0,31.584,16.873Z" transform="translate(-2.5 -9.833)"
                        fillRule="evenodd" />
                    <circle id="Ellipse_35" data-name="Ellipse 35" cx="2.74" cy="2.74" r="2.74" transform="translate(9.333 24.05)" fillRule="evenodd" />
                    <circle id="Ellipse_36" data-name="Ellipse 36" cx="2.74" cy="2.74" r="2.74" transform="translate(20.1 24.05)" fillRule="evenodd" />
                </g>
                <g className="items">
                    <rect id="Rechteck_544" data-name="Rechteck 544" width="3.755" height="3.755" transform="translate(12.849 13.741)" fillRule="evenodd" />
                    <rect id="Rechteck_545" data-name="Rechteck 545" width="3.755" height="3.755" transform="translate(18.106 13.741)" fillRule="evenodd" />
                    <rect id="Rechteck_546" data-name="Rechteck 546" width="4.506" height="4.506" transform="translate(14.351 7.733)" fillRule="evenodd" />
                </g>

            </g>
        </svg>

    )
}
