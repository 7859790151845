import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { StyledDescriptionBlock, StyledTitle } from './ProductDetailStyles'
import { colors } from '../../styles/colors'
import { FileTypeIcon } from '../../utils/getFileTypeIcon'


const StyledDocumentWrapper = styled.a`
    min-height: 48px;
    color: ${colors.gray4};
    small {
        color: ${colors.gray3};
    }
    display: flex;
    align-items: center;
    margin: 5px 5px 5px 0 ;
    cursor: pointer;
`

const DocumentsBlock = ({ article, withTitle }) => {
    const { t } = useTranslation()
    const showDocuments = article.detailDocuments && article.detailDocuments.length

    if (!showDocuments) {
        return null
    }

    return (
        <StyledDescriptionBlock>
            {withTitle ? <StyledTitle className="h1">{t('productDetail:associatedDocuments')}</StyledTitle> : null}
            {article.detailDocuments.map(document => (
                <div className="d-flex" key={document.id}>
                    <StyledDocumentWrapper href={document.url} download={document.name} filename={document.name} target="_blank">
                        <FileTypeIcon fileType={document.type} />
                        <div >
                            <div className='fw-bold'>{document.name || ''}</div>
                            <div>
                                <small className='text-uppercase'>{document.description || ''}</small>
                            </div>
                        </div>
                    </StyledDocumentWrapper>
                </div>
            ))}
        </StyledDescriptionBlock>)
}

export default DocumentsBlock
