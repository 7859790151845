import { action, observable, makeObservable } from 'mobx';
import apiToken from '../utils/api/apiToken'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { getErrorMessageFromApiResponse } from '../utils/api/apiUtils'
import { t } from 'i18next'

class LoginStore {
  constructor(appStore) {
    makeObservable(this, {
      passwordReset: observable,
      loginData: observable,
      isLoginButtonLoading: observable,
      isUserLoggedIn: observable,
      someLoginError: observable,
      specificLoginError: observable,
      logoutRedirecting: observable,
      initialLogin: observable,
      apiAccessToken: observable,
      setApiAccessToken: action,
      setLoginDataUsername: action,
      setSomeLoginError: action,
      setSpecificError: action,
      setLoginDataPassword: action,
      setLoginButtonState: action,
      logout: action,
      handleLoginSubmit: action,
      loginWithSSOCode: action,
      handleUsernameOnChange: action,
      handleSecurityQuestionAnswerOnChange: action,
      handleOnUsernameSubmit: action,
      handleOnLoginQuestionSubmit: action
    });

    this.app = appStore
  }

  passwordReset = {
    username: null,
    loginQuestion: null,
    securityQuestionId: null,
    securityQuestionAnswer: null,
    isLoading: false,
    isLoginQuestionError: false,
    finished: false,
    errorText: ''
  };

  loginData = {
    username: null,
    password: null
  };

  isLoginButtonLoading = false;
  isUserLoggedIn = false;
  someLoginError = false;
  specificLoginError = '';
  logoutRedirecting = false;
  initialLogin = false;
  apiAccessToken = {
    access_token: null,
    expires_in: null
  };

  setApiAccessToken(apiAccessToken) {
    this.apiAccessToken = apiAccessToken
    this.isUserLoggedIn = (apiAccessToken && apiAccessToken.hasOwnProperty('access_token'))

    // save access token in browser sessionStorage to stayed logged in on page reload
    sessionStorage.setItem('apiAccessToken', JSON.stringify(apiAccessToken))

    if (apiAccessToken) {
      apiClient.initClients(this.apiAccessToken.access_token)
      this.app.uiStore.initAppStart()
    }
    else {
      apiClient.initClients()
    }

  }

  setLoginDataUsername(username) {
    this.loginData.username = username
    sessionStorage.setItem('userName', JSON.stringify(username))
  }

  setSomeLoginError(someLoginError) {
    this.someLoginError = someLoginError
  }
  setSpecificError(specificLoginError) {
    this.specificLoginError = specificLoginError
  }

  setLoginDataPassword(password) {
    this.loginData.password = password
  }

  setLoginButtonState(isLoading = false) {
    this.isLoginButtonLoading = isLoading
    return true
  }

  async logout(e) {
    if (e) {
      e.preventDefault()
    }
    let logoutData = null
    if (!this.logoutRedirecting) {
      try {
        logoutData = await apiClient.deleteJson(APIEndpoints.logout)
      } catch (error) { console.log("nope") }
    }
    if (logoutData && logoutData.logoutUrl) {
      this.logoutRedirecting = true
      this.setApiAccessToken(null)
      //redirect to external url
      window.location = logoutData.logoutUrl
      return
    }

    this.setApiAccessToken(null)
    //reload page to clear states
    // eslint-disable-next-line
    window.location = window.location
  }

  async handleLoginSubmit() {
    this.setLoginButtonState(true)
    this.setSomeLoginError(false)
    this.setSpecificError('')

    try {
      const TokenData = await apiToken.getByUsernameAndPassword(
        this.loginData.username,
        this.loginData.password
      )
      if (TokenData.data.mfaUrl) {  // If MFA is required, open the MFA URL in a popup
        const mfaUrlWithToken = `${TokenData.data.mfaUrl}?token=${TokenData.data.access_token}`
        this.openPopup(mfaUrlWithToken);
      } else {                      // If no MFA is required, continue with the usual process
        this.initialLogin = true;
        this.setApiAccessToken(TokenData.data);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error_description) {
        this.setSpecificError(e.response.data.error_description)
      }
      else {
        this.setSomeLoginError(true)
      }
      this.setLoginButtonState(false)
    }
  }

  async loginWithSSOCode(code) {
    try {
      const TokenData = await apiToken.getBySSOData(code)
      this.setApiAccessToken(TokenData.data)
    } catch (err) {
      console.log(err)
    }
  }

  handleUsernameOnChange(e) {
    this.passwordReset.username = e.target.value
  }

  handleSecurityQuestionAnswerOnChange(e) {
    this.passwordReset.securityQuestionAnswer = e.target.value
  }

  async handleOnUsernameSubmit(e) {
    e.preventDefault()
    this.passwordReset.isLoading = true

    try {
      const questionData = await apiClient.getJson(APIEndpoints.loginQuestion, { login: this.passwordReset.username }, false)
      this.passwordReset.loginQuestion = questionData.displayName
      this.passwordReset.securityQuestionId = questionData.id
      this.passwordReset.isLoginQuestionError = false
      this.passwordReset.errorText = ''
    } catch (err) {
      this.passwordReset.isLoginQuestionError = true
      this.passwordReset.errorText = getErrorMessageFromApiResponse(err)
    }

    this.passwordReset.isLoading = false
  }

  async handleOnLoginQuestionSubmit(e) {
    e.preventDefault()
    this.passwordReset.isLoading = true
    this.passwordReset.finished = false

    var params = {
      login: this.passwordReset.username,
      securityQuestionId: this.passwordReset.securityQuestionId,
      securityQuestionAnswer: this.passwordReset.securityQuestionAnswer
    }

    try {
      const questionData = await apiClient.postJson('resetPassword', params, false)

      if (questionData.status === 'Succeeded') {
        this.passwordReset.finished = true
        this.passwordReset.isLoginQuestionError = false
        this.passwordReset.errorText = ''
      }
      else if (questionData.status === 'Invalid_SecurityAnswer_or_Login') {
        this.passwordReset.isLoginQuestionError = true
        this.passwordReset.errorText = t('passwordReset:checkInput')
      }
      else if (questionData.status === 'SendMail_Failed_NoEmailAddress') {
        this.passwordReset.isLoginQuestionError = true
        this.passwordReset.errorText = t('passwordReset:noEmailSet')
      }
      else {
        this.passwordReset.isLoginQuestionError = true
        this.passwordReset.errorText = questionData.status
      }
    } catch (err) {
      this.passwordReset.finished = false
      this.passwordReset.isLoginQuestionError = true
      this.passwordReset.errorText = getErrorMessageFromApiResponse(err)
    }

    this.passwordReset.isLoading = false
  }

  // Utility function to open the MFA popup
  openPopup(url) {
    const w = 350;
    const h = 300;
    const y = window.top.innerHeight  / 2 + window.top.screenY - (h / 2);
    const x = window.top.innerWidth  / 2 + window.top.screenX - (w / 2);
    window.open(url, 'mfa', `rel=opener, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`)
  }
}



export default LoginStore
