import React, { useState, useEffect, useCallback } from 'react'
import { Subheader } from '../../../components/Page/logix2020/Subheader'
import SubheaderForm from '../../../components//Page/logix2020/SubheaderForm'
import { useTranslation } from 'react-i18next'
import { ContentArea } from '../../../components/Page/logix2020/ContentArea'
import BigFooter from '../../../components/Footer/BigFooter'
import ArticlesTable from '../../../components/Administration/SizeDetection/ArticlesTable'
import SizeDetectionSearchBox from '../../../components/Administration/SizeDetection/SizeDetectionSearchBox'
import ConfigurationsModal from '../../../components/Administration/SizeDetection/ConfigurationsModal'
import VariantModal from '../../../components/Administration/SizeDetection/VariantModal'
import { Spinner, Alert } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import useStores from '../../../stores/useStore'
import { useObserver } from 'mobx-react'
import { SidebarLeft } from '../../../components/Page/logix2020/SidebarLeft'
import { FlexWrapper } from '../../../components/Page/logix2020/FlexWrapper'
import ContingentList from '../../../components/OffCanvasArea/ContingentList'
import { scrollToDataId } from '../../../utils/utils'
import ArticleScanButton from '../../../components/Buttons/ArticleScanButton'
import ArticleScan from '../../../components/Administration/SizeDetection/ArticleScan'
import useMountEffect from '../../../utils/hooks/useMountEffect'


const SizeDetectionByEmployeePageContent = () => {
    const { t } = useTranslation();
    const { employeeId } = useParams();
    const [openVariantModal, setOpenVariantModal] = useState(null)
    const [openConfigurationModal, setOpenConfigurationModal] = useState(null)
    const [preparedContingents, setPreparedContingents] = useState(null)
    const [searchText, setSearchText] = useState('')
    const [filteredContingents, setFilteredContingents] = useState(null)
    const [openScanModal, setOpenScanModal] = useState(false)

    const loadArticles = useCallback(async () => {
        return await apiClient.getJson(APIEndpoints.businessPartner(employeeId).articleConfigurations)
    }, [employeeId])

    const loadContingent = useCallback(async () => {
        return await apiClient.getJson(APIEndpoints.businessPartner(employeeId).contingent)
    }, [employeeId])

    const getPreparedContingents = useCallback((contingents, articles) => {
        const result = contingents.map(contingent => {
            contingent.categories = contingent.categories.map(category => {
                var newArticles = []
                for (const article of category.articles) {
                    const newArticle = articles.find(obj => obj.article.id === article.article.id)
                    if (newArticle) {
                        newArticles.push(newArticle)
                    }
                }
                category.articles = newArticles
                return category
            }).filter(category => category.articles.length > 0)
            return contingent
        }).filter(contingent => contingent.categories.length > 0)
        return result
    }, [])

    const reloadArticles = useCallback(async (startTunnel, c = preparedContingents) => {
        let articles = await loadArticles()
        let newPreparedContingents = getPreparedContingents(c, articles)
        setPreparedContingents(newPreparedContingents)
        let newFilteredContingents = getFilteredContingents(searchText, newPreparedContingents)
        setFilteredContingents(newFilteredContingents)
        if (startTunnel) {
            let nextArticle = getNextTunnelArticle(newFilteredContingents)
            setOpenConfigurationModal(null)
            setOpenVariantModal(null)
            if (nextArticle) {
                if (nextArticle.isCustomizable && (nextArticle.configurationIsMissing || !nextArticle.hasSelectedConfigurations)) {
                    setOpenConfigurationModal(nextArticle)
                } else {
                    setOpenVariantModal(nextArticle)
                }
            }
        }
    }, [getPreparedContingents, loadArticles, preparedContingents, searchText])

    const init = useCallback(async () => {
        try {
            let contingents = await loadContingent()
            reloadArticles(false, contingents)
        }
        catch (e) {
            console.log(e)
        }
    }, [loadContingent, reloadArticles])

    useMountEffect(() => {
        init()
    })

    const handleClickScrollToGroup = (categoryId) => {
        scrollToDataId(`category-${categoryId}`)
    }

    const getFilteredContingents = (search, preparedContingents) => {
        if (!search) return preparedContingents
        const searchText = search.toLowerCase()
        const clonedContingents = JSON.parse(JSON.stringify(preparedContingents))
        const result = clonedContingents.map(contingent => {
            contingent.categories = contingent.categories.map(category => {
                category.articles = category.articles.filter(article => {
                    return (article.article.displayName.toLowerCase().indexOf(searchText) !== -1)
                })
                return category
            }).filter(category => category.articles.length > 0)
            return contingent
        }).filter(contingent => contingent.categories.length > 0)
        return result
    }

    const getNextTunnelArticle = (newFilteredContingents) => {
        let nextArticle = null
        for (const contingent of newFilteredContingents) {
            for (const category of contingent.categories) {
                nextArticle = category.articles.find(art => (
                    (art.isWithSizes && art.sizeIsMissing) || (art.isCustomizable && (art.configurationIsMissing || !art.hasSelectedConfigurations))
                ))
                if (nextArticle) {
                    return nextArticle
                }
            }
        }
        return nextArticle
    }

    const handleArticleSearch = (searchText) => {
        setSearchText(searchText)
        setFilteredContingents(getFilteredContingents(searchText, preparedContingents))
    }

    return (
        <>
            <Subheader className="subHeader-v1">
                <SubheaderForm
                    title={t('header:sizeDetection')}
                    goBackLabel={t('Subheader:overviewEmployees')}
                />
            </Subheader>
            <FlexWrapper>
                <SidebarLeft>
                    {filteredContingents && <SizeDetectionSearchBox searchText={searchText} handleArticleSearch={handleArticleSearch} contingents={filteredContingents} />}
                    {preparedContingents && <><ArticleScanButton className="w-100" onClick={() => setOpenScanModal(true)} /><hr></hr></>}
                    {filteredContingents && filteredContingents.map(contingent => (
                        <ContingentList
                            key={contingent.id}
                            contingent={contingent}
                            handleClickScrollToGroup={handleClickScrollToGroup}
                        />
                    ))}
                </SidebarLeft>
                <ContentArea>
                    {(!filteredContingents)
                        ? <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
                        : <div className='container-fluid'>
                            <ArticlesTable
                                contingents={filteredContingents}
                                setOpenConfigurationModal={setOpenConfigurationModal}
                                setOpenVariantModal={setOpenVariantModal}
                            />
                            {openConfigurationModal && <ConfigurationsModal
                                employeeId={employeeId}
                                setOpenConfigurationModal={setOpenConfigurationModal}
                                reloadArticles={reloadArticles}
                                selectedArticle={openConfigurationModal}
                            />}
                            {openVariantModal && <VariantModal
                                reloadArticles={reloadArticles}
                                setOpenVariantModal={setOpenVariantModal}
                                employeeId={employeeId}
                                selectedArticle={openVariantModal}
                            />}
                            {openScanModal &&
                                <ArticleScan
                                    handleClose={() => setOpenScanModal(false)}
                                    employeeId={employeeId}
                                    reloadArticles={reloadArticles}
                                />}
                            {filteredContingents && filteredContingents.length === 0 ?
                                <Alert className='mt-4' variant='info'>{t('productsOverview:noProductsAvailable')}</Alert> : ''}
                        </div>
                    }
                </ContentArea>
            </FlexWrapper>
            <BigFooter />
        </>
    )
}


const SizeDetectionByEmployee = () => {
    const { uiStore } = useStores()
    const { t } = useTranslation()
    const [error, setError] = useState(false)
    useEffect(() => {
        const timer = setTimeout(() => {
            setError(true);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return useObserver(() => (
        <>
            {!uiStore.allowChangeArticleConfiguration
                ? (
                    <>
                        <Subheader className="subHeader-v1">
                            <div className="subheader-title">{t('header:sizeDetection')}</div>
                        </Subheader>
                        {error
                            ? <Alert variant="danger">{t('accessDenied')}</Alert>
                            : <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
                        }
                    </>
                )
                : <SizeDetectionByEmployeePageContent />
            }
        </>
    ))
}
export default SizeDetectionByEmployee