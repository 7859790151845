import { action, observable, makeObservable } from 'mobx';
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { cloneDeep } from 'lodash';


class ProductsCompareStore {
  constructor(rootStore) {
    makeObservable(this, {
      articles: observable,
      articlesInfo: observable,
      showProductsCompareModal: observable,
      setShowProductsCompareModal: action,
      onArticleCompareHandler: action,
      resetProductsCompareStore: action,
      deleteArticle: action,
      deleteArticleInfo: action,
      addArticle: action,
      updateProductsCompareStore: action,
      articleExists: action,
      getArticleInfo: action
    });

    this.app = rootStore
  }

  articles = [];
  articlesInfo = [];
  showProductsCompareModal = false;

  setShowProductsCompareModal(bool = false) {
    this.showProductsCompareModal = bool
  }
  onArticleCompareHandler(articleId, articleUrl, articlePrice) {
    if (this.articleExists(articleId)) {
      this.deleteArticle(articleId)
      this.deleteArticleInfo(articleId)
    } else {
      this.addArticle(articleId, articleUrl, articlePrice)
      this.getArticleInfo(articleId)
    }
  }

  resetProductsCompareStore() {
    this.articles = []
    this.articlesInfo = []
    this.showProductsCompareModal = false
    sessionStorage.removeItem('articles')
  }

  deleteArticle(articleId) {
    let articleIndex = this.articles.findIndex(art => art.id === articleId)
    this.articles.splice(articleIndex, 1)
    sessionStorage.setItem('articles', JSON.stringify(this.articles))
  }
  deleteArticleInfo(articleId) {
    let articleIndex = this.articlesInfo.findIndex(art => art.id === articleId)
    this.articlesInfo.splice(articleIndex, 1)
  }

  addArticle(articleId, articleUrl, articlePrice) {
    const getNumbers = (str) => {
      if (str && str.match(/\d/g)) {
        return str.match(/\d/g).join("");
      }
      else {
        return "0"
      }
    }
    let tempArticles = cloneDeep(this.articles)
    tempArticles.push({
      id: articleId,
      to: articleUrl,
      price: articlePrice
    })
    let sortedArticles = tempArticles.sort((a, b) => getNumbers(a.price).localeCompare(getNumbers(b.price), undefined, { numeric: true }))
    this.articles = sortedArticles
    sessionStorage.setItem('articles', JSON.stringify(this.articles))
  }

  updateProductsCompareStore() {
    var articles = []
    if (sessionStorage.getItem('articles')) {
      articles = JSON.parse(sessionStorage.getItem('articles'))
    }
    this.articles = articles
  }

  articleExists = (articleId) => {
    return this.articles.some(art => art.id === articleId)
  };

  getArticleInfo = async (articleId) => {
    var articleInfo = this.articlesInfo.find(art => art.id === articleId)
    if (articleInfo) {
      return articleInfo
    }
    else {
      try {
        articleInfo = await apiClient.getJson(APIEndpoints.article(articleId))
        this.articlesInfo.push(articleInfo)
        return articleInfo
      }
      catch (e) {
        return {}
      }
    }
  };
}

export default ProductsCompareStore;
