import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import ArraySelect from '../Select/ArraySelect'
import { useComplaintsState } from '../../stores/ComplaintsProvider'
import { t } from 'i18next'
import CostCenterSelect from '../Employees/Form/FormComponents/CostCenterSelect'
import CustomDropdown from '../Dropdowns/CustomDropdown'
import useStores from '../../stores/useStore'



const inputWho = (isComplaint, isRetoure) => [
  {
    name: isComplaint ? t('complaints:myComplaints') : isRetoure ? t('complaints:myReturns') : t('complaints:myReturnsComplaints'), value: false
  },
  {
    name: isComplaint ? t('complaints:allComplaints') : isRetoure ? t('complaints:allReturns') : t('complaints:allReturnsComplaints'), value: true
  }
]

const FilterModal = ({ setShowFilterModal }) => {
  const {
    filter,
    handleChangeFilter,
    setActiveFilterCostCenter
  } = useComplaintsState()
  const statusOptions = [
    {
      id: 'open',
      displayName: t('complaints:open'),
    },
    {
      id: 'closed_NotCleared',
      displayName: t('complaints:notCleared'),

    }
  ]
  const inputWhen = [
    {
      name: t('orders:lastMonth'),
      value: "1"
    },
    {
      name: t('orders:last3month'),
      value: "3"
    },
    {
      name: t('orders:last6month'),
      value: "6"
    },
    {
      name: t('orders:lastYear'),
      value: "12"
    },
    {
      name: t('all'),
      value: ''
    }
  ]
  const [status, setStatus] = useState(filter.status)
  const [lastMonths, setLastMonths] = useState(filter.lastMonths)
  const [showAllReturns, setShowAllReturns] = useState(filter.showAllReturns)
  const [targetCostcenterId, setTargetCostCenterId] = useState(filter.targetCostcenterId)
  const isComplaint = filter.type === 'complaint'
  const isRetoure = filter.type === 'retoure'
  const { uiStore, overlayRightStore } = useStores()
  const applyFilters = () => {
    let newFilter = { ...filter, lastMonths, status, showAllReturns, targetCostcenterId }
    handleChangeFilter(newFilter)
    handleClose()
  }
  const handleClose = () => {
    overlayRightStore.closeAll()
    setShowFilterModal(false)
  }

  const handleChangeCostCenter = (costCenter) => {
    setTargetCostCenterId(costCenter ? costCenter.id : null)
    setActiveFilterCostCenter(costCenter ? costCenter.displayName : null)
  }

  return (
    <Modal show={true} onHide={handleClose} backdrop="static" enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>{isComplaint ? t('complaints:filterComplaints') : isRetoure ? t('complaints:filterReturns') : t('complaints:filterReturnsComplaints')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex flex-wrap mb-3">
            <div className="mb-3 me-3">
              {uiStore.allowShowAllOrders && <CustomDropdown value={showAllReturns} data={inputWho(isComplaint, isRetoure)} returnObject={(obj) => setShowAllReturns(obj.value)} />
              }
            </div>
            <div className="mb-3">
              <CustomDropdown value={lastMonths} data={inputWhen} returnObject={(obj) => setLastMonths(obj.value)} />
            </div>
          </div>
          <div className="mb-3">
            <Form.Label>{t('costcenter')}</Form.Label>
            <CostCenterSelect id="costCenterSearchField"
              onChange={handleChangeCostCenter}
              value={targetCostcenterId}
              withSearch
              overLayRootZIndex={1056}
            />
          </div>
          <Form.Group className="mb-3">
            <Form.Label>{t('status')}</Form.Label>
            <ArraySelect
              name="status"
              onChange={obj => setStatus(obj.id)}
              value={status}
              options={statusOptions}
              defaultOptionIsSelectable
            //defaultOption.id???
            />
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>{t('cancelButton')}</Button>
        <Button onClick={applyFilters}>{t('okButton')}</Button>
      </Modal.Footer>
    </Modal >
  )
}

export default FilterModal