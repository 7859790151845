import React from 'react'
import DOMPurify from 'dompurify';

const SafelySetInnerHTML = React.forwardRef((props, ref) => {
    const { className, style, children } = props
    const dirty = children
    const clean = DOMPurify.sanitize(dirty, {
        FORCE_BODY: true,
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['target']
    });
    return (
        <div className={className} style={style} dangerouslySetInnerHTML={{ __html: clean }} />
    )
})

export default SafelySetInnerHTML
