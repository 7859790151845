import React, { useCallback, useState } from 'react'
import { Alert } from 'react-bootstrap'
import Progress from '../Form/Progress'
import { useEmployeeDataState } from '../../../stores/EmployeeDataProvider'
import { ButtonWithLoadingState } from '../../Buttons'
import { useEmployeesState } from '../../../stores/EmployeesProvider'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useStores from '../../../stores/useStore'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'

const StyledContainer = styled.div`
  @media screen and (min-width: 1081px) {
    position: sticky;
    top: 50px;
  }
`

const Sidebar = () => {
  const {
    submitAddEmployee,
    errorsOccurredOnSubmit,
    setSuccessSubmit,
    successSubmit
  } = useEmployeeDataState()
  const { setFilterEmployee } = useEmployeesState()
  const [isSaving, setIsSaving] = useState(false)
  const navigate = useNavigate();
  const { laundryShelfStore } = useStores()
  const submitBPLoundryShelvesError = useObserver(() => laundryShelfStore.submitBPLoundryShelvesError)

  const handleSubmitClick = useCallback(async e => {
    e.preventDefault()
    e.stopPropagation()
    setSuccessSubmit(false)
    setIsSaving(true)
    if (laundryShelfStore.precheckMandatoryLaundryData()) {
      const createdUserInfo = await submitAddEmployee()
      if (createdUserInfo) {
        const { createdUserId, personalNr } = createdUserInfo
        await laundryShelfStore.submitBPLoundryShelves(createdUserId)
        setFilterEmployee(personalNr)
        setTimeout(() => {
          navigate("/employees")
        }, 1500)
      }
    }
    setIsSaving(false)
  }, [setSuccessSubmit, laundryShelfStore, submitAddEmployee, setFilterEmployee, navigate])

  const { t } = useTranslation()

  return (
    <StyledContainer>
      <header>
        <div className="h2">{t('OverlayProgress:header')}</div>
      </header>
      <div className="content">
        <ButtonWithLoadingState
          className="submit-btn mb-3"
          isLoading={isSaving}
          onClick={handleSubmitClick}
          disabled={isSaving || successSubmit}
          buttonText={t('OverlayProgress:save')}
          loadingText={t('OverlayProgress:save')}
        />
        {(!!errorsOccurredOnSubmit || !!submitBPLoundryShelvesError) && (
          <Alert variant="danger">
            {t('OverlayProgress:danger')}
          </Alert>
        )}
        {!!successSubmit && (
          <Alert variant="success">
            {t('OverlayProgress:success')}

          </Alert>
        )}
        <Progress />
      </div>

    </StyledContainer>
  )
}

export default Sidebar
