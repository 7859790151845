import React, { useState, useEffect } from "react"
import { Spinner } from 'react-bootstrap'
import useCabinTypesState from '../../../stores/Configuration/useCabinTypesState'
import BootstrapTable from 'react-bootstrap-table-next'
import UniversalTableWrapperTest from '../../Table/UniversalTableWrapperTest'
import { useTranslation } from 'react-i18next'

const AppointmentTypesSelect = () => {
    const { appointmentTypes, assignedAppointmentTypes, setAssignedAppointmentTypes } = useCabinTypesState()
    const [checked, setChecked] = useState([])
    const { t } = useTranslation()

    //[{id:"",checked:bool,displayName:""}]
    useEffect(() => {
        if (assignedAppointmentTypes && appointmentTypes) {
            const newChecked = []
            for (const obj of appointmentTypes) {
                newChecked.push({ id: obj.id, checked: assignedAppointmentTypes.includes(obj.id), displayName: obj.displayName })
            }
            setChecked(newChecked)
        }

    }, [appointmentTypes, assignedAppointmentTypes])

    const handleLocalChangeCheckbox = id => {
        if (assignedAppointmentTypes.includes(id)) {
            var index = assignedAppointmentTypes.indexOf(id);
            var newValues = [...assignedAppointmentTypes]
            newValues.splice(index, 1);
            setAssignedAppointmentTypes(newValues)
        }
        else {
            setAssignedAppointmentTypes(prev => [...prev, id])
        }
    }

    const onRowClick = (e, row) => {
        handleLocalChangeCheckbox(row.id)
    }
    const columns = [
        {
            dataField: 'checked',
            text: t('assigned'),
            sort: true,
            formatter: (cell, row) => {
                return <input name="useSecondaryOfficeName" type="checkbox" checked={cell} onChange={() => { }} id={row.id} />
            }
        },
        {
            dataField: 'displayName',
            text: t('appointmentTypes:appointmentType'),
            sort: true
        }
    ];


    return (
        <div>
            {(appointmentTypes === null || assignedAppointmentTypes === null)
                ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
                : <>
                    <UniversalTableWrapperTest>
                        <BootstrapTable
                            keyField='id'
                            data={checked}
                            columns={columns}
                            bootstrap4
                            hover
                            bordered={false}
                            rowEvents={{ onClick: onRowClick }}
                        />
                    </UniversalTableWrapperTest>
                </>
            }

        </div>
    );
}

export default AppointmentTypesSelect