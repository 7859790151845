import React, { useEffect, useState } from 'react'
import { StyledCircleCheckIconWrapper } from './StyledCircleCheckIconWrapper'
import { CircleCheckIcon } from '../../../Icons/CircleCheckIcon'
import { useTranslation } from 'react-i18next'
import { emptyGuid } from '../../../../utils/emptyGuid'

const JobfunctionsCheck = ({ activities }) => {
  const [isValid, setIsValid] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (activities.newItems.length) {
      const activity = activities.newItems[0]
      if (activity.id !== emptyGuid || !!activity.percentage || activity.costCenter.id !== emptyGuid) {
        setIsValid(true)
      } else {
        setIsValid(false)
      }
    } else {
      setIsValid(false)
    }
  }, [activities.newItems])

  return (
    <>
      <StyledCircleCheckIconWrapper>
        <CircleCheckIcon variant={isValid ? 'optional' : 'none'} />
      </StyledCircleCheckIconWrapper>
      <span>{t('OverlayProgress:jobFunctions')}</span>
    </>
  )
}

export default JobfunctionsCheck
