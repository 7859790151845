import React, { Component } from 'react'
import { observable, reaction, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react'

import styled, { withTheme } from 'styled-components'
import { Alert, FormControl, Form } from 'react-bootstrap'

import { colors } from '../../styles/colors'
import { ButtonWithLoadingState } from '../Buttons'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import ArraySelect from '../Select/ArraySelect'
import PasswordInputField from '../Forms/PasswordInputField'
import MandatoryField from '../MandatoryField/MandatoryField'
const StyledButtons = styled.p`
  margin-top: 1rem;
`

const StyledFormControlWrapper = styled.div`
  margin-top: 1rem;
`

const StyledSubline = styled.p`
  color: ${colors.gray4};
  margin-top: 1rem;
  margin-bottom: 1rem;
`

class NewPasswordBox extends Component {
  securityQuestions = [];
  passwordRules = {};
  scrollTopRef = null
  scrollTopReaction = null

  constructor(props) {
    super(props)

    makeObservable(this, {
      securityQuestions: observable,
      passwordRules: observable
    });

    this.props.profileStore.passwordNew.isSucceeded = false
    this.scrollTopRef = React.createRef()

    apiClient.getJson(APIEndpoints.passwordRules).then(response => {
      this.passwordRules = response
    })
  }

  componentDidMount() {
    const { passwordNew } = this.props.profileStore

    reaction(() => this.props.profileStore.profile.id,
      (profileID, selfReaction) => {
        const { profile } = this.props.profileStore
        if (profileID) {
          apiClient.getJson(APIEndpoints.securityQuestions).then(response => {
            this.securityQuestions = response
            if (!!response.find(item => item.id === profile.securityQuestionId)) {
              passwordNew.securityQuestionId = profile.securityQuestionId
            } else if (response && response.length) {
              passwordNew.securityQuestionId = response[0].id
            }
          })

          selfReaction.dispose()
        }
      }, { fireImmediately: true })

    this.scrollTopReaction = reaction(
      () => this.props.profileStore.passwordNew.submitError,
      submitError => {
        if (submitError) {
          this.scrollTopRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }
    )
  }

  componentWillUnmount() {
    this.scrollTopReaction()
    this.props.profileStore.resetNewPasswordBox()
  }

  render() {
    const { t, profileStore, withCancelButton, loginStore } = this.props
    profileStore.profile.forcePasswordChange && loginStore.loginData.password && profileStore.setOldPassword(loginStore.loginData.password)
    const securityQuestionRequired = !profileStore.profile.hasAnsweredSecurityQuestion
    return (
      <React.Fragment>
        <div ref={this.scrollTopRef} style={{ position: 'relative', top: '-80px' }} />
        {profileStore.passwordNew.submitError
          ? <Alert variant='danger'>
            {t('loginBox:checkLoginData')}
          </Alert>
          : ''}

        <h4>{t('personalSpace:changePassword')}</h4>
        <Form onSubmit={(e) => profileStore.handleOnSetPasswordSubmit(e)}>
          <Form.Group className="mb-3">
            {profileStore.profile.forcePasswordChange && loginStore.loginData.password ? '' :
              <>
                <StyledSubline>{t('passwordReset:oldPassword')} *</StyledSubline>
                <StyledFormControlWrapper>
                  <PasswordInputField
                    inputKey='formOldPasswordBox'
                    placeholder="********"
                    id='formOldPasswordBox'
                    isInvalid={profileStore.passwordNew.formErrors.oldPwd}
                    onChange={(e) => profileStore.handleOnChangeOldPassword(e)}
                    autoComplete="none"
                  >
                    <FormControl.Feedback type="invalid">
                      {profileStore.passwordNew.formErrors.oldPwd}
                    </FormControl.Feedback>
                  </PasswordInputField>
                </StyledFormControlWrapper>
              </>
            }

            <StyledSubline>{t('passwordReset:newPassword')} *</StyledSubline>
            {this.passwordRules.hasRules && <Alert variant="info">{this.passwordRules.info}</Alert>}
            <StyledFormControlWrapper>
              <PasswordInputField
                inputKey='formNewPasswordBox'
                placeholder="********"
                id='formNewPasswordBox'
                onChange={(e) => profileStore.handleChangeNewPassword(e)}
                autoComplete="none"
                isInvalid={profileStore.passwordNew.formErrors.newPwd}
              />
            </StyledFormControlWrapper>

            <StyledFormControlWrapper>
              <PasswordInputField
                inputKey='formNewPasswordBoxRepeat'
                placeholder={t('passwordReset:newPasswordRepeat')}
                id='formNewPasswordBoxRepeat'
                isInvalid={profileStore.passwordNew.formErrors.newPwd}
                onChange={(e) => profileStore.handleChangeNewPasswordRepeat(e)}
                autoComplete="none"
              >
                <FormControl.Feedback type="invalid">
                  {profileStore.passwordNew.formErrors.newPwd}
                </FormControl.Feedback>
              </PasswordInputField>
            </StyledFormControlWrapper>

            <StyledSubline>{t('passwordReset:securityQuestion')} {securityQuestionRequired ? '*' : null}</StyledSubline>
            <StyledFormControlWrapper>
              <ArraySelect
                selectKey='securityQuestions'
                id='securityQuestions'
                returnEvent
                onChange={(e) => profileStore.handleChangeSequrityQuestion(e)}
                value={profileStore.passwordNew.securityQuestionId}
                autoComplete="none"
                options={this.securityQuestions}
                isInvalid={profileStore.passwordNew.formErrors.securityQuestionId}
              />
              <FormControl.Feedback type="invalid">
                {profileStore.passwordNew.formErrors.securityQuestionId}
              </FormControl.Feedback>
            </StyledFormControlWrapper>

            <StyledSubline>{t('passwordReset:answer')} {securityQuestionRequired ? '*' : null}</StyledSubline>
            <StyledFormControlWrapper>
              <PasswordInputField
                key='securityQuestionsAnswer'
                type='text'
                placeholder="***********"
                id='securityQuestionsAnswer'
                isInvalid={profileStore.passwordNew.formErrors.securityQuestionAnswer}
                onChange={(e) => profileStore.handleChangeSecurityQuestionAnswer(e)}
                autoComplete="none"
              >
                <FormControl.Feedback type="invalid">
                  {profileStore.passwordNew.formErrors.securityQuestionAnswer}
                </FormControl.Feedback>
              </PasswordInputField>
            </StyledFormControlWrapper>

            <div className="d-flex align-items-center">
              {withCancelButton && <Link to="/" className="btn btn-secondary me-3">{t('cancelButton')}</Link>}
              <StyledButtons className='d-flex justify-content-between align-items-center'>
                <ButtonWithLoadingState
                  buttonText={t('saveButton')}
                  className='float-end'
                  isLoading={profileStore.passwordNew.isLoading}
                  type='submit'
                  variant='primary' />
              </StyledButtons>
            </div>
            {profileStore.passwordNew.isSucceeded
              ? <Alert variant='success'>
                {t('personalSpace:changedPasswordSuccessfully')}
              </Alert>
              : ''}
          </Form.Group>
        </Form>
        <MandatoryField />
      </React.Fragment >
    )
  }
}

export default withTheme(withTranslation()(inject('profileStore', 'loginStore')(observer(NewPasswordBox))))
