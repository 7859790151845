import React from 'react'
import { inject, observer } from 'mobx-react'
import { Spinner } from 'react-bootstrap'
import { EditArticleComment } from './EditArticleComment'
import { StyledCard, StyledList } from './StyledDetailCard'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { formatedDate } from '../../../utils/formatedDate'




const GeneralInfoCard = inject('inventoryStore', 'uiStore')(observer(props => {
    const { inventoryStore, uiStore } = props
    const { t } = useTranslation()

    const dataArray = (articleDetails) => {
        return (
            [
                {
                    label: t('articles'),
                    value: articleDetails.article.displayName
                },
                {
                    label: t('inventory:articleNr'),
                    value: articleDetails.article.articleNumber
                },
                {
                    label: t('productDetail:expirationDate'),
                    value: `${formatedDate(articleDetails.nextExpiryDate)} (${articleDetails.nextExpiryQuantity}x)`,
                    hide: !formatedDate(articleDetails.nextExpiryDate)
                },
                {
                    label: t('inventory:contingentAmount'),
                    value: articleDetails.articleAvailability && articleDetails.articleAvailability.currentAmount
                },
                {
                    label: t('inventory:maxAmount'),
                    value: articleDetails.articleAvailability && articleDetails.articleAvailability.maxAmount
                },
                {
                    label: t('comment'),
                    value: <EditArticleComment />,
                    hide: !uiStore.allowBPArticleComment
                }
            ])
    }

    return (
        <StyledCard>

            {(!inventoryStore.articleDetails || !uiStore.dashboard || inventoryStore.articleDetailsIsLoading)
                ? <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
                : <>
                    <div className="d-flex justify-content-between align-items-start"><h1>{t('inventory:generalInfos')}</h1><Link className="btn btn-outline-secondary btn-details " to={`/articleCatalog/article/${inventoryStore.articleDetails.article.id}`}>{t("inventory:goToArticle")}</Link></div>
                    <StyledList>
                        {dataArray(inventoryStore.articleDetails).map((listItem, idx) =>
                            listItem.hide
                                ? null
                                : <li key={`${idx} ${listItem.label}`}>
                                    <div className="label">{listItem.label}</div>
                                    <div className="value" >{listItem.value}</div>
                                </li>
                        )}
                    </StyledList>
                </>
            }
        </StyledCard>
    )
}))

export default GeneralInfoCard
