import React, { useEffect, useState } from 'react'
import useStores from '../../stores/useStore'
import { useObserver } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import EmployeeHealthDataProvider from '../../stores/EmployeeHealthDataProvider'
import { Alert, Spinner } from 'react-bootstrap'
import EditSubheader from '../../components/Employees/EditHealthData/EditSubheader'
import Sidebar from '../../components/Employees/EditHealthData/Sidebar'
import EditContentArea from '../../components/Employees/EditHealthData/EditContentArea'
import { useParams } from 'react-router-dom'
import { useEmployeeHealthDataState } from '../../stores/EmployeeHealthDataProvider'
import styled from 'styled-components'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { TestSidebarRight } from '../../components/Page/logix2020/TestSidebarRight'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import usePrevious from '../../utils/hooks/usePrevious'

const StyledContentArea = styled(ContentArea)`
   padding: 20px 40px 20px 36px;
   @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px)  {
    padding: 20px;
    min-height: calc(100vh - 440px);//header+navbar+sidebar=440
   }
`
const EditEmployeePageContent = () => {
    const { employeeId } = useParams()
    const { loadEmployeeData } = useEmployeeHealthDataState()
    const prevEmployeeId = usePrevious({ employeeId })
    useEffect(() => {
        //only call function if employeeId changes cause other dependencies will cause infinity loop
        if (prevEmployeeId?.employeeId !== employeeId) {
            loadEmployeeData(employeeId)
        }
    }, [employeeId, loadEmployeeData, prevEmployeeId])

    return (
        <>
            <EditSubheader />
            <FlexWrapper >
                <StyledContentArea>
                    <EditContentArea />
                </StyledContentArea>
                <TestSidebarRight>
                    <Sidebar />
                </TestSidebarRight>
            </FlexWrapper>
            <BigFooter />
        </>
    )
}

const EditHealthDataPage = () => {
    const { uiStore } = useStores()
    const { t } = useTranslation()
    const [error, setError] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setError(true);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return useObserver(() => (
        <>
            {!uiStore.checkupAdministration
                ? (
                    <>
                        <EditSubheader withName={false} />
                        <FlexWrapper >
                            <StyledContentArea>
                                {error
                                    ? <Alert variant="danger">{t('accessDenied')}</Alert>
                                    : <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
                                }
                            </StyledContentArea>
                            <TestSidebarRight>
                            </TestSidebarRight>
                        </FlexWrapper>
                    </>
                ) : (
                    <EmployeeHealthDataProvider>
                        <EditEmployeePageContent />
                    </EmployeeHealthDataProvider>
                )}
        </>
    ))
}

export default EditHealthDataPage
