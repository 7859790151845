import React, { useCallback, useEffect, useState } from 'react'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { ImagesIcon } from '../components/Icons/ImagesIcon'
import useStores from './useStore'
import { t } from 'i18next'
import usePrevious from '../utils/hooks/usePrevious'

const InventoryBookingsTableContext = React.createContext({})
export function useInventoryBookingsState() {
  return React.useContext(InventoryBookingsTableContext)
}

// Dashboard Inventory bookings approval Table
const InventoryBookingsTableProvider = ({ children }) => {
  const [tableIsLoading, setTableIsLoading] = useState(false)
  const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([])
  const [incomingOrders, setIncomingOrders] = useState([])
  const [pageOffset, setPageOffset] = useState(0)
  const [pageLimit, setPageLimit] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [allSelected, setAllSelected] = useState(false)
  const [allSelectedLoading, setAllSelectedLoading] = useState(false)
  const [selectedImg, setSelectedImg] = useState(null)
  const { uiStore } = useStores()
  const prevTableProps = usePrevious({ pageLimit, pageOffset })

  const fetchOrders = useCallback(() => {
    setTableIsLoading(true)
    const params = {
      'filter.status': 'open',
      'page.limit': pageLimit,
      'page.offset': pageOffset,
      'filter.showAllRequests': uiStore.allowReleaseInventoryBookingChanges ? true : false
    }
    apiClient.getJson(APIEndpoints.inventoryChangeRequest().get, params, true, true).then(response => {
      const result = response.data
      setTotalCount(response.headers['x-total-count'] ? parseInt(response.headers['x-total-count']) : 0)
      const preparedResult = result.map(item => {

        return {
          ...item, actions: (
            <span>
              {
                item.image
                  ? <ImagesIcon
                    title={t('inventory:image_other')}
                    width="20px"
                    height="20px"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedImg({
                        "url": item.image.url,
                        "description": item.article.displayName,
                        "id": item.id
                      })
                    }}
                  />
                  : null
              }

            </span>
          ),
          checkBox: (
            <span>
              <input type="checkbox" id={item.id} name="scales"
                checked={selectedCheckBoxes.includes(item.id)} onChange={() => { }}>
              </input>
            </span>
          )
        }
      })
      setIncomingOrders(preparedResult)
    }).finally(() => {
      setTableIsLoading(false)
    })
  }, [pageLimit, pageOffset, selectedCheckBoxes, uiStore.allowReleaseInventoryBookingChanges])

  useEffect(() => {
    if (prevTableProps?.pageLimit !== pageLimit || prevTableProps?.pageOffset !== pageOffset) {
      fetchOrders()
    }
  }, [fetchOrders, pageLimit, pageOffset, prevTableProps])

  const onRowClick = (cell, row) => {
    if (allSelected) {
      setAllSelected(false)
    }
    if (selectedCheckBoxes.includes(row.id)) {
      var index = selectedCheckBoxes.indexOf(row.id);
      var newValues = [...selectedCheckBoxes]
      newValues.splice(index, 1);
      setSelectedCheckBoxes(newValues)
    }
    else {
      setSelectedCheckBoxes(prev => [...prev, row.id])
    }
  }

  const selectAll = () => {
    if (allSelected) {
      setAllSelected(false)
      setSelectedCheckBoxes([])
    }
    else {
      setAllSelectedLoading(true)
      const params = {
        'filter.status': 'open',
        'filter.showAllRequests': uiStore.allowReleasePointsChanges ? true : false
      }
      apiClient.getJson(APIEndpoints.inventoryChangeRequest().get, params).then(response => {
        if (response) {
          const arrayOfAllIds = response.map(obj => obj.id)
          setSelectedCheckBoxes(arrayOfAllIds)
        }
      }).finally(() => { setAllSelected(true); setAllSelectedLoading(false) })
    }
  }

  useEffect(() => {
    setIncomingOrders(prev => {
      return prev.map(item => ({
        ...item,
        checkBox: (
          <span>
            <input type="checkbox" id={item.id} name="scales"
              checked={selectedCheckBoxes.includes(item.id)} onChange={() => { }}>
            </input>
          </span>
        )
      }))
    })
  }, [selectedCheckBoxes])

  const contextValues = {
    tableIsLoading,
    incomingOrders,
    onRowClick,
    pageOffset,
    setPageOffset,
    pageLimit,
    setPageLimit,
    totalCount,
    setTotalCount,
    selectedCheckBoxes,
    setSelectedCheckBoxes,
    fetchOrders,
    setTableIsLoading,
    allSelectedLoading, selectAll, allSelected,
    selectedImg, setSelectedImg
  }
  return (
    <InventoryBookingsTableContext.Provider value={contextValues}>
      {children}
    </InventoryBookingsTableContext.Provider>
  )
}

export default InventoryBookingsTableProvider
