import React from 'react'
import { Subheader } from '../components/Page/logix2020/Subheader'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { ButtonWithLoadingState } from '../components/Buttons'
import { CartIcon } from '../components/Icons/CartIcon'
import styled from 'styled-components'
import { PdfButton } from '../components/Buttons/PdfButton'
import { ExcelButton } from '../components/Buttons/ExcelButton'
import { PenIcon } from '../components/Icons/PenIcon'
import { Link } from 'react-router-dom'
import { MdLibraryAdd } from 'react-icons/md'
import ArraySelectTests from '../components/Select/ArraySelectTests'
import FileInputTest from '../components/Forms/FileInputTest'
import { colors } from '../styles/colors'

const StyledRow = styled(Row)`
 margin-bottom:1rem;
`
const Colors = () => {
    return (
        <>
            <Subheader className="subHeader-v1">
                <div className="subheader-title">Colors</div>
            </Subheader>
            <ContentArea style={{ marginBottom: "100%" }}>
                <h1>Alerts</h1>
                <Alert variant="info">info</Alert>
                <Alert variant="danger">danger</Alert>
                <Alert >primary</Alert>
                <Alert variant="success">success</Alert>
                <h1>Buttons</h1>
                <StyledRow>
                    <Col>
                        <Button className="w-100" >primary</Button>
                    </Col>
                    <Col>
                        <Button className="w-100" variant="secondary" >secondary</Button>
                    </Col>
                    <Col>
                        <Button className="w-100" variant="danger">danger</Button>
                    </Col>
                    <Col>
                        <Button className="w-100" variant="success">success</Button>
                    </Col>
                </StyledRow>
                <h1>Buttons (disabled)</h1>
                <StyledRow>
                    <Col>
                        <Button disabled className="w-100" >primary</Button>
                    </Col>
                    <Col>
                        <Button disabled className="w-100" variant="secondary" >secondary</Button>
                    </Col>
                    <Col>
                        <Button disabled className="w-100" variant="danger">danger</Button>
                    </Col>

                    <Col>
                        <Button disabled className="w-100" variant="success">success</Button>
                    </Col>
                </StyledRow>
                <h1>Buttons (outline)</h1>
                <StyledRow>
                    <Col>
                        <Button variant="outline-primary" className="w-100" >primary</Button>
                    </Col>
                    <Col>
                        <Button variant="outline-secondary" className="w-100"  >secondary</Button>
                    </Col>
                    <Col>
                        <Button variant="outline-danger" className="w-100"  >danger</Button>
                    </Col>

                    <Col>
                        <Button variant="outline-success" className="w-100"  >success</Button>
                    </Col>
                </StyledRow>
                <h1>Buttons (with Icon)</h1>
                <StyledRow>
                    <Col>
                        <ButtonWithLoadingState className="w-100" buttonText="primary" buttonIcon={<CartIcon className="me-3" />} />
                    </Col>
                    <Col>
                        <ButtonWithLoadingState className="w-100" variant="secondary" buttonText="secondary" buttonIcon={<CartIcon className="me-3" />} />
                    </Col>
                    <Col>
                        <ButtonWithLoadingState className="w-100" variant="danger" buttonText="danger" buttonIcon={<CartIcon className="me-3" />} />
                    </Col>
                    <Col>
                        <ButtonWithLoadingState className="w-100" variant="success" buttonText="success" buttonIcon={<CartIcon className="me-3" />} />
                    </Col>
                </StyledRow>
                <h1>Buttons (loading)</h1>
                <StyledRow>
                    <Col>
                        <ButtonWithLoadingState className="w-100" isLoading />
                    </Col>
                    <Col>
                        <ButtonWithLoadingState className="w-100" variant="secondary" isLoading />
                    </Col>
                    <Col>
                        <ButtonWithLoadingState className="w-100" variant="danger" isLoading />
                    </Col>
                    <Col>
                        <ButtonWithLoadingState className="w-100" variant="success" isLoading />
                    </Col>
                </StyledRow>
                <h1>Buttons (Excel)</h1>
                <StyledRow>
                    <Col>
                        <ExcelButton className="w-100" variant="primary" buttonText="secondary" />
                    </Col>
                    <Col>
                        <ExcelButton className="w-100" variant="secondary" buttonText="secondary" />
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </StyledRow>
                <h1>Buttons (Pdf)</h1>
                <StyledRow>
                    <Col>
                        <PdfButton className="w-100" variant="primary" buttonText="secondary" />
                    </Col>
                    <Col>
                        <PdfButton className="w-100" variant="secondary" buttonText="secondary" />
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </StyledRow>
                <h1>Buttons (Add)</h1>

                <StyledRow>
                    <Col>
                        <Link to="#" className="w-100 btn btn-primary text-white"><MdLibraryAdd className="me-3" />primary</Link>
                    </Col>
                    <Col>
                        <Link to="#" className="w-100  btn btn-secondary text-white"><MdLibraryAdd className="me-3" />secondary</Link>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </StyledRow>
                <h1>Buttons (Edit)</h1>
                <StyledRow>
                    <Col>
                        <Button className="w-100" variant="primary" ><PenIcon color="white" /></Button>
                    </Col>
                    <Col>
                        <Button className="w-100" variant="secondary" ><PenIcon color="white" /></Button>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </StyledRow>
                <h1>global colors</h1>

                <StyledRow>
                    <Col>
                        <div>
                            <div className="w-100 p-2" style={{ background: colors.gray1 }}>
                                gray1
                            </div>
                            <div className="w-100 p-2" style={{ background: colors.gray2 }}>
                                gray2
                            </div>
                            <div className="w-100 p-2" style={{ background: colors.gray3 }}>
                                gray3
                            </div>
                            <div className="w-100 p-2" style={{ background: colors.gray4 }}>
                                gray4
                            </div>
                            <div className="w-100 p-2" style={{ background: colors.gray5 }}>
                                gray5
                            </div>
                            <div className="w-100 p-2" style={{ background: colors.gray6 }}>
                                gray6
                            </div>
                            <div className="w-100 p-2" style={{ background: colors.gray7 }}>
                                gray7
                            </div>
                            <div className="w-100 p-2" style={{ background: colors.gray8 }}>
                                gray8
                            </div>
                            <div className="w-100 p-2" style={{ background: colors.sidebarActiveGray }}>
                                sidebarActiveGray
                            </div>
                            <div className="w-100 p-2" style={{ background: colors.green }}>
                                green
                            </div>
                            <div className="w-100 p-2" style={{ background: colors.textColorB80 }}>
                                textColorB80
                            </div>
                            <div className="w-100 p-2" style={{ background: colors.textColor }}>
                                textColor
                            </div>
                            <div className="w-100 p-2" style={{ background: colors.secondary }}>
                                secondary
                            </div>
                        </div>
                    </Col>



                </StyledRow>
                <ArraySelectTests />
                <FileInputTest />
            </ContentArea >
        </>
    )
}

export default Colors