import React from 'react'
import { CloseButton, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { useEmployeesState } from '../../stores/EmployeesProvider'
import { useTranslation } from 'react-i18next'

const StyledWrapper = styled.div`
  //display: flex;
  flex-grow: 1;
  padding: 4px;

  .form-label {
    margin-bottom: 5px;
  }
`

const StyledItem = styled.div`
  margin: 5px;
  padding: 6px 10px;
  border: 1px solid ${colors.borderColor};
  border-radius: 8px;

  .close {
    margin-left: 15px;
  }
`

const StyledItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
`

const ActiveTableFilters = () => {
  const {
    officeFilter,
    setOfficeFilter,
    costCenterFilter,
    setCostCenterFilter,
    checkUpFilter,
    officeName,
    costCenterName,
    setCheckUpFilter,
    missingArticleConfigurationsFilter,
    setMissingArticleConfigurationsFilter } = useEmployeesState()

  const { t } = useTranslation()


  if (!officeFilter && !costCenterFilter && !checkUpFilter && !missingArticleConfigurationsFilter) {
    return null
  }
  return (
    <StyledWrapper>
      <Form.Label>{t('employeesPage:activeFilter')}</Form.Label>
      <StyledItemWrapper>
        {!!officeFilter && (
          <StyledItem><b>{t('orgaUnitShortcut')}:</b> {officeName}<CloseButton onClick={() => { setOfficeFilter(null) }} /></StyledItem>
        )}
        {!!costCenterFilter && (
          <StyledItem><b>{t('costcenterShortcut')}:</b> {costCenterName} <CloseButton
            onClick={() => { setCostCenterFilter(null) }} /></StyledItem>
        )}
        {!!checkUpFilter && (
          <StyledItem>{checkUpFilter.displayName} <CloseButton
            onClick={() => { setCheckUpFilter(null) }} /></StyledItem>
        )}
        {!!missingArticleConfigurationsFilter && (
          <StyledItem><b>Größen fehlen</b><CloseButton
            onClick={() => { setMissingArticleConfigurationsFilter(null) }} /></StyledItem>
        )}
      </StyledItemWrapper>
    </StyledWrapper>
  )
}

export default ActiveTableFilters
