import { cloneDeep } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Alert, Col, Form, Row } from 'react-bootstrap'
import { debounce } from 'throttle-debounce'
import { setValueByKey } from '../../../utils/utils'
import ArraySelect from '../../Select/ArraySelect'
import { t } from 'i18next'
import { useCancelToken } from '../../../utils/hooks/useCancelToken'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import apiClient from '../../../utils/api/apiClient'
import { useCallback } from 'react'
import { OverlaySpinner, OverlaySpinnerWrapper } from '../../Forms/StyledInputLoadingOverlay'
import { useContingentTemplatesState } from '../../../stores/Configuration/ContingentTemplatesProvider'
import { makeCall } from '../../../utils/api/makeCall'

const ArticleAmounts = ({ entitlementId, groupId, article }) => {
  const [_articleValues, _setArticleValues] = useState(article.value)
  const [loadingValues, setLoadingValues] = useState({})
  const { createOrCancelToken, sourceRef, isCancel } = useCancelToken();
  const { setBigData } = useContingentTemplatesState()
  const [error, setError] = useState({})

  const patchArticleBackground = useCallback(async (articleData, key) => {
    createOrCancelToken()
    makeCall('patchBackground', async () => {
      setLoadingValues(prev => {
        prev[key] = true
        return cloneDeep(prev)
      })
      await apiClient.patchJson(APIEndpoints.contingentTemplate(entitlementId, groupId, article.id).groupArticle, articleData, true, false, { cancelToken: sourceRef.current.token })
      //change bigData in background
      setBigData(prev => {
        prev[entitlementId].groups[groupId].articles[article.id].value = articleData.value
        return prev
      })
      setLoadingValues({})
    }, setError, setLoadingValues, isCancel)
  }, [article.id, createOrCancelToken, entitlementId, groupId, isCancel, setBigData, sourceRef])

  const updateItem = e => {
    let key = e.target.name
    let value

    if (key === 'pointValue') {
      value = parseFloat(e.target.value) || 0
    } else if (e.target.type === 'number') {
      value = parseInt(e.target.value) || 0
    } else {
      value = e.target.value
    }

    let newArticleValues = setValueByKey(key, value, _articleValues)
    trigerLazyLoadDebounced(newArticleValues, key)
    _setArticleValues(
      newArticleValues
    )
  }

  const trigerLazyLoadDebounced = useMemo(
    () => debounce(200, async (newArticleValues, key) => {
      let newArticle = cloneDeep(article)
      newArticle.value = newArticleValues
      await patchArticleBackground(newArticle, key)
    }), [article, patchArticleBackground])

  return (
    <Row>
      <Col sm="12" lg="6" xxl="4">
        <Form.Group as={Row} >
          <Form.Label column >
            {t('productDetail:referenceAmount')}
          </Form.Label>
          <Col>
            <OverlaySpinnerWrapper>
              <Form.Control
                onChange={updateItem}
                value={_articleValues.quantity || ""}
                name="quantity"
                type="number"
                min="0"
                onWheel={(e) => e.target.blur()}
              />
              {loadingValues.quantity && <OverlaySpinner />}
            </OverlaySpinnerWrapper>
          </Col>
        </Form.Group>
        <Form.Group as={Row} >
          <Form.Label column >
            {t('contingentTemplates:freeQuantity')}
          </Form.Label>
          <Col>
            <OverlaySpinnerWrapper>
              <Form.Control
                onChange={updateItem}
                value={_articleValues.freeQuantity || ""}
                type="number"
                min="0"
                onWheel={(e) => e.target.blur()}
                name="freeQuantity"
              />
              {loadingValues.freeQuantity && <OverlaySpinner />}
            </OverlaySpinnerWrapper>
          </Col>
        </Form.Group>
      </Col>
      <Col sm="12" lg="6" xxl="4" >
        <Form.Group as={Row} >
          <Form.Label column style={{ whiteSpace: 'nowrap' }} >
            {t('productDetail:referenceAmount')} 2
          </Form.Label>
          <Col>
            <OverlaySpinnerWrapper>
              <Form.Control
                onChange={updateItem}
                value={_articleValues.quantity2 || ""}
                type="number"
                min="0"
                onWheel={(e) => e.target.blur()}
                name="quantity2"
              />
              {loadingValues.quantity2 && <OverlaySpinner />}
            </OverlaySpinnerWrapper>
          </Col>
        </Form.Group>
        <Form.Group as={Row} >
          <Form.Label column style={{ whiteSpace: 'nowrap' }} >
            {t('contingentTemplates:wearingTime')} ({t('month_other')})
          </Form.Label>
          <Col>
            <OverlaySpinnerWrapper>
              <Form.Control
                onChange={updateItem}
                value={_articleValues.usageDuration || ""}
                type="number"
                min="0"
                onWheel={(e) => e.target.blur()}
                name="usageDuration"
              />
              {loadingValues.usageDuration && <OverlaySpinner />}
            </OverlaySpinnerWrapper>
          </Col>
        </Form.Group>
      </Col>
      <Col sm="12" lg="6" xxl="4" >
        <Form.Group as={Row} >
          <Form.Label column >
            {t('contingentTemplates:orderProposal')}
          </Form.Label>
          <Col>
            <OverlaySpinnerWrapper>
              <Form.Control
                onChange={updateItem}
                value={_articleValues.initialOrderQuantity || ""}
                type="number"
                min="0"
                onWheel={(e) => e.target.blur()}
                name="initialOrderQuantity"
              />
              {loadingValues.initialOrderQuantity && <OverlaySpinner />}
            </OverlaySpinnerWrapper>
          </Col>
        </Form.Group>
        <Form.Group as={Row} >
          <Form.Label column >
            {t('points')}
          </Form.Label>
          <Col>
            <OverlaySpinnerWrapper>
              <Form.Control
                onChange={updateItem}
                value={_articleValues.pointValue || ""}
                type="number"
                min="0"
                onWheel={(e) => e.target.blur()}
                name="pointValue"
              />
              {loadingValues.pointValue && <OverlaySpinner />}
            </OverlaySpinnerWrapper>
          </Col>
        </Form.Group>
      </Col>
      <Col sm="12" lg="6" xxl="4" >
        <Form.Group as={Row} >
          <Form.Label column >
            {t('Overlay:gender')}
          </Form.Label>
          <Col>
            <OverlaySpinnerWrapper>
              <ArraySelect
                value={_articleValues.gender}
                name='gender'
                options={[{ id: "unisex", displayName: t('contingentTemplates:m+w') }, { id: "male", displayName: t('contingentTemplates:m') }, { id: "female", displayName: t('contingentTemplates:w') }]}
                onChange={updateItem}
                returnEvent
              />
              {loadingValues.gender && <OverlaySpinner />}
            </OverlaySpinnerWrapper>
          </Col>
        </Form.Group>
      </Col>
      {error.patchBackground ? <Alert variant="danger">{error.patchBackground.value}</Alert> : null}
    </Row >
  )
}

export default ArticleAmounts