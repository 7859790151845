import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal, Button, Container, Row, Col, Alert } from 'react-bootstrap'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { APIEndpoints } from '../../utils/api/apiConfig'
import apiClient from '../../utils/api/apiClient'
import CustomDropdown from '../Dropdowns/CustomDropdown'
import { useLocation } from 'react-router-dom'

const StyledModal = styled(Modal)`
    p {
        font-weight:bold;
        cursor: default;
        margin-bottom: 10px;
    }
`

const LanguageSwitchModal = inject('uiStore', 'profileStore')(observer(
    ({
        setShowLanguageModal,
        uiStore,
        profileStore,
        t }) => {

        const [languages, setLanguages] = useState(null);
        const [deliveryCountries, setDeliveryCountries] = useState(null);
        const [language, setLanguage] = useState('');
        const [deliveryCountryId, setDeliveryCountryId] = useState('');
        const location = useLocation()
        const onCancelHandler = () => {

            setShowLanguageModal(false);
        }

        const loadLanguages = () => {
            apiClient.getJson(APIEndpoints.languages)
                .then(res => {
                    let mappedLanguagesArray = res.map(language => {
                        return { name: language.displayName, value: language.isoCode }
                    })
                    setLanguages(mappedLanguagesArray);

                }).catch(err => {
                    console.log(err)
                })
        }

        const loadCountries = () => {
            apiClient.getJson(APIEndpoints.countries)
                .then(res => {
                    let mappedCountriesArray = res.map(country => {
                        return { name: country.displayName, value: country.id, isoCode: country.isoCode }
                    })
                    setDeliveryCountries(mappedCountriesArray);
                }).catch(err => {
                    console.log(err)
                })

        }

        useEffect(() => {
            loadCountries()
            loadLanguages()
        }, [])
        const changingCountryforbidden = location.pathname && (location.pathname.endsWith("confirmorder") || location.pathname.endsWith("confirmaddress"))

        return deliveryCountries && languages && (
            <StyledModal
                show={true}
                onHide={() => setShowLanguageModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {uiStore.allowShipmentCountrySelection ? t('languageAndCountry:deliveryCountryAndLanguageModalTitle') : t('languageAndCountry:languageModalTitle')}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Row>
                            {profileStore.profile.country && uiStore.allowShipmentCountrySelection &&
                                <Col className="pb-3 position-relative">
                                    <p>{t('languageAndCountry:deliveryCountry')}:</p>
                                    <CustomDropdown
                                        disabled={changingCountryforbidden}
                                        value={profileStore.profile.country.id}
                                        data={deliveryCountries}
                                        returnObject={e => setDeliveryCountryId(e.value)}
                                    />
                                </Col>}
                            <Col className="pb-3">
                                <p>{t('languageAndCountry:systemLanguage')}:</p>
                                <CustomDropdown
                                    value={uiStore.language.toUpperCase()}
                                    data={languages}
                                    returnObject={e => setLanguage(e.value)}
                                />
                            </Col>
                        </Row>
                    </Container>
                    {changingCountryforbidden && <Alert variant="info">{t('languageAndCountry:countrySelectDisabled')}</Alert>}
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={onCancelHandler}
                    >{t('cancelButton')}</Button>
                    <Button
                        variant="primary"
                        onClick={() => uiStore.setLanguageAndDeliveryCountry(language.toLowerCase(), deliveryCountryId)}
                    >{t('saveButton')}</Button>
                </Modal.Footer>
            </StyledModal>
        )
    }))

export default withTranslation()(LanguageSwitchModal);
