import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledDescriptionBlock, StyledTitle, StyledTable } from './ProductDetailStyles'

const LogisticBlock = ({ article, withTitle }) => {
    const { t } = useTranslation()
    const showLogistic = article.supplier || article.manufacturer || article.primaryStoreHouse
    if (!showLogistic) {
        return null
    }
    return (
        <StyledDescriptionBlock>
            {withTitle ? <StyledTitle className="h1">{t('productDetail:logistics')}</StyledTitle> : null}
            <StyledTable striped bordered  >
                <tbody>
                    {article.supplier && article.supplier.displayName ? <tr>
                        <td>{t('supplier')}:</td>
                        <td>{article.supplier.displayName}</td>
                    </tr> : null}
                    {article.supplier && article.supplier.number ? <tr>
                        <td>{t('order:externNumber')}:</td>
                        <td>{article.supplier.number}</td>
                    </tr> : null}
                    {article.manufacturer && article.manufacturer.displayName ? <tr>
                        <td>{t('productDetail:manufacturer')}:</td>
                        <td>{article.manufacturer.displayName}</td>
                    </tr> : null}
                    {article.primaryStoreHouse && article.primaryStoreHouse.displayName ? <tr>
                        <td>{t('productDetail:primaryStoreHouse')}:</td>
                        <td>{article.primaryStoreHouse.displayName}</td>
                    </tr> : null}
                </tbody>
            </StyledTable>
        </StyledDescriptionBlock >
    )
}

export default LogisticBlock
