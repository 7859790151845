import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, InputGroup } from 'react-bootstrap'
import useAppointmentTypesState from '../../../stores/Configuration/useAppointmentTypesState'

const TimeLength = () => {
    const { t } = useTranslation()
    const { selectedAppointmentType, setSelectedAppointmentType } = useAppointmentTypesState()
    const handleChange = e => {
        const { name, value } = e.target
        setSelectedAppointmentType(prev => ({ ...prev, [name]: value }))
    }

    return (
        <Form.Group className="mb-3">
            <Form.Label>{t('cabinTypes:appointmentDuration')}</Form.Label>
            <InputGroup className="mb-3">
                <Form.Control
                    name="durationInMinutes"
                    type="number"
                    placeholder="Minuten"
                    value={selectedAppointmentType.durationInMinutes || ""}
                    onChange={handleChange}
                />
                <InputGroup.Text id="basic-addon2">{t('minutes')}</InputGroup.Text>
            </InputGroup>
        </Form.Group>
    )
}

export default TimeLength