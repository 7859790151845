import React from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import { useEmployeesState } from '../../stores/EmployeesProvider'
import InformationPopover from '../InformationPopover/InformationPopover'

const StyledCatalogViewSwitch = styled.div`
  margin-bottom: 25px;
  font-weight: bold;
  .custom-control-label{font-size:20px;}
  .custom-control-label::before {top: .5rem;}
  .custom-control-label::after {top: calc(.5rem + 2px);}
`
const MissingArticleConfigurationsFilter = () => {
    const { setMissingArticleConfigurationsFilter, missingArticleConfigurationsFilter } = useEmployeesState()

    const handleChange = (e) => {
        setMissingArticleConfigurationsFilter(prev => !prev)
    }
    return (
        <div className={"d-flex"}>
            <StyledCatalogViewSwitch>
                <Form.Check
                    type="switch"
                    label="Mitarbeiter mit fehlenden Größen"
                    onChange={handleChange}
                    id="catalog-view-switch"
                    checked={missingArticleConfigurationsFilter || false}
                />
            </StyledCatalogViewSwitch>
            <InformationPopover header="Mitarbeiter mit fehlenden Größen Filter" content="Es werden nur Träger mit fehlenden Größen markiert, die zuvor in einem Massenbestellungs Export ausgewählt wurden" />
        </div>
    )
}

export default MissingArticleConfigurationsFilter
