import React, { useState } from 'react'
import { emptyGuid } from '../../utils/emptyGuid'
import useStores from '../../stores/useStore'
import { ArrowIconNew, StyledIconWrapper, StyledItemWrapper, StyledListItemText, StyledTreeSubList } from '../TreeList/treeList'
import { FolderOpenIcon } from '../Icons/FolderOpenIcon'
import { FolderIcon } from '../Icons/FolderIcon'
import { colors } from '../../styles/colors'
/* 
    For Costcenter and Office Tree
*/
const TopLevelTreeItem = ({ handleClick, renderExtraItemContent, children }) => {
    const [isTopLevelActive, setIsTopLevelActive] = useState(true)
    const { profileStore } = useStores()
    let topLevelName = profileStore.profile.mandator.displayName
    let topLevelItem = {
        id: emptyGuid,
        displayName: topLevelName,
        hasChildren: true
    }

    return (
        <>
            <StyledItemWrapper isClickable={true}>
                <div className='d-flex' onClick={() => { setIsTopLevelActive(!isTopLevelActive) }}>
                    <ArrowIconNew color={isTopLevelActive ? '#9c9c9c' : '#3a3a38'}
                        variant={isTopLevelActive ? 'down' : 'right'} />
                    <StyledIconWrapper>
                        {isTopLevelActive ? (
                            <FolderOpenIcon color={colors.gray7} />
                        ) : (
                            <FolderIcon color={colors.gray7} />
                        )}
                    </StyledIconWrapper>
                </div>
                <div className='d-flex flex-grow-1' onClick={() => handleClick(topLevelItem)}>
                    <StyledListItemText>{topLevelName}</StyledListItemText>
                    {renderExtraItemContent(topLevelItem)}
                </div>
            </StyledItemWrapper>
            {isTopLevelActive &&
                <StyledTreeSubList>
                    {children}
                </StyledTreeSubList>}
        </>
    )
}


export default TopLevelTreeItem