import i18n from "../translation/i18n"

//return value of selected language or if null return first value thats not null
/* {
  "values": [
      {
          "langIsoCode": "DE",
          "value": "German name"
      },
      {
          "langIsoCode": "FR",
          "value": null
      },
      {
          "langIsoCode": "EN",
          "value": English name
      },
      {
          "langIsoCode": "ES",
          "value": null
      }
  ]
} */
export const getLanguageValue = (values = []) => {
  const selectedLanguage = i18n.language.toUpperCase()
  return (values.find(item => item.langIsoCode === selectedLanguage && item.value) || values.find(item => item.value) || { value: "" }).value
}