import React, { useEffect, useMemo, useState } from 'react'
import ArticleSelectModal from '../../ArticleSelect/ArticleSelectModal'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'

const AddArticles = ({ bigData, entitlementId, groupId, setShowAddArticlesModal, getGroupArticles }) => {
  const [handleSaveIsLoading, setHandleSaveIsLoading] = useState(false)
  const [selectedArticles, setSelectedArticles] = useState([])
  let articlesLoaded = useMemo(() => bigData[entitlementId].groups[groupId].articlesLoaded, [bigData, entitlementId, groupId])
  let groupArticles = useMemo(() => Object.values(bigData[entitlementId].groups[groupId].articles), [bigData, entitlementId, groupId])


  useEffect(() => {
    //have to get Articles first, so that those are not selectable in the List
    if (!articlesLoaded) getGroupArticles(entitlementId, groupId)
  }, [articlesLoaded, entitlementId, getGroupArticles, groupId])

  async function saveArticle(articleId) {
    const params = {
      type: "create",
      article: {
        id: articleId
      },
      value: {}
    }
    await apiClient.postJson(APIEndpoints.contingentTemplate(entitlementId, groupId).groupArticles, params)
  }

  async function handleSave() {

    setHandleSaveIsLoading(true)
    for (const article of selectedArticles) {
      await saveArticle(article.article.id)
    }
    await getGroupArticles(entitlementId, groupId)
    setHandleSaveIsLoading(false)

    setSelectedArticles([])
    setShowAddArticlesModal(null)
  }
  return (
    <div> {articlesLoaded
      ? <ArticleSelectModal
        selectedArticles={selectedArticles}
        setSelectedArticles={setSelectedArticles}
        setShowAddArticlesModal={setShowAddArticlesModal}
        handleSave={handleSave}
        hideArticles={groupArticles.map(a => a.article.id)}
        handleSaveIsLoading={handleSaveIsLoading}
      />
      : null}</div>
  )
}

export default AddArticles