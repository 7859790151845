import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Form, Spinner, Alert } from 'react-bootstrap'
import MediaQuery from 'react-responsive'
import { withTranslation } from 'react-i18next'
import { observable, makeObservable } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled, { withTheme } from 'styled-components'
import { debounce } from 'throttle-debounce'

import { formatedPriceObject } from '../../utils/formatedPrice'
import { getErrorMessageFromApiResponse } from '../../utils/api/apiUtils'
import PlusMinusAmount from '../Forms/PlusMinusAmount'
import { OverlayLoadingLayer } from '../Loading/OverlayLoadingLayer'
import { colors } from '../../styles/colors'
import { CategoryIconDefault, CategoryIcons } from '../Icons/CategoryIcons/CategoryIcons'
import trash from '../../assets/icons/trash.svg'

const StyledProductItemWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 85px 1fr auto;
  border-bottom: 1px solid ${colors.gray1};

  @media screen and (min-width: 992px) {
    grid-template-columns: 85px 1fr 140px 115px 100px;
    align-items: center;
  }
`

const StyledProdcutHeader = styled.div`
  opacity: 0.5;
`

const StyledImgCol = styled.div`
  align-self: center;
  grid-row: span 2;

  @media screen and (min-width: 992px) {
    grid-row: auto;
  }
`

const StyledProductImgWrap = styled.div`
  width: 85px;
  height: 85px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`

const StyledProductImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const StyledProductDescriptionCol = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  grid-column: span 2;
    @media screen and (min-width: 430px) {
      grid-column: auto;
    }
`

const StyledProductSubDescription = styled.div`
  color: ${colors.gray3};
  font-weight: 600;
`
const StyledProductLocalStoreHouse = styled.div`
  color: ${colors.gray3};
  font-weight: 600;
`

const StyledPriceCol = styled.div`
  align-self: end;
  grid-row: 2;
  grid-column: 3;
  text-align: right;
  white-space: nowrap;

  @media screen and (min-width: 430px) {
    grid-row: 1;
  }
  @media screen and (min-width: 992px) {
    align-self: auto;
    grid-row: auto;
    grid-column: auto;
    padding: 20px;
  }
`

const StyledOptionsCol = styled.div`
  grid-column: 3;
  text-align: right;
  white-space: nowrap;
  
  @media screen and (min-width: 992px) {
    grid-column: auto;
    padding: 20px 0 20px 20px;
  }
`

const StyledRemoveLink = styled(Link)`
  color: #ff0000;
  font-size: 0.9em;

  &:hover{
    color: #bf0000;
  }

  &:after{
    content: '';
    width: 1em;
    height: 1.1em;
    display: inline-block;
    margin-left: 0.25em;
    top: -2px;
    position: relative;
    vertical-align: middle;
    background: url(${trash}) center center no-repeat;
  }
`


class ProductItem extends Component {

  changeQuantityError = null;
  quantity = null;
  isLoading = false;

  constructor(props) {
    super(props)

    makeObservable(this, {
      changeQuantityError: observable,
      quantity: observable,
      isLoading: observable
    });

    this.quantity = props.position.quantity
  }

  onClickRemove = async (e) => {
    e.preventDefault()
    const { article, position, productsStore } = this.props
    await productsStore.removeArticleFromBasket(article.id, position.id);
  }

  onClickPlusIcon = async (e) => {
    const { article } = this.props
    e.preventDefault()
    this.quantity += (article.article.packageSize ? article.article.packageSize : 1)
    await this.changeQuantityInBasketDebounced(this.quantity)
  }

  onClickMinusIcon = (e) => {
    const { article } = this.props
    e.preventDefault()
    this.quantity -= (article.article.packageSize ? article.article.packageSize : 1)
    this.changeQuantityInBasketDebounced(this.quantity)
  }
  onChangeQauantity = async (e) => {
    const { value } = e.target
    if (parseInt(value)) {
      this.quantity = parseInt(value)
      await this.changeQuantityInBasketDebounced(this.quantity)
    }

  }
  changeQuantityInBasketDebounced = debounce(750, (quantity) => {
    const { article, position, productsStore } = this.props
    this.isLoading = true
    this.changeQuantityError = null
    productsStore.changePositionQuantity(article.id, position, quantity).catch((e) => {
      this.quantity = position.quantity
      this.changeQuantityError = getErrorMessageFromApiResponse(e)
    }).finally(() => {
      this.isLoading = false
    })
  }
  )

  getProductHeadline = () => {
    const { article } = this.props
    let chunks = []
    if (article.contingentAvailability && article.contingentAvailability.displayName) {
      chunks.push(article.contingentAvailability.displayName)
    }
    if (article.categoryAvailability && article.categoryAvailability.displayName) {
      chunks.push(article.categoryAvailability.displayName)
    }
    return chunks.join(' / ')
  }

  getSubDescription = (position) => {
    const { articleVariant, configurations } = position
    let subDescriptionParts = []

    if (articleVariant) {
      /*if (articleVariant.groupDisplayName) {
        subDescriptionParts.push(articleVariant.groupDisplayName)
      }*/

      if (articleVariant.separatedDisplayName && articleVariant.separatedDisplayName.length) {
        subDescriptionParts.push(articleVariant.separatedDisplayName.join(' '))
      } else {
        if (articleVariant.displayName) {
          subDescriptionParts.push(articleVariant.displayName)
        }
      }
    }

    if (configurations.length) {
      configurations.forEach(config => {
        let configText = `${config.name}: ${config.article.displayName}`
        if (config.comment) {
          configText = configText + '; ' + config.comment
        }
        subDescriptionParts.push(configText)
      })
    }

    if (position.comment) {
      subDescriptionParts.push(position.comment)
    }

    return subDescriptionParts.length ? subDescriptionParts.join(' | ') : ''
  }
  getLocalStoreHouse = (position, t) => {
    const { localStorehouse } = position
    if (localStorehouse) {
      return t('translation:DirectOutput') + ': ' + localStorehouse.displayName
    }
    return ''
  }

  render() {
    const { t, productsStore, deletable, theme, article, position, productDetailsStore } = this.props
    const CategoryIcon = this.props.iconType && CategoryIcons[this.props.iconType] ? CategoryIcons[this.props.iconType] : CategoryIconDefault
    const isRemoving = productsStore.removingPositionFromBasket[position.id]
    const productHeadline = this.getProductHeadline()
    const subDescription = this.getSubDescription(position)
    const localStoreHouse = this.getLocalStoreHouse(position, t)

    let sumPriceObject, sumPrice, sumShortPrice

    if (position.pricePerItem !== null) {
      sumPriceObject = {
        price: position.pricePerItem.price === null ? null : position.pricePerItem.price * position.quantity,
        currency: position.pricePerItem.currency,
        points: position.pricePerItem.points === null ? null : position.pricePerItem.points * position.quantity
      }
      sumPrice = formatedPriceObject(sumPriceObject)
      sumShortPrice = formatedPriceObject(sumPriceObject, true)
    }

    return (
      <React.Fragment>
        <StyledProductItemWrapper
          className={this.isLoading ? 'is-loading' : ''}>
          <StyledImgCol>
            <Link to={productDetailsStore.getProductToUrl(article)}>
              <StyledProductImgWrap>
                {article.article.image && article.article.image.url
                  ? <StyledProductImg src={article.article.image.url} alt='' />
                  : <CategoryIcon color={theme.primaryColor} />
                }
              </StyledProductImgWrap>
            </Link>
          </StyledImgCol>
          <StyledProductDescriptionCol>
            <StyledProdcutHeader className='text-primary'><i>
              <small>{productHeadline}</small>
            </i></StyledProdcutHeader>
            <span>{article.article.displayName}</span>
            <StyledProductSubDescription>
              {subDescription}
            </StyledProductSubDescription>
            <StyledProductLocalStoreHouse>
              {localStoreHouse}
            </StyledProductLocalStoreHouse>
          </StyledProductDescriptionCol>
          <Form.Group >
            <Form.Label>{t('amount')}</Form.Label>
            <PlusMinusAmount
              placeholder="1"
              value={this.quantity}
              onChange={this.onChangeQauantity}
              onChangePlus={this.onClickPlusIcon}
              onChangeMinus={this.onClickMinusIcon}
              disableMinus={(this.quantity === 1) || this.quantity <= article.article.minimumQuantity} 
              disablePlus={article.article.maximumQuantity && this.quantity >= article.article.maximumQuantity} />
          </Form.Group>
          <StyledPriceCol>
            {!productsStore.hidePricesAndPoints && position.pricePerItem !== null ?
              <>
                <MediaQuery maxWidth={991}>{sumShortPrice}</MediaQuery>
                <MediaQuery minWidth={992}>{sumPrice}</MediaQuery>
              </> : ''}
          </StyledPriceCol>
          <StyledOptionsCol>
            {deletable
              ? <>
                {
                  isRemoving
                    ? <span className='d-flex justify-content-center' style={{ width: '72px' }}>
                      <Spinner animation='border' variant='primary' />
                    </span>
                    : <StyledRemoveLink to={'#'} onClick={this.onClickRemove}>{t('deleteButton')}</StyledRemoveLink>
                }
              </>
              : null}
          </StyledOptionsCol>
          <OverlayLoadingLayer isLoading={this.isLoading} />
        </StyledProductItemWrapper>
        {this.changeQuantityError ? <Alert variant='danger' className='mb-3'>{this.changeQuantityError}</Alert> : ''}
      </React.Fragment>
    )
  }
}

export default withTheme(withTranslation()(inject('productsStore', 'productDetailsStore')(observer(ProductItem))))
