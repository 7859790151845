import React, { useState, useEffect } from 'react'
import { useObserver } from 'mobx-react'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import { useTranslation } from 'react-i18next'
import useStores from '../../stores/useStore'
import { Alert, Spinner } from 'react-bootstrap'
import AppointmentsContentArea from '../../components/AppointmentsAdministration/AppointmentsContentArea'
import AppointmentsAdministrationProvider from '../../stores/Administration/AppointmentsAdministrationProvider'



const PageContent = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Subheader className="subHeader-v1">
                <div className="subheader-title">{t('appointment:appointmentAdministration')}</div>
            </Subheader>
            <AppointmentsContentArea />
        </div>
    )
}

const AppointmentsAdministrationPage = () => {
    const { t } = useTranslation()
    const { uiStore } = useStores()
    const [error, setError] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setError(true);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return useObserver(() => (
        <>
            {!uiStore.fittingAdministration
                ? <div>
                    <Subheader className="subHeader-v1">
                        <div className="subheader-title">{t('appointment:appointmentAdministration')}</div>
                    </Subheader>
                    {error
                        ? <Alert variant="danger">{t('accessDenied')}</Alert>
                        : <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
                    }
                </div>
                : <AppointmentsAdministrationProvider>
                    <PageContent />
                </AppointmentsAdministrationProvider>
            }
        </>
    ))
}


export default AppointmentsAdministrationPage
