import React from 'react'
import styled, { useTheme, css } from 'styled-components'
import { colors } from '../../styles/colors'
import ProductGroupCard from '../../components/ProductGroupCard/ProductGroupCard'

const StyledH2 = styled.h2`
  color: ${colors.gray4}
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
  margin: 30px 0 0 0;
  padding:1rem;
  background:white;
margin:1rem;
  border: 3px solid white;

  @media screen and (max-width: 992px) {
    margin-top: 15px;
  }
`
const StyledGroup = styled.div`
    z-index:0;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position:relative;
    align-items: flex-end;
    overflow:hidden;
    margin-bottom:1rem;

    .contigent-image {
      height: 200px;
      margin-left:15px;
    }
.contigent-image-blur-background{
    position:absolute;
    filter: opacity(.7) blur(100px);
    width: 100%;
    height:200px;
    z-index:-1;
}
    @media screen and (max-width: 765px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .contigent-image {
            height: auto;
            width:100%;
            margin-left:0px;
        }
        .contigent-image-blur-background{
            height:auto;
        }
    }

    ${props => props.isClickable && css`
        &:hover {
            cursor: pointer;
        }
    `}
`

export const ContingentRows = ({ contingent, children, withoutImg, onClickHandler }) => {

    return (
        <>
            <tr data-id={`contingent-${contingent.id}`} onClick={() => onClickHandler && onClickHandler()}>
                <td className={"p-0"} colSpan="5">
                    <StyledGroup isClickable={onClickHandler}>
                        <StyledH2>{contingent.displayName}</StyledH2>
                        {
                            !withoutImg && contingent.imageUrl && <>
                                <img className="contigent-image-blur-background" src={contingent.imageUrl} alt={contingent.displayName} />

                                <img className="contigent-image" src={contingent.imageUrl} alt={contingent.displayName} />
                            </>

                        }
                    </StyledGroup>
                </td>
            </tr>
            {children}
        </>
    )
}

export const CategoryRows = ({ category, ...props }) => {
    const theme = useTheme();
    return (
        <>
            <tr data-id={`category-${category.id}`}>
                <td className={"p-0"} colSpan="5">
                    <ProductGroupCard
                        hideMaxedOut={props.hideMaxedOut}
                        hideCounter={props.hideCounter}
                        categoryName={category.displayName}
                        current={category.availability.currentAmount}
                        amountToOrder={category.availability.amountToOrder}
                        max={category.availability.maxAmount}
                        image={category.imageUrl ? category.imageUrl : ''}
                        iconType={category.iconType}
                        color={theme.primaryColor}
                    />
                </td>
            </tr>
            {props.children}
        </>
    )
}
