import React, { useCallback, useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import styled from 'styled-components'


const StyledCanvasWrap = styled.div`
  position: relative;
  &::after {
    content: '✎';
    font-size: 1.5rem;;
    width: 80%;
    color: #ccc;
    border-bottom: 1px dashed #ccc;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  canvas {
    border: 1px solid #ccc;
    width: 100%;
    height: 170px;
    z-index: 2;
    position: relative;
  }
`



const DigitalSignature = ({ sigRef, digitalSignature, setDigitalSignature }) => {
  const [sigCanvasWidth, setSigCanvasWidth] = useState(320)
  const prevSigCanvasWidthRef = useRef()

  const handleEnd = useCallback(() => {
    if (sigRef.current) {
      setDigitalSignature(sigRef.current.toDataURL())
    }
  }, [setDigitalSignature, sigRef])

  const updateSigCanvasWidth = useCallback(() => {
    setSigCanvasWidth(sigRef.current.getCanvas().getBoundingClientRect().width)
  }, [sigRef])

  useEffect(() => {
    if (prevSigCanvasWidthRef.current !== sigCanvasWidth) {
      sigRef.current.clear()
      if (digitalSignature) {
        const ratio = Math.max(window.devicePixelRatio || 1, 1)
        const canvas = sigRef.current.getCanvas()
        canvas.width = canvas.offsetWidth * ratio
        canvas.height = canvas.offsetHeight * ratio
        canvas.getContext('2d').scale(ratio, ratio)
        sigRef.current.fromDataURL(digitalSignature)
      }
    }
  }, [digitalSignature, sigCanvasWidth, sigRef])

  useEffect(() => {
    prevSigCanvasWidthRef.current = sigCanvasWidth
  })

  useEffect(() => {
    window.addEventListener('resize', updateSigCanvasWidth)
    updateSigCanvasWidth()
    return () => {
      window.removeEventListener('resize', updateSigCanvasWidth)
    }
  }, [updateSigCanvasWidth])

  return (
    <StyledCanvasWrap>
      <SignatureCanvas ref={sigRef} penColor="blue" canvasProps={{ width: sigCanvasWidth, height: 170, className: 'sigCanvas' }} onEnd={handleEnd} />
    </StyledCanvasWrap>
  )
}

export default DigitalSignature
