import React, { useState, useEffect, useRef } from 'react'
import { Observer } from 'mobx-react'
import { CalendarIcon } from '../Icons/CalendarIcon'
import { Link } from 'react-router-dom'
import ListCard from './ListCard'
import useStores from '../../stores/useStore'
import { useTranslation } from 'react-i18next'
import apiClient from '../../utils/api/apiClient'
import { formatedDate } from '../../utils/formatedDate'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { Alert } from 'react-bootstrap'
import DeleteModal from '../Modals/DeleteModal'
import { PenIcon } from '../Icons/PenIcon'
import { useNavigate } from 'react-router-dom'
import { getIsoDate } from '../../utils/getIsoDate'


const AppointmentsCard = () => {
  const { profileStore } = useStores()
  const { profile } = profileStore
  const businesspartnerId = profile.id
  const { t } = useTranslation()
  const [appointments, setAppointments] = useState([])
  const navigate = useNavigate();
  const initialLimit = 5
  const [limit, setLimit] = useState(initialLimit)
  const [totalCount, setTotalCount] = useState(0)
  const mountedRef = useRef(true)

  useEffect(() => {
    businesspartnerId && apiClient.getJson(APIEndpoints.appointments, {
      'filter.startDate': getIsoDate(new Date()),
      'filter.targetBusinesspartnerId': businesspartnerId
    }).then(response => {
      if (!mountedRef.current) return null
      //setTotalCount(Number(response.headers['x-total-count']))
      setAppointments(response)
      setTotalCount(response.length)
    })
  }, [businesspartnerId])

  //Cleanup Function
  useEffect(() => {
    return () => { mountedRef.current = false }
  }, [])

  const deleteRow = (appointmentId) => {
    apiClient.deleteJson(APIEndpoints.appointment(appointmentId)).then(() => {
      apiClient.getJson(APIEndpoints.appointments, {
        'filter.startDate': getIsoDate(new Date()),
        'filter.targetBusinesspartnerId': businesspartnerId
      }).then(response => {
        if (!mountedRef.current) return null
        setAppointments(response)
      })
    })
  }

  const editRow = (appointmentId) => {
    //redirect to appointment page
    let path = `/appointment/${profile.id}/${appointmentId}`;
    navigate(path);
  }

  return (
    <ListCard>
      <div className="d-flex justify-content-between align-items-baseline">
        <p className="h2 list-card__title">
          <i><CalendarIcon /></i>
          <span>{t('appointment:appointments')}</span>
        </p>
        <Observer>{() => (<Link to={`/appointment/${profile.id}`}>{t('appointment:newAppointment')}</Link>)}</Observer>
      </div>

      {appointments.length === 0 && (
        <Alert variant="info">{t('appointment:noAppointments')}</Alert>
      )}
      <ul id="myList">
        {appointments.slice(0, limit).map(appointment => {
          const Day = formatedDate(appointment.day)
          const Hour = appointment.timeSlot && (appointment.timeSlot.hour < 10 ? "0" + appointment.timeSlot.hour : appointment.timeSlot.hour)
          const Minute = appointment.timeSlot && (appointment.timeSlot.minute < 10 ? "0" + appointment.timeSlot.minute : appointment.timeSlot.minute)
          const Name = appointment.appointmentType && appointment.appointmentType.displayName

          return (
            <li key={appointment.id} className="list-card__row" >
              <span className="date">
                {Day}
              </span>
              <span className="date">
                {Hour}:{Minute} Uhr
              </span>
              <span className="title">{Name}</span>
              <div className="action-buttons">
                <PenIcon className="action-button" title={t('edit')} onClick={() => { editRow(appointment.id) }} />
                <DeleteModal
                  deleteFunction={() => deleteRow(appointment.id)}
                  ModalTitle={t('appointment:deleteAppointment')}
                  BodyPart1={t('appointment:areYouSure')}
                  BodyPart2={`${Day}\u00A0${Hour}:${Minute}Uhr\u00A0${Name}`}
                />
              </div>
            </li>
          )
        })}
      </ul>
      <p>
        {limit > initialLimit &&
          <span className="show-less" onClick={() => { setLimit(initialLimit) }}>{t('dashboard:showLess')}</span>
        }
        {totalCount > limit && (
          <span className="show-more" onClick={() => { setLimit(limit + 5) }}>{t('dashboard:showMore')}</span>
        )}
      </p>
    </ListCard >
  )
}

export default AppointmentsCard
