import React from 'react'
import FileInput from '../../Forms/FileInput'
import { FileOutput } from '../../Forms/FileOutput'
import styled from 'styled-components'
import { useState } from 'react'
import { t } from 'i18next'
import { StyledCard, StyledList } from '../ArticleDetails/StyledDetailCard'
import { ButtonWithLoadingState } from '../../Buttons'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { getErrorMessageFromApiResponse } from '../../../utils/api/apiUtils'
import useStores from '../../../stores/useStore'
import { Alert } from 'react-bootstrap'




const StyledDataDiv = styled.div`
display:flex;
justify-content:space-between;
flex-wrap: wrap;
div {
  max-width:700px;
  min-width:150px;
  flex-grow:1;
}
`

const StyledFlexDiv = styled.div`
display:flex;
justify-content:space-between;
flex-wrap: wrap;
align-items: center;
`
const ArticleRow = ({ article, successRedirect }) => {
  const [files, setFiles] = useState([])
  const { inventoryStore } = useStores()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const submitImage = async () => {
    setError(false)
    setLoading(true)
    try {
      await apiClient.postFile(APIEndpoints.inventoryChangeRequest(article.id).pictureUpload, {}, files, true)
      //maybe add some coole remove animation
      inventoryStore.removeFromMissingImagesList(article.id)
      if (!inventoryStore.missingImagesList.length) {
        successRedirect()
      }
    }
    catch (e) {
      setError(getErrorMessageFromApiResponse(e))
    }
    finally {
      setLoading(false)
    }
  }
  const dataArray = [

    {
      label: t('size'),
      value: article.articleVariant.displayName
    },
    {
      label: t('orderDialog:reason'),
      value: article.changeReason.displayName
    },
    {
      label: "RFID",
      value: article.rfid,
      hide: !article.rfid
    }
  ]


  return (
    <StyledCard >
      <StyledDataDiv>
        <div >
          <h1> {article.article.displayName} / {article.article.articleNumber}</h1>
          <StyledList>
            {dataArray.map((listItem, idx) =>
              listItem.hide
                ? null
                : <li key={`${idx} ${listItem.label}`}>
                  <div className="label">{listItem.label}</div>
                  <div className="value" >{listItem.value}</div>
                </li>
            )}
          </StyledList>
        </div>
        <div >
          {files.length ?
            null
            : <FileInput
              style={{ height: "100%" }}
              maxFiles={1}
              accept={{
                'image/jpeg': [],
                'image/png': []
              }}
              onDrop={(acceptedFiles) => {
                let newFiles = acceptedFiles.map(file => Object.assign(file, {
                  preview: URL.createObjectURL(file)
                }));
                setFiles(newFiles)
              }}
            />}
        </div>

      </StyledDataDiv>

      {files.length ? <><hr /><StyledFlexDiv> < FileOutput
        className="mb-2"
        file={files[0]}
        deleteFile={() => setFiles([])}
      />
        <ButtonWithLoadingState
          buttonText={t('submitButton')}
          isLoading={loading}
          onClick={() => submitImage()}
        /></StyledFlexDiv></> : null}
      {error ? <Alert variant="danger">{error}</Alert> : null}
    </StyledCard>

  )
}

export default ArticleRow