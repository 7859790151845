import React, { useEffect, useState } from 'react'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PenIcon } from '../../Icons/PenIcon'
import { Spinner } from 'react-bootstrap'
import { formatedDate } from '../../../utils/formatedDate'

const EmployeeHealthData = ({ selectedRow }) => {
    const [lastCheckUp, setLastCheckUp] = useState(null)
    const [currentQuarantineStatus, setCurrentQuarantineStatus] = useState(null)

    const { t } = useTranslation()

    useEffect(() => {
        if (selectedRow) {
            setLastCheckUp(null);
            (async () => {
                const response = await apiClient.getJson(APIEndpoints.businessPartner(selectedRow.id).checkUps)
                let lastCheckup = response.find(checkUp => checkUp.checkUpResult !== null)
                setLastCheckUp(lastCheckup)
            })()
            setCurrentQuarantineStatus(null);
            (async () => {
                const response = await apiClient.getJson(APIEndpoints.businessPartner(selectedRow.id).currentQuarantineStatus)
                setCurrentQuarantineStatus(response)
            })()

        }
    }, [selectedRow])

    const renderEmployeeData = () => (
        <ul className="data-list__data m-0">
            <li>
                <div className="data-list__label"> {t('EmployeeFormHealthData:quarantine')}:</div>
                <div className="data-list__value">
                    {currentQuarantineStatus.isInQuarantine
                        ? `${t('yes')} (${formatedDate(currentQuarantineStatus.startDate)} - ${formatedDate(currentQuarantineStatus.endDate)})`
                        : t('no')}</div>
            </li>
            <li >
                <div className="data-list__label">{t('EmployeeFormHealthData:healthCheck')}:</div>
                {lastCheckUp ? <div className="data-list__value">
                    <div>{formatedDate(lastCheckUp.dateOfCheckUp)}</div>
                    <div>{lastCheckUp.checkUpType && lastCheckUp.checkUpType.displayName} ({lastCheckUp && lastCheckUp.checkUpResult ? lastCheckUp.checkUpResult.displayName : "no result"})</div>
                </div> : <div className="data-list__value">-</div>}
            </li>
        </ul>
    )

    return (
        <>
            <span className="data-list__name h2">{t('EmployeeFormHealthData:healthData')}
                <Link to={`/editcheckups/${selectedRow.id}`}><PenIcon className="edit-button" /></Link>
            </span>
            {(lastCheckUp === null || currentQuarantineStatus === null)
                ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border'
                    variant='primary' /></div>
                : renderEmployeeData()}
        </>
    )
};
export default EmployeeHealthData;
