import React, { useEffect, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import { CategoryIconDefault } from '../Icons/CategoryIcons/CategoryIcons'
import { Form } from 'react-bootstrap'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu'
import { StyledContextMenu } from '../ContextMenu/StyledContextMenu'
import { BsDiagram3Fill } from 'react-icons/bs'
import { t } from 'i18next'

const StyleListItem = styled.div`
  cursor: pointer;
  padding: 10px 25px;
  border-bottom: 1px solid #ddd;
    &:hover {
      background-color: #ECECEC ;
    }
  ${props => props.selected && `background-color: #ECECEC ;`}
`
const StyledProductImgWrap = styled.div`
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
  background:white;
  width: 50px;
  height:  50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px 0 15px;
  svg {
    width: 100%;
    height: 100%;
    opacity: 0.3;
  }
`
const StyledProductImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const StyledFlexWrapper = styled.div`
  display:flex;
  justify-content:space-between;
  align-items: center;
  width: 100%;
  .leftSide{
    display:flex;
  }
  .rightSide{
    display: flex;
    justify-content: flex-end;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`
const ArticleDND = ({ onClickSelectedArticles, selectedArticles, article, index, getArticleAssignments }) => {

  const theme = useTheme()
  let { id, displayName, image } = article.article
  const isSelected = useMemo(() => selectedArticles.map(selectedArticle => selectedArticle.article.id).includes(id), [id, selectedArticles])
  const [, drag, preview] = useDrag({
    type: "treeItem",
    item: { type: "multipleArticles", selectedArticles },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: () => (isSelected)
  });
  //hide dragged
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  return (
    <StyledContextMenu>
      <ContextMenuTrigger id={id} holdToDisplay={-1}>
        <StyleListItem onClick={() => onClickSelectedArticles(article, index)}
          selected={isSelected}
          ref={drag}
        >
          <StyledFlexWrapper>
            <div className="leftSide">
              <StyledProductImgWrap>
                {
                  image
                    ? <StyledProductImg src={image.url} alt={displayName} />
                    : <CategoryIconDefault color={theme.primaryColor} />
                }
              </StyledProductImgWrap>
              <div>
                <h3>{displayName}</h3>
                <p>{article.article.articleNumber}</p>
              </div>
            </div>
            <div className="rightSide">
              <Form.Check
                type="checkbox"
                id={`article-${id}`}
                name="activityLevel.includeEndValue"
                checked={isSelected}
                onChange={() => onClickSelectedArticles(article, index)}
              />
            </div>
          </StyledFlexWrapper>
        </StyleListItem>
      </ContextMenuTrigger>
      <ContextMenu id={id}>
        <MenuItem onClick={() => getArticleAssignments(id)}>
          <BsDiagram3Fill /> {t('contingentTemplates:articleAssignments')}
        </MenuItem>
      </ContextMenu>
    </StyledContextMenu>
  )
}

export default ArticleDND