import { useDrag, useDrop } from 'react-dnd'


export const useDNDList = (index, moveCardHandler, accept) => {
  const [{ itemPosition, isOver }, drop] = useDrop({
    accept: accept,
    drop(item) {
      if (!drop) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Time to actually perform the action
      moveCardHandler(dragIndex, hoverIndex)
    },
    canDrop: (item) => ((item.index < index) ? "below" : (item.index > index) ? "above" : ""),
    collect: monitor => ({
      itemPosition: monitor.canDrop(),
      isOver: !!monitor.isOver()
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: accept,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  return { itemPosition, isOver, isDragging, drag, drop };
}