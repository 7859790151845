import React, { useCallback, useState } from "react"
import apiClient from "../../utils/api/apiClient"
import { APIEndpoints } from "../../utils/api/apiConfig"
import { cloneDeep } from 'lodash';
import { makeCall, setStateByKey } from "../../utils/api/makeCall";
import { useCancelToken } from "../../utils/hooks/useCancelToken";

const activityTemplate = {
  name: {
    values: [],
  }
}

const ActivitiesContext = React.createContext({})
export const useActivitiesState = () => {
  return React.useContext(ActivitiesContext)
}

const ActivitiesProvider = ({ children }) => {

  const [errorStates, setErrorStates] = useState({})
  const [loadingStates, setLoadingStates] = useState({})
  const [successStates, setSuccessStates] = useState({})
  const [activities, setActivities] = useState([])
  const [activity, setActivity] = useState(cloneDeep(activityTemplate))
  const [redirectId, setRedirectId] = useState('')
  const { createOrCancelToken, sourceRef, isCancel } = useCancelToken();

  const getActivities = useCallback(async (force) => {
    if ((activities.length || loadingStates.activities) && !force) {
      return null
    }
    else {
      await makeCall('activities', async () => {
        let loadedActivities = await apiClient.getJson(APIEndpoints.allActivities)
        setActivities(loadedActivities)
      }, setErrorStates, setLoadingStates)
    }
  }, [activities, loadingStates.activities])

  const getActivity = useCallback(async (activityId) => {
    await makeCall('actvity', async () => {
      let loadedActivity = await apiClient.getJson(APIEndpoints.administration(activityId).activities)
      setActivity(loadedActivity)
    }, setErrorStates, setLoadingStates)
  }, [])

  async function putActivitiesOrderSequenceBackground(newSequence) {
    createOrCancelToken();
    const activitiyIds = newSequence.map(a => a.id)
    makeCall('saveOrderSequenceBackground', async () => {
      await apiClient.putJson(APIEndpoints.administration().activitiesOrderSequences, activitiyIds, true, false, { cancelToken: sourceRef.current.token })
    }, setErrorStates, setLoadingStates, isCancel)
  }
  async function putActivitiesOrderSequence() {
    createOrCancelToken();
    let sortedSequence = activities.sort((a, b) => (a.displayName.localeCompare(b.displayName)))
    const activitiyIds = sortedSequence.map(a => a.id)
    makeCall('saveOrderSequence', async () => {
      await apiClient.putJson(APIEndpoints.administration().activitiesOrderSequences, activitiyIds, true, false, { cancelToken: sourceRef.current.token })
      getActivities(true)
    }, setErrorStates, setLoadingStates, isCancel)
  }

  function postActivity() {
    makeCall('saveActivity', async () => {
      let newActivity = await apiClient.postJson(APIEndpoints.administration().postActivity, activity)
      setRedirectId(newActivity.id)
      setStateByKey(setSuccessStates, 'saveActivity', true)
      getActivities(true)
    }, setErrorStates, setLoadingStates)
  }

  function patchActivity(activityId) {
    makeCall('saveActivity', async () => {
      await apiClient.patchJson(APIEndpoints.administration(activityId).activities, activity)
      await getActivity(activityId)
      getActivities(true)
      setStateByKey(setSuccessStates, 'saveActivity', true)
    }, setErrorStates, setLoadingStates)
  }

  function deleteActivity(activityId) {
    makeCall('deleteActivity', async () => {
      await apiClient.deleteJson(APIEndpoints.administration(activityId).activities)
      await getActivities(true)
      setStateByKey(setSuccessStates, 'deleteActivity', true)
    }, setErrorStates, setLoadingStates)
  }

  const resetActivityPage = useCallback(() => {
    setActivity(cloneDeep(activityTemplate))
    setErrorStates({})
    setLoadingStates({})
    setSuccessStates({})
    setRedirectId('')
  }, [])

  const contextValues = {
    errorStates,
    loadingStates,
    getActivities,
    setActivities,
    activities, activity, setActivity, successStates,
    redirectId,
    postActivity, patchActivity,
    deleteActivity,
    getActivity, setSuccessStates,
    resetActivityPage,
    putActivitiesOrderSequenceBackground,
    putActivitiesOrderSequence
  }

  return (
    <ActivitiesContext.Provider value={contextValues}>
      {children}
    </ActivitiesContext.Provider>
  )
}

export default ActivitiesProvider
