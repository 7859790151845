import React from 'react'
import { FolderOpenIconArrow } from '../Icons/FolderOpenIconArrow'
import { FolderIconArrow } from '../Icons/FolderIconArrow'
import { useTheme } from 'styled-components'
import { FileIcon } from '../Icons/FileIcon'
import {
    StyledDot,
    StyledArrowWrapper,
    StyledItemWrapper,
    ArrowIconNew,
    StyledIconWrapper,
    StyledListItemText,
    StyledTreeSubList,
} from '../TreeList/treeList'
import { FolderOpenIcon } from '../Icons/FolderOpenIcon'
import { FolderIcon } from '../Icons/FolderIcon'
import { colors } from '../../styles/colors'
import PortalContextMenu from '../ContextMenu/PortalContextMenu'
import { StyledContextMenu } from '../ContextMenu/StyledContextMenu'
import { ContextMenuTrigger } from 'react-contextmenu'

const CostCenterItem = ({
    renderExtraItemContent = () => null,
    handleClickCostcenter,
    costCenter,
    setCostCentersActive,
    costCentersActive,
    partialCostCenters,
    isClickable = costCenter => costCenter.isAllowToOrder,
    selectedCostCenterId,
    filterMainOfficeId,
    parentsWithSelectedChildren = [],
    getContextMenuOptions = () => []
}) => {
    const theme = useTheme()

    const handleClickFolder = id => () => {
        setCostCentersActive({ ...costCentersActive, [id]: !costCentersActive[id] })
    }
    const getChildCostCenter = (parentCostCenter) => {
        const costCenters = partialCostCenters.filter(costCenter => costCenter.parentCostCenter && costCenter.parentCostCenter.id === parentCostCenter.id)
        return costCenters
    }

    return (
        <StyledContextMenu>
            <ContextMenuTrigger id={`${costCenter.id}_context`} holdToDisplay={-1}>
                <StyledItemWrapper
                    isClickable={isClickable(costCenter)}
                    isInvalid={!isClickable(costCenter)}
                    style={costCenter.id === selectedCostCenterId ? { boxShadow: `inset 0px 0px 0px 2px  ${theme.primaryColor}` } : {}}
                >
                    {
                        costCenter.hasChildren && !filterMainOfficeId &&
                        <StyledArrowWrapper onClick={handleClickFolder(costCenter.id)}>
                            <ArrowIconNew color={costCentersActive[costCenter.id] ? '#9c9c9c' : '#3a3a38'}
                                variant={costCentersActive[costCenter.id] ? 'down' : 'right'} />
                            <StyledIconWrapper>
                                {costCentersActive[costCenter.id] ? (
                                    parentsWithSelectedChildren.includes(costCenter.id) ?
                                        <FolderOpenIconArrow color={colors.gray7} />
                                        : <FolderOpenIcon color={colors.gray7} />
                                ) : (
                                    parentsWithSelectedChildren.includes(costCenter.id) ?
                                        <FolderIconArrow color={colors.gray7} />
                                        : <FolderIcon color={colors.gray7} />
                                )}
                            </StyledIconWrapper>
                        </StyledArrowWrapper>
                    }
                    <div className='d-flex flex-grow-1' onClick={e => { return isClickable(costCenter) ? handleClickCostcenter(costCenter) : null }}>
                        {
                            !costCenter.hasChildren && !filterMainOfficeId &&
                            < >
                                <StyledDot>•</StyledDot>
                                <StyledIconWrapper>
                                    <FileIcon uniqueId={`costcenter-icon-${costCenter.id}`}
                                        color={colors.gray5} />
                                </StyledIconWrapper>
                            </ >
                        }
                        <StyledListItemText>{costCenter.displayName}</StyledListItemText>
                        {renderExtraItemContent(costCenter)}
                    </div>
                </StyledItemWrapper>
                {costCenter.hasChildren && costCentersActive[costCenter.id] &&
                    <StyledTreeSubList>
                        {getChildCostCenter(costCenter).map(childCostCenter => (
                            <CostCenterItem
                                key={childCostCenter.id}
                                costCenter={childCostCenter}
                                renderExtraItemContent={renderExtraItemContent}
                                handleClickCostcenter={handleClickCostcenter}
                                setCostCentersActive={setCostCentersActive}
                                costCentersActive={costCentersActive}
                                partialCostCenters={partialCostCenters}
                                isClickable={isClickable}
                                selectedCostCenterId={selectedCostCenterId}
                                filterMainOfficeId={filterMainOfficeId}
                                parentsWithSelectedChildren={parentsWithSelectedChildren}
                                getContextMenuOptions={getContextMenuOptions}

                            />
                        ))}
                    </StyledTreeSubList>}
            </ContextMenuTrigger>
            {getContextMenuOptions(costCenter).length ? <PortalContextMenu contextId={`${costCenter.id}_context`} options={getContextMenuOptions(costCenter)} /> : null}
        </StyledContextMenu>
    )
}

export default CostCenterItem