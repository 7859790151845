import React from 'react'

export const DownloadIcon = ({ color, title, ...rest }) => {
  const useColor = color ? color : '#3C3C3B'
  return (
    <svg width="28px" height="28px" viewBox="0 0 28 28" {...rest}>
      {title && <title>{title}</title>}
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={useColor}>
          <g transform="translate(7.000000, 7.000000)">
            <path
              d="M13.0909091,12.1818182 C13.5929861,12.1818182 14,12.588832 14,13.0909091 C14,13.5929861 13.5929861,14 13.0909091,14 L0.909090909,14 C0.407013864,14 9.00952733e-13,13.5929861 9.00891246e-13,13.0909091 C9.0082976e-13,12.588832 0.407013864,12.1818182 0.909090909,12.1818182 L13.0909091,12.1818182 Z M9.90416306,-1.86517468e-14 L9.904,6.162 L11.9142136,6.16255565 L7.20710678,10.8696624 L2.5,6.16255565 L4.51,6.162 L4.51005051,-1.86517468e-14 L9.90416306,-1.86517468e-14 Z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
