import React, { useState, useEffect } from 'react'
import { Alert, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import CostCenterSelect from '../../Employees/Form/FormComponents/CostCenterSelect'
import { StyledRequiredFormLabel } from '../shared/FormStyles'
import ArraySelect from '../../Select/ArraySelect'
import { useMemo } from 'react'
import styled from 'styled-components'
import { AiFillInfoCircle } from 'react-icons/ai'
const StyledAlert = styled(Alert)`
    display: flex;
    align-items: center;
`
/**
 * Form to create an reclamation issue
 * @param onSubmit
 * @param onChange
 * @param getPreSelectedCostCenter
 * @param defaultEmail
 * @returns {*}
 * @constructor
 */
const ReclamationForm = (
  {
    onSubmit,
    onChange,
    getPreSelectedCostCenter,
    defaultEmail,
    returnReasons
  }
) => {
  const { t } = useTranslation()
  const preSelectedCostCenter = getPreSelectedCostCenter()
  const [formData, setFormData] = useState({
    email: defaultEmail || '',
    costCenterId: preSelectedCostCenter ? preSelectedCostCenter.id : '',
    comment: '',
    reason: null
  })

  const [validated, setValidated] = useState(false)

  /**
   * Handle change events for every input and add the new value to formData state
   * @param event
   */
  function handleInputChange(event) {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  function handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()

    if (validated) {
      onSubmit(event)
    }
  }

  /**
   * Check html5 form validity
   * @param event
   */
  function validateForm(event) {
    const form = event.currentTarget
    setValidated(form.checkValidity())
  }

  useEffect(() => {
    onChange(formData, validated)
  }, [formData, validated, onChange])
  const selectedReason = useMemo(() => returnReasons.find(r => r.id === formData.reason), [formData.reason, returnReasons])
  return (
    <Form
      validated={validated}
      onChange={validateForm}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        <StyledRequiredFormLabel>{t('orderDialog:reason')}</StyledRequiredFormLabel>
        <ArraySelect
          value={formData.reason}
          onChange={(e) => handleInputChange(e)}
          name='reason'
          required
          options={returnReasons}
          returnEvent
        />
      </Form.Group>
      {selectedReason?.description ?
        <StyledAlert variant="info"  >
          <AiFillInfoCircle size={50} className="me-3" style={{ opacity: "0.2" }} /><div>{selectedReason.description}</div>
        </StyledAlert>
        : null}
      <Form.Group className="mb-3">
        <Form.Label>{t('comment')}</Form.Label>
        <Form.Control
          as='textarea'
          name='comment'
          value={formData.comment}
          rows='3'
          onChange={(e) => handleInputChange(e)}
        />
      </Form.Group>
      {preSelectedCostCenter
        ? <Form.Group className="mb-3">
          <Form.Label>{t('costcenter')} *</Form.Label>
          <Form.Control
            as='input'
            value={preSelectedCostCenter.displayName}
            onChange={() => console.log("cant be changed")}
            disabled
            className={validated ? 'is-valid' : ''}
            name='costcenter'
          />
        </Form.Group>
        : <Form.Group className="mb-3">
          <CostCenterSelect id="reclamationFormCostCenter"
            label={`${t('costcenter')} *`}
            value={formData.costCenterId}
            onChange={(costCenter) => { setFormData(prev => ({ ...prev, costCenterId: costCenter.id })) }}
            overLayRootZIndex={1056}
          />
        </Form.Group>}
      <Form.Group className="mb-3">
        <Form.Label>{t('email')} *</Form.Label>
        <Form.Control
          type='email'
          value={formData.email}
          onChange={(e) => handleInputChange(e)}
          name='email'
          required
        />
      </Form.Group>
    </Form>
  )
}

export default ReclamationForm
