import React, { useRef } from 'react'
import DatePicker from 'react-datepicker'
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import es from "date-fns/locale/es";
import { CalendarIcon } from '../Icons/CalendarIcon'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import 'react-datepicker/dist/react-datepicker.css'

registerLocale("de", de);
registerLocale("es", es);

export const StyledDatepickerWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  input {
    padding-right: 30px;
  }
  .react-datepicker-wrapper{
   width: 100%;
  }
`

const StyledCalendarIconWrap = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;

  svg {
    width: 100%;
  }
`

const CustomDatePicker = (props) => {
  const ref = useRef()
  const { t } = useTranslation()

  const handleClickIcon = () => {
    ref.current.setOpen(true)
  }
  const className = `form-control ${props.isInvalid && 'is-invalid'} ${props.isValid && 'is-valid'}`
  return (
    <>
      <StyledDatepickerWrapper className={props.className}>
        <DatePicker
          ref={ref}
          {...props}
          className={className}
          locale={t('datePicker:languageTag')}

        />
        <StyledCalendarIconWrap onClick={handleClickIcon}><CalendarIcon /></StyledCalendarIconWrap>
      </StyledDatepickerWrapper>

    </>
  )
}

export default CustomDatePicker
