import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Form } from 'react-bootstrap'
import { colors } from '../../styles/colors'
import { Arrow2BigIcon } from '../Icons/Arrow2BigIcon'

const StyledCategoryList = styled.ul`
  list-style: none;
  padding: 0 10px 0 5px;
  margin: 0;
`

const StyledCategoryListItem = styled.li`
  margin: 10px 0;
`

const StyledCategorySubList = styled.ul`
  list-style: none;
  padding: 0 0 0 20px;
  margin: 15px 0;

`

const StyledCategorySubListItem = styled.li`
  margin: 10px 0;
  position: relative;
  cursor: pointer;
`

const StyledItemWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 5px;
  }
`

const StyledListItemText = styled.span`
  display: inline-block;
  margin-left: 10px;
`

const CatalogCategoryFilter = inject('teamOrderStore')(observer((props) => {
  const { teamOrderStore } = props

  const handleClickFolder = id => e => {
    teamOrderStore.categoriesOpened[id] = !teamOrderStore.categoriesOpened[id]
  }

  const handleChangeCategory = (category) => e => {
    const childIds = getAllChildIds(category)

    if (e.target.checked) {
      for (const id of childIds) {
        teamOrderStore.selectedCategories[id] = true
      }
    } else {
      const parentIds = getAllParentIds(category);
      for (const id of childIds) {
        delete teamOrderStore.selectedCategories[id]
      }
      for (const id of parentIds) {
        delete teamOrderStore.selectedCategories[id]
      }
    }
    teamOrderStore.loadOrderableArticlesDebounced()
  }
  const getAllParentIds = (category) => {
    const ids = [];
    if (category.parentCategory) {
      ids.push(category.parentCategory.id);
      const parents = getParentCategory(category);
      for (const parent of parents) {
        ids.push(...getAllParentIds(parent));
      }
    }
    return ids;
  }
  const getParentCategory = (childCategory) => {
    return teamOrderStore.articleCatalogCategories.filter(category => childCategory.parentCategory && childCategory.parentCategory.id === category.id);
  }

  const getAllChildIds = (category) => {
    const ids = [category.id]
    if (category.hasChildren) {
      const children = getChildCategory(category)
      for (const child of children) {
        ids.push(...getAllChildIds(child))
      }
    }
    return ids
  }

  const getChildCategory = (parentCategory) => {
    return teamOrderStore.articleCatalogCategories.filter(category => category.parentCategory && category.parentCategory.id === parentCategory.id)
  }

  const renderCategoryItem = (category) => {
    return (
      <React.Fragment>
        <StyledItemWrapper>
          <div className='d-flex'>
            {category.hasChildren &&
              <div onClick={handleClickFolder(category.id)}>
                {teamOrderStore.categoriesOpened[category.id] ?
                  <Arrow2BigIcon variant="up" color={colors.gray4} /> : <Arrow2BigIcon variant="down" color={colors.gray4} />}
              </div>}
            {!category.hasChildren &&
              <div style={{ width: "30px" }}></div>
            }

            <div className='d-flex'>
              <Form.Check >
                <Form.Check.Input
                  type='checkbox'
                  id={`filter-category-${category.id}`}
                  onChange={handleChangeCategory(category)}
                  checked={!!teamOrderStore.selectedCategories[category.id]}
                />
                <Form.Check.Label htmlFor={`filter-category-${category.id}`}>
                  <StyledListItemText>{category.displayName}</StyledListItemText>
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
        </StyledItemWrapper>
        {category.hasChildren && teamOrderStore.categoriesOpened[category.id] &&
          <StyledCategorySubList>
            {getChildCategory(category).map(childCategory => (
              <StyledCategorySubListItem key={childCategory.id}>
                {renderCategoryItem(childCategory)}
              </StyledCategorySubListItem>
            ))}
          </StyledCategorySubList>}
      </React.Fragment>
    )
  }


  const topLevelCategories = teamOrderStore.articleCatalogCategories.filter(category => !category.parentCategory)

  return (
    <React.Fragment>
      <StyledCategoryList>
        {topLevelCategories.map(category => (
          <StyledCategoryListItem key={category.id}>
            {renderCategoryItem(category)}
          </StyledCategoryListItem>
        ))}
      </StyledCategoryList>
    </React.Fragment>
  )
}))

export default CatalogCategoryFilter
