import React, { useEffect, useState } from 'react'
import SubheaderInventoryAction from '../components/Inventory/SubheaderInventoryAction'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import { FlexWrapper } from '../components/Page/logix2020/FlexWrapper'
import BigFooter from '../components/Footer/BigFooter'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import SelectedAmounts from '../components/Inventory/InventoryAction/SelectedAmounts'
import { Alert, Button } from 'react-bootstrap'
import ChooseArticleSelectModal from '../components/Inventory/InventoryAction/ChooseArticleSelectModal'
import { MdLibraryAdd } from 'react-icons/md'
import TreeSelectModal from '../components/Inventory/InventoryAction/TreeSelectModal'
import ScanModal from '../components/Inventory/ScanModal'
import { t } from 'i18next'
import { ButtonWithLoadingState } from '../components/Buttons'
import ReturnContactDataModal from '../components/Inventory/ReturnContactDataModal'
import DeactivateModal from '../components/Employees/Form/FormParts/DeactivateModal'

const InventoryActionPage = inject('inventoryStore', 'uiStore')(observer(props => {
    const { inventoryStore, uiStore } = props
    const { employeeId, actionType, targetId } = useParams()
    const [showAddArticlesModal, setShowAddArticlesModal] = useState(true)
    const [showScanModal, setShowScanModal] = useState(false)
    const [showTreeModal, setShowTreeModal] = useState(false)
    const navigate = useNavigate();
    const currentLocation = useLocation()
    useEffect(() => {
        const initData = async () => {
            const oldEmployeeId = inventoryStore.employeeId;
            const oldActionType = inventoryStore.actionType;

            inventoryStore.setActionType(actionType)
            inventoryStore.setTargetEmployeeId(targetId)
            await inventoryStore.setEmployeeId(employeeId)
            inventoryStore.loadAllInstances(true)
            inventoryStore.loadReturnReasons()
            uiStore.loadBPDataSettings()
            if (oldEmployeeId !== inventoryStore.employeeId || (oldActionType !== inventoryStore.actionType)) {
                await inventoryStore.loadEmployeeContingent(true)
            }
        }
        initData()
        return () => {
            inventoryStore.resetActionPage()
        }
    }, [actionType, employeeId, inventoryStore, targetId, uiStore])

    const contingentAmountsExist = inventoryStore.contingents && inventoryStore.contingents.some(contingent => contingent.categories.some(category => category.articles.some(article => article.positions.some(position => position.rows && position.rows.length))))
    const catalogAmountsExist = inventoryStore.editCatalogArticles?.length && inventoryStore.editCatalogArticles.some(article => article.positions.some(position => position.rows && position.rows.length))

    return (
        <>
            <SubheaderInventoryAction />
            <FlexWrapper>
                <ContentArea>
                    <div className='container-fluid'>
                        <div className="d-flex justify-content-between mb-4">
                            <Button onClick={() => setShowAddArticlesModal(true)}><MdLibraryAdd className="me-3" />{t('inventory:addArticle')}</Button>
                            <ButtonWithLoadingState
                                disabled={!(contingentAmountsExist || catalogAmountsExist) || inventoryStore.loadingSave || inventoryStore.submitSuccess}
                                buttonText={t('submitButton')}
                                isLoading={inventoryStore.loadingSave}
                                onClick={() => inventoryStore.onSubmit(navigate, currentLocation)}
                            />
                        </div>
                        {inventoryStore.submitSuccess ? <Alert variant="success" >{t('registrationBox:savingSuccess')}</Alert> : null}
                        {inventoryStore.submitError ? <Alert variant="danger">{inventoryStore.submitError}</Alert> : null}
                        <SelectedAmounts />
                    </div>
                </ContentArea>
            </FlexWrapper>
            {showAddArticlesModal && <ChooseArticleSelectModal setShowScanModal={setShowScanModal} setShowTreeModal={setShowTreeModal} handleClose={() => setShowAddArticlesModal(false)} />}
            {showScanModal && <ScanModal
                handleClose={() => setShowScanModal(false)}
                actionType={actionType}
            />
            }
            {showTreeModal && <TreeSelectModal handleClose={() => setShowTreeModal(false)} actionType={actionType} />}
            {inventoryStore.retourResponse && inventoryStore.retourResponse.id && <ReturnContactDataModal
                retourResponse={inventoryStore.retourResponse}
                setReturnResponse={(val) => inventoryStore.setReturnResponse(val)}
                setOpenDeactivateModal={(val) => inventoryStore.setOpenDeactivateModal(val)}
            />}
            {inventoryStore.openDeactivateModal ?
                <DeactivateModal
                    handleClose={() => { inventoryStore.setOpenDeactivateModal(false); navigate(currentLocation.state.from) }}
                    employeeId={employeeId}
                />

                : null}
            <BigFooter />
        </>
    )
}))

export default InventoryActionPage
