import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import useStores from '../../../stores/useStore'
import { NewCheckIcon } from '../../Icons/NewCheckIcon'
import { useTranslation } from 'react-i18next'
import ArraySelect from '../../Select/ArraySelect'

const StyledWrapper = styled.div`
  display: inline-block;

  select {
    min-width: 200px !important;
  }

  @media screen and (min-width: 480px) {
    padding: 0 25px;
  }
`

const ShoppingActivitySelector = ({ onChange }) => {
  const { productsStore } = useStores()

  const handleChange = e => {
    productsStore.handleChangeActivity(e.target.value)
    if (onChange) {
      onChange(e.target.value)
    }
  }
  const { t } = useTranslation()

  return useObserver(() => (
    <StyledWrapper>
      <Form.Label>{t('Overlay:jobFunction')}</Form.Label>
      <InputGroup >
        <InputGroup.Text ><NewCheckIcon /></InputGroup.Text>
        <ArraySelect
          value={productsStore.activity && productsStore.activity.id}
          onChange={handleChange}
          options={productsStore.activities}
          returnEvent
        />
      </InputGroup>
    </StyledWrapper>
  ))
}

export default ShoppingActivitySelector
