import React from 'react'

export const FolderOpenIcon = (props) => {
  const color = props.color ? props.color : '#D0CFD0'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="folder-icon-svg" viewBox="0 0 28 28" width="28px" height="28px">
      <style type="text/css">
        {`
          .folder-icon-svg {
            fill:${color};
          }
        `}
      </style>
      <g>
        <g>
          <path fillRule="evenodd" clipRule="evenodd" d="M12.3 10h-2.1l2.1-2.1z" />
        </g>
        <path d="M21.7 12c.6 0 1 .4 1 1v.2l-1.5 6c-.1.4-.5.8-1 .8H7.8c-.5 0-.9-.3-1-.8l-1.5-6c-.1-.5.2-1 .7-1.2h15.7zM10.1 7c.7 0 1.4.2 2.1.5s1.3.5 2 .5H19c.6 0 1 .4 1 1v2H8V8c0-.6.4-1 1-1h1.1z" fillRule="evenodd" clipRule="evenodd" />
      </g>
    </svg>
  )
}

