import React, { useState, useRef, useEffect } from 'react'
import ReactCalendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { cloneDeep } from 'lodash';
import { StyledCalendarWrapper } from './StyledCalendarWrapper'
import i18n from '../../translation/i18n'

const CalendarMultiselect = ({ dates, setDates, ...props }) => {
  const [lastClickedDate, setLastClickedDate] = useState(null)
  const divRef = useRef(null);
  const [allowRange, setAllowRange] = useState(false);

  const dateAlreadyClicked = (dates, date) => dates.some(d => JSON.stringify(date) === JSON.stringify(d));
  const datesExcept = (dates, date) => dates.filter(d => JSON.stringify(date) !== JSON.stringify(d));

  const onClickDay = (date) => {
    setLastClickedDate(date)
    if (allowRange) {
      //add range
      let newDates = cloneDeep(dates)
      let range = getDaysRangeArray(lastClickedDate, date);
      newDates = newDates.filter(d => !range.map(dd => JSON.stringify(dd)).includes(JSON.stringify(d))).concat(range)
      setAllowRange(false);
      setDates(newDates)
    }
    else {
      //update single dates
      if (dateAlreadyClicked(dates, date)) {
        setDates(datesExcept(dates, date));
        setLastClickedDate(null)
      } else {
        setDates([...dates, date])
      }
    }
  }

  const tileClassName = ({ date }) => {
    if (dates && dates.some(d => JSON.stringify(date) === JSON.stringify(d))) {
      return 'react-calendar__tile--custom-active';
    }
  }

  const getDaysRangeArray = (firstInRange, lastInRange) => {
    let datesBetween = [];
    for (let newDay = new Date(firstInRange); newDay <= lastInRange; newDay.setDate(newDay.getDate() + 1)) {
      if (newDay.getDay() === 0 || newDay.getDay() === 6) {
        continue;
      }
      datesBetween.push(new Date(newDay));
    }
    return datesBetween;
  }

  useEffect(() => {
    let currentRev = divRef.current
    const keypressShiftCallback = e => {
      if (e.shiftKey && lastClickedDate) {
        setAllowRange(true);
      } else {
        setAllowRange(false);
      }
    }
    if (currentRev) {
      currentRev.addEventListener('click', keypressShiftCallback)
    }
    return () => { currentRev && currentRev.removeEventListener('click', keypressShiftCallback); }
  }, [lastClickedDate])

  return (
    <StyledCalendarWrapper defaultActiveDisabled ref={divRef}>
      <ReactCalendar
        tileClassName={tileClassName}
        onClickDay={(date) => onClickDay(date)}
        locale={i18n.language}
        {...props}
      />
    </StyledCalendarWrapper>
  )
}

export default CalendarMultiselect
