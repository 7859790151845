import React from 'react'
import { Subheader } from '../Page/logix2020/Subheader'
import SubheaderForm from '../Page/logix2020/SubheaderForm'
import { useTranslation } from 'react-i18next'
import { Alert, Dropdown } from 'react-bootstrap'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { PdfButton } from '../Buttons/PdfButton'
import { ExcelButton } from '../Buttons/ExcelButton'
import { useLocation, useParams } from 'react-router-dom'
import useStores from '../../stores/useStore'
import { Link } from 'react-router-dom'
import LaundryShelfData from './LaundryShelfData'
import { inject, observer } from "mobx-react";
import styled from 'styled-components'
import DownloadURLAlert from '../Forms/DownloadURLAlert'
import useReport from '../../utils/hooks/useReport'
import ReportSubscriptionModal from '../Files/Form/ReportSubscriptionModal'

const StyledSubHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (max-width: 770px) {
   
    .subheader__button {
      margin-right: 0.5rem;
    }
  }
  @media screen and (max-width: 457px) {
      flex-direction: column;
      width:100%;
      .dropdown {
          width:100%;
      }
  }
`;

const SubheaderInventoryOverview = inject("inventoryStore")(observer((props) => {
  const currentLocation = useLocation()
  const foreignEmployeeId = useParams().employeeId
  const { t } = useTranslation()
  const { inventoryStore, uiStore } = useStores()
  const { createReport, reportIsLoading, reportErrors, reportDownloadUrls, setReportDownloadUrls, reportSubscriptionId, setReportSubscriptionId } = useReport()
  const menuPointsAmount = ([uiStore.returnPersonalArticles, uiStore.allowArticleTransferToEmployee, uiStore.allowInventoryBookings].filter(Boolean).length)

  return (
    <Subheader className="subHeader-v1 ">
      <SubheaderForm
        title={foreignEmployeeId ? t("inventory:currentEquipment") : t("inventory:myEquipment")}
        goBackLabel={t("backButton")}
      />
      <LaundryShelfData />
      <StyledSubHeader>
        {menuPointsAmount === 1 ? (
          <>
            {uiStore.allowArticleTransferToEmployee &&
              <Link className="subheader__button btn btn-primary"
                to={
                  foreignEmployeeId
                    ? `/inventoryreturnemployeeselect/${foreignEmployeeId}`
                    : "/inventoryreturnemployeeselect"
                } >
                {t("inventory:articleTransfer")}
              </Link>}
            {uiStore.returnPersonalArticles &&
              <Link className="subheader__button btn btn-primary"
                to={foreignEmployeeId
                  ? `/inventoryAction/returnBookings/${foreignEmployeeId}`
                  : "/inventoryAction/returnBookings"}
                state={{ from: currentLocation.pathname }}
              >
                {t("inventory:returnBookings")}
              </Link>
            }{uiStore.allowInventoryBookings &&
              <Link className="subheader__button btn btn-primary "
                to={foreignEmployeeId
                  ? `/inventoryAction/inventoryBookings/${foreignEmployeeId}`
                  : "/inventoryAction/inventoryBookings"}
                state={{ from: currentLocation.pathname }}
              >
                {t("inventory:inventoryBooking")}
              </Link>
            }
          </>
        ) : (menuPointsAmount > 1) ? (
          <Dropdown className="subheader__button ">
            <Dropdown.Toggle className="btn btn-primary btn-extra-padding w-100">
              {t("inventory:bookingsReturns")}
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0 w-100">
              {uiStore.returnPersonalArticles &&
                <Link className="subheader__button btn btn-primary w-100 m-0 border border-white"
                  to={foreignEmployeeId
                    ? `/inventoryAction/returnBookings/${foreignEmployeeId}`
                    : "/inventoryAction/returnBookings"}
                  state={{ from: currentLocation.pathname }}
                >
                  {t("inventory:returnBookings")}
                </Link>}
              {uiStore.allowInventoryBookings &&
                <Link className="subheader__button btn btn-primary w-100 m-0 border border-white"
                  to={foreignEmployeeId
                    ? `/inventoryAction/inventoryBookings/${foreignEmployeeId}`
                    : "/inventoryAction/inventoryBookings"}
                  state={{ from: currentLocation.pathname }}
                >
                  {t("inventory:inventoryBooking")}
                </Link>}
              {uiStore.allowArticleTransferToEmployee &&
                <Link className="subheader__button btn btn-primary w-100 m-0 border border-white"
                  to={
                    foreignEmployeeId
                      ? `/inventoryreturnemployeeselect/${foreignEmployeeId}`
                      : "/inventoryreturnemployeeselect"
                  }
                  state={{ from: currentLocation.pathname }}>
                  {t("inventory:articleTransfer")}
                </Link>}
            </Dropdown.Menu>
          </Dropdown>
        ) : null}

        <Link className="subheader__button btn btn-secondary"
          to={
            foreignEmployeeId
              ? `/inventoryHistory/${foreignEmployeeId}`
              : "/inventoryHistory"
          } >
          {t("inventoryHistory:inventoryHistory")}
        </Link>

        {inventoryStore.employeeId && (
          <PdfButton
            variant="secondary"
            onClick={() => createReport("pdfReport", APIEndpoints.currentInventory(inventoryStore.employeeId, false), {})}
            isLoading={reportIsLoading.pdfReport}
            buttonText={t("inventory:currentEquipment")}
            className="subheader__button"
          />
        )}
        {inventoryStore.employeeId && (
          <ExcelButton
            variant="secondary"
            onClick={() => createReport("excelReport", APIEndpoints.currentInventory(inventoryStore.employeeId, true), {})}
            isLoading={reportIsLoading.excelReport}
            buttonText={t("inventory:currentEquipment")}
            className="subheader__button"
          />
        )}
      </StyledSubHeader>
      {
        (reportDownloadUrls.pdfReport || reportDownloadUrls.excelReport) &&
        <DownloadURLAlert
          downloadUrl={reportDownloadUrls.pdfReport || reportDownloadUrls.excelReport}
          setDownloadUrl={() => setReportDownloadUrls({})}
          fileName={t("inventory:currentEquipment")}
        />
      }

      {
        (reportErrors.pdfReport || reportErrors.excelReport) &&
        <Alert
          className="subheader__alert"
          variant="danger"
        >
          {reportErrors?.pdfReport?.value || reportErrors?.excelReport?.value}
        </Alert>
      }
      {reportSubscriptionId && <ReportSubscriptionModal
        reportSubscriptionId={reportSubscriptionId}
        setReportSubscriptionId={setReportSubscriptionId}
        message={t('reportConfig:createPending')}
      />}
    </Subheader >
  );
}))

export default SubheaderInventoryOverview
