import React from 'react'
import EmployeeData from '../Employees/Subheader/EmployeeData'
import { Subheader } from '../Page/logix2020/Subheader'
import SubheaderTitle from '../Employees/Subheader/SubheaderTitle'
import ShoppingActivitySelector from '../Employees/Subheader/ShoppingActivitySelector'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { TiInfoLarge } from 'react-icons/ti'
import useStores from '../../stores/useStore'

const StyledEmployeeData = styled(EmployeeData)`
flex-grow:1;
  justify-content:flex-start;
  
`
const StyledWrapper = styled.div`
  margin: 1rem 0 1rem 0;
  @media screen and (max-width: 479px) {
    width:100%;
  }
   @media screen and (min-width: 480px) {
    padding: 0 25px 0 0 ;
  }
`

const StyledInfoWrapper = styled.div`
  padding: 10px;
  .infoIcon {
    font-size: 2rem;
    fill: white;
    background-color: ${props => props.theme.primaryColor};
    border-radius: 7px;
  }
`

const SubheaderProductsOverview = () => {
  const foreignEmployeeId = useParams().employeeId
  let currentLocation = useLocation()
  const { t } = useTranslation()
  const { productsStore, uiStore } = useStores()

  return (
    <Subheader className="subHeader-v1">
      <div className="d-flex">
        <SubheaderTitle title={t('SubheaderProductsOverview:employeeOrder')} />
        <StyledInfoWrapper>
        </StyledInfoWrapper>
      </div>

      <div className="flex-grow-1">
        {productsStore.activities.length !== 1 ? <ShoppingActivitySelector /> : ''}
      </div>
      <StyledEmployeeData showJobFunction={productsStore.activities.length === 1 ? true : false} />

      {uiStore.returnPersonalArticles &&
        <StyledWrapper>
          <Link className="btn btn-primary w-100"
            to={foreignEmployeeId ? `/inventoryAction/returnBookings/${foreignEmployeeId}` : '/inventoryAction/returnBookings'}
            state={{ from: currentLocation.pathname }}
          >
            {t('inventory:returnBookings')}
          </Link>
        </StyledWrapper>
      }
      {uiStore.allowSeeEmployeeInventories == null || uiStore.allowSeeEmployeeInventories
        ? <StyledWrapper>
          <Link className="btn btn-primary w-100" to={foreignEmployeeId ? `/inventory/${foreignEmployeeId}` : '/inventory'}>{t('inventory:currentEquipment')}</Link>
        </StyledWrapper>
        : null}
      {productsStore.infoText && <TiInfoLarge title={t('info')} className="infoIcon" role="button" onClick={() => productsStore.openOrderInfoModal()} />}

    </Subheader >
  )
}

export default SubheaderProductsOverview
