import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { PageLoadingLayer } from '../components/Loading/PageLoadingLayer'
import MetaInfoBlockSupplierOrder from '../components/Orders/MetaInfoBlockSupplierOrder'
import { Alert } from 'react-bootstrap'
import { GoBackBtnEasy } from '../components/Buttons/GoBackBtnEasy'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useStores from '../stores/useStore'
import SupplierOrderList from '../components/Orders/Detail/SupplierOrderList'
import ExpectedArrivalsModal from '../components/Orders/Detail/ExpectedArrivalsModal'
const StyledHeader = styled.div`
display:flex;
align-items: center;
justify-content:space-between;
margin:  1.5rem 0 3rem 0;
flex-wrap: wrap ;
`
const SupplierOrderDetailPage = inject('laundryShelfStore')(observer((props) => {
    const { supplierOrderId } = useParams()
    const { t } = useTranslation()
    const { orderStore } = useStores()
    const navigate = useNavigate();

    useEffect(() => {
        orderStore.supplierOrderId = supplierOrderId
        orderStore.loadSupplierOrder()
        orderStore.loadSupplierOrderPositions()
    }, [orderStore, supplierOrderId])

    if (orderStore.incomingOrderFailed) {
        return <div className='container-fluid'>
            <Alert variant='info' className='mt-5'>{t('order:didNotExists')}</Alert>
        </div>
    }

    if (!orderStore.supplierOrder || !orderStore.supplierOrderPositions) {
        return <PageLoadingLayer isLoading={true} />
    }
    const supplierOrderPositions = orderStore.supplierOrderPositions || []
    return (
        <>
            <div className='container-fluid'>
                <StyledHeader>
                    {<GoBackBtnEasy className='ms-0' color="white" onClick={() => navigate(-1)}>
                        <span>{t('backButton')}</span>
                    </GoBackBtnEasy>}
                    <h1 className="m-3">{t('order:supplierOrder')}: #{orderStore.supplierOrder.displayName}</h1>
                    <MetaInfoBlockSupplierOrder supplierOrder={orderStore.supplierOrder} />
                </StyledHeader>
                <SupplierOrderList supplierOrderPositions={supplierOrderPositions} />
            </div>
            {orderStore.openExpectedArrivalsModal ? <ExpectedArrivalsModal /> : null}
        </>
    )
}))

export default SupplierOrderDetailPage
