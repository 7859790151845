import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import SearchField from '../Forms/SearchField'
import { debounce } from 'throttle-debounce'


class OrdersSearchField extends Component {

  handleChange = e => {
    const { ordersStore } = this.props
    ordersStore.searchText = e.target.value
    this.handleChangeDebounce(e.target.value)
  }
  handleClear = () => {
    const { handleChange } = this.props
    handleChange('')
  }

  handleChangeDebounce = debounce(700, (value) => {
    const { handleChange } = this.props
    handleChange(value)
  })

  render() {
    const { ordersStore } = this.props

    return <SearchField className={this.props.className} placeholder={this.props.placeholder} value={ordersStore.searchText} onChange={this.handleChange} onClearClick={this.handleClear} />

  }
}

export default inject('ordersStore')(observer(OrdersSearchField));
