import React from 'react'
import TagsInput from '../Forms/TagsInput'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'


const OrdersPartnerSearchField = inject('ordersStore')(observer(props => {
    const { ordersStore, handleChange } = props
    const { t } = useTranslation()

    return (
        <TagsInput tags={ordersStore.partnerSearchKeys} setTags={tags => handleChange(tags)} placeholder={t('orders:partnerSearch')} />
    )
}))

export default OrdersPartnerSearchField