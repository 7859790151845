import React, { useEffect, useState } from 'react'
import { Alert, Button, Modal, Tab, Tabs } from 'react-bootstrap'
import { ButtonWithLoadingState } from '../../../Buttons'
import { t } from 'i18next'
import { usePlaceholdersState } from '../../../../stores/Configuration/PlaceholdersProvider'
import { cloneDeep } from 'lodash';
import MandatorsTable from './MandatorsTable'
import PlaceholdersTable from './PlaceholdersTable'
import { APIEndpoints } from '../../../../utils/api/apiConfig'
import apiClient from '../../../../utils/api/apiClient'
import { getErrorMessageFromApiResponse } from '../../../../utils/api/apiUtils'

const PlaceholderAssignmentsModal = ({ handleClose }) => {
  const { loadSubMandators, subMandators, assignmentPlaceholders, loadAssignmentPlaceholders } = usePlaceholdersState()
  const [selectedMandators, setSelectedMandators] = useState([])
  const [selectedPlaceholders, setSelectedPlaceholders] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleSave = async () => {
    setError('')
    setLoading(true)
    try {
      await apiClient.postJson(APIEndpoints.placeholder().inheritance, { 'submandatorIds': selectedMandators, 'placeholderIds': selectedPlaceholders })
      handleClose()
    }
    catch (e) {
      setError(getErrorMessageFromApiResponse(e))
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadSubMandators()
    loadAssignmentPlaceholders()
  }, [loadAssignmentPlaceholders, loadSubMandators])

  const handleSelectMandator = (cell, row) => {
    let mandatorId = row.id
    setSelectedMandators(prev => {
      let foundIndex = prev.findIndex(id => id === mandatorId)
      if (foundIndex >= 0) {
        prev.splice(foundIndex, 1)
      }
      else {
        prev.push(mandatorId)
      }
      return cloneDeep(prev)
    })
  }

  const handleSelectedPlaceholder = (cell, row) => {
    let mandatorId = row.id
    setSelectedPlaceholders(prev => {
      let foundIndex = prev.findIndex(id => id === mandatorId)
      if (foundIndex >= 0) {
        prev.splice(foundIndex, 1)
      }
      else {
        prev.push(mandatorId)
      }
      return cloneDeep(prev)
    })
  }

  return (

    <Modal size="lg" show={true} onHide={handleClose} scrollable>
      <Modal.Header closeButton >
        <Modal.Title>{t('placeholder:assignPlaceholder')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey="MandatorsTable"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="MandatorsTable" title="MandatorsTable" mountOnEnter unmountOnExit>
            <MandatorsTable selectedMandators={selectedMandators} subMandators={subMandators} handleSelectMandator={handleSelectMandator} />
          </Tab>
          <Tab eventKey="PlaceholdersTable" title="PlaceholdersTable">
            <PlaceholdersTable selectedPlaceholders={selectedPlaceholders} handleSelectedPlaceholder={handleSelectedPlaceholder} assignmentPlaceholders={assignmentPlaceholders} />
          </Tab>
        </Tabs>
        {error ? <Alert variant="danger">{error}</Alert> : null}
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoadingState
          buttonText={t('saveButton')}
          isLoading={loading}
          onClick={handleSave}
        />
        <Button variant="secondary" onClick={handleClose}>
          {t("closeButton")}
        </Button>
      </Modal.Footer>
    </Modal>
  )

}

export default PlaceholderAssignmentsModal