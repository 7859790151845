import React, { useEffect, useState, useRef } from "react"
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { getErrorMessageFromApiResponse } from "../../utils/api/apiUtils"
import { setPointerErrorsFromApiResponse } from "../../utils/api/apiUtils"
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { hoursArray } from '../../utils/hoursAndMinutes'
import { cloneDeep, concat } from 'lodash';
import { useTranslation } from 'react-i18next'
import { useCallback } from "react"
import useMountEffect from "../../utils/hooks/useMountEffect"

export const AppointmentTypesContext = React.createContext({})

const AppointmentTypesProvider = ({ children }) => {
    let location = useLocation()

    const mountedRef = useRef(true)
    const { t } = useTranslation()
    const { appointmentTypeId } = useParams()
    const [appointmentTypes, setAppointmentTypes] = useState([])
    const [selectedAppointmentType, setSelectedAppointmentType] = useState({})
    const [error, setError] = useState("")
    const [formErrors, setFormErrors] = useState(null)
    const [success, setSuccess] = useState("")
    const [cabinTypes, setCabinTypes] = useState([])
    const [assignedCabins, setAssignedCabins] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    const [idAppointmentType, setIdAppointmentType] = useState(null)

    const [redirectId, setRedirectId] = useState("")

    //Cleanup Function
    useEffect(() => {
        return () => { mountedRef.current = false }
    }, [])
    useMountEffect(() => {
        reloadAppointmentData()
    })
    //pass state from create to edit page
    useEffect(() => {
        if (location.state && location.state.createNewAppointmentType) {
            setSuccess(t(`registrationBox:savingSuccess`))
        }
    }, [location.state, t])

    //get Appointmenttypes,cabinTypes
    const reloadAppointmentData = (force) => {
        if (!appointmentTypes.length || force) {
            apiClient.getJson(APIEndpoints.appointmenttypes()).then(response => {
                if (!mountedRef.current) { return null }
                setAppointmentTypes(response)
            }).catch((error) => {
                setError(getErrorMessageFromApiResponse(error))
            })
        }
        if (!cabinTypes.length || force) {
            apiClient.getJson(APIEndpoints.cabins()).then(response => {
                if (!mountedRef.current) { return null }
                setCabinTypes(response)
            }).catch((error) => {
                console.log(error)
            })
        }
    }
    const loadAppointmentTypeData = useCallback(async (appointmentTypeId) => {
        try {
            const response = await apiClient.getJson(APIEndpoints.appointmenttypes(appointmentTypeId).appointmenttypeData)
            if (!mountedRef.current) { return null }
            setSelectedAppointmentType(response)
        }
        catch (e) {
            setError(getErrorMessageFromApiResponse(e))
        }
    }, [])

    const loadAssignedCabins = useCallback(async (appointmentTypeId) => {
        try {
            const response = await apiClient.getJson(APIEndpoints.appointmenttypes(appointmentTypeId).assignedCabins)
            if (!mountedRef.current) { return null }
            setAssignedCabins(response)
        }
        catch (e) {
            setError(getErrorMessageFromApiResponse(e))
        }
    }, [])
    //get selected appointmentType and its assigned cabins
    useEffect(() => {
        if (appointmentTypeId) {
            loadAppointmentTypeData(appointmentTypeId);
            loadAssignedCabins(appointmentTypeId)
        }
    }, [appointmentTypeId, loadAppointmentTypeData, loadAssignedCabins])




    //helperFunction
    const timeSlotsToHours = useCallback((timeSlots) => {
        var newArray = cloneDeep(hoursArray)
        for (const timeSlot of timeSlots) {
            newArray[timeSlot.hour] = insertNew(timeSlot.hour, timeSlot.minute, newArray[timeSlot.hour])
        }
        return newArray
    }, [])
    const [hours, setHours] = useState(hoursArray)
    //get TimeSlots
    const loadTimeSlots = useCallback(() => {
        if (appointmentTypeId) {
            apiClient.getJson(APIEndpoints.appointmenttypes(appointmentTypeId).timeSlotsAdministration).then(response => {
                if (!mountedRef.current) { return null }
                setHours(timeSlotsToHours(response.timeSlots))
            }).catch((error) => {
                setError(getErrorMessageFromApiResponse(error))
            })
        }
    }, [appointmentTypeId, timeSlotsToHours])
    //helperFunction
    const hoursToTimeSlots = useCallback((array) => {
        return { durationInMinutes: selectedAppointmentType.durationInMinutes, timeSlots: concat(...array) }
    }, [selectedAppointmentType])

    //put TimeSlots
    const updateTimeSlots = useCallback((hoursArray) => {
        setSuccess('')
        setFormErrors(null)
        setError(null)
        apiClient.putJson(APIEndpoints.appointmenttypes(appointmentTypeId).timeSlotsAdministration, hoursToTimeSlots(hoursArray)).then(response => {
            if (!mountedRef.current) { return null }
            setHours(timeSlotsToHours(response.timeSlots))
            setSuccess(t(`registrationBox:savingSuccess`))
        }).catch((error) => {
            setError(getErrorMessageFromApiResponse(error))
        })
    }, [appointmentTypeId, hoursToTimeSlots, t, timeSlotsToHours])


    //helperFunction
    const insertNew = (hour, minute, prevArray) => {
        const newTime = { hour: hour, minute: minute }
        const newArray = [newTime, ...prevArray]
        newArray.sort(function (a, b) {
            return a.minute - b.minute
        })
        return newArray
    }

    //post new appointmentType, patch (update) appointmentType, put (update) assigned cabins
    const handleSubmit = (mode) => {
        setSuccess('')
        setFormErrors(null)
        switch (mode) {
            case "assignedCabins":
                apiClient.putJson(
                    APIEndpoints.appointmenttypes(selectedAppointmentType.id).assignedCabins,
                    assignedCabins
                ).then(() => {
                    if (!mountedRef.current) { return null }
                    setSuccess(t(`registrationBox:savingSuccess`))
                }).catch((error) => {
                    setFormErrors(setPointerErrorsFromApiResponse(error))
                })
                break;
            case "name":
                if (selectedAppointmentType.id) {
                    apiClient.patchJson(
                        APIEndpoints.appointmenttypes(selectedAppointmentType.id).default,
                        selectedAppointmentType
                    ).then(() => {
                        if (!mountedRef.current) { return null }
                        loadTimeSlots()
                        setSuccess(t(`registrationBox:savingSuccess`))
                    }).catch((error) => {
                        setFormErrors(setPointerErrorsFromApiResponse(error))
                    })
                }
                else {
                    apiClient.postJson(
                        APIEndpoints.appointmenttypes(),
                        selectedAppointmentType
                    ).then((response) => {
                        if (!mountedRef.current) { return null }
                        setRedirectId(response.id)
                    }).catch((error) => {
                        setFormErrors(setPointerErrorsFromApiResponse(error))
                    })
                }
                break;
            case "duration":
                updateTimeSlots(hours)
                break;
            default:
                break;
        }
    }

    const checkIfDeletable = async (id) => {
        var result = false
        await apiClient.getJson(APIEndpoints.appointmenttypes(id).deletable).then(response => {
            result = response.isDeletable
        }).catch((error) => {
            setError(getErrorMessageFromApiResponse(error))
        })
        return result
    }

    const handleDelete = (id, cancellationMessage = "") => {
        setBtnLoading(true)
        apiClient.deleteJson(
            APIEndpoints.appointmenttypes(id).default, null, true, false,
            {
                data: {
                    cancelComment: cancellationMessage,
                    cancelAppointments: true,
                }
            }
        ).then(() => {
            if (!mountedRef.current) { return null }
            reloadAppointmentData(true)
            setIdAppointmentType(null)
        }).catch((error) => {
            setError(getErrorMessageFromApiResponse(error))
        }).finally(() => setBtnLoading(false))
    }

    const resetStates = () => {
        setFormErrors(null)
        setError("")
        setSuccess("")
    }

    const initTimesGeneratorForm = useCallback(() => {
        loadTimeSlots()
        resetStates()
    }, [loadTimeSlots])

    useEffect(() => {
        let timer = null
        if (success) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                setSuccess("");
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [setSuccess, success])

    const contextValues = {
        updateTimeSlots,
        hours, setHours,
        appointmentTypes,
        selectedAppointmentType,
        setSelectedAppointmentType,
        handleSubmit,
        cabinTypes,
        handleDelete,
        assignedCabins,
        setAssignedCabins,
        checkIfDeletable,
        error, setError,
        formErrors,
        success,
        redirectId,
        loadAppointmentTypeData,
        loadAssignedCabins,
        btnLoading,
        idAppointmentType,
        setIdAppointmentType,
        initTimesGeneratorForm, setSuccess
    }

    return (
        <AppointmentTypesContext.Provider value={contextValues}>
            {children}
        </AppointmentTypesContext.Provider>
    )
}

export default AppointmentTypesProvider
