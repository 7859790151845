import React, { useState } from 'react'


const BillsContext = React.createContext()
export const useBillsState = () => {
    return React.useContext(BillsContext)
}

const BillsProvider = ({ children }) => {
    const [filter, setFilter] = useState({ 'filter.status': 'published' })

    const contextValues = {
        filter, setFilter
    }

    return (
        <BillsContext.Provider value={contextValues}>
            {children}
        </BillsContext.Provider>
    )
}

export default BillsProvider