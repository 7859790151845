import React from 'react'

export const HidePW = ({ color, ...rest }) => {
  const colorNew = color ? color : 'currentColor'
  return (
    <svg className="st0" strokeWidth="0" viewBox='0 0 16 16' height="22px" width="22px" {...rest}>
      <style type="text/css">
        {`
          .st0{fill:${colorNew};stroke:${colorNew};}
        `}
      </style>
      <g  >
        <path fillRule="evenodd" clipRule="evenodd" d="M1,8.269896149635315 c0,-3.9 3.1,-7 7,-7 s7,3.1 7,7 h-1 c0,-3.3 -2.7,-6 -6,-6 s-6,2.7 -6,6 H1 zm4,0 c0,-1.7000000000000002 1.3,-3 3,-3 s3,1.3 3,3 s-1.3,3 -3,3 s-3,-1.3 -3,-3 zm1,0 c0,1.1 0.9,2 2,2 s2,-0.9 2,-2 s-0.9,-2 -2,-2 s-2,0.9 -2,2 z" id="svg_1" />
        <path strokeOpacity="1" strokeWidth="1" strokeDasharray="none" strokeLinejoin="miter" strokeLinecap="butt" strokeDashoffset="" opacity="1" markerStart="" markerMid="" markerEnd="" d="M0.38754070863542733,2.809690629702729 L14.57439257512299,12.844293169413447 " transform="rotate(-1.0416253805160522 7.480966567993122,7.8269920349121005) " id="svg_4" />
      </g>
    </svg>
  )
}