import React, { useState } from 'react'
import { Button, Modal, Tabs, Tab, Form, Spinner, Alert } from 'react-bootstrap';
import { t } from 'i18next';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'react-ckeditor5-custom';

import EditorModalHeaderButtons from '../News/EditorModalHeaderButtons';
import FileInput from '../../Forms/FileInput';
import { makeCall } from '../../../utils/api/makeCall';
import apiClient from '../../../utils/api/apiClient';
import { APIEndpoints } from '../../../utils/api/apiConfig';
import { emptyGuid } from '../../../utils/emptyGuid';

const HTMLEditorModal = ({ handleClose, handleSave, data, title, loadingStates, setErrorStates, errorStates, setLoadingStates }) => {
  const [isFullScreen, setFullScreen] = useState(false);
  const [descriptionText, setDescriptionText] = useState(data)
  const handleFullScreen = () => {
    setFullScreen(!isFullScreen);
  };
  const [picturePath, setPicturePath] = useState('')

  const uploadPicture = (file) => {
    makeCall('savePicture', async () => {
      let result = await apiClient.postFile(APIEndpoints.feedsAdministration(emptyGuid).fileUpload, {}, file)
      if (result && result.fullPath) {
        setPicturePath(result.fullPath)
      }
    }, setErrorStates, setLoadingStates)
  }
  return (
    <Modal size="lg" backdrop="static" show={true} onHide={handleClose} fullscreen={isFullScreen} enforceFocus={false} scrollable>
      <Modal.Header >
        <Modal.Title>{title}</Modal.Title>
        <EditorModalHeaderButtons
          isFullScreen={isFullScreen}
          handleFullScreen={handleFullScreen}
          handleClose={handleClose}
        />
      </Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey="html"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="editor" title="Editor" mountOnEnter unmountOnExit>
            <CKEditor
              editor={Editor}
              config={{
                toolbar: [
                  'undo', 'redo', '|', 'fontSize', 'fontColor', 'fontBackgroundColor', '|',
                  'bold', 'italic', 'underline', 'strikethrough', '|', 'link', 'insertTable', 'blockQuote', '|',
                  'bulletedList', 'numberedList', 'outdent', 'indent'
                ]
              }}
              data={descriptionText}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescriptionText(data)
              }}
            />
          </Tab>

          <Tab eventKey="html" title="HTML">
            <>
              <Form.Group className="mb-3">
                <Form.Control
                  style={{ minHeight: "600px" }}
                  as="textarea"
                  placeholder=""
                  value={descriptionText || ""}
                  onChange={e => setDescriptionText(e.target.value)}
                  name="news.body.values"
                  isInvalid={errorStates?.saveNews?.formErrors?.body}
                />
              </Form.Group>
              {loadingStates.savePicture
                ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
                : <FileInput
                  maxFiles={1}
                  className="mb-3"
                  onDrop={(acceptedFiles) => {
                    let newFiles = acceptedFiles.map(file => Object.assign(file, {
                      preview: URL.createObjectURL(file)
                    }));
                    uploadPicture(newFiles)
                  }}
                  label={t('orderDialog:attachments')}
                />
              }
              {picturePath && <Alert dismissible variant="success">{picturePath}</Alert>}
            </>
          </Tab>
        </Tabs>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => { handleSave(descriptionText) }}>
          {t('saveButton')}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {t("closeButton")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default HTMLEditorModal