import React, { Component } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { withTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import ArraySelect from '../Select/ArraySelect'

const StyledWrapper = styled.div`
  margin-bottom: 30px;
`

class CatalogSelector extends Component {
  handleChangeCatalog = catalog => {
    const { teamOrderStore } = this.props

    if (catalog.isExternal) {
      (async () => {
        const catalogExternalUrl = await apiClient.getJson(APIEndpoints.externalCatalogs(catalog.id), { shoppingbasketId: this.props.productsStore.basket.id })
        if (catalogExternalUrl.redirectUrl) {
          window.open(catalogExternalUrl.redirectUrl, catalogExternalUrl.target ? catalogExternalUrl.target : "_blank")
        } else {
          console.log('no redirectUrl in response given')
        }
      })()
      return
    }
    teamOrderStore.setCatalog(catalog.id)
  }

  render() {
    const { t, teamOrderStore } = this.props

    return (
      <StyledWrapper>
        {teamOrderStore.articleCatalogs.length > 0 && (
          <Form.Group>
            <Form.Label>{t('ProductOverview:selectCatalogue')}</Form.Label>
            <ArraySelect
              noAutoSelect
              options={teamOrderStore.articleCatalogs}
              value={teamOrderStore.selectedCatalog}
              onChange={this.handleChangeCatalog}
            />
          </Form.Group>
        )}
      </StyledWrapper>
    )
  }
}

export default withTranslation()(inject('uiStore', 'productsStore', 'teamOrderStore')(observer(CatalogSelector)))
