import apiConfig from './apiConfig'
import axios from 'axios'
import qs from 'qs'
import i18n from '../../translation/i18n'
// function because language must be updated
const httpClientAuthorizationseverApiToken = () => axios.create({
  baseURL: apiConfig.AuthorizationserverTokenUrl,
  headers: {
    'Accept': 'application/vnd.api+json',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept-Language': i18n.language
  }
})
const httpClientLogixApiToken = () => axios.create({
  baseURL: apiConfig.APIBaseUrlToken,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  }
})

const apiToken = {
  getByUsernameAndPassword: (username, password) => {
    const useAuthorizationServer = process.env.REACT_APP_USE_AUTHORIZATIONSERVER === 'true'
    const httpClient = useAuthorizationServer ? httpClientAuthorizationseverApiToken() : httpClientLogixApiToken()

    return httpClient.post('', qs.stringify({
      grant_type: 'password',
      username: username,
      password: password,
      client_id: 'logix',
      // client_secret: 'pFC/gydzH21L+5N1JLHVFt3udJifKRVIQfqkNk0xCPo=',
    }))
  },
  getBySSOData: (code) => {
    return httpClientLogixApiToken().post('', qs.stringify({
      grant_type: 'authorization_code',
      code: code
    }))
  }
}

export default apiToken
