import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled, { withTheme } from 'styled-components'
import { ButtonToolbar, Form, DropdownButton } from 'react-bootstrap'

const StyledListItemText = styled.span`
  display: inline-block;
  margin-left: 10px;
`

const StyledButtonWrapp = styled.div`
  margin-bottom: 10px;

  button {
    width: 100%;
  }
`
const StyledButtonToolbar = styled(ButtonToolbar)`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    ${StyledButtonWrapp} {
      width: calc(50% - 7.5px);
    }
`

const StyledDropdownItem = styled.div`
  padding: 5px 10px;
`

class SearchCriteriaCatalogCategoriesFilter extends Component {

  handleClickSearchCriteriaCatalogCategoriesFolder = id => e => {
    this.props.teamOrderStore.searchCriteriaCatalogCategoriesOpened[id] = !this.props.teamOrderStore.searchCriteriaCatalogCategoriesOpened[id]
  }



  handleChangeSearchCriteriaCatalogCategories = (parentIndex, id) => e => {
    if (e.target.checked) {
      this.props.teamOrderStore.selectedSearchCriteriaCatalogCategories[id] = { index: parentIndex }
    } else {
      delete this.props.teamOrderStore.selectedSearchCriteriaCatalogCategories[id]
    }

    this.props.teamOrderStore.loadOrderableArticlesDebounced()
  }

  renderSearchCriteriaCatalogCategoriesItem(parentIndex, category) {
    const { teamOrderStore } = this.props

    return (
      <React.Fragment>
        <StyledDropdownItem>
          <div className='d-flex'>
            <Form.Check >
              <Form.Check.Input
                type='checkbox'
                id={`filter-searchCriteriaCatalogCategories-${category.id}`}
                onChange={this.handleChangeSearchCriteriaCatalogCategories(parentIndex, category.id)}
                checked={!!teamOrderStore.selectedSearchCriteriaCatalogCategories[category.id]}
              />
              <Form.Check.Label htmlFor={`filter-searchCriteriaCatalogCategories-${category.id}`}>
                <StyledListItemText>{category.displayName}</StyledListItemText>
              </Form.Check.Label>
            </Form.Check>
          </div>
        </StyledDropdownItem>
      </React.Fragment>
    )
  }

  getSearchCriteriaCatalogCategoriesCategoryTitle(category) {
    const { teamOrderStore } = this.props
    const countChecked = teamOrderStore.searchCriteriaCatalogCategories.find(searchCategory => searchCategory.id === category.id)
      .categories.reduce((acc, childCategory) => acc + (teamOrderStore.selectedSearchCriteriaCatalogCategories[childCategory.id] ? 1 : 0), 0)

    return `${category.displayName} ${countChecked ? `(${countChecked})` : ''}`
  }

  render() {
    const { teamOrderStore } = this.props

    const topLevelCategories = teamOrderStore.searchCriteriaCatalogCategories.filter(category => !category.parentCategory)

    return (
      <React.Fragment>
        <StyledButtonToolbar>
          {topLevelCategories.map((category, iterator) => (

            <StyledButtonWrapp key={category.id}>
              <DropdownButton
                drop='down'
                variant='custom-select'
                title={this.getSearchCriteriaCatalogCategoriesCategoryTitle(category)}
                id={`searchCriteriaCatalogCategories-${category.id}}`}
                key={category.id}
              >
                {category.categories.map(childCategory => (
                  <React.Fragment
                    key={childCategory.id}>{this.renderSearchCriteriaCatalogCategoriesItem(iterator, childCategory)}</React.Fragment>
                ))}
              </DropdownButton>
            </StyledButtonWrapp>

          ))}
        </StyledButtonToolbar>
      </React.Fragment>
    )
  }
}

export default withTheme(inject('uiStore', 'productsStore', 'teamOrderStore')(observer(SearchCriteriaCatalogCategoriesFilter)))
