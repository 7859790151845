import React, { useCallback, useState } from 'react'
import OverlayRight, { StyledOverlayInnerWrapper } from '../Page/logix2020/OverlayRight'
import OverlayContainer from '../Page/logix2020/OverlayContainer'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { useTranslation, Trans } from 'react-i18next'
import { ButtonWithLoadingState } from '../Buttons'
import OfficeImport from './Imports/OfficeImport'
import CostCenterImport from './Imports/CostCenterImport'
import OrderImport from './Imports/OrderImport'

import { Alert } from 'react-bootstrap'
import { setPointerErrorsFromApiResponse } from "../../utils/api/apiUtils"
import FileInput from '../Forms/FileInput'
import { FileOutput } from '../Forms/FileOutput'
import EntitlementImport from './Imports/EntitlementImport'
import CatalogImport from './Imports/CatalogImport'



const CreateNewImportContent = ({ importName }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [importIsPending, setImportIsPending] = useState(false)
    const [importIsSendByMail, setImportIsSendByMail] = useState(false)
    const [error, setError] = useState(false)
    const [formData, setFormData] = useState({})
    const [files, setFiles] = useState([])
    const [formErrors, setFormErrors] = useState({})

    const handleClickCreateImport = useCallback(() => {
        setImportIsPending(false)
        setImportIsSendByMail(false)
        resetError()
        setIsLoading(true)
        apiClient.postFile(
            APIEndpoints[importName],
            formData,
            files,
            true)
            .then(response => {
                if (response.status === 202) {
                    setImportIsPending(true)
                }
                if (response.status === 204) {
                    setImportIsSendByMail(true)
                }
            }).catch(e => {
                setError(true)
                setFormErrors(setPointerErrorsFromApiResponse(e))
            })
            .finally(() => {
                setIsLoading(false)
                deleteFile()
            })
    }, [files, importName, formData])

    const deleteFile = () => {
        setFiles([])
    }

    const resetError = () => {
        setError(false)
        setFormErrors({})
    }

    return (
        <>
            <div className="content">
                <FileInput
                    className="mb-3"
                    maxFiles={1}
                    accept={{
                        'application/vnd.ms-excel': [],
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
                    }}
                    onDrop={(acceptedFiles) => {
                        let newFiles = acceptedFiles.map(file => Object.assign(file, {
                            preview: URL.createObjectURL(file)
                        }));
                        resetError()
                        setFiles(newFiles)
                    }}
                    isInvalid={!!formErrors.file}
                    label={t('file')}
                    error={formErrors.file}
                />

                {files.length ? <FileOutput file={files[0]} deleteFile={deleteFile} /> : null}

                {importName === 'officeImport' && (
                    <OfficeImport formData={formData} setFormData={setFormData} formErrors={formErrors} />
                )}
                {importName === 'costcenterImport' && (
                    <CostCenterImport formData={formData} setFormData={setFormData} formErrors={formErrors} />
                )}
                {importName === 'orderImport' && (
                    <OrderImport formData={formData} setFormData={setFormData} formErrors={formErrors} />
                )}
                {importName === 'entitlementImport' && (
                    <EntitlementImport formData={formData} setFormData={setFormData} formErrors={formErrors} />
                )}
                {importName === 'catalogImport' && (
                    <CatalogImport formData={formData} setFormData={setFormData} formErrors={formErrors} />
                )}
                {error && (
                    <Alert variant="danger">
                        <strong>{t('import:createError')}</strong><br />
                        {/*{error}*/}
                    </Alert>
                )}
                {importIsSendByMail && (
                    <Alert variant="info">
                        {<Trans i18nKey='import:isSendByMail' />}
                    </Alert>
                )}
                {importIsPending && (
                    <Alert variant="success">
                        {t('export:createPending')}
                    </Alert>
                )}
            </div>
            <div className="footer p-4" >
                <ButtonWithLoadingState
                    className="w-100"
                    isLoading={isLoading}
                    onClick={handleClickCreateImport}
                    loadingText={`${t('submitButton')}...`}
                    buttonText={t('import:send&upload')} />
            </div>
        </>
    )
}

const CreateNewImportOverlay = ({ visible, onClose, ...rest }) => {
    const { t } = useTranslation()

    return (
        <OverlayContainer>
            <OverlayRight visible={visible} onClose={onClose} headerTitle={t('import')}>
                <StyledOverlayInnerWrapper>
                    {visible && <CreateNewImportContent {...rest} />}
                </StyledOverlayInnerWrapper>
            </OverlayRight>
        </OverlayContainer>
    )
}

export default CreateNewImportOverlay
