import React from 'react'
import { Modal } from 'react-bootstrap'

const VideoModal = ({ url, handleClose }) => {

    return (
        <Modal
            onHide={handleClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show
        >
            <Modal.Header closeButton />
            <Modal.Body>
                <video style={{ width: "100%" }} controls autoPlay>
                    <source src={url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Modal.Body>
        </Modal>
    )
}

export default VideoModal