import React from 'react'

export const Tools = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="tools" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <path className="st0" d="M96,29.2l-1-2.1L79,43.6L68.6,33.5L84.5,17l-2-0.8c-2.8-1.3-6.1-1.9-9.7-1.9c-6.9,0-13.6,2.8-18.2,7.7
        c-6.9,7.2-9.1,17.7-5.5,26.8l0.1,0.3l-0.2,0.3L18.1,81.6c-3.9,3.9-3.8,10.7,0.3,14.8c1.8,1.8,4.5,2.9,7.2,2.9c2.8,0,5.3-1,7.5-3
        l31-32.7l0.3,0.1c3.1,0.9,5.8,1.4,8.3,1.4c6.9,0,13.6-2.8,18.2-7.7C98.2,49.9,100.1,38.9,96,29.2z M88.7,55.3c-3.9,4.5-9.6,7-15.9,7
        c-2.9,0-5.9-0.6-8.4-1.7l-1-0.4L30.8,94c-1.5,1.5-3.4,2.2-5.4,2.2c-1.8,0-3.6-0.6-5-1.9c-2.8-2.8-2.8-7.3,0-10.4L52.9,50l-0.4-1.1
        c-3.7-8.3-2-18,4.4-24.6c3.9-4.5,9.6-7,15.9-7c1.5,0,3,0.2,4.7,0.5l1.8,0.3L64.5,33.5l14.7,14.5l14.7-15.3l0.3,1
        C96.2,41.4,94.2,49.6,88.7,55.3z"/>
    </svg>
  )
}
