import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import NewsItem from './NewsItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ErrorAlerts } from '../../../utils/api/makeCall';
const StyledWrapper = styled.div`
@media screen and (min-width: 1080px) {
  max-width: calc(100% - 480px);
  min-width: 600px;
}
`

const NewsOverview = ({ setOpenEditorModal, errorStates, loadingStates, news, setNews, putNewsOrderSequenceBackground, setDeleteNewsId, setEditNewsId, isMainMandator, setEditNewsIdAssignments }) => {

  function move(from, to) {
    let copiedNews = [...news]
    let numberOfDeletedElm = 1;
    const elm = copiedNews.splice(from, numberOfDeletedElm)[0];
    numberOfDeletedElm = 0;
    copiedNews.splice(to, numberOfDeletedElm, elm);
    return copiedNews
  }

  const moveCardHandler = (dragIndex, dropIndex) => {
    const dragItem = news[dragIndex];
    if (dragItem) {
      const newSequence = move(dragIndex, dropIndex)
      setNews(newSequence);
      putNewsOrderSequenceBackground(newSequence)
    }
  };

  const handleEditNews = (newsId) => {
    setEditNewsId(newsId)
    setOpenEditorModal(true)
  }

  return (
    <>
      <StyledWrapper>
        {((loadingStates.news && !news.length) || loadingStates.saveOrderSequence)
          ? <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
          : <DndProvider backend={HTML5Backend}>
            {news.map((item, index) => (
              <NewsItem
                key={item.id}
                news={item}
                setDeleteNewsId={setDeleteNewsId}
                handleEditNews={handleEditNews}
                index={index}
                moveCardHandler={moveCardHandler}
                isMainMandator={isMainMandator}
                setEditNewsIdAssignments={setEditNewsIdAssignments}
              />
            ))}
          </DndProvider>
        }
        {loadingStates.saveOrderSequenceBackground ? <div className="mt-2 d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div > : null}
        <ErrorAlerts errorStates={errorStates} />
      </StyledWrapper >
    </>
  )
}

export default NewsOverview