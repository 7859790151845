import React from 'react'

export const ShoppingCartIcon = (props) => {
  const color = props.color ? props.color : '#D0CFD0'

  return (

    <svg className="shopping-cart-icon-svg" width="30.251" height="26.662" viewBox="0 0 28 28" fill={color} {...props}>
      <path d="M7.3 21c1.8 0 3.3 1.5 3.3 3.3s-1.5 3.3-3.3 3.3S4 26.2 4 24.4 5.5 21 7.3 21zm15 0c1.8 0 3.3 1.5 3.3 3.3s-1.5 3.3-3.3 3.3-3.3-1.5-3.3-3.3 1.4-3.3 3.3-3.3zM3 0c.8 0 1.6.6 1.7 1.4L5.2 4h19.2c2 0 3.5 1.6 3.5 3.5 0 .3 0 .5-.1.8l-2 8.8c-.4 1.6-1.8 2.8-3.4 2.8H7c-1.7 0-3.1-1.2-3.4-2.8l-1.4-6.3c0-.1-.1-.2-.1-.3L.1 2C-.1 1.1.5.1 1.4-.1H3z"
            fillRule="evenodd"
            clipRule="evenodd"
      />
    </svg>
  )
}
