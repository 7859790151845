import React from 'react'

export const RetourIcon = ({ color, title, ...rest }) => {
	return (
		<svg width='30' height='30' viewBox='0 0 113.4 113.4'
			style={{ enableBackground: 'new 0 0 113.4 113.4' }} {...rest}>
			{title && <title>{title}</title>}
			<path fill={color ? color : '#D0CFD0'} d='M99.2,67.4c0-3.2,0-6.4,0-9.7c0-3.9,0-7.8,0-11.7c0-3.4,0-6.7,0-10.1c0-1.6,0-3.3,0-4.9c0-0.4,0-0.7-0.2-1
	c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.3-0.4-0.6-0.5c-0.1-0.1-0.3-0.1-0.4-0.2c-1.2-0.5-2.4-1-3.7-1.4c-2.9-1.2-5.9-2.3-8.8-3.5
	c-3.5-1.4-7.1-2.8-10.7-4.2c-3.1-1.2-6.1-2.4-9.2-3.6c-1.5-0.6-2.9-1.2-4.4-1.7c-0.6-0.2-1,0-1.5,0.2c-0.8,0.3-1.5,0.6-2.3,0.9
	c-2.8,1.1-5.5,2.2-8.3,3.3c-3.5,1.4-7.1,2.8-10.6,4.2c-3.2,1.3-6.4,2.5-9.6,3.8c-1.7,0.7-3.5,1.4-5.2,2.1c-0.1,0-0.1,0-0.2,0.1
	c-0.2,0-0.3,0.1-0.5,0.2c-0.5,0.3-0.9,0.9-0.9,1.5c0,1.3,0,2.7,0,4c0,3.2,0,6.4,0,9.7c0,3.9,0,7.8,0,11.7c0,1.6,0,3.3,0,4.9
	l-6.6,1.8c-0.1,0-0.3,0.1-0.4,0.2c-0.9,0.5-1.2,1.6-0.7,2.5l18.3,32.5c0.2,0.3,0.4,0.5,0.7,0.7c0.1,0,0.1,0.1,0.2,0.1
	c0.9,0.4,2-0.1,2.4-1l4.4-10.6c16.9,2.3,42.2,2.5,58.4-16.4c0.3-0.4,0.5-0.9,0.4-1.4C99.2,68.9,99.2,68.2,99.2,67.4z M56.6,20
	c1-0.4,2-0.8,3-1.2c0.3-0.1,0.7-0.3,1.1-0.4c1.1,0.4,2.1,0.8,3.2,1.3c2.9,1.2,5.9,2.3,8.8,3.5c3.5,1.4,7.1,2.8,10.6,4.2
	c3.1,1.2,6.1,2.4,9.2,3.6c0.2,0.1,0.3,0.1,0.5,0.2c0,0,0,0-0.1,0C90,32.4,87,33.7,84.1,35c-1.4,0.6-2.7,1.2-4.1,1.8
	c-1.1-0.4-2.2-0.9-3.3-1.3c-2.9-1.2-5.9-2.4-8.8-3.6c-3.5-1.5-7.1-2.9-10.6-4.4c-3.1-1.3-6.1-2.5-9.2-3.8c-0.2-0.1-0.3-0.1-0.5-0.2
	C50.6,22.3,53.6,21.1,56.6,20z M36.7,27.8c2.1-0.8,4.2-1.6,6.2-2.5c1.1,0.5,2.2,0.9,3.3,1.4c2.9,1.2,5.9,2.4,8.8,3.6
	c3.5,1.5,7.1,2.9,10.6,4.4c3.1,1.3,6.1,2.5,9.2,3.8c0.2,0.1,0.5,0.2,0.7,0.3c-0.7,0.3-1.4,0.6-2.1,0.9c-3.1,1.4-6.1,2.7-9.2,4
	c0,0,0,0,0,0c-1.2,0.5-2.5,1-3.7,1.6c-0.9-0.4-1.8-0.8-2.8-1.2c-2.9-1.3-5.9-2.6-8.8-3.9c-3.5-1.6-7.1-3.1-10.6-4.7
	c-3.1-1.4-6.1-2.7-9.2-4c-0.3-0.1-0.6-0.3-0.9-0.4C31.1,30,33.9,28.9,36.7,27.8z M25.6,57.8c0-3.9,0-7.8,0-11.7c0-3.4,0-6.7,0-10.1
	c0-0.8,0-1.6,0-2.3c0,0,0,0,0.1,0c0.3,0.1,0.7,0.3,1,0.4c3,1.3,5.9,2.6,8.9,3.9c3.5,1.6,7.1,3.1,10.6,4.7c3.1,1.3,6.1,2.7,9.2,4
	c0.4,0.2,0.7,0.3,1.1,0.5c0.8,0.4,1.7,0.8,2.5,1.1c0,1,0,1.9,0,2.9c0,3.2,0,6.4,0,9.7c0,2.6,0,5.2,0,7.8c-2.4-0.6-4.5-1.2-6.2-1.7
	c-1.9-0.6-2.4-0.9-3.3-1.2l4.2-10.1c0.1-0.4,0.2-0.8,0.1-1.2c-0.3-1-1.3-1.6-2.2-1.3l-25.9,7.1C25.6,59.5,25.6,58.6,25.6,57.8z
	 M39.5,83.6c-0.8-0.1-1.6,0.3-2,1.1L34,93.2L18.8,66l30-8.3l-3.5,8.4c-0.4,0.9,0,1.9,0.9,2.4c0.6,0.3,2.3,1,5.3,2
	c3,1,7.1,2.1,11.9,3c7.9,1.4,17.7,1.9,27.7-0.1C75.9,86.3,54.3,85.8,39.5,83.6z M64.1,69.8c-0.6-0.1-1.1-0.2-1.7-0.3
	c0-2.4,0-4.8,0-7.2c0-3.4,0-6.7,0-10.1c0-1.3,0-2.6,0-3.9c0.6-0.3,1.3-0.6,1.9-0.8c2.8-1.2,5.5-2.4,8.3-3.7c1.8-0.8,3.7-1.6,5.5-2.4
	c0,2.4,0,4.8,0,7.1c0,0.5,0,0.9,0,1.4c0,0.9,0.8,1.8,1.7,1.7c0.9,0,1.7-0.8,1.7-1.7c0-3.3,0-6.6,0-9.9c0-0.1,0-0.1,0-0.2
	c0.5-0.2,1.1-0.5,1.6-0.7c3.2-1.4,6.4-2.8,9.6-4.2c0.3-0.2,0.7-0.3,1-0.5c0.6-0.3,1.2-0.5,1.8-0.8c0,0.5,0,0.9,0,1.4
	c0,3.2,0,6.4,0,9.7c0,3.9,0,7.8,0,11.7c0,3.4,0,6.7,0,10.1c0,0.7,0,1.3,0,2C84.5,72,73.1,71.4,64.1,69.8z' />
		</svg>

	)
}
