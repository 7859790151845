import React from 'react'

export const FolderIconArrow = (props) => {
    const color = props.color ? props.color : '#D0CFD0'

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 28 28" className="folder-icon-svg">
            <style type='text/css'>
                {`
          .folder-icon-svg {
            fill:${color};
          }
        `}
            </style>
            <g>
                <g id="svg_1">
                    <g id="svg_2">
                        <g id="svg_3">
                            <path id="svg_4" d="m12.3,10l-2.1,0l2.1,-2.1l0,2.1z" clipRule="evenodd" fillRule="evenodd" />
                        </g>
                    </g>
                    <path id="svg_5" clipRule="evenodd" fillRule="evenodd" d="m20,11c0.6,0 1,0.4 1,1l0,7c0,0.6 -0.4,1 -1,1l-12,0c-0.6,0 -1,-0.4 -1,-1l0,-7c0,-0.6 0.4,-1 1,-1l12,0zm-9.9,-4c0.7,0 1.4,0.2 2.1,0.5s1.3,0.5 2,0.5l4.8,0c0.6,0 1,0.4 1,1l0,1l-12,0l0,-2c0,-0.6 0.4,-1 1,-1l1.1,0z" />
                </g>
                <path transform="rotate(16.3094 14.5134 15.6879)" stroke="null" id="svg_7" d="m13.30009,18.82058c-0.28595,-0.17422 -0.24844,-0.27973 0.4468,-1.25636c0.35106,-0.49315 0.63829,-0.91097 0.63829,-0.92849c0,-0.01751 -0.9694,-0.03185 -2.15424,-0.03185l-2.15424,0l0,-0.92362l0,-0.92362l2.17131,0c1.6742,0 2.15262,-0.02782 2.08968,-0.12153c-0.04489,-0.06684 -0.35114,-0.51039 -0.68055,-0.98566c-0.62094,-0.89589 -0.62074,-1.13615 0.00096,-1.21062c0.3094,-0.03706 5.12635,2.81045 5.29191,3.12829c-1.60448,1.13485 -3.52239,2.32274 -5.28909,3.37013c-0.09315,0 -0.25554,-0.0525 -0.36086,-0.11667l0,0z" fill="#fff" />
            </g>
        </svg>
    )
}
