import React from 'react'

export const DeliveryIcon = ({ color, ...rest }) => {
  const useColor = color ? color : '#3C3C3B'
  return (
    <svg width="28px" height="18px" viewBox="5 10 20 10"  {...rest}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={useColor}>
          <g transform="translate(5.000000, 8.500000)">
            <path d="M3.78947368,7.10526316 C4.78607774,7.10526316 5.60297053,7.8746986 5.67850997,8.85192743 L5.68421053,9 L5.68421053,9 C5.68421053,10.0464343 4.83590795,10.8947368 3.78947368,10.8947368 C2.74303942,10.8947368 1.89473684,10.0464343 1.89473684,9 C1.89473684,7.95356574 2.74303942,7.10526316 3.78947368,7.10526316 Z M13.2631579,7.10526316 C14.259762,7.10526316 15.0766547,7.8746986 15.1521942,8.85192743 L15.1578947,9 L15.1578947,9 C15.1578947,10.0464343 14.3095922,10.8947368 13.2631579,10.8947368 C12.2167236,10.8947368 11.3684211,10.0464343 11.3684211,9 C11.3684211,7.95356574 12.2167236,7.10526316 13.2631579,7.10526316 Z M11.3903552,0.473684211 C12.5363998,0.473684211 13.5823131,1.126614 14.0855977,2.15623669 L15.1578947,4.34994886 L17.0734693,5.56850157 C17.6024388,5.90499451 17.940174,6.46787211 17.9927898,7.08614033 L18,7.25600379 L18,8 C18,8.51283584 17.6139598,8.93550716 17.1166211,8.99327227 L17,9 L16.1052632,9 C16.1052632,7.43034861 14.8328093,6.15789474 13.2631579,6.15789474 C11.7516417,6.15789474 10.5157162,7.33783824 10.4262395,8.82686687 L10.4210526,9 L6.63157895,9 C6.63157895,7.43034861 5.35912508,6.15789474 3.78947368,6.15789474 C2.27795753,6.15789474 1.04203194,7.33783824 0.952555309,8.82686687 L0.947368421,9 L0,9 L0,2.47368421 C-1.3527075e-16,1.36911471 0.8954305,0.473684211 2,0.473684211 L11.3903552,0.473684211 Z M11.0487147,1.42105263 L10.4210526,1.42105263 L10.4210526,4.26315789 L13.6585442,4.26315789 L12.8570524,2.56670216 C12.52658,1.86721663 11.8223374,1.42105263 11.0487147,1.42105263 Z" />
          </g>
        </g>
      </g>
    </svg>
  )
}
