import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { StyledDescriptionBlock, StyledTitle } from './ProductDetailStyles'

const StyledDescription = styled.div`
  margin-bottom: 15px;
`

const StyledWashLabelImage = styled.img`
  max-width: 100%;
`

const WashDescriptionBlock = ({ article, withTitle }) => {
  const { t } = useTranslation()
  const washLabelImage = article?.washLabel?.url || false
  const washLabelDescription = article?.washLabel?.description || ''
  const showWashDescription = washLabelDescription || washLabelImage

  if (!showWashDescription) {
    return null
  }

  return (
    <StyledDescriptionBlock>
      {withTitle ? <StyledTitle className="h1">{t('productDetail:materialAndCare')}</StyledTitle> : null}
      <StyledDescription>
        {washLabelDescription}
      </StyledDescription>
      <StyledWashLabelImage src={washLabelImage} alt='' />
    </StyledDescriptionBlock>
  )
}
export default WashDescriptionBlock
