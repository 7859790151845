import React, { useMemo, useState } from 'react'
import { getLanguageValue } from '../../../utils/getLanguageValue';
import { Alert, Button, Modal, Table } from 'react-bootstrap';
import { APIEndpoints } from '../../../utils/api/apiConfig';
import apiClient from '../../../utils/api/apiClient';
import { getErrorMessageFromApiResponse } from '../../../utils/api/apiUtils';
import LoadingOverlay from '../../Loading/LoadingOverlay';
import { t } from 'i18next';
import { ButtonWithLoadingState } from '../../Buttons';

const MoveArticlesModal = ({ setOpenMoveOrCopyModal, getStructureArticles, getCatalogArticles, fromArticle, toCatalogOrDimension }) => {
  const [loading, setLoading] = useState(false)
  const [loadGroupArticles, setLoadGroupArticles] = useState(false)
  const [error, setError] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const articles = fromArticle.selectedArticles
  const currentArticle = articles[currentIndex]
  const toDimension = toCatalogOrDimension.type === "dimension"
  let title = useMemo(() => `${t('contingentTemplates:addArticles')} ( ${currentIndex + 1} / ${articles.length}) `, [currentIndex, articles.length])

  const getArticles = async () => {
    return toDimension
      ? await getStructureArticles(toCatalogOrDimension.catalogId, toCatalogOrDimension.id)
      : await getCatalogArticles(toCatalogOrDimension.id)
  }
  const postArticle = async (addArticleId) => {
    let params = { addArticleId }
    return toDimension
      ? await apiClient.postJson(APIEndpoints.catalogs(toCatalogOrDimension.catalogId, toCatalogOrDimension.id).structureArticles, params)
      : await apiClient.postJson(APIEndpoints.catalogs(toCatalogOrDimension.id).catalogArticles, params)
  }
  const leaveModal = async () => {
    setLoadGroupArticles(true)
    await getArticles()
    setLoadGroupArticles(false)
    setOpenMoveOrCopyModal(null)
  }
  const processNextArticle = () => {
    setError(null);
    (currentIndex < articles.length - 1)
      ? setCurrentIndex(prev => prev + 1)
      : leaveModal()
  }
  const handleSave = async () => {
    setError(null)
    setLoading(true)
    try {
      await postArticle(currentArticle.article.id)
      processNextArticle()
    }
    catch (e) {
      setError(getErrorMessageFromApiResponse(e))
    }
    finally { setLoading(false) }
  }

  const handleSaveAll = async () => {
    setError(null)
    setLoading(true)
    for (let i = currentIndex; i < articles.length; i++) {
      try {
        await postArticle(articles[i].article.id)
      }
      catch (e) {
        setError(getErrorMessageFromApiResponse(e))
        setCurrentIndex(i)
        setLoading(false)
        return;
      }
    }
    leaveModal()
  }

  const handleClose = () => {
    leaveModal()
  }
  return (
    <Modal style={{ textAlign: "center" }} show={true} onHide={handleClose} backdrop={"static"}  >
      <Modal.Header closeButton >
        <Modal.Title >{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body  >
        <LoadingOverlay isLoading={loadGroupArticles}>
          <Table striped bordered   >
            <tbody>
              <tr>
                <td>
                  <b>{t('articles')}:</b>
                </td>
                <td>
                  <div> {currentArticle.article.displayName}</div>
                  <div>  {currentArticle.article.articleNumber}</div>
                </td>
              </tr>
              {toDimension
                ? <tr><td><b>{t('catalogsAdministration:category')}:</b></td><td>  {getLanguageValue(toCatalogOrDimension.name.values)} </td></tr>
                : <tr><td><b>{t('catalogsAdministration:catalog')}:</b></td><td> {toCatalogOrDimension.displayName} </td></tr>}
            </tbody>
          </Table>
        </LoadingOverlay>
        {error ? <Alert variant="danger">{error}</Alert> : null}
      </Modal.Body >
      <Modal.Footer >
        <ButtonWithLoadingState
          disabled={loading || loadGroupArticles}
          onClick={processNextArticle}
          buttonText={t('contingentTemplates:skip')}
        />
        <ButtonWithLoadingState
          disabled={loading || loadGroupArticles}
          onClick={handleSave}
          isLoading={loading}
          buttonText={t('contingentTemplates:assign')}
        />
        <ButtonWithLoadingState
          disabled={loading || loadGroupArticles}
          onClick={handleSaveAll}
          isLoading={loading}
          buttonText={t('contingentTemplates:assignAll')}
        />
        {<Button variant="secondary" onClick={handleClose}>
          {t('appointment:cancel')}
        </Button>}
      </Modal.Footer>
    </Modal >
  )
}

export default MoveArticlesModal