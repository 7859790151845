import styled from 'styled-components'
import { ArrowLeftLink } from '../ArrowLeftLink/ArrowLeftLink'

export const GoBackBtn = styled(ArrowLeftLink)`
  background: #555555;
  color: white;
  padding: 0 16px 0 0;
  font-size: 14px;
  height: 36px;
  font-weight: 600;
  width: fit-content;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -36px;
  display: flex;
  align-items: center;

  &:before{
    padding-left: 6px !important;
    margin-right: 0;
    width: 2rem;
  }

  &:hover {
    color: white;
  }
`
