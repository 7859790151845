let mandatorConfig = {}
try {
  mandatorConfig = require(`./mandators/${process.env.REACT_APP_MANDATORSET}/config.js`)
} catch (e) {
  console.log(`mandatorset: "${process.env.REACT_APP_MANDATORSET}" not found in /src/config/mandators/`)
  mandatorConfig = { config: {} }
}

export const config = { themes: mandatorConfig.config }

