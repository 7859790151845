import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { useTranslation } from 'react-i18next'
import useStores from '../../stores/useStore'
import { useObserver } from 'mobx-react'

const StyledContainer = styled.div`
  display: flex;
  flex-grow:1;
  padding: 8px 1rem;
  max-height:90px;
  overflow:auto;
  @media screen and (max-width: 768px) {
    padding: 8px 0px;
    width:100%;
  }
`
const StyledItemWrapper = styled.div`
  min-width: 230px;
  margin-top: 5px;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 1.4;
  max-width: 460px;
`

const StyledLabel = styled.div`
  text-transform: uppercase;
  font-weight: bold;
`

const StyledValue = styled.div`
  color: ${colors.secondary}
`
const LaundryShelfData = () => {
  const { t } = useTranslation()
  const { laundryShelfStore } = useStores()
  const selectedItems = useObserver(() => laundryShelfStore.selectedItems)

  return (
    <StyledContainer   >
      {selectedItems.length ? <StyledWrapper>
        <StyledItemWrapper>
          <StyledLabel>{t('laundryShelves:laundryShelf', { count: selectedItems.length })}</StyledLabel>
          {selectedItems.map(item => <StyledValue key={item.id}>{item.displayName}</StyledValue>)}
        </StyledItemWrapper>
      </StyledWrapper> : null}
    </StyledContainer>
  )
}

export default LaundryShelfData
