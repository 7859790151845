import React, { useEffect, useMemo, useState } from 'react'
import { Form, Row, Modal, Col, Button, Alert } from 'react-bootstrap'
import SuggestionInput from '../Forms/SuggestionInput'
import { useTranslation } from 'react-i18next'
import useStores from '../../stores/useStore'

const ScanModal = ({ handleClose, actionType, article, articleVariant, index }) => {
  const [rfid, setRfid] = useState('')
  const [batchKey, setBatchKey] = useState('')
  const [serialKey, setSerialKey] = useState('')
  const [error, setError,] = useState('')
  const [instances, setInstances] = useState([])
  const [loading, setLoading] = useState(false)
  const [moreInputsNeeded, setMoreInputsNeeded] = useState(false)
  const { inventoryStore } = useStores()
  const { t } = useTranslation()

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      let newInstances = await inventoryStore.getInstances(article ? article.article.id : null, articleVariant)
      setInstances(newInstances)
      setLoading(false)
    }
    init()
  }, [article, articleVariant, inventoryStore])

  const filterSuggestions = (key, search) => {
    return instances
      .filter(instance => instance[key] && instance[key].includes(search) && ((rfid && key !== "rfid") ? instance.rfid === rfid : true) && ((serialKey && key !== "serialKey") ? instance.serialKey === serialKey : true) && ((batchKey && key !== "batchKey") ? instance.batchKey === batchKey : true))
      .map((instance, index) => ({ id: `${instance[key]}_${index}`, displayName: instance[key] }))
  }

  const selectedInstance = useMemo(() => {
    const foundInstance = instances.filter((inst => (rfid || batchKey || serialKey) && (rfid ? inst.rfid === rfid : true) && (serialKey ? inst.serialKey === serialKey : true) && (batchKey ? inst.batchKey === batchKey : true)))
    if (foundInstance.length === 1) {
      setMoreInputsNeeded(false)
      return foundInstance[0]
    }
    else if (foundInstance.length > 1) {
      setMoreInputsNeeded(true)
      return null
    }
    else {
      setMoreInputsNeeded(false)
      return null
    }

  }, [rfid, batchKey, serialKey, instances])


  const onSubmit = () => {

    const getNewAmount = (position) => {
      if (actionType === 'inventoryBookings') {
        return position.negativeAmount ? position.rows.length + 1 : 1
      }
      else {
        return (position.rows && position.rows.length) ? position.rows.length + 1 : 1
      }
    }

    const getIsMaxedOut = (actionType, position) => {
      if (actionType === 'inventoryBookings') {
        return (position.rows && position.rows.length) ? position.negativeAmount && (position.rows.length === position.currentQuantity) : !position.currentQuantity
      }
      return (position.rows && position.rows.length) ? position.rows.length === position.returnableQuantity : !position.returnableQuantity
    }

    //check if selected instance (rfid and batchkey and serialKey) is already selected
    if (!inventoryStore.instanceAlreadyExists(selectedInstance)) {
      //check if ScanModal prop article is set
      if (!article) {
        let foundArticles = inventoryStore.getArticlesByArticleId(selectedInstance.article.id)
        //check if there are more Articles with the same article id in the contingent
        if (foundArticles.length === 1) {
          let foundPosition = foundArticles[0].positions.find(position => position.articleVariant && position.articleVariant.id === selectedInstance.variant.id) || foundArticles[0].positions[0]
          if (foundPosition) {
            //check if the more articles are selected than returnableQuantity 
            let isMaxAmount = getIsMaxedOut(actionType, foundPosition)
            if (!isMaxAmount) {
              let newAmount = getNewAmount(foundPosition)
              inventoryStore.updateRows(foundArticles[0], foundPosition.articleVariant, newAmount, actionType === 'inventoryBookings')
              inventoryStore.updateInstances(foundArticles[0], selectedInstance.variant, selectedInstance, (newAmount - 1));
              handleClose()
            }
            else { setError(t('inventory:maxAmountAlert')) }
          }
          else { setError(t('inventory:notInInventoryAlert')) }
        }
        else { setError(t('inventory:multipleResultIdsAlert')) }
      }
      else {
        inventoryStore.updateInstances(article, articleVariant, selectedInstance, index);
        handleClose()
      }
    }
    else { setError(t('inventory:alreadySelectedAlert')) }
  }

  const handleChange = (key, value) => {
    setError('')
    switch (key) {
      case "rfid":
        setRfid(value)
        break;
      case "batchKey":
        setBatchKey(value)
        break;
      case "serialKey":
        setSerialKey(value)
        break;
      default:
        break;
    }
  }

  return (
    <Modal size="lg" show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('inventory:addArticle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-end">
          <Form.Group className="mb-3" as={Col}>
            <Form.Label>RFID</Form.Label>
            <SuggestionInput
              placeholder="RFID"
              value={rfid}
              onChange={(e) => handleChange("rfid", e.target.value)}
              suggestions={filterSuggestions("rfid", rfid)}
              onChangeSuggestion={(obj) =>
                handleChange("rfid", obj.displayName)
              }
              loading={loading}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-3" as={Col}>
            <Form.Label>{t('inventory:batchNumber')}</Form.Label>
            <SuggestionInput
              placeholder={t('inventory:batchNumber')}
              value={batchKey}
              onChange={(e) => handleChange("batchKey", e.target.value)}
              suggestions={filterSuggestions("batchKey", batchKey)}
              onChangeSuggestion={(obj) =>
                handleChange("batchKey", obj.displayName)
              }
              loading={loading}
            />
          </Form.Group>
          <Form.Group className="mb-3" as={Col}>
            <Form.Label>{t('inventory:serialNumber')}</Form.Label>
            <SuggestionInput
              placeholder={t('inventory:serialNumber')}
              value={serialKey}
              onChange={(e) => handleChange("serialKey", e.target.value)}
              suggestions={filterSuggestions("serialKey", serialKey)}
              onChangeSuggestion={(obj) =>
                handleChange("serialKey", obj.displayName)
              }
              loading={loading}
            />
          </Form.Group>
        </Row>
        {error && <Alert variant="danger">{error}</Alert>}
        {moreInputsNeeded && (
          <Alert variant="info">
            {t('inventory:multipleResultsAlert')}
          </Alert>
        )}
        {selectedInstance && (
          <Alert variant="success">
            <h2>{t('inventory:positionFound')}:</h2>
            <ul>
              {selectedInstance.rfid ? (
                <li>RFID: {selectedInstance.rfid}</li>
              ) : null}
              {selectedInstance.batchKey ? (
                <li>{t('inventory:batchNumber')}: {selectedInstance.batchKey}</li>
              ) : null}
              {selectedInstance.serialKey ? (
                <li>{t('inventory:serialNumber')}: {selectedInstance.serialKey}</li>
              ) : null}
              {selectedInstance.article ? (
                <li>
                  {t('productDetail:articleDescription')}: {selectedInstance.article.displayName}
                </li>
              ) : null}
            </ul>
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={!selectedInstance}
          onClick={() => onSubmit()}
        >
          {t("okButton")}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {t("closeButton")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ScanModal