import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/colors'

import Card from 'react-bootstrap/Card'
import { CategoryIconDefault, CategoryIcons } from '../Icons/CategoryIcons/CategoryIcons'
import { useTranslation, Trans } from 'react-i18next'
import { useObserver } from 'mobx-react'

const StyledProductGroupCard = styled(Card)`
  margin: 20px -15px;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 0;
  
  @media (min-width: 768px) {
    margin: 20px 0;
    border-left-width: 1px;
    border-right-width: 1px;
    border-radius: 0.25rem;
    flex-direction: row-reverse;
    justify-content: center;
  }
`

const StyledCardImgWrap = styled.div`
  height: 130px;
  position: relative;
  overflow: hidden;
  background-color: ${colors.gray1};
  text-align: right;
  @media (min-width: 400px) {
    height: 135px;
  }

  @media (min-width: 768px) {
    width: 66%;
  }

  &.placeholder-img {
    text-align: right;

    svg {
      position: static;
      height: 115%;
      width: auto;
      transform: rotate(-30deg) scale(1.6);
      margin-top: 20px;
      opacity: 0.3;

      &.headgear{
        margin-top: 0;
      }

      &.itEquip{
        transform: rotate(0) scale(1.6);
        margin-top: 0;
      }

      &.keys{
        transform: rotate(-60deg) scale(1.6);
      }

      &.footwear{
        transform: rotate(10deg) scale(1.6);
        margin-top: -5px;
      }

      &.consumables{
          transform: scale(1.6);
          margin-top: -5px;
      }

      &.tools{
        transform: rotate(-80deg) scale(1.6);
        margin-top: 30px;
      }

      &.keys{
        transform: rotate(-80deg) scale(1.4);
        margin-top: -10px;
      }

      &.advertising{
          transform:rotate(0) scale(1.20);
          margin-top: -40px;
      }
    }
  }

  img {
    position: static;
    height: 115%;
    width: auto;
  }
`

const StyledCardBody = styled(Card.Body)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  color: ${colors.gray4};

  @media (min-width: 768px) {
    padding: 15px;
    flex-direction: column-reverse;
  }
`

const StyledCardTitle = styled(Card.Title)`
  font-size: 24px;
  margin-bottom: 0;

  @media (min-width: 768px) {
    align-self: flex-start;
  }
`

const StyledCardCounterTitle = styled(Card.Text)`
  margin-bottom: 0;
`

const StyledCardCounterWrap = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${colors.gray4};
  font-size: 14px;

  @media (min-width: 768px) {
    align-self: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }
`

const StyledCardCounter = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;

    > span:first-child {
      margin-right: 10px;
    }
  }
`

const StyledCounter = styled.span`
  color: ${colors.gray4};

  .text-danger & {
    color: currentColor;
  }
`
const StyledInactiveLayer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  text-transform: uppercase;
  text-align: center;


  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    opacity: 0.7;
  }
`
const StyledInactiveText = styled.div`
  position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const ProductGroupCard = (props) => {
  const { t } = useTranslation()
  const {
    iconType,
    max,
    image,
    color,
    categoryName,
    amountToOrder,
    current,
    hideMaxedOut,
    hideCounter
  } = props
  const CategoryIcon = CategoryIcons[iconType] ? CategoryIcons[iconType] : CategoryIconDefault
  const isGroupAmountMaxedOut = max ? (current + amountToOrder) >= max : false
  return useObserver(() =>
    <React.Fragment>
      <StyledProductGroupCard className='card'>
        <StyledCardImgWrap className={!image ? 'placeholder-img' : ''}>
          {image ?
            <Card.Img variant='top' src={image} />
            :
            <CategoryIcon color={color} className={iconType} />
          }
        </StyledCardImgWrap>
        <StyledCardBody>
          <StyledCardTitle>{categoryName}</StyledCardTitle>
          {!hideCounter ? <StyledCardCounterWrap>
            <StyledCardCounterTitle>{t('productsOverview:referenceAmount')}</StyledCardCounterTitle>
            <StyledCardCounter className={isGroupAmountMaxedOut ? 'text-danger' : ''}>
              <Card.Text><span>{t('currentAmount')}</span><StyledCounter>{current}{amountToOrder ? <span
                className='text-primary'>(+{amountToOrder})</span> : ''}</StyledCounter></Card.Text>
              {max ? <Card.Text><span>{t('maxAmount')}</span><StyledCounter>{max}</StyledCounter></Card.Text> : ''}
            </StyledCardCounter>
          </StyledCardCounterWrap> : null}
          {(!hideMaxedOut && isGroupAmountMaxedOut) ? <StyledInactiveLayer>
            <StyledInactiveText className='text-danger'>
              <Trans i18nKey='productsOverview:maxAmountReachedGroup' />
            </StyledInactiveText>
          </StyledInactiveLayer> : null}
        </StyledCardBody>
      </StyledProductGroupCard>
    </React.Fragment>
  )

}
export default ProductGroupCard