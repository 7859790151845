import React, { useEffect, useRef } from 'react'
import { Spinner } from 'react-bootstrap'
import { isElementInViewport } from '../../utils/isElementInViewport'
import { debounce } from 'throttle-debounce'

/* 
listRef only if the scroll happens inside a div or modal. 
*/

const LazyLoad = ({ fetchData, listRef }) => {
  const lazyLoadDiv = useRef()

  const trigerLazyLoadDebounced = debounce(500, () => {
    const lazyLoadElement = lazyLoadDiv.current
    if (isElementInViewport(lazyLoadElement)) {
      fetchData()
    }
  })

  useEffect(() => {
    let listRefCurrent = listRef?.current
    trigerLazyLoadDebounced()
    const triggerLazyLoadEvent = event => trigerLazyLoadDebounced()
    !!listRefCurrent ? listRefCurrent.addEventListener('scroll', triggerLazyLoadEvent) : window.addEventListener('scroll', triggerLazyLoadEvent)
    return () => {
      !!listRefCurrent ? listRefCurrent.removeEventListener('scroll', triggerLazyLoadEvent) : window.removeEventListener('scroll', triggerLazyLoadEvent)
    }
  }, [listRef, trigerLazyLoadDebounced])


  return (
    <div className='d-flex justify-content-center p-3' ref={lazyLoadDiv}>
      <Spinner animation='border' variant='primary' />
    </div>
  )
}
export default LazyLoad