import { useObserver } from 'mobx-react'
import React from 'react'
import { Navigate } from 'react-router-dom'
import useStores from '../../stores/useStore'
import i18n from '../../translation/i18n'



export const navigationSideLeft = () => ([
  {

    menuName: i18n.t("administration:corporateStructure"),
    subMenu: [
      {
        key: 'officeadministration',
        to: '/officeadministration',
        name: i18n.t("administration:officeadministration"),
        accessRights: ['allowImportOffices']
      },
      {
        key: 'costcenteradministration',
        to: '/costcenteradministration',
        name: i18n.t("administration:costcenteradministration"),
        accessRights: ['allowImportCostcenters']
      },
    ]
  },
  {

    menuName: i18n.t("administration:employeeAccounts"),
    subMenu: [
      {
        key: 'activitiesAdministration',
        to: '/activitiesAdministration',
        name: i18n.t("activities:activities"),
        accessRights: ['allowEntitlementAdministration']
      },
      {
        key: 'entitlementPhasesAdministration',
        to: '/entitlementPhasesAdministration',
        name: i18n.t("entitlementPhases:entitlementPhases"),
        accessRights: ['allowEntitlementPhaseAdministration']
      },
      {
        key: 'contingentTemplatesAdministration',
        to: '/contingentTemplatesAdministration',
        name: i18n.t("contingentTemplates:contingentTemplates"),
        accessRights: ['allowEntitlementAdministration']
      },
      {
        key: 'stdPointRunAdministration',
        to: '/stdPointRunAdministration',
        name: i18n.t("stdPointRunAdmin:stdPointRun"),
        accessRights: ['showStdPointRun']
      }
    ]
  },
  {
    menuName: i18n.t("administration:catalogManagment"),
    to: '/catalogsAdministration',
    subMenu: [],
    accessRights: ['allowImportCatalogs']

  },
  {
    menuName: i18n.t("placeholder:placeholder"),
    to: '/placeholdersAdministration',
    subMenu: [],
    accessRights: ['allowTextPlaceholderAdministration']

  },
  {
    menuName: i18n.t("administration:appointmentManagement"),
    subMenu: [
      {
        key: 'appointmentTypes',
        to: '/appointmenttypes',
        name: i18n.t("administration:appointmentTypes"),
        accessRights: ['fittingAdministration']
      },
      {
        key: 'cabintypes',
        to: '/cabintypes',
        name: i18n.t("administration:cabins"),
        accessRights: ['fittingAdministration']
      },
    ]
  },
  {
    menuName: i18n.t("dashboard:news"),
    to: '/newsAdministration',
    subMenu: [],
    accessRights: ['allowNewsAdministration']
  }
])


const AdministrationPage = () => {
  const { uiStore } = useStores()

  let redirectTo = useObserver(() =>
    uiStore.allowImportOffices
      ? '/officeadministration'
      : uiStore.allowImportCostcenters
        ? '/costcenteradministration'
        : uiStore.fittingAdministration
          ? '/appointmentTypes'
          : uiStore.fittingAdministration
            ? '/cabintypes'
            : uiStore.allowEntitlementAdministration
              ? '/contingentTemplatesAdministration'
              : uiStore.allowEntitlementAdministration
                ? '/activitiesAdministration'
                : uiStore.allowEntitlementPhaseAdministration
                  ? '/entitlementPhasesAdministration'
                  : uiStore.allowImportCatalogs
                    ? '/catalogsAdministration' : '/')

  return (
    <Navigate to={redirectTo} replace />
  )
}

export default AdministrationPage
