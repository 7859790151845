import React, { useState } from 'react'
import useCabinTypesState from '../../../stores/Configuration/useCabinTypesState'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ModalComponent2 from '../../Modals/ModalComponent2'
import { useObserver } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import OverlayRightCabinTypeInfoInfo from "./OverlayRightCabinTypeInfo";
import { Spinner } from 'react-bootstrap'
import CabinTypeItem from './CabinTypeItem'

const StyledWrapper = styled.div`
    @media screen and (min-width: 1080px) {
    max-width: calc(100% - 480px);
    min-width: 600px;
    }

`

const CabinTypesOverview = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const {
        cabinTypes, blockCabinItem, deleteCabin, checkDeletableCabin, idCabinType, setIdCabinType, btnLoading, error
    } = useCabinTypesState()
    const [deletable, setDeletable] = useState(false)
    const [selectedCabinTypeId, setSelectedCabinTypeId] = useState('')
    const [showCabinTypeInfoOverlay, setShowCabinTypeInfoOverlay] = useState(false);

    const openCabinTypeInfo = (cabinType) => {
        setSelectedCabinTypeId(cabinType.id)
        setShowCabinTypeInfoOverlay(true)
    }

    const deleteProcess = async (id) => {
        const isDeletable = await checkDeletableCabin(id)
        setIdCabinType(id)
        setDeletable(isDeletable)
    }

    const editRow = (id) => {
        let path = `/cabinTypes/edit/${id}#general`;
        navigate(path);
    }

    return useObserver(() => (
        <>
            <StyledWrapper>
                {
                    !cabinTypes.length &&
                    <div className='d-flex justify-content-center' >
                        <Spinner animation='border' variant='primary' />
                    </div>
                }
                {cabinTypes.map((cabinType) => {
                    return (
                        <CabinTypeItem
                            key={cabinType.id}
                            editRow={editRow}
                            cabinType={cabinType}
                            deleteProcess={deleteProcess}
                            blockCabinItem={blockCabinItem}
                            openCabinTypeInfo={openCabinTypeInfo}
                            selectedCabinTypeId={selectedCabinTypeId}
                        />
                    )
                })}
            </StyledWrapper>
            {
                showCabinTypeInfoOverlay &&
                <OverlayRightCabinTypeInfoInfo
                    visible={showCabinTypeInfoOverlay}
                    onClose={() => setShowCabinTypeInfoOverlay(false)}
                    selectedCabinTypeId={selectedCabinTypeId}
                />
            }

            {
                idCabinType && (!deletable) && (<ModalComponent2
                    ModalTitle={t('cabinTypes:deleteCabinTypes')}
                    BodyPart1={t('cabinTypes:areYouSurePlus')}
                    bodyStyle="alert alert-danger"
                    functionCancel={() => { setIdCabinType(null) }}
                    button1Text={t('delete')}
                    button1Function={(cancellationMessage) => { deleteCabin(idCabinType, cancellationMessage) }}
                    button1Variant="danger"
                    button1Loading={btnLoading}
                    inputType="textarea"
                    inputLabel={t('cancelMessage')}
                    inputIsMandatory
                    error={error.message}
                />)
            }

            {
                idCabinType && (deletable) && (<ModalComponent2
                    ModalTitle={t('cabinTypes:deleteCabinTypes')}
                    BodyPart1={t('cabinTypes:areYouSure')}
                    bodyStyle="alert alert-danger"
                    functionCancel={() => { setIdCabinType(null) }}
                    button1Text={t('delete')}
                    button1Function={() => { deleteCabin(idCabinType) }}
                    button1Variant="danger"
                    button1Loading={btnLoading}
                    error={error.message}
                />)
            }
        </>
    ))
}

export default CabinTypesOverview
