import React, { useState } from 'react'

import { Subheader } from '../components/Page/logix2020/Subheader';
import { ContentArea } from '../components/Page/logix2020/ContentArea';
import CustomCKEditor from '../components/Configuration/News/CKEditor';

const Testpage = () => {
  const [value, setValue] = useState('')

  return (
    <>
      <Subheader className="subHeader-v1">
        <div className="subheader-title">WYSIWYG</div>
      </Subheader>
      <ContentArea  >
        <CustomCKEditor editorValue={value} setEditorValue={setValue} />
      </ContentArea >
    </>
  )
}

export default Testpage