import { emptyGuid } from '../utils/emptyGuid'
import { observable, makeObservable } from 'mobx';
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import axios from 'axios'
const CancelToken = axios.CancelToken;
var cancel;

class CostCenterStore {
    constructor(appStore) {
        makeObservable(this, {
            loadedCostCenterIds: observable,
            partialCostCenters: observable,
            costCentersLoading: observable,
            allLoaded: observable,
            allCostCentersLoading: observable,
            getFilteredCostCentersChache: observable
        });

        this.app = appStore
    }
    loadedCostCenterIds = [];
    partialCostCenters = [];
    costCentersLoading = false;
    allLoaded = false;
    allCostCentersLoading = false;
    getFilteredCostCentersChache = {
        lastParams: {},
        lastResult: {}
    };

    resetPartialCostCenters() {
        this.loadedCostCenterIds = []
        this.partialCostCenters = []
        this.allLoaded = false
    }

    async loadPartialCostCenters(costCenterParentId = emptyGuid, includeDeleted = false) {
        this.loadAllCostCenters(includeDeleted)
        if (this.loadedCostCenterIds.includes(costCenterParentId)) {
            return this.partialCostCenters
        }
        else if (this.costCentersLoading) {
            await this.app.uiStore.waitUntilLoaded(100, 'app.costCenterStore.costCentersLoading')
            return this.loadPartialCostCenters(costCenterParentId)
        }
        else {
            this.costCentersLoading = true
            let _newLoadedCostCenters = await this.loadTree(costCenterParentId, includeDeleted)
            if (!this.allLoaded) {
                this.partialCostCenters = [...this.partialCostCenters, ..._newLoadedCostCenters]
            }
            this.costCentersLoading = false
            return this.partialCostCenters
        }
    }

    async loadTree(costCenterParentId = emptyGuid, includeDeleted = false) {
        this.loadedCostCenterIds.push(costCenterParentId)

        const params = {
            'filters.costCenterParentId': costCenterParentId,
            'filters.includeDeleted': includeDeleted
        }
        return await apiClient.getJson(APIEndpoints.partialCostCenters, params)
    }

    async loadAllCostCenters(includeDeleted) {
        if (this.allLoaded) {
            return this.partialCostCenters
        }
        else if (this.allCostCentersLoading) {
            await this.app.uiStore.waitUntilLoaded(100, 'app.costCenterStore.allCostCentersLoading')
            return this.loadAllCostCenters(includeDeleted)
        }
        else {
            this.allCostCentersLoading = true
            const params = {
                'filters.includeDeleted': includeDeleted
            }
            let allCostCenters = await apiClient.getJson(APIEndpoints.partialCostCenters, params)
            this.resetPartialCostCenters()
            this.loadedCostCenterIds.push(emptyGuid)
            allCostCenters.forEach(costCenter => {
                this.loadedCostCenterIds.push(costCenter.id)
            });
            this.partialCostCenters = allCostCenters
            this.allLoaded = true
            this.allCostCentersLoading = false
        }
    }

    async loadPartialCostCenter(costCenterId) {
        return await apiClient.getJson(APIEndpoints.partialCostCenter(costCenterId))
    }

    cancelGetFilteredCostCenters() {
        //cancel previous calls that are still pending
        cancel && cancel()
    }

    async getFilteredCostCenters(costCenterName, officeId, includeDeleted = false) {
        const params = {
            'filters.costCenterName': costCenterName,
            'filters.includeDeleted': includeDeleted
        }
        if (officeId) {
            params['filters.officeId'] = officeId
            params['filters.includeParentOffice'] = true
        }
        if (JSON.stringify(params) === this.getFilteredCostCentersChache.lastParams) {
            return this.getFilteredCostCentersChache.lastResult
        }
        else {
            this.cancelGetFilteredCostCenters()
            const cancelToken = new CancelToken(function executor(c) {
                cancel = c;
            })
            let newResult = await apiClient.getJson(APIEndpoints.partialCostCenters, params, true, false, true, { cancelToken })
            this.getFilteredCostCentersChache = {
                lastParams: JSON.stringify(params),
                lastResult: newResult
            }
            return newResult
        }

    }
}
export default CostCenterStore



