import React, { memo, useCallback, useEffect, useState } from 'react'
import { FixedSizeList as List } from 'react-window';
import CatalogItem from './CatalogItem'
import { emptyGuid } from '../../../utils/emptyGuid';

const BigDataList = memo(({ bigData, ...rest }) => {
  const [bigDataArray, setBigDataArray] = useState([])

  const getDimensionChildren = useCallback((parentId, dimensions, level) => {
    let result = []
    let newLevel = ++level
    let levelDimensions = dimensions.filter(d => d.parentId === parentId)
    for (let levelD of levelDimensions) {
      levelD.level = newLevel
      if (levelD.open) {
        let articles = Object.values(levelD.articles).map(a => { a.level = newLevel + 1; return a })
        result.push(levelD, ...getDimensionChildren(levelD.id, dimensions, newLevel), ...articles)
      }
      else {
        result.push(levelD)
      }
    }
    return result
  }, [])

  const flattenBigData = useCallback((obj) => {
    let templatesArr = Object.values(obj).map((catalog) => {
      let dimensionList = []
      let visibleArticles = []
      if (catalog.open) {
        let dimensions = Object.values(catalog.dimensions)
        dimensionList = getDimensionChildren(emptyGuid, dimensions, 0)
        visibleArticles = Object.values(catalog.articles)
      }
      let preparedTemplate = { ...catalog, type: "catalog" }
      return ([preparedTemplate, ...dimensionList.flat(), ...visibleArticles])
    })
    return templatesArr.flat()
  }, [getDimensionChildren])

  useEffect(() => {
    if (bigData) {
      setBigDataArray(flattenBigData(bigData))
    }
  }, [bigData, flattenBigData])

  return (
    <List
      height={800}
      itemCount={bigDataArray.length}
      itemSize={32}
      width={650}
    >
      {({ index, style }) =>
        <div style={style}>
          <CatalogItem key={bigDataArray[index].id} index={index} arrayItem={bigDataArray[index]} {...rest} />
        </div>
      }
    </List>
  )
})

export default BigDataList