import styled from 'styled-components'

const ScrollWrapper = styled.div`
overflow-y: scroll;

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 1px grey;
}

::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9e9d9d;
}

// scrollbar styling for firefox
scrollbar-width: thin;
scrollbar-color: #dbdbdb #f1f1f1;
`
export default ScrollWrapper