import React from 'react'
import { StyledItemWrapper, StyledListItemText } from '../../TreeList/treeList'
import { Trans } from 'react-i18next'
import { PenIcon } from '../../Icons/PenIcon'
import { DeleteIcon } from '../../Icons/DeleteIcon'
import { t } from 'i18next'
import MediaQuery from 'react-responsive'
import MoreOptionsButton from '../../Buttons/MoreOptionsButton'
import PortalContextMenu from '../../ContextMenu/PortalContextMenu'
import { ContextMenuTrigger } from 'react-contextmenu'
import { StyledContextMenu } from '../../ContextMenu/StyledContextMenu'

const AppointmentTypesItem = ({ editRow, deleteProcess, appointmentType, selectedAppointmentType, openAppointmentTypeInfo }) => {

  const options = [
    {
      option: 'edit',
      optionText: t('edit'),
      optionIcon: <PenIcon />,
      onOptionSelect: () => editRow(appointmentType.id)
    },
    {
      option: 'delete',
      optionText: t('deleteButton'),
      optionIcon: <DeleteIcon />,
      onOptionSelect: () => deleteProcess(appointmentType.id)
    },

  ]

  return (
    <StyledContextMenu>
      <ContextMenuTrigger id={`${appointmentType.id}_context`} holdToDisplay={-1}>
        <StyledItemWrapper
          isClickable
          style={{
            boxShadow: appointmentType.id === selectedAppointmentType.id ? `inset 0px 0px 0px 2px` : null,
          }}
          key={appointmentType.id}
          onClick={() => openAppointmentTypeInfo(appointmentType)}>
          <StyledListItemText>
            <span>{appointmentType.displayName}</span>
            <div className="subText">
              <Trans
                i18nKey="appointmentTypes:timePerAppointment"
                values={{ minutes: appointmentType.durationInMinutes }}
              />
            </div>
          </StyledListItemText>
          <MediaQuery maxWidth={1499}>
            <div className="action-buttons"  >
              <MoreOptionsButton options={options} />
            </div>
          </MediaQuery>
        </StyledItemWrapper>
      </ContextMenuTrigger>
      <PortalContextMenu contextId={`${appointmentType.id}_context`} options={options} />
    </StyledContextMenu>
  )
}

export default AppointmentTypesItem