import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { getErrorMessageFromApiResponse } from '../../../utils/api/apiUtils'
import QrModal from '../../Modals/QrModal'
import { Trans } from 'react-i18next'
import MultipleConfigOptions from './MultipleConfigOptions'
import { playScanErrorSound, playScanOkSound } from '../../../utils/playSounds'
import { t } from 'i18next'


const ArticleScan = ({ handleClose, reloadArticles, employeeId }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [currentArticle, setCurrentArticle] = useState({ articleId: '', allConfigs: [], article: null, articleVariant: null })
  const [multipleConfigOptions, setMultipleConfigOptions] = useState([])

  const handleMultipleConfigMatches = (configMatches, article) => {
    let preparedConfigs = configMatches.map(articleConfiguration => (
      {
        articleConfiguration,
        article
      }
    ))
    setMultipleConfigOptions(preparedConfigs)
  }

  const handleSingleConfigMatch = async (articleConfiguration, article) => {
    let params = getConfigParams(articleConfiguration.id, article.id)
    await saveConfiguration(params);
    setSuccess({
      config:
      {
        articleConfigurationDisplayname: articleConfiguration.displayName,
        articleDisplayname: article.displayName
      }
    });
    reloadArticles()
  }

  const handleResult = async (result) => {
    playScanOkSound()
    setSuccess(null)
    setError(null)
    setLoading(true)
    try {
      let scanResponse = await apiClient.getJson(APIEndpoints.articleScan(result))
      let { isBTOArticle, article, articleVariant } = scanResponse

      if (isBTOArticle) {
        let configMatches = currentArticle.allConfigs.filter(config => config.selectableArticles.find(a => a.id === article.id))
        if (!configMatches.length) {
          setError(t('sizeDetection:errorConfigNoMatch'))
          playScanErrorSound()
        }
        else if (configMatches.length > 1) {
          handleMultipleConfigMatches(configMatches, article)
        }
        else {
          await handleSingleConfigMatch(configMatches[0], article)
        }
      }
      else {
        let variantId = articleVariant.id
        await saveVariant(article.id, variantId)
        reloadArticles()
        let allConfigs = await loadConfigurations(article.id)
        setCurrentArticle({
          allConfigs,
          article,
          articleVariant
        })
        setSuccess({ variant: true })
      }
    }
    catch (e) {
      setError(getErrorMessageFromApiResponse(e))
      playScanErrorSound()
    }
    finally {
      setLoading(false)
    }
  }

  const saveVariant = async (articleId, variantId) => {
    const params = [{ articleId, variantId }]
    return await apiClient.patchJson(APIEndpoints.businessPartner(employeeId).articleConfigurations, params)
  }

  const getConfigParams = (articleConfigurationId, articleId) => {
    let configurations = currentArticle.allConfigs.map(pc =>
    (
      {
        articleConfigurationId: pc.id,
        articleId: pc.lastSelectedArticleID,
        comment: pc.lastComment
      }
    ))
    let updateConfig = configurations.find(c => c.articleConfigurationId === articleConfigurationId)
    updateConfig.articleId = articleId
    return [{ articleId: currentArticle.article.id, configurations }]
  }

  const saveConfiguration = async (params) => {
    return await apiClient.patchJson(APIEndpoints.businessPartner(employeeId).articleConfigurations, params)
  }

  const loadConfigurations = async (articleId) => {
    return await apiClient.getJson(APIEndpoints.businessPartner(employeeId, articleId).articleConfigurationsConfigurations)
  }
  //auto remove success message after couple sec.
  useEffect(() => {
    let timeout = null
    if (success || error) {
      timeout = setTimeout(() => {
        setSuccess(null)
        setError(null)
      }, 5000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [success, error])

  return (
    <div>
      <QrModal
        title="Artikel Scan"
        handleClose={handleClose}
        qrModalError={error}
        handleResult={(r) => handleResult(r)}
        isLoading={loading || multipleConfigOptions.length}
      >
        {success?.variant && <Alert variant="success"><Trans
          i18nKey="sizeDetection:successVariant"
          values={{ articleName: currentArticle.article.displayName, variantName: currentArticle.articleVariant.displayName }}
        /></Alert>}
        {success?.config &&
          <Alert variant="success"><Trans
            i18nKey="sizeDetection:successConfig"
            values={{ articleConfigurationDisplayname: success.config.articleConfigurationDisplayname, articleDisplayname: success.config.articleDisplayname }}
          /></Alert>}
        {multipleConfigOptions.length ?
          <MultipleConfigOptions
            multipleConfigOptions={multipleConfigOptions}
            setMultipleConfigOptions={setMultipleConfigOptions}
            setError={setError}
            handleSingleConfigMatch={handleSingleConfigMatch}
          />
          : null}
      </QrModal>
    </div>
  )
}

export default ArticleScan