import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import MediaQuery from 'react-responsive'
import { inject, observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import { ArrowBigIcon } from '../Icons/ArrowBigIcon'
import { colors } from '../../styles/colors'
import { formatedPriceObject } from '../../utils/formatedPrice'
import { CategoryIconDefault, CategoryIcons } from '../Icons/CategoryIcons/CategoryIcons'
import ExpandedArticle from './ExpandedArticle'

const StyledTr = styled.tr`
  border-top: 1px solid #e0e0e0;
  &:hover {
    background-color: rgba(0,0,0,0.075);
    cursor: pointer;
  }

  td {
    vertical-align: middle;
  }
`

const StyledListRow = styled.div`
  padding: 6px;
  border-top: 1px solid #e0e0e0;
  &:hover {
    background-color: rgba(0,0,0,0.075);
    cursor: pointer;
  }
`

const StyledArticleDescription = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.textColorB80};

  svg {
    margin-top: -2px;
  }
`

const StyledProductImgWrap = styled.div`
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
  background: white;
  width: ${props => props.smallImage ? "50px" : "150px"};
  height:  ${props => props.smallImage ? "50px" : "150px"};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;

  svg {
    width: 100%;
    height: 100%;
    opacity: 0.3;
  }
`

const StyledProductImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const StyledName = styled.div`
  margin-left: 1rem;
  flex-basis: 50%;
`


const ProductListItem = inject('productListStore', 'productDetailsStore')(observer((props) => {
  const { t, theme, smallImage, catalogOnly, to, columns, shoppingBasketArticle, iconType, productListStore, expandedOnDefault, productDetailsStore, articleId, shouldHidePricesAndPoints } = props
  const navigate = useNavigate();
  const [expandArticle, setExpandArticle] = useState(expandedOnDefault)

  const currentProductObj = productListStore.productObj[shoppingBasketArticle.id]
  const CategoryIcon = iconType && CategoryIcons[iconType] ? CategoryIcons[iconType] : CategoryIconDefault
  const articlePrice = expandArticle && currentProductObj && currentProductObj.price ? currentProductObj.price : formatedPriceObject(shoppingBasketArticle.pricePerItem || {})
  const hasAddToBasketErrors = currentProductObj && currentProductObj.addToBasketErrors && currentProductObj.addToBasketErrors.length ? true : false
  const amountToOrder = currentProductObj && currentProductObj.articleAvailability ? currentProductObj.articleAvailability.amountToOrder : shoppingBasketArticle.articleAvailability.amountToOrder

  const withArticleColumn = columns.includes('article')
  const withPriceColumn = columns.includes('price')
  const withMaxColumn = columns.includes('max')
  const withCurrentColumn = columns.includes('current')
  const withAmountToOrderColumn = columns.includes('amountToOrder')

  useEffect(() => {
    if (hasAddToBasketErrors) {
      setExpandArticle(true)
    }
  }, [hasAddToBasketErrors])

  useEffect(() => {
    if (productDetailsStore.scrollRef === articleId) {
      let element = document.querySelector(`[data-id="${articleId}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      productDetailsStore.setScrollRef(null)
    }
  }, [articleId, productDetailsStore])

  const handleClickRow = () => {
    if (catalogOnly) { navigate(to) }
    else { setExpandArticle(prev => !prev) }
  }

  const ArticleDescription = () => (
    <StyledArticleDescription data-id={articleId}>
      {!catalogOnly ? <ArrowBigIcon variant={expandArticle ? 'down' : 'right'} /> : null}
      <StyledProductImgWrap smallImage={smallImage}>
        {shoppingBasketArticle.article.image && shoppingBasketArticle.article.image.url
          ? <StyledProductImg src={shoppingBasketArticle.article.image.url} alt='' />
          : <CategoryIcon color={theme.primaryColor} className={iconType} />
        }
      </StyledProductImgWrap>
      <StyledName>
        <div>{shoppingBasketArticle.article.displayName}</div>
        <small className='text-uppercase'>{t('inventory:articleNr')}: {shoppingBasketArticle.article.articleNumber}</small>
      </StyledName>
    </StyledArticleDescription>
  )
  return (
    <>
      <MediaQuery minWidth={993}>
        <StyledTr onClick={handleClickRow}>
          {withArticleColumn
            ? <td ><ArticleDescription /></td> : null}
          {withPriceColumn
            ? (!shouldHidePricesAndPoints ? <td>{articlePrice}</td> : <td></td>) : null}
          {withMaxColumn
            ? <td >{shoppingBasketArticle.articleAvailability.maxAmount || 0}</td> : null}
          {withCurrentColumn
            ? <td >{shoppingBasketArticle.articleAvailability.currentAmount || 0}</td> : null}
          {withAmountToOrderColumn
            ? <td>{amountToOrder > 0 ? `+${amountToOrder}` : amountToOrder}</td> : null}
        </StyledTr>
        {expandArticle && <tr>
          <td colSpan="5" className="ps-5">
            <ExpandedArticle shoppingBasketArticle={shoppingBasketArticle} />
          </td></tr>}
      </MediaQuery>

      <MediaQuery maxWidth={992}>
        <StyledListRow onClick={() => setExpandArticle(prev => !prev)}>
          <ArticleDescription />
        </StyledListRow>
        {expandArticle &&
          <ExpandedArticle shoppingBasketArticle={shoppingBasketArticle} />
        }
      </MediaQuery>
    </>
  )
}))

export default withTheme(withTranslation()(ProductListItem))