import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Spinner } from 'react-bootstrap'

const SSOLogin = inject('loginStore')(observer((props) => {

  const { loginStore, children } = props
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let url = window.location
    let queryString = url.search
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code')
    if (code) {
      (async () => {
        await loginStore.loginWithSSOCode(code)
        setLoading(false)
      })()
    } else {
      setLoading(false)
    }
  }, [loginStore])

  if (loading) {
    return <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
      <Spinner animation='border' variant='primary' />
    </div>
  }

  return <>{children}</>
}))



export default SSOLogin
