import i18n from '../../../translation/i18n'

export function supplierOrderStatusToString(status) {
    switch (status) {
        case "none":
            return i18n.t('order:statusOrdered')
        case "ArrivalsExisted":
            return i18n.t('order:statusInProcess')
        case "PartialDelivered":
            return i18n.t('order:statusPartialDelivered')
        case "CompletelyDelivered":
            return i18n.t('order:statusCompletelyDelivered')
        case "LocalStockRemoval":
            return i18n.t('order:statusInStock')
        default:
            return ""
    }
}