import React, { useEffect, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import { CategoryIconDefault, CategoryIcons } from '../../Icons/CategoryIcons/CategoryIcons'
import ArticleAmounts from './ArticleAmounts'


const StyledArticleCard = styled.div`
  ${props => props.autoScrollHere ? `border:3px dashed ${props.theme.primaryColor}` : ''};
  padding: 0 45px 0 20px;
  `
const StyledIconWrapper = styled.div`
margin-right:1rem;
width:30px;
height:30px;
`
const Article = ({ entitlementId, groupId, iconType, article, autoScrollTo }) => {
  const CategoryIcon = iconType && CategoryIcons[iconType] ? CategoryIcons[iconType] : CategoryIconDefault
  const theme = useTheme()
  const autoScrollHere = useMemo(() => autoScrollTo && (article.id === autoScrollTo.articleId), [article.id, autoScrollTo])
  useEffect(() => {
    if (autoScrollHere) {
      let element = document.querySelector(`[data-id="${`${groupId}_${article.id}`}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [article.id, autoScrollHere, groupId])
  return (
    <StyledArticleCard autoScrollHere={autoScrollHere} data-id={`${groupId}_${article.id}`}>
      <div className="d-flex align-items-center p-3">
        <StyledIconWrapper>
          < CategoryIcon color={theme.primaryColor} />
        </StyledIconWrapper>
        <h4 style={{ margin: "0px" }}>{article.article.displayName}</h4>
      </div>

      <ArticleAmounts key={article.id} entitlementId={entitlementId} groupId={groupId} article={article} />
    </StyledArticleCard>

  )
}

export default Article