import React, { useEffect } from 'react'
import ArraySelect from '../../../Select/ArraySelect'
import { inject, observer } from 'mobx-react'
import { Form } from 'react-bootstrap'
import { emptyGuid } from '../../../../utils/emptyGuid'
import { useTranslation } from 'react-i18next'

const LaundryShelfSelect = inject('laundryShelfStore', 'uiStore')(observer((props) => {
    const { status, laundryShelfStore, uiStore } = props
    const { t } = useTranslation()

    useEffect(() => {
        laundryShelfStore.getListItems()
    }, [laundryShelfStore])

    const handleChange = (obj) => {
        laundryShelfStore.selectedItems = [obj]
    }

    if (status === "hide") {
        return null
    }

    return (
        <Form.Group className="mb-3" >
            <Form.Label>{t('laundryShelves:laundryShelf')} {uiStore.laundryShelf_IsMandatory ? "*" : ""}</Form.Label>
            <ArraySelect
                disabled={status === "read"}
                noAutoSelect
                options={laundryShelfStore.listItems}
                value={laundryShelfStore.selectedItems.length ? laundryShelfStore.selectedItems[0].id : null}
                onChange={handleChange}
                defaultOption={{ value: emptyGuid, name: t("pleaseSelect") }}
                defaultOptionIsSelectable={!uiStore.laundryShelf_IsMandatory}
                isInvalid={laundryShelfStore.submitBPLoundryShelvesError}
            />
            <Form.Control.Feedback type="invalid">
                {t('mandatoryField')}
            </Form.Control.Feedback>
        </Form.Group>
    )
}))

export default LaundryShelfSelect
