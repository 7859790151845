import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import ProductItem from './ProductItem'
import ProductItemSimple from './ProductItemSimple'
import { cloneDeep } from 'lodash';

const StyledProductListWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    margin-bottom:2rem;
`

/* 

sortedPhases=[
  {
    phase:{id,displayName},
    articles:[]
  },...
]

*/

const ArticlesList = ({ articles, variant }) => {
  const [sortedArticles, setSortedArticles] = useState([])

  const sortArticles = useCallback(() => {
    let result = [{
      phase: { id: null, displayName: null },
      articles: []
    }]
    for (const article of articles) {
      for (const position of article.positions) {
        let newArticle = cloneDeep(article)
        newArticle.positions = [position]
        if (position.phase) {
          let foundIndex = result.findIndex(group => group.phase.id === position.phase.id)
          if (foundIndex >= 0) {
            result[foundIndex].articles.push(newArticle)
          }
          else {
            result.push({
              phase: position.phase,
              articles: [newArticle]
            })
          }
        }
        else {
          result[0].articles.push(newArticle)
        }
      }
    }
    setSortedArticles(result)
  }, [articles])

  useEffect(() => {
    sortArticles()
  }, [sortArticles])


  return <>
    {sortedArticles.map((group, index) => (
      <StyledProductListWrapper key={index}>
        {group.phase.displayName ? <h1>{group.phase.displayName}</h1> : null}
        {group.articles.map((article) =>
          article.positions.map((position) => {
            let deletable = position.metaProperties && position.metaProperties.find(metaProp => (metaProp.pointer === '/id' && metaProp.attributes === 'isDeletable'))
            let readOnly = position.metaProperties && position.metaProperties.find(metaProp => (metaProp.pointer === '/id' && metaProp.attributes === 'isReadOnly'))

            return (variant === 'simple' || readOnly) ?
              <ProductItemSimple
                key={position.id}
                article={article}
                position={position}
              />
              :
              <ProductItem
                key={position.id}
                article={article}
                position={position}
                deletable={deletable}
              />
          })
        )
        }
      </StyledProductListWrapper>))
    }
  </>

}

export default ArticlesList