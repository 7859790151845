import React from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'

import { PlusIcon } from '../Icons/PlusIcon'
import { MinusIcon } from '../Icons/MinusIcon'
import { colors } from '../../styles/colors'

const StyledAmount = styled(Form.Control)`
  text-align: center;
  font-weight: 600;
  min-width: 60px;
  max-width: 120px;
  font-size: 26px;
  border: unset;
  border-radius: unset;
  border-bottom: 1px solid ${colors.gray3};
  color: ${colors.gray5}
`

const StyledWrapper = styled.div`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  display: flex;
  align-items: center;
  ${PlusIcon},${MinusIcon} {
    opacity: 0.5;
  }

  &:hover {
    ${PlusIcon},${MinusIcon} {
      opacity: 1;
    }

    &.disabled-minus ${MinusIcon} {
      opacity: 0.15;
      cursor: default;
    }

    &.disabled-plus ${PlusIcon} {
      opacity: 0.15;
      cursor: default;
    }
  }
`

const PlusMinusAmount = (props) => {
  const {
    placeholder,
    name = "",
    value,
    onChange,
    onChangePlus,
    onChangeMinus,
    disableMinus,
    disablePlus,
    amountStyle,
    disableField = false
  } = props

  return (
    <>
      <StyledWrapper className={`${disableMinus ? 'disabled-minus' : ''} ${disablePlus ? 'disabled-plus' : ''}`}>
        <div><MinusIcon onClick={disableMinus ? () => { } : onChangeMinus} /></div>
        <StyledAmount
          type="number"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
          style={amountStyle}
          disabled={disableField}
        />
        <div><PlusIcon onClick={disablePlus ? () => { } : onChangePlus} /></div>
      </StyledWrapper>
    </>
  )
}

export default PlusMinusAmount
