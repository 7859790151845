// UI colors that are not relevant for themes

export const colors = {
  gray1: '#f2f2f2', // backgrounds
  gray2: '#ddd',
  gray3: '#9c9c9c',
  gray4: '#606060',
  gray5: '#303030',
  gray6: '#eee',
  gray7: '#3a3a38',
  gray8: 'rgba(0, 0, 0, 0.68)',
  sidebarActiveGray: '#e7e7e7',

  magenta1: "#f378c4",
  magenta2: "#eb0045",
  black: "#000000",

  green: '#198754',
  red: '#dc3545',
  yellow: '#ffc107',
  borderColor: '#9c9c9c',
  borderColorLight: '#C6C6C6',

  textColorB80: '#575756',
  textColor: '#3C3C3B',
  textColorSecondary: '#c6c6c6',
  secondary: '#706F6F',
  navActiveBackground: '#393938'
}
