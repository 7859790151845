import React from 'react'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import SubheaderTitle from '../../components/Page/logix2020/SubheaderTitle'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import CabinTypesGeneralForm from '../../components/Configuration/CabinType/CabinTypesGeneralForm'
import CabinTypesProvider from '../../stores/Configuration/CabinTypesProvider'
import SidebarNavigation from '../../components/Page/logix2020/SidebarNavigation'
import { t } from 'i18next'
const navigationLeft = () => [
    {
        key: 'general',
        to: '#general',
        name: t("costCenterAdmin:general"),
        disabled: false
    },
    {
        key: 'assignedAppointmentTypes',
        to: '#assignedAppointmentTypes',
        name: t("cabinTypes:appointmentTypeAssign"),
        disabled: true
    }
]

const CreateNewCabinTypePage = () => {

    return (
        <div>
            <Subheader className="subHeader-v1">
                <SubheaderTitle
                    withBackButton
                    smallTitle={t('cabinTypes:smallTitle')}
                    // goBackLabel={t('costCenterAdmin:goBackCost')}
                    goBackLabel={t('cabinTypes:goBackLabel')}
                    defaultBackTo="/cabintypes"
                />
            </Subheader>
            <FlexWrapper>
                <SidebarLeft>
                    <SidebarNavigation options={navigationLeft()} active={"general"} />
                </SidebarLeft>
                <ContentArea>
                    <CabinTypesProvider>
                        <CabinTypesGeneralForm />
                    </CabinTypesProvider>
                </ContentArea>
            </FlexWrapper>
            <BigFooter />
        </div >
    )
}

export default CreateNewCabinTypePage