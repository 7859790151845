import React, { useEffect, useState } from 'react'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import SubheaderTitle from '../../components/Page/logix2020/SubheaderTitle'
import { useLocation } from 'react-router-dom'
import CostGeneralForm from '../../components/Configuration/CostCenter/CostGeneralForm'
import CostCenterSidebarNavigation from '../../components/Configuration/CostCenter/CostCenterSidebarNavigation'
import { useParams } from 'react-router-dom'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { useTranslation } from 'react-i18next'
import CostAccessRights from '../../components/Configuration/CostCenter/CostAccessRights'
import InterfaceCostCenterTree from '../../components/Configuration/CostCenter/InterfaceCostCenterTree'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'

const CopyCostPage = () => {
  const { t } = useTranslation()
  let currentLocation = useLocation()
  const [activeNav, setActiveNav] = useState('')
  const { costCenterId } = useParams()
  const [costCenterDetails, setCostCenterDetails] = useState("")

  useEffect(() => {
    setActiveNav(currentLocation.hash.substr(1))
  }, [currentLocation.hash])

  useEffect(() => {
    apiClient.getJson(APIEndpoints.costCenters(costCenterId).details)
      .then(response => {
        setCostCenterDetails(response)
      })
      .catch(error => console.log(error))
  }, [costCenterId])

  if (costCenterDetails === "") {
    return null
  }

  return (
    <div>
      <Subheader className="subHeader-v1">
        <SubheaderTitle
          withBackButton
          smallTitle={t('costCenterAdmin:editNewCost')}
          goBackLabel={t('costCenterAdmin:goBackCost')}
          defaultBackTo="/costcenteradministration"
        />
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <CostCenterSidebarNavigation active={activeNav} mode={false} />
        </SidebarLeft>
        <ContentArea>
          {activeNav === 'general' && <CostGeneralForm mode="copy" costCenterDetails={costCenterDetails} />}
          {activeNav === 'access-rights' && <CostAccessRights />}
          {activeNav === 'interfaces' && <InterfaceCostCenterTree />}
        </ContentArea>
      </FlexWrapper>
      <BigFooter />
    </div >
  )
}

export default CopyCostPage
