import React, { useCallback, useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useAppointmentState } from '../../../stores/AppointmentProvider'
import styled from 'styled-components'
import { colors } from '../../../styles/colors'
import { useEffect } from 'react'
import { emptyGuid } from '../../../utils/emptyGuid'
const StyledTimeSelect = styled.div`
box-shadow: rgb(0 0 0 / 30%) 0px 0px 15px 0px;
display: flex;
width: fit-content;
button {
  border: 1px solid ${colors.borderColorLight};
  background-color: #ededed;
  border-radius: 8px;
  padding: 0 12px;
  font-weight: bold;
  color: ${colors.textColor};

  &:hover {
    background-color: #a6e144;
  }

  &:focus {
  outline: none;
  }
 
  &.selected{
    background-color: #a6e144;
    box-shadow:0 0 0 4px #518600;}
}
ul {
  position:relative;
  width:72px;
  list-style: none;
  padding:0;
  max-height: 300px ;
  overflow-y: hidden;
  &:hover{
    overflow-y:auto;
  }

  li {
    padding: 6px;
    height: 38px;
  }
}

`

const TimeSelect = () => {
  const { t } = useTranslation()

  const [selectedHour, setSelectedHour] = useState(null)
  const [selectedMinute, setSelectedMinute] = useState(null)
  const [sortedTimeSlots, setSortedTimeSlots] = useState(null)
  const {
    setAppointment,
    appointment,
    timeSlots,
    loadingStates
  } = useAppointmentState()

  const initOldAppointment = useCallback(() => {
    if (sortedTimeSlots && appointment && appointment.timeSlot && appointment.timeSlot.id !== emptyGuid) {

      sortedTimeSlots.forEach((arr, index) => {
        if (arr.some(e => e.id === appointment.timeSlot.id)) {
          setSelectedHour(index)
          setSelectedMinute(arr.findIndex(e => e.id === appointment.timeSlot.id))
        }
      })
    }
  }, [appointment, sortedTimeSlots])

  useEffect(() => {
    resetStates()
    if (timeSlots.length) {
      let newSortedTimeSlots = sortArray(timeSlots)
      setSortedTimeSlots(newSortedTimeSlots)
    }
  }, [timeSlots])

  useEffect(() => {
    initOldAppointment()
  }, [initOldAppointment])

  const resetStates = () => {
    setSelectedMinute(null)
    setSelectedHour(null)
    setSortedTimeSlots(null)
  }

  const sortArray = array => {
    var result = []
    var index = 0
    array.forEach(obj => {
      if (!result[index]) {
        result.push([obj])
      }
      else if (obj.hour !== result[index][0].hour) {
        result.push([obj])
        index++
      }
      else { result[index].push(obj) }
    })
    return result
  }

  const scrolll = (dataId) => {
    const element = document.querySelector(`[data-id=${dataId}]`)
    element.parentNode.scrollTop = element.offsetTop;
  }

  const handleClickHour = (index) => {
    setSelectedMinute(null)
    setSelectedHour(index)
    scrolll(`hour-${index}`)
    setAppointment(prev => ({
      ...prev, timeSlot: {
        "hour": 0,
        "minute": 0,
        "id": emptyGuid
      }
    }))
  }

  const handleClickMinute = (index, obj) => {
    setSelectedMinute(index)
    scrolll(`minute-${index}`)
    setAppointment(prev => ({ ...prev, timeSlot: obj }))
  }


  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t('appointment:selectTime')}</Form.Label>
        {loadingStates.loadTimeSlots
          ? <div><Spinner animation='border' variant='primary' /></div>
          : <StyledTimeSelect>
            <div className="m-1">
              <div>{t('appointment:hour')}</div>
              <ul >
                {sortedTimeSlots && sortedTimeSlots.map((arr, index) => {
                  const Hour = arr[0].hour < 10 ? "0" + arr[0].hour : arr[0].hour
                  return <li key={index} data-id={`hour-${index}`}>
                    <button
                      onClick={() => { handleClickHour(index) }}
                      className={selectedHour === index ? 'selected' : undefined}
                    >
                      {Hour}
                    </button>
                  </li>
                })
                }
              </ul >
            </div >
            <div className="m-1">
              <div>{t('appointment:minute')}</div>
              <ul>
                {sortedTimeSlots && selectedHour != null && sortedTimeSlots[selectedHour].map((obj, index) => {
                  const Minute = obj.minute < 10 ? "0" + obj.minute : obj.minute
                  return <li key={obj.id} data-id={`minute-${index}`}>
                    <button
                      onClick={() => handleClickMinute(index, obj)}
                      className={selectedMinute === index ? 'selected' : undefined}
                    >
                      {Minute}
                    </button>
                  </li>
                })
                }
              </ul>
            </div>
          </StyledTimeSelect >}
      </Form.Group >
    </ >
  )
}

export default TimeSelect
