import { action, observable, makeObservable } from 'mobx';
import apiClient from '../utils/api/apiClient'
import { themes } from '../styles/themes.js'
import { APIEndpoints } from '../utils/api/apiConfig'
import { emptyGuid } from '../utils/emptyGuid'
import { setPointerErrorsFromApiResponse } from '../utils/api/apiUtils'

class ProfileStore {
  constructor(rootStore) {
    makeObservable(this, {
      profile: observable,
      profileIsLoading: observable,
      passwordNew: observable,
      loadProfile: action,
      handleOnSetPasswordSubmit: action,
      handleOnChangeOldPassword: action,
      handleChangeNewPasswordRepeat: action,
      handleChangeNewPassword: action,
      handleChangeSecurityQuestionAnswer: action,
      handleChangeSequrityQuestion: action,
      setOldPassword: action,
      changeProfile: action
    });

    this.app = rootStore
  }

  profile = {
    businessPartnerDisplayName: null,
    businessPartnerGender: null,
    id: null,
    languageCode: null,
    mandator: {},
    points: null,
    isPunchOutUser: false,
    changePassword: true,
    forcePasswordChange: false,
    securityQuestionId: '',
    securityQuestionAnswer: '',
    hasAnsweredSecurityQuestion: false
  };
  profileIsLoading = false;

  _passwordNew = {
    oldPassword: null,
    newPassword: null,
    newRepeatPassword: null,
    securityQuestionId: '',
    securityQuestionAnswer: '',
    isLoading: false,
    submitError: false,
    isSucceeded: false,
    formErrors: {}
  }
  passwordNew = this._passwordNew;

  changeProfile(profile) {
    this.profile = profile
  }
  async loadProfile(force = false) {
    if (this.profile.id && !force) {
      return this.profile
    }
    if (this.profileIsLoading) {
      await this.app.uiStore.waitUntilLoaded(100, 'app.profileStore.profileIsLoading')
      return this.profile
    } else {
      this.profileIsLoading = true
      let loadedProfile = await apiClient.getJson(APIEndpoints.profile)
      this.changeProfile(loadedProfile)
      this.profileIsLoading = false
      return this.profile
    }
  }

  frontendValidation() {
    let error = { response: { data: { errors: [] } } }
    if (this.passwordNew.newPassword !== this.passwordNew.newRepeatPassword) {
      error.response.data.errors.push(
        {
          detail: "Passwort stimmt nicht überein!!!",
          source: { pointer: "/newPwd" }
        }
      )
      throw error
    }
  }

  async handleOnSetPasswordSubmit(e) {
    e.preventDefault();

    this.passwordNew.isSucceeded = false
    this.passwordNew.submitError = false
    this.passwordNew.formErrors = {}

    this.passwordNew.isLoading = true
    const params = {
      oldPwd: this.passwordNew.oldPassword,
      newPwd: this.passwordNew.newPassword,
      securityQuestionId: this.passwordNew.securityQuestionId || emptyGuid,
      securityQuestionAnswer: this.passwordNew.securityQuestionAnswer
    }
    try {
      this.frontendValidation()
      await apiClient.postJson(APIEndpoints.changePassword, params)
      this.passwordNew.isSucceeded = true
      e.target.reset();
      this.loadProfile(true)
    }
    catch (err) {
      this.passwordNew.submitError = true
      this.passwordNew.formErrors = setPointerErrorsFromApiResponse(err)
    }
    finally {
      this.passwordNew.isLoading = false
    }

  }

  handleOnChangeOldPassword(e) {
    delete this.passwordNew.formErrors.oldPwd
    this.passwordNew.oldPassword = e.target.value;
  }

  handleChangeNewPasswordRepeat(e) {
    delete this.passwordNew.formErrors.newPwd
    this.passwordNew.newRepeatPassword = e.target.value;
  }

  handleChangeNewPassword(e) {
    delete this.passwordNew.formErrors.newPwd
    this.passwordNew.newPassword = e.target.value;
  }

  handleChangeSecurityQuestionAnswer(e) {
    delete this.passwordNew.formErrors.securityQuestionAnswer
    this.passwordNew.securityQuestionAnswer = e.target.value;
  }
  handleChangeSequrityQuestion(e) {
    delete this.passwordNew.formErrors.securityQuestionId
    this.passwordNew.securityQuestionId = e.target.value;
  }
  resetNewPasswordBox() {
    this.passwordNew = this._passwordNew
  }
  setOldPassword = (oldPassword) => {
    this.passwordNew.oldPassword = oldPassword
  };

  getThemeNameByProfileMandorId() {
    return Object.keys(themes).find(themeName => themes[themeName].ids.length ? themes[themeName].ids.find(themeId => themeId === this.profile.mandator.id) : themes[themeName].id === this.profile.mandator.id)
  }
}

export default ProfileStore
