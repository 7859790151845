import React from 'react'
import { useObserver } from 'mobx-react'
import { Form } from 'react-bootstrap'

import useStores from '../../stores/useStore'
import SearchField from '../Forms/SearchField'
import { useTranslation } from 'react-i18next'
import SearchResultAmount from '../Forms/SearchResultAmount'



const InventorySearchBox = () => {
    const { inventoryStore } = useStores()

    const handleChangeSearchText = e => {
        inventoryStore.searchText = e.target.value
    }

    const handleClearClick = e => {
        inventoryStore.searchText = ''
    }

    const countArticles = (contingents) => {
        let sum = 0
        for (let contingent of contingents) {
            for (let category of contingent.categories) {
                if (category.articles) sum += category.articles.length
            }
        }
        return sum
    }

    const articleAmount = useObserver(() => countArticles(inventoryStore.filteredContingents))
    const { t } = useTranslation()
    return useObserver(() => (
        <div >
            <Form.Group >
                <Form.Label>{t('ProductOverview:searchArticle')}</Form.Label>
                <SearchField
                    placeholder={t('ProductOverview:searchArticle')}
                    value={inventoryStore.searchText}
                    onChange={handleChangeSearchText}
                    onClearClick={handleClearClick} catalogArticlesTotalCount
                />
                {inventoryStore.searchText
                    ? <div className="mt-2" style={{ color: "gray" }}>
                        <SearchResultAmount count={inventoryStore.showAllArticles ? inventoryStore.catalogArticlesTotalCount : articleAmount} />
                    </div>
                    : <div className="mt-2">&nbsp; </div>}
            </Form.Group >
        </div>
    ))
}

export default InventorySearchBox
