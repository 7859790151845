import React, { useCallback, useState } from "react"
import apiClient from "../../utils/api/apiClient"
import { APIEndpoints } from "../../utils/api/apiConfig"
import { makeCall } from "../../utils/api/makeCall";
import { cloneDeep } from 'lodash';
import { emptyGuid } from "../../utils/emptyGuid";
import { t } from "i18next";

const PlaceholdersContext = React.createContext({})
export const usePlaceholdersState = () => {
  return React.useContext(PlaceholdersContext)
}

const PlaceholdersProvider = ({ children }) => {

  const [errorStates, setErrorStates] = useState({})
  const [loadingStates, setLoadingStates] = useState({})
  const [placeholderTypes, setPlaceholderTypes] = useState([])
  const [allPlaceholders, setAllPlaceholders] = useState([])
  const [languages, setLanguages] = useState([])
  const [isMainMandator, setIsMainMandator] = useState(false)
  const [assignmentPlaceholders, setAssignmentPlaceholders] = useState([])

  const getPlaceholderTypes = useCallback(async (force) => {
    if ((placeholderTypes.length || loadingStates.placeholderTypes) && !force) {
      return null
    }
    else {
      await makeCall('placeholderTypes', async () => {
        let loadedPlaceholderTypes = await apiClient.getJson(APIEndpoints.placeholder().types)
        let includeEmpty = [{
          "name": t('placeholder:withoutType'),
          "description": "",
          "metaProperties": [],
          "id": emptyGuid
        }, ...loadedPlaceholderTypes]
        setPlaceholderTypes(includeEmpty)
      }, setErrorStates, setLoadingStates)
    }
  }, [placeholderTypes, loadingStates.placeholderTypes])

  const getAllPlaceholders = useCallback(async (force) => {
    if ((allPlaceholders.length || loadingStates.allPlaceholders) && !force) {
      return null
    }
    else {
      await makeCall('allPlaceholders', async () => {
        let loadedPlaceholders = await apiClient.getJson(APIEndpoints.placeholder().placeholders)
        setAllPlaceholders(loadedPlaceholders)
      }, setErrorStates, setLoadingStates)
    }
  }, [allPlaceholders.length, loadingStates.allPlaceholders])

  const createType = async (params, setShowCreateTypeModal) => {
    await makeCall('createType', async () => {
      await apiClient.postJson(APIEndpoints.placeholder().types, params)
      await getPlaceholderTypes(true)
      setShowCreateTypeModal(false)
    }, setErrorStates, setLoadingStates)
  }
  const editType = async (params, setShowEditTypeModal) => {
    await makeCall('createType', async () => {
      await apiClient.patchJson(APIEndpoints.placeholder(params.id).type, params)
      await getPlaceholderTypes(true)
      setShowEditTypeModal(null)
    }, setErrorStates, setLoadingStates)
  }
  const checkPlaceholders = async (typeId) => {
    let result = true
    await makeCall('checkPlaceholders', async () => {
      result = await apiClient.getJson(APIEndpoints.placeholder(typeId).checkPlaceholders)
    }, setErrorStates, setLoadingStates)
    return result
  }
  const deleteType = async (showDeleteType, setShowDeleteType, deletePlaceholders = true) => {
    await makeCall('deleteType', async () => {
      let step1 = !showDeleteType.hasPlaceholders
      if (step1) {
        let hasPlaceholders = await checkPlaceholders(showDeleteType.id)
        console.log(hasPlaceholders)
        if (hasPlaceholders) {
          //here starts step2
          setShowDeleteType(prev => ({ ...prev, hasPlaceholders: true }))
          return
        }
        else {
          await apiClient.deleteJson(APIEndpoints.placeholder(showDeleteType.id).type)
          await getPlaceholderTypes(true)
          setShowDeleteType(null)
        }
      } else {
        await apiClient.deleteJson(APIEndpoints.placeholder(showDeleteType.id).type, { deletePlaceholders })
        await getPlaceholderTypes(true)
        setShowDeleteType(null)
      }
    }, setErrorStates, setLoadingStates)
  }
  const deletePlaceholder = async (phId, setShowDeletePlaceholder) => {
    await makeCall('deletePlaceholder', async () => {
      await apiClient.deleteJson(APIEndpoints.placeholder(phId).placeholder)
      await getAllPlaceholders(true)
      setShowDeletePlaceholder(null)
    }, setErrorStates, setLoadingStates)
  }
  const updatePlaceholder = (updatedPlaceholder) => {
    setAllPlaceholders(prev => {
      let foundIndex = prev.findIndex(p => p.id === updatedPlaceholder.id && p.typeId === updatedPlaceholder.typeId)
      prev[foundIndex] = updatedPlaceholder
      return cloneDeep(prev)
    })
  }
  const addPlaceholder = (newPlaceholder) => {
    setAllPlaceholders(prev => {
      prev.push(newPlaceholder)
      return cloneDeep(prev)
    })
  }
  const removePlaceholder = (removePlaceholder) => {
    setAllPlaceholders(prev => {
      let foundIndex = prev.findIndex(p => p.id === removePlaceholder.id && p.typeId === removePlaceholder.typeId)
      prev.splice(foundIndex, 1)
      return cloneDeep(prev)
    })
  }
  const loadLanguages = () => {
    makeCall('loadLanguages', async () => {
      let res = await apiClient.getJson(APIEndpoints.languages)
      let mappedLanguagesArray = res.map(language => {
        return { name: language.displayName, value: language.isoCode }
      })
      setLanguages(mappedLanguagesArray);
    }, setErrorStates, setLoadingStates)
  }

  const loadCurrentMandator = () => {
    makeCall('loadCurrentMandator', async () => {
      let res = await apiClient.getJson(APIEndpoints.currentMandator)
      let isMainMandator = res?.mandatorType === "isMainmandator"
      setIsMainMandator(isMainMandator)
    }, setErrorStates, setLoadingStates)
  }
  const [subMandators, setSubMandators] = useState([])

  const loadSubMandators = useCallback(async () =>
    await makeCall('loadSubMandators', async () => {
      let loadedSubMandators = await apiClient.getJson(APIEndpoints.subMandators)
      setSubMandators(loadedSubMandators)
    }, setErrorStates, setLoadingStates), [])

  const loadAssignmentPlaceholders = useCallback(async () =>
    await makeCall('loadAssignmentPlaceholders', async () => {
      let assignmentPlaceholders = await apiClient.getJson(APIEndpoints.placeholder().inheritance)
      setAssignmentPlaceholders(assignmentPlaceholders)
    }, setErrorStates, setLoadingStates), [])

  const contextValues = {
    getPlaceholderTypes,
    placeholderTypes,
    errorStates,
    setErrorStates,
    loadingStates,
    setLoadingStates,
    getAllPlaceholders,
    allPlaceholders,
    createType, editType,
    deleteType,
    deletePlaceholder,
    updatePlaceholder,
    addPlaceholder,
    removePlaceholder,
    loadLanguages,
    languages,
    loadCurrentMandator,
    isMainMandator,
    loadSubMandators,
    subMandators,
    assignmentPlaceholders, loadAssignmentPlaceholders
  }

  return (
    <PlaceholdersContext.Provider value={contextValues}>
      {children}
    </PlaceholdersContext.Provider>
  )
}

export default PlaceholdersProvider
