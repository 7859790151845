
import { FolderOpenIcon } from '../Icons/FolderOpenIcon'
import { colors } from '../../styles/colors'
import { FolderIcon } from '../Icons/FolderIcon'
import { FileIcon } from '../Icons/FileIcon'
import {
    ArrowIconNew,
    StyledDot,
    StyledIconWrapper,
    StyledItemWrapper,
    StyledListItemText,
    StyledArrowWrapper,
    StyledTreeSubList
} from '../TreeList/treeList'
import { FolderOpenIconArrow } from '../Icons/FolderOpenIconArrow'
import { FolderIconArrow } from '../Icons/FolderIconArrow'
import { useTheme } from 'styled-components'
import { StyledContextMenu } from '../ContextMenu/StyledContextMenu'
import { ContextMenuTrigger } from 'react-contextmenu'
import PortalContextMenu from '../ContextMenu/PortalContextMenu'

const OfficeItem = ({
    office,
    setOfficesActive,
    onClick = () => ({}), //get chosen Office obj, 
    partialOffices,
    isClickable = office => office.isAllowToOrder,
    officesActive,
    renderExtraItemContent = (office) => null,
    parentsWithSelectedChildren = [], //folderIcon with arrow
    selectedOfficeId,
    setSelectedOfficeId,
    getContextMenuOptions = () => []
}) => {
    const { theme } = useTheme()

    const handleClickFolder = id => (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOfficesActive({ ...officesActive, [id]: !officesActive[id] })
    }

    const handleClickOffice = (office) => {
        onClick(office)
        setSelectedOfficeId(office)
    }

    const getChildOffice = (parentOffice) => {    // function to find the child offices if there are any
        return partialOffices.filter(office => office.parentOffice && office.parentOffice.id === parentOffice.id)
    }

    return (
        <StyledContextMenu>
            <ContextMenuTrigger id={`${office.id}_context`} holdToDisplay={-1}>
                <StyledItemWrapper isClickable={isClickable(office)} isInvalid={!isClickable(office)} onClick={() => handleClickOffice(office)} style={office.id === selectedOfficeId ? { boxShadow: `inset 0px 0px 0px 2px  ${theme.primaryColor}` } : {}}>
                    {office.hasChildren &&
                        <div className="d-flex" onClick={handleClickFolder(office.id)} >
                            <StyledArrowWrapper >
                                <ArrowIconNew color={officesActive[office.id] ? '#9c9c9c' : '#3a3a38'}
                                    variant={officesActive[office.id] ? 'down' : 'right'} />
                            </StyledArrowWrapper>
                            <StyledIconWrapper>
                                {officesActive[office.id] ? (
                                    parentsWithSelectedChildren.includes(office.id) ?
                                        <FolderOpenIconArrow color={colors.gray7} />
                                        : <FolderOpenIcon color={colors.gray7} />
                                ) : (
                                    parentsWithSelectedChildren.includes(office.id) ?
                                        <FolderIconArrow color={colors.gray7} />
                                        : <FolderIcon color={colors.gray7} />
                                )}
                            </StyledIconWrapper>
                        </div>}
                    {!office.hasChildren &&
                        <div className="d-flex">
                            <StyledDot>•</StyledDot>
                            <StyledIconWrapper>
                                <FileIcon uniqueId={`costcenter-icon-${office.id}`}
                                    color={colors.gray5} />
                            </StyledIconWrapper>
                        </div>}
                    <StyledListItemText>{office.displayName}</StyledListItemText>
                    {renderExtraItemContent(office)}
                </StyledItemWrapper>

                {office.hasChildren && officesActive[office.id] &&    // if the office has child and its open
                    <StyledTreeSubList>
                        {getChildOffice(office).map(childOffice => (
                            <OfficeItem
                                key={childOffice.id}
                                office={childOffice}
                                setOfficesActive={setOfficesActive}
                                onClick={onClick}
                                partialOffices={partialOffices}
                                isClickable={isClickable}
                                officesActive={officesActive}
                                renderExtraItemContent={renderExtraItemContent}
                                parentsWithSelectedChildren={parentsWithSelectedChildren}
                                selectedOfficeId={selectedOfficeId}
                                setSelectedOfficeId={setSelectedOfficeId}
                                getContextMenuOptions={getContextMenuOptions}
                            />
                        ))}
                    </StyledTreeSubList>}
            </ContextMenuTrigger>
            {getContextMenuOptions(office).length ? <PortalContextMenu contextId={`${office.id}_context`} options={getContextMenuOptions(office)} /> : null}
        </StyledContextMenu>
    )
}

export default OfficeItem