import React from 'react'

export const EmailSendIcon = ({ color, title, ...rest }) => {
  const useColor = color ? color : '#3C3C3B'
  return (
    <svg width="28px" height="28px" viewBox="0 0 28 28" {...rest}>
      {title && <title>{title}</title>}
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M19.9625556,12.2 L24.6696624,16.9071068 L19.9625556,21.6142136 L19.962,19.6042136 L13.8,19.6041631 L13.799,17 L13.792,17 L13.7928933,14.2171573 L13.799,14.217 L13.8,14.2100505 L19.962,14.2102136 L19.9625556,12.2 Z M18,8 C18.5522847,8 19,8.44771525 19,9 L19,9.837 L18.956116,9.79356035 L18.9546177,13.2180448 L12.7928933,13.2171309 L12.792,17 L6,17 C5.44771525,17 5,16.5522847 5,16 L5,9 C5,8.44771525 5.44771525,8 6,8 L18,8 Z M9.615,13.4201528 L6.25086717,15.3437744 L6.24900978,15.3448415 L6.17551991,15.3965328 C5.99546454,15.5491633 5.94457981,15.8132599 6.06678282,16.0249217 C6.18951277,16.2374962 6.44476539,16.3258564 6.66793311,16.2458958 L6.74979553,16.2079344 L10.592,13.9901528 L9.615,13.4201528 Z M17.5280983,9.2120386 L17.4462359,9.25 L12.1102379,12.3307399 L12.1012171,12.336074 L12.0923385,12.3415766 L12.0286527,12.2975833 L6.75008349,9.25 C6.51093718,9.11192881 6.20514197,9.19386639 6.06707079,9.4330127 C5.94434084,9.6455872 5.99544498,9.91082255 6.17627674,10.0641112 L6.25008349,10.1160254 L11.8742241,13.3631245 L11.8842044,13.3690421 L11.9705691,13.4216138 C11.9761779,13.425028 11.9821655,13.427777 11.9847454,13.4290357 C12.0131337,13.4531665 12.0514031,13.4630117 12.0880083,13.4554241 L12.1236313,13.4419089 L17.9451643,10.11416 C17.9457842,10.1138056 17.9464033,10.11345 17.9470217,10.1130929 C18.1851412,9.97561456 18.266727,9.67113226 18.1292486,9.4330127 C18.0065187,9.2204382 17.751266,9.13207801 17.5280983,9.2120386 Z"
          fill={useColor}/>
      </g>
    </svg>
  )
}
