import React, { useState } from 'react'
import { usePlaceholdersState } from '../../../stores/Configuration/PlaceholdersProvider'
import useMountEffect from '../../../utils/hooks/useMountEffect'
import { ErrorAlerts } from '../../../utils/api/makeCall'
import { Spinner } from 'react-bootstrap'
import PlaceholderItem from './PlaceholderItem'
import i18n from '../../../translation/i18n'
import CustomDropdown from '../../Dropdowns/CustomDropdown'

const PlaceholdersOverview = ({ ...rest }) => {
  const { getPlaceholderTypes, placeholderTypes, getAllPlaceholders, errorStates, loadingStates, languages, loadLanguages, loadCurrentMandator } = usePlaceholdersState()
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language.toUpperCase())

  useMountEffect(() => {
    getPlaceholderTypes()
    getAllPlaceholders()
    loadLanguages()
    loadCurrentMandator()
  })
  return (
    <>
      <div className="mb-2">
        <CustomDropdown
          value={selectedLanguage}
          data={languages}
          returnObject={e => setSelectedLanguage(e.value)}
        />
      </div>
      {placeholderTypes.map(type => <PlaceholderItem key={type.id} type={type}{...rest} selectedLanguage={selectedLanguage} />)}
      {(loadingStates.placeholderTypes || loadingStates.loadLanguages) && <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >}
      <ErrorAlerts errorStates={errorStates} />
    </>
  )
}

export default PlaceholdersOverview