import React from 'react'
import { CircleCheckIcon } from '../../Icons/CircleCheckIcon'
import styled from 'styled-components'
import { Alert } from 'react-bootstrap'


export const StyledCircleCheckIconWrapper = styled.div`
  display: inline-block;
  margin-right: 15px;
`

const AlertStyledWrapper = styled.div`
  font-size:1rem;
  margin-top:15px;
`


const StepsDataCheck = ({ label, isDone, description }) => {

    return (
        <>
            <StyledCircleCheckIconWrapper>
                <CircleCheckIcon variant={isDone ? "done" : "must"} />
            </StyledCircleCheckIconWrapper>
            <span>{label}</span>
            <AlertStyledWrapper>
                <Alert variant={isDone ? "success" : "info"}>{description}</Alert>
            </AlertStyledWrapper>
        </>
    )
}

export default StepsDataCheck
