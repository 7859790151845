import React from 'react'

export const Pants = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="pants" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <path className="st0" d="M86.5,14.3H26.9c-0.7,0-1.2,0.5-1.2,1.2l-4.6,82.4c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.8,0.4h27.6
        c0.6,0,1.1-0.5,1.2-1.1l5.7-30.8l0.1,0.6L62.9,98c0.1,0.6,0.6,1.1,1.2,1.1h27c0.3,0,0.6-0.1,0.9-0.4c0.2-0.2,0.4-0.5,0.4-0.9
        l-4.6-82.4C87.7,14.8,87.2,14.3,86.5,14.3z M85.8,26l1,20l-1.6-0.7c-4.6-2.1-10.3-7-11-18.2l-0.1-1.1h11.7L85.8,26z M27.7,23.3
        l0.3-6.6h57.3l0.3,6.7h-58L27.7,23.3z M27.6,25.9h11.7l-0.1,1.1c-0.7,11.2-6.4,16.1-11,18.2L26.6,46L27.6,25.9z M65.2,96.7l-0.1-0.3
        l-7.3-39.7c-0.1-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.1,0.5-1.2,1.1l-6.8,40H23.5l0-0.4l2.9-47.7l0.7-0.2c6.2-2.2,13.8-7.6,14.6-21.4l0.1-1
        h29.7l0.1,1c0.8,13.8,8.3,19.2,14.6,21.4l0.7,0.2l0,0.3l2.9,47.8H65.2z"/>
    </svg>
  )
}
