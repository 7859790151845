import React, { useState } from 'react'
import { inject } from 'mobx-react'
import { Modal, Button, Alert, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'

import ProductList from '../shared/ProductList'
import ReclamationForm from './ReclamationForm'
import OrderActionResult from '../shared/OrderActionResult'
import MandatoryField from '../../MandatoryField/MandatoryField'
import { PdfButton } from '../../Buttons/PdfButton'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { emptyGuid } from '../../../utils/emptyGuid'
import DownloadURLAlert from '../../Forms/DownloadURLAlert'
import FileInput from '../../Forms/FileInput'
import { FileOutput } from '../../Forms/FileOutput'
import ReclamationsExistAlert from './ReclamationsExistAlert'


/**
 * Accepts a list of products and enables the user to enter a reason text and an email address
 * for a reclamation request
 * Can display loading and success state if supplied
 * Optional: pass a list of costCenters to enable the user to select a costCenter, pass an eMail as default email value
 * @param costCenters
 * @param onSubmit
 * @param onCancel
 * @param defaultEmail
 * @param loading
 * @param success
 * @param issueId
 * @param issueEmail
 */
const ReclamationDialog = inject('orderStore', 'uiStore')((
  {
    costCenters,
    onSubmit,
    onCancel,
    defaultEmail,
    loading,
    success,
    issueId,
    issueEmail,
    emailWasSent,
    orderStore,
    uiStore,
    dialogError,
    contactData,
    feedbackId,
    returnReasons,
    complaints
  }
) => {
  const { t } = useTranslation()
  const [formData, setFormData] = useState(undefined)
  const [validated, setValidated] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState('')
  const [pdfIsLoading, setPdfIsLoading] = useState(false)
  const [complaintsReceiptError, setComplaintsReceiptError] = useState('')
  const [files, setFiles] = useState([])


  /**
   * Set formData and validated state from form
   */
  function handleChange(formData, validated) {
    setFormData(formData)
    setValidated(validated)
  }

  /**
   * Call the given submit function
   */
  function handleSubmit() {
    onSubmit(formData, files)
  }

  const loadPdf = () => {
    setComplaintsReceiptError('')
    setPdfIsLoading(true)
    setDownloadUrl('')

    apiClient.postJson(APIEndpoints.complaints(feedbackId).receipt, {}, true, true)
      .then(response => {
        if (response.headers.location) {
          const newWindowRef = window.open(response.headers.location)
          if (!newWindowRef) {
            setDownloadUrl(response.headers.location)
          }
        }
      }).catch(() => {
        setComplaintsReceiptError(t('reportConfig.createError'))
      }).finally(() => {
        setPdfIsLoading(false)
      })
  }

  function getPreSelectedCostCenter() {
    const targetCostCenter = orderStore.incomingOrder.targetCostCenter
    var costCenter = ''
    if (
      targetCostCenter &&
      targetCostCenter.id &&
      targetCostCenter.id !== emptyGuid
    ) {
      costCenter = targetCostCenter
    } else if (costCenters.length === 1) {
      costCenter = costCenters[0]
    }
    return costCenter
  }



  return (
    <Modal show centered onHide={() => onCancel()} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{t('orderDialog:claimProducts')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {(contactData === null) ?
          <div className='d-flex justify-content-center p-5'><Spinner animation='border' variant='primary' /></div>
          :
          <React.Fragment>
            <ProductList disableSelections={success} />
            {!success ? (
              <>
                <ReclamationsExistAlert complaints={complaints} />
                <ReclamationForm
                  onSubmit={handleSubmit}
                  onChange={handleChange}
                  defaultEmail={defaultEmail}
                  getPreSelectedCostCenter={getPreSelectedCostCenter}
                  returnReasons={returnReasons}
                />

                <FileInput
                  className="mb-3"
                  onDrop={(acceptedFiles) => {
                    let newFiles = acceptedFiles.map(file => Object.assign(file, {
                      preview: URL.createObjectURL(file)
                    }));
                    setFiles(prev => [...prev, ...newFiles])
                  }}
                  label={t('orderDialog:attachments')}
                />

                {files.map((file, index) => (
                  <FileOutput
                    style={{ height: "4rem", borderRadius: "1rem", padding: "0.5rem" }}
                    className="mb-2"
                    key={`${file.name}_${index}`}
                    file={file}
                    deleteFile={
                      () => setFiles(prev => {
                        prev.splice(index, 1)
                        return cloneDeep(prev)
                      })
                    }
                  />
                ))}
              </>

            ) : (
              <OrderActionResult
                issueId={issueId}
                issueEmail={issueEmail}
                emailWasSent={emailWasSent}
              />
            )}
            {dialogError ? (
              <Alert variant='danger' className='mt-4'>{dialogError}</Alert>
            ) : ''}
            {complaintsReceiptError && <Alert variant="danger">{complaintsReceiptError}</Alert>}
          </React.Fragment>}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        {!success ? (
          <React.Fragment>
            <MandatoryField />
            <div>
              <Button
                variant='secondary'
                onClick={() => onCancel()}
                className="me-2"
              >
                {t('cancelButton')}
              </Button>
              <Button
                variant='primary'
                onClick={() => handleSubmit()}
                disabled={loading || !validated}
              >
                {loading ? t('loading') : t('orderDialog:enterComplaint')}
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              {!uiStore.releaseComplaintsIsActive
                ? <PdfButton
                  variant='secondary'
                  onClick={() => loadPdf()}
                  isLoading={pdfIsLoading}
                  buttonText={t('orderDialog:printComplaintForm')}
                  className="me-2"
                />
                : null
              }
              <Button
                variant='primary'
                onClick={() => onCancel()}
              >
                {t('closeButton')}
              </Button>
            </div>
          </React.Fragment>
        )}
      </Modal.Footer>

      {
        downloadUrl &&
        <DownloadURLAlert
          downloadUrl={downloadUrl}
          setDownloadUrl={setDownloadUrl}
          fileName={t('orderDialog:printComplaintForm')}
        />
      }
    </Modal >
  )
})

export default ReclamationDialog
