import React, { useState, useMemo } from 'react'
import { CrossIcon } from '../Icons/CrossIcon'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useEmployeesState } from '../../stores/EmployeesProvider'
import { debounce } from 'throttle-debounce'

const StyledSearchFieldWrapper = styled.div`
  position: relative;
  width: 100%;
  
  ${CrossIcon} {
    position: absolute;
    top: 50%;
    right: 28px;
    display: none;
    margin-top: -8px;

    &.visible {
      display: block;
    }
  }
`
const HeaderSearchBar = ({ onFilter, column, getFilter }) => {
    const { t } = useTranslation()
    const { filter } = useEmployeesState()
    const [title, setTitle] = useState(filter[column.filterKey])

    const loadFilterDebounced = useMemo(
        () => debounce(500, (value) => {
            onFilter(value)
        }), [onFilter])

    const handleChangeFilter = (newTitle) => {
        setTitle(newTitle)
        loadFilterDebounced(newTitle)
    }

    getFilter(setTitle)

    const onClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }
    return [
        <StyledSearchFieldWrapper onClick={onClick} key={column.dataField}>
            <Form.Label className="filter-label">
                <Form.Control
                    value={title || ''}
                    key="input"
                    type="text"
                    placeholder={t(column.text)}
                    onChange={e => handleChangeFilter(e.target.value)}
                    autoComplete="nope"
                />
            </Form.Label>
            <CrossIcon color={colors.gray3} onClick={() => { setTitle(''); onFilter('') }}
                className={title ? 'visible' : ''} />
        </StyledSearchFieldWrapper>
    ];
}

export default HeaderSearchBar
