import React, { useMemo } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import ProductInfo from '../Product/ProductInfo'
import { useTranslation } from 'react-i18next'
import QuantityStatus from '../ProductBuyBox/QuantityStatus'

const StyledProductItemWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 85px 1fr auto;

  @media screen and (min-width: 992px) {
    align-items: center;
  }
`

const StyledAmountCol = styled.div`
  padding-left: 20px;
  font-size: 0.9em;
  white-space: nowrap;
  user-select: none;
  @media screen and (min-width: 992px) {
    grid-column: auto;
    padding: 20px 0 20px 20px;
  }
  @media screen and (min-width: 430px) {
    text-align:end;
  }
`


const ProductItemSimple = inject('productsStore', 'productDetailsStore')(observer((props) => {
  const { productsStore, article, position, productDetailsStore } = props
  const { t } = useTranslation()

  const availabilityStatus = useMemo(() => {
    let positionAvailability = productsStore.basketAvailabilities.find(x => x.positionId === position.id)
    return positionAvailability ? positionAvailability : null
  }, [position.id, productsStore.basketAvailabilities])

  return (
    <React.Fragment>
      <StyledProductItemWrapper>
        <ProductInfo articleObject={article} position={position} toArticle={productDetailsStore.getProductToUrl(article)} />
        <StyledAmountCol>
          <div>
            {t('amount')}:  <span className="ms-2 me-1">{position.quantity}</span>
          </div>
          {availabilityStatus && <QuantityStatus variantQuantities={availabilityStatus} />}
        </StyledAmountCol>
      </StyledProductItemWrapper>
    </React.Fragment>
  )

}))

export default ProductItemSimple
