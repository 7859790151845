import React from 'react'
import { inject, observer } from 'mobx-react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const LaundryShelfInput = inject('laundryShelfStore', 'uiStore')(observer((props) => {
    const { status, laundryShelfStore, uiStore } = props
    const { t } = useTranslation()

    const handleChange = (e) => {
        laundryShelfStore.selectedItems = [{ displayName: e.target.value }]
    }

    if (status === "hide") {
        return null
    }

    return (
        <Form.Group className="mb-3" >
            <Form.Label>{t('laundryShelves:laundryShelf')}  {uiStore.laundryShelf_IsMandatory ? "*" : ""}</Form.Label>
            <Form.Control
                disabled={status === "read"}
                isInvalid={laundryShelfStore.submitBPLoundryShelvesError}
                value={laundryShelfStore.selectedItems.length ? laundryShelfStore.selectedItems[0].displayName : ''}
                onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
                {t('mandatoryField')}
            </Form.Control.Feedback>
        </Form.Group>
    )
}))

export default LaundryShelfInput