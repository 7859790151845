import React, { useState } from 'react'
import { VisualInput } from '../../../Forms/VisualInput'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import StructureIcon from '../../../Icons/StructureIcon'
import { Form } from 'react-bootstrap'
import OverlayContainer from '../../../Page/logix2020/OverlayContainer'
import OverlayRight from '../../../Page/logix2020/OverlayRight'
import LaundryShelfTreeSelect from './LaundryShelfTreeSelect'
import { useTranslation } from 'react-i18next'
import {
    StyledDot
} from '../../../TreeList/treeList'

const StyledButton = styled.div`
cursor:pointer;
  border: 0;
  background: none;
  padding: 0;
  margin: 0 0 0 10px;
`

const LaundryShelfTreeButton = inject('laundryShelfStore', 'overlayRightStore')(observer((props) => {
    const { overlayRightStore, laundryShelfStore, employeeId } = props
    const [showLaundryOverlay, setShowLaundryOverlay] = useState(false)
    const { t } = useTranslation()

    return (<>
        <Form.Group className="mb-3" >
            <Form.Label>{t('laundryShelves:laundryShelf', { count: 2 })}</Form.Label>
            <div className="d-flex align-items-start">
                <VisualInput style={{ height: "100px", overflow: "auto" }} className={false ? 'is-invalid' : ''} onClick={() => { overlayRightStore.closeAll(); setShowLaundryOverlay(prev => !prev) }}>{laundryShelfStore.selectedItems.map(laundryShelf => (
                    <div key={laundryShelf.id} className="d-flex"><StyledDot>•</StyledDot> <div >{laundryShelf.displayName}</div></div>
                ))}</VisualInput>
                <StyledButton onClick={() => { overlayRightStore.closeAll(); setShowLaundryOverlay(prev => !prev) }}><StructureIcon /></StyledButton>
            </div>
        </Form.Group>
        <OverlayContainer >
            <OverlayRight headerTitle={t('laundryShelves:laundryShelf', { count: 2 })} large visible={showLaundryOverlay} onClose={() => { overlayRightStore.closeAll(); setShowLaundryOverlay(prev => !prev) }}>
                <div className="content">
                    <LaundryShelfTreeSelect employeeId={employeeId} />
                </div>
            </OverlayRight>
        </OverlayContainer>
    </>

    )
}))

export default LaundryShelfTreeButton
