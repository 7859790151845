import React, { useState, useEffect, useCallback } from 'react'
import { Subheader } from '../../Page/logix2020/Subheader'
import SubheaderForm from '../../Page/logix2020/SubheaderForm'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getEmployee } from '../../../utils/employeeLoader'
import { TiInfoLarge } from 'react-icons/ti'


const SubheaderImageUpload = ({ setOpenInfoModal }) => {
  const { t } = useTranslation()
  const { actionType, targetId } = useParams()
  const [name, setName] = useState('')

  const getSecondPart = useCallback(async () => {
    if (!targetId) {
      return ''
    }
    if (actionType === "articleTransfer") {
      const target = await getEmployee(targetId)
      return `: ${target.displayName}`
    }
    return ''
  }, [actionType, targetId])

  useEffect(() => {
    const init = async () => {
      let firstPart = t('inventory:inventoryBooking')
      const secondPart = await getSecondPart()
      setName(`${firstPart}${secondPart}`)
    }
    init()
  }, [getSecondPart, t]);


  return (
    <Subheader className="subHeader-v1 ">
      <div className="flex-grow-1">
        <SubheaderForm title={name} goBackLabel={t('backButton')} />
      </div>
      <TiInfoLarge title={t('info')} className="infoIcon" role="button" onClick={() => setOpenInfoModal(true)} />
    </Subheader>
  )
}

export default SubheaderImageUpload
