import React, { Component } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import { colors } from '../../styles/colors'
import { scrollToDataId } from '../../utils/utils'
import ContingentList from './ContingentList'
import ContingentListAccordion from './ContingentListAccordion'


const StyledOffCanvasContainer = styled.div`
  background: ${colors.gray1}
  border-right: 1px solid ${colors.gray2}
  padding: 40px 0;
  position: relative;
  //margin-right: -15px;
  height: 100%;
`

const StyledContainer = styled.div`
  @media screen and (max-width: 992px) {
    &.closed {
      display: none;
    }
  }

  &.open {
    position: absolute;
    height: 100%;
    left: 0;
    width: 80%;
  }
`

const StyledStickyWrapper = styled.div`
  position: sticky;
  top: 15px;
  max-height: 100%;
  overflow: auto;
`


class OffCanvasArea extends Component {

  handleClickScrollToGroup = (categoryId) => {
    scrollToDataId(`category-${categoryId}`)
  }

  render() {
    const { productsStore } = this.props
    const filteredContingents = productsStore.filteredContingents ? productsStore.filteredContingents : []

    return (
      <StyledContainer>
        <StyledOffCanvasContainer className="opened">
          <StyledStickyWrapper>
            {
              productsStore.useEntitlementSelection ?
                <ContingentListAccordion
                  contingents={productsStore.contingents || []}
                  handleClickScrollToGroup={this.handleClickScrollToGroup}
                  onSwitchContingentHandler={(index) => productsStore.onSwitchContingentHandler(index)}
                  selectedId={productsStore.selectedContingentId}
                />
                : filteredContingents.map(contingent => (
                  <ContingentList
                    key={contingent.id}
                    contingent={contingent}
                    handleClickScrollToGroup={this.handleClickScrollToGroup}
                  />
                ))
            }
          </StyledStickyWrapper>
        </StyledOffCanvasContainer>
      </StyledContainer>
    )
  }
}

export default inject('productsStore')(observer(OffCanvasArea));
