import React from 'react'
import { Form } from 'react-bootstrap'
import LanguageIcon from "../../assets/icons/ICN_BTN_Translate.svg"
import i18n from '../../translation/i18n'

import { StyledValidationMessage } from '../Employees/Form/formHelper'

const LanguageNameInput = ({ openLanguageModal, languageArray, onChange, label, isInvalid }) => {

    const selectedLanguage = i18n.language.toUpperCase()

    const handleChange = (e) => {
        const { value } = e.target
        const newObj = JSON.parse(JSON.stringify(languageArray))
        const index = newObj.findIndex(obj => obj.langIsoCode === selectedLanguage)
        if (index >= 0) {
            newObj[index].value = value
            onChange(newObj)
        }
        else {
            const newValue = { langIsoCode: selectedLanguage, value: value }
            onChange([...newObj, newValue])
        }
    }
    const findValue = (isoCode) => {
        return (languageArray.find(obj => obj.langIsoCode === isoCode) || { value: "" }).value
    }

    return (
        <Form.Group className="mb-3">
            <Form.Label>{label}</Form.Label>
            <div className="d-flex align-items-start">
                <Form.Control
                    type="text"
                    placeholder={label}
                    name="name"
                    onChange={handleChange}
                    value={findValue(selectedLanguage) || ""}
                    isInvalid={isInvalid}
                />
                <img style={{ cursor: "pointer" }} onClick={openLanguageModal} src={LanguageIcon} alt="icon" />
            </div>
            {isInvalid && <StyledValidationMessage>{isInvalid}</StyledValidationMessage>}
        </Form.Group>

    )
}

export default LanguageNameInput