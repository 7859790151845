export const scrollToDataId = dataId => {
  const element = document.querySelector(`[data-id=${dataId}]`)
  if (element && !isInView(element)) {
    element.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }
}

export const scrollToElement = element => {
  if (element && !isInView(element)) {
    element.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }
}

export const isInView = (el) => {
  var rect = el.getBoundingClientRect();
  var elemTop = rect.top;
  var elemBottom = rect.bottom;

  // Only completely visible elements return true:
  var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
  // Partially visible elements return true:
  //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

export const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

//creates fake synthetic event with most important properties
export const getFakeEvent = (name = "", value = "", type = "", checked = false) => {
  const fakeEvent = { target: { name, value, type, checked }, preventDefault: () => null, stopPropagation: () => null }
  return fakeEvent
}

//dynamically accsess object propertys with path sting 
//resolveObj("one.two", {one:{two:42}}) => 42
//resolveObj("one.two.three",{one:{two:{three:{four:42}}}}) => {four:42}
export const resolveObj = (path, obj) => {
  return path.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj);
};

//dynamically set value by key with key path string
//setValueByKey('one.two',"42",{one:{two:null}})=>{one:{two:42}}
export const setValueByKey = (key, value, obj) => {
  const keyFragments = key.split('.')
  const newValues = { ...obj }
  const prop = keyFragments.reduce((acc, item, index) => {
    return (index === keyFragments.length - 1 ? acc : acc[item])
  }, newValues)
  prop[keyFragments.pop()] = value
  return newValues
}

export const updateTranslationValue = (selectedLanguage, data, key, newValue) => {
  let values = resolveObj(key, data)

  let findIndex = values.findIndex(obj => obj.langIsoCode === selectedLanguage)
  if (findIndex >= 0) {
    values[findIndex].value = newValue
  }
  else {
    values.push({ langIsoCode: selectedLanguage, value: newValue })
  }
  data = setValueByKey(key, values, data)
}

export const testError = async () => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  let error = {
    response: {
      data: {
        errors: [
          {
            detail: "TestErrror",
            source: { pointer: "/testPointer" }
          }
        ]
      }
    }
  }

  throw error
}

export const waitForPromise = ms => new Promise(r => setTimeout(r, ms))
