import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { emptyGuid } from '../../utils/emptyGuid'
import { ShoppingCartIcon } from '../Icons/ShoppingCartIcon'
import { useTranslation } from 'react-i18next'
import useStores from '../../stores/useStore'
import { useObserver } from 'mobx-react'

const StyledShoppingCartButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 40px;
  border: none;
  background-color: transparent;

  padding: 0;
  &:focus {
    outline: none;
  }

  .show & {
    background-color: ${colors.navActiveBackground};
  }

  .bubble {
    background: ${props => props.theme.primaryColor};
    font-size: 8px;
    border-radius: 50px;
    height: 13px;
    width: 13px;
    text-align: center;
    line-height: 1.2;
    color: white;
    position: absolute;

    display: flex;
    bottom: 9px;
    right: 1px;
    align-items: center;
    justify-content: center;
  }
`

const StyledShoppingCart = styled.div`
  &.shopping-cart {
      height: 100%;
      .show {
        background: ${colors.navActiveBackground};

        .shopping-cart-icon-svg {
          fill: white;
        }
      }

      button {
        justify-content: center;
        display: flex;
      }

      .dropdown-menu {
        &.show {
          max-height: 280px;
          overflow: auto;

          -ms-overflow-style: none;
          &::-webkit-scrollbar {
            display: none;
          }

        }

        .dropdown-item {
          div:first-child {
            font-weight: 600;
          }
        }
      }
      .shopping-cart-icon-svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1rem;
        height: 1rem;
        fill: #3c3c3a;
      }
    }
`

const DropdownToggleRef = React.forwardRef(({ children, onClick }, ref) => (
  <StyledShoppingCartButton
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}>
    {children}
  </StyledShoppingCartButton>
))

export default function CartDropdown() {
  const { t } = useTranslation()
  const { uiStore } = useStores();

  const shoppingCarts = useObserver(() => uiStore.shoppingCarts)

  if (shoppingCarts.length === 0) {
    return <StyledShoppingCart className="shopping-cart">
      <StyledShoppingCartButton>
        <ShoppingCartIcon />
        <span className="bubble">{shoppingCarts.length}</span>
      </StyledShoppingCartButton>
    </StyledShoppingCart>
  }
  ///order/shoppingcart/:shoppingBasketId/:orderType/

  return (
    <StyledShoppingCart className="shopping-cart">
      <Dropdown align="end">
        <Dropdown.Toggle as={DropdownToggleRef}>
          <ShoppingCartIcon />
          <span className="bubble">{shoppingCarts.length}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {shoppingCarts.map(({ displayName, articleQuantity, id, targetCostCenter, targetOrderProcessType }) => (
            <LinkContainer key={id}
              to={targetCostCenter && targetCostCenter.id !== emptyGuid ? `/costcenter/${targetCostCenter.id}/shoppingcart/${id}`
                : targetOrderProcessType === "private" ? `/order/private/shoppingcart/${id}`
                  : `/order/personal/shoppingcart/${id}`}>
              <Dropdown.Item>
                <div>{t('shoppingCart')}</div>
                <div>{displayName} ({articleQuantity})</div>
              </Dropdown.Item>
            </LinkContainer>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </StyledShoppingCart>
  )
}
