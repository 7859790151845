import React, { useState } from 'react'
import Calendar from '../../../components/Calendar/Calendar'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import { useAppointmentState } from '../../../stores/AppointmentProvider'
import { emptyGuid } from '../../../utils/emptyGuid';
import { getIsoDate } from '../../../utils/getIsoDate'


const now = new Date()
const minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)

const DateSelect = () => {
    const { t } = useTranslation()
    const {
        setAppointment,
        appointment,
        dateSlots, getDateSlots, getTimeSlots, initialAppointment
    } = useAppointmentState()
    const [activeStartDate, setActiveStartDate] = useState(() => {
        const d = new Date()
        d.setDate(1)
        return d
    })
    const handleChangeDate = date => {
        if (date instanceof Date) {
            const preparedDate = getIsoDate(date)
            const resetTimeSlot = {
                hour: 0,
                minute: 0,
                id: emptyGuid
            }
            let newAppointment = { ...appointment, day: preparedDate, timeSlot: resetTimeSlot }
            setAppointment(newAppointment)
            getTimeSlots(newAppointment, initialAppointment)
        }
    }
    const handleChangeMonth = activeStartDate => {
        setActiveStartDate(activeStartDate)
        getDateSlots(appointment, activeStartDate)
    }
    const isTileDisabled = ({ date, view }) => {
        if (view !== "month") {
            return false
        }
        const freeSlots = dateSlots.find((slot) => {
            const slotDay = new Date(slot.day)
            if (slotDay.toJSON().substr(0, 10) !== date.toJSON().substr(0, 10)) {
                return false
            }
            return slot.countFreeTimeslots > 0
        })
        return (view === 'month') && freeSlots ? false : true;
    }

    return (
        <>
            {appointment &&
                <Form.Group className="mb-3">
                    <Form.Label>{t('appointment:selectDate')}</Form.Label>
                    <div className="calendar">
                        <Calendar
                            value={new Date(appointment.day)}
                            onChange={d => { handleChangeDate(d) }}
                            tileDisabled={isTileDisabled}
                            minDate={minDate}
                            activeStartDate={activeStartDate}
                            onActiveStartDateChange={(props) => { handleChangeMonth(props.activeStartDate) }}
                            locale={t('datePicker:languageTag')}
                        />
                    </div>
                </Form.Group>}
        </>
    )
}

export default DateSelect
