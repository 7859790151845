import React, { useEffect, useState } from 'react'
import { Alert, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getErrorMessageFromApiResponse } from "../../../utils/api/apiUtils"

import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
const MissingUseresAlert = ({ partnerIDs }) => {
    const [count, setCount] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        apiClient.postJson(APIEndpoints.businessPartnersWithMissingData, partnerIDs)
            .then(res => setCount(res))
            .catch(e => { setError(getErrorMessageFromApiResponse(e)) })
    }, [partnerIDs])

    return (
        <>
            {count == null
                ? <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
                : count > 0
                    ? <Alert variant="warning">
                        <Trans
                            i18nKey="sizeDetection:missingUserAlert"
                            values={{ count }}
                        />
                        <Link to={`/sizeDetection`} className='btn btn-primary mt-3' style={{ whiteSpace: 'nowrap' }}>{t('header:sizeDetection')}</Link>
                    </Alert>
                    : null
            }
            {error && <Alert variant="danger">{error}</Alert>}
        </>
    )
}

export default MissingUseresAlert
