import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from 'react-bootstrap'
import styled from 'styled-components'
import OverlayRight from '../../Page/logix2020/OverlayRight'
import OverlayContainer from '../../Page/logix2020/OverlayContainer'
import LanguageForm from "../../Forms/LanguageForm"
import LanguageNameInput from "../../Forms/LanguageNameInput"
import { ButtonWithLoadingState } from '../../Buttons'
import { useActivitiesState } from '../../../stores/Configuration/ActivitiesProvider'

const StyledDiv = styled.div`
  width:600px;
  .alert {
      margin-top:1.5rem;
  }
`

const ActivityGeneralForm = ({ activityId }) => {
  const { t } = useTranslation()
  const { successStates, errorStates, setActivity, activity, loadingStates, postActivity, patchActivity } = useActivitiesState()
  const [overlayVisible, setOverlayVisible] = useState(false)

  const handleCloseOverlay = () => {
    setOverlayVisible(false)
  }

  const updateNames = (translations) => {
    setActivity(prev => ({
      ...prev, name: {
        values: translations
      }
    }))
  }

  const handleSubmit = () => {
    activityId ? patchActivity(activityId) : postActivity()
  }

  return (
    <StyledDiv>
      <LanguageNameInput
        openLanguageModal={() => setOverlayVisible(true)}
        languageArray={activity.name.values}
        onChange={updateNames}
        label={t('name')}
        isInvalid={false}
      />
      <ButtonWithLoadingState
        buttonText={t('saveButton')}
        isLoading={loadingStates.saveActivity}
        onClick={handleSubmit}
      />
      <OverlayContainer>
        <OverlayRight visible={overlayVisible} onClose={handleCloseOverlay}>
          <LanguageForm
            languageTitle={t('activities:activity')}
            handleCloseOverlay={handleCloseOverlay}
            languageArray={activity.name.values}
            onChange={updateNames}
          />
        </OverlayRight>
      </OverlayContainer>
      {successStates.saveActivity &&
        <Alert variant="success" className="my-2">
          {t('registrationBox:savingSuccess')}
        </Alert>
      }
      {errorStates.saveActivity &&
        <Alert variant="danger">{errorStates.saveActivity.value}</Alert>
      }
    </StyledDiv>
  )
}

export default ActivityGeneralForm
