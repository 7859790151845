import React, { useCallback, useState } from 'react'
import { Form, Alert, Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { StyledCard } from '../Cards/StyledCard'
import { formatedDate } from '../../utils/formatedDate'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md"
import ComplaintCollapsed from './ComplaintCollapsed'
import { RetourIcon } from '../Icons/RetourIcon'
import { ClaimIcon } from '../Icons/ClaimIcon'
import PdfDownloadButton from './PdfDownloadButton'
import ComplaintDetails from './ComplaintDetails'
import ConvertToReturn from './ConvertToReturn'
import { ButtonWithLoadingState } from '../Buttons'
import DownloadURLAlert from '../Forms/DownloadURLAlert'
import useStores from '../../stores/useStore'
import { colors } from '../../styles/colors'
import { makeCall } from '../../utils/api/makeCall'
import useReport from '../../utils/hooks/useReport'
import ReportSubscriptionModal from '../Files/Form/ReportSubscriptionModal'
import ActivateModal from './ActivateModal'

const StyledFeedCard = styled(StyledCard)`
  background: white;
  1px solid  #dbdbdb;
  display: flex;
  padding: 1rem;
  margin-top:1rem;
  margin-bottom:1rem;

  .category-icon {
    transform: translateX(-10px);
  }

  .name {
    font-weight: 600;
    font-size: 18px;
    color: ${props => props.theme.primaryColor};
  }

  .date {
    margin-bottom: 10px;
    color: ${props => props.theme.primaryColor};
    small {
      font-weight: bold;
    }
  }
`
const StyledFlags = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display:flex;
    gap: 2px;
  .newFlag{
    background: ${props => props.theme.primaryColor};
    padding: 5px 15px;
    border-bottom-left-radius: 5px;
    color: white;
  }
  .approvedFlag{
    background: ${colors.green};
    padding: 5px 15px;
    border-bottom-left-radius: 5px;
    color: white;
  }
  .waitingForApprovalFlag{
      display:flex;
      gap: 1rem;
      align-items: center;
      background: ${colors.yellow};
      padding: 5px 15px;
      border-bottom-left-radius: 5px;
      color: white;
  }
  .noWrap{
    white-space: nowrap;
  }
`


const StyledFlexWrapper = styled.div`
  display:flex;
  justify-content:space-between;
  .leftSide{
    flex-basis:70%;
  }
  .rightSide{
    flex-basis:30%;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`


const ComplaintCard = ({ item, reloadComplaints, updateComplaint }) => {
  const { uiStore } = useStores()
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [openConvertToReturnDialog, setOpenConvertToReturnDialog] = useState(false)
  const [openConvertToReturnDialogIsLoading, setOpenConvertToReturnDialogIsLoading] = useState(false)
  const [openActivateModal, setOpenActivateModal] = useState(false)
  const { t } = useTranslation();
  const isComplaint = item.type === 'complaint'
  const isRetoure = item.type === 'retoure'
  const [errorStates, setErrorStates] = useState({})
  const [loadingStates, setLoadingStates] = useState({})
  let isReclamationClosed = item.status !== 'open'
  const { createReport, reportIsLoading, reportErrors, reportDownloadUrls, setReportDownloadUrls, reportSubscriptionId, setReportSubscriptionId } = useReport()
  const [messages, setMessages] = useState([]);
  // calculates the difference in hours between two dates 
  const getDifferenceBetweenTwoDates = () => {
    let complaintDate = new Date(item.createdAt);
    let currentDate = new Date();

    // The subtraction returns the difference between the two dates in milliseconds.
    // 36e5 is the scientific notation for 60*60*1000, dividing by which converts the milliseconds difference into hours.
    let hoursDifference = (Math.abs(currentDate - complaintDate) / 36e5).toFixed(2);
    return hoursDifference;
  }

  const reloadComplaint = async () => {
    await makeCall('reloadComplaint', async () => {
      let reloadedComplaint = await apiClient.getJson(APIEndpoints.complaints(item.id).default)
      updateComplaint(item.id, reloadedComplaint)
    }, setErrorStates, setLoadingStates)
  }

  const handleReclamationStatusChange = async (e) => {
    let isClosed = e.target.checked
    if (isClosed) {
      makeCall('openOrCloseComplaint', async () => {
        await apiClient.patchJson(APIEndpoints.complaints(item.id).close)
        await reloadComplaint()
      }, setErrorStates, setLoadingStates)
    } else {
      makeCall('openOrCloseComplaint', async () => {
        await apiClient.patchJson(APIEndpoints.complaints(item.id).reopen)
        await reloadComplaint()
      }, setErrorStates, setLoadingStates)
    }
  }

  const handleActivateComplaint = async (messageIds) => {
    await makeCall('activateComplaint', async () => {
      await apiClient.patchJson(APIEndpoints.complaints(item.id).activate, { messageIds })
      await reloadComplaint()
      setOpenActivateModal(false)
    }, setErrorStates, setLoadingStates)
  }

  const loadMessages = useCallback(async (force) => {
    if (!force && messages.length) {
      return
    }
    const endpoint =
      isComplaint ? APIEndpoints.complaints(item.id).messages
        : isRetoure ? APIEndpoints.returns(item.id).messages
          : null
    await makeCall('loadMessages', async () => {
      let response = await apiClient.getJson(endpoint)
      setMessages(response);
    }, setErrorStates, setLoadingStates)
  }, [isComplaint, isRetoure, item.id, messages.length])

  const complaintIsWaitingForApproval = uiStore.releaseComplaintsIsActive && item.type === 'complaint' && item.isWaitingForApproval
  const complaintIsApproved = uiStore.releaseComplaintsIsActive && item.type === 'complaint' && !item.isWaitingForApproval
  const allowReleaseComplaints = uiStore.allowReleaseComplaints && item.type === 'complaint'
  const allowConvertComplaintToReturn = uiStore.allowConvertComplaintToReturn && item.type === 'complaint'
  return (
    <StyledFeedCard  >
      <i className="category-icon">
        {isRetoure ? <RetourIcon color="#3a3a37" /> : isComplaint ? <ClaimIcon color="#3a3a37" /> : null}
      </i>
      <div className="flex-grow-1">
        <StyledFlags>
          {complaintIsWaitingForApproval
            ? <div className="waitingForApprovalFlag">
              <div className="noWrap text-primary">{t('complaints:awaitingApprovalStatus')}</div>
              {allowReleaseComplaints
                ? <ButtonWithLoadingState
                  variant="outline-primary"
                  style={{ background: colors.yellow }}
                  buttonText={t('complaints:approveComplaintAction')}
                  isLoading={loadingStates.activateComplaint}
                  onClick={() => setOpenActivateModal(true)}
                  size="sm"
                /> : null}
            </div>
            : complaintIsApproved
              ? <div className="approvedFlag">{t('complaints:approvedStatus')}</div>
              : null}
          {getDifferenceBetweenTwoDates() < 48 && <div className="newFlag">{t('new')}</div>}
        </StyledFlags>
        <div className='date'><small>{formatedDate(item.createdAt, true).slice(0, -4)}</small></div>
        <StyledFlexWrapper>
          <div className="leftSide">
            <div onClick={() => setIsCollapsed(prev => !prev)} className="name" role="button">
              {isComplaint ? t('complaints:complaint') : isRetoure ? "Retoure" : t('order:order')} - {item.feedbackNumber}
              {isCollapsed ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}
            </div>
            {(!isReclamationClosed || (isReclamationClosed && !isCollapsed)) &&
              <ComplaintDetails item={item} />
            }
          </div>
          <div className="rightSide">
            {(item.type === 'complaint') ?
              loadingStates.openOrCloseComplaint ?
                <Spinner size='sm' animation='border' variant='primary' />
                : <Form.Group className="mb-2">
                  <Form.Check
                    name="reclamationClosed"
                    type="checkbox"
                    id={`chb-${item.id}`}
                    onChange={handleReclamationStatusChange}
                    checked={isReclamationClosed}
                    label={t('complaints:complaintClosed')}
                    role="button"
                  />
                </Form.Group> : null}
            {(complaintIsApproved || isRetoure || !uiStore.releaseComplaintsIsActive)
              ? <PdfDownloadButton
                isComplaint={isComplaint}
                isRetoure={isRetoure}
                item={item}
                createReport={createReport}
                reportIsLoading={reportIsLoading}
              />
              : null}
            {(allowConvertComplaintToReturn)
              ? <ButtonWithLoadingState
                className="w-100 mb-3"
                buttonText={t('complaints:convertToReturnAction')}
                isLoading={openConvertToReturnDialogIsLoading}
                onClick={() => setOpenConvertToReturnDialog(true)}
              />
              : null}
          </div>
        </StyledFlexWrapper>
        {openConvertToReturnDialog && <ConvertToReturn
          item={item}
          openConvertToReturnDialogIsLoading={openConvertToReturnDialogIsLoading}
          setOpenConvertToReturnDialog={setOpenConvertToReturnDialog}
          setOpenConvertToReturnDialogIsLoading={setOpenConvertToReturnDialogIsLoading}
          reloadComplaints={reloadComplaints}
        />}
        {openActivateModal &&
          <ActivateModal
            handleClose={() => setOpenActivateModal(false)}
            handleActivateComplaint={handleActivateComplaint}
            loadingStates={loadingStates}
            errorStates={errorStates} messages={messages}
            loadMessages={loadMessages}
          />
        }
        {Object.values({ ...errorStates, ...reportErrors }).map((error, i) => <Alert key={i} variant="danger">{error.value}</Alert>)}
        {reportDownloadUrls.reportName && <DownloadURLAlert
          downloadUrl={reportDownloadUrls.reportName}
          setDownloadUrl={() => setReportDownloadUrls({})}
          fileName={isComplaint ? t('orderDialog:complaintForm') : isRetoure ? t('complaints:returnsReceipt') : null}
        />}
        {reportSubscriptionId && <ReportSubscriptionModal
          reportSubscriptionId={reportSubscriptionId}
          setReportSubscriptionId={setReportSubscriptionId}
          message={t('reportConfig:createPending')}
        />}
        {!isCollapsed &&
          <ComplaintCollapsed
            item={item}
            isReclamationClosed={isReclamationClosed}
            loadMessages={loadMessages}
            messagesLoading={loadingStates.loadMessages}
            messages={messages}
            reloadComplaint={reloadComplaint}
          />
        }

      </div>

    </StyledFeedCard>
  )
}

export default ComplaintCard;