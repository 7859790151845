import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

const EditorModalSwitches = ({ switchStates, changeSwitch }) => {
  return (
    <>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Check
            type="switch"
            id="isVisibleNews"
            label="Nachricht Sichtbar"
            checked={switchStates.isVisibleNews || false}
            onChange={changeSwitch}
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Check
            type="switch"
            id="isCollapsed"
            label="Nachricht eingeklappt"
            checked={switchStates.isCollapsed || false}
            onChange={changeSwitch}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Check
            type="switch"
            id="creationDate_IsVisible"
            label="Datum sichtbar"
            checked={switchStates.creationDate_IsVisible || false}
            onChange={changeSwitch}
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Check
            type="switch"
            id="creatorName_IsVisible"
            label="Nachrichtenersteller sichtbar"
            checked={switchStates.creatorName_IsVisible || false}
            onChange={changeSwitch}
          />
        </Form.Group>
      </Row></>
  )
}

export default EditorModalSwitches