import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react'
import { Alert, Table } from 'react-bootstrap'
import { Trans, withTranslation } from 'react-i18next'
import Form from 'react-bootstrap/Form'
import MediaQuery from 'react-responsive'
import { TiInfoLarge } from 'react-icons/ti'

import OffCanvasArea from '../components/OffCanvasArea/OffCanvasArea'
import ProductCard from '../components/ProductCard/ProductCard'
import { formatedPriceObject } from '../utils/formatedPrice'
import { PageLoadingLayer } from '../components/Loading/PageLoadingLayer'
import StickyShoppingBasket from '../components/StickyShoppingBasket/StickyShoppingBasket'
import SearchCriteriaCatalogCategories from '../components/SearchCriteriaCatalogCategoriesFilter/SearchCriteriaCatalogCategoriesFilter'
import { Subheader } from '../components/Page/logix2020/Subheader'
import SubheaderProductsOverview from '../components/ProductsOverview/SubheaderProductsOverview'
import CatalogSelector from '../components/ActivitySelector/CatalogSelector'
import SubheaderTitle from '../components/Employees/Subheader/SubheaderTitle'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../components/Page/logix2020/SidebarLeft'
import { FlexWrapper } from '../components/Page/logix2020/FlexWrapper'
import BigFooter from '../components/Footer/BigFooter'
import { settingValueToType } from '../utils/api/apiUtils'
import ProductListItem from '../components/ProductListView/ProductListItem'
import { AddToBasketButton } from '../components/Buttons/AddToBasketButton'
import SafelySetInnerHTML from '../components/SafelySetInnerHTML'
import ModalComponent2 from '../components/Modals/ModalComponent2'
import CatalogProductsSearch from '../components/ProductsOverview/CatalogProductsSearch'
import ContingentProductsSearch from '../components/ProductsOverview/ContingentProductsSearch'
import CatalogCategories from '../components/OffCanvasArea/CatalogCategories'
import { setCookie, getCookie } from '../utils/cookieHelper'
import { CategoryRows, ContingentRows } from '../components/ProductsOverview/Structure'
import LoadingExtremeLoadingLayer from '../components/ProductsOverview/LoadingExtremeLoadingLayer'
import withRouter from '../utils/withRouter'
import ArticleScanButton from '../components/Buttons/ArticleScanButton'
import ArticleScan from '../components/ProductsOverview/ArticleScan'
import LazyLoad from '../components/Forms/LazyLoad'

const StyledProductGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;

  @media screen and (min-width: 508px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (min-width: 1330px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const StyledCatalogViewSwitch = styled.div`
  margin-bottom: 25px;
  font-weight: bold;
`

class ProductsOverviewPage extends Component {

  constructor(props) {
    super(props)
    const { params, productsStore, teamOrderStore, profileStore } = this.props
    const { storeHouseId, costCenterId, employeeId, orderType } = params
    this.state = {
      showListView: getCookie("showListView") === "true",
      settingsPersonalOrder: null
    };
    //costcenter, private or empleyee/personal order depending on params and rights
    console.log('ProductsOverviewPage initialize:', storeHouseId, costCenterId, orderType)
    teamOrderStore.initializeCurrentProcessType(storeHouseId, costCenterId, orderType)
    const processType = teamOrderStore.getCurrentProcessType()
    if(processType === 'personal')
    {
      if (!this.state.settingsPersonalOrder) {
        apiClient.getJson(APIEndpoints.settings().personalOrder).then(response => {
          this.setState({ settingsPersonalOrder: response })
        })
      }
    }
    if (teamOrderStore.doResetTeamOrderProducts) {
      teamOrderStore.resetProductOverview()
    }

    //get + set employee ID depending on params or logged in user
    const newEmployeeId = employeeId || (!!costCenterId ? '' : profileStore.profile.id);
    if (productsStore.targetEmployeeId !== newEmployeeId) {
      productsStore.targetEmployeeId = newEmployeeId
    }

    (async () => {
      await productsStore.loadOrCreateBasket()

      //for the Einzelartikel switch and initial load
      this.showCatalogArticlesReactionDisposer = reaction(() => productsStore.showCatalogArticles,
        showCatalogArticles => {
          if (productsStore.showCatalogArticles) {
            productsStore.contingents = null
            if (teamOrderStore.doResetTeamOrderProducts) {
              (async () => {
                teamOrderStore.doResetTeamOrderProducts = false
                await productsStore.loadOrCreateBasket()
                await teamOrderStore.loadArticleCatalogs()
              })()
            }
          } else {
            (async () => {
              teamOrderStore.doResetTeamOrderProducts = true
              await productsStore.loadOrCreateBasket()
              await productsStore.loadContingent()
            })()
          }
        }, { fireImmediately: true })
    })()
  }

  componentDidUpdate(prevProps) {
    const { teamOrderStore } = this.props
    //when switching orders orderType
    const prev = prevProps.params.orderType
    const updated = this.props.params.orderType
    if (prev !== updated) {
      if (updated === "personal") {
        teamOrderStore.setPersonalOrder()
        if (!this.state.settingsPersonalOrder) {
          apiClient.getJson(APIEndpoints.settings().personalOrder).then(response => {
            this.setState({ settingsPersonalOrder: response })
          })
        }
      }
      else if (updated === "private") {
        teamOrderStore.setPrivateOrder()
      }
    }
  }
  componentWillUnmount() {
    //disposer function that you need to call in order to cancel it
    this.showCatalogArticlesReactionDisposer && this.showCatalogArticlesReactionDisposer()
  }

  hasPersonalOrderSetting = settingName => {
    if (!this.state.settingsPersonalOrder) {
      return false
    }
    return !!settingValueToType(this.state.settingsPersonalOrder.find(setting => setting.settingName === settingName))
  }

  handleChangeCatalogView = (e) => {
    this.props.productsStore.showCatalogArticles = e.target.checked

  }

  handleChangeProductsListView = (e) => {
    setCookie("showListView", (e.target.checked).toString(), 30)
    this.setState({ showListView: e.target.checked })
  }

  static isProductAmountMaxedOut(shoppingBasketArticle) {
    const { articleAvailability } = shoppingBasketArticle
    if (!articleAvailability) {
      return false
    }
    if (articleAvailability.maxAmount === null) {
      return false
    }
    return articleAvailability.maxAmount &&
      (articleAvailability.currentAmount + articleAvailability.amountToOrder) >= articleAvailability.maxAmount
  }

  static isProductInactive(shoppingBasketArticle) {
    const { articleAvailability, categoryAvailability } = shoppingBasketArticle
    if (!articleAvailability || !categoryAvailability) {
      return false
    }
    return categoryAvailability.orderableStatus === 'isNotOrderable' ||
      articleAvailability.orderableStatus === 'isNotOrderable' ||
      this.isProductAmountMaxedOut(shoppingBasketArticle)
  }

  renderCategory(category, articles, articlesHaveMaxAmounts = false, articlesHaveCurrentAmount = false) {
    const { t, productsStore } = this.props

    return (
      <CategoryRows key={category.id} category={category}>
        {this.state.showListView ?
          <>
            <MediaQuery minWidth={993}>
              <tr>
                <th>{t('productsOverview:article')}</th>
                <th>{!productsStore.hidePricesAndPoints ? t('price') : ''}</th>
                {articlesHaveMaxAmounts ? <th>{t('productsOverview:max')}</th> : null}
                {articlesHaveCurrentAmount ? <th>{t('productsOverview:current')}</th> : null}
                <th>{t('productsOverview:amountInBasket')}</th>
              </tr>
              {articles.map(shoppingBasketArticle => this.renderShoppingBasketArticle(shoppingBasketArticle, category, articlesHaveMaxAmounts, articlesHaveCurrentAmount))}
            </MediaQuery>
            <MediaQuery maxWidth={992}>
              {articles.map(shoppingBasketArticle => this.renderShoppingBasketArticle(shoppingBasketArticle, category, articlesHaveMaxAmounts, articlesHaveCurrentAmount))}
            </MediaQuery>
          </>
          :
          <tr>
            <td className={"p-0"} colSpan="5">
              <StyledProductGrid>
                {articles.map(shoppingBasketArticle => this.renderShoppingBasketArticle(shoppingBasketArticle, category, articlesHaveMaxAmounts, articlesHaveCurrentAmount))}
              </StyledProductGrid>
            </td>
          </tr>
        }
      </CategoryRows>
    )
  }


  // ProductCard or ProductListItem
  renderShoppingBasketArticle(shoppingBasketArticle, category = null, articlesHaveMaxAmounts = false, articlesHaveCurrentAmount = false) {
    const { productDetailsStore, teamOrderStore, productsStore } = this.props
    const getColumns = () => {
      var columns = ['article', 'price', 'amountToOrder']
      if (articlesHaveCurrentAmount) {
        columns.splice(2, 0, 'current')
      }
      if (articlesHaveMaxAmounts) {
        columns.splice(2, 0, 'max')
      }
      return columns
    }
    const articleAvailability = shoppingBasketArticle.articleAvailability || {}
    const articleSum = productsStore.filteredContingents.reduce((prev, obj) => ((obj.categories ? obj.categories.reduce((prev, obj) => (obj.articles ? obj.articles.length : 0) + prev, 0) : 0) + prev), 0)
    const maxArticles = 30
    const expandedOnDefault = articleSum <= maxArticles
    const shouldHidePricesAndPoints = productsStore.hidePricesAndPoints || shoppingBasketArticle.pricePerItem === null
    
    return (
      this.state.showListView ?
        <ProductListItem
          key={shoppingBasketArticle.article.id}
          articleId={shoppingBasketArticle.article.id}
          shoppingBasketArticle={shoppingBasketArticle}
          iconType={category ? category.iconType : null}
          smallImage={teamOrderStore.selectedCostcenterId ? true : false}
          columns={getColumns()}
          expandedOnDefault={expandedOnDefault}
          shouldHidePricesAndPoints={shouldHidePricesAndPoints}
        />
        :
        <ProductCard
          key={shoppingBasketArticle.article.id}
          id={shoppingBasketArticle.article.id}
          name={shoppingBasketArticle.article.displayName}
          articleNumber={shoppingBasketArticle.article.articleNumber}
          subDescription=''
          price={formatedPriceObject(shoppingBasketArticle.pricePerItem || {})}
          amountToOrder={articleAvailability.amountToOrder}
          current={articleAvailability.currentAmount}
          max={articleAvailability.maxAmount}
          image={shoppingBasketArticle.article.image}
          isInactive={ProductsOverviewPage.isProductInactive(shoppingBasketArticle)}
          isAmountMaxedOut={ProductsOverviewPage.isProductAmountMaxedOut(shoppingBasketArticle)}
          badge={productDetailsStore.getProductBadge(shoppingBasketArticle)}
          to={productDetailsStore.getProductToUrl(shoppingBasketArticle)}
          iconType={category ? category.iconType : null}
          onClick={() => { productDetailsStore.setShoppingBasketArticle(shoppingBasketArticle); productDetailsStore.setScrollRef(shoppingBasketArticle.article.id) }}
          shouldHidePricesAndPoints={shouldHidePricesAndPoints}
        />
    )
  }


  //for personal and employees order
  renderContingentArticlesOverview() {
    const { t, productsStore } = this.props
    const filteredContingents = productsStore.filteredContingents
    const contingents = productsStore.contingents || []
    const noSelectedContingentId = productsStore.useEntitlementSelection && !productsStore.selectedContingentId
    const noContingents = !productsStore.loadContingentIsLoading && !contingents.length
    const errorNoProducts = noContingents || ((filteredContingents.length === 0) && !noSelectedContingentId)
    const articlesHaveMaxAmounts = contingents.some(contingent => contingent.categories.some(category => category.articles.some(article => article.articleAvailability && article.articleAvailability.maxAmount)))
    const articlesHaveCurrentAmount = contingents.some(contingent => contingent.categories.some(category => category.articles.some(article => article.articleAvailability && article.articleAvailability.currentAmount)))

    return (
      <React.Fragment>
        <PageLoadingLayer
          isLoading={
            productsStore.contingents === null
          }
        >
          {errorNoProducts &&
            <Alert className="mt-4" variant="info">
              {t("productsOverview:noProductsAvailable")}
            </Alert>
          }

          <Table borderless>
            <tbody>
              {noSelectedContingentId
                ? contingents.map((contingent) => (
                  <ContingentRows key={contingent.id} contingent={contingent} onClickHandler={() => productsStore.onSwitchContingentHandler(contingent.id)} />
                ))
                : filteredContingents.map((contingent) => (
                  <ContingentRows key={contingent.id} contingent={contingent}>
                    {contingent.categories.map((category) =>
                      this.renderCategory(category, category.articles, articlesHaveMaxAmounts, articlesHaveCurrentAmount)
                    )}
                  </ContingentRows>
                ))
              }
            </tbody>
          </Table>
        </PageLoadingLayer>
      </React.Fragment>
    );
  }


  //for costcenter and private order
  renderCatalogArticlesOverview() {
    const { t, teamOrderStore, productsStore } = this.props

    return (
      <div className='mt-4'>
        {teamOrderStore.orderableArticlesError
          ? <Alert variant='danger'>{teamOrderStore.orderableArticlesError}</Alert>
          : <React.Fragment>
            {teamOrderStore.noArticlesAvailable
              ? <Alert variant='info'>{t('productsOverview:noProductsAvailable')}</Alert>
              : <PageLoadingLayer isLoading={teamOrderStore.orderableArticles.length === 0}>
                {this.state.showListView
                  ? <>
                    <MediaQuery minWidth={993}>
                      <Table borderless>
                        <thead>
                          <tr>
                            <th>{t('productsOverview:article')}</th>
                            <th>{!productsStore.hidePricesAndPoints ? t('price') : ''}</th>
                            <th>{t('productsOverview:amountInBasket')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {teamOrderStore.orderableArticles.map(shoppingBasketArticle => this.renderShoppingBasketArticle(shoppingBasketArticle))}
                        </tbody>
                      </Table>
                    </MediaQuery>
                    <MediaQuery maxWidth={992}>
                      {teamOrderStore.orderableArticles.map(shoppingBasketArticle => this.renderShoppingBasketArticle(shoppingBasketArticle))}
                    </MediaQuery>
                  </>
                  : <StyledProductGrid>
                    {teamOrderStore.orderableArticles.map(shoppingBasketArticle => this.renderShoppingBasketArticle(shoppingBasketArticle))}
                  </StyledProductGrid>
                }
                {((teamOrderStore.orderableArticlesAmount > teamOrderStore.orderableArticlesOffset) && !teamOrderStore.orderableArticlesIsLoading) ?
                  (<LazyLoad
                    fetchData={() => teamOrderStore.fetchOrderableArticles()}
                  />) : ''}
              </PageLoadingLayer>
            }
          </React.Fragment>
        }
      </div>
    )
  }

  render() {
    const { t, teamOrderStore, productsStore, productListStore, productDetailsStore, navigate } = this.props

    const processType = teamOrderStore.getCurrentProcessType()
    if (!productsStore.basket) { return null }
    return (
      <React.Fragment>
        {processType === 'costCenter' &&
          <Subheader className="subHeader-v1">
            <div className="flex-grow-1">
              <SubheaderTitle withBackButton defaultBackTo="/costcenter" goBackLabel={t('backButton')} />
            </div>
            {productsStore.infoText && <TiInfoLarge title={t('info')} className="infoIcon" role="button" onClick={() => productsStore.openOrderInfoModal()} />}
          </Subheader>
        }
        {processType === 'storeHouse' &&
          <Subheader className="subHeader-v1">
            <div className="flex-grow-1">
              <SubheaderTitle withBackButton defaultBackTo="/storehouse" goBackLabel={t('backButton')} />
            </div>
            {productsStore.infoText && <TiInfoLarge title={t('info')} className="infoIcon" role="button" onClick={() => productsStore.openOrderInfoModal()} />}
          </Subheader>
        }
        {processType === 'private' &&
          <Subheader className="subHeader-v1">
            <div className="flex-grow-1">
              <SubheaderTitle title={t('header:privateOrder')} />
            </div>
            {productsStore.infoText && <TiInfoLarge title={t('info')} className="infoIcon" role="button" onClick={() => productsStore.openOrderInfoModal()} />}
          </Subheader>
        }
        {processType === 'personal' &&
          <SubheaderProductsOverview />
        }
        <FlexWrapper>
          <SidebarLeft>
            <StyledCatalogViewSwitch>
              <Form.Check
                type="switch"
                label={t('productsOverview:listView')}
                onChange={this.handleChangeProductsListView}
                id="list-view-switch"
                checked={this.state.showListView}
              />
            </StyledCatalogViewSwitch>
            {processType === 'personal' && this.hasPersonalOrderSetting('allowAddArticles') && (
              <StyledCatalogViewSwitch>
                <Form.Check
                  type="switch"
                  label={t('productsOverview:labelSingleItems')}
                  onChange={this.handleChangeCatalogView}
                  id="catalog-view-switch"
                  checked={productsStore.showCatalogArticles}
                />
              </StyledCatalogViewSwitch>
            )}
            {productsStore.showCatalogArticles && <CatalogSelector />}
            {productsStore.showCatalogArticles ? <CatalogProductsSearch /> : <ContingentProductsSearch />}
            {processType === 'personal' && <><ArticleScanButton className="w-100" onClick={() => productsStore.openArticleScanModal()} /><hr></hr></>}
            {productsStore.showCatalogArticles && <SearchCriteriaCatalogCategories />}
            {productsStore.showCatalogArticles ? <CatalogCategories /> : <OffCanvasArea />}
            {
              productsStore.initialClothing && this.state.showListView ? <AddToBasketButton
                buttonText={t('productDetail:addToBasketButton')}
                onClick={() => productListStore.handleAddToBasketExtreme(navigate)}
                variant={'primary'}
                className="w-100 mb-2 button-reversed"
                type={'submit'}
                loadingText={t('productDetail:buttonWaitText')}
                isLoading={productListStore.addToBasketExtremeIsLoading}
              /> : null
            }
            {productListStore.addToBasketExtremeErrorOccurred ? <Alert variant="danger"><Trans i18nKey='EmployeeFormSidebar:errorOccurred' /></Alert> : null}
          </SidebarLeft >
          <ContentArea>
            {productsStore.showCatalogArticles
              ? this.renderCatalogArticlesOverview()
              : this.renderContingentArticlesOverview()}

          </ContentArea>
        </FlexWrapper >
        <BigFooter />
        <StickyShoppingBasket />
        {
          productsStore.showSwitchContingentModal &&
          <ModalComponent2
            ModalTitle={t('productsOverview:switchContingent')}
            BodyPart1={t('productsOverview:areYouSureEmptyBasket')}
            button1Text={t('okButton')}
            button1Function={() => productsStore.onSwitchContingentModalAction()}
            button1Loading={productsStore.switchContingentIsLoading}
            functionCancel={() => productsStore.closeSwitchContingentModal()}
          />
        }
        {
          productsStore.showOrderInfoModal &&
          <ModalComponent2
            ModalTitle={productsStore.orderInfoModalTitle}
            BodyPart1={<SafelySetInnerHTML>{productsStore.infoText}</SafelySetInnerHTML>}
            withoutCloseButton
            functionCancel={() => productsStore.closeOrderInfoModal()}
            button1Text={t('okButton')}
            button1Function={() => productsStore.closeOrderInfoModal()}
          />
        }
        {productsStore.showArticleScanModal &&
          <ArticleScan
            basketId={productsStore.basket.id}
            getArticleUrl={(article) => productDetailsStore.getProductToUrl(article)}
          />}

        {productListStore.addToBasketExtremeIsLoading ? <LoadingExtremeLoadingLayer /> : null}
      </React.Fragment >
    )
  }
}

export default withTheme(withTranslation()(withRouter(inject('uiStore', 'productsStore', 'teamOrderStore', 'profileStore', 'productDetailsStore', 'productListStore')(observer(ProductsOverviewPage)))))
