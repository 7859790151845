import React, { useState } from 'react'
import { Image, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { StyledDescriptionBlock, StyledTitle } from './ProductDetailStyles'
import ProductGalleryModal from '../ProductGallery/ProductGalleryModal'
import SafelySetInnerHTML from '../SafelySetInnerHTML'

const ProductDetailBlock = ({ article, additionalImages = [], withTitle }) => {
    const [openGalleryModal, setOpenGalleryModal] = useState(null)

    const { t } = useTranslation()
    return (
        <StyledDescriptionBlock>
            {withTitle ? <StyledTitle className="h1">{t('productDetail:productDetails')}</StyledTitle> : null}
            <Row>
                {additionalImages.length ? (
                    <Col>
                        <>
                            {additionalImages.map((img, index) => (
                                <Image style={{ cursor: "zoom-in" }} key={index} src={img.url} alt='' thumbnail onClick={() => setOpenGalleryModal({ startIndex: index })} />
                            ))}
                        </>
                    </Col>
                ) : null
                }
                <Col>
                    {article.additionalInfo.split("\n").map((s, i) =>
                        <React.Fragment key={i}>
                            <SafelySetInnerHTML>{s}</SafelySetInnerHTML>
                        </React.Fragment>)}
                </Col>
            </Row>
            {openGalleryModal && <ProductGalleryModal startIndex={openGalleryModal.startIndex} handleClose={() => setOpenGalleryModal(null)} detailImages={additionalImages} />}
        </StyledDescriptionBlock>)
}

export default ProductDetailBlock
