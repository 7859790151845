import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ProductsCardCompare from './ProductsCardCompare';


const ProductsCompare = ({ articles }) => {
  const responsive = {

    desktop: {
      breakpoint: { max: 4000, min: 1401 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1400, min: 991 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 990, min: 0 },
      items: 1,
    }
  };

  return (
    <div >
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={false}
        itemClass="carousel-item-padding-20-px"
      >
        {
          articles.map(article => {
            return (
              <ProductsCardCompare
                key={article.id}
                articleId={article.id}
                articleTo={article.to}
                articlePrice={article.price}
              />
            )
          })
        }
      </Carousel>
    </div >
  )
}

export default ProductsCompare