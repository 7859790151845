import React from "react";
import styled from "styled-components";
import { colors } from "../../../styles/colors";

const SidebarWrapper = styled.div`
  background: ${colors.gray6};
  border-right: 1px solid ${colors.gray2};
  overflow: auto;
  color: ${colors.gray5};
  min-width: 320px;

  @media screen and (min-width: 1081px) {
    left: 0;
    overflow: visible;
    max-width: 30vh;
    
  }
`;

const StyledSidebar = styled.div`
padding: 20px 20px 20px 36px;

@media screen and (min-width: 1080px) {
  position: sticky;
  top: 50px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #F1F1F1;
    box-shadow: inset 0 0 1px grey;
  }
  ::-webkit-scrollbar-thumb {
    background: #DBDBDB;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #9E9D9D;
  }
  scrollbar-width: thin;
  scrollbar-color: #DBDBDB #F1F1F1;
}
`

export const SidebarLeft = ({ children, ...rest }) => {
  return (
    <SidebarWrapper className="sidebar-v1" {...rest}>
      <StyledSidebar className="sidebarLeftContent">
        {children}
      </StyledSidebar>
    </SidebarWrapper>
  );
};
