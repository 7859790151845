import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import AddToBasketAnimation from './AddToBasketAnimation'

const StyledModal = styled(Modal)`
  .modal-content{
      background:rgba(0,0,0,.5);
  }
`

const LoadingExtremeLoadingLayer = () => {
  return (
    <StyledModal fullscreen show={true} >
      <Modal.Body className="p-0"  >
        <AddToBasketAnimation />
      </Modal.Body>
    </StyledModal >
  )
}

export default LoadingExtremeLoadingLayer