import React, { useCallback, useState } from 'react'
import { cloneDeep } from 'lodash'

import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { makeCall, setStateByKey } from '../../utils/api/makeCall'

const entitlementPhaseTemplate = {
  joinEntitlements: false,
  isPointDriven: false,
  isBoundToSingleEntitlement: false,
  name: {
    values: [],
  }
}

const EntitlementPhasesContext = React.createContext({})
export const useEntitlementPhasesState = () => {
  return React.useContext(EntitlementPhasesContext)
}

const EntitlementPhasesProvider = ({ children }) => {

  const [errorStates, setErrorStates] = useState({})
  const [loadingStates, setLoadingStates] = useState({})
  const [successStates, setSuccessStates] = useState({})
  const [entitlementPhases, setEntitlementPhases] = useState([])
  const [entitlementPhase, setEntitlementPhase] = useState(cloneDeep(entitlementPhaseTemplate))
  const [redirectId, setRedirectId] = useState('')
  const [deleteEntitlementPhaseId, setDeleteEntitlementPhaseId] = useState('')

  const getEntitlementPhases = useCallback(async (force) => {
    if ((entitlementPhases.length || loadingStates.entitlementPhases) && !force) {
      return null
    }
    else {
      await makeCall('entitlementPhases', async () => {
        let loadedEntitlementPhases = await apiClient.getJson(APIEndpoints.entitlementPhases)
        setEntitlementPhases(loadedEntitlementPhases)
      }, setErrorStates, setLoadingStates)
    }
  }, [entitlementPhases.length, loadingStates.entitlementPhases])

  async function getEntitlementPhase(entitlementPhaseId) {
    await makeCall('entitlementPhase', async () => {
      let loadedEntitlementPhase = await apiClient.getJson(APIEndpoints.administration(entitlementPhaseId).entitlementPhase)
      setEntitlementPhase(loadedEntitlementPhase)
    }, setErrorStates, setLoadingStates)
  }

  function postEntitlementPhase() {
    makeCall('saveEntitlementPhase', async () => {
      let newEntitlementPhase = await apiClient.postJson(APIEndpoints.administration().postEntitlementPhase, entitlementPhase)
      setRedirectId(newEntitlementPhase.id)
      setStateByKey(setSuccessStates, 'saveEntitlementPhase', true)
      getEntitlementPhases(true)

    }, setErrorStates, setLoadingStates)
  }

  function patchEntitlementPhase(entitlementPhaseId) {
    makeCall('saveEntitlementPhase', async () => {
      await apiClient.patchJson(APIEndpoints.administration(entitlementPhaseId).entitlementPhase, entitlementPhase)
      await getEntitlementPhase(entitlementPhaseId)
      setStateByKey(setSuccessStates, 'saveEntitlementPhase', true)
      getEntitlementPhases(true)

    }, setErrorStates, setLoadingStates)
  }

  function deleteEntitlementPhase(entitlementPhaseId) {
    makeCall('deleteEntitlementPhase', async () => {
      await apiClient.deleteJson(APIEndpoints.administration(entitlementPhaseId).entitlementPhase)
      await getEntitlementPhases(true)
      setDeleteEntitlementPhaseId('')
      setStateByKey(setSuccessStates, 'deleteEntitlementPhase', true)
    }, setErrorStates, setLoadingStates)
  }

  const resetEntitlementPhasesPage = useCallback(() => {
    setEntitlementPhase(cloneDeep(entitlementPhaseTemplate))
    setErrorStates({})
    setLoadingStates({})
    setSuccessStates({})
    setRedirectId('')
  }, [])

  const contextValues = {
    errorStates,
    loadingStates,
    getEntitlementPhases,
    entitlementPhases,
    entitlementPhase, setEntitlementPhase,
    successStates, setSuccessStates,
    redirectId,
    postEntitlementPhase,
    patchEntitlementPhase,
    deleteEntitlementPhase,
    getEntitlementPhase,
    resetEntitlementPhasesPage,
    deleteEntitlementPhaseId, setDeleteEntitlementPhaseId
  }

  return (
    <EntitlementPhasesContext.Provider value={contextValues}>
      {children}
    </EntitlementPhasesContext.Provider>
  )
}

export default EntitlementPhasesProvider