import { useState, useEffect } from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import { t } from 'i18next'

import { makeCall } from '../../utils/api/makeCall'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { ButtonWithLoadingState } from '../Buttons'


const BillsEmailModal = ({ billId, setShowEmailModal }) => {
    const [email, setEmail] = useState('')
    const [showSuccessAlert, setShowSuccessAlert] = useState(false)
    const [errorStates, setErrorStates] = useState({})
    const [loadingStates, setLoadingStates] = useState({})
    const [isInvalidEmail, setIsInvalidEmail] = useState(false)

    useEffect(() => {
        if (showSuccessAlert) {
            const timer = setTimeout(() => {
                setShowSuccessAlert(false)
            }, 3000)

            // Cleanup the timer
            return () => clearTimeout(timer)
        }
    }, [showSuccessAlert])

    useEffect(() => {
        if (errorStates.billingDetails) {
            const timer = setTimeout(() => {
                setErrorStates(prevState => ({
                    ...prevState,
                    billingDetails: null
                }));
            }, 3000)

            // Cleanup the timer
            return () => clearTimeout(timer)
        }
    }, [errorStates.billingDetails])

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!email || !emailPattern.test(email)) {
            setIsInvalidEmail(true)
            return false
        } else {
            setIsInvalidEmail(false)
            return true
        }
    }

    const handleChangeEmail = (e) => {
        setIsInvalidEmail(false)
        setEmail(e.target.value)
    }

    const handleSendEmail = () => {
        if (validateEmail(email)) {
            setShowSuccessAlert(false)
            let params = {
                billId: billId,
                mailAddress: email
            }
            makeCall('billingDetails', async () => {
                await apiClient.postJson(APIEndpoints.billingDetails, params)
                setShowSuccessAlert(true)
            }, setErrorStates, setLoadingStates)
        }
    }

    const handleClose = () => {
        setEmail('')
        setShowEmailModal(false)
    }

    return (
        <Modal show={true}>
            <Modal.Header>
                <Modal.Title>{t('bills:sendPerEmail')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mt-3">
                    <Form.Group>
                        <Form.Label>{t('bills:enterEmail')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Email"
                            name="email"
                            onChange={handleChangeEmail}
                            value={email}
                            isInvalid={isInvalidEmail}
                        />
                        <div style={{ minHeight: '25px', position: 'relative' }}>
                            <Form.Control.Feedback type="invalid" style={{ position: 'static', height: '25px', display: 'flex', alignItems: 'center' }}>
                                {isInvalidEmail && t('bills:invalidEmail')}
                            </Form.Control.Feedback>
                        </div>
                    </Form.Group>
                    <div className="my-0">
                        {showSuccessAlert && <Alert variant="success">{t('bills:emailSentSuccess')}</Alert>}
                        {errorStates.billingDetails && <Alert variant="danger">{t('bills:emailSentError')}</Alert>}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>{t('cancelButton')}</Button>
                <ButtonWithLoadingState
                    variant='primary'
                    buttonText={t('okButton')}
                    isLoading={loadingStates.billingDetails}
                    onClick={handleSendEmail}
                />
            </Modal.Footer>
        </Modal>
    )
}

export default BillsEmailModal