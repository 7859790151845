
import { observable, reaction, makeObservable } from 'mobx';
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { getErrorMessageFromApiResponse } from '../utils/api/apiUtils'
import axios from 'axios'

const CancelToken = axios.CancelToken;
var getTreeCancelToken;

class LaundryShelfStore {
    constructor(appStore) {
        makeObservable(this, {
            listItems: observable,
            selectedItems: observable,
            initSelectedItems: observable,
            submitIsLoading: observable,
            getListItemsError: observable,
            getBPListItemError: observable,
            submitBPLoundryShelvesError: observable,
            successLoundryShelvesSubmit: observable,
            dataHasChanged: observable,
            treeItems: observable,
            treeItemsIsLoading: observable
        });

        this.app = appStore
        reaction(
            () => this.selectedItems,
            selectedItems => {
                this.dataHasChanged = JSON.stringify(this.selectedItems) !== this.initSelectedItems
                this.submitBPLoundryShelvesError = false

            }
        )
        reaction(
            () => this.initSelectedItems,
            initSelectedItems => {
                this.dataHasChanged = JSON.stringify(this.selectedItems) !== this.initSelectedItems
            }
        )
    }

    listItems = [];
    selectedItems = [];
    initSelectedItems = "[]";
    submitIsLoading = false;
    getListItemsError = null;
    getBPListItemError = null;
    submitBPLoundryShelvesError = null;
    successLoundryShelvesSubmit = false;
    dataHasChanged = false;
    treeItems = [];
    treeItemsIsLoading = false;

    resetLaundryShelfStore() {
        this.listItems = []
        this.selectedItems = []
        this.initSelectedItems = "[]"
        this.submitIsLoading = false
        this.getListItemsError = null
        this.getBPListItemError = null
        this.submitBPLoundryShelvesError = null
        this.successLoundryShelvesSubmit = false
        this.dataHasChanged = false
        this.treeItems = []
        this.treeItemsIsLoading = false
    }
    resetSelectedItems() {
        this.selectedItems = JSON.parse(this.initSelectedItems)
        this.submitBPLoundryShelvesError = null
    }

    //select options
    async getListItems() {
        this.getListItemsError = null
        try {
            this.listItems = []
            this.listItems = await apiClient.getJson(APIEndpoints.laundryShelves().list)
        } catch (e) {
            this.getListItemsError = getErrorMessageFromApiResponse(e)
        }
    }

    //businesspartner assigned options
    async getBPListItem(employeeId) {
        this.getBPListItemError = null
        try {
            this.selectedItems = []
            let newSelectedItems = await apiClient.getJson(APIEndpoints.businessPartner(employeeId).laundryShelves)
            this.selectedItems = newSelectedItems
            this.initSelectedItems = JSON.stringify(newSelectedItems)
        } catch (e) {
            this.getBPListItemError = getErrorMessageFromApiResponse(e)
        }
    }

    //save selected options
    async submitBPLoundryShelves(employeeId) {
        if (this.app.uiStore.laundryShelf_Status !== "write") return null
        this.submitBPLoundryShelvesError = null
        this.submitIsLoading = true
        try {
            await apiClient.putJson(APIEndpoints.businessPartner(employeeId).laundryShelves, this.selectedItems)
            await this.getBPListItem(employeeId)
            this.successLoundryShelvesSubmit = true
        } catch (e) {
            this.submitBPLoundryShelvesError = getErrorMessageFromApiResponse(e)
        }
        finally {
            this.submitIsLoading = false
        }
    }

    //get tree
    async getTree(locationId) {
        if (getTreeCancelToken) {
            getTreeCancelToken.cancel("canceled")
        }
        getTreeCancelToken = CancelToken.source();
        try {
            this.treeItemsIsLoading = true
            this.treeItems = await apiClient.getJson(APIEndpoints.laundryShelves().tree, { locationId }, true, false, true, { cancelToken: getTreeCancelToken.token })

        } catch (e) {

        }
        this.treeItemsIsLoading = false
    }

    precheckMandatoryLaundryData() {
        if (this.app.uiStore.laundryShelf_IsMandatory && (!this.selectedItems.length || !this.selectedItems[0].displayName)) {
            this.submitBPLoundryShelvesError = true
            return false
        }
        return true
    }

}
export default LaundryShelfStore
