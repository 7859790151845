import React, { useCallback, useEffect, useState } from 'react'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import apiClient from '../../../utils/api/apiClient'
import { ErrorAlerts, makeCall } from '../../../utils/api/makeCall'
import { Button, Modal } from 'react-bootstrap'
import { ButtonWithLoadingState } from '../../Buttons'
import { t } from 'i18next'
import UniversalTableWrapperTest from '../../Table/UniversalTableWrapperTest'
import BootstrapTable from 'react-bootstrap-table-next'
import { useTableState } from '../../Table/TableProvider'

const NewsAssignmentsModal = ({ handleClose, newsId, currentMandator }) => {
  const [errorStates, setErrorStates] = useState({})
  const [loadingStates, setLoadingStates] = useState({})
  const [newsData, setNewsData] = useState({})
  const [subMandators, setSubmandators] = useState([])
  const { tableRef } = useTableState()

  const columns = [
    {
      dataField: '__assigneds',
      text: t('costCenterAdmin:isAssigned'),
      sort: false,
      formatter: (cell, row, rowIndex, extraProps) => {
        let mandatorId = row.id
        let assignedMandators = extraProps.newsData?.assignedMandators || []
        let checked = assignedMandators.includes(mandatorId)
        return <input id={row.id} type="checkbox" checked={checked} onChange={() => { }} />
      }, formatExtraData: {
        newsData
      }
    },
    {
      dataField: 'displayName',
      text: t('mandators'),
      sort: true,
    },
  ]


  const loadNews = useCallback(async () =>
    await makeCall('loadNews', async () => {
      let loadedNews = await apiClient.getJson(APIEndpoints.feedsAdministration(newsId).edit)
      setNewsData(loadedNews)
    }, setErrorStates, setLoadingStates), [newsId])

  const loadSubMandators = useCallback(async () =>
    await makeCall('loadSubMandators', async () => {
      let loadedNews = await apiClient.getJson(APIEndpoints.subMandators)
      setSubmandators([...loadedNews, currentMandator])
    }, setErrorStates, setLoadingStates), [currentMandator])

  const handleSave = () => {
    makeCall('saveNews', async () => {
      await apiClient.patchJson(APIEndpoints.feedsAdministration(newsId).edit, newsData)
      handleClose()
    }, setErrorStates, setLoadingStates)
  }

  useEffect(() => {
    loadNews()
    loadSubMandators()
  }, [loadNews, loadSubMandators])

  const handleCheckedBox = (cell, row, rowIndex) => {
    let mandatorId = row.id
    var assignedMandators = [...newsData.assignedMandators]

    let foundIndex = assignedMandators.findIndex(id => id === mandatorId)
    if (foundIndex >= 0) {
      assignedMandators.splice(foundIndex, 1)
    }
    else {
      assignedMandators.push(mandatorId)
    }
    setNewsData(prev => ({ ...prev, assignedMandators }))
  }

  return (

    <Modal size="lg" show={true} onHide={handleClose} scrollable>
      <Modal.Header closeButton >
        <Modal.Title>{t('newsAdmin:assignNews')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UniversalTableWrapperTest tableIsLoading={loadingStates.loadNews || loadingStates.loadSubMandators}>
          <BootstrapTable
            ref={tableRef}
            noDataIndication={() => { <div className="no-data-placeholder">No Data</div> }}
            bootstrap4
            keyField="id"
            hover
            data={subMandators}
            columns={columns}
            bordered={false}
            rowEvents={{ onClick: handleCheckedBox }}
          />
        </UniversalTableWrapperTest>
        <ErrorAlerts errorStates={errorStates} />
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoadingState
          buttonText={t('saveButton')}
          isLoading={loadingStates.saveNews}
          onClick={handleSave}
        />
        <Button variant="secondary" onClick={handleClose}>
          {t("closeButton")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default NewsAssignmentsModal