import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom'
import { BrowserRouter as Router } from "react-router-dom";
import LoginPage from './pages/LoginPage'
import { observer, inject } from 'mobx-react'
import ProductsOverviewPage from './pages/ProductsOverviewPage'
import ProductDetailsPage from './pages/ProductDetailsPage'
import CategoryGroupExample from './pages/CategoryGroupExample'
import Page404 from './pages/Page404'
import PersonalSpace from './pages/PersonalSpacePage'
import PrivateRoute from './pages/PrivateRoute'
import ShoppingCartPage from './pages/ShoppingCartPage'
import ConfirmAddressPage from './pages/ConfirmAddressPage'
import OrderSuccessPage from './pages/OrderSuccessPage'
import ConfirmOrderPage from './pages/ConfirmOrderPage'
import { ThemeProvider } from 'styled-components'
import { themes } from './styles/themes'
import ScrollToTop from './components/Page/ScrollToTop';
import CostcenterPage from './pages/CostcenterPage'
import OrderOnStockPage from './pages/OrderOnStockPage'
import OrdersPage from './pages/OrdersPage'
import OrdersDetailPage from './pages/OrdersDetailPage'
import SupplierOrderDetailPage from './pages/SupplierOrderDetailPage'
import OrderTemplatesPage from './pages/OrderTemplatesPage'
import AddEmployeePage from './pages/Employee/AddEmployeePage'
import EmployeesPage from './pages/EmployeesPage'
import EditEmployeePage from './pages/Employee/EditEmployeePage'
import EditHealthDataPage from './pages/Employee/EditHealthDataPage'
import EditAppointmentsPage from './pages/Employee/EditAppointmentsPage'
import EmployeesOrderTablePage from './pages/EmployeesOrderTablePage'
import ProductJobfunctionsPage from './pages/ProductJobfunctionsPage'
import ReportsPage from './pages/Administration/ReportsPage'
import AppointmentsAdministrationPage from './pages/Administration/AppointmentsAdministrationPage'
import SrmOrderAdministrationPage from './pages/Administration/SrmOrderAdministrationPage'
import AdministrationPage from './pages/Configuration/AdministrationPage'
import ConfigurationPage from './pages/Configuration/ConfigurationPage'
import OfficeAdministrationPage from './pages/Configuration/OfficeAdministrationPage'
import ActivitiesAdministrationPage from './pages/Configuration/ActivitiesAdministrationPage'
import CostCenterAdministrationPage from './pages/Configuration/CostCenterAdministrationPage'
import AppointmentPage from './pages/AppointmentPage'
import AppointmentTypesAdministrationPage from './pages/Configuration/AppointmentTypesAdministrationPage'
import CreateNewAppointmentTypePage from './pages/Configuration/CreateNewAppointmentTypePage'
import EditAppointmentTypePage from './pages/Configuration/EditAppointmentTypePage'
import CabinTypesAdministrationPage from './pages/Configuration/CabinTypesAdministrationPage'
import CreateNewCabinTypePage from './pages/Configuration/CreateNewCabinTypePage'
import EditCabinTypePage from './pages/Configuration/EditCabinTypePage'
import CreateNewOfficePage from './pages/Configuration/CreateNewOfficePage'
import CreateNewCostPage from './pages/Configuration/CreateNewCostPage'
import EditCostCenterPage from './pages/Configuration/EditCostCenterPage'
import EditOfficePage from './pages/Configuration/EditOfficePage'
import CostAccessRights from './components/Configuration/CostCenter/CostAccessRights'
import InterfaceCostCenterTree from './components/Configuration/CostCenter/InterfaceCostCenterTree'
import OfficeAccessRights from './components/Configuration/Office/OfficeAccessRights'
import CopyOfficePage from './pages/Configuration/CopyOfficePage'
import CopyCostPage from './pages/Configuration/CopyCostPage'
import TestDashboard from './pages/TestDashboard'
import CustomHtmlPage from './pages/CustomHtmlPage'
import BulkOrderPage from './pages/BulkOrderPage'
import InventoryPage from './pages/InventoryPage'
import InventoryDetailPage from './pages/InventoryDetailPage'
import InventoryHistoryPage from './pages/InventoryHistoryPage'
import InventoryActionPage from './pages/InventoryActionPage'
import SizeDetection from './pages/Administration/SizeDetection/SizeDetection'
import SizeDetectionByEmployee from './pages/Administration/SizeDetection/SizeDetectionByEmployee'
import CatalogProductDetailsPage from './pages/CatalogProductDetailsPage'
import CatalogProductsOverviewPage from './pages/CatalogProductsOverviewPage'
import InventoryReturnEmployeeSelect from './pages/InventoryReturnEmployeeSelect'
import Colors from './pages/Colors'
import InventoryImageUploadPage from './pages/InventoryImageUploadPage'
import CreateActivityPage from './pages/Configuration/CreateActivityPage'
import EditActivityPage from './pages/Configuration/EditActivityPage'
import EntitlementPhasesAdministrationPage from './pages/Configuration/EntitlementPhasesAdministrationPage'
import CreateEntitlementPhasePage from './pages/Configuration/CreateEntitlementPhasePage'
import EditEntitlementPhasePage from './pages/Configuration/EditEntitlementPhasePage'
import ContingentTemplatesAdministrationPage from './pages/Configuration/ContingentTemplatesAdministrationPage'
import CreateContingentTemplatePage from './pages/Configuration/CreateContingentTemplatePage'
import EditContingentTemplatePage from './pages/Configuration/EditContingentTemplatePage'
import CatalogsAdministrationPage from './pages/Configuration/CatalogsAdministrationPage';
import CreateCatalogPage from './pages/Configuration/CreateCatalogPage';
import EditCatalogPage from './pages/Configuration/EditCatalogPage';
import Testpage from './pages/Testpage';
import NewsAdministrationPage from './pages/Configuration/NewsAdministrationPage';
import PlaceholdersAdministrationPage from './pages/Configuration/PlaceholdersAdministrationPage';
import StdPointRunAdministrationPage from './pages/Configuration/StdPointRunAdministrationPage';



const orderRoutes = [
  {
    path: '/products/:orderType',
    component: <ProductsOverviewPage />


  },
  {
    path: '/products/:orderType/:employeeId',
    component: <ProductsOverviewPage />

  },
  {
    path: '/products/:orderType/:shoppingBasketArticleId/:employeeId',
    component: <ProductDetailsPage />


  },
  {
    path: '/order/:orderType/shoppingcart/:shoppingBasketId',
    component: <ShoppingCartPage />

  },
  {
    path: '/order/:orderType/shoppingcart/:shoppingBasketId/confirmaddress',
    component: <ConfirmAddressPage />

  },
  {
    path: '/order/:orderType/shoppingcart/:shoppingBasketId/confirmaddress/confirmorder',
    component: <ConfirmOrderPage />

  },
  {
    key: 'ordersuccess',
    path: '/order/:orderType/shoppingcart/:shoppingBasketId/confirmaddress/confirmorder/ordersuccess',
    component: <OrderSuccessPage />

  },
  {
    path: '/orderTemplates',
    component: <OrderTemplatesPage />

  },

]

const costcenterOrderRoutes = [
  {
    path: '/costcenter',
    component: <CostcenterPage />

  },
  {
    //used for CWS API from RLX News
    path: '/costCenterproducts/:shoppingBasketArticleId',
    component: <CostcenterPage />

  },
  {
    path: '/costcenter/:costCenterId/costcenterproducts',
    component: <ProductsOverviewPage />

  },
  {
    path: '/costcenter/:costCenterId/costcenterproducts/:shoppingBasketArticleId',
    component: <ProductDetailsPage />
  },
  {
    path: '/costcenter/:costCenterId/shoppingcart/:shoppingBasketId',
    component: <ShoppingCartPage />

  },
  {
    path: '/costcenter/:costCenterId/shoppingcart/:shoppingBasketId/confirmaddress',
    component: <ConfirmAddressPage />

  },
  {
    path: '/costcenter/:costCenterId/shoppingcart/:shoppingBasketId/confirmaddress/confirmorder',
    component: <ConfirmOrderPage />

  },
  {
    key: 'ordersuccess',
    path: '/costcenter/:costCenterId/shoppingcart/:shoppingBasketId/confirmaddress/confirmorder/ordersuccess',
    component: <OrderSuccessPage />

  }
]
const storehouseOrderRoutes = [
  {
    path: '/storehouse',
    component: <OrderOnStockPage />

  },
  {
    path: '/storehouse/:storeHouseId/:costCenterId/products',
    component: <ProductsOverviewPage />

  },
  {
    path: '/storehouse/:storeHouseId/:costCenterId/products/:shoppingBasketArticleId',
    component: <ProductDetailsPage />
  },
  {
    path: '/storehouse/:storeHouseId/:costCenterId/shoppingcart/:shoppingBasketId',
    component: <ShoppingCartPage />

  },
  {
    path: '/storehouse/:storeHouseId/:costCenterId/shoppingcart/:shoppingBasketId/confirmaddress',
    component: <ConfirmAddressPage />

  },
  {
    path: '/storehouse/:storeHouseId/:costCenterId/shoppingcart/:shoppingBasketId/confirmaddress/confirmorder',
    component: <ConfirmOrderPage />

  },
  {
    key: 'ordersuccess',
    path: '/storehouse/:storeHouseId/:costCenterId/shoppingcart/:shoppingBasketId/confirmaddress/confirmorder/ordersuccess',
    component: <OrderSuccessPage />

  }
]
const employeeRoutes = [
  {
    path: '/addemployee',
    component: <AddEmployeePage />

  },
  {
    path: '/editemployee/:employeeId',
    component: <EditEmployeePage />

  },
  {
    path: '/editcheckups/:employeeId',
    component: <EditHealthDataPage />

  },
  {
    path: '/editappointments/:employeeId',
    component: <EditAppointmentsPage />

  },
  {
    path: '/employees',
    component: <EmployeesPage />

  },
  {
    path: '/employeesordertable',
    component: <EmployeesOrderTablePage />

  },
  {
    path: '/productjobfunction/:employeeId',
    component: <ProductJobfunctionsPage />

  },
  {
    path: '/appointment/:employeeId',
    component: <AppointmentPage />

  },
  {
    path: '/appointment/:employeeId/:appointmentId',
    component: <AppointmentPage />

  }
]

let restrictedRoutes = [
  {
    path: '/',
    component: <TestDashboard />
  },
  {
    path: '/wysiwyg',
    component: <Testpage />
  },
  {
    path: '/inventory',
    component: <InventoryPage />

  },
  {
    path: '/inventory/:employeeId',
    component: <InventoryPage />

  },
  {
    path: '/inventoryDetails/:contingentArticleId',
    component: <InventoryDetailPage />

  },
  {
    path: '/inventoryDetails/:employeeId/:contingentArticleId',
    component: <InventoryDetailPage />

  },

  {
    path: '/inventoryHistory',
    component: <InventoryHistoryPage />

  },
  {
    path: '/inventoryHistory/:employeeId',
    component: <InventoryHistoryPage />

  },
  {
    path: '/inventoryAction/:actionType',
    component: <InventoryActionPage />

  },
  {
    path: '/inventoryAction/:actionType/:employeeId',
    component: <InventoryActionPage />

  },

  //für backToOrgaunit und backToEmployee
  //from logged in user to someone else
  {
    path: '/inventoryreturnto/:actionType/:targetId',
    component: <InventoryActionPage />

  },
  //from someone else to someone else
  {
    path: '/inventoryreturnto/:actionType/:employeeId/:targetId',
    component: <InventoryActionPage />

  },
  {
    path: '/inventoryBookings/imageUpload',
    component: <InventoryImageUploadPage />

  },
  {
    path: '/inventoryBookings/imageUpload/:employeeId',
    component: <InventoryImageUploadPage />

  },
  {
    path: '/inventoryreturnemployeeselect/:employeeId',
    component: <InventoryReturnEmployeeSelect />

  },
  {
    path: '/inventoryreturnemployeeselect',
    component: <InventoryReturnEmployeeSelect />

  },

  {
    path: '/personalSpace',
    component: <PersonalSpace />

  },
  {
    path: '/categorygroup-example',
    component: <CategoryGroupExample />

  },
  {
    path: '/orders',
    component: <OrdersPage />

  },
  {
    path: '/employee-orders/:employeeId',
    component: <OrdersPage />

  },
  {
    path: '/orders/:incomingOrderId',
    component: <OrdersDetailPage />

  },
  {
    path: '/orders/:incomingOrderId/supplierOrder/:supplierOrderId',
    component: <SupplierOrderDetailPage />

  },
  {
    path: '/orders/:incomingOrderId/:print',
    component: <OrdersDetailPage />

  },
  {
    path: '/reports',
    component: <ReportsPage />

  },
  {
    path: '/appointmentsadministration',
    component: <AppointmentsAdministrationPage />

  },
  {
    path: '/newsAdministration',
    component: <NewsAdministrationPage />

  },
  {
    path: '/srmorderadministration',
    component: <SrmOrderAdministrationPage />

  },
  {
    path: '/colors',
    component: <Colors />

  },
  {
    path: '/articleCatalog/article/:articleId',
    component: <CatalogProductDetailsPage />

  },
  {
    path: '/articleCatalog',
    component: <CatalogProductsOverviewPage />

  }
]

const administrationRoutes = [
  {
    path: '/administration',
    component: <AdministrationPage />

  },
  {
    path: '/configuration',
    component: <ConfigurationPage />

  },
  {
    path: '/activitiesAdministration',
    component: <ActivitiesAdministrationPage />

  },
  {
    path: '/activitiesAdministration/create',
    component: <CreateActivityPage />

  },
  {
    path: '/activitiesAdministration/edit/:activityId',
    component: <EditActivityPage />

  },
  {
    path: '/contingentTemplatesAdministration',
    component: <ContingentTemplatesAdministrationPage />

  },
  {
    path: '/contingentTemplatesAdministration/createContingent',
    component: <CreateContingentTemplatePage />

  },
  {
    path: '/contingentTemplatesAdministration/editContingent/:contingentTemplateId',
    component: <EditContingentTemplatePage />

  },
  {
    path: '/entitlementPhasesAdministration',
    component: <EntitlementPhasesAdministrationPage />

  },
  {
    path: '/entitlementPhasesAdministration/create',
    component: <CreateEntitlementPhasePage />

  },
  {
    path: '/entitlementPhasesAdministration/edit/:entitlementPhaseId',
    component: <EditEntitlementPhasePage />

  },
  {
    path: '/officeadministration',
    component: <OfficeAdministrationPage />

  },
  {
    path: '/officeadministration/create',
    component: <CreateNewOfficePage />

  },
  {
    path: '/officeadministration/create/access-rights',
    component: <OfficeAccessRights />

  },
  {
    path: '/officeadministration/edit/:officeId',
    component: <EditOfficePage />

  },
  {
    path: '/officeadministration/copy/:officeId',
    component: <CopyOfficePage />

  },
  {
    path: '/stdPointRunAdministration',
    component: <StdPointRunAdministrationPage />

  },
  {
    path: '/costcenteradministration/create',
    component: <CreateNewCostPage />

  },

  {
    path: '/costcenteradministration/edit/:costCenterId',
    component: <EditCostCenterPage />

  },
  {
    path: '/costcenteradministration/copy/:costCenterId',
    component: <CopyCostPage />

  },
  {
    path: '/costcenteradministration',
    component: <CostCenterAdministrationPage />

  },
  {
    path: '/costcenteradministration/create/access-rights',
    component: <CostAccessRights />

  },
  {
    path: '/costcenteradministration/create/interfaces',
    component: <InterfaceCostCenterTree />

  },
  {
    path: '/placeholdersAdministration',
    component: <PlaceholdersAdministrationPage />

  },
  {
    path: '/appointmenttypes',
    component: <AppointmentTypesAdministrationPage />

  },
  {
    path: '/appointmenttypes/create',
    component: <CreateNewAppointmentTypePage />

  },
  {
    path: '/appointmenttypes/edit/:appointmentTypeId',
    component: <EditAppointmentTypePage />

  },
  {
    path: '/cabintypes',
    component: <CabinTypesAdministrationPage />

  },
  {
    path: '/cabintypes/create',
    component: <CreateNewCabinTypePage />

  },
  {
    path: '/cabintypes/edit/:cabinTypeId',
    component: <EditCabinTypePage />

  },
  {
    path: '/catalogsAdministration',
    component: <CatalogsAdministrationPage />
  },
  {
    path: '/catalogsAdministration/createCatalog',
    component: <CreateCatalogPage />

  },
  {
    path: '/catalogsAdministration/editCatalog/:catalogId',
    component: <EditCatalogPage />

  },
  //NEW ROUTES FOR SIZE DETECTION FEATURE
  {
    path: '/sizeDetection',
    component: <SizeDetection />

  },
  {
    path: '/sizeDetection/:employeeId',
    component: <SizeDetectionByEmployee />

  }
]

const bulkOrderRoutes = [
  {
    path: '/bulkorder',
    component: <BulkOrderPage />

  }
]

const cwsMenuRoutes = [
  {
    path: '/custom-html/:menuId',
    component: <CustomHtmlPage />

  }
]
export const appRoutes = restrictedRoutes
  .concat(orderRoutes)
  .concat(costcenterOrderRoutes)
  .concat(storehouseOrderRoutes)
  .concat(employeeRoutes)
  .concat(administrationRoutes)
  .concat(cwsMenuRoutes)
  .concat(bulkOrderRoutes)


class LogixRoutes extends Component {
  constructor(props) {
    super(props)
    if (!props.uiStore.reloading) {
      //reset LoginStore.isUserLoggedIn and LoginStore.apiAccessToken
      props.loginStore.setApiAccessToken(sessionStorage.getItem('apiAccessToken') ? JSON.parse(sessionStorage.getItem('apiAccessToken')) : null)
    }
  }
  //todo move SSOLogin before ThemeInit. best case inside Router but then basename can not be what it is atm
  render() {
    const { uiStore } = this.props
    const urlPrefix = `${'/' + uiStore.theme + '/' + uiStore.language + '/'}`
    const theme = themes[uiStore.theme]

    if (this.props.uiStore.reloading) {
      return ''
    }
    return (
      <Router basename={process.env.PUBLIC_URL + urlPrefix}>
        <ThemeProvider theme={theme}>
          <ScrollToTop >
            <Routes >
              {/* Login routes */}
              {appRoutes.map(obj => <Route path={obj.path} key={obj.path} element={<PrivateRoute>{obj.component}</PrivateRoute>} />)}
              {['/login', '/password-reset', '/sign-up'].map(path => <Route path={path} key={path} element={<LoginPage />} />)}
              {/* Fallback 404 Page */}
              <Route path='*' element={<Page404 />} />
            </Routes>
          </ScrollToTop >
        </ThemeProvider>
      </Router >
    )
  }
}

export default inject('uiStore', 'loginStore')(observer(LogixRoutes));
