import React, { useState, useEffect } from 'react'
import QRCode from "react-qr-code";

const PrintQr = ({ order }) => {
    const [print, setPrint] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setPrint(true)
        }, 2000);
    }, [])

    useEffect(() => {
        if (print) {
            window.print()
            window.close()
        }
    }, [print, setPrint])

    return (
        <div>
            <QRCode value={order.orderNr} />
        </div>
    )
}

export default PrintQr
