import React, { useState, useEffect } from 'react'
import BigFooter from '../components/Footer/BigFooter'
import { Subheader } from '../components/Page/logix2020/Subheader'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import QrButton from '../components/Buttons/QrButton'
import { useTranslation } from 'react-i18next'
import CustomDropdown from '../components/Dropdowns/CustomDropdown'
import QrModal from '../components/Modals/QrModal'

import OrdersList from '../components/Orders/OrdersList'
import { observer, inject } from 'mobx-react'
import { useLocation, useNavigate } from 'react-router-dom'
import OrdersSearchField from '../components/Orders/OrdersSearchField'
import { cloneDeep } from 'lodash'
import { getErrorMessageFromApiResponse } from "../utils/api/apiUtils"
import CostCenterSearchField from '../components/Orders/CostCenterSearchField'
import OrdersArticleSearchField from '../components/Orders/OrdersArticleSearchField'
import { SidebarLeft } from '../components/Page/logix2020/SidebarLeft'
import { FlexWrapper } from '../components/Page/logix2020/FlexWrapper'
import { playScanErrorSound, playScanOkSound } from '../utils/playSounds'


const OrderTemplatesPage = inject('ordersStore')(observer(props => {
    const { t } = useTranslation()
    const [openQrModal, setOpenQrModal] = useState(false)
    const [qrModalError, setQrModalError] = useState('')
    const { ordersStore } = props;
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const { state } = location
        ordersStore.updateState(state || { selectedStatus: 'template' })
        ordersStore.loadIncomingOrders()
    }, [location, ordersStore])

    const handleQrResult = async (orderNr) => {
        playScanOkSound()
        setQrModalError('')
        const order = ordersStore.incomingOrders.find(order => order.orderNr === orderNr)
        if (order) {
            try {
                const shoppingCart = await ordersStore.reorder(order.id)
                navigate(`/costcenter/${order.targetCostCenter.id}/shoppingcart/${shoppingCart.id}/confirmaddress/confirmorder`)
            }
            catch (e) {
                setQrModalError(getErrorMessageFromApiResponse(e))
                playScanErrorSound()
            }
        }
        else {
            setQrModalError(t('orderTemplates:orderNrError', { orderNr }))
        }
    }

    const input = [
        {
            name: t('orders:lastMonth'),
            value: 1
        },
        {
            name: t('orders:last3month'),
            value: 3
        },
        {
            name: t('orders:last6month'),
            value: 6
        },
        {
            name: t('orders:lastYear'),
            value: 12
        },
        {
            name: t('all'),
            value: 0
        }
    ]
    const inputWho = [
        {
            name: t('orderTemplates:myTemplates'),
            value: false
        },
        {
            name: t('orderTemplates:allTemplates'),
            value: true
        }
    ]
    const handleChange = (name, value) => {
        var newState = location.state ? cloneDeep(location.state) : {}
        newState[name] = value

        navigate('/orderTemplates', {
            replace: true, state: newState
        });
    }


    return (
        <>
            <Subheader className="subHeader-v1">
                <div className="subheader-title ">
                    {t('orderTemplates:orderTemplates')}
                </div>
            </Subheader>
            <FlexWrapper>
                <SidebarLeft >
                    <div className="mb-3"> <CustomDropdown value={ordersStore.showAllOrders} data={inputWho} returnObject={(obj) => handleChange("showAllOrders", obj.value)} /></div>
                    <div className="mb-3"> <CustomDropdown value={ordersStore.lastMonths} data={input} returnObject={(obj) => handleChange("lastMonths", obj.value)} /></div>
                    <div className="mb-3"> <CostCenterSearchField handleChange={handleChange} /></div>
                    <div className="mb-3">   <OrdersArticleSearchField handleChange={handleChange} /></div>
                    <div className="mb-3">  <OrdersSearchField placeholder={t('orderTemplates:templateNumber')} handleChange={handleChange} /></div>
                    <div className="mb-3"><QrButton
                        buttonText={'QR-Code'}
                        variant="secondary"
                        className="w-100"
                        onClick={() => setOpenQrModal(prev => !prev)} /></div>
                </SidebarLeft>
                <ContentArea>
                    <OrdersList />
                </ContentArea>
            </FlexWrapper>
            {openQrModal && <QrModal title={t('orderTemplates:qrScanner')} handleClose={() => { setOpenQrModal(false); setQrModalError('') }} qrModalError={qrModalError} handleResult={handleQrResult} />}
            <BigFooter />
        </>
    )
}))

export default OrderTemplatesPage
