import React from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import { Table } from 'react-bootstrap'
import styled from 'styled-components'
import { colors } from '../../../styles/colors'
import { supplierOrderStatusToString } from './supplierOrderStatusToString'
import { formatedDate } from '../../../utils/formatedDate'

const StyledRow = styled.div`
  padding: 10px;
    border-bottom: 1px solid ${colors.gray3};
`
const SupplierOrderList = ({ supplierOrderPositions }) => {
    const { t } = useTranslation()
    const articleNr = t('productDetail:articleNumber')
    const articleDescription = t('productDetail:articleDescription')
    const amount = t('amount')
    const stored = t('order:stored')
    const status = t('status')
    const expectedDeliveryDate = t('order:expectedDeliveryDate')
    const comment = t('comment')
    const showDeliveryDateColumn = supplierOrderPositions.find(position => position.dateOfDelivery) ? true : false
    const showCommentColumn = supplierOrderPositions.find(position => position.comment) ? true : false 
    
    return (
        <div>
            <MediaQuery maxWidth={992}>
                <>
                    {supplierOrderPositions.map((position, index) => (
                        <StyledRow key={`list-${index}`}>
                            <div className='fw-bold'>{articleNr}: {position.article ? position.article.articleNumber : null}</div>
                            <div>{articleDescription}: {position.article ? position.article.displayName : null}</div>
                            <div>{amount}: {position.quantity}</div>
                            <div>{stored}: {position.storedQuantity}</div>
                            <div>{status}: {supplierOrderStatusToString(position.status)}</div>
                            {showDeliveryDateColumn && <div>{expectedDeliveryDate}: {formatedDate(position.dateOfDelivery)}</div>}
                            {showCommentColumn && <div>{comment}: {position.comment}</div>}
                        </StyledRow>
                    ))}
                </>
            </MediaQuery>
            <MediaQuery minWidth={993}>

                <Table>
                    <thead>
                        <tr>
                            <th>{articleNr}</th>
                            <th>{articleDescription}</th>
                            <th>{amount}</th>
                            <th>{stored}</th>
                            <th>{status}</th>
                            {showDeliveryDateColumn && <th>{expectedDeliveryDate}</th>}
                            {showCommentColumn && <th>{comment}</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {supplierOrderPositions.map((position, index) => (
                            <tr key={index}>
                                <td>{position.article ? position.article.articleNumber : null}</td>
                                <td>{position.article ? position.article.displayName : null}</td>
                                <td>{position.quantity}</td>
                                <td>{position.storedQuantity}</td>
                                <td>{supplierOrderStatusToString(position.status)}</td>
                                {showDeliveryDateColumn && <td>{formatedDate(position.dateOfDelivery)}</td>}
                                {showCommentColumn && <td>{position.comment}</td>}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </MediaQuery>
        </div>
    )
}

export default SupplierOrderList
