import React, { useEffect, useState, useRef } from 'react'
import { ContingentIcon } from '../Icons/ContingentIcon'
import PdfIcon from '../../assets/icons/pdf.svg'
import ListCard from './ListCard'
import { useTranslation } from 'react-i18next'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import useStores from '../../stores/useStore'
import { formatedDate } from '../../utils/formatedDate'
import { Alert, Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import DownloadURLAlert from '../Forms/DownloadURLAlert'
import useReport from '../../utils/hooks/useReport'
import ReportSubscriptionModal from '../Files/Form/ReportSubscriptionModal'
// import { VscHistory } from 'react-icons/vsc'

const StyledWrapper = styled.div`

 .list-card__row {
    
    @media screen and (min-width: 1080px) and (max-width: 1300px), (max-width:400px) {
      flex-wrap: wrap;
    }

    .title {
        @media screen and (min-width: 1080px) and (max-width: 1300px), (max-width:400px) {
          flex-basis: 100%;
          order: 2;
          padding-left: 0;
        }
      }
 }
`

const PdfIconBtn = styled.span`
  background: url(${PdfIcon}) center center no-repeat;
  background-size: contain;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;

  :hover {
    opacity: 0.8;
  }
`

const AccountHistoryCard = () => {
  const [budgetBalances, setBudgetBalances] = useState([])
  const initialLimit = 5
  const [limit, setLimit] = useState(initialLimit)
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const { createReport, reportIsLoading, reportErrors, reportDownloadUrls, setReportDownloadUrls, reportSubscriptionId, setReportSubscriptionId } = useReport()

  const { profileStore } = useStores()
  const { profile } = profileStore
  const { t } = useTranslation()
  const mountedRef = useRef(true)

  useEffect(() => {
    if (profile.id) {
      setIsLoading(true)
      apiClient.getJson(APIEndpoints.businessPartner(profile.id).budgetBalances, {
        'page.offset': 0,
        'page.limit': limit
      }, true, true)
        .then(response => {
          if (!mountedRef.current) { return null }
          setIsLoading(false)
          setBudgetBalances(response.data)
          setTotalCount(Number(response.headers['x-total-count']))
        }).catch(error => console.log("error"))
    }

  }, [profile.id, limit])

  //Cleanup Function
  useEffect(() => {
    return () => { mountedRef.current = false }
  }, [])
  const formateChangereason = (changeReason) => {
    const matches = changeReason.match(/([^:]*:)(.*)/)
    return matches ? <>
      {matches.slice(1).map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </> : changeReason
  }

  const loadPdf = () => {
    createReport("reportName", APIEndpoints.budgethistory(profile.id), {})
  }

  return (
    <StyledWrapper>
      <ListCard>
        <p className="h2 list-card__title justify-content-between">
          <span>
            <i><ContingentIcon /></i>
            <span>{t('dashboard:accountHistory')}</span>
          </span>
          <span title="Download (PDF)" role="button" onClick={loadPdf}>
            {!reportIsLoading.reportName ? <PdfIconBtn /> : <Spinner as='span' animation='border' size='sm' variant='primary' role='status' />}
          </span>
        </p>
        {budgetBalances.length === 0 && (
          <div className="p-4"><b>{t('dashboard:noAccountHistory')}</b></div>
        )}
        {reportErrors.reportName && <Alert variant="danger">{reportErrors.reportName.value}</Alert>}
        <ul>
          {budgetBalances.map(item => (
            <li key={item.id} className="list-card__row">
              <span className="date">{formatedDate(item.dateCreation)}</span>
              <span className="date">{formateChangereason(item.changeReason)}</span>
              <span className="counter text-nowrap">{item.changeValue > 0 ? '+' : ''} {item.changeValue}</span>
            </li>
          ))}
        </ul>
        {isLoading && (
          <div className="d-flex justify-content-center p-3"><Spinner animation='border' variant='primary' /></div>
        )}
        <p >
          {limit > initialLimit &&
            <span className="show-less " onClick={() => { setLimit(initialLimit) }}>{t('dashboard:showLess')}</span>
          }
          {totalCount > limit && (
            <span className="show-more " onClick={() => { setLimit(limit + 5) }}>{t('dashboard:showMore')}</span>
          )}
        </p>

        {reportDownloadUrls.reportName && <DownloadURLAlert
          downloadUrl={reportDownloadUrls.reportName}
          setDownloadUrl={() => setReportDownloadUrls({})}
          fileName={t('employeesPage:accountBallance')}
        />}
        {reportSubscriptionId && <ReportSubscriptionModal
          reportSubscriptionId={reportSubscriptionId}
          setReportSubscriptionId={setReportSubscriptionId}
          message={t('reportConfig:createPending')}
        />}
      </ListCard>
    </StyledWrapper>
  )
}

export default AccountHistoryCard
