import React from 'react'
import { observer, inject } from 'mobx-react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import LocalStorehouseSelect from '../Forms/LocalStorehouseSelect'
import { useTranslation } from 'react-i18next'

const StyledSubHeadline = styled.span`
  display: block;
  color: ${colors.gray4}
  font-weight: bold;
  margin: 15px 0 5px;
`

const LocalStorehouse = inject('productDetailsStore')(observer((props) => {
  const { productDetailsStore } = props
  const { t } = useTranslation()
  const { localStorehouses } = productDetailsStore

  if (!localStorehouses || localStorehouses.length === 0) {
    return null
  }

  return (
    <div className="mb-3">
      <Form.Label>
        <StyledSubHeadline>{t('productDetail:localStorehouse')}</StyledSubHeadline>
      </Form.Label>
      <LocalStorehouseSelect
        localStorehouses={localStorehouses}
        selectedLocalStorehouseId={productDetailsStore.selectedLocalStorehouseId}
        handleChangeLocalStorehouse={productDetailsStore.handleChangeLocalStorehouse}
        selectedLocalStorehouseError={productDetailsStore.selectedLocalStorehouseError}
      />
    </div >
  )
}))
export default LocalStorehouse
