import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { RetourIcon } from '../../Icons/RetourIcon'
import { colors } from '../../../styles/colors'
import { ClaimIcon } from '../../Icons/ClaimIcon'
import { CancelOrderIcon } from '../../Icons/CancelOrderIcon'
import { withTranslation } from 'react-i18next'

class ListActionButtons extends Component {

  render () {
    const { t, buttonsDisabled, showCancelButton = false, showRetourButton = false, showClaimButton = false, clickClaim = null, clickCancel = null, clickRetour = null } = this.props

    return (
      <div className='d-flex justify-content-end'>
        {showCancelButton &&
        <div className='m-1'>
          <Button variant='outline-light' disabled={buttonsDisabled} onClick={clickCancel}><CancelOrderIcon
            color={colors.gray4} /> {t('order:cancelSelectedProducts')}</Button>
        </div>}
        {showRetourButton &&
        <div className='m-1'>
          <Button variant='outline-light' disabled={buttonsDisabled} onClick={clickRetour}><RetourIcon
            color={colors.gray4} /> {t('order:returnSelectedProducts')}</Button>
        </div>}
        {showClaimButton &&
        <div className='m-1'>
          <Button variant='outline-light' disabled={buttonsDisabled} onClick={clickClaim}><ClaimIcon
            color={colors.gray4} /> {t('order:claimSelectedProducts')}</Button>
        </div>}
      </div>
    )
  }
}

export default withTranslation()(ListActionButtons)
