import React, { useEffect, useState } from 'react'
import { Alert, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LanguageNameInput from '../../Forms/LanguageNameInput'
import { ButtonWithLoadingState } from '../../Buttons'
import OverlayContainer from '../../Page/logix2020/OverlayContainer'
import OverlayRight from '../../Page/logix2020/OverlayRight'
import LanguageForm from '../../Forms/LanguageForm'
import { useCatalogsState } from '../../../stores/Configuration/CatalogsProvider'
import InformationPopover from '../../InformationPopover/InformationPopover'
import CustomDatePicker from '../../Forms/CustomDatePicker'
import ArraySelect from '../../Select/ArraySelect'
import { emptyGuid } from '../../../utils/emptyGuid'
import { getIsoDate } from '../../../utils/getIsoDate'
import { emptyDate } from '../../../utils/emptyDate'
import { StyledValidationMessage } from '../../Employees/Form/formHelper'

const StyledFormWrapper = styled.div`
  max-width: 600px;
  @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px) {
    max-width: none;
  }
`


const CatalogBaseForm = ({ catalogId }) => {
  const { t } = useTranslation()
  const { catalogBaseObj, setCatalogBaseObj, initCatalogBaseObj, postCatalogTemplate, supplierMandators, suppliers, loadingStates, patchCatalogTemplate, errorStates } = useCatalogsState()
  const [overlayVisible, setOverlayVisible] = useState()
  const [isLimitedInTime, setIsLimitedInTime] = useState(false)

  useEffect(() => {
    initCatalogBaseObj(catalogId)
  }, [catalogId, initCatalogBaseObj])

  useEffect(() => {
    if (catalogBaseObj.activeFrom !== emptyDate) {
      setIsLimitedInTime(true)
    }
  }, [catalogBaseObj.activeFrom])

  const updateNames = (translations) => {
    setCatalogBaseObj(prev => ({
      ...prev,
      name: {
        ...prev.name,
        values: translations
      }
    }))
  }

  const updateCheckboxOrRadioProperty = (event, name) => {
    switch (name) {
      case 'active':
        setIsLimitedInTime(false)
        setCatalogBaseObj(prev => ({
          ...prev,
          isAlwaysActive: true,
          activeFrom: emptyDate,
          activeTo: emptyDate
        }))
        break
      case 'inactive':
        setIsLimitedInTime(false)
        setCatalogBaseObj(prev => ({
          ...prev,
          isAlwaysActive: false,
          activeFrom: emptyDate,
          activeTo: emptyDate
        }))
        break
      case 'limited':
        setCatalogBaseObj(prev => ({
          ...prev,
          isAlwaysActive: false
        }))
        setIsLimitedInTime(true)
        break
      case 'isInternalCatalog':
        setCatalogBaseObj(prev => ({
          ...prev,
          isExternalCatalog: false
        }))
        break
      case 'isExternalCatalog':
        setCatalogBaseObj(prev => ({
          ...prev,
          isExternalCatalog: true,
          isRightDependent: true,
          isPunchOutCatalog: true
        }))
        break
      case 'isNotRightsDependent':
        setCatalogBaseObj(prev => ({
          ...prev,
          isRightDependent: false
        }))
        break
      case 'isRightsDependent':
        setCatalogBaseObj(prev => ({
          ...prev,
          isRightDependent: true
        }))
        break
      case 'isPunchOutCatalog':
        setCatalogBaseObj(prev => ({
          ...prev,
          isPunchOutCatalog: event.target.checked,
          isShopLink: false
        }))
        break
      case 'isShopLink':
        setCatalogBaseObj(prev => ({
          ...prev,
          isShopLink: event.target.checked,
          isPunchOutCatalog: false
        }))
        break
      default:
    }
  }

  const handleChangeDate = (dateForm) => date => {
    if (date instanceof Date) {
      const preparedDate = getIsoDate(date)

      switch (dateForm) {
        case "activeFrom":
          setCatalogBaseObj(prev => ({
            ...prev,
            activeFrom: preparedDate
          }))
          break;
        case "activeTo":
          setCatalogBaseObj(prev => ({
            ...prev,
            activeTo: preparedDate
          }))
          break;
        default: console.log("Datum kann nicht gespeichert werden")
      }
    }
  }
  const handleSubmit = () => {
    catalogId ? patchCatalogTemplate(catalogId) : postCatalogTemplate()
  }

  const handleCloseOverlay = () => {
    setOverlayVisible(false)
  }



  return (
    <div>
      <StyledFormWrapper>
        <LanguageNameInput
          openLanguageModal={() => setOverlayVisible(true)}
          languageArray={catalogBaseObj.name.values}
          onChange={updateNames}
          label={t('name')}
          isInvalid={errorStates?.saveCatalogTemplate?.formErrors?.name}
        />

        <Row className="mb-3">
          <Form.Group>
            <Form.Label>{t('costCenterAdmin:foreignKey')}<InformationPopover header={t('costCenterAdmin:foreignKey')} content={t('officeAdmin:noteForeignKey')} /></Form.Label>
            <Form.Control
              id="foreignKey"
              name="foreignKey"
              placeholder={t('costCenterAdmin:foreignKey')}
              value={catalogBaseObj.foreignKey}
              disabled={catalogBaseObj.id !== emptyGuid}
              onChange={e => setCatalogBaseObj(prev => ({
                ...prev,
                foreignKey: e.target.value
              }))}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group>
            <Form.Label>{t('catalogsAdministration:catalogStatus')}</Form.Label>
            <Form.Check
              type="radio"
              id="inactiveStatus"
              name="catalogStatus"
              label={t('catalogsAdministration:inactive')}
              aria-label="Set the status of the catalog to inactive"
              checked={!catalogBaseObj.isAlwaysActive && !isLimitedInTime}
              onChange={(e) => updateCheckboxOrRadioProperty(e, 'inactive')}
            />
            <Form.Check
              type="radio"
              id="activeStatus"
              name="catalogStatus"
              label={t('catalogsAdministration:alwaysActive')}
              aria-label="Set the status of the catalog to active"
              checked={catalogBaseObj.isAlwaysActive}
              onChange={(e) => updateCheckboxOrRadioProperty(e, 'active')}
            />
            <Form.Check
              type="radio"
              id="limitedStatus"
              name="catalogStatus"
              aria-label="Set the status of the catalog to limited in time"
              label={t('catalogsAdministration:limitedInTime')}
              checked={isLimitedInTime}
              onChange={(e) => updateCheckboxOrRadioProperty(e, 'limited')}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>{t('From')}</Form.Label>
            <CustomDatePicker
              dateFormat="dd.MM.yyyy"
              placeholderText={t('date')}
              showYearDropdown
              selected={(catalogBaseObj.activeFrom !== emptyDate && new Date(catalogBaseObj.activeFrom)) || ''}
              disabled={!isLimitedInTime}
              onChange={handleChangeDate('activeFrom')}
            />
          </Col>
          <Col>
            <Form.Label>{t('To')}</Form.Label>
            <CustomDatePicker
              dateFormat="dd.MM.yyyy"
              placeholderText={t('date')}
              showYearDropdown
              selected={(catalogBaseObj.activeTo !== emptyDate && new Date(catalogBaseObj.activeTo)) || ''}
              disabled={!isLimitedInTime}
              onChange={handleChangeDate('activeTo')}
              isInvalid={catalogBaseObj.activeFrom !== emptyDate && isLimitedInTime & 7 && catalogBaseObj.activeTo === emptyDate}
            />
            {catalogBaseObj.activeFrom !== emptyDate && isLimitedInTime && catalogBaseObj.activeTo === emptyDate ?
              <StyledValidationMessage>
                {t('catalogsAdministration:selectDates')}
              </StyledValidationMessage>
              : ""
            }

          </Col>
        </Row>

        <Row className="mb-3">
          <Form.Group>
            <Form.Label className="d-block">{t('catalogsAdministration:catalogIsRightsDependent')}</Form.Label>
            <Form.Check
              inline
              type="radio"
              id="isNotRightsDependent"
              name="rightsDependent"
              aria-label="Set the catalog to not be rights dependent"
              label={t('no')}
              checked={!catalogBaseObj.isRightDependent}
              disabled={catalogBaseObj.isExternalCatalog}
              onChange={(e) => updateCheckboxOrRadioProperty(e, 'isNotRightsDependent')}
            />
            <Form.Check
              inline
              type="radio"
              id="isRightsDependent"
              name="rightsDependent"
              aria-label="Set the catalog to be rights dependent"
              label={t('yes')}
              checked={catalogBaseObj.isRightDependent}
              disabled={catalogBaseObj.isExternalCatalog}
              onChange={(e) => updateCheckboxOrRadioProperty(e, 'isRightsDependent')}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group>
            <Form.Label className="d-block">{t('catalogsAdministration:catalogType')}</Form.Label>
            <Form.Check
              inline
              type="radio"
              id="isInternalCatalog"
              name="catalogType"
              aria-label="Set the catalog as internal"
              label={t('catalogsAdministration:internal')}
              checked={!catalogBaseObj.isExternalCatalog}
              onChange={(e) => updateCheckboxOrRadioProperty(e, 'isInternalCatalog')}
            />
            <Form.Check
              inline
              type="radio"
              id="isExternalCatalog"
              name="catalogType"
              aria-label="Set the catalog as external"
              label={t('catalogsAdministration:external')}
              checked={catalogBaseObj.isExternalCatalog}
              onChange={(e) => updateCheckboxOrRadioProperty(e, 'isExternalCatalog')}
            />
          </Form.Group>
        </Row>

        {catalogBaseObj.isExternalCatalog &&
          <div className='mb-3'>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>{t('catalogsAdministration:externalCatalogLogin')}</Form.Label>
                  <Form.Control
                    type="text"
                    id="externalLogin"
                    name="externalLogin"
                    value={catalogBaseObj.externalLogin}
                    onChange={e => setCatalogBaseObj(prev => ({
                      ...prev,
                      externalLogin: e.target.value
                    }))}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>{t('catalogsAdministration:externalCatalogPassword')}</Form.Label>
                  <Form.Control
                    type="text"
                    id="externalPassword"
                    name="externalPassword"
                    value={catalogBaseObj.externalPassword}
                    onChange={e => setCatalogBaseObj(prev => ({
                      ...prev,
                      externalPassword: e.target.value
                    }))}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Form.Group>
                <Form.Label>{t('catalogsAdministration:articleImportSupplierClient')}</Form.Label>
                <ArraySelect
                  value={catalogBaseObj.externalCatalogSupplierMandatorId}
                  options={supplierMandators}
                  defaultOption={{ value: emptyGuid, name: t('pleaseSelect') }}
                  defaultOptionIsSelectable
                  noAutoSelect
                  onChange={e => setCatalogBaseObj(prev => ({
                    ...prev,
                    externalCatalogSupplierMandatorId: e.target.value
                  }))}
                  returnEvent
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group>
                <Form.Check
                  inline
                  type="radio"
                  id="isPunchOutCatalog"
                  name="isPunchOutCatalog"
                  label={t('catalogsAdministration:punchOutCatalog')}
                  checked={catalogBaseObj.isPunchOutCatalog}
                  onChange={(e) => updateCheckboxOrRadioProperty(e, 'isPunchOutCatalog')}
                  isInvalid={!catalogBaseObj.isPunchOutCatalog && !catalogBaseObj.isShopLink}
                />
                <Form.Check
                  inline
                  type="radio"
                  id="isShopLink"
                  name="isPunchOutCatalog"
                  label={t('catalogsAdministration:linkedShop')}
                  checked={catalogBaseObj.isShopLink}
                  onChange={(e) => updateCheckboxOrRadioProperty(e, 'isShopLink')}
                  isInvalid={!catalogBaseObj.isShopLink && !catalogBaseObj.isPunchOutCatalog}
                />
              </Form.Group>
            </Row>

            {catalogBaseObj.isPunchOutCatalog &&
              <>
                <Row className="mb-3">
                  <Form.Group>
                    <Form.Label>{t('catalogsAdministration:punchOutCatalogURL')}</Form.Label>
                    <Form.Control
                      type="text"
                      id="punchOutCatalogURL"
                      name="punchOutCatalogURL"
                      value={catalogBaseObj.punchOutCatalogInvocationURL}
                      disabled={!catalogBaseObj.isPunchOutCatalog}
                      onChange={e => setCatalogBaseObj(prev => ({
                        ...prev,
                        punchOutCatalogInvocationURL: e.target.value,
                        shopLinkURL: ""
                      }))}
                      isInvalid={!catalogBaseObj.punchOutCatalogInvocationURL}
                    />
                  </Form.Group>
                </Row>
                {!catalogBaseObj.punchOutCatalogInvocationURL ?
                  <StyledValidationMessage>
                    {t('catalogsAdministration:specifyURL')}
                  </StyledValidationMessage>
                  : ""
                }
                <Row className="mb-3">
                  <Form.Group>
                    <Form.Label>{t('catalogsAdministration:catalogStandardSupplier')}</Form.Label>
                    <ArraySelect
                      value={catalogBaseObj.punchOutCatalogSupplierBpId}
                      defaultOption={{ value: emptyGuid, name: t('pleaseSelect') }}
                      defaultOptionIsSelectable
                      options={suppliers}
                      noAutoSelect
                      onChange={e => setCatalogBaseObj(prev => ({
                        ...prev,
                        punchOutCatalogSupplierBpId: e.target.value
                      }))}
                      disabled={!catalogBaseObj.isPunchOutCatalog}
                      returnEvent
                    />
                  </Form.Group>
                </Row>
              </>
            }

            {catalogBaseObj.isShopLink &&
              <Row className="mb-3">
                <Form.Group>
                  <Form.Label>{t('catalogsAdministration:linkedShopURL')}</Form.Label>
                  <Form.Control
                    type="text"
                    id="shopLinkURL"
                    name="shopLinkURL"
                    value={catalogBaseObj.shopLinkURL}
                    disabled={!catalogBaseObj.isShopLink}
                    onChange={e => setCatalogBaseObj(prev => ({
                      ...prev,
                      shopLinkURL: e.target.value,
                      punchOutCatalogInvocationURL: ""
                    }))}
                    isInvalid={!catalogBaseObj.shopLinkURL}
                  />
                </Form.Group>
                {!catalogBaseObj.shopLinkURL ?
                  <StyledValidationMessage>
                    {t('catalogsAdministration:specifyURL')}
                  </StyledValidationMessage>
                  : ""
                }
              </Row>
            }
          </div>
        }

        <ButtonWithLoadingState
          className="mb-2"
          buttonText={t('saveButton')}
          isLoading={loadingStates.saveCatalogTemplate}
          onClick={handleSubmit}
          disabled={(isLimitedInTime && (catalogBaseObj.activeFrom !== emptyDate) && (catalogBaseObj.activeTo === emptyDate)) ||
            (!catalogBaseObj.shopLinkURL && catalogBaseObj.isShopLink) ||
            (!catalogBaseObj.punchOutCatalogInvocationURL && catalogBaseObj.isPunchOutCatalog)}

        />

        {
          errorStates.saveCatalogTemplate ?
            <Alert variant="danger">{errorStates.saveCatalogTemplate.value}</Alert>
            : ""
        }

        <OverlayContainer>
          <OverlayRight visible={overlayVisible} onClose={handleCloseOverlay}>
            <LanguageForm
              languageTitle={t('name')}
              handleCloseOverlay={handleCloseOverlay}
              languageArray={catalogBaseObj.name.values}
              onChange={updateNames}
            />
          </OverlayRight>
        </OverlayContainer>
      </StyledFormWrapper>
    </div>
  )
}

export default CatalogBaseForm