let themeStyleSheet = document.createElement('link')
themeStyleSheet.rel = 'stylesheet'
themeStyleSheet.type = 'text/css'
themeStyleSheet.onload = () => {
  document.body.classList.remove('theme-loading')
}
document.head.appendChild(themeStyleSheet)

export const loadStyleSheet = (url) => {
  document.body.classList.add('theme-loading')

  const newThemeStyleSheet = document.createElement('link')
  newThemeStyleSheet.rel = 'stylesheet'
  newThemeStyleSheet.type = 'text/css'
  newThemeStyleSheet.onload = () => {
    document.body.classList.remove('theme-loading')
  }

  newThemeStyleSheet.href = url
  themeStyleSheet.replaceWith(newThemeStyleSheet)
  themeStyleSheet = newThemeStyleSheet
}
