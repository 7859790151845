import React from 'react'

export const DeleteIcon = ({ color, title, ...rest }) => {
  const useColor = color ? color : '#3C3C3B'

  return (
    <svg width="16px" height="16px" version="1.1" {...rest} viewBox="0 0 15 15">
      {title && <title>{title}</title>}
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path stroke="null" id="svg_2" d="m6.76912,0.22563l1.22649,0c0.49128,0 0.8967,0.36763 0.95617,0.84279l0.00752,0.12088l3.15384,0c1.10592,0 2.0124,0.85384 2.09624,1.93825l0.00632,0.16432l-1.05129,0l-0.86296,9.49255c-0.10666,1.17323 -1.09035,2.07155 -2.26842,2.07155l-5.30134,0c-1.17807,0 -2.16176,-0.89832 -2.26842,-2.07155l-0.86296,-9.49255l-1.05129,0c0,-1.16121 0.94135,-2.10257 2.10256,-2.10257l3.15384,0c0,-0.53221 0.43146,-0.96367 0.96368,-0.96367zm0.61325,4.11752c-0.25805,0 -0.47266,0.18595 -0.51717,0.43116l-0.00847,0.09448l0,7.35897c0,0.2903 0.23534,0.52564 0.52564,0.52564c0.25805,0 0.47266,-0.18595 0.51717,-0.43116l0.00847,-0.09448l0,-7.35897c0,-0.2903 -0.23534,-0.52564 -0.52564,-0.52564zm2.10256,0c-0.25804,0 -0.47266,0.18595 -0.51717,0.43116l-0.00846,0.09448l0,7.35897c0,0.2903 0.23533,0.52564 0.52563,0.52564c0.25805,0 0.47267,-0.18595 0.51718,-0.43116l0.00846,-0.09448l0,-7.35897c0,-0.2903 -0.23534,-0.52564 -0.52564,-0.52564zm-4.20512,0c-0.25805,0 -0.47267,0.18595 -0.51718,0.43116l-0.00846,0.09448l0,7.35897c0,0.2903 0.23534,0.52564 0.52564,0.52564c0.25804,0 0.47266,-0.18595 0.51717,-0.43116l0.00846,-0.09448l0,-7.35897c0,-0.2903 -0.23533,-0.52564 -0.52563,-0.52564z"
          fill={useColor}
        />
      </g>
    </svg>

  )
}

