import React, { useState, useEffect } from 'react'
import { emptyGuid } from '../../utils/emptyGuid'
import { FormControl, Form, Dropdown, Image, InputGroup } from 'react-bootstrap'
import { formatedPriceObject } from '../../utils/formatedPrice'
import ArraySelect from '../Select/ArraySelect'
import { DropdownStylingContainer, CustomToggle, StyledConfigArticleImgWrapper } from '../Dropdowns/ArticleConfiguration'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from "lodash"
import { getFakeEvent } from '../../utils/utils'

const ConfigurationSelect = (props) => {
    const {
        costumConfigurationArticle,
        config,
        showConfigPrice = false,
        customConfigcomment,
        configPrice = {},
        selectedConfigurationsErrors,
        handleRemoveConfig,
        handleAddConfig,
        handleAddComment
    } = props
    const [sortedSelectableArticles, setSortedSelectableArticles] = useState([])
    const { t } = useTranslation()

    useEffect(() => {
        let sortedArticles = cloneDeep(config.selectableArticles)
        if (sortedArticles.length) {
            sortedArticles.sort((a, b) => a.displayName > b.displayName ? 1 : -1)
        }
        setSortedSelectableArticles(sortedArticles)
    }, [config.selectableArticles])

    const onHandleAddConfig = article => {
        handleAddConfig(config.id, article)
        handleAddComment(config.id)(getFakeEvent('comment', ''))
    }

    const onHandleRemoveConfig = (configId) => {
        handleRemoveConfig(configId)
        handleAddComment(configId)(getFakeEvent('comment', ''))
    }
    return (
        <React.Fragment >
            <DropdownStylingContainer>
                <Dropdown >
                    <Dropdown.Toggle
                        as={CustomToggle}
                        config={config}
                        configurationSelected={costumConfigurationArticle.id !== emptyGuid}
                        triggerPrice={showConfigPrice && configPrice.id ? `(${formatedPriceObject(configPrice)})` : ''}
                        triggerName={`${config.displayName} ${costumConfigurationArticle.id !== emptyGuid ? ` | ${costumConfigurationArticle.displayName}` : ''}`}
                        deleteConfig={onHandleRemoveConfig}
                        isInvalid={selectedConfigurationsErrors}>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='dropdownContent' align={{ lg: 'end' }}>
                        <div className='d-flex'>
                            {costumConfigurationArticle.image && costumConfigurationArticle.image.url ?
                                <StyledConfigArticleImgWrapper>
                                    <Image src={costumConfigurationArticle.image.url} />
                                </StyledConfigArticleImgWrapper>
                                : ''}
                            <div className='flex-grow-1'>
                                {config.displayName}
                                <ArraySelect
                                    className='select'
                                    name="article"
                                    onChange={onHandleAddConfig}
                                    value={costumConfigurationArticle.id}
                                    options={sortedSelectableArticles}
                                    isInvalid={selectedConfigurationsErrors && selectedConfigurationsErrors.article}
                                />
                            </div>
                        </div>
                        {config.showComment ?
                            <React.Fragment>
                                {config.customCommentLabelName ?
                                    <Form.Label>{config.customCommentLabelName}</Form.Label>
                                    : ''}
                                <InputGroup>
                                    <FormControl
                                        disabled={!config.isChangeable || costumConfigurationArticle.id === emptyGuid}
                                        as='textarea'
                                        aria-label='With textarea'
                                        placeholder={t('comment')}
                                        name="comment"
                                        onChange={handleAddComment(config.id)}
                                        value={customConfigcomment}
                                        isInvalid={selectedConfigurationsErrors && selectedConfigurationsErrors.comment}
                                    />
                                </InputGroup>
                            </React.Fragment>
                            : ''}
                    </Dropdown.Menu>
                </Dropdown>
            </DropdownStylingContainer >
        </React.Fragment >
    )
}

export default ConfigurationSelect