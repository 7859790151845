import React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import ProductList from '../shared/ProductList'
import { useTranslation } from 'react-i18next'

const StyledParagraph = styled.p`
  margin-bottom: 0;
`

/**
 * Displays a list of given products and enables the user to either
 * confirm or cancel the cancellation
 * @param productsLength
 * @param onSubmit
 * @param onCancel
 * @param loading
 * @param success
 */
const CancellationDialog = (
  {
    productsLength,
    onSubmit,
    onCancel,
    loading,
    success,
    error
  }
) => {
  const { t } = useTranslation()
  const $dialogRoot = document.querySelector('#dialog-root')

  function handleSubmit() {
    onSubmit()
  }

  function handleCancel() {
    onCancel()
  }
  return ReactDOM.createPortal(
    <Modal show centered onHide={handleCancel} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{t('orderDialog:cancelProduct')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ProductList />
        {!success ? (
          <StyledParagraph>
            <b>
              {t('orderDialog:realyCancelProducts', { count: productsLength })}
            </b>
          </StyledParagraph>
        ) : (
          <Alert variant='success'>
            {t('orderDialog:successCancellation')}
          </Alert>
        )}
        {error ? (
          <Alert variant='danger' className='mt-4'>{error}</Alert>
        ) : ''}
      </Modal.Body>

      <Modal.Footer>
        {!success ? (
          <React.Fragment>
            <Button
              variant='secondary'
              onClick={handleCancel}
            >
              {t('cancelButton')}
            </Button>

            <Button
              variant='primary'
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? t('loading') : t('orderDialog:cancel')}
            </Button>
          </React.Fragment>
        ) : (
          <Button
            variant='primary'
            onClick={handleCancel}
          >
            {t('closeButton')}
          </Button>
        )}

      </Modal.Footer>
    </Modal>,
    $dialogRoot
  )
}

export default CancellationDialog
