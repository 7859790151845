import React from 'react'

export const NameTags = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="nameTags" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <g>
        <path className="st0" d="M78,25.1H62V14.3H50v10.8H34c-3.9,0-7.1,3.2-7.1,7.1v60.2c0,3.9,3.2,7.1,7.1,7.1H78c3.9,0,7.1-3.2,7.1-7.1V32.1
          C85.1,28.2,81.9,25.1,78,25.1z M78,96.9H34c-2.5,0-4.6-2.1-4.6-4.6V32.1c0-2.5,2.1-4.6,4.6-4.6h16v3h-5.4V33H50v2.2H62V33h5.4v-2.4
          H62v-3h16c2.5,0,4.6,2.1,4.6,4.6l0,60.2C82.6,94.8,80.6,96.9,78,96.9z M59.6,16.7v15.9h-7.2V16.7H59.6z"/>
        <path className="st0" d="M61.3,62.4c3-1.9,4.9-5.2,4.9-8.8c0-5.7-4.6-10.3-10.3-10.3c-5.7,0-10.3,4.6-10.3,10.3c0,3.6,1.9,6.9,4.9,8.8
          c-6.3,2.2-10.6,8.2-10.6,14.9v8.5c0,0.7,0.5,1.2,1.2,1.2h29.3c0.7,0,1.2-0.5,1.2-1.2v-8.5C71.9,70.6,67.6,64.7,61.3,62.4z M56,63.9
          c7.4,0,13.4,6,13.4,13.4v7.3H42.6v-7.3C42.6,69.9,48.6,63.9,56,63.9z M63.8,53.7c0,4.3-3.5,7.8-7.8,7.8c-4.3,0-7.8-3.5-7.8-7.8
          s3.5-7.8,7.8-7.8C60.3,45.8,63.8,49.3,63.8,53.7z"/>
      </g>
    </svg>
  )
}
