import { t } from "i18next"
/* 
mininum object

template = {
  checkboxes:[],
}
*/
export const bmwRegistration = () => ({
  headline: t('registrationBox:registration'),
  infoText: t('bmw:registrationInfo'),
  accountData: {
    headline: t('registrationBox:accessData'),
    login: {
      label: `${t('email')} *`
    },
    password: {
      label: `${t('loginBox:password')} *`
    },
  },
  personalData: {
    headline: t('registrationBox:personalInformation'),
    nameGenerator: {}
  },
  contactData: {
    headline: t('registrationBox:contactDetails')
  },
  addressData: {
    headline: t('registrationBox:privateBillingAddress')
  },
  genderData: {
    headline: t('registrationBox:cut')
  },
  additional: {//check this input
    headline: t('registrationBox:additional'),
    additionalForms: {
      personnelNumber: {
        label: `${t('registrationBox:qNumber')}}* ${t('registrationBox:onlydigits')}`,
        min: "0",
        type: "number",
        name: "employee.personnelNumber",
        placeholder: t('registrationBox:qNumber'),
        errorId: "employeepersonnelNumber"
      }
    }
  },
  checkboxes: [
    {
      headline: t('registrationBox:noExchange'),
      label: t('cws:checkBoxExchange'),
      id: 'bmw_check_1'
    },
    {
      headline: t('registrationBox:authenticationPerMail'),
      label: t('cws:checkBoxAuthentication'),
      id: 'bmw_check_2'
    }
  ]
})

export const porscheRegistration = () => ({
  headline: t('registrationBox:createAccount'),
  infoText: t('porsche:registrationInfo'),
  accountData: {
    headline: t('registrationBox:accessData'),
    login: {
      label: `${t('email')} *`
    },
    password: {
      label: `${t('loginBox:password')} *`
    },
  },
  personalData: {
    headline: t('registrationBox:personalInformation')
  },
  contactData: {
    headline: t('registrationBox:contactDetails')
  },
  officeAddressData: {
    headline: t('registrationBox:billingAddress'),

  },
  additional: {
    headline: t('registrationBox:additional'),
    additionalForms: {
      dealerName: {
        label: `${t('company')} *`,
        type: "input",
        name: "additionalData.name",
        placeholder: t('company'),
        errorId: "additionalDataname"
      },
      dealerNumber: {
        label: `${t('dealerNumber')} *`,
        type: "input",
        name: "additionalData.number",
        placeholder: t('dealerNumber'),
        errorId: "additionalDatanumber"
      },
      ustId: {
        label: `${t('shoppingCart:vat')}-ID *`,
        type: "input",
        name: "additionalData.foreignKey",
        placeholder: `${t('shoppingCart:vat')}-ID `,
        errorId: "additionalDataforeignKey"
      }
    }
  },
  checkboxes: [],
})
export const hyundaiRegistration = () => ({
  headline: t('registrationBox:createAccount'),
  infoText: t('hyundai:registrationInfo'),
  accountData: {
    headline: t('registrationBox:accessData'),
    login: {
      label: `${t('email')} *`
    },
    password: {
      label: `${t('loginBox:password')} *`
    },
  },
  personalData: {
    headline: t('registrationBox:personalInformation')
  },
  contactData: {
    headline: t('registrationBox:contactDetails')
  },
  officeAddressData: {
    headline: t('registrationBox:billingAddress')
  },
  additional: {
    headline: t('registrationBox:additional'),
    additionalForms: {
      dealerName: {
        label: `${t('company')} *`,
        type: "input",
        name: "additionalData.name",
        placeholder: t('company'),
        errorId: "additionalDataname"
      },
      dealerNumber: {
        label: `${t('dealerNumber')} *`,
        type: "input",
        name: "additionalData.number",
        placeholder: t('dealerNumber'),
        errorId: "additionalDatanumber"
      },
      ustId: {
        label: `${t('shoppingCart:vat')}-ID *`,
        type: "input",
        name: "additionalData.foreignKey",
        placeholder: `${t('shoppingCart:vat')}-ID `,
        errorId: "additionalDataforeignKey"
      }
    }
  },
  checkboxes: [],
})

export const exclusiveCustomersRegistraion = () => ({
  headline: t('registrationBox:createAccount'),
  infoText: t('w&m:registrationInfo'),
  accountData: {
    headline: t('registrationBox:accessData'),
    login: {
      label: `${t('email')} *`
    },
    password: {
      label: `${t('loginBox:password')} *`
    },
  },
  personalData: {
    headline: t('registrationBox:personalInformation')
  },
  contactData: {
    headline: t('registrationBox:contactDetails')
  },
  officeAddressData: {
    headline: t('registrationBox:billingAddress')
  },
  additional: {
    headline: t('registrationBox:additional'),
    additionalForms: {
      dealerName: {
        label: `${t('company')} *`,
        type: "input",
        name: "additionalData.name",
        placeholder: t('company'),
        errorId: "additionalDataname"
      },
      ustId: {
        label: `${t('shoppingCart:vat')}-ID *`,
        type: "input",
        name: "additionalData.foreignKey",
        placeholder: `${t('shoppingCart:vat')}-ID `,
        errorId: "additionalDataforeignKey"
      },
      conceptCode: {
        label: `${t('registrationBox:conceptcode')} *`,
        type: "input",
        name: "additionalData.parentForeignKey",
        placeholder: t('registrationBox:conceptcode'),
        errorId: "additionalDataparentForeignKey"
      }
    }
  },
  checkboxes: [],
})