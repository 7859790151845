import React, { memo, useCallback, useRef, useState } from 'react'
import { Col, Collapse, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ArrowBigIcon } from '../../../Icons/ArrowBigIcon'
import { colors } from '../../../../styles/colors'
import { StyledCollapseTrigger } from '../formHelper'

const CommunicationData = memo(({
  collapsible,
  communicationData,
  setCommunicationDataByKey,
  formErrors,
  submittedCommunicationData,
}) => {
  const [opened, setOpened] = useState(!collapsible)
  const ref = useRef()
  const { t } = useTranslation()
  const handleCange = (e) => {
    const { name, value } = e.target
    setCommunicationDataByKey(name, value)
  }
  const handleClickCollapse = useCallback(() => {
    setOpened(prev => !prev)
  }, [setOpened])
  
  // Checks and connects the specific email (primary / secondary) form control with the corresponding error from formErrors
  const checkEmailValidity = (formControlData) => {
    let isEmailInvalid = false
    let errorMessage = ''

    for (const key in formErrors) {
      if (!key.startsWith('changedItems') && !key.startsWith('newItems')) {
        continue; // Skip to the next iteration if key doesn't start with 'changedItems' or 'newItems'
      }

      // Get the index from the formErrors key: changedItems0 => 0
      const index = key.match(/\d+/)[0];

      // Determine if it's a changedItem or a newItem based on the key (only interested in those for the CommunicationData (emails))
      // Points to the changedItems[...] or newItems[...]
      const targetedItemsArray = key.startsWith('changedItems') ? submittedCommunicationData.changedItems : submittedCommunicationData.newItems

      // Comparison so we can target the correct formContrl (primary email or secondary email)
      // if they are equal, we have matched the formControl with the corresponding error from formErrors
      if (targetedItemsArray[index].isPrivate === formControlData.isPrivate) {
        isEmailInvalid = true;
        errorMessage = formErrors[key].msg
        break;
      }
    }
    return { isEmailInvalid, errorMessage }
  }

  return (
    <div ref={ref}>
      {collapsible ? (
        <StyledCollapseTrigger onClick={handleClickCollapse}>
          <h1><ArrowBigIcon variant={opened ? 'down' : 'right'} color={colors.textColor} />{t('EmployeeFormContactData:contactData')}
          </h1>
        </StyledCollapseTrigger>
      ) : (
        <h1>{t('EmployeeFormContactData:contactData')}</h1>
      )}
      <Collapse in={opened}>
        <div className="spacing-down">
          <h3 className="h2">{t('EmployeeFormContactData:primary')}:</h3>
          <Row>
            <Col sm="6" lg="3">
              <Form.Group className="mb-3">
                <Form.Label>{t('email')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('email')}
                  name="newItems.3.value"
                  onChange={handleCange}
                  value={communicationData.newItems[3].value}
                  isInvalid={Object.keys(submittedCommunicationData).length > 0 && checkEmailValidity(communicationData.newItems[3]).isEmailInvalid}
                />
                <Form.Control.Feedback type="invalid">
                  {Object.keys(submittedCommunicationData).length > 0 && checkEmailValidity(communicationData.newItems[3]).errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm="6" lg="3">
              <Form.Group className="mb-3">
                <Form.Label>{t('EmployeeFormContactData:phone')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Telefon"
                  name="newItems.4.value"
                  onChange={handleCange}
                  value={communicationData.newItems[4].value}
                  isInvalid={!!formErrors['communicationData.newItems.4.value']}
                />
              </Form.Group>
            </Col>
            <Col sm="6" lg="3">
              <Form.Group className="mb-3">
                <Form.Label>{t('EmployeeFormContactData:mobile')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Mobil"
                  name="newItems.5.value"
                  onChange={handleCange}
                  value={communicationData.newItems[5].value}
                  isInvalid={!!formErrors['communicationData.newItems.5.value']}
                />
              </Form.Group>
            </Col>
          </Row>
          <h2>{t('EmployeeFormContactData:secondary')}:</h2>
          <Row>
            <Col sm="6" lg="3">
              <Form.Group className="mb-3">
                <Form.Label>{t('email')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('email')}
                  name="newItems.0.value"
                  onChange={handleCange}
                  value={communicationData.newItems[0].value}
                  isInvalid={Object.keys(submittedCommunicationData).length > 0 && checkEmailValidity(communicationData.newItems[0]).isEmailInvalid}
                />
                <Form.Control.Feedback type="invalid">
                  {Object.keys(submittedCommunicationData).length > 0 && checkEmailValidity(communicationData.newItems[0]).errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm="6" lg="3">
              <Form.Group className="mb-3">
                <Form.Label>{t('EmployeeFormContactData:phone')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('EmployeeFormContactData:phone')}
                  name="newItems.1.value"
                  onChange={handleCange}
                  value={communicationData.newItems[1].value}
                  isInvalid={!!formErrors['communicationData.newItems.1.value']}
                />
              </Form.Group>
            </Col>
            <Col sm="6" lg="3">
              <Form.Group className="mb-3">
                <Form.Label>{t('EmployeeFormContactData:mobile')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('EmployeeFormContactData:mobile')}
                  name="newItems.2.value"
                  onChange={handleCange}
                  value={communicationData.newItems[2].value}
                  isInvalid={!!formErrors['communicationData.newItems.2.value']}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  )
})

export default CommunicationData
