import React, { useCallback, useEffect, useMemo } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { Alert, Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { useEmployeesState } from '../../stores/EmployeesProvider'
import {
  columns,
  personalNrFilter
} from './filterConfig'
import StructureIcon from '../Icons/StructureIcon'
import UniversalTableWrapperTest from "../Table/UniversalTableWrapperTest"
import TableProvider, { useTableState } from '../Table/TableProvider'
import useMountEffect from '../../utils/hooks/useMountEffect'
import { t } from 'i18next'

const StyledStructureButton = styled.button`
  border: 0;
  padding: 0;
  background: none;
`
const defaultSorted = [{
  dataField: 'name',
  order: 'desc'
}]

const EmployeesTableInner = ({ noActions, onRowClick, withCheckbox, tableId }) => {
  const {
    partialBuisinesspartners,
    setPageOffset,
    setPageLimit,
    filter,
    setFilter,
    selectedRow,
    setShowAllEmployees,
    setSorting,
    tableIsLoading,
    setOpenOfficeFilter,
    setOpenCostCenterFilter,
    setShowOverlayLeft,
    resetFilter,
    filterEmployee,
    setFilterEmployee,
    totalCount,
    selectAll,
    allCheckBoxesSelected,
    allSelectedLoading,
    setInitialTableId,
    initialTableId,
    setForceReloadEmployees,
    setSelectedCheckBoxes
  } = useEmployeesState()

  const { tableRef, paginationOptions, currentPage, totalPages } = useTableState()

  useMountEffect(() => {
    if (tableId !== initialTableId) {
      //reset Header Column Filters, Preselect Filter if SizeDetection
      initialTableId && resetFilter(tableId === "SizeDetection" ? true : null)
      setInitialTableId(tableId)
    }
    else if (tableId === "SizeDetection") {
      setForceReloadEmployees(true)
    }
    setSelectedCheckBoxes([])
  })

  useEffect(() => {
    //after Creating new Employee auto output him
    if (!!filterEmployee) {
      setTimeout(() => {
        resetFilter()
        //set text in personnel number searchbar
        personalNrFilter(filterEmployee)
        //set filter + trigger call
        setFilter((prev) => ({
          ...prev,
          'filter.personalNumber': filterEmployee,
        }))
      }, 50)
      setFilterEmployee(null)
    }
  }, [filterEmployee, setFilterEmployee, resetFilter, setFilter])

  const handleTableChange = (type, newState) => {
    setPageLimit(newState.sizePerPage)
    if (type === 'filter') {
      setShowAllEmployees(false)
      const { filters } = newState
      setFilter((prev) => ({
        ...prev,
        'filter.displayName': (filters.displayName ? filters.displayName.filterVal : ''),
        'filter.personalNumber': (filters.personalNr ? filters.personalNr.filterVal : ''),
        'filter.officeDisplayName': filters['office.displayName'] ? filters['office.displayName'].filterVal : '',
        'filter.costCenterDisplayName': filters['costCenter.displayName'] ? filters['costCenter.displayName'].filterVal : '',
      }))
    }

    if (type === 'sort') {
      const { sortOrder, sortField } = newState
      setSorting(`${sortOrder === 'asc' ? '-' : ''}${sortField}`)
    }
    setPageOffset(newState.page === 1 ? 0 : newState.sizePerPage * newState.page - newState.sizePerPage)
  }

  const handleStructureClick = useCallback(structureType => e => {
    e.stopPropagation()
    switch (structureType) {
      case 'costCenter':
        setOpenCostCenterFilter(prev => !prev)
        setOpenOfficeFilter(false)
        setShowOverlayLeft(true)
        break
      case 'office':
        setOpenCostCenterFilter(false)
        setOpenOfficeFilter(prev => !prev)
        setShowOverlayLeft(true)
        break
      default:
    }
  }, [setOpenCostCenterFilter, setOpenOfficeFilter, setShowOverlayLeft])

  const preparedColumns = useMemo(() => {
    const tmpColumns = [...columns]
    tmpColumns.forEach(tmpColumn => {
      tmpColumn.headerFormatter = (column, colIndex, components) => {
        switch (tmpColumn.dataField) {
          case 'checkBox':
            return (
              <span>
                {allSelectedLoading
                  ? <Spinner animation='border' variant='primary' size="sm" />
                  : <input type="checkbox" id='checkbox_header' name="scales"
                    checked={allCheckBoxesSelected} onChange={selectAll}>
                  </input>}
              </span>
            )
          case 'costCenter.displayName':
          case 'office.displayName':
            return (
              <>
                {column.text}
                {components.sortElement}
                <div className="d-flex align-items-end">
                  {components.filterElement}
                  <div className="ps-2">
                    <StyledStructureButton
                      onClick={handleStructureClick(tmpColumn.dataField === 'costCenter.displayName' ? 'costCenter' : 'office')}><StructureIcon />
                    </StyledStructureButton>
                  </div>
                </div>
              </>
            )
          default:
            return (
              <>
                {column.text}
                {components.sortElement}
                <div className="d-flex align-items-end">
                  {components.filterElement}
                </div>
              </>
            )
        }
      }
    });

    tmpColumns.forEach(tmpColumn => {
      tmpColumn.text = t(tmpColumn.text)
    })

    const ignoreColumns = () => {
      var result = []
      if (noActions) result.push('actions')
      if (!withCheckbox) result.push('checkBox')
      return result
    }

    return tmpColumns.filter(item => !ignoreColumns().includes(item.dataField))
  }, [allSelectedLoading, allCheckBoxesSelected, selectAll, handleStructureClick, noActions, withCheckbox])

  const getChosenFilters = () => {
    var message = []
    //save filters in Array
    if (filter) {
      message = Object.keys(filter).filter(key => filter[key] !== "")
    }
    return message
  }
  const noDataIndicationMessage = () => {
    const chosenFilters = getChosenFilters()
    const alertMessage = t('addEmployeeTable:alertMsg')
    var officeFilter = ''
    var costCenterFilter = ''

    //returns Alert if no results for used filters
    if ((totalCount === 0) && (chosenFilters.length > 0) && !tableIsLoading) {
      if (chosenFilters.includes('filter.officeIds')) officeFilter = t('addEmployeeTable:officeFilterAlertMsg')
      if (chosenFilters.includes('filter.costCenterIds')) costCenterFilter = t('addEmployeeTable:costCenterFilterAlertMsg')
      return <div className="no-data-placeholder-alert">
        {officeFilter && <Alert variant="info">{officeFilter}</Alert>}
        {costCenterFilter && <Alert variant="info">{costCenterFilter}</Alert>}
        {!costCenterFilter && !officeFilter && <Alert variant="info">{alertMessage}</Alert>}
      </div>
    }
    //returns empty Table
    else {
      return <div className="no-data-placeholder">{t('addEmployeeTable:pleaseSearchEmployee')}</div>
    }
  }


  return (
    <UniversalTableWrapperTest tableIsLoading={tableIsLoading} currentPage={currentPage} totalPages={totalPages} >

      <BootstrapTable
        ref={tableRef}
        remote
        loading={tableIsLoading}
        noDataIndication={() => noDataIndicationMessage()}
        onTableChange={handleTableChange}
        bootstrap4
        keyField="id"
        hover
        data={partialBuisinesspartners}
        columns={preparedColumns}
        defaultSorted={defaultSorted}
        rowEvents={{ onClick: onRowClick }}
        rowClasses={(row, rowIndex) => {
          let classes = []
          if (row === selectedRow) {
            classes.push('active')
          }
          if (row.isDeleted) {
            classes.push('is-deleted')
          }
          return classes.join(' ')
        }}
        pagination={paginationFactory(paginationOptions)}
        filter={filterFactory()}
        bordered={false}
      />

    </UniversalTableWrapperTest>
  )
}

const EmployeesTable = ({ noActions, onRowClick, withCheckbox, tableId }) => {

  const {
    pageOffset,
    setPageOffset,
    pageLimit,
    setPageLimit,
    totalCount,
    setTotalCount
  } = useEmployeesState()

  const providerSettings = {
    pageOffset,
    setPageOffset,
    pageLimit,
    setPageLimit,
    totalCount,
    setTotalCount
  }
  return (
    <TableProvider {...providerSettings} >
      <EmployeesTableInner noActions={noActions} withCheckbox={withCheckbox} onRowClick={onRowClick} tableId={tableId} />
    </TableProvider>
  )
}

export default EmployeesTable
