import React from 'react'
import { colors } from '../../../../styles/colors'
import { calculateTime, toTimeString } from '../../../../utils/hoursAndMinutes'
import styled from 'styled-components'
import { CrossIcon } from '../../../Icons/CrossIcon'
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next'
import { memo } from 'react'
import { Spinner } from 'react-bootstrap'
import { useState } from 'react'

const StyledTimeWrapper = styled.div`
    display:flex;
`
const StyledHorizontalLine = styled.div`
position:relative;
&::after{
    content: '';
    position: absolute;
    width: 5px;
    background-color: black;
    top: 0;
    bottom: 0;
    right: 0;
}
`
const StyledAppointments = styled.div`

position:relative;
flex-grow:1;
max-width:400px;
@media (max-width: 767px) {
    max-width:none;
  }
`

const StyledRelativeSpace = styled.div`
position:relative;
width:20px;
`

const StyledCircle = styled.div`
    position:relative;
    width:75px;
    &::after{
        z-index:2;
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        right: -6px;
        background-color: white;
        border: 5px solid #000000;
        top: 5px;
        border-radius: 50%;
    }
`
const StyledHour = styled.div`
    height: ${props => props.height};
    text-align: center;
    padding-right: 20px;
    font-size: 24px;
    font-weight: bold;
    color: gray;
    line-height: 24px;
`

const StyledTime = styled.div`
cursor:default;
z-index:1;
top:${props => props.top};
width: 100%;
position:absolute;
height:${props => props.height};
border: 1px solid ${colors.borderColor};
background-color: #a6e144;
border-radius: 8px;
padding: 0 1rem;
font-weight: bold;
color: ${colors.textColor};
display:flex; justify-content:space-between;
align-items: center;
&::before{
    content: '';
    border-bottom: 2px solid ${colors.borderColor};
    position: absolute;
    width: 20px;
    left: -21px;
    top: 11px;
}
`
const TimesList = memo(({ durationInMinutes, hours, updateTimeSlots }) => {
    const { t } = useTranslation()

    const TimeSlot = ({ obj, index }) => {
        const [isDeleting, setIsDeleting] = useState(false)
        const height = durationInMinutes < 60 ? '26px' : `${((26 / 60) * durationInMinutes)}px`
        const top = durationInMinutes < 60 ? `${(26 / durationInMinutes) * obj.minute}px` : `${(26 / 60) * obj.minute}px`
        const appointmentEnd = calculateTime("plus", obj.hour, obj.minute, durationInMinutes)

        const deleteTimeSlot = () => {
            setIsDeleting(true)
            const newHours = cloneDeep(hours)
            const newArray = newHours[obj.hour]
            newArray.splice(index, 1)
            newHours[obj.hour] = newArray
            updateTimeSlots(newHours)
        }

        return (
            < StyledTime height={height} top={top} >
                {toTimeString(obj.hour, obj.minute)} - {toTimeString(appointmentEnd[0], appointmentEnd[1])}
                {isDeleting
                    ? < Spinner size="sm" animation='border' variant='primary' />
                    : <CrossIcon color={colors.gray3} onClick={deleteTimeSlot} />}
            </StyledTime >
        )
    }

    const Hour = ({ times, hour }) => {
        const height = durationInMinutes < 60 ? `${26 * (60 / durationInMinutes)}px` : '26px'
        return <StyledTimeWrapper >
            <StyledHorizontalLine>
                <StyledCircle>
                    <StyledHour height={times.length ? height : "26px"}>
                        {toTimeString(hour, 0)}
                    </StyledHour>
                </StyledCircle>
            </StyledHorizontalLine>
            <StyledRelativeSpace>
            </StyledRelativeSpace>
            <StyledAppointments >
                {times.map((obj, index) => (
                    <TimeSlot key={`${obj.hour}-${index}`} obj={obj} index={index} />
                ))}
            </StyledAppointments>
        </StyledTimeWrapper>
    }

    return (
        <>
            <h2>{t('appointment:appointments')}</h2>
            {hours.map((h, index) => (
                < Hour key={index} times={h} hour={index} />
            ))}
        </>
    )
})

export default TimesList