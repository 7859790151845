import React from 'react'
import { CartIcon } from '../Icons/CartIcon.jsx'
import { ButtonWithLoadingState } from './ButtonWithLoadingState'

export const AddToBasketButton = (props) => {
  return (
    <React.Fragment>
      <ButtonWithLoadingState buttonIcon={<CartIcon className="me-3" />} {...props} />
    </React.Fragment>
  )
}
