import React from 'react'

export const MenuIcon = ({ color, title, ...rest }) => {
  const useColor = color ? color : '#3C3C3B'
  return (
    <svg width="28px" height="28px" viewBox="0 0 28 28" {...rest}>
      {title && <title>{title}</title>}
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={useColor}>
          <g transform="translate(7.000000, 9.000000)">
            <path
              d="M0.909090909,8 L13.0909091,8 C13.5929861,8 14,8.40701386 14,8.90909091 C14,9.41116795 13.5929861,9.81818182 13.0909091,9.81818182 L0.909090909,9.81818182 C0.407013864,9.81818182 9.07835497e-13,9.41116795 9.07774011e-13,8.90909091 C9.07712524e-13,8.40701386 0.407013864,8 0.909090909,8 Z M0.909090909,4 L13.0909091,4 C13.5929861,4 14,4.40701386 14,4.90909091 C14,5.41116795 13.5929861,5.81818182 13.0909091,5.81818182 L0.909090909,5.81818182 C0.407013864,5.81818182 9.07835497e-13,5.41116795 9.07774011e-13,4.90909091 C9.07712524e-13,4.40701386 0.407013864,4 0.909090909,4 Z M13.0909091,3.33066907e-16 C13.5929861,1.22124533e-15 14,0.407013864 14,0.909090909 C14,1.41116795 13.5929861,1.81818182 13.0909091,1.81818182 L0.909090909,1.81818182 C0.407013864,1.81818182 9.07835497e-13,1.41116795 9.07774011e-13,0.909090909 C9.07712524e-13,0.407013864 0.407013864,-1.11022302e-16 0.909090909,-5.55111512e-16 L13.0909091,3.33066907e-16 Z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
