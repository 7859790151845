import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { Carousel } from 'react-bootstrap'
import SafelySetInnerHTML from '../SafelySetInnerHTML'
import ProductGalleryModal from './ProductGalleryModal'

const StyledGalleryItemListContainer = styled.div`
  @media screen and (min-width: 992px){
    //width: 50vw;
    display: flex;
    flex-direction: row-reverse;
  }
  @media screen and (min-width: 1280px){
    //width: 640px;
  }
`

const StyledGalleryItemListWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;

  .carousel {
    border: 1px solid ${colors.borderColorLight};
  }

  .carousel-control-prev-icon{
    background-image:  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${encodeURIComponent(colors.gray4)}' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E")
  }
  .carousel-control-next-icon{
    background-image:  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='${encodeURIComponent(colors.gray4)}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e")
  }

  @media screen and (min-width: 992px){
    border: 1px solid #f2f2f2;
    margin: 5px;

    .carousel-control-prev,  .carousel-control-next{
      display: none;
    }
  }
`

const StyledGalleryItem = styled.div`
cursor:zoom-in;
  width: 100%;
  height: 290px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 992px){
    height: 620px;
  }
`

const StyledGalleryImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const StyledGalleryThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (min-width: 992px){
    justify-content: flex-end;
    flex-direction: column;
    margin-right: 15px;
  }
`

const StyledGalleryThumbnailItem = styled.div`
  cursor: pointer;
  width: 75px;
  height: 70px;
  margin: 7.5px;
  border: 1px solid ${colors.borderColorLight};
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 992px){
    width: 95px;
    height: 120px;
  }
`

const StyledGalleryThumbnailImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`
const StyledIMGDescription = styled.div`
background-color: ${props => props.theme.primaryColor};
color: #FFF;
position: absolute;
top: 0;
left: 0;
width:100%;
font-size: 13px;
letter-spacing: 0.4px;
text-align: center;
`

const ProductGallery = inject('uiStore')(observer((props) => {

  const { uiStore, article } = props
  const detailImages = article.detailImages ? article.detailImages.slice(0, 4) : []
  const [openGalleryModal, setOpenGalleryModal] = useState(null)
  return (
    <div>
      <StyledGalleryItemListContainer>
        <StyledGalleryItemListWrapper>
          <Carousel interval={null}
            activeIndex={uiStore.productGalleryCarouselIndex}
            onSelect={selectedIndex => { uiStore.setProductGalleryCarouselIndex(selectedIndex) }}
            indicators={false}
            data-touch="true">
            {detailImages.map((img, index) => (
              <Carousel.Item key={img.id}>
                <StyledGalleryItem onClick={() => setOpenGalleryModal({ startIndex: index })}>
                  {img.description &&
                    <StyledIMGDescription>
                      <SafelySetInnerHTML className="d-flex justify-content-center">
                        {img.description}
                      </SafelySetInnerHTML>
                    </StyledIMGDescription>}
                  <StyledGalleryImage
                    className="d-block"
                    src={img.url}
                    alt=""
                  />
                </StyledGalleryItem>
              </Carousel.Item>
            ))}
          </Carousel>
        </StyledGalleryItemListWrapper>

        <StyledGalleryThumbnailContainer>
          {detailImages.map((img, index) => (
            <StyledGalleryThumbnailItem key={img.id} onClick={() => uiStore.setProductGalleryCarouselIndex(index)}>
              <StyledGalleryThumbnailImage alt='' src={img.url} />
            </StyledGalleryThumbnailItem>
          ))}
        </StyledGalleryThumbnailContainer>
      </StyledGalleryItemListContainer>
      {openGalleryModal && <ProductGalleryModal startIndex={openGalleryModal.startIndex} handleClose={() => setOpenGalleryModal(null)} detailImages={detailImages} />}
    </div>
  )

}))

export default ProductGallery
