import { Button, Col, Form } from 'react-bootstrap'
import React from 'react'
import styled from 'styled-components'
import OverlayRight from '../Page/logix2020/OverlayRight'
import OverlayContainer from '../Page/logix2020/OverlayContainer'
import { colors } from '../../styles/colors'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

const StyledOverlayRight = styled(OverlayRight)`
  color: ${colors.secondary};
  font-size: 14px;
  input {
    outline: none;
    border:none;
    border-bottom:1px solid black;
    border-radius:0;
  }
  input[type=number]{
    background-color: #F2F2F2;
  }
  @media screen and (min-width: 1024px) {
    min-width: 400px;
  }

  .form-control {
    height: 30px;
  }

  .btn {
    width: 60%;
    margin: 5% 20%;
  }

  .prefill-variants-wrapper {
    margin: 0 -10px 25px;
    @media screen and (min-width: 600px) {
      display: flex;
      align-items: center;    }
    
    .form-label {
      width: 150px;
      flex-shrink: 0;
      margin: 10px;
    }

    .form-control {
    width: 60%;
      margin: 10px;
    }

    .btn {
      margin: 10px;
    }
  }
`

const StyledLabelRow = styled.div`
  border-bottom: 1px solid ${colors.borderColorLight};
  margin-bottom: 8px;
  padding-bottom: 12px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
`

const StyledFormLabel = styled.div`
  font-weight: bold;
`
const StyledVariantRow = styled.div`
  margin-bottom: 10px;
  input {
  max-width: 100px;
  }
`



const VariantQuantityOverlay = inject('productDetailsStore', 'teamOrderStore')(observer((props) => {

  const { t } = useTranslation()
  const { productDetailsStore, teamOrderStore, onClose, variantQuantitySum, variants, getVariantNameAndPrice } = props

  const articleId = productDetailsStore.article.id
  const quantityRecommendedPerBPExists = productDetailsStore.quantityRecommendedPerBPExists
  const overlayMaxAmount = teamOrderStore.overlayMaxAmount[articleId] || 0
  const selectedVariantQuantitiesErros = teamOrderStore.selectedVariantQuantitiesErros
  const overlayInputAmount = teamOrderStore.overlayInputAmount[articleId] || 0

  const calculateVariantQuantities = (e) => {
    e.preventDefault()
    e.stopPropagation()

    var sumQuantityRecommendedPerBP = 0
    var sumArticlesGoal = 0
    var articlesPerVariant = {};
    var sumArticlesCurrent = 0
    var biggestValueVariantId = ''
    var biggestValue = 0

    //get sumQuantityRecommendedPerBP
    sumQuantityRecommendedPerBP = variants.reduce((prev, cur) => {
      return prev + cur.quantityRecommendedPerBP;
    }, 0);

    //get sumArticlesGoal
    sumArticlesGoal = Math.round(
      sumQuantityRecommendedPerBP * overlayInputAmount
    );

    //get articlesPerVariant
    for (const obj of Object.values(variants)) {
      articlesPerVariant[obj.id] = obj.quantityRecommendedPerBP
        ? Math.round(obj.quantityRecommendedPerBP * overlayInputAmount)
        : 0;
    }

    // get biggestValue and sumArticlesCurrent
    for (const [key, value] of Object.entries(articlesPerVariant)) {
      if (value > biggestValue) {
        biggestValue = value
        biggestValueVariantId = key
      }
      sumArticlesCurrent += value
    }

    //correction on biggest Value
    if (sumArticlesGoal !== sumArticlesCurrent) {
      articlesPerVariant[biggestValueVariantId] += sumArticlesGoal - sumArticlesCurrent
    }

    //Apply values to TeamOrderStore
    updateValues(articlesPerVariant)
  };

  const updateValues = async (articlesPerVariant) => {
    //Apply values to TeamOrderStore
    await teamOrderStore.resetVariants(Object.entries(articlesPerVariant).filter(([key, value]) => !!value).map(([key, value]) => ({ variantId: key, quantity: value })))
    teamOrderStore.refreshOverlayMaxAmount()
  }


  const getSumPerVariant = (variantId) => {
    return teamOrderStore.selectedVariants.filter(item => item.variantId === variantId).map(item => parseInt(item.quantity)).reduce((prev, curr) => prev + curr, 0);
  }


  const overlayMaxAmountReached = (oldValue, newValue) => {
    if (oldValue === 0) {
      if (productDetailsStore.quantityRecommendedPerBPExists) {
        let oldSum = parseInt(teamOrderStore.selectedVariants.map(item => parseInt(item.quantity)).reduce((prev, curr) => prev + curr, 0))
        let articleId = productDetailsStore.article.id
        let maxAmount = teamOrderStore.overlayMaxAmount[articleId] || 0
        if ((oldSum + parseInt(newValue)) > maxAmount) {
          return true
        }
      }
    }
    return false
  }

  const changeSumPerVariant = (variantId) => async e => {
    let { value } = e.target
    let oldValue = getSumPerVariant(variantId)
    if (overlayMaxAmountReached(oldValue, value)) {
      return
    }
    let newSelectedVariants = teamOrderStore.selectedVariants.filter(item => item.variantId !== variantId)
    teamOrderStore.selectedVariants = newSelectedVariants
    await teamOrderStore.addVariant(variantId, oldValue)
    let newIndex = teamOrderStore.selectedVariants.findIndex(variant => variant.variantId === variantId)
    teamOrderStore.changeVariantQuantity(newIndex, value)
  }

  return (
    <OverlayContainer>
      <StyledOverlayRight visible={true} onClose={onClose} headerTitle={t('VariantQuantityTable:allSizes')}>
        <div className="content">
          {quantityRecommendedPerBPExists && (
            <>
              <Form onSubmit={calculateVariantQuantities} >
                <Form.Group className="prefill-variants-wrapper mb-3">
                  <Form.Label>{t('VariantQuantityTable:pleaseWriteAmount')}:</Form.Label>
                  <Form.Control type="number" placeholder="0" min="0" value={overlayInputAmount} onChange={teamOrderStore.setOverlayInputAmount} />
                  <Button type="submit" >{t('nextButton')}</Button>
                </Form.Group>
                <h3>{t('VariantQuantityTable:maxAmount')}: ({variantQuantitySum}/{overlayMaxAmount})</h3>
              </Form>
            </>
          )}
          <StyledLabelRow className="row">
            <span className="col-6">{t('size')}</span>
            <span className="col-3">{t('quantity')}</span>
          </StyledLabelRow>
          {variants.map(variant => {
            return (
              <Col key={variant.id}>
                <StyledVariantRow className="row">
                  <StyledFormLabel className="col-sm-6">{getVariantNameAndPrice(variant, true)}</StyledFormLabel>
                  <div className="col">
                    <Form.Control
                      type="number"
                      placeholder="0"
                      value={getSumPerVariant(variant.id) || ''}
                      onChange={changeSumPerVariant(variant.id)}
                      isInvalid={selectedVariantQuantitiesErros[variant.id]}
                    />
                  </div>
                </StyledVariantRow>
              </Col>
            )
          })}
          <Button onClick={onClose}>{t('closeButton')}</Button>
        </div>
      </StyledOverlayRight>
    </OverlayContainer>
  )
}))

export default VariantQuantityOverlay
