import React from 'react'
import { useTheme } from 'styled-components';

export const LostIcon = ({ title, height, width, isActive }) => {
  const theme = useTheme()

  const useHeight = height ? height : "24px";
  const useWidth = width ? width : "24px";
  const borderColor = isActive ? "white" : "lightGray";
  const backgroundColor = isActive ? theme.primaryColor : "ghostWhite";

  return (
    <svg width={useWidth} height={useHeight} viewBox="0 0 24 24" fill="none">
      {title && <title>{title}</title>}
      <rect width="24" height="24" fill={backgroundColor} />
      <circle cx="12" cy="10" r="6.5" stroke={borderColor} />
      <path d="M12 21L14.5981 16.5H9.40192L12 21Z" fill={borderColor} />
      <path
        d="M11.5469 11.75V11.7031C11.5521 11.2057 11.6042 10.8099 11.7031 10.5156C11.8021 10.2214 11.9427 9.98307 12.125 9.80078C12.3073 9.61849 12.526 9.45052 12.7813 9.29687C12.9349 9.20312 13.0729 9.09245 13.1953 8.96484C13.3177 8.83464 13.4141 8.6849 13.4844 8.51562C13.5573 8.34635 13.5938 8.15885 13.5938 7.95312C13.5938 7.69792 13.5339 7.47656 13.4141 7.28906C13.2943 7.10156 13.1341 6.95703 12.9336 6.85547C12.7331 6.75391 12.5104 6.70312 12.2656 6.70312C12.0521 6.70312 11.8464 6.7474 11.6484 6.83594C11.4505 6.92448 11.2852 7.0638 11.1523 7.25391C11.0195 7.44401 10.9427 7.69271 10.9219 8H9.9375C9.95833 7.55729 10.0729 7.17839 10.2812 6.86328C10.4922 6.54818 10.7695 6.30729 11.1133 6.14062C11.4596 5.97396 11.8438 5.89062 12.2656 5.89062C12.724 5.89062 13.1224 5.98177 13.4609 6.16406C13.8021 6.34635 14.0651 6.59635 14.25 6.91406C14.4375 7.23177 14.5313 7.59375 14.5313 8C14.5313 8.28646 14.487 8.54557 14.3984 8.77734C14.3125 9.00911 14.1875 9.21615 14.0234 9.39844C13.862 9.58073 13.6667 9.74219 13.4375 9.88281C13.2083 10.026 13.0247 10.1771 12.8867 10.3359C12.7487 10.4922 12.6484 10.6784 12.5859 10.8945C12.5234 11.1107 12.4896 11.3802 12.4844 11.7031V11.75H11.5469ZM12.0469 14.0625C11.8542 14.0625 11.6888 13.9935 11.5508 13.8555C11.4128 13.7174 11.3438 13.5521 11.3438 13.3594C11.3438 13.1667 11.4128 13.0013 11.5508 12.8633C11.6888 12.7253 11.8542 12.6562 12.0469 12.6562C12.2396 12.6562 12.4049 12.7253 12.543 12.8633C12.681 13.0013 12.75 13.1667 12.75 13.3594C12.75 13.487 12.7174 13.6042 12.6523 13.7109C12.5898 13.8177 12.5052 13.9036 12.3984 13.9687C12.2943 14.0313 12.1771 14.0625 12.0469 14.0625Z"
        fill={borderColor}
      />
    </svg>
  );
};
export const RemovedIcon = ({ title, height, width, isActive }) => {
  const theme = useTheme()

  const useHeight = height ? height : "24px";
  const useWidth = width ? width : "24px";
  const borderColor = isActive ? "white" : "lightGray";
  const backgroundColor = isActive ? theme.primaryColor : "ghostWhite";
  return (
    <svg width={useWidth} height={useHeight} viewBox="0 0 24 24" fill="none">
      {title && <title>{title}</title>}
      <rect width="24" height="24" fill={backgroundColor} />

      <path
        d="M19.5 7C19.5 7.21987 19.383 7.4892 19.0396 7.79361C18.6969 8.0975 18.1723 8.39401 17.4813 8.65316C16.1029 9.17006 14.1651 9.5 12 9.5C9.83494 9.5 7.89713 9.17006 6.51871 8.65316C5.82765 8.39401 5.30314 8.0975 4.96038 7.79361C4.61704 7.4892 4.5 7.21987 4.5 7C4.5 6.78013 4.61704 6.5108 4.96038 6.20639C5.30314 5.9025 5.82765 5.60599 6.51871 5.34684C7.89713 4.82994 9.83494 4.5 12 4.5C14.1651 4.5 16.1029 4.82994 17.4813 5.34684C18.1723 5.60599 18.6969 5.9025 19.0396 6.20639C19.383 6.5108 19.5 6.78013 19.5 7Z"
        stroke={borderColor}
      />
      <path d="M9 17.5L11 18L13 17L15 17.5" stroke={borderColor} />
      <path
        d="M11.1736 15.5526L13.2228 15.3269L14.751 13.6946L16.8002 13.4689"
        stroke={borderColor}
      />
      <line
        x1="4.4872"
        y1="6.88757"
        x2="7.4872"
        y2="19.8876"
        stroke={borderColor}
      />
      <line
        y1="-0.5"
        x2="13.3417"
        y2="-0.5"
        transform="matrix(-0.22486 0.974391 0.974391 0.22486 20 7)"
        stroke={borderColor}
      />
      <line x1="7" y1="19.5" x2="17" y2="19.5" stroke={borderColor} />
    </svg>
  );
};
export const StoredIcon = ({ title, height, width, isActive }) => {
  const theme = useTheme()

  const useHeight = height ? height : "24px";
  const useWidth = width ? width : "24px";
  const borderColor = isActive ? "white" : "lightGray";
  const backgroundColor = isActive ? theme.primaryColor : "ghostWhite";
  return (
    <svg
      width={useWidth}
      height={useHeight}
      viewBox="0 0 24 24"
      fill="none"
    >
      {title && <title>{title}</title>}
      <rect width="24" height="24" fill={backgroundColor} />
      <g>
        <path d="M6.95 4.4C7.04315 4.2758 7.16393 4.175 7.30279 4.10557C7.44164 4.03615 7.59475 4 7.75 4H16.25C16.4052 4 16.5584 4.03615 16.6972 4.10557C16.8361 4.175 16.9569 4.2758 17.05 4.4L19.9 8.2C19.9649 8.28655 20 8.39181 20 8.5V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V8.5C4 8.39181 4.03509 8.28655 4.1 8.2L6.95 4.4ZM11.5 5H7.75L5.5 8H11.5V5ZM12.5 5V8H18.5L16.25 5H12.5ZM19 9H5V19H19V9Z" fill={borderColor} />
        <path d="M11.6464 16.3536C11.8417 16.5488 12.1583 16.5488 12.3536 16.3536L15.5355 13.1716C15.7308 12.9763 15.7308 12.6597 15.5355 12.4645C15.3403 12.2692 15.0237 12.2692 14.8284 12.4645L12 15.2929L9.17157 12.4645C8.97631 12.2692 8.65973 12.2692 8.46447 12.4645C8.2692 12.6597 8.2692 12.9763 8.46447 13.1716L11.6464 16.3536ZM11.5 10V16H12.5V10H11.5Z" fill={borderColor} />
        <line x1="8" y1="17.5" x2="16" y2="17.5" stroke={borderColor} />
      </g>
    </svg>
  );
};

