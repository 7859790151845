
import { observable, makeObservable } from 'mobx';

class OverlayRightStore {
    openOverlay = '';

    constructor() {
        makeObservable(this, {
            openOverlay: observable
        });
    }

    closeAll() {
        this.openOverlay = ''
    }
    openOrClose(type) {
        if (this.openOverlay === type) {
            this.closeAll()
        }
        else {
            this.openOverlay = type
        }
    }
}
export default OverlayRightStore
