import React, { useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { ButtonWithLoadingState } from '../Buttons'
import ArticleSelect from './ArticleSelect'
import { t } from 'i18next'
import styled from 'styled-components'
const StyledWrapper = styled.div`
  margin-bottom: 30px;
 
`
const ArticleSelectModal = ({ selectedArticles, setSelectedArticles, handleSave, hideArticles, handleSaveIsLoading, setShowAddArticlesModal }) => {
  const listRef = useRef(null)
  const handleClose = () => {
    setShowAddArticlesModal(false)
  }
  return (
    <Modal size="lg" centered show={true} onHide={handleClose} scrollable>
      <Modal.Header closeButton className="pb-0">
        <Modal.Title>
          <div>
            <p className="m-0 pt-2">{t('article_select')}</p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={listRef}>
        <StyledWrapper>
          <ArticleSelect selectedArticles={selectedArticles} setSelectedArticles={setSelectedArticles} hideArticles={hideArticles} listRef={listRef} />
        </StyledWrapper>
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoadingState
          variant='primary'
          buttonText={`${t('saveButton')} ${selectedArticles.length ? `(${selectedArticles.length})` : ''}`}
          isLoading={handleSaveIsLoading}
          onClick={handleSave}
        />
      </Modal.Footer>
    </Modal >
  )
}

export default ArticleSelectModal