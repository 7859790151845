import React from 'react'

export const Advertising = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="advertising" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <path className="st0" d="M99.2,21.2c0-0.4-0.1-0.7-0.4-1l-5.6-5.6c-0.5-0.5-1.4-0.5-1.9,0l-4.6,4.6l-0.8-0.8c-0.3-0.3-0.6-0.4-1-0.4
      c-0.4,0-0.7,0.1-1,0.4L22.6,79.7c-0.1,0.1-0.2,0.2-0.3,0.4l-8,17.1c-0.2,0.5-0.1,1.1,0.3,1.6c0.4,0.4,1,0.5,1.6,0.3l17.1-8
      c0.1-0.1,0.3-0.2,0.4-0.3l53-53l3.3,3.3l-0.4,0.4L61.1,70l1.9,1.9L92.9,42c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.4-1l-4.3-4.3
      l6.4-6.4c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.4-1l-0.8-0.8l4.6-4.6C99.1,21.9,99.2,21.5,99.2,21.2z M18.4,95L25,81l7.5,7.5
      L18.4,95z M91.7,28.8L34.3,86.3l-7.2-7.2l0.4-0.4l57.5-57.5l7.2,7.2L91.7,28.8z M92.3,24.8l-3.7-3.7l3.6-3.6l3.7,3.7L92.3,24.8z"/>
    </svg>
  )
}
