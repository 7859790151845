import React, { useState, useEffect, useRef } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { colors } from '../../styles/colors'
import styled from 'styled-components'

const StyledItem = styled.div`
    display:flex;
    line-break:anywhere;
    padding: .25rem 1rem;
    &:hover {
        background:${colors.gray2};
        cursor:pointer;
    }
`

const StyledWrapper = styled.div`
    position:relative;
    width:100%;
`

const StyledAddTag = styled.div`
    position:absolute;
    background:white;
    z-index:1;
    max-height:200px;
    overflow:auto;
    width: 100%;
    border:1px solid ${colors.gray2};
    border-radius: 5px;
    margin-top: 2px;
    padding: .5rem 0;
`


const SuggestionInput = (props) => {
    const {
        suggestions,
        onChangeSuggestion,
        children,
        disabled = false,
        loading = false,
        ...rest
    } = props
    const wrapperRef = useRef(null);
    const [show, setShow] = useState(false)

    function useOutsideDivClick(ref) {
        useEffect(() => {
            /**
             * do something if clicked outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShow(false)
                }
                if (ref.current && ref.current.contains(event.target)) {
                    setShow(true)
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const onClickSuggestions = (obj) => {
        onChangeSuggestion(obj)
        setShow(false)
    }
    useOutsideDivClick(wrapperRef);
    return (
        <StyledWrapper ref={wrapperRef} >
            <Form.Control
                autoComplete="nope"
                disabled={disabled}
                {...rest}
            />

            {(show && !disabled && ((suggestions && suggestions.length) || loading)) ? <StyledAddTag >
                {loading ?
                    <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
                    : suggestions.map(obj => <StyledItem key={obj.id} onClick={() => onClickSuggestions(obj)}>
                        <div>{obj.icon && obj.icon}</div>
                        <div>{obj.displayName}</div>
                    </StyledItem>)}
            </StyledAddTag> : null}
            {children}
        </StyledWrapper>
    )
}

export default SuggestionInput