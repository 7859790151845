import styled from "styled-components";
import { colors } from '../../../styles/colors'

export const StyledCard = styled.div`
background: ${colors.gray6};
border-radius: 5px;
padding: 10px 15px;
border: 1px solid ${colors.gray2};
margin-bottom:2rem;
`
export const StyledList = styled.ul`
  list-style: none;
  padding-left: 0;
  font-size: 1rem;
  margin-bottom: 20px;
  
  li {
    margin-bottom: 10px;
    text-align: left;
    display: flex;
    @media screen and (max-width: 400px){
      flex-wrap: wrap;
    }
  }
  .label {
    flex-basis: 100%;
    font-weight: 600;
        @media screen and (min-width: 401px){
        flex-basis: 50%;
        }
   }
   .value{
    @media screen and (min-width: 401px){
        flex-basis: 50%;
        }
   }
  .comment-text{
    flex-basis: 50%;
   }
  
`