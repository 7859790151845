import React, { useState, useEffect, useMemo } from 'react'
import { Modal, Spinner, Alert, Button } from "react-bootstrap";
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { emptyGuid } from '../../../utils/emptyGuid'
import { cloneDeep } from 'lodash';
import { ButtonWithLoadingState } from '../../Buttons'
import { getErrorMessageFromApiResponse } from "../../../utils/api/apiUtils"
import ConfigurationSelect from '../../Forms/ConfigurationSelect';
import { t } from 'i18next';
import styled from 'styled-components';
import { CustomizationIcon } from '../../Icons/CustomizationIcon';
import { colors } from '../../../styles/colors';

const StyledButtonsWrapper = styled.div`
    .btn {
        margin-left: 5px;
    }

    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        .btn {
            margin: 3px 0;
        }
    }
`


const ConfigurationsModal = ({
    setOpenConfigurationModal,
    employeeId,
    reloadArticles,
    selectedArticle
}) => {
    const [configurations, setConfigurations] = useState(null)
    const [newConfigurations, setNewConfigurations] = useState({})
    const [newComments, setNewComments] = useState({})
    const [loadingSave, setLoadingSave] = useState(false)
    const [error, setError] = useState("")
    const [initialValues, setInitialValues] = useState('')
    const [loadingSaveAndNext, setLoadingSaveAndNext] = useState(false)

    //has to be fixed, bug when reselecting the initial option/comment
    const valuesHaveChanged = useMemo(() => {
        for (const [key, value] of Object.entries(newComments)) {
            let foundConfiguration = configurations.find(config => config.id === key)
            value === '' && foundConfiguration.lastComment === '' && delete newComments[key]
        }
        return initialValues && (`${JSON.stringify(newConfigurations)}_${JSON.stringify(newComments)}` !== initialValues)
    }, [initialValues, newConfigurations, newComments, configurations])

    const handleClose = () => {
        setOpenConfigurationModal(null)
    }

    const saveData = async () => {
        const params = []
        const configurations = []
        for (const [articleConfigurationId, article] of Object.entries(newConfigurations)) {
            configurations.push({ articleConfigurationId: articleConfigurationId, articleId: article.id })
        }
        params.push({ articleId: selectedArticle.article.id, configurations: configurations })
        for (const [articleConfigurationId, comment] of Object.entries(newComments)) {
            const configIdIndex = params[0].configurations.findIndex(newComments => newComments.articleConfigurationId === articleConfigurationId)
            if (configIdIndex >= 0) {
                params[0].configurations[configIdIndex].comment = comment
            }
        }
        return await apiClient.patchJson(APIEndpoints.businessPartner(employeeId).articleConfigurations, params)
    }

    const handleSave = async () => {
        setLoadingSave(true)
        try {
            await saveData()
            await reloadArticles()
            setOpenConfigurationModal(null)
        } catch (e) {
            setError(getErrorMessageFromApiResponse(e))
        }
    }
    const handleSaveAndNext = async () => {
        setLoadingSaveAndNext(true)
        try {
            await saveData()
            await reloadArticles({ startTunnel: true })
        } catch (e) {
            setError(getErrorMessageFromApiResponse(e))
        }
    }

    useEffect(() => {
        apiClient.getJson(APIEndpoints.businessPartner(employeeId, selectedArticle.article.id).articleConfigurationsConfigurations)
            .then(res => {
                setConfigurations(res);
                initializeSelectedConfigurations(res)
            }).catch(e => {
                setError(getErrorMessageFromApiResponse(e))
            })
    }, [employeeId, selectedArticle.article.id])

    const initializeSelectedConfigurations = (configurations) => {
        var newSelectedConfigurations = {}
        var newSelectedComments = {}
        for (const configuration of configurations) {
            if (configuration.lastSelectedArticleID) {
                newSelectedConfigurations[configuration.id] = configuration.selectableArticles.find(obj => obj.id === configuration.lastSelectedArticleID)
            }
            if (configuration.lastComment) {
                newSelectedComments[configuration.id] = configuration.lastComment
            }
        }
        setInitialValues(`${JSON.stringify(newSelectedConfigurations)}_${JSON.stringify(newSelectedComments)}`)
        setNewComments(newSelectedComments)
        setNewConfigurations(newSelectedConfigurations)
    }

    const addConfig = (id, article) => {
        setNewConfigurations(prev => ({ ...prev, [id]: article }))
    }

    const addComment = (id) => e => {
        setNewComments(prev => ({ ...prev, [id]: e.target.value }))
    }

    const deleteConfig = id => {
        const newData = cloneDeep(newConfigurations)
        const config = configurations.find(config => config.id === id)
        const emptyGuidArticle = config.selectableArticles.find(art => art.id === emptyGuid)
        if (emptyGuidArticle) {
            newData[id] = emptyGuidArticle
        }
        else {
            delete newData[id]
        }
        setNewConfigurations(newData)
    }

    return (
        <Modal centered show={true} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton className="pb-0">
                <Modal.Title>
                    <div className="d-flex">
                        <div className="me-3 mt-1">
                            <CustomizationIcon height="2rem" width="2rem" color={colors.gray2} />
                        </div>
                        <div>
                            <p className="m-0">{t('sizeDetection:editCustomization')}</p>
                            <p className="m-0" style={{ color: '#9c9c9c' }}>{t('productsOverview:article')}: {selectedArticle.article.displayName}</p>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!configurations
                    ? <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
                    : configurations.map(config => {
                        const costumConfigurationArticle = newConfigurations[config.id] || {}
                        const customConfigcomment = newComments[config.id] ? newComments[config.id] : ''
                        const mandatoryCommentIsMissing = config.commentIsMandatory && !config.lastComment && !newComments[config.id]
                        const mandaroryArticleIsMissing = config.isMandatory && !config.lastSelectedArticleID && !newConfigurations[config.id]
                        const selectedConfigurationsErrors = (mandatoryCommentIsMissing || mandaroryArticleIsMissing) ? { article: mandaroryArticleIsMissing, comment: mandatoryCommentIsMissing } : null
                        return (
                            <ConfigurationSelect
                                key={config.id}
                                costumConfigurationArticle={costumConfigurationArticle}
                                config={config}
                                customConfigcomment={customConfigcomment}
                                handleRemoveConfig={deleteConfig}
                                handleAddConfig={addConfig}
                                handleAddComment={addComment}
                                selectedConfigurationsErrors={selectedConfigurationsErrors}
                            />
                        )
                    })
                }
                {valuesHaveChanged ? <Alert variant="info">{t('EmployeeFormHealthData:dataChange')}</Alert> : null}
                {error ? <Alert variant="danger">{error}</Alert> : null}
            </Modal.Body>
            <Modal.Footer>
                <StyledButtonsWrapper>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >{t('cancelButton')}</Button>
                    <ButtonWithLoadingState
                        disabled={!newConfigurations || loadingSaveAndNext}
                        buttonText={t('saveButton')}
                        isLoading={loadingSave}
                        onClick={handleSave}
                    />
                    <ButtonWithLoadingState
                        disabled={!newConfigurations || loadingSave}
                        buttonText={t('saveAndContinue')}
                        isLoading={loadingSaveAndNext}
                        onClick={handleSaveAndNext}
                    />
                </StyledButtonsWrapper>
            </Modal.Footer>
        </Modal >
    )
}

export default ConfigurationsModal
