import React from 'react'
import { GoBackBtn } from '../../Buttons/GoBackBtn'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const StyledWrapper = styled.div`
  align-self: flex-start;
  padding: 10px 0;

  .subheader-title-small {
  margin-top: 5px;
  }
`

const SubheaderTitle = ({ title, smallTitle, withBackButton, defaultBackTo, goBackLabel }) => {
  let currentLocation = useLocation()
  const { t } = useTranslation()
  const backLabel = goBackLabel || t('backButton')

  return (
    <StyledWrapper>
      {withBackButton && (
        <div className="d-flex">
          <GoBackBtn color="white"
                     to={(currentLocation.state && currentLocation.state.from) || defaultBackTo || currentLocation.pathname}>
            {(currentLocation.state && currentLocation.state.backLabel) || backLabel}
          </GoBackBtn>
        </div>
      )}
      {title && (
        <div className="subheader-title">
          {title}
        </div>
      )}
      {smallTitle && (
        <div className="subheader-title-small">
          {smallTitle}
        </div>
      )}
    </StyledWrapper>
  )
}

export default SubheaderTitle
