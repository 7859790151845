import { action, observable, makeObservable } from 'mobx';
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { getErrorMessageFromApiResponse } from '../utils/api/apiUtils'

class OrderDialogStore {

  loading = false;
  /**
   * @type {string}
   * one of cancellation, reclamation, retouration
   */
  activeDialog = '';
  success = false;
  groupedOrderPositions = [];
  countries = [];
  error = '';
  contactData = null;
  complaintResponse = {};
  retourResponse = {};
  returnReasons = [];
  complaints = []
  returns = []
  // @observable shippingOrder = null

  constructor(appStore) {
    makeObservable(this, {
      loading: observable,
      activeDialog: observable,
      success: observable,
      groupedOrderPositions: observable,
      countries: observable,
      error: observable,
      contactData: observable,
      complaintResponse: observable,
      retourResponse: observable,
      returnReasons: observable,
      getPositions: action,
      showDialog: action,
      complaints: observable,
      returns: observable
    });

    this.app = appStore
  }

  async loadComplaints(incomingOrderId) {
    const complaints = await apiClient.getJson(APIEndpoints.incomingOrder(incomingOrderId).complaints)
    this.complaints = complaints
  }

  async loadReturns(incomingOrderId) {
    const returns = await apiClient.getJson(APIEndpoints.incomingOrder(incomingOrderId).returns)
    this.returns = returns
  }
  async loadComplaintsContactData(incomingOrderId) {
    const contactData = await apiClient.getJson(APIEndpoints.incomingOrder(incomingOrderId).complaintsContactData)
    this.contactData = contactData ? contactData : {}
  }

  async loadReturnsContactData(incomingOrderId) {
    const contactData = await apiClient.getJson(APIEndpoints.incomingOrder(incomingOrderId).returnsContactData)
    this.contactData = contactData ? contactData : {}
  }

  async loadCountries() {
    this.countries = await apiClient.getJson(APIEndpoints.countries)
  }

  async loadReturnReasons() {
    this.returnReasons = await apiClient.getJson(APIEndpoints.returnReasons)
  }

  async submitCancelation() {

    let finishedRequests = 0
    this.error = ''
    let errors = []
    this.groupedOrderPositions.forEach(async positionGroup => {
      try {
        if (positionGroup.incomingOrder) {
          await apiClient.patchJson(APIEndpoints.incomingOrders(this.app.orderStore.incomingOrder.id).changeIncomingOrderPositions, {
            cancelledPositions: positionGroup.positions.map(position => position.id)
          })
          this.app.profileStore.loadProfile(true)
        } else {
          await apiClient.patchJson(APIEndpoints.changeShippingOrderPositions(positionGroup.shippingOrder.id), {
            cancelledPositions: positionGroup.positions.map(position => position.id)
          })
        }
      } catch (e) {
        errors.push(getErrorMessageFromApiResponse(e) || e.message)
      }

      this.error = errors.length ? errors.join(' ') : ''

      finishedRequests++
      if (finishedRequests >= this.groupedOrderPositions.length) {
        this.success = errors.length === 0
        this.loading = false
      }
    })
  }

  async submitReclamation(formData, files) {
    this.error = ''
    this.complaintResponse = {}
    const positions = this.getPositions().map(position => {
      position.quantity = position._selectedQuantity
      return position
    })

    let params = {
      reportEmail: formData.email,
      comment: formData.comment,
      orderComplaint: {
        targetCostCenter: {
          id: formData.costCenterId
        }
      },
      complaintReason: { id: formData.reason },
      positions: positions
    }

    try {
      this.complaintResponse = await apiClient.postFile(APIEndpoints.incomingOrder(this.app.orderStore.incomingOrder.id).complaints, { data: JSON.stringify(params) }, files)
      this.success = true
    } catch (e) {
      this.error = getErrorMessageFromApiResponse(e) || e.message
      this.success = false
    }
    this.loading = false
  }

  getPositions() {
    return this.groupedOrderPositions.reduce((acc, positionGroup) => {
      //filter positions with _selectedQuantity > 0
      let filteredPositions = positionGroup.positions.filter(position => position._selectedQuantity)
      return acc.concat(filteredPositions)
    }, [])
  }
  createRetourParams(formData) {
    const { firstFormData, secondFormData } = formData
    //reducer function to throw all position objects in one array
    const positions = this.getPositions().map(position => {
      let newPosition = {
        quantity: position._selectedQuantity,
        shippingOrderPosition: position,
        returnReason: {
          id: firstFormData.reason
        }
      }
      if (!!position._reorderVariant) {
        newPosition.isMarkedForReorder = true
        newPosition.reorderVariantId = position._reorderVariant.id
      }

      return newPosition
    })

    return {
      reportEmail: this.contactData.shipmentAddress === null ? this.contactData.email : secondFormData.email,
      comment: firstFormData.comment,
      returnReason: {
        id: firstFormData.reason
      },
      positions: positions,
      phone: this.contactData.shipmentAddress === null ? this.contactData.phone : secondFormData.phone,
      email: this.contactData.shipmentAddress === null ? this.contactData.email : secondFormData.email,
      shipmentAddress: this.contactData.shipmentAddress === null ? null : {
        officeName: secondFormData.company,
        firstName: secondFormData.firstName,
        lastName: secondFormData.lastName,
        street: secondFormData.street,
        streetNr: secondFormData.houseNumber,
        city: secondFormData.city,
        zipCode: secondFormData.zip,
        countryIsoCode: secondFormData.country,
        careOf: secondFormData.careOf
      }
    }
  }
  async submitRetour(formData) {
    this.retourResponse = {}
    let params = this.createRetourParams(formData)
    try {
      this.retourResponse = await apiClient.postJson(APIEndpoints.incomingOrder(this.app.orderStore.incomingOrder.id).returns, params)
      this.success = true
    } catch (e) {
      this.error = getErrorMessageFromApiResponse(e) || e.message
      this.success = false
    }
    this.loading = false
  }
  async submitConvertToReturn(complaintId, formData) {
    this.retourResponse = {}
    let params = this.createRetourParams(formData)
    try {
      this.retourResponse = await apiClient.patchJson(APIEndpoints.complaints(complaintId).convertToReturn, params)
      this.success = true
    } catch (e) {
      this.error = getErrorMessageFromApiResponse(e) || e.message
      this.success = false
    }
    this.loading = false
  }


  showDialog(dialogName, groupedOrderPositions = []) {
    this.activeDialog = dialogName

    groupedOrderPositions.forEach(groupedPosition => {
      groupedPosition.positions.map(position => {

        position._selectedQuantity = this.activeDialog === 'retouration' ? position.returnableQuantity : position.quantity
        return position
      })
    })

    this.groupedOrderPositions = groupedOrderPositions
  }

  closeDialog() {

    //reload incomingOrder if dialog closed after success Action
    if (this.success) {
      this.app.orderStore.resetOrderPage()
    }
    //reset Data
    this.resetData()
  }
  resetData() {
    this.activeDialog = ''
    this.retourResponse = {}
    this.loading = false
    this.success = false
    this.error = ''
    this.contactData = null

  }

}

export default OrderDialogStore
