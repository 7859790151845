import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import StyledPageWrapper from './StyledPageWrapper/StyledPageWrapper'
import Header from '../components/Header/Header'
import { Navigate } from 'react-router-dom'
import styled from 'styled-components'
import ChangePasswordOverlay from '../components/Profile/ChangePasswordOverlay'

const StyleContainer = styled.div`
  margin-top: 0;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
`

class PrivateRoute extends Component {
  render() {
    const { loginStore, children, profileStore } = this.props

    if (loginStore.logoutRedirecting) {
      setTimeout(() => { loginStore.logoutRedirecting = false }, 1500)
      return null
    }

    return !loginStore.isUserLoggedIn
      ? <Navigate to='/login' />
      : <>
        <StyledPageWrapper>
          <Header />
          <StyleContainer>
            {children}
          </StyleContainer>
        </StyledPageWrapper>
        {profileStore.profile.forcePasswordChange && <ChangePasswordOverlay />}
      </>
  }
}

export default inject('loginStore', 'profileStore')(observer(PrivateRoute));
