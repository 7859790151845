import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom';
import { t } from 'i18next'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import SubheaderTitle from '../../components/Page/logix2020/SubheaderTitle'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import BigFooter from '../../components/Footer/BigFooter'
import EntitlementPhaseGeneralForm from '../../components/Configuration/EntitlementPhase/EntitlementPhaseGeneralForm'
import { useEntitlementPhasesState } from '../../stores/Configuration/EntitlementPhasesProvider'


const CreateEntitlementPhasePage = () => {
  const { redirectId, resetEntitlementPhasesPage } = useEntitlementPhasesState()

  useEffect(() => {
    resetEntitlementPhasesPage()
  }, [resetEntitlementPhasesPage])

  // redirect to edit page after new phase was created
  if (redirectId) {
    return <Navigate
      to={`/entitlementPhasesAdministration/edit/${redirectId}`}
      replace
    />
  }


  return (
    <div>
      <Subheader className="subHeader-v1">
        <SubheaderTitle
          withBackButton
          smallTitle={t('entitlementPhases:addEntitlementPhase')}
          goBackLabel={t('entitlementPhases:entitlementPhasesOverview')}
          defaultBackTo="/entitlementPhasesAdministration"
        />
      </Subheader>
      <ContentArea>
        <EntitlementPhaseGeneralForm />
      </ContentArea>
      <BigFooter />
    </div>
  )
}

export default CreateEntitlementPhasePage