import React from 'react'

export const ContingentIcon = (props) => {
  const color = props.color ? props.color : '#3a3a38'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 28 28">
        {props.title && <title>{props.title}</title>}
        <path d="M11.5 14L10 16.9l-3.2.5 2.3 2.3-.6 3.2 2.9-1.5 2.9 1.5-.6-3.2 2.3-2.3-3-.5-1.5-2.9zM14 6.6c4.1 0 7.4 3 8.1 6.8v2.1-.1.8l-.1.5c-1-4.9-5.3-8.5-10.5-8.5-1 0-2 .1-3 .4l.1-.1c1.5-1.2 3.3-1.9 5.4-1.9zm2.5-3.3c4.1 0 7.4 3.3 7.4 7.4v.2c-.1.3-.2.6-.4.9-1.2-4-5-7-9.5-7-1.1 0-2.2.2-3.2.5 1.6-1.1 3.8-2 5.7-2zM18.9 0c3.6 0 6.6 2.9 6.6 6.6 0 .7-.1 1.3-.3 1.9-1-3.9-4.5-6.8-8.8-6.8-.7 0-1.5.1-2.2.3 1.3-1.3 2.9-2 4.7-2zm1.7 19.4c-.1 1.2-.3 2.3-.8 3.3l-.1.2-.1.2-.1.2v-.1s0 .1-.1.1l.1-.2c-.1.2-.2.3-.3.5l-.1.2-.1.1-.2.3-.1.1-.1.2-.1.1v.2l-.1.1-.1.1-.1.1-.1.1-.7.7c-.1.1-.2.1-.3.2l-.1.1c-.1.1-.2.1-.3.2-.1 0-.1.1-.2.1h-.1l-.1.1-.1.1.1-.1-.1.1-.2.1-.3.2h-.2l-.2.1h-.1c-.1.1-.2.1-.4.2l-.2.1-.1.1h-.1-.1l-.2.1H13.5h.1-.4.1c-.1 0-.2 0-.3.1h.1c-.1 0-.2 0-.4.1h-.2H11.3c-5 0-9.1-4.1-9.1-9.1V16.2L3 16v.1-.1-.2c.1-.1.1-.3.2-.4 0 0 0-.1.1-.1v-.1c.1-.1.1-.2.2-.3.1-.1.1-.2.2-.4l.1-.2c.1-.3.2-.4.3-.5.6-.8 1.2-1.5 2-2.1l.4-.3.2-.1-.1.1c.1 0 .1-.1.2-.1.1-.1.2-.1.3-.1.1 0 .2-.1.2-.1h.1c.4-.2.8-.4 1.3-.5l.3-.3H9.3 9l.5-.1h.1c.1 0 .2 0 .3-.1h.2c.1 0 .3 0 .4-.1h2.2-.1.4c.2 0 .4.1.6.1h.1c.2 0 .4.1.6.2l.2.1c.1 0 .1 0 .2.1.2.1.4.1.5.2h.1l.2.1.1.1.1.1.1.1s.1 0 .1.1c.1 0 .1.1.2.1s.1.1.2.1l.1.1h.1c.2.1.3.2.5.4l.1.1.1.1c.1 0 .1.1.1.1.1.1.3.2.4.4l.1.1.4.4.1.1c0 .1.1.1.1.2l.1.1.2.2.2.2c.1.1.1.2.2.3 0 0 0 .1.1.1l.1.2s0 .1.1.1c.1.2.2.4.3.5l.1.2v.1l.1.1c0 .1 0 .1.1.2 0 0 0 .1.1.1l.3.9v.1c0 .1 0 .1.1.2v.5c.1.5.1 1 .1 1.6v.3z"
              fill={color}
              fillRule="evenodd"
              clipRule="evenodd"
        />
    </svg>
  )
}




