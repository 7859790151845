import React, { useState, useEffect, useRef } from 'react'
import { Form, Alert, Button, Row, Col } from 'react-bootstrap'
import OfficeSelect from '../../Employees/Form/FormComponents/OfficeSelect'
import styled from 'styled-components'
import OverlayContainer from '../../Page/logix2020/OverlayContainer'
import OverlayRight from '../../Page/logix2020/OverlayRight'
import LanguageForm from "../../Forms/LanguageForm"
import { useTranslation } from 'react-i18next'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { useLocation, useNavigate } from 'react-router-dom'
import { StyledValidationMessage } from '../../Employees/Form/formHelper'
import MediaQuery from 'react-responsive'
import { setPointerErrorsFromApiResponse } from "../../../utils/api/apiUtils"
import LanguageNameInput from "../../Forms/LanguageNameInput"
import InformationPopover from '../../InformationPopover/InformationPopover'
import MandatoryField from '../../MandatoryField/MandatoryField'
import useStores from '../../../stores/useStore'


const StyledFormWrapper = styled.div`
  max-width: 600px;
  .no-drop {
    cursor: no-drop
  }
  .default  {
    cursor: default
  }
  @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px) {
    max-width:none;
  }
`
const AlertWrapper = styled.div`
  margin-top:2rem;
`

const OfficeGeneralForm = ({ officeDetails, mode = "create" }) => {

  const { t } = useTranslation()
  let editMode = mode === "edit"

  const [namesTranslation, setNamesTranslation] = useState(officeDetails?.nameExternal?.values || [])
  const [commentTranslation, setCommentTranslation] = useState(officeDetails?.comment?.values || [])
  const [name, setName] = useState(officeDetails?.name || "");
  const [parentOffice, setParentOffice] = useState(officeDetails?.parentOffice?.id || "");
  const [number, setNumber] = useState(officeDetails?.number || "");
  const [foreignKey, setForeignKey] = useState(editMode ? officeDetails.foreignKey : "" || "");
  const [overlayVisible, setOverlayVisible] = useState(false)
  const [languageOverlayFieldName, setLanguageOverlayFieldName] = useState("")
  const [isGroupItem, setIsGroupItem] = useState(officeDetails?.isGroupItem)
  const [formErrors, setFormErrors] = useState({})
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [error, setError] = useState("")
  const mountedRef = useRef(true)
  const { officeStore } = useStores()
  const [redirectId, setRedirectId] = useState("")
  const [successCreated, setSuccessCreated] = useState(false)
  const [successUpdated, setSuccessUpdated] = useState(false)
  const navigate = useNavigate()
  let location = useLocation()

  //Cleanup Function
  useEffect(() => {
    return () => { mountedRef.current = false }
  }, [])

  const handleClick = (fieldName) => {
    setOverlayVisible(!overlayVisible)
    setLanguageOverlayFieldName(fieldName);
  }
  const handleCloseOverlay = () => {
    setOverlayVisible(false)
  }

  useEffect(() => {
    if (location.state && location.state.createNewOffice) {
      setSuccessCreated(true)
    }
  }, [location.state])

  const handleClickSubmit = () => {

    const params = {
      "name": name,
      "nameExternal": {
        "values": namesTranslation
      },
      "comment": {
        "values": commentTranslation
      },
      "number": number,

      "foreignKey": foreignKey,
      "isGroupItem": isGroupItem,
    }
    if (parentOffice) {
      params.parentOffice = { "id": parentOffice }
    }

    if (mode === "create" || mode === "copy") {
      apiClient.postJson(
        APIEndpoints.newOffices,
        params,
        setSuccessCreated(false),
      ).then((response) => {
        if (!mountedRef.current) { return null }
        setSuccessCreated(true)
        officeStore.resetPartialOffices()
        setRedirectId(response?.id)
      }).catch(error => {
        //setApiError(error)
        setFormErrors(setPointerErrorsFromApiResponse(error))
      }
      )
    }

    if (mode === "edit") {
      apiClient.patchJson(
        APIEndpoints.offices(officeDetails.id).details,
        params,
        setSuccessUpdated(false),
      )
        .then(() => {
          if (!mountedRef.current) { return null }
          setSuccessUpdated(true)
          officeStore.resetPartialOffices()
        })
        .catch((error) => {
          //setApiError(error)
          setFormErrors(setPointerErrorsFromApiResponse(error))
        })
    }
  }


  const handleChangeParentOffice = (officeId) => {
    setParentOffice(officeId)
    if (mode === "edit") {
      if (officeDetails.id === officeId) {
        setBtnDisabled(true)
        setError(`${t('costCenterAdmin:chooseOtherOffice')}`)
      } else {
        setBtnDisabled(false)
        setError("")
      }
    }
  }



  useEffect(() => {
    if (redirectId) {
      navigate(`/officeadministration/edit/${redirectId}#general`, { replace: true, state: { createNewOffice: true } })
    }
  }, [navigate, redirectId])



  return (
    <div>
      <StyledFormWrapper>
        <div className="mb-5">
          <Row>
            <Col>
              <Form.Group className="mb-3" >
                <Form.Label>{t('name')}*</Form.Label>
                <Form.Control placeholder={t('name')} value={name} onChange={e => setName(e.target.value)} isInvalid={formErrors.name} />
                <Form.Control.Feedback type="invalid">
                  {formErrors.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <LanguageNameInput
                openLanguageModal={() => handleClick("name")}
                languageArray={namesTranslation}
                onChange={setNamesTranslation}
                label={`${t('officeAdmin:externerName')}*`}
                isInvalid={formErrors && formErrors.nameExternal}
              />
            </Col>

          </Row>
          <Form.Group className="mb-3">
            <Form.Check type="checkbox" checked={isGroupItem} onChange={e => setIsGroupItem(e.target.checked)} label={t('officeAdmin:groupingElement')} id="is-group-element" isInvalid={formErrors.isGroupItem} />
            {formErrors.isGroupItem &&
              <StyledValidationMessage>
                {formErrors.isGroupItem}
              </StyledValidationMessage>
            }
          </Form.Group>
        </div>
        <LanguageNameInput
          openLanguageModal={() => handleClick("comment")}
          languageArray={commentTranslation}
          onChange={setCommentTranslation}
          label={t('comment')}
          isInvalid={formErrors && formErrors.comment}
        />
        <Form.Group className="mb-3">
          <Form.Label>{t('costCenterAdmin:parentOffice')}</Form.Label>
          <OfficeSelect id="officeGeneralFormOfficeTree"
            value={parentOffice}
            onChange={handleChangeParentOffice}
            isInvalid={formErrors.parentOffice} />
          <Form.Control.Feedback type="invalid">
            {formErrors.parentOffice}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('costCenterAdmin:number')}</Form.Label>
          <Form.Control placeholder={t('costCenterAdmin:number')} type="number" value={number} onChange={e => setNumber(e.target.value)} isInvalid={formErrors.number} />
          <Form.Control.Feedback type="invalid">
            {formErrors.number}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('costCenterAdmin:foreignKey')}<InformationPopover header={t('costCenterAdmin:foreignKey')} content={t('officeAdmin:noteForeignKey')} /></Form.Label>
          <Form.Control placeholder={t('costCenterAdmin:foreignKey')} value={foreignKey} onChange={e => setForeignKey(e.target.value)} isInvalid={formErrors.foreignKey} disabled={editMode} />
          <Form.Control.Feedback type="invalid">
            {formErrors.foreignKey}
          </Form.Control.Feedback>
        </Form.Group>
        <MediaQuery minWidth={769}>
          <div className="d-flex justify-content-between">
            <MandatoryField />
            <Button onClick={handleClickSubmit} className={btnDisabled === true ? "no-drop" : "pointer"} disabled={btnDisabled}>{t('saveButton')}</Button>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <MandatoryField />
          <Button onClick={handleClickSubmit} className={btnDisabled === true ? "no-drop w-100" : "pointer w-100"} disabled={btnDisabled}>{t('saveButton')}</Button>
        </MediaQuery>
        <AlertWrapper>
          {successCreated ?
            <Alert variant="success">
              {t('costCenterAdmin:successCreated')}
            </Alert>
            : ""
          }{successUpdated ?
            <Alert variant="success">
              {t('costCenterAdmin:successUpdate')}
            </Alert>
            : ""
          }
          {error ?
            <Alert variant="danger">
              {error}
            </Alert>
            : ""
          }
        </AlertWrapper>
      </StyledFormWrapper>
      <OverlayContainer>
        <OverlayRight visible={overlayVisible} onClose={handleCloseOverlay}>
          {
            languageOverlayFieldName === "name" && <LanguageForm languageTitle={t('officeAdmin:externerName')} handleCloseOverlay={handleCloseOverlay} languageArray={namesTranslation} onChange={setNamesTranslation} />
          }
          {
            languageOverlayFieldName === "comment" && <LanguageForm languageTitle={t('comment')} handleCloseOverlay={handleCloseOverlay} languageArray={commentTranslation} onChange={setCommentTranslation} />
          }
        </OverlayRight>
      </OverlayContainer>
    </div>
  )
}

export default OfficeGeneralForm
