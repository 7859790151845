import React, { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import InventoryTree from './InventoryTree'
import InventorySearchBox from '../InventorySearchBox'
import AllArticlesSwitch from '../AllArticlesSwitch'
import { useTranslation } from "react-i18next";

const AddArticlesModal = ({ handleClose, actionType }) => {
  const listRef = useRef(null)
  const { t } = useTranslation()
  return (
    <Modal size="lg" show={true} onHide={handleClose} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>{t('inventory:addArticle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body ref={listRef}>
        {actionType === "inventoryBookings" ? <AllArticlesSwitch /> : null}
        <InventorySearchBox />
        <hr />

        <InventoryTree listRef={listRef} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("closeButton")}
        </Button>
      </Modal.Footer>
    </Modal >
  );
}

export default AddArticlesModal