import React, { useEffect, useState } from 'react'
import { CircleCheckIcon } from '../../../Icons/CircleCheckIcon'
import { StyledCircleCheckIconWrapper } from './StyledCircleCheckIconWrapper'
import { useTranslation } from 'react-i18next'

const MainDataCheck = ({ mainData }) => {
  const [isValid, setIsValid] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!!mainData.lastName && !!mainData.firstName && !!mainData.gender && !!mainData.personalNr && !!mainData.dateStartEmployment && mainData.costCenter.id !== "00000000-0000-0000-0000-000000000000" && mainData.office.id !== "00000000-0000-0000-0000-000000000000") {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [mainData])

  return (
    <>
      <StyledCircleCheckIconWrapper>
        <CircleCheckIcon variant={isValid ? 'done' : 'must'} />
      </StyledCircleCheckIconWrapper>
      <span>{t('OverlayProgress:mainData')}</span>
    </>
  )
}

export default MainDataCheck
