import React from 'react'
import { Form } from 'react-bootstrap'

import QuarantineData from '../Form/FormParts/QuarantineData'
import CheckUpsData from '../Form/FormParts/CheckUpsData'


const EditContentArea = () => {

  return (

    <Form>
      <QuarantineData />
      <CheckUpsData />
    </Form>

  )
}

export default EditContentArea
