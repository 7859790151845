import CreateCostCenterSelect from './CreateCostCenterSelect'
import CreateOfficeSelect from './CreateOfficeSelect'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import { emptyGuid } from '../../../utils/emptyGuid'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import CustomDatePicker from '../../Forms/CustomDatePicker'
import { getIsoDate } from '../../../utils/getIsoDate'
import { useTranslation } from 'react-i18next'
import useStores from '../../../stores/useStore'
import { StyledValidationMessage } from '../../Employees/Form/formHelper'
import ArraySelect from '../../Select/ArraySelect'
import MandatoryField from '../../MandatoryField/MandatoryField'
import ArticleSelect from './ArticleSelect'


const DynamicFormElements = ({ formConfigTemplate, setFormData, formData, preSelectedData = {}, formErrors }) => {
    const { t } = useTranslation()
    const { profileStore } = useStores()
    const [shipmentTypes, setShipmentTypes] = useState(null)
    const [cabinTypes, setCabinTypes] = useState(null)
    const [officeAddresses, setOfficeAddresses] = useState(null)
    const [catalogs, setCatalogs] = useState(null)
    const [dateFrom, setDateFrom] = useState(null)
    const [dateTo, setDateTo] = useState(null)
    const profileId = profileStore.profile.id
    const handleChangeDate = useCallback(setDate => date => {
        setDate(date)
    }, [])
    useEffect(() => {
        if (formConfigTemplate.find(item => item.type === 'dateRange')) {
            setFormData(prev => {
                if (dateFrom) {
                    return { ...prev, startDate: getIsoDate(dateFrom, false) }
                }
                delete prev.startDate
                return prev
            })
        }
    }, [setFormData, dateFrom, formConfigTemplate])

    useEffect(() => {
        if (formConfigTemplate.find(item => item.type === 'dateRange')) {
            setFormData(prev => {
                if (dateFrom) {
                    return { ...prev, endDate: getIsoDate(dateTo, true) }
                }
                delete prev.endDate
                return prev
            })
        }
    }, [setFormData, dateTo, formConfigTemplate, dateFrom])


    const handleChange = useCallback(e => {
        const { target } = e
        if (target.type === 'checkbox') {
            setFormData(prev => ({ ...prev, [target.name]: target.checked }))
        } else {
            setFormData(prev => ({ ...prev, [target.name]: target.value }))
        }
    }, [setFormData])

    useEffect(() => {
        const loadShipmentTypes = formConfigTemplate.some(item => item.name === 'shipmentTypeId')
        if (loadShipmentTypes) {
            apiClient.getJson(APIEndpoints.shipmentTypes()).then(response => {
                setShipmentTypes(response)
            })
        } else setShipmentTypes({})

        const loadOfficeAddresses = formConfigTemplate.some(item => item.name === 'officeAddressID')
        if (loadOfficeAddresses) {
            apiClient.getJson(APIEndpoints.businessPartner(profileId).officeAdresses).then(response => {
                setOfficeAddresses(response)
            })
        } else setOfficeAddresses({})

        const loadCabinTypes = formConfigTemplate.some(item => item.name === 'cabinID')
        if (loadCabinTypes) {
            apiClient.getJson(APIEndpoints.cabins()).then(response => {
                setCabinTypes(response)
            })
        } else setCabinTypes({})

        const loadCatalogs = formConfigTemplate.some(item => item.name === 'catalogId')
        if (loadCatalogs) {
            apiClient.getJson(APIEndpoints.administration().catalogs).then(response => {
                setCatalogs(response)
            })
        } else setCatalogs({})
    }, [formConfigTemplate, profileId])

    const formConfig = useMemo(() => {
        if (shipmentTypes && officeAddresses && cabinTypes && catalogs) {
            const preparedFormConfig = [...formConfigTemplate]

            const shipmentTypesConfig = preparedFormConfig.find(item => item.name === 'shipmentTypeId')
            if (shipmentTypesConfig) {
                shipmentTypesConfig.options = shipmentTypes ? shipmentTypes : []
            }
            const officeAddressesConfig = preparedFormConfig.find(item => item.name === 'officeAddressID')
            if (officeAddressesConfig) {
                officeAddressesConfig.options = officeAddresses ? officeAddresses : []
            }
            const cabinTypesConfig = preparedFormConfig.find(item => item.name === 'cabinID')
            if (cabinTypesConfig) {
                cabinTypesConfig.options = cabinTypes ? cabinTypes : []
            }
            const catalogsConfig = preparedFormConfig.find(item => item.name === 'catalogId')
            if (catalogsConfig) {
                catalogsConfig.options = catalogs ? catalogs : []
            }
            return preparedFormConfig
        }
    }, [shipmentTypes, officeAddresses, cabinTypes, catalogs, formConfigTemplate])

    useEffect(() => {
        if (formConfig) {
            //prefill preSelectedData by name
            setFormData(prev => {
                const newValues = prev
                for (const [key, value] of Object.entries(preSelectedData)) {
                    let formDataObj = formConfig.find(item => item.name === key)
                    if (formDataObj) {
                        newValues[key] = value
                    }
                }
                return newValues
            })
            //prefill Date
            const dateRange = formConfig.find(item => item.type === 'dateRange')
            if (dateRange && preSelectedData && preSelectedData.dateRange) {
                setDateFrom(preSelectedData.dateRange[0])
            }
            if (dateRange && preSelectedData && preSelectedData.dateRange) {
                setDateTo(preSelectedData.dateRange[1])
            }
        }
    }, [formConfig, preSelectedData, setFormData])

    return formConfig
        ? <>
            {formConfig.map((item, index) => (
                <div key={`${item.type}-${item.name}-${index}`}>

                    {item.type === 'spacer' && <div className="mb-5" />}

                    {item.type === 'dateRange' && (
                        <>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('from')}</Form.Label>
                                        <CustomDatePicker
                                            dateFormat="dd.MM.yyyy"
                                            placeholderText={t('date')}
                                            onChange={handleChangeDate(setDateFrom)}
                                            showYearDropdown
                                            selected={dateFrom}
                                            isInvalid={formErrors && formErrors[item.name]}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('to')}</Form.Label>
                                        <CustomDatePicker
                                            dateFormat="dd.MM.yyyy"
                                            placeholderText={t('date')}
                                            onChange={handleChangeDate(setDateTo)}
                                            showYearDropdown
                                            selected={dateTo}
                                            isInvalid={formErrors && formErrors[item.name]}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {formErrors && formErrors[item.name] && <StyledValidationMessage>{formErrors[item.name]}</StyledValidationMessage>}
                        </>

                    )}
                    {item.type === 'costCenterSelect' && (
                        <>
                            <CreateCostCenterSelect
                                name={item.name}
                                value={formData[item.name]}
                                setFormData={setFormData}
                                label={t('costcenter')}
                                withSearch
                                includeDeleted={!!formData.includeDeletedCostCenters}
                                errorMessage={formErrors && formErrors[item.name]}
                                isInvalid={formErrors && formErrors[item.name]}
                            />
                        </>
                    )}
                    {item.type === 'officeSelect' && (
                        <>
                            <CreateOfficeSelect
                                name={item.name}
                                value={formData[item.name]}
                                setFormData={setFormData}
                                label={t('orgaUnit')}
                                withSearch
                                errorMessage={formErrors && formErrors[item.name]}
                                isInvalid={formErrors && formErrors[item.name]}
                            />
                        </>
                    )}
                    {/* e=>handleChange(e.target.name, e.target.checked) */}
                    {item.type === 'checkbox' && (
                        <Form.Group className="mb-3">
                            <Form.Check
                                id={item.name}
                                label={t(`reportConfig:${item.label}`)}
                                type="checkbox"
                                name={item.name}
                                onChange={handleChange}
                                checked={formData[item.name] || false}
                                isInvalid={formErrors && formErrors[item.name]}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formErrors && formErrors[item.name]}
                            </Form.Control.Feedback>
                        </Form.Group>

                    )}

                    {item.type === 'select' && (
                        <Form.Group className="mb-3">
                            <Form.Label>{`${t(`reportConfig:${item.label}`)} ${item.isMandatoryField ? '*' : ''}`}</Form.Label>
                            <ArraySelect
                                returnEvent={true}
                                options={item.options}
                                name={item.name}
                                onChange={handleChange}
                                value={formData[item.name]}
                                isInvalid={formErrors && formErrors[item.name]}
                                defaultOption={{ value: item.defaultIsEmptyGuid ? emptyGuid : '', name: t(item.defaultName) }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formErrors && formErrors[item.name]}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    {/* e=>handleChange(e.target.name, e.target.value) */}
                    {item.type === 'text' && (
                        <Form.Group className="mb-3">
                            <Form.Label>{t(`reportConfig:${item.label}`)}</Form.Label>
                            <Form.Control name={item.name} onChange={handleChange} value={formData[item.name] || ''} isInvalid={formErrors && formErrors[item.name]} autoComplete="email" />
                            <Form.Control.Feedback type="invalid">
                                {formErrors && formErrors[item.name]}
                            </Form.Control.Feedback>

                        </Form.Group>
                    )}

                    {item.type === 'articleSelect' && (
                        <ArticleSelect
                            name={item.name}
                            value={formData[item.name]}
                            setFormData={setFormData}
                        />

                    )}
                    {item.isMandatoryField && <MandatoryField />}
                </div>
            ))}
        </>
        : <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >

}
export default DynamicFormElements
