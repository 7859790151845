import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled, { withTheme } from 'styled-components'
import { PageLoadingLayer } from '../components/Loading/PageLoadingLayer'
import { StyledCard } from '../components/Cards/StyledCard'
import { PenIcon } from '../components/Icons/PenIcon'
import { ShoppingCartSummary } from '../components/ShoppingCart/ShoppingCartSummary'
import { ArrowLeftLink } from '../components/ArrowLeftLink/ArrowLeftLink'
import { formatedDate } from '../utils/formatedDate'
import ArticlesList from '../components/ShoppingCart/ArticlesList'
import { withTranslation } from 'react-i18next'
import { Subheader } from '../components/Page/logix2020/Subheader'
import SubheaderTitle from '../components/Employees/Subheader/SubheaderTitle'
import EmployeeData from '../components/Employees/Subheader/EmployeeData'
import { Link } from 'react-router-dom'
import ShoppingCartProgress from '../components/ShoppingCart/ShoppingCartProgress'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import { TestSidebarRight } from '../components/Page/logix2020/TestSidebarRight'
import BigFooter from '../components/Footer/BigFooter'
import { FlexWrapper } from '../components/Page/logix2020/FlexWrapper'
import withRouter from '../utils/withRouter'

const LinearGradient = styled.div`
width:100%;
@media only screen and (min-width: 1281px) {
  background: linear-gradient(to left, rgb(238, 238, 238) 0%, rgb(238, 238, 238) 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
 }
`
const StyledContentArea = styled(ContentArea)`
   padding: 46px 40px 20px 36px;
   @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px)  {
    padding: 20px;
    min-height: calc(100vh - 607px);//header+navbar+sidebar
   }
  
`
const StyledFlexWrapper = styled(FlexWrapper)`
max-width: 1280px;
margin-left:auto;
margin-right:auto;
min-height:0px;
`
const StyledH1 = styled.h1`
  margin: 0 0 25px 0;
`

const StyledH4 = styled.h2`
  font-size: 20px;
  margin-bottom: 25px;
`
const StyledAddressCard = styled.div`
  width: 100%;
  padding: 10px;

  @media screen and (min-width: 768px) {
    width: 100%;
    display: flex;

    > div {
      flex-grow: 1;
    }
  }
`



class ConfirmOrderPage extends Component {

  componentDidMount() {
    this.initialize()
  }
  componentDidUpdate(prevProps) {
    const params = this.props.params
    const prevParams = prevProps.params
    if (params.costCenterId !== prevParams.costCenterId || params.shoppingBasketId !== prevParams.shoppingBasketId) {
      this.initialize()
    }
  }

  initialize = async () => {
    const { teamOrderStore, productsStore, checkoutStore, params, navigate } = this.props
    const { storeHouseId, costCenterId, shoppingBasketId, orderType } = params;
    productsStore.resetPageErrors()
    console.log('ConfirmOrderPage initialize:', storeHouseId, costCenterId, orderType)
    teamOrderStore.initializeCurrentProcessType(storeHouseId, costCenterId, orderType)

    if (shoppingBasketId) {
      try {
        let basket = await productsStore.loadShoppingBasket(shoppingBasketId)
        checkoutStore.resetConfirmOrder(basket)
        productsStore.loadShoppingBasketAvailabilities(shoppingBasketId)
        productsStore.fixShoppingBasketStatus(navigate)
        checkoutStore.cwsOrderCommentProcess(basket)
      }
      catch (e) { }
    }
  }


  isConfirmOrderPageLoading() {
    return this.props.productsStore.loadOrCreateBasketIsLoading
  }

  renderAddresses() {
    return (
      <div className='mb-5'>
        <div className='d-flex flex-column flex-md-row' style={{ margin: '0 -10px' }}>
          <div className='flex-grow-1'>
            {this.renderShipmentAddress()}
          </div>
          <div className='flex-grow-1'>
            {this.renderBillingAddress()}
          </div>
        </div>
      </div>
    )
  }

  renderShipmentAddress() {
    const { t, productsStore, uiStore } = this.props
    const { basket } = productsStore
    if (!basket || !basket.shipmentAddress) {
      return
    }
    const { shipmentAddress } = basket
    const formattedAddressParts = shipmentAddress.formattedCompleteAddress ? shipmentAddress.formattedCompleteAddress.split('\n') : []

    return (
      <React.Fragment>
        <StyledH4 style={{ paddingLeft: '10px' }}>{t('confirmOrder:shippingAddress')}</StyledH4>
        <StyledAddressCard>
          <StyledCard invalid={productsStore.pageErrorPointers['/shipmentAddress']}>
            <StyledCard.Headline>{shipmentAddress.displayName}</StyledCard.Headline>
            <StyledCard.Body>
              <div className="edit">
                <Link to={uiStore.linkToConfirmAddress}><PenIcon /></Link>
              </div>
              <StyledCard.Body.Title>{formattedAddressParts.slice(0, 1)}</StyledCard.Body.Title>
              <div>
                {formattedAddressParts.slice(1).map((item, key) => {
                  return <React.Fragment key={key}>{item}<br /></React.Fragment>
                })}
              </div>
              <div className='mt-2 text-gray' style={{ fontSize: '14px' }}>
                <b>{t('confirmOrder:shipmentType')}</b> {basket.shipmentType.displayName}
                {productsStore.isScheduledPickUpDateRequired &&
                  <div>{t('confirmOrder:pickupDate')}: {formatedDate(basket.scheduledPickUpDate)}</div>
                }
              </div>
            </StyledCard.Body>
          </StyledCard>
        </StyledAddressCard>
      </React.Fragment>
    )
  }

  renderBillingAddress() {
    const { t, uiStore, productsStore } = this.props
    const { basket } = productsStore
    if (!basket || !basket.billingAddress) {
      return
    }
    const { billingAddress } = basket
    const formattedAddressParts = billingAddress.formattedCompleteAddress ? billingAddress.formattedCompleteAddress.split('\n') : []

    return (
      <React.Fragment>
        <StyledH4 style={{ paddingLeft: '10px' }}>{t('confirmOrder:billingAddress')}</StyledH4>
        <StyledAddressCard>
          <StyledCard invalid={productsStore.pageErrorPointers['/billingAddress']}>

            <StyledCard.Headline>{billingAddress.displayName}</StyledCard.Headline>
            <StyledCard.Body>
              <div className="edit">
                <Link to={uiStore.linkToConfirmAddress}><PenIcon /></Link>
              </div>
              <StyledCard.Body.Title>{formattedAddressParts.slice(0, 1)}</StyledCard.Body.Title>
              <div>
                {formattedAddressParts.slice(1).map((item, key) => {
                  return <React.Fragment key={key}>{item}<br /></React.Fragment>
                })}
              </div>
            </StyledCard.Body>
          </StyledCard>
        </StyledAddressCard>
      </React.Fragment>
    )
  }

  renderArticles() {
    const { t, productsStore, uiStore } = this.props
    const { basket } = productsStore
    if (!basket || !basket.articles) {
      return
    }
    const { articles } = basket
    const basketArticlesAmount = productsStore.getBasketArticlesAmount()
    return (
      <div className='mb-5'>
        <div className='d-flex justify-content-between align-items-start mb-4 border-bottom'>
          <div>
            <StyledH4 className='mb-3'>{t('shoppingCart')}</StyledH4>
          </div>
          <div>
            <span className='text-gray'>{basketArticlesAmount} {t('articles', { count: basketArticlesAmount })}</span>
          </div>
        </div>
        <ArticlesList articles={articles} variant='simple' />
        <div className='text-end mt-4'>
          <ArrowLeftLink to={uiStore.linkToBasket}>
            {t('confirmOrder:changeProducts')}
          </ArrowLeftLink>
        </div>
      </div>
    )
  }

  render() {
    const { t, productsStore, profileStore, uiStore } = this.props
    const basket = productsStore.basket ? productsStore.basket : {}

    return (
      <>
        <Subheader className="with-progress subHeader-v1">
          <SubheaderTitle withBackButton defaultBackTo={uiStore.linkToConfirmAddress} goBackLabel={t('shoppingCart:shipping')} />
          <div className="flex-grow-1 justify-content-center ps-4 pe-4">
            <ShoppingCartProgress>
              <ul>
                <li className="active">
                  <Link to={uiStore.linkToBasket}><div className="bubble-wrapper">{t('SubheaderProgress:shoppingCart')}</div></Link>
                </li>
                <li className="active">
                  <Link to={uiStore.linkToConfirmAddress}><div className="bubble-wrapper">{t('SubheaderProgress:shipping')}</div></Link>
                </li>
                <li className="active"><div className="bubble-wrapper">{t('SubheaderProgress:orderCompletion')}</div></li>
                <li><div className="bubble-wrapper">{t('SubheaderProgress:done')}</div></li>
              </ul>
            </ShoppingCartProgress>
          </div>
          {basket.targetOrderProcessType === 'personal' && (
            <EmployeeData />
          )}
        </Subheader>
        <LinearGradient>
          <StyledFlexWrapper>
            <StyledContentArea noPadding noPaddingY>
              <div>
                <StyledH1>{t('confirmOrder:confirmOrderTitle')}</StyledH1>
              </div>
              <PageLoadingLayer isLoading={this.isConfirmOrderPageLoading()}
                loadingText={t('shipmentAddress:loadAddresses')}>
                {this.isConfirmOrderPageLoading() ? '' :
                  <React.Fragment>
                    {this.renderAddresses()}
                    {this.renderArticles()}
                  </React.Fragment>
                }

              </PageLoadingLayer>
            </StyledContentArea>
            <TestSidebarRight>
              <ShoppingCartSummary basket={basket} profile={profileStore.profile} />
            </TestSidebarRight>
          </StyledFlexWrapper>
          <BigFooter />
        </LinearGradient>
      </>
    )
  }
}

export default withTheme(withTranslation()(withRouter(inject('uiStore', 'productsStore', 'profileStore', 'teamOrderStore', 'checkoutStore')(observer(ConfirmOrderPage)))))
