import React, { useEffect, useState, useMemo } from 'react'
import { Button, Form } from 'react-bootstrap'
import { t } from 'i18next'
import styled from 'styled-components'
import { cloneDeep } from 'lodash'
import ModalComponent2 from '../../Modals/ModalComponent2'
import { useCatalogsState } from '../../../stores/Configuration/CatalogsProvider'
import { emptyGuid } from '../../../utils/emptyGuid'
import { ButtonWithLoadingState } from '../../Buttons'
import OCITextTable from './OCITextTable'
import OCIAliasTable from './OCIAliasTable'

const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`

const CatalogOciInterface = () => {
  const { catalog, allOciParameters, aliasTypes, loadingStates, errorStates, deleteParameterRow, setDeleteParameterRow, setSuccessStates, patchCatalog } = useCatalogsState()
  const [ociAliasTypes, setOciAliasTypes] = useState([])
  const [ociTextTypes, setOciTextTypes] = useState([])
  const [initialOciTextTypes, setInitialOciTextTypes] = useState([])
  const [initialOciAliasTypes, setInitialOciAliasTypes] = useState([])
  const [useOciInterface, setUseOciInterface] = useState(false)
  const [initialUseOciInterface, setInitialUseOciInterface] = useState(false)

  useEffect(() => {
    if (catalog && catalog.catalog) {
      setUseOciInterface(catalog.catalog.externalCatalogUseOCIInterface)
      setInitialUseOciInterface(catalog.catalog.externalCatalogUseOCIInterface)
    }
  }, [catalog])

  useEffect(() => {
    if (allOciParameters) {
      let textTypes = []
      let aliasTypes = []
      allOciParameters.forEach(item => {
        if (item.type === 'text') {
          textTypes.push(item)
        } else {
          aliasTypes.push(item)
        }
      })
      let fixedValueIndex = aliasTypes.findIndex(aliasType => aliasType.name === '425BA011-AB56-49B8-9D31-5D7EA6FF5E19')
      if (fixedValueIndex >= 0) {
        aliasTypes[fixedValueIndex].name = 'Fixwert'
      }
      setOciTextTypes(textTypes)
      setOciAliasTypes(aliasTypes)
      setInitialOciTextTypes(JSON.stringify(textTypes))
      setInitialOciAliasTypes(JSON.stringify(aliasTypes))
    }
  }, [allOciParameters])

  const useOciInterfaceHasChanged = useMemo(() => {
    return initialUseOciInterface !== useOciInterface
  }, [initialUseOciInterface, useOciInterface])

  const ociTextTypesHasChanged = useMemo(() => {
    return initialOciTextTypes !== JSON.stringify(ociTextTypes)
  }, [initialOciTextTypes, ociTextTypes])

  const ociAliasTypesHasChanged = useMemo(() => {
    return initialOciAliasTypes !== JSON.stringify(ociAliasTypes)
  }, [initialOciAliasTypes, ociAliasTypes])

  const addParameter = (type) => {
    let newTypes = type === 'text' ? [...ociTextTypes] : [...ociAliasTypes]
    newTypes.push({
      name: '',
      value: '',
      type: type,
      id: `${emptyGuid}-${newTypes.length}`,  // length is added so that each new row has a unique id (needed for the table, changed to emptyGuid when saving)
      isNewParameter: true,
      constantValue: ''
    })
    type === 'text' ? setOciTextTypes(newTypes) : setOciAliasTypes(newTypes)
  }

  const deleteParameter = (row) => {
    const type = row.type
    let parametersCopy = type === 'text' ? [...ociTextTypes] : [...ociAliasTypes]
    let rowIndex = parametersCopy.findIndex(item => row.id === item.id)

    // if isNewParameter, row is only locally added -> remove directly from array, if not -> make the call to delete it
    if (row.isNewParameter) {
      parametersCopy.splice(rowIndex, 1)
      type === 'text' ? setOciTextTypes(parametersCopy) : setOciAliasTypes(parametersCopy)
      setDeleteParameterRow('')
    } else {
      let newCatalog = cloneDeep(catalog)
      let foundIndex = newCatalog.oCIParameters.findIndex(item => item.id === row.id)
      newCatalog.oCIParameters.splice(foundIndex, 1)
      patchCatalog(newCatalog.catalog.id, newCatalog)
    }
  }

  const handleSubmit = (type) => {
    let newCatalog = cloneDeep(catalog)
    let newOciParameters = ociTextTypes.concat(ociAliasTypes)

    // change all id's to emptyGuids for the new local parameters (we have to send out empty guids when saving)
    newOciParameters.forEach(item => {
      if (item.isNewParameter) {
        item.id = emptyGuid
        delete item.isNewParameter
      }
    })
    let fixedValueIndex = newOciParameters.findIndex(parameter => parameter.name === 'Fixwert' && parameter.type === 'alias')
    if (fixedValueIndex > 0) {
      newOciParameters[fixedValueIndex].name = '425BA011-AB56-49B8-9D31-5D7EA6FF5E19'
    }
    newCatalog.oCIParameters = newOciParameters
    newCatalog.catalog.externalCatalogUseOCIInterface = useOciInterface
    patchCatalog(newCatalog.catalog.id, newCatalog)
  }

  // we do this so we invoke a new setState once an input field value has been changed 
  // (to compare initial with current values and to enable or disable the save button)
  const handleAfterSaveCell = (oldValue, newValue, row, column) => {
    let copyOciTypes = row.type === 'text' ? [...ociTextTypes] : [...ociAliasTypes]
    row.type === 'text' ? setOciTextTypes(copyOciTypes) : setOciAliasTypes(copyOciTypes)
  }

  // *** IF BOTH TABLES ARE PART OF THE MAIN CREATE / EDIT CATALOG SCREEN, REMOVE THE SAVE BUTTONS, SINCE THERE SHOULD BE ONLY ONE FOR THE WHOLE PAGE? ***
  // *** WHOLE STATE LOGIC MIGHT NEED TO BE CHANGED THEN, CHECK IT OUT ***
  return (
    <div>
      <Form.Check
        type="checkbox"
        id="useOciInterface"
        label={t('catalogsAdministration:useOciInterface')}
        checked={useOciInterface}
        onChange={() => setUseOciInterface((curState) => !curState)}
      />

      {useOciInterface ?
        <div className="my-3">
          {/* First table - Free texts */}
          <div>
            <StyledHeaderWrapper>
              <h1 className="m-0">{t('catalogsAdministration:freeTexts')}</h1>
              <div>
                <Button className="mx-2" variant="success" onClick={() => addParameter('text')}>{t('catalogsAdministration:addParameter')}</Button>
              </div>
            </StyledHeaderWrapper>
            <OCITextTable
              handleAfterSaveCell={handleAfterSaveCell}
              tableIsLoading={loadingStates.getCatalog}
              setDeleteParameterRow={setDeleteParameterRow}
              ociTextTypes={ociTextTypes}
            />
          </div>

          {/* Second table - Alias mapping */}
          <div className="mt-5">
            <StyledHeaderWrapper>
              <h1 className="m-0">{t('catalogsAdministration:aliasMapping')}</h1>
              <div>
                <Button className="mx-2" variant="success" onClick={() => addParameter('alias')}>{t('catalogsAdministration:addParameter')}</Button>
              </div>
            </StyledHeaderWrapper>
            <OCIAliasTable
              handleAfterSaveCell={handleAfterSaveCell}
              ociAliasTypes={ociAliasTypes}
              aliasTypes={aliasTypes}
              tableIsLoading={loadingStates.getCatalog}
              setDeleteParameterRow={setDeleteParameterRow}
            />
          </div>
        </div>
        : ''
      }

      <ButtonWithLoadingState
        disabled={!useOciInterfaceHasChanged && !ociTextTypesHasChanged && !ociAliasTypesHasChanged}
        buttonText={t('saveButton')}
        isLoading={loadingStates.saveCatalog}
        onClick={() => handleSubmit('text')}
        className="my-3"
      />

      {deleteParameterRow && <ModalComponent2
        ModalTitle={t('catalogsAdministration:deleteParameterRow')}
        BodyPart1={t('catalogsAdministration:deleteBodyParameter')}
        button1Function={() => deleteParameter(deleteParameterRow)}
        button1Text={t('delete')}
        button1Loading={loadingStates.saveCatalog}
        button1Variant="danger"
        bodyStyle="alert alert-danger"
        functionCancel={() => { setDeleteParameterRow(''); setSuccessStates({}) }}
        error={errorStates?.saveCatalog?.value}
      />}
    </div>
  )
}

export default CatalogOciInterface