import React from 'react'
import { customFilter } from 'react-bootstrap-table2-filter'
import HeaderSearchBar from './HeaderSearchBar'

let displayNameFilter;
export let personalNrFilter;
let officeDisplayNameFilter;
let costCenterDisplayNameFilter;

export function clearFilter() {
  displayNameFilter(null)
  personalNrFilter(null)
  officeDisplayNameFilter(null)
  costCenterDisplayNameFilter(null)
}

export const columns = [
  {
    dataField: 'checkBox',
    text: '',
    headerClasses: 'checkBox-header-cell',
    classes: 'checkBox-cell'
  },
  {
    dataField: 'displayName',
    text: 'name',
    sort: true,
    filterKey: 'filter.displayName',
    filter: customFilter(),
    filterRenderer: (onFilter, column) => <HeaderSearchBar onFilter={onFilter} column={column} getFilter={(filter) => { displayNameFilter = filter }} />
  },
  {
    dataField: 'personalNr',
    text: 'employeesPage:personalNumber',
    sort: true,
    filterKey: 'filter.personalNumber',
    filter: customFilter(),
    filterRenderer: (onFilter, column) => <HeaderSearchBar onFilter={onFilter} column={column} getFilter={(filter) => { personalNrFilter = filter }} />
  }, {
    dataField: 'office.displayName',
    text: 'orgaUnit',
    sort: true,
    filterKey: 'filter.officeDisplayName',
    filter: customFilter(),
    filterRenderer: (onFilter, column) => <HeaderSearchBar onFilter={onFilter} column={column} getFilter={(filter) => { officeDisplayNameFilter = filter }} />
  }, {
    dataField: 'costCenter.displayName',
    text: 'costcenter',
    sort: true,
    filterKey: 'filter.costCenterDisplayName',
    filter: customFilter(),
    filterRenderer: (onFilter, column) => <HeaderSearchBar onFilter={onFilter} column={column} getFilter={(filter) => { costCenterDisplayNameFilter = filter }} />,
    headerClasses: 'costcenter-header-cell',
    classes: 'costcenter-cell'
  },
  {
    dataField: 'actions',
    text: '',
    headerClasses: 'actions-header-cell',
    classes: 'actions-cell'
  }
];
