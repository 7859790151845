import React from 'react'
import { Table, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { supplierOrderStatusToString } from './supplierOrderStatusToString'
import useStores from '../../../stores/useStore'
import MediaQuery from 'react-responsive'
import styled from 'styled-components'
import { colors } from '../../../styles/colors'
import { formatedDate } from '../../../utils/formatedDate'

const ButtonWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    white-space: nowrap;
    min-width: 200px;
`
const StyledRow = styled.div`
    display: flex;
    padding: 10px;
    flex-direction: column;
    gap: 1em;
    border-bottom: 1px solid ${colors.gray3}
`
const InfoWrapper = styled.div`
    margin-right: 10px;
    flex-grow: 1;
`

const StyledSpanLabel = styled.span`
    font-weight: bold;
    padding-right: 0.5rem;
`

const SupplierOrdersList = ({ incomingOrderId, supplierOrders }) => {
    const { t } = useTranslation()
    const { orderStore } = useStores()
    const openExpectedArrivalsModal = (id) => {
        orderStore.openExpectedArrivalsModal = id
    }
    return (
        <div className='mb-5'>
            <h3 className='mb-4'>{t('order:relatedSupplierOrders')}</h3>
            <hr />
            <MediaQuery maxWidth={992}>
                <div>
                    {supplierOrders.map((position, index) => (
                        <StyledRow key={`list-${index}`}>
                            <InfoWrapper>
                                <div>
                                    <StyledSpanLabel>{t('order:order')}:</StyledSpanLabel>
                                    {position.displayName}
                                </div>
                                <div>
                                    <StyledSpanLabel>{t('status')}:</StyledSpanLabel>
                                    {supplierOrderStatusToString(position.status)}</div>
                                <div>
                                    <StyledSpanLabel>{t('supplier')}:</StyledSpanLabel>
                                    {position.supplier ? position.supplier.displayName : null}
                                </div>
                                <div>
                                    <StyledSpanLabel>{t('productDetail:supplierNumber')}:</StyledSpanLabel>
                                    {position.externNumber ? position.externNumber : null}
                                </div>
                                <div>
                                    <StyledSpanLabel>{t('order:expectedDeliveryDate')}:</StyledSpanLabel>
                                    {formatedDate(position.requestedArrivalDate) ? formatedDate(position.requestedArrivalDate) : null}
                                </div>
                            </InfoWrapper>
                            <ButtonWrapper>
                                <Button className="w-100" onClick={() => openExpectedArrivalsModal(position.id)}>{t('order:goodReceipts')}</Button>
                                <Link className="mt-2 w-100 btn btn-primary" to={`/orders/${incomingOrderId}/supplierOrder/${position.id}`}>{t('order:goToSupplierOrder')}</Link>
                            </ButtonWrapper>
                        </StyledRow>
                    ))}
                </div>
            </MediaQuery>
            <MediaQuery minWidth={993}>
                <Table>
                    <tbody>
                        {supplierOrders.map(position => <tr key={position.id}>
                            <td>
                                <div className='fw-bold'>{t('order:order')}:</div>
                                {position.displayName}
                            </td>
                            <td>
                                <div className='fw-bold'>{t('status')}:</div>
                                {supplierOrderStatusToString(position.status)}
                            </td>
                            <td>
                                <div className='fw-bold'>{t('supplier')}:</div>
                                {position.supplier ? position.supplier.displayName : null}
                            </td>
                            <td>
                                <div className='fw-bold'>{t('productDetail:supplierNumber')}:</div>
                                {position.externNumber ? position.externNumber : null}
                            </td>
                            <td>
                                <div className='fw-bold'>{t('order:expectedDeliveryDate')}:</div>
                                {formatedDate(position.requestedArrivalDate) ? formatedDate(position.requestedArrivalDate) : null}
                            </td>

                            <td >
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button onClick={() => openExpectedArrivalsModal(position.id)}>{t('order:goodReceipts')}</Button>
                                    <Link className="ms-1 btn btn-primary" to={`/orders/${incomingOrderId}/supplierOrder/${position.id}`}>{t('order:goToSupplierOrder')}</Link>
                                </div>
                            </td>
                        </tr>)}
                    </tbody>
                </Table>
            </MediaQuery>

        </div>
    )
}

export default SupplierOrdersList
