import { useState } from "react";

export const getCookie = key =>
    document.cookie.split("; ").reduce((total, currentCookie) => {
        const item = currentCookie.split("=");
        const storedKey = item[0];
        const storedValue = item[1];
        return key === storedKey
            ? decodeURIComponent(storedValue)
            : total;
    }, '');

export const setCookie = (key, value, numberOfDays) => {
    const now = new Date();
    // set the time to be now + numberOfDays
    now.setTime(now.getTime() + (numberOfDays * 60 * 60 * 24 * 1000));
    document.cookie = `${key}=${value};     expires=${now.toUTCString()}; path=/`;
};

export const useCookie = (key, defaultValue) => {
    const findCookie = () => getCookie(key) || defaultValue;
    const [cookie, setNewCookie] = useState(findCookie());
    const updateCookie = (value, numberOfDays) => {
        setNewCookie(value);
        setCookie(key, value, numberOfDays);
    };
    return [cookie, updateCookie];
};