import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import UniversalTableWrapperTest from '../../Table/UniversalTableWrapperTest'
import BootstrapTable from 'react-bootstrap-table-next'
import { useTableState } from '../../Table/TableProvider'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'
import { DeleteIcon } from '../../Icons/DeleteIcon'

const aliasTypesNameOptions = [
  {
    value: 'Fixwert',
    label: 'Fixwert'
  },
  {
    value: 'NEW_ITEM-UNIT',
    label: 'NEW_ITEM-UNIT'
  },
  {
    value: 'NEW_ITEM-MATGROUP',
    label: 'NEW_ITEM-MATGROUP'
  },
  {
    value: 'NEW_ITEM-EXT_CATEGORY_ID',
    label: 'NEW_ITEM-EXT_CATEGORY_ID'
  },
  {
    value: 'NEW_ITEM-EXT_CATEGORY',
    label: 'NEW_ITEM-EXT_CATEGORY'
  },
  {
    value: 'NEW_ITEM-CUST_FIELD1',
    label: 'NEW_ITEM-CUST_FIELD1'
  },
  {
    value: 'NEW_ITEM-CUST_FIELD2',
    label: 'NEW_ITEM-CUST_FIELD2'
  },
  {
    value: 'NEW_ITEM-CUST_FIELD3',
    label: 'NEW_ITEM-CUST_FIELD3'
  },
  {
    value: 'NEW_ITEM-CUST_FIELD4',
    label: 'NEW_ITEM-CUST_FIELD4'
  },
  {
    value: 'NEW_ITEM-CUST_FIELD5',
    label: 'NEW_ITEM-CUST_FIELD5'
  },
]

const OCIAliasTable = ({ handleAfterSaveCell, tableIsLoading, ociAliasTypes, aliasTypes, setDeleteParameterRow }) => {
  const { tableRef } = useTableState()
  const [aliasTypesValueOptions, setAliasTypesValueOptions] = useState([])
  const columnsAliases = [
    {
      dataField: 'name',
      text: t('name'),
      sort: true,
      editor: {
        type: Type.SELECT,
        options: aliasTypesNameOptions
      },
      classes: 'cursor-pointer'

    },
    {
      dataField: 'value',
      text: t('value'),
      sort: true,
      editor: {
        type: Type.SELECT,
        options: aliasTypesValueOptions
      },
      classes: 'cursor-pointer'

    },
    {
      dataField: 'constantValue',
      text: 'Fixwert',
      sort: true,
      editable: (cell, row, rowIndex, colIndex) => {
        return row.name === 'Fixwert';
      },
      classes: (cell, row) => (row.name === 'Fixwert' ? 'cursor-pointer' : 'cursor-no-drop')
    },
    {
      isDummyField: true,
      dataField: 'isDummyField',
      text: '',
      sort: false,
      headerClasses: 'checkBox-header-cell',
      classes: 'checkBox-cell',
      editable: (cell, row, rowIndex, colIndex) => {
        return false;
      },
      formatter: (cell, row, rowIndex) => (
        <span role="button" onClick={() => setDeleteParameterRow(row)}>
          <DeleteIcon title={t('delete')} />
        </span>
      )
    }
  ]
  useEffect(() => {
    if (aliasTypes) {
      let aliasTypesValueOptions = aliasTypes.map(alias => ({
        value: alias.foreignKey,
        label: alias.foreignKey  // displayName instead? check if value name in Get catalog call is fixed
      }))
      setAliasTypesValueOptions(aliasTypesValueOptions)
    }
  }, [aliasTypes])
  return (
    <UniversalTableWrapperTest tableIsLoading={tableIsLoading}>
      <BootstrapTable
        ref={tableRef}
        noDataIndication={() => <div className="no-data-placeholder">{!tableIsLoading && t('catalogsAdministration:noAliasTypesAvailable')}</div>}
        loading={tableIsLoading}
        bootstrap4
        keyField="id"
        hover
        data={ociAliasTypes}
        columns={columnsAliases}
        cellEdit={cellEditFactory({ mode: "click", blurToSave: true, afterSaveCell: handleAfterSaveCell })}
        bordered={false}
      />
    </UniversalTableWrapperTest>
  )
}

export default OCIAliasTable