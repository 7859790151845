import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources } from './resources'

i18n
  .use(initReactI18next)
  .init({
    // debug: true,
    resources: resources,
    lng: 'de',
    fallbackLng: 'de',

    interpolation: {
      escapeValue: false
    }
  })

export default i18n
