import React from 'react'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import { Form } from 'react-bootstrap'
import { StyledFormHeadline, StyledValidationMessage } from './RegistrationStyles'

const PersonalData = inject('registrationStore', 'uiStore')(observer((props) => {
  const { registrationStore, registrationConfig } = props
  const employee = registrationStore.registrationData.employee

  return (
    <>
      <StyledFormHeadline>{registrationConfig.personalData.headline}</StyledFormHeadline>
      <Form.Group className="mb-3" controlId="formFirstName">
        <Form.Label>{t('formularFields:firstname')} *</Form.Label>
        <Form.Control
          value={employee.firstName}
          onChange={registrationStore.handleChange2}
          name="employee.firstName"
          placeholder={t('formularFields:firstname')}
          isInvalid={registrationStore.formErrors.employeefirstName}
        />
        <StyledValidationMessage type="invalid">
          {registrationStore.formErrors.employeefirstName}
        </StyledValidationMessage>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formLastName">
        <Form.Label>{t('formularFields:lastname')} *</Form.Label>
        <Form.Control
          value={employee.lastName}
          onChange={registrationStore.handleChange2}
          name="employee.lastName"
          placeholder={t('formularFields:lastname')}
          isInvalid={registrationStore.formErrors.employeelastName}
        />
        <StyledValidationMessage type="invalid">
          {registrationStore.formErrors.employeelastName}
        </StyledValidationMessage>
      </Form.Group>
      {registrationConfig.personalData.nameGenerator
        ? <Form.Group className="mb-3" controlId="formNameGenerator">
          <Form.Label>{t('formularFields:nameGenerator')}</Form.Label>
          <Form.Control
            autoComplete="nope"
            maxLength="20"
            name="employee.initials"
            value={employee.initials || ""}
            onChange={registrationStore.handleChange2}
            placeholder={t('formularFields:nameTag')}
            disabled
            isInvalid={registrationStore.formErrors.employeeinitials}
          />
          <StyledValidationMessage type="invalid">
            {registrationStore.formErrors.employeeinitials}
          </StyledValidationMessage>
        </Form.Group>
        : null}
    </>
  )
}))

export default PersonalData