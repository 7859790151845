import React from 'react'
import { formatedDate } from '../../utils/formatedDate'
import styled from 'styled-components'
import { StyledCard } from '../Cards/StyledCard'
import { useTranslation } from 'react-i18next'

const StyledMetaInfoBlock = styled(StyledCard)`
  font-size: 14px;
`

const StyledInfoItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledInfoItem = styled.div`
  padding: 15px 25px;
  width: 200px;
`

const StyledMetaLabel = styled.div`
  font-weight: bold;
`

export default function MetaInfoBlock(props) {
  const { order } = props
  const { t } = useTranslation()
  const mandate = process.env.REACT_APP_MANDATORSET

  return (
    <StyledMetaInfoBlock>
      <StyledInfoItemsContainer>
        <StyledInfoItem>
          <StyledMetaLabel>{t('order:dateTitle')}:</StyledMetaLabel>
          <div>{formatedDate(order.createdAt)}</div>
        </StyledInfoItem>
        <StyledInfoItem>
          <StyledMetaLabel>{t('order:targetBusinessPartnerTitle')}:</StyledMetaLabel>
          <div>{order.targetBusinessPartner != null ? order.targetBusinessPartner.displayName : order.targetStorehouse != null ? order.targetStorehouse.displayName : order.targetCostCenter != null ? order.targetCostCenter.displayName : ""}</div>
        </StyledInfoItem>
        <StyledInfoItem>
          <StyledMetaLabel>{t('order:createdByTitle')}:</StyledMetaLabel>
          <div>{order.createdByDisplayName}</div>
        </StyledInfoItem>
        {order.targetCostCenter ?
          <StyledInfoItem>
            <StyledMetaLabel>{t('order:targetCostCenterTitle')}:</StyledMetaLabel>
            <div>{order.targetCostCenter && order.targetCostCenter.displayName}</div>
          </StyledInfoItem>
          : ''}
        {order.transmittedSRMNumber ?
          <StyledInfoItem>
            <StyledMetaLabel>{mandate === 'sixt' ? t('order:coupaOrderNumber') : t('order:srmOrderNumber')}</StyledMetaLabel>
            <div>{order.transmittedSRMNumber}</div>
          </StyledInfoItem>
          : ''}
        {order.foreignOrderNr ?
          <StyledInfoItem>
            <StyledMetaLabel>{t('order:sapOrderNumber')}</StyledMetaLabel>
            <div>{order.foreignOrderNr}</div>
          </StyledInfoItem>
          : ''}
      </StyledInfoItemsContainer>
    </StyledMetaInfoBlock>
  )
}
