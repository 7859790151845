import React, { useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { cloneDeep } from 'lodash';
import { t } from 'i18next';

const StyledMessage = styled.div`
    white-space:${props => props.mustShowMore ? 'break-spaces' : 'nowrap'};
    overflow: hidden;
    text-overflow: ellipsis;
    color: grey;
}
`
const StyleListItem = styled.div`
position:relative;
  cursor: pointer;
  padding: 10px 25px;
  border-bottom: 1px solid #ddd;
    &:hover {
      background-color: #ECECEC ;
      .center{
        visibility: visible;
      }
    }
  ${props => props.selected && `background-color: #ECECEC ;`}
  
  .center{
    visibility: hidden;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  `

const StyledFlexWrapper = styled.div`
  display:flex;
  justify-content:space-between;
  align-items: center;
  width: 100%;
  .leftSide{
    display:flex;
    flex-basis:80%;
  }
  .rightSide{
    display: flex;
    justify-content: flex-end;
    flex-basis:20%;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const MessageSelect = ({ messagesLoading, messages, selected, setSelected }) => {
  const [showMore, setShowMore] = useState([])

  const handleSelect = (messageId) => {
    setSelected(prev => {
      let foundIndex = prev.findIndex(s => s === messageId)
      if (foundIndex >= 0) {
        prev.splice(foundIndex, 1)
      }
      else {
        prev.push(messageId)
      }
      return cloneDeep(prev)
    })
  }
  const handleShowMore = (e, messageId) => {
    e.stopPropagation();
    setShowMore(prev => {
      let foundIndex = prev.findIndex(s => s === messageId)
      if (foundIndex >= 0) {
        prev.splice(foundIndex, 1)
      }
      else {
        prev.push(messageId)
      }
      return cloneDeep(prev)
    })
  }
  if (messagesLoading) {
    return <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
  }
  return (
    <div>{messages.map((m, i) => {
      let isSelected = selected.includes(m.id)
      let mustShowMore = showMore.includes(m.id)
      let isFirstMessage = i === 0
      return (
        <StyleListItem
          key={m.id}
          onClick={() => isFirstMessage ? null : handleSelect(m.id)}
          selected={isSelected}
        >
          <StyledFlexWrapper>
            <div className="leftSide">
              <div style={{ display: "grid" }}>
                <b>{m.createdByDisplayName}</b>
                <StyledMessage mustShowMore={mustShowMore}>
                  {m.text}
                </StyledMessage >
              </div>
            </div>
            <div className="rightSide">
              <Form.Check
                type="checkbox"
                id={`message-${m.id}`}
                checked={isSelected || isFirstMessage}
                onChange={() => { }}
                disabled={isFirstMessage}
              />
            </div>
          </StyledFlexWrapper>
          <div className="center">
            <Button variant="light" onClick={(e) => handleShowMore(e, m.id)}>{mustShowMore ? t('dashboard:showLess') : t('dashboard:showMore')}</Button>
          </div>
        </StyleListItem>)
    }
    )}</div>
  )
}

export default MessageSelect