import styled from 'styled-components'

export const Arrow2BigIcon = styled.span`
  display: inline-block;
  width: 25px;
  height: 25px;
  cursor: pointer;
  &::before{
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${props => encodeURIComponent(props.color? props.color: '#00035c')}' viewBox='0 0 28 28'%3E%3Cg%3E%3Cg%3E%3Cpath d='M18.2 22.2c-.4.4-1 .4-1.4 0L10 15.4c-.1 0-.2-.1-.3-.2-.2-.2-.3-.5-.3-.7 0-.3.1-.5.3-.7.1-.1.2-.1.3-.2l6.8-6.8c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-6.3 6.3 6.3 6.3c.4.4.4 1.1 0 1.4z' fill-rule='evenodd' clip-rule='evenodd' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center center;
    ${props => {
      switch(props.variant){
        case 'up':
          return 'transform: rotate(90deg);'
        case 'down':
          return 'transform: rotate(-90deg);'
        case 'left':
          return 'transform: rotate(0deg);'
        case 'right':
          return 'transform: rotate(180deg);'
        default:
      }
    }
  }
`
