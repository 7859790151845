import React from 'react'

export const Mobile = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="mobile" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <g>
        <path className="st0" d="M71.9,14.5H41.4c-3.7,0-6.8,3-6.8,6.8v70.7c0,3.7,3,6.8,6.8,6.8h30.5c3.7,0,6.8-3,6.8-6.8V21.3
          C78.7,17.6,75.7,14.5,71.9,14.5z M76.2,92.1c0,2.4-1.9,4.2-4.2,4.2H41.4c-2.3,0-4.2-1.9-4.2-4.2V80.7h39V92.1z M76.2,78.2h-39V29
          h39V78.2z M76.4,26.7H37v-5.4c0-2.5,2-4.5,4.5-4.5h30.5c2.5,0,4.5,2,4.5,4.5V26.7z"/>
        <path className="st0" d="M56.7,85.4c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1
          C59.8,86.8,58.4,85.4,56.7,85.4z"/>
        <path className="st0"
              d="M59.5,20.7h-5.6c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h5.6c0.6,0,1-0.5,1-1C60.5,21.2,60.1,20.7,59.5,20.7z"/>
      </g>
    </svg>
  )
}
