import React from 'react'

export const ExcelIcon = ({ title, height, width, ...rest }) => {
  const useHeight = height ? height : '29px'
  const useWidth = width ? width : '29px'
  return (

    <svg enableBackground="new 0 0 24 24" height={useHeight} width={useWidth} id="Layer_1" version="1.1" viewBox="0 0 24 24" {...rest}>
      <g>
        <rect fill="#FFFFFF" height="17" width="11.5" x="12" y="3.5" />
        <path d="M23.5,21h-10c-0.2763672,0-0.5-0.2236328-0.5-0.5s0.2236328-0.5,0.5-0.5H23V4h-9.5   C13.2236328,4,13,3.7763672,13,3.5S13.2236328,3,13.5,3h10C23.7763672,3,24,3.2236328,24,3.5v17   C24,20.7763672,23.7763672,21,23.5,21z" fill="#177848" /><polygon fill="#177848" points="14,0 0,2.6086957 0,21.391304 14,24  " /><polygon fill="#FFFFFF" opacity="0.2" points="0,2.6087036 0,2.8587036 14,0.25 14,0  " />
        <rect fill="#177848" height="2" width="4" x="13" y="5" /><rect fill="#177848" height="2" width="4" x="18" y="5" />
        <rect fill="#177848" height="2" width="4" x="13" y="8" /><rect fill="#177848" height="2" width="4" x="18" y="8" />
        <rect fill="#177848" height="2" width="4" x="13" y="11" /><rect fill="#177848" height="2" width="4" x="18" y="11" />
        <rect fill="#177848" height="2" width="4" x="13" y="14" /><rect fill="#177848" height="2" width="4" x="18" y="14" />
        <rect fill="#177848" height="2" width="4" x="13" y="17" /><rect fill="#177848" height="2" width="4" x="18" y="17" />
        <polygon opacity="0.1" points="0,21.3912964 14,24 14,23.75 0,21.1412964  " />
        <linearGradient gradientUnits="userSpaceOnUse" id="SVGID_1_" x1="9.5" x2="23.3536377" y1="7.5" y2="21.3536377">
          <stop offset="0" style={{ stopColor: '#000000', stopOpacity: '0.1' }} />
          <stop offset="1" style={{ stopColor: '#000000', stopOpacity: '0' }} />
        </linearGradient><path d="M23.5,21c0.2763672,0,0.5-0.2236328,0.5-0.5V13L14,3v18H23.5z" fill="url(#SVGID_1_)" />
        <polygon fill="#FFFFFF" points="7.357666,12.5 9.6552734,8.3642578 9.6262817,8.3481445 7.8796387,8.4729004 6.5,10.9562378    5.225647,8.6624756 3.5758667,8.7802734 5.642334,12.5 3.5758667,16.2197266 5.225647,16.3375244 6.5,14.0437622    7.8796387,16.5270996 9.6262817,16.6518555 9.6552734,16.6357422  " />
        <linearGradient gradientTransform="matrix(60.9756088 0 0 60.9756088 20560.1210938 -26748.4140625)" gradientUnits="userSpaceOnUse" id="SVGID_2_" x1="-337.1860046" x2="-336.9563904" y1="438.8707886" y2="438.8707886">
          <stop offset="0" style={{ stopColor: '#FFFFFF' }} /><stop offset="1" style={{ stopColor: '#000000' }} />
        </linearGradient><path d="M14,0L0,2.6086957v18.782608L14,24V0z" fill="url(#SVGID_2_)" opacity="0.05" />
        <linearGradient gradientUnits="userSpaceOnUse" id="SVGID_3_" x1="-1.5634501" x2="25.0453987" y1="5.9615331" y2="18.369442">
          <stop offset="0" style={{ stopColor: '#FFFFFF', stopOpacity: '0.2' }} /><stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0' }} />
        </linearGradient><path d="M23.5,3H14V0L0,2.6087036v18.7825928L14,24v-3h9.5c0.2763672,0,0.5-0.2236328,0.5-0.5v-17   C24,3.2236328,23.7763672,3,23.5,3z" fill="url(#SVGID_3_)" />
      </g><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g />
    </svg>

  )
}
