import React, { useEffect } from 'react'
import useStores from '../../stores/useStore'
import { LinkContainer } from 'react-router-bootstrap'
import StyledNav from './StyledNav'
import styled from 'styled-components'
import { Nav, NavDropdown } from "react-bootstrap"
import MediaQuery from 'react-responsive'
import { useObserver } from 'mobx-react'
import { useTranslation } from 'react-i18next'

const StyledNavWrapper = styled.div`
  height: 100%;
  margin-left: 0;
  font-weight: bold;
  font-size: 17px;
  @media screen and (max-width: 767px) {
    position: absolute;
    background: #f6f6f6;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    height: 50px;
    top: 50px;
    left: 0;
    width: 100%;
  }
  .nav{
    flex-wrap:nowrap;
  }
`

const NavigationRight = () => {
    const { uiStore, cwsStore } = useStores()
    const { t } = useTranslation()

    useEffect(() => {
        cwsStore.loadMenuItems()
    }, [cwsStore])

    const NavigationR = useObserver(() => (
        <Nav variant="pills">
            {cwsStore.menuItems && cwsStore.menuItems.length ?
                <NavDropdown title={cwsStore.menuTitle ? cwsStore.menuTitle : "Services"} active={uiStore.activeNav === 'advice-and-services'}>
                    {
                        cwsStore.menuItems.map(item => <LinkContainer to={`/custom-html/${item.id}`} key={item.id}>
                            <NavDropdown.Item>{item.displayName}</NavDropdown.Item>
                        </LinkContainer>)
                    }
                    {
                        uiStore.articleCatalogAvailable ? <LinkContainer to="/articleCatalog" >
                            <NavDropdown.Item>{t('header:articleCatalog')}</NavDropdown.Item>
                        </LinkContainer> : null
                    }
                </NavDropdown>
                : uiStore.articleCatalogAvailable ?
                    <Nav.Item>
                        <LinkContainer to="/articleCatalog">
                            <Nav.Link>{t('header:articleCatalog')}</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    : null}
        </Nav >
    ))
    return <>
        <MediaQuery minWidth={993}>
            <StyledNavWrapper>
                <StyledNav>
                    {NavigationR}
                </StyledNav>
            </StyledNavWrapper>
        </MediaQuery>
        <MediaQuery maxWidth={992}>
            {NavigationR}
        </MediaQuery>
    </>

}

export default NavigationRight