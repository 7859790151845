import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button } from 'react-bootstrap'
import useAppointmentTypesState from '../../../stores/Configuration/useAppointmentTypesState'
import TimeLength from './TimeLength'
import TimesList from './TimeGenerator/TimesList'
import { calculateTime } from '../../../utils/hoursAndMinutes'
import AddTimesModal from './TimeGenerator/AddTimesModal'
import AddSeriesModal from './TimeGenerator/AddSeriesModal'
import OverlayRight from '../../Page/logix2020/OverlayRight'
import OverlayContainer from '../../Page/logix2020/OverlayContainer'
import LanguageForm from '../../Forms/LanguageForm'
import styled from 'styled-components'
import { cloneDeep } from 'lodash'
const StyledFlexWrapper = styled.div`
display: flex;
flex-direction: row;

@media (max-width: 767px) {
  flex-direction: column;
}
`
const StyledDivLeft = styled.div`
 width: 400px;
  @media screen and (max-width: 767px) {
      width:100%;
  }
`
const StyledDivRight = styled.div`
margin-left:40px;
flex-grow:1;
@media (max-width: 767px) {
    margin: 0px;
  }
`

const StyledButton = styled(Button)`
margin-top:1.5rem;
margin-bottom:1.5rem;
  @media screen and (max-width: 767px) {
      width:100%;
`
const TimesGeneratorForm = () => {

    const { t } = useTranslation()
    const [languageModalVisible, setLanguageModalVisible] = useState(false)
    const [openAddTimesModal, setOpenAddTimesModal] = useState(false)
    const [openAddSeriesModal, setOpenAddSeriesModal] = useState(false)
    const { handleSubmit, selectedAppointmentType, setSelectedAppointmentType, hours, updateTimeSlots, initTimesGeneratorForm, error, success } = useAppointmentTypesState()

    //update state when switching between general and assignedCabins
    useEffect(() => {
        initTimesGeneratorForm()
    }, [initTimesGeneratorForm])

    const createAppointmentSeries = ({ timeFrom, timeTo, breakTime }) => {
        var durationInt = parseInt(durationInMinutes)
        if (durationInt <= 0) { return null }
        durationInt += parseInt(breakTime)

        const newTimes = cloneDeep(hours)

        var startHour = parseInt(timeFrom.substring(0, 2))
        var startMinute = parseInt(timeFrom.substring(3, 5))
        const endHour = parseInt(timeTo.substring(0, 2))
        const endMinute = parseInt(timeTo.substring(3, 5))

        var tempTimes = []

        while (((startHour * 60) + startMinute) < ((endHour * 60) + endMinute)) {
            tempTimes = calculateTime("plus", startHour, startMinute, durationInt)
            if (tempTimes[0] < startHour) break
            newTimes[startHour].push({ hour: startHour, minute: startMinute })
            startHour = tempTimes[0]
            startMinute = tempTimes[1]
        }
        updateTimeSlots(newTimes)
    }

    const updateNames = (translations) => {
        setSelectedAppointmentType(prev => ({
            ...prev, name: {
                values: translations
            }
        }))
    }

    const closeLanguageModal = () => {
        setLanguageModalVisible(false)
    }

    const durationInMinutes = selectedAppointmentType.durationInMinutes

    return (
        <>
            <StyledFlexWrapper>
                <StyledDivLeft>
                    <TimeLength />
                    <Button className="mb-3 w-100" onClick={() => setOpenAddTimesModal(true)}>{t('appointment:addSingleAppointment')}</Button>
                    <Button className="mb-3 w-100" onClick={() => setOpenAddSeriesModal(true)}>{t('appointment:addAppointmentSeries')}</Button>
                    <StyledButton className="btn-extra-padding" onClick={() => handleSubmit("duration")}>{t('saveButton')}</StyledButton>

                </StyledDivLeft>
                <StyledDivRight>
                    {(hours && durationInMinutes)
                        ? <TimesList durationInMinutes={durationInMinutes} hours={hours} updateTimeSlots={updateTimeSlots} />
                        : null
                    }
                </StyledDivRight>
            </StyledFlexWrapper>
            <OverlayContainer>
                <OverlayRight visible={languageModalVisible} onClose={closeLanguageModal}>
                    {
                        <LanguageForm
                            languageTitle={t('name')}
                            handleCloseOverlay={closeLanguageModal}
                            languageArray={(selectedAppointmentType.name && selectedAppointmentType.name.values) || []}
                            onChange={updateNames} />
                    }
                </OverlayRight>
            </OverlayContainer>
            {error &&
                <Alert variant="danger" >
                    {error}
                </Alert>
            }
            {success &&
                <Alert variant="success" className="my-2">
                    {success}
                </Alert>
            }
            {openAddTimesModal && <AddTimesModal
                duration={parseInt(durationInMinutes)}
                hoursArray={hours}
                updateTimeSlots={updateTimeSlots}
                handleClose={() => setOpenAddTimesModal(false)} />}
            {openAddSeriesModal && <AddSeriesModal
                createSeries={(obj) => createAppointmentSeries(obj)}
                handleClose={() => setOpenAddSeriesModal(false)} />}
        </>
    )
}

export default TimesGeneratorForm

