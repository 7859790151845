import React, { useState, useEffect } from 'react'
import { Subheader } from '../components/Page/logix2020/Subheader'
import SubheaderForm from '../components/Page/logix2020/SubheaderForm'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Sidebar from '../components/Appointment/Sidebar/Sidebar'
import AppointmentContentArea from '../components/Appointment/AppointmentContentArea'
import { Alert, Spinner } from 'react-bootstrap'
import useStores from '../stores/useStore'
import AppointmentProvider, { useAppointmentState } from '../stores/AppointmentProvider'
import { useObserver } from 'mobx-react'
import { colors } from '../styles/colors'
import styled from 'styled-components'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import { TestSidebarRight } from '../components/Page/logix2020/TestSidebarRight'
import BigFooter from '../components/Footer/BigFooter'
import { FlexWrapper } from '../components/Page/logix2020/FlexWrapper'


const StyledContentArea = styled(ContentArea)`
  .flex-container {
    display: flex;
  }
  .flexBox {
    width:100%;
    padding-left: 55.47px;
    @media screen and (max-width: 1079px) {
      padding-left:0;
     }
  }
  hr {
    background-color: ${colors.borderColor};
    margin: 35px 0;
  }
`

const AppointmentPageContent = () => {
    const { t } = useTranslation()
    const { employeeId, appointmentId } = useParams()

    const {
        getUserData,
        getOldAppointmentData,
        getAppointmentTypes
    } = useAppointmentState()

    useEffect(() => {
        getAppointmentTypes()
        if (appointmentId) {
            getOldAppointmentData(employeeId, appointmentId)
        }
        else {
            getUserData(employeeId)
        }
    }, [appointmentId, employeeId, getAppointmentTypes, getOldAppointmentData, getUserData])

    return (
        <>
            <Subheader className="subHeader-v1">
                <div className="d-flex justify-content-lg-start">
                    <SubheaderForm
                        title={appointmentId ? `${t('appointment:editAppointment')}` : `${t('appointment:makeAppointment')}`}
                        goBackLabel={t('backButton')}
                    />
                </div>
            </Subheader>
            <FlexWrapper>
                <StyledContentArea>
                    <AppointmentContentArea />
                </StyledContentArea>
                <TestSidebarRight>
                    <Sidebar />
                </TestSidebarRight>
            </FlexWrapper>
            <BigFooter />
        </>
    )
}

const AppointmentPage = () => {
    const { uiStore } = useStores()
    const { appointmentId } = useParams()
    const { t } = useTranslation()
    const [error, setError] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setError(true);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return useObserver(() => (
        <>
            {!uiStore.fittingReservation ? (
                <div>
                    <Subheader>
                        <SubheaderForm
                            title={appointmentId ? 'Termin bearbeiten' : 'Termin vereinbaren'}
                            goBackLabel={t('backButton')}
                        />
                    </Subheader>
                    {error
                        ? <Alert variant="danger">{t('accessDenied')}</Alert>
                        : <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
                    }
                </div>
            ) : (
                <AppointmentProvider>
                    <AppointmentPageContent />
                </AppointmentProvider>
            )}
        </>
    ))
}

export default AppointmentPage
