import { action, observable, reaction, makeObservable } from 'mobx';
import { getErrorMessageFromApiResponse, setPointerErrorsFromApiResponse } from '../utils/api/apiUtils'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import axios from 'axios';
import { emptyGuid } from '../utils/emptyGuid';
const CancelToken = axios.CancelToken;
var source;
class InventoryStore {
    constructor(appStore) {
        makeObservable(this, {
            contingents: observable,
            loadContingentIsLoading: observable,
            loadContingentError: observable,
            searchText: observable,
            filteredContingents: observable,
            employeeId: observable,
            targetEmployeeId: observable,
            articlesExpanded: observable,
            showAllArticles: observable,
            articleDetails: observable,
            articleDetailsIsLoading: observable,
            articleHistories: observable,
            articleHistoriesIsLoading: observable,
            articleInstances: observable,
            articleInstancesIsLoading: observable,
            selectedArticles: observable,
            scrollRef: observable,
            selectedReturnAmounts: observable,
            actionType: observable,
            articleVariants: observable,
            submitSuccess: observable,
            submitError: observable,
            submitErrorPointers: observable,
            loadingSave: observable,
            loadInstancesLoading: observable,
            instances: observable,
            showContactDataModal: observable,
            catalogArticles: observable,
            editCatalogArticles: observable,
            catalogArticlesTotalCount: observable,
            catalogArticlesOffset: observable,
            catalogArticlesLoading: observable,
            getCatalogArticles: action,
            getCurrentArticle: action,
            loadEmployeeContingent: action,
            loadArticleVariants: action,
            availableVariantOptions: action,
            addCorrectionVariant: action,
            deleteCorrectionVariant: action,
            onMoreOptionsHandler: action,
            loadContingentArticleDetails: action,
            loadContingentArticleHistories: action,
            loadContingentArticleInstances: action,
            saveArticleComment: action,
            expandArticles: action,
            setScrollRef: action,
            missingImagesList: observable,
            retourResponse: observable,
            openDeactivateModal: observable,
            setOpenDeactivateModal: action,
            setReturnResponse: action,
            onSubmit: action,
            createParams: action,
            instanceAlreadyExists: action,
            getArticlesByArticleId: action,
            loadAllInstances: action,
            returnReasons: observable
        });

        this.app = appStore
        reaction(
            () => this.searchText,
            () => {
                this.filterArticles()
            }
        )
        reaction(
            () => this.contingents,
            () => {
                this.filterArticles()
            }
        )
    }

    contingents = null;
    loadContingentIsLoading = false;
    loadContingentError = null;
    searchText = '';
    filteredContingents = [];
    employeeId = '';
    targetEmployeeId = '';
    articlesExpanded = [];
    showAllArticles = false;
    articleDetails = null;
    articleDetailsIsLoading = false;
    articleHistories = null;
    articleHistoriesIsLoading = false;
    articleInstances = [];
    articleInstancesIsLoading = false;
    selectedArticles = null;
    scrollRef = null;
    selectedReturnAmounts = [];
    actionType = '';
    articleVariants = null;
    submitSuccess = '';
    submitError = '';
    submitErrorPointers = {};
    loadingSave = false;
    loadInstancesLoading = false;
    instances = [];
    showContactDataModal = false;

    catalogArticles = [];
    editCatalogArticles = [];
    catalogArticlesTotalCount = 0;
    catalogArticlesOffset = 0;
    catalogArticlesLoading = false;

    async getCatalogArticles(
        newParams = { newOffset: this.catalogArticlesOffset, newSearch: this.searchText }
    ) {
        if (source) {
            //cancel previous calls that are still pending
            source.cancel("canceled")
        }
        //create new token source
        source = CancelToken.source();
        this.catalogArticlesLoading = true

        let append = !!newParams.newOffset

        const params = this.getParamsForArticlesRequest(newParams)
        try {
            let response = await apiClient.getJson(APIEndpoints.articles, params, true, true, true, { cancelToken: source.token })
            let data = response.data.map(article => ({ ...article, positions: [] }))
            this.catalogArticles = append ? [...this.catalogArticles, ...data] : data
            this.catalogArticlesTotalCount = response.headers['x-total-count'] ? parseInt(response.headers['x-total-count']) : 0
            this.catalogArticlesOffset = newParams.newOffset + 10
        }
        catch (e) { }
        finally {
            this.catalogArticlesLoading = false
        }
    }
    getParamsForArticlesRequest(newParams) {
        const { newOffset, newSearch } = newParams
        return ({
            'filter.searchText': newSearch ? newSearch : null,
            'page.offset': newOffset,
            'page.limit': 10,
            'filter.targetBusinesspartnerId': this.targetEmployeeId ? this.targetEmployeeId : this.employeeId
        })
    }

    // gets the current article from the contingents object (cloned) that we want to edit
    getCurrentArticle(article) {
        const contingentId = article.contingentAvailability.id
        const categoryId = article.categoryAvailability.id
        const articleId = article.article.id
        let contingent = this.contingents.find(contingent => contingent.id === contingentId)

        if (contingentId === emptyGuid) {
            //is catalog article
            let foundArticle = this.editCatalogArticles.find(article => article.article.id === articleId)
            if (foundArticle) {
                return foundArticle
            }
            else {
                this.editCatalogArticles.push(article)
                return this.getCurrentArticle(article)
            }
        }
        else if (contingent) {
            let category = contingent.categories.find(category => category.id === categoryId)
            if (category) {
                return category.articles.find(article => article.article.id === articleId)
            }
        }

        return null
    }

    async loadEmployeeContingent(force) {
        if (!force) {
            if (this.contingents) {
                return this.contingents
            }
        }
        if (this.loadContingentIsLoading) {
            await this.app.uiStore.waitUntilLoaded(100, 'app.inventoryStore.loadContingentIsLoading')
            return this.contingents
        } else {
            this.contingents = null
            this.editCatalogArticles = []
            this.loadContingentError = null
            this.loadContingentIsLoading = true
            try {
                this.contingents = await apiClient.getJson(APIEndpoints.businessPartner(this.employeeId).contingent)
            } catch (e) {
                this.loadContingentError = getErrorMessageFromApiResponse(e)
            }
            this.loadContingentIsLoading = false
            return this.contingents
        }
    }

    async loadArticleVariants(article) {
        if (article.variants) {
            return
        }
        const variants = await apiClient.getJson(APIEndpoints.articleVariants(article.article.id))

        let currentArticle = this.getCurrentArticle(article)
        currentArticle.variants = variants;

    }

    availableVariantOptions(articleVariants, articlePositions) {
        let availableOptions = []
        //singleItem with no currentQuantity
        if (articleVariants && !articleVariants.length && !articlePositions.length) {
            availableOptions.push({
                id: null,
                displayName: null
            })
            return availableOptions
        }
        if (articleVariants) {
            articleVariants.forEach(variant => {
                let foundVariant = articlePositions.find(position => position.articleVariant.id === variant.id)
                if (!foundVariant) {
                    availableOptions.push({
                        id: variant.id,
                        displayName: variant.displayName
                    })
                }
            })
        }
        return availableOptions

    }

    //position.negativeAmount = false
    //position.rows = {instance:null,comment:''}

    rowsTemplate = { instance: null, comment: '' }
    updateRows(article, positionVariant, newAmount, negativeAmount = false) {
        //find article that has to be modified
        let updatedArticle = this.getCurrentArticle(article)

        const foundPosition = updatedArticle.positions.find(position => position.articleVariant && position.articleVariant.id === positionVariant.id) || updatedArticle.positions[0]

        if (!foundPosition.rows) {
            foundPosition.rows = []
        }
        let oldAmount = foundPosition.rows.length

        if (newAmount < oldAmount) {
            foundPosition.rows.splice(newAmount)
        } else if (newAmount > oldAmount) {
            let newRows = Array(newAmount - oldAmount).fill(this.rowsTemplate)
            foundPosition.rows.push(...newRows)
        }
        foundPosition.negativeAmount = negativeAmount
    }

    deleteRow(article, positionVariant, index) {
        //find article that has to be modified
        let updatedArticle = this.getCurrentArticle(article)
        const foundPosition = updatedArticle.positions.find(position => position.articleVariant && position.articleVariant.id === positionVariant.id) || updatedArticle.positions[0]
        foundPosition.rows.splice(index, 1)
    }


    updateInstances(article, positionVariant, newInstance, index) {
        let updatedArticle = this.getCurrentArticle(article)
        let foundPosition = updatedArticle.positions.find(pos => pos.articleVariant && pos.articleVariant.id === positionVariant.id) || updatedArticle.positions[0]
        foundPosition.rows[index].instance = newInstance
    }

    updateComment(article, positionVariant, newValue, index) {
        let updatedArticle = this.getCurrentArticle(article)
        let foundPosition = updatedArticle.positions.find(pos => pos.articleVariant && pos.articleVariant.id === positionVariant.id) || updatedArticle.positions[0]
        foundPosition.rows[index].comment = newValue
    }
    updateReason(article, positionVariant, newValue, index) {
        let updatedArticle = this.getCurrentArticle(article)
        let foundPosition = updatedArticle.positions.find(pos => pos.articleVariant && pos.articleVariant.id === positionVariant.id) || updatedArticle.positions[0]
        foundPosition.rows[index].changeReasonId = newValue
    }

    addCorrectionVariant(article, selectedOption) {
        let newVariant = {
            "articleVariant": selectedOption.id ? {
                "displayName": selectedOption.displayName,
                "separatedDisplayName": null,
                "groupDisplayName": null,
                "isObsolete": null,
                "isChangeable": false,
                "id": selectedOption.id
            } : null,
            "currentQuantity": 0,
            "orderedQuantity": 0,
            "returnableQuantity": 0,
            "shippedQuantity": 0,
            "isUserAdded": true
        }
        let currentArticle = this.getCurrentArticle(article)
        currentArticle.positions.push(newVariant)
    }

    deleteCorrectionVariant(article, positionIndex) {
        let currentArticle = this.getCurrentArticle(article)
        currentArticle.positions.splice(positionIndex, 1)
    }

    onMoreOptionsHandler(item, action) {
        const fillOrClearArticle = (article) => {
            let currentArticle = this.getCurrentArticle(article)
            if (currentArticle) {
                currentArticle.positions.forEach(position => {
                    position.rows = null
                    if (action === 'fill') {
                        position.rows = Array(position.returnableQuantity).fill(this.rowsTemplate)
                    }
                })
            }
        }
        // fillOrClearArticle for a contingent
        if (item.categories && item.categories.length) {
            this.filteredContingents.find(contingent => contingent.id === item.id)
                .categories.forEach(category => {
                    category.articles.forEach(article => {
                        fillOrClearArticle(article)
                    })
                })
        }
        // fillOrClearArticle for a category
        else if (item.articles && item.articles.length) {
            this.filteredContingents.find(contingent => contingent.categories.find(category => category.id === item.id))
                .categories.find(category => category.id === item.id)
                .articles.forEach(article => {
                    fillOrClearArticle(article)
                })
        }
        // fillOrClearArticle for a single article
        else {
            fillOrClearArticle(item)
        }
    }

    async loadContingentArticleDetails() {
        this.articleDetailsIsLoading = true
        this.articleDetails = await apiClient.getJson(APIEndpoints.businessPartner(this.employeeId, this.contingentArticleId).articleDetails)
        this.articleDetailsIsLoading = false
    }

    async loadContingentArticleHistories() {
        this.articleHistoriesIsLoading = true
        this.articleHistories = await apiClient.getJson(APIEndpoints.businessPartner(this.employeeId, this.contingentArticleId).articleHistories)
        this.articleHistoriesIsLoading = false
    }
    async loadContingentArticleInstances() {
        this.articleInstancesIsLoading = true
        this.articleInstances = await apiClient.getJson(APIEndpoints.businessPartner(this.employeeId, this.contingentArticleId).articleInstances)
        this.articleInstancesIsLoading = false
    }
    async saveArticleComment(comment) {
        return await apiClient.putJson(APIEndpoints.businessPartner(this.employeeId, this.contingentArticleId).articleComment, comment)
    }


    async setEmployeeId(employeeId) {
        if (employeeId) {
            this.employeeId = employeeId
        }
        else {
            const profile = await this.app.profileStore.loadProfile()
            this.employeeId = profile.id
        }
    }
    async setSelectedContingentArticleId(contingentArticleId) {
        this.contingentArticleId = contingentArticleId
        return this.contingentArticleId
    }

    setTargetEmployeeId(targetId) {
        if (targetId) {
            this.targetEmployeeId = targetId
        }
        else this.targetEmployeeId = ''
    }
    setActionType(actionType) {
        this.actionType = actionType
    }
    setSubmitSuccess(bool) {
        this.submitSuccess = bool
    }
    setSubmitError(message) {
        this.submitError = message
    }
    setLoadingSave(bool) {
        this.loadingSave = bool
    }


    filterArticles() {
        const searchText = this.searchText.toLowerCase()
        if (this.contingents === null) {
            this.filteredContingents = []
            return
        }
        const clonedContingents = JSON.parse(JSON.stringify(this.contingents))
        this.filteredContingents = clonedContingents.map(contingent => {
            contingent.categories = contingent.categories.map(category => {
                category.articles = category.articles.filter(article => {
                    return (article.article.displayName.toLowerCase().indexOf(searchText) !== -1) && (article.articleAvailability && article.articleAvailability.currentAmount && article.articleAvailability.currentAmount > 0)
                })
                return category
            }).filter(category => category.articles.length > 0)
            return contingent
        }).filter(contingent => contingent.categories.length > 0)
        if (this.showAllArticles) {
            this.getCatalogArticles({ newOffset: 0, newSearch: this.searchText })
        }

    }

    expandArticles = (articleId) => {
        if (this.articlesExpanded.includes(articleId)) {
            this.articlesExpanded.splice(this.articlesExpanded.indexOf(articleId), 1)
        } else {
            this.articlesExpanded.push(articleId)
        }
    };


    setScrollRef(id) {
        this.scrollRef = id;
    }
    missingImagesList = [];

    retourResponse = {};
    openDeactivateModal = false;

    setOpenDeactivateModal(bool) {
        this.openDeactivateModal = bool
    }

    setReturnResponse(response = {}) {
        this.retourResponse = response
    }
    async onSubmit(navigate, currentLocation) {
        const params = this.createParams()

        this.setSubmitError('')
        this.submitErrorPointers = {}
        this.setSubmitSuccess(false)
        this.setLoadingSave(true)
        let allowDeactivateEmployee = this.app.uiStore.allowChangeActivationStatus
        try {
            const result = await apiClient.postJson(APIEndpoints.businessPartner(this.employeeId)[this.actionType], params)
            //reset
            this.loadEmployeeContingent(true)
            this.editCatalogArticles = []

            if (this.actionType === "inventoryBookings" && result && result.length) {
                this.missingImagesList = result
                let path = '/inventoryBookings/imageUpload'
                navigate(path)
            }
            else if (this.actionType === "returnBookings" && this.app.uiStore.allowBookingEmployeeReturnsWithoutInventoryBookings && result.id) {
                this.setReturnResponse(result)
            }
            else if (allowDeactivateEmployee && (this.actionType === "returnBookings" || this.actionType === "articleTransfer")) {
                this.setOpenDeactivateModal(true)
            }
            else {

                this.setSubmitSuccess(true)
                let path = currentLocation.state.from
                setTimeout(() => { navigate(path) }, 2000)
            }
        }
        catch (err) {
            this.submitError = getErrorMessageFromApiResponse(err)
            this.submitErrorPointers = setPointerErrorsFromApiResponse(err)
        }
        finally {
            this.setLoadingSave(false)
        }
    }

    removeFromMissingImagesList(requestId) {
        this.missingImagesList.splice(this.missingImagesList.findIndex(item => item.id === requestId), 1)
    }

    createParams() {
        let bookingPositions = []
        const createBookingPositions = (articleArray) => {
            for (let article of articleArray) {
                let filteredPositions = article.positions.filter(position => position.rows && position.rows.length)
                for (let position of filteredPositions) {
                    for (let row of position.rows) {
                        bookingPositions.push({
                            rfid: (row.instance && row.instance.rfid) ? row.instance.rfid : null,
                            batchKey: (row.instance && row.instance.batchKey) ? row.instance.batchKey : null,
                            serialKey: (row.instance && row.instance.serialKey) ? row.instance.serialKey : null,
                            variantId: position.articleVariant ? position.articleVariant.id : null,
                            comment: row.comment,
                            changeReasonId: row.changeReasonId || null,
                            quantity: position.negativeAmount ? -1 : 1,
                            id: article.id
                        })
                    }
                }
            }
        }
        for (let contingent of this.contingents) {
            for (let category of contingent.categories) {
                createBookingPositions(category.articles)
            }
        }
        createBookingPositions(this.editCatalogArticles)
        let params = { bookingPositions }

        if (this.actionType === "articleTransfer") {
            params.targetBusinesspartnerId = this.targetEmployeeId
        }

        return params
    }
    instanceAlreadyExists(instance) {
        for (let contingent of this.contingents) {
            for (let category of contingent.categories) {
                for (let article of category.articles) {
                    for (let position of article.positions) {
                        if (position.rows
                            && position.rows.length
                            && position.rows
                                .some(row => row.instance && (row.instance.rfid === instance.rfid && row.instance.batchKey === instance.batchKey && row.instance.serialKey === instance.serialKey))) {
                            return true
                        }
                    }
                }
            }
        }
        return false
    }

    getArticlesByArticleId(articleId) {
        let result = []
        for (let contingent of this.contingents) {
            for (let category of contingent.categories) {
                for (let article of category.articles) {
                    if (article.article.id === articleId) {
                        result.push(article)
                    }
                }
            }
        }
        return result
    }
    articleVariantToString(articleId, variant) {
        return `${articleId}_${variant ? variant.id : '_'}`
    }


    async loadAllInstances(force) {
        if (!force) {
            if (this.instances) {
                return null
            }
        }

        let instances = []
        try {
            this.loadInstancesLoading = true
            instances = await apiClient.getJson(APIEndpoints.businessPartner(this.employeeId).instances)
            this.instances = instances
        }
        catch (e) {

        }
        finally {
            this.loadInstancesLoading = false
        }
    }

    async getInstances(articleId = null, variant = null) {
        if (this.loadInstancesLoading) {
            await this.app.uiStore.waitUntilLoaded(100, 'app.inventoryStore.loadInstancesLoading')
            return this.getInstances(articleId, variant)
        }
        else {
            if (articleId && variant) {
                return this.instances.filter(instance => instance.article && (instance.article.id === articleId) && instance.variant && (instance.variant.id === variant.id))
            } else if (articleId) {
                return this.instances.filter(instance => instance.article && (instance.article.id === articleId))
            }
            else { return this.instances }
        }
    }
    returnReasons = null;
    async loadReturnReasons() {
        this.returnReasons = null
        if (this.actionType === "inventoryBookings") {
            this.returnReasons = await apiClient.getJson(APIEndpoints.inventoryBookingReasons)
        }
        else if (this.actionType === "returnBookings") {
            this.returnReasons = await apiClient.getJson(APIEndpoints.returnBookingReasons)
        }
    }
    resetActionPage() {
        this.setSubmitSuccess(false)
        this.setSubmitError('')

        this.setReturnResponse({})

    }
}

export default InventoryStore
