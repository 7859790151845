import { action, observable, reaction, makeObservable } from 'mobx';
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { debounce } from 'throttle-debounce'
import { getIsoDate } from '../utils/getIsoDate'
import { emptyDate } from '../utils/emptyDate'
import { emptyGuid } from '../utils/emptyGuid'
import { t } from 'i18next'

class CheckoutStore {
  constructor(appStore) {
    makeObservable(this, {
      billingCountries: observable,
      shippingCountries: observable,
      gTCIsAccepted: observable,
      additionalConditionIsAccepted: observable,
      additionalConditions: observable,
      foreignOrderNr: observable,
      orderComment: observable,
      shipmentPriceIsAccepted: observable,
      showShipmentPriceAccepted: observable,
      entitlementExceedanceIsAccepted: observable,
      showEntitlementExceedanceIsAccepted: observable,
      pointsCreditIsAccepted: observable,
      showPointsCreditIsAccepted: observable,
      preferSecondhandArticles: observable,
      articleReturnDate: observable,
      setCountry: action,
      resetConfirmOrder: action,
      isCustomShipmentAddress: observable,
      cwsOrderCommentProcess: action,
      loadBillingCountries: action,
      loadShippingCountries: action,
      handleForeignOrderNrChange: action,
      handleOrderCommentChange: action,
      handleArticleReturnDateChange: action,
      updateComments: action,
      setAdditionalConditionIsAccepted: action,
      setPreferSecondhandArticles: action,
      setGTCIsAccepted: action,
      setShipmentPriceIsAccepted: action,
      setEntitlementExceedanceIsAccepted: action,
      setPointsCreditIsAccepted: action
    });
    reaction(
      () => this.orderComment,
      orderComment => {
        this.updateTextsDebounced()
      }
    )
    reaction(
      () => this.foreignOrderNr,
      foreignOrderNr => {
        this.updateTextsDebounced()
      }
    )
    this.app = appStore
  }

  billingCountries = [];
  shippingCountries = [];

  gTCIsAccepted = false;
  additionalConditionIsAccepted = false;
  additionalConditions = {};
  foreignOrderNr = "";
  orderComment = "";
  shipmentPriceIsAccepted = false;
  showShipmentPriceAccepted = false;
  entitlementExceedanceIsAccepted = false;
  showEntitlementExceedanceIsAccepted = false;
  pointsCreditIsAccepted = false;
  showPointsCreditIsAccepted = false;
  preferSecondhandArticles = false;
  articleReturnDate = null;
  setCountry(country) {
    this.country = country
  }

  resetConfirmOrder(basket) {
    this.showShipmentPriceAccepted = false
    this.showEntitlementExceedanceIsAccepted = false
    this.showPointsCreditIsAccepted = false
    this.additionalConditions = {}
    this.gTCIsAccepted = false
    this.additionalConditionIsAccepted = false
    this.shipmentPriceIsAccepted = false
    this.entitlementExceedanceIsAccepted = false
    this.pointsCreditIsAccepted = false
    this.preferSecondhandArticles = false
    this.isCustomShipmentAddress = false

    let resetObj = {}
    if (basket.gTCIsAccepted) resetObj.gTCIsAccepted = false
    if (basket.additionalConditionIsAccepted) resetObj.additionalConditionIsAccepted = false
    if (basket.shipmentPriceIsAccepted) resetObj.shipmentPriceIsAccepted = false
    if (basket.entitlementExceedanceIsAccepted) resetObj.entitlementExceedanceIsAccepted = false
    if (basket.pointsCreditIsAccepted) resetObj.pointsCreditIsAccepted = false
    if (basket.preferSecondhandArticles) resetObj.preferSecondhandArticles = false
    if (Object.keys(resetObj).length) {
      this.app.productsStore.setApiBasket(resetObj)
    }
  }
  isCustomShipmentAddress = false;
  cwsOrderCommentProcess = (basket) => {
    const cwsOrderCommentProcessActive = this.app.uiStore.cwsOrderCommentProcessActive
    const isCustomShipmentAddress = !basket.shipmentAddress.originAddressId || basket.shipmentAddress.originAddressId === emptyGuid
    if (cwsOrderCommentProcessActive && isCustomShipmentAddress) {
      this.isCustomShipmentAddress = true
      this.handleOrderCommentChange(t('cws:specialCheckoutOrderComment'))
    }
  };

  async loadBillingCountries() {
    if (this.billingCountries.length) {
      return
    }
    let result = await apiClient.getJson(APIEndpoints.countries)
    if (result) {
      this.billingCountries = result
      let defaultCountry = result.find(country => country.isDefault)
      if (defaultCountry && !this.app.productsStore.customBillingAddress.countryIsoCode) {
        this.app.productsStore.customBillingAddress.countryIsoCode = defaultCountry.isoCode
      }
    }
  }

  async loadShippingCountries(shoppingBasketId) {
    if (this.shippingCountries.length) {
      return
    }
    let result = await apiClient.getJson(APIEndpoints.availableCountries(shoppingBasketId))
    if (result) {
      this.shippingCountries = result
      let defaultCountry = result.find(country => country.isDefault)
      if (defaultCountry && !this.app.productsStore.customShipmentAddress.countryIsoCode) {
        this.app.productsStore.customShipmentAddress.countryIsoCode = defaultCountry.isoCode
      }
    }
  }



  handleForeignOrderNrChange = value => {
    //setApiBasket onSubmit
    this.foreignOrderNr = value
  };
  handleOrderCommentChange = value => {
    //setApiBasket onSubmit
    this.orderComment = value
  };
  handleArticleReturnDateChange = date => {
    this.articleReturnDate = getIsoDate(date) || emptyDate
  };

  updateComments = async () => {
    const comments = {}
    if (this.app.productsStore.basket.foreignOrderNr !== this.foreignOrderNr) {
      comments.foreignOrderNr = this.foreignOrderNr
    }
    if (this.app.productsStore.basket.orderComment !== this.orderComment) {
      comments.orderComment = this.orderComment
    }

    Object.keys(comments).length && (await this.app.productsStore.setApiBasket(comments))
  };

  setAdditionalConditionIsAccepted = e => {
    let { checked, name } = e.target

    this.additionalConditions[name] = checked

    let allConditions = this.app.productsStore.basket.additionalConditions
    let allConditionsAccepted = this.app.productsStore.basket.additionalConditionIsAccepted
    let newAllConditionsAccepted = allConditions.every(condition => this.additionalConditions[condition.type])

    if (allConditionsAccepted !== newAllConditionsAccepted) {
      this.app.productsStore.setApiBasket({
        additionalConditionIsAccepted: newAllConditionsAccepted
      })
    }
  };

  setPreferSecondhandArticles = e => {
    let { checked } = e.target
    this.preferSecondhandArticles = checked
    this.app.productsStore.setApiBasket({
      preferSecondhandArticles: checked
    })
  };

  setGTCIsAccepted = e => {
    let { checked } = e.target;
    this.gTCIsAccepted = checked
    this.app.productsStore.setApiBasket({
      gTCIsAccepted: checked
    })
  };

  setShipmentPriceIsAccepted = e => {
    let { checked } = e.target
    this.shipmentPriceIsAccepted = checked
    this.app.productsStore.setApiBasket({
      shipmentPriceIsAccepted: checked
    })
  };

  setEntitlementExceedanceIsAccepted = e => {
    let { checked } = e.target
    this.entitlementExceedanceIsAccepted = checked
    this.app.productsStore.setApiBasket({
      entitlementExceedanceIsAccepted: checked
    })
  };

  setPointsCreditIsAccepted = e => {
    let { checked } = e.target
    this.pointsCreditIsAccepted = checked
    this.app.productsStore.setApiBasket({
      pointsCreditIsAccepted: checked
    })
  };

  isShipmentAddressAvailable() {
    const { productsStore } = this.app
    return productsStore.basket
      && productsStore.basket.shipmentAddress
      && productsStore.basket.metaProperties.find(metaProp => (metaProp.pointer === '/shipmentAddress' && metaProp.attributes === 'isRequired'))
  }

  isCustomShipmentAddressAvailable() {
    const { productsStore } = this.app
    return productsStore.basket
      && productsStore.basket.metaProperties
      && !productsStore.basket.metaProperties.find(metaProp => (metaProp.pointer === '/shipmentAddress' && metaProp.attributes === 'isReadOnly'))
      && this.isShipmentAddressAvailable()
  }

  isBillingAddressAvailable() {
    const { productsStore } = this.app
    return productsStore.basket
      && productsStore.basket.billingAddress
      && productsStore.basket.metaProperties.find(metaProp => (metaProp.pointer === '/billingAddress' && metaProp.attributes === 'isRequired'))
  }

  isCustomBillingAddressAvailable() {
    const { productsStore } = this.app
    return productsStore.basket
      && productsStore.basket.metaProperties
      && !productsStore.basket.metaProperties.find(metaProp => (metaProp.pointer === '/billingAddress' && metaProp.attributes === 'isReadOnly'))
      && this.isBillingAddressAvailable()
  }




  updateTextsDebounced = debounce(350, () => {
    this.updateComments()
  })

  articleReturnDateReaction = reaction(
    () => this.articleReturnDate,
    articleReturnDate => {
      this.updateArticleReturnDateDebounced()
    }
  )
  updateArticleReturnDateDebounced = debounce(350, () => {
    this.app.productsStore.setApiBasket({
      articleReturnDate: this.articleReturnDate
    })
  })
}

export default CheckoutStore
