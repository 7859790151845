import React, { useEffect, useState, useRef, useMemo, useCallback } from "react"
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { getErrorMessageFromApiResponse } from "../../utils/api/apiUtils"
import { getIsoDate } from '../../utils/getIsoDate.js';
import { cloneDeep } from 'lodash';
import useStores from '../useStore'

const AppointmentsAdministrationContext = React.createContext({})
export function useAppointmentsAdministrationState() {
    return React.useContext(AppointmentsAdministrationContext)
}

const AppointmentsAdministrationProvider = ({ children }) => {
    const [appointmentTypes, setAppointmentTypes] = useState([])
    const [error, setError] = useState("")
    const [selectedDays, setSelectedDays] = useState([])
    const [selectedCabin, setSelectedCabin] = useState("")
    const [appointmentDetails, setAppointmentDetails] = useState('')
    const [cabinTypes, setCabinTypes] = useState([])
    const [bookingSlots, setBookingSlots] = useState([])
    const [selectedDate, setSelectedDate] = useState('')
    const [showDailyAppointmentsCompareModal, setShowDailyAppointmentsCompareModal] = useState(false)
    const mountedRef = useRef(true)
    const [dates, setDates] = useState([]);
    const { overlayRightStore } = useStores()
    const setShowOverviewOverlay = () => overlayRightStore.openOrClose("dailyAppointmentsOverview")
    const getPreparedPeriods = useCallback((selectedDays) => {
        const preparedPeriods = selectedDays.map(day => ({
            start: day[0],
            end: day[1]
        }));
        return preparedPeriods
    }, [])
    // get bookingOverview for the selectedDays
    const getBookingOverview = useCallback((selectedDays) => {
        if (selectedDays.length > 0) {
            let periods = getPreparedPeriods(selectedDays)
            apiClient.postJson(APIEndpoints.bookingOverview, {
                'appointmentTypeId': "00000000-0000-0000-0000-000000000000",
                'cabinId': selectedCabin,
                'periods': periods
            }).then(response => {
                let slots = [];
                response.forEach(appType =>
                    appType.cabins[0].bookingSlots.forEach(slot => {
                        slot.appointmentType = appType.appointmentType;
                        slot.cabin = appType.cabins[0].cabin;
                        slot.type = appType.type;
                        slots.push(slot)
                    })
                )
                setBookingSlots(slots);

            }).catch(error => {
                setError(getErrorMessageFromApiResponse(error))
            });

            // reset the selectedDate each time we have only one selected day 
            if (selectedDays.length === 1) {
                let date = selectedDays[0][0].slice(0, 10);     // to get the selected date for the calendar in format 2021-10-21
                setSelectedDate(date);
            }
        } else {
            setSelectedDate('');
        }
    }, [getPreparedPeriods, selectedCabin])
    useEffect(() => {
        if (dates) {
            let sortedDates = cloneDeep(dates).sort((a, b) => a > b ? 1 : -1);
            let newPeriods = [];
            for (let date of sortedDates) {
                newPeriods.push([getIsoDate(date), getIsoDate(date, true)]);
            }
            setSelectedDays(newPeriods);
            getBookingOverview(newPeriods)
        }
    }, [dates, getBookingOverview])

    useEffect(() => {
        setDates([])
    }, [selectedCabin])
    //get AppointmentTypes from Backend
    useEffect(() => {
        setError('')
        apiClient.getJson(APIEndpoints.appointmenttypes()).then(response => {
            if (!mountedRef.current) { return null }
            setAppointmentTypes(response)
        }).catch((error) => {
            setError(getErrorMessageFromApiResponse(error))
        })
        apiClient.getJson(APIEndpoints.cabins()).then(response => {
            if (!mountedRef.current) { return null }
            setCabinTypes(response)
        }).catch((error) => {
            setError(getErrorMessageFromApiResponse(error))
        })
    }, [])

    //Cleanup Function
    useEffect(() => {
        return () => { mountedRef.current = false }
    }, [])


    // removes the canceled event from the bookingSlots array (so it doesn't show in the calendar)
    const removeCanceledEvent = useCallback(() => {
        let filteredBookingSlots = bookingSlots.filter(slot => slot.id !== appointmentDetails.id);
        setBookingSlots(filteredBookingSlots);
        overlayRightStore.closeAll();
        setAppointmentDetails('');
    }, [appointmentDetails.id, bookingSlots, overlayRightStore])



    const lockAll = (comment = "") => {
        setError('')
        const preparedParams = { periods: getPreparedPeriods(), comment: comment }
        apiClient.postJson(APIEndpoints.cabins(selectedCabin).locks, preparedParams).then((result) => {
            console.log(result)
        }).catch(error => {
            setError(getErrorMessageFromApiResponse(error))
        })
    }

    const deleteEvent = (event) => {
        const preparedParams = { periods: getPreparedPeriods(), [event]: true }
        return apiClient.deleteJson(APIEndpoints.cabins(selectedCabin).events, null, true, true, { data: preparedParams })
    }


    const isStep1Done = useMemo(() => {
        if (selectedCabin !== "") return true
        else return false
    }, [selectedCabin])

    const isStep2Done = useMemo(() => {
        if (selectedDays.length) return true
        else return false
    }, [selectedDays])


    const contextValues = {
        dates, setDates,
        isStep1Done,
        isStep2Done,
        appointmentTypes,
        selectedDays, setSelectedDays,
        selectedCabin, setSelectedCabin,
        cabinTypes,
        deleteEvent,
        lockAll,
        error,
        appointmentDetails, setAppointmentDetails,
        bookingSlots, setBookingSlots,
        selectedDate, setSelectedDate,
        showDailyAppointmentsCompareModal, setShowDailyAppointmentsCompareModal,
        setShowOverviewOverlay, removeCanceledEvent
    }
    return (
        <AppointmentsAdministrationContext.Provider value={contextValues}>
            {children}
        </AppointmentsAdministrationContext.Provider>
    )
}

export default AppointmentsAdministrationProvider
