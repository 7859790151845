import React, { useCallback, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import useStores from '../../../../stores/useStore'
import { emptyGuid } from '../../../../utils/emptyGuid'
import StructureIcon from '../../../Icons/StructureIcon'
import styled from 'styled-components'
import OverlayRight from '../../../Page/logix2020/OverlayRight'
import OverlayContainer from '../../../Page/logix2020/OverlayContainer'
import { VisualInput } from '../../../Forms/VisualInput'
import CostCenterTree from '../../../CostCenter/CostCenterTree'
import { StyledValidationMessage } from '../formHelper'
import { useTranslation } from 'react-i18next'
import { CrossIcon } from '../../../Icons/CrossIcon'
import { colors } from '../../../../styles/colors'
import { useObserver } from 'mobx-react'
import { usePrevious2 } from '../../../../utils/hooks/usePrevious'

const StyledButton = styled.div`
  border: 0;
  background: none;
  padding: 0;
  margin: 0 0 0 10px;
  cursor:pointer;
`

const StyledCrossIcon = styled(CrossIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  z-index: 0;
  right: 6px;
`
const CostCenterSelect = ({
  id,
  label,
  onChange,
  value,
  isInvalid = false,
  withSearch = false,
  includeDeleted = false,
  showTopLevel = false,
  overLayRootZIndex,
  isClickable = () => true,
  errorMessage,
  filterMainOfficeId = ''
}) => {
  const { costCenterStore, profileStore, overlayRightStore } = useStores()
  const [costCenterName, setCostCenterName] = useState('')
  const { t } = useTranslation()
  const showCostCenterTree = useObserver(() => overlayRightStore.openOverlay === id)
  const partialCostCenters = useObserver(() => costCenterStore.partialCostCenters || [])
  const previosIncludeDeleted = usePrevious2(includeDeleted)

  useEffect(() => {
    if (previosIncludeDeleted !== includeDeleted) {
      costCenterStore.resetPartialCostCenters()
    }
    costCenterStore.loadPartialCostCenters(emptyGuid, includeDeleted)
  }, [includeDeleted, costCenterStore, previosIncludeDeleted])

  const loadCostCenterName = useCallback(async (value) => {
    var loadedCostCenterName = ''
    try {
      const loadedCostCenter = await costCenterStore.loadPartialCostCenter(value)
      loadedCostCenterName = loadedCostCenter.displayName
    } finally {
      setCostCenterName(loadedCostCenterName)
    }
  }, [costCenterStore])

  useEffect(() => {
    if (value && partialCostCenters.length && value !== emptyGuid) {
      const foundCostCenter = partialCostCenters.find(item => item.id === value)
      if (foundCostCenter) {
        setCostCenterName(foundCostCenter.displayName)
      }
      else {
        loadCostCenterName(value)
      }
    }
    else if (showTopLevel && value === emptyGuid) {
      setCostCenterName(profileStore.profile.mandator.displayName)
    }
    else {
      setCostCenterName('')
    }
  }, [value, partialCostCenters, showTopLevel, loadCostCenterName, profileStore.profile.mandator.displayName])



  const handleClickCostCenter = (costCenter) => {
    onChange(costCenter)
    overlayRightStore.closeAll()
  }
  const handleClear = (e) => {
    e.stopPropagation()
    onChange(null)
  }

  const CostCenterInput = ({ whiteBackground, withClearIcon, withPlaceholder }) => (
    <div className="d-flex align-items-start">
      <VisualInput style={{ overflow: "auto" }} className={`${whiteBackground ? 'white-background' : ''} ${isInvalid ? 'is-invalid' : ''}`}
        onClick={() => overlayRightStore.openOrClose(id)}>

        <>
          {costCenterName
            ? <>{costCenterName}{withClearIcon ? <StyledCrossIcon color={colors.gray2} onClick={handleClear} /> : ''}</>
            : withPlaceholder ? <span style={{ color: '#c6c6c6' }}>{t('searchCostCenter')}</span> : ''}
        </>
      </VisualInput>
      <StyledButton onClick={() => overlayRightStore.openOrClose(id)}><StructureIcon /></StyledButton>
    </div >
  )
  return (
    <>
      {
        label
          ? (
            <Form.Group className="mb-3" >
              <Form.Label>{label}</Form.Label>
              <CostCenterInput />
              {errorMessage && <StyledValidationMessage>{errorMessage}</StyledValidationMessage>}
            </Form.Group >)
          : (
            <CostCenterInput whiteBackground withPlaceholder withClearIcon />
          )}

      <OverlayContainer overLayRootZIndex={showCostCenterTree ? overLayRootZIndex : undefined}>
        <OverlayRight headerTitle={t('costcenter')} visible={showCostCenterTree} onClose={() => overlayRightStore.closeAll()}>
          <div className="content">
            {showCostCenterTree &&
              <CostCenterTree
                includeDeleted={includeDeleted}
                onClick={handleClickCostCenter}
                isClickable={isClickable}
                withSearch={withSearch}
                showTopLevel={showTopLevel}
                filterMainOfficeId={filterMainOfficeId}
              />}
          </div>
        </OverlayRight>
      </OverlayContainer>
    </>
  )
}
export default CostCenterSelect
