import React from 'react'

export const Default_kln = (props) => {
    const color = props.color ? props.color : '#020203'
    return (
        <svg version="1.1" id="Ebene_1" x="0px" y="0px"
            viewBox="0 0 300 300" style={{ enableBackground: 'new 0 0 300 300' }} >
            <style type="text/css">
                {`
            .st0{fill:${color};}
          `}
            </style>
            <path className="st0" d="M245.3,269.2L245.3,269.2c-4.7,0-8.5-3.8-8.5-8.5V39.3c0-4.7,3.8-8.5,8.5-8.5h0c4.7,0,8.5,3.8,8.5,8.5v221.4
	C253.8,265.4,250,269.2,245.3,269.2z"/>
            <path className="st0" d="M54.7,269.2L54.7,269.2c-4.7,0-8.5-3.8-8.5-8.5V39.3c0-4.7,3.8-8.5,8.5-8.5h0c4.7,0,8.5,3.8,8.5,8.5v221.4
	C63.2,265.4,59.4,269.2,54.7,269.2z"/>
            <rect x="53.1" y="240.5" className="st0" width="192.2" height="12.8" />
            <rect x="53.1" y="178.9" className="st0" width="192.2" height="12.8" />
            <rect x="53.1" y="115.7" className="st0" width="192.2" height="12.8" />
            <rect x="53.1" y="53.3" className="st0" width="192.2" height="12.8" />
            <path className="st0" d="M171,244.4h-41c-2.2,0-4-1.8-4-4v-30.6c0-2.2,1.8-4,4-4h41c2.2,0,4,1.8,4,4v30.6
	C175,242.6,173.2,244.4,171,244.4z"/>
            <path className="st0" d="M115.5,244.4h-41c-2.2,0-4-1.8-4-4v-30.6c0-2.2,1.8-4,4-4h41c2.2,0,4,1.8,4,4v30.6
	C119.5,242.6,117.7,244.4,115.5,244.4z"/>
            <path className="st0" d="M226.5,243.5h-41c-2.2,0-4-1.8-4-4v-30.6c0-2.2,1.8-4,4-4h41c2.2,0,4,1.8,4,4v30.6
	C230.5,241.7,228.7,243.5,226.5,243.5z"/>
            <path className="st0" d="M226.5,182.8h-41c-2.2,0-4-1.8-4-4v-30.6c0-2.2,1.8-4,4-4h41c2.2,0,4,1.8,4,4v30.6
	C230.5,181,228.7,182.8,226.5,182.8z"/>
            <path className="st0" d="M171,182.8h-41c-2.2,0-4-1.8-4-4v-30.6c0-2.2,1.8-4,4-4h41c2.2,0,4,1.8,4,4v30.6C175,181,173.2,182.8,171,182.8
	z"/>
            <path className="st0" d="M115.5,182.8h-41c-2.2,0-4-1.8-4-4v-30.6c0-2.2,1.8-4,4-4h41c2.2,0,4,1.8,4,4v30.6
	C119.5,181,117.7,182.8,115.5,182.8z"/>
            <path className="st0" d="M171,119.6h-41c-2.2,0-4-1.8-4-4V85c0-2.2,1.8-4,4-4h41c2.2,0,4,1.8,4,4v30.6C175,117.8,173.2,119.6,171,119.6z
	"/>
            <path className="st0" d="M115.5,119.6h-41c-2.2,0-4-1.8-4-4V85c0-2.2,1.8-4,4-4h41c2.2,0,4,1.8,4,4v30.6
	C119.5,117.8,117.7,119.6,115.5,119.6z"/>
        </svg>
    )
}

