import React, { useCallback, useEffect, useState } from 'react'
import { CloseButton, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import styled from 'styled-components'
import ArraySelect from '../../components/Select/ArraySelect';

const StyledLabelInnerWrap = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;

  button.close {
    outline: none;
  }
`
const ReportTypeSelect = ({ onChange, value, withResetButton }) => {
  const { t } = useTranslation()
  const [reportTypes, setReportTypes] = useState([])
  const [selectedValue, setSelectedValue] = useState(value || '')

  useEffect(() => {
    apiClient.getJson(APIEndpoints.reportJobsCounter).then(response => {
      setReportTypes(response.map(item => item.type))
    })
  }, [])

  const handleChange = useCallback(e => {
    setSelectedValue(e.target.value)
    onChange(e.target.value)
  }, [onChange])

  const handleReset = useCallback(() => {
    setSelectedValue('')
    onChange('')
  }, [onChange])

  return (
    <>
      <Form.Label><StyledLabelInnerWrap><span>{t('reports:reportType')}</span>{withResetButton &&
        <CloseButton onClick={handleReset} />}</StyledLabelInnerWrap></Form.Label>

      <ArraySelect
        defaultOption={{ value: '', name: t('all') }}
        options={reportTypes}
        value={selectedValue}
        onChange={handleChange}
        returnEvent
        defaultOptionIsSelectable
        noAutoSelect
      />

    </>
  )
}

export default ReportTypeSelect
