import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Alert, Form } from 'react-bootstrap'
import { t } from 'i18next'

import OfficesTree from '../../Offices/OfficesTree'
import { useCatalogsState } from '../../../stores/Configuration/CatalogsProvider'

const StyledWrapper = styled.div`
  @media screen and (min-width: 1080px) {
    max-width: calc(100% - 480px);
    min-width: 600px;
  }
  .search-wrapper{
    padding-left: 0;
    padding-right: 0;
  }
  .action-buttons {
    visibility: visible !important;
  }
`
const StyledCheckbox = styled(Form.Check.Input)`
  cursor: pointer;
`

const CatalogActivateArticle = ({ selectedOffices, setSelectedOffices }) => {
  const { successStates, setSuccessStates, initCatalogActivateArticle } = useCatalogsState()

  useEffect(() => {
    if (successStates.saveCatalogOfficeAvailabilities || successStates.deleteCatalogOfficeAvailabilities) {
      setTimeout(() => {
        setSuccessStates({})
      }, 3000)
    }
  }, [setSuccessStates, successStates]);

  useEffect(() => {
    setSelectedOffices(null)
    initCatalogActivateArticle()
  }, [initCatalogActivateArticle, setSelectedOffices])

  const handleCheckboxClick = (office) => {
    let tempSelectedOffices = (selectedOffices || [])
    const isSelected = tempSelectedOffices.includes(office.id);
    if (isSelected) {
      setSelectedOffices(tempSelectedOffices.filter((id) => id !== office.id));
    } else {
      setSelectedOffices([...tempSelectedOffices, office.id]);
    }
  };

  const renderExtraItemContent = (office) => {
    return (
      <>
        <div className="action-buttons">
          <div className="d-flex">
            <Form.Check>
              <StyledCheckbox
                type="checkbox"
                checked={selectedOffices && selectedOffices.includes(office.id)}
                onChange={() => handleCheckboxClick(office)}
                id={office.id}
              />
            </Form.Check>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="d-flex align-items-center additional">
        <StyledWrapper>
          <OfficesTree onClick={handleCheckboxClick} isClickable={() => true}
            renderExtraItemContent={renderExtraItemContent}
          />
          {successStates.saveCatalogOfficeAvailabilities || successStates.deleteCatalogOfficeAvailabilities ? (
            <Alert variant="success" className="mt-2">
              {t('catalogsAdministration:successMsg')}
            </Alert>
          ) : null}
        </StyledWrapper>
      </div>
    </>
  )
}

export default CatalogActivateArticle