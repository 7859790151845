import React from 'react'

export const LockOpenIcon = ({ color, title, ...rest }) => {
    const useColor = color ? color : '#3C3C3B'
    return (
        <svg width="16px" height="16px" viewBox="8 5 16 16"  {...rest}>
            {title && <title>{title}</title>}
            <g stroke="none" strokeWidth="1" fill={useColor} fillRule="evenodd">
                <g transform="translate(9.000000, 6.000000)">
                    <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z" />
                </g>
            </g>
        </svg>
    )
}
