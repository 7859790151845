import React, { useState, useEffect } from 'react'
import OverlayRight, { StyledOverlayInnerWrapper } from '../../Page/logix2020/OverlayRight'

import OverlayContainer from '../../Page/logix2020/OverlayContainer'
import { useTranslation } from 'react-i18next'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import apiClient from '../../../utils/api/apiClient'
import { StyledDataList } from '../../DataList/dataList'
import { Spinner, Form } from 'react-bootstrap'
import { PenIcon } from '../../Icons/PenIcon'
import { Link } from 'react-router-dom'
import ArraySelect from '../../Select/ArraySelect'
import { getLanguageValue } from '../../../utils/getLanguageValue'

const OfficeDetails = ({ selectedOffice, openDeleteModal, openCopyModal }) => {
    const { t } = useTranslation()
    const [officeDetail, setOfficeDetail] = useState(null)
    const [officeGroupAssignments, setOfficeGroupAssignments] = useState(null)
    const [officeAddresses, setOfficeAddresses] = useState(null)
    const [shippingAddress, setShippingAddress] = useState(null)
    const [billingAddress, setBillingAddress] = useState(null)
    const [languageNameExternal, setLanguageNameExternal] = useState("")
    const [languageComment, setLanguageComment] = useState("")
    const [actionSelect, setActionSelect] = useState("")

    useEffect(() => {
        setActionSelect("")
        setOfficeDetail(null)
        setOfficeAddresses(null)
        setOfficeGroupAssignments(null)
        apiClient.getJson(APIEndpoints.offices(selectedOffice.id).details)
            .then(response => {
                setOfficeDetail(response)
                let name = getLanguageValue(response.nameExternal.values)
                let comment = getLanguageValue(response.comment.values)
                setLanguageNameExternal(name)
                setLanguageComment(comment)
            })
            .catch(error => console.log(error))

        apiClient.getJson(APIEndpoints.offices(selectedOffice.id).addresses)
            .then(response => {
                setOfficeAddresses(response)
                setShippingAddress(response.find(item => item.addressType === "shipment" && item.isDefaultAddress))
                setBillingAddress(response.find(item => item.addressType === "billing" && item.isDefaultAddress))
            })
            .catch(error => console.log(error))

        apiClient.getJson(APIEndpoints.offices(selectedOffice.id).groupAssignments)
            .then(response => {
                setOfficeGroupAssignments(response)
            })
            .catch(error => console.log(error))
    }, [selectedOffice.id])

    const selectAction = (action) => {
        if (action === "delete") {
            openDeleteModal(selectedOffice)
        }
        if (action === "copy") {
            openCopyModal(selectedOffice)
        }
    }

    if (!officeDetail || !officeAddresses || !officeGroupAssignments) {
        return <div className='d-flex justify-content-center' >
            <Spinner animation='border' variant='primary' />
        </div>
    }

    const officeDetailArray = [
        { label: `${t('officeAdmin:externerName')}:`, value: languageNameExternal || "-" },
        { label: `${t('comment')}:`, value: languageComment || "-" },
        { label: `${t('costCenterAdmin:number')}:`, value: officeDetail.number ? officeDetail.number : "-" },
        { label: `${t('costCenterAdmin:foreignKey')}:`, value: officeDetail.foreignKey },
        { label: `${t('costCenterAdmin:parentOffice')}:`, value: officeDetail.parentOffice ? officeDetail.parentOffice.displayName : "-" },
    ]
    const costCenterAdminArray = [
        { label: `${t('officeAdmin:billingAddress')}:`, value: billingAddress ? `${billingAddress.street} ${billingAddress.streetNr} - ${billingAddress.city}` : "-" },
        { label: `${t('officeAdmin:shippingAddress')}:`, value: shippingAddress ? `${shippingAddress.street} ${shippingAddress.streetNr} - ${shippingAddress.city}` : "-" },
    ]


    return (
        <>
            <StyledDataList className="content">
                <div className="data-list__name h2">
                    {officeDetail.name}
                    <Link to={`/officeadministration/edit/${selectedOffice.id}#general`} >
                        <PenIcon title={t('edit')} />
                    </Link>
                </div>
                <ul className="data-list__data">
                    {officeDetailArray.map((listItem, idx) => {
                        return (
                            <li key={`${idx} ${listItem.label}`} className='data-list__entry'>
                                <div className="data-list__label">{listItem.label}</div>
                                <div className="data-list__value">{listItem.value}</div>
                            </li>
                        )
                    })}
                </ul>

                <div className="data-list__name h3 mt-4">
                    {t('costCenterAdmin:addresses')}
                    <Link to={`/officeadministration/edit/${selectedOffice.id}#addresses`}>
                        <PenIcon className="edit-button" />
                    </Link>
                </div>
                <ul className="data-list__data">
                    {costCenterAdminArray.map((listItem, idx) => {
                        return (
                            <li key={`${idx} ${listItem.label}`} className='data-list__entry'>
                                <div className="data-list__label">{listItem.label}</div>
                                <div className="data-list__value">{listItem.value}</div>
                            </li>
                        )
                    })}
                </ul>

                <div className="data-list__name h3 mt-4">
                    {t('costCenterAdmin:accessRights')}
                    <Link to={`/officeadministration/edit/${selectedOffice.id}#access-rights`}>
                        <PenIcon className="edit-button" />
                    </Link>
                </div>
                <ul >
                    {
                        officeGroupAssignments && officeGroupAssignments.map((item, i) => {
                            return item.isAssigned ?
                                <li className="data-list__value" key={item.group.id} >{item.group.displayName}</li>
                                : ""
                        })
                    }
                </ul>
            </StyledDataList>

            <StyledDataList className="footer p-4">
                <Form.Group className="mb-3">
                    <ArraySelect
                        defaultOption={{ value: '', name: t('selectAction') }}
                        options={[{ id: 'delete', displayName: t('delete') }, { id: 'copy', displayName: t('officeAdmin:copy') }]}
                        value={actionSelect}
                        onChange={e => selectAction(e.target.value)}
                        returnEvent
                    />
                </Form.Group>
            </StyledDataList>
        </>
    )
}



const OverlayRightOfficeInfo = ({ visible, onClose, ...rest }) => {
    const { t } = useTranslation()
    return (
        <OverlayContainer>
            <OverlayRight visible={visible} onClose={onClose} headerTitle={t('details')}>
                <StyledOverlayInnerWrapper>
                    {visible && <OfficeDetails {...rest} />}
                </StyledOverlayInnerWrapper>
            </OverlayRight>
        </OverlayContainer>
    )
}

export default OverlayRightOfficeInfo
