import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from 'styled-components'

const StyledCard = styled.div`
 padding:3rem;
 margin:2rem;
 box-shadow: 0 0 15px rgb(0 0 0 / 20%);
 cursor:pointer;
 opacity:0.6;
 &:hover{
     opacity:1;
 }
 flex-grow:1;
`
const ChooseArticleSelectModal = ({ handleClose, setShowScanModal, setShowTreeModal }) => {
  const { t } = useTranslation()
  return (
    <Modal size="lg" show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('inventory:addArticle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          <div className="d-flex flex-wrap">
            <StyledCard
              onClick={() => {
                setShowTreeModal(true);
                handleClose();
              }}
            >
              <h2>{t('inventory:manualSelection')}</h2>
              <div>{t('inventory:name&Size')}</div>
            </StyledCard>
            <StyledCard
              onClick={() => {
                setShowScanModal(true);
                handleClose();
              }}
            >
              <h2>{t('inventory:searchScan')}</h2>
              <div>RFID</div>
              <div> {t('inventory:serialNumber')}</div>
              <div> {t('inventory:batchNumber')}</div>
            </StyledCard>
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("closeButton")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ChooseArticleSelectModal