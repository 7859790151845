import React from 'react'
import styled from 'styled-components'
import { OverlayTrigger, Popover } from 'react-bootstrap'


const StyledIcon = styled.div`
&:before{
    background: ${props => props.color ? props.color : props.theme.primaryColor};
    color: white;
    content:${props => props.initials}; 
    display: inline-block; 
    border-radius: 50%; 
    vertical-align: middle; 
    width: 35px; 
    height: 35px; 
    line-height: 35px; 
    text-align: center;
}
`


const UserInitials = ({ username, withPopover, color, ...rest }) => {

    const initials = username ? username.split(' ').map(word => word[0]).join('').slice(0, 2).toUpperCase() : ""

    return (
        withPopover
            ? <OverlayTrigger placement="bottom"
                overlay={
                    <Popover id="popover-basic"  >
                        {username
                            ? <Popover.Body style={{ padding: ".2rem" }} >
                                {username}
                            </Popover.Body >
                            : null}
                    </Popover>
                }
            >
                <StyledIcon initials={`'${initials}'`} color={color} />
            </OverlayTrigger>
            : <div style={{ width: "35px" }} {...rest}>
                <StyledIcon initials={`'${initials}'`} color={color} />
            </div>

    )
}

export default UserInitials
