import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Tab, TabContent, Tabs } from 'react-bootstrap'
import styled, { withTheme } from 'styled-components'
import OrdersList from './OrdersList'
import { withTranslation } from 'react-i18next'
import getThemeValue from '../../utils/getThemeValue'
import { cloneDeep } from 'lodash'

import withRouter from '../../utils/withRouter'




const StyledTabs = styled.div`
  margin-bottom: 20px;

  .nav-item, .nav-item.nav-link.disabled {
    display: inline-block;
    border-color: #dee2e6;
    margin-top:30px;
    margin-left: 2px;
    margin-right: 2px;

    &:first-child{
      margin-left: 0;
    }

    &:last-child{
      margin-right: 0;
    }
  }

  @media screen and (max-width: 600px) {
    .nav-item, .nav-item.nav-link.disabled {
      margin-top: 10px;
    }
  }
`

class OrdersTabs extends Component {

  handleSelect = key => {
    const { ordersStore } = this.props

    var newState = this.props.location.state ? cloneDeep(this.props.location.state) : {}
    newState.selectedStatus = key
    const pathname = ordersStore.targetBusinessPartnerId ? `/employee-orders/${ordersStore.targetBusinessPartnerId}` : '/orders'
    this.props.navigate(pathname, { state: newState })
  }

  getTabTitle(apiObj) {
    const { t } = this.props
    let title = ''
    switch (apiObj.status) {
      case 'open':
        title = t('orders:tabOpen')
        break
      case 'closed':
        title = t('orders:tabClosed')
        break
      case 'cancelled':
        title = t('orders:tabCancelled')
        break
      case 'openApprovals':
        title = t('orders:openApprovalsOrders')
        break
      case 'openTransmissionsSRM':
        title = t('orders:openTransmissionsSRMOrders')
        break
      case 'openApprovalsSRM':
        title = getThemeValue('ordersTabs', 'ordersTabHeader', t('orders:openApprovalsSRMOrders'))
        break
      default:
        title = apiObj.status
        break
    }
    return `${title}${apiObj.count ? ` (${apiObj.count})` : ''}`
  }

  renderTabs() {
    const { ordersStore } = this.props
    return <Tabs activeKey={ordersStore.selectedStatus} onSelect={this.handleSelect} unmountOnExit={true}>
      {ordersStore.incomingOrdersCounter.filter(apiObj => apiObj.count).map(apiObj => (
        <Tab key={apiObj.status} eventKey={apiObj.status} title={this.getTabTitle(apiObj)} />
      ))}
    </Tabs>
  }

  render() {
    return (
      <StyledTabs>
        {this.renderTabs()}
        <TabContent><OrdersList /></TabContent>
      </StyledTabs>
    )
  }
}

export default withTheme(withTranslation()(withRouter(inject('ordersStore')(observer(OrdersTabs)))))
