import React from "react";
import { useState } from "react";
import { Modal, Button, Alert, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import { ButtonWithLoadingState } from "../Buttons";

/* 
    ModalComponent2 can also wait for callbackfunction to then display error or success and does not close automatically
*/

const ModalComponent2 = (props) => {
    const {
        ModalTitle,
        BodyPart1,
        BodyPart2,
        button1Text,
        button1Variant,
        button1Function = () => { },
        button1Loading,
        button1Disabled,
        button2Text,
        button2Variant,
        button2Function = () => { },
        button2Loading,
        button2Disabled,
        error,
        success,
        bodyStyle = null,
        functionCancel = () => { },
        withoutCloseButton,
        backdrop = "static",
        inputType,
        inputLabel,
        inputDefaultValue = "",
        inputRows,
        inputMaxLength,
        inputIsMandatory,
        size,
        closeButtonText,
        children,
        showButton2,
        HeaderIcon,
        centered
    } = props
    const [inputValue, setInputValue] = useState(inputDefaultValue)

    const handleClose = () => {
        functionCancel()
    }
    const { t } = useTranslation()

    return (
        <>
            <Modal style={{ textAlign: "center" }} show={true} onHide={handleClose} backdrop={backdrop} size={size} centered={centered}>
                <Modal.Header closeButton={withoutCloseButton ? false : true}>
                    <Modal.Title >
                        <div className="d-flex align-items-center">
                            {HeaderIcon ? <div className="me-3 mt-1">
                                {HeaderIcon}
                            </div> : null}
                            <div>
                                {ModalTitle}
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${bodyStyle} mb-0`}>{/* alert-danger */}
                    {BodyPart1 ? <>{BodyPart1}<br /><br /></> : null}
                    {BodyPart2}
                    {children}
                    {inputType && <Form.Group className="mb-3">
                        <Form.Label>  {inputLabel}</Form.Label>
                        <Form.Control
                            as={inputType}
                            name="name"
                            onChange={e => { setInputValue(e.target.value) }}
                            value={inputValue}
                            maxLength={inputMaxLength}
                            rows={inputRows}
                        />
                    </Form.Group>}
                    {success ? <Alert variant="success">{success}</Alert> : null}
                    {error ? <Alert variant="danger">{error}</Alert> : null}
                </Modal.Body >
                <Modal.Footer >
                    {button1Text &&
                        <ButtonWithLoadingState
                            disabled={button1Loading || button1Disabled || (inputIsMandatory && !inputValue)}
                            variant={button1Variant} onClick={() => button1Function(inputValue)}
                            isLoading={button1Loading}
                            buttonText={button1Text}
                        />}
                    {showButton2 &&
                        <ButtonWithLoadingState
                            disabled={button2Loading || button2Disabled}
                            variant={button2Variant} onClick={() => button2Function(inputValue)}
                            isLoading={button2Loading}
                            buttonText={button2Text}
                        />
                    }
                    {withoutCloseButton ? null : <Button variant="secondary" onClick={handleClose}>
                        {closeButtonText ? closeButtonText : t('appointment:cancel')}
                    </Button>}
                </Modal.Footer>
            </Modal >
        </>
    );
};

export default ModalComponent2;


/* 
Merge ModalComponent -> ModalComponent2
1)
             <ModalComponent
                ModalTitle={t('orders:rejectOrderHeader')}
                functionButton1={(comment) => { submit('reject', comment) }}
                BodyPart1={t('orders:rejectOrderBody')}
                textButton1={t('reject')}
                variantButton1="danger"
                variantButton2="danger"
                variantBody="alert-danger"
                functionCancel={() => { setReject(false) }}
                withInput
                inputLabel={t('comment')}
             />
2)
            <ModalComponent2
                ModalTitle={t('orders:rejectOrderHeader')}
                BodyPart1={t('orders:rejectOrderBody')}
                button1Function={(comment) => { submit('reject', comment) }}
                button1Text={t('reject')}
                button1Variant="danger"
                button1Loading={isLoading}
                bodyStyle="alert alert-danger"
                functionCancel={() => { setReject(false); setError('') }}
                inputType="textarea"
                inputLabel={t('comment')}
                error={error}
            />
*/