import React from 'react'
import styled, { withTheme } from 'styled-components'
import { colors } from '../../styles/colors'
import { withTranslation } from 'react-i18next'
import VersionButton from './VersionButton'
import getThemeValue from '../../utils/getThemeValue'

const StyledFooter = styled.footer`
    border-top:15px solid ${props => (props.theme.footer && props.theme.footer.login_borderColor) ? props.theme.footer.login_borderColor : 'transparent'};
    height:200px;
    text-align:center;
    width: 100%;
    background-color: ${props => (props.theme.footer && props.theme.footer.login_backgroundColor) ? props.theme.footer.login_backgroundColor : colors.gray5};
    color: ${props => (props.theme.footer && props.theme.footer.login_textColor) ? props.theme.footer.login_textColor : colors.gray4};
    position: fixed;
    z-index: 1;
    bottom: 0;
    display: flex;
    .child--featured {
      padding: 30px 0 30px  30px ;
      flex-grow: 1;
    }
    .modal-first :hover{
      color: #fff;
      text-decoration: none; 
    }
    .modal-first {
      display: inline-block;
      color:  ${colors.gray3};
      cursor: pointer;
      margin: 5px;
  }
`

const StyledFooterExternalLink = styled.a`
  margin: 5px;
  color:  ${colors.gray3};
  color:  ${props => (props.theme.footer && props.theme.footer.login_textColor) ? props.theme.footer.login_textColor : colors.gray3};
  :hover{
    color: #fff;
    text-decoration: none;
  }
`

function Footer(props) {
  const { t, theme } = props

  const mandate = process.env.REACT_APP_MANDATORSET
  const dataProtectionUrl = getThemeValue('footer', 'dataProtectionUrl', '')
  const agbUrl = getThemeValue('footer', 'agbUrl', '')
  const imprintUrl = getThemeValue('footer', 'imprintUrl', '')
  return (
    <StyledFooter>
      <div className="child--featured" >
        {theme.footer.agbUrl || theme.footer.imprintUrl || theme.footer.dataProtectionUrl ?
          <div className='d-flex justify-content-center'>
            {dataProtectionUrl && <StyledFooterExternalLink href={dataProtectionUrl} target="_blank">{t('dataProtection')}</StyledFooterExternalLink>}
            {mandate !== "cws" && agbUrl && <StyledFooterExternalLink href={agbUrl} target="_blank">{t('gtc')}</StyledFooterExternalLink>}
            {imprintUrl && <StyledFooterExternalLink href={imprintUrl} target="_blank">{t('imprint')}</StyledFooterExternalLink>}
          </div>
          :
          null}
        <div className='d-flex justify-content-center'>
          <div>{getThemeValue('footer', 'footerAdditionalSentense', '')}</div>
        </div>
        <div className='d-flex justify-content-center'>
          <div>{theme.footer.footerCopyRight ? theme.footer.footerCopyRight : '© 2022 Rigilog AG'}</div>
        </div>
      </div>
      <VersionButton />
    </StyledFooter >
  )
}

export default withTheme(withTranslation()(Footer))
