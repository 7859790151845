import React, { useCallback, useState } from 'react'
import OverlayRight from '../Page/logix2020/OverlayRight'
import OverlayContainer from '../Page/logix2020/OverlayContainer'
import styled from 'styled-components'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { useTranslation } from 'react-i18next'
import { ButtonWithLoadingState } from '../Buttons'
import OfficeExport from './Exports/OfficeExport'
import CostCenterExport from './Exports/CostCenterExport'
import OrderExport from './Exports/OrderExport'
import { Alert } from 'react-bootstrap'
import MissingUseresAlert from './Form/MissingUseresAlert'
import DownloadURLAlert from '../Forms/DownloadURLAlert'
import EntitlementExport from './Exports/EntitlementExport'
import ReportSubscriptionModal from './Form/ReportSubscriptionModal'
import useReport from '../../utils/hooks/useReport'

const StyledContentWrapper = styled.div`
  padding: 0 25px 65px;
  flex-grow: 1;
  position: relative;

  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }
`

const StyledCreateButtonWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  padding: 0 25px;
  button {
    width: 100%;
  }
`

const CreateNewExportContent = ({ exportName, extraFormData }) => {

    const { t } = useTranslation()
    const [formData, setFormData] = useState({})
    const { createReport, reportIsLoading, reportDownloadUrls, reportErrors, setReportDownloadUrls, reportIsSendByMail, reportSubscriptionId, setReportSubscriptionId } = useReport()
    const [openMissingUsersAlert, setOpenMissingUsersAlert] = useState(false)
    const handleClickCreateExport = useCallback(async () => {
        const preparedFormData = extraFormData ? { ...formData, ...extraFormData } : { ...formData }
        await createReport("reportName",
            APIEndpoints[exportName],
            preparedFormData)
        if (exportName === 'orderExport') {
            setOpenMissingUsersAlert(true)
        }
    }, [createReport, exportName, extraFormData, formData])


    return (
        <>
            <StyledContentWrapper>
                {exportName === 'officeExport' && (
                    <OfficeExport formData={formData} setFormData={setFormData} formErrors={reportErrors?.reportName?.formErrors} />
                )}
                {exportName === 'costcenterExport' && (
                    <CostCenterExport formData={formData} setFormData={setFormData} formErrors={reportErrors?.reportName?.formErrors} />
                )}
                {exportName === 'orderExport' && (
                    <OrderExport formData={formData} setFormData={setFormData} formErrors={reportErrors?.reportName?.formErrors} />
                )}
                {exportName === 'entitlementExport' && (
                    <EntitlementExport formData={formData} setFormData={setFormData} formErrors={reportErrors?.reportName?.formErrors} />
                )}
                {reportErrors?.reportName && (
                    <Alert variant="danger">
                        <strong>{reportErrors.reportName.value}</strong><br />
                    </Alert>
                )}
                {reportDownloadUrls.reportName && <DownloadURLAlert
                    downloadUrl={reportDownloadUrls.reportName}
                    setDownloadUrl={() => setReportDownloadUrls({})}
                    fileName={exportName}
                />}
                {reportIsSendByMail && (
                    <Alert variant="success">
                        {t('reportConfig:isSendByMail')}
                    </Alert>
                )}
                {openMissingUsersAlert && <MissingUseresAlert partnerIDs={extraFormData.partnerIDs} />}
                {reportSubscriptionId && <ReportSubscriptionModal
                    reportSubscriptionId={reportSubscriptionId}
                    setReportSubscriptionId={setReportSubscriptionId}
                    message={t('export:createPending')}
                />}
            </StyledContentWrapper>
            <StyledCreateButtonWrapper>
                <ButtonWithLoadingState isLoading={reportIsLoading.reportName} onClick={handleClickCreateExport}
                    loadingText={`${t('submitButton')}...`}
                    buttonText={t('export:send&download')}
                    disabled={exportName === 'orderExport' && !formData.officeAddressID} />
            </StyledCreateButtonWrapper>
        </>
    )
}

const CreateNewExportOverlay = ({ visible, onClose, ...rest }) => {
    const { t } = useTranslation()

    return (
        <OverlayContainer>
            <OverlayRight visible={visible} onClose={onClose} headerTitle={t('export')}>
                {visible && <CreateNewExportContent {...rest} />}
            </OverlayRight>
        </OverlayContainer>
    )
}

export default CreateNewExportOverlay
