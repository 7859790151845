import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { PageLoadingLayer } from '../components/Loading/PageLoadingLayer'
import MetaInfoBlock from '../components/Orders/MetaInfoBlock'
import PrintQr from '../components/Orders/PrintQr'
import PositionsListWrapper from '../components/Orders/Detail/PositionsListWrapper'
import OrderDialogs from '../components/Orders/Detail/OrderDialogs'
import { Alert } from 'react-bootstrap'
import styled from 'styled-components'
import SupplierOrdersList from '../components/Orders/Detail/SupplierOrdersList'
import ExpectedArrivalsModal from '../components/Orders/Detail/ExpectedArrivalsModal'
import SubheaderOrdersDetail from '../components/Orders/Detail/SubheaderOrdersDetail'
import OrderTemplateName from '../components/Orders/OrderTemplateName'
import { t } from 'i18next'
import { useParams } from 'react-router-dom'

const StyledHeader = styled.div`

display:flex;
align-items: center;
justify-content:space-between;
margin:  1.5rem 0 3rem 0;
flex-wrap: wrap ;
`

const OrdersDetailPage = inject('orderStore')(observer((props) => {
  const { orderStore } = props
  const { incomingOrderId, print } = useParams()


  useEffect(() => {
    orderStore.incomingOrderId = incomingOrderId
    orderStore.loadIncomingOrder()
    orderStore.loadIncomingOrderPositions()
    orderStore.loadShippingOrders()
    orderStore.loadOrderDetailSettings()
    orderStore.loadSupplierOrders()
  }, [incomingOrderId, orderStore])

  if (orderStore.incomingOrderFailed) {
    return <div className='container-fluid'>
      <Alert variant='info' className='mt-5'>{t('order:didNotExists')}</Alert>
    </div>
  }

  if (!orderStore.incomingOrder || !orderStore.incomingOrderPositions || !orderStore.orderDetailSettings || !orderStore.supplierOrders) {
    return <PageLoadingLayer isLoading={true} />
  }

  const { incomingOrder } = orderStore
  const positionsWithoutShippingOrder = orderStore.incomingOrderPositions.filter(position => position.shippingOrders === null) || []
  const shippingOrdersInPreparation = orderStore.shippingOrders ? orderStore.shippingOrders.filter(position => position.status === 'open') || [] : []
  const shippedOrders = orderStore.shippingOrders ? orderStore.shippingOrders.filter(position => position.status === 'closed') || [] : []
  const supplierOrders = orderStore.supplierOrders || []

  return (
    <>
      <SubheaderOrdersDetail order={incomingOrder} print={print} />
      <div className='container-fluid'>
        <StyledHeader>
          {incomingOrder.status === "template" && <OrderTemplateName order={incomingOrder} />}
          <MetaInfoBlock order={incomingOrder} />
        </StyledHeader>
        {print && <PrintQr order={incomingOrder} />}
        {positionsWithoutShippingOrder.length
          ? <PositionsListWrapper incomingOrder={incomingOrder} positions={positionsWithoutShippingOrder}
            title={t('order:orderedProducts')} />
          : ''}
        {shippingOrdersInPreparation.length
          ? <PositionsListWrapper incomingOrder={incomingOrder} orders={shippingOrdersInPreparation}
            title={t('order:shippingOrdersInPreparation')} />
          : ''}
        {shippedOrders.length
          ? <PositionsListWrapper incomingOrder={incomingOrder} orders={shippedOrders} title={t('order:shippedOrders')} />
          : ''}
        {supplierOrders.length
          ? <SupplierOrdersList incomingOrderId={incomingOrderId} supplierOrders={supplierOrders} />
          : ''}
      </div>
      <OrderDialogs incomingOrderId={incomingOrderId} />
      {orderStore.openExpectedArrivalsModal ? <ExpectedArrivalsModal /> : null}
    </>
  )
}))

export default OrdersDetailPage
