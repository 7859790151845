import React from 'react'
import { useTableState } from '../../Table/TableProvider'
import UniversalTableWrapperTest from '../../Table/UniversalTableWrapperTest'
import BootstrapTable from 'react-bootstrap-table-next'
import { DeleteIcon } from '../../Icons/DeleteIcon'
import { t } from 'i18next'
import cellEditFactory from 'react-bootstrap-table2-editor'

const OCITextTable = ({ handleAfterSaveCell, tableIsLoading, setDeleteParameterRow, ociTextTypes }) => {
  const { tableRef } = useTableState()
  const columnsTexts = [
    {
      dataField: 'name',
      text: t('name'),
      sort: true,
      classes: 'cursor-pointer'
    },
    {
      dataField: 'value',
      text: t('value'),
      sort: true,
      classes: 'cursor-pointer'
    },
    {
      isDummyField: true,
      dataField: 'isDummyField',
      text: '',
      sort: false,
      headerClasses: 'checkBox-header-cell',
      classes: 'checkBox-cell',
      editable: (cell, row, rowIndex, colIndex) => {
        return false;
      },
      formatter: (cell, row, rowIndex) => (
        <span role="button" onClick={() => setDeleteParameterRow(row)}>
          <DeleteIcon title={t('delete')} />
        </span>
      )
    }
  ]
  return (
    <UniversalTableWrapperTest tableIsLoading={tableIsLoading}>
      <BootstrapTable
        ref={tableRef}
        noDataIndication={() => <div className="no-data-placeholder">{!tableIsLoading && t('catalogsAdministration:noFreeTextTypesAvailable')}</div>}
        loading={tableIsLoading}
        bootstrap4
        keyField="id"
        hover
        data={ociTextTypes}
        columns={columnsTexts}
        cellEdit={cellEditFactory({ mode: "click", blurToSave: true, afterSaveCell: handleAfterSaveCell })}
        bordered={false}
      />
    </UniversalTableWrapperTest>
  )
}

export default OCITextTable