import React, { useCallback, useEffect, useState } from 'react'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { PenIcon } from '../components/Icons/PenIcon'
import { t } from 'i18next'
import useStores from './useStore'
import usePrevious from '../utils/hooks/usePrevious'

const PointsChangesTableContext = React.createContext({})
export function usePointsChangesState() {
  return React.useContext(PointsChangesTableContext)
}

// Dashboard Inventory bookings approval Table
const PointsChangesTableProvider = ({ children }) => {
  const [tableIsLoading, setTableIsLoading] = useState(false)
  const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([])
  const [incomingOrders, setIncomingOrders] = useState([])
  const [pageOffset, setPageOffset] = useState(0)
  const [pageLimit, setPageLimit] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [allSelected, setAllSelected] = useState(false)
  const [allSelectedLoading, setAllSelectedLoading] = useState(false)
  const { uiStore } = useStores()
  const prevTableProps = usePrevious({ pageLimit, pageOffset })

  const fetchPointsChanges = useCallback(() => {
    setTableIsLoading(true)
    const params = {
      'filter.status': 'open',
      'page.limit': pageLimit,
      'page.offset': pageOffset,
      'filter.showAllRequests': uiStore.allowReleasePointsChanges ? true : false
    }
    apiClient.getJson(APIEndpoints.pointsChangeRequest.get, params, true, true).then(response => {
      const result = response.data
      setTotalCount(response.headers['x-total-count'] ? parseInt(response.headers['x-total-count']) : 0)
      const preparedResult = result.map(item => {

        return {
          ...item, actions: (
            <span>
              <span>
                <PenIcon title={t('edit')} className="pen-icon" color="#000000" />
              </span>
            </span>
          ),
          checkBox: (
            <span>
              <input type="checkbox" id={item.id} name="scales"
                checked={selectedCheckBoxes.includes(item.id)} onChange={() => { }}>
              </input>
            </span>
          )
        }
      })
      setIncomingOrders(preparedResult)
    }).finally(() => {
      setTableIsLoading(false)
    })
  }, [pageLimit, pageOffset, selectedCheckBoxes, uiStore.allowReleasePointsChanges])

  useEffect(() => {
    if (prevTableProps?.pageLimit !== pageLimit || prevTableProps?.pageOffset !== pageOffset) {
      fetchPointsChanges()
    }
  }, [fetchPointsChanges, pageLimit, pageOffset, prevTableProps])

  const onRowClick = (cell, row) => {
    if (allSelected) {
      setAllSelected(false)
    }
    if (selectedCheckBoxes.includes(row.id)) {
      var index = selectedCheckBoxes.indexOf(row.id);
      var newValues = [...selectedCheckBoxes]
      newValues.splice(index, 1);
      setSelectedCheckBoxes(newValues)
    }
    else {
      setSelectedCheckBoxes(prev => [...prev, row.id])
    }
  }

  const selectAll = () => {
    if (allSelected) {
      setAllSelected(false)
      setSelectedCheckBoxes([])
    }
    else {
      setAllSelectedLoading(true)
      const params = {
        'filter.status': 'open',
        'filter.showAllRequests': uiStore.allowReleasePointsChanges ? true : false
      }
      apiClient.getJson(APIEndpoints.pointsChangeRequest.get, params).then(response => {
        if (response) {
          const arrayOfAllIds = response.map(obj => obj.id)
          setSelectedCheckBoxes(arrayOfAllIds)
        }
      }).finally(() => { setAllSelected(true); setAllSelectedLoading(false) })
    }
  }

  useEffect(() => {
    setIncomingOrders(prev => prev.map(item => ({
      ...item,
      checkBox: (
        <span>
          <input type="checkbox" id={item.id} name="scales"
            checked={selectedCheckBoxes.includes(item.id)} onChange={() => { }}>
          </input>
        </span>
      )
    })))
  }, [selectedCheckBoxes])

  const contextValues = {
    tableIsLoading,
    incomingOrders,
    onRowClick,
    pageOffset,
    setPageOffset,
    pageLimit,
    setPageLimit,
    totalCount,
    setTotalCount,
    selectedCheckBoxes,
    setSelectedCheckBoxes,
    fetchPointsChanges,
    setTableIsLoading,
    allSelectedLoading, selectAll, allSelected
  }
  return (
    <PointsChangesTableContext.Provider value={contextValues}>
      {children}
    </PointsChangesTableContext.Provider>
  )
}

export default PointsChangesTableProvider
