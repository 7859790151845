import React, { useState } from 'react'
import { Alert, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useEntitlementPhasesState } from '../../../stores/Configuration/EntitlementPhasesProvider'
import { ButtonWithLoadingState } from '../../Buttons'
import LanguageForm from '../../Forms/LanguageForm'
import LanguageNameInput from '../../Forms/LanguageNameInput'
import OverlayContainer from '../../Page/logix2020/OverlayContainer'
import OverlayRight from '../../Page/logix2020/OverlayRight'


const StyledDiv = styled.div`
  width: 600px;
  .alert {
    margin-top: 1.5rem;
  }
`


const EntitlementPhaseGeneralForm = ({ entitlementPhaseId }) => {
  const { t } = useTranslation()
  const { entitlementPhase, setEntitlementPhase, loadingStates, successStates, errorStates, patchEntitlementPhase, postEntitlementPhase } = useEntitlementPhasesState()
  const [overlayVisible, setOverlayVisible] = useState()

  const updateNames = (translations) => {
    setEntitlementPhase(prev => ({
      ...prev,
      name: {
        values: translations
      }
    }))
  }

  const handleSubmit = () => {
    entitlementPhaseId ? patchEntitlementPhase(entitlementPhaseId) : postEntitlementPhase()
  }

  const handleCloseOverlay = () => {
    setOverlayVisible(false)
  }

  const updateCheckboxProperty = (name, value) => {
    setEntitlementPhase(prev => ({
      ...prev,
      [name]: value
    }))
  }

  return (
    <StyledDiv>
      <LanguageNameInput
        openLanguageModal={() => setOverlayVisible(true)}
        languageArray={entitlementPhase.name.values}
        onChange={updateNames}
        label={t('name')}
        isInvalid={false}
      />
      <div className="my-3">
        <Form.Check
          type="checkbox"
          id="mergeContingents"
          label={t('entitlementPhases:joinEntitlements')}
          checked={entitlementPhase.joinEntitlements}
          onChange={(e) => updateCheckboxProperty('joinEntitlements', e.target.checked)}
        />
        <Form.Check
          type="checkbox"
          id="controlsPoint"
          label={t('entitlementPhases:pointDriven')}
          checked={entitlementPhase.isPointDriven}
          onChange={(e) => updateCheckboxProperty('isPointDriven', e.target.checked)}
        />
        <Form.Check
          type="checkbox"
          id="alternative"
          label={t('entitlementPhases:alternative')}
          checked={entitlementPhase.isBoundToSingleEntitlement}
          onChange={(e) => updateCheckboxProperty('isBoundToSingleEntitlement', e.target.checked)}
        />
      </div>
      <ButtonWithLoadingState
        buttonText={t('saveButton')}
        isLoading={loadingStates.saveEntitlementPhase}
        onClick={handleSubmit}
      />
      <OverlayContainer>
        <OverlayRight visible={overlayVisible} onClose={handleCloseOverlay}>
          <LanguageForm
            languageTitle={t('name')}
            handleCloseOverlay={handleCloseOverlay}
            languageArray={entitlementPhase.name.values}
            onChange={updateNames}
          />
        </OverlayRight>
      </OverlayContainer>
      {successStates.saveEntitlementPhase &&
        <Alert variant="success" className="my-2">
          {t('registrationBox:savingSuccess')}
        </Alert>
      }
      {errorStates.saveEntitlementPhase &&
        <Alert variant="danger">{errorStates.saveEntitlementPhase.value}</Alert>
      }
    </StyledDiv>
  )
}

export default EntitlementPhaseGeneralForm