import React, { useCallback, useEffect, useState, memo } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import CostCenterSelect from '../FormComponents/CostCenterSelect'
import OfficeSelect from '../FormComponents/OfficeSelect'
import { emptyDate } from '../../../../utils/emptyDate'
import CustomDatePicker from '../../../Forms/CustomDatePicker'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { emptyGuid } from '../../../../utils/emptyGuid'
import { StyledValidationMessage } from '../formHelper'
import ArraySelect from '../../../Select/ArraySelect'
import MandatoryField from '../../../MandatoryField/MandatoryField'
import { useParams } from 'react-router-dom'
import useStores from '../../../../stores/useStore'
import { useObserver } from 'mobx-react'
import LaundryShelfSelect from '../FormComponents/LaundryShelfSelect'
import LaundryShelfInput from '../FormComponents/LaundryShelfInput'
import LaundryShelfTreeButton from '../FormComponents/LaundryShelfTreeButton'
import apiClient from '../../../../utils/api/apiClient'
import { APIEndpoints } from '../../../../utils/api/apiConfig'
import { getIsoDate } from '../../../../utils/getIsoDate'

const StyledWrapper = styled.div`
  .form-label {
    white-space: nowrap;
  }
`

const MainData = memo(({
  mainData,
  setMainDataByKey,
  formErrors,
  resetErrorByFormKey
}) => {

  const { employeeId } = useParams()
  const { uiStore, costCenterStore } = useStores()
  //one of write,read,hide
  const laundryShelfStatus = useObserver(() => uiStore.laundryShelf_Status)
  const orderPhaseStatus = useObserver(() => uiStore.orderPhase_Status)
  //one of text,list,tree
  const selector = useObserver(() => uiStore.laundryShelf_Selector)
  const [dateStartEmployment, setDateStartEmployment] = useState('')
  const [dateExitEmployment, setDateExitEmployment] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [availableLanguages, setAvailableLanguages] = useState([])
  const [availableOrderPhases, setAvailableOrderPhases] = useState([])

  useEffect(() => {
    //load settings data
    (async () => {
      const languages = await apiClient.getJson(APIEndpoints.languages)
      setAvailableLanguages(languages)
      const orderPhases = await apiClient.getJson(APIEndpoints.entitlementPhases)
      setAvailableOrderPhases(orderPhases)
    })()
  }, [])

  const setDateWithString = useCallback((setDate, dateISOString) => {
    if (dateISOString && dateISOString !== emptyDate) {
      setDate(new Date(dateISOString))
    } else {
      setDate('')
    }
  }, [])

  useEffect(() => {
    setDateWithString(setDateStartEmployment, mainData.dateStartEmployment)
  }, [mainData.dateStartEmployment, setDateWithString])

  useEffect(() => {
    setDateWithString(setDateExitEmployment, mainData.dateExitEmployment)
  }, [mainData.dateExitEmployment, setDateWithString])

  useEffect(() => {
    setDateWithString(setBirthDate, mainData.birthDate)
  }, [mainData.birthDate, setDateWithString])

  const handleChangeCostCenter = (costCenter) => {
    setMainDataByKey('costCenter.id', costCenter.id)
    resetErrorByFormKey('costCenter')
  }

  const handleChangeOffice = (value) => {
    setMainDataByKey('office.id', value)
    autoChangeCostCenter(value)
    resetErrorByFormKey('office')
  }

  const autoChangeCostCenter = async (value) => {
    setMainDataByKey('costCenter.id', emptyGuid)
    const filteredCostCenters = await costCenterStore.getFilteredCostCenters('', value)
    if (filteredCostCenters.length === 1) {
      setMainDataByKey('costCenter.id', filteredCostCenters[0].id)
    }
    else {
      setMainDataByKey('costCenter.id', emptyGuid)
    }
  }

  const handleChangeDate = name => date => {
    setMainDataByKey(name, (date && getIsoDate(date)) || '')
    resetErrorByFormKey(name)
  }
  const handleCange = (e) => {
    const { name, value } = e.target
    setMainDataByKey(name, value)
    resetErrorByFormKey(name)
  }
  const { t } = useTranslation()
  return (
    <>
      <h2 className="h1">{t('EmployeeFormMainData:employeeForm')}</h2>
      <StyledWrapper className="spacing-down">
        <Row>
          <Col lg="3" sm="6" xs="12">
            <Form.Group className="mb-3">
              <Form.Label>{t('EmployeeFormMainData:firstName')} *</Form.Label>
              <div>
                <Form.Control type="text" placeholder={t('EmployeeFormMainData:firstName')} name="firstName" onChange={handleCange} value={mainData.firstName} isInvalid={!!formErrors['firstName']} />
                <Form.Control.Feedback type="invalid">
                  {formErrors['firstName'] && formErrors['firstName'].msg}
                </Form.Control.Feedback>
              </div>
            </Form.Group >
          </Col>

          <Col sm="6" lg="3">
            <Form.Group className="mb-3">
              <Form.Label>{t('EmployeeFormMainData:lastName')} *</Form.Label>
              <Form.Control type="text" placeholder={t('EmployeeFormMainData:lastName')} name="lastName" onChange={handleCange} value={mainData.lastName} isInvalid={!!formErrors['lastName']} />
              <Form.Control.Feedback type="invalid">
                {formErrors['lastName'] && formErrors['lastName'].msg}
              </Form.Control.Feedback>

            </Form.Group>
          </Col>
          <Col sm="6" lg="3">
            <Form.Group className="mb-3">
              <Form.Label>{t('EmployeeFormMainData:title')}</Form.Label>
              <ArraySelect
                name="title"
                onChange={handleCange}
                value={mainData.title}
                isInvalid={!!formErrors['title']}
                returnEvent
                options={[
                  { id: "Dr.", displayName: "Dr." },
                  { id: "Prof.", displayName: "Prof." },
                  { id: "Prof. Dr.", displayName: "Prof. Dr." },
                  { id: "Ing.", displayName: "Ing." },
                  { id: "Dipl. Ing.", displayName: "Dipl. Ing." },
                  { id: "Mag.", displayName: "Mag." },
                ]}
                defaultOptionIsSelectable
              />
            </Form.Group>
          </Col>
          <Col sm="6" lg="3">
            <Form.Group className="mb-3">
              <Form.Label>{t('EmployeeFormMainData:gender')} *</Form.Label>
              <ArraySelect
                options={[{ id: "male", displayName: t('EmployeeFormMainData:male') }, { id: "female", displayName: t('EmployeeFormMainData:female') }]}
                name="gender"
                onChange={handleCange}
                value={mainData.gender}
                isInvalid={!!formErrors['gender']}
                returnEvent
              />
              <Form.Control.Feedback type="invalid">
                {formErrors['gender'] && formErrors['gender'].msg}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm="6" lg="3">
            <Form.Group className="mb-3">
              <Form.Label>{t('EmployeeFormMainData:personalNumber1')} *</Form.Label>
              <Form.Control type="text" placeholder={t('EmployeeFormMainData:personalNumber1')} name="personalNr" onChange={handleCange} value={mainData.personalNr} isInvalid={!!formErrors['personalNr']} />
              <Form.Control.Feedback type="invalid">
                {formErrors['personalNr'] && formErrors['personalNr'].msg}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm="6" lg="3">
            <Form.Group className="mb-3">
              <Form.Label>{t('EmployeeFormMainData:personalNumber2')}</Form.Label>
              <Form.Control type="text" placeholder={t('EmployeeFormMainData:personalNumber2')} name="personalNr2" onChange={handleCange} value={mainData.personalNr2} isInvalid={!!formErrors['personalNr2']} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="6" lg="3">
            <Form.Group className="mb-3">
              <Form.Label>{t('EmployeeFormMainData:entranceDate')} *</Form.Label>
              <CustomDatePicker
                dateFormat="dd.MM.yyyy"
                placeholderText={t('date')}
                onChange={handleChangeDate('dateStartEmployment')}
                showYearDropdown
                selected={dateStartEmployment}
                isInvalid={!!formErrors['dateStartEmployment']}
              />
              {!!formErrors['dateStartEmployment'] &&
                <StyledValidationMessage>{formErrors['dateStartEmployment'].msg}</StyledValidationMessage>
              }
            </Form.Group>
          </Col>
          <Col sm="6" lg="3">
            <Form.Group className="mb-3">
              <Form.Label>{t('EmployeeFormMainData:exitDate')}</Form.Label>
              <CustomDatePicker
                dateFormat="dd.MM.yyyy"
                placeholderText={t('date')}
                onChange={handleChangeDate('dateExitEmployment')}
                parseISO
                showYearDropdown
                selected={dateExitEmployment}
                isInvalid={!!formErrors['dateExitEmployment']}
              />
              {!!formErrors['dateExitEmployment'] &&
                <StyledValidationMessage>{formErrors['dateExitEmployment'].msg}</StyledValidationMessage>
              }
            </Form.Group>
          </Col>
          <Col sm="6" lg="3">
            <Form.Group className="mb-3">
              <Form.Label>{t('EmployeeFormMainData:birthdate')}</Form.Label>
              <CustomDatePicker
                dateFormat="dd.MM.yyyy"
                placeholderText={t('date')}
                onChange={handleChangeDate('birthDate')}
                selected={birthDate}
                showYearDropdown
                isInvalid={!!formErrors['birthDate']}
              />
              {!!formErrors['birthDate'] &&
                <StyledValidationMessage>{formErrors['birthDate'].msg}</StyledValidationMessage>
              }
            </Form.Group>
          </Col>
          <Col sm="6" lg="3">
            <Form.Group className="mb-3">
              <Form.Label>{t('EmployeeFormMainData:language')}</Form.Label>
              <ArraySelect
                returnEvent
                options={availableLanguages}
                defaultOption={{ name: t('pleaseSelect'), value: emptyGuid }}
                defaultOptionIsSelectable
                name="language.id"
                onChange={handleCange}
                value={mainData.language.id}
                isInvalid={!!formErrors['language.id']}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors['language.id'] && formErrors['language.id'].msg}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm="6" >
            <OfficeSelect id="EmployeeFormMainDataOfficeTree"
              label={`${t('EmployeeFormMainData:mainOffice')} *`}
              errorMessage={formErrors['office'] && formErrors['office'].msg}
              onChange={handleChangeOffice}
              value={mainData.office.id}
              isInvalid={!!formErrors['office']}
              withSearch
            />
          </Col>
          <Col sm="6">
            <CostCenterSelect id="EmployeeFormMainDataConstCenterTree"
              label={`${t('EmployeeFormMainData:mainCostcenter')} *`}
              errorMessage={formErrors['costCenter'] && formErrors['costCenter'].msg}
              onChange={handleChangeCostCenter}
              value={mainData.costCenter.id}
              isInvalid={formErrors['costCenter']}
              withSearch
              filterMainOfficeId={mainData.office.id !== emptyGuid ? mainData.office.id : ''}
            />
          </Col>
          <Col sm="6">
            {selector === "list" ? <LaundryShelfSelect status={laundryShelfStatus} /> : null}
            {selector === "text" ? <LaundryShelfInput status={laundryShelfStatus} /> : null}
            {selector === "tree" ? <LaundryShelfTreeButton status={laundryShelfStatus} employeeId={employeeId} /> : null}
          </Col>
          {orderPhaseStatus && orderPhaseStatus !== 'hide' ?
            <Col sm="6">
              <Form.Group className="mb-3">
                <Form.Label>{t('EmployeeFormMainData:orderPhase')}</Form.Label>
                <ArraySelect
                  isInvalid={formErrors['orderPhase']}
                  returnEvent
                  options={availableOrderPhases}
                  defaultOption={{ name: t('pleaseSelect'), value: emptyGuid }}
                  defaultOptionIsSelectable={true}
                  name="orderPhase.id"
                  onChange={handleCange}
                  value={mainData.orderPhase.id}
                  disabled={orderPhaseStatus === 'read' ? true : false}
                />
              </Form.Group>
            </Col> : null
          }
        </Row>
        <MandatoryField />
      </StyledWrapper>
    </>
  )
})

export default MainData
