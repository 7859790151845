import React, { useState, useEffect, useCallback } from 'react'
import { Subheader } from '../Page/logix2020/Subheader'
import SubheaderForm from '../Page/logix2020/SubheaderForm'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getEmployee } from '../../utils/employeeLoader'


const SubheaderInventoryAction = () => {
    const { t } = useTranslation()
    const { actionType, targetId } = useParams()
    const [name, setName] = useState('')

    const getSecondPart = useCallback(async () => {
        if (!targetId) {
            return ''
        }
        if (actionType === "articleTransfer") {
            const target = await getEmployee(targetId)
            return `: ${target.displayName}`
        }
        return ''
    }, [actionType, targetId])

    useEffect(() => {
        const init = async () => {
            let firstPart = ""
            switch (actionType) {
                case 'inventoryBookings':
                    firstPart = t('inventory:inventoryBooking')
                    break;
                case 'returnBookings':
                    firstPart = t('inventory:returnBookings')
                    break;
                case 'articleTransfer':
                    firstPart = t('inventory:articleTransfer')
                    break;
                default:
            }
            const secondPart = await getSecondPart()
            setName(`${firstPart}${secondPart}`)
        }
        init()
    }, [actionType, getSecondPart, t]);


    return (
        <Subheader className="subHeader-v1 ">
            <SubheaderForm title={name} goBackLabel={t('backButton')} />
        </Subheader>
    )
}

export default SubheaderInventoryAction
