import { observable, reaction, makeObservable } from 'mobx';
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { getErrorMessageFromApiResponse } from '../utils/api/apiUtils'
import { getEmployee } from '../utils/employeeLoader'
import axios from 'axios'
const CancelToken = axios.CancelToken;
var cancelLoadIncomingOrdersFunction;
var cancelLoadIncomingOrdersCounterFunction;
class OrdersStore {
  incomingOrders = [];
  incomingOrdersCounter = [];
  incomingOrdersIsLoading = false;
  incomingOrdersCounterIsLoading = false;
  targetCostcenterId = '';
  selectedStatus = 'open';
  searchText = '';
  articleSearchKeys = [];
  showAllOrders = false;
  lastMonths = 3;
  limit = 20;
  offset = 0;
  partnerSearchKeys = [];
  _targetCostcenterId = ''
  _selectedStatus = 'open'
  _searchText = ''
  _articleSearchKeys = []
  _showAllOrders = false
  _lastMonths = 3
  _limit = 20
  _offset = 0
  _partnerSearchKeys = []

  incomingOrdersError = null;
  totalCount = 0;
  targetBusinessPartnerId = '';
  targetBusinessPartner = {};

  constructor(appStore) {
    makeObservable(this, {
      incomingOrders: observable,
      incomingOrdersCounter: observable,
      incomingOrdersIsLoading: observable,
      incomingOrdersCounterIsLoading: observable,
      targetCostcenterId: observable,
      selectedStatus: observable,
      searchText: observable,
      articleSearchKeys: observable,
      showAllOrders: observable,
      lastMonths: observable,
      limit: observable,
      offset: observable,
      partnerSearchKeys: observable,
      incomingOrdersError: observable,
      totalCount: observable,
      targetBusinessPartnerId: observable,
      targetBusinessPartner: observable
    });

    this.app = appStore
  }
  cancelLoadIncomingOrders() {
    //cancel previous calls that are still pending
    cancelLoadIncomingOrdersFunction && cancelLoadIncomingOrdersFunction()
  }

  async loadIncomingOrders({ append } = { append: false }) {
    this.cancelLoadIncomingOrders()
    const cancelToken = new CancelToken(function executor(c) {
      cancelLoadIncomingOrdersFunction = c;
    })
    this.incomingOrdersIsLoading = true
    this.incomingOrdersError = null
    if (!append) {
      this.incomingOrders = []
    }
    const params = this.getParamsForIncommingOrdersRequest()
    try {
      const response = await apiClient.getJson(APIEndpoints.incomingOrders().incomingOrders, params, true, true, true, { cancelToken })
      this.incomingOrders = append ? this.incomingOrders.concat(response.data) : response.data
      this.totalCount = response.headers['x-total-count'] ? parseInt(response.headers['x-total-count']) : 0
      this.incomingOrdersIsLoading = false
      this.offset = this.offset + this.limit
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log("cancelled by cancelToken")
      }
      else {
        this.incomingOrdersError = getErrorMessageFromApiResponse(e)
        this.incomingOrdersIsLoading = false
      }
    }

  }

  getParamsForIncommingOrdersRequest(withPagination = true) {
    let params = withPagination ? {
      'page.limit': this.limit,
      'page.offset': this.offset
    } : {}

    params = {
      ...params,
      'filter.status': this.selectedStatus,
      'filter.lastMonths': this.lastMonths,
      'filter.searchText': this.searchText ? this.searchText : null,
      'filter.articleSearchKeys': this.articleSearchKeys.length ? this.articleSearchKeys : null,
      'filter.showAllOrders': this.showAllOrders ? this.showAllOrders : null,
      'filter.targetCostcenterId': this.targetCostcenterId ? this.targetCostcenterId : null,
      'filter.partnerSearchKeys': this.partnerSearchKeys.length ? this.partnerSearchKeys : null
    }

    if (this.targetBusinessPartnerId) {
      params['filter.targetBusinesspartnerId'] = this.targetBusinessPartnerId
    }

    return params
  }
  cancelLoadIncomingOrdersCounter() {
    //cancel previous calls that are still pending
    cancelLoadIncomingOrdersCounterFunction && cancelLoadIncomingOrdersCounterFunction()
  }
  async loadIncomingOrdersCounter() {
    this.cancelLoadIncomingOrdersCounter()
    const cancelToken = new CancelToken(function executor(c) {
      cancelLoadIncomingOrdersCounterFunction = c;
    })
    this.incomingOrdersCounterIsLoading = true
    const params = this.getParamsForIncommingOrdersRequest(false)
    this.incomingOrdersCounter = await apiClient.getJson(APIEndpoints.incomingOrdersCounter, params, true, false, true, { cancelToken })
    this.incomingOrdersCounterIsLoading = false


  }

  resetStateReaction = reaction(
    () => this.targetBusinessPartnerId,
    targetBusinessPartnerId => {
      this.resetState()
      this.targetBusinessPartner = {}
      if (this.targetBusinessPartnerId) {
        getEmployee(this.targetBusinessPartnerId).then(response => { this.targetBusinessPartner = response })
      }
    }
  )

  resetState() {
    this.incomingOrdersCounter = []
    this.incomingOrders = []
    this.selectedStatus = 'open'
    this.incomingOrdersError = null
    this.searchText = ''
    this.lastMonths = 3
    this.offset = 0
    this.totalCount = 0
    this.showAllOrders = false
    this.targetCostcenterId = ''
  }
  updateState(state) {
    this.selectedStatus = state.selectedStatus || this._selectedStatus
    this.lastMonths = state.lastMonths != null ? state.lastMonths : this._lastMonths
    this.showAllOrders = state.showAllOrders || this._showAllOrders
    this.targetCostcenterId = state.targetCostcenterId || this.__targetCostcenterId
    this.searchText = state.searchText || this._searchText
    this.articleSearchKeys = state.articleSearchKeys || this._articleSearchKeys
    this.partnerSearchKeys = state.partnerSearchKeys || this._partnerSearchKeys
    this.limit = state.limit || this._limit
    this.offset = state.offset || this._offset
  }
  async reorder(id) {
    const shoppingBasket = await apiClient.postJson(APIEndpoints.reorders(id))
    return shoppingBasket
  }

}

export default OrdersStore
