import React from 'react'

export const ShowPW = ({ color, ...rest }) => {
  const colorNew = color ? color : 'currentColor'
  return (
    <svg className="st0" strokeWidth="0" viewBox='0 0 16 16' height="22px" width="22px" {...rest}>
      <style type="text/css">
        {`
          .st0{fill:${colorNew};stroke:${colorNew};}
        `}
      </style>
      <rect width="100%" height="100%" x="0" y="0" fill="none" stroke="none" />
      <g  >
        <path fillRule="evenodd" clipRule="evenodd" d="M1,8.269896149635315 c0,-3.9 3.1,-7 7,-7 s7,3.1 7,7 h-1 c0,-3.3 -2.7,-6 -6,-6 s-6,2.7 -6,6 H1 zm4,0 c0,-1.7000000000000002 1.3,-3 3,-3 s3,1.3 3,3 s-1.3,3 -3,3 s-3,-1.3 -3,-3 zm1,0 c0,1.1 0.9,2 2,2 s2,-0.9 2,-2 s-0.9,-2 -2,-2 s-2,0.9 -2,2 z" id="svg_1"  >
        </path></g></svg>
  )
}