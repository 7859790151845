import React from 'react'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import SubheaderTitle from '../../components/Page/logix2020/SubheaderTitle'
import OfficeGeneralForm from '../../components/Configuration/Office/OfficeGeneralForm'
import { useTranslation } from 'react-i18next'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import i18n from '../../translation/i18n'
import SidebarNavigation from '../../components/Page/logix2020/SidebarNavigation'

const navigationLeft = () => [
  {
    key: 'general',
    to: '#general',
    name: i18n.t("costCenterAdmin:general"),
    disabled: false

  },
  {
    key: 'addresses',
    to: '#addresses',
    name: i18n.t("costCenterAdmin:addresses"),
    disabled: true

  },
  {
    key: 'access-rights',
    to: '#access-rights',
    name: i18n.t("costCenterAdmin:accessRights"),
    disabled: true

  }
]

const CreateNewOfficePage = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Subheader className="subHeader-v1">
        <SubheaderTitle
          withBackButton
          smallTitle={t('officeAdmin:createNewButton')}
          goBackLabel={t('officeAdmin:goBackOffice')}
          defaultBackTo="/officeadministration"
        />
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <SidebarNavigation options={navigationLeft()} active='general' />
        </SidebarLeft>
        <ContentArea>
          <OfficeGeneralForm mode="create" />
        </ContentArea>
      </FlexWrapper>
      <BigFooter />
    </div >
  )
}

export default CreateNewOfficePage
