import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import SubheaderTitle from '../../components/Page/logix2020/SubheaderTitle'
import { useTranslation } from 'react-i18next'
import GeneralForm from '../../components/Configuration/AppointmentType/GeneralForm'
import TimesGeneratorForm from '../../components/Configuration/AppointmentType/TimesGeneratorForm'
import AppointmentTypeAssignedCabinsForm from '../../components/Configuration/AppointmentType/AssignedCabinsForm'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import i18n from '../../translation/i18n'
import SidebarNavigation from '../../components/Page/logix2020/SidebarNavigation'
import AppointmentTypesProvider from '../../stores/Configuration/AppointmentTypesProvider'


const navigationLeft = () => [
    {
        key: 'general',
        to: '#general',
        name: i18n.t("costCenterAdmin:general"),
        disabled: false
    },
    {
        key: 'timesGenerator',
        to: '#timesGenerator',
        name: i18n.t("cabinTypes:timesGenerator"),
        disabled: false
    },
    {
        key: 'assignedCabins',
        to: '#assignedCabins',
        name: i18n.t("cabinTypes:cabinAssignment"),
        disabled: false
    }
]

const EditAppointmentTypePage = () => {
    const { t } = useTranslation()
    let currentLocation = useLocation()
    const [activeNav, setActiveNav] = useState('')
    useEffect(() => {
        if (currentLocation.hash) {
            setActiveNav(currentLocation.hash.substr(1))
        }
        else { setActiveNav('general') }
    }, [currentLocation.hash])



    return (
        <div>
            <Subheader className="subHeader-v1">
                <SubheaderTitle
                    withBackButton
                    smallTitle={t('appointment:appointmentEditTitle')}
                    goBackLabel={t('appointment:goBackAppointment')}
                    defaultBackTo="/appointmenttypes"
                />
            </Subheader>
            <FlexWrapper>
                <SidebarLeft>
                    <SidebarNavigation options={navigationLeft()} active={activeNav} />
                </SidebarLeft>
                <ContentArea>
                    <AppointmentTypesProvider>
                        {activeNav === 'general' && <GeneralForm />}
                        {activeNav === 'timesGenerator' && <TimesGeneratorForm />}
                        {activeNav === 'assignedCabins' && <AppointmentTypeAssignedCabinsForm />}
                    </AppointmentTypesProvider>
                </ContentArea>
            </FlexWrapper>
            <BigFooter />
        </div >
    )
}

export default EditAppointmentTypePage