import React, { Component } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { StyledCard } from '../Cards/StyledCard'
import { Col, Form } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import ArraySelect from '../Select/ArraySelect'
import { getFakeEvent } from '../../utils/utils'
import ZipSuggestion from '../Forms/ZipSuggestion'

const StyledAddressCard = styled.div`
  width: 100%;
  padding: 10px;
  
  @media screen and (min-width: 768px) {
    width: 50%;
    display: flex;
    
    > div {
      flex-grow: 1;
    }
  }
`

const StyledCustomAddressCard = styled(StyledAddressCard)`
  @media screen and (min-width: 768px) {
    width: 100%;
  }
`

const StyledTitle = styled.span`
  letter-spacing: 0.8px;
`

const StyledCWSForm = styled.div`
  input {
    outline: none;
    border:none;
    border-bottom:1px solid black;
    border-radius:0;
  }
  select {
    outline: none;
    border:none;
    border-bottom:1px solid black;
    border-radius:0;
  }

`

class CustomBillingAddress extends Component {

  handleCustomBillingAddressCardClick() {
    this.props.productsStore.setCustomBillingAddressSelected(true)
  }
  renderBillingAddressForm() {
    const { t, productsStore, checkoutStore } = this.props
    const { customBillingAddress } = productsStore
    return (
      <Form name='customBillingAddress'>
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:company')}<span className='text-primary'></span></Form.Label>
          <Form.Control type='text' placeholder={t('formularFields:company')}
            value={customBillingAddress.officeName} name='officeName'
            onChange={productsStore.handleChangeCustomBillingAddress}
            isInvalid={productsStore.requiredFieldsError.customBillingAddress.officeName} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:firstname')} <span className='text-primary'>*</span></Form.Label>
          <Form.Control type='text' placeholder={t('formularFields:firstname')}
            value={customBillingAddress.firstName} name='firstName'
            onChange={productsStore.handleChangeCustomBillingAddress}
            isInvalid={productsStore.requiredFieldsError.customBillingAddress.firstName} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:lastname')} <span className='text-primary'>*</span></Form.Label>
          <Form.Control type='text' placeholder={t('formularFields:lastname')}
            value={customBillingAddress.lastName} name='lastName'
            onChange={productsStore.handleChangeCustomBillingAddress}
            isInvalid={productsStore.requiredFieldsError.customBillingAddress.lastName} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:street')} <span className='text-primary'>*</span></Form.Label>
          <Form.Control type='text' placeholder={t('formularFields:street')}
            value={customBillingAddress.street} name='street'
            onChange={productsStore.handleChangeCustomBillingAddress}
            isInvalid={productsStore.requiredFieldsError.customBillingAddress.street} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:housenumber')} <span className='text-primary'>*</span></Form.Label>
          <Form.Control type='text' placeholder={t('formularFields:housenumber')}
            value={customBillingAddress.streetNr} name='streetNr'
            onChange={productsStore.handleChangeCustomBillingAddress}
            isInvalid={productsStore.requiredFieldsError.customBillingAddress.streetNr} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:additionalAddress')} <span className='text-primary'></span></Form.Label>
          <Form.Control type='text' placeholder={t('formularFields:additionalAddress')}
            value={customBillingAddress.careOf} name='careOf'
            onChange={productsStore.handleChangeCustomBillingAddress} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:zip')}<span className='text-primary'>*</span></Form.Label>
          <ZipSuggestion
            type='text'
            placeholder={t('formularFields:zip')}
            value={customBillingAddress.zipCode} name='zipCode'
            onChange={productsStore.handleChangeCustomBillingAddress}
            isInvalid={productsStore.requiredFieldsError.customBillingAddress.zipCode}
            onChangeSuggestion={obj => productsStore.handleChangeCustomBillingAddress(getFakeEvent("city", obj.cityName, "text"))}
            countryIsoCode={customBillingAddress.countryIsoCode || "de"}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:city')}<span className='text-primary'>*</span></Form.Label>
          <Form.Control type='text' placeholder={t('formularFields:city')}
            value={customBillingAddress.city} name='city'
            onChange={productsStore.handleChangeCustomBillingAddress}
            isInvalid={productsStore.requiredFieldsError.customBillingAddress.city} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:country')}</Form.Label>
          <ArraySelect
            placeholder={t('formularFields:country')}
            value={customBillingAddress.countryIsoCode}
            name='countryIsoCode'
            options={checkoutStore.billingCountries}
            onChange={productsStore.handleChangeCustomBillingAddress}
            isInvalid={productsStore.requiredFieldsError.customBillingAddress.countryIsoCode}
            otherValueKey="isoCode"
            returnEvent
          />
        </Form.Group>
      </Form>
    )
  }
  render() {
    const { t, productsStore, checkoutStore } = this.props
    const mandate = process.env.REACT_APP_MANDATORSET

    if (!checkoutStore.isCustomBillingAddressAvailable()) {
      return null
    }

    return (
      <React.Fragment>
        <StyledCustomAddressCard>
          <StyledCard withCheckbox={true} active={productsStore.customBillingAddressSelected}
            onClick={() => this.handleCustomBillingAddressCardClick()}>
            <StyledCard.Body className='mt-3'>
              <StyledCard.Body.Title><StyledTitle><small className='text-primary text-uppercase fw-bold'>{t('confirmAddress:customBillingAddress')}</small></StyledTitle></StyledCard.Body.Title>
            </StyledCard.Body>
          </StyledCard>
        </StyledCustomAddressCard>
        {productsStore.customBillingAddressSelected ?
          <Col className='mt-3'>
            {mandate === "cws" ?
              <StyledCWSForm>
                {this.renderBillingAddressForm()}
              </StyledCWSForm>
              : this.renderBillingAddressForm()
            }
          </Col>
          : ''}
      </React.Fragment>
    )
  }
}

export default withTranslation()(inject('productsStore', 'checkoutStore')(observer(CustomBillingAddress)))
