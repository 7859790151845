import React from 'react'
import DynamicFormElements from '../Form/DynamicFormElements'

const formConfigTemplate = [
    {
        type: 'dateRange'
    },
    {
        type: 'costCenterSelect',
        name: 'targetCostcenterID'
    },
    {
        type: 'checkbox',
        name: 'include_Subordinated_CostCenters',
        label: 'includeSubordinatedCostCenters'
    },
    {
        type: 'text',
        name: 'emailAddress',
        label: 'emailAddress'
    }
]

const TurnOverStatistics = ({ formData, setFormData, formErrors }) => {

    return (
        <>
            <DynamicFormElements formConfigTemplate={formConfigTemplate} setFormData={setFormData} formData={formData} formErrors={formErrors} />
        </>
    )
}
export default TurnOverStatistics