import React, { useEffect, useState, Suspense, lazy, useRef } from 'react'
import { Alert, Tab, Tabs } from 'react-bootstrap'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import styled from 'styled-components'
import CollapsedFeedCard from './CollapsedFeedCard'
import { useTranslation } from 'react-i18next'
import Reports from './FeedCardContent/Reports'
import OrderApproval from './FeedCardContent/OrderApproval'
import OrderApprovalSRM from './FeedCardContent/OrderApprovalSRM'
import ReportJobsProvider from '../../stores/Administration/ReportJobsProvider'
import OrdersTableProvider from '../../stores/OrdersTableProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import useStores from '../../stores/useStore'
import { useObserver } from 'mobx-react'
import ComplaintsProvider from '../../stores/ComplaintsProvider'
import Complaints from './FeedCardContent/Complaints'
import getThemeValue from '../../utils/getThemeValue'
import InventoryBookingsTableProvider from '../../stores/InventoryBookingsTableProvider'
import InventoryBookingsApproval from './FeedCardContent/InventoryBookingsApproval'
import PointsChangesTableProvider from '../../stores/PointsChangesTableProvider'
import PointsChangesApproval from './FeedCardContent/PointsChangesApproval'
import Bills from './FeedCardContent/Bills'
import BillsProvider from '../../stores/BillsProvider'

const mandate = process.env.REACT_APP_MANDATORSET //return mandate=cws

const lazyImportContactModule = () => {
  return import(`../../components_overrides/${mandate}/Dashboard/ContactModuleCWS`).catch(() => {
    return import(`../../components_overrides/EmptyComponent`)
  });
};

const ContactModule = lazy(lazyImportContactModule)

const StyledTabsWrapper = styled.div`
  .tab-content {
    margin-top: 25px;
  }
  .flexSpaceBetween{
    padding:10px;
    display: flex;
    justify-content: space-between;
  }
  .nav-tabs {
      background:none;
      .nav-item {
        .nav-link {
            font-weight: 500;
            color: black;
            border-radius: 0;
            padding-top:20px;
            margin-bottom:0;
            &:focus {
              outline: 0;
            }
            &:hover {
              border-color: transparent;
              color: ${props => props.theme.primaryColor};
            }
            &.active {
              background:none;
              border-left: 0;
              border-top: 0;
              border-right: 0;
              position: relative;
              &:after {
                content:"";
                height: 5px;
                width: calc(100% + 0px);
                display: block;
                background: ${props => props.theme.primaryColor};
                z-index: 10;
                position: absolute;
                bottom: -2.5px;
                left: 0;
                border-radius: 10px;
              }
            }
        }
      }
  }
`

const StyledFeedCardsContainer = styled.div`
  // max-width: 820px;
  max-width: calc(100%-10px);
  
`
const Feeds = ({ setOpen }) => {
  const [feeds, setFeeds] = useState([])
  const [activeTabKey, setActiveTabKey] = useState('news')
  const [groupedFeeds, setGroupedFeeds] = useState({})
  const { t } = useTranslation()
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const mountedRef = useRef(true)
  const { uiStore } = useStores()

  useEffect(() => {
    uiStore.loadOrderApprovalCount()
    uiStore.loadReportsCount()
    uiStore.loadSrmCostCentersCount()
    uiStore.loadComplaintsCount()
    uiStore.loadPointsChangesCount()
    uiStore.loadInventoryBookingsCounter()

    apiClient.getJson(APIEndpoints.feeds).then(response => {
      if (!mountedRef.current) return null
      if (response.length === 0) {
        setOpen(true)
      }
      setFeeds(response)
    })

    return () => {
      mountedRef.current = false
    }
  }, [setOpen, uiStore])

  useEffect(() => {
    if (currentLocation.hash === '#pointsChanges') {
      setActiveTabKey('pointsChanges')
    }
    if (currentLocation.hash === '#reports') {
      setActiveTabKey('reports')
    }
    if (currentLocation.hash === '#orderApproval') {
      setActiveTabKey('orderApproval')
    }
    if (currentLocation.hash === '#orderApprovalSRM') {
      setActiveTabKey('orderApprovalSRM')
    }
    if (currentLocation.hash === '#complaints') {
      setActiveTabKey('complaints')
    }
    if (currentLocation.hash === '#inventoryBookings') {
      setActiveTabKey('inventoryBookings')
    }
    if (currentLocation.hash === '#billingDetails') {
      setActiveTabKey('billingDetails')
    }
    if (currentLocation.hash === '') {
      setActiveTabKey('news')
    }
  }, [currentLocation])

  useEffect(() => {
    const grouped = {}
    feeds.forEach(feed => {
      if (!grouped[feed.type]) {
        grouped[feed.type] = []
      }
      grouped[feed.type].push(feed)
    })
    setGroupedFeeds(grouped)
  }, [feeds])

  const handleTabSelect = (eventKey) => {
    setActiveTabKey(eventKey)
    setHash(eventKey)
  }

  const setHash = (key) => {
    switch (key) {
      case 'reports':
        navigate("/#reports")
        break;
      case 'orderApproval':
        navigate("/#orderApproval")
        break;
      case 'orderApprovalSRM':
        navigate("/#orderApprovalSRM")
        break;
      case 'complaints':
        navigate("/#complaints")
        break;
      case 'inventoryBookings':
        navigate("/#inventoryBookings")
        break;
      case 'pointsChanges':
        navigate("/#pointsChanges")
        break;
      case 'billingDetails':
        navigate("/#billingDetails")
        break;
      default:
        navigate("/")
        break;
    }
  }

  const showOrderApprovalTab = useObserver(() => (uiStore.orderApprovalCount.find(obj => obj.status === "openApprovals") || { count: 0 }).count)
  const showOrderApprovalSRMTab = useObserver(() => uiStore.srmCostCentersCount)
  const showReportsTab = useObserver(() => uiStore.reportsCount.length > 0)
  const showComplaintsTab = useObserver(() => uiStore.complaintsCount > 0)
  const showInventoryBookingsTab = useObserver(
    () => uiStore.inventoryBookingsCounter && uiStore.inventoryBookingsCounter.some(count => count.status === "open" && count.count > 0)
  )
  const showPointsChangesTab = useObserver(
    () => uiStore.pointsChangeCounter && uiStore.pointsChangeCounter.some(count => count.status === "open" && count.count > 0)
  )
  // Validation for If there are only news avaible remove the tabs for cws mandator only
  const onlyNews = mandate === 'cws' && uiStore.showNews && !showComplaintsTab && !showOrderApprovalSRMTab
    && !showReportsTab && !showOrderApprovalTab

  return useObserver(() => (
    <StyledTabsWrapper>
      <Tabs id="uncontrolled-tab-example" onSelect={handleTabSelect} activeKey={activeTabKey} style={onlyNews ? { display: 'none' } : {}}  >
        {
          uiStore.showNews &&
          <Tab eventKey="news" title={mandate === 'cws' ? t('dashboard:homepage') : t('feeds:news')} tabClassName="fw-bold" >
            <StyledFeedCardsContainer>
              <div>{groupedFeeds.news && groupedFeeds.news.map(item => (
                <CollapsedFeedCard key={item.id} item={item} />
              ))}</div>
              {feeds.length !== 0 && (!groupedFeeds.news || groupedFeeds.news.length === 0) && (
                <div className="mt-4"><Alert variant="info">{t('feeds:noEntries')}</Alert></div>
              )}
            </StyledFeedCardsContainer>
            {getThemeValue('contactInfos', 'showContactModules', false)
              ? <Suspense fallback={<div>Loading...</div>}>
                <ContactModule />
              </Suspense>
              : null}
          </Tab>
        }
        {(showReportsTab) && <Tab eventKey="reports" title={t('reports')} tabClassName="fw-bold" mountOnEnter unmountOnExit >
          <ReportJobsProvider>
            <Reports />
          </ReportJobsProvider>
        </Tab>}
        {showOrderApprovalTab ? < Tab eventKey="orderApproval" title={t('feeds:orderApproval')} tabClassName="fw-bold" mountOnEnter unmountOnExit >
          <OrdersTableProvider>
            <OrderApproval />
          </OrdersTableProvider>
        </Tab> : null}
        {/* Order pool */}
        {showOrderApprovalSRMTab ?
          <Tab eventKey="orderApprovalSRM" title={t('feeds:orderApprovalSRM')} tabClassName="fw-bold" mountOnEnter unmountOnExit >
            <OrdersTableProvider>
              <OrderApprovalSRM />
            </OrdersTableProvider>
          </Tab>
          : activeTabKey === "orderApprovalSRM" ?
            <Tab eventKey="orderApprovalSRM" title={t('feeds:orderApprovalSRM')} tabClassName="fw-bold" mountOnEnter unmountOnExit >
              <Alert variant="info" >Bestellungen müssen möglicherweise erst vom KST-Verantwortlichen freigegeben werden, bevor sie hier angezeigt werden</Alert>
            </Tab>
            : null}
        {uiStore.showBillingDetails &&
          <Tab eventKey="billingDetails" title={t('bills:billingDetails')} tabClassName="fw-bold" mountOnEnter unmountOnExit>
            <BillsProvider>
              <Bills />
            </BillsProvider>
          </Tab>}
        {showComplaintsTab &&
          <Tab eventKey="complaints" title={t('feeds:reurnscomplaints')} tabClassName="fw-bold" mountOnEnter unmountOnExit>
            <ComplaintsProvider>
              <Complaints />
            </ComplaintsProvider>
          </Tab>}
        {showInventoryBookingsTab &&
          <Tab eventKey="inventoryBookings" title={t('inventory:inventoryBooking_other')} tabClassName="fw-bold" mountOnEnter unmountOnExit>
            <InventoryBookingsTableProvider>
              <InventoryBookingsApproval />
            </InventoryBookingsTableProvider>
          </Tab>}
        {showPointsChangesTab &&
          <Tab eventKey="pointsChanges" title={t('feeds:pointsChanges')} tabClassName="fw-bold" mountOnEnter unmountOnExit>
            <PointsChangesTableProvider>
              <PointsChangesApproval />
            </PointsChangesTableProvider>
          </Tab>}
      </Tabs>
    </StyledTabsWrapper >
  ))
}

export default Feeds
