import React, { useCallback, useEffect, useState } from 'react'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { useTranslation } from 'react-i18next'
import { EmailIcon } from '../../components/Icons/EmailIcon'
import { getErrorMessageFromApiResponse } from '../../utils/api/apiUtils'
import usePrevious from '../../utils/hooks/usePrevious'

const SRMGroupContext = React.createContext({})
export function useSRMGroupTableState() {
    return React.useContext(SRMGroupContext)
}

const SRMGroupTableProvider = ({ children }) => {
    const { t } = useTranslation()

    const [tableIsLoading, setTableIsLoading] = useState(false)
    const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([])
    const [incomingOrders, setIncomingOrders] = useState([])
    const [pageOffset, setPageOffset] = useState(0)
    const [pageLimit, setPageLimit] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [allSelected, setAllSelected] = useState(false)
    const [allSelectedLoading, setAllSelectedLoading] = useState(false)
    const [error, setError] = useState('')
    const prevTableProps = usePrevious({ pageLimit, pageOffset })

    const fetchOrders = useCallback(() => {
        setTableIsLoading(true)
        setError('')
        const params = {
            'page.limit': pageLimit,
            'page.offset': pageOffset
        }
        apiClient.getJson(APIEndpoints.incomingOrders().groups, params, true, true)
            .then(response => {
                const result = response.data
                setTotalCount(response.headers['x-total-count'] ? parseInt(response.headers['x-total-count']) : 0)
                const preparedResult = result.map(item => ({
                    ...item,
                    checkBox: (
                        <span>
                            <input type="checkbox" id={item.id} name="scales"
                                checked={selectedCheckBoxes.includes(item.id)} onChange={() => { }}>
                            </input>
                        </span>
                    ),
                    actions: (
                        <span >
                            {
                                item.email ? <span >
                                    <a href={`mailto: ${item.email}`} onClick={e => { e.stopPropagation() }}
                                        title={t('employeesPage:editAction')}><EmailIcon /></a>
                                </span> : null
                            }
                        </span>

                    )
                }))
                setIncomingOrders(preparedResult)
            })
            .catch(error => setError(getErrorMessageFromApiResponse(error)))
            .finally(() => {
                setTableIsLoading(false)
            })
    }, [pageLimit, pageOffset, selectedCheckBoxes, t])

    useEffect(() => {
        if (prevTableProps?.pageLimit !== pageLimit || prevTableProps?.pageOffset !== pageOffset) {
            fetchOrders()
        }
    }, [fetchOrders, pageLimit, pageOffset, prevTableProps?.pageLimit, prevTableProps?.pageOffset])

    const onRowClick = (cell, row) => {
        if (allSelected) {
            setAllSelected(false)
        }
        if (selectedCheckBoxes.includes(row.id)) {
            var index = selectedCheckBoxes.indexOf(row.id);
            var newValues = [...selectedCheckBoxes]
            newValues.splice(index, 1);
            setSelectedCheckBoxes(newValues)
        }
        else {
            setSelectedCheckBoxes(prev => [...prev, row.id])
        }
    }

    useEffect(() => {
        // add action icons
        // todo only change selected one
        setIncomingOrders(prev => prev.map(item => ({
            ...item,
            checkBox: (
                <span>
                    <input type="checkbox" id={item.id} name="scales"
                        checked={selectedCheckBoxes.includes(item.id)} onChange={() => { }}>
                    </input>
                </span>
            )
        })))
    }, [selectedCheckBoxes])

    const selectAll = () => {
        if (allSelected) {
            setAllSelected(false)
            setSelectedCheckBoxes([])
        }
        else {
            setAllSelectedLoading(true)
            setError('')
            apiClient.getJson(APIEndpoints.incomingOrders().groups)
                .then(response => {
                    if (response) {
                        const arrayOfAllIds = response.map(obj => obj.id)
                        setSelectedCheckBoxes(arrayOfAllIds)
                    }
                })
                .catch(error => setError(getErrorMessageFromApiResponse(error)))
                .finally(() => { setAllSelected(true); setAllSelectedLoading(false) })
        }
    }

    const getOrderIds = async () => {
        const orderIds = []
        setError('')

        const orders = await apiClient.getJson(APIEndpoints.incomingOrders().groups).catch(error => setError(getErrorMessageFromApiResponse(error)))
        if (orders) {
            for (const obj of orders) {
                if (selectedCheckBoxes.includes(obj.id)) {
                    orderIds.push(...obj.orderIds)
                }
            }
        }
        return orderIds
    }
    const contextValues = {
        error, setError,
        getOrderIds,
        tableIsLoading,
        incomingOrders,
        onRowClick,
        pageOffset,
        setPageOffset,
        pageLimit,
        setPageLimit,
        totalCount,
        setTotalCount,
        selectedCheckBoxes,
        setSelectedCheckBoxes,
        fetchOrders,
        setTableIsLoading,
        allSelectedLoading, selectAll, allSelected
    }
    return (
        <SRMGroupContext.Provider value={contextValues}>
            {children}
        </SRMGroupContext.Provider>
    )
}

export default SRMGroupTableProvider
