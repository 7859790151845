import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTheme } from 'styled-components'
import NavigationLeft from '../Navigation/NavigationLeft'
import CartDropdown from './CartDropdown'
import ScaleButton from './ScaleButton'
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg'
import UserInitials from './UserInitials'
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch'
import { Link } from 'react-router-dom'
import { Dropdown, NavDropdown, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import StyledNav from '../Navigation/StyledNav'
import { colors } from '../../styles/colors'
import MediaQuery from 'react-responsive'
import { MenuIcon } from '../Icons/MenuIcon'
import BrandLogo from './BrandLogo'
import { useTranslation } from 'react-i18next'
import { inject, observer, useObserver } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import ScrollToTop from '../Buttons/ScrollToTop'
import NavigationRight from '../Navigation/NavigationRight'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import getThemeValue from '../../utils/getThemeValue'
import HelpVideoButton from './HelpVideoButton'

const StyledHeader = styled.header`
  background: white;
  box-shadow: 0 0 9px rgba(0,0,0,0.16);
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
   z-index: 15;

  @media screen and (min-width: 768px) {
    top: 0;
    padding-left: 36px;
  }
`
const StyledHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`

const StyledHeaderRight = styled.div`
  display: flex;
  align-items: center;
`

const StyledProfileButton = styled.button`
  height: 100%;
  width: 50px;
  border: none;
  background-color: transparent;

  padding: 0;
  &:focus {
    outline: none;
  }
  img {
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    overflow:hidden;
    border-radius: 100%;
  }

  svg {
    width: 35px;
    height: auto;
  }

  .show & {
    background-color: ${colors.navActiveBackground};
  }

  @media screen and (max-width: 992px){
    .show & {
      background-color: #FFF;
    }
  }
`

const StyledIndentItems = styled.div`

  padding-left: 15px;
  .dropdown-menu & .dropdown-item {
    font-weight: normal;
    padding-top: 0.45rem;
    padding-bottom: 0.45rem;
  }
`

const StyledMobileWrapper = styled.div`
  .show > .nav-link {
    &:after {
      transform: rotate(-180deg);
    }
  }
  .nav-item{
    width:100%;
  }
  .nav-link {
    font-weight: bold;
    background-color: #FFF;
    &:after {
      transition: 200ms ease transform;
    }
  }

  .mobile-dropdown-menu {
    border:none;
    width: 100vw;
    box-shadow: 0 5px 5px 0px rgba(0,0,0,0.4);
    .dropdown-menu{
      position: relative !important;
      transform: none !important;
    }
  }

  .dropdown-menu {
    border:none;
  }
   .dropdown-toggle.nav-link {
    justify-content: space-between;
  }
`

const Header = inject('uiStore', 'loginStore', 'cwsStore', 'profileStore')(observer((props) => {
  const { uiStore, loginStore, cwsStore, profileStore } = props
  const { t } = useTranslation()
  const profileImg = false
  const theme = useTheme();
  const location = useLocation()
  const profile = useObserver(() => profileStore.profile)

  useEffect(() => {
    uiStore.loadDashboard()
    uiStore.checkStdPointRun()
    uiStore.checkDocumentUpload()
    uiStore.fetchNewShoppingCarts()
    cwsStore.loadFooterItems()
  }, [cwsStore, uiStore])

  const redirectToRlx = async (e) => {
    e.preventDefault()
    try {
      const url = await apiClient.getJson(APIEndpoints.linksRlx)
      if (url) {
        window.open(url, "_blank")
      }
    } catch (e) { }
  }

  const ProfileNav = React.forwardRef(({ children, onClick }, ref) => (
    <StyledProfileButton
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}>
      {children}
    </StyledProfileButton>
  ))

  const MyProfileNav = () => {

    const imprintUrl = cwsStore.imprintUrl || getThemeValue('footer', 'imprintUrl', '')
    const agbUrl = cwsStore.agbUrl || getThemeValue('footer', 'agbUrl', '')
    const dataProtectionUrl = cwsStore.dataProtectionUrl || getThemeValue('footer', 'dataProtectionUrl', '')

    return (
      <>
        {uiStore.showRLXLink && <LinkContainer to="/" onClick={redirectToRlx}>
          <NavDropdown.Item>RLX</NavDropdown.Item>
        </LinkContainer>}
        <LinkContainer to='/orders' state={{ selectedStatus: 'open', showAllOrders: uiStore.allowShowAllOrders ? true : false }}>
          <NavDropdown.Item>{t('orders:ordersOverview')}</NavDropdown.Item>
        </LinkContainer >
        {
          uiStore.personalOrderAvailable && <LinkContainer to="/inventory">
            <NavDropdown.Item>{t('inventory:myEquipment')}</NavDropdown.Item>
          </LinkContainer>
        }
        {
          uiStore.personalOrderAvailable && <LinkContainer to="/inventoryHistory">
            <NavDropdown.Item>{t('inventoryHistory:inventoryHistory')}</NavDropdown.Item>
          </LinkContainer>
        }
        <LinkContainer to="/personalSpace">
          <NavDropdown.Item>{t('personalSpace:naviEntry')}</NavDropdown.Item>
        </LinkContainer>
        <Dropdown.Header>{t('header:legal')}</Dropdown.Header>
        <StyledIndentItems>
          {
            (agbUrl || imprintUrl || dataProtectionUrl) ?
              <>
                {dataProtectionUrl && <Dropdown.Item href={dataProtectionUrl}
                  target="_blank">{t('dataProtection')}</Dropdown.Item>}
                {agbUrl && <Dropdown.Item href={agbUrl} target="_blank">{t('gtc')}</Dropdown.Item>}
                {imprintUrl && <Dropdown.Item href={imprintUrl} target="_blank">{t('imprint')}</Dropdown.Item>}
              </>
              :
              null
          }
        </StyledIndentItems>
        <LinkContainer to="/" onClick={(e) => loginStore.logout(e)}>
          <NavDropdown.Item>{t('logout')}</NavDropdown.Item>
        </LinkContainer>
      </>
    )
  }

  const NaviationIcons = (
    <>
      <HelpVideoButton location={location} />
      <CartDropdown />
      {theme.enableCostCenterProductsComparison ? <ScaleButton location={location} /> : null}
      <MediaQuery minWidth={425}>
        {(uiStore.allowImportOffices || uiStore.allowImportCostcenters || uiStore.fittingAdministration || uiStore.allowEntitlementAdministration
          || uiStore.allowEntitlementPhaseAdministration || uiStore.allowImportCatalogs) &&
          <Link to="/administration"><SettingsIcon /></Link>
        }
      </MediaQuery>
    </>
  )

  return <>
    <MediaQuery maxWidth={992}>
      <StyledMobileWrapper>
        <StyledHeader>
          <StyledHeaderLeft>
            <BrandLogo checkMandatorImage />
          </StyledHeaderLeft>
          <StyledHeaderRight>
            <StyledNav>
              {NaviationIcons}
              <Dropdown align="end">
                <Dropdown.Toggle as={ProfileNav} ><MenuIcon /></Dropdown.Toggle>
                <Dropdown.Menu className="mobile-dropdown-menu">
                  <NavigationLeft />
                  <NavigationRight />
                  <Nav variant="pills">
                    <NavDropdown title={t('header:myProfile')} active={false}>
                      <MyProfileNav />
                    </NavDropdown>
                  </Nav>
                </Dropdown.Menu>
              </Dropdown>
              <LanguageSwitch />
            </StyledNav>
          </StyledHeaderRight>
        </StyledHeader>
        <ScrollToTop />
      </StyledMobileWrapper>
    </MediaQuery>
    <MediaQuery minWidth={993}>
      <StyledHeader>
        <StyledHeaderLeft>
          <BrandLogo checkMandatorImage />
          <NavigationLeft />
        </StyledHeaderLeft>
        <StyledHeaderRight>
          <NavigationRight />
          <StyledNav>
            {NaviationIcons}
            <Dropdown align="end">
              <Dropdown.Toggle as={ProfileNav}>{profileImg
                ? <img src={profileImg} alt="" />
                : <UserInitials username={profile.businessPartnerDisplayName} withPopover />}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <MyProfileNav />
              </Dropdown.Menu>
            </Dropdown>
          </StyledNav>
          <LanguageSwitch />
        </StyledHeaderRight>
      </StyledHeader>
      <ScrollToTop />
    </MediaQuery>
  </>
}))

export default Header
