import React from 'react'
import { observer, inject } from 'mobx-react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import VariantSelect from '../Forms/VariantSelect'
import { useTranslation } from 'react-i18next'

const StyledSubHeadline = styled.span`
  display: block;
  color: ${colors.gray4}
  font-weight: bold;
  margin: 15px 0 5px;
`

const Variants = inject('productDetailsStore')(observer((props) => {
  const { productDetailsStore } = props
  const { t } = useTranslation()
  const { variants } = productDetailsStore

  if (!variants || variants.length === 0) {
    return null
  }

  return (
    <div className="mb-3">
      <Form.Label>
        <StyledSubHeadline>{t('productDetail:variantSize')}</StyledSubHeadline>
      </Form.Label>
      <VariantSelect
        variants={variants}
        selectedFirstPartVariant={productDetailsStore.selectedFirstPartVariant}
        handleChangeFirstPartVariant={productDetailsStore.handleChangeFirstPartVariant}
        selectedVariantError={productDetailsStore.selectedVariantError}
        selectedSecondPartVariant={productDetailsStore.selectedSecondPartVariant}
        handleChangeSecondPartVariant={productDetailsStore.handleChangeSecondPartVariant}
      />
    </div >
  )
}))
export default Variants
