import React from 'react'
import DynamicFormElements from '../Form/DynamicFormElements'

const formConfigTemplate = [
    {
        type: 'dateRange',
        name: 'dateRange',
    },
    {
        type: 'select',
        name: 'cabinID',
        label: 'cabinID',
        defaultIsEmptyGuid: true,
        defaultName: 'select',
        options: []
    },
    {
        type: 'text',
        name: 'emailAddress',
        label: 'emailAddress'
    }
]

const CabinEventStatistic = ({ formData, setFormData, preSelectedData, formErrors }) => {
    return (
        <>
            <DynamicFormElements formConfigTemplate={formConfigTemplate} setFormData={setFormData} formData={formData} preSelectedData={preSelectedData} formErrors={formErrors} />
        </>
    )
}
export default CabinEventStatistic