import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { formatedCurrencyValue, formatedPrice, formatedPriceObject } from '../../utils/formatedPrice'
import styled, { withTheme } from 'styled-components'
import { useTranslation, withTranslation } from 'react-i18next'
import { Button, Form, Spinner } from 'react-bootstrap'
import VariantQuantityRow from './VariantQuantityRow'
import VariantQuantityOverlay from './VariantQuantityOverlay'
import { inject, observer } from 'mobx-react'
import ArraySelect from '../Select/ArraySelect'
import { DeleteIcon } from '../Icons/DeleteIcon'
import { MdLibraryAdd } from 'react-icons/md'
import ConfigurationModal from './ConfigurationModal'

const StyledSumRow = styled.div`
  display: flex;
  font-weight: bold;

  > div:first-child{
    width: 60%;
  }

  > div {
    padding: 3px;
  }
`

const StyledAmountContainer = styled.div`
  .btn-secondary {
    margin-right: 10px !important;
  }
`

const StyledSumValuesRow = styled(StyledSumRow)`
  color: ${props => props.theme.primaryColor};
`

const VariantQuantityTable = inject('teamOrderStore', 'productDetailsStore')(observer(props => {
  const { teamOrderStore, productDetailsStore } = props;
  const { t } = useTranslation()

  const [variantQuantityOverlayOpened, setVariantQuantityOverlayOpened] = useState(productDetailsStore.quantityRecommendedPerBPExists)
  const [tempRow, setTempRow] = useState(false)
  const [openConfigurationModal, setOpenConfigurationModal] = useState(null)
  const [createNewRowIsLoading, setCreateNewRowIsLoading] = useState(false)

  const getVariantNameAndPrice = useCallback((variant, wrapPrice = false) => {
    const variantPrice = productDetailsStore.articlePrices
      ? formatedPriceObject(productDetailsStore.getPriceObjectByVariantId(variant.id) || {}, false)
      : ''
    let variantNameAndPrice = variant.separatedDisplayName && variant.separatedDisplayName.length ? variant.separatedDisplayName.join(' ') : variant.displayName
    if (productDetailsStore.differentPrices) {
      variantNameAndPrice = wrapPrice
        ? <>{variantNameAndPrice} <span>(à&nbsp;{variantPrice})</span></>
        : `${variantNameAndPrice} (à ${variantPrice})`
    }
    return variantNameAndPrice
  }, [productDetailsStore])

  const createNewRow = async (preparedVariant) => {
    setTempRow(false)
    setCreateNewRowIsLoading(true)
    await teamOrderStore.addVariant(preparedVariant.id)
    setCreateNewRowIsLoading(false)
  }

  const handleCloseVariantQuantityOverlay = () => {
    setVariantQuantityOverlayOpened(false)
  }

  const selectedVariants = teamOrderStore.selectedVariants
  const selectedVariantsSum = teamOrderStore.selectedVariantsSum
  const variants = useMemo(() => (productDetailsStore.variants || []), [productDetailsStore.variants])
  const preparedVariantSizes = useMemo(() => variants.map(item => ({ id: item.id, displayName: getVariantNameAndPrice(item) })), [getVariantNameAndPrice, variants])
  const withConfigurationButton = useMemo(() => (productDetailsStore.articleConfiguration && productDetailsStore.articleConfiguration.length), [productDetailsStore.articleConfiguration])

  // automatically selects the variant if it's the only one available and not yet added to shopping cart
  useEffect(() => {
    if (variants.length === 1 && !selectedVariants.length === 0) { teamOrderStore.addVariant(variants[0].id) }
  }, [selectedVariants.length, teamOrderStore, variants])

  if (variants.length === 0) {
    return null
  }

  return (
    <React.Fragment>
      <div>
        <StyledAmountContainer>
          <div className="row">
            <div className="col-6">
              <Form.Label>{t('VariantQuantityTable:variantSize')}</Form.Label>
            </div>
            <div className="col-3">
              <Form.Label>{t('VariantQuantityTable:amount')}</Form.Label>
            </div>
          </div>
          {selectedVariants.map((item, index) => (
            <VariantQuantityRow
              key={`${item.variantId}_${index}`}
              vaiants={variants}
              preparedVariantSizes={preparedVariantSizes}
              index={index}
              item={item}
              openConfigurationModal={() => setOpenConfigurationModal(index)}
              withConfigurationButton={withConfigurationButton}
            />
          ))}
          {tempRow ?
            <div className="d-flex align-items-baseline">
              <ArraySelect
                options={preparedVariantSizes}
                onChange={createNewRow}
              />
              <div onClick={() => setTempRow(false)} className="mx-4" style={{ cursor: 'pointer' }}>
                <DeleteIcon color="black"></DeleteIcon>
              </div>
            </div>
            : null}
          {createNewRowIsLoading ?
            <div className="d-flex align-items-center justify-content-center p-1">< Spinner animation='border' variant='primary' /></div >
            : null}
          <div className="mt-3">
            <Button variant="secondary" className="mb-1"
              onClick={() => setTempRow(true)} hidden={productDetailsStore.quantityRecommendedPerBPExists} disabled={tempRow}><MdLibraryAdd className="me-3" />{t('VariantQuantityTable:addMoreSizes')}</Button>
            <Button variant="secondary" className="mb-1"
              onClick={() => { setVariantQuantityOverlayOpened(prev => !prev) }}>{t('VariantQuantityTable:showAllSizes')}</Button>
            {variantQuantityOverlayOpened
              ? <VariantQuantityOverlay
                onClose={handleCloseVariantQuantityOverlay}
                variants={variants}
                getVariantNameAndPrice={getVariantNameAndPrice}
                variantQuantitySum={selectedVariantsSum}
              />
              : null
            }
          </div>
        </StyledAmountContainer>
      </div >
      <div className='mt-3'>
        <StyledSumRow>
          <div>{t('productDetail:variantTableSum')}</div>
          <div>{t('productDetail:variantTableAmount')}</div>
        </StyledSumRow>
        <StyledSumValuesRow>
          <div>{formatedPrice(teamOrderStore.priceSum, formatedCurrencyValue(productDetailsStore.shoppingBasketArticle.pricePerItem || {}))} </div>
          <div>{selectedVariantsSum} {t('productDetail:variantTablePieces')}</div>
        </StyledSumValuesRow>
      </div>
      {openConfigurationModal != null
        ? <ConfigurationModal
          handleClose={() => setOpenConfigurationModal(null)}
          index={openConfigurationModal}
        />
        : null
      }
    </React.Fragment >
  )
}))

export default withTheme(withTranslation()(VariantQuantityTable))
