import React from 'react'

export const UploadIcon = ({ color, title, height, width }) => {
  const useColor = color ? color : '#3C3C3B'
  const useHeight = height ? height : '16px'
  const useWidth = width ? width : '16px'
  return (
    <svg fill={useColor} height={useHeight} width={useWidth} viewBox="0 0 16 16">
      <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
    </svg>
  )
}
