import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { PenIcon } from '../../Icons/PenIcon';
import { useTranslation } from 'react-i18next'
import ModalComponent from '../../Modals/ModalComponent'
import styled from 'styled-components'
import { colors } from '../../../styles/colors'

const StyledComment = styled.div`
    min-height:40px;
    flex-grow:1;
    color: ${colors.gray3};
    padding: 4px;
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 15px;
    font-style:italic;
    background:white;
`

export const EditArticleComment = inject('inventoryStore')(observer(props => {
    const { inventoryStore } = props
    const { comment, commentMaxLength } = inventoryStore.articleDetails
    const [showEditCommentModal, setShowEditCommentModal] = useState(false)
    const { t } = useTranslation()

    const saveNewComment = async (newComment) => {
        await inventoryStore.saveArticleComment(newComment)
        await inventoryStore.loadContingentArticleDetails()
    }

    return (
        <>
            <div className="d-flex align-items-stretch">
                <StyledComment>{comment}</StyledComment><div style={{ cursor: "pointer", padding: "1rem" }} onClick={() => setShowEditCommentModal(true)}><PenIcon title={t('edit')} /></div>
            </div>

            {showEditCommentModal && (<ModalComponent
                ModalTitle={t('comment')}
                functionButton1={(newComment => saveNewComment(newComment))}
                textButton1={t('saveButton')}
                functionCancel={() => { setShowEditCommentModal(false) }}
                withInput={true}
                inputLabel={t('comment')}
                inputContent={comment}
                inputMaxLength={commentMaxLength}
                inputRows="4"
            />)}
        </>
    )
}))