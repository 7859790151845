import React, { useState, useEffect, useCallback } from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import useStores from '../../../../stores/useStore'
import { useObserver } from 'mobx-react'
import { useParams } from 'react-router-dom'
import apiClient from '../../../../utils/api/apiClient'
import { APIEndpoints } from '../../../../utils/api/apiConfig'
import { useTranslation } from 'react-i18next'
import DeactivateModal from './DeactivateModal'

const StyledSwitch = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: row-reverse;
`
const EmployeeActivationSwitch = () => {
    const [info, setInfo] = useState(null)
    const { uiStore } = useStores()
    const hasRightToSwitch = useObserver(() => uiStore.allowChangeActivationStatus)
    const { employeeId } = useParams()
    const [infoIsLoading, setInfoIsLoading] = useState(false)
    const { t } = useTranslation()
    const [openDeactivateModal, setOpenDeactivateModal] = useState(false)

    const loadBusinesspartnerInfo = useCallback(async () => {
        try {
            const info = await apiClient.getJson(APIEndpoints.businessPartner(employeeId).info)
            setInfo(info)
        }
        catch (e) {

        }
    }, [employeeId])

    useEffect(() => {
        loadBusinesspartnerInfo()
    }, [loadBusinesspartnerInfo])


    const updateActivationStatus = async (status) => {
        if (status) {
            activateBP()
        }
        else {
            setOpenDeactivateModal(true)
        }
    }
    const activateBP = async () => {
        try {
            setInfoIsLoading(true)
            await apiClient.patchJson(APIEndpoints.businessPartner(employeeId).activationStatus, { activate: true })
            await loadBusinesspartnerInfo()
            setInfoIsLoading(false)
        }
        catch (e) {

        }
    }

    const handleCloseDeactivateModal = async () => {
        setInfoIsLoading(true)
        setOpenDeactivateModal(false)
        await loadBusinesspartnerInfo()
        setInfoIsLoading(false)
    }
    return (
        <>
            <StyledSwitch>
                {info ?
                    <Form.Check
                        disabled={!hasRightToSwitch || infoIsLoading}
                        type="switch"
                        id="custom-switch"
                        label={t('Subheader:employeeIsActive')}
                        onChange={e => updateActivationStatus(e.target.checked)}
                        checked={info.activationDate ? true : false}
                    /> : null}
            </StyledSwitch>
            {openDeactivateModal ?
                <DeactivateModal employeeId={employeeId} handleClose={handleCloseDeactivateModal} /> : null}
        </>

    )
}

export default EmployeeActivationSwitch
