import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { formatedDate } from '../../utils/formatedDate'
import { Link } from 'react-router-dom'
import { StyledDescriptionBlock, StyledTitle } from './ProductDetailStyles'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import MediaQuery from 'react-responsive'
import { Spinner } from 'react-bootstrap'

const StyledShowMore = styled.span`
  color: ${props => props.theme.primaryColor};
  margin-top: 8px;
  cursor: pointer;
`
const StyledTable = styled.table`
  width:100%;
  color: ${colors.gray3};
  
`

const StyledList = styled.ul`
  color: ${colors.gray3};
  padding: 10px 0;
  
`
const StyledTr = styled.tr`
  td{
    padding: 10px;
    vertical-align: center;
  }
  td:first-child{
    padding-left: 0;
    color: ${colors.gray4};

    small{
      color: ${colors.gray3};
    }
  }
 
`

const StyledLi = styled.li`
  padding: 10px;
  >div:first-child{
    flex-grow: 1;
    margin-right: 10px;
    border-bottom: 1px solid ${colors.gray3};
  }
`

const HistoryTable = ({ productHistory }) => {
    const { t } = useTranslation()
    return (
        <StyledTable  >
            <tbody>
                {productHistory.map((item, index) => <StyledTr key={index}>
                    <td>
                        <div className='fw-bold'>{formatedDate(item.createdAt)}</div>
                        <div>
                            <small className='text-uppercase'>{t('orders:orderNumber')} {item.order.orderNr}</small>
                        </div>
                    </td>
                    <td>{item.createdByDisplayName}</td>
                    <td>{item.articleVariant.displayName}</td>
                    <td>{t('amount')}: {item.quantity}</td>
                    <td>  <Link to={`/orders/${item.order.id}`} className='btn btn-primary  w-100' style={{ whiteSpace: 'nowrap' }}>{t('orders:toOrderButton')}</Link></td>
                </StyledTr>)}

            </tbody>
        </StyledTable>
    )
}

const HistoryList = ({ productHistory }) => {
    const { t } = useTranslation()
    return (
        <StyledList>
            {productHistory.map((item, index) =>
                <StyledLi key={index} className='d-flex justify-content-between align-items-end'>
                    <div>
                        <div className='fw-bold'>{formatedDate(item.createdAt)}</div>
                        <div>
                            <small className='text-uppercase'>{t('orders:orderNumber')} {item.order.orderNr}</small>
                        </div>
                        <div>
                            {item.createdByDisplayName}
                        </div>
                        <div>
                            {t('amount')}:{item.quantity}
                        </div>
                    </div>
                    <div>
                        <Link to={`/orders/${item.order.id}`} className='btn btn-primary  w-100' style={{ whiteSpace: 'nowrap' }}>{t('orders:toOrderButton')}</Link>
                    </div>
                </StyledLi>
            )}
        </StyledList>
    )
}
const OrderHistoryBlock = inject('productDetailsStore')(observer((props) => {
    const { productDetailsStore } = props
    const { t } = useTranslation()
    useEffect(() => {
        productDetailsStore.loadProductHistory()
        return () => {
            productDetailsStore.resetProductHistory()
        }
    }, [productDetailsStore])


    const showMore = () => {
        productDetailsStore.historyOffset = productDetailsStore.historyOffset + productDetailsStore.historyLimit
        productDetailsStore.loadProductHistory({ append: true })
    }
    if (!productDetailsStore.productHistory.length) {
        return null
    }
    return (
        <StyledDescriptionBlock>
            <StyledTitle className="h1">{t('inventory:orderHistory')}</StyledTitle>
            <MediaQuery maxWidth={992}>
                <HistoryList productHistory={productDetailsStore.productHistory} />
            </MediaQuery>
            <MediaQuery minWidth={993}>
                <HistoryTable productHistory={productDetailsStore.productHistory} />
            </MediaQuery>
            {productDetailsStore.productHistoryIsLoading ? <div className='d-flex justify-content-center'>
                <Spinner animation='border' variant='primary' />
            </div> : ''}
            {((productDetailsStore.historyTotalCount > productDetailsStore.historyOffset + productDetailsStore.historyLimit) && !productDetailsStore.productHistoryIsLoading) ?
                <div className='d-flex justify-content-center' >
                    <StyledShowMore onClick={showMore}>{t('dashboard:showMore')}</StyledShowMore>
                </div> : ''}
        </StyledDescriptionBlock>
    )
}))

export default OrderHistoryBlock
