import React from 'react'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import SubheaderTitle from '../../components/Page/logix2020/SubheaderTitle'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import BigFooter from '../../components/Footer/BigFooter'
import { t } from 'i18next'
import ActivityGeneralForm from '../../components/Configuration/Activity/ActivityGeneralForm'
import { useActivitiesState } from '../../stores/Configuration/ActivitiesProvider'
import { Navigate } from 'react-router-dom'

const CreateActivityPage = () => {
  const { redirectId } = useActivitiesState()

  //redirect to edit page after new type was created
  if (redirectId) {
    return <Navigate
      to={`/activitiesAdministration/edit/${redirectId}`}
      replace
    />
  }
  return (
    <div>
      <Subheader className="subHeader-v1">
        <SubheaderTitle
          withBackButton
          smallTitle={t('activities:addActivity')}
          goBackLabel={'Aktivitätenübersicht'}
          defaultBackTo="/activitiesAdministration"
        />
      </Subheader>
      <ContentArea>
        <ActivityGeneralForm />
      </ContentArea>
      <BigFooter />
    </div >
  )
}

export default CreateActivityPage