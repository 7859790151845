import React, { useState, useEffect, useRef, useCallback } from "react"
import BootstrapTable from 'react-bootstrap-table-next'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import apiClient from '../../../utils/api/apiClient'
import { useParams } from 'react-router-dom'
import { Button, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { getErrorMessageFromApiResponse } from "../../../utils/api/apiUtils"
import styled from 'styled-components'
import UniversalTableWrapperTest from '../../Table/UniversalTableWrapperTest'
import TableProvider, { useTableState } from '../../Table/TableProvider'


const StyledAlerts = styled.div`
  max-width: 450px;
  margin-top: 20px;
  `

const OfficeAccessRightsInner = () => {
  const { t } = useTranslation()
  const [groups, setGroups] = useState([])
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const { officeId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const mountedRef = useRef(true)
  const { tableRef } = useTableState()

  //Cleanup Function
  useEffect(() => {
    return () => { mountedRef.current = false }
  }, [])

  const columns = [
    {
      dataField: 'isAssigned',
      text: t('costCenterAdmin:isAssigned'), //  colum header
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return <input type="checkbox" checked={cell} onChange={() => { }} />  // return checkbox if the checkbox is checked use the cell true or false
      }
    },
    {
      dataField: 'group.displayName', // group.displayName
      text: t('costCenterAdmin:groupName'),
      sort: true,
    },
  ]

  const handleCheckedBox = (index) => {
    const newGroups = [...groups]
    newGroups[index].isAssigned = !newGroups[index].isAssigned
    setGroups(newGroups)
    setSuccess("")
  }

  const getGroupAssignments = useCallback(() => {
    apiClient.getJson(APIEndpoints.offices(officeId).groupAssignments)
      .then(response => {
        if (!mountedRef.current) { return null }
        setGroups(response)
        setIsLoading(false)
      })
      .catch(error => setError(getErrorMessageFromApiResponse(error)))
  }, [officeId])

  useEffect(() => {
    getGroupAssignments()
  }, [getGroupAssignments])

  const handleClickSubmit = () => {
    const param = {
      "newAssignmentIds": [],
      "removedAssignmentIds": []
    }

    groups.forEach(item => {
      if (item.isAssigned) {
        param.newAssignmentIds.push(item.id)
      } else {
        param.removedAssignmentIds.push(item.id)
      }
    })

    apiClient.patchJson(APIEndpoints.offices(officeId).groupAssignments, param)
      .then(response => {
        if (!mountedRef.current) { return null }
        setSuccess(`${t('costCenterAdmin:successRight')}`)
      })
      .catch(error => setError(getErrorMessageFromApiResponse(error)))
  }

  const onRowClick = (cell, row, rowIndex) => {
    handleCheckedBox(rowIndex)
  }

  return (
    <div>
      <UniversalTableWrapperTest tableIsLoading={isLoading}>
        <BootstrapTable
          ref={tableRef}
          noDataIndication={() => <div className="no-data-placeholder">{!isLoading && t('officeAdmin:noGroupAssignments')}</div>}
          bootstrap4
          keyField="id"
          hover
          data={groups}
          columns={columns}
          bordered={false}
          rowEvents={{ onClick: onRowClick }}
          loading={isLoading}
        />
      </UniversalTableWrapperTest>
      <Button className="mb-4 mt-3" onClick={handleClickSubmit}>{t('saveButton')}</Button>
      <StyledAlerts>
        {error ?
          <Alert variant="danger"> {error} </Alert>
          : ""
        }
        {success ?
          <Alert variant="success"> {success} </Alert>
          : ""
        }
      </StyledAlerts>
    </div>
  )
}

const OfficeAccessRights = () => {
  return (
    <TableProvider>
      <OfficeAccessRightsInner />
    </TableProvider>
  )
}
export default OfficeAccessRights
