import { observable, makeObservable } from 'mobx';
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'

class OrderStore {

  incomingOrder = null;

  incomingOrderId = null;
  shippingOrders = null;
  incomingOrderFailed = false;
  reloadShippingPositions = false;
  orderDetailSettings = null;
  supplierOrderPositions = null;

  supplierOrderId = null;
  incomingOrderPositions = null;
  supplierOrders = null;
  supplierOrder = null;
  expectedArrivals = null;
  openExpectedArrivalsModal = null;

  constructor(appStore) {
    makeObservable(this, {
      incomingOrder: observable,
      incomingOrderId: observable,
      shippingOrders: observable,
      incomingOrderFailed: observable,
      reloadShippingPositions: observable,
      orderDetailSettings: observable,
      supplierOrderPositions: observable,
      supplierOrderId: observable,
      incomingOrderPositions: observable,
      supplierOrders: observable,
      supplierOrder: observable,
      expectedArrivals: observable,
      openExpectedArrivalsModal: observable
    });

    this.app = appStore
  }

  async loadIncomingOrder(force = false) {
    if ((!this.incomingOrder || this.incomingOrder.id !== this.incomingOrderId) || force) {
      try {
        this.incomingOrder = null
        this.incomingOrderFailed = false
        this.incomingOrder = await apiClient.getJson(APIEndpoints.incomingOrders(this.incomingOrderId).incomingOrder)
      } catch (e) {
        this.incomingOrderFailed = true
      }
    }
  }
  async editOrderName(name) {
    await apiClient.patchJson(APIEndpoints.incomingOrders(this.incomingOrderId).incomingOrder, { name })
  }

  async loadIncomingOrderPositions() {
    this.incomingOrderPositions = null
    this.incomingOrderPositions = await apiClient.getJson(APIEndpoints.incomingOrders(this.incomingOrderId).incomingOrderPositions)
  }

  async loadOrderDetailSettings() {
    this.orderDetailSettings = null
    this.orderDetailSettings = await apiClient.getJson(APIEndpoints.orderDetails)
  }

  async loadShippingOrders() {
    this.shippingOrders = null
    this.shippingOrders = await apiClient.getJson(APIEndpoints.incomingOrders(this.incomingOrderId).shippingOrders)
  }
  async loadSupplierOrders() {
    this.supplierOrders = null
    this.supplierOrders = await apiClient.getJson(APIEndpoints.incomingOrders(this.incomingOrderId).supplierOrders)
  }
  async loadSupplierOrder() {
    this.supplierOrder = null
    this.supplierOrder = await apiClient.getJson(APIEndpoints.supplierOrders(this.supplierOrderId).supplierOrder)
  }


  async loadExpectedArrivals() {
    this.expectedArrivals = null
    this.expectedArrivals = await apiClient.getJson(APIEndpoints.supplierOrders(this.supplierOrderId).expectedArrivals)
  }
  async loadSupplierOrderPositions() {
    this.supplierOrderPositions = null
    this.supplierOrderPositions = await apiClient.getJson(APIEndpoints.supplierOrders(this.supplierOrderId).supplierOrderPositions)
  }
  async deleteOrder(id, cancellationText) {
    await apiClient.deleteJson(APIEndpoints.incomingOrders(id || this.incomingOrderId).incomingOrders, null, true, false, {
      data: {
        cancellationText,
        id
      }
    })
  }

  resetOrderPage() {
    this.app.orderStore.loadIncomingOrder(true)
    this.app.orderStore.loadIncomingOrderPositions();

    (async () => {
      await this.app.orderStore.loadShippingOrders()
      this.app.orderStore.reloadShippingPositions = true
    })()
  }

}

export default OrderStore
