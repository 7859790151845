import React from 'react'
import styled from 'styled-components'
import Form from 'react-bootstrap/Form'
import { hex2rgba } from '../../../utils/utils'
import { PositionItemImageAmount } from './PositionItemImageAmount'
import { Link, useNavigate } from 'react-router-dom'
import MediaQuery from 'react-responsive'
import { t } from 'i18next'


const StyledItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 10px 5px 0;
  border-radius: 10px;
  .mobileWrapper {
    display: flex;
    flex-direction: column;
    flex-grow:1;
  }
  &:hover{
    background-color: rgba(0,0,0,0.02);
  }
`

const StyledCheckboxWrapper = styled.div`
  margin-right: -10px;
  margin-left: 20px;

  ${StyledItemRow}:hover &{
    .custom-control-input ~ .custom-control-label::before{
      box-shadow: 0 0 0 0.2rem ${props => hex2rgba(props.theme.primaryColor, 0.25)};
    }
  }
`


const PositionItem = (props) => {
  const { orderPosition, changeChecked, isChecked, showCheckbox } = props
  const navigate = useNavigate();
  const disableCheckbox = orderPosition.isCanceled || !showCheckbox
  const returnedPositionsExist = orderPosition.returnableQuantity != null && orderPosition.returnableQuantity < orderPosition.quantity
  const handleClick = () => {
    const path = `/articleCatalog/article/${orderPosition.article.id}`
    navigate(path)
  }
  return (
    <StyledItemRow onClick={disableCheckbox ? null : changeChecked(orderPosition.id)}>
      <MediaQuery maxWidth={789}>
        <div className="mobileWrapper">
          <PositionItemImageAmount handleClick={handleClick} orderPosition={orderPosition} quantity={orderPosition.quantity} returnedPositionsExist={returnedPositionsExist} />
          <Link className="btn btn-outline-secondary btn-sm m-2" to={`/articleCatalog/article/${orderPosition.article.id}`}>{t("inventory:goToArticle")}</Link>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={790}>
        <PositionItemImageAmount handleClick={handleClick} orderPosition={orderPosition} quantity={orderPosition.quantity} returnedPositionsExist={returnedPositionsExist} />
        <Link className="btn btn-outline-secondary m-2" to={`/articleCatalog/article/${orderPosition.article.id}`}>{t("inventory:goToArticle")}</Link>
      </MediaQuery>
      {orderPosition.isCanceled ? <div className='text-danger ms-2'>{t('order:isCanceled')}</div> : ''}
      {/*{orderPosition.isCanceled ? <div className='text-danger ms-2'>{t('order:isCanceled')}: {formatedDate(incomingOrder.canceledAt)}</div> : ''}*/}
      <div>
        <StyledCheckboxWrapper>
          {!disableCheckbox &&
            <Form.Check type='checkbox'
              id={`checkbox-${orderPosition.id}`}
              onChange={() => { }}
              checked={isChecked}
              label=''
              value={orderPosition.id}
            />}
        </StyledCheckboxWrapper>
      </div>
    </StyledItemRow>
  )
}

export default PositionItem

