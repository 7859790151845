import React from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import { Table } from 'react-bootstrap'
import styled from 'styled-components'
import { colors } from '../../../styles/colors'


const StyledRow = styled.div`
  padding: 10px;
    border-bottom: 1px solid ${colors.gray3};
`
const ExpectedArrivalsList = ({ expectedArrivals }) => {
    const { t } = useTranslation()
    const article = t('articles')
    const expectedQuantity = t('order:expectedQuantity')
    const shipmentForeignkey = t('order:shipmentForeignkey')
    const expectedTimeOfArrival = t('order:expectedTimeOfArrival')
    const dateOfShipment = t('order:dateOfShipment')
    const isClosed = t('order:isClosed')
    const articleNr = t('productDetail:articleNumber')
    return (
        <div>
            <MediaQuery maxWidth={992}>
                <>
                    {expectedArrivals.map((position, index) => (
                        <StyledRow key={`list-${index}`}>
                            <div className='fw-bold'>{articleNr} {position.article ? position.article.articleNumber : null}</div>
                            <div >{article}:  {position.article ? position.article.displayName : null}</div>
                            <div>{expectedQuantity}: {position.quantity}</div>
                            <div>{shipmentForeignkey}: {position.shipmentForeignkey} </div>
                            <div>{expectedTimeOfArrival}:  {position.expectedTimeOfArrival}</div>
                            <div>{dateOfShipment}: {position.dateOfShipment} </div>
                            <div>{isClosed}: {position.isClosed} </div>

                        </StyledRow>
                    ))}
                </>
            </MediaQuery>
            <MediaQuery minWidth={993}>

                <Table>
                    <thead>
                        <tr>
                            <th>{article}</th>
                            <th>{expectedQuantity}</th>
                            <th>{shipmentForeignkey}</th>
                            <th>{expectedTimeOfArrival}</th>
                            <th>{dateOfShipment}</th>
                            <th>{isClosed}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {expectedArrivals.map((position, index) => (
                            <tr key={index}>
                                <td>
                                    <div >
                                        <div>{position.article ? position.article.articleNumber : null}</div>
                                        <small className='text-uppercase'>{articleNr}: {position.article ? position.article.displayName : null}</small>
                                    </div>
                                </td>
                                <td>{position.quantity} </td>
                                <td>{position.shipmentForeignkey}  </td>
                                <td>{position.expectedTimeOfArrival} </td>
                                <td>  {position.dateOfShipment}</td>
                                <td>  {position.isClosed}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </MediaQuery>
        </div>
    )
}

export default ExpectedArrivalsList
