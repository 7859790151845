import React from 'react'
import { navigationSideLeft } from '../../pages/Configuration/AdministrationPage'

import AdministrationSidebar from './AdministrationSidebar'

const AdministrationSidebarNavigation = ({ active }) => {
  return (
    <AdministrationSidebar options={navigationSideLeft()} active={active}/>
  )
}

export default AdministrationSidebarNavigation
