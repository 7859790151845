import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import i18n from '../../translation/i18n';
import { colors } from '../../styles/colors'
import { CategoryIconDefault, CategoryIcons } from '../Icons/CategoryIcons/CategoryIcons'


const StyledImgCol = styled.div`
  align-self: center;
  grid-row: span 2;

  @media screen and (min-width: 992px) {
    grid-row: auto;
  }
`

const StyledProductImgWrap = styled.div`
  width: 85px;
  height: 85px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`

const StyledProductDescriptionCol = styled.div`
  padding-left: 20px;
  padding-right: 10px;
  grid-column: span 2;
  
  @media screen and (min-width: 430px) {
    grid-column: auto;
  }
`

const StyledProdcutHeader = styled.div`
  opacity: 0.5;
`

const StyledProductImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const StyledProductSubDescription = styled.div`
  color: ${colors.gray3};
`
const StyledProductLocalStoreHouse = styled.div`
  color: ${colors.gray3};
  font-weight: 600;
`
class ProductInfo extends Component {

  getProductHeadline = () => {
    const { articleObject } = this.props
    let chunks = []
    if (articleObject.contingentAvailability && articleObject.contingentAvailability.displayName) {
      chunks.push(articleObject.contingentAvailability.displayName)
    }
    if (articleObject.categoryAvailability && articleObject.categoryAvailability.displayName) {
      chunks.push(articleObject.categoryAvailability.displayName)
    }
    return chunks.join(' / ')
  }

  getSubDescription = (position) => {
    const { articleVariant, configurations } = position
    let subDescriptionParts = []

    if (articleVariant) {
      /*if (articleVariant.groupDisplayName) {
        subDescriptionParts.push(articleVariant.groupDisplayName)
      }*/

      if (articleVariant.separatedDisplayName && articleVariant.separatedDisplayName.length) {
        subDescriptionParts.push(articleVariant.separatedDisplayName.join(' '))
      } else {
        if (articleVariant.displayName) {
          subDescriptionParts.push(articleVariant.displayName)
        }
      }
    }

    if (configurations && configurations.length) {
      configurations.forEach(config => {
        subDescriptionParts.push(`${config.name}: ${config.article.displayName}`)
        if (config.comment) {
          subDescriptionParts.push(config.comment)
        }
      })
    }

    return subDescriptionParts.length ? subDescriptionParts.join(' | ') : ''
  }
  getLocalStoreHouse = (position) => {
    const { localStorehouse } = position

    if (localStorehouse) {
      return i18n.t('translation:DirectOutput') + ': ' + localStorehouse.displayName
    }
    return ''
  }
  renderThumbnail() {
    const { articleObject, theme, iconType } = this.props
    const CategoryIcon = iconType && CategoryIcons[iconType] ? CategoryIcons[iconType] : CategoryIconDefault

    return (
      <StyledProductImgWrap>
        {articleObject.article.image && articleObject.article.image.url
          ? <StyledProductImg src={articleObject.article.image.url} alt='' />
          : <CategoryIcon color={theme.primaryColor} />
        }
      </StyledProductImgWrap>
    )
  }

  renderContent() {
    const { articleObject, position, toArticle } = this.props

    const productHeadline = this.getProductHeadline()
    const subDescription = this.getSubDescription(position)
    const localStoreHouse = this.getLocalStoreHouse(position)

    return (
      <React.Fragment>
        <StyledImgCol>
          {toArticle ?
            <Link to={toArticle}>
              {this.renderThumbnail()}
            </Link>
            :
            this.renderThumbnail()}
        </StyledImgCol>
        <StyledProductDescriptionCol>
          {productHeadline ?
            <StyledProdcutHeader className='text-primary'><i>
              <small>{productHeadline}</small>
            </i></StyledProdcutHeader> : ''}
          <span>{articleObject.article.displayName}</span>
          {subDescription ?
            <StyledProductSubDescription>
              <small>{subDescription}</small>
            </StyledProductSubDescription> : ''}
            <StyledProductLocalStoreHouse>
              {localStoreHouse}
            </StyledProductLocalStoreHouse>
        </StyledProductDescriptionCol>
      </React.Fragment>
    )
  }

  render() {
    const { standalone } = this.props

    return (
      <React.Fragment>
        {standalone ? (
          <div className='d-flex align-items-center'>
            {this.renderContent()}
          </div>
        ) : this.renderContent()}
      </React.Fragment>
    )
  }
}

export default withTheme(ProductInfo)
