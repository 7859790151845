import React from "react"
import ReportsTableFilter from '../../Reports/ReportsTableFilter'
import ReportsTable from '../../Reports/ReportsTable'


const Reports = () => {
    return (
        <>
            <ReportsTableFilter />
            <ReportsTable />
        </>
    )
}

export default Reports;