import React, { Component } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { ShoppingCartSummary } from '../components/ShoppingCart/ShoppingCartSummary'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import { StyledCard } from '../components/Cards/StyledCard'
import { PageLoadingLayer } from '../components/Loading/PageLoadingLayer'
import CustomShipmentAddress from '../components/ConfirmAddress/CustomShipmentAddress'
import CustomBillingAddress from '../components/ConfirmAddress/CustomBillingAddress'
import { withTranslation } from 'react-i18next'
import { Subheader } from '../components/Page/logix2020/Subheader'
import SubheaderTitle from '../components/Employees/Subheader/SubheaderTitle'
import EmployeeData from '../components/Employees/Subheader/EmployeeData'
import { Link } from 'react-router-dom'
import ShoppingCartProgress from '../components/ShoppingCart/ShoppingCartProgress'
import CustomDatePicker from '../components/Forms/CustomDatePicker'
import { getIsoDate } from '../utils/getIsoDate'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import { TestSidebarRight } from '../components/Page/logix2020/TestSidebarRight'
import BigFooter from '../components/Footer/BigFooter'
import { FlexWrapper } from '../components/Page/logix2020/FlexWrapper'
import MandatoryField from '../components/MandatoryField/MandatoryField'
import { StyledValidationMessage } from '../components/Employees/Form/formHelper'
import withRouter from '../utils/withRouter'

const LinearGradient = styled.div`
width:100%;
@media only screen and (min-width: 1281px) {
  background: linear-gradient(to left, rgb(238, 238, 238) 0%, rgb(238, 238, 238) 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
 }
`
const StyledContentArea = styled(ContentArea)`
   padding: 46px 40px 20px 36px;
   @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px)  {
    padding: 20px;
    min-height: calc(100vh - 450px);//header+navbar+sidebar
   }
  
`
const StyledFlexWrapper = styled(FlexWrapper)`
max-width: 1280px;
margin-left:auto;
margin-right:auto;
min-height:0px;
`





const StyledH1 = styled.h1`
  margin: 0 0 25px 0;
`
/*
const StyledButtonExtraText = styled.span`
  opacity: 0.6;
`*/

const StyledShippingButtonsWrapper = styled.div`
   margin: 0 -5px 20px;

`

const StyledShippingButton = styled(Button)`
  margin: 5px;
`

const StyledAddressCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`

const StyledAddressCard = styled.div`
  width: 100%;
  padding: 10px;

  @media screen and (min-width: 768px) {
    width: 50%;
    display: flex;

    > div {
      flex-grow: 1;
    }
  }
`

const StyledCustomDatePicker = styled(CustomDatePicker)`
  @media screen and (min-width: 600px) {
      max-width: 180px;
  }
`



class ConfirmAddressPage extends Component {
  constructor(props) {
    super(props)
    const { teamOrderStore, productsStore, checkoutStore, params } = this.props
    const { storeHouseId, costCenterId, shoppingBasketId, orderType } = params;

    (async () => {

      console.log('ConfirmAddressPage initial:', storeHouseId, costCenterId, orderType)
      teamOrderStore.initializeCurrentProcessType(storeHouseId, costCenterId, orderType)

      if (shoppingBasketId) {
        try {
          productsStore.resetPageErrors()
          const basket = await productsStore.loadShoppingBasket(shoppingBasketId)
          if (basket) {
            //init default shipmentType
            productsStore.loadShipmentTypes().then(result => result && productsStore.setShipmentType(result.find(shipmentType => shipmentType.isDefault) || result[0]))
            productsStore.loadAddressSettings()
            productsStore.loadAvailableAddresses()
            checkoutStore.loadBillingCountries()
            checkoutStore.loadShippingCountries(shoppingBasketId)
          }
        }
        catch (e) { }

      }
    })()
  }

  handleBillingAddressCardClick(address) {
    this.props.productsStore.setBillingAddress(address)
    this.props.productsStore.setCustomBillingAddressSelected(false)
  }

  handleShipmentAddressCardClick(address) {
    this.props.productsStore.setShipmentAddress(address)
    this.props.productsStore.setCustomShipmentAddressSelected(false)
  }

  isConfirmAddressPageLoading() {
    const { productsStore } = this.props
    return productsStore.loadAvailableAddressesIsLoading || productsStore.loadShipmentTypesIsLoading
  }

  renderShipmentAddressBlock() {
    const { t, productsStore, checkoutStore } = this.props
    const shipmentAddress = productsStore.shipmentAddress
    const availableShipmentAddresses = productsStore.getAvailableShipmentAddresses()
    const noOptionButMandatory = !checkoutStore.isCustomShipmentAddressAvailable() && !availableShipmentAddresses.length

    if (!checkoutStore.isShipmentAddressAvailable()) {
      return null
    }

    return (
      <React.Fragment>
        <h4 className='mt-5 mb-4'>{t('confirmAddress:shipmentAddressBlockTitle')}</h4>
        {noOptionButMandatory
          ? <Alert variant="danger">{t('confirmAddress:noAddressesAvailable')}</Alert>
          : <StyledAddressCardWrapper>
            {availableShipmentAddresses.map(address => {
              const formattedAddressParts = address.formattedCompleteAddress.split('\n')
              return (
                <StyledAddressCard key={address.id}>
                  <StyledCard withCheckbox
                    active={productsStore.customShipmentAddressSelected === false && shipmentAddress.originAddressId === address.originAddressId}
                    onClick={() => this.handleShipmentAddressCardClick(address)}>
                    <StyledCard.Headline><span className='text-primary'>{address.displayName}</span></StyledCard.Headline>
                    <StyledCard.Body>
                      <StyledCard.Body.Title>{formattedAddressParts.slice(0, 1)}</StyledCard.Body.Title>
                      <div>
                        {formattedAddressParts.slice(1).map((item, key) => {
                          return <React.Fragment key={key}>{item}<br /></React.Fragment>
                        })}
                      </div>
                    </StyledCard.Body>
                  </StyledCard>
                </StyledAddressCard>
              )
            })}
            <CustomShipmentAddress />
          </StyledAddressCardWrapper>}
      </React.Fragment>
    )
  }

  renderBillingAddressBlock() {
    const { t, productsStore, checkoutStore } = this.props
    const billingAddress = productsStore.billingAddress
    const availableBillingAddresses = productsStore.getAvailableBillingAddresses()
    const noOptionButMandatory = !checkoutStore.isCustomBillingAddressAvailable() && !availableBillingAddresses.length
    if (!checkoutStore.isBillingAddressAvailable()) {
      return null
    }

    return (
      <React.Fragment>
        <h4 className='mt-5 mb-4'>{t('confirmAddress:billingAddressBlockTitle')}</h4>
        {noOptionButMandatory
          ? <Alert variant="danger">{t('confirmAddress:noAddressesAvailable')}</Alert>
          : <StyledAddressCardWrapper className='mb-5'>
            {availableBillingAddresses.map(address => {
              const formattedAddressParts = address.formattedCompleteAddress.split('\n')
              return (
                <StyledAddressCard key={address.id}>
                  <StyledCard withCheckbox
                    active={productsStore.customBillingAddressSelected === false && billingAddress.originAddressId === address.originAddressId}
                    onClick={() => this.handleBillingAddressCardClick(address)}>
                    <StyledCard.Headline>{address.displayName}</StyledCard.Headline>
                    <StyledCard.Body>
                      <StyledCard.Body.Title>{formattedAddressParts.slice(0, 1)}</StyledCard.Body.Title>
                      <div>
                        {formattedAddressParts.slice(1).map((item, key) => {
                          return <React.Fragment key={key}>{item}<br /></React.Fragment>
                        })}
                      </div>
                    </StyledCard.Body>
                  </StyledCard>
                </StyledAddressCard>
              )
            })}
            <CustomBillingAddress />
          </StyledAddressCardWrapper>
        }
      </React.Fragment>
    )
  }

  renderShipmentTypesBlock() {
    const { productsStore } = this.props
    const shipmentTypes = productsStore.shipmentTypes ? productsStore.shipmentTypes : []

    return (
      <React.Fragment>
        <StyledShippingButtonsWrapper hidden={shipmentTypes.length === 1}>
          {shipmentTypes.map(shipmentType =>
            <StyledShippingButton key={shipmentType.id}
              className={productsStore.shipmentType && productsStore.shipmentType.id === shipmentType.id ? "buttonSRMExport-v1" : ""}
              variant={productsStore.shipmentType && productsStore.shipmentType.id === shipmentType.id ? 'primary' : 'outline-dark'}
              onClick={() => productsStore.setShipmentType(shipmentType)}>
              {shipmentType.displayName}
            </StyledShippingButton>
          )}

          {/*<StyledShippingButton variant='outline-dark'>
                            Express-Versand<StyledButtonExtraText> | +7 Punkte</StyledButtonExtraText>
                          </StyledShippingButton>*/}
        </StyledShippingButtonsWrapper>
      </React.Fragment>
    )
  }
  renderContactInfoBlock() {
    const { t, productsStore } = this.props
    const basket = productsStore.basket ? productsStore.basket : {}
    const phoneRequired = basket.metaProperties && Object.values(basket.metaProperties).some((obj) => obj.pointer === '/phone' && obj.attributes === 'isRequired')
    const emailRequired = basket.metaProperties && Object.values(basket.metaProperties).some((obj) => obj.pointer === '/email' && obj.attributes === 'isRequired')
    return (
      <React.Fragment>
        <h4 className='mt-5 mb-4'>{t('confirmAddress:contactDataTitle')}</h4>
        <Form>
          <Row>
            <Col md='6'>
              <Form.Group className="mb-3" data-id='basketEmailInput'>
                <Form.Label>{t('confirmAddress:emailAddress')} <span className='text-primary'> {emailRequired && '*'}</span></Form.Label>
                <Form.Control type='email' placeholder='beispiel@rigilog.de'
                  value={productsStore.basketEmail ? productsStore.basketEmail : ''} name='email'
                  onChange={productsStore.handleBasketEmailChange}
                  isInvalid={productsStore.requiredFieldsError.basketEmail || productsStore.fieldErrors.basketEmail} />
                <Form.Control.Feedback
                  type='invalid'>{productsStore.fieldErrors.basketEmail}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md='6'>
              <Form.Group className="mb-3" data-id='basketPhoneInput'>
                <Form.Label>{t('confirmAddress:telephoneNumber')} <span className='text-primary'>{phoneRequired && '*'}</span></Form.Label>
                <Form.Control type='text' placeholder='0123456789'
                  value={productsStore.basketPhone ? productsStore.basketPhone : ''} name='phone'
                  onChange={productsStore.handleBasketPhoneChange}
                  isInvalid={productsStore.requiredFieldsError.basketPhone || productsStore.fieldErrors.basketPhone} />
              </Form.Group>
              {basket.shippingSMSIsEnabled &&
                <Form.Group className="mb-3">
                  <Form.Check
                    label={t('confirmAddress:sendShipmentSMS')}
                    id='sendShipmentSMS'
                    name='sendShipmentSMS'
                    disabled={!productsStore.basketPhone}
                    checked={productsStore.sendShipmentSMS}
                    onChange={productsStore.handleSendShipmentSMSChange}
                  />
                </Form.Group>}
            </Col>
            {
              emailRequired || phoneRequired ?
                <MandatoryField /> :
                ""
            }
          </Row>
        </Form>

      </React.Fragment>
    )
  }

  renderPickUpDateBlock() {
    const { t, productsStore } = this.props

    return (
      <React.Fragment>
        {productsStore.isScheduledPickUpDateRequired &&
          <Form.Group className="mb-3">
            <Form.Label>{t('confirmAddress:pickupDateTitle')} <span className='text-primary'>*</span></Form.Label>
            <div className="w-100">
              <StyledCustomDatePicker
                dateFormat="dd.MM.yyyy"
                placeholderText={t('date')}
                onChange={(date) => { productsStore.handleScheduledPickUpDateChange(getIsoDate(date)) }}
                parseISO
                showYearDropdown
                selected={productsStore.scheduledPickUpDate ? new Date(productsStore.scheduledPickUpDate) : ''}
                isInvalid={productsStore.requiredFieldsError.scheduledPickUpDate || productsStore.fieldErrors.scheduledPickUpDate}
              />
            </div>
            {productsStore.fieldErrors.scheduledPickUpDate
              ? <StyledValidationMessage>
                {productsStore.fieldErrors.scheduledPickUpDate}
              </StyledValidationMessage>
              : null
            }
          </Form.Group>
        }
      </React.Fragment>
    )
  }

  render() {
    const { t, profileStore, productsStore, uiStore } = this.props
    const basket = productsStore.basket ? productsStore.basket : {}

    return (
      <>
        <Subheader className="with-progress subHeader-v1">
          <SubheaderTitle withBackButton defaultBackTo={uiStore.linkToBasket} goBackLabel={t('shoppingCart')} />
          <div className="flex-grow-1 justify-content-center ps-4 pe-4">
            <ShoppingCartProgress>
              <ul>
                <li className="active">
                  <Link to={uiStore.linkToBasket}><div className="bubble-wrapper">{t('SubheaderProgress:shoppingCart')}</div></Link>
                </li>
                <li className="active"><div className="bubble-wrapper">{t('SubheaderProgress:shipping')}</div></li>
                <li><div className="bubble-wrapper">{t('SubheaderProgress:orderCompletion')}</div></li>
                <li><div className="bubble-wrapper">{t('SubheaderProgress:done')}</div></li>
              </ul>
            </ShoppingCartProgress>
          </div>
          {basket.targetOrderProcessType === 'personal' && (
            <EmployeeData />
          )}
        </Subheader>
        <LinearGradient>
          <StyledFlexWrapper>
            <StyledContentArea >
              <div>
                <StyledH1>{t('confirmAddress:shipmentAddressTitle')}</StyledH1>
              </div>
              <PageLoadingLayer isLoading={this.isConfirmAddressPageLoading()}
                loadingText={t('confirmAddress:loadAddresses')}>

                {this.renderShipmentTypesBlock()}

                {this.renderPickUpDateBlock()}

                {this.renderContactInfoBlock()}

                {this.renderShipmentAddressBlock()}

                {this.renderBillingAddressBlock()}

                <div className="pb-5" />
              </PageLoadingLayer>
            </StyledContentArea>
            <TestSidebarRight>
              <ShoppingCartSummary basket={basket} profile={profileStore.profile} />
            </TestSidebarRight>
          </StyledFlexWrapper>
          <BigFooter />
        </LinearGradient>
      </>
    )
  }
}

export default withTranslation()(withRouter(inject('uiStore', 'productsStore', 'teamOrderStore', 'profileStore', 'checkoutStore')(observer(ConfirmAddressPage))))
