import React, { useMemo } from 'react'
import { useObserver } from 'mobx-react'
import { Form } from 'react-bootstrap'
import SearchField from '../../Forms/SearchField'
import { useTranslation } from 'react-i18next'
import SearchResultAmount from '../../Forms/SearchResultAmount'

const SizeDetectionSearchBox = ({ searchText, handleArticleSearch, contingents }) => {
    const countArticles = (contingents) => {
        let sum = 0
        for (let contingent of contingents) {
            for (let category of contingent.categories) {
                if (category.articles) sum += category.articles.length
            }
        }
        return sum
    }
    const articleAmount = useMemo(() => (countArticles(contingents)), [contingents])
    const { t } = useTranslation()
    return useObserver(() => (
        <div style={{ marginBottom: "35px" }}>
            <hr />
            <Form.Group className="mb-3">
                <Form.Label>{t('ProductOverview:searchArticle')}</Form.Label>
                <SearchField
                    placeholder={t('ProductOverview:searchArticle')}
                    value={searchText}
                    onChange={e => handleArticleSearch(e.target.value)}
                    onClearClick={() => handleArticleSearch('')}
                />
                {searchText ? <SearchResultAmount count={articleAmount} /> : <div className="mt-2">&nbsp;</div>}
            </Form.Group >
            <hr />
        </div>
    ))
}

export default SizeDetectionSearchBox
