import React from 'react'
import { formatedDate } from '../../utils/formatedDate'
import styled from 'styled-components'
import { StyledCard } from '../Cards/StyledCard'
import { useTranslation } from 'react-i18next'

const StyledMetaInfoBlock = styled(StyledCard)`
  font-size: 14px;
`

const StyledInfoItem = styled.div`
  padding: 15px 25px;
  flex: 1 1 0;
`

const StyledMetaLabel = styled.div`
  font-weight: bold;
`
export default function MetaInfoBlockSupplierOrder({ supplierOrder }) {
    const { t } = useTranslation()
    return (
        <StyledMetaInfoBlock>
            <div className='d-flex justify-content-between flex-wrap'>
                <div className='d-flex justify-content-between flex-grow-1'>
                    <StyledInfoItem>
                        <StyledMetaLabel>{t('supplier')}:</StyledMetaLabel>
                        <div>{supplierOrder.supplier ? supplierOrder.supplier.displayName : null}</div>
                    </StyledInfoItem>
                </div>
                <div>
                    <StyledInfoItem>
                        <StyledMetaLabel>{t('order:externNumber')}</StyledMetaLabel>
                        <div>{supplierOrder.externNumber ? supplierOrder.externNumber : null}</div>
                    </StyledInfoItem>
                </div>
                <div className='d-flex justify-content-between flex-grow-1'>
                    {formatedDate(supplierOrder.approvalDate) ? <StyledInfoItem>
                        <StyledMetaLabel>{t('order:orderDate')}:</StyledMetaLabel>
                        <div>{formatedDate(supplierOrder.approvalDate)}</div>
                    </StyledInfoItem> : null}
                    {formatedDate(supplierOrder.requestedArrivalDate) ? <StyledInfoItem>
                        <StyledMetaLabel>{t('order:expectedDeliveryDate')}:</StyledMetaLabel>
                        <div>{formatedDate(supplierOrder.requestedArrivalDate)}</div>
                    </StyledInfoItem> : null}
                </div>
            </div>
        </StyledMetaInfoBlock>
    )
}
