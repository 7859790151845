import React, { memo } from 'react'
import ReactDOM from 'react-dom'
import { StyledContextMenu } from './StyledContextMenu'
import { ContextMenu, MenuItem } from 'react-contextmenu'

const PortalContextMenu = memo(({ contextId, options }) => {
  return ReactDOM.createPortal(
    <StyledContextMenu   >
      <ContextMenu id={contextId}>
        {options && options.filter(option => option).map(op => (
          <MenuItem key={op.option} onClick={op.onOptionSelect} disabled={op.disabled}>
            <span>{op.optionIcon}</span><span>{op.optionText}</span>
          </MenuItem>
        ))}

      </ContextMenu>
    </StyledContextMenu>
    , document.getElementById('overlay-root')
  )
})

export default PortalContextMenu