import { BsSortAlphaDown } from "react-icons/bs"
import { ButtonWithLoadingState } from "../../components/Buttons"
import { Subheader } from "../../components/Page/logix2020/Subheader"
import { t } from "i18next"
import { MdLibraryAdd } from "react-icons/md"
import { FlexWrapper } from "../../components/Page/logix2020/FlexWrapper"
import { SidebarLeft } from "../../components/Page/logix2020/SidebarLeft"
import AdministrationSidebarNavigation from "../../components/Configuration/AdministrationSidebarNavigation"
import { ContentArea } from "../../components/Page/logix2020/ContentArea"
import BigFooter from "../../components/Footer/BigFooter"
import NewsOverview from "../../components/Configuration/News/NewsOverview"
import { useState } from "react"
import { Button } from "react-bootstrap"
import React, { useCallback } from 'react';
import useMountEffect from "../../utils/hooks/useMountEffect"
import { APIEndpoints } from "../../utils/api/apiConfig"
import apiClient from "../../utils/api/apiClient"
import ModalComponent2 from "../../components/Modals/ModalComponent2"
import EditorModal from "../../components/Configuration/News/EditorModal"
import { makeCall } from "../../utils/api/makeCall"
import { useCancelToken } from "../../utils/hooks/useCancelToken"
import NewsAssignmentsModal from "../../components/Configuration/News/NewsAssignmentsModal"

const NewsAdministrationPage = () => {
  const [openEditorModal, setOpenEditorModal] = useState(false)
  const [errorStates, setErrorStates] = useState({})
  const [loadingStates, setLoadingStates] = useState({})
  const [languages, setLanguages] = useState(null);
  const [news, setNews] = useState([])
  const [deleteNewsId, setDeleteNewsId] = useState('')
  const [editNewsId, setEditNewsId] = useState('')
  const [currentMandator, setCurrentMandator] = useState(null)
  const [editNewsIdAssignments, setEditNewsIdAssignments] = useState('')
  const { createOrCancelToken, sourceRef, isCancel } = useCancelToken();
  let isMainMandator = currentMandator?.mandatorType === "isMainmandator"

  useMountEffect(() => {
    getNews()
    loadLanguages()
    loadCurrentMandator()
  })

  const loadLanguages = () => {
    makeCall('loadLanguages', async () => {
      let res = await apiClient.getJson(APIEndpoints.languages)
      let mappedLanguagesArray = res.map(language => {
        return { name: language.displayName, value: language.isoCode }
      })
      setLanguages(mappedLanguagesArray);
    }, setErrorStates, setLoadingStates)
  }

  const loadCurrentMandator = () => {
    makeCall('loadCurrentMandator', async () => {
      let res = await apiClient.getJson(APIEndpoints.currentMandator)
      setCurrentMandator(res)
    }, setErrorStates, setLoadingStates)
  }

  const getNews = useCallback(async (force) => {
    if ((news.length || loadingStates.news) && !force) {
      return null
    }
    else {
      await makeCall('news', async () => {
        let loadedNews = await apiClient.getJson(APIEndpoints.feedsAdministration().feeds)
        setNews(loadedNews)
      }, setErrorStates, setLoadingStates)
    }
  }, [news, loadingStates.news])

  const deleteNews = () => {
    makeCall('deleteNews', async () => {
      await apiClient.deleteJson(APIEndpoints.feedsAdministration(deleteNewsId).edit)
      await getNews(true)
      setDeleteNewsId('')
    }, setErrorStates, setLoadingStates)
  }

  const putNewsOrderSequenceBackground = (newSequence) => {
    createOrCancelToken();
    const newsIds = newSequence.map(a => a.id)
    makeCall('saveOrderSequenceBackground', async () => {
      await apiClient.putJson(APIEndpoints.feedsAdministration().orderSequences, newsIds, true, false, { cancelToken: sourceRef.current.token })
    }, setErrorStates, setLoadingStates, isCancel)
  }

  function saveSortedSequence() {
    let sortedSequence = news.sort((a, b) => (a.header.localeCompare(b.header))).map(a => a.id)
    putNewsOrderSequence(sortedSequence)
  }
  async function putNewsOrderSequence(newSequence) {
    createOrCancelToken()
    makeCall('saveOrderSequence', async () => {
      await apiClient.putJson(APIEndpoints.feedsAdministration().orderSequences, newSequence, true, false, { cancelToken: sourceRef.current.token })
      getNews(true)
    }, setErrorStates, setLoadingStates, isCancel)
  }

  const handleCloseEditNews = () => {
    setOpenEditorModal(false);
    setEditNewsId('')
  }


  return (
    <>
      <Subheader className="subHeader-v1 justify-content-between">
        <div className="subheader-title flex-grow-1">{t('newsAdmin:editNews')}</div>
        <ButtonWithLoadingState
          variant={'primary'}
          buttonText={t('activities:sortAlphabetically')}
          isLoading={loadingStates.saveOrderSequence}
          onClick={saveSortedSequence}
          buttonIcon={<BsSortAlphaDown size="23" className="me-3 " />}
          className="subheader__button btn btn-primary text-white"
        />
        <Button onClick={() => setOpenEditorModal(true)} className="subheader__button "><MdLibraryAdd className="me-3" />{t('newsAdmin:addNews')}</Button>
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <AdministrationSidebarNavigation active="newsAdministration" />
        </SidebarLeft>
        <ContentArea>
          <NewsOverview
            setOpenEditorModal={setOpenEditorModal}
            errorStates={errorStates}
            loadingStates={loadingStates}
            news={news}
            setNews={setNews}
            putNewsOrderSequenceBackground={putNewsOrderSequenceBackground}
            setDeleteNewsId={setDeleteNewsId}
            setEditNewsId={setEditNewsId}
            isMainMandator={isMainMandator}
            setEditNewsIdAssignments={setEditNewsIdAssignments}
          />
        </ContentArea>
      </FlexWrapper>
      {deleteNewsId && <ModalComponent2
        ModalTitle={t('newsAdmin:deleteNews')}
        BodyPart1={t('newsAdmin:deleteAreYouSure')}
        button1Function={() => deleteNews(deleteNewsId)}
        button1Text={t('delete')}
        button1Variant="danger"
        button1Loading={loadingStates.deleteNews}
        bodyStyle="alert alert-danger"
        functionCancel={() => setDeleteNewsId('')}
        error={errorStates?.deleteNews?.value}
      />}
      {openEditorModal && <EditorModal handleClose={handleCloseEditNews} newsId={editNewsId} languages={languages} getNews={getNews} />}
      {editNewsIdAssignments && <NewsAssignmentsModal newsId={editNewsIdAssignments} currentMandator={currentMandator} handleClose={() => setEditNewsIdAssignments('')} />}
      <BigFooter />
    </>
  )
}
export default NewsAdministrationPage