import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { inject, observer } from 'mobx-react'
import { Navigate } from 'react-router-dom'
import LoginBox from '../components/Login/LoginBox'
import ContactInfos from '../components/ContactInfos/ContactInfos'
import PasswordResetBox from '../components/Login/PasswordResetBox'
import LoginLanguageSwitch from '../components/LanguageSwitch/LoginLanguageSwitch'
import BrandLogo from '../components/Header/BrandLogo'
import Infograph from '../components/Infograph/Infograph'
import Footer from '../components/Footer/Footer'
import RegistrationBox from '../components/Registration/RegistrationBox'
import withRouter from '../utils/withRouter'
const mandate = process.env.REACT_APP_MANDATORSET //return mandate=cws

const LoginPageHeader = styled.div`
  background-position: ${props => props.theme.userLogin.backgroundPosition};

  background-size: ${props => props.theme.userLogin.backgroundSize};
  background-repeat:no-repeat;
  display: block;
  height: 350px;
  width: 100%;
  box-shadow: inset 0 -100px 100px -100px #000000;
  @media screen and (min-width: 1200px) {
    height: 500px;
  }

  background-image: url(${props => props.theme.userLogin.backgroundImage});
`

const LoginPageHeaderNav = styled.div`
  height: 75px;
  width: 100%;
`
const LoginPageHeaderContainer = styled.div`
  position: relative;
  background-color: white;
  box-shadow: 0 0 13px 0 rgba(0,0,0,0.3);
`


const StyledLoginPageWrapper = styled.div`
background: white;
margin-bottom:  200px;
min-height: 100vh ;
position: relative;
box-shadow: 0 0 100px 0 rgba(0,0,0,0.8);
 @media screen and (min-width: 1200px) {
    min-height:100vh ;
 }
`

const StyledBox = styled.div`
  .styledBox{
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
    margin-top: -175px;
    margin-right: auto;
    margin-left: auto;
    padding: 20px;
     .form-label{
      color:white;
    }
    .form-text > a {
        color:white;
      }
    @media screen and (min-width: 576px) {
      padding: 30px 40px;
    }
    hr {
      margin-bottom: 2rem;
    }
  }
  .smallBox{max-width:575px;}
  .largeBox{max-width:700px;}
`

class LoginPage extends Component {
  render() {
    const { loginStore, theme } = this.props

    if (!loginStore.initialLogin && loginStore.isUserLoggedIn) {
      return <Navigate to='/' />
    }
    return (
      <div>
        <div style={{ position: 'relative', zIndex: 2 }}>
          <StyledLoginPageWrapper>
            <LoginPageHeaderContainer>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                    <LoginPageHeaderNav className='d-flex justify-content-between align-items-center'>
                      <BrandLogo classes='login' />
                      <LoginLanguageSwitch />
                    </LoginPageHeaderNav>
                  </div>
                </div>
              </div>
            </LoginPageHeaderContainer>
            {
              mandate !== 'kliniken' &&
              <LoginPageHeader className='d-flex justify-content-between align-items-center' />
            }
            <div className='container-fluid'>
              <div className='row'>
                <div className='col'>
                  <StyledBox className="cws-loginBox">
                    {this.props.location.pathname === "/password-reset" && <PasswordResetBox />}
                    {this.props.location.pathname === "/login" && <LoginBox />}
                    {this.props.location.pathname === "/sign-up" && <RegistrationBox />}
                  </StyledBox>
                  {theme.contactInfos && Object.keys(theme.contactInfos).length ? <ContactInfos /> : ''}
                  {mandate !== 'protectus' && mandate !== 'cws' && mandate !== 'kliniken' && <Infograph />}
                </div>
              </div>
            </div>
          </StyledLoginPageWrapper>
        </div>
        <Footer />
      </div>
    )
  }
}

export default withTheme(withRouter(inject('uiStore', 'loginStore')(observer(LoginPage))))