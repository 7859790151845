import React, { useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next';
import { VscSettings } from "react-icons/vsc";
import { StyledListItemText } from '../../TreeList/treeList'
import { PenIcon } from '../../Icons/PenIcon'
import { DeleteIcon } from '../../Icons/DeleteIcon'
import MoreOptionsButton from '../../Buttons/MoreOptionsButton';
import { getLanguageValue } from '../../../utils/getLanguageValue';
import { useDNDTree } from '../../../utils/hooks/useDNDTree';
import { MdLibraryAdd } from 'react-icons/md'
import { emptyGuid } from '../../../utils/emptyGuid';
import { BiCopy } from "react-icons/bi";
import { BsDiagram3Fill } from "react-icons/bs";
import { StyledContextMenu } from '../../ContextMenu/StyledContextMenu';
import { ContextMenuTrigger } from 'react-contextmenu';
import PortalContextMenu from '../../ContextMenu/PortalContextMenu';
import MediaQuery from 'react-responsive';
import { StyledItemWrapperDND, renderTemplateArrow, ArticleIcon, renderGroupArrow } from '../../TreeList/dndTreeList'

const ContingentTemplateItem = ({
  arrayItem,
  deleteTemplate,
  index,
  moveCardHandler,
  changeOpenProp,
  deleteArticle,
  addArticles,
  editGroup,
  addGroup,
  deleteGroup,
  copyTemplate,
  getArticleAssignments,
  assignmentTree,
  deleteAssignments
}) => {
  const navigate = useNavigate()
  const isTemplate = arrayItem.type === "template"
  const isGroup = arrayItem.type === "group"
  const isArticle = arrayItem.type === "article"

  const isAssigned = assignmentTree[arrayItem.entitlementId]
    && ((isGroup && assignmentTree[arrayItem.entitlementId].groups[arrayItem.id])
      || (assignmentTree[arrayItem.entitlementId].groups[arrayItem.groupId] && assignmentTree[arrayItem.entitlementId].groups[arrayItem.groupId].articles[arrayItem.article.id])
    )
  const canDrop = (item) => {
    let sameGroupOrTemplate = !!item && item.groupId === arrayItem.groupId && item.entitlementId === arrayItem.entitlementId && item.type === arrayItem.type && !item.isVirtualGroup && !arrayItem.isVirtualGroup
    let articleInsert = !!item && isGroup && item.type === "article" && arrayItem.id !== item.groupId
    let multipleArticlesInsert = !!item && isGroup && item.type === "multipleArticles"

    if (sameGroupOrTemplate || articleInsert || multipleArticlesInsert) {
      return true
    }
    return false
  }

  const { isOver, isDragging, drag, drop, item } = useDNDTree({ index, ...arrayItem }, moveCardHandler, canDrop)
  const ref = useRef(null)

  const editContingent = (contingentTemplateId) => {
    let path = `/contingentTemplatesAdministration/editContingent/${contingentTemplateId}#base`
    navigate(path)
  }
  const editContingentAmounts = (contingentTemplateId, groupId, articleId) => {
    let path = `/contingentTemplatesAdministration/editContingent/${contingentTemplateId}#editQuantities`
    navigate(path, { state: { groupId, articleId } })
  }
  const itemName = useMemo(() => {
    if (isTemplate) {
      return arrayItem.displayName
    }
    if (isGroup) {
      return arrayItem.isVirtualGroup ? t('contingentTemplates:virtualGroup') : getLanguageValue(arrayItem.name.values)
    }
    return <span>{arrayItem.article.displayName} <b>({arrayItem.article.articleNumber})</b></span>
  }, [arrayItem, isGroup, isTemplate])

  const opacity = isDragging ? "0.2" : "1";

  const options = [
    (isTemplate || isGroup) ? {
      option: 'add',
      optionText: isTemplate ? t('contingentTemplates:addGroup') : t('inventory:addArticle'),
      optionIcon: <MdLibraryAdd />,
      onOptionSelect: () => isTemplate ? addGroup({ entitlementId: arrayItem.id }) : addArticles({ entitlementId: arrayItem.entitlementId, groupId: arrayItem.id })
    } : null,
    (isTemplate || (isGroup && arrayItem.id !== emptyGuid)) ? {
      option: 'edit',
      optionText: t('edit'),
      optionIcon: <PenIcon />,
      onOptionSelect: () => isTemplate ? editContingent(arrayItem.id) : editGroup({ entitlementId: arrayItem.entitlementId, groupId: arrayItem.id })
    } : null,
    isTemplate ?
      {
        option: 'copy',
        optionText: t('officeAdmin:copy'),
        optionIcon: <BiCopy />,
        onOptionSelect: () => copyTemplate(arrayItem.id)
      }
      : null,
    (!isTemplate && !isGroup && !isAssigned) ? {
      option: 'assignments',
      optionText: t('contingentTemplates:articleAssignments'),
      optionIcon: <BsDiagram3Fill />,
      onOptionSelect: () => getArticleAssignments(arrayItem.article.id)
    } : null,
    isAssigned ?
      {
        option: '!assignments',
        optionText: <del>{t('contingentTemplates:articleAssignments')}</del>,
        optionIcon: <BsDiagram3Fill />,
        onOptionSelect: () => deleteAssignments({})
      } : null,
    !isTemplate ?
      {
        option: 'amounts',
        optionText: t('contingentTemplates:quantities'),
        optionIcon: <VscSettings />,
        onOptionSelect: () => editContingentAmounts(arrayItem.entitlementId, isGroup ? arrayItem.id : arrayItem.groupId, !isGroup ? arrayItem.id : null)
      } : null,
    (!isGroup || (isGroup && arrayItem.id !== emptyGuid)) ? {
      option: 'delete',
      optionText: t('deleteButton'),
      optionIcon: <DeleteIcon />,
      onOptionSelect: () => isTemplate
        ? deleteTemplate(arrayItem.id)
        : isGroup
          ? deleteGroup({ entitlementId: arrayItem.entitlementId, groupId: arrayItem.id })
          : deleteArticle({ entitlementId: arrayItem.entitlementId, groupId: arrayItem.groupId, articleId: arrayItem.id })
    } : null
  ]

  //open arrayItem automatically if drag mouse isOver
  useEffect(() => {
    let timerRef = null
    const handleStateChange = () => {
      timerRef = setTimeout(() => {
        changeOpenProp(arrayItem.id)
      }, 1000);
    };
    isOver && !timerRef && isTemplate && handleStateChange();
    return () => {
      clearTimeout(timerRef);
    };
  }, [isOver, changeOpenProp, isTemplate, arrayItem.id]);

  drag(drop(ref))
  let sameGroupOrTemplate = !!item && item.groupId === arrayItem.groupId && item.entitlementId === arrayItem.entitlementId && item.type === arrayItem.type
  let articleInsert = !!item && isGroup && item.type === "article" && arrayItem.id !== item.groupId
  let multipleArticleInsert = !!item && isGroup && item.type === "multipleArticles"
  return (
    <StyledContextMenu>
      <ContextMenuTrigger id={`${arrayItem.id}_${arrayItem.entitlementId}`} holdToDisplay={-1}>
        <StyledItemWrapperDND
          ref={ref}
          id={`${index}_id`}
          style={{ opacity }}
          isClickable
          articleOverGroup={isOver && (articleInsert || multipleArticleInsert)}
          showTopLine={isOver && sameGroupOrTemplate && item.index > index}
          showBottomLine={isOver && sameGroupOrTemplate && item.index < index}
          hierarchyLevel={isTemplate ? 0 : isGroup ? 1 : 2}
          assigned={isAssigned}
          onContextMenu={e => e.preventDefault()}
        >
          {isTemplate ? renderTemplateArrow(arrayItem.open, arrayItem.groupsLoaded, () => changeOpenProp(arrayItem.id))
            : isGroup ? renderGroupArrow(arrayItem.open, arrayItem.articlesLoaded, !!Object.keys(arrayItem.articles).length, () => changeOpenProp(arrayItem.entitlementId, arrayItem.id))
              : <ArticleIcon iconType={arrayItem.iconType} />}
          <StyledListItemText title={isArticle ? `${arrayItem.article.displayName} (${arrayItem.article.articleNumber})` : null}>
            {itemName}
          </StyledListItemText>
          <MediaQuery maxWidth={1499}>
            <div className="action-buttons">
              <MoreOptionsButton options={options} />
            </div>
          </MediaQuery>
        </StyledItemWrapperDND>
      </ContextMenuTrigger>
      <PortalContextMenu contextId={`${arrayItem.id}_${arrayItem.entitlementId}`} options={options} />
    </StyledContextMenu>
  )
}

export default ContingentTemplateItem