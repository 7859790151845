import { useState } from 'react'
import apiClient from '../api/apiClient'
import { getErrorMessageFromApiResponse, setPointerErrorsFromApiResponse } from '../api/apiUtils'

const useReport = () => {
  const [reportIsLoading, setReportIsLoading] = useState({})
  const [reportErrors, setReportErrors] = useState({})
  const [reportDownloadUrls, setReportDownloadUrls] = useState({})
  const [reportSubscriptionId, setReportSubscriptionId] = useState("")
  const [reportIsSendByMail, setReportIsSendByMail] = useState(false)

  const createReport = async (name, endpoint, params) => {
    setReportIsLoading(prev => ({ ...prev, [name]: true }))
    setReportErrors(prev => {
      if (prev[name]) delete prev[name]
      return prev
    })
    setReportDownloadUrls(prev => ({ ...prev, [name]: null }))

    try {
      let response = await apiClient.postJson(endpoint, params, true, true)
      if (response.headers.location) {
        if (response.status === 201) {
          const path = response.headers.location.match(/ReportPath=(.*)/)[1]
          if (path) {
            const newWindowRef = window.open(response.headers.location)
            if (!newWindowRef) {
              setReportDownloadUrls(prev => ({ ...prev, [name]: response.headers.location }))
            }
          }
          else { setReportIsSendByMail(true) }
        }
        else if (response.status === 202) {
          const id = response.headers.location.match(/Reportjobs\/(.*)\//)[1]
          if (id) {
            setReportSubscriptionId(id)
          }
        }
      }
      if (response.status === 204) {
        setReportIsSendByMail(true)
      }
    }
    catch (e) {
      setReportErrors(prev => ({ ...prev, [name]: { value: getErrorMessageFromApiResponse(e), formErrors: setPointerErrorsFromApiResponse(e) } }))
    }
    finally {
      setReportIsLoading(prev => ({ ...prev, [name]: false }))
    }
  }


  return (
    { createReport, reportIsLoading, setReportIsLoading, reportErrors, setReportErrors, reportDownloadUrls, setReportDownloadUrls, reportSubscriptionId, setReportSubscriptionId, reportIsSendByMail, setReportIsSendByMail }
  )
}

export default useReport