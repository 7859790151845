import React, { useId } from 'react'
import { useEffect, useState } from 'react'
import { Alert, Form, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { useCancelToken } from '../../utils/hooks/useCancelToken'
import SearchField from '../Forms/SearchField'
import Article from './Article'
import ArticleDND from './ArticleDND'
import LazyLoad from '../Forms/LazyLoad'
import SearchResultAmount from '../Forms/SearchResultAmount'
import { makeCall } from '../../utils/api/makeCall'


const ArticleSelect = ({ selectedArticles, setSelectedArticles, hideArticles, withDND, getArticleAssignments, listRef }) => {

  const [fetchedArticles, setFetchedArticles] = useState([])
  const [searchText, setSearchText] = useState('')
  const [offset, setOffset] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const { t } = useTranslation()
  const { createOrCancelToken, sourceRef, isCancel } = useCancelToken();
  const limit = 10
  const [lastClickedArticleId, setLastClickedArticleId] = useState(null)
  const [allowRange, setAllowRange] = useState(false);
  const [allSelected, setAllSelected] = useState(false)
  // here we are checking if there are already selected articles for the Contigent templates modal user
  const filteredArray = hideArticles ? fetchedArticles.filter(item => !hideArticles.find(element => element === item.article.id)) : fetchedArticles
  const [, setErrorStates] = useState({})
  const [loadingStates, setLoadingStates] = useState({})
  const handleChangeSearchText = newSearchText => {
    setSearchText(newSearchText)
    fetchArticles({ newOffset: 0, newSearch: newSearchText })
  }

  const fetchArticles = async (newParams = { newOffset: offset, newSearch: searchText }) => {
    createOrCancelToken()
    let append = !!newParams.newOffset
    const params = getParamsForArticlesRequest(newParams)
    makeCall('getArticles', async () => {
      let response = await apiClient.getJson(APIEndpoints.articles, params, true, true, true, { cancelToken: sourceRef.current.token })
      setFetchedArticles(prev => append ? [...prev, ...response.data] : response.data)
      setTotalCount(response.headers['x-total-count'] ? parseInt(response.headers['x-total-count']) : 0)
      setOffset(newParams.newOffset + limit)
    }, setErrorStates, setLoadingStates, isCancel)
  }

  const getParamsForArticlesRequest = (newParams) => {
    const { newOffset, newSearch } = newParams
    return ({
      'filter.searchText': newSearch ? newSearch : null,
      'page.offset': newOffset,
      'page.limit': limit,
      'filter.officeIndependent': true
    })
  }

  function getArticlesInRange(lastClickedId, newId) {
    let lastIndex = filteredArray.findIndex(article => article.article.id === lastClickedId)
    let newIndex = filteredArray.findIndex(article => article.article.id === newId)
    if (lastIndex < newIndex) {
      return filteredArray.slice(lastIndex, newIndex + 1)
    }
    else if (lastIndex > newIndex) {
      return filteredArray.slice(newIndex, lastIndex + 1)
    }
    else return []
  }

  const onClickSelectedArticles = (article) => {
    setAllSelected(false)
    let selectedArticleId = article.article.id
    setLastClickedArticleId(selectedArticleId)
    let newSelectedArticles = [...selectedArticles]
    let existingArticleIndex = selectedArticles.findIndex(article => article.article.id === selectedArticleId)
    if (allowRange) {
      let articlesInRange = getArticlesInRange(lastClickedArticleId, selectedArticleId)
      //lastClickedArticleId is selected atm
      if (selectedArticles.find(article => article.article.id === lastClickedArticleId)) {
        //add articles
        setSelectedArticles([...new Set([...newSelectedArticles, ...articlesInRange])])
      }
    }
    else if (existingArticleIndex >= 0) {
      //remove article if already selected
      newSelectedArticles.splice(existingArticleIndex, 1)
      setSelectedArticles(newSelectedArticles)
    } else {
      newSelectedArticles.push(article)
      setSelectedArticles(newSelectedArticles)
    }
  }

  useEffect(() => {
    let listRefCurrent = listRef?.current
    const keypressShiftCallback = e => {
      if (e.shiftKey && lastClickedArticleId) {
        setAllowRange(true);
      } else {
        setAllowRange(false);
      }
    }
    if (listRefCurrent) {
      listRefCurrent.addEventListener('click', keypressShiftCallback)
    }
    return () => { listRefCurrent && listRefCurrent.removeEventListener('click', keypressShiftCallback); }
  }, [lastClickedArticleId, listRef])

  const setAllArticles = async () => {
    var articles = []
    const params = {
      'filter.searchText': searchText,
      'filter.officeIndependent': true
    }
    await makeCall('getAllArticles', async () => {
      articles = await apiClient.getJson(APIEndpoints.articles, params)
    }, setErrorStates, setLoadingStates, isCancel)
    return articles
  }

  const selectAll = async () => {
    var articles = []
    if (!allSelected) {
      articles = await setAllArticles()
    }
    setSelectedArticles(articles)
    setAllSelected(prev => !prev)
  }
  const checkboxId = useId()
  return (
    <>
      <Form.Group className="mb-3 p-2">
        <Form.Label>{t('ProductOverview:searchArticle')}</Form.Label>
        <SearchField
          placeholder={t('ProductOverview:searchArticle')}
          value={searchText}
          onChange={e => handleChangeSearchText(e.target.value)}
          onClearClick={() => handleChangeSearchText('')}
        />
        <div className=" d-flex justify-content-between">
          {searchText ? <SearchResultAmount count={totalCount} /> : <div className="mt-2">&nbsp;</div>}
          {loadingStates.getAllArticles
            ? <Spinner size="sm" className="mt-2" animation='border' variant='primary' />
            : filteredArray.length ? <Form.Check
              className="mt-2"
              label={t('order:selectAll')}
              id={checkboxId}
              checked={allSelected}
              onChange={selectAll}
            />
              : null}
        </div>

      </Form.Group >
      {filteredArray.map((article, index) => (
        withDND
          ? <ArticleDND key={article.article.id} onClickSelectedArticles={onClickSelectedArticles} selectedArticles={selectedArticles} article={article} index={index} getArticleAssignments={getArticleAssignments} />
          : <Article key={article.article.id} onClickSelectedArticles={onClickSelectedArticles} selectedArticles={selectedArticles} article={article} index={index} />
      ))}
      {
        (searchText && !loadingStates.getArticles && !filteredArray.length) &&
        <>
          <Alert variant='info' >{t('productsOverview:noProductsAvailable')}</Alert>
        </>
      }
      {((totalCount > offset) && !loadingStates.getArticles) ?
        (<LazyLoad
          fetchData={fetchArticles}
          listRef={listRef}
        />) : ''}
      {loadingStates.getArticles &&
        <div className='d-flex justify-content-center p-3' >
          <Spinner animation='border' variant='primary' />
        </div>}
    </>
  )
}

export default ArticleSelect