import React from 'react'

export const CancelOrderIcon = (props) => {
  const color = props.color ? props.color : '#D0CFD0'

  return (
    <svg width='30' height='30' viewBox='0 0 113.4 113.4' className='cancelOrder-icon-svg'
         style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type='text/css'>
        {`
          .cancelOrder-icon-svg .st0{
            fill:${color};
          }
        `}
      </style>
      <g>
        <polygon className='st0' points='48.6,60.9 40.2,69.3 31.8,60.9 28.1,64.6 36.5,73 28.1,81.4 31.8,85.1 40.2,76.7 48.6,85.1 52.3,81.4
		43.9,73 52.3,64.6 	' />
        <path className='st0' d='M99.2,30.4C99.2,30.4,99.2,30.4,99.2,30.4C99.2,30.4,99.2,30.4,99.2,30.4c0-0.4-0.1-0.7-0.2-0.9
		c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0
		c-0.1,0-0.2-0.1-0.3-0.1c-1.2-0.5-2.4-1-3.6-1.4c-2.9-1.2-5.8-2.3-8.7-3.5c-3.5-1.4-7.1-2.8-10.6-4.2c-3-1.2-6.1-2.4-9.1-3.6
		c-1.5-0.6-2.9-1.2-4.4-1.7c-0.6-0.2-1,0-1.5,0.2c-0.8,0.3-1.5,0.6-2.3,0.9c-2.7,1.1-5.5,2.2-8.2,3.3c-3.5,1.4-7,2.8-10.5,4.2
		c-3.2,1.2-6.3,2.5-9.5,3.8c-1.7,0.7-3.5,1.4-5.2,2c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0-0.3,0.1-0.5,0.2c-0.5,0.3-0.9,0.9-0.9,1.5
		c0,1.3,0,2.7,0,4c0,3.2,0,6.4,0,9.6c0,2.9,0,6.6,0,9.7c-5.2,4.8-8.5,11.6-8.5,19.3c0,14.4,11.7,26.1,26.1,26.1
		c8.6,0,16.3-4.2,21-10.7c0,0,0.1,0,0.1,0c0.1,0,0.3-0.1,0.4-0.2c0,0,0,0,0,0c1.2-0.5,2.4-1.1,3.6-1.6c2.9-1.3,5.8-2.6,8.7-3.8
		c3.5-1.6,7-3.1,10.5-4.6c3-1.3,6.1-2.7,9.1-4c1.5-0.6,3-1.2,4.4-2c0,0,0,0,0.1,0c0.4-0.2,0.7-0.6,0.8-1.1c0-0.1,0-0.1,0-0.2
		c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0-1.3,0-2.7,0-4c0-3.2,0-6.4,0-9.6c0-3.9,0-7.7,0-11.6c0-3.3,0-6.7,0-10
		C99.2,33.8,99.2,32.1,99.2,30.4C99.2,30.5,99.2,30.5,99.2,30.4z M56.9,19.4c1-0.4,2-0.8,3-1.2c0.3-0.1,0.7-0.3,1-0.4
		c1.1,0.4,2.1,0.8,3.2,1.3c2.9,1.1,5.8,2.3,8.7,3.4c3.5,1.4,7,2.8,10.5,4.2c3,1.2,6.1,2.4,9.1,3.6c0.2,0.1,0.3,0.1,0.5,0.2
		c0,0,0,0-0.1,0c-2.9,1.3-5.8,2.6-8.7,3.8c-1.4,0.6-2.7,1.2-4.1,1.8c-1.1-0.4-2.2-0.9-3.2-1.3c-2.9-1.2-5.8-2.4-8.7-3.6
		c-3.5-1.4-7-2.9-10.6-4.3c-3-1.2-6.1-2.5-9.1-3.8c-0.2-0.1-0.3-0.1-0.5-0.2C51,21.8,53.9,20.6,56.9,19.4z M37.2,27.2
		c2.1-0.8,4.1-1.6,6.2-2.4c1.1,0.5,2.2,0.9,3.3,1.4c2.9,1.2,5.8,2.4,8.7,3.6c3.5,1.4,7,2.9,10.6,4.3c3,1.2,6.1,2.5,9.1,3.8
		c0.2,0.1,0.4,0.2,0.7,0.3c-0.7,0.3-1.4,0.6-2.1,0.9c-3,1.3-6.1,2.7-9.1,4c-1.2,0.5-2.4,1-3.6,1.6c-0.9-0.4-1.8-0.8-2.8-1.2
		c-2.9-1.3-5.8-2.6-8.7-3.8c-3.5-1.6-7-3.1-10.5-4.6c-3-1.3-6.1-2.7-9.1-4c-0.3-0.1-0.6-0.3-0.9-0.4C31.7,29.4,34.4,28.3,37.2,27.2z
		 M26.1,45.4c0-3.3,0-6.7,0-10c0-0.8,0-1.6,0-2.3c0.3,0.2,0.7,0.3,1,0.5c2.9,1.3,5.9,2.6,8.8,3.9c3.5,1.6,7,3.1,10.6,4.7
		c3,1.3,6.1,2.7,9.1,4c0.4,0.2,0.7,0.3,1.1,0.5c0.8,0.4,1.7,0.8,2.5,1.1c0,1,0,1.9,0,2.9c0,1.6,0,3.1,0,4.7
		c-4.8-5.1-11.5-8.2-19-8.2c-5.2,0-10,1.5-14.1,4.2C26.1,49.3,26.1,47,26.1,45.4z M40.2,95.3c-12.3,0-22.3-10-22.3-22.3
		c0-12.3,10-22.3,22.3-22.3s22.3,10,22.3,22.3C62.6,85.3,52.5,95.3,40.2,95.3z M95.7,34.4c0,3.2,0,6.4,0,9.6c0,3.9,0,7.7,0,11.6
		c0,3.3,0,6.7,0,10c0,1.3,0,2.6,0,3.9c-0.9,0.4-1.9,0.8-2.8,1.2c-2.9,1.3-5.8,2.6-8.7,3.8c-3.5,1.6-7,3.1-10.5,4.6
		c-3,1.3-6.1,2.7-9.1,4c-0.2,0.1-0.3,0.1-0.5,0.2c1.4-3.2,2.2-6.7,2.2-10.5c0-4.8-1.3-9.4-3.6-13.2c0-2.8,0-5.5,0-8.3
		c0-1.3,0-2.6,0-3.9c0.6-0.3,1.3-0.6,1.9-0.8c2.7-1.2,5.5-2.4,8.2-3.6c1.8-0.8,3.7-1.6,5.5-2.4c0,2.4,0,4.7,0,7.1c0,0.5,0,0.9,0,1.4
		c0,0.9,0.8,1.8,1.7,1.7c0.9,0,1.7-0.8,1.7-1.7c0-3.3,0-6.6,0-9.8c0-0.1,0-0.1,0-0.2c0.5-0.2,1-0.5,1.6-0.7c3.2-1.4,6.4-2.8,9.5-4.2
		c0.4-0.2,0.7-0.3,1.1-0.5c0.6-0.3,1.2-0.5,1.7-0.7C95.7,33.5,95.7,34,95.7,34.4z' />
      </g>
    </svg>

  )
}
