import React from 'react'
import MainDataCheck from '../AddEmployee/ProgressChecks/MainDataCheck'
import CommunicationDataCheck from '../AddEmployee/ProgressChecks/CommunicationDataCheck'
import AccessibleCostcentersCheck from '../AddEmployee/ProgressChecks/AccessibleCostcentersCheck'
import AddressesCheck from '../AddEmployee/ProgressChecks/AddressesCheck'
import JobfunctionsCheck from '../AddEmployee/ProgressChecks/JobfunctionsCheck'
import styled from 'styled-components'
import { useEmployeeDataState } from '../../../stores/EmployeeDataProvider'
import { useTranslation } from 'react-i18next'
import AccessibleOfficesCheck from '../AddEmployee/ProgressChecks/AccessibleOfficesCheck'
import AccessibleCatallogsCheck from '../AddEmployee/ProgressChecks/AccessibleCatallogsCheck'
import AccoundDataCheck from '../AddEmployee/ProgressChecks/AccoundDataCheck'
import AssignedGroupsCheck from '../AddEmployee/ProgressChecks/AssignedGroupsCheck'

const StyledList = styled.ul`
  list-style: none;
  margin: 5px 0 0 0;
  padding: 0;
  font-size: 18px;

  li {
    padding: 10px 0;
  }
`

const Progress = () => {
  const { activities, showUserData, accountData, communicationData, mainData, addresses } = useEmployeeDataState()
  const { accessibleCostcenters, accessibleOffices, accessibleCatalogs, login, password, id, assignedGroups } = accountData
  const shipmentAddresses = addresses.newItems.filter(item => item.addressType === 'shipment')
  const billingAddresses = addresses.newItems.filter(item => item.addressType === 'billing')
  const { t } = useTranslation()

  return (
    <StyledList>
      <li>
        <MainDataCheck mainData={mainData} />
      </li>
      <li>
        <CommunicationDataCheck communicationData={communicationData} />
      </li>
      <li>
        <AddressesCheck label={t('OverlayProgress:shippingAddress')} addresses={shipmentAddresses} />
      </li>
      <li>
        <AddressesCheck label={t('OverlayProgress:billingAddress')} addresses={billingAddresses} />
      </li>
      <li>
        <JobfunctionsCheck activities={activities} />
      </li>
      {showUserData && <>
        <li>
          <AccoundDataCheck login={login} password={password} id={id} />
        </li>
        <li>
          <AccessibleCostcentersCheck accessibleCostcenters={accessibleCostcenters} mainCostCenterId={mainData.costCenter.id} />
        </li>
        <li>
          <AccessibleOfficesCheck accessibleOffices={accessibleOffices} mainOfficeId={mainData.office.id} />
        </li>
        <li>
          <AccessibleCatallogsCheck accessibleCatalogs={accessibleCatalogs} />
        </li>
        <li>
          <AssignedGroupsCheck assignedGroups={assignedGroups} />
        </li>
      </>}
    </StyledList>
  )
}

export default Progress
