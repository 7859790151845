import React, { useCallback, useEffect, useRef, useState, memo, useMemo } from 'react'
import { Alert, Button, DropdownButton, FormControl, InputGroup, Col, Collapse, Dropdown, Form, Row } from 'react-bootstrap'
import { colors } from '../../../../styles/colors'
import { DeleteIcon } from '../../../Icons/DeleteIcon'
import CostCenterSelect from '../FormComponents/CostCenterSelect'
import { ArrowBigIcon } from '../../../Icons/ArrowBigIcon'
import { StyledCollapseTrigger } from '../formHelper'
import { Trans, useTranslation } from 'react-i18next'
import ArraySelect from '../../../Select/ArraySelect'
import { MdLibraryAdd } from 'react-icons/md'
import { emptyGuid } from '../../../../utils/emptyGuid'
import { cloneDeep } from 'lodash';

const JobFunctions = memo(({ selectableAvtivities, collapsible, activities, setActivitiesByKey, formErrors, resetErrorByFormKey }) => {
  const template = useMemo(() => ({ id: emptyGuid, percentage: '', costCenter: { id: emptyGuid } }), [])
  const activitiesItems = activities.newItems
  const [invalidActivityErrors, setInvalidActivityErrors] = useState({})
  const [opened, setOpened] = useState(!collapsible)
  const ref = useRef()

  const handleClickCollapse = useCallback(() => {
    setOpened(prev => !prev)
  }, [setOpened])

  const addRow = () => {
    const newJobs = [...activitiesItems]
    const clonedTemplate = cloneDeep(template)
    newJobs.push(clonedTemplate)
    setActivitiesByKey('newItems', newJobs)
  }

  const deleteRow = index => () => {
    const newJobs = [...activitiesItems]
    newJobs.splice(index, 1)
    setActivitiesByKey('newItems', newJobs)
  }

  const handleChangeFunction = (index, item) => {
    const newJobs = [...activitiesItems]
    newJobs[index]["id"] = item.id
    newJobs[index]["displayName"] = item.displayName
    setActivitiesByKey('newItems', newJobs)
  }

  const handleChangeCostCenter = index => costCenter => {
    const newJobs = [...activitiesItems]
    newJobs[index].costCenter.id = costCenter.id
    setActivitiesByKey('newItems', newJobs)
    resetErrorByFormKey(`newItems.${index}.costCenter.id`)
  }

  const handleOnSelectPercentage = (index, precentage) => {
    const newJobs = [...activitiesItems]
    newJobs[index]['percentage'] = precentage
    setActivitiesByKey('newItems', newJobs)
  }

  useEffect(() => {
    if (activitiesItems.length) {
      activitiesItems.forEach((item, index) => {
        if (item.id && (item.id !== emptyGuid)) {
          let selectableAvtivitiesIncludeSelected = selectableAvtivities.find(activity => activity.id === item.id)
          if (!selectableAvtivitiesIncludeSelected) {
            setInvalidActivityErrors(prev => { prev[`${index}_blub`] = true; return cloneDeep(prev) })
          }
          else {
            setInvalidActivityErrors(prev => { prev[`${index}_blub`] = false; return cloneDeep(prev) })
          }
        }
      })
    }
  }, [activitiesItems, selectableAvtivities, setInvalidActivityErrors])

  const { t } = useTranslation()
  return (
    <div ref={ref}>
      {collapsible ? (
        <StyledCollapseTrigger onClick={handleClickCollapse}>
          <h1><ArrowBigIcon variant={opened ? 'down' : 'right'} color={colors.textColor} />{t('EmployeeFormJobFunction:jobFunction')}
          </h1>
        </StyledCollapseTrigger>
      ) : (
        <h1>{t('EmployeeFormJobFunction:jobFunction')}</h1>
      )}
      <Collapse in={opened}>
        <div className="spacing-down">
          {activitiesItems.length === 0 && (
            <Alert variant="info">{t('EmployeeFormJobFunction:noJobFunction')}</Alert>
          )}
          {activitiesItems.map((item, index) => {
            return (
              <Row className="added-row" key={index}>
                <Col sm="6" lg="3">
                  <Form.Group>
                    <Form.Label>{t('EmployeeFormJobFunction:functionName')}</Form.Label>
                    <ArraySelect
                      name="id"
                      onChange={(obj) => handleChangeFunction(index, obj)}
                      value={item.id}
                      options={selectableAvtivities}
                      defaultOption={{ value: emptyGuid, name: t('pleaseSelect') }}
                      defaultOptionIsSelectable
                      isInvalid={!!formErrors[`newItems.${index}.id`] || !!invalidActivityErrors[`${index}_blub`]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors[`newItems.${index}.id`] || (invalidActivityErrors[`${index}_blub`]
                        ? <Trans
                          i18nKey="EmployeeFormJobFunction:invalidJobFunction"
                          values={{ displayName: item.displayName }}
                        />
                        : null)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm="6" lg="3">
                  <Form.Label>{t('EmployeeFormJobFunction:amount')}</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl placeholder={t('EmployeeFormJobFunction:amountPlaceholder')}
                      name="percentage"
                      onChange={e => handleOnSelectPercentage(index, e.target.value)}
                      value={item.percentage} />
                    <DropdownButton
                      title=""
                      id="input-group-dropdown-2"
                      align="end"
                      onSelect={(percentage) => handleOnSelectPercentage(index, percentage)}
                    >
                      <Dropdown.Item eventKey="100">100</Dropdown.Item>
                      <Dropdown.Item eventKey="50">50</Dropdown.Item>
                      <Dropdown.Item eventKey="33.33">33,33</Dropdown.Item>
                    </DropdownButton>
                  </InputGroup>
                </Col>
                <Col sm="6" lg="4">
                  <div className="d-flex">
                    <div style={{ flex: "1" }}>
                      <CostCenterSelect id={`newItems.${index}.costCenter`} label={t('EmployeeFormJobFunction:costcenter')} value={item.costCenter.id}
                        onChange={handleChangeCostCenter(index)}
                        isInvalid={!!formErrors[`newItems.${index}.costCenter.id`]} />
                    </div>
                    <div onClick={deleteRow(index)} title={t('deleteButton')} style={{ padding: "2.5rem 0 0 0.5rem" }} className="delete-btn">
                      <DeleteIcon />
                    </div>
                  </div>
                </Col>

              </Row>
            )
          })}
          <Button onClick={addRow}><MdLibraryAdd className="me-3" />{t('EmployeeFormJobFunction:addAdditionalFunction')}</Button>
        </div>
      </Collapse>
    </div>
  )
})

export default JobFunctions
