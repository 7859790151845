import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, Button, Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import InLineVariantDisplay from './InLineVariantDisplay'
import MediaQuery from 'react-responsive'
import { colors } from '../../../styles/colors'
import { ContingentRows, CategoryRows } from '../../ProductsOverview/Structure'
import { CustomizationIcon } from '../../Icons/CustomizationIcon'

const StyledWrapper = styled.div`
    padding: 10px;
    flex-grow: 1;
    border-bottom: 1px solid ${colors.gray3};
`

const ArticlesTable = memo(function ArticlesTable({ contingents, setOpenConfigurationModal, setOpenVariantModal }) {
    const { t } = useTranslation()
    const ListItem = ({ article }) => (
        <tr >
            <td colSpan="5">
                <StyledWrapper  >
                    <Row>
                        <Col xs={12} sm={6}><div>{article.article.displayName}</div>
                            <div>
                                <small className='text-uppercase'>({article.article.articleNumber})</small>
                            </div></Col>
                        <Col><Row className={"mt-3"}>
                            <Col>{article.isCustomizable ? <Button
                                size='sm'
                                className="w-100"
                                variant={(article.configurationIsMissing || !article.hasSelectedConfigurations) ? "secondary" : "success"}
                                onClick={() => setOpenConfigurationModal(article)}
                            >
                                <CustomizationIcon color="white" width="15px" height="15px" />
                            </Button> : null}
                            </Col>
                            <Col >{article.isWithSizes ? <InLineVariantDisplay
                                lastSelectedVariant={article.lastSelectedVariant}
                                setOpenVariantModal={setOpenVariantModal}
                                article={article}
                            /> : null}</Col>
                        </Row>
                        </Col>
                    </Row>
                </StyledWrapper>
            </td>
        </tr>
    )
    const TableItem = ({ article }) => (
        <tr  >
            <td>{article.article.displayName}</td>
            <td>{article.article.articleNumber}</td>
            <td >{article.isCustomizable
                ? <Button
                    size='sm'
                    className="w-100"
                    variant={(article.configurationIsMissing || !article.hasSelectedConfigurations) ? "secondary" : "success"}
                    onClick={() => setOpenConfigurationModal(article)}
                >
                    <CustomizationIcon color="white" width="15px" height="15px" />
                </Button>
                : null}
            </td>
            {article.isWithSizes
                ? <td>
                    <InLineVariantDisplay
                        lastSelectedVariant={article.lastSelectedVariant}
                        setOpenVariantModal={setOpenVariantModal}
                        article={article}
                    />
                </td>
                : null
            }
        </tr>)
    return (
        <>
            <Table borderless>
                <tbody>
                    {contingents.map(contingent => (
                        <ContingentRows key={contingent.id} contingent={contingent}>
                            {contingent.categories.map(category => {
                                const configurationsAvailable = category.articles.find(article => article.isCustomizable)
                                const sizesAvailable = category.articles.find(article => article.isWithSizes)
                                return <CategoryRows key={category.id} category={category} hideMaxedOut hideCounter>
                                    <MediaQuery minWidth={993}>
                                        <tr>
                                            <th>{t('productDetail:articleDescription')}</th>
                                            <th>{t('productDetail:articleNumber')}</th>
                                            {configurationsAvailable ? <th style={{ textAlign: 'center' }}>{t('productDetail:customize')}</th> : <th></th>}
                                            {sizesAvailable ? <th>{t('productDetail:variantTableSize')}</th> : null}
                                        </tr>
                                        {category.articles.map(article => <TableItem key={article.article.id} article={article} />)}
                                    </MediaQuery>
                                    <MediaQuery maxWidth={992}>
                                        {category.articles.map(article => <ListItem key={article.article.id} article={article} />)}
                                    </MediaQuery>
                                </CategoryRows>
                            })}
                        </ContingentRows>
                    ))}
                </tbody>
            </Table>
        </>
    )
})

export default ArticlesTable
