import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import OverlayRight from '../../Page/logix2020/OverlayRight'
import OverlayContainer from '../../Page/logix2020/OverlayContainer'
import { useTranslation } from 'react-i18next'
import { StyledDataList } from '../../DataList/dataList'
import { PenIcon } from '../../Icons/PenIcon'
import useAppointmentTypesState from '../../../stores/Configuration/useAppointmentTypesState';
import { Spinner } from 'react-bootstrap'
import { getLanguageValue } from '../../../utils/getLanguageValue'


const AppointmentTypeDetails = ({ selectedAppointmentTypeId }) => {
    const { t } = useTranslation();
    const [dataIsLoading, setDataIsLoading] = useState(false)
    const { cabinTypes, assignedCabins, selectedAppointmentType, loadAppointmentTypeData, loadAssignedCabins } = useAppointmentTypesState();
    const [appointedCabins, setAppointedCabins] = useState([]);

    let appointmentName = selectedAppointmentType.name && getLanguageValue(selectedAppointmentType.name.values);

    const loadData = useCallback(async () => {
        setDataIsLoading(true)
        const promises = [loadAppointmentTypeData(selectedAppointmentTypeId), loadAssignedCabins(selectedAppointmentTypeId)]
        Promise.all(promises).then(() => setDataIsLoading(false))
    }, [loadAppointmentTypeData, loadAssignedCabins, selectedAppointmentTypeId])

    useEffect(() => {
        if (selectedAppointmentTypeId) {
            loadData()
        }
    }, [loadData, selectedAppointmentTypeId])

    useEffect(() => {
        if (cabinTypes && assignedCabins.length > 0) {
            let newAppointedCabins = [];
            cabinTypes.forEach(cabin => {
                if (assignedCabins.find(cabinId => cabinId === cabin.id)) {
                    newAppointedCabins.push(cabin);
                }
            })
            setAppointedCabins(newAppointedCabins);
        }
    }, [cabinTypes, assignedCabins])

    if (dataIsLoading) {
        return <div className='d-flex justify-content-center' >
            <Spinner animation='border' variant='primary' />
        </div>
    }

    return (
        <StyledDataList className="content">
            <div className="data-list__name h2">
                {appointmentName}
                <Link to={`/appointmenttypes/edit/${selectedAppointmentType.id}#general`}>
                    <PenIcon title={t('edit')} />
                </Link>
            </div>
            <ul className="data-list__data">
                <li className="data-list__entry">
                    <div className="data-list__label">{t('translation:duration')} :</div>
                    <div className="data-list__value">{selectedAppointmentType.durationInMinutes} {t('translation:minutes')}</div>
                </li>
            </ul>
            <div className="data-list__name h3 mt-4">
                {t('cabinTypes:cabins')}
                <Link to={`/appointmenttypes/edit/${selectedAppointmentType.id}#assignedCabins`}>
                    <PenIcon title={t('edit')} />
                </Link>
            </div>
            <ul >
                {appointedCabins.map((cabin, idx) => {
                    return (
                        <li className="data-list__value" key={`${idx} ${cabin.id}`} >
                            {cabin.displayName}
                        </li>
                    )
                })}
            </ul>
        </StyledDataList>
    )
}

const OverlayRightAppointmentTypeInfo = ({ visible, onClose, selectedAppointmentTypeId }) => {
    const { t } = useTranslation()
    return (
        <OverlayContainer>
            <OverlayRight visible={visible} onClose={onClose} headerTitle={t('details')}>
                <AppointmentTypeDetails selectedAppointmentTypeId={selectedAppointmentTypeId} />
            </OverlayRight>
        </OverlayContainer>
    )
}

export default OverlayRightAppointmentTypeInfo;
