import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BiMoviePlay } from "react-icons/bi";
import styled from 'styled-components';
import VideoModal from '../Modals/VideoModal';
import { makeCall } from '../../utils/api/makeCall';
import apiClient from '../../utils/api/apiClient';
import { APIEndpoints } from '../../utils/api/apiConfig';

const StyledVideoButton = styled.div`
  cursor:pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: none;
  background-color: transparent;
  opacity: 0;
  width: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, width 0.5s ease;
  &.visible{
    opacity: 1;
    width: 40px;
  }
`
//define what page will show which video. multiple pages can show the same video through correct regular expression
const pageMapping =
    [
        { regex: /^\/$/, placeholder: "CMS_Document_Name_Dashboard" },
        { regex: /^\/employees$/, placeholder: "CMS_Document_Name_Accounts" },
        { regex: /^\/products\/personal/, placeholder: "CMS_Document_Name_Order_Personal_Order" },
        { regex: /^\/newsAdministration$/, placeholder: "CMS_Document_Name_Adminstration_News" },
        { regex: /^\/placeholdersAdministration$/, placeholder: "CMS_Document_Name_Adminstration_Placeholders" },
    ]

const HelpVideoButton = ({ location }) => {
    const [openVideoModal, setOpenVideoModal] = useState(false)
    const [videoUrl, setVideoUrl] = useState("")
    const [, setErrorStates] = useState({})
    const [, setLoadingStates] = useState({})

    const findVideoPageMatch = (location) => {
        let result = {}
        pageMapping.forEach(element => {
            if (element.regex.test(location.pathname)) {
                result = element
            }
        });
        return result
    }

    const videoPage = useMemo(() => findVideoPageMatch(location), [location])

    const getVideoUrl = useCallback(async () => {
        await makeCall('getAllArticles', async () => {
            let url = await apiClient.getJson(APIEndpoints.customHTMLVideo(videoPage.placeholder))
            setVideoUrl(url)
        }, setErrorStates, setLoadingStates)
    }, [videoPage.placeholder])

    useEffect(() => {
        if (videoPage.placeholder) {
            getVideoUrl()
        }
        else { setVideoUrl("") }
    }, [getVideoUrl, videoPage.placeholder])


    const handleClickVideoButton = async () => {
        setOpenVideoModal(true)
    }

    return (
        <>
            <StyledVideoButton onClick={handleClickVideoButton} className={videoUrl ? 'visible' : ''}><BiMoviePlay size="22" /></StyledVideoButton>
            {openVideoModal && <VideoModal url={videoUrl} handleClose={() => { setOpenVideoModal(false) }} />}
        </>
    )
}
export default HelpVideoButton