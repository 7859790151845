import React, { memo, useCallback, useEffect, useState } from 'react'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { formatedDate } from '../../../utils/formatedDate'
import { emptyDate } from '../../../utils/emptyDate'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PenIcon } from '../../Icons/PenIcon'
import { Spinner, Alert } from 'react-bootstrap'
import { makeCall } from "../../../utils/api/makeCall";
import { useCancelToken } from "../../../utils/hooks/useCancelToken";

const EmployeeData = memo(({ selectedRow, handleCloseOverlayRight }) => {
    const { t } = useTranslation()
    const [businessPartnerInfo, setBusinessPartnerInfo] = useState(null)
    const [error, setError] = useState({})
    const [loading, setLoading] = useState({})
    const { createOrCancelToken, sourceRef, isCancel } = useCancelToken();

    const getBusinessPartnerInfo = useCallback(() => {
        createOrCancelToken();
        makeCall('getInfo', async () => {
            let data = await apiClient.getJson(APIEndpoints.businessPartner(selectedRow.id).info, null, true, false, true, { cancelToken: sourceRef.current.token })
            setBusinessPartnerInfo(data);
        }, setError, setLoading, isCancel)
    }, [createOrCancelToken, isCancel, selectedRow.id, sourceRef])

    useEffect(() => {
        if (selectedRow) {
            setBusinessPartnerInfo(null);
            getBusinessPartnerInfo()
        }
        return () => { setError('') }
    }, [getBusinessPartnerInfo, selectedRow])



    const employeeDataArray = businessPartnerInfo && [
        {
            label: `${t('Overlay:gender')}:`,
            value: businessPartnerInfo.gender === 'male' ? t('male') : t('female')
        },
        {
            label: `${t('Overlay:birthdate')}:`,
            value: businessPartnerInfo.birthDate === emptyDate ? '-' : formatedDate(businessPartnerInfo.birthDate)
        },
        {
            label: `${t('Overlay:personalNumber1')}:`,
            value: businessPartnerInfo.personalNr
        },
        {
            label: `${t('Overlay:personalNumber2')}:`,
            value: businessPartnerInfo.personalNr2 || '-'
        },
        {
            label: `${t('Overlay:company')}:`,
            value: businessPartnerInfo.mandator.displayName
        },
        {
            label: `${t('Overlay:organisationUnit')}:`,
            value: businessPartnerInfo.office ? businessPartnerInfo.office.displayName : '-'
        },
        {
            label: `${t('Overlay:costCenter')}:`,
            value: businessPartnerInfo.costCenter ? businessPartnerInfo.costCenter.displayName : '-'
        },
        {
            label: `${t('Overlay:jobFunction')}:`,
            value: (businessPartnerInfo.activities.map(item => <div key={item.id}>{item.displayName}</div>)) || '-'
        },
        {
            label: `${t('Overlay:contactData')}:`,
            value: (<>
                <div>{businessPartnerInfo.businessPhone || ''}</div>
                <div>{businessPartnerInfo.businessCellPhone || ''}</div>
                <div>{businessPartnerInfo.businessEmail}</div>
            </>)
        },
        {
            label: `${t('Overlay:login')}:`,
            value: businessPartnerInfo.userName || '-'
        },
        formatedDate(businessPartnerInfo.deactivationDate)
            ? {
                label: `${t('Overlay:inactiveSince')}:`,
                value: formatedDate(businessPartnerInfo.deactivationDate)
            }
            : {
                label: `${t('Overlay:activeSince')}:`,
                value: formatedDate(businessPartnerInfo.activationDate) ? formatedDate(businessPartnerInfo.activationDate) : '-'
            }
    ]

    if (loading.getInfo) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <Spinner animation='border' variant='primary' />
            </div>
        )
    }
    return (
        <>
            <span className="data-list__name h2">
                {selectedRow.displayName}
                <Link to={`/editemployee/${selectedRow.id}`}>
                    <PenIcon onClick={handleCloseOverlayRight} className="edit-button" />
                </Link>
            </span>
            {error.getInfo && <Alert variant="danger">{error.getInfo.value}</Alert>}
            {businessPartnerInfo === null
                ? <div className="d-flex align-items-center justify-content-center">
                    <Spinner animation='border' variant='primary' />
                </div>
                : <ul className="data-list__data">
                    {employeeDataArray.map((listItem, idx) => {
                        return (
                            <li key={`${idx} ${listItem.label}`} className='data-list__entry'>
                                <div className="data-list__label">{listItem.label}</div>
                                <div className="data-list__value">{listItem.value}</div>
                            </li>
                        )
                    })}
                </ul>
            }
        </>
    )
});

export default EmployeeData;
