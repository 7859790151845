import React from 'react'

export const PenIcon = ({ color, title, ...rest }) => {
  const useColor = color ? color : '#3C3C3B'
  return (
    <svg width="15px" height="15px" fill={useColor} viewBox="0 0 28 28" {...rest}>
      {title && <title>{title}</title>}
      <path d="M3.024 17.858L19.64 1.241c1.626-1.626 4.313-1.626 5.94 0l1.202 1.202c1.626 1.627 1.626 4.314 0 5.94L10.166 25l-7.142-7.142zm-2.97 8.485c-.07-.353-.07-.636 0-.848l1.131-5.798 7.142 7.141-5.798 1.132c-1.202.212-2.263-.566-2.475-1.627z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  )
}
