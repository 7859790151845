import React, { useState } from 'react'
import { getErrorMessageFromApiResponse } from '../../../utils/api/apiUtils'
import { ButtonWithLoadingState } from '../../Buttons'
import { playScanErrorSound } from '../../../utils/playSounds'

const MultipleConfigOptions = ({ multipleConfigOptions, setMultipleConfigOptions, setError, handleSingleConfigMatch }) => {
  const [loading, setLoading] = useState(false)
  const handleSave = async (option) => {
    try {
      setLoading(true)
      await handleSingleConfigMatch(option.articleConfiguration, option.article)
    }
    catch (e) {
      setError(getErrorMessageFromApiResponse(e))
      playScanErrorSound()
    } finally {
      setMultipleConfigOptions([])
    }
  }
  return multipleConfigOptions.map(option =>
  (
    <ButtonWithLoadingState
      key={option.articleConfiguration.id}
      className="w-100 m-2"
      buttonText={option.articleConfiguration.displayName}
      disabled={loading}
      onClick={() => handleSave(option)}
    />
  ))
}
export default MultipleConfigOptions
