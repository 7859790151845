import React from 'react'
import { LockOpenIcon } from '../../Icons/LockOpenIcon';
import { LockClosedIcon } from '../../Icons/LockClosedIcon';
import { DeleteIcon } from '../../Icons/DeleteIcon';
import { PenIcon } from '../../Icons/PenIcon';
import {
  StyledItemWrapper,
  StyledListItemText,
} from '../../TreeList/treeList';
import { t } from 'i18next';
import MediaQuery from 'react-responsive';
import MoreOptionsButton from '../../Buttons/MoreOptionsButton';
import { StyledContextMenu } from '../../ContextMenu/StyledContextMenu';
import { ContextMenuTrigger } from 'react-contextmenu';
import PortalContextMenu from '../../ContextMenu/PortalContextMenu';

const CabinTypeItem = ({ cabinType, openCabinTypeInfo, blockCabinItem, editRow, deleteProcess, selectedCabinTypeId }) => {
  const options = [
    {
      option: 'edit',
      optionText: t('edit'),
      optionIcon: <PenIcon />,
      onOptionSelect: () => editRow(cabinType.id)
    },
    {
      option: 'delete',
      optionText: t('deleteButton'),
      optionIcon: <DeleteIcon />,
      onOptionSelect: () => deleteProcess(cabinType.id)
    },
    cabinType.isBlocked
      ? {
        option: 'unlock',
        optionText: "Freigeben",
        optionIcon: <LockOpenIcon />,
        onOptionSelect: () => blockCabinItem(cabinType)
      }
      : {
        option: 'lock',
        optionText: "Sperren",
        optionIcon: <LockClosedIcon />,
        onOptionSelect: () => blockCabinItem(cabinType)
      }
  ]

  return (
    <StyledContextMenu>
      <ContextMenuTrigger id={`${cabinType.id}_context`} holdToDisplay={-1}>
        <StyledItemWrapper
          isClickable
          style={{
            boxShadow: cabinType.id === selectedCabinTypeId ? `inset 0px 0px 0px 2px` : null,
            backgroundColor: cabinType.isBlocked ? '#dbdbdb' : null,
          }}
          key={cabinType.id}
          onClick={() => openCabinTypeInfo(cabinType)}
        >
          <StyledListItemText>
            {cabinType.displayName}
          </StyledListItemText>
          <MediaQuery maxWidth={1499}>
            <div className="action-buttons"  >
              <MoreOptionsButton options={options} />
            </div>
          </MediaQuery>
        </StyledItemWrapper>
      </ContextMenuTrigger>
      <PortalContextMenu contextId={`${cabinType.id}_context`} options={options} />
    </StyledContextMenu>
  )
}

export default CabinTypeItem