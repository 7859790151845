import React from 'react'
import { Subheader } from '../../Page/logix2020/Subheader'
import SubheaderForm from '../../Page/logix2020/SubheaderForm'
import { useTranslation } from 'react-i18next'

import { useParams } from 'react-router-dom'



const SubheaderInventoryDetail = () => {
    const { t } = useTranslation()
    const foreignEmployeeId = useParams().employeeId
    return (
        <Subheader className="subHeader-v1 ">
            <div className="flex-grow-1">
                <SubheaderForm title={foreignEmployeeId ? "Ausstattung: Details" : "Meine Ausstattung: Details"} goBackLabel={t('backButton')} />
            </div>
        </Subheader>
    )
}

export default SubheaderInventoryDetail
