import React, { useCallback, useEffect, useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import Progress from '../Form/Progress'
import styled from 'styled-components'
import { useEmployeeDataState } from '../../../stores/EmployeeDataProvider'
import { ButtonWithLoadingState } from '../../Buttons'
import { useTranslation, Trans } from 'react-i18next'
import useStores from '../../../stores/useStore'
import { useObserver } from 'mobx-react'
import { useParams } from 'react-router-dom'

const StyledContainer = styled.div`
  @media screen and (min-width: 1081px) {
    position: sticky;
    top: 50px;
  }
`

const StyledCancelButton = styled(Button)`
  width: 100%;
  max-width: 250px;
`
const Sidebar = () => {

  const {
    submitEditEmployee,
    errorsOccurredOnSubmit,
    formDataHasChanged,
    resetFormData,
    setSuccessSubmit,
    successSubmit
  } = useEmployeeDataState()

  const { employeeId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const { laundryShelfStore } = useStores()
  const laundryDataHasChanged = useObserver(() => laundryShelfStore.dataHasChanged)
  const submitBPLoundryShelvesError = useObserver(() => laundryShelfStore.submitBPLoundryShelvesError)
  const successLoundryShelvesSubmit = useObserver(() => laundryShelfStore.successLoundryShelvesSubmit)

  const handleSubmit = useCallback(async e => {
    setSuccessSubmit(false)
    setIsLoading(true)
    formDataHasChanged && await submitEditEmployee()
    await laundryShelfStore.submitBPLoundryShelves(employeeId)
    setIsLoading(false)
  }, [employeeId, formDataHasChanged, laundryShelfStore, setSuccessSubmit, submitEditEmployee])

  const handleClickCancel = () => {
    resetFormData();
    laundryShelfStore.resetSelectedItems()
  }

  //auto remove success message after couple sec.
  useEffect(() => {
    let timeout = null
    if (!isLoading && (!!successSubmit || !!successLoundryShelvesSubmit)) {
      timeout = setTimeout(() => {
        setSuccessSubmit(false)
        laundryShelfStore.successLoundryShelvesSubmit = false
      }, 3000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [isLoading, successSubmit, successLoundryShelvesSubmit, setSuccessSubmit, laundryShelfStore])

  const { t } = useTranslation()

  return (
    <StyledContainer>
      <header>
        <div className="h2">{t('EmployeeFormSidebar:editTitle')}</div>
      </header>
      <div className="content">
        <ButtonWithLoadingState
          className="submit-btn mb-3"
          isLoading={isLoading}
          onClick={handleSubmit}
          disabled={isLoading || (!formDataHasChanged && !laundryDataHasChanged)}
          buttonText={t('OverlayProgress:save')}
          loadingText={t('OverlayProgress:save')}
        />
        {(formDataHasChanged || laundryDataHasChanged) && (
          <>
            <StyledCancelButton className="mb-3" variant="secondary" onClick={handleClickCancel}>{t('cancelButton')}</StyledCancelButton>
            <div className=" alert alert-info" >{t('EmployeeFormHealthData:dataChange')}</div>
          </>
        )}

        {(!isLoading && (!!errorsOccurredOnSubmit || !!submitBPLoundryShelvesError)) && (
          <Alert variant="danger">
            <Trans i18nKey='EmployeeFormSidebar:errorOccurred' />
          </Alert>
        )}
        {(!isLoading && (!!successSubmit || !!successLoundryShelvesSubmit)) && (
          <Alert variant="success">
            {t('EmployeeFormSidebar:dataSaved')}
          </Alert>
        )}
        <Progress />
      </div>
    </StyledContainer>
  )
}

export default Sidebar
