import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { OverlayLeft } from '../components/Page/logix2020/OverlayLeft'
import OverlayLeftFilter from '../components/Employees/OverlayLeftFilter'
import { Button, Spinner, Alert } from 'react-bootstrap'
import EmployeesTable from '../components/Employees/EmployeesTable'
import styled from 'styled-components'
import { Subheader } from '../components/Page/logix2020/Subheader'
import { useEmployeesState } from '../stores/EmployeesProvider'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ActiveTableFilters from '../components/Employees/ActiveTableFilters'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import BigFooter from '../components/Footer/BigFooter'
import useStores from '../stores/useStore'
import { useObserver } from 'mobx-react'

const StyledFilter = styled.div`
  display: flex;
  justify-content: space-between; 
  margin-bottom:20px;

  .btn {
    margin-left: 35px;
  }

 
`
const StyledSubheader = styled(Subheader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledContentArea = styled(ContentArea)`
  width: 100%;
  padding: 20px 30px 20px 36px;
  // height: 100% !important;
  @media only screen 
  and (max-width: 426px), (min-device-width: 320px) 
  and (max-device-width: 426px)  {
    padding:20px;
  }
`

const EmployeesOrderTablePageContent = () => {
  const { setShowAllEmployees, resetFilter, showOverlayLeft, setShowOverlayLeft, updateOverlayLeftStates } = useEmployeesState()
  const [selectedEmployee, setSelectedEmployee] = useState()
  const { t } = useTranslation()

  const currentLocation = useLocation()

  const onRowClick = (e, row) => {
    setSelectedEmployee(row)
  }

  const handleCloseOverlayLeft = e => {
    setShowOverlayLeft(false)
    updateOverlayLeftStates()
  }

  if (selectedEmployee) {
    return (
      <Navigate to={`/productjobfunction/${selectedEmployee.id}`} state={{ from: currentLocation.pathname }} />
    )
  }

  return (
    <div>
      <StyledSubheader className="subHeader-v1">
        <div className="subHeader-v1">
          <div className="subheader-title">{t('header:order')}</div>
        </div>
      </StyledSubheader>

      <OverlayLeft className={`overlay-variant ${showOverlayLeft ? 'visible' : ''}`}>
        <div className="header" onClick={handleCloseOverlayLeft}>
          <span onClick={handleCloseOverlayLeft}><i className="close-icon" /></span>
          <span className="header-title">Filter</span>
        </div>
        <div className="body">
          <OverlayLeftFilter />
        </div>
      </OverlayLeft>

      <StyledContentArea>
        <StyledFilter>
          <div className="d-flex flex-wrap align-items-baseline">
            <Button variant="secondary" className="m-0" onClick={() => {
              setShowOverlayLeft(prev => !prev)
            }}>{t('allFilters')}</Button>
          </div>
          <div className="d-flex flex-wrap mb-2 align-items-baseline">
            <Button variant="secondary" className="btn-extra-padding" onClick={() => {
              resetFilter()
              setShowAllEmployees(true)
            }}>{t('employeesPage:showAllEmployees')}</Button></div>
        </StyledFilter>
        <ActiveTableFilters />
        <EmployeesTable onRowClick={onRowClick} noActions tableId="EmployeesOrder" />
      </StyledContentArea>
      <BigFooter />
    </div>
  )
}
const EmployeesOrderTablePage = () => {
  const { uiStore } = useStores()
  const { t } = useTranslation()
  const [error, setError] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setError(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return useObserver(() => (
    <>
      {!uiStore.employeeOrderAvailable
        ? (
          <>
            <StyledSubheader className="subHeader-v1">
              <div className="subHeader-v1">
                <div className="subheader-title">{t('header:order')}</div>
              </div>
            </StyledSubheader>
            {error
              ? <Alert variant="danger">{t('employeesPage:accessDenied')}</Alert>
              : <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
            }
          </>
        )
        : <EmployeesOrderTablePageContent />
      }
    </>
  ))
}
export default EmployeesOrderTablePage
