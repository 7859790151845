import React from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { StyledCard } from '../Cards/StyledCard'
import { Col, Form } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import ArraySelect from '../Select/ArraySelect'
import ZipSuggestion from '../Forms/ZipSuggestion'
import { getFakeEvent } from '../../utils/utils'


const StyledAddressCard = styled.div`
  width: 100%;
  padding: 10px; 
  
  @media screen and (min-width: 768px) {
    width: 50%;
    display: flex;
    
    > div {
      flex-grow: 1;
    }
  }
`

const StyledCustomAddressCard = styled(StyledAddressCard)`
  @media screen and (min-width: 768px) {
    width: 100%;
  }
`

const StyledTitle = styled.span`
  letter-spacing: 0.8px;
`

const StyledCWSForm = styled.div`
  input {
    outline: none;
    border:none;
    border-bottom:1px solid black;
    border-radius:0;
  }
  select {
    outline: none;
    border:none;
    border-bottom:1px solid black;
    border-radius:0;
  }

`


const CustomShipmentAddress = inject('productsStore', 'checkoutStore')(observer((props) => {
  const { t, productsStore, checkoutStore } = props
  const mandate = process.env.REACT_APP_MANDATORSET

  if (!checkoutStore.isCustomShipmentAddressAvailable()) {
    return null
  }

  const handleCustomShipmentAddressCardClick = () => {
    productsStore.setCustomShipmentAddressSelected(true)
  }

  const renderShipmentAddressForm = () => {
    const { customShipmentAddress, customAddressSettings } = productsStore


    return (
      <Form name='customShipmentAddress'>
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:company')}</Form.Label>
          <Form.Control type='text' placeholder={t('formularFields:company')}
            value={customShipmentAddress.officeName} name='officeName'
            onChange={productsStore.handleChangeCustomShipmentAddress}
            isInvalid={productsStore.requiredFieldsError.customShipmentAddress.officeName}
            maxLength={customAddressSettings.companyMaxChars ? customAddressSettings.companyMaxChars : null}

          />
        </Form.Group>
        {!customAddressSettings.addressHideFirstname ? <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:firstname')} <span className='text-primary'>*</span></Form.Label>
          <Form.Control type='text' placeholder={t('formularFields:firstname')}
            value={customShipmentAddress.firstName} name='firstName'
            onChange={productsStore.handleChangeCustomShipmentAddress}
            isInvalid={productsStore.requiredFieldsError.customShipmentAddress.firstName}
            maxLength={customAddressSettings.nameMaxChars ? customAddressSettings.nameMaxChars : null}
          />
        </Form.Group> : null}
        {!customAddressSettings.addressHideLastname ? <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:lastname')} <span className='text-primary'>*</span></Form.Label>
          <Form.Control type='text' placeholder={t('formularFields:lastname')}
            value={customShipmentAddress.lastName} name='lastName'
            onChange={productsStore.handleChangeCustomShipmentAddress}
            isInvalid={productsStore.requiredFieldsError.customShipmentAddress.lastName}
            maxLength={customAddressSettings.nameMaxChars ? customAddressSettings.nameMaxChars : null}
          />
        </Form.Group> : null}
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:street')} <span className='text-primary'>*</span></Form.Label>
          {/* <Form.Row className='mb-2'> */}
          <Form.Control type='text' placeholder={t('formularFields:street')}
            value={customShipmentAddress.street} name='street'
            onChange={productsStore.handleChangeCustomShipmentAddress}
            isInvalid={productsStore.requiredFieldsError.customShipmentAddress.street}
            maxLength={customAddressSettings.streetMaxChars ? customAddressSettings.streetMaxChars : null}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:housenumber')} <span className='text-primary'>*</span></Form.Label>
          <Form.Control type='text' placeholder={t('formularFields:housenumber')}
            value={customShipmentAddress.streetNr} name='streetNr'
            onChange={productsStore.handleChangeCustomShipmentAddress}
            isInvalid={productsStore.requiredFieldsError.customShipmentAddress.streetNr} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:additionalAddress')} <span className='text-primary'></span></Form.Label>
          <Form.Control type='text' placeholder={t('formularFields:additionalAddress')}
            value={customShipmentAddress.careOf} name='careOf'
            onChange={productsStore.handleChangeCustomShipmentAddress}
            maxLength={customAddressSettings["c/oMaxChars"] ? customAddressSettings["c/oMaxChars"] : null}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:zip')} <span className='text-primary'>*</span></Form.Label>
          <ZipSuggestion
            type={customAddressSettings.zipcodeLimitNumericValues ? 'number' : 'text'}
            placeholder={t('formularFields:zip')}
            value={customShipmentAddress.zipCode} name='zipCode'
            onChange={productsStore.handleChangeCustomShipmentAddress}
            isInvalid={productsStore.requiredFieldsError.customShipmentAddress.zipCode}
            onChangeSuggestion={obj => productsStore.handleChangeCustomShipmentAddress(getFakeEvent("city", obj.cityName, "text"))}
            countryIsoCode={customShipmentAddress.countryIsoCode || "de"}
            maxLength={customAddressSettings.zipcodeMaxChars ? customAddressSettings.zipcodeMaxChars : null}

          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:city')} <span className='text-primary'>*</span></Form.Label>
          <Form.Control type='text' placeholder={t('formularFields:city')}
            value={customShipmentAddress.city} name='city'
            onChange={productsStore.handleChangeCustomShipmentAddress}
            isInvalid={productsStore.requiredFieldsError.customShipmentAddress.city}
            maxLength={customAddressSettings.cityMaxChars ? customAddressSettings.cityMaxChars : null}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('formularFields:country')}</Form.Label>
          <ArraySelect
            placeholder={t('formularFields:country')}
            value={customShipmentAddress.countryIsoCode}
            name='countryIsoCode'
            options={checkoutStore.shippingCountries}
            onChange={productsStore.handleChangeCustomShipmentAddress}
            isInvalid={productsStore.requiredFieldsError.customShipmentAddress.countryIsoCode}
            otherValueKey="isoCode"
            returnEvent
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            label={t('confirmAddress:updateAddress')}
            name='updateUserAddress'
            onChange={productsStore.handleChangeCustomShipmentAddress}
            checked={customShipmentAddress.updateUserAddress}
            id='updateUserAddress'
            isInvalid={productsStore.requiredFieldsError.customShipmentAddress.updateUserAddress}
          />
        </Form.Group>
      </Form>
    )
  }


  return (
    <React.Fragment>
      <StyledCustomAddressCard>
        <StyledCard withCheckbox={true} active={productsStore.customShipmentAddressSelected}
          onClick={() => handleCustomShipmentAddressCardClick()}>
          <StyledCard.Body className='mt-3'>
            <StyledCard.Body.Title><StyledTitle><small className='text-primary text-uppercase fw-bold'>{t('confirmAddress:customShippingAddress')}</small></StyledTitle></StyledCard.Body.Title>
          </StyledCard.Body>
        </StyledCard>
      </StyledCustomAddressCard>
      {productsStore.customShipmentAddressSelected ?
        <Col className='mt-3'>
          {mandate === "cws" ?
            <StyledCWSForm>
              {renderShipmentAddressForm()}
            </StyledCWSForm>
            : renderShipmentAddressForm()
          }
        </Col>
        : ''}
    </React.Fragment>
  )
}))

export default withTranslation()(CustomShipmentAddress)
