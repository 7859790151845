import React from 'react'
import { StyledMessageHeader } from './StyledChat'
import UserInitials from '../../Header/UserInitials'
import { formatedDate } from '../../../utils/formatedDate'

const MessageHeader = ({ message }) => {
  return (
    <StyledMessageHeader>
      <UserInitials username={message.createdByDisplayName} className="me-3" />
      <b>{message.createdByDisplayName}</b>
      <div className="ms-3" style={{ color: "gray" }}><b><small >{formatedDate(message.createdAt, true).slice(0, -4)}</small></b></div>
    </StyledMessageHeader>
  )
}

export default MessageHeader