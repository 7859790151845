import React from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { BiExitFullscreen, BiFullscreen, BiX } from 'react-icons/bi'

const EditorModalHeaderButtons = ({ isFullScreen, handleFullScreen, handleClose }) => {
  return (
    <ButtonToolbar className="ms-auto">
      {isFullScreen ?
        <Button variant="light" onClick={handleFullScreen}>
          <BiExitFullscreen size="1.5em" />
        </Button>
        : <Button variant="light" onClick={handleFullScreen}>
          <BiFullscreen size="1.5em" />
        </Button>}
      <Button variant="light" onClick={handleClose}>
        <BiX size="1.5em" />
      </Button>
    </ButtonToolbar>
  )
}

export default EditorModalHeaderButtons