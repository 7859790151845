import React, { useState, useEffect } from 'react'
import { Col, Row, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { StyledRequiredFormLabel } from '../shared/FormStyles'
import { useTranslation } from 'react-i18next'
import ArraySelect from '../../Select/ArraySelect'
import ZipSuggestion from '../../Forms/ZipSuggestion'
import { getFakeEvent } from '../../../utils/utils'
import { useRef } from 'react'

const StyledFormRow = styled(Row)`
  :not(:last-child) {
    margin-bottom: 10px;
  }
`

const ReturnAddressForm = (
  {
    onChange,
    countries = [],
    contactData = {}
  }
) => {
  const { t } = useTranslation()
  const formRef = useRef(null);
  const [formData, setFormData] = useState(() => {
    const defaultShipmentAddress = contactData.shipmentAddress ? contactData.shipmentAddress : {}
    return ({
      email: contactData.email || '',
      company: defaultShipmentAddress.officeName || '',
      salutation: '',
      firstName: defaultShipmentAddress.firstName || '',
      lastName: defaultShipmentAddress.lastName || '',
      street: defaultShipmentAddress.street || '',
      houseNumber: defaultShipmentAddress.streetNr || '',
      careOf: defaultShipmentAddress.careOf || '',
      zip: defaultShipmentAddress.zipCode || '',
      city: defaultShipmentAddress.city || '',
      country: defaultShipmentAddress.countryIsoCode || '',
      phone: contactData.phone || ''
    })
  })
  const [validated, setValidated] = useState(false)

  /**
   * Handle change events for every input and add the new value to formData state
   * @param event
   */
  function handleInputChange(event) {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  useEffect(() => {
    if (formRef.current.checkValidity()) {
      //set validated true if initial data is already valid
      setValidated(true)
    }
    onChange(formData, validated)
  }, [formData, onChange, validated])

  return (
    <Form
      validated={validated}
      ref={formRef}
    >
      <Form.Group className="mb-3">
        <Form.Label>{t('formularFields:company')}</Form.Label>
        <Form.Control
          type='text'
          value={formData.company}
          placeholder={t('formularFields:company')}
          onChange={handleInputChange}
          name='company'
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <StyledRequiredFormLabel>{t('formularFields:firstLastname')}</StyledRequiredFormLabel>
        <Row>
          <Col>
            <Form.Control
              type='text'
              value={formData.firstName}
              placeholder={t('formularFields:firstname')}
              onChange={handleInputChange}
              name='firstName'
              required
            />
          </Col>
          <Col>
            <Form.Control
              type='text'
              value={formData.lastName}
              placeholder={t('formularFields:lastname')}
              onChange={handleInputChange}
              name='lastName'
              required
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3">
        <StyledRequiredFormLabel>{t('formularFields:streetHousenumber')}</StyledRequiredFormLabel>
        <StyledFormRow>
          <Col>
            <Form.Control
              type='text'
              value={formData.street}
              placeholder={t('formularFields:street')}
              onChange={handleInputChange}
              name='street'
              required
            />
          </Col>
          <Col xs lg='3'>
            <Form.Control
              type='text'
              value={formData.houseNumber}
              placeholder={t('formularFields:housenumber')}
              onChange={handleInputChange}
              name='houseNumber'
              required
            />
          </Col>
        </StyledFormRow>
        <StyledFormRow>
          <Col>
            <Form.Control
              type='text'
              value={formData.careOf}
              placeholder={t('formularFields:CAREOF')}
              onChange={handleInputChange}
              name='careOf'
            />
          </Col>
        </StyledFormRow>
      </Form.Group>
      <Form.Group className="mb-3">
        <StyledRequiredFormLabel>{t('formularFields:zipCity')}</StyledRequiredFormLabel>
        <Row>
          <Col>
            <ZipSuggestion
              type='text'
              value={formData.zip}
              placeholder={t('formularFields:zip')}
              onChange={handleInputChange}
              name='zip'
              required
              onChangeSuggestion={obj => handleInputChange(getFakeEvent("city", obj.cityName, "text"))}
              countryIsoCode={formData.country || "de"}
            />
          </Col>
          <Col>
            <Form.Control
              type='text'
              value={formData.city}
              placeholder={t('formularFields:city')}
              onChange={handleInputChange}
              name='city'
              required
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3">
        <StyledRequiredFormLabel>{t('formularFields:country')}</StyledRequiredFormLabel>
        <ArraySelect
          value={formData.country}
          onChange={handleInputChange}
          name='country'
          required
          options={countries}
          returnEvent
          otherValueKey="isoCode"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <StyledRequiredFormLabel>{t('email')}</StyledRequiredFormLabel>
        <Form.Control
          type='email'
          value={formData.email}
          placeholder='name@example.org'
          onChange={handleInputChange}
          name='email'
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <StyledRequiredFormLabel>{t('formularFields:telnumber')}</StyledRequiredFormLabel>
        <Form.Control
          type='phone'
          value={formData.phone}
          placeholder='0123456789'
          onChange={handleInputChange}
          name='phone'
          required
        />
      </Form.Group>
    </Form >
  )
}

export default ReturnAddressForm
