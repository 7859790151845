import i18n from '../translation/i18n';
import { emptyGuid } from './emptyGuid'

const getPreparedLocalStorehouses = (localStorehouses) => {
    if (!localStorehouses) {
        return null
    }
    let preparedLocalStorehouses = []
    let preparedLocalStorehouse = {
        id: emptyGuid,
        displayName: i18n.t('pleaseSelect'),
    }
    preparedLocalStorehouses.push(preparedLocalStorehouse)
    localStorehouses.forEach(sth => {
        const displayName = sth.fQ ? sth.displayName+' ('+i18n.t('quantity')+': '+sth.fQ+')' : sth.displayName
        preparedLocalStorehouse = {
            id: sth.id,
            displayName: displayName,
        }
        preparedLocalStorehouses.push(preparedLocalStorehouse)
    })
    return preparedLocalStorehouses
}
export default getPreparedLocalStorehouses