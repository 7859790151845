import styled from 'styled-components'
import { colors } from '../../../styles/colors'

export const OverlayLeft = styled.div`

  background: ${colors.gray6};
  border-right: 1px solid ${colors.gray2};
  overflow: auto;
  color: ${colors.gray5};

  .body{
    padding: 36px 20px 20px 36px;s
  }
  .header {
    position: sticky;
    top: 0;
    background: ${colors.gray7};
    color: white;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    z-index: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
  
    .header-title {
      font-size: 18px;
      font-weight: normal;
      color: #FFF;
      display: inline-block;
      margin-left: 20px;
    }
  
    .close-icon {
      width: 18px;
      height: 18px;
      display: inline-block;
      opacity: 1;
      position: relative;
      cursor: pointer;
      margin-top: -3px;
  
      :hover {
        opacity: .7;
      }
  
      :before,
      :after {
        content: ' ';
        position: absolute;
       left: calc(50% - 1px);
         height: 100%;
        width: 2px;
        background-color: #fff;
      }
      :before {
        transform: rotate(45deg);
      }
      :after {
        transform: rotate(-45deg);
      }
    }
  }

  @media screen and (min-width: 1080px) {
    left: 0;
    top: ${props => props.noSubheader ? '50px' : '140px'};
    z-index: 1;
    width: 30vw;
    max-width: 530px;
    height: calc(100% - ${props => props.noSubheader ? '50px' : '140px'});
    position: fixed;
  }

  &.overlay-variant {
    left: 0;
    z-index: 5;
    height: calc(100% - 140px);
    transform: translateX(-100%);
    transition: transform .2s ease-in-out;
    position: absolute;
    top: auto;
    width: 100vw;
    @media screen and (min-width: 421px) {
      min-width: 420px;
      width: 30vw;
    }


    .close-btn {
      cursor: pointer;
      margin: 0 0 40px 0;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      span {
        margin-right: 5px;
      }
    }

    &.visible {
      transform: translateX(0);
    }
  }

  .content {
    flex-grow: 1;

    ul,
    .list-group {
      list-style: none;

      li,
      .list-group-item {
        display: flex;
        align-items: center;
        font-size: 18px;
        word-break: break-all;
      }
    }
  }

  footer {
    display: flex;

    .btn {
      margin: 20px auto;
    }
  }
`
