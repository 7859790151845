import React from 'react'

const StructureIcon = () => {
  return (
    <svg width="38px" height="38px" viewBox="0 0 36 36">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-733.000000, -238.000000)">
          <g transform="translate(733.000000, 238.000000)">
            <rect fill="#706F6F" x="0" y="0" width="36" height="36" rx="6" />
            <path d="M16,22 C16.5522847,22 17,22.4477153 17,23 L17,23.8181818 C17,24.3704666 16.5522847,24.8181818 16,24.8181818 L15,24.8181818 C14.4477153,24.8181818 14,24.3704666 14,23.8181818 L14,23 C14,22.4477153 14.4477153,22 15,22 L16,22 Z M24.0909091,22.5 C24.5929861,22.5 25,22.9070139 25,23.4090909 C25,23.911168 24.5929861,24.3181818 24.0909091,24.3181818 L18.9090909,24.3181818 C18.4070139,24.3181818 18,23.911168 18,23.4090909 C18,22.9070139 18.4070139,22.5 18.9090909,22.5 L24.0909091,22.5 Z M16,17 C16.5522847,17 17,17.4477153 17,18 L17,18.8181818 C17,19.3704666 16.5522847,19.8181818 16,19.8181818 L15,19.8181818 C14.4477153,19.8181818 14,19.3704666 14,18.8181818 L14,18 C14,17.4477153 14.4477153,17 15,17 L16,17 Z M24.0909091,17.5 C24.5929861,17.5 25,17.9070139 25,18.4090909 C25,18.911168 24.5929861,19.3181818 24.0909091,19.3181818 L18.9090909,19.3181818 C18.4070139,19.3181818 18,18.911168 18,18.4090909 C18,17.9070139 18.4070139,17.5 18.9090909,17.5 L24.0909091,17.5 Z M11,12 C11.5522847,12 12,12.4477153 12,13 L12,13.8181818 C12,14.3704666 11.5522847,14.8181818 11,14.8181818 L10,14.8181818 C9.44771525,14.8181818 9,14.3704666 9,13.8181818 L9,13 C9,12.4477153 9.44771525,12 10,12 L11,12 Z M24.0909091,12.5 C24.5929861,12.5 25,12.9070139 25,13.4090909 C25,13.911168 24.5929861,14.3181818 24.0909091,14.3181818 L13.9090909,14.3181818 C13.4070139,14.3181818 13,13.911168 13,13.4090909 C13,12.9070139 13.4070139,12.5 13.9090909,12.5 L24.0909091,12.5 Z" fill="#FFFFFF" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default StructureIcon
