import React, { useState } from 'react'
import useAppointmentTypesState from '../../../stores/Configuration/useAppointmentTypesState'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useObserver } from 'mobx-react'
import ModalComponent2 from '../../Modals/ModalComponent2'
import OverlayRightAppointmentTypeInfo from '../../Configuration/AppointmentType/OverlayRightAppointmentTypeInfo';
import AppointmentTypesItem from './AppointmentTypesItem'

const StyledWrapper = styled.div`
@media screen and (min-width: 1080px) {
  max-width: calc(100% - 480px);
  min-width: 600px;
}
`

const AppointmentTypesOverview = () => {
    const { t } = useTranslation()
    const {
        appointmentTypes,
        handleDelete,
        checkIfDeletable,
        error,
        setError,
        selectedAppointmentType,
        setSelectedAppointmentType,
        idAppointmentType,
        setIdAppointmentType,
        btnLoading
    } = useAppointmentTypesState()
    const navigate = useNavigate();
    const [deletable, setDeletable] = useState(false)
    const [showAppointmentTypeInfoOverlay, setShowAppointmentTypeInfoOverlay] = useState(false)

    const openAppointmentTypeInfo = appointmentType => {
        setSelectedAppointmentType(appointmentType);
        setShowAppointmentTypeInfoOverlay(true);
    }

    const editRow = (appointmentId) => {
        let path = `/appointmenttypes/edit/${appointmentId}#general`;
        navigate(path);
    }

    const deleteProcess = async (id) => {
        setError("")
        const isDeletable = await checkIfDeletable(id)
        setIdAppointmentType(id)
        setDeletable(isDeletable)
    }

    return useObserver(() => (
        <StyledWrapper>
            {
                !appointmentTypes.length &&
                <div className='d-flex justify-content-center' >
                    <Spinner animation='border' variant='primary' />
                </div>
            }
            {appointmentTypes.map((appointmentType) => (
                <AppointmentTypesItem
                    key={appointmentType.id}
                    editRow={editRow}
                    deleteProcess={deleteProcess}
                    appointmentType={appointmentType}
                    selectedAppointmentType={selectedAppointmentType}
                    openAppointmentTypeInfo={openAppointmentTypeInfo}
                />
            ))}

            <OverlayRightAppointmentTypeInfo
                visible={showAppointmentTypeInfoOverlay}
                onClose={() => setShowAppointmentTypeInfoOverlay(false)}
                selectedAppointmentTypeId={selectedAppointmentType.id}
            />

            {idAppointmentType && !deletable && (<ModalComponent2
                ModalTitle={t('appointmentTypes:deleteTypeRecoursive')}
                BodyPart1={t('appointmentTypes:areYouSureRecoursive')}
                bodyStyle="alert alert-danger"
                functionCancel={() => { setIdAppointmentType(null) }}
                button1Text={t('appointmentTypes:deleteTypeRecoursive')}
                button1Function={(cancellationMessage) => { handleDelete(idAppointmentType, cancellationMessage) }}
                button1Variant="danger"
                button1Loading={btnLoading}
                inputType="textarea"
                inputLabel={t('cancelMessage')}
                inputIsMandatory
                error={error}
            />)}

            {idAppointmentType && deletable && (<ModalComponent2
                ModalTitle={t('appointmentTypes:deleteType')}
                BodyPart1={t('appointmentTypes:areYouSure')}
                bodyStyle="alert alert-danger"
                functionCancel={() => { setIdAppointmentType(null) }}
                button1Text={t('delete')}
                button1Function={() => { handleDelete(idAppointmentType) }}
                button1Variant="danger"
                button1Loading={btnLoading}
                error={error}
            />)}
        </StyledWrapper>
    ))
}

export default AppointmentTypesOverview
