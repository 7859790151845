import React from 'react'
import styled from 'styled-components'
import { LostIcon, RemovedIcon, StoredIcon } from './PositionIcons'
import { t } from 'i18next'
const StyledIcons = styled.div`
  display: flex;
 >svg{
  margin:0 .5rem;
 }

`
const HeaderIcons = ({ actionToPerform }) => {
  let stored = actionToPerform === "store"
  let removed = actionToPerform === "dispose"
  let lost = actionToPerform === "markLost"

  return (
    <StyledIcons>
      <StoredIcon width="35px" height="35px" title={t('complaints:stored')} isActive={stored} />
      <RemovedIcon width="35px" height="35px" title={t('complaints:removed')} isActive={removed} />
      <LostIcon width="35px" height="35px" title={t('complaints:lost')} isActive={lost} />
    </StyledIcons>
  )
}

export default HeaderIcons