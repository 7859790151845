import React, { useState } from 'react'
import { OverlayLeft } from '../components/Page/logix2020/OverlayLeft'
import OverlayLeftFilter from '../components/Employees/OverlayLeftFilter'
import { Button } from 'react-bootstrap'
import EmployeesTable from '../components/Employees/EmployeesTable'
import styled from 'styled-components'
import { Subheader } from '../components/Page/logix2020/Subheader'
import { useEmployeesState } from '../stores/EmployeesProvider'
import { useTranslation } from 'react-i18next'
import ActiveTableFilters from '../components/Employees/ActiveTableFilters'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import BigFooter from '../components/Footer/BigFooter'
import MediaQuery from 'react-responsive'
import { ExcelButton } from '../components/Buttons/ExcelButton'

import CreateNewExportOverlay from '../components/Files/CreateNewExportOverlay'
import CreateNewImportOverlay from '../components/Files/CreateNewImportOverlay'

const StyledSubheader = styled(Subheader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledContentArea = styled(ContentArea)`
  width: 100%;
  padding: 20px 30px 20px 36px;
  // height: 100% !important;
 .blub{display:felx; flex-wrap: wrap; align-items: baseline ;}

  @media only screen  and (max-width: 767px), (min-device-width: 320px)  and (max-device-width: 767px)  {
    .mobile-test{ width: 100%;margin-top:0.5rem;}
    .blub{display:block; flex-wrap: nowrap; align-items: stretch ;}
  }
  
  .filterSpaceBetween{
    margin-bottom:20px;

    @media only screen  and (min-width: 768px) {
      display: flex;
      justify-content: space-between; 
  
    }
  }
`

const BulkOrderPage = () => {
    const { setShowAllEmployees, resetFilter, showOverlayLeft, setShowOverlayLeft, updateOverlayLeftStates, handleLocalChangeCheckbox, selectedCheckBoxes } = useEmployeesState()
    const { t } = useTranslation()
    const onRowClick = (e, row) => {
        handleLocalChangeCheckbox(row.id)
    }
    const [showExportOverlay, setShowExportOverlay] = useState(false)
    const [showImportOverlay, setShowImportOverlay] = useState(false)
    const handleCloseOverlayLeft = e => {
        setShowOverlayLeft(false)
        updateOverlayLeftStates()
    }
    const onCloseOverlayClick = () => {
        setShowExportOverlay(false)
        setShowImportOverlay(false)
    }
    return (
        <div>
            <StyledSubheader className="subHeader-v1">
                <div className="subheader-title">{t('header:bulkOrder')}</div>
                <MediaQuery minWidth={768}>
                    <div>
                        <ExcelButton variant="secondary" onClick={() => { setShowExportOverlay(true) }} buttonText={`${t('export')} ${selectedCheckBoxes.length > 0 ? `(${selectedCheckBoxes.length})` : ""}`} />
                        <ExcelButton variant="secondary" className="ms-3" onClick={() => { setShowImportOverlay(true) }} buttonText={t('import')} />
                    </div>
                </MediaQuery>
            </StyledSubheader>
            <OverlayLeft className={`overlay-variant ${showOverlayLeft ? 'visible' : ''}`}>
                <div className="header" onClick={handleCloseOverlayLeft}>
                    <span onClick={handleCloseOverlayLeft}><i className="close-icon" /></span>
                    <span className="header-title">Filter</span>
                </div>
                <div className="body">
                    <OverlayLeftFilter />
                </div>
            </OverlayLeft>
            <StyledContentArea>
                <MediaQuery maxWidth={767}>
                    <Button variant="secondary" className="btn-extra-padding w-100" onClick={() => { setShowExportOverlay(true) }}>
                        {t('export')} {selectedCheckBoxes.length > 0 && `(${selectedCheckBoxes.length})`}
                    </Button>
                    <Button variant="secondary" className="btn-extra-padding w-100 " onClick={() => { setShowImportOverlay(true) }}>
                        {t('import')}
                    </Button>
                </MediaQuery>
                <div className="filterSpaceBetween">
                    <div className="blub">
                        <Button variant="secondary" className="mobile-test " onClick={() => { setShowOverlayLeft(prev => !prev) }}>
                            {t('allFilters')}
                        </Button>
                    </div>
                    <div className="blub">
                        <Button variant="secondary" className="mobile-test btn-extra-padding" onClick={() => { resetFilter(); setShowAllEmployees(true) }}>
                            {t('employeesPage:showAllEmployees')}
                        </Button>
                    </div>
                </div>
                <ActiveTableFilters />
                <EmployeesTable onRowClick={onRowClick} noActions withCheckbox tableId="BulkOrder" />
            </StyledContentArea>
            <BigFooter />

            <CreateNewExportOverlay exportName="orderExport" visible={showExportOverlay} onClose={onCloseOverlayClick} extraFormData={{ partnerIDs: selectedCheckBoxes }} />
            <CreateNewImportOverlay importName="orderImport" visible={showImportOverlay} onClose={onCloseOverlayClick} />
        </div>
    )
}

export default BulkOrderPage
