import React from 'react'
import { Button } from 'react-bootstrap'

import { BiBarcodeReader } from "react-icons/bi";



const ArticleScanButton = (props) => {


  return (
    <Button {...props} ><BiBarcodeReader className="me-2" size={22} /> Artikel Scan</Button>

  )
}

export default ArticleScanButton