import React, { useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import styled from 'styled-components'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { RiZoomOutFill, RiZoomInFill } from 'react-icons/ri'
import { ScaleIcon } from '../Icons/ScaleIcon'
import { useAppointmentsAdministrationState } from '../../stores/Administration/AppointmentsAdministrationProvider'
import i18n from '../../translation/i18n'
import ScrollWrapper from '../Page/logix2020/ScrollWrapper'




const StyledCalendarHeader = styled.div`
  height: 3rem;
  background-color: #518600;
  color: white;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 0.5rem;
  z-index: 6;
  position: sticky;
  top: 0;

  .arrowIcon {
    color: white;
    font-size: 1.4rem;
    display: flex;
    align-self: center;

    &:hover {
      color: #a6e144;
      cursor: pointer;
    }
  }

  .zoomIcon {
    font-size: 1.2rem;
    margin: 0 5px;

    &:hover {
      color: #a6e144;
      cursor: pointer;
    }
  }
`

const StyledCalendarBody = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledCalendarRow = styled.div`
  display: flex;
  border-top: 1px dotted #c6c6c6;
  height: ${props => props.isZoomedIn ? '180px' : '60px'};
`

const StyledHourBlock = styled.div`
  width: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
`

const StyledEventsBlock = styled.div`
  flex-grow: 1;
  position: relative;
  border-left: 1px dotted #c6c6c6;
`

const StyledEvent = styled.div`
  position: absolute;
  height: ${props => props.eventDuration};
  width: 100%;
  // width: ${props => props.eventWidth};
  // left: ${props => props.eventLeft};
  top: ${props => props.eventStart};
  z-index: 5;
  background-color: #c5c3c3;
  color: black;
  border-top: 1px solid white;
  border-radius: 5px;
  padding: 0 5px;
  font-size: ${props => props.fontSize};
  text-align: start;

  &:hover {
    cursor: pointer;
    filter: brightness(0.8);
  }
`

const StyledScaleButton = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 40px;
  border: none;
  background-color: transparent;
  path {
    fill: white;
  }
  path:hover {
    fill: #a6e144;
  }

  .bubble {
    background: #a6e144;
    font-size: 8px;
    border-radius: 50px;
    height: 13px;
    width: 13px;
    text-align: center;
    line-height: 1.2;
    color: white;
    position: absolute;
    display: flex;
    bottom: 9px;
    right: 1px;
    align-items: center;
    justify-content: center;
  }
`


const DailyAppointmentsOverview = (props) => {
  const {
    date,
    selectedDateHoursArray,
    showSimpleHeader,
    setSelectedAction
  } = props;

  const {
    selectedDays,
    setAppointmentDetails,
    setSelectedDate,
    setShowDailyAppointmentsCompareModal,
    setShowOverviewOverlay
  } = useAppointmentsAdministrationState()

  const [isZoomedIn, setIsZoomed] = useState(false);

  // formats the selected date from 2021-10-25 to '25 October, 2021' (translates based on locale language)
  const formatSelectedDate = (selectedDate) => {
    let date = new Date(selectedDate);
    let dateString = date.toLocaleString(i18n.language, {
      month: 'long', day: '2-digit',
      year: 'numeric'
    });

    return (
      <div>
        <span style={{ fontWeight: "bold" }}>
          {dateString}
        </span>
      </div>
    );
  };


  const createEventBubble = (event, startTime, endTime, indexRow, indexEvent) => {
    let startHour = parseInt(startTime.substring(11, 13));
    let startMinute = parseInt(startTime.substring(14, 16));
    let endHour = parseInt(endTime.substring(11, 13));
    let endMinute = parseInt(endTime.substring(14, 16));

    let eventStart = isZoomedIn ? startMinute * 3 : startMinute
    let eventDuration = isZoomedIn
      ? (endHour * 60 + endMinute - (startHour * 60 + startMinute)) * 3
      : endHour * 60 + endMinute - (startHour * 60 + startMinute);
    let fontSize = eventDuration > 20 ? '12px' : '7px';

    // eventWidth and eventLeft can be used if we want to show the events as a scale ( staircase layout `-_ )
    // let eventWidth = 100 / selectedDateHoursArray[indexRow].length;
    // eventLeft = indexEvent * eventWidth;

    const popover = (
      <Popover id="popover-basic" style={{ pointerEvents: "none" }}>
        <Popover.Header as="h3">
          {formatSelectedDate(date)}
        </Popover.Header>
        <Popover.Body>
          <span>
            {startTime.substring(11, 16)} - {endTime.substring(11, 16)}
          </span>
        </Popover.Body>
      </Popover>
    );

    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        key={indexEvent}
        placement="top"
        overlay={popover}
      >
        <StyledEvent
          key={indexEvent}
          fontSize={fontSize}
          eventStart={`${eventStart}px`}
          eventDuration={`${eventDuration}px`}
          onClick={() => !showSimpleHeader && onEventClickHandler(event)}
        // eventWidth={`${eventWidth}%`}
        // eventLeft={`${eventLeft}%`}
        >
          {eventDuration >= 9 && (
            <span>
              {startTime.substring(11, 16)} - {endTime.substring(11, 16)}
            </span>
          )}
        </StyledEvent>
      </OverlayTrigger>
    );
  };

  const onEventClickHandler = (event) => {
    setShowOverviewOverlay();
    setSelectedAction('');
    setAppointmentDetails(event);
  }

  const goToPreviousDay = () => {
    let currentDateIndex = selectedDays.findIndex(day => day[0].includes(date));
    if (currentDateIndex > 0) {
      let newDate = selectedDays[currentDateIndex - 1][0].slice(0, 10);
      setSelectedDate(newDate);
    }
  }

  const goToNextDay = () => {
    let currentDateIndex = selectedDays.findIndex(day => day[0].includes(date));
    if (currentDateIndex < selectedDays.length - 1) {
      let newDate = selectedDays[currentDateIndex + 1][0].slice(0, 10);
      setSelectedDate(newDate);
    }
  }
  return (
    <ScrollWrapper style={{ height: showSimpleHeader ? '75vh' : '410px' }}>
      <StyledCalendarHeader>
        <div>
          {isZoomedIn ?
            <RiZoomOutFill className="zoomIcon" onClick={() => setIsZoomed((prev) => !prev)} />
            :
            <RiZoomInFill className="zoomIcon" onClick={() => setIsZoomed((prev) => !prev)} />
          }
        </div>
        {formatSelectedDate(date)}
        {!showSimpleHeader &&
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <StyledScaleButton onClick={() => setShowDailyAppointmentsCompareModal(true)}>
              <ScaleIcon />
              <span className="bubble">
                {selectedDays && selectedDays.length}
              </span>
            </StyledScaleButton>
            <IoIosArrowBack
              className="arrowIcon mx-2"
              onClick={() => goToPreviousDay()}
            />
            <IoIosArrowForward
              className="arrowIcon"
              onClick={() => goToNextDay()}
            />
          </div>
        }
      </StyledCalendarHeader>
      <StyledCalendarBody>
        {/* loop through hoursArray to get 24 hour rows */}
        {selectedDateHoursArray.map((hour, indexRow) => {
          return (
            <StyledCalendarRow key={indexRow} isZoomedIn={isZoomedIn}>
              <StyledHourBlock>
                <span>{`${indexRow < 10 ? "0" : ""}${indexRow}:00`}</span>
              </StyledHourBlock>
              <StyledEventsBlock>
                {hour.map((event, indexEvent) => createEventBubble(event, event.startTime, event.endTime, indexRow, indexEvent))}
              </StyledEventsBlock>
            </StyledCalendarRow>
          );
        })}
      </StyledCalendarBody>
    </ScrollWrapper >
  );
};

export default DailyAppointmentsOverview;