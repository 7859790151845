import { t } from 'i18next'
import { cloneDeep } from 'lodash'
import React, { useEffect } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { MdLibraryAdd } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useActivitiesState } from '../../../stores/Configuration/ActivitiesProvider'
import { useContingentTemplatesState } from '../../../stores/Configuration/ContingentTemplatesProvider'
import { emptyGuid } from '../../../utils/emptyGuid'
import { setValueByKey } from '../../../utils/utils'
import { ButtonWithLoadingState } from '../../Buttons'

import ActivityRow from './ActivityRow'

const StyledWrapper = styled.div`
  max-width: 660px;
  @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px) {
    max-width: none;
  }
`
const activityAssignmentTemplate = [
  {
    activityLevel: {
      "startValue": "0",
      "endValue": "100",
      "includeStartValue": true,
      "includeEndValue": true
    },
    activityId: emptyGuid
  }
]

const ContingentTemplateActivites = () => {
  const { assignedActivities, setAssignedActivities, initAssignedActivities, loadingStates, patchContingentTemplate, assignedActivitiesHasChanged, errorStates } = useContingentTemplatesState()
  const { activities } = useActivitiesState()
  const { contingentTemplateId } = useParams()

  useEffect(() => {
    return () => initAssignedActivities()
  }, [initAssignedActivities])


  const updateItem = index => e => {
    let itemToChange = cloneDeep(assignedActivities)[index]
    let key = e.target.name
    let value = null
    if (e.target.type === "checkbox") { value = e.target.checked }
    else if (e.target.type === "number") {
      value = parseInt(e.target.value)
    }
    else { value = e.target.value }
    setAssignedActivities(prev => {
      const newValues = [...prev]
      newValues[index] = setValueByKey(key, value, itemToChange)
      return newValues
    })
  }

  const deleteItem = index => {
    setAssignedActivities(prev => {
      const newValues = [...prev]
      newValues.splice(index, 1)
      return newValues
    })
  }

  const addRow = () => {
    setAssignedActivities(prev => [...prev, ...activityAssignmentTemplate])
  }

  const handleSubmit = () => {
    patchContingentTemplate(contingentTemplateId)
  }
  return (
    <StyledWrapper>
      {loadingStates.getContingentTemplate
        ? <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
        : assignedActivities.map((item, index) => {
          return (
            <ActivityRow
              item={item}
              updateItem={updateItem(index)}
              deleteItem={() => deleteItem(index)}
              activities={activities}
              index={index}
              key={`ac_${index}`}
              formErrors={errorStates?.saveContingentTemplate?.formErrors}
            />
          )
        })
      }
      <div className="d-flex justify-content-between mt-3">
        <ButtonWithLoadingState
          disabled={!assignedActivitiesHasChanged}
          buttonText={t('saveButton')}
          isLoading={loadingStates.saveContingentTemplate}
          onClick={handleSubmit}
        />
        <Button variant="secondary" onClick={addRow} >
          <MdLibraryAdd className="me-3" />{t('contingentTemplates:addActivityAssignment')}
        </Button>
      </div>
    </StyledWrapper>
  )
}

export default ContingentTemplateActivites