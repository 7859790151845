import React, { useEffect, useState, useRef } from 'react'
import { useEmployeesState } from '../../stores/EmployeesProvider'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import ArraySelect from '../Select/ArraySelect'
import { t } from 'i18next'
import { useObserver } from 'mobx-react'

const StyledWrapper = styled.div`


  .form-group {
    padding-left:20px;

    @media screen and (min-width: 460px) {
      width: auto;
    }
  }
`

const CheckUpFilter = () => {
  const { checkUpFilter, setCheckUpFilter } = useEmployeesState()
  const [checkUpTypes, setCheckUpTypes] = useState([])
  let checkUpResultId = useObserver(() => checkUpFilter?.checkUpResultId || '')
  let checkUpTypeId = useObserver(() => checkUpFilter?.checkUpTypeId || '')
  const mountedRef = useRef(true)
  const [checkupResults, setCheckupResults] = useState([])

  useEffect(() => {
    apiClient.getJson(APIEndpoints.businesspartnerCheckUpConfigs).then(resp => {
      if (!mountedRef.current) return null
      setCheckUpTypes(resp)
    })
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (checkUpTypeId) {
      let results = checkUpTypes.find(item => item.checkUpType.id === Number(checkUpTypeId))?.possibleResults || []
      setCheckupResults(results)
    }
    else {
      setCheckupResults([])
    }
  }, [checkUpTypeId, checkUpTypes])

  const updateCheckUpFilter = (displayName, checkUpTypeId = '', checkUpResultId = '') => {
    let newObj = {
      displayName,
      checkUpTypeId,
      checkUpResultId
    }
    setCheckUpFilter(newObj)
  }

  const handleChange = e => {
    let newCheckUpTypeId = e.target.value

    if (newCheckUpTypeId) {
      const checkUpTypeName = checkUpTypes.find((obj) => obj.checkUpType.id === Number(newCheckUpTypeId)).checkUpType.displayName || "?"
      updateCheckUpFilter(`${checkUpTypeName} ${t('noResult')}`, newCheckUpTypeId)
    }
    else {
      setCheckUpFilter(null)
    }
  }

  const handleChangeResult = e => {
    const resultId = e.target.value
    const checkUpTypeName = checkUpTypes.find((obj) => obj.checkUpType.id === Number(checkUpTypeId)).checkUpType.displayName || "?"
    if (resultId) {
      const checkUpResultName = checkupResults.find((item) => item.id === resultId).displayName || "?"
      updateCheckUpFilter(`${checkUpTypeName} (${checkUpResultName})`, checkUpTypeId, resultId)
    }
    else {
      updateCheckUpFilter(`${checkUpTypeName} ${t('noResult')}`, checkUpTypeId)
    }
  }

  return (
    <StyledWrapper>
      <div>
        <Form.Group className="mb-3">
          <Form.Label>{t('employeesPage:healthCheck')}</Form.Label>
          <ArraySelect
            options={checkUpTypes}
            otherNameKey={"checkUpType.displayName"}
            otherValueKey={"checkUpType.id"}
            value={checkUpTypeId}
            onChange={handleChange}
            defaultOption={{ value: "", name: t('employeesPage:filterNotSelected') }}
            defaultOptionIsSelectable
            returnEvent
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>{t('employeesPage:healthCheckResult')}</Form.Label>
          <ArraySelect
            onChange={handleChangeResult}
            value={checkUpResultId}
            disabled={checkupResults.length === 0}
            options={checkupResults}
            defaultOption={{ name: t('noResult'), value: '' }}
            defaultOptionIsSelectable
            returnEvent
          />
        </Form.Group>
      </div>
    </StyledWrapper>
  )
}

export default CheckUpFilter
