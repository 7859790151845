import { t } from "i18next";
import React from "react"
import { Alert, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { useComplaintsState } from "../../../stores/ComplaintsProvider";
import ActiveComplaintFilters from "../../Complaints/ActiveComplaintFilters";
import ComplaintCard from "../../Complaints/ComplaintCard";
import ComplaintCardsFilter from "../../Complaints/ComplaintCardsFilter";
import useMountEffect from "../../../utils/hooks/useMountEffect";
import LazyLoad from "../../Forms/LazyLoad";
import useStores from "../../../stores/useStore";

const StyledComplaintCardContainer = styled.div`
  max-width: 920px;
  margin:0 auto;
`
const Complaints = () => {
  const { complaintsIsLoading, pageOffset, setFilterByKey, complaints, totalCount, loadComplaints, reloadComplaints, error, updateComplaint } = useComplaintsState()
  const { uiStore } = useStores()

  useMountEffect(() => {
    if (uiStore.allowShowAllOrders) {
      setFilterByKey('showAllReturns', true)
    }
    else {
      loadComplaints()
    }
  })

  return (
    <StyledComplaintCardContainer>
      <ComplaintCardsFilter />
      <ActiveComplaintFilters />
      {complaints.map(item => (
        <ComplaintCard key={item.id} item={item} reloadComplaints={reloadComplaints} updateComplaint={updateComplaint} />
      ))}
      {((totalCount > pageOffset) && !complaintsIsLoading) ?
        (<LazyLoad
          fetchData={loadComplaints}
        />) : ''}
      {error
        ? <Alert variant="danger">{error}</Alert>
        : null}
      {complaintsIsLoading
        ? <div className="d-flex align-items-center justify-content-center p-3"><Spinner animation='border' variant='primary' /></div>
        : null}
      {!complaintsIsLoading && !complaints.length
        ? <Alert >{t('complaints:noResults')}</Alert>
        : null}
    </StyledComplaintCardContainer>
  )
}

export default Complaints;