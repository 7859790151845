import { action, observable, makeObservable } from 'mobx';
import axios from 'axios'

import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { getErrorMessageFromApiResponse } from '../utils/api/apiUtils'
import { scrollToDataId } from '../utils/utils'
import { emptyGuid, isEmptyGuid } from '../utils/emptyGuid'
import { formatedPriceObject, addConfigPrices } from '../utils/formatedPrice'
import { addToBasketBuildConfigurations, equalConfigsCheck, equalPhaseCheck, equalLocalStorehouseCheck } from '../utils/shoppingBasketHelper'
import i18n from '../translation/i18n'

const CancelToken = axios.CancelToken;
let cancel;

class ProductDetailsStore {
    constructor(appStore) {
        makeObservable(this, {
            article: observable,
            addToBasketPositions: observable,
            addToBasketConfigurations: observable,
            selectedVariantId: observable,
            selectedVariantError: observable,
            selectedLocalStorehouseId: observable,
            selectedLocalStorehouseError: observable,
            selectedConfigurationsErrors: observable,
            selectedQuantity: observable,
            minimumQuantity: observable,
            maximumQuantity: observable,
            selectedFirstPartVariant: observable,
            selectedSecondPartVariant: observable,
            customConfigurations: observable,
            addToBasketIsLoading: observable,
            addToBasketErrors: observable,
            addToBasketSuccess: observable,
            customComment: observable,
            customCommentUserChanged: observable,
            articleConfiguration: observable,
            configPrices: observable,
            variants: observable,
            articlePrices: observable,
            differentPrices: observable,
            price: observable,
            variantQuantities: observable,
            localStorehouses: observable,
            lastComment: observable,
            shoppingBasketArticle: observable,
            shoppingBasketArticleNextExpiries: observable,
            orderableFollowerArticle: observable,
            orderablePredecessorArticles: observable,
            quantityRecommendedPerBPExists: observable,
            scrollRef: observable,
            resetBuyBoxState: action,
            cancelLoadAarticle: action,
            loadArticle: action,
            loadShoppingBasketArticle: action,
            loadShoppingBasketArticleNextExpiries: action,
            setShoppingBasketArticle: action,
            updateArticleQuantity: action,
            setScrollRef: action,
            loadOrderAbleFollowerArticle: action,
            loadOrderablePredecessorArticles: action,
            getShoppingBasketId: action,
            loadArticlePrices: action,
            refreshDifferentPrices: action,
            setSelectedVariant: action,
            setSelectedLocalStorehouse: action,
            refreshPrice: action,
            articleConfigurationIsLoading: observable,
            articleConfigurationIsLoaded: observable,
            loadArticleConfiguration: action,
            initCustomConfigurations: action,
            loadVariantQuantities: action,
            loadLocalStorehouses: action,
            loadLastComment: action,
            handleCustomComment: action,
            handleAddToBasketErrors: action,
            setSelectedQuantity: action,
            handleRemoveConfiguration: action,
            loadOrderableVariants: action,
            productHistory: observable,
            historyTotalCount: observable,
            historyOffset: observable,
            historyLimit: observable,
            productHistoryIsLoading: observable,
            loadProductHistory: action,
            resetProductHistory: action
        });

        this.app = appStore
    }

    article = null;
    addToBasketPositions = null;
    addToBasketConfigurations = null;
    selectedVariantId = null;
    selectedVariantError = false;
    selectedLocalStorehouseId = null;
    selectedLocalStorehouseError = false;
    selectedConfigurationsErrors = {};
    selectedQuantity = 1;
    minimumQuantity = 1;
    maximumQuantity = null;
    selectedFirstPartVariant = null;
    selectedSecondPartVariant = null;
    customConfigurations = {};
    addToBasketIsLoading = false;
    addToBasketErrors = [];
    addToBasketSuccess = false;
    customComment = '';
    customCommentUserChanged = false;
    articleConfiguration = [];
    configPrices = {};
    variants = null;
    articlePrices = null;
    differentPrices = null;
    price = '';
    variantQuantities = null;
    localStorehouses = null;
    lastComment = null;
    shoppingBasketArticle = null;
    shoppingBasketArticleNextExpiries = null;
    orderableFollowerArticle = null;
    orderablePredecessorArticles = null;
    quantityRecommendedPerBPExists = false;
    scrollRef = null;

    resetBuyBoxState() {
        this.article = null
        this.addToBasketPositions = null
        this.addToBasketConfigurations = null
        this.selectedVariantId = null
        this.selectedVariantError = false
        this.selectedLocalStorehouseId = null
        this.selectedLocalStorehouseError = false
        this.selectedConfigurationsErrors = {}
        this.selectedQuantity = 1
        this.minimumQuantity = 1
        this.maximumQuantity = null
        this.selectedFirstPartVariant = null
        this.selectedSecondPartVariant = null
        this.customConfigurations = {}
        this.addToBasketIsLoading = false
        this.addToBasketErrors = []
        this.addToBasketSuccess = false
        this.customComment = ''
        this.customCommentUserChanged = false
        this.articleConfiguration = []
        this.configPrices = {}
        this.variants = null
        this.articlePrices = null
        this.differentPrices = null
        this.price = ''
        this.variantQuantities = null
        this.localStorehouses = null
        this.lastComment = null
        this.shoppingBasketArticle = null
        this.shoppingBasketArticleNextExpiries = null
        this.orderableFollowerArticle = null
        this.orderablePredecessorArticles = null
        this.quantityRecommendedPerBPExists = false
        // this.scrollRef = null
        this.articleConfigurationIsLoading = false
        this.articleConfigurationIsLoaded = false
    }

    cancelLoadAarticle() {
        //cancel previous calls that are still pending
        cancel && cancel()
    }

    async loadArticle(articleId) {
        const cancelToken = new CancelToken(function executor(c) {
            cancel = c;
        })
        try {
            const article = await apiClient.getJson(APIEndpoints.article(articleId), {}, true, false, true, { cancelToken })
            this.article = article

        }
        catch (e) {
            if (axios.isCancel(e)) {
                console.log("cancelled by cancelToken")
            }
        }
    }

    async loadShoppingBasketArticle(shoppingBasketArticleId) {
        const shoppingBasketId = await this.getShoppingBasketId()
        if (this.shoppingBasketArticle && this.shoppingBasketArticle.id === shoppingBasketArticleId) {
            return
        }
        this.shoppingBasketArticle = null
        this.shoppingBasketArticle = await apiClient.getJson(APIEndpoints.shoppingBasketArticle(shoppingBasketId, shoppingBasketArticleId))
        this.updateArticleQuantity(this.shoppingBasketArticle.articleAvailability)
    }

    async loadShoppingBasketArticleNextExpiries(shoppingBasketArticleId) {
        const shoppingBasketId = await this.getShoppingBasketId()
        this.shoppingBasketArticleNextExpiries = null
        this.shoppingBasketArticleNextExpiries = await apiClient.getJson(APIEndpoints.shoppingBasketArticleNextExpiries(shoppingBasketId, shoppingBasketArticleId))
    }

    setShoppingBasketArticle(shoppingBasketArticle) {
        this.shoppingBasketArticle = shoppingBasketArticle
        this.updateArticleQuantity(shoppingBasketArticle.articleAvailability) // do i need this?
    }

    updateArticleQuantity(articleAvailability) {
        const packageSize = this.shoppingBasketArticle.article.packageSize
        const minimumQuantity = this.shoppingBasketArticle.article.minimumQuantity
        const recommendedAmount = this.shoppingBasketArticle.recommendedAmount
        const minQuantity = this.app.productsStore.getMinQuantity(articleAvailability ? articleAvailability.amountToOrder : 0, packageSize, minimumQuantity)
        const selectedQuantity = this.app.productsStore.getSelectedQuantity(articleAvailability ? articleAvailability.amountToOrder : 0, packageSize, minimumQuantity, recommendedAmount)
        this.selectedQuantity = selectedQuantity
        this.minimumQuantity = minQuantity
        this.maximumQuantity = this.shoppingBasketArticle.article.maximumQuantity
        this.shoppingBasketArticle.articleAvailability = articleAvailability
    }

    setScrollRef(id) {
        this.scrollRef = id
    }

    getProductBadge(shoppingBasketArticle) {
        switch (shoppingBasketArticle.offerStatus) {
            case 'isObsolete':
                return i18n.t('discontinuedItem')
            case 'isNew':
                return i18n.t('new')
            default:
                return ''
        }
    }
    async loadOrderAbleFollowerArticle() {
        if (!this.article.followerArticle) {
            return
        }
        const shoppingBasketId = await this.getShoppingBasketId()
        try {
            this.orderableFollowerArticle = await apiClient.getJson(APIEndpoints.orderableFollowerArticle(shoppingBasketId, this.article.id))
        } catch (e) { }
    }

    async loadOrderablePredecessorArticles() {
        if (!this.article.hasPredecessorArticles) {
            return
        }
        const shoppingBasketId = await this.getShoppingBasketId()
        try {
            this.orderablePredecessorArticles = await apiClient.getJson(APIEndpoints.orderablePredecessorArticles(shoppingBasketId, this.article.id))
        } catch (e) { }
    }

    getPriceObjectByVariantId(variantId) {
        if (!this.articlePrices || !this.articlePrices.length) {
            return null
        }
        return (this.articlePrices.find(priceObject => priceObject.articleVariant.id === variantId) || { pricePerItem: null }).pricePerItem
    }

    async getShoppingBasketId() {
        if (this.app.productsStore.basket) {
            return this.app.productsStore.basket.id
        }
        else {
            let basket = await this.app.productsStore.loadOrCreateBasket()
            return basket.id
        }
    }

    async loadArticlePrices() {
        if (this.articlePrices || this.app.productsStore.hidePricesAndPoints) {
            return
        }
        const shoppingBasketId = await this.getShoppingBasketId()
        this.price = ''
        this.differentPrices = false
        if (this.shoppingBasketArticle && this.article && this.shoppingBasketArticle.pricePerItem !== null && this.shoppingBasketArticle.pricePerItem.points === null && this.shoppingBasketArticle.pricePerItem.price !== null) {
            const prices = await apiClient.getJson(APIEndpoints.prices(shoppingBasketId, this.article.id))
            this.articlePrices = prices
        } else {
            this.articlePrices = null
        }
        this.refreshPrice()
        this.refreshDifferentPrices()
    }

    //check if all prices for all variants are the same
    refreshDifferentPrices() {
        if (!this.articlePrices || this.articlePrices.length === 0 || this.app.productsStore.hidePricesAndPoints) {
            this.differentPrices = false
            return
        }

        this.differentPrices = this.articlePrices.reduce((acc, currentPrice, currentIndex, reduceArray) => {
            const pricePerItem = currentPrice.pricePerItem
            if (!pricePerItem) {
                return acc
            }
            
            const { price, points } = pricePerItem
            const prevPricePerItem = currentIndex ? reduceArray[currentIndex - 1].pricePerItem : {}
            const prevPrice = prevPricePerItem.price ? prevPricePerItem.price : price
            const prevPoints = prevPricePerItem.points ? prevPricePerItem.points : points

            return acc || (price !== prevPrice || points !== prevPoints)
        }, false)
    }

    async setSelectedVariant(variantId) {
        const selectedVariantHasChanged = this.selectedVariantId !== variantId
        if (selectedVariantHasChanged && this.article && this.article.id) {
            this.selectedVariantId = variantId
            this.refreshPrice()
            await this.loadVariantQuantities()
            await this.loadLastComment()
            await this.loadLocalStorehouses()
        }
    }
    async setSelectedLocalStorehouse(localStorehouseId) {
        const selectedLocalStorehouseHasChanged = this.selectedLocalStorehouseId !== localStorehouseId
        if (selectedLocalStorehouseHasChanged && this.article && this.article.id) {
            this.selectedLocalStorehouseId = localStorehouseId
        }
    }
    async refreshPrice() {
        if (!this.article || !this.shoppingBasketArticle || this.app.productsStore.hidePricesAndPoints) {
            return
        }
        const variantPriceObject = this.selectedVariantId ? this.getPriceObjectByVariantId(this.selectedVariantId) : null
        const shoppingBasketArticlePricePerItem = this.shoppingBasketArticle.pricePerItem || {}
        const priceObject = this.articlePrices === null ?
            shoppingBasketArticlePricePerItem :
            (variantPriceObject ? variantPriceObject : shoppingBasketArticlePricePerItem)
        //add configuration Prices or Points
        const configArticlesIds = Object.values(this.customConfigurations).map(config => config.article.id)
        const configPrices = await this.getConfigPrices(configArticlesIds)
        this.price = formatedPriceObject(addConfigPrices(priceObject, configPrices), false)
    }

    loadConfigPrice = async (shoppingBasketId, articleId) => {
        let result = {}
        if (articleId !== emptyGuid) {
            const configPrice = await apiClient.getJson(APIEndpoints.prices(shoppingBasketId, articleId))
            if (configPrice && configPrice.length) {
                result = configPrice[0].pricePerItem
            }
        }
        return result
    }

    getConfigPrices = async (configArticleIds = []) => {
        const shoppingBasketId = await this.getShoppingBasketId()
        let result = []
        for (const articleId of configArticleIds) {
            if (!this.configPrices[articleId]) {
                this.configPrices[articleId] = await this.loadConfigPrice(shoppingBasketId, articleId)
            }
            result.push(this.configPrices[articleId])
        }

        return result
    }

    //values must be reseted
    articleConfigurationIsLoading = false;
    articleConfigurationIsLoaded = false;
    async loadArticleConfiguration() {
        if (this.articleConfigurationIsLoaded) {
            return
        }
        else if (this.articleConfigurationIsLoading) {
            await this.app.uiStore.waitUntilLoaded(100, 'app.productDetailsStore.articleConfigurationIsLoading')
            return
        }
        else {
            this.articleConfigurationIsLoading = true
            const shoppingBasketId = await this.getShoppingBasketId()
            this.articleConfiguration = await apiClient.getJson(APIEndpoints.shoppingBasketArticleConfigurations(shoppingBasketId, this.article.id))
            this.articleConfigurationIsLoaded = true
            this.articleConfigurationIsLoading = false
            return
        }
    }

    async initCustomConfigurations() {
        await this.loadArticleConfiguration()
        this.articleConfiguration.forEach(config => {
            const article = config.selectableArticles.find(article => article.id === config.lastSelectedArticleID)
            if (!this.customConfigurations[config.id]) {
                if (article) {
                    this.customConfigurations[config.id] = {
                        comment: config.lastComment,
                        article
                    }
                }
            }
        })
        this.refreshPrice()
    }

    async loadVariantQuantities() {
        const shoppingBasketId = await this.getShoppingBasketId()

        if (!shoppingBasketId || !this.article) {
            return
        }
        const params = this.variants ? {
            variantId: this.selectedVariantId
        } : {}
        this.variantQuantities = await apiClient.getJson(APIEndpoints.variantQuantities(shoppingBasketId, this.article.id), params)
    }

    async loadLocalStorehouses() {
        const shoppingBasketId = await this.getShoppingBasketId()

        if (!shoppingBasketId || !this.article) {
            return
        }
        const params = {}
        if(this.variants)
            params.variantId = this.selectedVariantId
        
        let totalQ = parseInt(this.selectedQuantity)
        if(!isEmptyGuid(this.selectedLocalStorehouseId))
        {
            const oldPositions = this.getArticlePositions(this.shoppingBasketArticle.id)
            const existingPosition = oldPositions.find(position => position.articleVariant.id === (this.selectedVariantId ? this.selectedVariantId : emptyGuid)
                && equalLocalStorehouseCheck(position.localStorehouse, this.selectedLocalStorehouseId)
            )
            if(existingPosition)
                totalQ += existingPosition.quantity;
        }
        if(totalQ && totalQ>0)
            params.amount = totalQ

        this.localStorehouses = await apiClient.getJson(APIEndpoints.localStorehouses(shoppingBasketId, this.article.id), params)
        if(!this.localStorehouses.find(sth => sth.id === this.selectedLocalStorehouseId))
            this.setSelectedLocalStorehouse(null)
    }

    async loadLastComment() {
        const shoppingBasketId = await this.getShoppingBasketId()

        if (!shoppingBasketId || !this.article) {
            return
        }
        const params = this.variants ? {
            variantId: this.selectedVariantId
        } : {}
        const response = await apiClient.getJson(APIEndpoints.lastComment(shoppingBasketId, this.article.id), params, true, true)
        //if(respnse !== null)
        if (response.headers['content-length'] > 0) {
            this.lastComment = response.data
            if (!this.customCommentUserChanged) {
                this.customComment = this.lastComment
            }
        }
    }

    handleCustomComment = e => {
        this.customCommentUserChanged = true
        this.customComment = e.target.value
    };
    handleChangeLocalStorehouse = (e) => {
        this.selectedLocalStorehouseError = false
        const localStorehouse = this.localStorehouses.find(sth => sth.id === e.target.value)
        if (localStorehouse) {
            this.setSelectedLocalStorehouse(e.target.value)
        } else {
            this.setSelectedLocalStorehouse(null)
        }
        this.selectedLocalStorehouseId = e.target.value
    }

    handleChangeFirstPartVariant = (e) => {
        this.selectedVariantError = false
        this.selectedSecondPartVariant = null
        const variant = this.variants.find(variant => variant.id === e.target.value)
        if (variant) {
            this.setSelectedVariant(e.target.value)
        } else {
            this.setSelectedVariant(null)
        }
        this.selectedFirstPartVariant = e.target.value
    }

    handleChangeSecondPartVariant = (e) => {
        this.selectedVariantError = false

        this.setSelectedVariant(e.target.value)
        this.selectedSecondPartVariant = e.target.value
    }

    handleAddToBasket = async () => {
        const { basket } = this.app.productsStore

        this.addToBasketSuccess = false
        this.selectedVariantError = false
        this.selectedConfigurationsErrors = {}

        if (!this.app.teamOrderStore.selectedCostcenterId) {
            if ((this.variants && this.variants.length && !this.selectedVariantId)) {
                this.selectedVariantError = true
                return
            }
        }

        this.addToBasketBuildPositions()
        if (!Object.keys(this.addToBasketPositions).length) {
            return
        }

        try {
            this.addToBasketErrors = []
            this.addToBasketIsLoading = true
            const addedShoopingArticle = await apiClient.patchJson(APIEndpoints.shoppingBasketArticle(basket.id, this.shoppingBasketArticle.id), this.addToBasketPositions)
            await this.app.productsStore.loadBasket(basket.id)
            //update productsOverviewPage categoryAvailability
            if (!this.app.teamOrderStore.selectedCostcenterId && !this.app.teamOrderStore.privateOrder) {
                this.app.productsStore.loadContingent(true)
            }
            //update productDetailsPage amountToOrder
            if (this.shoppingBasketArticle) {
                this.updateArticleQuantity(addedShoopingArticle.articleAvailability)
            }

            this.loadVariantQuantities()
            this.app.uiStore.fetchNewShoppingCarts()
            this.addToBasketSuccess = true
            scrollToDataId('add-to-basket-success')
        } catch (error) {
            this.handleAddToBasketErrors(error)
        }

        this.addToBasketIsLoading = false
    }

    addToBasketBuildPositions() {
        const { orderInfo } = this.app.productsStore
        this.addToBasketConfigurations = addToBasketBuildConfigurations(this.customConfigurations)

        let newPositions = []
        let changedPositions = []
        let removedPositions = []

        let defaultBuyBox = !this.app.teamOrderStore.selectedCostcenterId || (this.app.teamOrderStore.selectedCostcenterId && (!this.variants || !this.variants.length))
        if (defaultBuyBox) {
            const oldPositions = this.getArticlePositions(this.shoppingBasketArticle.id)
            const existingPosition = oldPositions.find(position => position.articleVariant.id === (this.selectedVariantId ? this.selectedVariantId : emptyGuid)
                && equalLocalStorehouseCheck(position.localStorehouse, this.selectedLocalStorehouseId)
                && equalConfigsCheck(this.addToBasketConfigurations, position.configurations)
                && (this.lastComment !== null ? JSON.stringify(position.comment) === JSON.stringify(this.customComment) : true)
                && equalPhaseCheck(orderInfo.phase, position.phase)
            )
            if (existingPosition) {
                changedPositions.push(
                    {
                        id: existingPosition.id,
                        quantity: existingPosition.quantity + parseInt(this.selectedQuantity),
                        articleVariant: { id: existingPosition.articleVariant.id },
                        localStorehouse: existingPosition.localStorehouse ? { id: existingPosition.localStorehouse.id } : {},
                    }
                )
            }
            else {
                newPositions.push({
                    quantity: this.selectedQuantity,
                    articleVariant: this.selectedVariantId ? this.variants.find(variant => variant.id === this.selectedVariantId) : {},
                    configurations: this.addToBasketConfigurations,
                    comment: this.lastComment !== null ? this.customComment : null,
                    localStorehouse: this.selectedLocalStorehouseId ? this.localStorehouses.find(sth => sth.id === this.selectedLocalStorehouseId) : {},
                })
            }
        } else {
            const { selectedVariants } = this.app.teamOrderStore
            removedPositions = this.getArticlePositions(this.shoppingBasketArticle.id).map(position => position.id)
            newPositions = selectedVariants.map(position => ({
                quantity: position.quantity,
                articleVariant: { id: position.variantId },
                configurations: addToBasketBuildConfigurations(position.customConfigurations)
            }))
        }
        var addToBasketPositions = {}
        if (newPositions.length) {
            addToBasketPositions.newPositions = newPositions
        }

        if (changedPositions.length) {
            addToBasketPositions.changedPositions = changedPositions
        }

        if (removedPositions.length) {
            addToBasketPositions.removedPositions = removedPositions
        }
        this.addToBasketPositions = addToBasketPositions
    }

    handleAddToBasketErrors(e) {
        //api errors
        if (e.response && e.response.data && e.response.data.errors) {
            e.response.data.errors.forEach(error => {
                if (error.source && error.source.pointer) {
                    //configurations errors
                    const configurationErrorMatches = error.source.pointer.match(/^\/positions\/\d+\/configurations\/(\d+)\/([^/]+)/)
                    if (configurationErrorMatches && configurationErrorMatches.length) {
                        const configId = this.addToBasketConfigurations[configurationErrorMatches[1]].articleConfigurationId
                        if (!this.selectedConfigurationsErrors[configId]) {
                            this.selectedConfigurationsErrors[configId] = {}
                        }
                        this.selectedConfigurationsErrors[configId][configurationErrorMatches[2]] = true
                    }

                    //costcenterOrder variant position errors
                    /* {
                        "errors": [
                            {
                                "detail": "Die Menge ist ungültig!",
                                "source": {
                                    "pointer": "/positions/1/quantity"
                                }
                            }
                        ]
                    } */
                    const positonsErrors = error.source.pointer.match(/^\/positions\/(\d+)\//)
                    if (positonsErrors && positonsErrors.length) {
                        //get all Frontend Positions for their articleVariant-ids because positionsErrors is only knowing the indexes
                        this.app.teamOrderStore.selectedVariantQuantitiesErros[positonsErrors[1]] = true
                    }
                }
                this.addToBasketErrors.push({ msg: error.detail })
            })
        } else {
            this.addToBasketErrors.push({ msg: getErrorMessageFromApiResponse(e) })
        }
    }

    async setSelectedQuantity(quantity) {
        this.selectedQuantity = quantity
        await this.loadLocalStorehouses()
    }

    handleRemoveConfiguration = configId => {
        const config = this.articleConfiguration.find(config => config.id === configId)
        const emptyGuidArticle = config.selectableArticles.find(art => art.id === emptyGuid)
        if (emptyGuidArticle) {
            this.customConfigurations[configId].article = emptyGuidArticle
        }
        else {
            delete this.customConfigurations[configId]
        }
        delete this.selectedConfigurationsErrors[configId]

        this.refreshPrice()
    };

    async loadOrderableVariants(presetVariant) {
        if (!this.article || !this.shoppingBasketArticle) {
            return
        }
        const shoppingBasketId = await this.getShoppingBasketId()
        if (!this.article || !this.shoppingBasketArticle) {
            return
        }
        this.variants = await apiClient.getJson(APIEndpoints.shoppingBasketArticleVariants(shoppingBasketId, this.article.id))

        this.quantityRecommendedPerBPExists = !!this.variants.find(variant => variant.quantityRecommendedPerBP && variant.quantityRecommendedPerBP > 0)

        this.initVariant(presetVariant)
        return this.variants
    }

    initVariant(presetVariant) {
        if (!this.article || !this.shoppingBasketArticle) {
            return
        }
        const lastSelectedVariant = presetVariant ? presetVariant : this.shoppingBasketArticle.lastSelectedVariant
        if (lastSelectedVariant && lastSelectedVariant.id) {
            const variant = this.variants.find(variant => variant.id === lastSelectedVariant.id)
            if (variant) {
                if (variant.separatedDisplayName.length > 1) {
                    this.selectedFirstPartVariant = variant.separatedDisplayName[0]
                    this.selectedSecondPartVariant = variant.id
                } else {
                    this.selectedFirstPartVariant = variant.id
                    this.selectedSecondPartVariant = null
                }
                this.setSelectedVariant(variant.id)
            }
        }
        if (this.app.teamOrderStore.selectedCostcenterId) {
            //prefill variant quantities with basket positions
            const prefilledValues2 = []
            const existingVariantPositions = this.getArticlePositions(this.shoppingBasketArticle.id)
            existingVariantPositions.forEach(position => {
                prefilledValues2.push({
                    variantId: position.articleVariant.id,
                    quantity: position.quantity,
                    configurations: position.configurations
                })
            })
            this.app.teamOrderStore.resetVariants(prefilledValues2)
        }
    }
    getArticlePositions(shoppingBasketArticleId) {
        const { basket } = this.app.productsStore
        return (basket.articles.find(shoppingBasketArticle => shoppingBasketArticle.id === shoppingBasketArticleId) || { positions: [] }).positions
    }
    getProductToUrl(shoppingBasketArticle) {
        const { basket } = this.app.productsStore
        switch(basket.targetOrderProcessType)
         {
            case 'storeHouse':
                return `/storehouse/${basket.targetStorehouse.id}/${basket.targetCostCenter.id}/products/${shoppingBasketArticle.id}`
            case 'costCenter':
                return `/costcenter/${basket.targetCostCenter.id}/costcenterproducts/${shoppingBasketArticle.id}`
            default:
                return `/products/${basket.targetOrderProcessType}/${shoppingBasketArticle.id}/${basket.targetBusinesspartner.id}`
         }
    }

    resetConfigErrors(configId) {
        delete this.selectedConfigurationsErrors[configId]
        this.addToBasketErrors = this.addToBasketErrors.filter(error => (!error.configId || error.configId !== configId))
    }
    handleChangeConfiguration = async (configId, article) => {
        this.resetConfigErrors(configId)
        //handle change configuration
        this.customConfigurations[configId].article = article

        this.refreshPrice()
    }

    handleChangeConfigurationComment = (configId, comment) => {
        this.resetConfigErrors(configId)
        this.customConfigurations[configId].comment = comment
    }

    productHistory = [];
    historyTotalCount = 0;
    historyOffset = 0;
    historyLimit = 10;
    productHistoryIsLoading = false;

    async loadProductHistory({ append } = { append: false }) {
        this.productHistoryIsLoading = true

        const shoppingBasketId = await this.getShoppingBasketId()
        let params = {
            'page.limit': this.historyLimit,
            'page.offset': this.historyOffset
        }
        try {
            const response = await apiClient.getJson(APIEndpoints.productHistory(shoppingBasketId, this.article.id), params, true, true)
            //const response = await apiClient.getJson(APIEndpoints.productHistory("0abf0b73-bb05-ec11-a97b-001c425c5055", "7cd7b8b2-aef1-430a-94b6-d94927a67b02"), params, true, true)
            this.productHistory = append ? this.productHistory.concat(response.data) : response.data
            this.historyTotalCount = response.headers['x-total-count'] ? parseInt(response.headers['x-total-count']) : 0
        } catch (e) { }
        this.productHistoryIsLoading = false
    }

    resetProductHistory() {
        this.productHistory = []
        this.historyTotalCount = 0
        this.historyOffset = 0
        this.historyLimit = 10
        this.productHistoryIsLoading = false
    }

}
export default ProductDetailsStore
