import React from 'react'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { useTranslation } from 'react-i18next'

const StyledSpinner = styled.span`
  padding-right: 12px;
`

export const ButtonWithLoadingState = (props) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      {props.isLoading
        ? <Button
          active={props.active}
          className={props.className}
          disabled={props.disabled ? props.disabled : props.isLoading}
          type={props.type}
          variant={props.variant || 'primary'}
          size={props.size}
          form={props.form}
        >
          <Spinner
            as='span'
            animation='border'
            size='sm'
            role='status'
          />
          <StyledSpinner />
          {props.loadingText ? props.loadingText : t('loading')}
        </Button>
        : <Button
          active={props.active}
          className={props.className}
          variant={props.variant || 'primary'}
          type={props.type}
          onClick={props.onClick}
          disabled={props.disabled || false}
          size={props.size}
          form={props.form}
        >
          {props.buttonIcon ? props.buttonIcon : ''}
          {props.buttonText}
        </Button>
      }
    </React.Fragment>
  )
}
