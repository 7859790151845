import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { t } from 'i18next'

import { Subheader } from '../../components/Page/logix2020/Subheader'
import SubheaderTitle from '../../components/Page/logix2020/SubheaderTitle'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import SidebarNavigation from '../../components/Page/logix2020/SidebarNavigation'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import BigFooter from '../../components/Footer/BigFooter'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import ContingentTemplateBaseForm from '../../components/Configuration/ContingentTemplate/ContingentTemplateBaseForm'
import { useContingentTemplatesState } from '../../stores/Configuration/ContingentTemplatesProvider'
import ContingentTemplatePhases from '../../components/Configuration/ContingentTemplate/ContingentTemplatePhases'
import { useEntitlementPhasesState } from '../../stores/Configuration/EntitlementPhasesProvider'
import ContingentTemplateActivites from '../../components/Configuration/ContingentTemplate/ContingentTemplateActivities'
import { Alert } from 'react-bootstrap'
import { useActivitiesState } from '../../stores/Configuration/ActivitiesProvider'
import { ExcelButton } from '../../components/Buttons/ExcelButton'
import useReport from '../../utils/hooks/useReport'
import DownloadURLAlert from '../../components/Forms/DownloadURLAlert'
import ArticlesGroups from '../../components/Configuration/ContingentTemplate/ArticlesGroups'
import { getLanguageValue } from '../../utils/getLanguageValue'
import useMountEffect from '../../utils/hooks/useMountEffect'
import { ErrorAlerts } from '../../utils/api/makeCall'
import ReportSubscriptionModal from '../../components/Files/Form/ReportSubscriptionModal'
import ContingentPointsCalculation from '../../components/Configuration/ContingentTemplate/ContingentPointsCalculation'

const navigationLeft = () => {

  return [
    {
      key: '#base',
      to: '#base',
      name: t('contingentTemplates:base'),
      disabled: false
    },
    {
      key: '#activities',
      to: '#activities',
      name: t('contingentTemplates:activities'),
      disabled: false
    },
    {
      key: '#attributionPhase',
      to: '#attributionPhase',
      name: t('contingentTemplates:attributionPhase'),
      disabled: false
    },
    {
      key: '#editQuantities',
      to: '#editQuantities',
      name: t('contingentTemplates:quantities'),
      disabled: false
    }

  ]
}


const EditContingentTemplatePage = () => {
  const currentLocation = useLocation()
  const { bigData, getContingentTemplate, getGroupArticles, loadingStates, successStates, setSuccessStates, resetContingentTemplatesPage, getGroups, getPicture, contingentTemplate, errorStates } = useContingentTemplatesState()
  const { getActivities } = useActivitiesState()
  const { getEntitlementPhases } = useEntitlementPhasesState()
  const { contingentTemplateId } = useParams()
  const [activeNav, setActiveNav] = useState('')
  const { createReport, reportIsLoading, reportErrors, reportDownloadUrls, setReportDownloadUrls, reportSubscriptionId, setReportSubscriptionId } = useReport()

  const loadData = async () => {
    getContingentTemplate(contingentTemplateId)
    getEntitlementPhases()
    getPicture(contingentTemplateId)
    getActivities()
  }

  useEffect(() => {
    return () => resetContingentTemplatesPage()
  }, [resetContingentTemplatesPage])

  useMountEffect(() => {
    loadData()
  })
  useEffect(() => {
    let timer = null
    if (successStates.saveContingentTemplate) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        setSuccessStates({});
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [setSuccessStates, successStates.saveContingentTemplate])

  useEffect(() => {
    setActiveNav(currentLocation.hash || '#base')
  }, [currentLocation.hash])

  return (
    <div>
      <Subheader className="subHeader-v1 d-flex justify-content-between">
        <SubheaderTitle
          withBackButton
          smallTitle={getLanguageValue(contingentTemplate.name.values)}
          goBackLabel={t('contingentTemplates:contingentTemplatesOverview')}
          defaultBackTo="/contingentTemplatesAdministration"
        />
        <ExcelButton
          className="subheader__button"
          disabled={reportIsLoading.reportName}
          isLoading={reportIsLoading.reportName}
          buttonText={t('contingentTemplates:statisticEntitlement')}
          variant="secondary"
          onClick={() => createReport("reportName", "statisticEntitlement", { entitlementID: contingentTemplateId })}
        />
        {reportDownloadUrls.reportName &&
          <DownloadURLAlert
            downloadUrl={reportDownloadUrls.reportName}
            setDownloadUrl={() => setReportDownloadUrls({})}
            fileName={t("srm:exportButton")}
          />
        }
        {reportErrors.reportName && <Alert className="subheader__alert" variant="danger"  >{reportErrors.reportName.value}</Alert>}
        {reportSubscriptionId && <ReportSubscriptionModal
          reportSubscriptionId={reportSubscriptionId}
          setReportSubscriptionId={setReportSubscriptionId}
          message={t('reportConfig:createPending')}
        />}
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <SidebarNavigation options={navigationLeft()} active={activeNav} />
        </SidebarLeft>
        <ContentArea>
          <ErrorAlerts errorStates={errorStates} />
          {activeNav === '#base' && <ContingentTemplateBaseForm contingentTemplateId={contingentTemplateId} />}
          {activeNav === '#activities' && <ContingentTemplateActivites />}
          {activeNav === '#attributionPhase' && <ContingentTemplatePhases />}
          {activeNav === '#editQuantities' && <ArticlesGroups
            groupsLoading={loadingStates.groups}
            getGroups={getGroups}
            autoScrollTo={currentLocation.state}
            contingentTemplateId={contingentTemplateId}
            groups={(bigData && bigData[contingentTemplateId] && Object.values(bigData[contingentTemplateId].groups)) || []}
            getGroupArticles={getGroupArticles}
          />}
          {activeNav === '#editPointsCalculation' && <ContingentPointsCalculation />}

        </ContentArea>
      </FlexWrapper>
      <BigFooter />
    </div>
  )
}

export default EditContingentTemplatePage