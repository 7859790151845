import { emptyGuid } from '../utils/emptyGuid'
import { observable, makeObservable } from 'mobx';
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import axios from 'axios'
const CancelToken = axios.CancelToken;
let cancel;

class OfficeStore {
    constructor(appStore) {
        makeObservable(this, {
            loadedOfficeIds: observable,
            loadedOffices: observable,
            officesLoading: observable,
            allLoaded: observable,
            allOfficesLoading: observable
        });

        this.app = appStore
    }
    loadedOfficeIds = [];
    loadedOffices = [];
    officesLoading = false;
    allLoaded = false;
    allOfficesLoading = false;

    resetPartialOffices() {
        this.loadedOfficeIds = []
        this.loadedOffices = []
        this.allLoaded = false
    }

    async getOffices(officeParentId = emptyGuid) {
        this.loadAllOffices() //loadAll in background
        if (this.loadedOfficeIds.includes(officeParentId)) {
            return this.loadedOffices
        }
        else if (this.officesLoading) {
            await this.app.uiStore.waitUntilLoaded(100, 'app.officeStore.officesLoading')
            return this.getOffices(officeParentId)
        }
        else {
            this.officesLoading = true
            let _newLoadedOffices = await this.loadTree(officeParentId)
            if (!this.allLoaded) {
                this.loadedOffices = [...this.loadedOffices, ..._newLoadedOffices]
            }
            this.officesLoading = false
            return this.loadedOffices
        }
    }

    async loadTree(officeParentId = emptyGuid) {
        this.loadedOfficeIds.push(officeParentId)
        const params = {
            'filters.concernOfficeParentId': officeParentId
        }
        return await apiClient.getJson(APIEndpoints.partialOffices, params)
    }

    async loadAllOffices() {
        if (this.allLoaded) {
            return this.loadedOffices
        }
        else if (this.allOfficesLoading) {
            await this.app.uiStore.waitUntilLoaded(100, 'app.officeStore.allOfficesLoading')
            return this.loadAllOffices()
        }
        else {
            this.allOfficesLoading = true
            let allOffices = await apiClient.getJson(APIEndpoints.partialOffices)
            this.resetPartialOffices()
            this.loadedOfficeIds.push(emptyGuid)
            allOffices.forEach(office => {
                this.loadedOfficeIds.push(office.id)
            });
            this.loadedOffices = allOffices
            this.allLoaded = true
            this.allOfficesLoading = false
        }
    }

    async getOffice(officeId) {
        return await apiClient.getJson(APIEndpoints.partialOffice(officeId))
    }

    cancelGetFilteredOffices() {
        //cancel previous calls that are still pending
        cancel && cancel()
    }

    async getFilteredOffices(officeName) {
        this.cancelGetFilteredOffices()
        const cancelToken = new CancelToken(function executor(c) {
            cancel = c;
        })
        const params = {
            'filters.concernOfficeName': officeName
        }
        return await apiClient.getJson(APIEndpoints.partialOffices, params, true, false, true, { cancelToken })
    }
}
export default OfficeStore



