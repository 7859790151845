import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { Table } from 'react-bootstrap'

export const StyledTitle = styled.h2`
  padding-bottom: 8px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${colors.gray2};
`
export const StyledDescriptionBlock = styled.div`
  margin-bottom: 25px;
`
export const StyledTitleSmall = styled.div`
color: ${colors.gray5};
font-size: 1.2rem;
font-weight: 600;
padding-bottom: 8px;
margin-bottom: 15px;
border-bottom: 1px solid ${colors.gray2};
`
export const StyledTable = styled(Table)`
word-break:break-word;
`