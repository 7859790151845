import React from 'react'
import { Collapse } from 'react-bootstrap'
import { ArrowIcon } from '../Icons/ArrowIcon'
import { colors } from '../../styles/colors'
import styled from 'styled-components'
import CostCenterFilter from './CostCenterFilter'
import OfficeFilter from './OfficeFilter'
import { useEmployeesState } from '../../stores/EmployeesProvider'
import { StyledItemWrapper } from '../TreeList/treeList'
import { useTranslation } from 'react-i18next'
import CheckUpFilter from './CheckUpFilter'
import MissingArticleConfigurationsFilter from './MissingArticleConfigurationsFilter'

const StyledCollapseWrapper = styled.div`
  margin-bottom: 25px;
 

  ${StyledItemWrapper} {
    background-color: #FFF;
  }

`

const StyledFilterToggle = styled.h2`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${colors.gray5};
  font-size: 20px;
  margin-bottom: 10px;
  ${ArrowIcon} {
    margin-right: 10px;
  }
`

const OverlayLeftFilter = ({ checkupAdministration, missingArticleConfigurations }) => {
  const {
    openOfficeFilter,
    setOpenOfficeFilter,
    openCostCenterFilter,
    setOpenCostCenterFilter,
    openHealthDataFilter,
    setOpenHealthDataFilter
  } = useEmployeesState()

  const { t } = useTranslation()
  return (
    <>
      <StyledCollapseWrapper>
        <StyledFilterToggle onClick={() => setOpenOfficeFilter(!openOfficeFilter)} aria-controls="office-collapse"
          aria-expanded={openOfficeFilter}>
          <ArrowIcon variant={openOfficeFilter ? 'down' : 'right'} color={colors.gray5} />{t('orgaUnit')} ({t('orgaUnitShortcut')})
        </StyledFilterToggle>
        <Collapse in={openOfficeFilter}>
          <div id="office-collapse">
            {openOfficeFilter && <OfficeFilter />}
          </div>
        </Collapse>
      </StyledCollapseWrapper>

      <StyledCollapseWrapper>
        <StyledFilterToggle onClick={() => setOpenCostCenterFilter(!openCostCenterFilter)}
          aria-controls="costCenter-collapse" aria-expanded={openCostCenterFilter}>
          <ArrowIcon variant={openCostCenterFilter ? 'down' : 'right'} color={colors.gray5} />{t('costcenter')} ({t('costcenterShortcut')})
        </StyledFilterToggle>
        <Collapse in={openCostCenterFilter}>
          <div id="costCenter-collapse">
            {openCostCenterFilter && <CostCenterFilter />}
          </div>
        </Collapse>
      </StyledCollapseWrapper>

      {checkupAdministration && <StyledCollapseWrapper>
        <StyledFilterToggle onClick={() => setOpenHealthDataFilter(!openHealthDataFilter)}
          aria-controls="costCenter-collapse" aria-expanded={openCostCenterFilter}>
          <ArrowIcon variant={openHealthDataFilter ? 'down' : 'right'} color={colors.gray5} />{t('header:healthData')}
        </StyledFilterToggle>
        <Collapse in={openHealthDataFilter}>
          <div id="healthData-collapse">
            <CheckUpFilter />
          </div>
        </Collapse>
      </StyledCollapseWrapper>}
      {
        missingArticleConfigurations && <MissingArticleConfigurationsFilter />
      }
    </>
  )
}

export default OverlayLeftFilter
