import { cloneDeep } from "lodash"
import { getErrorMessageFromApiResponse, setPointerErrorsFromApiResponse } from "./apiUtils"
import { Alert } from "react-bootstrap"

//add or update state object property
/* 
  const [exampleState,setExampleState]=useState({})
  setStateByKey(setExampleState,"myKey",42) 
  
  now exampleState is equal to {myKey:42}
*/
export function setStateByKey(setState, key, value) {
  setState(prev => {
    prev[key] = value
    return cloneDeep(prev)
  })
}

//remove property from object 
/* 
  const [exampleState,setExampleState]=useState({myKey1:42,myKey2:55})
  deleteStateByKey(setExampleState,myKey1) 
  
  now exampleState is equal to {myKey2:55}
*/
export function deleteStateByKey(setState, key) {
  setState(prev => {
    delete prev[key]
    return cloneDeep(prev)
  })
}

/* 
  -------WITHOUT makeCall FUNCTION-----------------------------------------------------------
  const [errorStates, setErrorStates] = useState({call1Error:null})
  const [loadingStates, setLoadingStates] = useState({call1Loading:false})

  function makeCall1(){
    deleteStateByKey(setErrorStates, call1Error)
    setStateByKey(setLoadingStates, call1Loading, true)
     try {
       let result1 = await apiClient.getJson(APIEndpoints.myEndpoint1)
       console.log("log result1",result1)
    }
    catch (e) {
      setStateByKey(setErrorStates, call1Error, getErrorMessageFromApiResponse(e))
    }
    finally {
      deleteStateByKey(setLoadingStates, call1Loading)
    }
  }

  -------WITH makeCall FUNCTION-----------------------------------------------------------
  const [errorStates, setErrorStates] = useState({call1Error:null,call2Error:null})
  const [loadingStates, setLoadingStates] = useState({call1Loading:false,call2Loading:false})

 function makeCall1(){
    makeCall(call1,async()=>{
         let result2 = await apiClient.getJson(APIEndpoints.myEndpoint2)
         console.log("log result2",result2)}, setErrorStates, setLoadingStates)
      },  setErrorStates,setLoadingStates
 }
*/

export async function makeCall(key, callback, setErrorStates, setLoadingStates, isCancel) {
  deleteStateByKey(setErrorStates, key)
  setStateByKey(setLoadingStates, key, true)
  try {
    await callback()
    deleteStateByKey(setLoadingStates, key)
  }
  catch (e) {
    if (!isCancel || !isCancel(e)) {
      //isCancel comes from axios CancelToken and tells you if the error is from the cancelToken
      deleteStateByKey(setLoadingStates, key)
      console.log(getErrorMessageFromApiResponse(e))
      console.log(setPointerErrorsFromApiResponse(e))
      setStateByKey(setErrorStates, key, { value: getErrorMessageFromApiResponse(e), formErrors: setPointerErrorsFromApiResponse(e) })

    }
  }
}

export const ErrorAlerts = ({ errorStates }) => (
  Object.values(errorStates).map((error, i) => <Alert key={i} variant="danger">{error.value}</Alert>)
)

