import React from 'react'

export const ArrowBigIcon = (props) => {
  const color = props.color || '#3C3C3B'
  const variant = props.variant || 'left'
  const width = props.width || "28px"
  const height = props.height || "28px"
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={color}>
          {variant === 'left' && (
            <path d="M19.9497475,8 C20.5020322,8 20.9497475,8.44771525 20.9497475,9 L20.9507765,18.6389989 C20.9827253,18.7368132 21,18.841263 21,18.9497475 C21,19.2388791 20.8772936,19.4993512 20.6811162,19.6819283 C20.4993512,19.8772936 20.2388791,20 19.9497475,20 C19.841263,20 19.7368132,19.9827253 19.6389989,19.9507765 L10,19.9497475 C9.44771525,19.9497475 9,19.5020322 9,18.9497475 C9,18.3974627 9.44771525,17.9497475 10,17.9497475 L18.949,17.949 L18.9497475,9 C18.9497475,8.44771525 19.3974627,8 19.9497475,8 Z"
              transform="translate(15.000000, 14.000000) rotate(-225.000000) translate(-15.000000, -14.000000)" />
          )}
          {variant === 'right' && (
            <path d="M15.9497475,8 C16.5020322,8 16.9497475,8.44771525 16.9497475,9 L16.9507765,18.6389989 C16.9827253,18.7368132 17,18.841263 17,18.9497475 C17,19.2388791 16.8772936,19.4993512 16.6811162,19.6819283 C16.4993512,19.8772936 16.2388791,20 15.9497475,20 C15.841263,20 15.7368132,19.9827253 15.6389989,19.9507765 L6,19.9497475 C5.44771525,19.9497475 5,19.5020322 5,18.9497475 C5,18.3974627 5.44771525,17.9497475 6,17.9497475 L14.949,17.949 L14.9497475,9 C14.9497475,8.44771525 15.3974627,8 15.9497475,8 Z"
              transform="translate(11.000000, 14.000000) rotate(-45.000000) translate(-11.000000, -14.000000)" />
          )}
          {variant === 'up' && (
            <path d="M18.9497475,5 C19.5020322,5 19.9497475,5.44771525 19.9497475,6 L19.9507765,15.6389989 C19.9827253,15.7368132 20,15.841263 20,15.9497475 C20,16.2388791 19.8772936,16.4993512 19.6811162,16.6819283 C19.4993512,16.8772936 19.2388791,17 18.9497475,17 C18.841263,17 18.7368132,16.9827253 18.6389989,16.9507765 L9,16.9497475 C8.44771525,16.9497475 8,16.5020322 8,15.9497475 C8,15.3974627 8.44771525,14.9497475 9,14.9497475 L17.949,14.949 L17.9497475,6 C17.9497475,5.44771525 18.3974627,5 18.9497475,5 Z"
              transform="translate(14.000000, 16.000000) rotate(-135.000000) translate(-14.000000, -11.000000)" />
          )}
          {variant === 'down' && (
            <path d="M18.9497475,5 C19.5020322,5 19.9497475,5.44771525 19.9497475,6 L19.9507765,15.6389989 C19.9827253,15.7368132 20,15.841263 20,15.9497475 C20,16.2388791 19.8772936,16.4993512 19.6811162,16.6819283 C19.4993512,16.8772936 19.2388791,17 18.9497475,17 C18.841263,17 18.7368132,16.9827253 18.6389989,16.9507765 L9,16.9497475 C8.44771525,16.9497475 8,16.5020322 8,15.9497475 C8,15.3974627 8.44771525,14.9497475 9,14.9497475 L17.949,14.949 L17.9497475,6 C17.9497475,5.44771525 18.3974627,5 18.9497475,5 Z"
              transform="translate(14.000000, 11.000000) rotate(-315.000000) translate(-14.000000, -11.000000)" />
          )}
        </g>
      </g>
    </svg>
  )
}

