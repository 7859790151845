import React from 'react'

import { AddToBasketButton } from '../Buttons/AddToBasketButton'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { inject, observer } from 'mobx-react'
import { Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import VariantQuantityTable from './VariantQuantityTable'
import { StyledFormCol } from './ProductBuyBoxStyles'
import { useTheme } from 'styled-components'
import { t } from 'i18next'
import DefaultBuyBox from './DefaultBuyBox'

const CostCenterBuyBox = inject('productDetailsStore', 'teamOrderStore', 'productsCompareStore')(observer((props) => {
  const { productDetailsStore, teamOrderStore, productsCompareStore } = props
  const currentLocation = useLocation()
  const shoppingBasketArticle = productDetailsStore.shoppingBasketArticle
  const articleAvailability = shoppingBasketArticle.articleAvailability || {}
  const orderableStatus = articleAvailability.orderableStatus
  const variants = productDetailsStore.variants || []
  const submitButtonDisabled = variants.length && (teamOrderStore.selectedVariants).length === 0
  const theme = useTheme()

  return (
    <Row>
      <StyledFormCol as={Col} xs={12}
        className={(!orderableStatus || orderableStatus === 'isOrderable') ? '' : 'notOrderable'}
        data-max-mount-reached={t('productDetail:maxAmountReached')}>
        {variants.length
          ? <VariantQuantityTable />
          : <DefaultBuyBox />
        }
        <hr className='mt-5' />
        <div className='d-flex align-items-md-center flex-column flex-md-row justify-content-end'>
          <AddToBasketButton
            buttonText={t('productDetail:addToBasketButton')}
            onClick={productDetailsStore.handleAddToBasket}
            variant={submitButtonDisabled ? 'secondary' : 'primary'}
            type={'submit'}
            loadingText={t('productDetail:buttonWaitText')}
            isLoading={productDetailsStore.addToBasketIsLoading}
            disabled={submitButtonDisabled}
          />
        </div>
        <hr />
        {theme.enableCostCenterProductsComparison && teamOrderStore.selectedCostcenterId ? <div className='d-flex align-items-md-center flex-column flex-md-row justify-content-end'>
          {productsCompareStore.articles.some(art => art.id === productDetailsStore.article.id) ?
            <Button onClick={() => productsCompareStore.deleteArticle(productDetailsStore.article.id)} size="sm" variant="danger">{t('articleComparison:removeFromComparison')}</Button>
            :
            <Button onClick={() => productsCompareStore.onArticleCompareHandler(productDetailsStore.article.id, currentLocation.pathname, productDetailsStore.price)} disabled={productsCompareStore.articles.length >= 5} size="sm" >{t('articleComparison:compareArticle')}</Button>
          }
        </div> : null}
      </StyledFormCol>
    </Row>
  )
}))

export default CostCenterBuyBox