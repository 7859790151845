import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import PositionsList from './PositionsList'
import ListActionButtons from './ListActionButtons'
import { observable, makeObservable } from 'mobx';
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { settingValueToType } from '../../../utils/api/apiUtils'

class PositionsListWrapper extends Component {
  showRetourButton = false
  showCancelButton = false

  hasRightToClaim = false
  hasRightToReturn = false
  hasRightToCancel = false
  allPositions = {};

  changeAllIsChecked = {};
  positionsChecked = {};
  buttonsDisabled = true;
  loadedPositions = {};
  /* 
      Rules:
      returnButton 
        *position not cancelled
        *shippingOrder status===closed
        *setting allowCreateReturnShipment
      cancelButton
        *position not cancelled
        *shippingOrder status===open || not shippingOrder/is incomingOrder
        *incomingOrder.metaProperties isDeletable
      claimButton
        *position not cancelled
        *setting allowCreateComplaints
    */
  constructor(props) {
    super(props)

    makeObservable(this, {
      allPositions: observable,
      changeAllIsChecked: observable,
      positionsChecked: observable,
      buttonsDisabled: observable,
      loadedPositions: observable
    });

    const { orders, positions, incomingOrder, orderStore } = this.props

    //orders -> shippingOrders, position -> incomingOrderPositions
    this.hasRightToCancel = incomingOrder.metaProperties && incomingOrder.metaProperties.find(metaProp => (metaProp.pointer === '/id' && metaProp.attributes === 'isDeletable'))
    this.hasRightToReturn = !!settingValueToType(orderStore.orderDetailSettings.find(setting => setting.settingName === 'allowCreateReturnShipment'))
    this.hasRightToClaim = !!settingValueToType(orderStore.orderDetailSettings.find(setting => setting.settingName === 'allowCreateComplaints'))
    this.allowOrderWasPickedUp = !!settingValueToType(orderStore.orderDetailSettings.find(setting => setting.settingName === 'allowOrderWasPickedUp'))
    this.allowCreateReturnsFromDifferentShippingOrders = !!settingValueToType(orderStore.orderDetailSettings.find(setting => setting.settingName === 'allowCreateReturnsFromDifferentShippingOrders'))

    if (orders && orders.length) {
      this.initShippingPositions()
    } else {
      this.showCancelButton = true
      this.allPositions[incomingOrder.id] = positions
      this.positionsChecked[incomingOrder.id] = {}
      this.changeAllIsChecked[incomingOrder.id] = false
      this.initPositionsChecked(incomingOrder.id, this.allPositions[incomingOrder.id])
    }
  }

  componentDidUpdate() {
    const { orderStore } = this.props
    if (orderStore.reloadShippingPositions) {
      orderStore.reloadShippingPositions = false
      this.initShippingPositions()
    }
  }

  initShippingPositions() {
    const { orders } = this.props

    if (orders && orders.length) {
      this.showRetourButton = orders[0].status === 'closed' //orders are sorted in OrdersDetailPage in categories closed and open
      this.showCancelButton = orders[0].status === 'open'

      let counterLoaded = 0
      orders.forEach(order => {
        this.positionsChecked[order.id] = {}
        this.changeAllIsChecked[order.id] = false;

        (async () => {
          this.loadedPositions[order.id] = []
          const loadedPositions = await apiClient.getJson(APIEndpoints.shippingOrderPositions(order.id))
          this.initPositionsChecked(order.id, loadedPositions)
          this.loadedPositions[order.id] = loadedPositions
          this.allPositions[order.id] = loadedPositions
          counterLoaded++
          if (counterLoaded >= orders.length) {
            this.forceUpdate()
          }
        })()
      })

    }
  }

  initPositionsChecked(incomingOrderOrShippingOrderId, positions) {
    const hasSomeRights = (this.hasRightToCancel && this.showCancelButton) || this.hasRightToClaim || (this.hasRightToReturn && this.showRetourButton)
    if (hasSomeRights) {
      positions.forEach(orderPosition => (orderPosition.isCanceled) ? '' : this.positionsChecked[incomingOrderOrShippingOrderId][orderPosition.id] = false)
    }
  }

  getCheckedPositions() {
    const { orderStore } = this.props
    let filteredPositions = []
    Object.keys(this.allPositions).forEach(incomingOrderOrShippingOrderId => {
      const checkedItems = this.allPositions[incomingOrderOrShippingOrderId].filter(position => {
        return this.positionsChecked[incomingOrderOrShippingOrderId][position.id]
      })
      if (checkedItems.length) {
        let positionGroup = filteredPositions.find(pGroup => pGroup.incomingOrderOrShippingOrderId === incomingOrderOrShippingOrderId)
        if (!positionGroup) {
          //needed for submitCancelation function
          const incomingOrder = incomingOrderOrShippingOrderId === orderStore.incomingOrder.id ? orderStore.incomingOrder : null
          const shippingOrder = orderStore.shippingOrders.find(shippingOrder => shippingOrder.id === incomingOrderOrShippingOrderId)
          positionGroup = {
            incomingOrderOrShippingOrderId: incomingOrderOrShippingOrderId,
            shippingOrder: shippingOrder,
            positions: [],
            incomingOrder: incomingOrder
          }

          filteredPositions.push(positionGroup)

        }
        /*if (shippingOrder) {
          checkedItems.forEach(position => {
            // position.shippingOrderPosition = {id: ship}
          })
        }*/
        positionGroup.positions = positionGroup.positions.concat(checkedItems)
      }
    })
    return filteredPositions
  }

  handleChangeAll = incomingOrderOrShippingOrderId => e => {
    this.changeAllIsChecked[incomingOrderOrShippingOrderId] = !this.changeAllIsChecked[incomingOrderOrShippingOrderId]
    this.allPositions[incomingOrderOrShippingOrderId].forEach(orderPosition => {
      if (this.positionsChecked[incomingOrderOrShippingOrderId].hasOwnProperty(orderPosition.id)) {
        this.positionsChecked[incomingOrderOrShippingOrderId][orderPosition.id] = this.changeAllIsChecked[incomingOrderOrShippingOrderId]
      }
    })

    if (!this.allowCreateReturnsFromDifferentShippingOrders) {
      // Uncheck all positions from other groups
      Object.keys(this.positionsChecked).forEach(orderId => {
        if (orderId !== incomingOrderOrShippingOrderId) {
          Object.keys(this.positionsChecked[orderId]).forEach(posId => {
            this.positionsChecked[orderId][posId] = false;
          });
          this.changeAllIsChecked[orderId] = false;
        }
      });
    }
  }

  handleChangeChecked = incomingOrderOrShippingOrderId => positionId => e => {
    console.log('handleChangeChecked', { incomingOrderOrShippingOrderId, positionId, e })
    // Check or uncheck the specific position
    this.positionsChecked[incomingOrderOrShippingOrderId][positionId] = !this.positionsChecked[incomingOrderOrShippingOrderId][positionId]

    // Update the changeAllIsChecked state for the current group
    this.changeAllIsChecked[incomingOrderOrShippingOrderId] = Object.keys(this.positionsChecked[incomingOrderOrShippingOrderId]).reduce((acc, id) => this.positionsChecked[incomingOrderOrShippingOrderId][id] && acc, true)
    console.log('positionsChecked now', this.positionsChecked)

    if (!this.allowCreateReturnsFromDifferentShippingOrders) {
      // If a position is checked, uncheck all positions from other groups
      if (this.positionsChecked[incomingOrderOrShippingOrderId][positionId]) {
        Object.keys(this.positionsChecked).forEach(orderId => {
          if (orderId !== incomingOrderOrShippingOrderId) {
            Object.keys(this.positionsChecked[orderId]).forEach(posId => {
              this.positionsChecked[orderId][posId] = false;
            });
            this.changeAllIsChecked[orderId] = false;
          }
        });
      }
    }
  }

  /**
   *
   * @param action string, one of cancellation, reclamation, retouration
   */
  handleClickAction = action => e => {
    const { orderDialogStore } = this.props
    orderDialogStore.showDialog(action, this.getCheckedPositions())
  }

  renderPositionsList() {
    const { incomingOrder } = this.props
    const positions = this.allPositions[incomingOrder.id]
    const isTemplate = incomingOrder.status === 'template'
    const hasCheckablePositions = !isTemplate && this.positionsChecked[incomingOrder.id] && Object.keys(this.positionsChecked[incomingOrder.id]).length > 0

    if (positions && positions.length) {
      return <React.Fragment>
        <PositionsList
          incomingOrder={incomingOrder}
          positions={positions}
          orderInfo={null}
          positionsChecked={this.positionsChecked[incomingOrder.id]}
          handleChangeChecked={this.handleChangeChecked(incomingOrder.id)}
          handleChangeAll={this.handleChangeAll(incomingOrder.id)}
          changeAllIsChecked={this.changeAllIsChecked[incomingOrder.id]}
          hasCheckablePositions={hasCheckablePositions} />
        {hasCheckablePositions && this.renderListActionButtons()}
      </React.Fragment>
    }
    return null
  }

  renderOrderLists() {
    const { incomingOrder, orders, orderStore } = this.props
    if (orders && orders.length) {
      const hasCheckablePositions = orders.reduce((acc, shippingOrder) => {
        if (this.positionsChecked[shippingOrder.id]) {
          return acc || Object.keys(this.positionsChecked[shippingOrder.id]).length > 0
        }
        return acc
      }, false)
      console.log('loadedPositions', this.loadedPositions)
      const lists = orders.map((shippingOrder, it) => (
        this.loadedPositions[shippingOrder.id] ? (
          <PositionsList
            incomingOrder={incomingOrder}
            positions={this.loadedPositions[shippingOrder.id]}
            key={shippingOrder.id}
            orderInfo={shippingOrder}
            index={it + 1}
            positionsChecked={this.positionsChecked[shippingOrder.id]}
            handleChangeChecked={this.handleChangeChecked(shippingOrder.id)}
            handleChangeAll={this.handleChangeAll(shippingOrder.id)}
            changeAllIsChecked={this.changeAllIsChecked[shippingOrder.id]}
            hasCheckablePositions={hasCheckablePositions}
            allowOrderWasPickedUp={this.allowOrderWasPickedUp}
            resetOrderPage={() => orderStore.resetOrderPage()}
          />
        ) : ''
      ))

      return <React.Fragment>
        {lists}
        {hasCheckablePositions && this.renderListActionButtons()}
      </React.Fragment>
    }
    return null
  }

  renderListActionButtons() {
    const buttonsDisabled = !this.getCheckedPositions().length


    return (
      <ListActionButtons buttonsDisabled={buttonsDisabled}
        showRetourButton={this.hasRightToReturn && this.showRetourButton}
        showClaimButton={this.hasRightToClaim}
        showCancelButton={this.hasRightToCancel && this.showCancelButton}
        clickCancel={this.handleClickAction('cancellation')}
        clickClaim={this.handleClickAction('reclamation')}
        clickRetour={this.handleClickAction('retouration')} />
    )
  }

  render() {
    const { title } = this.props
    return (
      <React.Fragment>
        <div className='mb-5'>
          <h3 className='mb-4'>{title}</h3>
          {/*  incomingOrderPositions Without ShippingOrder */}
          {this.renderPositionsList()}
          {/*  open or closed positions */}
          {this.renderOrderLists()}
        </div>
      </React.Fragment>
    )
  }
}

export default inject('orderDialogStore', 'orderStore')(observer(PositionsListWrapper));
