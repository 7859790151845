import React from 'react'

export const LockClosedIcon = ({ color, title, ...rest }) => {

    const useColor = color ? color : '#3C3C3B'
    return (
        <svg width="16px" height="16px" fill={useColor} viewBox="7 5 16 16" {...rest}>
            {title && <title>{title}</title>}
            <g transform="translate(7.000000, 6.000000)">
                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                />
            </g>

        </svg>
    )
}