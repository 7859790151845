import React, { useCallback, useRef, useState, memo, useEffect } from 'react'
import Address from '../FormComponents/Address'
import { StyledCollapseTrigger } from '../formHelper'
import { ArrowBigIcon } from '../../../Icons/ArrowBigIcon'
import { colors } from '../../../../styles/colors'
import { Collapse } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import apiClient from '../../../../utils/api/apiClient'
import { APIEndpoints } from '../../../../utils/api/apiConfig'
import InformationPopover from '../../../InformationPopover/InformationPopover'

const BillingAddress = memo(({ type, collapsible, addresses, setAddressesByKey }) => {
  const businessBillingAddress = addresses.newItems[1]
  const [availableCountries, setAvailableCountries] = useState([])
  const [opened, setOpened] = useState(!collapsible)
  const ref = useRef()

  useEffect(() => {
    (async () => {
      const countries = await apiClient.getJson(APIEndpoints.countries)
      if (countries && countries.length) {
        setAvailableCountries(countries)
        const defaultCountry = countries.find(obj => obj.isDefault)
        if (defaultCountry && type === "create") {
          setAddressesByKey('newItems.1.countryIsoCode', defaultCountry.isoCode)
        }
      }
    })()
  }, [setAddressesByKey, type])

  const handleClickCollapse = useCallback(() => {
    setOpened(prev => !prev)
  }, [setOpened])

  const handleChange = e => {
    const key = `newItems.1.${e.target.name}`
    setAddressesByKey(key, e.target.value)
  }

  const { t } = useTranslation()

  return (
    <div ref={ref}>
      {collapsible ? (
        <StyledCollapseTrigger onClick={handleClickCollapse}>
          <h1>
            <ArrowBigIcon variant={opened ? 'down' : 'right'} color={colors.textColor} />
            {t('EmployeeFormBillingAddress:billingAddress')}
            <InformationPopover header={t('EmployeeFormBillingAddress:billingAddress')} content={t('EmployeeFormBillingAddress:billingAddressInfo')} />
          </h1>
        </StyledCollapseTrigger>
      ) : (
        <h1>
          {t('EmployeeFormBillingAddress:billingAddress')}
          <InformationPopover header={t('EmployeeFormBillingAddress:billingAddress')} content={t('EmployeeFormBillingAddress:billingAddressInfo')} />
        </h1>
      )}
      <Collapse in={opened}>
        <div>
          <div className="spacing-down">
            <Address availableCountries={availableCountries} onChange={handleChange} address={businessBillingAddress} />
          </div>
        </div>
      </Collapse>
    </div>
  )
})
export default BillingAddress
