import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import styled from 'styled-components'
import { NewCheckIcon } from '../../Icons/NewCheckIcon'
import { useTranslation } from 'react-i18next'
import ArraySelect from '../../Select/ArraySelect'
import { useEmployeeDataState } from '../../../stores/EmployeeDataProvider'
import useStores from '../../../stores/useStore'

const StyledWrapper = styled.div`
  margin:7px;
  select {
    min-width: 200px !important;
  }
  @media only screen and (max-width: 410px), (min-device-width: 320px) and (max-device-width: 410px)  {  
    select {
      margin-bottom: 20px;
    }
}  
`


const EmployeeUserSelector = () => {
  const { t } = useTranslation()
  const { accountId, showUserData, setShowUserData } = useEmployeeDataState()
  const { uiStore } = useStores()

  return (
    <StyledWrapper>
      <Form.Label>{t('Subheader:userGroup')}</Form.Label>
      <InputGroup >
        <InputGroup.Text ><NewCheckIcon /></InputGroup.Text>
        {
          accountId ?
            <InputGroup.Text style={{ minWidth: "150px" }}>User</InputGroup.Text>
            : uiStore.allowAccessUserModule ?
              <ArraySelect
                value={showUserData ? "user" : "employee"}
                onChange={() => setShowUserData(prev => !prev)}
                options={[{ id: "employee", displayName: t('header:employees') }, { id: "user", displayName: "User" }]}
              />
              : <InputGroup.Text style={{ minWidth: "150px" }}>{t('header:employees')}</InputGroup.Text>
        }
      </InputGroup>
    </StyledWrapper>
  )
}

export default EmployeeUserSelector
