import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import { cloneDeep } from "lodash"

const _formData = {
    time: "--:--"
}

const AddTimesModal = (props) => {
    const { handleClose, updateTimeSlots, hoursArray } = props
    const innerRef = useRef();
    const [formData, setFormData] = useState({ ..._formData })
    const [validated, setValidated] = useState(false)
    const { t } = useTranslation()

    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }

    //autofocus
    useEffect(() => innerRef.current && innerRef.current.focus(), []);

    //cleanup
    useEffect(() => {
        return () => clearData()
    }, [])

    const clearData = () => {
        setFormData({ ..._formData })
        setValidated(false)
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        event.stopPropagation()

        if (validated) {
            const hour = parseInt(formData.time.substring(0, 2))
            const minute = parseInt(formData.time.substring(3, 5))
            const newArray = cloneDeep(hoursArray)
            newArray[hour].push({ hour, minute })
            updateTimeSlots(newArray)
            handleClose()
        }
    }

    const validateForm = (event) => {
        const form = event.currentTarget
        setValidated(form.checkValidity())
    }

    return (
        <>
            <Modal style={{ textAlign: "center" }} centered show={true} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title >{t('appointment:addTime')}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form
                        id="my-form"
                        validated={validated}
                        onChange={validateForm}
                        onSubmit={handleSubmit}
                    >
                        <Form.Group className="mb-3">
                            <Form.Label>{t('start')}</Form.Label>
                            <Form.Control
                                ref={innerRef}
                                value={formData.time}
                                type="time"
                                onChange={handleInputChange}
                                name='time'
                                required
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body >
                <Modal.Footer >
                    <Button
                        form="my-form"
                        variant='primary'
                        disabled={!validated}
                        type="submit"
                    >
                        {t('create')}
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    );
};

export default AddTimesModal;
