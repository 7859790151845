import React from 'react'
import { ContentArea } from '../../Page/logix2020/ContentArea'
import AppointmentTable from '../Form/FormParts/AppointmentTable'

const EditContentArea = () => {

  return (
    <>
      <ContentArea >
        <AppointmentTable />
      </ContentArea>
    </>
  )
}

export default EditContentArea