import React from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

const withRouter = WrappedComponent => props => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // etc... other react-router-dom v6 hooks

  return (
    <WrappedComponent
      {...props}
      params={params}
      navigate={navigate}
      location={location}
    // etc...
    />
  );
};
export default withRouter