import React, { useCallback, useMemo, useState } from 'react'
import { cloneDeep } from 'lodash'

import { useCancelToken } from '../../utils/hooks/useCancelToken'
import { makeCall, setStateByKey } from '../../utils/api/makeCall'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { addAssignedTreeDimensionArticles, addCatalogArticles, addMissingStructures, getassignedTree } from '../../components/Configuration/Catalog/CatalogHelperFunctions'
import { emptyGuid } from '../../utils/emptyGuid'
import { emptyDate } from '../../utils/emptyDate'


const catalogTemplateObj = {
  catalog: {
    name: {
      values: []
    },
    foreignKey: "",
    activeFrom: emptyDate,
    activeTo: emptyDate,
    isAlwaysActive: false,
    useAutomaticImport: false,
    isRightDependent: false,
    isExternalCatalog: false,
    externalLogin: "",
    externalPassword: "",
    externalCatalogSupplierMandatorId: emptyGuid,
    externalCatalogUseOCIInterface: false,
    isPunchOutCatalog: false,
    punchOutCatalogInvocationURL: "",
    punchOutCatalogSupplierBpId: emptyGuid,
    isShopLink: false,
    shopLinkURL: "",
    id: emptyGuid
  },
  importParameter: {
    isFTP: false,
    fTP_UsesSFPT: false,
    fTP_UsesFTPS: false,
    fTP_Server: "",
    fTP_Port: 0,
    fTP_Login: "",
    fTP_Password_Encrypted: "",
    importFile_Path: "",
    importFile_Format: "",
    id: emptyGuid
  },
  oCIParameters: [],
  assignedOrderProcessTypes: []
}

const catalogBaseObjTemplate = {
  name: {
    values: []
  },
  foreignKey: "",
  activeFrom: emptyDate,
  activeTo: emptyDate,
  isAlwaysActive: false,
  useAutomaticImport: false,
  isRightDependent: false,
  isExternalCatalog: false,
  externalLogin: "",
  externalPassword: "",
  externalCatalogSupplierMandatorId: emptyGuid,
  externalCatalogUseOCIInterface: false,
  isPunchOutCatalog: false,
  punchOutCatalogInvocationURL: "",
  punchOutCatalogSupplierBpId: emptyGuid,
  isShopLink: false,
  shopLinkURL: "",
  id: emptyGuid
}

const CatalogsContext = React.createContext({})
export const useCatalogsState = () => {
  return React.useContext(CatalogsContext)
}

const CatalogsProvider = ({ children }) => {
  const [errorStates, setErrorStates] = useState({})
  const [loadingStates, setLoadingStates] = useState({})
  const [successStates, setSuccessStates] = useState({})
  const [catalog, setCatalog] = useState(catalogTemplateObj)
  const [redirectId, setRedirectId] = useState('')
  const [deleteCatalogId, setDeleteCatalogId] = useState('')
  const [orderProcessTypes, setOrderProcessTypes] = useState([])
  const [initialAssignedOrderProcessTypes, setInitialAssignedOrderProcessTypes] = useState(JSON.stringify([]))
  const [assignedOrderProcessTypes, setAssignedOrderProcessTypes] = useState([])
  const [aliasTypes, setAliasTypes] = useState(false)
  const [allOciParameters, setAllOciParameters] = useState([])
  const [ociTextTypes, setOciTextTypes] = useState([])
  const [ociAliasTypes, setOciAliasTypes] = useState([])
  const [deleteParameterRow, setDeleteParameterRow] = useState('')
  const [bigData, setBigData] = useState(null)
  const [catalogBaseObj, setCatalogBaseObj] = useState(catalogBaseObjTemplate)
  const [initialCatalogBaseObj, setInitialCatalogBaseObj] = useState(JSON.stringify(catalogBaseObjTemplate))
  const [supplierMandators, setSupplierMandators] = useState([])
  const [suppliers, setSuppliers] = useState([])

  const [assignmentTree, setAssignmentTree] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { createOrCancelToken, sourceRef, isCancel } = useCancelToken();

  const assignedOrderProcessTypesHasChanged = useMemo(() => {
    return initialAssignedOrderProcessTypes !== JSON.stringify(assignedOrderProcessTypes)
  }, [initialAssignedOrderProcessTypes, assignedOrderProcessTypes])

  const getCatalogs = useCallback(async (force) => {
    if ((bigData && Object.keys(bigData).length) && !force) {
      return null
    }
    else {
      await makeCall('catalogs', async () => {
        let loadedCatalogs = await apiClient.getJson(APIEndpoints.administration().catalogs)
        let newBigData = {}
        for (let catalog of loadedCatalogs) {
          let newValue = catalog
          newValue.dimensions = {}
          newValue.articles = {}
          newBigData[catalog.id] = newValue
        }
        setBigData(newBigData)
      }, setErrorStates, setLoadingStates)
    }
  }, [bigData])

  async function getCatalog(catalogId) {
    await makeCall('getCatalog', async () => {
      let loadedCatalog = await apiClient.getJson(APIEndpoints.catalogs(catalogId).catalog)
      setCatalog(loadedCatalog)
      setCatalogBaseObj(loadedCatalog.catalog)
      setInitialCatalogBaseObj(JSON.stringify(loadedCatalog.catalog))
      setAllOciParameters(loadedCatalog.oCIParameters)
      setInitialAssignedOrderProcessTypes(JSON.stringify(loadedCatalog.assignedOrderProcessTypes))
      setAssignedOrderProcessTypes(loadedCatalog.assignedOrderProcessTypes)
    }, setErrorStates, setLoadingStates)
  }

  function getCatalogStructure(catalogId) {
    makeCall('getCatalogStructure', async () => {
      let loadedStructure = await apiClient.getJson(APIEndpoints.catalogs(catalogId).structures)
      let newDimensions = {}
      for (let dimension of loadedStructure) {
        dimension.type = "dimension"
        dimension.articles = {}
        dimension.catalogId = catalogId
        dimension.dimensionsLoaded = true
        newDimensions[dimension.id] = dimension
      }
      setBigData(prev => {
        prev[catalogId].dimensions = newDimensions
        prev[catalogId].dimensionsLoaded = true
        return cloneDeep(prev)
      })
    }, setErrorStates, setLoadingStates)
  }

  async function updateCatalogStructure(catalogId, parentId) {
    let params = { parentId }
    await makeCall('getCatalogStructure', async () => {
      let loadedStructure = await apiClient.getJson(APIEndpoints.catalogs(catalogId).structures, params)
      for (let dimension of loadedStructure) {
        dimension.type = "dimension"
        dimension.articles = {}
        dimension.catalogId = catalogId
        dimension.dimensionsLoaded = true
      }
      setBigData(prev => {
        //remove dimensions with parentId 
        let prevDimensions = Object.values(prev[catalogId].dimensions).filter(d => d.parentId !== parentId)
        let newDimensionsArray = [...prevDimensions, ...loadedStructure]
        let newDimensions = {}
        for (let dimension of newDimensionsArray) {
          newDimensions[dimension.id] = dimension
        }
        prev[catalogId].dimensions = newDimensions
        prev[catalogId].dimensionsLoaded = true
        return cloneDeep(prev)
      })
    }, setErrorStates, setLoadingStates)
  }

  async function getStructureArticles(catalogId, elementId) {
    await makeCall('structureArticles', async () => {
      let loadedArticles = await apiClient.getJson(APIEndpoints.catalogs(catalogId, elementId).structureArticles)
      let newArticles = {}
      for (let article of loadedArticles) {
        article.type = "article"
        newArticles[article.id] = article
      }
      setBigData(prev => {
        prev[catalogId].dimensions[elementId].articles = newArticles
        prev[catalogId].dimensions[elementId].articlesLoaded = true
        return cloneDeep(prev)
      })

    }, setErrorStates, setLoadingStates)
  }

  async function getCatalogArticles(catalogId) {
    await makeCall('catalogArticles', async () => {
      let loadedArticles = await apiClient.getJson(APIEndpoints.catalogs(catalogId).catalogArticles)
      let newArticles = {}
      for (let article of loadedArticles) {
        article.level = 1
        article.type = "article"
        newArticles[article.id] = article
      }
      setBigData(prev => {
        prev[catalogId].articles = newArticles
        prev[catalogId].articlesLoaded = true
        return cloneDeep(prev)
      })
    }, setErrorStates, setLoadingStates)
  }

  async function patchDimension(catalogId, elementId, parentId, params, handleClose) {
    await makeCall('saveDimension', async () => {
      await apiClient.patchJson(APIEndpoints.catalogs(catalogId, elementId).dimension, params)
      await updateCatalogStructure(catalogId, parentId)
      setStateByKey(setSuccessStates, 'saveDimension', true)
      handleClose()
    }, setErrorStates, setLoadingStates)
  }
  async function postDimension(catalogId, parentId, params, handleClose) {
    await makeCall('saveDimension', async () => {
      await apiClient.postJson(APIEndpoints.catalogs(catalogId).createDimension, params)
      await updateCatalogStructure(catalogId, parentId)
      setStateByKey(setSuccessStates, 'saveDimension', true)
      handleClose()
    }, setErrorStates, setLoadingStates)
  }
  async function deleteDimension(catalogId, elementId, parentId) {
    await makeCall('deleteDimension', async () => {
      await apiClient.deleteJson(APIEndpoints.catalogs(catalogId, elementId).dimension)
      await updateCatalogStructure(catalogId, parentId)
    }, setErrorStates, setLoadingStates)
  }

  async function deleteCatalogArticle(catalogId, articleId) {
    await makeCall('deleteArticle', async () => {
      await apiClient.deleteJson(APIEndpoints.catalogs(catalogId, null, articleId).deleteCatalogArticle)
      await getCatalogArticles(catalogId)
    }, setErrorStates, setLoadingStates)
  }

  async function deleteStructureArticle(catalogId, elementId, articleId) {
    await makeCall('deleteArticle', async () => {
      await apiClient.deleteJson(APIEndpoints.catalogs(catalogId, elementId, articleId).deleteStructureArticle)
      await getStructureArticles(catalogId, elementId)
    }, setErrorStates, setLoadingStates)
  }
  async function putCatalogsOrderSequence(newSequence) {
    createOrCancelToken()
    makeCall('saveOrderSequence', async () => {
      await apiClient.putJson(APIEndpoints.administration().catalogsOrderSequences, newSequence, true, false, { cancelToken: sourceRef.current.token })
      getCatalogs(true)
    }, setErrorStates, setLoadingStates, isCancel)
  }
  async function patchStructureSequence(catalogId, parentId, newSequence) {
    createOrCancelToken()
    makeCall('structureOrderSequence', async () => {
      await apiClient.patchJson(APIEndpoints.catalogs(catalogId).structureOrderSequence, newSequence, true, false, { cancelToken: sourceRef.current.token })
      updateCatalogStructure(catalogId, parentId)
    }, setErrorStates, setLoadingStates, isCancel)
  }

  async function putCatalogsOrderSequenceBackground(newSequence) {
    createOrCancelToken()
    const catalogsIds = newSequence.map(a => a.id)
    makeCall('saveOrderSequenceBackground', async () => {
      await apiClient.putJson(APIEndpoints.administration().catalogsOrderSequences, catalogsIds, true, false, { cancelToken: sourceRef.current.token })
    }, setErrorStates, setLoadingStates, isCancel)
  }

  async function getOrderProcessTypes() {
    await makeCall('orderProcessTypes', async () => {
      let loadedOrderProcessTypes = await apiClient.getJson(APIEndpoints.orderProcessTypes)
      setOrderProcessTypes(loadedOrderProcessTypes)
    }, setErrorStates, setLoadingStates)
  }

  async function getAliasTypes() {
    await makeCall('getAliasTypes', async () => {
      let loadedAliasTypes = await apiClient.getJson(APIEndpoints.aliasTypes)
      setAliasTypes(loadedAliasTypes)
    }, setErrorStates, setLoadingStates)
  }

  function patchCatalog(catalogId, catalogData = null) {
    makeCall('saveCatalog', async () => {
      await apiClient.patchJson(APIEndpoints.catalogs(catalogId).catalog, catalogData ? catalogData : catalog)
      await getCatalog(catalogId)
      setDeleteParameterRow('')
      setStateByKey(setSuccessStates, 'saveCatalog', true)
    }, setErrorStates, setLoadingStates)
  }

  async function deleteCatalog(catalogId) {
    await makeCall('deleteCatalog', async () => {
      await apiClient.deleteJson(APIEndpoints.catalogs(catalogId).catalog)
      await getCatalogs(true)
    }, setErrorStates, setLoadingStates)
  }

  function postCatalogTemplate() {
    //redirect will be after the call is successful
    let catalogCopy = catalogTemplateObj
    catalogCopy.catalog = catalogBaseObj
    makeCall('saveCatalogTemplate', async () => {
      let newCatalogTemplate = await apiClient.postJson(APIEndpoints.administration().catalogs, catalogCopy)
      await getCatalogs(true)
      setRedirectId(newCatalogTemplate.catalog.id)
      setStateByKey(setSuccessStates, 'saveCatalogTemplate', true)
    }, setErrorStates, setLoadingStates)
  }

  function patchCatalogTemplate(catalogId) {
    let catalogCopy = cloneDeep(catalog)
    catalogCopy.catalog = cloneDeep(catalogBaseObj)
    makeCall('saveCatalogTemplate', async () => {
      await apiClient.patchJson(APIEndpoints.catalogs(catalogId).catalog, catalogCopy)
      await getCatalog(catalogId)
      getCatalogs(true)
      setStateByKey(setSuccessStates, 'saveCatalogTemplate', true)
    }, setErrorStates, setLoadingStates)
  }
  const resetStates = () => {
    setErrorStates({})
    setLoadingStates({})
    setSuccessStates({})
  }

  const initAssignedOrderProcessTypes = useCallback(() => {
    setAssignedOrderProcessTypes(JSON.parse(initialAssignedOrderProcessTypes))
    resetStates()
  }, [initialAssignedOrderProcessTypes])

  const initCatalogBaseObj = useCallback(() => {
    setCatalogBaseObj(JSON.parse(initialCatalogBaseObj))
    resetStates()
  }, [initialCatalogBaseObj])

  const initCatalogActivateArticle = useCallback(() => {
    resetStates()
  }, [])

  async function saveCatalogOfficeAvailabilities(catalogId, officeIds) {
    await makeCall('saveCatalogOfficeAvailabilities', async () => {
      await apiClient.postJson(APIEndpoints.catalogs(catalogId).officeAvailabilities, officeIds)
      setIsLoading(false)
      setStateByKey(setSuccessStates, 'saveCatalogOfficeAvailabilities', true)
    }, setErrorStates, setLoadingStates)
  }

  function deleteCatalogOfficeAvailabilities(catalogId) {
    makeCall('deleteCatalogOfficeAvailabilities', async () => {
      await apiClient.deleteJson(APIEndpoints.catalogs(catalogId).officeAvailabilities)
      setIsLoading(false)
      setStateByKey(setSuccessStates, 'deleteCatalogOfficeAvailabilities', true)
    }, setErrorStates, setLoadingStates)
  }

  async function getArticleAssignments(articleId) {
    makeCall('articleAssignments', async () => {
      let assignments = await apiClient.getJson(APIEndpoints.articleCatalogAssingments(articleId))
      //filter assingments with catalogId's which are not present in the bigData state due to missing access rights of the user
      assignments = assignments.filter(a => !!bigData[a.catalogId])
      //gets a set of unique catalogId's from the assignments
      let catalogIds = [...new Set(assignments.map(a => a.catalogId))]
      let newBigData = cloneDeep(bigData)
      //loads all dimensions/structures/folders of all catalogs, which are relevant for the assignments
      await addMissingStructures(catalogIds, newBigData)
      //creates a tree structure from the assignments 
      let assignmentTree = getassignedTree(catalogIds, newBigData, assignments, articleId)
      //loads all Articles for every dimension/structure/folder which will be displayed open
      await addCatalogArticles(catalogIds, newBigData)
      await addAssignedTreeDimensionArticles(assignmentTree, newBigData)
      //set state bigData with all currently loaded information 
      setBigData(newBigData)
      //set state assignmentTree so that every catalogItem knows if it should apply the styles for an assigned item. 
      setAssignmentTree(assignmentTree)
    }, setErrorStates, setLoadingStates)
  }

  const getSupplierMandators = useCallback(() => {
    makeCall('supplierMandators', async () => {
      let loadSupplierMandators = await apiClient.getJson(APIEndpoints.supplierMandators)
      setSupplierMandators(loadSupplierMandators)
    }, setErrorStates, setLoadingStates)
  }, [])

  const getSuppliers = useCallback(() => {
    makeCall('supplierMandators', async () => {
      let loadSuppliers = await apiClient.getJson(APIEndpoints.suppliers)
      setSuppliers(loadSuppliers)
    }, setErrorStates, setLoadingStates)
  }, [])



  const resetCatalogsPage = useCallback(() => {
    resetStates()
    setCatalog(cloneDeep(catalogTemplateObj))
    setCatalogBaseObj(cloneDeep(catalogBaseObjTemplate))
    setInitialCatalogBaseObj(JSON.stringify(catalogBaseObjTemplate))
    setRedirectId('')
    setAssignedOrderProcessTypes([])
  }, [])

  const contextValues = {
    errorStates,
    loadingStates,
    successStates, setSuccessStates,
    getCatalogs,
    redirectId,
    resetCatalogsPage,
    putCatalogsOrderSequence,
    putCatalogsOrderSequenceBackground,
    deleteCatalog,
    deleteCatalogId, setDeleteCatalogId,
    catalog, setCatalog,
    getCatalog,
    orderProcessTypes,
    getOrderProcessTypes,
    assignedOrderProcessTypes, setAssignedOrderProcessTypes,
    initAssignedOrderProcessTypes,
    assignedOrderProcessTypesHasChanged,
    patchCatalog,
    resetStates,
    postCatalogTemplate,
    getSupplierMandators, supplierMandators,
    getSuppliers, suppliers,
    patchCatalogTemplate,
    getAliasTypes,
    aliasTypes,
    ociTextTypes, setOciTextTypes,
    ociAliasTypes, setOciAliasTypes,
    allOciParameters,
    deleteParameterRow, setDeleteParameterRow,
    bigData, setBigData,
    getCatalogStructure,
    patchStructureSequence,
    getStructureArticles,
    getCatalogArticles,
    deleteDimension,
    patchDimension, postDimension,
    deleteCatalogArticle,
    deleteStructureArticle,
    getArticleAssignments,
    assignmentTree, setAssignmentTree,
    initCatalogBaseObj,
    catalogBaseObj, setCatalogBaseObj,
    saveCatalogOfficeAvailabilities,
    deleteCatalogOfficeAvailabilities,
    isLoading, setIsLoading,
    initCatalogActivateArticle
  }

  return (
    <CatalogsContext.Provider value={contextValues}>
      {children}
    </CatalogsContext.Provider>
  )
}

export default CatalogsProvider