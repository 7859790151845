import React, { useState } from 'react'
import styled from 'styled-components'
import { formatedPoints, formatedPriceObject } from '../../utils/formatedPrice'
import { formatedDate } from '../../utils/formatedDate'
import { colors } from '../../styles/colors'
import MediaQuery from 'react-responsive'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import { Navigate } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { getErrorMessageFromApiResponse } from "../../utils/api/apiUtils"
import { RetourIcon } from '../Icons/RetourIcon'
import { ClaimIcon } from '../Icons/ClaimIcon'


const StyledTr = styled.tr`
  td{
    padding: 10px;
    vertical-align: center;
  }
  td:first-child{
    color: ${colors.gray4};

    small{
      color: ${colors.gray3};
    }
  }
  td:last-child{
    padding-right: 0;
    text-align: right;
  }
`

const StyledPriceTd = styled.td`
  text-align: right;
  white-space: nowrap;
`

const StyledLi = styled.li`
  display:flex;
  padding: 10px;
  @media screen and (max-width: 575px) {
    border-bottom: 1px solid ${colors.gray3};
    flex-direction:column;
    >div:first-child{
      margin:0 0 1rem 0;
    }
  }
  @media screen and (min-width: 576px) {
    justify-content:space-between;
    >div:first-child{
      flex-grow: 1;
      margin-right: 10px;
      border-bottom: 1px solid ${colors.gray3}; //only for tablet and above
    }
  }
`
const ButtonWrapper = styled.div`
  display:flex;
  align-items:flex-end;
  justify-content: flex-end;
  flex-direction:column;
  white-space:nowrap;
  min-width:200px;
`
const StyledRetourIcon = styled(RetourIcon)`
  cursor:help;
`
const StyledClaimIcon = styled(ClaimIcon)`
  cursor:help;
`

const OrdersListItem = inject('ordersStore', 'uiStore')(observer(props => {
  const { t, item, setOpenDeleteModal, ordersStore, uiStore } = props
  const isReorder = item.metaProperties && item.metaProperties.find(obj => obj.attributes === 'isReorderable')
  const isTemplate = item.status === 'template'
  const [redirectId, setRedirectId] = useState('')

  if (redirectId) {
    return <Navigate to={`/costcenter/${item.targetCostCenter.id}/shoppingcart/${redirectId}/confirmaddress/confirmorder`} />
  }
  const targetDisplayName = (item.targetBusinessPartner && item.targetBusinessPartner.displayName) ? item.targetBusinessPartner.displayName :
                            (item.targetStorehouse && item.targetStorehouse.displayName) ? item.targetStorehouse.displayName :
                            (item.targetCostCenter && item.targetCostCenter.displayName) ? item.targetCostCenter.displayName : ''
  const handleReorder = async () => {
    try {
      ordersStore.incomingOrdersError = null
      const shoppingCart = await ordersStore.reorder(item.id)
      shoppingCart && setRedirectId(shoppingCart.id)
    }
    catch (e) {
      ordersStore.incomingOrdersError = getErrorMessageFromApiResponse(e)
    }
  }
  const handlePrint = (id) => {
    const urlPrefix = `${'/' + uiStore.theme + '/' + uiStore.language}`
    const basename = process.env.PUBLIC_URL + urlPrefix
    window.open(`${basename}/orders/${id}/print`, "_blank", " top=400,left=400,width = 1000, height = 600")
  }

  return (
    <React.Fragment>
      <MediaQuery maxWidth={1299}>
        <StyledLi>
          <div>
            <div className='fw-bold'>{isTemplate ? item.templateName : formatedDate(item.createdAt)}</div>
            <div>
              <small className='text-uppercase'>{t('orders:orderNumber')} {item.orderNr}</small>
            </div>
            <div>
              {t('order:targetBusinessPartnerTitle')}: {targetDisplayName}
            </div>
            <div>
              {t('amount')}: {item.totalQuantityOrdered}
              {item.returnedPositionsExist ? <StyledRetourIcon title={t('orders:returnsExist')} color={colors.gray3} /> : null}
              {item.complaintsExist ? <StyledClaimIcon title="Reklamationen existieren" color={colors.gray3} /> : null}
            </div>
            <div>
              {t('order:createdByTitle')}: {item.createdByDisplayName}
            </div>
            {item.totalValue && <div>
              {formatedPriceObject(item.totalValue)}&nbsp;{formatedPoints(item.totalValue) ? `(${formatedPoints(item.totalValue)})` : ''}
            </div>}
          </div>
          <ButtonWrapper>
            {isReorder && <Button onClick={handleReorder} className="w-100">{t('orders:reorder')}</Button>}
            <Link to={`/orders/${item.id}`} className='btn btn-primary mt-2 w-100' >{t('orders:toOrderButton')}</Link>
            {isTemplate && <Button onClick={() => setOpenDeleteModal(item.id)} variant="danger" className="w-100 mt-2">{t('delete')}</Button>}
          </ButtonWrapper>
        </StyledLi>
      </MediaQuery>
      <MediaQuery minWidth={1300}>
        <StyledTr>
          <td>
            <div className='fw-bold'>{isTemplate ? item.templateName : formatedDate(item.createdAt)}</div>
            <div>
              <small className='text-uppercase'>{t('orders:orderNumber')} {item.orderNr}</small>
            </div>
          </td>
          <td>
            <div className='fw-bold'>{t('order:targetBusinessPartnerTitle')}:</div>
            {targetDisplayName}
          </td>
          <td>
            <div>{t('amount')}: {item.totalQuantityOrdered}</div>
          </td>
          <td>
            {item.returnedPositionsExist ? <StyledRetourIcon title={t('orders:returnsExist')} color={colors.gray3} /> : null}
          </td>
          <td>
            {item.complaintsExist ? <StyledClaimIcon title={t('orders:claimsExist')} color={colors.gray3} /> : null}
          </td>
          <td>
            <div className='fw-bold'>{t('order:createdByTitle')}:</div>
            <div>{item.createdByDisplayName}</div>
          </td>
          <StyledPriceTd>
            {item.totalValue && formatedPriceObject(item.totalValue)}
          </StyledPriceTd>
          {uiStore.showPoints ? <StyledPriceTd>
            {formatedPoints(item.totalValue) ? formatedPoints(item.totalValue) : ''}
          </StyledPriceTd> : null}
          <td>
            <div className="d-flex ">
              <Link to={`/orders/${item.id}`} className='btn btn-primary' >{t('orders:toOrderButton')}</Link>
              {/*  {isReorder && <StyledButton className="ms-2" variant="primary" title="nochmal bestellen"><ShoppingCartReOrderIcon /><span></span></StyledButton>} */}
              {isReorder && <Button onClick={handleReorder} className="ms-2">{t('orders:reorder')}</Button>}
              {isTemplate && <Button onClick={() => handlePrint(item.id)} className="ms-2">{t('orders:printQrCode')}</Button>}
              {isTemplate && <Button onClick={() => setOpenDeleteModal(item.id)} variant="danger" className="ms-2">{t('delete')}</Button>}
            </div>
          </td>
        </StyledTr>
      </MediaQuery>

    </React.Fragment>
  )
}))

export default withTranslation()(OrdersListItem)
