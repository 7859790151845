import React, { useCallback, useState } from 'react'
import { Alert, Col, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { debounce } from 'throttle-debounce'
import { setValueByKey } from '../../../utils/utils'
import { t } from 'i18next'
import { useCancelToken } from '../../../utils/hooks/useCancelToken'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { cloneDeep } from 'lodash'
import { useMemo } from 'react'
import { OverlaySpinner, OverlaySpinnerWrapper } from '../../Forms/StyledInputLoadingOverlay'
import { useContingentTemplatesState } from '../../../stores/Configuration/ContingentTemplatesProvider'
import { makeCall } from '../../../utils/api/makeCall'

const StyledAmounts = styled.div`
 max-width:800px;
  `
const GroupAmounts = ({ entitlementId, group }) => {
  //edit bigData here
  const [groupValues, setGroupValues] = useState(group)
  const groupId = group.id
  const [loadingValues, setLoadingValues] = useState({})
  const { createOrCancelToken, sourceRef, isCancel } = useCancelToken();
  const [error, setError] = useState({})
  const { setBigData } = useContingentTemplatesState()

  const patchGroupBackground = useCallback(async (groupData, key) => {
    createOrCancelToken()
    makeCall('patchBackground', async () => {
      setLoadingValues(prev => {
        prev[key] = true
        return cloneDeep(prev)
      })
      await apiClient.patchJson(APIEndpoints.contingentTemplate(entitlementId, groupId).group, groupData, true, false, { cancelToken: sourceRef.current.token })
      //change bigData in background
      setBigData(prev => {
        prev[entitlementId].groups[groupId].value = groupData.value
        return prev
      })
      setLoadingValues({})
    }, setError, setLoadingValues, isCancel)
  }, [createOrCancelToken, entitlementId, groupId, isCancel, setBigData, sourceRef])

  const updateItem = e => {
    let key = e.target.name
    let value = parseInt(e.target.value)
    setGroupValues(prev => {
      return setValueByKey(key, value, prev)
    })
    trigerLazyLoadDebounced(groupValues, key)
  }

  const trigerLazyLoadDebounced = useMemo(
    () => debounce(200, async (newGroup, key) => {
      let params = {
        value: {
          quantity: newGroup.value.quantity,
          freeQuantity: newGroup.value.freeQuantity,
          quantity2: newGroup.value.quantity2,
          usageDuration: newGroup.value.usageDuration,
        }
      }
      patchGroupBackground(params, key)
    }), [patchGroupBackground])
  return (
    <StyledAmounts>
      <Row>
        <Col sm="12" md={{ span: 5, offset: 2 }}><Form.Group as={Row} >
          <Form.Label column >
            {t('productDetail:referenceAmount')}
          </Form.Label>
          <Col>
            <OverlaySpinnerWrapper>
              <Form.Control
                onChange={updateItem}
                value={groupValues.value.quantity || ""}
                type="number"
                min="0"
                onWheel={(e) => e.target.blur()}
                name="value.quantity"
              />
              {loadingValues["value.quantity"] && <OverlaySpinner />}
            </OverlaySpinnerWrapper>
          </Col>
        </Form.Group>
          <Form.Group as={Row} >
            <Form.Label column >
              {t('contingentTemplates:freeQuantity')}
            </Form.Label>
            <Col>
              <OverlaySpinnerWrapper>
                <Form.Control
                  onChange={updateItem}
                  value={groupValues.value.freeQuantity || ""}
                  type="number"
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  name="value.freeQuantity"
                />
                {loadingValues["value.freeQuantity"] && <OverlaySpinner />}
              </OverlaySpinnerWrapper>
            </Col>
          </Form.Group>
        </Col>
        <Col sm="12" md="5" >
          <Form.Group as={Row} >
            <Form.Label column style={{ whiteSpace: 'nowrap' }}>
              {t('productDetail:referenceAmount')} 2
            </Form.Label>
            <Col>
              <OverlaySpinnerWrapper>
                <Form.Control
                  onChange={updateItem}
                  value={groupValues.value.quantity2 || ""}
                  type="number"
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  name="value.quantity2"
                />
                {loadingValues["value.quantity2"] && <OverlaySpinner />}
              </OverlaySpinnerWrapper>
            </Col>
          </Form.Group>
          <Form.Group as={Row} >
            <Form.Label column style={{ whiteSpace: 'nowrap' }} >
              {t('contingentTemplates:wearingTime')} ({t('month_other')})
            </Form.Label>
            <Col>
              <OverlaySpinnerWrapper>
                <Form.Control
                  onChange={updateItem}
                  value={groupValues.value.usageDuration || ""}
                  type="number"
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  name="value.usageDuration"
                />
                {loadingValues["value.usageDuration"] && <OverlaySpinner />}
              </OverlaySpinnerWrapper>
            </Col>
          </Form.Group>
        </Col>
      </Row>
      {error.patchBackground ? <Alert variant="danger">{error.patchBackground.value}</Alert> : null}
    </StyledAmounts>
  )
}

export default GroupAmounts