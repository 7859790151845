import { action, observable, reaction, makeObservable } from 'mobx';
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { getErrorMessageFromApiResponse } from '../utils/api/apiUtils'
import { debounce } from 'throttle-debounce'
import axios from 'axios'
import { emptyGuid } from '../utils/emptyGuid'
import { sumPricePerItemArray, sumVariantPrices } from '../utils/formatedPrice'

const CancelToken = axios.CancelToken;
var source;
class TeamOrderStore {
  constructor(appStore) {
    makeObservable(this, {
      costcenterActive: observable,
      selectedCostcenterId: observable,
      selectedStorehouseId: observable,
      selectedCatalog: observable,
      articleCatalogs: observable,
      articleCatalogCategories: observable,
      searchCriteriaCatalogCategories: observable,
      searchCriteriaCatalogCategoriesOpened: observable,
      selectedSearchCriteriaCatalogCategories: observable,
      orderableArticles: observable,
      categoriesOpened: observable,
      selectedCategories: observable,
      orderableArticlesError: observable,
      noArticlesAvailable: observable,
      selectedVariants: observable,
      selectedVariantQuantitiesErros: observable,
      overlayMaxAmount: observable,
      overlayInputAmount: observable,
      showLoadNextOrderableArticlesSpinner: observable,
      orderableArticlesIsLoading: observable,
      orderableArticlesLimit: observable,
      orderableArticlesOffset: observable,
      doResetTeamOrderProducts: observable,
      privateOrder: observable,
      catalogOnly: observable,
      productsSearchText: observable,
      productSearchIncludeSelected: observable,
      selectedVariantsSum: observable,
      priceSum: observable,
      loadArticleCatalogs: action,
      setCatalog: action,
      loadArticleCatalogCategories: action,
      loadSearchCriteriaCatalogCategories: action,
      orderableArticlesAmount: observable,
      fetchOrderableArticles: action,
      loadOrderableArticles: action,
      setSelectedCostcenterId: action,
      setSelectedStorehouseId: action,
      setPrivateOrder: action,
      setPersonalOrder: action,
      setCatalogOnly: action,
      resetOrderType: action,
      resetProductOverview: action,
      setOverlayInputAmount: action
    });
    reaction(
      () => this.productsSearchText, productsSearchText => {
        this.loadOrderableArticlesDebounced()
        //this.loadOrderableArticles()
      }
    )

    this.app = appStore
  }

  costcenterActive = {};
  selectedCostcenterId = null;
  selectedStorehouseId = null;
  selectedCatalog = null;
  articleCatalogs = [];
  articleCatalogCategories = [];
  searchCriteriaCatalogCategories = [];
  searchCriteriaCatalogCategoriesOpened = {};
  selectedSearchCriteriaCatalogCategories = {};

  orderableArticles = [];

  categoriesOpened = {};
  selectedCategories = {};

  orderableArticlesError = null;
  noArticlesAvailable = null;
  selectedVariants = [];
  selectedVariantQuantitiesErros = {};
  overlayMaxAmount = {};
  overlayInputAmount = {};
  showLoadNextOrderableArticlesSpinner = false;
  orderableArticlesIsLoading = false;
  orderableArticlesLimit = 20;
  orderableArticlesOffset = 0;
  doResetTeamOrderProducts = false;
  privateOrder = false;
  catalogOnly = false;
  productsSearchText = '';
  productSearchIncludeSelected = false;
  selectedVariantsSum = 0;
  priceSum = 0;



  async loadArticleCatalogs() {

    if (this.catalogOnly) {
      this.articleCatalogs = await apiClient.getJson(APIEndpoints.defaultArticleCatalog)
    }
    else {
      this.articleCatalogs = await apiClient.getJson(APIEndpoints.articleCatalogs(this.app.productsStore.basket.id))
    }
    const defaultCatalog = this.articleCatalogs.find(catalog => catalog.isDefault) || this.articleCatalogs[0] || {}
    if (defaultCatalog.id) {
      this.setCatalog(defaultCatalog.id)
    }
    return null
  }

  async setCatalog(catalogId) {
    if (this.selectedCatalog !== catalogId) {
      this.resetProductOverview()
    }
    if (catalogId) {
      this.selectedCatalog = catalogId

      await this.loadArticleCatalogCategories()
      await this.loadSearchCriteriaCatalogCategories()
      await this.loadOrderableArticles()
    }
    return null
  }

  async loadArticleCatalogCategories() {
    if (this.selectedCatalog) {
      this.articleCatalogCategories = []
      this.categoriesOpened = {}
      this.selectedCategories = {}
      this.articleCatalogCategories = await apiClient.getJson(APIEndpoints.articleCatalogCategories(this.selectedCatalog))

      //initialy open all categories
      if (this.articleCatalogCategories && this.articleCatalogCategories.length < 100) {
        this.articleCatalogCategories.forEach(category => {
          this.categoriesOpened[category.id] = true
        })
      }
    }
    return null
  }

  async loadSearchCriteriaCatalogCategories() {
    if (this.selectedCatalog) {
      this.searchCriteriaCatalogCategories = []
      this.searchCriteriaCatalogCategoriesOpened = {}
      this.selectedSearchCriteriaCatalogCategories = {}
      this.searchCriteriaCatalogCategories = await apiClient.getJson(APIEndpoints.searchCriteriaCatalogCategories(this.selectedCatalog))
    }
  }

  buildParamsForOrderableArticles() {
    let params = {}
    if (!this.catalogOnly) {
      params['articleCatalogIds'] = [this.selectedCatalog]
    }
    //-------articleCatalogCategoryIds---------
    if (Object.keys(this.selectedCategories).length) {
      //params['articleCatalogCategoryIds'] = Object.keys(this.selectedCategories)
      let filteredSelectedCategories = [];
      Object.keys(this.selectedCategories).forEach(selectedCategoryID => {
        let selectedCategory = this.articleCatalogCategories.filter(category => category.id === selectedCategoryID);
        if (selectedCategory[0].hasChildren && !selectedCategory[0].parentCategory) {  // if we have a parent category at top level (that doesn't have a parent it self), push it to the array
          filteredSelectedCategories.push(selectedCategoryID);
        } // else if we have a child category (can also be a parent category to other children), but it's parent category is not selected, push it to the array  
        else if (selectedCategory[0].parentCategory && !Object.keys(this.selectedCategories).some(i => i === selectedCategory[0].parentCategory.id)) {
          filteredSelectedCategories.push(selectedCategoryID);
        }
      });
      params['articleCatalogCategoryIds'] = filteredSelectedCategories
    }
    //-------categoryRestrictions---------
    if (Object.keys(this.selectedSearchCriteriaCatalogCategories).length) {
      params['categoryRestrictions'] = []
      //prepare 
      for (const index of this.searchCriteriaCatalogCategories.keys()) {
        params['categoryRestrictions'][index] = { categoryIds: [] }
      }
      //fill 
      for (const [id, obj] of Object.entries(this.selectedSearchCriteriaCatalogCategories)) {
        params['categoryRestrictions'][obj.index].categoryIds.push(id)
      }
    }
    //-------searchText---------
    if (this.productsSearchText !== '') {
      if (this.productSearchIncludeSelected) {
        //params['filter.searchText'] = this.productsSearchText
        params['searchText'] = this.productsSearchText
      }
      else {
        //params = {'filter.searchText': this.productsSearchText }
        params = { 'searchText': this.productsSearchText }
      }
    }
    return params
  }
  orderableArticlesAmount = 0;
  async fetchOrderableArticles() {
    if (source) {
      //cancel previous calls that are still pending
      source.cancel("canceled")
    }
    //create new token source
    source = CancelToken.source();

    const params = this.buildParamsForOrderableArticles()
    //const params = { articleCatalogIds: ["79dadb76-3c16-4dd4-be1c-96e1c48a4c68"], searchText: "bluse" }

    this.orderableArticlesIsLoading = true
    this.noArticlesAvailable = false
    try {
      var response = null
      if (this.catalogOnly) {
        response = await apiClient.postJson(APIEndpoints.defaultArticleCatalogArticles(this.selectedCatalog, this.orderableArticlesLimit, this.orderableArticlesOffset), params, true, true, { cancelToken: source.token })
      }
      else {
        response = await apiClient.postJson(APIEndpoints.orderableArticles(this.app.productsStore.basket.id, this.orderableArticlesLimit, this.orderableArticlesOffset), params, true, true, { cancelToken: source.token })

      }
      const articlesChunk = response.data
      this.orderableArticlesAmount = response.headers['x-total-count'] ? parseInt(response.headers['x-total-count']) : 0
      if (articlesChunk.length === 0) {
        this.noArticlesAvailable = true
      } else {
        this.noArticlesAvailable = false
      }

      //lazyload
      if (this.orderableArticlesOffset === 0) {
        this.orderableArticles = articlesChunk
      }
      else {
        this.orderableArticles = this.orderableArticles.concat(articlesChunk)
      }
      this.orderableArticlesOffset = this.orderableArticlesOffset + this.orderableArticlesLimit

      this.orderableArticlesError = null
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.orderableArticlesError = getErrorMessageFromApiResponse(e)
      }
    }
    this.orderableArticlesIsLoading = false
  }

  async loadOrderableArticles() {
    this.orderableArticles = []
    this.orderableArticlesOffset = 0
    await this.fetchOrderableArticles()
  }
  initializeCurrentProcessType(storeHouseId, costCenterId, orderType)
  {
    if (storeHouseId) {
      this.setSelectedStorehouseId(storeHouseId, costCenterId)
    }
    else if (costCenterId) {
      this.setSelectedCostcenterId(costCenterId)
    }
    else if (orderType === "private") {
      this.setPrivateOrder();
    }
    else {
      this.setPersonalOrder();
    }
  }
  getCurrentProcessType()
  {
    if(this.selectedStorehouseId) return 'storeHouse'
    if(this.selectedCostcenterId) return 'costCenter'
    return this.privateOrder ? 'private' : 'personal'
  }
  setSelectedStorehouseId(selectedStorehouseId, selectedCostcenterId) {
    if (selectedStorehouseId !== this.selectedStorehouseId) {
      this.resetOrderType()
      this.doResetTeamOrderProducts = true
      this.app.productsStore.showCatalogArticles = true
    }
    if (selectedStorehouseId) {
      this.selectedStorehouseId = selectedStorehouseId
    }
    if (selectedCostcenterId) {
      this.selectedCostcenterId = selectedCostcenterId
    }
    console.log('TeamOrderStore setSelectedStorehouseId:', selectedStorehouseId, selectedCostcenterId)
  }
  setSelectedCostcenterId(selectedCostcenterId) {
    if (selectedCostcenterId !== this.selectedCostcenterId) {
      this.resetOrderType()
      this.doResetTeamOrderProducts = true
      this.app.productsStore.showCatalogArticles = true
    }
    if (selectedCostcenterId) {
      this.selectedCostcenterId = selectedCostcenterId
    }
    console.log('TeamOrderStore setSelectedCostcenterId:', selectedCostcenterId)
  }

  setPrivateOrder() {
    if (!this.privateOrder) {
      this.resetOrderType("privateOrder")
      this.doResetTeamOrderProducts = true
      this.app.productsStore.showCatalogArticles = true
    }
  }

  setPersonalOrder() {
    if (!this.personalOrder) {
      this.resetOrderType("personalOrder")
      this.app.productsStore.showCatalogArticles = false
    }
  }
  setCatalogOnly() {
    if (!this.catalogOnly) {
      this.resetOrderType("catalogOnly")
    }
  }

  resetOrderType(orderType) {
    this.selectedCostcenterId = null
    this.selectedStorehouseId = null
    this.privateOrder = orderType === "privateOrder" ? true : false
    this.personalOrder = orderType === "personalOrder" ? true : false
    this.catalogOnly = orderType === "catalogOnly" ? true : false
  }

  resetProductOverview() {
    this.orderableArticles = []
    this.orderableArticlesOffset = 0
    this.orderableArticlesError = null
    this.searchCriteriaCatalogCategories = []
    this.searchCriteriaCatalogCategoriesOpened = {}
    this.selectedSearchCriteriaCatalogCategories = {}
    this.articleCatalogCategories = []
    this.categoriesOpened = {}
    this.selectedCategories = {}
    this.priceSum = 0
    this.selectedVariantQuantitiesErros = {}
  }

  setOverlayInputAmount = e => {
    let articleId = this.app.productDetailsStore.article.id
    this.overlayInputAmount[articleId] = e.target.value
  };
  refreshOverlayMaxAmount() {
    let articleId = this.app.productDetailsStore.article.id
    this.overlayMaxAmount[articleId] = this.selectedVariantsSum
  }

  calculateQuantitySum = () => {
    this.selectedVariantsSum = this.selectedVariants.map(item => parseInt(item.quantity)).reduce((prev, curr) => prev + curr, 0);
  }
  refreshPrices = async () => {
    await this.app.productDetailsStore.loadArticlePrices()
    const articlePrices = this.app.productDetailsStore.articlePrices
    const allVariantPricesSum = sumVariantPrices(this.selectedVariants, articlePrices)
    const allConfigArticleIds = []
    for (const variant of this.selectedVariants) {
      for (const config of Object.values(variant.customConfigurations)) {
        //configs times quantaties
        for (let index = 0; index < variant.quantity; index++) {
          allConfigArticleIds.push(config.article.id)
        }
      }
    }
    const pricePerItemArray = await this.app.productDetailsStore.getConfigPrices(allConfigArticleIds)
    const allConfigPricesSum = sumPricePerItemArray(pricePerItemArray)
    this.priceSum = allVariantPricesSum + allConfigPricesSum
  }

  overlayMaxAmountReached = (index, value) => {
    let articleId = this.app.productDetailsStore.article.id
    let quantityRecommendedPerBPExists = this.app.productDetailsStore.quantityRecommendedPerBPExists
    if (quantityRecommendedPerBPExists) {
      let newTotalSum = parseInt(this.selectedVariants.map(item => parseInt(item.quantity)).reduce((prev, curr) => prev + curr, 0) - this.selectedVariants[index].quantity) + parseInt(value);
      let maxAmount = this.overlayMaxAmount[articleId] || 0
      if (newTotalSum > maxAmount) {
        return true
      }
    }
    return false
  }

  changeVariantQuantity = (index, value) => {
    if (value == null || value < 0) return
    if (value === 0) { this.removeVariant(index); return }
    if (this.overlayMaxAmountReached(index, value)) {
      this.selectedVariantQuantitiesErros[index] = true
      setTimeout(() => {
        this.selectedVariantQuantitiesErros[index] = false
      }, 1000)
    }
    else {
      this.selectedVariantQuantitiesErros[index] = false
      this.selectedVariants[index].quantity = value
      this.refreshPrices()
      this.calculateQuantitySum()
    }
  }

  changeVariantSize = async (index, newVariantId) => {
    this.selectedVariants[index].variantId = newVariantId
    this.selectedVariants[index].variantQuantity = await this.getVariantQuantities(newVariantId)
    this.refreshPrices()
  }

  removeVariant = index => {
    this.selectedVariants.splice(index, 1);
    this.refreshPrices()
    this.calculateQuantitySum()
  }

  addVariant = async (variantId, quantity) => {
    const defaultConfig = await this.initCustomConfigurations()
    let minimumQuantity = this.app.productDetailsStore.article.minimumQuantity

    this.selectedVariants.push({
      variantId,
      quantity: quantity ? quantity : minimumQuantity ? minimumQuantity : 1,
      customConfigurations: defaultConfig,
      variantQuantity: await this.getVariantQuantities(variantId)
    })
    this.refreshPrices()
    this.calculateQuantitySum()
  }

  resetVariants = async (newSelectedVariants = []) => {
    this.selectedVariants = newSelectedVariants
    await this.initPrefillVariants()
    this.refreshPrices()
    this.calculateQuantitySum()
  }
  handleRemoveConfiguration = (configId, index) => {
    const config = this.app.productDetailsStore.articleConfiguration.find(config => config.id === configId)
    const emptyGuidArticle = config.selectableArticles.find(art => art.id === emptyGuid)
    if (emptyGuidArticle) {
      this.selectedVariants[index].customConfigurations[configId].article = emptyGuidArticle
    }
    this.refreshPrices()
  }
  handleChangeConfig = (configId, article, index) => {
    this.selectedVariants[index].customConfigurations[configId].article = article
    this.refreshPrices()
  }
  handleChangeConfigComment = (configId, comment, index) => {
    this.selectedVariants[index].customConfigurations[configId].comment = comment
  }

  initPrefillVariants = async () => {
    for (const variant of this.selectedVariants) {
      let customConfigurations = await this.initCustomConfigurations(variant)
      variant.customConfigurations = customConfigurations
      let variantQuantity = await this.getVariantQuantities(variant.variantId)
      variant.variantQuantity = variantQuantity
    }
  }

  initCustomConfigurations = async (selectedVariantQuantity = {}) => {
    await this.app.productDetailsStore.loadArticleConfiguration()

    var customConfigurations = {}
    this.app.productDetailsStore.articleConfiguration.forEach(config => {
      //shoppingcart position configs
      const prefilledConfig = selectedVariantQuantity.configurations && selectedVariantQuantity.configurations.find(configg => configg.articleConfigurationId === config.id)
      if (prefilledConfig) {
        customConfigurations[config.id] = {
          comment: prefilledConfig.comment,
          article: prefilledConfig.article
        }
      }
      else {
        const article = config.selectableArticles.find(article => article.id === config.lastSelectedArticleID)
        if (article) {
          customConfigurations[config.id] = {
            comment: config.lastComment,
            article
          }
        }
      }

    })
    return customConfigurations
  }



  loadOrderableArticlesDebounced = debounce(350, () => {
    this.loadOrderableArticles()
  })

  getVariantQuantities = async (variantId) => {
    const shoppingBasketId = this.app.productsStore.basket.id;
    const variantQuantity = await apiClient.getJson(APIEndpoints.variantQuantities(shoppingBasketId, this.app.productDetailsStore.article.id), { variantId })
    return variantQuantity
  }
}

export default TeamOrderStore
