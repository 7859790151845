import styled from 'styled-components'

export const FlexWrapper = styled.div`
min-height: calc(100vh - 140px);//header+navbar=140px
    display:flex; 
    @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px)  {
        flex-direction:column;
        .right-icon, .left-icon {
            display:none;
        }
    }
`