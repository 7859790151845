import React from 'react'

export const Weaponry = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="weaponry" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <path className="st0" d="M99.2,25.8c0-0.3-0.2-0.7-0.5-1c-0.3-0.3-0.6-0.4-1-0.4H18.4c-0.7,0-1.3,0.5-1.4,1.2l-2.8,17.1
        c-0.1,1.3,0.2,2.5,1,3.5c0.9,0.9,2,1.4,3.2,1.4H21v1c0,0.6-0.2,1.7-0.5,3.3l-5.9,30.4c-0.3,1.7,0.1,3.3,1.2,4.7c1,1.3,2.6,2,4.3,2
        h14.6c2.6,0,5-1.9,5.5-4.5l3.7-18.7h11.2c6.1,0,11.3-4.4,12.4-10.5l0.9-5.5h22.8c4.5,0,8.1-3.6,8.1-8.1V25.8z M64.6,54.9
        C63.9,59.5,59.7,63,55,63H44.4l2.6-13.1H51l-0.1,1.1c-0.2,1.8,0,3.8,0.6,6.1c0.1,0.4,0.4,0.7,0.7,0.9c0.5,0.2,1,0.2,1.4-0.1
        c0.5-0.3,0.8-1,0.6-1.6c-0.2-0.4-0.9-2.3-0.4-5.6l0.1-0.9h11.5L64.6,54.9z M96.4,41.8c0,2.9-2.4,5.2-5.2,5.2H45.7
        c-0.7,0-1.3,0.5-1.4,1.2l-3.1,15.7c-0.1,0.3-0.1,0.5-0.1,0.6L37.4,84c-0.3,1.3-1.4,2.2-2.7,2.2H20.1c-0.9,0-1.7-0.4-2.1-1.1
        c-0.2-0.3-0.7-1-0.5-2.1l0,0l6-30.6c0.5-2.4,1-4.9-0.3-6.4c-0.6-0.9-1.7-1.3-3.2-1.3h-1.4c-0.6,0-0.9-0.3-1.1-0.5
        c-0.4-0.4-0.4-0.8-0.4-1.1l1.3-7.9h55.8c0.8,0,1.5-0.6,1.5-1.5s-0.6-1.5-1.5-1.5H18.9l0.9-5.2h76.7L96.4,41.8z"/>
    </svg>
  )
}
