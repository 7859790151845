import React, { useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import OrdersTable from '../../Orders/OrdersTable'
import { useOrdersTableState } from '../../../stores/OrdersTableProvider'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ArraySelect from "../../Select/ArraySelect"
import { emptyGuid } from '../../../utils/emptyGuid'
import { colors } from '../../../styles/colors'
import { getIsoDate } from '../../../utils/getIsoDate'
import { ExcelButton } from '../../Buttons/ExcelButton'
import { getErrorMessageFromApiResponse } from "../../../utils/api/apiUtils"
import useStores from '../../../stores/useStore'
import ModalComponent2 from '../../Modals/ModalComponent2'
import { ButtonWithLoadingState } from '../../Buttons'
import DownloadURLAlert from '../../Forms/DownloadURLAlert'
import useReport from '../../../utils/hooks/useReport'
import ReportSubscriptionModal from '../../Files/Form/ReportSubscriptionModal'
import useMountEffect from '../../../utils/hooks/useMountEffect'

const StyledHeaderButtons = styled.div`
@media screen and (min-width:768px) {
    display:flex;
    justify-content: space-between;
    align-items: flex-end;
}
@media only screen and (max-width: 767px), (min-device-width: 320px) and (max-device-width: 767px)  {
    button{
        width:100%;
    }
}
`
const StyledInfoBoxText = styled.div`
background: ${colors.gray8};
  color:white;
  padding: 2rem;
  border-radius: 5px;
  margin-bottom:1rem;
`

const OrderApprovalSRM = () => {
    const { selectedCheckBoxes, setSelectedCheckBoxes, fetchOrders, setStatus, setTargetCostcenterId, targetCostcenterId, setLastMonths } = useOrdersTableState()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [isLoading, setIsLoading] = useState('')
    const [openSubmitModal, setOpenSubmitModal] = useState(false)
    const { t } = useTranslation()
    const [error, setError] = useState('')
    const [srmCostCenters, setSrmCostCenters] = useState([])
    const mandate = process.env.REACT_APP_MANDATORSET
    const { uiStore, profileStore } = useStores()
    const { createReport, reportIsLoading, reportErrors, reportDownloadUrls, setReportDownloadUrls, reportSubscriptionId, setReportSubscriptionId } = useReport()


    useMountEffect(() => {
        setLastMonths(null)
        setStatus("waitingForApprovalSRM")
        loadSRMCostCenters()
    })

    const loadSRMCostCenters = () => {
        apiClient.getJson(APIEndpoints.incomingOrders().srmCostCenters).then(response => {
            setSrmCostCenters(response)
        }).catch(e => setError(getErrorMessageFromApiResponse(e)))
    }

    const submitGroup = async (number) => {
        setError('')
        setIsLoading(true)
        const isoDateNow = getIsoDate(new Date());
        const params = {
            number,
            createdAt: isoDateNow,
            orderIds: selectedCheckBoxes,
            id: emptyGuid
        }
        try {
            const response = await apiClient.postJson(APIEndpoints.incomingOrders().groups, params, true, true)
            if (response.data && response.data.checkoutUrl) {
                console.log(response.data.checkoutUrl)
                window.open(response.data.checkoutUrl, "_self")
            }
            setSelectedCheckBoxes([])
            fetchOrders();
            setOpenSubmitModal(false)
        } catch (e) {
            setError(getErrorMessageFromApiResponse(e))
        } finally {
            setIsLoading(false)
        }
    }

    const deleteGroup = async (message) => {
        setError('')
        setIsLoading(true)
        const params = selectedCheckBoxes.map(id => ({
            cancellationText: message,
            id: id
        }))
        try {
            await apiClient.postJson(APIEndpoints.incomingOrders().cancellations, params)
            setSelectedCheckBoxes([])
            fetchOrders();
            setOpenDeleteModal(false)
        } catch (e) {
            setError(getErrorMessageFromApiResponse(e))
        } finally {
            setIsLoading(false)
        }
    }

    const createExport = () => {
        const params = {
            emailAddress: "",
            orderIDs: selectedCheckBoxes
        }
        createReport("reportName", APIEndpoints.srmOrderExport, params)
    }

    const handleSubmit = () => {
        const { isPunchOutUser } = profileStore.profile
        if (isPunchOutUser) {
            submitGroup(null)
        }
        else {
            setOpenSubmitModal(true)
        }
    }

    const cancelActions = () => {
        setOpenSubmitModal(false);
        setError('')
        setOpenDeleteModal(false);
        setIsLoading(false)
    }

    return (
        <>
            {mandate === "cws" ? <StyledInfoBoxText>
                <ol type="1" className="mb-0">
                    <li><b>{t('srm:infoBoxText1')}</b></li>
                    <li><b>{t('srm:infoBoxText2')}</b></li>
                    <ol type="a">
                        <li>{t('srm:infoBoxText2A')}</li>
                        <li>{t('srm:infoBoxText2B')}</li>
                    </ol>
                </ol>
            </StyledInfoBoxText> : null}
            <StyledHeaderButtons>
                <Form.Group className="mb-3">
                    <Form.Label>{t('srm:selectCostCenter')}</Form.Label>
                    <ArraySelect
                        options={srmCostCenters}
                        value={targetCostcenterId}
                        onChange={obj => setTargetCostcenterId(obj.id)}
                        isInvalid={false}
                        defaultOption={{ value: emptyGuid, name: t('pleaseSelect') }}
                    />
                </Form.Group>
                <div>
                    <ExcelButton
                        className="mb-3 me-2 buttonSRMExport-v1"
                        disabled={selectedCheckBoxes.length === 0 || reportIsLoading.reportName}
                        isLoading={reportIsLoading.reportName}
                        buttonText={t('srm:exportButton')}
                        variant="secondary"
                        onClick={() => createExport()}
                    />
                    <ButtonWithLoadingState
                        className="mb-3 me-2"
                        disabled={selectedCheckBoxes.length === 0 || isLoading}
                        isLoading={isLoading}
                        buttonText={t('srm:transfer')}
                        variant="success"
                        onClick={handleSubmit}
                    />
                    {
                        uiStore.allowCancelPreOrders
                            ? <Button
                                className="mb-3 "
                                disabled={selectedCheckBoxes.length === 0}
                                variant="danger"
                                onClick={() => setOpenDeleteModal(true)}
                            >
                                {t('delete')}
                            </Button>
                            : null
                    }
                </div>
            </StyledHeaderButtons>
            {reportErrors.reportName && <Alert variant="danger">{reportErrors.reportName.value}</Alert>}
            {reportDownloadUrls.reportName && <DownloadURLAlert
                downloadUrl={reportDownloadUrls.reportName}
                setDownloadUrl={() => setReportDownloadUrls({})}
                fileName={t('srm:exportButton')}
            />}
            {reportSubscriptionId && <ReportSubscriptionModal
                reportSubscriptionId={reportSubscriptionId}
                setReportSubscriptionId={setReportSubscriptionId}
                message={t('export:createPending')}
            />}
            <OrdersTable />

            {openSubmitModal && (<ModalComponent2
                ModalTitle={t('srm:transferOrders')}
                BodyPart1={t('srm:enterNumber')}
                button1Function={(number) => { submitGroup(number) }}
                button1Text={t('srm:transfer')}
                button1Variant="success"
                button1Loading={isLoading}
                bodyStyle="alert alert-success"
                functionCancel={cancelActions}
                inputType="textarea"
                inputIsMandatory
                inputLabel={t('srm:srmNumber')}
                error={error}
            />)}

            {openDeleteModal && (<ModalComponent2
                ModalTitle={t('srm:ordersDelete')}
                BodyPart1={t('srm:areYouSureDeleteOrders')}
                button1Function={(comment) => { deleteGroup(comment) }}
                button1Text={t('delete')}
                button1Variant="danger"
                button1Loading={isLoading}
                bodyStyle="alert alert-danger"
                functionCancel={cancelActions}
                inputType="textarea"
                inputIsMandatory
                inputLabel={t('cancelMessage')}
                error={error}
            />)}
        </>
    )
}

export default OrderApprovalSRM
