import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { DeleteIcon } from '../Icons/DeleteIcon'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'


const StyledButton = styled.div`
    display: inline-block;
    cursor: pointer;  
  `
const DeleteModal = (props) => {
    const { deleteFunction, deleteRecursivelyFunction, ModalTitle, BodyPart1, BodyPart2, showRecursivly } = props

    const [show, setShow] = useState(false);


    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);
    }

    const { t } = useTranslation()

    const deleteButton = () => {
        deleteFunction()
        handleClose()
    }
    const deleteRecursivelyButton = () => {
        deleteRecursivelyFunction()
        handleClose()
    }

    return (
        <>
            <StyledButton>
                <DeleteIcon title={t('delete')} onClick={handleShow} />
            </StyledButton>

            <Modal style={{ textAlign: "center" }} centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title >{ModalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="alert alert-danger mb-0">
                    <p>{BodyPart1}</p>
                    <p>{BodyPart2}</p>
                </Modal.Body >
                <Modal.Footer >
                    <Button variant="secondary" onClick={handleClose}>
                        {t('appointment:cancel')}
                    </Button>
                    <Button variant="danger" onClick={deleteButton}>
                        {t('delete')}
                    </Button>
                    {showRecursivly ?
                        <Button variant="danger" onClick={deleteRecursivelyButton}>
                            {t('officeAdmin:deleteRecursively')}
                        </Button>
                        : ""
                    }
                </Modal.Footer>
            </Modal >

        </>
    );
};

export default DeleteModal;
