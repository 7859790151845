import React from 'react'
import styled from 'styled-components'


import { ButtonWithLoadingState } from './ButtonWithLoadingState'
import ExcelIcon from '../../assets/icons/excel.svg'


const ButtonIcon = styled.span`
  background: url(${ExcelIcon}) center center no-repeat;
  background-size: contain;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  margin-right: 16px;
  vertical-align: middle;
  width: 20px;
`

export const ExcelButton = (props) => {
    return (
        <React.Fragment>
            <ButtonWithLoadingState buttonIcon={<ButtonIcon />} {...props} />
        </React.Fragment>
    )
}
