import React, { useMemo, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { useParams } from 'react-router-dom'
import { DeleteIcon } from "../../Icons/DeleteIcon";
import useStores from "../../../stores/useStore";
import ArraySelect from '../../Select/ArraySelect'
import ScanModal from '../ScanModal'
import { useTranslation } from "react-i18next";
import { observer } from 'mobx-react'

const StyledTr = styled.tr`
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  border-top: ${(props) => (props.borderTop ? "1px solid #e0e0e0" : "")};
`;
const StyledExpandedRow = styled.div`
  display: flex;
  margin-left: 2rem;
`

const StyledExpandedRowInner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow:1;
  .part1{
    display: flex;
    flex-basis: 50%;
  	padding: 0.5rem;
    align-items: flex-start;
    @media screen and (max-width: 992px) {
    flex-basis: 100%;
    }
  }
  .part2{
    display: flex;
    flex-basis: 30%;
    padding: 0.5rem;
    @media screen and (max-width: 992px) {
    flex-basis: 100%;
    }
  }
`;


const ExpandedArticle = observer(({ position, article, instances }) => {
  const { t } = useTranslation()
  const { inventoryStore } = useStores()
  const { actionType } = useParams()
  const [openScanModal, setOpenScanModal] = useState(null)
  const instancesAreLoading = inventoryStore.loadInstancesLoading



  const instanceToString = (instance) => {

    if (instance) {
      let rfid = instance.rfid ? instance.rfid : ""
      let batchKey = instance.batchKey ? instance.batchKey : ""
      let serialKey = instance.serialKey ? instance.serialKey : ""
      return `${rfid}${(rfid && (batchKey || serialKey)) ? " | " : ""}${batchKey}${(batchKey && serialKey) ? " | " : ""}${serialKey}`
    }
    else return ""
  }

  const preparedInstances = useMemo(() => (instances.map((instance => {
    let instanceString = instanceToString(instance)
    return ({ id: instanceString, displayName: instanceString, instance: instance })
  }))), [instances])

  const handleDelete = (position, index) => {
    inventoryStore.deleteRow(article, position.articleVariant, index)
  };


  const renderRows = () => {
    let hideRFIDSelect = !preparedInstances.length || (actionType === 'inventoryBookings' && !position.negativeAmount)
    return position.rows.map((row, index) => (
      <StyledExpandedRow key={index}>
        <div className="m-2">{index + 1}.</div>
        <StyledExpandedRowInner>
          <div className="part1">
            {!hideRFIDSelect ?
              <>
                <ArraySelect
                  defaultOptionIsSelectable
                  value={instanceToString(row.instance)}
                  options={preparedInstances}
                  disabledKeys={position.rows ? position.rows.map(row => instanceToString(row.instance)) : []}
                  //defaultOption.instance = undefined?
                  onChange={(option) => inventoryStore.updateInstances(article, position.articleVariant, option.instance, index)}
                  noAutoSelect
                  isInvalid={instanceToString(row.instance) === ""}
                  defaultOption={{ value: "", name: t('inventory:pleaseSelectRFID') }}
                />
                <Button className="mx-2" onClick={() => setOpenScanModal(index)}>{t('search')}</Button>
              </>
              : <div></div>
            }
          </div>
          <div className="part2">
            {inventoryStore.returnReasons && inventoryStore.returnReasons.length
              ? <ArraySelect
                onChange={obj => inventoryStore.updateReason(article, position.articleVariant, obj.id, index)}
                value={row.changeReasonId}
                options={inventoryStore.returnReasons}
                defaultOption={{ value: "", name: t('inventory:selectReason') }}
                isInvalid={inventoryStore.submitErrorPointers.partnerId && !row.changeReasonId}
              />
              : <Form.Control as="textarea" rows="1" name="comment" placeholder={t('comment')} onChange={e => inventoryStore.updateComment(article, position.articleVariant, e.target.value, index)} value={row.comment} />
            }
            <div
              className="m-2"
              role="button"
              onClick={() => handleDelete(position, index)}
            >
              <DeleteIcon title={"delete"} />
            </div>
          </div>
        </StyledExpandedRowInner>
      </StyledExpandedRow>
    )
    );
  };

  return <StyledTr className="bg-light">
    <td colSpan="4">
      {instancesAreLoading
        ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
        : <div>
          {renderRows()}
          {openScanModal != null
            ? <ScanModal
              actionType={actionType}
              article={article}
              articleVariant={position.articleVariant}
              handleClose={() => { setOpenScanModal(null) }}
              index={openScanModal}
            />
            : null
          }
        </div>
      }
    </td>
  </StyledTr>
})
export default ExpandedArticle