import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import { useAppointmentState } from '../../../stores/AppointmentProvider'
import MandatoryField from '../../MandatoryField/MandatoryField'
import usePrevious from "../../../utils/hooks/usePrevious";

const UserData = () => {
    const {
        appointment,
        setAppointment,
        userData
    } = useAppointmentState()
    const { t } = useTranslation()
    const prevData = usePrevious({ userData })

    useEffect(() => {
        if (userData && (prevData?.userData !== userData)) {
            let number = findNumber(userData)
            let address = findAddress(userData)
            let newAppointment = { ...appointment, email: address, phone: number }
            setAppointment(newAppointment)
        }
    }, [appointment, prevData, setAppointment, userData])

    const findNumber = (data) => {
        var result = ''
        if (data.length > 0) {
            let privateCellphone = data.find(obj => (obj.type === "cellphone" && obj.isPrivate && obj.value))
            let cellphone = data.find(obj => (obj.type === "cellphone" && !obj.isPrivate && obj.value))
            let phone = data.find(obj => (obj.type === "phone" && !obj.isPrivate && obj.value))
            if (privateCellphone) { result = privateCellphone.value }
            else if (cellphone) { result = cellphone.value }
            else if (phone) { result = phone.value }
        }
        return result
    }
    const findAddress = (data) => {
        var result = ''
        if (data.length > 0) {
            let email = data.find(obj => (obj.type === "email" && !obj.isPrivate && obj.value))
            let privateEmail = data.find(obj => (obj.type === "email" && obj.isPrivate && obj.value))

            if (email) { result = email.value }
            else if (privateEmail) { result = privateEmail.value }
        }
        return result
    }

    const handleChange = e => {
        const { name, value } = e.target
        setAppointment(prev => ({ ...prev, [name]: value }))
    }

    return (
        <>
            {appointment && <>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" >
                            <Form.Label>{t('comment')}</Form.Label>
                            <Form.Control as="textarea" rows="1" name="comment" placeholder={t('comment')} onChange={handleChange} value={appointment.comment} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('email')}*</Form.Label>
                            <Form.Control
                                name="email"
                                type="email"
                                placeholder="Enter email"
                                value={appointment.email}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('appointment:telephone')}*</Form.Label>
                            <Form.Control
                                name="phone"
                                type="tel"
                                placeholder={t('appointment:labelTelephone')}
                                value={appointment.phone}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </>}
            < MandatoryField />
        </>
    )
}
export default UserData