import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { ArrowBigIcon } from '../Icons/ArrowBigIcon';
import useStores from '../../stores/useStore';
import { colors } from '../../styles/colors';
import { useObserver } from 'mobx-react';

const StyledContingentList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const StyledContingentTitle = styled.span`
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
  cursor: pointer;
  font-size: 18px;
  color: ${colors.textColorB80};

  a {
    color: ${colors.textColorB80};
  }

  svg {
    margin-top: -2px;
    margin-right: 15px;
  }

  &:hover {
    background-color: ${colors.sidebarActiveGray};
  }
`;

const StyledProductGroupList = styled.ul`
  list-style: none;
  padding: 0 0 0 20px;
  opacity: 0.8;
`;

const StyledProductGroupListItem = styled.li`
  margin: 20px 0;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${colors.sidebarActiveGray};
  }
`;

const StyledListWrapper = styled.div`
  padding-left: 45px;
`;

const AdministrationSidebar = ({ options, active }) => {
  const { uiStore } = useStores();
  const navigate = useNavigate();

  const [contingentStates, setContingentStates] = useState(() => {
    const initialState = {};
    options.forEach((option) => {
      initialState[option.menuName] = true;
    });
    return initialState;
  });

  const handleClickTitle = (menuName, goTo) => {
    setContingentStates((prevState) => ({
      ...prevState,
      [menuName]: !prevState[menuName],
    }));
    if (goTo) {
      navigate(goTo)
    }
  };

  const checkRights = (accessRights) => {
    if (Array.isArray(accessRights) && accessRights.length > 0) {
      return accessRights.every(item => uiStore[item]);
    }
    return false;
  };

  return useObserver(() => (
    <StyledContingentList>
      {options.map((option, index) => {
        const isContingentOpen = contingentStates[option.menuName];
        const hasVisibleSubmenu = option.subMenu.some(subMenu => checkRights(subMenu.accessRights));
        const hasMenuAccessRights = checkRights(option.accessRights);
        const isSingleMenu = option.subMenu.length === 0

        if (hasVisibleSubmenu || hasMenuAccessRights || (isSingleMenu && hasMenuAccessRights)) {
          return (
            <li className={`mb-4`} key={index}>
              <StyledContingentTitle onClick={() => handleClickTitle(option.menuName, option.to)}>
                {(hasVisibleSubmenu && !isSingleMenu)
                  ? <>
                    <ArrowBigIcon variant={isContingentOpen ? 'down' : 'right'} />
                    {option.menuName}
                  </>
                  : <div style={{ marginLeft: '43px' }}  >
                    {option.menuName}
                  </div>
                }
              </StyledContingentTitle>
              {hasVisibleSubmenu && (
                <Collapse in={isContingentOpen}>
                  <StyledListWrapper>
                    <StyledProductGroupList>
                      {option.subMenu.map((subMenu, subIndex) => {
                        const hasAccess = checkRights(subMenu.accessRights);
                        if (hasAccess) {
                          return (
                            <StyledProductGroupListItem key={subIndex} onClick={() => navigate(subMenu.to)}>
                              {subMenu.name}
                            </StyledProductGroupListItem>
                          );
                        }
                        return null;
                      })}
                    </StyledProductGroupList>
                  </StyledListWrapper>
                </Collapse>
              )}
            </li>
          );
        }
        return null;
      })}
    </StyledContingentList>
  ));
};

export default AdministrationSidebar;
