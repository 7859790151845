import styled from 'styled-components'

export const Arrow2Icon = styled.span`
  display: inline-block;
  width: 17px;
  height: 17px;
  padding: 3px;
  cursor: pointer;
  &::before{
    content: '';
    width: 100%;
    height: 100%;
    display: block;    
    /* background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${props => encodeURIComponent(props.color? props.color: '#00035c')}' viewBox='0 0 8 8'%3E%3Cpath d='M5 1v2h-5v1h5v2l3-2.531-3-2.469z'/%3E%3C/svg%3E") no-repeat center center; */
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${props => encodeURIComponent(props.color? props.color: '#00035c')}' viewBox='0 0 28 28'%3E%3Cg%3E%3Cg%3E%3Cpath d='M21.7 10.3c.4.4.4 1 0 1.4l-6.8 6.8c0 .1-.1.2-.2.3-.2.1-.4.2-.7.2-.3 0-.5-.1-.7-.3-.1-.1-.1-.2-.2-.3l-6.8-6.8c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l6.3 6.3 6.3-6.3c.4-.3 1.1-.3 1.4.1z' fillRule='evenodd' clipRule='evenodd' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center center;
    ${props => {
      switch(props.variant){
        case 'up':
          return 'transform: rotate(90deg);'
        case 'down':
          return 'transform: rotate(-90deg);'
        case 'left':
          return 'transform: rotate(-180deg);'
        default:
      }
    }
  }
`
