import React, { useCallback, useEffect, useState } from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { Button, Form, Alert, Spinner } from 'react-bootstrap'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import { OverlayLeft } from '../components/Page/logix2020/OverlayLeft'
import { Subheader } from '../components/Page/logix2020/Subheader'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { Link } from 'react-router-dom'
import EmployeesTable from '../components/Employees/EmployeesTable'
import EmployeeOverlay from '../components/Employees/EmployeeOverlay'
import { useEmployeesState } from '../stores/EmployeesProvider'
import OverlayLeftFilter from '../components/Employees/OverlayLeftFilter'
import useStores from '../stores/useStore'
import { useTranslation } from 'react-i18next'
import ActiveTableFilters from '../components/Employees/ActiveTableFilters'
import MediaQuery from 'react-responsive'
import BigFooter from '../components/Footer/BigFooter'
import { MdLibraryAdd } from 'react-icons/md'


const StyledContentArea = styled(ContentArea)`
  width: 100%;
  padding: 20px 30px 20px 36px;
 .control-row{display:felx; flex-wrap: wrap; align-items: baseline ;}
  @media only screen  and (max-width: 767px), (min-device-width: 320px)  and (max-device-width: 767px)  {
    .mobileButton{ width: 100%; margin-top:16px; margin-bottom:16px;} 
    padding:20px;
    .control-row{display:block; flex-wrap: nowrap; align-items: stretch ;}
  }
  .filterSpaceBetween{
    @media only screen  and (min-width: 768px) {
      display: flex;
      justify-content: space-between; 
      margin-bottom:20px;
    }
  }
`

const EmployeesPageContent = () => {
  const {
    setShowAllEmployees,
    showDisabledEmployees,
    setShowDisabledEmployees,
    resetFilter,
    selectedRow,
    setSelectedRow,
    showOverlayLeft,
    setShowOverlayLeft,
    updateOverlayLeftStates
  } = useEmployeesState()
  const { uiStore } = useStores()
  const { t } = useTranslation()

  const handleCloseOverlayRight = useCallback(() => {
    setSelectedRow(false)
  }, [setSelectedRow])

  const handleCloseOverlayLeft = e => {
    setShowOverlayLeft(false)
    updateOverlayLeftStates()
  }

  const onRowClick = (e, row) => {
    setSelectedRow(prev => prev === row ? false : row)
  }
  return useObserver(() => (
    <div>
      <Subheader className="subHeader-v1">
        <div className="subheader-title flex-grow-1">{t('employeesPage:Employee')}</div>
        {(uiStore.allowCreateNewEmployee) && <MediaQuery minWidth={768}>
          <Link className="subheader__button btn btn-primary btn-extra-padding" to="/addemployee">
            <MdLibraryAdd className="me-3" />{t('employeesPage:addNewEmployee')}
          </Link>
        </MediaQuery>}
      </Subheader>
      <OverlayLeft className={`overlay-variant ${showOverlayLeft ? 'visible' : ''}`}>
        <div className="header" onClick={handleCloseOverlayLeft}>
          <span onClick={handleCloseOverlayLeft}><i className="close-icon" /></span>
          <span className="header-title">{t('filter')}</span>
        </div>
        <div className="body">
          <OverlayLeftFilter checkupAdministration={uiStore.checkupAdministration} />
        </div>
      </OverlayLeft>
      <StyledContentArea>
        <div className="filterSpaceBetween">
          <div className="control-row">
            {(uiStore.allowCreateNewEmployee) && <MediaQuery maxWidth={767}>
              <Link className="btn btn-primary btn-extra-padding w-100" to="/addemployee">
                {t('employeesPage:addNewEmployee')}
              </Link>

            </MediaQuery>}
            <Button variant="secondary" className="mobileButton" onClick={() => { setShowOverlayLeft(prev => !prev) }}>
              {t('allFilters')}
            </Button>
          </div>
          <div className="control-row">
            <Form.Check
              className="me-2"
              type="checkbox"
              id="checkbox"
              checked={showDisabledEmployees}
              onChange={(e) => setShowDisabledEmployees(e.target.checked)}
              label={t('employeesPage:showDeactivatedEmployees')}
            />
            <Button variant="secondary" className="btn-extra-padding mobileButton" onClick={() => { resetFilter(); setShowAllEmployees(true) }}>
              {t('employeesPage:showAllEmployees')}
            </Button>
          </div>
        </div>
        <ActiveTableFilters />
        <EmployeesTable onRowClick={onRowClick} tableId="Employees" />
      </StyledContentArea>
      <BigFooter />
      <EmployeeOverlay
        employeeAdministration={uiStore.employeeAdministration || uiStore.allowAccessUserModule}
        fittingReservation={uiStore.fittingReservation}
        checkupAdministration={uiStore.checkupAdministration}
        handleCloseOverlayRight={handleCloseOverlayRight}
        selectedRow={selectedRow}
      />
    </div>
  ))
}



const EmployeesPage = () => {

  const { uiStore } = useStores()
  const { t } = useTranslation()
  const [error, setError] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setError(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return useObserver(() => (
    <>
      {!(uiStore.employeeAdministration || uiStore.allowAccessUserModule || uiStore.allowSeeEmployeeInventories)
        ? (
          <>
            <Subheader className="subHeader-v1">
              <div className="subheader-title">{t('employeesPage:Employee')}</div>
            </Subheader>
            {error
              ? <Alert variant="danger">{t('employeesPage:accessDenied')}</Alert>
              : <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
            }
          </>
        )
        : <EmployeesPageContent />
      }
    </>
  ))
}
export default EmployeesPage

