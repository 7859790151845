import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button } from 'react-bootstrap'
import useAppointmentTypesState from '../../../stores/Configuration/useAppointmentTypesState'
import LanguageNameInput from '../../Forms/LanguageNameInput'
import { Navigate } from 'react-router-dom'
import OverlayRight from '../../Page/logix2020/OverlayRight'
import OverlayContainer from '../../Page/logix2020/OverlayContainer'
import LanguageForm from '../../Forms/LanguageForm'
import styled from 'styled-components'



const StyledButton = styled(Button)`
margin-top:1.5rem;
margin-bottom:1.5rem;
  @media screen and (max-width: 767px) {
      width:100%;
`
const GeneralForm = () => {


    const { t } = useTranslation()
    const [languageModalVisible, setLanguageModalVisible] = useState(false)
    const { handleSubmit, selectedAppointmentType, setSelectedAppointmentType, formErrors, redirectId, error, success } = useAppointmentTypesState()

    const updateNames = (translations) => {
        setSelectedAppointmentType(prev => ({
            ...prev, name: {
                values: translations
            }
        }))
    }

    const closeLanguageModal = () => {
        setLanguageModalVisible(false)
    }
    //redirect to edit page after new type was created
    if (redirectId) {
        return <Navigate
            to={`/appointmenttypes/edit/${redirectId}#general`}
            state={{ createNewAppointmentType: true }}
            replace
        />
    }


    return (
        <>
            <LanguageNameInput
                openLanguageModal={() => setLanguageModalVisible(true)}
                languageArray={(selectedAppointmentType.name && selectedAppointmentType.name.values) || []}
                onChange={updateNames}
                label={t('name')}
                isInvalid={formErrors && formErrors.name}
            />
            <StyledButton className="btn-extra-padding" onClick={() => handleSubmit("name")}>{t('saveButton')}</StyledButton>
            {error &&
                <Alert variant="danger" >
                    {error}
                </Alert>
            }
            {success &&
                <Alert variant="success" className="my-2">
                    {success}
                </Alert>
            }
            <OverlayContainer>
                <OverlayRight visible={languageModalVisible} onClose={closeLanguageModal}>
                    {
                        <LanguageForm
                            languageTitle={t('name')}
                            handleCloseOverlay={closeLanguageModal}
                            languageArray={(selectedAppointmentType.name && selectedAppointmentType.name.values) || []}
                            onChange={updateNames} />
                    }
                </OverlayRight>
            </OverlayContainer>
        </>
    )
}

export default GeneralForm
