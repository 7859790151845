import React from 'react';
import styled from 'styled-components';
import { TbPlayerTrackPrevFilled, TbPlayerTrackNextFilled } from 'react-icons/tb';
import { t } from 'i18next';
const DateCard = styled.div`
overflow:hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5; /* Light gray background color */
  border: 1px solid #ccc; /* Border color */
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
  padding: 15px;
  margin: 10px;
`;

const ArrowContainer = styled.div`
  position: absolute;
  top:-5px;

`;

const PreviousArrow = styled(TbPlayerTrackPrevFilled)`
color: rgb(119 119 119 / 10%);
`;

const NextArrow = styled(TbPlayerTrackNextFilled)`
color: rgb(119 119 119 / 10%);
`;

const DateText = styled.span`
  font-size: 18px;
  color: #808080; /* Gray color for date text */
  z-index: 1; /* Place text above the arrows */
`;

const Label = styled.span`
  font-size: 16px;
  color: #808080; /* Gray color for label text */
  margin-top: 5px;
  z-index: 1; /* Place text above the arrows */
`;

const DateDisplay = ({ isPrevious, date }) => {
  return (
    <DateCard>
      <ArrowContainer>
        {isPrevious ? <PreviousArrow size="133" /> : <NextArrow size="133" />}
      </ArrowContainer>
      <DateText>{date}</DateText>
      <Label>{isPrevious ? t('stdPointRunAdmin:lastPointrun') : t('stdPointRunAdmin:nextPointrun')}</Label>
    </DateCard>
  );
};

export default DateDisplay;