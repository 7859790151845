import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import OrdersTable from '../../Orders/OrdersTable'
import CustomDropdown from '../../Dropdowns/CustomDropdown'
import { useOrdersTableState } from '../../../stores/OrdersTableProvider'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getErrorMessageFromApiResponse } from "../../../utils/api/apiUtils"
import ModalComponent2 from '../../Modals/ModalComponent2'

const StyledHeaderButtons = styled.div`

@media screen and (min-width:768px) {
    display:flex;
justify-content: space-between;
}
@media only screen and (max-width: 767px), (min-device-width: 320px) and (max-device-width: 767px)  {
    .customDropdown{
        margin-bottom:1rem;
    }
    button{
        width:100%;
        margin-bottom:1rem;
    }
}
`

const OrderApproval = () => {
    const { selectedCheckBoxes, setSelectedCheckBoxes, setLastMonths, fetchOrders, setStatus } = useOrdersTableState()
    const [reject, setReject] = useState(false)
    const [release, setRelease] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation()
    const [error, setError] = useState('')

    useEffect(() => {
        setStatus("waitingForApproval")
    }, [setStatus])

    const input = [
        {
            name: t('orders:last3month'),
            value: 3
        },
        {
            name: t('orders:last6month'),
            value: 6
        },
        {
            name: t('orders:lastYear'),
            value: 12
        },
        {
            name: t('all'),
            value: null
        }
    ]

    const myFunction = e => {
        setLastMonths(e.value)
    }
    const submit = async (type, message) => {
        setError('')
        const endpoint = prepareEndpoint(type)
        const params = prepareParams(message)
        try {
            setIsLoading(true)
            await apiClient.postJson(endpoint, params)
            setSelectedCheckBoxes([])
            fetchOrders()
            setReject(false)
            setRelease(false)
        } catch (e) {
            setError(getErrorMessageFromApiResponse(e))
        } finally {
            setIsLoading(false)
        }
    }

    //array of ids to array of objects [id1,id2] => [{comment:"",id:id1},{comment:"", id:id2}]
    const prepareParams = (message) => {
        const result = selectedCheckBoxes.map(id => {
            var rObj = {}
            rObj.comment = message
            rObj.id = id
            return rObj
        })
        return result
    }

    const prepareEndpoint = (type) => {
        let endpoint = ''
        switch (type) {
            case 'release':
                endpoint = APIEndpoints.incomingOrders().releases
                break;
            case 'reject':
                endpoint = APIEndpoints.incomingOrders().rejections
                break;
            default:
            // code block
        }
        return endpoint
    }

    const cancelActions = () => {
        setReject(false)
        setError('')
        setRelease(false)
        setIsLoading(false)
    }

    return (
        <>
            <StyledHeaderButtons>
                <div className="customDropdown"><CustomDropdown data={input} returnObject={myFunction} /></div>
                <div>
                    <Button
                        className="mb-3 me-2"
                        disabled={selectedCheckBoxes.length === 0}
                        variant="success"
                        onClick={() => setRelease(true)}
                    >{t('release')}</Button>
                    <Button
                        className="mb-3"
                        disabled={selectedCheckBoxes.length === 0}
                        variant="danger"
                        onClick={() => setReject(true)}
                    >{t('reject')}</Button>
                </div>
            </StyledHeaderButtons>
            <OrdersTable />
            {reject && (<ModalComponent2
                ModalTitle={t('orders:rejectOrderHeader')}
                BodyPart1={t('orders:rejectOrderBody')}
                button1Function={(comment) => { submit('reject', comment) }}
                button1Text={t('reject')}
                button1Variant="danger"
                button1Loading={isLoading}
                bodyStyle="alert alert-danger"
                functionCancel={cancelActions}
                inputType="textarea"
                inputLabel={t('comment')}
                error={error}
            />)}

            {release && (<ModalComponent2
                ModalTitle={t('orders:releaseOrderHeader')}
                BodyPart1={t('orders:releaseOrderBody')}
                button1Function={(comment) => { submit('release', comment) }}
                button1Text={t('release')}
                button1Variant="success"
                button1Loading={isLoading}
                bodyStyle="alert alert-success"
                functionCancel={cancelActions}
                inputType="textarea"
                inputLabel={t('comment')}
                error={error}
            />)}

        </>
    )
}

export default OrderApproval
