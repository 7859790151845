import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Alert } from 'react-bootstrap'
import { CategoryIconDefault } from '../Icons/CategoryIcons/CategoryIcons'
import styled, { withTheme } from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from '../../styles/colors'
import { withTranslation, Trans } from 'react-i18next'

const StyledFollowerArticleLink = styled(Link)`
  color: #000;
`

const StyledProductImgWrap = styled.div`
  background-color: #FFF;
  border: 1px solid ${colors.gray2};
  border-radius: 5px;
  width: 85px;
  height: 85px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`

const StyledProductImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`

class FollowerArticle extends Component {
  componentDidMount() {
    this.props.productDetailsStore.loadOrderAbleFollowerArticle()
  }

  render() {
    const { productDetailsStore, theme } = this.props
    if (!productDetailsStore.article.followerArticle || !productDetailsStore.orderableFollowerArticle) {
      return null
    }
    const followerArticle = productDetailsStore.orderableFollowerArticle
    return (
      <Alert className='mt-5' variant='warning'>
        <Trans i18nKey='productDetail:isFollowerArticle' />

        <StyledFollowerArticleLink
          to={productDetailsStore.getProductToUrl(followerArticle)}
          className='d-block mt-2'>
          <div className='d-flex align-items-center'>
            <StyledProductImgWrap>
              {followerArticle.article.image && followerArticle.article.image.url
                ? <StyledProductImg src={followerArticle.article.image.url} alt='' />
                : <CategoryIconDefault color={theme.primaryColor} />
              }
            </StyledProductImgWrap>
            <div className='ms-3'>{followerArticle.article.displayName}</div>
          </div>
        </StyledFollowerArticleLink>
      </Alert>
    )
  }
}

export default withTheme(withTranslation()(inject('productDetailsStore')(observer(FollowerArticle))))
