import * as ReactDOM from 'react-dom'
import { useEffect } from 'react'

const overlayRoot = document.getElementById('overlay-root')

const OverlayContainer = ({ children, overLayRootZIndex }) => {
  const el = document.createElement('div')

  useEffect(() => {
    if (overLayRootZIndex) {
      overlayRoot.style.position = 'relative'
      overlayRoot.style.zIndex = overLayRootZIndex
    }

    return () => {
      if (overLayRootZIndex) {
        overlayRoot.style.position = null
        overlayRoot.style.zIndex = null
      }
    }
  }, [overLayRootZIndex])

  useEffect(() => {
    overlayRoot.appendChild(el)
    return () => {
      overlayRoot.removeChild(el)
    }
  }, [el])

  return ReactDOM.createPortal(children, overlayRoot)
}

export default OverlayContainer
