import React, { useEffect, useState } from 'react'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'

const ReportsJobsTypeContext = React.createContext()

const ReportJobsTypeProvider = ({ children }) => {
  const [reportJobTypeGroups, setReportJobTypeGroups] = useState([])
  const [reportJobTypeGroup, setReportJobTypeGroup] = useState('none')
  const [reportJobTypes, setReportJobTypes] = useState([])

  useEffect(() => {
    apiClient.getJson(APIEndpoints.reportJobTypes).then(response => {
      setReportJobTypes(response)
    })
    apiClient.getJson(APIEndpoints.reportJobTypeGroups).then(response => {
      setReportJobTypeGroups(response)
    })
  }, [])

  const contextValues = {
    reportJobTypeGroup,
    setReportJobTypeGroup,
    reportJobTypeGroups,
    reportJobTypes,
    setReportJobTypes
  }

  return (
    <ReportsJobsTypeContext.Provider value={contextValues}>
      {children}
    </ReportsJobsTypeContext.Provider>
  )
}

export const useReportJobsTypeState = () => {
  return React.useContext(ReportsJobsTypeContext)
}

export default ReportJobsTypeProvider
