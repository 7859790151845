import { Form } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledFormHeadline = styled.h1`
  color: white;
  margin-bottom: 1rem;
  margin-top: 2rem;
`

export const StyledValidationMessage = styled(Form.Control.Feedback)`
  background: #ff001842;
  padding: 6px 10px;
  color: white;
  margin-top: 0px;
  font-size:  16px ;
`