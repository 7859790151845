import React, { useEffect } from 'react'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import SubheaderTitle from '../../components/Page/logix2020/SubheaderTitle'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import BigFooter from '../../components/Footer/BigFooter'
import { t } from 'i18next'
import ActivityGeneralForm from '../../components/Configuration/Activity/ActivityGeneralForm'
import { useActivitiesState } from '../../stores/Configuration/ActivitiesProvider'
import { useParams } from 'react-router-dom'

const EditActivityPage = () => {
  const { getActivity, resetActivityPage, successStates, setSuccessStates } = useActivitiesState()
  const { activityId } = useParams()

  useEffect(() => {
    getActivity(activityId)
    return () => resetActivityPage()
  }, [activityId, getActivity, resetActivityPage])

  useEffect(() => {
    let timer = null
    if (successStates.saveActivity) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        setSuccessStates({});
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [setSuccessStates, successStates.saveActivity])

  return (
    <div>
      <Subheader className="subHeader-v1">
        <SubheaderTitle
          withBackButton
          smallTitle={t('activities:editActivity')}
          goBackLabel={t('activities:activitesOverview')}
          defaultBackTo="/activitiesAdministration"
        />
      </Subheader>
      <ContentArea>
        <ActivityGeneralForm activityId={activityId} />
      </ContentArea>
      <BigFooter />
    </div >
  )
}

export default EditActivityPage