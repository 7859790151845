import React from 'react'
import { Form, Spinner } from 'react-bootstrap'

import MainData from '../Form/FormParts/MainData'
import CommunicationData from '../Form/FormParts/CommunicationData'
import ShippingAddresses from '../Form/FormParts/ShippingAddresses'
import BillingAddress from '../Form/FormParts/BillingAddress'
import JobFunctions from '../Form/FormParts/JobFunctions'
import EmployeeActivationSwitch from '../Form/FormParts/EmployeeActivationSwitch'
import CostCenterMultiselect from '../Form/FormParts/CostCenterMultiselect'
import OfficeMultiSelect from '../Form/FormParts/OfficeMultiSelect'
import CatalogsTable from '../Form/FormParts/CatalogsTable'
import GroupMemberTable from '../Form/FormParts/GroupMemberTable'
import AccountData from '../Form/FormParts/AccountData'
import { useEmployeeDataState } from '../../../stores/EmployeeDataProvider'


const EditContentArea = () => {
  const {
    communicationData,
    setCommunicationDataByKey,
    formErrors,
    mainData,
    setMainDataByKey,
    resetErrorByFormKey,
    addresses,
    setAddressesByKey,
    activities,
    setActivitiesByKey,
    accountData,
    setAccountDataByKey,
    showUserData,
    loadedEmployeeData,
    selectableAvtivities,
    submittedCommunicationData
  } = useEmployeeDataState()
  return (
    <Form>
      <EmployeeActivationSwitch />
      {
        //employeeData must load first. because arraySelect might change stuff before employeeData resets it again
        !loadedEmployeeData
          ?
          <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
          :
          <>
            <MainData mainData={mainData} setMainDataByKey={setMainDataByKey} formErrors={formErrors} resetErrorByFormKey={resetErrorByFormKey} />
            <CommunicationData collapsible communicationData={communicationData} setCommunicationDataByKey={setCommunicationDataByKey} formErrors={formErrors} submittedCommunicationData={submittedCommunicationData} />
            <ShippingAddresses collapsible addresses={addresses} setAddressesByKey={setAddressesByKey} />
            <BillingAddress collapsible addresses={addresses} setAddressesByKey={setAddressesByKey} />
            <JobFunctions selectableAvtivities={selectableAvtivities} collapsible activities={activities} setActivitiesByKey={setActivitiesByKey} formErrors={formErrors} resetErrorByFormKey={resetErrorByFormKey} />
            {
              showUserData ? <>
                <AccountData collapsible accountData={accountData} setAccountDataByKey={setAccountDataByKey} formErrors={formErrors} resetErrorByFormKey={resetErrorByFormKey} />
                <CostCenterMultiselect collapsible mainData={mainData} setMainDataByKey={setMainDataByKey} resetErrorByFormKey={resetErrorByFormKey} accountData={accountData} setAccountDataByKey={setAccountDataByKey} />
                <OfficeMultiSelect collapsible mainData={mainData} setMainDataByKey={setMainDataByKey} resetErrorByFormKey={resetErrorByFormKey} accountData={accountData} setAccountDataByKey={setAccountDataByKey} />
                <CatalogsTable collapsible accountData={accountData} setAccountDataByKey={setAccountDataByKey} />
                <GroupMemberTable collapsible accountData={accountData} setAccountDataByKey={setAccountDataByKey} />
              </>
                : null
            }
          </>
      }
    </Form>)
}

export default EditContentArea
