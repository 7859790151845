import { t } from 'i18next'
import React from 'react'
import { useState } from 'react'
import { Image } from 'react-bootstrap'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { CrossIcon } from '../Icons/CrossIcon'
import ProductGalleryModal from '../ProductGallery/ProductGalleryModal'
import { FileTypeIcon } from '../../utils/getFileTypeIcon'

const StyledWrapper = styled.div`
  display:flex;
  align-items: center;
  justify-content: space-between;
`
const StyledName = styled.div`
  word-break:break-all;
  display:flex;
  align-items: center;
  margin-right: 1rem;
`
const StyledSize = styled.div`
  color:${props => props.sizeColor ? props.sizeColor : colors.gray3};
  display:flex;
  align-items: center;
  flex-shrink:0;
`
const StyledThumbnail = styled.div`
  flex-shrink:0;
  margin-right:1rem;
  width:4rem;
  >img{
    max-width:4rem;
    cursor:zoom-in;
  }
`

const bytesToKiloBytes = bytes => Math.round(bytes / (1024));
const imageFileTypes = ["image/png", "image/jpeg"]

export const FileOutput = ({ file, deleteFile, sizeColor, ...rest }) => {
  const [openGalleryModal, setOpenGalleryModal] = useState(null)
  return <StyledWrapper {...rest}>
    <StyledName>
      <StyledThumbnail>
        {imageFileTypes.includes(file.type)
          ? <Image src={file.preview} rounded onClick={() => setOpenGalleryModal({ url: file.preview })} />
          : <FileTypeIcon fileType={file.type} />}
      </StyledThumbnail>
      <div><a href={file.preview} download={file.name}>{file.name}</a></div>
    </StyledName>
    <StyledSize sizeColor={sizeColor}>
      <div>{`(${bytesToKiloBytes(file.size)} KB)`}</div>
      {deleteFile ? <CrossIcon
        title={t('delete')}
        color={sizeColor || colors.gray3}
        onClick={deleteFile}
      /> : null}
    </StyledSize>
    {openGalleryModal && <ProductGalleryModal handleClose={() => setOpenGalleryModal(null)} detailImages={[openGalleryModal]} />}
  </StyledWrapper>
}


