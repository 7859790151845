import React from 'react'
import { StyledSendButton } from './StyledChat'
import { t } from 'i18next'
import { Spinner } from 'react-bootstrap'
import { ReactComponent as SendIcon } from '../../../assets/icons/sendIcon.svg'

const SendButton = ({ disabled, onClick, isLoading }) => {
  return (
    <StyledSendButton
      disabled={disabled}
      onClick={onClick}
      title={t('submitButton')}
    >
      {isLoading ? <Spinner size="sm" animation='border' variant='light' /> : < SendIcon style={{ color: "white" }} />}
    </StyledSendButton>
  )
}

export default SendButton