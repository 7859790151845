import React from 'react'
import OverlayRight, { StyledOverlayInnerWrapper } from '../Page/logix2020/OverlayRight'
import { StyledDataList } from '../DataList/dataList'
import EmployeeData from './EmployeeOverlay/EmployeeData'
import EmployeeHealthData from './EmployeeOverlay/EmployeeHealthData'
import AppointmentData from './EmployeeOverlay/AppointmentData'
import ActionSelect from './ActionSelect'

const EmployeeOverlay = (props) => {
  const {
    checkupAdministration,
    fittingReservation,
    handleCloseOverlayRight,
    selectedRow, employeeAdministration
  } = props

  return (
    <OverlayRight visible={selectedRow} onClose={handleCloseOverlayRight}>
      <StyledOverlayInnerWrapper>
        <StyledDataList className="content">
          {employeeAdministration && <EmployeeData selectedRow={selectedRow} handleCloseOverlayRight={handleCloseOverlayRight} />}
          <ActionSelect selectedRow={selectedRow} />
          {checkupAdministration && <EmployeeHealthData selectedRow={selectedRow} />}
          {fittingReservation && <AppointmentData selectedRow={selectedRow} />}
        </StyledDataList>
      </StyledOverlayInnerWrapper>
    </OverlayRight>
  )
}

export default EmployeeOverlay
