import React, { useState, useEffect } from 'react'
import { useObserver } from 'mobx-react'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import { useTranslation } from 'react-i18next'
import useStores from '../../stores/useStore'
import { Alert, Spinner } from 'react-bootstrap'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import SRMOrderContent from '../../components/Administration/SrmOrder/SRMOrderContent'
import styled from 'styled-components'
import BigFooter from '../../components/Footer/BigFooter'
import SRMGroupTableProvider from '../../stores/Administration/SRMGroupTableProvider'
import SubheaderSRMGroup from '../../components/Administration/SrmOrder/SubheaderSRMGroup'

const StyledContentArea = styled(ContentArea)`
   @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px)  {
    padding: 20px;
    min-height: calc(100vh - 140px);//header+navbar=140px
   }
`
const PageContent = () => {
    return (
        <>
            <SubheaderSRMGroup />
            <StyledContentArea >
                <SRMOrderContent />
            </StyledContentArea>
            <BigFooter />
        </>
    )
}

const SrmOrderAdministrationPage = () => {
    const { t } = useTranslation()
    const { uiStore } = useStores()
    const [error, setError] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setError(true);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return useObserver(() => (
        <>
            {!uiStore.activateSRMOrder
                ? <div>
                    <Subheader className="subHeader-v1">
                        <div className="subheader-title">{t('srm:srmOrderAdministration')}</div>

                    </Subheader>
                    {error
                        ? <Alert variant="danger">{t('accessDenied')}</Alert>
                        : <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
                    }
                </div>
                : <SRMGroupTableProvider><PageContent /></SRMGroupTableProvider>
            }
        </>
    ))
}


export default SrmOrderAdministrationPage