import React, { useEffect, useRef, useState } from 'react'
import SizePerPageRenderer from './SizePerPageRenderer'
import { Arrow2BigIcon } from '../Icons/Arrow2BigIcon'
import PageButtonRenderer from './PageButtonRenderer'
import { useTranslation } from 'react-i18next'

export const TableContext = React.createContext({})

export const useTableState = () => {
  const context = React.useContext(TableContext)

  return context
}

const TableProvider = ({
  children,
  pageOffset: _pageOffset,
  setPageOffset: _setPageOffset,
  pageLimit: _pageLimit,
  setPageLimit: _setPageLimit,
  totalCount: _totalCount,
  setTotalCount: _setTotalCount,
  sort: _sort,
  setSort: _setSort,
}) => {

  const { t } = useTranslation()
  const tableRef = useRef()
  const [__pageOffset, __setPageOffset] = useState(0)
  const [__pageLimit, __setPageLimit] = useState(10)
  const [__totalCount, __setTotalCount] = useState(0)
  const [__sort, __setSort] = useState('')

  const pageOffset = _pageOffset || __pageOffset
  const setPageOffset = _setPageOffset || __setPageOffset
  const pageLimit = _pageLimit || __pageLimit
  const setPageLimit = _setPageLimit || __setPageLimit
  const totalCount = _totalCount || __totalCount
  const setTotalCount = _setTotalCount || __setTotalCount
  const sort = _sort || __sort
  const setSort = _setSort || __setSort

  const [currentPage, setCurrentPage] = useState(pageOffset === 0 ? 1 : (pageOffset + pageLimit) / pageLimit)
  const [totalPages, setTotalPages] = useState(0)

  const paginationOptions = {
    paginationSize: 5,
    page: currentPage,
    sizePerPage: pageLimit,
    totalSize: totalCount,
    sizePerPageList: [{
      text: `10 ${t('reportConfig:perPage')}`, value: 10
    }, {
      text: `25 ${t('reportConfig:perPage')}`, value: 25
    }, {
      text: `50 ${t('reportConfig:perPage')}`, value: 50
    }, {
      text: `100 ${t('reportConfig:perPage')}`, value: 100
    }/*,
      {
        text: 'All', value: partialBuisinesspartners.length
      }*/],
    hideSizePerPage: totalCount === 0,
    sizePerPageRenderer: SizePerPageRenderer,
    pageButtonRenderer: PageButtonRenderer,
    nextPageText: <span key="nextPageText" className="d-flex align-items-center">
      {t('nextButton')} <Arrow2BigIcon variant="right" style={{ margin: '-6px 0 -5px 0' }} />
    </span>,
    prePageText: <span key="prePageText" className="d-flex align-items-center">
      <Arrow2BigIcon variant="left" style={{ margin: '-6px 0 -5px 0' }} /> {t('backButton')}
    </span>,
    firstPageText: <span key="firstPageText" className="d-flex align-items-center text-nowrap">
      <Arrow2BigIcon variant="left" style={{ margin: '-6px -18px -5px 0' }} />
      <Arrow2BigIcon variant="left" style={{ margin: '-6px 0 -5px 0' }} /> {t('firstPage')}
    </span>,
    lastPageText: <span key="lastPageText" className="d-flex align-items-center text-nowrap">
      {t('lastPage')} <Arrow2BigIcon variant="right" style={{ margin: '-6px 0 -5px 0' }} />
      <Arrow2BigIcon variant="right" style={{ margin: '-6px 0 -5px -18px' }} />
    </span>,
    /*pageListRenderer: ({pages, onPageChange}) => {
      return (
        <div className="d-flex align-items-center">
          {
            pages.map(p => (
              <button className="btn btn-primary" onClick={ () => onPageChange(p.page) }>{ p.page }</button>
            ))
          }
        </div>
      );
    }*/
  }

  useEffect(() => {
    setCurrentPage(pageOffset === 0 ? 1 : (pageOffset + pageLimit) / pageLimit)
  }, [pageOffset, pageLimit])

  useEffect(() => {
    setTotalPages(Math.ceil(totalCount / pageLimit))
  }, [totalCount, pageLimit])

  const contextValues = {
    tableRef,
    paginationOptions,
    pageLimit,
    setPageLimit,
    pageOffset,
    setPageOffset,
    totalCount,
    setTotalCount,
    sort,
    setSort,
    currentPage,
    totalPages
  }

  return (
    <TableContext.Provider value={contextValues}>
      {children}
    </TableContext.Provider>
  )
}

export default TableProvider
