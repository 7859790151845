import React, { useState } from 'react'
import { Collapse } from 'react-bootstrap';
import styled from 'styled-components';

import { colors } from '../../styles/colors';
import { ArrowBigIcon } from '../Icons/ArrowBigIcon';


const StyledContingentList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  `

const StyledContingentTitle = styled.span`
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
  cursor: pointer;
  font-size: 18px;
  color: ${colors.textColorB80};
  
  svg {
    margin-top: -2px;
    margin-right: 15px;
  }
  `

const StyledProductGroupList = styled.ul`
  list-style: none;
  padding: 0 0 0 20px;
  opacity: 0.8;
  `

const StyledProductGroupListItem = styled.li`
  margin: 20px 0;
  cursor: pointer;
  
  &:first-child {
    margin-top: 0;
  }
  
  &:last-child {
    margin-bottom: 0;
  }
  `

const StyledListWrapper = styled.div`
    padding-left: 45px;
  `


const ContingentList = (props) => {
  const { contingent, handleClickScrollToGroup } = props;
  const [isContingentOpen, setIsContingentOpen] = useState(true);
  return (
    <StyledContingentList>
      <li className="mb-4">
        {contingent.displayName !== '' && (
          <StyledContingentTitle
            onClick={() => setIsContingentOpen(prev => !prev)}
          >
            <ArrowBigIcon variant={isContingentOpen ? 'down' : 'right'} />
            {contingent.displayName}
          </StyledContingentTitle>
        )}
        <Collapse in={isContingentOpen}>
          <StyledListWrapper>
            <StyledProductGroupList>
              {contingent.categories.map(category => {
                return (
                  <StyledProductGroupListItem
                    onClick={() => handleClickScrollToGroup(category.id)}
                    key={category.id}
                  >
                    {category.displayName}
                  </StyledProductGroupListItem>

                )
              })}
            </StyledProductGroupList>
          </StyledListWrapper>
        </Collapse>
      </li>
    </StyledContingentList>
  )
}

export default ContingentList