import React from 'react'
import { CloseButton, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { t } from 'i18next'
import { useComplaintsState } from '../../stores/ComplaintsProvider'
import { colors } from '../../styles/colors'

const StyledWrapper = styled.div`
  flex-grow: 1;
  padding: 4px;
  
  .form-label {
    margin-bottom: 5px;
  }
`

const StyledItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px; // test later
`

const StyledItem = styled.div`
  margin: 5px;
  padding: 6px 10px;
  border: 1px solid ${colors.borderColor};
  border-radius: 8px;

  .btn-close {
    margin-left: 5px;
  }
`


const ActiveComplaintFilters = () => {
  const {
    filter,
    setFilterByKey,
    activeFilterCostCenter,
    setActiveFilterCostCenter
  } = useComplaintsState()

  const statusTextShown = (status) => {
    let text = ''
    switch (status) {
      case 'open':
        text = t('complaints:open')
        break;
      case 'closed_NotCleared':
        text = t('complaints:notCleared')
        break;
      default:
    }
    return text
  }

  const lastMonthsTextShown = (value) => {
    let text = ''
    switch (value) {
      case "1":
        text = t('orders:lastMonth')
        break;
      case "3":
        text = t('orders:last3month')
        break;
      case "6":
        text = t('orders:last6month')
        break;
      case "12":
        text = t('orders:lastYear')
        break;
      default:
    }
    return text
  }

  const removeFilter = (filterName) => {
    switch (filterName) {
      case 'status':
        setFilterByKey('status', '')
        break;
      case 'lastMonths':
        setFilterByKey('lastMonths', null)
        break;
      case 'showAllReturns':
        setFilterByKey('showAllReturns', false)
        break;
      case 'targetCostcenterId':
        setFilterByKey('targetCostcenterId', '')
        setActiveFilterCostCenter('')
        break;
      default: break
    }
  }
  const isComplaint = filter.type === 'complaint'
  const isRetoure = filter.type === 'retoure'
  if (!filter.showAllReturns && !filter.lastMonths && !filter.status && !filter.targetCostcenterId) {
    return null
  }

  return (
    <StyledWrapper>
      <Form.Label>{t('employeesPage:activeFilter')}:</Form.Label>
      <StyledItemWrapper>
        {!!filter.showAllReturns && (
          <StyledItem>{isComplaint ? t('complaints:allComplaints') : isRetoure ? t('complaints:allReturns') : t('complaints:allReturnsComplaints')}<CloseButton onClick={() => removeFilter('showAllReturns')} /></StyledItem>
        )}
        {!!filter.lastMonths && (
          <StyledItem>{lastMonthsTextShown(filter.lastMonths)}<CloseButton onClick={() => removeFilter('lastMonths')} /></StyledItem>
        )}
        {!!filter.status && (
          <StyledItem><b>{t('status')}:</b> {statusTextShown(filter.status)}<CloseButton onClick={() => removeFilter('status')} /></StyledItem>
        )}
        {!!filter.targetCostcenterId && (
          <StyledItem><b>{t('costcenterShortcut')}:</b> {activeFilterCostCenter}<CloseButton onClick={() => removeFilter('targetCostcenterId')} /></StyledItem>
        )}
      </StyledItemWrapper>
    </StyledWrapper>
  )
}

export default ActiveComplaintFilters