import React from 'react'
import { FileOutput } from '../../Forms/FileOutput'
import { cloneDeep } from 'lodash'

const FilesList = ({ files, setFiles }) => (
  files.map((file, index) => (
    <FileOutput
      style={{ height: "4rem", borderRadius: "1rem", padding: "0.5rem" }}
      className="mb-2"
      key={`${file.name}_${index}`}
      file={file}
      sizeColor="white"
      deleteFile={
        () => setFiles(prev => {
          prev.splice(index, 1)
          return cloneDeep(prev)
        })
      }
    />
  ))
)

export default FilesList