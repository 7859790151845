import React from 'react'

export const ProtectionEquip = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="protectionEquip" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <g>
        <path className="st0" d="M25.3,30.7c-0.5,0.2-0.8,0.7-0.7,1.3L32,75.5c0.1,0.3,0.2,0.6,0.5,0.8l23.6,16c0.2,0.1,0.4,0.2,0.6,0.2
          c0.2,0,0.4-0.1,0.6-0.2l23.6-16c0.3-0.2,0.4-0.4,0.5-0.7L88.8,32c0.1-0.5-0.2-1.1-0.7-1.3l-31-11c-0.2-0.1-0.5-0.1-0.8,0L25.3,30.7
          z M27,32.5L56.7,22l29.7,10.5l-7.2,42.1L56.7,89.9L34.2,74.6L27,32.5z"/>
        <path className="st0" d="M93.4,27.1L57.1,14.2c-0.2-0.1-0.5-0.1-0.8,0L19.9,27.1c-0.5,0.2-0.8,0.7-0.7,1.3l8.7,51.1c0.1,0.3,0.2,0.6,0.5,0.7
          L56.1,99c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.5-0.1,0.6-0.2L85,80.2c0.3-0.2,0.4-0.4,0.5-0.7l8.7-51.1C94.3,27.8,94,27.3,93.4,27.1z
           M91.8,28.9l-8.5,49.7L56.7,96.7L30.1,78.6l-8.5-49.7l35.1-12.4L91.8,28.9z"/>
        <path className="st0" d="M44.2,72.3h24.9c0.6,0,1.1-0.5,1.1-1.1v-7.2c0-5.7-3.5-10.7-8.8-12.7c2.5-1.6,4-4.4,4-7.4c0-4.9-4-8.8-8.8-8.8
          s-8.8,4-8.8,8.8c0,3,1.5,5.8,4,7.4c-5.3,2-8.8,7.1-8.8,12.7v7.2C43.1,71.8,43.6,72.3,44.2,72.3z M56.7,52.6
          c6.2,0,11.3,5.1,11.3,11.3V70H45.4v-6.1C45.4,57.6,50.5,52.6,56.7,52.6z M56.7,37.2c3.6,0,6.5,2.9,6.5,6.5c0,3.6-2.9,6.5-6.5,6.5
          c-3.6,0-6.5-2.9-6.5-6.5C50.2,40.2,53.1,37.2,56.7,37.2z"/>
        <path className="st0" d="M73.8,33.4l7.8,2.8l-1,5.8c-0.1,0.3,0,0.6,0.2,0.8c0.2,0.2,0.4,0.4,0.7,0.5c0.1,0,0.1,0,0.2,0c0.6,0,1-0.4,1.1-0.9l1.1-6.7
          c0.1-0.5-0.2-1.1-0.7-1.3l-8.8-3.1c-0.6-0.2-1.2,0.1-1.5,0.7c-0.1,0.3-0.1,0.6,0,0.9C73.3,33.1,73.6,33.3,73.8,33.4z"/>
        <path className="st0" d="M63.4,29.7l5.8,2.1c0.1,0,0.3,0.1,0.4,0.1c0.5,0,0.9-0.3,1.1-0.8c0.1-0.3,0.1-0.6,0-0.9c-0.1-0.3-0.4-0.5-0.6-0.6l-5.8-2.1
          c-0.6-0.2-1.2,0.1-1.5,0.7c-0.1,0.3-0.1,0.6,0,0.9C62.9,29.3,63.2,29.6,63.4,29.7z"/>
        <path className="st0" d="M80.5,49.5L80.5,49.5c-0.3,0-0.6,0-0.8,0.2c-0.3,0.2-0.4,0.4-0.5,0.7l-1.8,10.8c-0.1,0.3,0,0.6,0.2,0.8
          c0.2,0.2,0.4,0.4,0.7,0.5c0.1,0,0.1,0,0.2,0c0.6,0,1-0.4,1.1-0.9l1.8-10.8C81.6,50.2,81.2,49.7,80.5,49.5z"/>
      </g>
    </svg>
  )
}
