import { useEffect, useMemo, useState } from "react";
import { throttle } from 'throttle-debounce'

const useMousePosition = (ref) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const trigerLazyLoadDebounced = useMemo(
        () => throttle(50, (e) => {
            var bounds = e.target.getBoundingClientRect();
            var newX = parseInt(e.clientX - bounds.left)
            var newY = parseInt(e.clientY - bounds.top)
            setPosition({ x: newX, y: newY });
        }), [])

    useEffect(() => {
        ref.current.addEventListener("mousemove", trigerLazyLoadDebounced);
    }, [ref, trigerLazyLoadDebounced]);

    return position;
};
export default useMousePosition;
