import React, { useState, useCallback, memo, useMemo } from 'react'
import styled from 'styled-components'
import CostCenterTree from '../../../CostCenter/CostCenterTree'
import { StyledCollapseTrigger } from '../formHelper'
import { Collapse, Spinner } from 'react-bootstrap'
import { ArrowBigIcon } from '../../../Icons/ArrowBigIcon'
import { colors } from '../../../../styles/colors'
import { useObserver } from 'mobx-react'
import useStores from '../../../../stores/useStore'
import { t } from 'i18next'
import { emptyGuid } from '../../../../utils/emptyGuid'


const StyledCostCenterTree = styled.div`
  padding:1rem;
  background: ${colors.gray1};
  margin-bottom:1rem;
  height:400px;
  overflow:auto;
  border: 1px solid ${colors.gray3};
  width:100%;
  @media screen and (min-width: 1080px) {
    max-width: calc(100% - 480px);
    min-width: 600px;
  }
  .checkBox {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;  
 }
`

const CostCenterMultiselect = memo(({
    collapsible,
    mainData,
    accountData,
    setAccountDataByKey
}) => {
    const { costCenterStore } = useStores()
    const [opened, setOpened] = useState(!collapsible)
    const partialCostCenters = useObserver(() => costCenterStore.partialCostCenters)
    const allCostCentersLoaded = useObserver(() => costCenterStore.allLoaded)

    const accessibleCostcenters = accountData.accessibleCostcenters

    const mainCostCenterId = mainData.costCenter.id

    const handleClickCollapse = useCallback(() => {
        setOpened(prev => !prev)
    }, [setOpened])

    const handleChange = costCenter => e => {
        const childIds = getAllChildIds(costCenter)
        var newValues = [...accessibleCostcenters]
        if (e.target.checked) {
            for (const id of childIds) {
                if (!accessibleCostcenters.includes(id)) {
                    newValues.push(id)
                }
            }
        } else {
            for (const id of childIds) {
                let foundIndex = newValues.indexOf(id);
                if (foundIndex > -1) {
                    newValues.splice(foundIndex, 1)
                }
            }
        }
        setAccountDataByKey("accessibleCostcenters", newValues)
    }

    const getAllChildIds = (costCenter) => {
        const ids = [costCenter.id]
        if (costCenter.hasChildren) {
            const children = getChildCostCenters(costCenter)
            for (const child of children) {
                ids.push(...getAllChildIds(child))
            }
        }
        return ids
    }

    const getChildCostCenters = (parentCostCenter) => {
        if (parentCostCenter.id === emptyGuid) { return partialCostCenters.filter(costCenter => !costCenter.parentCostCenter) }
        return partialCostCenters.filter(costCenter => costCenter.parentCostCenter && costCenter.parentCostCenter.id === parentCostCenter.id)
    }
    const getCostCenterById = useCallback((costCenterId) => {
        return partialCostCenters.find(costCenter => costCenter.id === costCenterId)
    }, [partialCostCenters])

    const getParentCostCenter = useCallback((childCostCenter) => {
        return partialCostCenters.find(costCenter => childCostCenter.parentCostCenter && childCostCenter.parentCostCenter.id === costCenter.id);
    }, [partialCostCenters])

    const getAllParentIds = useCallback((costCenter, allParents) => {
        const ids = [];
        if (costCenter && costCenter.parentCostCenter) {
            const parent = getParentCostCenter(costCenter);

            if (!allParents.includes(parent.id)) {
                ids.push(costCenter.parentCostCenter.id, ...getAllParentIds(parent, allParents));
            }
        }
        return ids;
    }, [getParentCostCenter])

    const parentFolders = useMemo(() => {
        let allParents = []
        if (allCostCentersLoaded && partialCostCenters.length < 2000) {
            for (let costCenterId of accessibleCostcenters) {
                let costCenter = getCostCenterById(costCenterId)
                let newParents = getAllParentIds(costCenter, allParents)
                allParents.push(...newParents)
            }
            if (mainCostCenterId !== emptyGuid) {
                let costCenter = getCostCenterById(mainCostCenterId)
                allParents.push(getAllParentIds(costCenter, allParents))
            }
            return allParents
        }
        return allParents
    }, [allCostCentersLoaded, partialCostCenters.length, mainCostCenterId, accessibleCostcenters, getCostCenterById, getAllParentIds])

    const renderExtraItemContent = (costCenter) => {
        let isMainCostCenter = costCenter.id === mainCostCenterId
        return (
            <div className="checkBox">
                <input checked={accessibleCostcenters.includes(costCenter.id) || isMainCostCenter} onChange={handleChange(costCenter)} disabled={isMainCostCenter} className="me-4" type="checkbox" />
            </div>
        )
    }
    return (
        <>
            {collapsible ? (
                <StyledCollapseTrigger onClick={handleClickCollapse}>
                    <h2 className="h1"><ArrowBigIcon variant={opened ? 'down' : 'right'} color={colors.textColor} />{t('administration:costcenteradministration')}
                    </h2>
                </StyledCollapseTrigger>
            ) : (
                <h2 className="h1">{t('administration:costcenteradministration')}</h2>
            )}
            <Collapse in={opened}>

                <div className="spacing-down">
                    {!allCostCentersLoaded
                        ? <div className="d-flex justify-content-center p-3"><Spinner animation='border' variant='primary' /></div>
                        : <>
                            <StyledCostCenterTree><CostCenterTree
                                showTopLevel
                                renderExtraItemContent={renderExtraItemContent}
                                parentsWithSelectedChildren={parentFolders}
                            /></StyledCostCenterTree>
                        </>}
                </div>
            </Collapse>
        </>
    )
})

export default CostCenterMultiselect