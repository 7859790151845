import { action, observable, makeObservable } from 'mobx';
import apiClient from '../utils/api/apiClient';
import { APIEndpoints } from '../utils/api/apiConfig';
import { getErrorMessageFromApiResponse } from '../utils/api/apiUtils';
import { emptyGuid, isEmptyGuid } from '../utils/emptyGuid';
import { addConfigPrices, formatedPriceObject } from '../utils/formatedPrice';
import { addToBasketBuildConfigurations, equalConfigsCheck, equalPhaseCheck, equalLocalStorehouseCheck } from '../utils/shoppingBasketHelper'
import axios from 'axios'

const CancelToken = axios.CancelToken;
let cancel;

class ProductListStore {
  constructor(appStore) {
    makeObservable(this, {
      productObj: observable,
      addToBasketExtremeIsLoading: observable,
      addToBasketExtremeErrorOccurred: observable,
      initProductObject: action,
      cancelCalls: action,
      loadOrderableVariants: action,
      setSelectedVariant: action,
      setSelectedLocalStorehouse:action,
      setSelectedQuantity:action,
      loadAllVariantsQuantities: action,
      updateSingleVariantQuantity: action,
      loadArticlePrices: action,
      refreshPrice: action,
      loadLocalStorehouses: action,
      loadArticleConfiguration: action,
      initCustomConfigurations: action,
      handleAddConfiguration: action,
      handleRemoveConfiguration: action,
      handleAddToBasketErrors: action
    });

    this.app = appStore
  }
  cancelToken = new CancelToken(function executor(c) {
    cancel = c;
  })
  bubbleVariantBreakAmount = 10
  productObj = {};
  addToBasketExtremeIsLoading = false;
  addToBasketExtremeErrorOccurred = false;

  initProductObject = (shoppingBasketArticle) => {
    const minQuantity = this.app.productsStore.getMinQuantity(shoppingBasketArticle.articleAvailability.amountToOrder, shoppingBasketArticle.article.packageSize, shoppingBasketArticle.article.minimumQuantity)
    const selectedQuantity = this.app.productsStore.getSelectedQuantity(shoppingBasketArticle.articleAvailability.amountToOrder, shoppingBasketArticle.article.packageSize, shoppingBasketArticle.article.minimumQuantity, shoppingBasketArticle.recommendedAmount)
    this.productObj[shoppingBasketArticle.id] = {
      articleId: shoppingBasketArticle.article.id,
      selectedQuantity: selectedQuantity,
      minimumQuantity: minQuantity,
      maximumQuantity: shoppingBasketArticle.article.maximumQuantity,
      recommendedAmount: shoppingBasketArticle.recommendedAmount,
      packageSize: shoppingBasketArticle.article.packageSize || 1,
      customConfigurations: {},
      selectedConfigurationsErrors: {},
      addToBasketErrors: [],
      configPrices: {},
      addToBasketSuccess: false,
      selectedVariantId: null,
      selectedLocalStorehouseId : null,
      addToBasketConfigurations: null,
      addToBasketIsLoading: false,
      article: null,
      variantQuantities: null,
      localStorehouses: null,
      articlePrices: null,
      price: '',
      variants: null,
      lastSelectedVariant: shoppingBasketArticle.lastSelectedVariant,
      pricePerItem: shoppingBasketArticle.pricePerItem,
      articleAvailability: shoppingBasketArticle.articleAvailability,
      contingentId: shoppingBasketArticle.contingentAvailability ? shoppingBasketArticle.contingentAvailability.id : null,
      shoppingBasketArticle
    }
  };
  cancelCalls() {
    //cancel previous calls that are still pending
    cancel && cancel()
  }
  resetProductList() {
    this.cancelCalls()
    this.cancelToken = new CancelToken(function executor(c) {
      cancel = c;
    })
    this.productObj = {}
    this.addToBasketExtremeErrorOccurred = false
    this.addToBasketExtremeIsLoading = false
  }

  updateQuantity(shoppingBasketArticleId, amountToOrder) {
    const currentProductObj = this.productObj[shoppingBasketArticleId]
    if (currentProductObj) {
      const minQuantity = this.app.productsStore.getMinQuantity(amountToOrder, currentProductObj.packageSize, currentProductObj.minimumQuantity)
      const selectedQuantity = this.app.productsStore.getSelectedQuantity(amountToOrder, currentProductObj.packageSize, currentProductObj.minimumQuantity, currentProductObj.recommendedAmount)
      currentProductObj.selectedQuantity = selectedQuantity
      currentProductObj.minimumQuantity = minQuantity
      currentProductObj.shoppingBasketArticle.articleAvailability.amountToOrder = amountToOrder
    }
  }

  async loadOrderableVariants(shoppingBasketArticleId) {
    const shoppingBasketId = this.app.productsStore.basket.id;
    const currentProductObj = this.productObj[shoppingBasketArticleId]
    if (!currentProductObj) {
      return null
    }

    try {
      const variants = await apiClient.getJson(APIEndpoints.shoppingBasketArticleVariants(shoppingBasketId, currentProductObj.articleId), {}, true, false, true, { cancelToken: this.cancelToken })
      //this.productObj[shoppingBasketArticleId] = {}
      currentProductObj.variants = variants
      this.initVariant(shoppingBasketArticleId);
    } catch (e) { }

  }
  async loadLocalStorehouses(shoppingBasketArticleId) {
    const shoppingBasketId = this.app.productsStore.basket.id;
    const currentProductObj = this.productObj[shoppingBasketArticleId]
    if (!currentProductObj) {
      return null
    }
    const params = {}
    if(currentProductObj.variants)
        params.variantId = currentProductObj.selectedVariantId
    
    let totalQ = parseInt(currentProductObj.selectedQuantity)
    if(!isEmptyGuid(currentProductObj.selectedLocalStorehouseId))
    {
      const oldPositions = this.getArticlePositions(shoppingBasketArticleId)
      const existingPosition = oldPositions.find(position => position.articleVariant.id === (currentProductObj.selectedVariantId ? currentProductObj.selectedVariantId : emptyGuid)
          && equalLocalStorehouseCheck(position.localStorehouse, currentProductObj.selectedLocalStorehouseId)
      )
      if(existingPosition)
          totalQ += existingPosition.quantity;
    }

    if(totalQ && totalQ>0)
        params.amount = totalQ

    currentProductObj.localStorehouses = await apiClient.getJson(APIEndpoints.localStorehouses(shoppingBasketId, currentProductObj.articleId), params)
    if(!currentProductObj.localStorehouses.find(sth => sth.id === currentProductObj.selectedLocalStorehouseId))
      this.setSelectedLocalStorehouse(null, shoppingBasketArticleId)
}
  initVariant(shoppingBasketArticleId) {
    // come back to this later
    const currentProductObj = this.productObj[shoppingBasketArticleId]
    if (currentProductObj && currentProductObj.lastSelectedVariant) {
      const variant = currentProductObj.variants.find(variant => variant.id === currentProductObj.lastSelectedVariant.id)

      if (variant) {
        currentProductObj.selectedVariantId = variant.id
        if (variant.separatedDisplayName.length > 1) {
          currentProductObj.selectedFirstPartVariant = variant.separatedDisplayName[0]
          currentProductObj.selectedSecondPartVariant = variant.id
        } else {
          currentProductObj.selectedFirstPartVariant = variant.id
          currentProductObj.selectedSecondPartVariant = null
        }
        this.setSelectedVariant(variant.id, shoppingBasketArticleId)
      }
    }
  }
  async setSelectedLocalStorehouse(localStorehouseId, shoppingBasketArticleId) {
    const currentProductObj = this.productObj[shoppingBasketArticleId]
    currentProductObj.selectedLocalStorehouseId = localStorehouseId
  }

  async setSelectedVariant(variantId, shoppingBasketArticleId) {
    const currentProductObj = this.productObj[shoppingBasketArticleId]
    const reloadConfiguration = currentProductObj.selectedVariantId !== variantId
    currentProductObj.selectedVariantId = variantId

    if (reloadConfiguration) {
      this.updateSingleVariantQuantity(shoppingBasketArticleId, variantId)
      this.loadArticleConfiguration(shoppingBasketArticleId)
    }
    this.refreshPrice(shoppingBasketArticleId)
    await this.loadLocalStorehouses(shoppingBasketArticleId)
  }

  async loadAllVariantsQuantities(shoppingBasketArticleId) {
    const currentProductObj = this.productObj[shoppingBasketArticleId]

    if (!currentProductObj) {
      return
    }
    if (currentProductObj.variants && currentProductObj.variants.length && currentProductObj.variants.length <= this.bubbleVariantBreakAmount) {
      currentProductObj.variants.forEach(variant => {
        this.updateSingleVariantQuantity(shoppingBasketArticleId, variant.id)
      })
    }
    else {
      this.updateSingleVariantQuantity(shoppingBasketArticleId)
    }
  }


  async updateSingleVariantQuantity(shoppingBasketArticleId, variantId) {
    const currentProductObj = this.productObj[shoppingBasketArticleId]
    const shoppingBasketId = this.app.productsStore.basket.id;

    if (!shoppingBasketId || !currentProductObj) {
      return
    }

    if (currentProductObj.variants && currentProductObj.variants.length && currentProductObj.variants.length <= this.bubbleVariantBreakAmount) {
      //bubbleVariants
      try {
        let result = await apiClient.getJson(APIEndpoints.variantQuantities(shoppingBasketId, currentProductObj.articleId), { variantId }, true, false, true, { cancelToken: this.cancelToken })
        currentProductObj.variants.find(v => v.id === variantId).variantStatus = result.status;
      }
      catch (e) { }

    }
    else {
      // for QuantityStatus
      try {
        let result = await apiClient.getJson(APIEndpoints.variantQuantities(shoppingBasketId, currentProductObj.articleId), { variantId }, true, false, true, { cancelToken: this.cancelToken })
        currentProductObj.variantQuantities = result
      }
      catch (e) { }
    }
  }
  handleChangeLocalStorehouse = (e, shoppingBasketArticleId) => {
    this.productObj[shoppingBasketArticleId].selectedSecondPartVariant = null
    const localStorehouse =  this.productObj[shoppingBasketArticleId].localStorehouses.find(sth => sth.id === e.target.value)
    if (localStorehouse) {
        this.setSelectedLocalStorehouse(e.target.value, shoppingBasketArticleId)
    } else {
        this.setSelectedLocalStorehouse(null, shoppingBasketArticleId)
    }
    this.productObj[shoppingBasketArticleId].selectedLocalStorehouseId = e.target.value
  }

  handleChangeFirstPartVariant = (e, shoppingBasketArticleId) => {
    this.productObj[shoppingBasketArticleId].selectedSecondPartVariant = null

    const variant = this.productObj[shoppingBasketArticleId].variants.find(variant => variant.id === e.target.value)
    if (variant) {
      this.setSelectedVariant(e.target.value, shoppingBasketArticleId)
    } else {
      this.setSelectedVariant(null, shoppingBasketArticleId)
    }
    this.productObj[shoppingBasketArticleId].selectedFirstPartVariant = e.target.value
  }

  handleChangeSecondPartVariant = (e, shoppingBasketArticleId) => {
    this.setSelectedVariant(e.target.value, shoppingBasketArticleId)
    this.productObj[shoppingBasketArticleId].selectedSecondPartVariant = e.target.value
  }

  handleChangeQuantity = (e, shoppingBasketArticleId) => {
    const quantity = e.target.value
    //this.productObj[shoppingBasketArticleId].selectedQuantity = quantity ? quantity : 0
    this.setSelectedQuantity(quantity, shoppingBasketArticleId)
  }
  async setSelectedQuantity(quantity, shoppingBasketArticleId) {
    const currentProductObj = this.productObj[shoppingBasketArticleId]
    currentProductObj.selectedQuantity = quantity ? quantity : 0
    await this.loadLocalStorehouses(shoppingBasketArticleId)
  }

  async loadArticlePrices(shoppingBasketArticleId) {
    //const shoppingBasketId = await this.getShoppingBasketId()
    const shoppingBasketId = this.app.productsStore.basket.id;
    const currentProductObj = this.productObj[shoppingBasketArticleId]
    if (!currentProductObj || !this.app.productsStore.hidePricesAndPoints) {
      return
    }
    currentProductObj.price = ''
    if (currentProductObj && currentProductObj.pricePerItem !== null && currentProductObj.pricePerItem.points === null) {
      try {
        const prices = await apiClient.getJson(APIEndpoints.prices(shoppingBasketId, currentProductObj.articleId), {}, true, false, true, { cancelToken: this.cancelToken })
        currentProductObj.articlePrices = prices
        this.refreshPrice(shoppingBasketArticleId)

      } catch (e) { }
    }
  }

  refreshPrice = (shoppingBasketArticleId) => {

    const currentProductObj = this.productObj[shoppingBasketArticleId]
    if (!currentProductObj) {
      return
    }
    const variantPriceObject = currentProductObj.selectedVariantId ? this.getPriceObjectByVariantId(currentProductObj.selectedVariantId, shoppingBasketArticleId) : null
    const shoppingBasketArticlePricePerItem = currentProductObj.pricePerItem || {}
    const priceObject = currentProductObj.articlePrices === null ?
      shoppingBasketArticlePricePerItem :
      (variantPriceObject ? variantPriceObject : shoppingBasketArticlePricePerItem)
    const configPrices = Object.values(currentProductObj.configPrices)
    //add configuration Prices or Points
    currentProductObj.price = formatedPriceObject(addConfigPrices(priceObject, configPrices), false)

  };

  getPriceObjectByVariantId = (variantId, shoppingBasketArticleId) => {
    const currentProductObj = this.productObj[shoppingBasketArticleId]
    if (!currentProductObj || !currentProductObj.articlePrices || !currentProductObj.articlePrices.length) {
      return
    }
    return (currentProductObj.articlePrices.find(priceObject => priceObject.articleVariant.id === variantId) || { pricePerItem: null }).pricePerItem
  }

  async loadArticleConfiguration(shoppingBasketArticleId) {
    const currentProductObj = this.productObj[shoppingBasketArticleId]
    const shoppingBasketId = this.app.productsStore.basket.id;
    if (!currentProductObj) {
      return
    }

    try {
      const articleConfiguration = await apiClient.getJson(APIEndpoints.shoppingBasketArticleConfigurations(shoppingBasketId, currentProductObj.articleId), {
        variantId: currentProductObj.selectedVariantId
      }, true, false, true, { cancelToken: this.cancelToken })
      currentProductObj.articleConfiguration = articleConfiguration
      this.initCustomConfigurations(shoppingBasketArticleId)
    }
    catch (e) { }
  }

  initCustomConfigurations(shoppingBasketArticleId) {
    const currentProductObj = this.productObj[shoppingBasketArticleId]

    currentProductObj.articleConfiguration.forEach(config => {
      const article = config.selectableArticles.find(article => article.id === config.lastSelectedArticleID)
      if (!currentProductObj.customConfigurations[config.id]) {
        if (article) {
          currentProductObj.customConfigurations[config.id] = {
            comment: '',
            article: {},
          }
          this.handleAddConfiguration(config.id, article, shoppingBasketArticleId)
          this.handleAddConfigurationComment(config.id, config.lastComment, shoppingBasketArticleId)
        }
      }
    })
  }

  handleAddConfiguration = async (configId, article, shoppingBasketArticleId) => {
    const currentProductObj = this.productObj[shoppingBasketArticleId]
    delete currentProductObj.selectedConfigurationsErrors[configId]
    currentProductObj.addToBasketErrors = currentProductObj.addToBasketErrors.filter(error => (!error.configId || error.configId !== configId))
    //const shoppingBasketId = await this.getShoppingBasketId()
    const shoppingBasketId = this.app.productsStore.basket.id;
    //handle change configuration price
    const configPrice = await apiClient.getJson(APIEndpoints.prices(shoppingBasketId, article.id))
    if (configPrice && configPrice.length) {
      currentProductObj.configPrices[configId] = configPrice[0].pricePerItem
    }
    currentProductObj.customConfigurations[configId].article = article
    //close dropdown, edge needs the timeout befor triggering the click
    /* setTimeout(() => document.querySelector('body').click(), 50) */
    this.refreshPrice(shoppingBasketArticleId)   // come back to this later
  };

  handleAddConfigurationComment = (configId, comment, shoppingBasketArticleId) => {
    const currentProductObj = this.productObj[shoppingBasketArticleId]
    delete currentProductObj.selectedConfigurationsErrors[configId]
    currentProductObj.addToBasketErrors = currentProductObj.addToBasketErrors.filter(error => (!error.configId || error.configId !== configId))
    currentProductObj.customConfigurations[configId].comment = comment
  }

  handleRemoveConfiguration = (configId, shoppingBasketArticleId) => {
    const currentProductObj = this.productObj[shoppingBasketArticleId]
    const config = currentProductObj.articleConfiguration.find(config => config.id === configId)
    const emptyGuidArticle = config.selectableArticles.find(art => art.id === emptyGuid)

    if (emptyGuidArticle) {
      currentProductObj.customConfigurations[configId].article = emptyGuidArticle
    }
    else {
      delete currentProductObj.customConfigurations[configId]
    }

    delete currentProductObj.selectedConfigurationsErrors[configId]
    delete currentProductObj.configPrices[configId]

    this.refreshPrice(shoppingBasketArticleId) // come back to this later ivan
  };

  async addBasketArticle(shoppingBasketArticleId) {
    const { basket } = this.app.productsStore
    const params = this.addToBasketBuildPositions(shoppingBasketArticleId)
    if (!Object.keys(params).length) {
      return
    }
    return await apiClient.patchJson(APIEndpoints.shoppingBasketArticle(basket.id, shoppingBasketArticleId), params)
  }


  handleAddToBasket = async (shoppingBasketArticleId) => {
    const { basket } = this.app.productsStore
    const currentProductObj = this.productObj[shoppingBasketArticleId]

    currentProductObj.addToBasketSuccess = false
    currentProductObj.selectedConfigurationsErrors = {}
    currentProductObj.addToBasketErrors = []
    currentProductObj.addToBasketIsLoading = true

    try {
      const addedShoppingArticle = await this.addBasketArticle(shoppingBasketArticleId)
      //update categoryAvailability
      if (!this.app.teamOrderStore.selectedCostcenterId && !this.app.teamOrderStore.privateOrder) {
        this.app.productsStore.updateCategoryQuantity(addedShoppingArticle.categoryAvailability)
      }
      this.app.productsStore.loadBasket(basket.id)
      //update articleAvailability
      this.updateQuantity(shoppingBasketArticleId, addedShoppingArticle.articleAvailability.amountToOrder)

      //update variant availabillities
      this.updateSingleVariantQuantity(shoppingBasketArticleId, currentProductObj.selectedVariantId)
      //update baskets in header
      this.app.uiStore.fetchNewShoppingCarts()

      currentProductObj.addToBasketSuccess = true
      setTimeout(() => {
        currentProductObj.addToBasketSuccess = false
      }, 5000)

    } catch (error) {
      this.handleAddToBasketErrors(error, shoppingBasketArticleId)
    }
    currentProductObj.addToBasketIsLoading = false
  }


  //only for employee Order and sixt and if productsStore.useEntitlementSelection
  async handleAddToBasketExtreme(navigate) {
    const { basket, useEntitlementSelection, selectedContingentId } = this.app.productsStore

    this.addToBasketExtremeErrorOccurred = false
    this.addToBasketExtremeIsLoading = true
    let promises = []

    for (const shoppingBasketArticleId of Object.keys(this.productObj)) {
      const currentProductObj = this.productObj[shoppingBasketArticleId]
      currentProductObj.addToBasketErrors = []

      if (!currentProductObj.selectedQuantity || currentProductObj.selectedQuantity === "0") {
        continue
      }
      if (useEntitlementSelection && currentProductObj.contingentId !== selectedContingentId) {
        continue
      }
      currentProductObj.selectedConfigurationsErrors = {}
      try {
        const addedShoppingArticle = await this.addBasketArticle(shoppingBasketArticleId)
        promises.push(addedShoppingArticle)
        this.updateQuantity(shoppingBasketArticleId, addedShoppingArticle.articleAvailability.amountToOrder)
        this.app.productsStore.updateCategoryQuantity(addedShoppingArticle.categoryAvailability)
        //update variant availabillities
        this.updateSingleVariantQuantity(shoppingBasketArticleId, currentProductObj.selectedVariantId)
      } catch (error) {
        this.addToBasketExtremeErrorOccurred = true
        this.handleAddToBasketErrors(error, shoppingBasketArticleId)
      }
    }
    //--------------------
    Promise.all(promises).then(() => {
      this.addToBasketExtremeIsLoading = false
      this.app.productsStore.loadBasket(basket.id)
      this.app.uiStore.fetchNewShoppingCarts()
      this.addToBasketExtremeIsLoading = false
      if (!this.addToBasketExtremeErrorOccurred) {
        navigate(this.app.uiStore.linkToBasket)
      }
    })
  }


  getArticlePositions(shoppingBasketArticleId) {
    const { basket } = this.app.productsStore
    return (basket.articles.find(shoppingBasketArticle => shoppingBasketArticle.id === shoppingBasketArticleId) || { positions: [] }).positions
  }

  addToBasketBuildPositions = (shoppingBasketArticleId) => {
    const { orderInfo } = this.app.productsStore
    const currentProductObj = this.productObj[shoppingBasketArticleId]

    currentProductObj.addToBasketConfigurations = addToBasketBuildConfigurations(currentProductObj.customConfigurations)

    let newPositions = []
    let changedPositions = []

    // if for personal / private orders? do we need to make the difference for personal/private/costcenter order now with the list view or no? come back to this!!

    const existingPosition = this.getArticlePositions(shoppingBasketArticleId)
      .find(position => position.articleVariant.id === (currentProductObj.selectedVariantId ? currentProductObj.selectedVariantId : emptyGuid)
        && equalLocalStorehouseCheck(position.localStorehouse, currentProductObj.selectedLocalStorehouseId)
        && equalConfigsCheck(currentProductObj.addToBasketConfigurations, position.configurations)
        && equalPhaseCheck(orderInfo.phase, position.phase)
      )
    if (existingPosition) {
      changedPositions.push(
        {
          id: existingPosition.id,
          quantity: existingPosition.quantity + parseInt(currentProductObj.selectedQuantity),
          articleVariant: { id: existingPosition.articleVariant.id },
          localStorehouse: existingPosition.localStorehouse ? { id: existingPosition.localStorehouse.id } : {},
        }
      )
    }
    else {
      newPositions.push(
        {
          quantity: currentProductObj.selectedQuantity,
          articleVariant: currentProductObj.selectedVariantId ? currentProductObj.variants.find(variant => variant.id === currentProductObj.selectedVariantId) : {},
          configurations: currentProductObj.addToBasketConfigurations,
          comment: null,
          localStorehouse: currentProductObj.selectedLocalStorehouseId ? currentProductObj.localStorehouses.find(sth => sth.id === currentProductObj.selectedLocalStorehouseId) : {},
        }
      )
    }
    var addToBasketPositions = {}
    if (newPositions.length) {
      addToBasketPositions.newPositions = newPositions
    }

    if (changedPositions.length) {
      addToBasketPositions.changedPositions = changedPositions
    }
    return addToBasketPositions
  }



  handleAddToBasketErrors = (e, shoppingBasketArticleId) => {
    const currentProductObj = this.productObj[shoppingBasketArticleId]

    // api errors
    if (e.response && e.response.data && e.response.data.errors) {
      e.response.data.errors.forEach(error => {
        let myError = {}
        myError.msg = error.detail
        if (error.source && error.source.pointer) {
          //configurations errors
          const configurationErrorMatches = error.source.pointer.match(/^\/positions\/\d+\/configurations\/(\d+)\/([^/]+)/)
          if (configurationErrorMatches && configurationErrorMatches.length) {
            const configId = currentProductObj.addToBasketConfigurations[configurationErrorMatches[1]].articleConfigurationId
            myError.configId = configId
            if (!currentProductObj.selectedConfigurationsErrors[configId]) {
              currentProductObj.selectedConfigurationsErrors[configId] = {}
            }
            currentProductObj.selectedConfigurationsErrors[configId][configurationErrorMatches[2]] = true
          }
        }
        currentProductObj.addToBasketErrors.push(myError)
      })
    } else {
      currentProductObj.addToBasketErrors.push({ msg: getErrorMessageFromApiResponse(e) })
    }
  };

}
export default ProductListStore