import { useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend';


export const useDNDTree = (componentItem, moveCardHandler, canDrop, forbidDrag) => {
  const [{ isOver, item }, drop] = useDrop({
    accept: "treeItem",
    drop(item) {
      if (!drop) {
        return;
      }
      const dragIndex = item.index;
      const dropIndex = componentItem.index;

      // Don't replace items with themselves
      let groupsWithOtherParents = item.type === "group" && componentItem.type === "group" && item.entitlementId !== componentItem.entitlementId
      if (dragIndex === dropIndex || groupsWithOtherParents) {
        return;
      }

      // Time to actually perform the action
      moveCardHandler(item, componentItem)
    },
    canDrop: (item) => canDrop(item),
    collect: monitor => ({
      isOver: monitor.isOver(),
      item: monitor.getItem()
    }),
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "treeItem",
    item: componentItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: () => !forbidDrag
  });

  //hide dragged
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])
  return { isOver, isDragging, drag, drop, item };
}