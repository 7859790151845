import UiStore from './UiStore'
import LoginStore from './LoginStore'
import registrationStore from './RegistrationStore'
import ProfileStore from './ProfileStore'
import ProductsStore from './ProductsStore'
import CheckoutStore from './CheckoutStore'
import TeamOrderStore from './TeamOrderStore'
import OrdersStore from './OrdersStore'
import OrderStore from './OrderStore'
import OrderDialogStore from './OrderDialogStore'
import CwsStore from './CwsStore'
import InventoryStore from './InventoryStore'
import ProductDetailsStore from './ProductDetailsStore'
import ProductsCompareStore from './ProductsCompareStore'
import OfficeStore from './OfficeStore'
import OverlayRightStore from './OverlayRightStore'
import ProductListStore from './ProductListStore'
import LaundryShelfStore from './LaundryShelfStore'
import CostCenterStore from './CostCenterStore'

class RootStore {
  constructor() {
    this.uiStore = new UiStore(this)
    this.loginStore = new LoginStore(this)
    this.registrationStore = new registrationStore(this)
    this.profileStore = new ProfileStore(this)
    this.productsStore = new ProductsStore(this)
    this.checkoutStore = new CheckoutStore(this)
    this.teamOrderStore = new TeamOrderStore(this)
    this.ordersStore = new OrdersStore(this)
    this.orderStore = new OrderStore(this)
    this.orderDialogStore = new OrderDialogStore(this)
    this.cwsStore = new CwsStore(this)
    this.inventoryStore = new InventoryStore(this)
    this.productDetailsStore = new ProductDetailsStore(this)
    this.productsCompareStore = new ProductsCompareStore(this)
    this.officeStore = new OfficeStore(this)
    this.costCenterStore = new CostCenterStore(this)
    this.overlayRightStore = new OverlayRightStore(this)
    this.productListStore = new ProductListStore(this)
    this.laundryShelfStore = new LaundryShelfStore(this)
  }
}

export const appStore = new RootStore()
