import { inject, observer } from 'mobx-react'
import React from 'react'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import ReturnDialog from '../OrderActions/ReturnDialog/ReturnDialog'
import useMountEffect from '../../utils/hooks/useMountEffect'

const ConvertToReturn = inject('orderDialogStore')(observer(({ orderDialogStore, reloadComplaints, item, setOpenConvertToReturnDialog, openConvertToReturnDialogIsLoading, setOpenConvertToReturnDialogIsLoading }) => {

  useMountEffect(() => {
    initData()
  })

  const initData = async () => {
    setOpenConvertToReturnDialogIsLoading(true)
    //orderDialogStore.loadComplaintsContactData(item.targetOrder.id),
    const p0 = orderDialogStore.loadComplaintsContactData(item.targetOrder.id)
    const p1 = orderDialogStore.loadReturnReasons()
    const p2 = orderDialogStore.loadCountries()
    const p3 = loadPositions()
    //wait with opening the Modal until all Data is Loaded
    Promise.all([p0, p1, p2, p3]).then(([v0, v1, v2, promisedPositions]) => {
      orderDialogStore.showDialog('retouration', [{ positions: promisedPositions }])
      setOpenConvertToReturnDialogIsLoading(false)
    })
  }
  const loadPositions = async () => {
    let shippingOrders = await apiClient.getJson(APIEndpoints.incomingOrders(item.targetOrder.id).shippingOrders)
    let promises = []
    shippingOrders.forEach(order => promises.push(apiClient.getJson(APIEndpoints.shippingOrderPositions(order.id))))
    let promisedPositions = await Promise.all(promises)
    return promisedPositions.flat()
  }
  if (openConvertToReturnDialogIsLoading) {
    return null
  }

  const submit = (formData) => {
    orderDialogStore.submitConvertToReturn(item.id, formData)
  }
  const cancelFunction = () => {
    setOpenConvertToReturnDialog(false)
    if (orderDialogStore.success) {
      reloadComplaints()
    }
    orderDialogStore.resetData()
  }

  return (
    <ReturnDialog
      products={orderDialogStore.groupedOrderPositions}
      onSubmit={submit}
      onCancel={() => cancelFunction()}
      loading={orderDialogStore.loading}
      success={orderDialogStore.success}
      issueId={orderDialogStore.retourResponse.feedbackNumber}
      issueEmail={orderDialogStore.retourResponse.reportEmail}
      emailWasSent={orderDialogStore.retourResponse.emailWasSent}
      feedbackId={orderDialogStore.retourResponse.id}
      returnsPortalUrl={orderDialogStore.retourResponse.returnsPortalUrl}
      contactData={orderDialogStore.contactData}
      returnReasons={orderDialogStore.returnReasons}
      countries={orderDialogStore.countries}
      orderDialogError={orderDialogStore.error}
      hideReorderOption={true}
    />
  )
}))

export default ConvertToReturn