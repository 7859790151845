import React from 'react'
import CostCenterTree from '../CostCenter/CostCenterTree'
import { useEmployeesState } from '../../stores/EmployeesProvider'

const CostCenterFilter = () => {
  const { setCostCenterFilter, setCostCenterName } = useEmployeesState()

  const handleClickCostCenterName = costCenter => {
    setCostCenterName(costCenter.displayName)
    setCostCenterFilter(prev => (costCenter.id === prev ? '' : costCenter.id))
  }


  return (
    <CostCenterTree onClick={handleClickCostCenterName} isClickable={() => true} />
  )
}

export default CostCenterFilter
