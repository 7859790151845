import React from 'react'

export const InfoIcon = (props) => {
  const color = props.color ? props.color : '#3a3a37'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28px" viewBox="0 0 28 28">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={color}>
          <g transform="translate(11.500000, 7.500000)">
            <path
              d="M2.5,4 C3.32842712,4 4,4.67157288 4,5.5 L4,12 L4.5,12 C4.77614237,12 5,12.2238576 5,12.5 C5,12.7761424 4.77614237,13 4.5,13 L0.5,13 C0.223857625,13 3.38176876e-17,12.7761424 0,12.5 C-3.38176876e-17,12.2238576 0.223857625,12 0.5,12 L1,12 L1,5.996 L0.94511967,5.99299314 C0.570475907,5.94216834 0.281718057,5.62102859 0.281718057,5.23244583 C0.281718057,5.15357222 0.293875173,5.07516983 0.31776123,5 C0.506989729,4.4044947 1.05994751,4 1.68479468,4 L2.5,4 L2.5,4 Z M2.5,0 C3.32842712,0 4,0.671572875 4,1.5 C4,2.32842712 3.32842712,3 2.5,3 C1.67157288,3 1,2.32842712 1,1.5 C1,0.671572875 1.67157288,0 2.5,0 Z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
