import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import useCabinTypesState from '../../../stores/Configuration/useCabinTypesState'
import OverlayRight from '../../Page/logix2020/OverlayRight'
import OverlayContainer from '../../Page/logix2020/OverlayContainer'
import { useTranslation } from 'react-i18next'
import { StyledDataList } from '../../DataList/dataList'
import { PenIcon } from '../../Icons/PenIcon';
import { Spinner } from 'react-bootstrap'
import { getLanguageValue } from '../../../utils/getLanguageValue'


const CabinDetails = ({ selectedCabinTypeId }) => {
    const { t } = useTranslation()
    const [dataIsLoading, setDataIsLoading] = useState(false)

    const { selectedCabinType, appointmentTypes, assignedAppointmentTypes, loadAssignedAppointmenttypes, loadCabinData } = useCabinTypesState();
    const [appointedAppointmentTypes, setAppointedAppointmentTypes] = useState([]);

    let cabinName = selectedCabinType && getLanguageValue(selectedCabinType.name.values)

    const loadData = useCallback(async () => {
        setDataIsLoading(true)
        const promises = [loadAssignedAppointmenttypes(selectedCabinTypeId), loadCabinData(selectedCabinTypeId)]
        Promise.all(promises).then(() => setDataIsLoading(false))
    }, [loadAssignedAppointmenttypes, loadCabinData, selectedCabinTypeId])

    useEffect(() => {
        if (selectedCabinTypeId) {
            loadData()
        }
    }, [loadData, selectedCabinTypeId])

    useEffect(() => {
        if (appointmentTypes && assignedAppointmentTypes) {
            let newAppointedAppointments = [];
            appointmentTypes.forEach(appointment => {
                if (assignedAppointmentTypes.find(appointmentId => appointmentId === appointment.id)) {
                    newAppointedAppointments.push(appointment)
                }
            })
            setAppointedAppointmentTypes(newAppointedAppointments);
        }
    }, [appointmentTypes, assignedAppointmentTypes])

    if (dataIsLoading) {
        return <div className='d-flex justify-content-center' >
            <Spinner animation='border' variant='primary' />
        </div>
    }
    return (
        <StyledDataList className="content">
            <div className="data-list__name h2">
                {cabinName}
                <Link to={`/cabinTypes/edit/${selectedCabinType.id}#general`} >
                    <PenIcon title={t('edit')} />
                </Link>
            </div>

            <div className="data-list__name h3 mt-4">
                {t('administration:appointmentTypes')}
                <Link to={`/cabinTypes/edit/${selectedCabinType.id}#assignedAppointmentTypes`} >
                    <PenIcon title={t('edit')} />
                </Link>

            </div>
            <ul >
                {appointedAppointmentTypes.map((appointmentType, idx) => {
                    return (
                        <li className="data-list__value" key={`${idx} ${appointmentType.id}`} >
                            {appointmentType.displayName}
                        </li>
                    )
                })}
            </ul>
        </StyledDataList>
    )
}



const OverlayRightCabinTypeInfoInfo = ({ visible, onClose, selectedCabinTypeId }) => {
    const { t } = useTranslation()
    return (
        <OverlayContainer>
            <OverlayRight visible={visible} onClose={onClose} headerTitle={t('details')}>
                {visible && <CabinDetails selectedCabinTypeId={selectedCabinTypeId} />}
            </OverlayRight>
        </OverlayContainer>
    )
}

export default OverlayRightCabinTypeInfoInfo
