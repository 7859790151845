import React from 'react'
import ArraySelect from './ArraySelect'
import { useState } from 'react'
import { useEffect } from 'react'

const options1 = [{ id: "1", displayName: "11" }]


const ArraySelectTests = () => {
  const [selected, setSelected] = useState('')
  const [availableOptions, setAvailableOptions] = useState([])

  useEffect(() => {
    let timeout = null
    timeout = setTimeout(() => {
      setAvailableOptions(options1)
    }, 5000)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <div>
      <ArraySelect
        style={{ width: "200px" }}
        value={selected}
        options={availableOptions}
        onChange={(obj) => setSelected(obj.id)}
        defaultOption={{ value: "0", name: 'pleaseSelect' }}
        defaultOptionIsSelectable={false}
        noAutoSelect={false}
      />
    </div>
  )
}

export default ArraySelectTests