import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { useParams } from 'react-router-dom'
import { emptyGuid } from '../../utils/emptyGuid'
import { useTranslation } from 'react-i18next'

const addressTemplate = {
    id: emptyGuid,
    addressType: '',
    secondaryOfficeName: '',
    useSecondaryOfficeName: false,
    careOf: '',
    isDefaultAddress: false,
    street: '',
    streetNr: '',
    postOfficeBox: '',
    zipCode: '',
    city: '',
    countryIsoCode: 'DE',
    type: {
        displayName: "",
        type: "",
        isDefaultType: false,
        isMultipleSelectable: false,
        id: emptyGuid
    }
}

export const OfficeContext = React.createContext({})

const OfficeProvider = ({ children }) => {
    const { t } = useTranslation()

    const { officeId } = useParams()
    const [availableCountries, setAvailableCountries] = useState([])
    const [availableAddressTypes, setAvailableAddressTypes] = useState([])
    const [officeName, setOfficeName] = useState([])
    const [officeAddresses, setOfficeAddresses] = useState([])
    const [initialOfficeAddresses, setInitialOfficeAddresses] = useState("")
    const [formErrors, setFormErrors] = useState([])
    const [otherErrors, setOtherErrors] = useState([])
    const [success, setSuccess] = useState("")
    const [selectedOffice, setSelectedOffice] = useState([])
    const mountedRef = useRef(true)
    const [dataIsLoading, setDataIsLoading] = useState({ addressesLoading: true, countriesLoading: true, availableAddressTypesLoading: true, detailsLoading: true })
    const [submitIsLoading, setSubmitIsLoading] = useState(false)

    const resetFormErrors = useCallback((officeAddresses) => {
        const newFormErrors = []
        officeAddresses.forEach(() => newFormErrors.push({}))
        setFormErrors(newFormErrors)
    }, [])

    const resetSelectedOffices = useCallback((officeAddresses) => {
        const newSelecteOffices = []
        officeAddresses.forEach(() => newSelecteOffices.push(""))
        setSelectedOffice(newSelecteOffices)
    }, [])


    const reloadData = useCallback(() => {
        //load settings data 
        setDataIsLoading({ addressesLoading: true, countriesLoading: true, availableAddressTypesLoading: true, detailsLoading: true })
        apiClient.getJson(APIEndpoints.offices(officeId).addresses).then(result => {
            if (!mountedRef.current) { return null }
            setOfficeAddresses(result)
            setInitialOfficeAddresses(JSON.stringify(result))
            resetFormErrors(result)
            resetSelectedOffices(result)
            setDataIsLoading(prev => ({ ...prev, addressesLoading: false }))
        })
        apiClient.getJson(APIEndpoints.countries).then(response => {
            if (!mountedRef.current) { return null }
            setAvailableCountries(response)
            setDataIsLoading(prev => ({ ...prev, countriesLoading: false }))
        })

        apiClient.getJson(APIEndpoints.offices(officeId).availableAddressTypes).then(response => {
            if (!mountedRef.current) { return null }
            setAvailableAddressTypes(response)
            setDataIsLoading(prev => ({ ...prev, availableAddressTypesLoading: false }))
        })
        apiClient.getJson(APIEndpoints.offices(officeId).details).then(response => {
            if (!mountedRef.current) { return null }
            setOfficeName(response.name)
            setDataIsLoading(prev => ({ ...prev, detailsLoading: false }))
        })
    }, [officeId, resetFormErrors, resetSelectedOffices])
    //Cleanup Function
    useEffect(() => {
        reloadData()
        return () => { mountedRef.current = false }
    }, [reloadData])






    const getExternOfficeAddress = useCallback((index, officeId) => {
        setSelectedOffice(prev => {
            const newValues = [...prev]
            newValues.splice(index, 1, officeId)
            return newValues
        })
        const addressType = officeAddresses[index].addressType
        var address = {}
        apiClient.getJson(APIEndpoints.offices(officeId).addresses).then(result => {
            if (!mountedRef.current) { return null }
            address = result.find(obj => obj.type.type === addressType && obj.type.isDefaultType)
            if (address) {
                address.id = emptyGuid
                setOfficeAddresses(prev => {
                    const newAddresses = [...prev]
                    newAddresses.splice(index, 1, address)
                    return newAddresses
                })
            }
            else {
                setFormErrors(prev => {
                    const newFormErrors = [...prev]
                    const newFormError = prev[index]
                    newFormError["office"] = "keine Addresse gefunden"
                    newFormErrors.splice(index, 1, newFormError)
                    return newFormErrors
                })
            }
        })
    }, [officeAddresses])



    const dataHasChanged = useMemo(() => {
        if (initialOfficeAddresses) {
            const formDataString = officeAddresses
            return JSON.stringify(formDataString) !== initialOfficeAddresses
        }
    }, [officeAddresses, initialOfficeAddresses])

    const showErrors = (apiError, index) => {
        if (apiError.response) {
            let newOtherErrrors = []
            let newFormError = {}
            const errorsApi = apiError.response.data.errors
            errorsApi.forEach(item => {
                if (item.source && item.source.pointer) {
                    newFormError[item.source.pointer.substr(1)] = item.detail
                }
                else { newOtherErrrors.push(item.detail) }
            })
            setFormErrors(prev => {
                const newFormErrors = [...prev]
                newFormErrors.splice(index, 1, newFormError)
                return newFormErrors
            })
            setOtherErrors(newOtherErrrors)
        }
    }

    const resetFormErrorsByKey = useCallback((formKey, index) => {
        setFormErrors(prev => {
            let row = prev[index]
            if (row && row[formKey]) {
                delete row[formKey]
            }
            return prev
        })
    }, [setFormErrors])

    const deleteAddress = (index) => {
        if (officeAddresses[index].id !== emptyGuid) {
            const addressId = officeAddresses[index].id
            apiClient.deleteJson(APIEndpoints.offices(officeId, addressId).deleteAddress).then(() => {
                if (!mountedRef.current) { return null }
                reloadData()
            })
        }
        setSelectedOffice(prev => {
            const newValues = [...prev]
            newValues.splice(index, 1)
            return newValues
        })
        setFormErrors(prev => {
            const newValues = [...prev]
            newValues.splice(index, 1)
            return newValues
        })
    }

    const cancel = () => {
        let newOfficeAddresses = JSON.parse(initialOfficeAddresses)
        setOfficeAddresses(newOfficeAddresses)
        resetFormErrors(newOfficeAddresses)
        resetSelectedOffices(newOfficeAddresses)
    }

    const handleAddAddress = addressType => {

        const newAddress = JSON.parse(JSON.stringify(addressTemplate))
        newAddress.type = addressType
        newAddress.addressType = addressType.type
        newAddress.isDefaultAddress = addressType.isDefaultType

        setOfficeAddresses(prev => ([...prev, newAddress]))
        setSelectedOffice(prev => ([...prev, ""]))
        setFormErrors(prev => ([...prev, {}]))

    }

    const submit = async () => {
        setSubmitIsLoading(true)
        resetFormErrors(officeAddresses)
        setSuccess("")
        var errors = false
        var initialAddressesObj = JSON.parse(initialOfficeAddresses)
        //id from initialAddresses not in officeAddresses -> delete adress
        for (const [index, obj] of initialAddressesObj.entries()) {
            var isInShippingAddresses = officeAddresses.some(address => address.id === obj.id)
            if (!isInShippingAddresses) {
                try {
                    await apiClient.deleteJson(APIEndpoints.offices(officeId, obj.id).deleteAddress)
                } catch (error) {
                    errors = true
                    showErrors(error, index)
                }
            }
        }
        for (const [index, obj] of officeAddresses.entries()) {
            var initialData = JSON.parse(initialOfficeAddresses)
            var isAddressModified = initialData.every(item => JSON.stringify(item) !== JSON.stringify(obj))
            //no id and modified --> post address
            if (obj.id === emptyGuid && isAddressModified) {
                const preparedAddresses = JSON.stringify(obj)
                try {
                    await apiClient.postJson(
                        APIEndpoints.offices(officeId).addresses,
                        preparedAddresses
                    )
                } catch (error) {
                    errors = true
                    showErrors(error, index)
                }
            }
            //id and modified
            else if (isAddressModified) {
                const preparedAddresses = JSON.stringify(obj)
                try {
                    await apiClient.patchJson(
                        APIEndpoints.offices(officeId, obj.id).patchAddress,
                        preparedAddresses
                    )
                } catch (error) {
                    errors = true
                    showErrors(error, index)
                }
            }
        }
        if (!errors) {
            reloadData()
            setSuccess(t(`registrationBox:savingSuccess`))
            resetSelectedOffices(officeAddresses)
        }
        setSubmitIsLoading(false)

    }

    const contextValues = {
        dataIsLoading,
        officeName,
        selectedOffice,
        officeAddresses,
        availableCountries,
        availableAddressTypes,
        submit,
        submitIsLoading,
        cancel,
        deleteAddress,
        success,
        formErrors,
        dataHasChanged,
        getExternOfficeAddress,
        otherErrors,
        resetFormErrorsByKey,
        handleAddAddress,
        setOfficeAddresses
    }

    return (
        <OfficeContext.Provider value={contextValues}>
            {children}
        </OfficeContext.Provider>
    )
}

export default OfficeProvider
