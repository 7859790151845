import React from 'react'

export const Accessoires = (props) => {
  return (
    <svg className="accessoires" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
            .st0{fill:${props.color};}
          `}
      </style>
      <path className="st0" d="M22.2,94.5h69c2.2,0,3.9-1.8,3.9-3.9V59.7l0.4-0.2c1.1-0.5,2.1-1,3-1.5c0.4-0.2,0.6-0.6,0.6-1l0-21.5
        c0-2.2-1.8-3.9-3.9-3.9H70.4v-6.9c0-3.1-2.6-5.7-5.7-5.7h-16c-3.1,0-5.7,2.6-5.7,5.7v6.9H18.2c-2.2,0-3.9,1.8-3.9,3.9l0,22
        c0,0.4,0.3,0.9,0.7,1.1c1,0.5,2,0.9,3,1.3l0.4,0.2v30.5C18.2,92.7,20,94.5,22.2,94.5z M91.2,92.2h-69c-0.9,0-1.6-0.7-1.6-1.6V61
        l0.9,0.3c8.1,3,17.1,4.7,26.8,5.3l0.7,0v3.2c0,1.5,1.2,2.7,2.7,2.7h10c1.5,0,2.7-1.2,2.7-2.7v-3.3l0.7,0C74.9,66,84,64.1,91.9,61
        l0.9-0.4l0,29.9C92.8,91.4,92.1,92.2,91.2,92.2z M51.3,70.3v-7.6H62v7.6H51.3z M45.3,24.6c0-1.9,1.5-3.4,3.4-3.4h16
        c1.8,0,3.4,1.5,3.4,3.4v6.9H45.3V24.6z M16.9,57l-0.4-0.2v-0.2l0-21.2c0-0.9,0.7-1.6,1.6-1.6h77.1c0.9,0,1.6,0.7,1.6,1.6l0,20.8
        l-0.4,0.2c-8.8,4.4-19.4,7.1-31.4,7.8l-0.7,0v-1.2c0-1.5-1.2-2.7-2.7-2.7h-10c-1.5,0-2.7,1.2-2.7,2.7v1.3l-0.7,0
        C36.5,63.6,25.9,61.2,16.9,57z"/>
    </svg>
  )
}

