import React from 'react'
import { useObserver } from 'mobx-react'
import { Form } from 'react-bootstrap'
import useStores from '../../stores/useStore'
import SearchField from '../Forms/SearchField'
import { useTranslation } from 'react-i18next'
import SearchResultAmount from '../Forms/SearchResultAmount'



const ContingentProductsSearch = () => {
  const { productsStore } = useStores()

  const handleChangeSearchText = e => {
    productsStore.searchText = e.target.value
  }

  const handleClearClick = e => {
    productsStore.searchText = ''
  }
  const countArticles = (contingents) => {
    let sum = 0
    for (let contingent of contingents) {
      for (let category of contingent.categories) {
        if (category.articles) sum += category.articles.length
      }
    }
    return sum
  }
  const articleAmount = useObserver(() => countArticles(productsStore.filteredContingents))
  const { t } = useTranslation()
  return useObserver(() => (
    <>
      <hr></hr>
      <Form.Group className="mb-3">
        <Form.Label>{t('ProductOverview:searchArticle')}</Form.Label>
        <SearchField
          placeholder={t('ProductOverview:searchArticle')}
          value={productsStore.searchText}
          onChange={handleChangeSearchText}
          onClearClick={handleClearClick}
        />
        {productsStore.searchText ? <SearchResultAmount count={articleAmount} /> : <div className="mt-2">&nbsp;</div>}
      </Form.Group >
      <hr></hr>
    </ >
  ))
}

export default ContingentProductsSearch

