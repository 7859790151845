import React, { useCallback, useEffect, useState } from 'react'
import SubheaderInventoryHistory from '../components/InventoryHistory/SubheaderInventoryHistory'
import InventoryHistoryList from '../components/InventoryHistory/InventoryHistoryList'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import BigFooter from '../components/Footer/BigFooter'
import { useParams } from 'react-router-dom'
import useStores from '../stores/useStore'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { Spinner } from 'react-bootstrap'
import LazyLoad from '../components/Forms/LazyLoad'

const InventoryHistoryPage = () => {
    const { employeeId } = useParams()
    const { profileStore } = useStores()
    const [inventoryHistory, setInventoryHistory] = useState([])
    const [offset, setOffset] = useState(0)
    const limit = 20
    const [totalCount, setTotalCount] = useState(0)
    const [inventoryHistoryIsLoading, setInventoryHistoryIsLoading] = useState(false)

    const getParams = useCallback((append) => {
        let params = {
            'page.limit': limit,
            'page.offset': append ? offset : 0
        }
        return params
    }, [offset])

    const fetchHistoryData = useCallback(async ({ append } = { append: false }) => {
        setInventoryHistoryIsLoading(true)
        let id = employeeId
        if (!id) {
            let profile = await profileStore.loadProfile()
            id = profile.id
        }
        try {
            let params = getParams(append)
            let response = await apiClient.getJson(APIEndpoints.businessPartner(id).inventorHistory, params, true, true)
            setInventoryHistory(prev => append ? [...prev, ...response.data] : response.data)
            setTotalCount(response.headers['x-total-count'] ? parseInt(response.headers['x-total-count']) : 0)
            setOffset(prev => prev + limit)
        }
        catch (e) {

        }
        setInventoryHistoryIsLoading(false)
    }, [employeeId, getParams, profileStore])

    useEffect(() => {
        setOffset(0)
        fetchHistoryData()
        //eslint-disable-next-line
    }, [employeeId])

    return (
        <>
            <SubheaderInventoryHistory />
            <ContentArea>
                <div className='container-fluid'>
                    <InventoryHistoryList inventoryHistory={inventoryHistory} />
                </div>
                {((totalCount > offset) && !inventoryHistoryIsLoading) ?
                    (<LazyLoad
                        fetchData={() => fetchHistoryData({ append: true })}
                    />) : ''}
                {inventoryHistoryIsLoading &&
                    <div className='d-flex justify-content-center' >
                        <Spinner animation='border' variant='primary' />
                    </div>}
            </ContentArea>
            <BigFooter />
        </>
    )
}

export default InventoryHistoryPage