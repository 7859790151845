import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { useTranslation } from 'react-i18next'

const EmployeeHealthDataContext = React.createContext({})
export function useEmployeeHealthDataState() {
    return React.useContext(EmployeeHealthDataContext)
}
const EmployeeHealthDataProvider = ({ children }) => {

    const [businesspartnerId, setBusinesspartnerId] = useState('')
    const [currentQuarantineStatus, setCurrentQuarantineStatus] = useState(null)
    const [checkupTypes, setCheckupTypes] = useState(null)
    const [checkUpsData, setCheckupsData] = useState(null)
    const [initialCurrentQuarantineStatus, setInitialCurrentQuarantineStatus] = useState({})
    const [initialCheckupsData, setInitialCheckupsData] = useState([])
    const [error, setError] = useState(false)
    const [apiError, setApiError] = useState([])
    const [frontendValidation, setFrontendValidation] = useState(null)
    const [nextTests, setNextTests] = useState([])
    const [initialNextTests, setInitialNextTests] = useState([])
    const [appointments, setAppointments] = useState([])
    const { t } = useTranslation()
    const nextTestTemplate = { checkUpType: { id: 0 }, dateOfCheckUp: '' }
    const mountedRef = useRef(true)

    //Cleanup Function
    useEffect(() => {
        return () => { mountedRef.current = false }
    }, [])

    const updateNextTests = useCallback((checkUps) => {
        const newData = [...nextTests]
        checkUps.forEach(() => {
            newData.push('')
        })
        setInitialNextTests(JSON.stringify(newData))
        setNextTests(newData)
    }, [nextTests])

    const loadEmployeeData = useCallback(id => {
        setBusinesspartnerId(id)
        apiClient.getJson(APIEndpoints.businessPartner(id).currentQuarantineStatus).then(result => {
            if (!mountedRef.current) { return null }
            setCurrentQuarantineStatus(result)
            //stringify here so that checkupsdata and InitialCheckupsData dont share the SAME Array
            setInitialCurrentQuarantineStatus(JSON.stringify(result))
        })
        apiClient.getJson(APIEndpoints.businessPartner(id).checkUps).then(result => {
            if (!mountedRef.current) { return null }
            //soll nicht die nextTests behinhalten
            //const resultsFromPast = result.filter((obj) => (new Date(obj.dateOfCheckUp) <= new Date()))
            //updateFrontendValidation(result)
            setFrontendValidation(resetFrontendValidation(result))

            updateNextTests(result)
            setCheckupsData(result)
            setInitialCheckupsData(JSON.stringify(result))
        })
        apiClient.getJson(APIEndpoints.businesspartnerCheckUpConfigs).then(result => {
            if (!mountedRef.current) { return null }
            setCheckupTypes(result)
        })
    }, [updateNextTests])

    const resetFrontendValidation = (checkUps) => {
        const newFrontendValidation = {}
        const newCheckUps = []
        checkUps.forEach(() => {
            newCheckUps.push({ checkUpType: '', dateOfCheckUp: '', checkUpResult: '', nextTest: '' })
        })
        newFrontendValidation.startDate = ''
        newFrontendValidation.endDate = ''
        newFrontendValidation.quarantineCheck = ''
        newFrontendValidation.checkUps = newCheckUps
        return newFrontendValidation
    }



    const currentQuarantineStatusHasChanged = useMemo(() => {
        if (initialCurrentQuarantineStatus) {
            const formDataString = currentQuarantineStatus
            return JSON.stringify(formDataString) !== initialCurrentQuarantineStatus
        }
    }, [currentQuarantineStatus, initialCurrentQuarantineStatus])

    const checkUpsDataHasChanged = useMemo(() => {
        if (initialCheckupsData) {
            const formDataString = checkUpsData
            return JSON.stringify(formDataString) !== initialCheckupsData
        }
    }, [checkUpsData, initialCheckupsData])


    const nextTestsDataHasChanged = useMemo(() => {
        if (initialNextTests) {
            const nextTestsString = nextTests
            return JSON.stringify(nextTestsString) !== initialNextTests
        }
    }, [nextTests, initialNextTests])

    const resetFormData = () => {
        setError(false)
        setApiError([])
        setCurrentQuarantineStatus(JSON.parse(initialCurrentQuarantineStatus))
        setCheckupsData(JSON.parse(initialCheckupsData))
        setNextTests(JSON.parse(initialNextTests))
        setFrontendValidation(resetFrontendValidation(JSON.parse(initialCheckupsData)))
        //loadEmployeeData(businesspartnerId)
    }

    const startFrontendValidation = () => {
        var result = true
        const resultFrontendValidation = resetFrontendValidation(checkUpsData)
        //check if form values that have to be checked have unvalid values
        if (currentQuarantineStatusHasChanged) {
            if (!currentQuarantineStatus.startDate || !currentQuarantineStatus.endDate) {
                if (!currentQuarantineStatus.startDate) {
                    resultFrontendValidation.startDate = t('EmployeeFormHealthData:startValidation');
                }
                if (!currentQuarantineStatus.endDate) {
                    resultFrontendValidation.endDate = t('EmployeeFormHealthData:startValidation');
                }
                result = false;
            }
            else if (new Date(currentQuarantineStatus.startDate) > new Date(currentQuarantineStatus.endDate)) {
                resultFrontendValidation.startDate = t('EmployeeFormHealthData:beforeQuarantineDateValidation');
                result = false;
            }
            else if (new Date(currentQuarantineStatus.endDate) < new Date()) {
                resultFrontendValidation.endDate = t('EmployeeFormHealthData:quarantineEndNotPastValidation');
                result = false;
            }
        }
        if (checkUpsDataHasChanged) {
            for (const [index, obj] of checkUpsData.entries()) {
                if (obj.checkUpType.id === 0) {
                    resultFrontendValidation.checkUps[index].checkUpType = t('EmployeeFormHealthData:emptyValidation');
                    result = false;
                }
                if (obj.dateOfCheckUp === "") {
                    resultFrontendValidation.checkUps[index].dateOfCheckUp = t('EmployeeFormHealthData:emptyDateValidation');
                    result = false
                }
                else if ((!obj.checkUpResult) && (new Date(obj.dateOfCheckUp) <= new Date())) {
                    resultFrontendValidation.checkUps[index].checkUpResult = t('EmployeeFormHealthData:emptyTestValidation');
                    result = false
                }
                else if (obj.checkUpResult && (new Date(obj.dateOfCheckUp) > new Date())) {
                    resultFrontendValidation.checkUps[index].checkUpResult = t('EmployeeFormHealthData:futureTestValidation');
                    result = false
                }
            }
        }
        if (nextTestsDataHasChanged) {
            for (const index of Object.keys(nextTests)) {
                if (nextTests[index] && (new Date(nextTests[index]) <= new Date())) {
                    resultFrontendValidation.checkUps[index].nextTest = t('EmployeeFormHealthData:testToBeFutureValidation')
                    result = false
                }
                if (nextTests[index] &&
                    checkUpsData[index] &&
                    (new Date(nextTests[index]).getDate() === new Date(checkUpsData[index].dateOfCheckUp).getDate()) &&
                    (new Date(nextTests[index]).getMonth() === new Date(checkUpsData[index].dateOfCheckUp).getMonth()) &&
                    (new Date(nextTests[index]).getFullYear() === new Date(checkUpsData[index].dateOfCheckUp).getFullYear())) {
                    resultFrontendValidation.checkUps[index].nextTest = 'Für den Gesundheitschecktyp existiert bereits ein Eintrag an diesem TAG'
                    result = false
                }

                //comparing next Test Dates with getTime() for doublicates
                /*  let doublicates = nextTests.filter(item => new Date(item).getTime() === new Date(nextTests[index]).getTime())
                 if (doublicates.length > 1) {
                     resultFrontendValidation.checkUps[index].nextTest = 'Für den Gesundheitschecktyp existiert bereits ein Eintrag an diesem TAG'
                     result = false
                 } */
            }
        }
        setFrontendValidation(resultFrontendValidation)
        return result
    }
    const resetFrontendValidationByKey = (formKey, index) => {
        if (frontendValidation) {
            if (index || index === 0) {
                setFrontendValidation(prev => {
                    let row = prev.checkUps[index]
                    if (row[formKey]) {
                        delete row[formKey]
                    }
                    return prev
                })
            }
            else {
                setFrontendValidation(prev => {
                    if (prev[formKey]) {
                        delete prev[formKey]
                    }
                    return prev
                })
            }
        }
    }

    const parseApiFormErrors = (response) => {
        var apiErrors = []
        if (response && response.data && response.data.errors) {
            response.data.errors.forEach(err => {
                if (err.detail) {
                    apiErrors.push(err.detail)
                }
            })
        }
        return apiErrors
    }

    const deleteCheckUp = (id, index) => {
        if (id) {
            apiClient.deleteJson(APIEndpoints.businessPartner(businesspartnerId, id).checkUp).then(() => {
                if (!mountedRef.current) { return null }
                //loadEmployeeData(businesspartnerId)
                let newIndex = JSON.parse(initialCheckupsData).findIndex(obj => obj.id === id)
                setInitialCheckupsData(prev => {
                    let arr = JSON.parse(prev)
                    arr.splice(newIndex, 1)
                    return JSON.stringify(arr)
                })

                setInitialNextTests(prev => {
                    let arr = JSON.parse(prev)
                    arr.splice(newIndex, 1)
                    return JSON.stringify(arr)
                })
            }).catch(e => {
                if (e.response) {
                    let apiError = parseApiFormErrors(e.response)
                    setApiError(apiError)
                } else { setError(true) }
            })
        }
        setError(false)
        setApiError([])
    }

    const submit = async () => {
        setError(false)
        setApiError([])
        const newApiErrors = []
        const frontendValidationPassed = startFrontendValidation()
        if (frontendValidationPassed) {
            const initialData = JSON.parse(initialCheckupsData)
            var quarantinePut = true, checkUpPost = true, checkUpPatch = true

            if (currentQuarantineStatusHasChanged) {
                //if comment is null
                const preparedQuarantineStatus = { ...currentQuarantineStatus }
                if (!preparedQuarantineStatus.comment) { preparedQuarantineStatus.comment = "" }
                const preparedQuarantineStatus2 = JSON.stringify(preparedQuarantineStatus)
                try {
                    await apiClient.putJson(APIEndpoints.businessPartner(businesspartnerId).currentQuarantineStatus, preparedQuarantineStatus2)
                } catch (e) {
                    quarantinePut = false;

                    if (e.response) {
                        let apiError = parseApiFormErrors(e.response)
                        newApiErrors.push(...apiError)
                    }
                    else { setError(true) }
                }
            }
            if (checkUpsDataHasChanged || nextTestsDataHasChanged) {
                for (const [index, obj] of checkUpsData.entries()) {
                    //foreach checkup that got modified -> patch checkup
                    if (obj.hasOwnProperty("id")) {
                        if (initialData.every(item => JSON.stringify(item) !== JSON.stringify(obj))) {
                            const preparedModified = JSON.stringify(obj)
                            try {
                                await apiClient.patchJson(APIEndpoints.businessPartner(businesspartnerId, obj.id).checkUp, preparedModified).then(() => {
                                })
                            } catch (e) {
                                checkUpPatch = false;
                                if (e.response) {
                                    let apiError = parseApiFormErrors(e.response)
                                    newApiErrors.push(...apiError)
                                }
                                else { setError(true) }
                            }
                        }
                    }
                    //foreach checkup without id -> post checkup
                    else {
                        const preparedNew = JSON.stringify(obj)
                        try {

                            await apiClient.postJson(APIEndpoints.businessPartner(businesspartnerId).checkUps, preparedNew)
                        } catch (e) {
                            checkUpPost = false;
                            if (e.response) {
                                let apiError = parseApiFormErrors(e.response)
                                newApiErrors.push(...apiError)
                            }
                            else { setError(true) }
                        }
                    }
                    if (nextTests[index]) {
                        var params = nextTestTemplate
                        params.checkUpType.id = obj.checkUpType.id
                        params.dateOfCheckUp = nextTests[index]
                        const preparedNextTest = JSON.stringify(params)
                        try {
                            await apiClient.postJson(APIEndpoints.businessPartner(businesspartnerId).checkUps, preparedNextTest)
                        } catch (e) {
                            checkUpPost = false;
                            if (e.response) {
                                let apiError = parseApiFormErrors(e.response)
                                newApiErrors.push(...apiError)
                            }
                            else { setError(true) }
                        }
                    }
                }
            }
            //no Api Errors => succesfull saved alert
            if (quarantinePut && checkUpPost && checkUpPatch) {
                loadEmployeeData(businesspartnerId)
                return true
            }
            loadEmployeeData(businesspartnerId)

        }
        else { setError(true) }
        setApiError(newApiErrors)
    }
    const contextValues = {
        nextTests,
        appointments,
        setAppointments,
        frontendValidation,
        setFrontendValidation,
        currentQuarantineStatus,
        setCurrentQuarantineStatus,
        resetFrontendValidationByKey,
        currentQuarantineStatusHasChanged,
        nextTestsDataHasChanged,
        checkUpsDataHasChanged,
        businesspartnerId,
        loadEmployeeData,
        setCheckupsData,
        resetFormData,
        deleteCheckUp,
        checkUpsData,
        setNextTests,
        checkupTypes,
        apiError,
        submit,
        error
    }
    return (
        <EmployeeHealthDataContext.Provider value={contextValues}>
            {children}
        </EmployeeHealthDataContext.Provider>
    )
}

export default EmployeeHealthDataProvider
