import React, { useEffect, useState, useRef } from 'react'
import { Form } from 'react-bootstrap'
import { getFakeEvent, resolveObj } from '../../utils/utils'
import i18n from '../../translation/i18n'
import { useTranslation } from 'react-i18next'
import { usePrevious2 } from '../../utils/hooks/usePrevious'
import { useCallback } from 'react'
//defaultOptionIsSelcetable should always be in combination with noAutoSelect
const ArraySelect = (
    {
        returnEvent = false,
        name,
        options = [],
        disabledKeys = [],
        value,
        onChange = () => { },
        isInvalid,
        defaultOption = { value: "", name: i18n.t("pleaseSelect") },
        defaultOptionIsSelectable,
        otherValueKey,
        otherNameKey,
        selectKey,
        id,
        required,
        autoComplete,
        noAutoSelect,
        className,
        style,
        disabled
    }
) => {
    const [loadingDotsIndex, setLoadingDotsIndex] = useState(0)
    const loadingDots = [".", "..", "..."]
    const { t } = useTranslation()
    const interval = useRef(null);
    const valueKey = otherValueKey ? otherValueKey : "id"
    const nameKey = otherNameKey ? otherNameKey : "displayName"
    const noValue = !value || value === defaultOption.value
    const noOptionAvailable = options.length === 0
    const oneOptionAvailable = options.length === 1
    const prevOptions = usePrevious2([]);

    const handleAutoselect = useCallback(() => {
        //autoselect if only one option
        if (noValue && !noAutoSelect) {
            if (oneOptionAvailable) {
                if (returnEvent) {
                    onChange(getFakeEvent(name, options[0][valueKey], "select"))
                }
                else {
                    onChange(options[0])
                }
            }
        }
    }, [name, noAutoSelect, noValue, onChange, oneOptionAvailable, options, returnEvent, valueKey])

    useEffect(() => {
        if (prevOptions !== options) {
            //only when options change
            handleAutoselect()
        }
        //wait for options
        if (noOptionAvailable && !interval.current) {
            interval.current = setInterval(() => { setLoadingDotsIndex(prev => (prev + 1) % 3) }, 400);
        }
        return () => {
            clearInterval(interval.current);
        }
    }, [handleAutoselect, noOptionAvailable, options, prevOptions])

    //return option object onSelect
    const handleChange = (e) => {
        var result = {}
        if (returnEvent) {
            onChange(getFakeEvent(e.target.name, e.target.value, "select"))
        }
        else {
            result = options.find(obj => obj[valueKey] === e.target.value)
            if (result) {
                onChange(result)
            }
            else { onChange(defaultOption) }
        }
    }

    return (
        < Form.Select
            disabled={disabled || noOptionAvailable || (oneOptionAvailable && !defaultOptionIsSelectable && !noAutoSelect)}
            name={name}
            onChange={handleChange}
            value={value || defaultOption.value}
            placeholder={loadingDots}
            isInvalid={isInvalid}
            key={selectKey}
            id={id}
            required={required}
            autoComplete={autoComplete}
            style={noOptionAvailable ? { color: '#c6c6c6', ...style } : { ...style }}
            className={className}
        >
            <option
                hidden={!defaultOptionIsSelectable}
                value={defaultOption.value}
            >
                {noOptionAvailable ? `(${t('noOptions')})${loadingDots[loadingDotsIndex]}` : defaultOption.name}
            </option>
            {options.map(option => (
                <option
                    key={`${resolveObj(valueKey, option)}-${resolveObj(nameKey, option)}`}
                    disabled={disabledKeys.some(key => key === resolveObj(valueKey, option))}
                    value={resolveObj(valueKey, option)}
                >
                    {resolveObj(nameKey, option)}
                </option>
            ))}
        </Form.Select >
    )
}

export default ArraySelect