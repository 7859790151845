import styled from 'styled-components'
import { colors } from '../../../styles/colors'

export const Subheader = styled.div`
  border-bottom: 1px solid ${colors.gray3};
  background: #dbdbdb;
  width: 100%;
  left: 0;
  min-height: 90px;
  padding: 0 25px 0 15px;
  color: ${colors.textColor};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  z-index: 1;

  @media screen and (min-width: 768px) {
    padding-left: 36px;
  }

  .with-progress {
    min-height: 120px;
  
  }
    @media screen and (min-width: 768px) {
    .with-progress {
        min-height: 90px;
    }
      }
  

  .subheader-title {
    font-size: 26px;
    font-weight: 600;
  }

  .subheader-title-small {
    font-size: 19px;
    font-weight: 600;
  }

  a + .subheader-title-small {
    margin-top: 6px;
  }

  .h1,
  .h2 {
    color: ${colors.textColor};
  }
  .subheader{
      &__button{
        margin: .5rem 0 .5rem .5rem;
        @media screen and (max-width: 768px) {
            width:100%;
            margin: 0 0 1rem 0;
        }
      }
      &__buttonWrapper{
        @media screen and (max-width: 768px) {
          width:100%;
       }
      }
      &__alert{
        width:100%;
        margin: 1rem 0 1rem 0;
        a{
          text-decoration:underline;
        }
      }
  }

  .infoIconWrapper {
    padding: 10px;
  }

  .infoIcon {
    font-size: 2rem;
    fill: white;
    background-color: ${props => props.theme.primaryColor};
    border-radius: 7px;
    margin: 15px;
  }

`