import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ArraySelect from '../Select/ArraySelect'
import getPreparedLocalStorehouses from '../../utils/getPreparedLocalStorehouses'

const LocalStorehouseSelect = ({ localStorehouses, selectedLocalStorehouseId, handleChangeLocalStorehouse, selectedLocalStorehouseError}) => {
    const preparedStorehouse = getPreparedLocalStorehouses(localStorehouses)
    const { t } = useTranslation()
    return (
        <Row>
            <Form.Group as={Col} >
                <ArraySelect
                    onChange={handleChangeLocalStorehouse}
                    isInvalid={selectedLocalStorehouseError}
                    value={selectedLocalStorehouseId}
                    options={preparedStorehouse}
                    returnEvent
                />
                <Form.Control.Feedback type='invalid'>{selectedLocalStorehouseError && t('productDetail:selectLocalStorehouse')}</Form.Control.Feedback>
            </Form.Group>
        </Row>
    )
}
export default LocalStorehouseSelect