const APIBaseUrl = process.env.REACT_APP_API_URL
const APIBaseUrlToken = APIBaseUrl + 'token'
const APIBaseUrlEndpoints = APIBaseUrl + 'api/'
const AuthorizationserverTokenUrl = process.env.REACT_APP_AUTHORIZATIONSERVER_URL + 'token'


export const APIEndpoints = {
  settings: () => {
    const firstPart = 'settings/page'
    return {
      dashboard: `${firstPart}/dashboard`,
      businesspartnerData: `${firstPart}/businesspartnerData`,
      personalOrder: `${firstPart}/personalOrder`
    }
  },
  coreservices: () => ({
    stdPointRun: 'coreservices/stdPointRun/status',
    pointRunConfig: 'coreservices/stdPointRun/configuration'
  }),
  checkDocumentUpload: 'settings/check/documentUpload',
  orderDetails: '/settings/page/orderDetails',
  shoppingCartTunnel: '/settings/page/shoppingCartTunnel',
  partialCostCenters: 'partialCostCenters',
  partialCostCenter: costCenterId => `partialCostCenters/${costCenterId}`,
  balanceChangeReasons: 'balanceChangeReasons',
  reorders: orderId => `shoppingBaskets/reorders/${orderId}`,
  articleCatalogs: basketId => `shoppingBaskets/${basketId}/articleCatalogs`,
  defaultArticleCatalog: '/articleCatalogs/default',
  defaultArticleCatalogArticles: (articleCatalogId, limit, offset) => `/articleCatalogs/${articleCatalogId}/articles?page.limit=${limit}&page.offset=${offset}`,
  defaultArticleCatalogArticle: (catalogId, articleId) => `articleCatalogs/${catalogId}/articles/${articleId}`,
  externalCatalogs: articleCatalogId => `externalCatalogs/${articleCatalogId}`,
  articleBarcodes: catalogId => `articleCatalogs/${catalogId}/reports/articleBarcodes`,
  articleCatalogCategories: articleCatalogId => `articleCatalogs/${articleCatalogId}/articleCatalogCategories`,
  searchCriteriaCatalogCategories: articleCatalogId => `articleCatalogs/${articleCatalogId}/searchCriteriaCatalogCategories`,
  orderableArticles: (basketId, limit, offset) => `shoppingBaskets/${basketId}/orderableArticles?page.limit=${limit}&page.offset=${offset}`,
  profile: 'profile',
  resetPassword: 'resetPassword',
  newPassword: 'newPassword',
  loginQuestion: 'loginQuestion',
  changePassword: 'changePassword',
  passwordRules: 'passwordRules',
  securityQuestions: 'securityQuestions',
  businessPartners: 'businessPartners',
  businessPartner: (partnerId, id) => {
    const firstPart = `businessPartners/${partnerId}`
    return {
      patch: firstPart,
      info: `${firstPart}/info`,
      mainData: `${firstPart}/maindata`,
      communicationData: `${firstPart}/communicationData`,
      addresses: `${firstPart}/addresses`,
      activities: `${firstPart}/activities`,
      shoppingActivities: `${firstPart}/shoppingActivities`,
      budgetBalances: `${firstPart}/budgetBalances`,
      checkUps: `${firstPart}/checkUps`,
      instances: `${firstPart}/instances`,
      currentQuarantineStatus: `${firstPart}/currentQuarantineStatus`,
      officeAdresses: `${firstPart}/officeAdresses`,
      articleConfigurations: `${firstPart}/articleConfigurations`,
      articleConfigurationsVariants: `${firstPart}/articleConfigurations/${id}/variants`,
      articleConfigurationsConfigurations: `${firstPart}/articleConfigurations/${id}/configurations`,
      articleComment: `${firstPart}/contingent/${id}/comment`,
      articleDetails: `${firstPart}/contingent/${id}/details`,
      articleHistories: `${firstPart}/contingent/${id}/histories`,
      articleInstances: `${firstPart}/contingent/${id}/instances`,
      activationStatus: `${firstPart}/activationStatus`,
      laundryShelves: `${firstPart}/laundryShelves`,
      inventoryBookings: `${firstPart}/contingent/inventoryBookings`,
      returnBookings: `${firstPart}/contingent/returnBookings`,
      articleTransfer: `${firstPart}/contingent/articleTransfer`,
      account: `${firstPart}/account`,
      inventorHistory: `${firstPart}/inventorHistory`,
      currentPoints: `${firstPart}/currentPoints`,
      contingent: `${firstPart}/contingent`,
      checkUp: `${firstPart}/checkUps/${id}`
    }
  },
  shoppingBaskets: `shoppingBaskets`,
  acceptedShoppingBaskets: `shoppingBaskets/acceptedShoppingBaskets`,
  shoppingBasket: basketId => `shoppingBaskets/${basketId}`,
  shoppingBasketArticle: (basketId, shoppingBasketArticleId) => `shoppingBaskets/${basketId}/shoppingBasketArticles/${shoppingBasketArticleId}`,
  shoppingBasketArticleVariants: (basketId, shoppingBasketArticleId) => `shoppingBaskets/${basketId}/articles/${shoppingBasketArticleId}/orderableVariants`,
  shoppingBasketArticleConfigurations: (basketId, shoppingBasketArticleId) => `shoppingBaskets/${basketId}/articles/${shoppingBasketArticleId}/configuration`,
  shoppingBasketArticleNextExpiries: (basketId, shoppingBasketArticleId) => `shoppingBaskets/${basketId}/shoppingBasketArticles/${shoppingBasketArticleId}/nextExpiries`,
  shoppingBasketAvailabilities: basketId => `shoppingBaskets/${basketId}/availabilities`,
  shoppingBasketScan: (basketId, barcode) => `shoppingBaskets/${basketId}/scan/${barcode}/order`,
  orderableAccessories: (basketId, articleId) => `shoppingBaskets/${basketId}/articles/${articleId}/orderableAccessories`,
  variantQuantities: (basketId, aticleId) => `shoppingBaskets/${basketId}/articles/${aticleId}/variantQuantities`,
  localStorehouses: (basketId, aticleId) => `shoppingBaskets/${basketId}/articles/${aticleId}/localStorehouses`,
  lastComment: (basketId, aticleId) => `shoppingBaskets/${basketId}/articles/${aticleId}/lastComment`,
  prices: (basketId, aticleId) => `shoppingBaskets/${basketId}/articles/${aticleId}/prices`,
  orderableFollowerArticle: (basketId, aticleId) => `shoppingBaskets/${basketId}/articles/${aticleId}/orderableFollowerArticle`,
  orderablePredecessorArticles: (basketId, articleId) => `shoppingBaskets/${basketId}/articles/${articleId}/orderablePredecessorArticles`,
  productHistory: (basketId, aticleId) => `shoppingBaskets/${basketId}/articles/${aticleId}/orderHistory`,
  availableAddresses: basketId => `shoppingBaskets/${basketId}/availableAddresses`,
  availableCountries: basketId => `shoppingBaskets/${basketId}/availableCountries`,
  orderTemplates: basketId => `shoppingBaskets/${basketId}/orderTemplates`,
  orderInfo: basketId => `shoppingBaskets/${basketId}/orderInfo`,
  validator: basketId => `shoppingBaskets/${basketId}/validator`,

  shipmentTypes: basketId => {
    if (!basketId) {
      return 'shipmentTypes'
    }
    return `shoppingBaskets/${basketId}/shipmentTypes`
  },
  article: articleId => `articles/${articleId}`,
  articleScan: barcode => `articles/${barcode}/scan`,
  articleVariants: articleId => `articles/${articleId}/variants`,
  articleEntitlementAssignments: articleId => `articles/${articleId}/entitlementAssignments`,
  articleCatalogAssingments: articleId => `articles/${articleId}/catalogAssignments`,
  contingent: basketId => `/shoppingBaskets/${basketId}/contingent`,
  countries: 'countries',
  languages: 'languages',
  entitlementPhases: 'entitlementphases',
  incomingOrdersCounter: 'incomingOrdersCounter',
  incomingOrders: incomingOrderId => {
    const firstPart = incomingOrderId ? `incomingOrders/${incomingOrderId}` : 'incomingOrders'
    return {
      incomingOrder: firstPart,
      incomingOrderPositions: `${firstPart}/incomingOrderPositions`,
      changeIncomingOrderPositions: `${firstPart}/changeIncomingOrderPositions`,
      shippingOrders: `${firstPart}/shippingOrders`,
      incomingOrders: firstPart,
      releases: `${firstPart}/releases`,
      rejections: `${firstPart}/rejections`,
      groups: `${firstPart}/groups`,
      cancellations: `${firstPart}/cancellations`,
      groupReleases: `${firstPart}/groupReleases`,
      groupRejections: `${firstPart}/groupRejections`,
      srmCostCenters: `${firstPart}/srmOrders/costCenters`,
      srmCostCentersCounter: `${firstPart}/srmOrders/costCentersCounter`,
      supplierOrders: `${firstPart}/supplierOrders`,
      receipt: `${firstPart}/receipt`
    }
  },
  supplierOrders: incomingOrderId => {
    const firstPart = `supplierOrders/${incomingOrderId}`
    return {
      supplierOrder: firstPart,
      expectedArrivals: `${firstPart}/expectedArrivals`,
      supplierOrderPositions: `${firstPart}/supplierOrderPositions`
    }
  },
  placeholder: (id) => ({
    placeholders: 'administration/placeholders',
    placeholder: `administration/placeholders/${id}`,
    inheritance: `administration/inheritance/placeholders`,
    types: 'administration/types',
    type: `administration/types/${id}`,
    checkPlaceholders: `administration/types/${id}/checkPlaceholders`
  }
  ),

  laundryShelves: (id) => {
    const firstPart = 'laundryShelves'
    return id
      ? {
        locations: `${firstPart}/locations/${id}`,
        elements: `${firstPart}/elements/${id}`,
        levels: `${firstPart}/levels/${id}`
      }
      : {
        list: `${firstPart}/list`,
        tree: `${firstPart}/tree`,
        locations: `${firstPart}/locations`,
        levels: `${firstPart}/levels`
      }
  },

  changeShippingOrderPositions: shippingOrderId => `shippingOrders/${shippingOrderId}/changeShippingOrderPositions`,
  shippingOrderPositions: shippingOrderId => `shippingOrders/${shippingOrderId}/shippingOrderPositions`,
  shippingOrderPositionsReorderableVariants: shippingOrderId => `shippingOrderPositions/${shippingOrderId}/reorderableVariants`,
  returnReasons: `returnReasons`,
  infoTAT: shippingOrderId => `shippingOrders/${shippingOrderId}/infoTAT`,
  feeds: 'feeds',
  feedsAdministration: id => {
    return {
      feeds: 'administration/news',
      edit: `administration/news/${id}`,
      orderSequences: `administration/news/orderSequences`,
      fileUpload: `administration/news/${id}/fileUpload`
    }
  },
  logout: 'logout',
  registrations: mandator => {
    const firstPart = `registrations/${mandator}`
    return {
      registration: firstPart,
      countries: `${firstPart}/countries`
    }
  },
  partialBusinessPartners: 'partialBusinessPartners',
  partialOffices: 'partialOffices',
  partialOffice: officeId => `partialOffices/${officeId}`,
  partialShoppingBaskets: (status = 'isOpen') => `partialShoppingBaskets?filter.status=${status}`,
  currentInventory: (businesspartnerId, asExcel) => `businesspartnerReports/${businesspartnerId}/currentInventory?asExcel=${asExcel}`,
  budgethistory: businesspartnerId => `businesspartnerReports/${businesspartnerId}/budgethistory`,
  contingenthistory: businesspartnerId => `businesspartnerReports/${businesspartnerId}/contingenthistory`,
  allActivities: 'activities',
  businessPartnersWithMissingData: 'articleConfigurations/countBusinesspartnersWithMissingData',
  reportJobTypes: 'reportJobTypes',
  reportJobTypeGroups: 'reportJobTypeGroups',
  reportJobs: id => {
    if (!id) {
      return 'reportJobs'
    }
    const firstPart = `reportJobs/${id}`
    return {
      details: `${firstPart}`,
      subscriptions: `${firstPart}/subscriptions`
    }
  },
  cabinEventReport: 'cabinEventReport',
  reportJobsCounter: 'reportJobsCounter',
  statisticCostcenters: 'statisticCostcenters',
  statisticEmployeeList: 'statisticEmployeeList',
  statisticEmployeeHealthList: 'statisticEmployeeHealthList',
  statisticCostcentersFromOrders: 'statisticCostcentersFromOrders',
  turnOverStatisticsOnEntityUnit: 'turnOverStatisticsOnEntityUnit',
  openReportJobs: 'openReportJobs',
  statisticEmployeeInventory: 'statisticEmployeeInventory',
  businesspartnerCheckUpTypes: 'businesspartnerCheckUpTypes',
  businesspartnerCheckUpConfigs: 'businesspartnerCheckUpConfigs',
  appointmenttypes: appointmentTypeId => {
    if (!appointmentTypeId) {
      return 'appointmenttypes'
    }
    const firstPart = `appointmenttypes/${appointmentTypeId}`
    return {
      default: firstPart,
      deletable: `${firstPart}/deletable`,
      appointmenttypeData: `${firstPart}/appointmenttypeData`,
      assignedCabins: `${firstPart}/assignedCabins`,
      timeSlots: `${firstPart}/timeSlots`,
      timeSlotsAdministration: `${firstPart}/timeSlots/administration`,
      cabins: `${firstPart}/cabins`,
      bookableTimeSlots: (cabinId, date) => `${firstPart}/cabins/${cabinId}/bookableTimeSlots/${date}`,
      appointmentOverviews: (cabinId) => `${firstPart}/cabins/${cabinId}/appointmentOverviews`,
    }
  },
  cabins: id => {
    if (!id) {
      return 'cabins'
    }
    const firstPart = `cabins/${id}`;
    return {
      details: `${firstPart}`,
      cabinData: `${firstPart}/cabinData`,
      deletable: `${firstPart}/deletable`,
      assignedAppointmenttypes: `${firstPart}/assignedAppointmenttypes`,
      events: `${firstPart}/events`,
      locks: `${firstPart}/locks`,
      groupReservations: `${firstPart}/groupReservations`,
    }
  },
  appointments: 'appointments',
  bookingOverview: 'appointments/bookingOverview',
  appointment: id => `appointments/${id}`,
  costCenters: id => {
    const firstPart = `costCenters/${id}`;
    return {
      details: `${firstPart}`,
      groupAssignments: `${firstPart}/groupAssignments`,
      interfaces: `${firstPart}/interfaces`
    }
  },
  newCostCenters: "costCenters",
  costCenterTypes: "costCenterTypes",
  offices: (id, addressId) => {
    return {
      details: `offices/${id}`,
      groupAssignments: `offices/${id}/groupAssignments`,
      addresses: `offices/${id}/addresses`,
      availableAddressTypes: `offices/${id}/availableAddressTypes`,
      deleteAddress: `offices/${id}/addresses/${addressId}`,
      patchAddress: `offices/${id}/addresses/${addressId}`
    }
  },
  newOffices: "offices",
  customHtmlMenu: 'customHTML/menu',
  customHtmlPage: id => `customHTML/page/${id}`,
  customFooter: 'customHTML/footer',
  customContact: 'customHTML/contact',
  customHTMLVideo: placeholder => `customHTML/video/${placeholder}`,
  officeExport: "officeExport",
  officeImport: "officeImport",
  entitlementImport: "entitlementImport",
  entitlementExport: "entitlementExport",
  costcenterExport: "costcenterExport",
  costcenterImport: "costcenterImport",
  orderExport: "orderExport",
  orderImport: "orderImport",
  srmOrderExport: "srmOrderExport",
  catalogImport: "catalogImport",
  paymentStatus: id => `paymentProviders/shoppingbaskets/paymentStatus?id=${id}`,
  linksRlx: "links/rlx",
  groups: "groups",
  accounts: id => {
    return {
      accessibleCostcenters: `accounts/${id}/costcenters`,
      accessibleOffices: `accounts/${id}/offices`,
      accessibleCatalogs: `accounts/${id}/catalogs`,
      assignedGroups: `accounts/${id}/groups`
    }
  },
  returns: id => ({
    receipt: `returns/${id}/receipt`,
    overview: 'returns/overview',
    messages: `returns/${id}/messages`,
    positions: `returns/${id}/positions`,
    contactData: `returns/${id}/contactData`
  }),
  incomingOrder: id => ({
    returns: `incomingOrder/${id}/returns`,
    complaintsContactData: `incomingOrder/${id}/complaintsContactData`,
    complaints: `incomingOrder/${id}/complaints`,
    returnsContactData: `incomingOrder/${id}/returnsContactData`
  }),
  accessibleArticleCatalogs: "articleCatalogs",
  complaints: id => {
    const firstPart = `complaints/${id}`
    return {
      default: firstPart,
      convertToReturn: `${firstPart}/convertToReturn`,
      receipt: `${firstPart}/receipt`,
      messages: `${firstPart}/messages`,
      close: `${firstPart}/close`,
      reopen: `${firstPart}/reopen`,
      activate: `${firstPart}/activate`
    }
  },
  complaintsFile: (complaintId, messageId) => `complaints/${complaintId}/messages/${messageId}/fileUpload`,
  pointsChangesCounter: 'pointsChangeRequest/counter',
  pointsChangeRequest: {
    get: 'pointsChangeRequest',
    cancellations: 'pointsChangeRequest/cancellations',
    releases: 'pointsChangeRequest/releases',
    rejections: 'pointsChangeRequest/rejections',
    counter: 'pointsChangeRequest/counter'
  },
  inventoryChangeRequest: id => ({
    get: 'inventoryChangeRequest',
    cancellations: 'inventoryChangeRequest/cancellations',
    releases: 'inventoryChangeRequest/releases',
    rejections: 'inventoryChangeRequest/rejections',
    counter: 'inventoryChangeRequest/counter',
    pictureUpload: `inventoryChangeRequest/${id}/pictureUpload`
  }),
  shippingOrders: id => ({
    getSignature: `shippingOrders/${id}/wasPickedUp/signature`,
    setSignature: `shippingOrders/${id}/wasPickedUp`,
  }),
  inventoryBookingReasons: 'inventoryBookingReasons',
  returnBookingReasons: 'returnBookingReasons',
  administration: id => ({
    postActivity: `administration/activities`,
    activities: `administration/activities/${id}`,
    activitiesOrderSequences: `administration/activities/ordersequences`,
    entitlementPhase: `administration/entitlementphases/${id}`,
    postEntitlementPhase: `administration/entitlementphases`,
    entitlementPhasesOrderSequences: `administration/entitlementphases/ordersequences`,
    contingentTemplates: 'entitlements',
    contingentTemplatesOrderSequences: `administration/entitlements/ordersequences`,
    catalogs: `administration/articleCatalogs`,
    catalogsOrderSequences: `administration/articleCatalogs/ordersequences`
  }),
  articles: 'articles',
  contingentTemplate: (id, groupId, articleId) => ({
    create: 'administration/entitlements',
    delete: `administration/entitlements/${id}`,
    edit: `administration/entitlements/${id}`,
    copy: `administration/entitlements/${id}/copy`,
    pictureUpload: `administration/entitlements/${id}/pictureUpload`,
    picture: `administration/entitlements/${id}/picture`,
    groups: `administration/entitlements/${id}/groups`,
    groupsOrderSequences: `administration/entitlements/${id}/groups/ordersequences`,
    group: `administration/entitlements/${id}/groups/${groupId}`,
    createGroup: `administration/entitlements/${id}/groups`,
    groupPicture: `administration/entitlements/${id}/groups/${groupId}/picture`,
    groupPictureUpload: `administration/entitlements/${id}/groups/${groupId}/pictureUpload`,
    groupArticles: `administration/entitlements/${id}/groups/${groupId}/articles`,
    groupArticlesOrderSequences: `administration/entitlements/${id}/groups/${groupId}/articles/ordersequences`,
    groupArticle: `administration/entitlements/${id}/groups/${groupId}/articles/${articleId}`
  }),
  catalogs: (id, elementId, articleId) => ({
    catalog: `administration/articleCatalogs/${id}`,  // GET, PATCH, DELETE 
    officeAvailabilities: `administration/articleCatalogs/${id}/officeAvailabilities`,  // POST, DELETE 
    structures: `administration/articleCatalogs/${id}/structures`,
    structureOrderSequence: `administration/articleCatalogs/${id}/structures/ordersequences`,
    structureArticles: `administration/articleCatalogs/${id}/structures/${elementId}/articleAssignments`,
    catalogArticles: `administration/articleCatalogs/${id}/articleAssignments`,
    dimension: `administration/articleCatalogs/${id}/structures/${elementId}`,
    createDimension: `administration/articleCatalogs/${id}/structures`,
    deleteStructureArticle: `administration/articleCatalogs/${id}/structures/${elementId}/articleAssignments/${articleId}`,
    deleteCatalogArticle: `administration/articleCatalogs/${id}/articleAssignments/${articleId}`
  }),
  orderProcessTypes: 'orderProcessTypes',
  aliasTypes: 'aliasTypes',
  supplierMandators: 'supplierMandators',
  suppliers: 'suppliers',
  currentMandator: 'currentMandator',
  subMandators: 'subMandators',
  bills: 'bills',
  billingDetails: 'oac/reports/billingdetails',
  
  storehouses: storehouseId => {
    if (!storehouseId) {
      return 'storehouses'
    }
    const firstPart = `storehouses/${storehouseId}`;
    return {
      details: `${firstPart}`,
      costcenters: `${firstPart}/costcenters`,
    }
  },
}
let result = { APIBaseUrl, APIBaseUrlToken, APIBaseUrlEndpoints, APIEndpoints, AuthorizationserverTokenUrl }
export default result
