import React from 'react'
import SidebarNavigation from '../../Page/logix2020/SidebarNavigation'
import i18n from '../../../translation/i18n'

const navigationLeft = () => [
    {
        key: '#general',
        to: '#general',
        name: i18n.t("costCenterAdmin:general")
    },
    {
        key: '#access-rights',
        to: '#access-rights',
        name: i18n.t("costCenterAdmin:accessRights")
    },
    {
        key: '#interfaces',
        to: '#interfaces',
        name: i18n.t("costCenterAdmin:interfaces")
    },
]

const CostCenterSidebarNavigation = ({ active }) => {
    return (
        <SidebarNavigation options={navigationLeft()} active={active} />
    )
}

export default CostCenterSidebarNavigation