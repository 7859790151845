import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import CostCenterTree from '../components/CostCenter/CostCenterTree'
import { Navigate, useParams } from 'react-router-dom'
import { Subheader } from '../components/Page/logix2020/Subheader'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import BigFooter from '../components/Footer/BigFooter'
import styled from 'styled-components'
import getThemeValue from '../utils/getThemeValue'
import { useObserver } from 'mobx-react'
import useStores from '../stores/useStore'

const StyledContentArea = styled(ContentArea)`
   @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px)  {
    padding: 20px;
    min-height: calc(100vh - 140px);//header+navbar=140px
   }
`
const StyledWrapper = styled.div`
@media screen and (min-width: 1080px) {
  min-width: 600px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
`
const CostcenterPage = () => {
  const { shoppingBasketArticleId } = useParams()
  const { t } = useTranslation()
  const { costCenterStore } = useStores()
  const partialCostCenters = useObserver(() => costCenterStore.partialCostCenters)
  const allLoaded = useObserver(() => costCenterStore.allLoaded)

  const [selectedCostcenterId, setSelectedCostcenterId] = useState(null)

  const redirectAutomatically = useCallback(() => {
    let allowedCostCenters = partialCostCenters.filter(costcenter => costcenter.isAllowToOrder === true)
    if (allowedCostCenters.length === 1) {
      setSelectedCostcenterId(allowedCostCenters[0].id)
    }
  }, [partialCostCenters])

  useEffect(() => {
    allLoaded && redirectAutomatically()
  }, [allLoaded, redirectAutomatically])

  const handleClickCostcenter = costCenter => {
    if (costCenter.isAllowToOrder) {
      setSelectedCostcenterId(costCenter.id)
    }
  }

  if (selectedCostcenterId) {
    if (shoppingBasketArticleId) {
      //---used for CWS API from RLX News---
      return <Navigate to={`/costcenter/${selectedCostcenterId}/costcenterproducts/${shoppingBasketArticleId}`} />
    }
    else {
      return <Navigate to={`/costcenter/${selectedCostcenterId}/costcenterproducts`} />
    }
  }

  return (
    <React.Fragment>
      <Subheader className="subHeader-v1">
        <div className="subheader-title">{getThemeValue('header', 'costcenterOrder', t('CostcenterOrder:costcenterOrder'))}</div>
      </Subheader>
      <StyledContentArea >
        <h1 className='mt-5 text-center'>{t('CostcenterOrder:pageTitle')}</h1>
        <StyledWrapper>
          <CostCenterTree onClick={handleClickCostcenter} />
        </StyledWrapper>
      </StyledContentArea>
      <BigFooter />
    </React.Fragment>
  )
}

export default CostcenterPage
