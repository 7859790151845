import React, { useState } from 'react'
import OfficesTree from '../../Offices/OfficesTree'
import styled from 'styled-components'
import { PenIcon } from '../../Icons/PenIcon'
import { useTranslation } from 'react-i18next'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import apiClient from '../../../utils/api/apiClient'
import { useNavigate } from 'react-router-dom'
import { emptyGuid } from '../../../utils/emptyGuid'
import ModalComponent2 from '../../Modals/ModalComponent2'
import OverlayRightOfficeInfo from '../../Configuration/Office/OverlayRightOfficeInfo'
import { DeleteIcon } from '../../Icons/DeleteIcon'
import useStores from '../../../stores/useStore'
import MediaQuery from 'react-responsive'
import MoreOptionsButton from '../../Buttons/MoreOptionsButton'

const StyledWrapper = styled.div`

  @media screen and (min-width: 1080px) {
    max-width: calc(100% - 480px);
    min-width: 600px;
  }

  .search-wrapper{
    padding-left: 0;
    padding-right: 0;
  }

`

const EditOfficesTree = ({ onOpen }) => {
  const { t } = useTranslation()
  const [actionSelect, setActionSelect] = useState("")
  const [selectedOffice, setSelectedOffice] = useState({})
  const [showOfficeOverlay, setShowOfficeOverlay] = useState(false)
  const [btn1Loading, setBtn1Loading] = useState(false)
  const [btn2Loading, setBtn2Loading] = useState(false)
  const [error, setError] = useState("")
  const { officeStore } = useStores()
  const navigate = useNavigate();

  const reloadOfficeTree = () => {
    officeStore.resetPartialOffices()
    officeStore.getOffices(emptyGuid)
  }

  // delete function with two arguments id and recurisvely
  const deleteOfficeItem = (recursively = false) => {
    recursively ? setBtn2Loading(true) : setBtn1Loading(true)
    apiClient.deleteJson(APIEndpoints.offices(selectedOffice.id).details, { recursively })
      .then(() => {
        reloadOfficeTree()
        setActionSelect("")
        setShowOfficeOverlay(false)
      }).catch((error) => {
        setError(error.message)
      }).finally(() => {
        setBtn1Loading(false)
        setBtn2Loading(false)
      })
  }

  const copyOfficeItem = () => {
    navigate(`/officeadministration/copy/${selectedOffice.id}#general`);
  }
  const openOfficeInfo = office => {
    setSelectedOffice(office)
    setShowOfficeOverlay(true)
  }
  const openDeleteModal = (office) => {
    setSelectedOffice(office)
    setActionSelect("delete")
  }

  const openCopyModal = (office) => {
    setSelectedOffice(office)
    setActionSelect("copy")
  }
  const getContextMenuOptions = (office) => [
    {
      option: 'edit',
      optionText: t('edit'),
      optionIcon: <PenIcon />,
      onOptionSelect: () => navigate(`/officeadministration/edit/${office.id}#general`)
    },
    {
      option: 'delete',
      optionText: t('deleteButton'),
      optionIcon: <DeleteIcon />,
      onOptionSelect: () => openDeleteModal(office)
    },
  ]
  const renderExtraItemContent = (office) => (
    <MediaQuery maxWidth={1499}>
      <div className="action-buttons"  >
        <MoreOptionsButton options={getContextMenuOptions(office)} />
      </div>
    </MediaQuery>
  )

  return (
    <StyledWrapper>
      <OfficesTree
        withSearch
        onClick={openOfficeInfo}
        isClickable={() => true}
        getContextMenuOptions={getContextMenuOptions}
        renderExtraItemContent={renderExtraItemContent}
      />
      <OverlayRightOfficeInfo
        visible={showOfficeOverlay}
        onClose={() => setShowOfficeOverlay(false)}
        selectedOffice={selectedOffice}
        openDeleteModal={openDeleteModal}
        openCopyModal={openCopyModal}
      />

      {actionSelect === "delete" &&
        <ModalComponent2
          ModalTitle={t('officeAdmin:deleteOffice')}
          BodyPart1={t('officeAdmin:areYouSure')}
          bodyStyle="alert alert-danger"
          functionCancel={() => setActionSelect("")}
          showButton2={selectedOffice.hasChildren}
          button1Text={t('delete')}
          button1Function={() => { deleteOfficeItem() }}
          button1Loading={btn1Loading}
          button1Variant="danger"
          button2Text={t('officeAdmin:deleteRecursively')}
          button2Function={() => { deleteOfficeItem(true) }}
          button2Loading={btn2Loading}
          button2Variant="danger"
          error={error.message}
        />
      }

      {actionSelect === "copy" &&
        <ModalComponent2
          ModalTitle={t('officeAdmin:copyOffice')}
          BodyPart1={t('officeAdmin:areYouSureCopy')}
          bodyStyle="alert alert-primary"
          functionCancel={() => { setActionSelect("") }}
          button1Text={t('officeAdmin:copy')}
          button1Function={() => { copyOfficeItem() }}
          button1Variant="primary"
        />
      }
    </StyledWrapper>
  )
}

export default EditOfficesTree
