import React, { useState, useEffect, useMemo, memo, useCallback } from "react"
import BootstrapTable from 'react-bootstrap-table-next'
import { useTranslation } from 'react-i18next'
import UniversalTableWrapperTest from '../../../Table/UniversalTableWrapperTest'
import TableProvider, { useTableState } from '../../../Table/TableProvider'
import { Collapse } from "react-bootstrap"
import { StyledCollapseTrigger } from "../formHelper"
import { ArrowBigIcon } from "../../../Icons/ArrowBigIcon"
import { colors } from '../../../../styles/colors'
import apiClient from "../../../../utils/api/apiClient"
import { APIEndpoints } from "../../../../utils/api/apiConfig"

const CatalogsTableInner = ({
    collapsible,
    accountData,
    setAccountDataByKey
}) => {
    const { t } = useTranslation()
    const [loadedCatalogs, setLoadedCatalogs] = useState([])
    const [opened, setOpened] = useState(!collapsible)

    const { tableRef } = useTableState()


    const accessibleCatalogs = accountData.accessibleCatalogs

    const handleClickCollapse = useCallback(() => {
        setOpened(prev => !prev)
    }, [setOpened])

    useEffect(() => {
        loadCatalogs()
    }, [])

    const loadCatalogs = async () => {
        let result = await apiClient.getJson(APIEndpoints.accessibleArticleCatalogs)
        if (result) {
            setLoadedCatalogs(result)
        }
    }

    const handleCheckedBox = useCallback(id => {
        var newValues = [...accessibleCatalogs]
        let foundIndex = newValues.indexOf(id);
        if (foundIndex > -1) {
            newValues.splice(foundIndex, 1)
        } else {
            newValues.push(id)
        }
        setAccountDataByKey("accessibleCatalogs", newValues)
    }, [accessibleCatalogs, setAccountDataByKey])

    const columns = useMemo(() => ([
        {
            dataField: 'isAssigned',
            text: t('costCenterAdmin:isAssigned'),
            sort: true,
            formatter: (cell, row, rowIndex, extraProps) => (
                <span>
                    <input type="checkbox" id={row.id} name="scales"
                        checked={cell} onChange={() => { }}>
                    </input>
                </span>
            ), formatExtraData: {
                handleCheckedBox
            }
        },
        {
            dataField: 'displayName',
            text: t('header:articleCatalog'),
            sort: true,
        },
    ]), [handleCheckedBox, t])

    const preparedData = useMemo(() => {
        return loadedCatalogs.map(item => ({
            ...item, isAssigned: accessibleCatalogs.includes(item.id)
        }))
    }, [accessibleCatalogs, loadedCatalogs])

    const onRowClick = (cell, row, rowIndex) => {
        handleCheckedBox(row.id)
    }
    return (
        <>
            {collapsible ? (
                <StyledCollapseTrigger onClick={handleClickCollapse}>
                    <h2 className="h1"><ArrowBigIcon variant={opened ? 'down' : 'right'} color={colors.textColor} />{t('header:articleCatalog')}
                    </h2>
                </StyledCollapseTrigger>
            ) : (
                <h2 className="h1">{t('header:articleCatalog')}</h2>
            )}
            <Collapse in={opened}>
                <div className="spacing-down">
                    <UniversalTableWrapperTest  >
                        <BootstrapTable
                            ref={tableRef}
                            noDataIndication={() => <div className="no-data-placeholder">{t('officeAdmin:noGroupAssignments')}</div>}
                            bootstrap4
                            keyField="id"
                            hover
                            data={preparedData}
                            columns={columns}
                            bordered={false}
                            rowEvents={{ onClick: onRowClick }}
                        />
                    </UniversalTableWrapperTest>
                </div>
            </Collapse>
        </>
    )
}
const CatalogsTable = memo(({ ...rest }) => {
    return (
        <TableProvider >
            <CatalogsTableInner  {...rest} />
        </TableProvider>
    )
})
export default CatalogsTable
