import React, { useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Form, Alert } from 'react-bootstrap'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ButtonWithLoadingState } from '../Buttons'
import SafelySetInnerHTML from '../SafelySetInnerHTML'
import { colors } from '../../styles/colors'
import MandatoryField from '../MandatoryField/MandatoryField'
import AccountData from './AccountData'
import { StyledFormHeadline } from './RegistrationStyles'
import PersonalData from './PersonalData'
import ContactData from './ContactData'
import AddressData from './AddressData'
import GenderData from './GenderData'
import Additional from './Additional'
import OfficeAddressData from './OfficeAddressData'

const StyledRegistrationBoxHeadline = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: white;

  @media screen and (min-width: 576px) {
    font-size: 2.5rem;
  }
`

const StyledRegistrationButtonWrap = styled.div`
  margin-top: 0.5rem;

  button {
    border: black;
    background-color: white;
    color: black;
  }

  button:hover {
    background-color: black;
    color:white;
  }
`
const StyledInfoText = styled.div`
  background: ${colors.gray8};
  color: white;
  padding: 2rem;
  border-radius: 5px;
`


const RegistrationBox = inject('registrationStore', 'uiStore')(observer((props) => {
  const { registrationStore } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const registrationConfig = theme.registrationConfig ? theme.registrationConfig() : null

  function handleOnSubmit(e) {
    e.preventDefault()
    props.registrationStore.handleRegistrationSubmit()
  }

  useEffect(() => {
    if (registrationConfig) {
      registrationStore.loadCountries()
      registrationStore.initRegistrationConfig(registrationConfig)
    }
    return () => {
      registrationStore.clearData()
    }
    //eslint-disable-next-line
  }, [])

  const registrationButton = theme.userLogin.registrationButton || false
  if (!registrationButton) {
    return <Navigate to={"/login"} replace />
  }

  return (
    <div className="styledBox largeBox">
      <StyledRegistrationBoxHeadline>{registrationConfig.headline}</StyledRegistrationBoxHeadline>
      {registrationConfig.infoText
        ? <StyledInfoText><SafelySetInnerHTML>{registrationConfig.infoText}</SafelySetInnerHTML></StyledInfoText>
        : null}
      <Form id="registrationForm" onSubmit={handleOnSubmit} >
        {registrationConfig.accountData
          ? <AccountData registrationConfig={registrationConfig} />
          : null}
        {registrationConfig.personalData
          ? <PersonalData registrationConfig={registrationConfig} />
          : null}
        {registrationConfig.contactData
          ? <ContactData registrationConfig={registrationConfig} />
          : null}
        {registrationConfig.addressData
          ? <AddressData registrationConfig={registrationConfig} />
          : null}
        {registrationConfig.officeAddressData
          ? <OfficeAddressData registrationConfig={registrationConfig} />
          : null}
        {registrationConfig.genderData
          ? <GenderData registrationConfig={registrationConfig} />
          : null}
        {registrationConfig.additional
          ? <Additional registrationConfig={registrationConfig} />
          : null}
        {
          registrationConfig.checkboxes.map((checkboxObj) => {
            return (
              <Form.Group className="mb-3" key={checkboxObj.id}>
                <StyledFormHeadline>{checkboxObj.headline}</StyledFormHeadline>
                <Form.Check
                  onChange={registrationStore.handleCheckbox}
                  isInvalid={registrationStore.formErrors[checkboxObj.id]}
                  checked={registrationStore.checkboxes[checkboxObj.id] || false}
                  id={checkboxObj.id}
                  name={checkboxObj.id}
                  label={<div className={registrationStore.formErrors[checkboxObj.id] ? "text-red" : "text-white"}>
                    <SafelySetInnerHTML>{checkboxObj.label}</SafelySetInnerHTML>
                  </div>}
                />
              </Form.Group>)
          })
        }

        {(registrationStore.formErrors.account || registrationStore.formErrors.employee || registrationStore.formErrors.address || registrationStore.formErrors.email || registrationStore.formErrors.phone) &&
          <Alert variant='danger' >
            {registrationStore.formErrors.account && <p>{registrationStore.formErrors.account}</p>}
            {registrationStore.formErrors.employee && <p>{registrationStore.formErrors.employee}</p>}
            {registrationStore.formErrors.address && <p>{registrationStore.formErrors.address}</p>}
            {registrationStore.formErrors.email && <p>{registrationStore.formErrors.email}</p>}
            {registrationStore.formErrors.phone && <p>{registrationStore.formErrors.phone}</p>}
          </Alert>
        }
        {registrationStore.mainError
          && <Alert variant='danger'>
            {t('registrationBox:somethingWentWrong')}
          </Alert>
        }
        {registrationStore.mainSuccess
          && <Alert variant='success'>
            {t('registrationBox:savingSuccess')}
          </Alert>
        }

        <div className='d-flex justify-content-between'>
          <Form.Text>
            <Link to='/login'>{t('loginBox:Login')}</Link>
          </Form.Text>
          <StyledRegistrationButtonWrap className="login-button">
            <ButtonWithLoadingState
              buttonText={t('registrationBox:registerNow')}
              isLoading={registrationStore.isRegistrationButtonLoading}
              type='submit'
              variant='primary'
            />
          </StyledRegistrationButtonWrap>
        </div>
        < MandatoryField color={"white"} />
      </Form>
    </div >
  )
}))

export default RegistrationBox
