import React from 'react'
import PositionItem from './PositionItem'
import PositionsListHeader from './PositionsListHeader'

const PositionsList = ({ positions, allowOrderWasPickedUp, incomingOrder, orderInfo, index, positionsChecked, handleChangeChecked, handleChangeAll, changeAllIsChecked, hasCheckablePositions, resetOrderPage }) => {
  if (!positions.length) {
    return null
  }
  return (
    <div className='mb-5'>
      <PositionsListHeader
        incomingOrder={incomingOrder}
        orderInfo={orderInfo}
        index={index}
        changeAllIsChecked={changeAllIsChecked}
        changeAll={handleChangeAll}
        showChangeAllCheckbox={hasCheckablePositions}
        allowOrderWasPickedUp={allowOrderWasPickedUp}
        resetOrderPage={resetOrderPage}
      />
      {positions.map(orderPosition => (
        <PositionItem key={orderPosition.id}
          orderPosition={orderPosition}
          incomingOrder={incomingOrder}
          isChecked={positionsChecked[orderPosition.id] || false}
          changeChecked={handleChangeChecked}
          showCheckbox={hasCheckablePositions}
        />
      ))}
    </div>
  )
}


export default PositionsList;
