import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next'

const ModalComponent = (props) => {
    const {
        ModalTitle,
        functionButton1 = () => { },
        functionButton2 = () => { },
        BodyPart1,
        BodyPart2,
        textButton1,
        textButton2,
        variantButton1,
        variantButton2,
        variantBody,
        showButton2,
        functionCancel = () => { },
        withInput,
        inputLabel,
        inputType,
        inputIsMandatory,
        withoutCloseButton,
        inputContent = "",
        inputRows,
        inputMaxLength,
        backdrop = "static"
    } = props

    const [testMessage, setTextMessage] = useState(inputContent)
    const handleClose = () => {
        functionCancel()
    }
    const { t } = useTranslation()

    const clickButton1 = () => {
        if (withInput) {
            functionButton1(testMessage)
        }
        else { functionButton1() }
        handleClose()
    }
    const clickButton2 = () => {
        functionButton2()
        handleClose()
    }

    return (
        <>
            <Modal style={{ textAlign: "center" }} centered show={true} onHide={handleClose} backdrop={backdrop}>
                <Modal.Header closeButton={withoutCloseButton ? false : true}>
                    <Modal.Title >{ModalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`alert ${variantBody} mb-0`}>{/* alert-danger */}
                    {BodyPart1 ? <>{BodyPart1}<br /><br /></> : null}
                    {BodyPart2}
                    {withInput && <Form.Group className="mb-3">
                        <Form.Label>  {inputLabel}</Form.Label>
                        <Form.Control
                            as={inputType ? inputType : "textarea"}
                            name="name"
                            onChange={e => { setTextMessage(e.target.value) }}
                            value={testMessage}
                            maxLength={inputMaxLength}
                            rows={inputRows}
                        />
                    </Form.Group>}
                </Modal.Body >
                <Modal.Footer >
                    {withoutCloseButton ? null : <Button variant="secondary" onClick={handleClose}>
                        {t('appointment:cancel')}
                    </Button>}
                    {withInput ?
                        <Button disabled={inputIsMandatory && !testMessage} variant={variantButton1} onClick={clickButton1}>
                            {textButton1}
                        </Button>
                        :
                        <Button variant={variantButton1} onClick={clickButton1}>
                            {textButton1}
                        </Button>
                    }
                    {showButton2 &&
                        <Button variant={variantButton2} onClick={clickButton2}>
                            {textButton2}
                        </Button>
                    }
                </Modal.Footer>
            </Modal >
        </>
    );
};

export default ModalComponent;
