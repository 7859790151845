import React, { useState } from 'react'
import { useObserver } from 'mobx-react'
import styled, { useTheme } from 'styled-components'
import useStores from '../../../stores/useStore'
import { colors } from '../../../styles/colors'
import { useTranslation } from 'react-i18next'
import { PenIcon } from '../../Icons/PenIcon';
import EditPointsModal from './EditPointsModal';

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px 0;
`
const StyledItemWrapper = styled.div`
  //min-width: 230px;
  margin-top: 5px;
  flex: 0 0 230px;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  font-size: 12px;
  line-height: 1.4;
  max-width: ${props => props.showJobFunction ? '690px' : '460px'};
`

const StyledLabel = styled.div`
  text-transform: uppercase;
  font-weight: bold;
`

const StyledValue = styled.div`
  color: ${colors.secondary}
`
const StyledEditIconWrapper = styled.div`
padding:4px;
cursor:pointer;
`
const EmployeeData = (props) => {
  const { productsStore, uiStore } = useStores()
  const targetEmployee = useObserver(() => productsStore.targetEmployee)
  const [showEditPointsModal, setShowEditPointsModal] = useState(false)
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <>
      <StyledContainer {...props} >
        <StyledWrapper showJobFunction={props.showJobFunction}>
          {props.showJobFunction ?
            <StyledItemWrapper>
              <StyledLabel>{t('Overlay:jobFunction')}</StyledLabel>
              <StyledValue className='value-color'>{productsStore.activities[0].displayName}&nbsp;</StyledValue>
            </StyledItemWrapper> : ''}
          <StyledItemWrapper>
            <StyledLabel>{t('OrderSubheader:personalnr')}.</StyledLabel>
            <StyledValue className='value-color'>{targetEmployee.personalNr}&nbsp;</StyledValue>
          </StyledItemWrapper>
          <StyledItemWrapper>
            <StyledLabel>{t('OrderSubheader:costcenter')}</StyledLabel>
            <StyledValue className='value-color'>{targetEmployee.costCenter && targetEmployee.costCenter.displayName}&nbsp;</StyledValue>
          </StyledItemWrapper>
          <StyledItemWrapper>
            <StyledLabel>{t('OrderSubheader:orgaUnit')}</StyledLabel>
            <StyledValue className='value-color'>{targetEmployee.office && targetEmployee.office.displayName}&nbsp;</StyledValue>
          </StyledItemWrapper>
          {targetEmployee.points != null ? (
            <StyledItemWrapper>
              <div className="d-flex align-items-center">
                <div>
                  <StyledLabel>{t('OrderSubheader:accountBallance')}</StyledLabel>
                  <StyledValue className='value-color'>{targetEmployee.points} {t('points')}</StyledValue>
                </div>
                {uiStore.allowChangePoints ? <StyledEditIconWrapper onClick={() => setShowEditPointsModal(prev => !prev)}><PenIcon color={theme.primaryColor} title={t('OrderSubheader:editPoints')} /></StyledEditIconWrapper> : null}
              </div>
            </StyledItemWrapper>
          ) : ''}
        </StyledWrapper>
      </StyledContainer>
      {showEditPointsModal && <EditPointsModal allowReleasePointsChanges={uiStore.allowReleasePointsChanges} employeeId={targetEmployee.id} points={targetEmployee.points} handleClose={() => setShowEditPointsModal(false)} />}
    </>
  )
}

export default EmployeeData
