import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useParams } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next'

import { useEntitlementPhasesState } from '../../../stores/Configuration/EntitlementPhasesProvider'
import UniversalTableWrapperTest from '../../Table/UniversalTableWrapperTest'
import { useTableState } from '../../Table/TableProvider'
import { ButtonWithLoadingState } from '../../Buttons'
import { useContingentTemplatesState } from '../../../stores/Configuration/ContingentTemplatesProvider'


const ContingentTemplatePhases = () => {
  const { assignedPhases, setAssignedPhases, initAssignedPhases, assignedPhasesHasChanged, patchContingentTemplate, loadingStates } = useContingentTemplatesState()
  const { entitlementPhases } = useEntitlementPhasesState()
  const { tableRef } = useTableState()
  const [checkedPhases, setCheckedPhases] = useState([])
  const { contingentTemplateId } = useParams()

  useEffect(() => {
    return () => initAssignedPhases()
  }, [initAssignedPhases])

  // [{ id: "", isAssigned: bool, displayName: "" }]
  useEffect(() => {
    if (entitlementPhases && assignedPhases) {
      const newCheckedPhases = []
      for (const obj of entitlementPhases) {
        newCheckedPhases.push({ id: obj.id, isAssigned: assignedPhases.includes(obj.id), displayName: obj.displayName })
      }
      setCheckedPhases(newCheckedPhases)
    }
  }, [entitlementPhases, assignedPhases])

  const columns = [
    {
      dataField: 'isAssigned',
      text: t('assigned'), // column header
      sort: true,
      formatter: (cell, row) => {
        return <input type="checkbox" checked={cell} aria-label="If checked, phase will be assigned." onChange={() => { }} id={row.id} />  // return checkbox if the checkbox is checked use the cell true or false
      }
    },
    {
      dataField: 'displayName',
      text: t('contingentTemplates:attributionPhase'),
      sort: true,
    }
  ]

  const handleChangeCheckBox = id => {
    if (assignedPhases.includes(id)) {
      let index = assignedPhases.indexOf(id);
      let newValues = [...assignedPhases]
      newValues.splice(index, 1);
      setAssignedPhases(newValues)
    }
    else {
      setAssignedPhases(prev => [...prev, id])
    }
  }

  const onRowClick = (cell, row, rowIndex) => {
    handleChangeCheckBox(row.id)
  }

  const handleSubmit = () => {
    patchContingentTemplate(contingentTemplateId)
  }

  return (
    <div>
      <UniversalTableWrapperTest tableIsLoading={loadingStates.getContingentTemplate}>
        <BootstrapTable
          ref={tableRef}
          noDataIndication={() => <div className="no-data-placeholder">{!loadingStates.getContingentTemplate && t('contingentTemplates:noPhasesAvailable')}</div>}
          loading={loadingStates.getContingentTemplate}
          bootstrap4
          keyField="id"
          hover
          data={checkedPhases}
          columns={columns}
          bordered={false}
          rowEvents={{ onClick: onRowClick }}
        />
      </UniversalTableWrapperTest>
      <ButtonWithLoadingState
        disabled={!assignedPhasesHasChanged}
        className="my-3"
        buttonText={t('saveButton')}
        isLoading={loadingStates.saveContingentTemplate}
        onClick={handleSubmit}
      />
    </div>
  )
}

export default ContingentTemplatePhases