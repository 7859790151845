import React, { useState, useMemo, useCallback } from 'react'
import { cloneDeep } from 'lodash'

import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { makeCall, setStateByKey } from '../../utils/api/makeCall'
import { useCancelToken } from '../../utils/hooks/useCancelToken'
import { waitForPromise } from '../../utils/utils'

// change the template obj with correct data
const contingentTemplateObj = {
  name: {
    values: []
  }
}

const ContingentTemplatesContext = React.createContext({})
export const useContingentTemplatesState = () => {
  return React.useContext(ContingentTemplatesContext)
}

const ContingentTemplatesProvider = ({ children }) => {

  const [errorStates, setErrorStates] = useState({})
  const [loadingStates, setLoadingStates] = useState({})
  const [successStates, setSuccessStates] = useState({})
  const [bigData, setBigData] = useState(null)
  const [assignedActivities, setAssignedActivities] = useState([])
  const [assignedPhases, setAssignedPhases] = useState([])
  const [contingentTemplate, setContingentTemplate] = useState(contingentTemplateObj)
  const [initialContingentTemplate, setInitialContingentTemplate] = useState(JSON.stringify(contingentTemplateObj))
  const [contingentTemplatePicture, setContingentTemplatePicture] = useState(null)
  const [groupPicture, setGroupPicture] = useState(null)
  const [redirectId, setRedirectId] = useState('')
  const [initialAssignedActivities, setInitialAssignedActivities] = useState(null)
  const [initialAssignedPhases, setInitialAssignedPhases] = useState(null)
  const [getSelectedArticles, setGetSelectedArticles] = useState([])

  const { createOrCancelToken, sourceRef, isCancel } = useCancelToken();

  const contingentTemplateHasChanged = useMemo(() => {
    return initialContingentTemplate !== JSON.stringify(contingentTemplate)
  }, [initialContingentTemplate, contingentTemplate])

  const assignedActivitiesHasChanged = useMemo(() => {
    return initialAssignedActivities !== JSON.stringify(assignedActivities)
  }, [initialAssignedActivities, assignedActivities])

  const assignedPhasesHasChanged = useMemo(() => {
    return initialAssignedPhases !== JSON.stringify(assignedPhases)
  }, [initialAssignedPhases, assignedPhases])
  const getContingentTemplates = useCallback(async (force) => {
    if ((bigData && Object.keys(bigData).length) && !force) {
      return bigData
    }
    else if (loadingStates.contingentTemplates) {
      await waitForPromise(100)
      return await getContingentTemplates()
    }
    else {
      let newBigData = {}
      await makeCall('contingentTemplates', async () => {
        let loadedContingentTemplates = await apiClient.getJson(APIEndpoints.administration().contingentTemplates)
        for (let template of loadedContingentTemplates) {
          let newValue = template
          newValue.groups = {}
          newBigData[template.id] = newValue
        }
        setBigData(newBigData)
      }, setErrorStates, setLoadingStates)
      return newBigData
    }
  }, [bigData, loadingStates.contingentTemplates])

  const getGroups = useCallback(async (entitlementId, force) => {
    let loadedBigData = await getContingentTemplates()
    if (loadedBigData[entitlementId].groupsLoaded && !force) {
      return null
    }
    await makeCall('groups', async () => {
      let loadedGroups = await apiClient.getJson(APIEndpoints.contingentTemplate(entitlementId).groups)
      let newGroups = {}
      for (let group of loadedGroups) {
        let newValue = group
        newValue.articles = {}
        newGroups[group.id] = newValue
      }
      setBigData(prev => {
        let newData = cloneDeep(prev)
        newData[entitlementId].groups = newGroups
        newData[entitlementId].groupsLoaded = true
        return newData
      })

    }, setErrorStates, setLoadingStates)
  }, [getContingentTemplates])

  async function deleteGroup(entitlementId, groupId) {
    await makeCall('deleteGroup', async () => {
      await apiClient.deleteJson(APIEndpoints.contingentTemplate(entitlementId, groupId).group)
      await getGroups(entitlementId, true)
      setStateByKey(setSuccessStates, 'deleteGroup', true)
    }, setErrorStates, setLoadingStates)
  }

  async function postGroup(entitlementId, group, files, handleClose) {
    const params = cloneDeep(group)
    params.entitlementId = entitlementId
    //redirect will be after the call is successful
    await makeCall('saveGroup', async () => {
      await apiClient.postFile(APIEndpoints.contingentTemplate(entitlementId).createGroup, { data: JSON.stringify(params) }, files)
      getGroups(entitlementId, true)
      setStateByKey(setSuccessStates, 'saveGroup', true)
      handleClose()
    }, setErrorStates, setLoadingStates)
  }

  async function patchGroup(entitlementId, groupId, group, handleClose) {
    await makeCall('saveGroup', async () => {
      await apiClient.patchJson(APIEndpoints.contingentTemplate(entitlementId, groupId).group, group)
      getGroups(entitlementId, true)
      setStateByKey(setSuccessStates, 'saveGroup', true)
      handleClose()
    }, setErrorStates, setLoadingStates)
  }




  async function getContingentTemplate(contingentTemplateId) {
    await makeCall('getContingentTemplate', async () => {
      let loadedContingentTemplate = await apiClient.getJson(APIEndpoints.contingentTemplate(contingentTemplateId).edit)
      setInitialContingentTemplate(JSON.stringify(loadedContingentTemplate.entitlement))
      setInitialAssignedActivities(JSON.stringify(loadedContingentTemplate.assignedActivities))
      setInitialAssignedPhases(JSON.stringify(loadedContingentTemplate.assignedPhases))
      setContingentTemplate(loadedContingentTemplate.entitlement)
      setAssignedActivities(loadedContingentTemplate.assignedActivities)
      setAssignedPhases(loadedContingentTemplate.assignedPhases)
    }, setErrorStates, setLoadingStates)
  }

  async function putContingentTemplatesOrderSequence(newSequence) {
    createOrCancelToken()
    makeCall('saveOrderSequence', async () => {
      await apiClient.putJson(APIEndpoints.administration().contingentTemplatesOrderSequences, newSequence, true, false, { cancelToken: sourceRef.current.token })
      await getContingentTemplates(true)
    }, setErrorStates, setLoadingStates, isCancel)
  }

  function postContingentTemplate(files) {
    let params = { entitlement: contingentTemplate, assignedActivities, assignedPhases }
    //redirect will be after the call is successful
    makeCall('saveContingentTemplate', async () => {
      let newContingentTemplate = await apiClient.postFile(APIEndpoints.contingentTemplate().create, { data: JSON.stringify(params) }, files)
      await getContingentTemplates(true)
      setRedirectId(newContingentTemplate.entitlement.id)
      setStateByKey(setSuccessStates, 'saveContingentTemplate', true)
    }, setErrorStates, setLoadingStates)
  }

  function patchContingentTemplate(contingentTemplateId) {
    makeCall('saveContingentTemplate', async () => {
      await apiClient.patchJson(APIEndpoints.contingentTemplate(contingentTemplateId).edit, { entitlement: contingentTemplate, assignedActivities, assignedPhases })
      await getContingentTemplate(contingentTemplateId)
      getContingentTemplates(true)
      setStateByKey(setSuccessStates, 'saveContingentTemplate', true)
    }, setErrorStates, setLoadingStates)
  }

  async function deleteContingentTemplate(contingentTemplateId) {
    await makeCall('deleteContingentTemplate', async () => {
      await apiClient.deleteJson(APIEndpoints.contingentTemplate(contingentTemplateId).delete)
      await getContingentTemplates(true)
      setStateByKey(setSuccessStates, 'deleteContingentTemplate', true)
    }, setErrorStates, setLoadingStates)
  }
  async function copyContingentTemplate(contingentTemplateId) {
    await makeCall('copyContingentTemplate', async () => {
      await apiClient.postJson(APIEndpoints.contingentTemplate(contingentTemplateId).copy)
      await getContingentTemplates(true)
      setStateByKey(setSuccessStates, 'copyContingentTemplate', true)
    }, setErrorStates, setLoadingStates)
  }




  function resetStates() {
    setErrorStates({})
    setLoadingStates({})
    setSuccessStates({})
  }

  const resetContingentTemplatesPage = useCallback(() => {
    resetStates()
    //getContingentTemplates(true)
    setContingentTemplate(cloneDeep(contingentTemplateObj))
    setInitialContingentTemplate(JSON.stringify(contingentTemplateObj))
    setAssignedActivities([])
    setAssignedPhases([])
    setRedirectId('')
  }, [])

  const initAssignedActivities = useCallback(() => {
    setAssignedActivities(JSON.parse(initialAssignedActivities) || [])
    resetStates()
  }, [initialAssignedActivities])

  const initAssignedPhases = useCallback(() => {
    setAssignedPhases(JSON.parse(initialAssignedPhases) || [])
    resetStates()
  }, [initialAssignedPhases])

  function initContingentTemplates(contingentTemplateId) {
    setContingentTemplate(contingentTemplateId ? JSON.parse(initialContingentTemplate) : cloneDeep(contingentTemplateObj))
    resetStates()
  }

  function initGroup() {
    setGroupPicture(null)
    resetStates()
  }

  async function getPicture(contingentTemplateId) {
    await makeCall('savePicture', async () => {
      let loadedContingentTemplatePicture = await apiClient.getJson(APIEndpoints.contingentTemplate(contingentTemplateId).picture)
      setContingentTemplatePicture(loadedContingentTemplatePicture)
    }, setErrorStates, setLoadingStates)
  }
  function uploadPicture(contingentTemplateId, files) {
    makeCall('savePicture', async () => {
      await apiClient.postFile(APIEndpoints.contingentTemplate(contingentTemplateId).pictureUpload, {}, files)
      await getPicture(contingentTemplateId)
    }, setErrorStates, setLoadingStates)
  }
  function deletePicture(contingentTemplateId) {
    makeCall('savePicture', async () => {
      await apiClient.deleteJson(APIEndpoints.contingentTemplate(contingentTemplateId).picture)
      await getPicture(contingentTemplateId)
    }, setErrorStates, setLoadingStates)
  }
  async function getGroupPicture(contingentTemplateId, groupId) {
    await makeCall('saveGroupPicture', async () => {
      let loadedGroupPicture = await apiClient.getJson(APIEndpoints.contingentTemplate(contingentTemplateId, groupId).groupPicture)
      setGroupPicture(loadedGroupPicture)
    }, setErrorStates, setLoadingStates)
  }
  function uploadGroupPicture(contingentTemplateId, groupId, files) {
    makeCall('saveGroupPicture', async () => {
      await apiClient.postFile(APIEndpoints.contingentTemplate(contingentTemplateId, groupId).groupPictureUpload, {}, files)
      await getGroupPicture(contingentTemplateId, groupId)
    }, setErrorStates, setLoadingStates)
  }
  function deleteGroupPicture(contingentTemplateId, groupId) {
    makeCall('saveGroupPicture', async () => {
      await apiClient.deleteJson(APIEndpoints.contingentTemplate(contingentTemplateId, groupId).groupPicture)
      await getGroupPicture(contingentTemplateId, groupId)
    }, setErrorStates, setLoadingStates)
  }

  async function putGroupsOrderSequenceBackground(contingentTemplateId, newSequence) {
    createOrCancelToken();
    makeCall('saveOrderSequence', async () => {
      await apiClient.putJson(APIEndpoints.contingentTemplate(contingentTemplateId).groupsOrderSequences, newSequence, true, false, { cancelToken: sourceRef.current.token })
      await getGroups(contingentTemplateId, true)
    }, setErrorStates, setLoadingStates, isCancel)
  }
  async function putArticlesOrderSequenceBackground(contingentTemplateId, groupId, newSequence) {
    createOrCancelToken();
    makeCall('saveOrderSequence', async () => {
      await apiClient.putJson(APIEndpoints.contingentTemplate(contingentTemplateId, groupId).groupArticlesOrderSequences, newSequence, true, false, { cancelToken: sourceRef.current.token })
      await getGroupArticles(contingentTemplateId, groupId)
    }, setErrorStates, setLoadingStates, isCancel)
  }

  const getGroupArticles = useCallback(async (entitlementId, groupId) => {
    await makeCall('groupArticles', async () => {
      let loadedArticles = await apiClient.getJson(APIEndpoints.contingentTemplate(entitlementId, groupId).groupArticles)
      let newArticles = {}
      for (let article of loadedArticles) {
        newArticles[article.id] = article
      }
      setBigData(prev => {
        prev[entitlementId].groups[groupId].articles = newArticles
        prev[entitlementId].groups[groupId].articlesLoaded = true
        return cloneDeep(prev)
      }
      )
    }, setErrorStates, setLoadingStates)
  }, [])

  async function deleteGroupArticle(entitlementId, groupId, articleId) {
    await makeCall('deleteArticle', async () => {
      await apiClient.deleteJson(APIEndpoints.contingentTemplate(entitlementId, groupId, articleId).groupArticle)
      await getGroupArticles(entitlementId, groupId)
    }, setErrorStates, setLoadingStates)
  }

  const loadAssignedGroups = useCallback(async (entitnlementIds) => {
    //step1 load groups 
    let promises = []
    for (let entitlementId of entitnlementIds) {
      if (!bigData[entitlementId] || !bigData[entitlementId].groupsLoaded) {
        promises.push(apiClient.getJson(APIEndpoints.contingentTemplate(entitlementId).groups))
      }
      else { promises.push(new Promise((resolve) => { resolve(Object.values(bigData[entitlementId].groups)) })) }
    }


    await Promise.all(promises).then(responses => {
      setBigData(prev => {
        let index = 0
        for (let entitlementId of entitnlementIds) {
          let newGroups = {}
          for (let group of responses[index]) {
            let newValue = group
            newValue.articles = group.articles || {}
            newValue.open = false
            newGroups[group.id] = newValue
          }
          prev[entitlementId].groups = newGroups
          prev[entitlementId].open = true
          prev[entitlementId].groupsLoaded = true

          index++
        }
        return cloneDeep(prev)
      })

    })
  }, [bigData, setBigData])
  const [assignmentTree, setAssignmentTree] = useState({})

  const createAssignmentTree = useCallback((assignments) => {
    let newAssignmentTree = {}
    for (let ass of assignments) {
      if (!newAssignmentTree[ass.entitlementId]) {
        newAssignmentTree[ass.entitlementId] = { groups: {} }
      }
      if (!newAssignmentTree[ass.entitlementId].groups[ass.groupId]) {
        newAssignmentTree[ass.entitlementId].groups[ass.groupId] = { articles: {} }
      }
      if (!newAssignmentTree[ass.entitlementId].groups[ass.groupId].articles[ass.articleId]) {
        newAssignmentTree[ass.entitlementId].groups[ass.groupId].articles[ass.articleId] = 42
      }
    }
    setAssignmentTree(newAssignmentTree)
  }, [setAssignmentTree])

  const getArticleAssignments = useCallback(async (articleId) => {
    makeCall('articleAssignments', async () => {
      let assignments = await apiClient.getJson(APIEndpoints.articleEntitlementAssignments(articleId))
      let entitnlementIds = [...new Set(assignments.map(a => a.entitlementId))]
      await loadAssignedGroups(entitnlementIds)
      createAssignmentTree(assignments)
    }, setErrorStates, setLoadingStates)
  }, [setErrorStates, setLoadingStates, createAssignmentTree, loadAssignedGroups])

  const contextValues = {
    getArticleAssignments,
    errorStates,
    loadingStates,
    successStates, setSuccessStates,
    contingentTemplate, setContingentTemplate,
    redirectId,
    uploadPicture,
    deletePicture,
    getPicture,
    getContingentTemplates,
    getContingentTemplate,
    postContingentTemplate,
    patchContingentTemplate,
    deleteContingentTemplate,
    copyContingentTemplate,
    resetContingentTemplatesPage,
    putContingentTemplatesOrderSequence,
    assignedActivities, setAssignedActivities,
    contingentTemplatePicture,
    contingentTemplateHasChanged,
    assignedActivitiesHasChanged, initAssignedActivities,
    getGroups,
    deleteGroup,
    assignedPhases, setAssignedPhases,
    assignedPhasesHasChanged,
    initAssignedPhases,
    initContingentTemplates,
    postGroup,
    patchGroup,
    getSelectedArticles,
    setGetSelectedArticles,
    initGroup,
    groupPicture, setGroupPicture, getGroupPicture, uploadGroupPicture, deleteGroupPicture,
    putGroupsOrderSequenceBackground,
    putArticlesOrderSequenceBackground,
    bigData, setBigData,
    getGroupArticles,
    deleteGroupArticle,
    assignmentTree, setAssignmentTree
  }

  return (
    <ContingentTemplatesContext.Provider value={contextValues}>
      {children}
    </ContingentTemplatesContext.Provider>
  )
}

export default ContingentTemplatesProvider