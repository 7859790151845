import React, { useCallback, useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { useTranslation } from 'react-i18next'

import { useBillsState } from '../../stores/BillsProvider'
import TableProvider, { useTableState } from '../Table/TableProvider'
import UniversalTableWrapperTest from '../Table/UniversalTableWrapperTest'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { DownloadIcon } from '../Icons/DownloadIcon'
import { EmailIcon } from '../Icons/EmailIcon'
import BillsEmailModal from './BillsEmailModal'
import { useCancelToken } from '../../utils/hooks/useCancelToken'


const BillsTableInner = () => {
    const { t } = useTranslation()
    const { filter } = useBillsState()
    const [bills, setBills] = useState(null)
    const [bill, setBill] = useState(null)
    const [tableIsLoading, setTableIsLoading] = useState(true)
    const [showEmailModal, setShowEmailModal] = useState(false)
    const { tableRef, paginationOptions, pageLimit, setPageLimit, pageOffset, setPageOffset, setTotalCount, sort, setSort, currentPage, totalPages } = useTableState()
    const { createOrCancelToken, sourceRef, isCancel } = useCancelToken();


    const fetchBills = useCallback(() => {
        createOrCancelToken()
        setTableIsLoading(true)

        const params = { 'page.limit': pageLimit, 'page.offset': pageOffset, ...filter }

        if (sort) {
            params.sort = sort
        }

        apiClient.getJson(APIEndpoints.bills, params, true, true, true, { cancelToken: sourceRef.current.token })
            .then(response => {
                setBills(response.data)
                setTotalCount(Number(response.headers['x-total-count']))
            }).catch((e) => {
                if (isCancel(e)) {
                    console.log('canceled by cancelToken')
                }
            }).finally(() => {
                setTableIsLoading(false)
            })
    }, [filter, pageLimit, pageOffset, setTotalCount, sort, createOrCancelToken, sourceRef, isCancel])

    useEffect(() => {
        setPageOffset(0)
    }, [filter, setPageOffset])

    useEffect(() => {
        fetchBills(pageLimit, pageOffset, filter, sort)
    }, [pageLimit, pageOffset, filter, sort, fetchBills])

    const handleClickReportDownload = (bill) => {
        apiClient.postJson(APIEndpoints.billingDetails, { billId: bill.id }, true, true, { responseType: 'blob' })
            .then(response => {
                // Extract filename from response headers
                const contentDisposition = response.headers['content-disposition']
                const filename = contentDisposition.split(' filename=')[1].replace(/"/g, '')

                // Create a Blob object from the response data
                const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })

                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob)

                // Create a link element and trigger download
                const a = document.createElement('a')
                a.href = url
                a.download = filename
                document.body.appendChild(a)
                a.click()

                // Clean up
                window.URL.revokeObjectURL(url)
                document.body.removeChild(a)
            }).catch(error => {
                console.log('Failed to download report:', error)
            })
    }

    const handleClickReportSend = (bill) => {
        setBill(bill)
        setShowEmailModal(true)
    }

    const handleTableChange = (type, newState) => {
        setPageLimit(newState.sizePerPage)
        setPageOffset(newState.page === 1 ? 0 : newState.sizePerPage * newState.page - newState.sizePerPage)

        if (type === 'sort') {
            const { sortOrder, sortField } = newState;
            setSort(`${sortOrder === 'asc' ? '-' : ''}${sortField}`)
        }
    }

    const columns = [
        {
            dataField: 'billNumber',
            text: t('bills:billNumber'),
            sort: true,
        },
        {
            dataField: 'billName',
            text: t('bills:title'),
            sort: true,
        },
        {
            dataField: '__actions',
            text: '',
            sort: false,
            headerClasses: 'actions-header-cell',
            formatter: (cell, row, rowIndex, extraProps) => {
                return (
                    <span>
                        <span>
                            <DownloadIcon title={t('download')} onClick={() => handleClickReportDownload(row)} style={{ cursor: 'pointer' }} />
                        </span>
                        <span>
                            <EmailIcon title={t('bills:sendPerEmail')} onClick={() => handleClickReportSend(row)} style={{ cursor: 'pointer' }} />
                        </span>
                    </span>
                )
            },
            formatExtraData: {
                handleClickReportDownload,
                handleClickReportSend
            }
        }
    ]

    return (
        <UniversalTableWrapperTest tableIsLoading={tableIsLoading} currentPage={currentPage} totalPages={totalPages} >
            <BootstrapTable
                remote
                ref={tableRef}
                loading={tableIsLoading}
                noDataIndication={() => <div className="no-data-placeholder">{t('reports:noReportsAvailable')}</div>} // change the translation ivan
                onTableChange={handleTableChange}
                bootstrap4
                keyField="id"
                hover
                data={bills || []}
                columns={columns}
                pagination={paginationFactory(paginationOptions)}
                bordered={false}
            />

            {showEmailModal &&
                <BillsEmailModal billId={bill.id} setShowEmailModal={setShowEmailModal} />
            }
        </UniversalTableWrapperTest>
    )
}

const BillsTable = () => {
    return (
        <TableProvider>
            <BillsTableInner />
        </TableProvider>
    )
}

export default BillsTable
