import React from 'react'
import { useTableState } from '../../../Table/TableProvider'
import { t } from 'i18next'
import UniversalTableWrapperTest from '../../../Table/UniversalTableWrapperTest'
import BootstrapTable from 'react-bootstrap-table-next'

const PlaceholdersTable = ({ selectedPlaceholders, handleSelectedPlaceholder, assignmentPlaceholders }) => {
  const { tableRef } = useTableState()

  const columns = [
    {
      dataField: '__assigneds',
      text: '',
      headerClasses: 'checkBox-header-cell',
      classes: 'checkBox-cell',
      sort: false,
      formatter: (cell, row, rowIndex, extraProps) => {
        let placeholderId = row.id
        let checked = selectedPlaceholders.includes(placeholderId)
        return <span> <input id={row.id} type="checkbox" checked={checked} onChange={() => { }} /></span>
      }, formatExtraData: {
        selectedPlaceholders,
        selectedMandatorsTrigger: selectedPlaceholders.length
      }
    },
    {
      dataField: 'placeholder',
      text: t('placeholder:placeholder'),
      sort: true,
    },
    {
      dataField: 'type',
      text: t('type'),
      sort: true,
    },
  ]
  return (
    <UniversalTableWrapperTest >
      <BootstrapTable
        ref={tableRef}
        noDataIndication={() => { <div className="no-data-placeholder">No Data</div> }}
        bootstrap4
        keyField="id"
        hover
        data={assignmentPlaceholders}
        columns={columns}
        bordered={false}
        rowEvents={{ onClick: handleSelectedPlaceholder }}
      />
    </UniversalTableWrapperTest>
  )
}

export default PlaceholdersTable