import React, { useEffect, useState } from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { ButtonWithLoadingState } from '../Buttons'
import { t } from 'i18next'
import MessageSelect from './MessageSelect'

const ActivateModal = ({ handleClose, handleActivateComplaint, loadingStates, errorStates, messages, loadMessages }) => {
  const [selected, setSelected] = useState([])
  const [step2, setStep2] = useState(false)

  useEffect(() => {
    loadMessages()
  }, [loadMessages])

  return (
    <Modal centered show={true} onHide={handleClose}>
      <Modal.Header closeButton >
        <Modal.Title>
          {step2 ? t('complaints:approveMessages') : t('complaints:approveComplaintAction')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step2
          ? <p>{t('complaints:approveConfirmationMessage')}</p>
          : <MessageSelect messagesLoading={loadingStates.loadMessages} messages={messages} selected={selected} setSelected={setSelected} />
        }
        {errorStates.activateComplaint ? <Alert variant="danger">{errorStates.activateComplaint}</Alert> : null}
      </Modal.Body>
      <Modal.Footer>
        {step2 ? <>
          <Button onClick={() => setStep2(false)}>{t('backButton')}</Button>
          <ButtonWithLoadingState
            onClick={() => handleActivateComplaint([messages[0].id, ...selected])}
            isLoading={loadingStates.activateComplaint}
            buttonText={t('complaints:approveAction')}
          /></>
          : <Button onClick={() => setStep2(true)}>{t('nextButton')}</Button>}
        <Button variant="secondary" onClick={handleClose}>
          {t('appointment:cancel')}
        </Button>
      </Modal.Footer>
    </Modal >

  )
}

export default ActivateModal