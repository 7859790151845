import React from 'react'
import { Subheader } from '../../Page/logix2020/Subheader'
import EmployeeData from './EmployeeData'
import SubheaderForm from '../../Page/logix2020/SubheaderForm'
import { useTranslation } from 'react-i18next'




const EmployeeSubheader = () => {

  const { t } = useTranslation()


  return (
    <Subheader className="subHeader-v1">

      {/* <SubheaderTitle withBackButton defaultBackTo="/employees" /> */}
      <SubheaderForm
        title={t('header:order')}
        goBackLabel={t('backButton')}
      />
      <div className="flex-grow-1" />
      <EmployeeData />
    </Subheader>
  )
}
export default EmployeeSubheader
