import React, { useState } from 'react'
import useStores from '../../../stores/useStore'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { PenIcon } from '../../Icons/PenIcon'
import { DeleteIcon } from '../../Icons/DeleteIcon'
import { useNavigate } from 'react-router-dom'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import apiClient from '../../../utils/api/apiClient'
import CostCenterTree from '../../CostCenter/CostCenterTree'
import OverlayRightCostCenterInfo from '../../Configuration/CostCenter/OverlayRightCostCenterInfo'
import ModalComponent2 from '../../Modals/ModalComponent2'
import MediaQuery from 'react-responsive'
import MoreOptionsButton from '../../Buttons/MoreOptionsButton'

const StyledWrapper = styled.div`
@media screen and (min-width: 1080px) {
  max-width: calc(100% - 480px);
  min-width: 600px;
}
`

const EditCostCenterTree = () => {
    const { costCenterStore } = useStores()
    const { t } = useTranslation()
    const [selectedCostCenter, setSelectedCostCenter] = useState({})
    const [showCostCenterInfoOverlay, setShowCostCenterInfoOverlay] = useState(false)
    const [actionSelect, setActionSelect] = useState("")
    const [btn1Loading, setBtn1Loading] = useState(false)
    const [btn2Loading, setBtn2Loading] = useState(false)
    const [error, setError] = useState("")
    const navigate = useNavigate()

    const openCostCenterInfo = costcenter => {
        setSelectedCostCenter(costcenter)
        setShowCostCenterInfoOverlay(true)
    }
    const openDeleteModal = (costcenter) => {
        setSelectedCostCenter(costcenter)
        setActionSelect("delete")
    }

    const openCopyModal = (costcenter) => {
        setSelectedCostCenter(costcenter)
        setActionSelect("copy")
    }

    const deleteCostCenterItem = (recursively = false) => {
        recursively ? setBtn2Loading(true) : setBtn1Loading(true)
        apiClient.deleteJson(APIEndpoints.costCenters(selectedCostCenter.id).details, { recursively })
            .then(() => {
                costCenterStore.resetPartialCostCenters()  // reseting the state
                costCenterStore.loadPartialCostCenters()  // then reloading it again
                setActionSelect("")
                setShowCostCenterInfoOverlay(false)
            }).catch((error) => {
                setError(error)
            }).finally(() => {
                setBtn1Loading(false)
                setBtn2Loading(false)
            })
    }

    const copyCostCenterItem = () => {
        navigate(`/costcenteradministration/copy/${selectedCostCenter.id}#general`);
    }
    const getContextMenuOptions = (costCenter) => [
        {
            option: 'edit',
            optionText: t('edit'),
            optionIcon: <PenIcon />,
            onOptionSelect: () => navigate(`/costcenteradministration/edit/${costCenter.id}#general`)
        },
        {
            option: 'delete',
            optionText: t('deleteButton'),
            optionIcon: <DeleteIcon />,
            onOptionSelect: () => openDeleteModal(costCenter)
        },
    ]
    const renderExtraItemContent = (costCenter) => (
        <MediaQuery maxWidth={1499}>
            <div className="action-buttons"  >
                <MoreOptionsButton options={getContextMenuOptions(costCenter)} />
            </div>
        </MediaQuery>
    )

    return (

        <StyledWrapper>
            <CostCenterTree
                withSearch
                onClick={openCostCenterInfo}
                isClickable={() => true}
                renderExtraItemContent={renderExtraItemContent}
                getContextMenuOptions={getContextMenuOptions}
            />
            <OverlayRightCostCenterInfo
                visible={showCostCenterInfoOverlay}
                onClose={() => setShowCostCenterInfoOverlay(false)}
                selectedCostCenter={selectedCostCenter}
                actionSelect={actionSelect}
                openDeleteModal={openDeleteModal}
                openCopyModal={openCopyModal}
            />

            {actionSelect === "delete" &&
                <ModalComponent2
                    ModalTitle={t('costCenterAdmin:deleteCostCenter')}
                    BodyPart1={t('costCenterAdmin:areYouSure')}
                    bodyStyle="alert alert-danger"
                    functionCancel={() => setActionSelect("")}
                    showButton2={selectedCostCenter.hasChildren}
                    button1Text={t('delete')}
                    button1Function={() => { deleteCostCenterItem() }}
                    button1Loading={btn1Loading}
                    button1Variant="danger"
                    button2Text={t('officeAdmin:deleteRecursively')}
                    button2Function={() => { deleteCostCenterItem(true) }}
                    button2Loading={btn2Loading}
                    button2Variant="danger"
                    error={error.message}
                />
            }

            {actionSelect === "copy" &&
                <ModalComponent2
                    ModalTitle={t('costCenterAdmin:copyCost')}
                    BodyPart1={t('costCenterAdmin:areYouSureCopy')}
                    bodyStyle="alert alert-primary"
                    functionCancel={() => { setActionSelect("") }}
                    button1Text={t('officeAdmin:copy')}
                    button1Function={() => { copyCostCenterItem() }}
                    button1Variant="primary"
                />
            }
        </StyledWrapper>
    )
}

export default EditCostCenterTree
