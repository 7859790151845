import { Modal } from 'react-bootstrap'
import React from 'react'
import { t } from 'i18next'
import ConfigurationSelect from '../Forms/ConfigurationSelect'
import { inject, observer } from 'mobx-react'
import { CustomizationIcon } from '../Icons/CustomizationIcon'
import { colors } from '../../styles/colors'


const ConfigurationModal = inject('teamOrderStore', 'productDetailsStore')(observer(props => {
  const { teamOrderStore, productDetailsStore, handleClose, index } = props
  const customConfigurations = teamOrderStore.selectedVariants[index].customConfigurations
  const configurations = productDetailsStore.articleConfiguration

  return (
    <Modal centered show={true} onHide={handleClose} >
      <Modal.Header closeButton className="pb-0">
        <Modal.Title>
          <div className="d-flex">
            <div className="me-3 mt-1">
              <CustomizationIcon height="2rem" width="2rem" color={colors.gray2} />
            </div>
            <div>
              <p className="m-0">{t('sizeDetection:editCustomization')}</p>
              <p className="m-0" style={{ color: colors.gray3 }}>{t('productsOverview:article')}: {productDetailsStore.article.displayName}</p>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {configurations.map(config => {
          const customConfig = customConfigurations[config.id]
          const costumConfigurationArticle = customConfig.article ? customConfig.article : {}
          const customConfigcomment = customConfig.comment || ""
          //const selectedConfigurationsErrors = productDetailsStore.selectedConfigurationsErrors[config.id]
          const configPrice = productDetailsStore.configPrices[costumConfigurationArticle.id]
          const priceObject = productDetailsStore.shoppingBasketArticle.pricePerItem;
          return (
            <ConfigurationSelect
              key={config.id}
              costumConfigurationArticle={costumConfigurationArticle}
              config={config}
              showConfigPrice={priceObject !== null && priceObject.points === null && priceObject.price !== null}
              customConfigcomment={customConfigcomment}
              configPrice={configPrice}
              //selectedConfigurationsErrors={selectedConfigurationsErrors}
              handleRemoveConfig={(configId) => teamOrderStore.handleRemoveConfiguration(configId, index)}
              handleAddConfig={(configId, article) => teamOrderStore.handleChangeConfig(configId, article, index)}
              handleAddComment={configId => e => teamOrderStore.handleChangeConfigComment(configId, e.target.value, index)}
            />
          )
        })}

      </Modal.Body>

    </Modal >
  )
}))


export default ConfigurationModal