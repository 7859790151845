import React from 'react'

export const FolderIcon = (props) => {
  const color = props.color ? props.color : '#D0CFD0'
  const height = props.height ? props.height : "28px"
  const width = props.width ? props.width : "28px"

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 28" className="folder-icon-svg">
      <style type='text/css'>
        {`
          .folder-icon-svg {
            fill:${color};
          }
        `}
      </style>
      <g>
        <g>
          <g>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.3 10h-2.1l2.1-2.1z" />
          </g>
        </g>
        <path d="M20 11c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1H8c-.6 0-1-.4-1-1v-7c0-.6.4-1 1-1h12zm-9.9-4c.7 0 1.4.2 2.1.5s1.3.5 2 .5H19c.6 0 1 .4 1 1v1H8V8c0-.6.4-1 1-1h1.1z" fillRule="evenodd" clipRule="evenodd" />
      </g>
    </svg>
  )
}
