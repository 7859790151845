import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { StyledFormHeadline, StyledValidationMessage } from './RegistrationStyles'

const ContactData = inject('registrationStore', 'uiStore')(observer((props) => {
  const { registrationStore, registrationConfig } = props
  return (
    <>
      <StyledFormHeadline>{registrationConfig.contactData.headline}</StyledFormHeadline>
      <Row>
        <Form.Group className="mb-3" as={Col} controlId="formPhonePrivate">
          <Form.Label>{t('formularFields:telnumber')} *</Form.Label>
          <Form.Control
            value={registrationStore.registrationData.phone.value}
            type="text"
            placeholder={t('formularFields:telnumber')}
            name="phone.value"
            onChange={registrationStore.handleChange2}
            isInvalid={registrationStore.formErrors.phonevalue}
          />
          <StyledValidationMessage type="invalid">
            {registrationStore.formErrors.phonevalue}
          </StyledValidationMessage>
        </Form.Group>
      </Row>
    </>
  )
}))

export default ContactData