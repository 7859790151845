import React from 'react'
import DynamicFormElements from '../Form/DynamicFormElements'

const formConfigTemplate = [
  {
    type: 'dateRange'
  },
  {
    type: 'costCenterSelect',
    name: 'targetCostcenterID'
  },
  {
    type: 'spacer',
  },
  {
    type: 'checkbox',
    name: 'includeDeletedCostCenters',
    label: 'includeDeletedCostCenters'
  },
  {
    type: 'checkbox',
    name: 'include_Subordinated_CostCenters',
    label: 'includeSubordinatedCostCenters'
  },
  {
    type: 'checkbox',
    name: 'onlyOrderPositions_IsNotShipped',
    label: 'onlyOrderPositions_IsNotShipped'
  },
  {
    type: 'checkbox',
    name: 'include_Cancelled_Orders',
    label: 'include_Cancelled_Orders'
  },
  {
    type: 'checkbox',
    name: 'includeDactivatedBP',
    label: 'includeDactivatedBP'
  },
  {
    type: 'checkbox',
    name: 'showPoints',
    label: 'showPoints'
  },
  {
    type: 'checkbox',
    name: 'showPrice',
    label: 'showPrice'
  },
  {
    type: 'spacer',
  },
  {
    type: 'text',
    name: 'emailAddress',
    label: 'emailAddress'
  }
]

const CostCenterStatistics = ({ preSelectedData, formData, setFormData, formErrors }) => {



  return (
    <>
      <DynamicFormElements preSelectedData={preSelectedData} formConfigTemplate={formConfigTemplate} setFormData={setFormData} formData={formData} formErrors={formErrors} />
    </>
  )
}
export default CostCenterStatistics
