import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import axios, { isCancel } from 'axios'

import CustomCKEditor from './CKEditor'
import { getFakeEvent } from '../../../utils/utils'
import { getErrorMessageFromApiResponse } from '../../../utils/api/apiUtils'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'

const CancelToken = axios.CancelToken;
let cancel;

function MyCustomUploadAdapterPlugin(extraProps) {
  return function function2(editor) {
    return editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new MyUploadAdapter({ loader, extraProps })
    }
  }
}

class MyUploadAdapter {
  constructor(props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props.loader;
    this.newsId = props.extraProps.newsId
    this.setError = props.extraProps.setError
  }
  cancelLastCall() {
    //cancel previous calls that are still pending
    cancel && cancel()
  }
  // Starts the upload process.
  async upload() {
    this.cancelLastCall()
    const cancelToken = new CancelToken(function executor(c) {
      cancel = c;
    })
    try {
      let file = await this.loader.file
      let response = await apiClient.postFile(APIEndpoints.feedsAdministration(this.newsId).fileUpload, {}, [file], false, { cancelToken })
      return { default: response.fullPath }
    }
    catch (e) {
      if (isCancel(e)) {
        console.log("cancelled by cancelToken")
      }
      else {
        this.setError(getErrorMessageFromApiResponse(e))
      }
    }
  }

  // Aborts the upload process.
  abort() {
    this.cancelLastCall()
  }
}

const CKEditorNews = ({ changeLanguageText, newsId, selectedLanguage, currentBody }) => {
  const [initialEditorData, setInitialEditorData] = useState('')

  useEffect(() => {
    setInitialEditorData(currentBody)
    //eslint-disable-next-line
  }, [selectedLanguage])

  const [error, setError] = useState('')
  return (
    <>
      <CustomCKEditor
        initialEditorData={initialEditorData || ""}
        setEditorValue={data => changeLanguageText(getFakeEvent('news.body.values', data))}
        extraPlugins={[MyCustomUploadAdapterPlugin({ newsId, setError })]}
        error={error}
      />
      {error ? <Alert className="mt-2" variant="danger">{error}</Alert> : null}</>

  )
}

export default CKEditorNews