import React, { useState } from 'react'
import { OverlayLeft } from '../components/Page/logix2020/OverlayLeft'
import OverlayLeftFilter from '../components/Employees/OverlayLeftFilter'
import { Button } from 'react-bootstrap'
import EmployeesTable from '../components/Employees/EmployeesTable'
import styled from 'styled-components'
import { Subheader } from '../components/Page/logix2020/Subheader'
import { useEmployeesState } from '../stores/EmployeesProvider'
import { useTranslation } from 'react-i18next'
import ActiveTableFilters from '../components/Employees/ActiveTableFilters'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import BigFooter from '../components/Footer/BigFooter'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import SubheaderForm from '../components/Page/logix2020/SubheaderForm'

const StyledFilter = styled.div`
  display: flex;
  justify-content: space-between; 
  margin-bottom:20px;

  .btn {
    margin-left: 35px;
  }
 
`
const StyledSubheader = styled(Subheader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledContentArea = styled(ContentArea)`
  width: 100%;
  padding: 20px 30px 20px 36px;
  // height: 100% !important;
  @media only screen 
  and (max-width: 426px), (min-device-width: 320px) 
  and (max-device-width: 426px)  {
    padding:20px;
  }
`

const InventoryReturnEmployeeSelect = () => {
  const { setShowAllEmployees, resetFilter, showOverlayLeft, setShowOverlayLeft, updateOverlayLeftStates } = useEmployeesState()
  const [selectedEmployee, setSelectedEmployee] = useState()
  const { t } = useTranslation()
  const currentLocation = useLocation()
  const foreignEmployeeId = useParams().employeeId
  const onRowClick = (e, row) => {
    setSelectedEmployee(row)
  }

  const handleCloseOverlayLeft = e => {
    setShowOverlayLeft(false)
    updateOverlayLeftStates()
  }

  if (selectedEmployee) {
    return (
      <Navigate to={`/inventoryreturnto/articleTransfer/${foreignEmployeeId ? `${foreignEmployeeId}/` : ''}${selectedEmployee.id}`} state={{ ...currentLocation.state }} />
    )
  }

  return (
    <div>
      <StyledSubheader className="subHeader-v1">
        <div className="subheader-title">
          <SubheaderForm
            title={t("inventory:articleTransfer")}
            goBackLabel={t("backButton")}
          />
        </div>
      </StyledSubheader>

      <OverlayLeft
        className={`overlay-variant ${showOverlayLeft ? "visible" : ""}`}
      >
        <div className="header" onClick={handleCloseOverlayLeft}>
          <span onClick={handleCloseOverlayLeft}>
            <i className="close-icon" />
          </span>
          <span className="header-title">{t("filter")}</span>
        </div>
        <div className="body">
          <OverlayLeftFilter />
        </div>
      </OverlayLeft>

      <StyledContentArea>
        <StyledFilter>
          <div className="d-flex flex-wrap align-items-baseline">
            <Button
              variant="secondary"
              className="m-0"
              onClick={() => {
                setShowOverlayLeft((prev) => !prev);
              }}
            >
              {t("allFilters")}
            </Button>
          </div>
          <div className="d-flex flex-wrap mb-2 align-items-baseline">
            <Button
              variant="secondary"
              className="btn-extra-padding"
              onClick={() => {
                resetFilter();
                setShowAllEmployees(true);
              }}
            >
              {t("employeesPage:showAllEmployees")}
            </Button>
          </div>
        </StyledFilter>
        <ActiveTableFilters />
        <EmployeesTable
          onRowClick={onRowClick}
          noActions
          tableId="InventoryReturn"
        />
      </StyledContentArea>
      <BigFooter />
    </div>
  );
}
export default InventoryReturnEmployeeSelect