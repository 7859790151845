import React from 'react'

export const EmailIcon = ({ color, title, ...rest }) => {
  const useColor = color ? color : '#3C3C3B'
  return (
    <svg width="28px" height="28px" viewBox="0 0 28 28" {...rest}>
      <title>{title}</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#FFFFFF">
          <g transform="translate(10.000000, 7.000000)">
            <polygon transform="translate(1.767767, 2.517767) rotate(-225.000000) translate(-1.767767, -2.517767) "
                     points="1.76776695 1.76776695 3.26776695 3.26776695 0.267766953 3.26776695"
            />
          </g>
        </g>
        <path
          d="M20,10 C20.5522847,10 21,10.4477153 21,11 L21,18 C21,18.5522847 20.5522847,19 20,19 L8,19 C7.44771525,19 7,18.5522847 7,18 L7,11 C7,10.4477153 7.44771525,10 8,10 L20,10 Z M16.579,15.3981528 L15.578,15.9751528 L19.4459479,18.2079344 C19.6850943,18.3460056 19.9908895,18.264068 20.1289606,18.0249217 C20.2670318,17.7857754 20.1850943,17.4799802 19.9459479,17.341909 L19.9459479,17.341909 L16.579,15.3981528 Z M11.615,15.4201528 L8.25086717,17.3437744 C8.25024728,17.3441288 8.24962814,17.3444844 8.24900978,17.3448415 L8.17551991,17.3965328 C7.99546454,17.5491633 7.94457981,17.8132599 8.06678282,18.0249217 C8.20485401,18.264068 8.51064921,18.3460056 8.74979553,18.2079344 L8.74979553,18.2079344 L12.592,15.9901528 L11.615,15.4201528 Z M20.1292486,11.4330127 C19.9911774,11.1938664 19.6853822,11.1119288 19.4462359,11.25 L19.4462359,11.25 L14.1102379,14.3307399 C14.104177,14.3342392 14.0982099,14.3378527 14.0923385,14.3415766 L14.0923385,14.3415766 L14.0286527,14.2975833 L8.75008349,11.25 C8.51093718,11.1119288 8.20514197,11.1938664 8.06707079,11.4330127 C7.9289996,11.672159 8.01093718,11.9779542 8.25008349,12.1160254 L8.25008349,12.1160254 L13.8742241,15.3631245 L13.8842044,15.3690421 L13.9705691,15.4216138 C13.9761779,15.425028 13.9821655,15.427777 13.9847454,15.4290357 C14.0225965,15.4612101 14.0780139,15.4679879 14.1236313,15.4419089 L14.1236313,15.4419089 L19.9451643,12.11416 C19.9457842,12.1138056 19.9464033,12.11345 19.9470217,12.1130929 C20.1851412,11.9756146 20.266727,11.6711323 20.1292486,11.4330127 Z"
          fill={useColor}
        />
      </g>
    </svg>
  )
}
