import React, { useRef, useState } from 'react'
import { FormControl } from 'react-bootstrap'
import styled from 'styled-components'
import { HidePW } from '../Icons/HidePW'
import { ShowPW } from '../Icons/ShowPW'
const StyledFormWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 22px;
  }

  .is-invalid {
    background-position: right calc(40px) center;
  }
`

const PasswordInputField = (props) => {
  const {
    inputKey,
    id,
    name = "",
    value,
    isInvalid,
    autoComplete,
    disabled,
    onChange,
    placeholder,
    required,
    children
  } = props

  const [showPassword, setShowPassword] = useState(false)
  const ref = useRef(null)

  const togglePassword = () => {
    setShowPassword((curState) => !curState)
    ref.current.focus()
  }

  return (
    <StyledFormWrapper>
      <FormControl
        key={inputKey}
        id={id}
        name={name}
        value={value}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        isInvalid={isInvalid}
        autoComplete={autoComplete}
        required={required}
        disabled={disabled}
        onChange={onChange}
        onBlur={() => setShowPassword(false)}
        ref={ref}
      />

      {children}

      {showPassword ?
        <HidePW
          role='button'
          onClick={togglePassword}
        />
        :
        <ShowPW
          role='button'
          onClick={togglePassword}
        />
      }
    </StyledFormWrapper>
  )
}

export default PasswordInputField