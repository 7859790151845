import React, { useCallback, useEffect, useState } from 'react'
import useReport from '../../utils/hooks/useReport'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { useNavigate } from 'react-router-dom'
import ArraySelect from '../Select/ArraySelect'
import { t } from 'i18next'
import { Alert, Form } from 'react-bootstrap'
import DownloadURLAlert from '../Forms/DownloadURLAlert'
import { useCancelToken } from '../../utils/hooks/useCancelToken'
import { makeCall } from '../../utils/api/makeCall'
import apiClient from '../../utils/api/apiClient'
import ReportSubscriptionModal from '../Files/Form/ReportSubscriptionModal'

const ActionSelect = ({ selectedRow }) => {
  const { createReport, reportErrors, reportDownloadUrls, setReportDownloadUrls, reportSubscriptionId, setReportSubscriptionId } = useReport()
  const [businessPartnerInfo, setBusinessPartnerInfo] = useState(null)
  const { createOrCancelToken, sourceRef, isCancel } = useCancelToken();
  const [, setError] = useState({})
  const [, setLoading] = useState({})
  const navigate = useNavigate();

  const getBusinessPartnerInfo = useCallback(() => {
    createOrCancelToken();
    makeCall('getInfo', async () => {
      let data = await apiClient.getJson(APIEndpoints.businessPartner(selectedRow.id).info, null, true, false, true, { cancelToken: sourceRef.current.token })
      setBusinessPartnerInfo(data);
    }, setError, setLoading, isCancel)
  }, [createOrCancelToken, isCancel, selectedRow.id, sourceRef])

  useEffect(() => {
    if (selectedRow) {
      console.log(selectedRow)
      setBusinessPartnerInfo(null);
      getBusinessPartnerInfo()
    }
    return () => { setError('') }
  }, [getBusinessPartnerInfo, selectedRow])



  const actionNames = {
    order: t("employeesPage:equipEmployees"),
    orderHistory: t("orders"),
    printInventory: t("employeesPage:currentEquipment"),
    printBudget: t("employeesPage:accountBallance"),
    printOrderHistory: t("employeesPage:orderHistory"),
  }

  const handleChangeAction = (e) => {
    let actionName = e.target.value
    if (actionName !== '') {
      let path = ''
      switch (actionName) {
        case 'printBudget':
          createReport("reportName", APIEndpoints.budgethistory(selectedRow.id), {})
          break
        case 'printInventory':
          path = `/inventory/${selectedRow.id}`
          navigate(path)
          break
        case 'printOrderHistory':
          path = `/inventoryHistory/${selectedRow.id}`
          navigate(path)
          break
        case 'order':
          path = `/productjobfunction/${selectedRow.id}`
          navigate(path)
          break
        case 'orderHistory':
          path = `/employee-orders/${selectedRow.id}`
          navigate(path, {
            state:
            {
              selectedStatus: 'open'
            }
          })
          break
        default:
          break;
      }
    }
  }
  return (
    <div>
      {reportErrors.reportName && <Alert variant="danger">{reportErrors.reportName.value}</Alert>}

      {reportDownloadUrls.reportName &&
        <DownloadURLAlert
          downloadUrl={reportDownloadUrls.reportName}
          setDownloadUrl={() => setReportDownloadUrls({})}
          fileName={t("employeesPage:accountBallance")}
        />
      }
      {reportSubscriptionId && <ReportSubscriptionModal
        reportSubscriptionId={reportSubscriptionId}
        setReportSubscriptionId={setReportSubscriptionId}
        message={t('reportConfig:createPending')}
      />}
      <Form.Group className="mb-3">
        <ArraySelect
          defaultOption={{ value: '', name: t('selectAction') }}
          options={businessPartnerInfo ? businessPartnerInfo.allowedActions.map(item => ({ id: item, displayName: actionNames[item] })) : []}
          onChange={handleChangeAction}
          returnEvent
        />
      </Form.Group>
    </div>
  )
}

export default ActionSelect