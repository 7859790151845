import { t } from 'i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, CloseButton, Col, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { useComplaintsState } from '../../stores/ComplaintsProvider'
import ArraySelect from '../Select/ArraySelect'
import FilterModal from './FilterModal'
import useStores from '../../stores/useStore'

const StyledFilterWrapper = styled.div`
  margin: 0 auto;

  .form-label {
    padding-right: 10px;
    display: flex;
    justify-content: space-between;

    button.close {
      outline: none;
    }
  }

  .filtersButtonWrapper {
    display: flex;
    flex-direction: column-reverse;
  }
`



const ComplaintCardsFilter = () => {
  const { filter, setFilterByKey, sort, handleChangeSort } = useComplaintsState()
  const [showFilterModal, setShowFilterModal] = useState(false);
  const { uiStore } = useStores();
  const [types, setTypes] = useState([])

  const getTypes = useCallback(async () => {
    await uiStore.loadDashboard()
    let result = []
    uiStore.openComplaints && result.push({ id: 'complaint', displayName: t('complaints:complaint') })
    uiStore.openReturnShipment && result.push({ id: 'retoure', displayName: t('complaints:return') })
    setTypes(result)
  }, [uiStore])

  useEffect(() => {
    getTypes()
  }, [getTypes])

  const sortOptions = [
    {
      id: 'feedbackNumber',
      displayName: t('complaints:referenceNumber')
    },
    {
      id: 'targetOrder.orderNr',
      displayName: t('complaints:orderNumber')
    },
    {
      id: 'targetBusinessPartner.displayName',
      displayName: t('complaints:customer')
    },
    {
      id: 'targetOffice.displayName',
      displayName: t('complaints:office')
    },
    {
      id: '-createdAt',
      displayName: t('complaints:createdAt')

    },
    {
      id: 'targetBill.billNumber',
      displayName: t('complaints:billNumber')
    }
  ]

  const handelChangeSearchText = (e) => {
    const { value } = e.target
    setFilterByKey('searchText', value)
  }

  const handleChangeType = (id) => {
    setFilterByKey('type', id)
  }

  const resetSearchText = () => {
    setFilterByKey('searchText', '')
  }
  return (
    <StyledFilterWrapper>
      <Row>
        <Form.Group className="mb-3" as={Col} sm="3">
          <Form.Label>
            {t('type')}
            {filter.type && (types.length > 1) && <CloseButton onClick={() => handleChangeType(null)} />}
          </Form.Label>
          <ArraySelect
            onChange={obj => handleChangeType(obj.id)}
            value={filter.type}
            options={types}
          />
        </Form.Group>
        <Form.Group className="mb-3" as={Col} sm="3">
          <Form.Label>
            <span className="me-1">{t('search')}</span>
            {filter.searchText && <CloseButton onClick={resetSearchText} />}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={`${t('complaints:referenceNumber')}, ${t('complaints:orderNumber')}`}
            name="searchText"
            value={filter.searchText}
            onChange={handelChangeSearchText}
          />
        </Form.Group>

        <Form.Group className="mb-3" as={Col} sm="3">
          <Form.Label>
            <span className="me-1">{t('complaints:sortBy')}</span>
            {sort && <CloseButton onClick={() => handleChangeSort(null)} />}
          </Form.Label>
          <ArraySelect
            name="sort"
            options={sortOptions}
            value={sort}
            onChange={(obj) => handleChangeSort(obj.id)}
          />
        </Form.Group>

        <Form.Group className="mb-3 filtersButtonWrapper" as={Col} sm="3">
          <Button variant="secondary" onClick={() => setShowFilterModal(true)}>{t('allFilters')}</Button>
        </Form.Group>

      </Row>
      {showFilterModal &&
        <FilterModal
          setShowFilterModal={setShowFilterModal}
        />
      }
    </StyledFilterWrapper>
  )
}

export default ComplaintCardsFilter