import { useObserver } from 'mobx-react'
import React, { useEffect } from 'react'
import { Modal, Spinner, Alert } from 'react-bootstrap'
import useStores from '../../../stores/useStore'
import { useTranslation } from 'react-i18next'
import ExpectedArrivalsList from './ExpectedArrivalsList'

const ExpectedArrivalsModal = () => {
    const { orderStore } = useStores()
    const { t } = useTranslation()
    useEffect(() => {
        orderStore.supplierOrderId = orderStore.openExpectedArrivalsModal
        orderStore.loadExpectedArrivals()
    }, [orderStore])
    const expectedArrivals = useObserver(() => orderStore.expectedArrivals)
    const handleClose = () => {
        orderStore.openExpectedArrivalsModal = null
    }
    return (
        <Modal size="lg" show={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('order:goodsReceiptPlanning')} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(expectedArrivals && expectedArrivals.length) ? <ExpectedArrivalsList expectedArrivals={expectedArrivals} />
                    : (expectedArrivals && !expectedArrivals.length) ? <Alert variant="info">{t('order:noGoodReceipts')}</Alert>
                        : <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
                }
            </Modal.Body>
        </Modal>
    )
}

export default ExpectedArrivalsModal
