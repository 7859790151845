import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { ArrowBigIcon } from '../Icons/ArrowBigIcon'




export const StyledIconWrapper = styled.div`
  padding-left: 10px;
  position: relative;

  &::before {
    content: '';
    display:block;
    width: 1px;
    height: 17px;
    position: absolute;
    left: 0;
    top: 5px;
    background-color: ${colors.gray3};
  }
`
export const StyledArrowWrapper = styled.div`
  display:flex;
  cursor:pointer;
`

export const StyledTreeSubList = styled.div`
  padding: 0 0 0 30px;
`


export const ArrowIconNew = styled(ArrowBigIcon)`
  width: 30px;
`

export const StyledItemWrapper = styled.div`
  color: ${props => props.isInvalid ? colors.gray3 : colors.gray7};
  display: flex;
  background: ${colors.gray6};
  border-radius: 5px;
  margin: 1px 0;
   padding: 2px;
  min-height: 28px;
  align-items: center;
  cursor: ${props => props.isClickable ? 'pointer' : 'default'};
  ul{
    color: ${props => colors.gray4};
  }

  @media screen and (min-width: 1080px) {
    .action-buttons {
      visibility: hidden;
    }
  }
  &:hover {
    background: #dbdbdb;

   .action-buttons {
    visibility: visible;
   }
  }
  .action-buttons {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;

    & > div {
      margin: 0 5px;
      cursor: pointer;
    }
 }
`

export const StyledDot = styled.div`
  color: #a8a8a8;
  text-align: center;
  width: 30px;
  font-size: 26px;
  line-height: 1;
  font-weight: 100;
  position: relative;
  flex-shrink: 0;
  
`


export const StyledListItemText = styled.span`
  width:100%;
  display: inline-block;
  margin: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .subText{
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 4px 8px;
    color: ${colors.gray3};
  }
`
export const StyledImage = styled.div`
height:24px;
width:24px;
background-size: contain;
background-image: none;
background-image: url(${props => props.image ? props.image : ""});
`
