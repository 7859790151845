import React from 'react'
import DynamicFormElements from '../Form/DynamicFormElements'

const formConfigTemplate = [
    {
        type: 'officeSelect',
        name: 'officeID'
    },
    {
        type: 'checkbox',
        name: 'includeSubordinatedOffices',
        label: 'includeSubordinatedOffices'
    },
    {
        type: 'checkbox',
        name: 'includeDeactivatedBP',
        label: 'includeDactivatedBP'
    },

    {
        type: 'text',
        name: 'emailAddress',
        label: 'emailAddress'
    }
]

const EmployeeHealthListStatistics = ({ formData, setFormData, formErrors }) => {

    return (
        <>
            <DynamicFormElements formConfigTemplate={formConfigTemplate} setFormData={setFormData} formData={formData} formErrors={formErrors} />
        </>
    )
}
export default EmployeeHealthListStatistics