import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import Address from './Address'
import { Button } from 'react-bootstrap'
import useOfficeState from '../../../stores/Configuration/useOfficeState'
import { Alert } from 'react-bootstrap'
import { PageLoadingLayer } from '../../Loading/PageLoadingLayer'
import { ButtonWithLoadingState } from '../../Buttons'
import { MdLibraryAdd } from 'react-icons/md'

const OfficeAddressesForm = () => {
  const {
    dataIsLoading,
    officeAddresses,
    availableAddressTypes,
    submit,
    submitIsLoading,
    cancel,
    success,
    otherErrors,
    dataHasChanged,
    handleAddAddress
  } = useOfficeState()

  const { t } = useTranslation()

  if (dataIsLoading.addressesLoading || dataIsLoading.countriesLoadid || dataIsLoading.availableAddressTypesLoading || dataIsLoading.detailsLoading) {
    return <PageLoadingLayer isLoading={true} />
  }

  const showAddButton = (addressType) => {
    //addressType exists and is not MultipleSelectable
    if (officeAddresses.some(address => (address.type.id === addressType.id && !addressType.isMultipleSelectable))) { return false }
    else return true
  }

  return (
    <>
      {officeAddresses.map((shippingAddress, index) => (
        <div key={index}>
          <Address
            index={index}
            address={shippingAddress}
          />
        </div>
      ))}
      <div className="d-flex">
        {/* add Address Buttons */}
        {availableAddressTypes.map((addressType, index) =>
          showAddButton(addressType) &&
          <Button key={index} className="my-2 me-2" onClick={() => handleAddAddress(addressType)} >
            <MdLibraryAdd className="me-3" />
            <Trans
              i18nKey="OfficeAddress:addAddressType"
              values={{ name: addressType.displayName }}
            />
          </Button>
        )}
      </div>
      <div>
        <ButtonWithLoadingState
          className="my-2 me-2"
          onClick={submit}
          isLoading={submitIsLoading}
          buttonText={t('saveButton')}
          disabled={!dataHasChanged}
        />
        {dataHasChanged && <Button className="my-2 me-2" variant="secondary" onClick={cancel} >{t('cancelButton')}</Button>}
      </div>
      {success &&
        <Alert variant="success" className="my-2">
          {success}
        </Alert>
      }
      {dataHasChanged &&
        <Alert variant="info" className="my-2">
          {t('EmployeeFormHealthData:dataChange')}
        </Alert>
      }
      {otherErrors.map((err, index) => (
        <Alert key={index} variant="danger" className="my-2">
          {err}
        </Alert>
      ))
      }
    </>
  )
}

export default OfficeAddressesForm
