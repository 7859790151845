import React, { useMemo } from 'react'
import { Table } from 'react-bootstrap'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { StyledCard } from './StyledDetailCard'
import { t } from 'i18next';
import { formatedDate } from '../../../utils/formatedDate'
import styled from 'styled-components'

const StyledTr = styled.tr`
  td{
    vertical-align: middle ; 
  }
`

const InstancesTable = (props) => {
    const { t } = useTranslation()
    const { articleInstances, showRFIDCol, showSerialKeyCol, showBatchlKeyCol, showWashCol } = props

    const LastScan = ({ obj }) => {
        let scanDate = formatedDate(obj.lastScanDate)
        let scanComment = obj.lastScanComment
        return <>
            <div>{scanDate}</div>
            <div>{scanComment}</div>
        </>
    }
    return (<>
        <Table borderless striped>
            <thead>
                <tr>
                    {showRFIDCol && <th>
                        RFID
                    </th>}
                    {showRFIDCol && <th>
                        Letzter Scan (RFID)
                    </th>}
                    {showSerialKeyCol && <th>
                        {t('inventory:serialNumber')}
                    </th>}
                    {showBatchlKeyCol && <th>
                        {t('inventory:batchNumber')}
                    </th>}
                    {showWashCol && <th>
                        Waschvorgänge
                    </th>}
                    {showWashCol && <th>
                        Waschzyklen
                    </th>}
                </tr>
            </thead>
            <tbody>
                {articleInstances.map((obj, i) => (
                    <StyledTr key={i}>
                        {showRFIDCol && <td>{obj.rfid}</td>}
                        {showRFIDCol && <td><LastScan obj={obj} /></td>}
                        {showSerialKeyCol && <td>{obj.serialKey && obj.serialKey}</td>}
                        {showBatchlKeyCol && <td>{obj.batchKey && obj.batchKey}</td>}
                        {showWashCol && <td>{obj.washCounter != null && obj.washCounter}</td>}
                        {showWashCol && <td>{obj.washCycles != null && obj.washCycles}</td>}
                    </StyledTr>
                ))}
            </tbody>
        </Table>
    </>)
}

const InstancesCard = inject('inventoryStore')(observer(props => {
    const { inventoryStore } = props

    const showRFIDCol = useMemo(() => (
        inventoryStore.articleInstances.some(instance => instance.rfid)
    ), [inventoryStore.articleInstances])

    const showSerialKeyCol = useMemo(() => (
        inventoryStore.articleInstances.some(instance => instance.serialKey)
    ), [inventoryStore.articleInstances])

    const showBatchlKeyCol = useMemo(() => (
        inventoryStore.articleInstances.some(instance => instance.batchKey)
    ), [inventoryStore.articleInstances])

    const showWashCol = useMemo(() => {
        let someWashCounters = inventoryStore.articleInstances.some(instance => instance.washCounter)
        let someWashCycles = inventoryStore.articleInstances.some(instance => instance.washCycles)
        return someWashCounters || someWashCycles
    }, [inventoryStore.articleInstances])


    return (
        <>
            {(showRFIDCol || showSerialKeyCol || showBatchlKeyCol || showWashCol) ? <StyledCard>
                <h1>{t('inventory:instances')}</h1>
                <InstancesTable
                    showRFIDCol={showRFIDCol}
                    showSerialKeyCol={showSerialKeyCol}
                    showBatchlKeyCol={showBatchlKeyCol}
                    showWashCol={showWashCol}
                    articleInstances={inventoryStore.articleInstances}
                />
            </StyledCard>
                : null}
        </>
    )
}))

export default InstancesCard
