import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import styled, { withTheme } from 'styled-components'
import { Link } from 'react-router-dom'
import { Alert } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { AiFillInfoCircle } from 'react-icons/ai';

import { colors } from '../../styles/colors'
import { formatedPriceObject } from '../../utils/formatedPrice'
import { ShoppingCartIcon } from '../Icons/ShoppingCartIcon'

const StyledStickyBasketWrapper = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  margin: 0;
  right: 0;

  @media screen and (min-width: 380px) {
    width: 365px;
    right: 35px;
    float: right;
    border-radius: 5px 5px 0 0;
  }

  @media screen and (min-width: 1360px) {
    right: calc( (100vw - 1360px) / 2 + 15px)
  }
`

const StyledLink = styled(Link)`
  background-color: ${colors.gray1};
  color: ${colors.gray4};
  display: flex;
  justify-content: space-between;

  &:hover, &:active {
    color: ${colors.gray4};
  }

  & > div {
    display: flex; align-items: center;
  }
`

const StyledIconWrapper = styled.div`
  width: 70px;
`
const StyledAlert = styled(Alert)`
  margin: 0px;
  border-radius: 0px;
  padding: 0px 1rem;
  display: flex;
  align-items: center;
  border: none;
`

class StickyShoppingBasket extends Component {
  render() {
    const { t, uiStore, productsStore, teamOrderStore, theme } = this.props

    if (!productsStore.basket || !productsStore.basket.articles || productsStore.basket.articles.length === 0) {
      return null
    }

    const basketArticlesAmount = productsStore.getBasketArticlesAmount()
    const processType = teamOrderStore.getCurrentProcessType()
    return (
      <StyledStickyBasketWrapper>

        <StyledLink to={uiStore.linkToBasket}>
          <div className='p-3'>
            {t('shoppingCart')}<br />
            {basketArticlesAmount} {t('articles', { count: basketArticlesAmount })}
          </div>
          {/*  <div>
            <table style={{ height: 'fit-content' }}>
              <tbody>
                <tr   >
                  <td style={{ textAlign: 'right', paddingRight: '10px' }}>{t('stickyShoppingBasket:sum')}:</td>
                  <td style={{ textAlign: 'left' }}>{formatedPriceObject(productsStore.basket.totalPositionsValue)}</td>
                </tr>
                {(!teamOrderStore.selectedCostcenterId && !teamOrderStore.privateOrder && (productsStore.targetEmployee.points != null) && (productsStore.basket.totalPositionsValue != null)) ?
                  <tr   >
                    <td style={{ textAlign: 'right', paddingRight: '10px' }}>Rest:</td>
                    <td style={{ textAlign: 'left' }}>{(productsStore.targetEmployee.points - productsStore.basket.totalPositionsValue.points).toFixed(0)} {t('shoppingCart:points')}</td>
                  </tr>
                  : null}
              </tbody>
            </table>
          </div> */}

          {!productsStore.hidePricesAndPoints &&
            <div style={{ flexDirection: "column", justifyContent: "center" }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ textAlign: 'right' }}>{t('stickyShoppingBasket:sum')}:</div>
                {productsStore.basket.totalPositionsValue !== null &&
                  <div style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{formatedPriceObject(productsStore.basket.totalPositionsValue)}</div>
                }
              </div>
              {(processType === 'personal' && (productsStore.targetEmployee.points != null) && (productsStore.basket.totalPositionsValue != null)) ?
                <div style={{ display: 'flex', justifyContent: 'space-between', color: 'black' }}>
                  <div style={{ textAlign: 'right' }}>{t('rest')}:</div>
                  <div style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{(productsStore.targetEmployee.points - productsStore.basket.totalPositionsValue.points).toFixed(0)} {t('shoppingCart:points')}</div>
                </div>
                : null
              }
            </div>
          } 

          <StyledIconWrapper className='d-flex justify-content-center align-items-center'
            style={{ backgroundColor: theme.primaryColor }}>
            <ShoppingCartIcon color='#fff' />
          </StyledIconWrapper>
        </StyledLink>
        {productsStore.orderForFree
          ? <StyledAlert variant="info"  >
            <AiFillInfoCircle size={50} className="me-3" style={{ opacity: "0.2" }} /><div>{t('stickyShoppingBasket:phaseNoPoints')}</div>
          </StyledAlert>
          : null}
      </StyledStickyBasketWrapper>
    )
  }
}

export default withTheme(withTranslation()(inject('productsStore', 'uiStore', 'teamOrderStore')(observer(StickyShoppingBasket))))
