import React, { useEffect, useState } from 'react'
import { StyledCircleCheckIconWrapper } from './StyledCircleCheckIconWrapper'
import { CircleCheckIcon } from '../../../Icons/CircleCheckIcon'
import { useTranslation } from 'react-i18next'

const CommunicationDataCheck = ({ communicationData }) => {
  const [isValid, setIsValid] = useState(false)

  //check that email and one of phone numbers is given in officeData or privateData
  useEffect(() => {
    const officeData = communicationData.newItems.filter(item => item.isPrivate)
    const privateData = communicationData.newItems.filter(item => !item.isPrivate)

    const valid = [officeData, privateData].reduce((acc, data) => {
      const hasPhone = data.filter(item => ['phone', 'cellphone'].includes(item.type)).reduce((acc2, item) => acc2 || !!item.value, false)
      const hasEmail = !!data.find(item => item.type === 'email').value
      return acc || (hasEmail || hasPhone)
    }, false)

    setIsValid(valid)
  }, [communicationData])

  const { t } = useTranslation()
  return (
    <>
      <StyledCircleCheckIconWrapper>
        <CircleCheckIcon variant={isValid ? 'optional' : 'none'} />
      </StyledCircleCheckIconWrapper>
      <span>{t('OverlayProgress:contactData')}</span>
    </>
  )
}

export default CommunicationDataCheck
