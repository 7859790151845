import { t } from 'i18next';
import React from 'react';
import { useState } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import ActivityItem from './ActivityItem';
import ModalComponent2 from '../../Modals/ModalComponent2'
import { useActivitiesState } from '../../../stores/Configuration/ActivitiesProvider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FixedSizeList as List } from 'react-window';
import useMountEffect from '../../../utils/hooks/useMountEffect';
const StyledWrapper = styled.div`
@media screen and (min-width: 1080px) {
  max-width: calc(100% - 480px);
  min-width: 600px;
}
`

const ActivitiesOverview = () => {
  const { getActivities, errorStates, loadingStates, activities, setActivities, deleteActivity, successStates, setSuccessStates, putActivitiesOrderSequenceBackground } = useActivitiesState()
  const [deleteActivityId, setDeleteActivityId] = useState('')

  useMountEffect(() => {
    getActivities()
  })

  if (errorStates.activities) {
    return <Alert variant="danger">{errorStates.activities.value}</Alert>
  }
  function move(from, to) {
    let copiedActivities = [...activities]
    let numberOfDeletedElm = 1;
    const elm = copiedActivities.splice(from, numberOfDeletedElm)[0];
    numberOfDeletedElm = 0;
    copiedActivities.splice(to, numberOfDeletedElm, elm);
    return copiedActivities
  }

  const moveCardHandler = (dragIndex, dropIndex) => {
    const dragItem = activities[dragIndex];
    if (dragItem) {
      const newSequence = move(dragIndex, dropIndex)
      setActivities(newSequence);
      putActivitiesOrderSequenceBackground(newSequence)
    }
  };

  return (
    <>
      <StyledWrapper>
        {((loadingStates.activities && !activities.length) || loadingStates.saveOrderSequence)
          ? <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
          : <DndProvider backend={HTML5Backend}>
            <List
              height={800}
              itemCount={activities.length}
              itemSize={30}
            >
              {({ index, style }) => <div style={style}><ActivityItem key={activities[index].id} index={index} activity={activities[index]} moveCardHandler={moveCardHandler} setDeleteActivityId={setDeleteActivityId} /></div>}
            </List>
          </DndProvider>
        }
        {loadingStates.saveOrderSequenceBackground ? <div className="mt-2 d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div > : null}

      </StyledWrapper >
      {deleteActivityId && <ModalComponent2
        ModalTitle={'Activität löschen'}
        BodyPart1={"Möchten Sie diese Aktivität wirklich löschen?"}
        button1Function={() => deleteActivity(deleteActivityId)}
        button1Text={!successStates.deleteActivity ? t('delete') : null}
        button1Variant="danger"
        button1Loading={loadingStates.deleteActivity}
        bodyStyle="alert alert-danger"
        functionCancel={() => { setDeleteActivityId(''); setSuccessStates({}) }}
        error={errorStates?.deleteActivity?.value}
        success={successStates.deleteActivity ? t('registrationBox:savingSuccess') : null}
      />}
    </>
  )
}

export default ActivitiesOverview