import React, { useState } from 'react'
import ModalComponent2 from '../../Modals/ModalComponent2';
import { getLanguageValue } from '../../../utils/getLanguageValue';
import { Table } from 'react-bootstrap';
import { APIEndpoints } from '../../../utils/api/apiConfig';
import apiClient from '../../../utils/api/apiClient';
import { getErrorMessageFromApiResponse } from '../../../utils/api/apiUtils';
import { t } from 'i18next';

const CopyOrMoveModal = ({ setOpenMoveOrCopyModal, getGroupArticles, fromArticle, toGroup }) => {
  const [loading, setLoading] = useState('')
  const [error, setError] = useState(null)
  async function handleSave(type) {
    setError(null)
    setLoading(type)
    let params = {
      id: fromArticle.id,
      type
    }
    try {
      await apiClient.postJson(APIEndpoints.contingentTemplate(toGroup.entitlementId, toGroup.id).groupArticles, params)
      await getGroupArticles(toGroup.entitlementId, toGroup.id)
      type === "move" && await getGroupArticles(fromArticle.entitlementId, fromArticle.groupId)
      setOpenMoveOrCopyModal(null)
    }
    catch (e) {
      setError(getErrorMessageFromApiResponse(e))
    }
    finally { setLoading('') }
  }
  return (
    <ModalComponent2
      ModalTitle={t('contingentTemplates:copyOrMove')}
      button1Function={() => handleSave("copy")}
      button1Text={t('officeAdmin:copy')}
      button1Loading={loading === "copy"}
      button2Function={() => handleSave("move")}
      button2Text={t('contingentTemplates:move')}
      button2Loading={loading === "move"}
      functionCancel={() => setOpenMoveOrCopyModal(null)}
      error={error}
      showButton2
    >
      <Table striped bordered   >
        <tbody>
          <tr><td><b>{t('articles')}:</b></td><td> {fromArticle.article.displayName} </td></tr>
          <tr><td><b>{t('EmployeeFormMainData:group')}:</b></td><td>  {getLanguageValue(toGroup.name.values)} </td></tr>
        </tbody>
      </Table>
    </ModalComponent2>
  )
}

export default CopyOrMoveModal