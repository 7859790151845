import { action, observable, makeObservable } from 'mobx';
import { setPointerErrorsFromApiResponse } from "../utils/api/apiUtils"
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { setValueByKey } from '../utils/utils'
import { t } from 'i18next'

class RegistrationStore {
    constructor(appStore) {
        makeObservable(this, {
            registrationData: observable,
            checkboxes: observable,
            formErrors: observable,
            isRegistrationButtonLoading: observable,
            countries: observable,
            mainError: observable,
            mainSuccess: observable,
            clearData: action,
            clearError: action,
            clearError2: action,
            handleChange2: action,
            handleChangeAddressCountry: action,
            handleChangeOfficeAddressCountry: action,
            createAddressee: action,
            createInitials: action,
            setCutMale: action,
            setCutFemale: action,
            setEmailRepeat: action,
            setPasswordRepeat: action,
            loadCountries: action,
            frontendValidation: action,
            handleRegistrationSubmit: action,
            config: observable
        });

        this.app = appStore
    }

    _registrationTemplate = {
        employee: {
            gender: "",
            firstName: "",
            lastName: "",
            initials: "",
            personnelNumber: ""
        },
        account: {
            login: "",
            password: ""
        },
        address: {
            addressee: "",
            street: "",
            streetNumber: "",
            zIP: "",
            town: "",
            country: {},
            id: "00000000-0000-0000-0000-000000000000"
        },
        email: {
            value: "",
            isPrivate: true
        },
        phone: {
            value: "",
            isPrivate: true
        },
        additionalData: {
            type: "",
            name: "", //Firma
            number: "", //Händlernummer
            foreignKey: "", //Ust-id
            parentForeignKey: "", //kundencode
            address: {
                officeLine1: "",
                officeLine2: "",
                careOf: "",
                street: "",
                streetNumber: "",
                zIP: "",
                town: "",
                country: {}
            }
        },
        frontendData: {
            passwordRepeat: "",
            emailRepeat: "",
            cutMale: false,
            cutFemale: false
        }
    }


    registrationData = { ...this._registrationTemplate };
    checkboxes = {};

    formErrors = {};
    isRegistrationButtonLoading = false;
    countries = [];
    mainError = "";
    mainSuccess = "";

    clearData() {
        this.formErrors = {}
        this.mainError = false
        this.mainSuccess = false
        this.registrationData = { ...this._registrationTemplate }
    }
    clearError(parentObjName, key = "") {
        if (this.formErrors.hasOwnProperty(`${parentObjName}${key}`)) {
            this.formErrors[`${parentObjName}${key}`] = false
        }
    }
    clearError2(name) {
        const errorProp = name.replaceAll('.', '');
        if (this.formErrors.hasOwnProperty(errorProp)) {
            this.formErrors[errorProp] = false
        }
    }
    clearCheckboxes() {
        Object.keys(this.checkboxes).forEach(key => this.checkboxes[key] = false)
    }
    handleChange2 = (event) => {
        const { name, value } = event.target
        setValueByKey(name, value, this.registrationData)
        if (name === "employee.firstName" || name === "employee.lastName") {
            this.createAddressee()
            this.createInitials()
        }
        if (name === "email.value") {
            this.clearError("account", "login")
        }
        this.clearError2(name)
    };
    handleChangeAddressCountry = (addressObj) => {
        this.registrationData.address.country = addressObj
        this.clearError2("address.country.id")
    };
    handleChangeOfficeAddressCountry = (addressObj) => {
        this.registrationData.additionalData.address.country = addressObj
        this.clearError2("additionalData.address.country.id")
    };
    createAddressee() {
        this.registrationData.address.addressee = this.registrationData.employee.firstName + " " + this.registrationData.employee.lastName
    }
    createInitials() {
        const firstName = this.registrationData.employee.firstName
        const lastName = this.registrationData.employee.lastName
        var nameGenerator = ""
        if (firstName && lastName) {
            if (firstName.length + lastName.length > 19) {
                nameGenerator = firstName.slice(0).substring(0, 1).concat('. ', lastName);
                if (nameGenerator.length > 19) {
                    nameGenerator = nameGenerator.substring(0, 20);
                }
            }
            else nameGenerator = `${firstName} ${lastName}`
        }
        this.registrationData.employee.initials = nameGenerator
        this.clearError("employee", "initials")
    }
    setCutMale = (event) => {
        if (event.target.checked) {
            this.registrationData.employee.gender = "male"
            this.registrationData.frontendData.cutMale = true
            this.registrationData.frontendData.cutFemale = false
        }
        else {
            this.registrationData.employee.gender = ""
            this.registrationData.frontendData.cutMale = false
            this.registrationData.frontendData.cutFemale = false
        }
        this.clearError("employee", "gender")
    };
    setCutFemale = (event) => {
        if (event.target.checked) {
            this.registrationData.employee.gender = "female"
            this.registrationData.frontendData.cutMale = false
            this.registrationData.frontendData.cutFemale = true
        }
        else {
            this.registrationData.employee.gender = ""
            this.registrationData.frontendData.cutMale = false
            this.registrationData.frontendData.cutFemale = false
        }
        this.clearError("employee", "gender")
    };

    setEmailRepeat = (event) => {
        const { value } = event.target
        this.registrationData.frontendData.emailRepeat = value
        this.registrationData.account.login = value
        this.clearError("email", "value")
        this.clearError("account", "login")//because email is also acount.login
    };
    setPasswordRepeat = (event) => {
        this.registrationData.frontendData.passwordRepeat = event.target.value
        this.clearError("account", "password")
    };


    handleCheckbox = event => {
        const { name, checked } = event.target
        this.checkboxes[name] = checked
        this.clearError(name)
    }



    async loadCountries() {
        const subMandator = this.app.uiStore.theme
        if (subMandator) {
            try {
                this.countries = await apiClient.getJson(APIEndpoints.registrations(subMandator).countries, {}, false)
                const defaultCountry = this.countries.find(obj => obj.isDefault)
                if (defaultCountry) {
                    this.registrationData.address.country = defaultCountry
                    this.registrationData.additionalData.address.country = defaultCountry
                }
            } catch (e) {
                this.mainError = true
            }
        }
    }
    frontendValidation() {
        var result = true
        if (this.registrationData.account.password !== this.registrationData.frontendData.passwordRepeat) {
            this.formErrors.accountpassword = t('registrationBox:pwDoesntMatch')
            result = false
        }
        if (this.registrationData.email.value !== this.registrationData.frontendData.emailRepeat) {
            this.formErrors.emailvalue = t('registrationBox:emailDoesntMatch')
            result = false
        }
        for (const key of Object.keys(this.checkboxes)) {
            if (!this.checkboxes[key]) {
                this.formErrors[key] = t('registrationBox:pleaseConfirm')
                result = false
            }
        }
        if (this.config.officeAddressData) {
            //each required filed check if empty
            if (!this.registrationData.additionalData.address.street) {
                this.formErrors.additionalDataaddressstreet = t('mandatoryField')
                result = false
            }
            if (!this.registrationData.additionalData.address.streetNumber) {
                this.formErrors.additionalDataaddressstreetNumber = t('mandatoryField')
                result = false
            }
            if (!this.registrationData.additionalData.address.zIP) {
                this.formErrors.additionalDataaddresszIP = t('mandatoryField')
                result = false
            }
            if (!this.registrationData.additionalData.address.town) {
                this.formErrors.additionalDataaddresstown = t('mandatoryField')
                result = false
            }
            if (!this.registrationData.additionalData.address.country.id) {
                this.formErrors.additionalDataaddresscountryid = t('mandatoryField')
                result = false
            }

        }
        if (this.config.additional && this.config.additional.additionalForms.hasOwnProperty('dealerName')) {
            //check if empty
            if (!this.registrationData.additionalData.name) {
                this.formErrors.additionalDataname = t('mandatoryField')
                result = false
            }
        }
        if (this.config.additional && this.config.additional.additionalForms.hasOwnProperty('dealerNumber')) {
            //check if empty
            if (!this.registrationData.additionalData.number) {
                this.formErrors.additionalDatanumber = t('mandatoryField')
                result = false
            }
        }
        if (this.config.additional && this.config.additional.additionalForms.hasOwnProperty('ustId')) {
            //check if empty
            if (!this.registrationData.additionalData.foreignKey && !this.config.additional.additionalForms.ustId.optional) {
                this.formErrors.additionalDataforeignKey = t('mandatoryField')
                result = false
            }
        }
        if (this.config.additional && this.config.additional.additionalForms.hasOwnProperty('conceptCode')) {
            //check if empty
            if (!this.registrationData.additionalData.parentForeignKey) {
                this.formErrors.additionalDataparentForeignKey = t('mandatoryField')
                result = false
            }
        }
        return result
    }


    async handleRegistrationSubmit() {
        this.isRegistrationButtonLoading = true
        this.mainError = false
        this.mainSuccess = false
        const subMandator = this.app.uiStore.theme
        if (this.frontendValidation()) {
            apiClient.postJson(APIEndpoints.registrations(subMandator).registration, this.getParams(), false)
                .then(() => {
                    this.clearData()
                    this.mainSuccess = true
                })
                .catch(e => {
                    this.mainError = true
                    this.formErrors = setPointerErrorsFromApiResponse(e)
                }).finally(() => {
                    this.isRegistrationButtonLoading = false
                });
        }
        else {
            this.mainError = true
            this.isRegistrationButtonLoading = false
        }
    }


    getParams() {
        let result = {}
        if (this.config.accountData) {
            result.account = this.registrationData.account
        }
        if (this.config.personalData) {
            result.employee = {
                gender: this.config.genderData ? this.registrationData.employee.gender : null,
                firstName: this.config.personalData ? this.registrationData.employee.firstName : null,
                lastName: this.config.personalData ? this.registrationData.employee.lastName : null,
                initials: this.config.personalData.nameGenerator ? this.registrationData.employee.initials : null,
                personnelNumber: this.config.additional && this.config.additional.additionalForms.hasOwnProperty('personnelNumber') ? this.registrationData.employee.personnelNumber : null
            }
        }
        if (this.config.addressData) {
            result.address = this.registrationData.address
        }
        if (this.config.officeAddressData) {
            result.additionalData = result.additionalData ? result.additionalData : { type: "office" }
            result.additionalData.address = this.registrationData.additionalData.address
        }
        if (this.config.additional && this.config.additional.additionalForms.hasOwnProperty('dealerName')) {
            result.additionalData = result.additionalData ? result.additionalData : { type: "office" }
            result.additionalData.name = this.registrationData.additionalData.name
        }
        if (this.config.additional && this.config.additional.additionalForms.hasOwnProperty('dealerNumber')) {
            result.additionalData = result.additionalData ? result.additionalData : { type: "office" }
            result.additionalData.number = this.registrationData.additionalData.number

        }
        if (this.config.additional && this.config.additional.additionalForms.hasOwnProperty('ustId')) {
            result.additionalData = result.additionalData ? result.additionalData : { type: "office" }
            result.additionalData.foreignKey = this.registrationData.additionalData.foreignKey

        }
        if (this.config.additional && this.config.additional.additionalForms.hasOwnProperty('conceptCode')) {
            result.additionalData = result.additionalData ? result.additionalData : { type: "office" }
            result.additionalData.parentForeignKey = this.registrationData.additionalData.parentForeignKey

        }

        if (this.config.contactData) {
            result.phone = this.registrationData.phone
            result.email = this.registrationData.email
        }
        return result
    }




    config = {};

    initRegistrationConfig(config) {
        this.config = config
        config.checkboxes.forEach((checkboxObj) => {
            this.checkboxes[checkboxObj.id] = false
        });
    }
}


export default RegistrationStore
