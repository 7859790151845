import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { PlusIcon } from '../Icons/PlusIcon'
import { CheckIcon } from '../Icons/CheckIcon'
import { InputGroup } from 'react-bootstrap'

export const StyledSubHeadline = styled.span`
  display: block;
  color: ${colors.gray4}
  font-weight: bold;
  margin: 15px 0 5px;
`

export const DropdownStylingContainer = styled.div`
  margin: 10px 0;

  .input-group{
    flex-wrap: nowrap;
  }
  .dropdown.show {
    .input-group-text {
      border-bottom-left-radius: 0;
    }

    .input-group-prepend + div{
      border-bottom-right-radius: 0;
    }
  }

  .dropdownContent {
    border-color: ${colors.borderColor};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
    margin: 0;
    padding: 13px 10px 10px 10px;
    width: 100%;
  }
  .select {
    margin: 10px 0;
  }
`
export const StyledTriggerDiv = styled.div`
display: block;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 0 .25rem .25rem 0;
  border: 1px solid ${colors.borderColor};
  color: #495057;
  flex: 1 1 auto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  padding: .375rem .75rem;
  position: relative;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  .configText{
    word-break: break-word;
  }
  .configPrice{
    text-align: right;
    color: ${props => props.theme.primaryColor};
  }
`


export const StyledRemoveIcon = styled(PlusIcon)`
  transform: rotate(45deg) scale(1.25);
  position: absolute;
  right: 8px;
  top: 10px;
`

export const StyledConfigArticleImgWrapper = styled.div`
  width: 110px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: center;
  img {
    max-width: 100%;
    max-height: 110px;
  }
`

export const StyledIconWrap = styled.span`
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
`

export const CustomToggle = React.forwardRef((props, ref) => {
  const handleClick = e => {
    e.preventDefault()
    props.onClick(e)
  }
  const deleteConfig = e => {
    e.stopPropagation()
    e.preventDefault()
    props.deleteConfig(props.config.id)
  }
  return (
    <div ref={ref} role="button" onClick={handleClick} className={` ${props.isInvalid ? 'border border-danger' : ''}`}>
      <InputGroup>
        <InputGroup.Text>
          <StyledIconWrap>
            {props.configurationSelected ?
              <CheckIcon color={colors.green} />
              :
              <PlusIcon color={colors.gray5} />
            }
          </StyledIconWrap>
        </InputGroup.Text>
        <StyledTriggerDiv>
          <div>{props.triggerName}</div>
          <div className="configPrice">{props.triggerPrice}</div>
        </StyledTriggerDiv>
        {props.configurationSelected && !props.config.isMandatory && props.config.isChangeable ?
          <StyledRemoveIcon color={colors.gray3}
            onClick={deleteConfig} /> : null}
      </InputGroup>
    </div>
  )
})