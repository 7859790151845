import React from "react"
import CalendarMultiselect from '../Calendar/CalendarMultiselect'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useAppointmentsAdministrationState } from '../../stores/Administration/AppointmentsAdministrationProvider'




const DateSelect = () => {
    const { t } = useTranslation()
    const { dates, setDates } = useAppointmentsAdministrationState();

    const isTileDisabled = ({ date, view }) => {
        if (view !== "month") {
            return false
        }
        if (date.getDay() === 0 || date.getDay() === 6) {
            return true
        }
        else return false
    }

    return (
        <Form.Group className="mb-3">
            <Form.Label>{t('appointment:selectDate')}</Form.Label>
            <div className="calendar" >
                <CalendarMultiselect
                    tileDisabled={isTileDisabled}
                    dates={dates}
                    setDates={setDates}
                />
            </div>
        </Form.Group>
    )
}

export default DateSelect
