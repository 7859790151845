import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ContingentRows, CategoryRows } from '../ProductsOverview/Structure'
import { Table } from 'react-bootstrap'
import ArticleListItem from './ArticleListItem'

const StyledHeaderTr = styled.tr`
th{
    .pending-delivery-info{
        font-size:small;
        color:gray;
        font-weight: initial;
    }
    &:last-child{
        width:120px;
    }
}
`

const TableVersion = ({ contingents }) => {
    const { t } = useTranslation()

    return (
        <Table borderless>
            <tbody>
                {contingents.map(contingent => (
                    <ContingentRows key={contingent.id} contingent={contingent}>
                        {contingent.categories.map(category =>
                            <CategoryRows key={category.id} category={category} hideMaxedOut hideCounter>
                                <StyledHeaderTr>
                                    <th> {t('articles')} </th>
                                    <th> <div>{t('quantity')}</div><div className="pending-delivery-info">* {t('inventory:pendingDelivery')}</div></th>
                                    <th> {t('size')} </th>
                                    <th > </th>
                                </StyledHeaderTr>
                                {category.articles.map(listItem => <ArticleListItem key={listItem.article.id} item={listItem} />)}
                            </CategoryRows>)
                        }
                    </ContingentRows>
                ))}
            </tbody>
        </Table>
    )
}

export default TableVersion
