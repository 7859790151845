import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Form, Row } from 'react-bootstrap'
import CustomDatePicker from '../../../Forms/CustomDatePicker'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'react-bootstrap'
import { useEmployeeHealthDataState } from '../../../../stores/EmployeeHealthDataProvider'
import { CircleCheckIcon } from '../../../Icons/CircleCheckIcon'
import { StyledValidationMessage } from '../formHelper'
import { getIsoDate } from '../../../../utils/getIsoDate'

const QuarantineData = () => {
    const { t } = useTranslation()

    const {
        setCurrentQuarantineStatus,
        currentQuarantineStatus,
        frontendValidation,
        businesspartnerId,
        resetFrontendValidationByKey
    } = useEmployeeHealthDataState()

    const handleChangeText = (e) => {
        e.preventDefault();
        const { value } = e.target
        setCurrentQuarantineStatus({ ...currentQuarantineStatus, comment: value })
    }

    const handleChangeDate = (dateForm) => date => {
        if (date instanceof Date) {
            const preparedDate = getIsoDate(date)

            switch (dateForm) {
                case "startDate":
                    setCurrentQuarantineStatus({ ...currentQuarantineStatus, startDate: preparedDate })
                    resetFrontendValidationByKey('startDate')
                    break;
                case "endDate":
                    setCurrentQuarantineStatus({ ...currentQuarantineStatus, endDate: preparedDate })
                    resetFrontendValidationByKey('endDate')
                    break;
                default: console.log("Datum kann nicht gespeichert werden")
            }
        }
    }
    return (
        <>
            <h1>{t('EmployeeFormHealthData:quaranitneStatus')}</h1>
            {currentQuarantineStatus === null
                ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border'
                    variant='primary' /></div>
                : <Row className="mb-4">
                    <Col sm="12">
                        <Row >
                            <Col sm="12" lg="3">
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Label>{t('EmployeeFormHealthData:inQuarantine')}</Form.Label>
                                    <Form.Check className="p-0">
                                        <div className="d-flex">
                                            <div className="pe-2"><CircleCheckIcon variant={currentQuarantineStatus.isInQuarantine ? "done" : "undone"} /></div>
                                            <div>{t('EmployeeFormHealthData:employeeInQuarantine')}</div>
                                        </div>
                                    </Form.Check>
                                </Form.Group>
                            </Col>
                            <Col sm="6" lg="2">
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('EmployeeFormHealthData:quarantineStart')}</Form.Label>
                                    <CustomDatePicker
                                        dateFormat="dd.MM.yyyy"
                                        placeholderText={t('date')}
                                        onChange={handleChangeDate("startDate")}
                                        showYearDropdown
                                        selected={(currentQuarantineStatus.startDate && new Date(currentQuarantineStatus.startDate)) || ''}
                                        isInvalid={frontendValidation && frontendValidation.startDate}
                                    />
                                    {frontendValidation && frontendValidation.startDate ?
                                        <StyledValidationMessage>
                                            {frontendValidation && frontendValidation.startDate}
                                        </StyledValidationMessage>
                                        : ""
                                    }
                                </Form.Group>
                            </Col>
                            <Col sm="6" lg="2">
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('EmployeeFormHealthData:quarantineEnd')}</Form.Label>
                                    <CustomDatePicker
                                        dateFormat="dd.MM.yyyy"
                                        placeholderText={t('date')}
                                        onChange={handleChangeDate("endDate")}
                                        showYearDropdown
                                        selected={(currentQuarantineStatus.endDate && new Date(currentQuarantineStatus.endDate)) || ''}
                                        isInvalid={frontendValidation && frontendValidation.endDate}
                                    />
                                    {frontendValidation && frontendValidation.endDate ?
                                        <StyledValidationMessage>
                                            {frontendValidation && frontendValidation.endDate}
                                        </StyledValidationMessage>
                                        : ""
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            {businesspartnerId && <Col lg="3" sm="6" >
                                <Form.Group className="mb-3">
                                    <Form.Label>&nbsp;</Form.Label>
                                    <Link className="btn btn-secondary w-100" to={`/appointment/${businesspartnerId}`}>{t('EmployeeFormHealthData:appointmentMake')}</Link>
                                </Form.Group>
                            </Col>}
                            <Col sm="6" lg="4">
                                <Form.Group className="mb-3" controlId="asdf">
                                    <Form.Label>{t('comment')}</Form.Label>
                                    <Form.Control as="textarea" rows="1" name="aa" placeholder={t('comment')} onChange={handleChangeText} value={currentQuarantineStatus.comment ? currentQuarantineStatus.comment : ""} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        </>
    )
}
export default QuarantineData