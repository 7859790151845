import React, { useCallback, useState, useEffect } from 'react'
import { Modal, Button, Alert, Spinner } from 'react-bootstrap'

import OrderActionResult from '../shared/OrderActionResult'
import ProductList from '../shared/ProductList'
import ReturnAddressForm from './ReturnAddressForm'
import ReturnReasonForm from './ReturnReasonForm'
import { useTranslation } from 'react-i18next'
import { PdfButton } from '../../Buttons/PdfButton'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import MandatoryField from '../../MandatoryField/MandatoryField'
import DownloadURLAlert from '../../Forms/DownloadURLAlert'
import ReturnsExistAlert from './ReturnsExistAlert'

const ReturnDialog = (
  {
    onCancel,
    onSubmit,
    success,
    loading,
    issueId,
    issueEmail,
    emailWasSent,
    feedbackId,
    returnsPortalUrl,
    contactData,
    returnReasons,
    countries,
    orderDialogError,
    hideReorderOption,
    returns
  }
) => {
  const { t } = useTranslation()

  const [isSecondStep, setIsSecondStep] = useState(false)
  const [firstFormData, setFirstFormData] = useState(undefined)
  const [secondFormData, setSecondFormData] = useState(undefined)
  const [firstFormValidated, setFirstFormValidated] = useState(false)
  const [secondFormValidated, setSecondFormValidated] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState('')
  const [pdfIsLoading, setPdfIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [returnsPortalUrlAlert, setReturnsPortalUrlAlert] = useState(false)

  /**
   * Set formData and validated state from form
   */
  const handleFirstFormChange = useCallback((formData, validated) => {
    setFirstFormData(formData)
    setFirstFormValidated(validated)
  }, [])
  function handleFirstFormSubmit() {
    setIsSecondStep(true)
  }

  const handleSecondFormChange = useCallback((formData, validated) => {
    setSecondFormData(formData)
    setSecondFormValidated(validated)
  }, [])

  function handleSubmit() {
    if (secondFormValidated) {
      console.log(secondFormData)
      onSubmit({ firstFormData, secondFormData })
    }
  }

  const loadPdf = () => {
    setError('')
    setPdfIsLoading(true)
    setDownloadUrl('')

    apiClient.postJson(APIEndpoints.returns(feedbackId).receipt, {}, true, true)
      .then(response => {
        if (response.headers.location) {
          const newWindowRef = window.open(response.headers.location)
          if (!newWindowRef) {
            setDownloadUrl(response.headers.location)
          }
        }
      }).catch(() => {
        setError(t('reportConfig.createError'))
      }).finally(() => {
        setPdfIsLoading(false)
      })
  }

  const redirectToPortal = useCallback((returnsPortalUrl) => {
    const newWindowRef = window.open(returnsPortalUrl, '_blank')
    if (!newWindowRef) {
      setReturnsPortalUrlAlert(true)
    }
  }, [])

  useEffect(() => {
    if (returnsPortalUrl) {
      redirectToPortal(returnsPortalUrl)
    }
  }, [redirectToPortal, returnsPortalUrl])

  const checkReorderIsMandatory = () => {
    if(firstFormData)
    {
      let reason = returnReasons.find(e => e.id === firstFormData.reason);
      if(reason)
        return reason.reorderIsMandatory
    }
    return false
  }
  let reorderIsMandatory = checkReorderIsMandatory()

  return <Modal
    show
    centered
    onHide={() => onCancel()}
    backdrop='static'
  >
    <Modal.Header>
      <Modal.Title>
        {t('orderDialog:returnProducts')}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {contactData === null ?
        <div className='d-flex justify-content-center p-5'><Spinner animation='border' variant='primary' /></div>
        : <>
          <ProductList hideReorderOption={hideReorderOption} disableSelections={success} reorderIsMandatory={reorderIsMandatory}/>
          {(!isSecondStep && !success && (
            <>
              <ReturnsExistAlert returns={returns} />
              <ReturnReasonForm
                onChange={handleFirstFormChange}
                onSubmit={handleFirstFormSubmit}
                reasons={returnReasons}
              />

            </>
          )) || (isSecondStep && !success && (
            <ReturnAddressForm
              onChange={handleSecondFormChange}
              countries={countries}
              contactData={contactData || {}}
            />
          )) || (success && (
            <OrderActionResult
              issueId={issueId}
              issueEmail={issueEmail}
              emailWasSent={emailWasSent}
            />
          ))}
          {orderDialogError ? (
            <Alert variant='danger' className='mt-4'>{orderDialogError}</Alert>
          ) : ''}
          {error && <Alert variant="danger">{error}</Alert>}
          <MandatoryField />
        </>}
    </Modal.Body>
    <Modal.Footer>
      {!success && <Button
        variant='secondary'
        onClick={() => onCancel()}
      >
        {t('cancelButton')}
      </Button>}
      {!success && !isSecondStep && (
        <Button
          variant='primary'
          onClick={handleFirstFormSubmit}
          disabled={!firstFormValidated}
        >
          {t('nextButton')}
        </Button>
      )}
      {!success && isSecondStep && (
        <Button
          variant='primary'
          onClick={handleSubmit}
          disabled={loading || !secondFormValidated}
        >
          {loading ? t('loading') : t('submitButton')}
        </Button>
      )}

      {success && (
        <React.Fragment>
          <PdfButton
            variant='secondary'
            onClick={() => loadPdf()}
            isLoading={pdfIsLoading}
            buttonText={t('orderDialog:printReturnForm')}
          />
          <Button
            variant='primary'
            onClick={onCancel}
          >
            {t('closeButton')}
          </Button>
        </React.Fragment>
      )}
    </Modal.Footer>
    {downloadUrl && (
      <DownloadURLAlert
        downloadUrl={downloadUrl}
        setDownloadUrl={setDownloadUrl}
        fileName={t('orderDialog:printReturnForm')}
      />
    )}
    {returnsPortalUrlAlert &&
      <DownloadURLAlert
        downloadUrl={returnsPortalUrl}
        setDownloadUrl={setReturnsPortalUrlAlert}
        fileName={returnsPortalUrl}
      />}
  </Modal>
}

export default ReturnDialog
