import React from 'react'
import { Row, Col } from 'react-bootstrap'
import AppointmentTypeSelect from '../../components/Appointment/Content/AppointmentTypeSelect'
import CabinSelect from '../../components/Appointment/Content/CabinSelect'
import DateSelect from '../../components/Appointment/Content/DateSelect'
import TimeSelect from '../../components/Appointment/Content/TimeSelect'
import Info from '../../components/Appointment/Content/Info'
import UserData from '../../components/Appointment/Content/UserData'
import { useAppointmentState } from '../../stores/AppointmentProvider'
import { useTranslation } from 'react-i18next'
import { StyledDisabledContainer } from '../Forms/StyledDisabledContainer'

const AppointmentContentArea = () => {
  const { t } = useTranslation()
  const {
    isStep1Done,
    isStep2Done,
  } = useAppointmentState()

  return (
    <div className="flex-container">
      <div className="flexBox">
        <h1>{t('appointment:step')} 1</h1>
        <Row>
          <Col lg="4">
            <AppointmentTypeSelect />
          </Col>
        </Row>
        <hr></hr>
        <StyledDisabledContainer disabled={!isStep1Done}>
          <h1>{t('appointment:step')} 2</h1>
          <Row>

            <Col lg="4">
              <CabinSelect />
            </Col>
          </Row>

          <Row>
            <Col>
              <DateSelect />
            </Col>
            <Col>
              <TimeSelect />
            </Col>
          </Row>

        </StyledDisabledContainer>
        <hr></hr>
        <StyledDisabledContainer disabled={!isStep2Done}>
          <h1>{t('appointment:step')} 3</h1>
          <Row>
            <Col sm="12" lg="6">
              <Info />
            </Col>
            <Col sm="12" lg="6">
              <UserData />
            </Col>
          </Row>
        </StyledDisabledContainer>
      </div>
    </div >
  )
}

export default AppointmentContentArea
