import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next'
import { t } from 'i18next'

import { useCatalogsState } from '../../../stores/Configuration/CatalogsProvider'
import UniversalTableWrapperTest from '../../Table/UniversalTableWrapperTest'
import { useTableState } from '../../Table/TableProvider'
import { ButtonWithLoadingState } from '../../Buttons'


const CatalogOrderProcesses = () => {
  const { setCatalog, orderProcessTypes, assignedOrderProcessTypes, setAssignedOrderProcessTypes, initAssignedOrderProcessTypes, assignedOrderProcessTypesHasChanged, patchCatalog, loadingStates } = useCatalogsState()
  const { tableRef } = useTableState()
  const [checkedOrderProcessTypes, setCheckedOrderProcessTypes] = useState([])
  const { catalogId } = useParams()

  useEffect(() => {
    initAssignedOrderProcessTypes()
  }, [initAssignedOrderProcessTypes])

  // [{ id: "", isAssigned: bool, isDefaultCatalogForOrderProcess: bool, displayName: "" }]
  useEffect(() => {
    if (orderProcessTypes && assignedOrderProcessTypes) {
      const newCheckedOrderProcessTypes = []
      for (const obj of orderProcessTypes) {
        newCheckedOrderProcessTypes.push({ id: obj.id, isAssigned: assignedOrderProcessTypes.some(item => item.id === obj.id), isDefaultCatalogForOrderProcess: assignedOrderProcessTypes.some(item => item.id === obj.id && item.isDefaultCatalogForOrderProcess === true), displayName: obj.displayName })
      }
      setCheckedOrderProcessTypes(newCheckedOrderProcessTypes)
    }
  }, [orderProcessTypes, assignedOrderProcessTypes])

  const handleIsAssignedCheckbox = (row, cell) => {
    // case 1: if isAssigned is false and isDefaultCatalogForOrderProcess is true, set isDefaultCatalogForOrderProcess to false also
    // case 2: if isAssigned is true/false and isDefaultCatalogForOrderProcess is false, set only isAssigned to true/false
    let newValues = [...assignedOrderProcessTypes]
    let foundIndex = newValues.findIndex(orderProcessType => orderProcessType.id === row.id)

    if (foundIndex > -1) {
      newValues.splice(foundIndex, 1)
    } else {
      let newOrderProcessType = {
        isDefaultCatalogForOrderProcess: false,
        id: row.id
      }
      newValues.push(newOrderProcessType)
    }
    setAssignedOrderProcessTypes(newValues)
    setCatalog(prev => ({
      ...prev,
      assignedOrderProcessTypes: newValues
    }))
  }

  const handleIsDefaultCatalogCheckbox = (row, cell) => {
    // case 1: if isDefaultCatalogForOrderProcess is true and isAssigned is false, set isAssigned also to true
    // case 2: if isDefaultCatalogForOrderProcess is true/false and isAssigned is true, set only isDefaultCatalogForOrderProcess to true/false
    let newValues = [...assignedOrderProcessTypes]
    let foundIndex = newValues.findIndex(orderProcessType => orderProcessType.id === row.id)

    // here we know that it has to exist in the assignedOrderProcessTypes already, since isAssigned has to be true
    newValues[foundIndex].isDefaultCatalogForOrderProcess = !newValues[foundIndex].isDefaultCatalogForOrderProcess
    setAssignedOrderProcessTypes(newValues)
    setCatalog(prev => ({
      ...prev,
      assignedOrderProcessTypes: newValues
    }))
  }

  const columns = [
    {
      dataField: 'isAssigned',
      text: t('assigned'),  // column header
      sort: true,
      formatter: (cell, row, rowIndex, extraProps) => (
        <span>
          <input
            type="checkbox"
            id={`${row.id}-isAssigned`}
            name="cbIsAssigned"
            checked={cell}
            onChange={() => extraProps.handleIsAssignedCheckbox(row, cell)}
          />
        </span>
      ), formatExtraData: {
        handleIsAssignedCheckbox
      }
    },
    {
      dataField: 'isDefaultCatalogForOrderProcess',
      text: t('catalogsAdministration:standardCatalogForOrderProcesses'),
      sort: true,
      formatter: (cell, row, rowIndex, extraProps) => (
        <span>
          <input
            type="checkbox"
            id={`${row.id}-isDefaultCatalog`}
            name="cbIsDefaultCatalog"
            checked={cell}
            disabled={!row.isAssigned}
            onChange={() => extraProps.handleIsDefaultCatalogCheckbox(row, cell)}
          />
        </span>
      ), formatExtraData: {
        handleIsDefaultCatalogCheckbox
      }
    },
    {
      dataField: 'displayName',
      text: t('catalogsAdministration:availableOrderProcesses'),
      sort: true,
    }
  ]

  const handleSubmit = () => {
    patchCatalog(catalogId)
  }

  return (
    <div>
      <UniversalTableWrapperTest tableIsLoading={loadingStates.orderProcessTypes}>
        <BootstrapTable
          ref={tableRef}
          noDataIndication={() => <div className="no-data-placeholder">{!loadingStates.orderProcessTypes && t('catalogsAdministration:noOrderProcessesAvailable')}</div>}
          loading={loadingStates.orderProcessTypes}
          bootstrap4
          keyField="id"
          hover
          data={checkedOrderProcessTypes}
          columns={columns}
          bordered={false}
        />
      </UniversalTableWrapperTest>
      <ButtonWithLoadingState
        disabled={!assignedOrderProcessTypesHasChanged}
        className="my-3"
        buttonText={t('saveButton')}
        isLoading={loadingStates.saveCatalog}
        onClick={handleSubmit}
      />
    </div>
  )
}

export default CatalogOrderProcesses