import apiClient from './api/apiClient'
import { APIEndpoints } from './api/apiConfig'

let employee = null

export async function getEmployee(employeeId, force) {
  if (!employeeId) {
    return null
  }
  if (!force && employee && employee.id === employeeId) {
    return employee
  }

  employee = await apiClient.getJson(APIEndpoints.businessPartner(employeeId).info)
  return employee
}
