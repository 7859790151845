import React from 'react'

export const FilledCircleIcon = ({ color, title, ...rest }) => {
  const useColor = color ? color : '#3C3C3B'

  return (
    <svg width="16px" height="16px" version="1.1" {...rest} viewBox="0 0 15 15">
      {title && <title>{title}</title>}
      <circle cx="50%" cy="50%" r="50%" fill={useColor} />
    </svg>
  )
}