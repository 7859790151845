import { StyledCircleCheckIconWrapper } from './StyledCircleCheckIconWrapper'
import { CircleCheckIcon } from '../../../Icons/CircleCheckIcon'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { emptyGuid } from '../../../../utils/emptyGuid'

const AccessibleCostcentersCheck = ({ accessibleCostcenters, mainCostCenterId }) => {
    const [isValid, setIsValid] = useState(false)
    useEffect(() => {
        let mainCostCenterExists = mainCostCenterId && (mainCostCenterId !== emptyGuid)
        let accessibleCostcentersExist = !!accessibleCostcenters.length
        setIsValid(mainCostCenterExists || accessibleCostcentersExist)
    }, [accessibleCostcenters, mainCostCenterId])

    return (
        <>
            <StyledCircleCheckIconWrapper>
                <CircleCheckIcon variant={isValid ? 'optional' : 'none'} />
            </StyledCircleCheckIconWrapper>
            <span>{t('administration:costcenteradministration')}</span>
        </>
    )
}

export default AccessibleCostcentersCheck