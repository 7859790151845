import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { DeleteIcon } from '../../Icons/DeleteIcon'
import ArraySelect from '../../Select/ArraySelect'
import { StyledItemWrapper } from '../../TreeList/treeList'
import { t } from 'i18next'



const StyledItemContent = styled.div`
  padding-left: 1rem;

  label, .form-check {
    margin: 0px;
    line-height: 38px;
  }
`


const StyledRow1 = styled(Row)`
  div {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .leftColumn {
    justify-content: end;
  }

  .rightColumn {
    justify-content: start;
    padding-left: 20px; 
  }

  input[type="number"] {
    width: 70px;
  }

`

const StyledRowDropdown = styled(Row)`
  margin-bottom: 1rem;
  margin-top: 10px;
  display: flex;

  label {
    margin-right: 50px;
  }

  .form-select {
    display: inline-block;
    width: 300px;
  }
`
const ActivityRow = ({ index, item, activities, updateItem, deleteItem, formErrors }) => {
  return (
    <StyledItemWrapper    >
      <StyledItemContent>
        <StyledRowDropdown>
          <Form.Group as={Col} >
            <Form.Label>{t('contingentTemplates:relatedActivity')}:</Form.Label>
            <ArraySelect
              value={item.activityId || ""}
              name='activityId'
              options={activities}
              onChange={updateItem}
              returnEvent
              isInvalid={formErrors && formErrors[`dataassignedActivities${index}`]}
            />
          </Form.Group>
        </StyledRowDropdown>
        <Row>
          <p>{t('contingentTemplates:pecentageTimeInfo')}:</p>
        </Row>
        <StyledRow1>
          <Form.Group as={Col} className="leftColumn">
            <Form.Label>{t('contingentTemplates:initValue')}:</Form.Label>
            <Form.Control
              size="sm"
              name="activityLevel.startValue"
              type="number"
              value={item.activityLevel.startValue || 0}
              onChange={updateItem}
            />
            <span>%</span>
          </Form.Group>
          <Form.Group as={Col} className="rightColumn">
            <Form.Check
              type="checkbox"
              id={`includeStartValue_${index}`}
              name="activityLevel.includeStartValue"
              label={t('contingentTemplates:includeInitValueCheck')}
              checked={item.activityLevel.includeStartValue || false}
              onChange={updateItem}
            />
          </Form.Group>
        </StyledRow1>
        <StyledRow1>
          <Form.Group as={Col} className="leftColumn">
            <Form.Label>{t('contingentTemplates:finalValue')}:</Form.Label>
            <Form.Control
              size="sm"
              name="activityLevel.endValue"
              type="number"
              value={item.activityLevel.endValue || 0}
              onChange={updateItem}
            />
            <span>%</span>
          </Form.Group>
          <Form.Group as={Col} className="rightColumn">
            <Form.Check
              type="checkbox"
              id={`includeEndValue_${index}`}
              name="activityLevel.includeEndValue"
              label={t('contingentTemplates:includeFinalValueCheck')}
              checked={item.activityLevel.includeEndValue || false}
              onChange={updateItem}
            />
          </Form.Group>
        </StyledRow1>
      </StyledItemContent>
      <div className='action-buttons'>
        <div onClick={deleteItem} title={t('deleteButton')}>
          <DeleteIcon />
        </div>
      </div>
    </StyledItemWrapper>
  )
}

export default ActivityRow