import React, { useMemo } from 'react'
import { useObserver } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import TableProvider, { useTableState } from '../../Table/TableProvider'
import BootstrapTable from 'react-bootstrap-table-next'
import UniversalTableWrapperTest from '../../Table/UniversalTableWrapperTest'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { formatedDate } from '../../../utils/formatedDate'
import styled from 'styled-components'
import { useSRMGroupTableState } from '../../../stores/Administration/SRMGroupTableProvider'
import { Spinner } from 'react-bootstrap'
import { formatedPriceObject } from '../../../utils/formatedPrice'

const StyledReportsTableWrapper = styled.div`
.react-bootstrap-table {background:white}
`
const OrdersTableInner = () => {
    const { t } = useTranslation()

    const { tableRef, paginationOptions, setPageLimit, setPageOffset, currentPage, totalPages } = useTableState()
    const { onRowClick, tableIsLoading, incomingOrders, allSelectedLoading, selectAll, allSelected } = useSRMGroupTableState()

    const columns = useMemo(() => [
        {
            dataField: 'checkBox',
            text: '',
            headerClasses: 'checkBox-header-cell',
            classes: 'checkBox-cell'
        },
        {
            dataField: 'number',
            text: t('srm:srmNumber')
        },
        {
            dataField: 'totalValue',
            text: t('srm:basketSum'),
            formatter: (cell, row) => ((cell && cell.id) ? formatedPriceObject(cell) : "-")
        },
        {
            dataField: 'createdAt',
            text: t('date'),
            formatter: (cell, row) => (formatedDate(cell))
        },
        {
            dataField: 'createdByDisplayName',
            text: t('srm:srmCreator'),
            formatter: (cell, row) => (cell ? cell : "-")
        },
        {
            dataField: 'actions',
            text: t('contact'),
            headerClasses: 'actions-header-cell',
        },

    ], [t]);

    const handleTableChange = (type, newState) => {
        setPageLimit(newState.sizePerPage)
        setPageOffset(newState.page === 1 ? 0 : newState.sizePerPage * newState.page - newState.sizePerPage)
    }

    const preparedColumns = useMemo(() => {
        const tmpColumns = [...columns]
        tmpColumns.forEach(tmpColumn => {
            tmpColumn.headerFormatter = (column, colIndex, components) => {
                switch (tmpColumn.dataField) {
                    case 'checkBox':
                        return (
                            <span>
                                {allSelectedLoading
                                    ? <Spinner animation='border' variant='primary' size="sm" />
                                    : <input type="checkbox" id='checkbox_header' name="scales"
                                        checked={allSelected} onChange={selectAll}>
                                    </input>}
                            </span>)

                    default:
                        return (
                            <>
                                {column.text}
                            </>
                        )
                }
            }
        });
        return tmpColumns
    }, [columns, allSelectedLoading, allSelected, selectAll])

    return useObserver(() => (
        <StyledReportsTableWrapper>
            <UniversalTableWrapperTest tableIsLoading={tableIsLoading} currentPage={currentPage} totalPages={totalPages} >
                <BootstrapTable
                    ref={tableRef}
                    remote
                    noDataIndication={() => <div className="no-data-placeholder">{t('orders:noOrdersAvailable')}</div>}
                    loading={tableIsLoading}
                    keyField='id'
                    data={incomingOrders}
                    columns={preparedColumns}
                    bootstrap4
                    hover
                    bordered={false}
                    pagination={paginationFactory(paginationOptions)}
                    onTableChange={handleTableChange}
                    rowEvents={{ onClick: onRowClick }}
                />
            </UniversalTableWrapperTest>
        </StyledReportsTableWrapper>
    ))
}

const SRMGroupTable = () => {
    //communication between the two stores
    const {
        pageOffset,
        setPageOffset,
        pageLimit,
        setPageLimit,
        totalCount,
        setTotalCount
    } = useSRMGroupTableState()

    const providerSettings = {
        pageOffset,
        setPageOffset,
        pageLimit,
        setPageLimit,
        totalCount,
        setTotalCount
    }
    return (
        <TableProvider {...providerSettings}>
            <OrdersTableInner />
        </TableProvider>
    )
}

export default SRMGroupTable;