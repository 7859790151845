import React, { useCallback, useState, memo } from 'react'
import { Col, Collapse, Form, Row } from 'react-bootstrap'
import { ArrowBigIcon } from '../../../Icons/ArrowBigIcon'
import { StyledCollapseTrigger } from '../formHelper'
import { colors } from '../../../../styles/colors'
import { useTranslation } from 'react-i18next'
import MandatoryField from '../../../MandatoryField/MandatoryField'

const AccountData = memo(({
    collapsible,
    accountData,
    setAccountDataByKey,
    formErrors,
    resetErrorByFormKey
}) => {
    const { t } = useTranslation()

    const [opened, setOpened] = useState(true)
    const handleClickCollapse = useCallback(() => {
        setOpened(prev => !prev)
    }, [setOpened])

    const handleChange = (e) => {
        const { name, value } = e.target
        setAccountDataByKey(name, value)
        if (name === "login") {
            resetErrorByFormKey("accountlogin")
        } else if (name === "password") {
            resetErrorByFormKey("accountpassword")
        }
    }

    return (
        <>
            {collapsible ? (
                <StyledCollapseTrigger onClick={handleClickCollapse}>
                    <h2 className="h1"><ArrowBigIcon variant={opened ? 'down' : 'right'} color={colors.textColor} />{t('username')} / {t('password')}
                    </h2>
                </StyledCollapseTrigger>
            ) : (
                <h2 className="h1">{t('username')} / {t('password')}</h2>
            )}
            <Collapse in={opened}>
                <div className="spacing-down">
                    <Row>
                        <Col lg="3" sm="6" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label>{t('username')} *</Form.Label>
                                <div>
                                    <Form.Control
                                        type="text"
                                        placeholder={t('username')}
                                        name="login"
                                        onChange={handleChange}
                                        value={accountData.login}
                                        isInvalid={!!formErrors['accountlogin']}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formErrors['accountlogin'] && formErrors['accountlogin'].msg}
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group >
                        </Col>

                        <Col sm="6" lg="3">
                            <Form.Group className="mb-3">
                                <Form.Label>{t('loginBox:password')} *</Form.Label>
                                <div>
                                    <Form.Control
                                        type="text"
                                        placeholder="***********"
                                        name="password"
                                        onChange={handleChange}
                                        value={accountData.password || ""}
                                        isInvalid={!!formErrors['accountpassword']}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formErrors['accountpassword'] && formErrors['accountpassword'].msg}
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    < MandatoryField />
                </div>
            </Collapse>
        </>
    )
})

export default AccountData