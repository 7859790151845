import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ScaleIcon } from '../Icons/ScaleIcon'
import { inject, observer } from 'mobx-react'
import ProductsCompareModal from '../ProductsCompare/ProductsCompareModal'

const StyledScaleButton = styled.div`
  cursor:pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 40px;
  border: none;
  background-color: transparent;
  .bubble {
    background: ${props => props.theme.primaryColor};
    font-size: 8px;
    border-radius: 50px;
    height: 13px;
    width: 13px;
    text-align: center;
    line-height: 1.2;
    color: white;
    position: absolute;
    display: flex;
    bottom: 9px;
    right: 1px;
    align-items: center;
    justify-content: center;
  }
`
const ScaleButton = inject('productsCompareStore')(observer((props) => {
    const { location, productsCompareStore } = props
    const [showScaleButton, setShowScaleButton] = useState(false)

    useEffect(() => {
        productsCompareStore.updateProductsCompareStore()
    }, [productsCompareStore])

    useEffect(() => {
        if (location.pathname.includes('/costcenterproducts')) {
            setShowScaleButton(true)
        }
        else {
            setShowScaleButton(false)
            productsCompareStore.resetProductsCompareStore()
        }
    }, [location, productsCompareStore, setShowScaleButton])

    return (
        <>
            {showScaleButton && <StyledScaleButton onClick={() => productsCompareStore.setShowProductsCompareModal(true)}>
                <ScaleIcon />
                <span className="bubble">{productsCompareStore.articles.length}</span>
            </StyledScaleButton>}
            <ProductsCompareModal />
        </>
    )
}))

export default ScaleButton
