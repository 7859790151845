import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/colors'
import { Collapse, Spinner } from 'react-bootstrap'
import { ArrowBigIcon } from '../../Icons/ArrowBigIcon'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import MoreOptionsButton from '../../Buttons/MoreOptionsButton'
import { BsCheckAll, BsTrashFill } from 'react-icons/bs'

import useStores from '../../../stores/useStore'
import { t } from 'i18next'
import ArticleListItem from './ArticleListItem'
import CatalogArticles from './CatalogArticles'


export const StyledContingentList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`
export const StyledProductGroupList = styled.ul`
  list-style: none;
  padding: 0 0 0 20px;
`
export const StyledListWrapper = styled.div`
  padding-left: 45px;
`
export const StyledTitleWrapper = styled.div`
  display: flex;
  margin-bottom: 25px;

  .moreOptions {
    margin-left: 10px;
    cursor: pointer;
    display: none;
  }

  &:hover {
    .moreOptions {
        display:block;
    }
  }

  .title  {
    display: flex;
    align-items: flex-start;
    
    cursor: pointer;
    font-size: 18px;
    color: ${colors.textColorB80};
  
    svg {
      margin-top: -2px;
      margin-right: 15px;
    }
  }
`


export const moreOptions = (item, inventoryStore) => {

    const options = [
        {
            option: 'fill',
            optionText: t('ProductOverview:fillItem'),
            optionIcon: <BsCheckAll />,
            onOptionSelect: () => inventoryStore.onMoreOptionsHandler(item, 'fill')
        },
        {
            option: 'clear',
            optionText: t('ProductOverview:clearItems'),
            optionIcon: <BsTrashFill />,
            onOptionSelect: () => inventoryStore.onMoreOptionsHandler(item, 'clear')
        }
    ]

    return options;
}

const ContingentsList = ({ contingent }) => {
    const [contingentOpened, setContingentOpened] = useState(true)
    const { actionType } = useParams()
    const { inventoryStore } = useStores()
    const options = moreOptions(contingent, inventoryStore);

    return (
        <StyledContingentList>
            <li className="mb-4">
                {contingent.displayName !== '' && (
                    <StyledTitleWrapper>
                        <span className="title" onClick={() => setContingentOpened(prev => !prev)}>
                            <ArrowBigIcon variant={contingentOpened ? 'down' : 'right'} />
                            {contingent.displayName}
                        </span>
                        {(actionType === "returnBookings" || actionType === "articleTransfer") ?
                            <div className="moreOptions">
                                <MoreOptionsButton options={options} />
                            </div> : null}
                    </StyledTitleWrapper>
                )}
                <Collapse in={contingentOpened}>
                    <StyledListWrapper>
                        <StyledProductGroupList>
                            {contingent.categories.map(category => (
                                <CategoryList
                                    key={category.id}
                                    category={category}
                                />
                            ))}
                        </StyledProductGroupList>
                    </StyledListWrapper>
                </Collapse>
            </li>
        </StyledContingentList >)
}

const CategoryList = ({ category }) => {
    const [categoryOpened, setCategoryOpened] = useState(true)
    const { actionType } = useParams()
    const { inventoryStore } = useStores()
    const options = moreOptions(category, inventoryStore);

    return (
        <StyledContingentList>
            <li className="mb-4">
                {category.displayName !== '' && (
                    <StyledTitleWrapper>
                        <span className="title" onClick={() => setCategoryOpened(prev => !prev)}>
                            <ArrowBigIcon variant={categoryOpened ? 'down' : 'right'} />
                            {category.displayName}
                        </span>
                        {(actionType === "returnBookings" || actionType === "articleTransfer") ?
                            <div className="moreOptions">
                                <MoreOptionsButton options={options} />
                            </div> : null}
                    </StyledTitleWrapper>
                )}
                <Collapse in={categoryOpened}>
                    <StyledListWrapper>
                        <StyledProductGroupList>
                            {category.articles.map(article => (
                                <ArticleListItem article={article} key={article.id} />
                            ))}
                        </StyledProductGroupList>
                    </StyledListWrapper>
                </Collapse>
            </li>
        </StyledContingentList >)
}




const InventoryTree = observer(({ listRef }) => {
    const { t } = useTranslation()
    const { inventoryStore } = useStores()
    const filteredContingents = inventoryStore.filteredContingents
    const contingentsIsLoading = inventoryStore.loadContingentIsLoading
    return (
        <>
            {contingentsIsLoading && <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >}
            {(filteredContingents.length === 0 && !contingentsIsLoading) && (
                <strong>{t('ProductOverview:noResultsFound')}</strong>
            )}
            {inventoryStore.showAllArticles
                ? <CatalogArticles listRef={listRef} />
                : filteredContingents.map(contingent => (
                    <ContingentsList contingent={contingent} key={contingent.id} />
                ))}
        </>
    )
})

export default InventoryTree
