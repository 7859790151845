import React, { useState, useEffect } from 'react'
import { Col, Row, Button } from "react-bootstrap";

const InLineVariantDisplay = ({ lastSelectedVariant, setOpenVariantModal, article }) => {
    const [firstSelected, setFirstSelected] = useState(null)
    const [secondSelected, setSecondSelected] = useState(null)

    useEffect(() => {
        if (lastSelectedVariant) {
            if (lastSelectedVariant.separatedDisplayName.length) {
                setFirstSelected(lastSelectedVariant.separatedDisplayName[0])
                setSecondSelected(lastSelectedVariant.separatedDisplayName[1])
            }
            else {
                setFirstSelected(lastSelectedVariant.displayName)
                setSecondSelected(null)
            }
        }
        else {
            setFirstSelected(null)
            setSecondSelected(null)
        }
    }, [lastSelectedVariant])
    return (
        <Row>

            <Col>
                <Button className="w-100" onClick={() => setOpenVariantModal(article)} size='sm' variant={firstSelected ? "success" : "danger"}>{firstSelected || "-"}</Button>
            </Col>
            {
                secondSelected
                    ? <Col><Button className="w-100" onClick={() => setOpenVariantModal(article)} size='sm' variant="success">{secondSelected}</Button></Col>
                    : null
            }
        </Row >
    )
}

export default InLineVariantDisplay
