import React from 'react'
import OverlayWrapper from '../Page/logix2020/OverlayWrapper'
import NewPasswordBox from '../PersonalSpaceBox/NewPasswordBox'
import OverlayContainer from '../Page/logix2020/OverlayContainer'
import styled from 'styled-components'

const StyledContentWrapper = styled.div`
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100vw - 30px);
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  padding: 20px;

  @media screen and (min-width: 630px){
    width: 600px;
  }

`

const ChangePasswordOverlay = () => {

  return (
    <OverlayContainer>
      <OverlayWrapper>
        <StyledContentWrapper>
          <NewPasswordBox/>
        </StyledContentWrapper>
      </OverlayWrapper>
    </OverlayContainer>
  )
}

export default ChangePasswordOverlay
