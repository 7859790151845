import React from 'react'
import { Alert } from 'react-bootstrap'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
const StyledAlert = styled(Alert)`
  p:last-child {
    margin-bottom: 0;
  }
`

/**
 * Displays a result message
 * @param issueId
 * @param issueEmail
 * @returns {*}
 * @constructor
 */
const mandate = process.env.REACT_APP_MANDATORSET

const OrderActionResult = (
  {
    issueId,
    issueEmail,
    emailWasSent
  }
) => {
  const { t } = useTranslation()

  return (
    <StyledAlert variant='success'>
      <p>
        {t('orderDialog:successMsg')} <b>{issueId}</b>.
      </p>
      {
        mandate === 'kliniken' && <p> <Trans i18nKey='kliniken:orderDialogSuccessMsg'></Trans></p>
      }
      {emailWasSent ? <p>
        <b>
          <Trans i18nKey='orderDialog:successMsgSubtext'>Die Bestätigung wurde an {{ issueEmail }} verschickt. Überprüfen Sie auch Ihren Spam Ordner.</Trans>
        </b>
      </p> : null}
    </StyledAlert>
  )
}

export default OrderActionResult
