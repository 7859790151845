import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import ProductInfo from '../../Product/ProductInfo'
import { RetourIcon } from '../../Icons/RetourIcon'
import { colors } from '../../../styles/colors'

const StyledProductItemWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${props => props.showQuantity ? '85px 2fr 1fr' : '85px 1fr'};
  flex-grow: 1;
  max-width: 600px;
  
  &.has-error{
    border: 1px solid red;
    border-radius: 5px;
    padding: 10px;
    margin: 0 -11px;
  }
  
  &:hover {
    ${props => props.withHandleClick && 'cursor: pointer;'}
  }
  
  @media screen and (min-width: 790px) {      
    align-items: center;
  }
`

const StyledAmountCol = styled.div`    
  padding-left: 20px;
  font-size: 0.9em;
  white-space: nowrap;
  user-select: none;
  
  @media screen and (min-width: 992px) {
    grid-column: auto;
    padding: 20px 0 20px 20px;
  }
`
const StyledAmount = styled.span`
  display: inline-block;
  margin: 0 5px;
`
const StyledAmountArrowsWrap = styled.div`
  display: inline-flex;
  vertical-align: middle;
  margin-left: 4px;
  align-items: center;
`
const StyledRetourIcon = styled(RetourIcon)`
  cursor: help;
`
export const PositionItemImageAmount = ({ handleClick, orderPosition, quantity, showQuantity = true, returnedPositionsExist = false }) => {
  const { t } = useTranslation()

  return (
    <StyledProductItemWrapper withHandleClick={!!handleClick} onClick={handleClick} data-bs-toggle="tooltip" title={!!handleClick ? t('inventory:goToArticle') : null} showQuantity={showQuantity}>
      <ProductInfo articleObject={orderPosition} position={orderPosition} iconType={null} />
      {showQuantity &&
        <StyledAmountCol>{t('amount')}: &nbsp;
          <StyledAmountArrowsWrap>
            <StyledAmount>{quantity}</StyledAmount>
            {returnedPositionsExist ? <StyledRetourIcon title={t('orders:returnsExist')} color={colors.gray3} /> : null}

          </StyledAmountArrowsWrap>
        </StyledAmountCol>}
    </StyledProductItemWrapper>
  )
}