import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import CostCenterSelect from "../Employees/Form/FormComponents/CostCenterSelect"


class CostCenterSearchField extends Component {
    render() {
        const { ordersStore } = this.props
        return <CostCenterSelect id="costCenterSearchField"
            onChange={(costCenter) => this.props.handleChange(costCenter ? costCenter.id : null)}
            value={ordersStore.targetCostcenterId}
            withSearch />

    }
}

export default withTranslation()(inject('ordersStore')(observer(CostCenterSearchField)))
