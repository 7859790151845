import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Form } from 'react-bootstrap'
import { colors } from '../../../../styles/colors'
import { useTranslation } from 'react-i18next'

const StyledWrapper = styled.div`
border: 1px solid rgba(0,0,0,0.125);
border-radius: 0.25rem;
padding 1rem;
height:100px;
overflow: auto;
background: ${colors.gray6};

.action-buttons {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    & > div {
      margin: 0 5px;
      width:24px;
      height:24px;
    }
 }
 .styled-row {
     display:flex;
     margin:1px ;
  @media screen and (min-width: 1080px) {
    .action-buttons {
      visibility: hidden;
      &.visible {
        visibility: visible;
      }
    }
  }
  &:hover {
   .action-buttons {
    visibility: visible;
   }
  }
}

`

const LaundryShelfTreeSelectedItems = inject('laundryShelfStore')(observer((props) => {
  const { laundryShelfStore, renderExtraItemContent } = props
  const { t } = useTranslation()
  return (
    <Form.Group className="mb-3">
      <Form.Label>{t('laundryShelves:assignedLaundryShelves')}</Form.Label>
      <StyledWrapper>
        {laundryShelfStore.selectedItems.map(laundryShelf => (
          <div key={laundryShelf.id} className="styled-row">
            <div>{laundryShelf.displayName}</div>
            {renderExtraItemContent(laundryShelf, true)}
          </div>
        ))}
      </StyledWrapper>
    </Form.Group >

  )
}))

export default LaundryShelfTreeSelectedItems
