import styled from 'styled-components'

export const PlusIcon = styled.span`
  display: block;
  width: 17px;
  height: 17px;
  padding: 3px;
  cursor: pointer;
  &::before{
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${props => encodeURIComponent(props.color ? props.color : '#00035c')}' viewBox='0 0 8 8'%3E%3Cpath d='M3 0v3h-3v2h3v3h2v-3h3v-2h-3v-3h-2z'/%3E%3C/svg%3E") no-repeat center center;
  }
`
