import React, { useRef } from 'react'
import { DeleteIcon } from '../../Icons/DeleteIcon';
import { PenIcon } from '../../Icons/PenIcon';
import {
  StyledListItemText,
} from '../../TreeList/treeList';
import { t } from 'i18next';
import { useDNDList } from '../../../utils/hooks/useDNDList';
import { BiGlobe } from 'react-icons/bi';
import { StyledContextMenu } from '../../ContextMenu/StyledContextMenu';
import { ContextMenuTrigger } from 'react-contextmenu';
import { StyledItemWrapperDND } from '../../TreeList/dndTreeList';
import MediaQuery from 'react-responsive';
import MoreOptionsButton from '../../Buttons/MoreOptionsButton';
import PortalContextMenu from '../../ContextMenu/PortalContextMenu';
import { formatedDate } from '../../../utils/formatedDate';

const NewsItem = ({ news, setDeleteNewsId, handleEditNews, index, moveCardHandler, isMainMandator, setEditNewsIdAssignments }) => {
  const ref = useRef(null)
  const { itemPosition, isOver, isDragging, drag, drop } = useDNDList(index, moveCardHandler, "CARD")
  const opacity = isDragging ? "0.2" : "1";
  drag(drop(ref))

  const options = [
    {
      option: 'edit',
      optionText: t('edit'),
      optionIcon: <PenIcon />,
      onOptionSelect: () => handleEditNews(news.id)
    },
    {
      option: 'delete',
      optionText: t('deleteButton'),
      optionIcon: <DeleteIcon />,
      onOptionSelect: () => setDeleteNewsId(news.id)
    },
    isMainMandator ? {
      option: 'assignNews',
      optionText: t('newsAdmin:assignNews'),
      optionIcon: <BiGlobe />,
      onOptionSelect: () => setEditNewsIdAssignments(news.id)
    } : null
  ]

  return (
    <StyledContextMenu>
      <ContextMenuTrigger id={`${news.id}_context`} holdToDisplay={-1}>
        <StyledItemWrapperDND
          ref={ref}
          id={`${index}_id`}
          style={{ opacity }}
          showTopLine={itemPosition === "above" && isOver}
          showBottomLine={itemPosition === "below" && isOver}
          hierarchyLevel={0}
          isClickable
        >
          <StyledListItemText >
            <div className='d-flex justify-content-between mb-2'>
              <div>{news.createdByDisplayName}</div>
              <div>{formatedDate(news.createdAt)}</div>
            </div>
            <b> {news.header ? news.header : t('newsAdmin:noHeadline')}&nbsp;</b>
            <span className="subText"> {news.body} </span>
          </StyledListItemText>
          <MediaQuery maxWidth={1499}>
            <div className="action-buttons">
              <MoreOptionsButton options={options} />
            </div>
          </MediaQuery>
        </StyledItemWrapperDND>
      </ContextMenuTrigger>
      <PortalContextMenu contextId={`${news.id}_context`} options={options} />
    </StyledContextMenu>
  )
}


export default NewsItem