import React from 'react'

export const CustomizationIcon = ({ color, title, height, width, ...rest }) => {
  const useColor = color ? color : '#3C3C3B'
  const useHeight = height ? height : '16px'
  const useWidth = width ? width : '16px'
  return (
    <svg stroke="currentColor" fill={useColor} strokeWidth="0" viewBox="3 3 18 18" height={useHeight} width={useWidth}  >
      {title && <title>{title}</title>}
      <path d="M4 11h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1zm1-6h4v4H5V5zm15-2h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 6h-4V5h4v4zm-9 12a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6zm-5-6h4v4H5v-4zm13-1h-2v2h-2v2h2v2h2v-2h2v-2h-2z">
      </path>
    </svg>
  )
}

