import React, { useState, useEffect } from 'react'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import AdministrationSidebarNavigation from '../../components/Configuration/AdministrationSidebarNavigation'
import { Link } from 'react-router-dom'
import AppointmentTypesProvider from '../../stores/Configuration/AppointmentTypesProvider'
import AppointmentTypesOverview from '../../components/Configuration/AppointmentType/AppointmentTypesOverview'
import { useTranslation } from 'react-i18next'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import useStores from '../../stores/useStore'
import { Alert, Spinner } from 'react-bootstrap'
import { MdLibraryAdd } from 'react-icons/md'


const AppointmentTypesAdministrationPage = () => {
  const { t } = useTranslation()
  const { uiStore } = useStores()
  const [error, setError] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setError(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {!uiStore.fittingAdministration ? (
        <>
          <Subheader className="subHeader-v1">
            <div className="subheader-title">{t('appointment:typeAdministration')}</div>
          </Subheader>
          <FlexWrapper>
            <SidebarLeft>
              <AdministrationSidebarNavigation active="appointmentTypes" />
            </SidebarLeft>
            <ContentArea>
              {error
                ? <Alert variant="danger">{t('accessDenied')}</Alert>
                : <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
              }
            </ContentArea>
          </FlexWrapper>
          <BigFooter />
        </>
      ) : (
        <>
          <Subheader className="subHeader-v1">
            <div className="subheader-title flex-grow-1">{t('appointment:typeAdministration')}</div>
            <Link to='/appointmenttypes/create' className="btn btn-primary subheader__button text-white"><MdLibraryAdd className="me-3" />{t('appointment:addNewType')}</Link>
          </Subheader>
          <FlexWrapper>
            <SidebarLeft>
              <AdministrationSidebarNavigation active="appointmentTypes" />
            </SidebarLeft>
            <ContentArea>
              <AppointmentTypesProvider>
                <AppointmentTypesOverview />
              </AppointmentTypesProvider>
            </ContentArea>
          </FlexWrapper>
          <BigFooter />
        </>
      )}
    </>

  )
}

export default AppointmentTypesAdministrationPage
