import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { DeleteIcon } from '../../Icons/DeleteIcon';
import { PenIcon } from '../../Icons/PenIcon';
import { StyledListItemText, } from '../../TreeList/treeList';
import { t } from 'i18next';
import { useDNDList } from '../../../utils/hooks/useDNDList';
import { ContextMenuTrigger } from 'react-contextmenu';
import { StyledContextMenu } from '../../ContextMenu/StyledContextMenu';
import { StyledItemWrapperDND } from '../../TreeList/dndTreeList';
import MediaQuery from 'react-responsive';
import MoreOptionsButton from '../../Buttons/MoreOptionsButton';
import PortalContextMenu from '../../ContextMenu/PortalContextMenu';




const ActivityItem = ({ activity, setDeleteActivityId, index, moveCardHandler }) => {

  const navigate = useNavigate();
  const ref = useRef(null)

  const editRow = (activityId) => {
    let path = `/activitiesAdministration/edit/${activityId}`;
    navigate(path);
  }
  const { itemPosition, isOver, isDragging, drag, drop } = useDNDList(index, moveCardHandler, "CARD")

  const opacity = isDragging ? "0.2" : "1";
  drag(drop(ref))
  const options = [
    {
      option: 'edit',
      optionText: t('edit'),
      optionIcon: <PenIcon />,
      onOptionSelect: () => editRow(activity.id)
    },
    {
      option: 'delete',
      optionText: t('deleteButton'),
      optionIcon: <DeleteIcon />,
      onOptionSelect: () => setDeleteActivityId(activity.id)
    }
  ]

  return (
    <StyledContextMenu>
      <ContextMenuTrigger id={`${activity.id}_context`} holdToDisplay={-1}>
        <StyledItemWrapperDND
          ref={ref}
          id={`${index}_id`}
          style={{ opacity }}
          showTopLine={itemPosition === "above" && isOver}
          showBottomLine={itemPosition === "below" && isOver}
          hierarchyLevel={0}
          isClickable

        >
          <StyledListItemText>
            {activity.displayName}
          </StyledListItemText>
          <MediaQuery maxWidth={1499}>
            <div className="action-buttons">
              <MoreOptionsButton options={options} />
            </div>
          </MediaQuery>
        </StyledItemWrapperDND>
      </ContextMenuTrigger>
      <PortalContextMenu contextId={`${activity.id}_context`} options={options} />
    </StyledContextMenu>

  )
}

export default ActivityItem