import React from 'react'
import styled, { withTheme } from 'styled-components'
import { MagnifyingGlassIcon } from '../Icons/MagnifyingGlassIcon'
import { colors } from '../../styles/colors'
import { CrossIcon } from '../Icons/CrossIcon'

const StyledInput = styled.input`
  padding-right: 30px;
  width: 100% !important;
`

const StyledMagnifyingGlassIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  z-index: 0;
`

const StyledCrossIcon = styled(CrossIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  z-index: 0;
  right: 32px;
`


const SearchField = (props) => {
  const { theme, onChange, placeholder, value, className, onClearClick } = props
  return (
    <div className={`${className ? className : ''} form-inline position-relative`}>
      <StyledInput type='text' className='form-control' placeholder={placeholder} onChange={onChange} value={value} />

      <StyledMagnifyingGlassIcon>
        <MagnifyingGlassIcon color={theme.primaryColor} />
      </StyledMagnifyingGlassIcon>

      {value ?
        <StyledCrossIcon color={colors.gray2} onClick={onClearClick} />
        : ''}
    </div>
  )
}

export default withTheme(SearchField)
