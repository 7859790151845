
import React, { useMemo } from 'react'
import { useObserver } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import TableProvider, { useTableState } from '../Table/TableProvider'
import BootstrapTable from 'react-bootstrap-table-next'
import UniversalTableWrapperTest from '../Table/UniversalTableWrapperTest'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { formatedDate } from '../../utils/formatedDate'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { useInventoryBookingsState } from '../../stores/InventoryBookingsTableProvider'

const StyledReportsTableWrapper = styled.div`
.react-bootstrap-table {background:white}
`
const InventoryBookingsTableInner = () => {
  const { t } = useTranslation()
  const { tableRef, paginationOptions, setPageLimit, setPageOffset, currentPage, totalPages } = useTableState()
  const { onRowClick, tableIsLoading, incomingOrders, allSelectedLoading, selectAll, allSelected } = useInventoryBookingsState()
  const columns = useMemo(() => [
    {
      dataField: 'checkBox',
      text: '',
      headerClasses: 'checkBox-header-cell',
      classes: 'checkBox-cell'
    },
    {
      dataField: 'createdAt',
      text: t('date'),
      formatter: (cell) => <div >{formatedDate(cell)}</div>
    },
    {
      dataField: '__users',
      text: t('reports:requestedAtHeader'),
      formatter: (cell, row) => {
        return (
          <>
            <div ><b>{t('From')}:</b> {row.createdByDisplayName}</div>
            <div><b>{t('For')}:</b>  {row.targetBusinessPartner.displayName} </div>
          </>
        )
      }
    },
    {
      dataField: '__article',
      text: t('articles'),
      formatter: (cell, row) => {
        return (
          <>
            <div   >{row.article.displayName} ({row.article.articleNumber}) </div>
            <div>
              <small> {t('size')}:{row.articleVariant.displayName}</small>
            </div>
          </>
        )
      }
    },
    {
      dataField: '__quantities',
      text: t('variation'),
      formatter: (cell, row) => <>{row.requestedQuantity} ({t('inventory:currentAmount')}: {row.currentQuantity})</>
    },
    {
      dataField: 'changeReason',
      text: t('orderDialog:reason'),
      formatter: (cell, row) => (cell.displayName)
    },
    {
      dataField: 'actions',
      text: '',
      headerClasses: 'checkBox-header-cell',
      classes: 'checkBox-cell'
    }
  ], [t]);

  const handleTableChange = (type, newState) => {
    setPageLimit(newState.sizePerPage)
    setPageOffset(newState.page === 1 ? 0 : newState.sizePerPage * newState.page - newState.sizePerPage)
  }
  const preparedColumns = useMemo(() => {
    const tmpColumns = [...columns]
    tmpColumns.forEach(tmpColumn => {
      tmpColumn.headerFormatter = (column, colIndex, components) => {
        switch (tmpColumn.dataField) {
          case 'checkBox':
            return (
              <span>
                {allSelectedLoading
                  ? <Spinner animation='border' variant='primary' size="sm" />
                  : <input type="checkbox" id='checkbox_header' name="scales"
                    checked={allSelected} onChange={selectAll}>
                  </input>}
              </span>)
          default:
            return (
              <>
                {column.text}
              </>
            )
        }
      }
    });
    return tmpColumns
  }, [columns, allSelectedLoading, allSelected, selectAll])

  return useObserver(() => (
    <StyledReportsTableWrapper>
      <UniversalTableWrapperTest tableIsLoading={tableIsLoading} currentPage={currentPage} totalPages={totalPages} >
        <BootstrapTable
          ref={tableRef}
          remote
          noDataIndication={() => <div className="no-data-placeholder">{t('feeds:noEntries')}</div>}
          loading={tableIsLoading}
          keyField='id'
          data={incomingOrders}
          columns={preparedColumns}
          bootstrap4
          hover
          bordered={false}
          pagination={paginationFactory(paginationOptions)}
          onTableChange={handleTableChange}
          rowEvents={{ onClick: onRowClick }}
        />
      </UniversalTableWrapperTest>
    </StyledReportsTableWrapper>
  ))
}

const InventoryBookingsTable = () => {
  //communication between the two stores
  const {
    pageOffset,
    setPageOffset,
    pageLimit,
    setPageLimit,
    totalCount,
    setTotalCount
  } = useInventoryBookingsState()

  const providerSettings = {
    pageOffset,
    setPageOffset,
    pageLimit,
    setPageLimit,
    totalCount,
    setTotalCount
  }
  return (
    <TableProvider {...providerSettings}>
      <InventoryBookingsTableInner />
    </TableProvider>
  )
}

export default InventoryBookingsTable;