import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { PrevOrderIcon } from '../Icons/PrevOrderIcon'
import { Tab, Tabs } from 'react-bootstrap'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import LoadingOverlay from '../Loading/LoadingOverlay'
import ListCard from './ListCard'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import getThemeValue from '../../utils/getThemeValue'
import { t } from 'i18next'

const StyledList = styled.ul`
  margin: 0 -4px;
  .list-card__row{
    padding-left: 4px;
    padding-right: 8px;
  }
`

const StyledTabs = styled.div`
.nav-tabs { 
  .nav-item {
    .nav-link {
    &.active {
      background: none;
      font-weight:bold;
    }
  }
  }
}
`
const OrdersSummaryCard = ({ showAllOrders = false }) => {
  const [counters, setCounters] = useState([])
  const [lastMonths, setLastMonths] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const mountedRef = useRef(true)


  const mandate = process.env.REACT_APP_MANDATORSET
  const openApprovalsSRMText = mandate === 'sixt' ? t('orders:openApprovalsCOUPAOrders') : t('orders:openApprovalsSRMOrders')

  useEffect(() => {
    const loadingTimeout =
      setTimeout(() => { setIsLoading(true) }, 200)
    const filter = { 'filter.lastMonths': lastMonths, 'filter.showAllOrders': showAllOrders ? showAllOrders : null }
    apiClient.getJson(APIEndpoints.incomingOrdersCounter, filter).then(result => {
      if (!mountedRef.current) { return null }
      setCounters(result)
      clearTimeout(loadingTimeout)
      setIsLoading(false)
    })
    return () => {
      clearTimeout(loadingTimeout);
    }
  }, [lastMonths, showAllOrders])

  //Cleanup Function
  useEffect(() => {
    return () => { mountedRef.current = false }
  }, [])

  const getName = useCallback((status) => {
    var title = ''
    switch (status) {
      case 'open':
        title = t('orders:tabOpen')
        break
      case 'closed':
        title = t('orders:tabClosed')
        break
      case 'cancelled':
        title = t('orders:tabCancelled')
        break
      case 'openApprovals':
        title = t('orders:openApprovalsOrders')
        break
      case 'openTransmissionsSRM':
        title = t('orders:openTransmissionsSRMOrders')
        break
      case 'openApprovalsSRM':
        title = getThemeValue('ordersTabs', 'ordersTabHeader', openApprovalsSRMText)
        break
      default:
        title = status
        break
    }
    return title
  }, [openApprovalsSRMText])

  const OrdersCountList = useMemo(() => () => (
    <StyledList>
      {counters.filter(apiObj => apiObj.count).map(apiObj => <li key={apiObj.status} className="list-card__row">
        <span className="title">
          <Link
            to='/orders'
            state={{ selectedStatus: apiObj.status, lastMonths, showAllOrders }}
          >
            {getName(apiObj.status)}</Link></span>
        <span className="counter">{apiObj.count}</span>
      </li>)}
    </StyledList >
  ), [counters, getName, lastMonths, showAllOrders])

  const handleSelectTab = useCallback(month => {
    setLastMonths(parseInt(month))
  }, [])

  return (
    <ListCard>
      <p className="h2 list-card__title">
        <i><PrevOrderIcon /></i>
        {<span>{showAllOrders ? t('orders:allOrders') : t('orders:title')}</span>}
      </p>
      <StyledTabs >
        <Tabs defaultActiveKey="1" onSelect={handleSelectTab} transition={false}>
          <Tab eventKey="1" title={t('dashboard:lastMonth')}>
            <LoadingOverlay isLoading={isLoading} noBackground>
              <OrdersCountList />
            </LoadingOverlay>
          </Tab>
          <Tab eventKey="3" title={t('dashboard:lastQuarter')}>
            <LoadingOverlay isLoading={isLoading} noBackground>
              <OrdersCountList />
            </LoadingOverlay>
          </Tab>
          <Tab eventKey="12" title={t('dashboard:lastYear')}>
            <LoadingOverlay isLoading={isLoading} noBackground>
              <OrdersCountList />
            </LoadingOverlay>
          </Tab>
          <Tab eventKey="0" title={t('all')}>
            <LoadingOverlay isLoading={isLoading} noBackground>
              <OrdersCountList />
            </LoadingOverlay>
          </Tab>
        </Tabs>
      </StyledTabs>
    </ListCard>
  )
}

export default OrdersSummaryCard

