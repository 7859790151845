import React, { useEffect, useState } from 'react'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { formatedDate } from '../../../utils/formatedDate'
import { PenIcon } from '../../Icons/PenIcon'
import { getIsoDate } from '../../../utils/getIsoDate'


const EmployeeHealthData = ({ selectedRow }) => {
    const [nextCovidAppointment, setNextCovidAppointment] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        setIsLoading(true)
        setNextCovidAppointment(null)
        selectedRow && apiClient.getJson(APIEndpoints.appointments, {
            'filter.startDate': getIsoDate(new Date()),
            'filter.targetBusinesspartnerId': selectedRow.id
        }).then(appointments => {
            if (appointments) {
                let next = appointments[0]
                next && setNextCovidAppointment(next)
            }
        })
            .finally(() => {
                setIsLoading(false)
            })
    }, [selectedRow])

    const getFormattedDate = (obj) => {
        const Type = obj.appointmentType && obj.appointmentType.displayName
        const Day = formatedDate(obj.day)
        const Hour = obj.timeSlot && obj.timeSlot.hour < 10 ? "0" + obj.timeSlot.hour : obj.timeSlot.hour
        const Minute = obj.timeSlot && obj.timeSlot.minute < 10 ? "0" + obj.timeSlot.minute : obj.timeSlot.minute
        return `${Type} ${Day} ${Hour}:${Minute}Uhr`
    }
    const renderAppointmentData = () => (
        <ul className="data-list__data">
            <li>
                <div className="data-list__label"> {t('EmployeeFormHealthData:nextAppointment')}:</div>
                <div className="data-list__value">{(nextCovidAppointment && getFormattedDate(nextCovidAppointment)) || "-"}</div>
            </li>
        </ul>
    )
    return (
        <>
            <span className="data-list__name h2">{t('appointment:appointments')}
                <Link to={`/editappointments/${selectedRow.id}`}><PenIcon className="edit-button" /></Link>
            </span>
            {isLoading
                ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border'
                    variant='primary' /></div>
                : renderAppointmentData()}
            <div className="px-4">
                <Link className="btn btn-secondary w-100 mb-2" to={`/appointment/${selectedRow.id}`}>{t('EmployeeFormHealthData:appointmentMake')}</Link>
            </div>
        </>
    )
}
export default EmployeeHealthData;