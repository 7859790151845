import { t } from 'i18next'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { MdLibraryAdd } from 'react-icons/md'
import ArticleSelectModal from '../../ArticleSelect/ArticleSelectModal'

const ArticleSelect = ({ setFormData, name }) => {
  const [showAddArticlesModal, setShowAddArticlesModal] = useState(false)
  const [selectedArticles, setSelectedArticles] = useState([])
  useEffect(() => {
    setFormData(prev => {
      const newValues = { ...prev }
      if (!selectedArticles.length) {
        delete newValues[name]
      } else {
        newValues[name] = selectedArticles.map(a => a.article.id)
      }
      return newValues
    })
  }, [setFormData, selectedArticles, name])

  return (
    <>
      <Button className="w-100 mb-3" onClick={() => setShowAddArticlesModal(true)}><MdLibraryAdd className="me-3" />{t('inventory:addArticle')} {selectedArticles.length ? `(${selectedArticles.length})` : null}</Button>
      {showAddArticlesModal
        ? <ArticleSelectModal
          selectedArticles={selectedArticles}
          setSelectedArticles={setSelectedArticles}
          setShowAddArticlesModal={setShowAddArticlesModal}
          showAddArticlesModal={showAddArticlesModal}
          handleSave={() => setShowAddArticlesModal(false)}
        />
        : null}
    </>

  )
}

export default ArticleSelect