import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import SRMGroupTable from './SRMGroupTable'
import { useSRMGroupTableState } from '../../../stores/Administration/SRMGroupTableProvider'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ModalComponent2 from '../../Modals/ModalComponent2'

const StyledHeaderButtons = styled.div`
@media screen and (min-width:768px) {
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
}
@media only screen and (max-width: 767px), (min-device-width: 320px) and (max-device-width: 767px)  {
    button{
        width:100%;
    }
}
`

const SRMOrderContent = () => {
    const { selectedCheckBoxes, setSelectedCheckBoxes, fetchOrders, error, setError } = useSRMGroupTableState()
    const [openGroupReleasesModal, setOpenGroupReleasesModal] = useState(false)
    const [openGroupRejectionsModal, setOpenGroupRejectionsModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { t } = useTranslation()

    const prepareParams = () => {
        const result = selectedCheckBoxes.map(id => {
            var rObj = {}
            rObj.id = id
            return rObj
        })
        return result
    }

    const release = async () => {
        setError('')
        try {
            setIsLoading(true)
            await apiClient.postJson(APIEndpoints.incomingOrders().groupReleases, prepareParams())
            setSelectedCheckBoxes([])
            fetchOrders();
            setOpenGroupReleasesModal(false)
        } catch (e) {
            setError(t('somethingWentWrong'))
        } finally {
            setIsLoading(false)
        }
    }

    const reject = async () => {
        setError('')
        try {
            setIsLoading(true)
            await apiClient.postJson(APIEndpoints.incomingOrders().groupRejections, prepareParams())
            setSelectedCheckBoxes([])
            fetchOrders();
            setOpenGroupRejectionsModal(false)
        } catch (e) {
            setError(t('somethingWentWrong'))
        } finally {
            setIsLoading(false)
        }
    }

    const cancelActions = () => {
        setOpenGroupRejectionsModal(false)
        setOpenGroupReleasesModal(false)
        setError('')
        setIsLoading(false)
    }
    return (
        <>
            <StyledHeaderButtons>
                <div>
                    <Button
                        className="mb-3 me-3"
                        disabled={selectedCheckBoxes.length === 0}
                        variant="success"
                        onClick={() => setOpenGroupReleasesModal(true)}
                    >{t('release')}</Button>
                    <Button
                        className="mb-3"
                        disabled={selectedCheckBoxes.length === 0}
                        variant="danger"
                        onClick={() => setOpenGroupRejectionsModal(true)}
                    >{t('delete')}</Button>
                </div>
            </StyledHeaderButtons>
            <SRMGroupTable />

            {openGroupReleasesModal && (<ModalComponent2
                ModalTitle={t('srm:srmOrderRelease')}
                button1Function={release}
                button1Text={t('release')}
                button1Variant="success"
                button1Loading={isLoading}
                bodyStyle="alert alert-success"
                BodyPart1={t('orders:releaseOrderBody')}
                functionCancel={cancelActions}
                error={error}
            />)}

            {openGroupRejectionsModal && (<ModalComponent2
                ModalTitle={t('srm:srmOrderDelete')}
                button1Function={reject}
                button1Text={t('delete')}
                button1Variant="danger"
                button1Loading={isLoading}
                bodyStyle="alert alert-danger"
                BodyPart1={t('srm:areYouSureDelete')}
                functionCancel={cancelActions}
                error={error}
            />)}
        </>
    )
}

export default SRMOrderContent
