import React, { useState } from 'react'
import ModalComponent2 from '../../../Modals/ModalComponent2'
import { Alert } from 'react-bootstrap'
import apiClient from '../../../../utils/api/apiClient'
import { APIEndpoints } from '../../../../utils/api/apiConfig'
import { getErrorMessageFromApiResponse } from '../../../../utils/api/apiUtils'
import { t } from 'i18next'

const DeactivateModal = ({ handleClose, employeeId }) => {
  const [inventoryWarning, setInventoryWarning] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const deactivateBP = async () => {
    let params = { activate: false }
    if (inventoryWarning) {
      params.enforceStatusChanges = true
    }
    try {
      setLoading(true)
      let result = await apiClient.patchJson(APIEndpoints.businessPartner(employeeId).activationStatus, params)
      if (result?.inventoryExists && !result?.enforceStatusChanges) {
        setInventoryWarning(true)
      } else {
        handleClose()
      }
    }
    catch (e) {
      setError(getErrorMessageFromApiResponse(e))
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <ModalComponent2
      ModalTitle={t('inventory:deactivateUser')}
      button1Function={deactivateBP}
      button1Text={inventoryWarning ? t('continue') : t('deactivate')}
      button1Loading={loading}
      BodyPart1={t('inventory:confirmDeactivateUser')}
      functionCancel={handleClose}
      error={error}
      centered
    >
      {inventoryWarning ?
        <Alert variant="warning">
          {t('inventory:deactivateUserAlert')}
        </Alert> : null}
    </ModalComponent2 >
  )
}

export default DeactivateModal