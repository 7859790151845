import React from 'react'
import { useTheme } from 'styled-components'

export const NewCheckIcon = ({ color }) => {
  const theme = useTheme()
  const colorToUse = color || theme.primaryColor
  return (
    <svg width="20px" height="20px" viewBox="2 2 16 16" >
      <path
        d="M13.9162458,14.2495791 L6.58291245,14.2495791 L6.58291245,11.8051347 L11.4709124,11.8045791 L11.4718013,3.24957911 L13.9162458,3.24957911 L13.9162458,14.2495791 Z"
        fill={colorToUse}
        opacity="1"
        transform="translate(10.249579, 8.749579) rotate(-315.000000) translate(-10.249579, -8.749579) "
      />
    </svg>
  )
}
