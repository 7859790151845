import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useObserver } from 'mobx-react'
import useStores from '../../stores/useStore'
import styled from 'styled-components'
import { FormControl } from 'react-bootstrap'
import { emptyGuid } from '../../utils/emptyGuid'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'react-bootstrap'
import { debounce } from 'throttle-debounce'
import CostCenterItem from './CostCenterItem'
import SearchResultAmount from '../Forms/SearchResultAmount'
import TopLevelTreeItem from '../Offices/TopLevelTreeItem'



const StyledSearchWrap = styled.div`
  margin-bottom: 2rem;
`
const StyledShowMore = styled.span`
  color: ${props => props.theme.primaryColor};
  float: right;
  margin-top: 15px;
  cursor: pointer;
`

const CostCenterTree = ({
  onClick = () => ({}),
  isClickable,
  withSearch,
  renderExtraItemContent = () => null,
  includeDeleted,
  showTopLevel,
  parentsWithSelectedChildren,
  filterMainOfficeId,
  getContextMenuOptions
}) => {
  const [searchText, setSearchText] = useState('')
  const [selectedCostCenterId, setSelectedCostCenterId] = useState('')
  const [filteredCostCenters, setFilteredCostCenters] = useState([])
  const [filterIsLoading, setFilterIsLoading] = useState(false)
  const { costCenterStore } = useStores()
  const { t } = useTranslation()
  const [limit, setLimit] = useState(true)
  const limitLength = 40
  const [costCentersActive, setCostCentersActive] = useState({})
  const partialCostCenters = useObserver(() => costCenterStore.partialCostCenters)

  useEffect(() => {
    costCenterStore.loadPartialCostCenters(emptyGuid, includeDeleted)
  }, [costCenterStore, includeDeleted])

  useEffect(() => {
    if (Object.keys(costCentersActive).length) {
      for (const costcenterId of Object.keys(costCentersActive)) {
        if (costCentersActive[costcenterId]) {
          costCenterStore.loadPartialCostCenters(costcenterId, includeDeleted)
        }
      }
    }
  }, [costCenterStore, costCentersActive, includeDeleted, partialCostCenters])    // to render in dropdown what is open

  const handleClickCostcenter = costCenter => {
    setSelectedCostCenterId(costCenter.id)
    onClick(costCenter)
  }

  const topLevelCostCenters = useMemo(() => {
    let costCenters = []
    if (searchText || filterMainOfficeId) {
      costCenters = filteredCostCenters
    }
    else {
      costCenters = partialCostCenters.filter(costCenter => !costCenter.parentCostCenter)
    }
    return costCenters
  }, [searchText, filterMainOfficeId, filteredCostCenters, partialCostCenters])


  const limitedTopLevelCostCenters = useMemo(() => {
    if (limit) {
      return topLevelCostCenters.slice(0, limitLength)
    }
    return topLevelCostCenters
  }, [limit, topLevelCostCenters])
  const handleChangeFilter = (e) => {
    const { value } = e.target
    // the search bar function
    setSearchText(value)
    loadFilteredCostCentersDebounced(value)
  }
  const getFilteredCostCenters = useCallback(async (value = '') => {
    if (!value && !filterMainOfficeId) {
      setLimit(false)
      costCenterStore.cancelGetFilteredCostCenters()
      return
    }
    try {
      setLimit(true)
      setFilteredCostCenters([])
      setFilterIsLoading(true)
      let loadedCostCenters = await costCenterStore.getFilteredCostCenters(value, filterMainOfficeId, includeDeleted)
      setFilteredCostCenters(loadedCostCenters)
    }
    finally {
      setFilterIsLoading(false)
    }
  }, [costCenterStore, filterMainOfficeId, includeDeleted])

  useEffect(() => {
    if (filterMainOfficeId) {
      getFilteredCostCenters()
    }
  }, [filterMainOfficeId, getFilteredCostCenters])

  const loadFilteredCostCentersDebounced = useMemo(
    () => debounce(500, (value) => {
      getFilteredCostCenters(value)
    }), [getFilteredCostCenters])



  return useObserver(() => (
    <>
      {withSearch && (
        <StyledSearchWrap >
          <FormControl type="input" placeholder={t('costCenterAdmin:searchCostcenter')} onChange={handleChangeFilter} value={searchText} />
          {searchText && <SearchResultAmount count={topLevelCostCenters.length} />}
        </StyledSearchWrap>
      )}
      {showTopLevel
        ? <TopLevelTreeItem handleClick={handleClickCostcenter} renderExtraItemContent={renderExtraItemContent}>
          {partialCostCenters.length
            ? limitedTopLevelCostCenters.map(costCenter => (
              <CostCenterItem
                key={costCenter.id}
                costCenter={costCenter}
                renderExtraItemContent={renderExtraItemContent}
                handleClickCostcenter={handleClickCostcenter}
                setCostCentersActive={setCostCentersActive}
                costCentersActive={costCentersActive}
                partialCostCenters={partialCostCenters}
                isClickable={isClickable}
                selectedCostCenterId={selectedCostCenterId}
                filterMainOfficeId={filterMainOfficeId}
                parentsWithSelectedChildren={parentsWithSelectedChildren}
                getContextMenuOptions={getContextMenuOptions}
              />
            ))
            : ''}
        </TopLevelTreeItem>
        : partialCostCenters.length
          ? limitedTopLevelCostCenters.map(costCenter => (
            <CostCenterItem
              key={costCenter.id}
              costCenter={costCenter}
              renderExtraItemContent={renderExtraItemContent}
              handleClickCostcenter={handleClickCostcenter}
              setCostCentersActive={setCostCentersActive}
              costCentersActive={costCentersActive}
              partialCostCenters={partialCostCenters}
              isClickable={isClickable}
              selectedCostCenterId={selectedCostCenterId}
              filterMainOfficeId={filterMainOfficeId}
              parentsWithSelectedChildren={parentsWithSelectedChildren}
              getContextMenuOptions={getContextMenuOptions}
            />
          ))
          : ''
      }
      {topLevelCostCenters.length > limitLength && limit && <div className='d-flex justify-content-center' >
        <StyledShowMore className="show-more" onClick={() => { setLimit(false) }}>{t('officeAdmin:showAll')}</StyledShowMore>
      </div>}
      {topLevelCostCenters.length === 0 && !costCenterStore.costCentersLoading && !filterIsLoading && !filterMainOfficeId && (
        <div className="ps-4 pe-2"><strong>{t('officeAdmin:searchStructureTreeNoresult')}</strong></div>
      )}
      {topLevelCostCenters.length === 0 && !costCenterStore.costCentersLoading && !filterIsLoading && filterMainOfficeId && (
        <div className="ps-4 pe-2"><strong>{t('costCenterAdmin:assignedOfficeNoresult')}</strong></div>
      )}
      {(costCenterStore.costCentersLoading || filterIsLoading) && <div className='d-flex justify-content-center' >
        <Spinner animation='border' variant='primary' />
      </div>}
    </>
  ))
}

export default CostCenterTree
