import React from 'react'
import { useAppointmentState } from '../../../stores/AppointmentProvider'
import { Col, Row, Form } from "react-bootstrap";
import i18n from '../../../translation/i18n'
import { emptyGuid } from '../../../utils/emptyGuid';
import { useTranslation } from 'react-i18next'


const Info = () => {
    const { t } = useTranslation()
    const {
        appointment
    } = useAppointmentState()

    return (
        <>
            {appointment &&
                <>
                    <h2 >{appointment.appointmentType && appointment.appointmentType.displayName}</h2>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <h2>{t('date')}</h2>
                                <p>
                                    {appointment.day && new Date(appointment.day).toLocaleDateString(
                                        i18n.language,
                                        {
                                            weekday: 'long',
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric',
                                        })}{(appointment.timeSlot.id !== emptyGuid) && ` - ${appointment.timeSlot.hour < 10 ? "0" + appointment.timeSlot.hour : appointment.timeSlot.hour}:${appointment.timeSlot.minute < 10 ? '0' : ''}${appointment.timeSlot.minute} Uhr`}
                                </p>
                            </Form.Group>

                        </Col>
                        <Col>
                            <h2>{t('appointment:place')}</h2>
                            <p>{appointment.cabin.displayName}</p>
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}

export default Info
