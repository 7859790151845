import React, { useEffect } from 'react'
import { Modal, Alert } from 'react-bootstrap'
import { inject, observer } from 'mobx-react'
import ProductsCompare from './ProductsCompare'
import { useTranslation } from 'react-i18next';

const ProductsCompareModal = inject('productsCompareStore')(observer((props) => {
    const { productsCompareStore } = props;
    useEffect(() => {
        productsCompareStore.updateProductsCompareStore()
    }, [productsCompareStore])
    const { t } = useTranslation();

    const handleClose = () => productsCompareStore.setShowProductsCompareModal(false);
    return (
        <Modal size="xl" show={productsCompareStore.showProductsCompareModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('articleComparison:articleComparison')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {productsCompareStore.articles.length ?
                    <div>
                        <ProductsCompare articles={productsCompareStore.articles} />
                    </div>
                    :
                    <Alert variant='info' >{t('productsOverview:noProductsAvailable')}</Alert>}
            </Modal.Body>
        </Modal>
    )
}))

export default ProductsCompareModal
