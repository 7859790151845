import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import useStores from '../../../stores/useStore'
import { colors } from '../../../styles/colors'

const StyledList = styled.ul`
  margin: 0 -20px 0 -36px;
  padding: 0;
  list-style: none;
  font-weight: bold;
  font-size: 18px;

  li {
    &:hover {
      background-color: ${colors.gray2};
    }
    &.active {
      background-color: ${colors.sidebarActiveGray} !important;
      padding: 8px 0px 8px 36px;
    }
    &.disabled {
      color: lightgrey;
      background-color: #EEEEEE;
      curosor: none;
      padding: 8px 20px 8px 36px;
    }

    &.hidden {
      display:none
    }
  
    a {
      // padding: 8px 20px 8px 36px;
      color: ${colors.textColor};
      display: block;
    }

  }
  @media screen and (max-width: 1080px) {
    margin:0;
  }
`


const SidebarNavigation = ({ options, active }) => {
  const { uiStore } = useStores()

  const checkRights = accessRights => {
    var result = true
    if (accessRights) {
      for (const item of accessRights) {
        result = result && uiStore[item]
      }
    }
    return result
  }

  return (
    <StyledList className="sidebar-v1">
      {options.map(item => (
        <li key={item.key} className={`${active === item.key ? 'active' : 'disabled'} list-v1 ${!checkRights(item.accessRights) && 'hidden'}`} >
          {
            !item.disabled ?
              <Link to={item.to}>{item.name}</Link>
              : <span>{item.name}</span>
          }
        </li>
      ))}
    </StyledList>
  )
}
export default SidebarNavigation
