import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { StyledRequiredFormLabel } from '../shared/FormStyles';
import { useTranslation } from 'react-i18next'
import ArraySelect from '../../Select/ArraySelect'
/**
 * Form to create an return issue
 * @param onSubmit
 * @param onChange
 * @param costCenters
 * @returns {*}
 * @constructor
 */
const ReturnReasonForm = (
  {
    onSubmit,
    onChange,
    reasons = [/*
      ['unhappy', 'Gefällt mir nicht'],
      ['broken', 'Kaputt geliefert'],
      ['wrong', 'Habe ich nie bestellt'],
      ['highAmount', 'Zu viel erhalten']*/
    ]
  }
) => {
  const { t } = useTranslation()

  const [formData, setFormData] = useState({
    reason: '',
    comment: ''
  })
  const [validated, setValidated] = useState(false)

  /**
   * Handle change events for every input and add the new value to formData state
   * @param event
   */
  function handleInputChange(event) {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  function handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()

    if (validated) {
      onSubmit(event, formData)
    }
  }

  /**
   * Check html5 form validity
   * @param event
   */
  function validateForm(event) {
    const form = event.currentTarget

    setValidated(form.checkValidity())
  }

  useEffect(() => {
    onChange(formData, validated)
  }, [formData, onChange, validated])

  return (
    <Form
      validated={validated}
      onChange={validateForm}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        <StyledRequiredFormLabel>{t('orderDialog:reason')}</StyledRequiredFormLabel>
        <ArraySelect
          value={formData.reason}
          onChange={(e) => handleInputChange(e)}
          name='reason'
          required
          options={reasons}
          returnEvent
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <StyledRequiredFormLabel>{t('comment')}</StyledRequiredFormLabel>
        <Form.Control
          as='textarea'
          name='comment'
          value={formData.comment}
          rows='3'
          onChange={(e) => handleInputChange(e)}
          required
        />
      </Form.Group>
    </Form>
  )
}

export default ReturnReasonForm
