import React from 'react'
import { Modal } from 'react-bootstrap'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { hoursArray } from '../../utils/hoursAndMinutes'
import { useAppointmentsAdministrationState } from '../../stores/Administration/AppointmentsAdministrationProvider'
import DailyAppointmentsOverview from './DailyAppointmentsOverview'


const StyledModal = styled(Modal)`
  .modal-content {
    height: 90vh;
  }
`

const DailyAppointmentsCompareModal = () => {
  const { selectedDays, showDailyAppointmentsCompareModal, setShowDailyAppointmentsCompareModal, bookingSlots } = useAppointmentsAdministrationState();
  const { t } = useTranslation()

  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1366 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1365, min: 992 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 991, min: 0 },
      items: 1,
    }
  };

  const handleClose = () => setShowDailyAppointmentsCompareModal(false);

  // filters the booking slots for an individual day and renders the event calendar for it
  const renderSelectedDay = (startTime, index) => {
    let selectedDate = startTime.slice(0, 10); // gets the selected date for the calendar in format '2021-10-21'
    let filteredBookingSlots = bookingSlots.filter(slot => slot.startTime.includes(selectedDate) && slot.status === 'isAppointment'); // && slot.status === 'isAppointment', add condition later
    let hoursArrayCopy = cloneDeep(hoursArray);
    filteredBookingSlots.forEach(slot => {
      let hour = parseInt(slot.startTime.substring(11, 13));
      hoursArrayCopy[hour].push(slot);
    });
    return <div key={index} className="m-1" >
      <DailyAppointmentsOverview
        date={selectedDate}
        selectedDateHoursArray={hoursArrayCopy}
        showSimpleHeader={true}
      />
    </div>
  }

  return (
    <StyledModal
      size="xl"
      show={showDailyAppointmentsCompareModal}
      onHide={() => handleClose()}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('appointment:appointmentComparison')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          selectedDays.length ? <div>
            <Carousel
              responsive={responsive}
              swipeable={true}
              draggable={false}
            >
              {
                selectedDays.map((day, index) => renderSelectedDay(day[0], index))
              }
            </Carousel>
          </div>
            : null
        }
      </Modal.Body>
    </StyledModal>
  )
}

export default DailyAppointmentsCompareModal