import React from 'react'
import { inject, observer } from 'mobx-react'
import { Alert } from 'react-bootstrap'
import styled from 'styled-components'
import { t } from 'i18next'

import { PositionItemImageAmount } from '../../Orders/Detail/PositionItemImageAmount'
import PlusMinusAmount from '../../Forms/PlusMinusAmount'
import ReorderVariant from '../ReturnDialog/ReorderVariant'

const StyledArticleList = styled.div`
  margin-bottom: 10px;
`

const StyledProductItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

const StyledHr = styled.hr`
  margin-left: -15px;
  margin-right: -15px;
`

const ProductList = inject('orderDialogStore')(observer((props) => {
  const { orderDialogStore, hideReorderOption, disableSelections, reorderIsMandatory } = props
  const isRetouration = orderDialogStore.activeDialog === 'retouration'
  const isCancellation = orderDialogStore.activeDialog === 'cancellation'

  const getMaxQuantity = (position) => {
    return isRetouration ? position.returnableQuantity : position.quantity
  }
  const getMinQuantity = (position) => {
    return isRetouration ? 0 : 1
  }

  const checkIsMax = position => {
    let maxQuantity = getMaxQuantity(position)
    return position._selectedQuantity >= maxQuantity
  }

  const checkIsMin = position => {
    let minQuantity = getMinQuantity(position)
    return position._selectedQuantity <= minQuantity
  }

  const handleOnClickMinus = position => {
    if (checkIsMin(position)) {
      return
    }
    position._selectedQuantity = parseInt(position._selectedQuantity) - 1
  }

  const handleOnClickPlus = position => {
    if (checkIsMax(position)) {
      return
    }
    position._selectedQuantity = parseInt(position._selectedQuantity) + 1
  }

  const handleOnChangeAmount = (e, position) => {
    const { value } = e.target
    let maxQuantity = getMaxQuantity(position)
    let minQuantity = getMinQuantity(position)
    if (value < minQuantity || value > maxQuantity) {
      return
    }
    position._selectedQuantity = value
  }

  const noSelectedPositions = !!!orderDialogStore.getPositions().length

  return (
    <React.Fragment>
      <StyledArticleList>
        {orderDialogStore.groupedOrderPositions.map(positionGroup => positionGroup.positions.map(position => {
          const disablePlus = checkIsMax(position) || disableSelections
          const disableMinus = checkIsMin(position) || disableSelections
          return (
            <div key={position.id}>
              <StyledProductItem >
                <PositionItemImageAmount key={position.id} orderPosition={position} quantity={position.quantity}
                  showQuantity={false} />
                {!isCancellation &&
                  <PlusMinusAmount
                    value={position._selectedQuantity}
                    onChange={e => handleOnChangeAmount(e, position)}
                    onChangePlus={() => handleOnClickPlus(position)}
                    onChangeMinus={() => handleOnClickMinus(position)}
                    disableMinus={disableMinus}
                    disablePlus={disablePlus}
                    disableField={disableSelections}
                  />}
              </StyledProductItem>
              {(isRetouration && !hideReorderOption)
                ? <ReorderVariant position={position} reorderVariant={position._reorderVariant} disableField={disableSelections} reorderIsMandatory={reorderIsMandatory}/>
                : null}
            </div>
          )
        }
        ))}
        {noSelectedPositions && <Alert variant="danger">{t('orderDialog:noPositionsAlert')}</Alert>}
      </StyledArticleList>
      <StyledHr />
    </React.Fragment>
  )
}))

export default ProductList
