import React from "react"
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useAppointmentsAdministrationState } from '../../stores/Administration/AppointmentsAdministrationProvider'
import ArraySelect from '../Select/ArraySelect'

const CabinSelect = () => {
    const { t } = useTranslation()
    const { cabinTypes, selectedCabin, setSelectedCabin } = useAppointmentsAdministrationState()

    return (
        <div>
            <Form.Group className="mb-3">
                <Form.Label>{t('cabinTypes:selectCabin')}</Form.Label>
                <ArraySelect
                    onChange={cabin => setSelectedCabin(cabin.id)}
                    value={selectedCabin}
                    options={cabinTypes}
                />
            </Form.Group>
        </div>
    )
}

export default CabinSelect