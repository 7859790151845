import React from 'react';
import { useTheme } from 'styled-components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Figure from 'react-bootstrap/Figure'
import getThemeValue from '../../utils/getThemeValue'

const InfographWrapper = styled.div`
margin: 3rem 0;
  justify-content: center;
`

const ContactWrappedStyled = styled.div`
    width: 100%;
    margin: 3rem 0;
    text-align:center;
    p{margin:0px;}
`


const InfographItemWrapper = styled.div`
    display:flex;
    flex-direction:column;

    @media screen and (min-width: 1200px) {
        margin-top: 0;
    }

    @media screen and (min-width: 992px) {
        flex-direction: row;
    }
`
const InfographItem = styled.div`
    border-left: 0;
    padding: 0 2rem ;
    margin: 0 0 1rem;
    @media screen and (min-width: 992px) {
        border-left: 1px solid #ccc;
    }  
    .figure-img {
        max-width: 4.5rem;  
        margin-right: 1rem;
    }
    &:first-child {
        border-left: 0;
    }
`


const StyledFigure = styled(Figure)`
  display: flex;
  align-items: flex-start;
  margin:0px;

`
const ContactInfos = () => {
    const { t } = useTranslation()
    const theme = useTheme()
    return (
        <>
            <ContactWrappedStyled>
                <div>{getThemeValue('contactInfos', 'contactInfosHeadline1', t('loginBox:message1'))} </div>
                <div>{getThemeValue('contactInfos', 'contactInfosHeadline2', t('loginBox:message2'))} </div>
            </ContactWrappedStyled>
            <InfographWrapper className='d-flex'>
                <InfographItemWrapper >
                    {theme.contactInfos.phoneContact ? <InfographItem>
                        <StyledFigure>
                            <Figure.Image
                                width={40}
                                height={40}
                                alt="40x40"
                                src={theme.userLogin.phoneIcon ? theme.userLogin.phoneIcon : ""}
                            />
                            <Figure.Caption>
                                <h3>{t('loginBox:phone')} </h3>
                                <p><a className="text-secondary" href={`tel:${theme.contactInfos.phoneContact}`}>{theme.contactInfos.phoneContact}</a></p>
                            </Figure.Caption>
                        </StyledFigure>
                    </InfographItem> : null}
                    {theme.contactInfos.emailContact ?
                        <InfographItem>
                            <StyledFigure>
                                <Figure.Image
                                    width={40}
                                    height={40}
                                    alt="40x40"
                                    src={theme.userLogin.mailIcon ? theme.userLogin.mailIcon : ""}
                                />
                                <Figure.Caption>
                                    <h3>{t('loginBox:email')} </h3>
                                    <p > <a className="text-secondary" href={`mailto:${theme.contactInfos.emailContact}`}>{theme.contactInfos.emailContact}</a></p>
                                </Figure.Caption>

                            </StyledFigure>
                        </InfographItem> : null
                    }

                    {(theme.contactInfos.workingWeekHours || theme.contactInfos.workingWeekendHours) ?
                        <InfographItem>
                            <StyledFigure>
                                <Figure.Image
                                    width={40}
                                    height={40}
                                    alt="40x40"
                                    src={theme.userLogin.contactIcon ? theme.userLogin.contactIcon : ""}
                                />
                                <Figure.Caption>
                                    <h3>{t('loginBox:contactTimes')} </h3>
                                    <div className="text-secondary ">{theme.contactInfos.workingWeekHours}</div>
                                    {theme.contactInfos && theme.contactInfos.workingWeekendHours && theme.contactInfos.workingWeekendHours !== "" ?
                                        <div className="text-secondary "> {theme.contactInfos.workingWeekendHours} </div>
                                        : ""
                                    }
                                </Figure.Caption>

                            </StyledFigure>
                        </InfographItem>
                        : null}
                </InfographItemWrapper>
            </InfographWrapper>

        </>
    )
}

export default ContactInfos