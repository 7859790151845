import { t } from 'i18next';
import React from 'react'
import { Alert } from 'react-bootstrap';
/* 
  <DownloadURLAlert
  downloadUrl={returnsPortalUrl}
  setDownloadUrl={setReturnsPortalUrl}
  fileName={returnsPortalUrl}
  />
*/

const DownloadURLAlert = ({ downloadUrl, setDownloadUrl, fileName }) => {
  return (
    <Alert
      style={{ wordBreak: "break-all" }}
      variant="info"
      //className="subheader__alert"
      dismissible
      onClose={() => setDownloadUrl('')}
    >
      {t("Overlay:downloadUrlText")}
      <br />
      <a href={downloadUrl}
        onClick={() =>
          setTimeout(() => {
            setDownloadUrl("");
          }, 500)
        }  >
        {fileName}
      </a>
    </Alert>
  )
}

export default DownloadURLAlert