import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { PenIcon } from '../components/Icons/PenIcon'
import { useTranslation } from 'react-i18next'
import { emptyGuid } from '../utils/emptyGuid'
import useStores from '../stores/useStore'

const OrdersTableContext = React.createContext({})
export function useOrdersTableState() {
    return React.useContext(OrdersTableContext)
}


const OrdersTableProvider = ({ children }) => {
    const { t } = useTranslation()
    const { uiStore } = useStores()
    const [tableIsLoading, setTableIsLoading] = useState(false)
    const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([])
    const [incomingOrders, setIncomingOrders] = useState([])
    const [pageOffset, setPageOffset] = useState(0)
    const [pageLimit, setPageLimit] = useState(10)
    const [lastMonths, setLastMonths] = useState(3)
    const [totalCount, setTotalCount] = useState(0)
    const [status, setStatus] = useState('') //waitingForApproval, waitingForApprovalSRM
    const [targetCostcenterId, setTargetCostcenterId] = useState(emptyGuid)
    const [allSelected, setAllSelected] = useState(false)
    const [allSelectedLoading, setAllSelectedLoading] = useState(false)
    const [lastParams, setLastParams] = useState(JSON.stringify({ lastMonths, targetCostcenterId }))

    const getPreparedResult = useCallback((result) => {
        return result.map(item => {
            let targetShoppingBasket = item.targetShoppingBasket
            let prefix = ''

            if (targetShoppingBasket) {
                if (targetShoppingBasket.targetOrderProcessType === 'personal') {
                    prefix = '/order/personal'
                }
                if (targetShoppingBasket.targetOrderProcessType === 'costCenter') {
                    prefix = `/costcenter/${item.targetCostCenter.id}`
                }
                if (targetShoppingBasket.targetOrderProcessType === 'private') {
                    prefix = '/order/private'
                }
            }

            return {
                ...item, actions: (
                    <span>
                        <span>
                            {((status === "waitingForApproval" || (status === "waitingForApprovalSRM" && uiStore.allowCancelPreOrders)) && targetShoppingBasket) ? <Link to={`${prefix}/shoppingcart/${targetShoppingBasket.id}`} title={t('employeesPage:editAction')}><PenIcon color="#000000" /></Link> : null}
                        </span>
                    </span>
                ),
                checkBox: (
                    <span>
                        <input type="checkbox" id={item.id} name="scales"
                            checked={selectedCheckBoxes.includes(item.id)} onChange={() => { }}>
                        </input>
                    </span>
                )
            }
        })
    }, [selectedCheckBoxes, status, t, uiStore.allowCancelPreOrders])

    const fetchOrders = useCallback(() => {
        setTableIsLoading(true)
        const params = {
            'page.limit': pageLimit,
            'page.offset': pageOffset,
            'filter.lastMonths': lastMonths,
            'filter.status': status,
            'filter.targetCostcenterId': targetCostcenterId
        }
        apiClient.getJson(APIEndpoints.incomingOrders().incomingOrders, params, true, true).then(response => {
            const result = response.data
            setTotalCount(response.headers['x-total-count'] ? parseInt(response.headers['x-total-count']) : 0)
            const preparedResult = getPreparedResult(result)
            setIncomingOrders(preparedResult)
        }).finally(() => {
            const newParams = JSON.stringify({ lastMonths, targetCostcenterId })
            if (newParams !== lastParams) {
                setLastParams(newParams)
                setSelectedCheckBoxes([])
                setAllSelected(false)
            }
            setTableIsLoading(false)
        })
    }, [pageLimit, pageOffset, lastMonths, status, targetCostcenterId, getPreparedResult, lastParams])

    useEffect(() => {
        if (status === "waitingForApprovalSRM") {
            if (targetCostcenterId !== emptyGuid) {
                fetchOrders()
            }
        }
        else if (status === "waitingForApproval") {
            fetchOrders()
        }
        //eslint-disable-next-line
    }, [lastMonths, pageLimit, pageOffset, status, targetCostcenterId])

    const onRowClick = (cell, row) => {
        if (allSelected) {
            setAllSelected(false)
        }
        if (selectedCheckBoxes.includes(row.id)) {
            var index = selectedCheckBoxes.indexOf(row.id);
            var newValues = [...selectedCheckBoxes]
            newValues.splice(index, 1);
            setSelectedCheckBoxes(newValues)
        }
        else {
            setSelectedCheckBoxes(prev => [...prev, row.id])
        }
    }

    const selectAll = () => {
        if (allSelected) {
            setAllSelected(false)
            setSelectedCheckBoxes([])
        }
        else {
            setAllSelectedLoading(true)
            const params = {
                'filter.status': status,
                'filter.targetCostcenterId': targetCostcenterId,
                'filter.lastMonths': lastMonths,
            }
            apiClient.getJson(APIEndpoints.incomingOrders().incomingOrder, params).then(response => {
                if (response) {
                    const arrayOfAllIds = response.map(obj => obj.id)
                    setSelectedCheckBoxes(arrayOfAllIds)
                }
            }).finally(() => { setAllSelected(true); setAllSelectedLoading(false) })
        }
    }

    useEffect(() => {
        const preparedResult = incomingOrders.map(item => ({
            ...item,
            checkBox: (
                <span>
                    <input type="checkbox" id={item.id} name="scales"
                        checked={selectedCheckBoxes.includes(item.id)} onChange={() => { }}>
                    </input>
                </span>
            )
        }))
        setIncomingOrders(preparedResult)
        //eslint-disable-next-line
    }, [selectedCheckBoxes])

    const contextValues = {
        tableIsLoading,
        incomingOrders,
        onRowClick,
        pageOffset,
        setPageOffset,
        pageLimit,
        setPageLimit,
        totalCount,
        setTotalCount,
        selectedCheckBoxes,
        setSelectedCheckBoxes,
        setLastMonths,
        setStatus,
        fetchOrders,
        setTableIsLoading,
        setTargetCostcenterId,
        targetCostcenterId,
        allSelectedLoading, selectAll, allSelected
    }
    return (
        <OrdersTableContext.Provider value={contextValues}>
            {children}
        </OrdersTableContext.Provider>
    )
}

export default OrdersTableProvider
