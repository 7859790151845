import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { clearFilter } from '../components/Employees/filterConfig'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { PenIcon } from '../components/Icons/PenIcon'
import { ShoppingCartIcon } from '../components/Icons/ShoppingCartIcon'
import { DeliveryIcon } from '../components/Icons/DeliveryIcon'
import { useTranslation } from 'react-i18next'
import useStores from '../stores/useStore'
import axios from 'axios'

const CancelToken = axios.CancelToken;
let cancel;

const EmployeesContext = React.createContext({})
export function useEmployeesState() {
  return React.useContext(EmployeesContext)
}

const initialFilter = {
  'filter.displayName': '',
  'filter.personalNumber': '',
  'filter.officeIds': '',
  'filter.officeDisplayName': '',
  'filter.costCenterIds': '',
  'filter.costCenterDisplayName': '',
  'filter.gender': '',
  'filter.includeDeletedBps': '',
  'filter.checkUpTypeId': '',
  'filter.checkUpResultId': '',
  'filter.missingArticleConfigurations': ''
}

const EmployeesProvider = ({ children }) => {
  const { uiStore } = useStores()

  const [loaded, setLoaded] = useState(false)
  const [pageOffset, setPageOffset] = useState(0)
  const [pageLimit, setPageLimit] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [showAllEmployees, setShowAllEmployees] = useState(false)
  const [showDisabledEmployees, setShowDisabledEmployees] = useState(false)
  const [openOfficeFilter, setOpenOfficeFilter] = useState(false)
  const [openCostCenterFilter, setOpenCostCenterFilter] = useState(false)
  const [openHealthDataFilter, setOpenHealthDataFilter] = useState(false)
  const [filter, setFilter] = useState(initialFilter)
  const [officeFilter, setOfficeFilter] = useState('')
  const [officeName, setOfficeName] = useState('')
  const [costCenterFilter, setCostCenterFilter] = useState('')
  const [missingArticleConfigurationsFilter, setMissingArticleConfigurationsFilter] = useState('')
  const [costCenterName, setCostCenterName] = useState('')
  const [checkUpFilter, setCheckUpFilter] = useState(null)
  const [sorting, setSorting] = useState('displayName')
  const [selectedRow, setSelectedRow] = useState(false)
  const [partialBuisinesspartners, setPartialBuisinesspartners] = useState([])
  const [tableIsLoading, setTableIsLoading] = useState(false)
  const [showOverlayLeft, setShowOverlayLeft] = useState(false)
  const [filterEmployee, setFilterEmployee] = useState(null)
  const [forceReloadEmployees, setForceReloadEmployees] = useState(false)
  const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([])
  const [allCheckBoxesSelected, setAllCheckBoxesSelected] = useState(false)
  const [allSelectedLoading, setAllSelectedLoading] = useState(false)
  const [initialTableId, setInitialTableId] = useState(null)

  const [lastParams, setLastParams] = useState(JSON.stringify({
    pageOffset,
    pageLimit,
    filter,
    showAllEmployees,
    sorting
  }))
  const { t } = useTranslation()
  const resetFilter = (test = null) => {
    clearFilter()
    setFilter(initialFilter)
    setOfficeFilter(null)
    setCostCenterFilter(null)
    setCheckUpFilter(null)
    setShowDisabledEmployees(false)
    setMissingArticleConfigurationsFilter(test)

    if (!showAllEmployees) {
      setPartialBuisinesspartners([])
      setTotalCount(0)
    }

  }
  //when closing OverlayLeft 
  const updateOverlayLeftStates = () => {
    officeFilter ? setOpenOfficeFilter(true) : setOpenOfficeFilter(false)
    costCenterFilter ? setOpenCostCenterFilter(true) : setOpenCostCenterFilter(false)
    checkUpFilter ? setOpenHealthDataFilter(true) : setOpenHealthDataFilter(false)
  }
  useEffect(() => {
    setFilter(prev => ({ ...prev, 'filter.costCenterIds': costCenterFilter || '' }))
  }, [costCenterFilter])

  useEffect(() => {
    setFilter(prev => ({ ...prev, 'filter.officeIds': officeFilter || '' }))
  }, [officeFilter])

  useEffect(() => {
    setFilter(prev => ({ ...prev, 'filter.missingArticleConfigurations': missingArticleConfigurationsFilter || '' }))
  }, [missingArticleConfigurationsFilter])

  useEffect(() => {
    setFilter(prev => ({ ...prev, 'filter.includeDeletedBps': showDisabledEmployees || '' }))
  }, [showDisabledEmployees])

  useEffect(() => {
    setFilter(prev => ({ ...prev, 'filter.checkUpTypeId': checkUpFilter?.checkUpTypeId || '', 'filter.checkUpResultId': checkUpFilter?.checkUpResultId || '' }))
  }, [checkUpFilter])

  const handleLocalChangeCheckbox = id => {
    if (allCheckBoxesSelected) {
      setAllCheckBoxesSelected(false)
    }
    if (selectedCheckBoxes.includes(id)) {
      var index = selectedCheckBoxes.indexOf(id);
      var newValues = [...selectedCheckBoxes]
      newValues.splice(index, 1);
      setSelectedCheckBoxes(newValues)
    }
    else {
      setSelectedCheckBoxes(prev => [...prev, id])
    }
  }

  //cancel previous calls that are still pending if existing
  const cancelPrevious = () => {
    cancel && cancel()
  }

  const loadEmployees = async () => {
    cancelPrevious()
    const cancelToken = new CancelToken(function executor(c) {
      cancel = c;
    })
    try {
      let params = getParams()
      const response = await apiClient.getJson(APIEndpoints.partialBusinessPartners, params, true, true, true, { cancelToken })
      if (response.headers['x-total-count']) {
        setTotalCount(Number(response.headers['x-total-count']))
      } else { setTotalCount(0) }
      return response.data
    }
    catch (e) {
      if (axios.isCancel(e)) {
        console.log("cancelled by cancelToken")
      }
      return null
    }
  }

  const getParams = () => {
    const filterWithValues = { ...filter }
    Object.keys(filterWithValues).forEach(filterName => {
      if (filterWithValues[filterName] === '') {
        delete filterWithValues[filterName]
      }
    })
    let params = { ...filterWithValues }
    params['page.offset'] = pageOffset
    params['page.limit'] = pageLimit
    if (sorting) {
      params['sort'] = sorting
    }
    return params
  }

  const appendData = (data) => {
    return data.map(item => ({
      ...item, actions: (
        <span>
          {(uiStore.employeeAdministration || uiStore.allowAccessUserModule) && <span>
            <Link to={`/editemployee/${item.id}`} onClick={e => { e.stopPropagation() }}
              title={t('employeesPage:editAction')}><PenIcon color="#000000" /></Link>
          </span>}
          {uiStore.allowShowAllOrders && <span>
            <Link
              to={`/employee-orders/${item.id}`}
              state={{
                selectedStatus: 'open'
              }}
              onClick={e => { e.stopPropagation() }}
              title={t('employeesPage:ordersAction')}><DeliveryIcon className="delivery-icon"
                color="#000000" />
            </Link>
          </span>}
          {!item.isDeleted && uiStore.employeeOrderAvailable && (
            <span>
              <Link to={`/productjobfunction/${item.id}`} state={{ from: '/employees' }}
                onClick={e => { e.stopPropagation() }}
                title={t('employeesPage:shoppingAction')}><ShoppingCartIcon className="cart-icon"
                  color="#000000" /></Link>
            </span>
          )}
        </span>
      ),
      checkBox: (
        <span>
          <input type="checkbox" id={item.id} name="scales"
            checked={selectedCheckBoxes.includes(item.id)} onChange={() => { }}>
          </input>
        </span>
      )
    }))
  }

  useEffect(() => {
    const newParams = JSON.stringify({ pageOffset, pageLimit, filter, showAllEmployees, sorting })
    const filtersHaveChanged = JSON.stringify(filter) !== JSON.stringify(JSON.parse(lastParams).filter)
    const sortingHasChanged = JSON.stringify(sorting) !== JSON.stringify(JSON.parse(lastParams).sorting)
    //go to page 1 if filter or sorting have changed
    if (pageOffset !== 0 && (sortingHasChanged || filtersHaveChanged)) {
      var newParamsFilter = JSON.parse(lastParams)
      newParamsFilter.filter = filter
      newParamsFilter.sorting = sorting
      setLastParams(JSON.stringify(newParamsFilter))
      setPageOffset(0)
    }
    else if (lastParams !== newParams || forceReloadEmployees) {
      setForceReloadEmployees(false);
      setLastParams(newParams);
      (async () => {
        if (showAllEmployees || Object.keys(filter).reduce((acc, item) => (acc || filter[item] !== ''), false)) {
          setTableIsLoading(true)
          const result = await loadEmployees()
          if (result) {
            // add action icons
            let preparedResult = appendData(result)
            setPartialBuisinesspartners(preparedResult)
            setTableIsLoading(false)
          }
        } else {
          cancelPrevious()
          setPartialBuisinesspartners([])
          setTotalCount(0)
          setTableIsLoading(false)
        }
        if (filtersHaveChanged) {
          //setSelectedCheckBoxes([])
          setAllCheckBoxesSelected(false)
        }
      })()
    }
    //eslint-disable-next-line
  }, [pageOffset, pageLimit, filter, showAllEmployees, sorting, forceReloadEmployees, setForceReloadEmployees])


  const selectAll = () => {
    if (allCheckBoxesSelected || (partialBuisinesspartners.length < 1)) {
      setAllCheckBoxesSelected(false)
      setSelectedCheckBoxes([])
    }
    else {
      setAllSelectedLoading(true)
      const filterWithValues = { ...filter }
      Object.keys(filterWithValues).forEach(filterName => {
        if (filterWithValues[filterName] === '') {
          delete filterWithValues[filterName]
        }
      })
      apiClient.getJson(APIEndpoints.partialBusinessPartners, { ...filterWithValues }).then(result => {
        if (result) {
          const arrayOfAllIds = result.map(obj => obj.id)
          setSelectedCheckBoxes(arrayOfAllIds)
        }
      }).finally(() => { setAllCheckBoxesSelected(true); setAllSelectedLoading(false) })
    }
  }


  useEffect(() => {
    // add action icons
    // todo only change selected one

    setPartialBuisinesspartners(prev => (prev.map(item => ({
      ...item,
      checkBox: (
        <span>
          <input type="checkbox" id={item.id} name="scales"
            checked={selectedCheckBoxes.includes(item.id)} onChange={() => { }}>
          </input>
        </span>
      )
    }))))
  }, [selectedCheckBoxes])


  const contextValues = {
    setSelectedCheckBoxes,
    allCheckBoxesSelected,
    selectAll,
    allSelectedLoading,
    selectedCheckBoxes,
    handleLocalChangeCheckbox,
    loaded,
    setLoaded,
    pageOffset,
    setPageOffset,
    pageLimit,
    setPageLimit,
    totalCount,
    setTotalCount,
    showAllEmployees,
    setShowAllEmployees,
    showDisabledEmployees,
    setShowDisabledEmployees,
    filter,
    setFilter,
    costCenterFilter,
    setCostCenterFilter,
    missingArticleConfigurationsFilter,
    setMissingArticleConfigurationsFilter,
    checkUpFilter,
    setCheckUpFilter,
    officeFilter,
    setOfficeFilter,
    openHealthDataFilter,
    setOpenHealthDataFilter,
    partialBuisinesspartners,
    setPartialBuisinesspartners,
    selectedRow,
    setSelectedRow,
    sorting,
    setSorting,
    openOfficeFilter,
    setOpenOfficeFilter,
    openCostCenterFilter,
    setOpenCostCenterFilter,
    resetFilter,
    updateOverlayLeftStates,
    tableIsLoading,
    showOverlayLeft,
    setShowOverlayLeft,
    filterEmployee,
    setFilterEmployee,
    forceReloadEmployees,
    setForceReloadEmployees,
    officeName,
    setOfficeName,
    costCenterName,
    setCostCenterName,
    initialTableId, setInitialTableId
  }
  return (
    <EmployeesContext.Provider value={contextValues}>
      {children}
    </EmployeesContext.Provider>
  )
}

export default EmployeesProvider
