import React, { useEffect, useState } from 'react'
import FileInput from '../../Forms/FileInput'
import { FileOutput } from '../../Forms/FileOutput'
const FileUpload = ({ setDigitalSignature }) => {
  const [file, setFile] = useState(null)

  function fileToDataURL(blob, callback) {
    var a = new FileReader();
    a.readAsDataURL(blob);
    a.onload = (e) => callback(e.target.result)
  }

  useEffect(() => {
    if (file) {
      fileToDataURL(file, (dataUrl) => setDigitalSignature(dataUrl))
    }
  }, [file, setDigitalSignature])

  function resetFileUpload() {
    setFile(null)
    setDigitalSignature('')
  }

  return (
    <>
      {file
        ? <FileOutput
          className="mb-2"
          key={file.name}
          file={file}
          deleteFile={resetFileUpload}
        />
        : <FileInput
          style={{ height: "100%" }}
          maxFiles={1}
          accept={{
            'image/jpeg': [],
            'image/png': []
          }}
          onDrop={(acceptedFiles) => {
            let newFiles = acceptedFiles.map(file => Object.assign(file, {
              preview: URL.createObjectURL(file)
            }));
            setFile(newFiles[0])
          }}
          isInvalid={false}
        />}
    </>
  )
}

export default FileUpload