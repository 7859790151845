import React, { useCallback, useEffect, useState } from 'react'
import { Modal, Alert, Spinner } from "react-bootstrap";
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { ButtonWithLoadingState } from '../../Buttons'
import { getErrorMessageFromApiResponse } from "../../../utils/api/apiUtils"
import VariantSelect from '../../Forms/VariantSelect';
import { t } from 'i18next';
import styled from 'styled-components'

const StyledButtonsWrapper = styled.div`
    .btn {
        margin-left: 5px;
    }

    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        .btn {
            margin: 3px 0;
        }
    }
`


const VariantModal = ({ reloadArticles, selectedArticle, setOpenVariantModal, employeeId }) => {
    const [vairants, setVariants] = useState(null)
    const [selectedFirstPartVariant, setSelectedFirstPartVariant] = useState(null)
    const [selectedSecondPartVariant, setSelectedSecondPartVariant] = useState(null)
    const [newVariant, setNewVariant] = useState(null)
    const [loadingSave, setLoadingSave] = useState(false)
    const [error, setError] = useState("")
    const [loadingSaveAndNext, setLoadingSaveAndNext] = useState(false)

    const handleClose = () => {
        setOpenVariantModal(null)
    }

    const saveData = async () => {
        const params = [{ articleId: selectedArticle.article.id, variantId: newVariant.id }]
        return await apiClient.patchJson(APIEndpoints.businessPartner(employeeId).articleConfigurations, params)
    }

    const handleSave = async () => {
        setLoadingSave(true)
        try {
            await saveData()
            await reloadArticles()
            setOpenVariantModal(null)
        } catch (e) {
            setError(getErrorMessageFromApiResponse(e))
        }
    }

    const handleSaveAndNext = async () => {
        setLoadingSaveAndNext(true)
        try {
            await saveData()
            await reloadArticles({ startTunnel: true })

        } catch (e) {
            setError(getErrorMessageFromApiResponse(e))
        }
    }

    const init = useCallback(async () => {
        let loadedVariants = await apiClient.getJson(APIEndpoints.businessPartner(employeeId, selectedArticle.article.id).articleConfigurationsVariants)
        setVariants(loadedVariants)

        if (selectedArticle.lastSelectedVariant && selectedArticle.lastSelectedVariant.id) {
            const variant = loadedVariants.find(variant => variant.id === selectedArticle.lastSelectedVariant.id)
            if (variant) {
                if (variant.separatedDisplayName.length > 1) {
                    setSelectedFirstPartVariant(variant.separatedDisplayName[0])
                    setSelectedSecondPartVariant(variant.id)
                } else {
                    setSelectedFirstPartVariant(variant.id)
                }
                setNewVariant(variant)
            }
        }
    }, [employeeId, selectedArticle.article.id, selectedArticle.lastSelectedVariant])

    useEffect(() => {
        init()
    }, [init])


    const handleChangeFirstPartVariant = (e) => {
        setSelectedFirstPartVariant(e.target.value)
        setSelectedSecondPartVariant(null)
        if (vairants) {
            const variantObj = vairants.find(variant => variant.id === e.target.value)
            if (variantObj) {
                setNewVariant(variantObj)
            }
            else {
                setNewVariant(null)
            }
        }
    }

    const handleChangeSecondPartVariant = (e) => {
        setSelectedSecondPartVariant(e.target.value)
        const variantObj = vairants.find(variant => variant.id === e.target.value)
        setNewVariant(variantObj)
    }

    return (
        <Modal centered show={true} onHide={handleClose}>
            <Modal.Header closeButton className="pb-0">
                <Modal.Title>
                    <div>
                        <p className="m-0">{t('sizeDetection:editSize')}</p>
                        <p className="m-0" style={{ color: '#9c9c9c' }}>{t('productsOverview:article')}: {selectedArticle.article.displayName}</p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!vairants
                    ? <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
                    :
                    <VariantSelect
                        variants={vairants}
                        selectedFirstPartVariant={selectedFirstPartVariant}
                        handleChangeFirstPartVariant={handleChangeFirstPartVariant}
                        selectedVariantError={false}
                        selectedSecondPartVariant={selectedSecondPartVariant}
                        handleChangeSecondPartVariant={handleChangeSecondPartVariant}
                    />}

                {error ? <Alert variant="danger">{error}</Alert> : null}
            </Modal.Body>
            <Modal.Footer>
                <StyledButtonsWrapper>
                    <ButtonWithLoadingState
                        disabled={!newVariant || loadingSaveAndNext}
                        buttonText={t('saveButton')}
                        isLoading={loadingSave}
                        onClick={handleSave}
                    />
                    <ButtonWithLoadingState
                        disabled={!newVariant || loadingSave}
                        buttonText={t('saveAndContinue')}
                        isLoading={loadingSaveAndNext}
                        onClick={handleSaveAndNext}
                    />
                </StyledButtonsWrapper>
            </Modal.Footer>
        </Modal >
    )
}

export default VariantModal
