import React, { useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import { CategoryIconDefault } from '../Icons/CategoryIcons/CategoryIcons'
import { Form } from 'react-bootstrap'

const StyleListItem = styled.div`
  cursor: pointer;
  padding: 10px 25px;
  border-bottom: 1px solid #ddd;
    &:hover {
      background-color: #ECECEC ;
    }
  ${props => props.selected && `background-color: #ECECEC ;`}
`
const StyledProductImgWrap = styled.div`
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
  background:white;
  width: 50px;
  height:  50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px 0 15px;
  svg {
    width: 100%;
    height: 100%;
    opacity: 0.3;
  }
`
const StyledProductImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const StyledFlexWrapper = styled.div`
  display:flex;
  justify-content:space-between;
  align-items: center;
  width: 100%;
  .leftSide{
    display:flex;
    flex-basis:70%;
  }
  .rightSide{
    display: flex;
    justify-content: flex-end;
    flex-basis:30%;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`
const Article = ({ onClickSelectedArticles, selectedArticles, article, index }) => {
  const theme = useTheme()
  const isSelected = useMemo(() => selectedArticles.map(selectedArticle => selectedArticle.article.id).includes(article.article.id), [article.article.id, selectedArticles])

  return (
    <StyleListItem onClick={() => onClickSelectedArticles(article, index)}
      selected={isSelected}
    >
      <StyledFlexWrapper>
        <div className="leftSide">
          <StyledProductImgWrap>
            {
              article.article.image
                ? <StyledProductImg src={article.article.image.url} alt={article.article.displayName} />
                : <CategoryIconDefault color={theme.primaryColor} />
            }
          </StyledProductImgWrap>
          <div>
            <h3>{article.article.displayName}</h3>
            <p>{article.article.articleNumber}</p>
          </div>
        </div>
        <div className="rightSide">
          <Form.Check
            type="checkbox"
            id={`article-${article.article.id}`}
            name="activityLevel.includeEndValue"
            checked={isSelected}
            onChange={() => onClickSelectedArticles(article, index)}
          />
        </div>
      </StyledFlexWrapper>
    </StyleListItem>
  )
}

export default Article