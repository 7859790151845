import React from 'react'

export const Footwear = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="footwear" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <path className="st0" d="M28.4,97.1c1.2,1.2,2.8,1.8,4.4,1.8c1.7,0,3.3-0.7,4.5-1.9l55.6-56.7l-7.9-7.8c-6.5-6.2-12.7-12.2-18.4-17.9
        c-0.2-0.2-0.6-0.3-0.9-0.2c-0.3,0.1-0.6,0.3-0.7,0.6c-5.3,16.3-15.7,25-27.8,23.4c-0.3,0-0.5,0-0.8,0.2c-0.2,0.2-0.3,0.5-0.3,0.8
        c1.1,16.2-1.3,20.2-11.3,37.1L24.8,77c-0.9,1.6-1.9,3.2-3,5c-3.7,6.2-0.1,9.4,4.9,13.8l0,0l0.2,0.2l0,0
        C27.4,96.3,27.9,96.7,28.4,97.1z M84.5,35.1l5.1,5.1L35.8,95.5c-0.9,0.9-1.8,1.3-2.8,1.3c-1.7,0-3.2-1.3-4.2-2.1l-0.1,0
        c-0.2-0.2-0.5-0.4-0.7-0.6l-0.8-0.6l0.1-0.1L84.5,35.1L84.5,35.1z M29.8,72.9L37.9,56l4.5-0.1l-0.2-2.3l-3.4,0.1l-0.1-0.8
        c-0.1-1.1,0.1-1.8,0.2-2.4l0.1-0.6l3.2-0.1l0-2.3L39,47.6l0-0.9c0-1.5,0.1-3-0.1-4.8l-0.1-1l1,0.1c7.2,0.6,20.6-6,26.6-21.9l0.5-1.2
        l0.1,0.1l15.6,15.3l-0.1,0.1L37,80.3L29.8,72.9L29.8,72.9z M25.8,91.8l-0.6-0.6c-4-4.1-2.4-6.7,0.8-11.9c0.6-1,1.3-2.1,2-3.4l0.6-1
        l6.8,6.9l-0.1,0.1L25.8,91.8z"/>
    </svg>
  )
}
