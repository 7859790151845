import React, { Component, Suspense } from 'react'
import { inject, observer } from 'mobx-react'

import Nav from 'react-bootstrap/Nav'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Subheader } from '../components/Page/logix2020/Subheader'

const NewPasswordBox = React.lazy(() => import('../components/PersonalSpaceBox/NewPasswordBox'))
// const ContactInfoBox = React.lazy(() => import('../components/PersonalSpaceBox/ContactInfoBox'))
const PersonalOptionsBox = React.lazy(() => import('../components/PersonalSpaceBox/PersonalOptionsBox'))

const StyledContainer = styled.div`
  background: white;
  margin-top: 5px;

`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  text-align: left;
  max-width: 768px;
`

const StyledNavitem = styled(Nav.Item)`
  a {
    position: relative;
    margin-top: 20px;
    text-align: center;
  }
`
class PersonalSpace extends Component {

  render() {
    const { t, profileStore } = this.props
    const profile = profileStore.profile

    return (
      <React.Fragment>
        <Subheader className="subHeader-v1">
          <div>
            <div className="subheader-title">{t('personalSpace:PersonalOptions')}</div>
            <div className="subheader-title-small">{profile.businessPartnerDisplayName}</div>
          </div>
        </Subheader>
        <StyledContainer className='flex-grow-1 d-flex flex-column'>
          <StyledContainer className='container-fluid flex-grow-1 d-flex flex-column align-items-center'>
            <StyledWrapper>
              <Suspense fallback={<div>Bearbeitungsfunktionen werden geladen</div>}>
                <PersonalOptionsBox />
                <hr />
                {/*<ContactInfoBox/>
                <hr />*/}
                <NewPasswordBox withCancelButton />
                <hr />
              </Suspense>
              <StyledNavitem>
                <Nav.Link as={NavLink} to='/'>{t('backToStartpage')}</Nav.Link>
              </StyledNavitem>

            </StyledWrapper>
          </StyledContainer>
        </StyledContainer>
      </React.Fragment>
    )
  }
}

export default withTranslation()(inject('profileStore')(observer(PersonalSpace)))
