import React, { useEffect } from 'react'
import SubheaderInventoryOverview from '../components/Inventory/SubheaderInventoryOverview'
import InventorySearchBox from '../components/Inventory/InventorySearchBox'

import AllArticlesSwitch from '../components/Inventory/AllArticlesSwitch'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../components/Page/logix2020/SidebarLeft'
import { FlexWrapper } from '../components/Page/logix2020/FlexWrapper'
import BigFooter from '../components/Footer/BigFooter'
import { Alert } from 'react-bootstrap'
import { PageLoadingLayer } from '../components/Loading/PageLoadingLayer'
import TableVersion from '../components/Inventory/TableVersion'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ContingentList from '../components/OffCanvasArea/ContingentList'
import { scrollToDataId } from '../utils/utils'
import CatalogArticles from '../components/Inventory/CatalogArticles'

const InventoryPage = inject('inventoryStore', 'laundryShelfStore', 'uiStore')(observer(props => {
    const { t, inventoryStore, laundryShelfStore, uiStore } = props
    const { employeeId } = useParams()

    useEffect(() => {
        const initData = async () => {
            const oldEmployeeId = inventoryStore.employeeId;
            await inventoryStore.setEmployeeId(employeeId)
            await inventoryStore.loadEmployeeContingent(oldEmployeeId !== inventoryStore.employeeId)
            await uiStore.loadBPDataSettings();
            (uiStore.laundryShelf_Status !== "hide") && laundryShelfStore.getBPListItem(inventoryStore.employeeId)
        }
        initData()
    }, [employeeId, inventoryStore, laundryShelfStore, uiStore])

    const renderContingentArticlesOverviewTable = () => {
        const contingents = inventoryStore.filteredContingents ? inventoryStore.filteredContingents : []
        return (
            <React.Fragment>
                <PageLoadingLayer isLoading={inventoryStore.loadContingentIsLoading}>
                    {!inventoryStore.loadContingentIsLoading && contingents.length === 0 ?
                        <Alert className='mt-4' variant='info'>{t('productsOverview:noProductsAvailable')}</Alert> : null
                    }
                    {inventoryStore.loadContingentError ?
                        <Alert className='mt-4' variant='danger'>{inventoryStore.loadContingentError}</Alert> : null
                    }

                    {inventoryStore.showAllArticles
                        ? <CatalogArticles />
                        : <TableVersion contingents={contingents} />}

                </PageLoadingLayer>
            </React.Fragment>
        )
    }

    const handleClickScrollToGroup = (categoryId) => {
        scrollToDataId(`category-${categoryId}`)
    }

    return (
        <>
            <SubheaderInventoryOverview />
            <FlexWrapper>
                <SidebarLeft>
                    <AllArticlesSwitch />
                    <hr />
                    <InventorySearchBox />
                    <hr />
                    {(inventoryStore.filteredContingents.length === 0 && inventoryStore.contingents && inventoryStore.contingents.length && (
                        <strong>{t('ProductOverview:noResultsFound')}</strong>
                    )) || ''}
                    {inventoryStore.filteredContingents && inventoryStore.filteredContingents.map(contingent => (
                        <ContingentList
                            key={contingent.id}
                            contingent={contingent}
                            handleClickScrollToGroup={handleClickScrollToGroup}
                        />
                    ))}
                </SidebarLeft>
                <ContentArea>
                    {renderContingentArticlesOverviewTable()}
                </ContentArea>
            </FlexWrapper>
            <BigFooter />
        </>
    )
}))

export default withTranslation()(InventoryPage)
