import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoIosArrowUp } from "react-icons/io";

const StyledBtnScrollToTop = styled.button`
    padding: 5px;
  position: fixed;
  right: 0;
  bottom: 20px;
  background-color: #b1b1b1;
  border: none;
  border-radius: 5px;
  z-index:11;
`;

const ScrollToTop = () => {
  const [isVisable, setIsVisable] = useState(0);

  const toggleVisibility = () => {
    if (window.pageYOffset > 150) {
      setIsVisable(true);
    } else {
      setIsVisable(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behaviour: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="d-flex justify-content-start align-items-center">
      <StyledBtnScrollToTop
        className="no-border-radius"
        onClick={scrollToTop}
        style={{ opacity: isVisable ? "100" : "0", transition: '0.5s ease' }}
      >
        <IoIosArrowUp size="23" className="text-white" />
      </StyledBtnScrollToTop>
    </div>
  );
};

export default ScrollToTop;
