import { Spinner } from "react-bootstrap"
import styled from "styled-components"

export const OverlaySpinnerWrapper = styled.div`
position: relative;
.absoluteSpinner{
  position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 0;
    opacity: .5;
}
`
export const OverlaySpinner = () => (
  <Spinner animation='border' variant='primary' className="absoluteSpinner" />
)