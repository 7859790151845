import { t } from 'i18next'
import React from 'react'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { PdfButton } from '../Buttons/PdfButton'

const PdfDownloadButton = ({ reportIsLoading, createReport, isComplaint, isRetoure, item }) => {

  const loadPdf = async () => {
    const endpoint =
      isComplaint ? APIEndpoints.complaints(item.id).receipt
        : isRetoure ? APIEndpoints.returns(item.id).receipt
          : null
    createReport("reportName", endpoint, {})
  }

  return (
    <PdfButton
      className=" w-100 mb-3"
      variant='secondary'
      onClick={loadPdf}
      isLoading={reportIsLoading.reportName}
      buttonText={isComplaint ? t('complaints:complaintForm') : isRetoure ? t('complaints:returnsReceipt') : ''}
    />
  )
}

export default PdfDownloadButton