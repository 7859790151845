import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'react-ckeditor5-custom'

const CustomCKEditor = ({ initialEditorData, setEditorValue, extraPlugins = [] }) => {
  return (
    <div className="App">
      <CKEditor
        editor={Editor}
        config={{
          toolbar: [
            'undo', 'redo', '|', 'fontSize', 'fontColor', 'fontBackgroundColor', '|',
            'bold', 'italic', 'underline', 'strikethrough', '|', 'link', 'imageUpload', 'insertTable', 'blockQuote', '|',
            'bulletedList', 'numberedList', 'outdent', 'indent'
          ],
          extraPlugins: extraPlugins,
          link: {
            addTargetToExternalLinks: true
          }
        }}
        data={initialEditorData}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorValue(data)
        }}
      />
    </div>
  )
}

export default CustomCKEditor