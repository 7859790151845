import React, { useState, useEffect } from 'react'
import useStores from '../../../stores/useStore'
import { Form, Button, Alert } from 'react-bootstrap'
import OfficeSelect from '../../Employees/Form/FormComponents/OfficeSelect'
import styled from 'styled-components'
import OverlayContainer from '../../Page/logix2020/OverlayContainer'
import OverlayRight from '../../Page/logix2020/OverlayRight'
import LanguageForm from "../../Forms/LanguageForm"
import CostCenterSelect from "../../Employees/Form/FormComponents/CostCenterSelect"
import { emptyGuid } from '../../../utils/emptyGuid'
import { useTranslation } from 'react-i18next'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { Navigate, useLocation } from 'react-router-dom'
import MediaQuery from 'react-responsive'
import { setPointerErrorsFromApiResponse } from "../../../utils/api/apiUtils"
import ArraySelect from "../../Select/ArraySelect"
import LanguageNameInput from "../../Forms/LanguageNameInput"
import MandatoryField from '../../MandatoryField/MandatoryField'

const StyledFormWrapper = styled.div`
 max-width: 600px;
 @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px) {
  max-width:none;
}
`

const AlertStyled = styled.div`
  margin-top:2rem;
`

const CostCenterGeneralForm = ({ costCenterDetails, setCostCenterDetails, mode = "create" }) => {
  const { t } = useTranslation()
  let editMode = mode === "edit"

  const [costCenterId, setCostCenterId] = useState(costCenterDetails?.parentCostCenter?.id || "");
  const [number, setNumber] = useState(costCenterDetails?.number || "");
  const [foreignKey, setForeignKey] = useState(editMode ? (costCenterDetails?.foreignKey || "") : "");
  const [accountType, setAccountType] = useState(costCenterDetails?.type?.id || "");
  const [officeId, setOfficeId] = useState(costCenterDetails?.office?.id || "");
  const [overlayVisible, setOverlayVisible] = useState(false)
  const [success, setSuccess] = useState("")
  const { costCenterStore } = useStores()
  const [redirectId, setRedirectId] = useState("")
  const [languageOverlayFieldName, setLanguageOverlayFieldName] = useState("")
  const [formErrors, setFormErrors] = useState({})
  const [costCenterTypes, setCostCenterTypes] = useState([])
  const [namesTranslation, setNamesTranslation] = useState(costCenterDetails?.name?.values || [])
  const [commentTranslation, setCommentTranslation] = useState(costCenterDetails?.comment?.values || [])
  let location = useLocation()

  useEffect(() => {
    apiClient.getJson(APIEndpoints.costCenterTypes).then(response => {
      setCostCenterTypes(response)
    })
  }, [])

  const handleClickSubmit = () => {
    setSuccess("")
    setFormErrors({})

    const params = {
      "name": {
        "values": namesTranslation
      },
      "comment": {
        "values": commentTranslation
      },
      "number": number,
      "foreignKey": foreignKey,
      "type": {
        "id": accountType
      }
    }

    if (costCenterId) {
      params.parentCostCenter = { "id": costCenterId }
    }
    if (officeId) {
      params.office = { "id": officeId }
    }

    if (mode === "create" || mode === "copy") {
      apiClient.postJson(
        APIEndpoints.newCostCenters,
        params
      ).then((response) => {
        setSuccess(`${t('costCenterAdmin:successCreated')}`)
        costCenterStore.resetPartialCostCenters()  // reseting the state
        costCenterStore.loadPartialCostCenters()  // then reloading it again
        setRedirectId(response.id)
      })
        .catch((error) => {
          setFormErrors(setPointerErrorsFromApiResponse(error))
        })
    }
    if (editMode) {
      apiClient.patchJson(
        APIEndpoints.costCenters(costCenterDetails.id).details,
        params
      ).then((updatedCostcenter) => {
        setSuccess(`${t('costCenterAdmin:successUpdate')}`)
        costCenterStore.resetPartialCostCenters()  // reseting the state
        costCenterStore.loadPartialCostCenters()  // then reloading it again
        setCostCenterDetails(updatedCostcenter)
      })
        .catch((error) => {
          setFormErrors(setPointerErrorsFromApiResponse(error))
        })
    }
  }

  const handleClick = (fieldName) => {
    setOverlayVisible(!overlayVisible)
    setLanguageOverlayFieldName(fieldName);
  }
  const handleCloseOverlay = () => {
    setOverlayVisible(false)
  }
  useEffect(() => {
    if (location.state && location.state.createNewCostCenter) {
      setSuccess(`${t('costCenterAdmin:successCreated')}`)
    }
  }, [location.state, t])

  if (redirectId) {
    return <Navigate
      to={`/costcenteradministration/edit/${redirectId}#general`}
      state={{ createNewCostCenter: true }}
      replace
    />
  }

  return (
    <div>
      <StyledFormWrapper>
        <Form>
          <LanguageNameInput
            openLanguageModal={() => handleClick("name")}
            languageArray={namesTranslation}
            onChange={setNamesTranslation}
            label={`${t('name')}*`}
            isInvalid={formErrors && formErrors.name}
          />
          <LanguageNameInput
            openLanguageModal={() => handleClick("comment")}
            languageArray={commentTranslation}
            onChange={setCommentTranslation}
            label={t('comment')}
            isInvalid={formErrors && formErrors.comment}
          />

          <CostCenterSelect id="costCenterAdminCostCenterTree"
            label={t('costCenterAdmin:parentCostCenter')}
            errorMessage={formErrors.costCenterId}
            value={costCenterId}
            onChange={(costCenter) => setCostCenterId(costCenter.id)}
            isInvalid={formErrors.costCenterId}
          />
          <Form.Group className="mb-3">
            <Form.Label>{t('costCenterAdmin:number')}</Form.Label>
            <Form.Control type="number" placeholder={t('costCenterAdmin:number')} value={number} onChange={e => setNumber(e.target.value)} isInvalid={formErrors.number} />
            <Form.Control.Feedback type="invalid">
              {formErrors.number}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <div>
              <Form.Label>{t('costCenterAdmin:foreignKey')}</Form.Label>
              <Form.Control placeholder={t('costCenterAdmin:foreignKey')} value={foreignKey} onChange={e => setForeignKey(e.target.value)} isInvalid={formErrors.foreignKey} disabled={editMode} />
              <Form.Control.Feedback type="invalid">
                {formErrors.foreignKey}
              </Form.Control.Feedback>
            </div>
            <span>{t('officeAdmin:noteForeignKey')}</span>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('costCenterAdmin:accountType')} *</Form.Label>
            <ArraySelect
              options={costCenterTypes}
              value={accountType}
              onChange={obj => setAccountType(obj.id)}
              isInvalid={formErrors.type}
              defaultOption={{ value: emptyGuid, name: t('pleaseSelect') }}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.type}
            </Form.Control.Feedback>
          </Form.Group>
          <OfficeSelect id="costCenterAdminOfficeTree"
            label={t('costCenterAdmin:organizationalUnit')}
            value={officeId}
            onChange={setOfficeId}
            isInvalid={formErrors.office}
            errorMessage={formErrors.office && !officeId ? formErrors.office : ''}
          />
          <MediaQuery minWidth={769}>
            <div className="d-flex justify-content-between">
              <MandatoryField />
              <Button onClick={handleClickSubmit}>{t('saveButton')}</Button>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={768}>
            <MandatoryField />
            <Button className="w-100" onClick={handleClickSubmit}>{t('saveButton')}</Button>
          </MediaQuery>

        </Form>
        <AlertStyled>
          {success ?
            <Alert variant="success">
              {success}
            </Alert>
            : ""
          }
        </AlertStyled>
      </StyledFormWrapper>
      <OverlayContainer>
        <OverlayRight visible={overlayVisible} onClose={handleCloseOverlay}>
          {
            languageOverlayFieldName === "name" &&
            <LanguageForm
              languageTitle={t('name')}
              handleCloseOverlay={handleCloseOverlay}
              languageArray={namesTranslation}
              onChange={setNamesTranslation} />
          }
          {
            languageOverlayFieldName === "comment" &&
            <LanguageForm
              languageTitle={t('comment')}
              handleCloseOverlay={handleCloseOverlay}
              languageArray={commentTranslation}
              onChange={setCommentTranslation} />
          }
        </OverlayRight>
      </OverlayContainer>
    </div >
  )
}

export default CostCenterGeneralForm
