import styled from 'styled-components'
import { colors } from '../../styles/colors'
import sortingSvg from '../../assets/icons/sorting.svg'
import sortingSvgAsc from '../../assets/icons/sortingAsc.svg'
import sortingSvgDesc from '../../assets/icons/sortingDesc.svg'
import React, { useCallback, useEffect, useRef } from 'react'
import TableLoadingLayer from './TableLoadingLayer'
import { useTableState } from './TableProvider'
import i18n from '../../translation/i18n';

const StyledTableWrapper = styled.div`
  position: relative;

  .table-scroll-helper {
    position: relative;
    top: -85px;
  }
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
  .cursor-pointer{
    cursor:pointer
  }
  .cursor-no-drop{
    cursor:no-drop
  }

  .react-bootstrap-table {
    overflow: auto;
    background: white;
    padding: 1px;
    .table{
      margin-bottom:0;
    }
  }
  .group-member-table {
    overflow: visible !important;

  }

  table {
    color: ${colors.textColorB80};
    word-break:break-word;
  }

  tr.active, tr.active:hover {
    background-color: rgba(0,0,0,0.075);
  }

  tr.is-deleted {
    text-decoration: line-through;
  }

  .table-bordered {
    border: 0;
    td {
      border-left: 0;
      border-right: 0;
      overflow: auto;
    }
  }

  thead {
    box-shadow: 0px 0px 0px 1px #b8b8b8;
    th {
      border:none;
      font-size: 12px;
      text-transform: uppercase;
      padding:  5px 10px 5px 10px;

      .order-4,
      .caret-4-desc,
      .caret-4-asc {
        position: absolute;
        right: 15px;

        &:before {
          content: '';
          display: block;
          height: 14px;
          width: 8px;
          background: url(${encodeURI(sortingSvg)});
          opacity: 1;
        }
        &:after {
        content: none;
        }
      }

      .caret-4-asc {
        &:before {
           background: url(${encodeURI(sortingSvgAsc)}) center center;
        }
      }

      .caret-4-desc {
        &:before {
           background: url(${encodeURI(sortingSvgDesc)}) center center;
        }
      }
      .filter-label {
        margin: 3px 0 0 0;
        display: block;
        position: relative;
        width: 100%;

        input {
          padding-right: 35px;

          &::-ms-clear {
            display: none;
          }
        }

        &:after {
          content: "";
          height: 16px;
          width: 16px;
          display: block;
          position: absolute;
          top: 8px;
          right: 10px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M505.7 475.6L360.1 330c28.2-34.8 45.2-79.1 45.2-127.3C405.3 90.9 314.4 0 202.7 0S0 90.9 0 202.7s90.9 202.7 202.7 202.7c48.2 0 92.5-17 127.3-45.2l145.6 145.6c4.2 4.2 9.6 6.3 15.1 6.3s10.9-2.1 15.1-6.3c8.3-8.4 8.3-21.9-.1-30.2zm-303-112.9c-88.2 0-160-71.8-160-160s71.8-160 160-160 160 71.8 160 160-71.8 160-160 160z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
        }
      }
    }
  }
  .no-data-placeholder-alert {
    margin-bottom: 28rem;
    @media screen and (max-width:767px){
      display: none;
    }
  }
  .no-data-placeholder {
    margin: 14rem 0;
    font-weight: 600;
    font-size: 1.6rem;
    color: ${colors.textColorSecondary};
    @media screen and (max-width:426px){
      display: none;
    }
  }

  th {
    background: ${colors.gray1};
    position:relative;
    &:after {
      content: "";
      display: block;
      width: 1px;
      height: 87.5%;
      background: ${colors.gray3};
      position: absolute;
      right: 0;
      top: 7.5%;
    }
  
    
    &:last-child {
      &:after {
        display: none;
      }
    }


  }
  
  .pagination {
    justify-content: flex-end;
  }

  .actions-cell svg {
    fill: black;
    width: 20px;
    margin-right: 10px;
    cursor: pointer;

    &.delivery-icon {
      width: 30px;
    }
  }

 
  .costcenter-cell {
    border-right: 0;
  }
  .actions-header-cell {
    position:relative;
    border-left: 0;
    width: 180px;
  }
  .actions-cell {
    padding: 10px;
    vertical-align: middle;
    > span {
      display: none;
      justify-content: space-evenly; 
    }
  }
  .checkBox-header-cell {
    position:relative;
    border-left: 0;
    width: 100px;
    vertical-align: middle;
    > span {
      display: flex;
      justify-content: space-evenly; 
    }
  }
  .checkBox-cell {
    vertical-align: middle;
    > span {
      display: flex;
      justify-content: space-evenly; 
      align-items: center
    }
  }
  .checkBox-cell svg {
    cursor: pointer;
  }
 
  tbody tr:hover {
    .actions-cell > span {
      display: flex;
    }
  }
  tbody{
    td{
      padding:0.75rem;
    }
  }

  .react-bootstrap-table-pagination {
    margin-top:20px;
    justify-content: center;
    flex-direction: row-reverse;
    > div {
      flex: 0;
      max-width: none;
    }

    .pagination {
      justify-content: flex-start;
      overflow: auto;
    }
  }


  @media
  only screen 
  and (max-width: 1079px), (min-device-width: 320px) and (max-device-width: 1079px)  {
    thead > tr > th > span {
      display:none;
    }
    .actions-cell > span {
      display: flex;
    }
  }
  }
 
  @media
  only screen 
  and (max-width: 767px), (min-device-width: 320px) and (max-device-width: 767px)  {

      .react-bs-table-no-data{display:none}

      .table {
        empty-cells: show;
      }
      
      /* Table head to be lines of 2 */
      thead > tr > th{
         width:50%;
         display:inline-block;
         border:.5px solid gray;
         text-align:center;
      }
      .fullWidthMobile{
        width:100%;
        display:inline-block;
     }

      /* Force table to not be like tables anymore */
        tbody, td, tr {
        display: block;
      }

     th{
      &:after {
        display:none;
      }
     }
        
      tr:nth-child(2n) {
        background: #ccc;
      }
    
      td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        empty-cells: show !important;
        position: relative;
        white-space: normal;
        text-align:left;
      }

      td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 611x;
        left: 6px;
        width: 44%;
        white-space: nowrap;
        text-align:left;
        font-weight: bold;
      }  
      
      .actions-header-cell, .checkBox-header-cell {
        display:none
      }

      .actions-cell {
        width: 100% !important;
      }  
    }
  }

  ${props => {
    if (props.totalPages) {
      return `
      .react-bootstrap-table-pagination-list {
        &:before {
          text-align: center;
          display: block;
          white-space: nowrap;
          content: "${i18n.t('page')} ${props.currentPage} ${i18n.t('of')} ${props.totalPages}";
        }
      }
      `
    }
  }
  }`

const UniversalTableWrapperTest = ({ children, tableIsLoading, noAutoscroll, currentPage, totalPages }) => {
  const tableScrollHelperRef = useRef()
  const { tableRef } = useTableState()

  const scrollTableIntoView = useCallback(() => {
    const { top } = tableScrollHelperRef.current.getBoundingClientRect()
    if (top < 0 || top > window.innerHeight) {
      tableScrollHelperRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }, [])

  useEffect(() => {
    if (tableIsLoading && !noAutoscroll) {
      scrollTableIntoView()
    }
  }, [tableIsLoading, noAutoscroll, scrollTableIntoView])

  return (
    <>
      <div className="table-scroll-helper" ref={tableScrollHelperRef} />
      <StyledTableWrapper currentPage={currentPage} totalPages={totalPages} >
        {children}
        <TableLoadingLayer tableRef={tableRef} tableIsLoading={tableIsLoading} />
      </StyledTableWrapper>
    </>
  )
}

export default UniversalTableWrapperTest





