import React from 'react'

export const Consumables = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="consumables" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <path className="st0" d="M98.4,50.6c0.6-0.6,0.8-1.5,0.8-2.3c0-0.8-0.3-1.5-0.8-2c0.6-0.6,0.8-1.5,0.8-2.3c-0.1-1.3-0.8-2.4-2-2.9L64,27.3
        c-2.1-0.9-4.5-0.8-6.5,0.2L16.1,47.2c-1.2,0.6-1.9,1.7-1.9,3c0,0.8,0.3,1.6,0.8,2.1c-0.5,0.6-0.8,1.4-0.8,2.2c0,0.8,0.3,1.6,0.8,2.1
        c-0.5,0.6-0.8,1.4-0.8,2.2c0,0.8,0.3,1.6,0.8,2.2c-0.5,0.6-0.8,1.4-0.8,2.2c0,0.8,0.3,1.6,0.8,2.1c-0.5,0.6-0.8,1.4-0.8,2.2
        c0,1.3,0.8,2.4,1.9,3l33.6,15.3c1.1,0.5,2.2,0.7,3.3,0.7c1.3,0,2.6-0.3,3.7-0.9l40.7-21.3c1.1-0.6,1.8-1.8,1.8-3.1
        c0-0.8-0.3-1.5-0.8-2c0.6-0.6,0.8-1.5,0.8-2.3c0-0.8-0.3-1.5-0.8-2c0.6-0.6,0.8-1.5,0.8-2.3C99.2,51.9,98.9,51.2,98.4,50.6z
         M17.1,58L17.1,58l32.6,14.9c2.2,1,4.9,1,7-0.2L96.4,52c0.3,0.2,0.5,0.5,0.5,0.8c0,0.4-0.2,0.7-0.5,0.9L55.6,75
        c-1.6,0.8-3.4,0.9-5,0.1L17.1,59.8c-0.4-0.2-0.6-0.5-0.6-0.9C16.5,58.5,16.7,58.2,17.1,58z M17.1,62.3L17.1,62.3l32.6,14.9
        c2.2,1,4.9,1,7-0.2l39.7-20.7c0.3,0.2,0.5,0.5,0.5,0.8c0,0.4-0.2,0.7-0.5,0.9L55.6,79.3c-1.6,0.8-3.4,0.9-5,0.1L17.1,64.1
        c-0.4-0.2-0.6-0.5-0.6-0.9C16.5,62.8,16.7,62.5,17.1,62.3z M17.1,66.7L17.1,66.7l32.6,14.9c2.2,1,4.9,1,7-0.2l39.7-20.7
        c0.3,0.2,0.5,0.5,0.5,0.8c0,0.4-0.2,0.7-0.5,0.9L55.6,83.7c-1.6,0.8-3.4,0.9-5,0.1L17.1,68.4c-0.4-0.2-0.6-0.5-0.6-0.9
        C16.5,67.2,16.7,66.9,17.1,66.7z M96.3,49.3L55.6,70.6c-1.6,0.8-3.4,0.9-5,0.1L17.1,55.4c-0.4-0.2-0.6-0.5-0.6-0.9
        c0-0.4,0.2-0.7,0.6-0.9l0,0l32.6,14.9c2.2,1,4.9,1,7-0.2l39.7-20.7c0.3,0.2,0.5,0.5,0.5,0.8C96.9,48.8,96.7,49.1,96.3,49.3z
         M96.3,45L55.6,66.3c-1.6,0.8-3.4,0.9-5,0.1L17.1,51.1c-0.4-0.2-0.6-0.5-0.6-0.9c0-0.4,0.2-0.7,0.6-0.9l41.5-19.7
        c0.8-0.4,1.6-0.5,2.4-0.5c0.8,0,1.5,0.1,2.2,0.4l33.1,13.8c0.4,0.1,0.6,0.5,0.6,0.9C96.9,44.5,96.7,44.8,96.3,45z"/>
    </svg>
  )
}
