import React, { useState } from 'react'

const ReportsJobsContext = React.createContext()

const ReportJobsProvider = ({children}) => {
  const [filter, setFilter] = useState({})

  const contextValues = {
    filter,
    setFilter
  }

  return (
    <ReportsJobsContext.Provider value={contextValues} >
      {children}
    </ReportsJobsContext.Provider>
  )
}

export const useReportsJobsState = () => {
  return React.useContext(ReportsJobsContext)
}

export default ReportJobsProvider
