import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { PageLoadingLayer } from '../components/Loading/PageLoadingLayer'
import StickyShoppingBasket from '../components/StickyShoppingBasket/StickyShoppingBasket'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import { TestSideBarLeftDash } from '../components/Page/logix2020/TestSideBarLeftDash'
import { Subheader } from '../components/Page/logix2020/Subheader'
import OrdersSummaryCard from '../components/Dashboard/OrdersSummaryCard'
import AccountHistoryCard from '../components/Dashboard/AccountHistoryCard'
import { colors } from '../styles/colors'
import Feeds from '../components/Feeds/Feeds'
import AppointmentsCard from '../components/Dashboard/AppointmentsCard'
import BigFooter from '../components/Footer/BigFooter'
import { FlexWrapper } from '../components/Page/logix2020/FlexWrapper'
import { FiChevronsLeft } from "react-icons/fi";
import { FiChevronsRight } from "react-icons/fi";
import { ContingentIcon } from '../components/Icons/ContingentIcon'
import { CalendarIcon } from '../components/Icons/CalendarIcon'
import { PrevOrderIcon } from '../components/Icons/PrevOrderIcon'
import MediaQuery from 'react-responsive'

const StyledSidebarLeft = styled(TestSideBarLeftDash)`
  background: white;

  .pointer {
    cursor: pointer;
  }

  .sidebarOpen {
    display: block;
    padding-right: 36px;
  }

  .sidebarClose {
    width: 10px !important;
    opacity:0;
    padding-left: 36px;
    padding-top: 56px;
  }

    ul {
      list-style: none;
      padding: 0;
    }
    .sidebarLeftContent {
      position: static;
      max-height: none;
      
      &:hover {
        overflow-y: hidden;  
      }
    }
    .sidebarClosed {
      min-width:0;
      width: 0;
      overflow-x: hidden;
    }
    .icons-side {
      width: 33px;
      position: fixed;
      top: 140px;
      z-index:0;
      background-color: white;
      height: 100vh;
    }

    @media screen and (max-width: 1081px) {
      .icons-side {
        display: none !important;
      }
    }
   
`
const StyledContentArea = styled(ContentArea)`
    background: ${colors.gray6};
    padding:0px 20px 20px 33px;
    flex-shrink: 2;
    -webkit-box-shadow: -1px 3px 6px -1px rgba(115,115,115,0.22); 
    @media only screen and (max-width: 426px), (min-device-width: 320px) and (max-device-width: 426px)  {
      padding:0px 20px 20px 20px;
    }
`

const StyledIcon = styled.div`
    .left-icon {
      position: fixed;
      opacity: 0.5;
      top: 140px;
      z-index: 6;
      left: 0px;
      background-color: #303030;
      color: white;
      border: 1px solid white;
      border-radius: 5px;
      box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 4px 1px;
    } 
    .fa-icon {
      cursor: pointer;
      z-index: 11;
    }
    .fa-icon:hover {
      background-color: ${props => props.theme.primaryColor};
      opacity: 1;
      border-radius: 5px;

    }
    .right-icon {
      position: fixed;
      opacity: 0.5;
      top: 140px;
      z-index: 16;
      left: 0px;
      background-color: #303030;
      color: white;
      border: 1px solid white;
      border-radius: 5px;
    } 
     .left-icon:hover, .right-icon:hover {
      opacity: 1;
     }
`

const StyledAccountBallance = styled.p`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.gray2};
    margin-bottom: 20px;
    padding-bottom: 20px;
    span:first-child{
    margin-right: 14px;
    }
    span:last-child{
      color: ${props => props.theme.primaryColor}
    }
`

const TestDashboard = inject('uiStore', 'profileStore')(observer((props) => {
  const { t, uiStore, profileStore } = props
  const [open, setOpen] = useState(false);
  const profile = profileStore.profile

  useEffect(() => {
    if (uiStore.showPoints) {
      profileStore.loadProfile(true)
    }
  }, [profileStore, uiStore.showPoints])

  if (!uiStore.dashboard) {
    return <PageLoadingLayer isLoading={true} />
  }


  const openSlideBar = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(prev => !prev)
  }


  return (
    <div className="side-v1" >
      <Subheader className="subHeader-v1">
        <div>
          <div className="subheader-title">{t('dashboard:welcome')}</div>
          <div className="subheader-title-small">{profile.businessPartnerDisplayName}</div>
        </div>
      </Subheader>
      <FlexWrapper>
        <StyledSidebarLeft open={open} className={`sidebar-v1`} >
          <MediaQuery minWidth={1081}>
            {
              open ?
                <div className={`${open ? "sidebarOpen" : "sidebarClose"}`}>
                  <StyledAccountBallance className="h1">
                    {profileStore.profile.points && uiStore.showPoints ? <>
                      <span>{t('dashboard:myAccountBallance')}</span><span>{profileStore.profile.points} {t('points')}</span></> : ''}
                  </StyledAccountBallance>
                  <ul>
                    {uiStore.showPointHistory && <li><AccountHistoryCard /></li>}
                    {uiStore.fittingReservation && <li><AppointmentsCard /></li>}
                    {(uiStore.employeeOrderAvailable || uiStore.costcenterOrderAvailable || uiStore.personalOrderAvailable || uiStore.privateOrder) && <li><OrdersSummaryCard /></li>}
                    {uiStore.allowShowAllOrders && <li><OrdersSummaryCard showAllOrders={true} /></li>}
                  </ul>
                </div >
                :
                <div className="d-flex flex-column align-items-center icons-side">
                  {uiStore.showPointHistory && <i className="pt-5 pointer" data-toggle="tooltip" data-placement="right" title={t('dashboard:accountHistory')} onClick={(e) => openSlideBar(e)}><ContingentIcon className="pt-5" /></i>}
                  {uiStore.fittingReservation && <i className="pt-5 pointer" data-toggle="tooltip" data-placement="right" title={t('appointment:appointments')} onClick={(e) => openSlideBar(e)}><CalendarIcon className="pt-5" /></i>}
                  {(uiStore.employeeOrderAvailable || uiStore.costcenterOrderAvailable || uiStore.personalOrderAvailable || uiStore.privateOrder) && <i className="pt-5 pointer" data-toggle="tooltip" data-placement="right" title={t('orders:title')} onClick={(e) => openSlideBar(e)}><PrevOrderIcon className="pt-5" /></i>}
                  {uiStore.allowShowAllOrders && <i className="pt-5 pointer" data-toggle="tooltip" data-placement="right" title={t('orders:allOrders')} onClick={(e) => openSlideBar(e)}><PrevOrderIcon className="pt-5" /></i>}
                </div>
            }
            <StyledIcon>
              {
                open ?
                  <div data-toggle="tooltip" data-placement="right" title={t('collapse')}>
                    <span className="left-icon no-border-radius">
                      <FiChevronsLeft size={33} className="fa-icon" onClick={(e) => openSlideBar(e)} />
                    </span>
                  </div>
                  :
                  <div data-toggle="tooltip" data-placement="right" title={t('expand')}>
                    <span className="right-icon no-border-radius">
                      <FiChevronsRight size={33} className="fa-icon no-border-radius" onClick={openSlideBar} />
                    </span>
                  </div>
              }
            </StyledIcon>
          </MediaQuery>

          <MediaQuery maxWidth={1080}>
            <div>
              <StyledAccountBallance className="h1 p-3">
                {profileStore.profile.points && uiStore.showPoints ? <>
                  <span>{t('dashboard:myAccountBallance')}</span><span>{profileStore.profile.points} {t('points')}</span></> : ''}
              </StyledAccountBallance>
              <ul>
                {uiStore.showPointHistory && <li><AccountHistoryCard /></li>}
                {uiStore.fittingReservation && <li><AppointmentsCard /></li>}
                {(uiStore.employeeOrderAvailable || uiStore.costcenterOrderAvailable || uiStore.personalOrderAvailable || uiStore.privateOrder) && <li><OrdersSummaryCard /></li>}
                {uiStore.allowShowAllOrders && <li><OrdersSummaryCard showAllOrders={true} /></li>}
              </ul>
            </div >
          </MediaQuery>
        </StyledSidebarLeft >
        <StyledContentArea className="content-v1">
          <Feeds setOpen={setOpen} />
          <StickyShoppingBasket />
        </StyledContentArea>
      </FlexWrapper >
      <BigFooter />
    </div >
  )
}))

export default withTranslation()(TestDashboard)
