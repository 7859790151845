import styled, { css } from 'styled-components'

export const StyledDisabledContainer = styled.div`
    position: relative;
    ${props => props.disabled && css`
        &:after{
            content: '';
            display:block;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: rgba(255,255,255,0.6);
        }`
    };
`