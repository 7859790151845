import React from 'react'

const PageButtonRenderer = ({
  page,
  active,
  disabled,
  title,
  onPageChange
}) => {
  const handleClick = (e) => {
    e.preventDefault()
    onPageChange(page)
  }
  return (
    <li key={typeof page === 'object' ? page.key : page}
        className={`page-item ${active && 'active'} ${disabled && 'disabled'}`} title={title}>
      <button className="page-link" onClick={handleClick}>{page}</button>
    </li>

  )
}

export default PageButtonRenderer
