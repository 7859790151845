import React from 'react'
import { useTheme } from 'styled-components'



export const CircleCheckIcon = ({ variant='undone', backgroundColor, color, opacity, border }) => {
  const theme = useTheme()
  const defaultColors = {
    undone: {
      color: '#959595',
      backgroundColor: '#DDDDDD',
      opacity: '0.327520461'
    },
    must: {
      color: '#959595',
      backgroundColor: '#DDDDDD',
      opacity: '0',
      border: 'red'
    },
    done: {
      color: '#FFFFFF',
      backgroundColor: theme.primaryColor,
      opacity: '1',
      border: 'red'
    },
    optional: {
      color: '#FFFFFF',
      backgroundColor: theme.primaryColor,
      opacity: '1',
      border: "none"
    },
    none: {
      opacity: '0',
    }
  }

  const useColor = color || defaultColors[variant].color
  const useBackgroundColor = backgroundColor || defaultColors[variant].backgroundColor
  const useOpacity = opacity || defaultColors[variant].opacity
  const useBorder = border ||  defaultColors[variant].border

  
  return (
    <svg width="25px" height="25px" viewBox="0 0 20 20" >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={useBackgroundColor} >
          <circle cx="10" cy="10" r="9" stroke={useBorder} strokeWidth="1"/>
        </g>
        <path
            d="M13.9162458,14.2495791 L6.58291245,14.2495791 L6.58291245,11.8051347 L11.4709124,11.8045791 L11.4718013,3.24957911 L13.9162458,3.24957911 L13.9162458,14.2495791 Z"
            fill={useColor}
            opacity={useOpacity}
            transform="translate(10.249579, 8.749579) rotate(-315.000000) translate(-10.249579, -8.749579)"
        />
      </g>
    </svg>
  )
}
