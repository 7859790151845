import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import MediaQuery from 'react-responsive'
import { formatedDate } from '../../utils/formatedDate'
import { Table, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'


const StyledTr = styled.tr`
  td{ 
    .shipping-sum{
        color: gray;
    }
  }
`
const StyledHeaderTr = styled.tr`
vertical-align: baseline;
th{
    .pending-delivery-info{
        font-size:small;
        color:gray;
        font-weight: initial;
    }
}
`

const StyledRow = styled(Row)`
  padding: 10px;
    border-bottom: 1px solid ${colors.gray3};
    .pending-delivery-info{
        font-size:small;
        color:gray;
        font-weight: initial;
    }
`

const StyledComment = styled.div`
    color: ${colors.gray3};
    padding: 4px;
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 15px;
    font-style:italic;
    background:white;
`
const InventoryHistoryList = ({ inventoryHistory }) => {
    const { t } = useTranslation()
    const getPendingDeliveryAmount = (item) => {
        let orderedQuantity = item.orderedQuantity || 0
        let shippedQuantity = item.shippedQuantity || 0
        return orderedQuantity - shippedQuantity
    }
    return (
        <React.Fragment>
            <MediaQuery maxWidth={992}>
                <>
                    {inventoryHistory.map((item, index) => (
                        <StyledRow key={`list-${index}`}>
                            <Col xs={12} sm={6}>
                                <div className='fw-bold'>{formatedDate(item.createdAt)}</div>
                                <div>{item.article.displayName} {item.article.articleNumber}</div>
                                <div>{t('size')}: {item.articleVariant.displayName}</div>
                                <div>{t('type')}: <span className='text-uppercase'>{item.type}</span></div>
                                <div>{t('variation')}: {item.quantity}
                                    {item.type === "ordered"
                                            ? <span className='shipping-sum'>&nbsp;{getPendingDeliveryAmount(item) === 0 ? null : `(${getPendingDeliveryAmount(item)}*)`}</span>
                                            : null
                                        }
                                </div>
                                <div className="pending-delivery-info">* {t('inventory:pendingDelivery')}</div>
                            </Col >
                            {item.comment && <Col xs={12} sm={6}>
                                <StyledComment>{item.comment}</StyledComment>
                            </Col>}
                        </StyledRow>
                    ))}
                </>
            </MediaQuery>
            <MediaQuery minWidth={993}>
                <Table >
                    <thead>
                        <StyledHeaderTr>
                            <th>{t('date')}</th>
                            <th>{t('articles')}</th>
                            <th>{t('size')}</th>
                            <th>{t('type')}</th>
                            <th><div>{t('variation')}</div><div className="pending-delivery-info">* {t('inventory:pendingDelivery')}</div></th>
                            <th>{t('comment')}</th>
                        </StyledHeaderTr>
                    </thead>
                    <tbody>
                        {inventoryHistory.map((item, index) => (
                            <StyledTr key={index}>
                                <td>
                                    <div className='fw-bold'>{formatedDate(item.createdAt)}</div>

                                </td>
                                <td>
                                    <div>{item.article.displayName} {item.article.articleNumber}</div>

                                </td>
                                <td>
                                    <div>{item.articleVariant.displayName}</div>

                                </td>
                                <td>
                                    <span className='text-uppercase'>{item.type}</span>
                                </td>
                                <td>
                                    {item.quantity}
                                    {item.type === "ordered"
                                        ? <span className='shipping-sum'>&nbsp;{getPendingDeliveryAmount(item) === 0 ? null : `(${getPendingDeliveryAmount(item)}*)`}</span>
                                        : null
                                    }
                                </td>
                                <td style={{ maxWidth: "300px", wordWrap: "break-word" }}>
                                    {item.comment && <StyledComment>{item.comment}</StyledComment>}
                                </td>
                            </StyledTr>
                        ))
                        }

                    </tbody>
                </Table>

            </MediaQuery>
        </React.Fragment >
    )

}

export default InventoryHistoryList
