import React from 'react'
import { Calendar as ReactCalendar } from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { StyledCalendarWrapper } from './StyledCalendarWrapper'

const Calendar = (props) => {

  return (
    <StyledCalendarWrapper>
      <ReactCalendar {...props} />
    </StyledCalendarWrapper>
  )
}

export default Calendar
