import { t } from 'i18next'
import React from 'react'
import UniversalTableWrapperTest from '../../../Table/UniversalTableWrapperTest'
import BootstrapTable from 'react-bootstrap-table-next'
import { useTableState } from '../../../Table/TableProvider'

const MandatorsTable = ({ selectedMandators, subMandators, handleSelectMandator }) => {
  const { tableRef } = useTableState()

  const columns = [
    {
      dataField: '__assigneds',
      text: t('costCenterAdmin:isAssigned'),
      classes: 'checkBox-cell',
      sort: false,
      formatter: (cell, row, rowIndex, extraProps) => {
        let mandatorId = row.id
        let checked = selectedMandators.includes(mandatorId)
        return <span> <input id={row.id} type="checkbox" checked={checked} onChange={() => { }} /></span>
      }, formatExtraData: {
        selectedMandators,
        selectedMandatorsTrigger: selectedMandators.length
      }
    },
    {
      dataField: 'displayName',
      text: t('mandators'),
      sort: true,
    },
  ]
  return (
    <UniversalTableWrapperTest >
      <BootstrapTable
        ref={tableRef}
        noDataIndication={() => { <div className="no-data-placeholder">No Data</div> }}
        bootstrap4
        keyField="id"
        hover
        data={subMandators}
        columns={columns}
        bordered={false}
        rowEvents={{ onClick: handleSelectMandator }}
      />
    </UniversalTableWrapperTest>
  )
}

export default MandatorsTable