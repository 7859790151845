import React from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useAppointmentState } from '../../../stores/AppointmentProvider'
import { emptyGuid } from '../../../utils/emptyGuid'
import ArraySelect from '../../Select/ArraySelect'

const AppointmentTypeSelect = ({ value, onChange, ...rest }) => {
    const { t } = useTranslation()
    const {
        appointment,
        setAppointment,
        appointmentTypes,
        getCabins
    } = useAppointmentState()

    const handleChange = appointmentType => {
        const resetCabin = {
            displayName: "",
            isBlocked: true,
            id: emptyGuid
        }
        const resetDay = ""
        const resetTimeSlot = {
            hour: 0,
            minute: 0,
            id: emptyGuid
        }
        let newAppointment = { ...appointment, appointmentType: appointmentType, cabin: resetCabin, day: resetDay, timeSlot: resetTimeSlot }
        setAppointment(newAppointment)
        getCabins(newAppointment)
    }

    return (
        <>
            {appointment &&

                <Form.Group controlId="formBasicCheckbox">
                    <Form.Label>{t('appointment:selectType')}</Form.Label>
                    <ArraySelect
                        onChange={handleChange}
                        value={appointment.appointmentType.id}
                        options={appointmentTypes}
                        defaultOption={{ value: emptyGuid, name: t('pleaseSelect') }}
                    />


                </Form.Group>}
        </>
    )
}

export default AppointmentTypeSelect