import React, { useState, useEffect, useCallback } from 'react'
import styled, { withTheme } from 'styled-components'
import { inject, observer } from 'mobx-react'
import ProductGallery from '../components/ProductGallery/ProductGallery'
import ProductBuyBox from '../components/ProductBuyBox/ProductBuyBox'
import { Spinner } from 'react-bootstrap'
import ProductDetail from '../components/ProductDetail/ProductDetail'
import StickyShoppingBasket from '../components/StickyShoppingBasket/StickyShoppingBasket'
import { withTranslation } from 'react-i18next'
import { Subheader } from '../components/Page/logix2020/Subheader'
import SubheaderTitle from '../components/Employees/Subheader/SubheaderTitle'
import EmployeeData from '../components/Employees/Subheader/EmployeeData'
import { Navigate } from 'react-router-dom'
import OrderableAccessories from '../components/ProductDetail/OrderableAccessories'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import BigFooter from '../components/Footer/BigFooter'
import { useParams } from 'react-router-dom'

const StyledContentArea = styled(ContentArea)`
   @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px)  {
    padding: 20px;
    min-height: calc(100vh - 140px);//header+navbar=140px
   }
   
`
const StyledContainer = styled.div`
  padding-top: 0;
`
const StyledLoading = styled.div`
  align-items: center;
  background: rgba(255,255,255,.7);
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  height: calc(100vH - 237px);
`
const StyledLoadingText = styled.span`
  color: ${props => props.theme.primaryColor};
  font-size: 16px;
  letter-spacing: -1px;
  margin-left: 40px;
  text-transform: uppercase;
`

const StyledContentWrapper = styled.div`
  max-width: 1440px;
`


const ProductDetailsPage = inject('productsStore', 'teamOrderStore', 'profileStore', 'productDetailsStore')(observer((props) => {
  const [redirectToProductsOverview] = useState(false)
  const { employeeId, shoppingBasketArticleId, costCenterId, storeHouseId, orderType } = useParams()
  const { t, productsStore, teamOrderStore, profileStore, productDetailsStore } = props


  const initPrdoductDetailPage = useCallback(async () => {
    try {
      await productsStore.loadOrCreateBasket()
      await productDetailsStore.loadShoppingBasketArticle(shoppingBasketArticleId)
      await productDetailsStore.loadShoppingBasketArticleNextExpiries(shoppingBasketArticleId)
      await productDetailsStore.loadArticle(productDetailsStore.shoppingBasketArticle.article.id)
    }
    catch (e) { }
  }, [productDetailsStore, productsStore, shoppingBasketArticleId])

  useEffect(() => {
    console.log('ProductDetailsPage useEffect:', storeHouseId, costCenterId, orderType)
    teamOrderStore.initializeCurrentProcessType(storeHouseId, costCenterId, orderType)
    //get + set employee ID depending on params or logged in user
    const newEmployeeId = employeeId || (!!costCenterId ? '' : profileStore.profile.id);
    if (productsStore.targetEmployeeId !== newEmployeeId) {
      productsStore.targetEmployeeId = newEmployeeId
    }
    initPrdoductDetailPage()
    return () => { productDetailsStore.cancelLoadAarticle(); productDetailsStore.resetBuyBoxState() }
  }, [storeHouseId, costCenterId, employeeId, initPrdoductDetailPage, orderType, productDetailsStore, productsStore, profileStore.profile.id, shoppingBasketArticleId, teamOrderStore])



  const article = productDetailsStore.article
  const shoppingBasketArticle = productDetailsStore.shoppingBasketArticle
  const toProductsOverview = productsStore.targetEmployeeId === profileStore.profile.id ? '/products/personal' : `/products/personal/${productsStore.targetEmployeeId}`

  const processType = teamOrderStore.getCurrentProcessType()
  if (redirectToProductsOverview) {
    return <Navigate to={toProductsOverview} />
  }
  const getLinkToProductsOverview = () => {
    switch(processType)
    {
      case 'storeHouse':
        return `/storehouse/${teamOrderStore.selectedStorehouseId}/${teamOrderStore.selectedCostcenterId}/products`
      case 'costCenter':
        return `/costcenter/${teamOrderStore.selectedCostcenterId}/costcenterproducts`
      case 'private':
        return `/products/private`
      case 'personal':
      default:
        return toProductsOverview
    }
  }
  if (!article || !shoppingBasketArticle) {
    return <StyledLoading>
      <Spinner animation="border" variant="primary" />
      <StyledLoadingText>{t('productDetail:loadingProduct')}</StyledLoadingText>
    </StyledLoading>
  }

  return (
    <div>
      <Subheader className="subHeader-v1">
        {processType === 'personal' ?
          <>
            <div className="flex-grow-1">
              <SubheaderTitle
                withBackButton
                defaultBackTo={getLinkToProductsOverview()} />
            </div>
            <EmployeeData showJobFunction />
          </>
          :
          <SubheaderTitle
          withBackButton
          defaultBackTo={getLinkToProductsOverview()}
          goBackLabel={t('productDetail:backToProductsOverview')}
        />
        }
      </Subheader>
      <StyledContentArea >
        <StyledContentWrapper className="container-fluid flex-grow-1 d-flex flex-column">
          <StyledContainer className="row mt-4 mb-4">

            <div className="col-xl-9">
              <div className="row">
                <div className="col-md-6">
                  <ProductGallery article={article} />
                </div>
                <div className="col-md-6">
                  <ProductBuyBox />
                </div>
              </div>
              <div className="row">
                <ProductDetail article={article} processType={processType} />
              </div>
            </div>
            <div className="col-xl-3">
              {productsStore.basket.id && article.id && (
                <OrderableAccessories basketId={productsStore.basket.id} orderType={orderType} articleId={article.id} costCenterId={costCenterId || null} employeeId={employeeId} />
              )}
            </div>
          </StyledContainer>
          <StickyShoppingBasket />
        </StyledContentWrapper>
      </StyledContentArea>
      <BigFooter />
    </div>
  )
}))

export default withTheme(withTranslation()(ProductDetailsPage))
