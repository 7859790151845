import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { resourcesLogin } from '../../translation/resources'
import { t } from 'i18next'
import { useEffect } from 'react'

// const langSymbol = " <img style={{ cursor: 'pointer' }} src={LanguageIcon} alt='icon' />"
const LoginLanguageSwitch = inject('uiStore')(observer((props) => {
  const { uiStore } = props
  const [languages, setLanguages] = useState(resourcesLogin())

  useEffect(() => {
    const selectedLanguage = uiStore.language
    if (!Object.keys(languages).includes(selectedLanguage)) {
      uiStore.updateLanguageInUrl(Object.keys(languages)[0])
    }
  }, [languages, uiStore])

  const handleChange = (eventKey) => {
    uiStore.updateLanguageInUrl(eventKey)
    setLanguages(resourcesLogin())
  }

  if (Object.keys(languages).length <= 1) {
    return null
  }
  return (
    <DropdownButton variant="outline-primary" id='dropdown-basic-button' title={t('language_' + uiStore.language)} onSelect={handleChange}>
      {Object.keys(languages).map(langKey =>
        uiStore.language !== langKey &&
        <Dropdown.Item key={langKey} eventKey={langKey}>{t(`language_${langKey}`)}</Dropdown.Item>
      )}
    </DropdownButton>
  )

}))

export default LoginLanguageSwitch