import { t } from 'i18next'
import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { PdfButton } from '../../Buttons/PdfButton'
import DownloadURLAlert from '../../Forms/DownloadURLAlert'
import { Subheader } from '../../Page/logix2020/Subheader'
import SubheaderForm from '../../Page/logix2020/SubheaderForm'

const SubheaderOrdersDetail = ({ order, print }) => {
  const [error, setError] = useState('')
  const [downloadUrl, setDownloadUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const showPrintButton = (order.status !== "template" && !order.isCanceled)
  const loadPdf = () => {
    setError('')
    setIsLoading(true)
    setDownloadUrl('')
    apiClient.postJson(APIEndpoints.incomingOrders(order.id).receipt, {}, true, true)
      .then(response => {
        const newWindowRef = window.open(response.headers.location)
        if (!newWindowRef) {
          setDownloadUrl(response.headers.location)
        }
      }).catch(() => {
        setError(t('export:createError'));
      }).finally(() => {
        setIsLoading(false)
      })
  }
  return (
    <Subheader className="subHeader-v1 ">
      {!print && <div className="flex-grow-1">
        <SubheaderForm title={`${t('order:order')}: #${order.orderNr}`} goBackLabel={t('backButton')} />
      </div>}
      {showPrintButton && <PdfButton
        variant="secondary"
        onClick={() => loadPdf()}
        isLoading={isLoading}
        buttonText={t('breadcrumb:orderSuccess')}
        className="subheader__button m-3"
      />}
      <div className="w-100">
        {downloadUrl &&
          <DownloadURLAlert
            downloadUrl={downloadUrl}
            setDownloadUrl={setDownloadUrl}
            fileName={t('breadcrumb:orderSuccess')}
          />}
        {error && <Alert variant="danger">{error}</Alert>}
      </div>

    </Subheader>
  )
}

export default SubheaderOrdersDetail