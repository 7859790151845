import React from 'react'

export const CheckIcon = ({ color }) => {
  return (
    <svg width="15px" height="15px" viewBox="0 0 56.975 43.63">
        <g fill={color} transform="translate(212.94 -21.941)">
        <path d="m-156.27 27.048-4.6567-4.789c-0.18521-0.21167-0.47625-0.3175-0.74083-0.3175-0.29104 0-0.55563 0.10583-0.74083 0.3175l-32.279 32.517-11.748-11.748c-0.21167-0.21167-0.47625-0.3175-0.74083-0.3175-0.26459 0-0.52917 0.10583-0.74084 0.3175l-4.7096 4.7096c-0.42333 0.42333-0.42333 1.0848 0 1.5081l14.817 14.817c0.9525 0.9525 2.1167 1.5081 3.0956 1.5081 1.4023 0 2.6194-1.0319 3.0692-1.4552h0.0265l35.375-35.56c0.37041-0.44979 0.37041-1.1112-0.0265-1.5081z" strokeWidth=".26458"/>
        </g>
    </svg>
  )
}
