
import { useObserver } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'
import useMountEffect from '../../utils/hooks/useMountEffect'

const StyledWrapper = styled.div`
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const StyledBubbleVariant = styled.div`
    line-height: 41px;
    margin: 0.2rem;
    padding: 0 10px;
    border-radius: 10px;
    border: 1px solid #9c9c9c;
    min-width: 3rem;
    text-align: center;
    transition: 0.1s ease-in-out;
    font-weight: 600;

    &:hover {
    cursor: pointer;
    outline: 2px solid;
    box-shadow: 2px 2px 3px 3px gray;
    }
    
    &.activeBubble {
        outline: 2px solid;
        box-shadow: 2px 2px 3px 3px gray;
    }



    &.isNotAvailable {
        position: relative;
        opacity: 0.5;
        background-color: #eff1f3;
    }

    &.isNotAvailable:before,
    &.isNotAvailable:after {
        position: absolute;
        width: 40px;
        left: calc(50% - 40px);
        top: 50%;
        height: 1px;
        content: '';
        background: gray;
    }

    &.isNotAvailable:before {
        transform: translate(50%) rotate(45deg);
    }

    &.isNotAvailable:after {
        transform: translate(50%) rotate(-45deg);
    }
`

const BubbleVariants = ({ currentProductObj, shoppingBasketArticleId, setSelectedVariant }) => {

    useMountEffect(() => {
        if (currentProductObj.variants.length === 1) {
            setSelectedVariant(currentProductObj.variants[0].id, shoppingBasketArticleId)
        }
    })

    return useObserver(() => (
        <StyledWrapper>
            {currentProductObj.variants.map(variant => (
                <StyledBubbleVariant
                    key={variant.id}
                    className={`${variant.variantStatus} ${variant.variantStatus === "isLimited" ? "text-warning" : variant.variantStatus === "isNotAvailable" ? "text-danger" : "text-success"}  ${currentProductObj.selectedVariantId === variant.id ? 'activeBubble' : ''}`}
                    onClick={() => setSelectedVariant(variant.id, shoppingBasketArticleId)}
                >
                    {variant.displayName}
                </StyledBubbleVariant>
            )
            )}
        </StyledWrapper>
    ))
}

export default BubbleVariants