export function availableAsBillingAddress (addressType) {
  if (!addressType) {
    return true
  }

  return !!addressType.match(/^billing|both$/)
}

export function availableAsShipmentAddress (addressType) {
  if (!addressType) {
    return true
  }

  return !!addressType.match(/^shipment|both$/)
}
