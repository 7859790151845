import React, { useState, useEffect, useRef } from 'react'
import { GoBackBtnEasy } from '../../Buttons/GoBackBtnEasy'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'

import { getEmployee } from '../../../utils/employeeLoader'


const StyledSubheaderDescription = styled.div`
  padding-left: 10px;
`

const StyledFlexWrapper = styled.div`
display: flex;
flex-wrap: wrap;
align-items: center;
`

const SubheaderForm = ({ title, goBackLabel }) => {
    const { employeeId } = useParams()
    const [employee, setEmployee] = useState()
    const mountedRef = useRef(true)

    useEffect(() => {
        employeeId
            ? getEmployee(employeeId).then(response => {
                if (!mountedRef.current || !response) { return null }
                setEmployee(response)
            })
            : setEmployee(null)

    }, [employeeId])
    //Cleanup Function
    useEffect(() => {
        return () => { mountedRef.current = false }
    }, [])

    let navigate = useNavigate()
    return (
        <StyledFlexWrapper>
            <GoBackBtnEasy color="white" onClick={() => navigate(-1)}>
                <span>{goBackLabel}</span>
            </GoBackBtnEasy>
            <StyledSubheaderDescription>
                <div className="subheader-title">
                    {title}
                </div>
                {employee && employee.firstName && <div className="subheader-title-small">{`${employee.firstName} ${employee.lastName}`}</div>}
            </StyledSubheaderDescription>
        </StyledFlexWrapper>
    )
}

export default SubheaderForm