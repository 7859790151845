import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import ImgZoom from './ImgZoom'
import Carousel from "react-multi-carousel";
import styled from 'styled-components'
import { colors } from '../../styles/colors'

const StyledCarusel = styled(Carousel)`
height:100%;
.react-multi-carousel-track{
    height:100%;
}
`
const StyledModal = styled(Modal)`
.modal-content{
    background:rgba(0,0,0,.5);
}
.modal-header{
    border:none;
    background:rgba(0,0,0,.6);
}
.modal-title{
    color:${colors.gray3};
}
`

const ProductGalleryModal = ({ handleClose, detailImages, startIndex = 0 }) => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const [zoom, setZoom] = useState(false)
    const sortedImages = () => {
        const sortedImages = []
        for (let index in detailImages) {
            let sortedImage = detailImages[(parseInt(index) + parseInt(startIndex)) % detailImages.length]
            sortedImages.push(sortedImage)
        }
        return sortedImages
    }
    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 1401 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1400, min: 991 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 990, min: 0 },
            items: 1,
        }
    };
    return (
        <StyledModal fullscreen show={true} onHide={handleClose}>
            <Modal.Header closeButton closeVariant="white" >
                <Modal.Title>{`${currentSlide + 1}/${detailImages.length}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0"  >
                <StyledCarusel
                    infinite={detailImages.length > 1}
                    responsive={responsive}
                    swipeable
                    draggable={false}
                    beforeChange={(nextSlide) => {
                        setCurrentSlide((nextSlide + 2) % detailImages.length)
                        setZoom(false)
                    }}
                >
                    {
                        sortedImages().map(img => {
                            return (
                                <ImgZoom key={img.url} img={img} zoom={zoom} setZoom={setZoom} />
                            )
                        })
                    }
                </StyledCarusel>
            </Modal.Body>
        </StyledModal >
    )
}

export default ProductGalleryModal
