import React, { useState } from 'react'

import styled from 'styled-components'
import Footer from '../components/Footer/Footer'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import useStores from '../stores/useStore'
import { t } from 'i18next'

const StyledDisplayFont = styled.div`
  max-width: 100%;
  color: #fff;
  font-size: 160px;
  font-weight: normal;
  text-shadow: 0 0 15px rgba(0,0,0,0.2);
   @media screen and (min-width: 600px) {
    font-size: 190px;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 170px);
  padding: 1rem;
  width: 100%;
  text-align: center;
`
const StyledMessage = styled.p`
  color: ${props => props.theme.primaryColor}
  margin-bottom: 3rem;
`

const Page404 = () => {
  const location = useLocation();
  const params = useParams()
  let { uiStore } = useStores()
  const navigate = useNavigate();
  useState(() => {
    console.log(location, params)
    if (!location.pathname.includes(uiStore.theme)) {
      navigate('/', { replace: true });
    }
  }, [])



  return (
    <React.Fragment>
      <StyledWrapper>
        <StyledDisplayFont>404</StyledDisplayFont>
        <p>{t('page404:pageNotFound')}</p>
        <StyledMessage>{t('page404:sorryMsg')}</StyledMessage>
        <div>
          <Link to='/' className='btn btn-primary'>{t('backToStartpage')}</Link>
        </div>
      </StyledWrapper>
      <Footer />
    </React.Fragment>
  )

}

export default Page404
