import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Alert } from 'react-bootstrap'
import styled from 'styled-components'
import AppointmentTypesSelect from './AppointmentTypesSelect'
import useCabinTypesState from '../../../stores/Configuration/useCabinTypesState'

const StyledButton = styled(Button)`
margin-top:1.5rem;
margin-bottom:1.5rem;
  @media screen and (max-width: 767px) {
      width:100%;
`
const AssignedAppointmentTypesForm = () => {
    const { t } = useTranslation()
    const { handleSubmit, error, success } = useCabinTypesState()


    return (
        <>
            <AppointmentTypesSelect />
            <StyledButton className="btn-extra-padding" onClick={() => handleSubmit("assignedAppointmentTypes")}>{t('saveButton')}</StyledButton>
            {error &&
                <Alert variant="danger" >
                    {error}
                </Alert>
            }
            {success &&
                <Alert variant="success" className="my-2">
                    {success}
                </Alert>
            }
        </>
    )
}

export default AssignedAppointmentTypesForm