import React, { useEffect } from 'react'
import styled, { withTheme } from 'styled-components'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Trans, withTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'

import { colors } from '../../styles/colors'
import { CategoryIconDefault, CategoryIcons } from '../Icons/CategoryIcons/CategoryIcons'
import SafelySetInnerHTML from '../SafelySetInnerHTML'

const StyledMainWrapper = styled.div`
  color: ${colors.gray4};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;

  &:hover {
    color: ${colors.gray4};
  }
`

const StyledProductCardWrapper = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  height: 230px;
  overflow: hidden;
  position: relative;
  width: 100%;
`

const StyledProductCardImgWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  &.placeholder-img {
    svg {
      width: 100%;
      height: 100%;
      opacity: 0.3;
    }
  }
`

const StyledProductCardImg = styled.img`
  max-width: 185px;
  max-height: 150px;
`

const StyledMaxWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #FFF;
  border-top-left-radius: 4px;
  padding: 8px 12px;
  font-weight: 600;
`

const StyledMaxLabel = styled.div`
  font-size: 11px;
  color: ${colors.gray3};
`

const StyledMaxCounter = styled.div`
  font-size: 14px;
  color: ${colors.gray4};
`

const StyledDescription = styled.div`
  padding: 12px 12px 0px 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .is-inactive & {
    opacity: 0.3;
  }
`

const StyledArticleNumberSpan = styled.span`
  font-size: 12px;
  color: ${colors.gray3};
  text-transform: uppercase;
`

const StyledArticleCompare = styled.div`
  padding: 1px 1px;
  margin: 0;
  vertical-align: center;
  border-bottom: 1px solid ${colors.gray3};
  font-size: small;

  label {
    margin: 0px;
    padding-left: 5px;
  }
`

const StyledPrice = styled.div`
  color: ${props => props.theme.primaryColor};
  font-weight: bold;
`

const StyledCardBadge = styled.div`
  background-color: ${props => props.theme.primaryColor};
  color: #FFF;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top-right-radius: 4px;
  padding: 4px 6px;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
`

const StyledInactiveLayer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  text-transform: uppercase;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    opacity: 0.7;
  }
`

const StyledInactiveText = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledIMGDescription = styled.div`
  background-color: ${props => props.theme.primaryColor};
  color: #FFF;
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  font-size: 13px;
  letter-spacing: 0.4px;
  text-align: center;
`


const ProductCard = inject('teamOrderStore', 'productsCompareStore', 'uiStore', 'productDetailsStore')(observer((props) => {
  const { t, productsCompareStore, teamOrderStore, uiStore, theme, productDetailsStore } = props
  const CategoryIcon = CategoryIcons[props.iconType] ? CategoryIcons[props.iconType] : CategoryIconDefault

  useEffect(() => {
    if (productDetailsStore.scrollRef === props.id) {
      let element = document.querySelector(`[data-id="${props.id}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      productDetailsStore.setScrollRef(null)
    }
  }, [productDetailsStore, props.id])

  return (
    <StyledMainWrapper data-id={props.id}>
      <Link
        to={props.to ? props.to : '#'}
        className={`${props.className} ${props.isInactive ? 'is-inactive' : ''} `}
        onClick={props.onClick}
      >
        <StyledProductCardWrapper className="no-border-radius">

          {props.badge &&
            <StyledCardBadge>
              {props.badge}
            </StyledCardBadge>
          }

          {props.image && props.image.description &&
            <StyledIMGDescription>
              <SafelySetInnerHTML className="d-flex justify-content-center">
                {props.image.description}
              </SafelySetInnerHTML>
            </StyledIMGDescription>}

          <StyledProductCardImgWrapper className={!props.image ? 'placeholder-img' : ''}>
            {props.image && props.image.url
              ? <StyledProductCardImg src={props.image.url} />
              : <CategoryIcon color={theme.primaryColor} className={props.iconType} />
            }
            {props.isInactive ? (
              <StyledInactiveLayer><StyledInactiveText
                className='text-danger'>{typeof props.isInactive === 'object' ? props.isInactive :
                  <Trans i18nKey='productsOverview:maxAmountReached' />}</StyledInactiveText></StyledInactiveLayer>
            ) : ''}
          </StyledProductCardImgWrapper>
          <StyledMaxWrapper className='d-flex flex-column align-items-end'>
            {props.max &&
              <>
                <StyledMaxLabel
                  className='text-uppercase'>{t('maxAmount')}
                </StyledMaxLabel>
                <StyledMaxCounter className={props.isAmountMaxedOut ? 'text-danger' : ''}>
                  {props.max}
                </StyledMaxCounter>
              </>
            }
            {(props.current || props.amountToOrder || props.current === 0) ?
              <>
                <StyledMaxLabel
                  className='text-uppercase'>{t('currentAmount')}
                </StyledMaxLabel>
                <StyledMaxCounter className={props.isAmountMaxedOut ? 'text-danger' : ''}>
                  {props.current || props.current === 0 ? props.current : ''}
                  <span className='text-primary'>{props.amountToOrder ? `(+${props.amountToOrder})` : ''}</span>
                </StyledMaxCounter>
              </>
              : ''}
          </StyledMaxWrapper>
        </StyledProductCardWrapper>

        <StyledDescription>
          <div>{props.name}</div>
          {uiStore.showArticleNumberInOverview && <StyledArticleNumberSpan>{t('productDetail:articleNumber')} {props.articleNumber}</StyledArticleNumberSpan>}
          <div className='d-flex justify-content-between'>
            <small>{props.subDescription}</small>
            {!props.shouldHidePricesAndPoints && <StyledPrice>{props.price}</StyledPrice>}
          </div>
        </StyledDescription>
      </Link>

      {theme.enableCostCenterProductsComparison && teamOrderStore.selectedCostcenterId ? <StyledArticleCompare>
        <div>
          <Form.Check
            name="articleCompare"
            id={props.id}
            checked={productsCompareStore.articles.some(art => art.id === props.id)}
            disabled={productsCompareStore.articles.length >= 5 && !productsCompareStore.articles.some(art => art.id === props.id)}
            onChange={() => productsCompareStore.onArticleCompareHandler(props.id, props.to, props.price)}
            label={t('articleComparison:compareArticle')}
          />

        </div>
      </StyledArticleCompare> : null}
    </StyledMainWrapper>
  )
}))

export default withTheme(withTranslation()(ProductCard))