import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Badge from 'react-bootstrap/Badge'
import { inject, observer } from 'mobx-react'
import { Alert, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FollowerArticle from './FollowerArticle'
import { withTranslation } from 'react-i18next'
import { ArrowBigIcon } from '../Icons/ArrowBigIcon'
import getThemeValue from '../../utils/getThemeValue'
import PredecessorArticle from './PredecessorArticle'
import PersonalBuyBox from './PersonalBuyBox'
import CostCenterBuyBox from './CostCenterBuyBox'
import { useLocation } from 'react-router-dom'

const StyledBuyBoxContainer = styled.div`
  position: relative;

  @media screen and (min-width: 993px) {
    padding-left: 20px;
    padding-right: 20px;
  }

`
const StyledArticleNumberSpan = styled.span`
  font-size: 12px;
  color: ${colors.gray3};
  text-transform: uppercase;
`
const StyledArticleNameHeadline = styled.h3`
  margin: 0 0 30px 0;
`
const StyledBadge = styled(Badge)`
  border-radius: 4px 0 4px 0;
  left: 0;
  padding: 10px 15px;
  /* position: absolute; */
  top: 20px;

  @media screen and (min-width: 993px) {
    left: 20px;
  }
`

const StyledPriceContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 15px;
`

const StyledPriceTag = styled.span`
  color: ${props => props.theme.primaryColor};
  display: block;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
`
const StyledPriceInfo = styled.span`
  font-size: 14px;
  display: inline-block;
  margin-left: 40px;
`

const ProductBuyBox = inject('productsStore', 'teamOrderStore', 'profileStore', 'productDetailsStore')(observer((props) => {
  const { t, productsStore, teamOrderStore, profileStore, productDetailsStore } = props
  const shoppingBasketArticle = productDetailsStore.shoppingBasketArticle
  const { articleNumber, displayName } = productDetailsStore.article
  const articleBadge = productDetailsStore.getProductBadge(shoppingBasketArticle)
  const [isLoading, setIsLoading] = useState(true)

  const location = useLocation()
  const getLinkToProductsOverview = () => {
    let processType = teamOrderStore.getCurrentProcessType()
    switch(processType)
    {
      case 'storeHouse':
        return `/storehouse/${teamOrderStore.selectedStorehouseId}/${teamOrderStore.selectedCostcenterId}/products`
      case 'costCenter':
        return `/costcenter/${teamOrderStore.selectedCostcenterId}/costcenterproducts`
      case 'private':
        return `/products/private`
      case 'personal':
      default:
        return productsStore.targetEmployeeId === profileStore.profile.id ? `/products/personal` : `/products/personal/${productsStore.targetEmployeeId}`
    }
  }
  useEffect(() => {
    (async function () {
      await productDetailsStore.loadArticlePrices()
      await productDetailsStore.loadOrderableVariants(location?.state?.presetVariant)
      await productDetailsStore.loadLocalStorehouses()
      setIsLoading(false)
    })()
  }, [location.state, productDetailsStore])

  return (
    <StyledBuyBoxContainer>
      {articleBadge && <StyledBadge variant='primary'>{articleBadge}</StyledBadge>}
      <StyledArticleNameHeadline className="h1">{displayName}</StyledArticleNameHeadline>
      <StyledArticleNumberSpan>{t('productDetail:articleNumber')} {articleNumber}</StyledArticleNumberSpan>
      <StyledPriceContainer>
        {productDetailsStore.price ?
          <React.Fragment>
            <StyledPriceTag>
              {productDetailsStore.differentPrices && !productDetailsStore.selectedVariantId ? t('productDetail:priceStartsWith') : ''} {productDetailsStore.price}
            </StyledPriceTag>
            {!getThemeValue('removeShippingCostInfos', null, false) && <StyledPriceInfo>{t('productDetail:ifNecessaryPlusShipping')}</StyledPriceInfo>}
          </React.Fragment>
          : ''}
      </StyledPriceContainer>
      <hr />
      {isLoading
        ? <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
        : teamOrderStore.selectedCostcenterId ? <CostCenterBuyBox /> : <PersonalBuyBox />}
      {productDetailsStore.addToBasketErrors.length ? (
        <Row>
          <Col>
            {productDetailsStore.addToBasketErrors.map((error, errorIndex) =>
              <Alert key={errorIndex} variant='danger' className='mt-3 mb-3'>
                {error.msg}
              </Alert>
            )}
          </Col>
        </Row>
      ) : ''}

      {productDetailsStore.addToBasketSuccess ? (
        <Row>
          <Col> <Alert variant='success' className='mt-3 mb-3' data-id='add-to-basket-success'>
            {t('productDetail:productAddedSuccesfullyToBasket')}
            <div className='mt-3 d-flex justify-content-end'>
              <Link
                to={getLinkToProductsOverview()}
                className="btn btn-primary d-flex align-items-center">
                <ArrowBigIcon variant="left" color="#FFF" /> {t('productDetail:backToProductsOverview')}
              </Link>
            </div>
          </Alert></Col>
        </Row>
      ) : ''}
      <FollowerArticle />
      <PredecessorArticle />
    </StyledBuyBoxContainer>
  )
}))

export default withTranslation()(ProductBuyBox)
