import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Subheader } from '../Page/logix2020/Subheader'
import SubheaderForm from '../Page/logix2020/SubheaderForm'
import { useTranslation } from 'react-i18next'
import useStores from '../../stores/useStore'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { PdfButton } from '../Buttons/PdfButton'
import { Alert } from 'react-bootstrap'
import { useObserver } from 'mobx-react'
import DownloadURLAlert from '../Forms/DownloadURLAlert'

const SubheaderInventoryHistory = () => {
  const foreignEmployeeId = useParams().employeeId
  const { t } = useTranslation()
  const { profileStore } = useStores()
  const [error, setError] = useState('')
  const [downloadUrl, setDownloadUrl] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const employeeId = useObserver(() => foreignEmployeeId || profileStore.profile.id)

  const loadPdf = () => {
    setError('')
    setIsLoading(true)
    setDownloadUrl('')
    apiClient.postJson(APIEndpoints.contingenthistory(foreignEmployeeId || employeeId), {}, true, true)
      .then(response => {
        const newWindowRef = window.open(response.headers.location)
        if (!newWindowRef) {
          setDownloadUrl(response.headers.location)
        }
      }).catch(() => {
        setError(t('export:createError'));
      }).finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Subheader className="subHeader-v1 ">
      <div className="flex-grow-1">
        <SubheaderForm
          title={t("inventoryHistory:inventoryHistory")}
          goBackLabel={t("backButton")}
        />
      </div>
      {downloadUrl &&
        <DownloadURLAlert
          downloadUrl={downloadUrl}
          setDownloadUrl={setDownloadUrl}
          fileName={t("inventoryHistory:inventoryHistory")}
        />
      }
      {error && <Alert variant="danger">{error}</Alert>}
      {employeeId && (
        <PdfButton
          variant="secondary"
          onClick={() => loadPdf()}
          isLoading={isLoading}
          buttonText={t("inventoryHistory:inventoryHistory")}
          className="subheader__button "
        />
      )}
    </Subheader>
  );
}

export default SubheaderInventoryHistory
