import React from 'react'

export const CalendarIcon = (props) => {
  const color = props.color ? props.color : '#3a3a38'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 28 28">
        <path fill={color} d="M2.2 4C2.6 5.2 3.7 6 5 6s2.4-.8 2.8-2h.3c.5 1.2 1.6 2 2.9 2s2.4-.8 2.8-2h.3c.4 1.2 1.5 2 2.8 2 1.3 0 2.4-.8 2.8-2h.3c.4 1.2 1.5 2 2.8 2s2.4-.8 2.8-2h.4c1.1 0 2 .9 2 2v20c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h.2zM26 8H2v18h24V8zM6 22c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zM6 18c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zM6 14c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zM6 10c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm4 0c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zM5 0c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1s-1-.4-1-1V1c0-.6.4-1 1-1zm6 0c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1s-1-.4-1-1V1c0-.6.4-1 1-1zm6 0c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1s-1-.4-1-1V1c0-.6.4-1 1-1zm6 0c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1s-1-.4-1-1V1c0-.6.4-1 1-1z" fillRule="evenodd" clipRule="evenodd"/>
    </svg>
  )
}





