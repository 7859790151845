import React, { useMemo, useRef } from 'react'
import { StyledListItemText } from '../../TreeList/treeList'
import { useDNDTree } from '../../../utils/hooks/useDNDTree';
import { StyledItemWrapperDND, renderTemplateArrow, ArticleIcon, renderGroupArrow } from '../../TreeList/dndTreeList'
import MediaQuery from 'react-responsive';
import MoreOptionsButton from '../../Buttons/MoreOptionsButton';
import { StyledContextMenu } from '../../ContextMenu/StyledContextMenu';
import { ContextMenuTrigger } from 'react-contextmenu';
import PortalContextMenu from '../../ContextMenu/PortalContextMenu';
import { PenIcon } from '../../Icons/PenIcon';
import { t } from 'i18next';
import { useNavigate } from 'react-router';
import { getLanguageValue } from '../../../utils/getLanguageValue';
import { DeleteIcon } from '../../Icons/DeleteIcon';
import { MdLibraryAdd } from 'react-icons/md';
import { emptyGuid } from '../../../utils/emptyGuid';
import { useEffect } from 'react';
import { BsDiagram3Fill } from 'react-icons/bs';
import { ExcelIcon } from '../../Icons/ExcelIcon';




const CatalogItem = ({ arrayItem, index, moveCardHandler, changeOpenProp, deleteDimension, createDimension, editDimension, deleteArticle, deleteCatalog, getArticleAssignments, assignmentTree, deleteAssignments, allowCatalogPrint, createReport }) => {
  const navigate = useNavigate()

  const isCatalog = arrayItem.type === "catalog"
  const isDimension = arrayItem.type === "dimension"
  const isArticle = arrayItem.type === "article"
  const isAssigned = (isArticle && arrayItem.elementId !== emptyGuid
    && assignmentTree[arrayItem.catalogId]
    && assignmentTree[arrayItem.catalogId].dimensions
    && assignmentTree[arrayItem.catalogId].dimensions[arrayItem.elementId]
    && assignmentTree[arrayItem.catalogId].dimensions[arrayItem.elementId].articles
    && assignmentTree[arrayItem.catalogId].dimensions[arrayItem.elementId].articles[arrayItem.article.id])
    || (
      isArticle && arrayItem.elementId === emptyGuid
      && assignmentTree[arrayItem.catalogId]
      && assignmentTree[arrayItem.catalogId].articles
      && assignmentTree[arrayItem.catalogId].articles[arrayItem.article.id]
    )
    || (isDimension && assignmentTree[arrayItem.catalogId]
      && assignmentTree[arrayItem.catalogId].dimensions
      && assignmentTree[arrayItem.catalogId].dimensions[arrayItem.id])
  const canDrop = (item) => {
    //allow dnd inside a dimension or catalog to update orderSequence
    let sameType = !!item && !isArticle && arrayItem.type === item.type && arrayItem.parentId === item.parentId && arrayItem.catalogId === item.catalogId
    //allow article into dimension and catalog. but not article from dimension X into dimension X or article from catalog Y into catalog Y
    let articleInsert = !!item && !isArticle && item.type === "article" && arrayItem.id !== item.elementId && arrayItem.id && !(isCatalog && item.elementId === emptyGuid && arrayItem.id === item.catalogId)
    let multipleArticlesInsert = !!item && !isArticle && item.type === "multipleArticles"
    if (sameType || articleInsert || multipleArticlesInsert) {
      return true
    }
    return false
  }
  const ref = useRef(null)
  const { isOver, isDragging, drag, drop, item } = useDNDTree({ index, ...arrayItem }, moveCardHandler, canDrop)
  const opacity = isDragging ? "0.2" : "1";
  drag(drop(ref))
  //deleteStructure
  const options = [
    !isArticle ? {
      option: 'add',
      optionText: t('catalogsAdministration:addCategory'),
      optionIcon: <MdLibraryAdd />,

      onOptionSelect: () => isDimension ? createDimension({ catalogId: arrayItem.catalogId, parentId: arrayItem.id }) : createDimension({ catalogId: arrayItem.id, parentId: emptyGuid })
    } : null,
    !isArticle ? {
      option: 'edit',
      optionText: t('edit'),
      optionIcon: <PenIcon />,
      onOptionSelect: () => isCatalog ? editCatalog() : isDimension ? editDimension({ catalogId: arrayItem.catalogId, elementId: arrayItem.id, parentId: arrayItem.parentId }) : null
    } : null,
    {
      option: 'delete',
      optionText: t('deleteButton'),
      optionIcon: <DeleteIcon />,
      onOptionSelect: () => isCatalog
        ? deleteCatalog({ catalogId: arrayItem.id })
        : isDimension
          ? deleteDimension({ catalogId: arrayItem.catalogId, elementId: arrayItem.id, parentId: arrayItem.parentId })
          : deleteArticle({ catalogId: arrayItem.catalogId, elementId: arrayItem.elementId, articleId: arrayItem.id })
    },
    (isArticle && !isAssigned) ? {
      option: 'assignments',
      optionText: t('contingentTemplates:articleAssignments'),
      optionIcon: <BsDiagram3Fill />,
      onOptionSelect: () => getArticleAssignments(arrayItem.article.id)
    } : null,
    isAssigned ?
      {
        option: '!assignments',
        optionText: <del>{t('contingentTemplates:articleAssignments')}</del>,
        optionIcon: <BsDiagram3Fill />,
        onOptionSelect: () => deleteAssignments({})
      } : null,
    isCatalog && allowCatalogPrint ?
      {
        option: 'barcodes',
        optionText: t('barcodeList'),
        optionIcon: <ExcelIcon height="16px" width="16px" />,
        onOptionSelect: () => createReport(arrayItem.id)
      } : null,
  ]

  const editCatalog = () => {
    let path = `/catalogsAdministration/editCatalog/${arrayItem.id}#base`
    navigate(path)
  }
  const itemName = useMemo(() => {
    if (isCatalog) {
      return arrayItem.displayName
    }
    if (isDimension) {
      return getLanguageValue(arrayItem.name.values)
    }
    return <span>{arrayItem.article.displayName} <b>({arrayItem.article.articleNumber})</b></span>
  }, [arrayItem, isCatalog, isDimension])

  //open arrayItem automatically if drag mouse isOver
  useEffect(() => {
    let timerRef = null
    const handleStateChange = () => {
      timerRef = setTimeout(() => {
        changeOpenProp(arrayItem)
      }, 1000);
    };
    isOver && !timerRef && !isArticle && (item.type === "article" || item.type === "multipleArticles") && handleStateChange();
    return () => {
      clearTimeout(timerRef);
    };
  }, [isOver, changeOpenProp, arrayItem, isArticle, item]);

  let sameType = !!item && !isArticle && arrayItem.type === item.type && arrayItem.parentId === item.parentId && arrayItem.catalogId === item.catalogId
  let articleInsert = !!item && !isArticle && item.type === "article" && arrayItem.id !== item.elementId && arrayItem.id && !(isCatalog && item.elementId === emptyGuid && arrayItem.id === item.catalogId)
  let multipleArticlesInsert = !!item && !isArticle && item.type === "multipleArticles"

  return (
    <StyledContextMenu>
      <ContextMenuTrigger id={`${arrayItem.id}_${arrayItem.entitlementId}`} holdToDisplay={-1}>
        <StyledItemWrapperDND
          ref={ref}
          id={`${index}_id`}
          style={{ opacity }}
          articleOverGroup={isOver && (articleInsert || multipleArticlesInsert)}
          showTopLine={isOver && sameType && item.index > index}
          showBottomLine={isOver && sameType && item.index < index}
          hierarchyLevel={arrayItem.level || 0}
          assigned={isAssigned}
          isClickable
        >
          {isCatalog ? renderTemplateArrow(arrayItem.open, arrayItem.dimensionsLoaded && arrayItem.articlesLoaded, () => changeOpenProp(arrayItem))
            : isDimension ? renderGroupArrow(arrayItem.open, arrayItem.dimensionsLoaded && arrayItem.articlesLoaded, arrayItem.hasChildren || !!Object.keys(arrayItem.articles).length, () => changeOpenProp(arrayItem))
              : <ArticleIcon />}
          <StyledListItemText title={isArticle ? `${arrayItem.article.displayName} (${arrayItem.article.articleNumber})` : null}>
            {itemName}
          </StyledListItemText>
          <MediaQuery maxWidth={1499}>
            <div className="action-buttons">
              <MoreOptionsButton options={options} />
            </div>
          </MediaQuery>
        </StyledItemWrapperDND>
      </ContextMenuTrigger>
      <PortalContextMenu contextId={`${arrayItem.id}_${arrayItem.entitlementId}`} options={options} />
    </StyledContextMenu>
  )
}

export default CatalogItem