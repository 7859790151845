import { t } from 'i18next'
import { cloneDeep } from 'lodash'
import React, { useState } from 'react'
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap'
import { emptyGuid } from '../../../utils/emptyGuid'
import { setValueByKey } from '../../../utils/utils'
import { ButtonWithLoadingState } from '../../Buttons'
import LanguageForm from '../../Forms/LanguageForm'
import LanguageNameInput from '../../Forms/LanguageNameInput'
import OverlayContainer from '../../Page/logix2020/OverlayContainer'
import OverlayRight from '../../Page/logix2020/OverlayRight'
import InformationPopover from '../../InformationPopover/InformationPopover'
import { useCatalogsState } from '../../../stores/Configuration/CatalogsProvider'
import useMountEffect from '../../../utils/hooks/useMountEffect'
const groupTemplateObj = {
  name: {
    values: []
  },
  id: emptyGuid,
  foreignKey: ""
}
const StructureModal = ({ handleClose, catalogId, elementId, parentId }) => {
  const { loadingStates, resetStates, patchDimension, postDimension, bigData, errorStates } = useCatalogsState()
  const [overlayVisible, setOverlayVisible] = useState(false)
  const [dimension, setDimension] = useState(null)

  useMountEffect(() => {
    resetStates()
    if (!!elementId) {
      setDimension(bigData[catalogId].dimensions[elementId])
    }
    else {
      setDimension(cloneDeep(groupTemplateObj))
    }
  })

  const updateNames = (translations) => {
    setDimension(prev => ({
      ...prev,
      name: {
        ...prev.name,
        values: translations
      }
    }))
  }
  const updateItem = e => {
    let key = e.target.name
    let value = e.target.value
    setDimension(prev => (setValueByKey(key, value, prev)))
  }
  const handleCloseOverlay = () => {
    setOverlayVisible(false)
  }
  const handleSave = () => {
    let params = { ...dimension, parentId }
    !!elementId
      ? patchDimension(catalogId, elementId, parentId, params, handleClose)
      : postDimension(catalogId, parentId, params, handleClose)
  }

  return (
    <div>
      <Modal show={true} size="lg" onHide={handleClose} enforceFocus={false}>
        <Modal.Header closeButton>
          <Modal.Title>{!!elementId ? t('catalogsAdministration:editCategory') : t('catalogsAdministration:addCategory')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(loadingStates.getGroup || !dimension)
            ? <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
            : <Form>
              <LanguageNameInput
                openLanguageModal={() => setOverlayVisible(true)}
                languageArray={dimension.name.values}
                onChange={updateNames}
                label={t('name')}
                isInvalid={errorStates?.saveDimension?.formErrors?.name}
              />
              <Form.Group className="mb-3">
                <Form.Label>{t('costCenterAdmin:foreignKey')}<InformationPopover header={t('costCenterAdmin:foreignKey')} content={t('officeAdmin:noteForeignKey')} /></Form.Label>
                <Form.Control name="foreignKey" placeholder={t('costCenterAdmin:foreignKey')} value={dimension.foreignKey} onChange={updateItem} disabled={!!elementId} />
              </Form.Group>
            </Form>}
          {errorStates.saveDimension && <Alert variant="danger">{errorStates.saveDimension.value}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('cancelButton')}
          </Button>
          <ButtonWithLoadingState
            disabled={false}
            buttonText={t('saveButton')}
            isLoading={loadingStates.saveDimension}
            onClick={handleSave}
          />
        </Modal.Footer>
      </Modal>
      <OverlayContainer>
        <OverlayRight visible={overlayVisible} overLayZIndex={1056} onClose={handleCloseOverlay}>
          <LanguageForm
            languageTitle={t('name')}
            handleCloseOverlay={handleCloseOverlay}
            languageArray={dimension ? dimension.name.values : []}
            onChange={updateNames}
          />
        </OverlayRight>
      </OverlayContainer>
    </div>
  )
}

export default StructureModal