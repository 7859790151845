import React from 'react'
import { useEmployeesState } from '../../stores/EmployeesProvider'
import OfficesTree from '../Offices/OfficesTree'

const OfficeFilter = () => {
  const { setOfficeFilter, setOfficeName } = useEmployeesState()

  const handleClickOfficeName = office => {
    setOfficeName(office.displayName)
    setOfficeFilter(prev => (office.id === prev ? '' : office.id))
  }

  return (
    <OfficesTree onClick={handleClickOfficeName} isClickable={() => true} />
  )
}

export default OfficeFilter
