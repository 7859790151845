import { StyledCircleCheckIconWrapper } from './StyledCircleCheckIconWrapper'
import { CircleCheckIcon } from '../../../Icons/CircleCheckIcon'
import React, { useEffect, useState } from 'react'

const AddressesCheck = ({ label, addresses }) => {
  const [isValid, setIsValid] = useState(false)
  useEffect(() => {
    setIsValid(
      addresses.reduce((acc, item) => {
        return acc || (
          // (item.isPrivate ? true : !!item.officeName) &&
          !!item.careOf || !!item.street || !!item.streetNr || !!item.postOfficeBox
          || !!item.zipCode || !!item.city || !!item.countryIsoCode
        )
      }, false)
    )
  }, [addresses])

  return (
    <>
      <StyledCircleCheckIconWrapper>
        <CircleCheckIcon variant={isValid ? 'optional' : 'none'} />
      </StyledCircleCheckIconWrapper>
      <span>{label}</span>
    </>
  )
}

export default AddressesCheck
