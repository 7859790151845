import React from 'react'
import { StyledDocumentWrapper, StyledMessageBody } from './StyledChat';
import { FileTypeIcon } from '../../../utils/getFileTypeIcon';

const MessageBody = ({ message }) => {
  const formatMessageParts = (messageText) => {
    let messageParts = messageText.split('\n');
    return messageParts.map((msg, index) => {
      if (msg.toLowerCase().startsWith('grund')) {
        return <p key={index} className="mt-2">{msg.slice(7)}</p> // removes the 'Grund: ' part from the message
      } else {
        return <p key={index}>{msg}</p>
      }
    })
  }
  return (
    <StyledMessageBody>
      {formatMessageParts(message.text)}
      {message.documents.map(document => (
        <div className="d-flex" key={document.id}>
          <StyledDocumentWrapper href={document.url} download={document.name} filename={document.name}>
            <FileTypeIcon fileType={document.type} />
            <div >
              <div className='fw-bold'>{document.name || ''}</div>
              <div>
                <small className='text-uppercase'>{document.description || ''}</small>
              </div>
            </div>
          </StyledDocumentWrapper>
        </div>
      ))}
    </StyledMessageBody>
  )
}

export default MessageBody