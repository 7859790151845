import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Alert } from 'react-bootstrap'
import styled from 'styled-components'
import OverlayRight from '../../Page/logix2020/OverlayRight'
import OverlayContainer from '../../Page/logix2020/OverlayContainer'
import useCabinTypesState from '../../../stores/Configuration/useCabinTypesState'
import LanguageForm from "../../Forms/LanguageForm"
import LanguageNameInput from "../../Forms/LanguageNameInput"
import { Navigate } from 'react-router-dom'

const StyledDiv = styled.div`
  width:600px;
  .alert {
      margin-top:1.5rem;
  }
`

const CabinTypesGeneralForm = () => {
  const { t } = useTranslation()

  const [overlayVisible, setOverlayVisible] = useState(false)
  const { handleSubmit, error, formErrors, success, selectedCabinType, setSelectedCabinType, redirectId } = useCabinTypesState()

  const handleCloseOverlay = () => {
    setOverlayVisible(false)
  }

  const updateNames = (translations) => {
    setSelectedCabinType(prev => ({
      ...prev, name: {
        values: translations
      }
    }))
  }
  //redirect to edit page after new type was created

  if (redirectId) {
    return <Navigate
      to={`/cabinTypes/edit/${redirectId}#general`}
      replace
    />
  }

  return (
    <StyledDiv>
      <LanguageNameInput
        openLanguageModal={() => setOverlayVisible(true)}
        languageArray={(selectedCabinType.name && selectedCabinType.name.values) || []}
        onChange={updateNames}
        label={t('name')}
        isInvalid={formErrors && formErrors.name}
      />
      <Button onClick={() => handleSubmit("general")}>{t('saveButton')}</Button>
      <OverlayContainer>
        <OverlayRight visible={overlayVisible} onClose={handleCloseOverlay}>
          <LanguageForm
            languageTitle="Cabin"
            handleCloseOverlay={handleCloseOverlay}
            languageArray={(selectedCabinType.name && selectedCabinType.name.values) || []}
            onChange={updateNames} />
        </OverlayRight>
      </OverlayContainer>
      {success &&
        <Alert variant="success" className="my-2">
          {success}
        </Alert>
      }
      {error &&
        <Alert variant="danger">{error}</Alert>
      }
    </StyledDiv>
  )
}

export default CabinTypesGeneralForm
