import React, { useCallback, useEffect, useMemo, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { useTranslation } from 'react-i18next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import TableProvider, { useTableState } from '../Table/TableProvider'
import { useReportsJobsState } from '../../stores/Administration/ReportJobsProvider'
import styled, { keyframes } from 'styled-components'
import { EmailSendIcon } from '../Icons/EmailSendIcon'
import { formatedDate } from '../../utils/formatedDate'
import { ClockIcon } from '../Icons/ClockIcon'
import { SettingsIcon } from '../Icons/SettingsIcon'
import { ErrorDocumentIcon } from '../Icons/ErrorDocumentIcon'
import { DocumentIcon } from '../Icons/DocumentIcon'
import { DownloadIcon } from '../Icons/DownloadIcon'
import { DeleteIcon } from '../Icons/DeleteIcon'
import { EmailIcon } from '../Icons/EmailIcon'
import i18n from '../../translation/i18n'
import MediaQuery from 'react-responsive'
import StatusLegend from './StatusLegend'
import UniversalTableWrapperTest from '../Table/UniversalTableWrapperTest'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`


const StyledSettingsIcon = styled(SettingsIcon)`
  animation: ${rotate} 6s linear infinite;
`

const ReportJobStatusIcon = ({ type }) => {
  // types = 'isOpen', 'isStarted', 'hasError', 'isFinished', 'isSentByEmail', 'isPickedUp'
  const { t } = useTranslation()

  switch (type) {
    case 'isSentByEmail':
      return <div className="ps-2"><EmailSendIcon title={t('reports:isSentByEmail')} /></div>
    case 'isOpen':
      return <div className="ps-2"><ClockIcon title={t('reports:isOpen')} /></div>
    case 'isStarted':
      return <div className="ps-2"><StyledSettingsIcon title={t('reports:isStarted')} /></div>
    case 'hasError':
      return <div className="ps-2"><ErrorDocumentIcon title={t('reports:hasError')} /></div>
    case 'isFinished':
      return <div className="ps-2"><DocumentIcon title={t('reports:isFinished')} /></div>
    case 'isPickedUp':
      return <div className="ps-2"><DownloadIcon title={t('reports:isPickedUp')} /></div>
    default:
      return <>{type}</>
  }
}

const columns = [
  {
    dataField: 'status',
    text: 'reports:statusHeader',
    sort: true,
    formatter: (cell) => {
      return <ReportJobStatusIcon type={cell} />
    }
  },
  {
    dataField: 'name',
    text: 'name',
    sort: true,
  },
  {
    dataField: 'type.displayName',
    text: 'reports:reportTypeHeader',
    sort: true,
  },
  {
    dataField: 'requestedDate',
    text: 'reports:requestedAtHeader',
    sort: true,
    formatter: (cell) => {
      return <span>{formatedDate(cell, true)}</span>
    }
  },
  /* {
     dataField: 'finishedDate',
     text: 'reports:doneAtHeader',
     sort: true,
     formatter: (cell) => {
       return <>{formatedDate(cell, true)}</>
     }
   },*/
  {
    dataField: '__actions',
    text: '',
    sort: false,
    headerClasses: 'actions-header-cell',
    formatter: (cell, row, rowIndex, extraProps) => {

      const allowedDownload = !!row.allowedActions.find(item => item === 'download') || false
      const allowedDelete = !!row.allowedActions.find(item => item === 'delete') || false
      const allowedReopen = !!row.allowedActions.find(item => item === 'reopen') || false
      return (
        <span>
          {allowedDelete && <DeleteIcon onClick={extraProps.handleClickDelete(row)} title={i18n.t('delete')} />}
          {allowedDownload && !!row.downloadUrl &&
            <DownloadIcon onClick={extraProps.handleClickReport(row)} title="download" />}
          {allowedReopen && <EmailIcon onClick={extraProps.handleClickReopen(row)} title={i18n.t('reports:reopen')} />}
        </span>
      )
    }

  }
]

const ReportsTableInner = () => {
  const { t } = useTranslation()
  const [reportJobs, setReportJobs] = useState(null)
  const [tableIsLoading, setTableIsLoading] = useState(true)
  const { tableRef, paginationOptions, pageLimit, setPageLimit, pageOffset, setPageOffset, setTotalCount, sort, setSort, currentPage, totalPages } = useTableState()
  const { filter } = useReportsJobsState()
  const fetchReports = useCallback(() => {
    setTableIsLoading(true)
    const params = { 'page.limit': pageLimit, 'page.offset': pageOffset, ...filter }
    if (sort) {
      params.sort = sort
    }
    apiClient.getJson(
      APIEndpoints.reportJobs(),
      params,
      true,
      true
    ).then(response => {
      setReportJobs(response.data)
      setTotalCount(Number(response.headers['x-total-count']))
    }).finally(() => {
      setTableIsLoading(false)
    })
  }, [filter, pageLimit, pageOffset, setTotalCount, sort])

  useEffect(() => {
    setPageOffset(0)
  }, [filter, setPageOffset])

  useEffect(() => {
    fetchReports(pageLimit, pageOffset, filter, sort)
  }, [pageLimit, pageOffset, filter, sort, fetchReports])

  const handleTableChange = (type, newState) => {
    setPageLimit(newState.sizePerPage)
    setPageOffset(newState.page === 1 ? 0 : newState.sizePerPage * newState.page - newState.sizePerPage)

    if (type === 'sort') {
      const { sortOrder, sortField } = newState;
      setSort(`${sortOrder === 'asc' ? '-' : ''}${sortField}`)
    }
  }

  const handleClickReport = useCallback((reportJob) => () => {
    window.open(reportJob.downloadUrl)
    //updating report row properties in the frontend because the status is not coming from the backend but from a reporting service
    setReportJobs(prev => {
      const reportIndex = prev.findIndex(obj => obj.id === reportJob.id)
      const newValues = [...prev];
      const newItem = Object.assign({}, reportJob);
      newItem.allowedActions = ["delete"]
      newItem.status = "isPickedUp"
      newValues.splice(reportIndex, 1, newItem)
      return newValues
    })
  }, [])

  const handleClickDelete = useCallback((reportJob) => () => {
    apiClient.deleteJson(APIEndpoints.reportJobs(reportJob.id).details).then(() => {
      fetchReports(pageLimit, pageOffset, filter, sort, true)
    })
  }, [fetchReports, pageLimit, pageOffset, filter, sort])

  const handleClickReopen = useCallback((reportJob) => () => {
    apiClient.postJson(APIEndpoints.openReportJobs, { id: reportJob.id }, true, true)
      .then(response => {
        if (response.headers.location) {
          window.open(response.headers.location)
        }
      })
      .finally(() => {
        fetchReports(pageLimit, pageOffset, filter, sort, true)
      })
  }, [fetchReports, pageLimit, pageOffset, filter, sort])

  const preparedColumns = useMemo(() => {
    const tmpColumns = [...columns]
    tmpColumns.forEach(tmpColumn => {
      tmpColumn.text = t(tmpColumn.text)
    })

    tmpColumns.find(col => col.dataField === '__actions').formatExtraData = {
      handleClickReport,
      handleClickDelete,
      handleClickReopen
    }
    return tmpColumns
  }, [handleClickReport, handleClickDelete, handleClickReopen, t])

  return (
    <UniversalTableWrapperTest tableIsLoading={tableIsLoading} currentPage={currentPage} totalPages={totalPages}>

      <BootstrapTable
        remote
        ref={tableRef}
        loading={tableIsLoading}
        noDataIndication={() => <div className="no-data-placeholder">{t('reports:noReportsAvailable')}</div>}
        onTableChange={handleTableChange}
        bootstrap4
        keyField="id"
        hover
        data={reportJobs || []}
        columns={preparedColumns}
        pagination={paginationFactory(paginationOptions)}
        bordered={false}
      />
      <MediaQuery maxWidth={427}>
        <StatusLegend />
      </MediaQuery>
    </UniversalTableWrapperTest>


  )
}

const ReportsTable = () => {
  return (
    <TableProvider>
      <ReportsTableInner />
    </TableProvider>
  )
}

export default ReportsTable;
