import React from 'react'
import { Accordion } from 'react-bootstrap'


const ContingentListAccordion = (props) => {
  const { contingents, handleClickScrollToGroup, onSwitchContingentHandler, selectedId } = props

  if (!contingents.length) {
    return null
  }

  return (
    <Accordion className="mb-3" activeKey={selectedId}>
      {contingents.map((contingent) => (
        <Accordion.Item
          key={contingent.id}
          eventKey={contingent.id}
          onClick={() => onSwitchContingentHandler(contingent.id)}
        >
          <Accordion.Header>{contingent.displayName}</Accordion.Header>
          <Accordion.Body>
            {contingent.categories.map(category => (
              <p
                key={category.id}
                role='button'
                onClick={() => handleClickScrollToGroup(category.id)}
              >
                {category.displayName}
              </p>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  )
}

export default ContingentListAccordion