import React, { useCallback, useEffect, useState } from 'react'
import { Form, Collapse, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { formatedDate } from '../../../utils/formatedDate'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { useTranslation } from 'react-i18next'
import { ArrowBigIcon } from '../../Icons/ArrowBigIcon'
import getThemeValue from '../../../utils/getThemeValue'
import SignatureModal from './SignatureModal'

const StyledCustomCheckboxAll = styled.div`
  .form-check {
    display: flex;
    flex-direction: row-reverse;
  }
  .form-check-label {
    margin-right: 2rem;
  }
`

const StyledStatusWrapper = styled.div`
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom:1rem;
`

const StyledOl = styled.ol`
  li {
    margin-bottom: 0.25rem;
  }
`

const PositionsListHeader = (props) => {
  const { incomingOrder, orderInfo, index, changeAllIsChecked, changeAll, showChangeAllCheckbox, allowOrderWasPickedUp, resetOrderPage } = props
  const [infoTat, setInfoTat] = useState([])
  const [openSignatureModal, setOpenSignatureModal] = useState(false)
  const { t } = useTranslation()
  const [opened, setOpened] = useState(false)
  const isCanceled = incomingOrder.isCanceled

  const mandate = process.env.REACT_APP_MANDATORSET
  const transmittedAtSRMText = mandate === 'sixt' ? t('orders:openApprovalsCOUPAOrders') : t('orders:openApprovalsSRMOrders')

  const getInfoTAT = useCallback(async (orderInfo) => {
    let newInfoTAT = await apiClient.getJson(APIEndpoints.infoTAT(orderInfo.id))
    setInfoTat(newInfoTAT)
  }, [])

  useEffect(() => {
    if (orderInfo && orderInfo.id && orderInfo.hasTATInfo) {
      getInfoTAT(orderInfo)
    }
  }, [getInfoTAT, orderInfo])

  const getCanceledText = (canceledAt) => {
    let isCanceledText = ''
    if (isCanceled) {
      if (incomingOrder.canceledByDisplayName === '[OrderInCancelation]') {
        isCanceledText = `${t('order:isCanceled')} - ${t('order:isSystemCancelation')} (${canceledAt ? canceledAt : ''})`
      } else {
        isCanceledText = `${t('order:isCanceled')} - ${incomingOrder.canceledByDisplayName} (${canceledAt ? canceledAt : ''})`
      }
    }
    return isCanceledText
  }

  const renderTitleAndStatusText = () => {
    const shipmentTypeDisplayName = orderInfo && orderInfo.shipmentType ?
      orderInfo.shipmentType.displayName :
      incomingOrder.shipmentType.displayName

    const title = `${t('order:shipment')} ${(orderInfo && orderInfo.shippingOrderNumber) || ''} (${shipmentTypeDisplayName})`
    const createdAt = formatedDate(incomingOrder.createdAt, true)
    const approvalRequestedAt = formatedDate(incomingOrder.approvalRequestedAt, true)
    const approvalReleasedAt = formatedDate(incomingOrder.approvalReleasedAt, true)
    const waitingTransmissionAtSRM = formatedDate(incomingOrder.waitingTransmissionAtSRM, true)
    const transmittedAtSRM = formatedDate(incomingOrder.transmittedAtSRM, true)
    const canceledAt = formatedDate(incomingOrder.canceledAt, true)
    const activatedAt = formatedDate(incomingOrder.activatedAt, true)
    const pickingApprovedAt = orderInfo && formatedDate(orderInfo.pickingApprovedAt, true)
    const pickingStartedAt = orderInfo && formatedDate(orderInfo.pickingStartedAt, true)
    const pickingCompletedAt = orderInfo && formatedDate(orderInfo.pickingCompletedAt, true)
    const pickedUpAt = orderInfo && formatedDate(orderInfo.pickedUpAt, true)
    const shippedAt = orderInfo && formatedDate(orderInfo.shippedAt, true)
    const deliveredAt = orderInfo && formatedDate(orderInfo.deliveredAt, true)

    let statusText
    if (orderInfo && orderInfo.shippingState !== 'isOpen') {
      statusText = deliveredAt ? `${t('order:shippingStateIsDelivered')} (${deliveredAt})`
        : pickedUpAt ? `${t('order:shippingStateIsPickedUpAt')} (${pickedUpAt})`
          : shippedAt ? `${t('order:shippingStateIsShipped')} (${shippedAt})`
            : pickingCompletedAt ? `${t('order:shippingStateIsPickingComplete')} (${pickingCompletedAt})`
              : pickingStartedAt ? `${t('order:shippingStateIsInPickingProcess')} (${pickingStartedAt})`
                : pickingApprovedAt ? `${t('order:shippingStateIsReadyForShipment')} (${pickingApprovedAt})`
                  : ''


    } else {
      statusText = isCanceled ? getCanceledText(canceledAt)
        : activatedAt ? `${t('orders:waitingForDelivery')} (${activatedAt})`
          : transmittedAtSRM ? `${getThemeValue('ordersTabs', 'ordersTabHeader', transmittedAtSRMText)} (${transmittedAtSRM})`
            : waitingTransmissionAtSRM ? `${t('orders:openTransmissionsSRMOrders')} (${waitingTransmissionAtSRM})`
              : approvalReleasedAt ? `${t('orders:approvalReleasedAt')} (${approvalReleasedAt})`
                : approvalRequestedAt ? `${t('orders:approvalRequestedAt')} (${approvalRequestedAt})`
                  : `${t('order:shippingStateIsOpen')} (${createdAt})`
    }

    return (
      <React.Fragment>
        {title}
        {statusText && <StyledStatusWrapper onClick={() => setOpened(prev => !prev)}><small className={`${isCanceled ? 'text-danger' : 'text-success'} fw-normal`}>{t('status')}: {statusText}</small><ArrowBigIcon width={"20px"} height={"20px"} variant={opened ? 'down' : 'right'} /></StyledStatusWrapper>}
        <Collapse in={opened}>
          <div className='mt-2 '>
            <small className='fw-normal'>
              <StyledOl className={isCanceled ? 'text-danger' : 'text-success'} >
                {createdAt ? <li>{t('order:shippingStateIsOpen')} {`(${createdAt})`} </li> : null}
                {approvalRequestedAt ? <li>{t('orders:approvalRequestedAt')} {`(${approvalRequestedAt})`}</li> : null}
                {approvalReleasedAt ? <li>{t('orders:approvalReleasedAt')} {`(${approvalReleasedAt})`}</li> : null}
                {waitingTransmissionAtSRM ? <li>{t('orders:openTransmissionsSRMOrders')} {`(${waitingTransmissionAtSRM})`}</li> : null}
                {transmittedAtSRM ? <li>{getThemeValue('ordersTabs', 'ordersTabHeader', transmittedAtSRMText)} {`(${transmittedAtSRM})`}</li> : null}
                {activatedAt ? <li>{`${t('orders:waitingForDelivery')} (${activatedAt})`}</li> : null}
                {pickingApprovedAt ? <li>{t('order:shippingStateIsReadyForShipment')} {`(${pickingApprovedAt})`} </li> : null}
                {pickingStartedAt ? <li>{t('order:shippingStateIsInPickingProcess')} {`(${pickingStartedAt})`} </li> : null}
                {pickingCompletedAt ? <li>{t('order:shippingStateIsPickingComplete')} {`(${pickingCompletedAt})`} </li> : null}
                {shippedAt ? <li>{t('order:shippingStateIsShipped')} {`(${shippedAt})`} </li> : null}
                {pickedUpAt ? <li>{t('order:shippingStateIsPickedUpAt')} {`(${pickedUpAt})`} </li> : null}
                {deliveredAt ? <li>{t('order:shippingStateIsDelivered')} {`(${deliveredAt})`} </li> : null}
                {isCanceled ? <li>{getCanceledText(canceledAt)}</li> : null}
              </StyledOl>
            </small>
          </div>
        </Collapse>
        <div className="d-flex align-items-center">
          {infoTat.map(tat =>
            tat.url
              ? <div key={tat.id} className='me-3'>
                <a href={tat.url} className='btn btn-primary' target='_blank' rel='noopener noreferrer'>
                  {t('order:trackDelivery')}
                </a>
              </div>
              : null
          )}
          {(allowOrderWasPickedUp && orderInfo && orderInfo.status === "closed") &&
            <Button onClick={() => setOpenSignatureModal(true)}>{t('order:receivedDelivery')}</Button>
          }
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className='d-flex justify-content-between align-items-end flex-wrap'>
        <h4 className=''>
          {renderTitleAndStatusText()}
        </h4>
        {showChangeAllCheckbox &&
          <StyledCustomCheckboxAll>
            <Form.Check
              id={`positions-list-${orderInfo ? orderInfo.id : ''}-${index}`}
              onChange={changeAll}
              checked={changeAllIsChecked}
              label={t('order:selectAll')}
            />
          </StyledCustomCheckboxAll>
        }
      </div>
      <hr />
      {openSignatureModal && <SignatureModal setOpenSignatureModal={setOpenSignatureModal} shippingOrderId={orderInfo.id} resetOrderPage={resetOrderPage} />}
    </React.Fragment>
  )

}

export default PositionsListHeader
