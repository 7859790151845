import { action, observable, makeObservable, runInAction } from 'mobx';
import { themes } from '../styles/themes.js'
import apiClient from '../utils/api/apiClient'
import { loadStyleSheet } from '../utils/loadStylesheet'
import { APIEndpoints } from '../utils/api/apiConfig'
//import { emptyGuid } from '../utils/emptyGuid'
import { settingValueToType } from '../utils/api/apiUtils'

export const defaultTheme = 'default'

const waitForPromise = ms => new Promise(r => setTimeout(r, ms))

class UiStore {
  constructor(appStore) {
    makeObservable(this, {
      theme: observable,
      language: observable,
      reloading: observable,
      galleryListPositionLeft: observable,
      productGalleryCarouselIndex: observable,
      dashboard: observable,
      dashboardIsLoading: observable,
      costcenterOrderAvailable: observable,
      storehouseOrderAvailable: observable,
      employeeOrderAvailable: observable,
      employeeAdministration: observable,
      fittingReservation: observable,
      fittingAdministration: observable,
      checkupAdministration: observable,
      personalOrderAvailable: observable,
      reportAdministration: observable,
      privateOrder: observable,
      allowChangeArticleConfiguration: observable,
      showArticleNumberInOverview: observable,
      allowCancelPreOrders: observable,
      articleCatalogAvailable: observable,
      allowArticleTransferToEmployee: observable,
      returnPersonalArticles: observable,
      allowAccessUserModule: observable,
      allowBookingEmployeeReturnsWithoutInventoryBookings: observable,
      allowInventoryBookings: observable,
      allowCreateNewEmployee: observable,
      allowSeeEmployeeInventories: observable,
      laundryShelf_IsMandatory: observable,
      activitiesDependentOnSelectedOffice: observable,
      allowReportArticleCatalogSheet: observable,
      allowReleaseComplaints: observable,
      releaseComplaintsIsActive: observable,
      allowReleaseInventoryBookingChanges: observable,
      allowReleasePointsChanges: observable,
      allowNewsAdministration: observable,
      allowConvertComplaintToReturn: observable,
      allowEntitlementAdministration: observable,
      allowEntitlementPhaseAdministration: observable,
      allowImportCatalogs: observable,
      allowTextPlaceholderAdministration: observable,
      allowBPArticleComment: observable,
      allowImportCostcenters: observable,
      allowImportOffices: observable,
      allowImportOrders: observable,
      allowImportEntitlements: observable,
      showPoints: observable,
      showNews: observable,
      srmProcessIsActive: observable,
      activateSRMOrder: observable,
      allowShowAllOrders: observable,
      showPointHistory: observable,
      linkToBasket: observable,
      linkToConfirmAddress: observable,
      linkToConfirmOrder: observable,
      linkToSuccessOrder: observable,
      allowChangePoints: observable,
      news: observable,
      orderApprovalCount: observable,
      reportsCount: observable,
      srmCostCentersCount: observable,
      showRLXLink: observable,
      complaintsCount: observable,
      cwsOrderCommentProcessActive: observable,
      activeNav: observable,
      openComplaints: observable,
      openReturnShipment: observable,
      showStdPointRun: observable,
      shoppingCarts: observable,
      allowShipmentCountrySelection: observable,
      allowOrderTemplates: observable,
      showBillingDetails: observable,
      setLanguageAndDeliveryCountry: action,
      buildCheckoutLinks: action,
      setProductGalleryCarouselIndex: action,
      setTheme: action,
      setMandatorThemeAndLanguage: action,
      initAppStart: action,
      loadDashboard: action,
      documentUploadPossible: observable,
      checkDocumentUpload: action,
      bPDataSettings: observable,
      bPDataSettingsIsLoading: observable,
      allowChangeActivationStatus: observable,
      laundryShelf_Status: observable,
      laundryShelf_Selector: observable,
      orderPhase_Status: observable,
      loadBPDataSettings: action,
      loadOrderApprovalCount: action,
      fetchNewShoppingCarts: action,
      loadReportsCount: action,
      loadSrmCostCentersCount: action,
      loadComplaintsCount: action,
      pointsChangeCounter: observable,
      loadPointsChangesCount: action,
      inventoryBookingsCounter: observable,
      loadInventoryBookingsCounter: action,
      changeActiveNav: action,
      changeShoppingCarts: action
    });

    this.app = appStore
  }

  theme = defaultTheme;
  language = 'de';
  reloading = false;

  galleryListPositionLeft = '0';
  productGalleryCarouselIndex = 0;
  dashboard = null;
  dashboardIsLoading = false;

  costcenterOrderAvailable = false;
  storehouseOrderAvailable = false;
  employeeOrderAvailable = false;
  employeeAdministration = false;
  fittingReservation = false;
  fittingAdministration = false;
  checkupAdministration = false;
  personalOrderAvailable = false;
  reportAdministration = false;
  privateOrder = false;
  allowChangeArticleConfiguration = false;
  showArticleNumberInOverview = false;
  allowCancelPreOrders = false;
  articleCatalogAvailable = false;
  allowArticleTransferToEmployee = false;
  returnPersonalArticles = false;
  allowAccessUserModule = false;
  allowBookingEmployeeReturnsWithoutInventoryBookings = false;
  allowInventoryBookings = false;
  allowCreateNewEmployee = false;
  allowSeeEmployeeInventories = false;
  laundryShelf_IsMandatory = false;
  activitiesDependentOnSelectedOffice = false;
  allowReportArticleCatalogSheet = false;
  allowReleaseComplaints = false;
  releaseComplaintsIsActive = false;
  allowReleaseInventoryBookingChanges = false;
  allowReleasePointsChanges = false;
  allowNewsAdministration = false;

  allowConvertComplaintToReturn = false;
  allowEntitlementAdministration = false;
  allowEntitlementPhaseAdministration = false;
  allowImportCatalogs = false;
  allowTextPlaceholderAdministration = false;
  allowBPArticleComment = false;
  allowImportCostcenters = false;
  allowImportOffices = false;
  allowImportOrders = false;
  allowImportEntitlements = false;
  showPoints = false;
  showNews = false;
  srmProcessIsActive = false;
  activateSRMOrder = false;
  allowShowAllOrders = false;
  showPointHistory = false;
  linkToBasket = '';
  linkToConfirmAddress = '';
  linkToConfirmOrder = '';
  linkToSuccessOrder = '';
  allowChangePoints = false;
  news = null;
  orderApprovalCount = [];
  reportsCount = [];
  srmCostCentersCount = 0;
  showRLXLink = false;
  complaintsCount = 0;
  cwsOrderCommentProcessActive = false;
  activeNav = '';
  openComplaints = false;
  openReturnShipment = false;

  showStdPointRun = false;

  shoppingCarts = [];

  allowShipmentCountrySelection = false;
  allowOrderTemplates = false;
  showBillingDetails = false;

  setLanguageAndDeliveryCountry(languageCode, deliveryCountryId) {
    apiClient.patchJson(APIEndpoints.profile,
      {
        profile: {
          countryId: deliveryCountryId,
          languageCode: languageCode ? languageCode : this.language
        }
      })
      .then(res => {
        this.updateLanguageInUrl(languageCode ? languageCode : this.language);
      })
  }

  buildCheckoutLinks() {
    const { productsStore } = this.app
    const srmProcess = this.srmProcessIsActive && (productsStore.basket.targetOrderProcessType === "costCenter" || productsStore.basket.targetOrderProcessType === "personal")

    let prefix = ''
    switch(productsStore.basket.targetOrderProcessType)
    {
      case 'storeHouse':
        prefix = `/storehouse/${productsStore.basket.targetStorehouse.id}/${productsStore.basket.targetCostCenter.id}`
        break;
      case 'costCenter':
        prefix = `/costcenter/${productsStore.basket.targetCostCenter.id}`
        break;
      case 'private':
        prefix = '/order/private'
        break;
      case 'personal':
      default:
        prefix = '/order/personal'
        break;
    }
    this.linkToBasket = `${prefix}/shoppingcart/${productsStore.basket.id}`
    this.linkToConfirmAddress = `${prefix}/shoppingcart/${productsStore.basket.id}/confirmaddress`
    this.linkToConfirmOrder = `${prefix}/shoppingcart/${productsStore.basket.id}/confirmaddress/confirmorder`
    this.linkToSuccessOrder = srmProcess ? '/#orderApprovalSRM' : `${prefix}/shoppingcart/${productsStore.basket.id}/confirmaddress/confirmorder/ordersuccess`
  }
  resetCheckoutLinks() {
    this.linkToBasket = ''
    this.linkToConfirmAddress = ''
    this.linkToConfirmOrder = ''
    this.linkToSuccessOrder = ''
  }


  updateLanguageInUrl(language) {
    let currentPathname = window.location.pathname.replace(process.env.PUBLIC_URL, '')
    let pathParts = currentPathname.split('/')
    pathParts[2] = language
    const newPath = pathParts.join('/')
    this.reloading = true
    window.location = process.env.PUBLIC_URL + newPath

    // i18n.changeLanguage(this.language)
  }

  async checkStdPointRun() {
    let status = await apiClient.getJson(APIEndpoints.coreservices().stdPointRun)
    this.showStdPointRun = status?.active
  }
  setProductGalleryCarouselIndex(index) {
    this.productGalleryCarouselIndex = index
  }

  setTheme(theme) {
    // document.documentElement.classList.remove(this.theme)
    this.theme = theme
    // document.documentElement.classList.add(this.theme)
    loadStyleSheet('themes/' + process.env.REACT_APP_MANDATORSET + '/scss/' + this.theme + '.css')
  }

  setMandatorThemeAndLanguage(mandatorId, mandatorLanguage = this.language) {
    // redirect to mandator theme
    const currentTheme = themes[this.theme] || themes[defaultTheme]
    const currentThemeMatchesMandatorId = currentTheme.ids.length ? currentTheme.ids.find(id => mandatorId === id) : false
    const themeName = this.app.profileStore.getThemeNameByProfileMandorId()
    const currentLanguageMatchesProfileLanguage = this.language === mandatorLanguage
    if ((!currentThemeMatchesMandatorId && themeName) || !currentLanguageMatchesProfileLanguage) {
      //navigate('/' + (themeName || this.theme) + '/' + mandatorLanguage + '/')
      //this.reloading = true
      //Reload page, dont use window.reload(); it is not working correctly in firefox with pushStates
      // eslint-disable-next-line
      window.location = process.env.PUBLIC_URL + '/' + (themeName || this.theme) + '/' + mandatorLanguage + '/'
    }
    else {
      this.app.loginStore.initialLogin = false
    }
  }




  async initAppStart() {

    const profile = await this.app.profileStore.loadProfile()
    this.app.loginStore.initialLogin ?
      this.setMandatorThemeAndLanguage(profile.mandator.id, profile.languageCode) : this.setMandatorThemeAndLanguage(profile.mandator.id)
  }

  async waitUntilLoaded(ms, isLoadingProperty) {
    const getKey = (key, obj) => {
      return key.split('.').reduce((a, b) => {
        return a && a[b]
      }, obj)
    }

    //loops until isLoadingProperty is true
    while (getKey(isLoadingProperty, this)) {
      await waitForPromise(ms)
    }
  }

  async loadDashboard() {
    if (this.dashboard) {
      return null
    }
    else if (this.dashboardIsLoading) {
      await this.waitUntilLoaded(100, 'dashboardIsLoading')
      return null
    }
    if (!this.dashboardIsLoading) {
      this.dashboardIsLoading = true
      const _dashboard = await apiClient.getJson(APIEndpoints.settings().dashboard)

      const navigations = _dashboard.find(setting => setting.settingName === 'navigations') || []
      runInAction(() => {
        this.personalOrderAvailable = !!navigations.settingValue.find(value => value === 'personalOrder')
        this.costcenterOrderAvailable = !!navigations.settingValue.find(value => value === 'costcenterOrder')
        this.storehouseOrderAvailable = !!navigations.settingValue.find(value => value === 'storehouseOrder')
        this.employeeOrderAvailable = !!navigations.settingValue.find(value => value === 'employeeOrder')
        this.employeeAdministration = !!navigations.settingValue.find(value => value === 'employeeAdministration')
        this.fittingReservation = !!navigations.settingValue.find(value => value === 'fittingReservation')
        this.fittingAdministration = !!navigations.settingValue.find(value => value === 'fittingAdministration')
        this.checkupAdministration = !!navigations.settingValue.find(value => value === 'checkupAdministration')
        this.reportAdministration = !!navigations.settingValue.find(value => value === 'reportAdministration')
        this.privateOrder = !!navigations.settingValue.find(value => value === 'privateOrder')
        this.orderApproval = !!navigations.settingValue.find(value => value === 'releaseOrder')
        this.articleCatalogAvailable = !!navigations.settingValue.find(value => value === 'articleCatalog')
        this.activateSRMOrder = !!navigations.settingValue.find(value => value === 'activateSRMOrder')
        this.openComplaints = !!navigations.settingValue.find(value => value === 'openComplaints')
        this.openReturnShipment = !!navigations.settingValue.find(value => value === 'openReturnShipment')
        this.cwsOrderCommentProcessActive = settingValueToType(_dashboard.find(setting => setting.settingName === 'OrderAddressSelectionMarkDifferentAddressInOrderConfirmation'))
        this.allowImportCostcenters = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowImportCostcenters'))
        this.allowImportOffices = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowImportOffices'))
        this.allowImportOrders = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowImportOrders'))
        this.showPoints = settingValueToType(_dashboard.find(setting => setting.settingName === 'showPoints'))
        this.showNews = settingValueToType(_dashboard.find(setting => setting.settingName === 'showNews'))
        this.srmProcessIsActive = settingValueToType(_dashboard.find(setting => setting.settingName === 'srmProcessIsActive'))
        this.allowShipmentCountrySelection = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowShipmentCountrySelection'))
        this.allowChangeArticleConfiguration = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowChangeArticleConfiguration'))
        this.allowOrderTemplates = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowOrderTemplates'))
        this.allowShowAllOrders = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowShowAllOrders'))
        this.showPointHistory = settingValueToType(_dashboard.find(setting => setting.settingName === 'showPointHistory'))
        this.allowChangePoints = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowChangePoints'))
        this.showArticleNumberInOverview = settingValueToType(_dashboard.find(setting => setting.settingName === 'showArticleNumberInOverview'))
        this.allowCancelPreOrders = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowCancelPreOrders'))
        this.allowBPArticleComment = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowBPArticleComment'))
        this.showRLXLink = settingValueToType(_dashboard.find(setting => setting.settingName === 'showRLXLink'))
        this.allowInventoryBookings = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowInventoryBookings'))
        this.returnPersonalArticles = !!navigations.settingValue.find(value => value === 'returnPersonalArticles')
        this.allowArticleTransferToEmployee = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowArticleTransferToEmployee'))
        this.allowEntitlementAdministration = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowEntitlementAdministration'))
        this.allowReleaseInventoryBookingChanges = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowReleaseInventoryBookingChanges'))
        this.allowReleasePointsChanges = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowReleasePointsChanges'))
        this.allowConvertComplaintToReturn = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowConvertComplaintToReturn'))
        this.allowAccessUserModule = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowAccessUserModule'))
        this.allowBookingEmployeeReturnsWithoutInventoryBookings = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowBookingEmployeeReturnsWithoutInventoryBookings'))
        this.allowEntitlementPhaseAdministration = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowEntitlementPhaseAdministration'))
        this.allowImportEntitlements = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowImportEntitlements'))
        this.allowCreateNewEmployee = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowCreateNewEmployee'))
        this.allowSeeEmployeeInventories = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowSeeEmployeeInventories'))
        this.allowImportCatalogs = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowImportCatalogs'))
        this.allowTextPlaceholderAdministration = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowTextPlaceholderAdministration'))
        this.allowReportArticleCatalogSheet = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowReportArticleCatalogSheet'))
        this.allowReleaseComplaints = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowReleaseComplaints'))
        this.releaseComplaintsIsActive = settingValueToType(_dashboard.find(setting => setting.settingName === 'releaseComplaintsIsActive'))
        this.allowNewsAdministration = settingValueToType(_dashboard.find(setting => setting.settingName === 'allowNewsAdministration'))
        this.showBillingDetails = settingValueToType(_dashboard.find(setting => setting.settingName === 'showBillingDetails'))
      })
      //make sure all settings are set before this.dashboard. so that !!this.dashboard is equal to "all settings are set"
      this.dashboard = _dashboard
      this.dashboardIsLoading = false
      return null
    }
  }

  documentUploadPossible = false;

  async checkDocumentUpload() {
    if (this.checkDocumentUploadIsLoading) {
      await this.waitUntilLoaded(100, 'checkDocumentUploadIsLoading')
      return null
    }
    else {
      let result = await apiClient.getJson(APIEndpoints.checkDocumentUpload)
      this.documentUploadPossible = result
      this.checkDocumentUploadIsLoading = true
    }
  }

  bPDataSettings = null;
  bPDataSettingsIsLoading = false;
  allowChangeActivationStatus = false;
  laundryShelf_Status = '';
  laundryShelf_Selector = '';
  orderPhase_Status = '';
  //businesspartner data settings
  async loadBPDataSettings(force = false) {
    if (this.bPDataSettings && !force) {
      return null
    }
    else if (this.bPDataSettingsIsLoading) {
      await this.waitUntilLoaded(100, 'bPDataSettingsIsLoading')
      return null
    }
    if (!this.bPDataSettingsIsLoading) {
      this.bPDataSettingsIsLoading = true
      this.bPDataSettings = await apiClient.getJson(APIEndpoints.settings().businesspartnerData)
      this.allowChangeActivationStatus = settingValueToType(this.bPDataSettings.find(setting => setting.settingName === 'allowChangeActivationStatus'))
      this.laundryShelf_Status = settingValueToType(this.bPDataSettings.find(setting => setting.settingName === 'laundryShelf_Status'))
      this.laundryShelf_Selector = settingValueToType(this.bPDataSettings.find(setting => setting.settingName === 'laundryShelf_Selector'))
      this.orderPhase_Status = settingValueToType(this.bPDataSettings.find(setting => setting.settingName === 'orderPhase_Status'))
      this.laundryShelf_IsMandatory = settingValueToType(this.bPDataSettings.find(setting => setting.settingName === 'laundryShelf_IsMandatory'))
      this.activitiesDependentOnSelectedOffice = settingValueToType(this.bPDataSettings.find(setting => setting.settingName === 'activitiesDependentOnSelectedOffice'))

      this.bPDataSettingsIsLoading = false
    }
    return null

  }

  async loadOrderApprovalCount() {
    await this.loadDashboard()
    this.orderApprovalCount = await apiClient.getJson(APIEndpoints.incomingOrdersCounter, { 'filter.status': 'waitingForApproval' })
  }

  changeShoppingCarts(shoppingCarts) {
    this.shoppingCarts = shoppingCarts
  }

  async fetchNewShoppingCarts() {
    const response = await apiClient.getJson(APIEndpoints.partialShoppingBaskets())

    if (response) {
      this.changeShoppingCarts(response.filter(({ articleQuantity }) => articleQuantity > 0))
    }
  }

  async loadReportsCount() {
    await this.loadDashboard()
    if (this.reportAdministration) {
      this.reportsCount = await apiClient.getJson(APIEndpoints.reportJobsCounter)
    }
  }
  async loadSrmCostCentersCount() {
    await this.loadDashboard()
    if (this.srmProcessIsActive) {
      this.srmCostCentersCount = await apiClient.getJson(APIEndpoints.incomingOrders().srmCostCentersCounter)
    }
  }

  changeActiveNav(activeNav) {
    this.activeNav = activeNav
  }

  async loadComplaintsCount() {
    await this.loadDashboard()
    let params = {
      'page.limit': 1,
      'page.offset': 0,
      'filter.type': 'all'
    }
    if (this.allowShowAllOrders) {
      params['filter.showAllReturns'] = true
    }

    const response = await apiClient.getJson(APIEndpoints.returns().overview, params, true, true)
    this.complaintsCount = Number(response.headers['x-total-count'])
  }

  pointsChangeCounter = [];
  async loadPointsChangesCount() {
    await this.loadDashboard()
    let params = {
      'filter.showAllRequests': this.app.uiStore.allowReleasePointsChanges ? true : false
    }
    this.pointsChangeCounter = await apiClient.getJson(APIEndpoints.pointsChangeRequest.counter, params)
  }
  inventoryBookingsCounter = [];
  async loadInventoryBookingsCounter() {
    await this.loadDashboard()
    let params = {
      'filter.showAllRequests': this.app.uiStore.allowReleaseInventoryBookingChanges ? true : false
    }
    this.inventoryBookingsCounter = await apiClient.getJson(APIEndpoints.inventoryChangeRequest().counter, params)
  }
}

export default UiStore