import React, { useState } from 'react'
import { cloneDeep } from 'lodash'
import { setValueByKey } from '../utils/utils'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { getErrorMessageFromApiResponse } from '../utils/api/apiUtils'
import { useCancelToken } from '../utils/hooks/useCancelToken'


const ComplaintsContext = React.createContext()
export const useComplaintsState = () => {
  return React.useContext(ComplaintsContext)
}

const filterTemplate = {
  type: '',
  status: '',
  searchText: '', // the feedbackNumber: 'F25604556'
  targetCostcenterId: '',
  showAllReturns: false,
  costcenterName: '',
  lastMonths: ''
}


const ComplaintsProvider = ({ children }) => {
  const [filter, setFilter] = useState(cloneDeep(filterTemplate))
  const [sort, setSort] = useState(null)
  const [error, setError] = useState(null)
  const [searchedComplaint, setSearchedComplaint] = useState(null)
  const [complaintsIsLoading, setComplaintsIsLoading] = useState(false)
  const [complaints, setComplaints] = useState([])
  const [pageOffset, setPageOffset] = useState(0)
  const pageLimit = 10
  const [totalCount, setTotalCount] = useState(0)
  const [activeFilterCostCenter, setActiveFilterCostCenter] = useState('')
  const { createOrCancelToken, sourceRef, isCancel } = useCancelToken();

  const handleChangeSort = (newSort) => {
    if (sort !== newSort) {
      setSort(newSort)
      loadComplaints(newSort, filter, 0)
    }
  }

  const handleChangeFilter = (newFilter) => {
    let newFilterString = JSON.stringify(newFilter)
    let lastFilterString = JSON.stringify(filter)
    if (lastFilterString !== newFilterString) {
      setFilter(newFilter)
      loadComplaints(sort, newFilter, 0)
    }
  }

  const setFilterByKey = (key, value) => {
    let newFilter = setValueByKey(key, value, filter)
    handleChangeFilter(newFilter)
  }

  const reloadComplaints = () => {
    loadComplaints(sort, filter, 0)
  }



  const updateComplaint = (complaintId, complaint) => {
    setComplaints(prev => {
      let updatedComplaintIndex = prev.findIndex(item => item.id === complaintId)
      prev[updatedComplaintIndex] = complaint
      return cloneDeep(prev)
    })
  }

  const loadComplaints = async (newSort = sort, newFilter = filter, newPageOffset = pageOffset) => {
    createOrCancelToken();
    setComplaintsIsLoading(true)
    setError(false)

    if (!newPageOffset) {
      setComplaints([])
    }
    const params = getParamsForComplaintsRequest(newSort, newFilter, newPageOffset)
    try {
      const response = await apiClient.getJson(APIEndpoints.returns().overview, params, true, true, true, { cancelToken: sourceRef.current.token })
      setTotalCount(response.headers['x-total-count'] ? parseInt(response.headers['x-total-count']) : 0)
      setPageOffset(newPageOffset + pageLimit);
      setComplaints(prev => [...prev, ...response.data])
      setComplaintsIsLoading(false)
    } catch (e) {
      if (isCancel(e)) {
        console.log("cancelled by cancelToken")
      }
      else {
        setError(getErrorMessageFromApiResponse(e))
      }
    }
  }

  const getParamsForComplaintsRequest = (newSort, newFilter, newPageOffset) => ({
    'page.limit': pageLimit,
    'page.offset': newPageOffset,
    'sort': newSort,
    'filter.type': newFilter.type ? newFilter.type : 'all',
    'filter.lastMonths': newFilter.lastMonths ? newFilter.lastMonths : null,
    'filter.status': newFilter.status ? newFilter.status : null,
    'filter.searchText': newFilter.searchText ? newFilter.searchText : null,
    'filter.targetCostcenterId': newFilter.targetCostcenterId ? newFilter.targetCostcenterId : null,
    'filter.showAllReturns': newFilter.showAllReturns ? true : null,
    'filter.costcenterName': newFilter.costcenterName ? newFilter.costcenterName : null,
  })

  const contextValues = {
    filter,
    searchedComplaint, setSearchedComplaint,
    setFilterByKey,
    loadComplaints,
    reloadComplaints,
    pageOffset,
    totalCount,
    setTotalCount,
    complaints,
    complaintsIsLoading,
    sort, handleChangeSort,
    error, //add error alert somewhere
    activeFilterCostCenter, setActiveFilterCostCenter,
    handleChangeFilter,
    updateComplaint
  }

  return (
    <ComplaintsContext.Provider value={contextValues}>
      {children}
    </ComplaintsContext.Provider>
  )
}
export default ComplaintsProvider

