import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import SuggestionInput from './SuggestionInput'
import { MdLocationOn } from "react-icons/md";
import { useCancelToken } from '../../utils/hooks/useCancelToken'

const ZipAPIBaseUrl = "https://api.zippopotam.us"
const zipAPIEndpoint = (langCode, zip) => (`${ZipAPIBaseUrl}/${langCode}/${zip}`)


const ZipSuggestion = (props) => {
    const {
        value,
        countryIsoCode,
        ...rest
    } = props

    const [suggestions, setSuggestions] = useState(null)
    const { createOrCancelToken, sourceRef } = useCancelToken();

    const getPreparedData = useCallback((data) => {
        return data.map((obj, index) => ({ cityName: obj["place name"], displayName: `${value}, ${obj["place name"]}`, id: index, icon: <MdLocationOn className="me-3" /> }))
    }, [value])
    const fetchAddresses = useCallback(async () => {
        createOrCancelToken();
        try {
            const res = await axios.get(zipAPIEndpoint(countryIsoCode, value), { cancelToken: sourceRef.current.token });
            if (res && res.data && res.data.places) {
                setSuggestions(getPreparedData(res.data.places))
            }
        }
        catch (e) {
            setSuggestions(null)
        }
    }, [countryIsoCode, createOrCancelToken, getPreparedData, sourceRef, value])

    useEffect(() => {
        if (value && value.length >= 3) {
            fetchAddresses()
        }
        else setSuggestions(null)
    }, [value, countryIsoCode, fetchAddresses])



    return (
        <SuggestionInput
            value={value}
            suggestions={suggestions}
            {...rest}
        />
    )
}

export default ZipSuggestion