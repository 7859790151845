import i18n from '../translation/i18n'
import { appStore } from '../stores/stores'
import { themes } from '../styles/themes'

const getThemeValue = (key1, key2, fallback) => {
    const theme = themes[appStore.uiStore.theme]
    if (key2) {
        return ((theme[key1] != null) && (theme[key1][key2] != null)) ? theme[key1][`${key2}_${i18n.language}`] || theme[key1][key2] : fallback
    }
    else {
        return theme[key1] != null ? theme[`${key1}_${i18n.language}`] || theme[key1] : fallback
    }
}

export default getThemeValue