import React, { useState } from 'react'
import styled from 'styled-components'
import { StyledCard } from '../Cards/StyledCard'
import { colors } from '../../styles/colors'
import { InfoIcon } from '../Icons/InfoIcon'
import { formatedDate } from '../../utils/formatedDate'
import { useTranslation } from 'react-i18next'
import SafelySetInnerHTML from '../SafelySetInnerHTML'
import Collapse from 'react-bootstrap/Collapse'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import getThemeValue from '../../utils/getThemeValue'

const StyledFeedCard = styled(StyledCard)`
  background: white;
  border: 1px solid #dbdbdb;
  box-shadow: none;
  display: flex;
  min-height: 0;

  .category-icon {
    transform: translateX(-10px);
  }

  .collapse-div {
    display:flex;
    justify-content: space-between;
    margin-top:${props => props.nameUnderNewFlag ? '34px' : '0px'};
    cursor:${props => props.collapsable ? 'pointer' : 'unset'};
  }
  .name{
    font-weight: 600;
    font-size: 18px;
    // margin-bottom: 10px;
    color: ${colors.gray7};
    color:  ${props => props.theme.primaryColor};
  }

  .date {
    margin-bottom: 10px;
    color: ${props => props.theme.primaryColor};
    small{
      font-weight: bold;
    }
  }
  .flag {
    position: absolute;
    top: 0;
    right: 0;
    background: ${props => props.theme.primaryColor};
    padding: 5px 15px;
    border-bottom-left-radius: 5px;
    color: white;
  }
  .name-wrapper{
    display:flex;
    align-items:center;
  }
`

const CollapsedFeedCard = ({ item }) => {
  const MyReplaceAll = (string, search, replace) => {
    return string.split(search).join(replace);
  }

  const location = window.location.href
  const { t } = useTranslation()
  const content = item.body

  const withLinks = MyReplaceAll(MyReplaceAll(content, "hrefToContentPage=\"", `href="${location}custom-html/`), "hrefToLogix=\"", `href="${location}`)
  const [collapsed, setCollapsed] = useState(item.isCollapsed);
  const collapsable = item.isCollapsed
  const hideInfoIcon = getThemeValue('dashboard', 'hideInfoIcon', false)
  return (
    <StyledFeedCard className='mt-4 mb-4 p-2 no-border-radius' collapsable={collapsable} nameUnderNewFlag={item.status === 'isNew' && !item.createdAt}>
      {!hideInfoIcon ?
        <i className="category-icon"><InfoIcon /></i>
        : null}
      <div className="flex-grow-1">
        {item.status === 'isNew' ? <div className="flag">{t('new')}</div> : ''}
        {item.createdAt && <div className='date'><small>{formatedDate(item.createdAt)}</small></div>}
        <div className="collapse-div"
          onClick={() => setCollapsed(!collapsed)}
          aria-controls="example-fade-text"
          aria-expanded={collapsable && collapsed}
        >
          <div className="name-wrapper">
            <SafelySetInnerHTML className="name" >
              {item.header}
            </SafelySetInnerHTML>
            {collapsable ? collapsed ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp /> : null}
          </div>
          <div className="font-italic ">
            {item.createdByDisplayName ? `${t('-')} ${item.createdByDisplayName}` : ''}
          </div>
        </div>
        <Collapse in={!collapsable || !collapsed}>
          <SafelySetInnerHTML className="mt-2 ck-content">
            {withLinks}
          </SafelySetInnerHTML>
        </Collapse>
      </div>
    </StyledFeedCard >
  )
}

export default CollapsedFeedCard
