import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { inject, observer } from 'mobx-react'
import { ShoppingCartSummary } from '../components/ShoppingCart/ShoppingCartSummary'
import ArticlesList from '../components/ShoppingCart/ArticlesList'
import { PageLoadingLayer } from '../components/Loading/PageLoadingLayer'
import { withTranslation } from 'react-i18next'
import { Subheader } from '../components/Page/logix2020/Subheader'
//import { isEmptyGuid } from '../utils/emptyGuid'
import SubheaderTitle from '../components/Employees/Subheader/SubheaderTitle'
import EmployeeData from '../components/Employees/Subheader/EmployeeData'
import ShoppingCartProgress from '../components/ShoppingCart/ShoppingCartProgress'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import { TestSidebarRight } from '../components/Page/logix2020/TestSidebarRight'
import BigFooter from '../components/Footer/BigFooter'
import { FlexWrapper } from '../components/Page/logix2020/FlexWrapper'
import withRouter from '../utils/withRouter'

const LinearGradient = styled.div`
width:100%;
@media only screen and (min-width: 1281px) {
  background: linear-gradient(to left, rgb(238, 238, 238) 0%, rgb(238, 238, 238) 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
 }
`

const StyledH1 = styled.h1`
  margin-bottom: 0;
`
const StyledContentArea = styled(ContentArea)`
   padding: 46px 40px 20px 36px;
   @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px)  {
    padding: 20px;
    min-height: calc(100vh - 481px);//header+navbar+sidebar
   }
  
`
const StyledFlexWrapper = styled(FlexWrapper)`
max-width: 1280px;
margin-left:auto;
margin-right:auto;
min-height:0px;
`
const StyledSubHeader = styled(Subheader)`
/* position:fixed;
  z-index:1; */
`

class ShoppingCartPage extends Component {

  componentDidMount() {
    this.initialize()
  }
  componentDidUpdate(prevProps) {
    const params = this.props.params
    const prevParams = prevProps.params
    if (params.storeHouseId !== prevParams.storeHouseId || params.costCenterId !== prevParams.costCenterId || params.shoppingBasketId !== prevParams.shoppingBasketId) {
      this.initialize()
    }
  }


  initialize = async () => {
    const { productsStore, teamOrderStore, params, navigate } = this.props
    const { storeHouseId, costCenterId, shoppingBasketId, orderType } = params
    productsStore.resetPageErrors()
    console.log('ShoppingCartPage initialize:', storeHouseId, costCenterId, orderType)
    teamOrderStore.initializeCurrentProcessType(storeHouseId, costCenterId, orderType)

    if (shoppingBasketId) {
      try {
        const basket = await productsStore.loadShoppingBasket(shoppingBasketId)
        if (basket) {
          await productsStore.fixShoppingBasketStatus(navigate)
        }
      } catch (error) { }
    }
  }



  render() {
    const { t, profileStore, productsStore, uiStore, params } = this.props
    const basket = productsStore.basket ? productsStore.basket : {}
    const { shoppingBasketId } = params
    const articles = basket.articles ? basket.articles : []
    const basketArticlesAmount = productsStore.getBasketArticlesAmount()

    const backToLink = () => {
      switch(basket.targetOrderProcessType)
      {
          case 'costCenter':
            return `/costcenter/${basket.targetCostCenter.id}/costcenterproducts`
          case 'storeHouse':
            return `/storehouse/${basket.targetStorehouse.id}/${basket.targetCostCenter.id}/products`
          case 'private':
            return '/products/private'
          case 'personal':
          default:
            if (basket.targetBusinesspartner && basket.targetBusinesspartner.id !== profileStore.profile.id) {
              return `/products/personal/${basket.targetBusinesspartner.id}`
            }
            return '/products/personal'
      }
    }

    const shoppingCartExists = () => {
      if (shoppingBasketId && uiStore.shoppingCarts && productsStore.basket) {
        if (uiStore.shoppingCarts.map(obj => obj.id).includes(shoppingBasketId)) return true
      }
      return false
    }

    return (
      <React.Fragment>
        <StyledSubHeader className="subHeader-v1 with-progress">
          <SubheaderTitle withBackButton={shoppingCartExists()} defaultBackTo={backToLink()} goBackLabel={t('shoppingCart:backtoShopping')} />
          <div className="flex-grow-1 justify-content-center ps-4 pe-4">
            <ShoppingCartProgress>
              <ul>
                <li className="active">
                  <div className="bubble-wrapper">{t('SubheaderProgress:shoppingCart')}</div>
                </li>
                <li>
                  <div className="bubble-wrapper">{t('SubheaderProgress:shipping')}</div>
                </li>
                <li>
                  <div className="bubble-wrapper">{t('SubheaderProgress:orderCompletion')}</div>
                </li>
                <li>
                  <div className="bubble-wrapper">{t('SubheaderProgress:done')}</div>
                </li>
              </ul>
            </ShoppingCartProgress>
          </div>
          {basket.targetOrderProcessType === 'personal' && (
            <EmployeeData />
          )}
        </StyledSubHeader>
        <LinearGradient>
          <StyledFlexWrapper>
            <StyledContentArea noPadding noPaddingY>
              <div className='d-flex justify-content-between align-items-end mb-4'>
                <div>
                  <StyledH1>
                    {t('shoppingCart')}
                    {basket.targetOrderProcessType === 'storeHouse' /*&& !isEmptyGuid(basket.targetStorehouse.id)*/ && ` ${t('for')} ${basket.targetStorehouse.displayName}`}
                    {basket.targetOrderProcessType === 'costCenter' /*&& !isEmptyGuid(basket.targetCostCenter.id)*/ && ` ${t('for')} ${basket.targetCostCenter.displayName}`}
                    {productsStore.targetEmployee.id && ` ${t('for')} ${productsStore.targetEmployee.firstName} ${productsStore.targetEmployee.lastName}`}
                  </StyledH1>
                </div>
                <div>
                  <span>{basketArticlesAmount} {t('articles', { count: basketArticlesAmount })}</span>
                </div>
              </div>
              <PageLoadingLayer isLoading={productsStore.loadOrCreateBasketIsLoading}
                loadingText={t('shoppingCart:loadingShoppingBasket')}>
                {productsStore.basket ? <div>
                  <h2 className='mb-5'>{basket.targetActivity && basket.targetActivity.displayName}</h2>
                  <div className='mb-5'>
                    <ArticlesList articles={articles} />
                  </div>
                </div> : null}
              </PageLoadingLayer>
            </StyledContentArea>
            <TestSidebarRight>
              <ShoppingCartSummary />
            </TestSidebarRight>
          </StyledFlexWrapper>
          <BigFooter />
        </LinearGradient>
      </React.Fragment>
    )
  }
}

export default withTheme(withTranslation()(withRouter(inject('uiStore', 'productsStore', 'teamOrderStore', 'profileStore')(observer(ShoppingCartPage)))))
