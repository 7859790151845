import React from 'react'
import { useTranslation } from 'react-i18next'
import { Subheader } from '../../Page/logix2020/Subheader'
import SubheaderForm from '../../Page/logix2020/SubheaderForm'
import EmployeeUserSelector from '../Subheader/EmployeeUserSelector'


const EditSubheader = () => {
  const { t } = useTranslation()

  return (
    <Subheader className="subHeader-v1">
      <div className="flex-grow-1">
        <SubheaderForm
          title={t('Subheader:editEmployee')}
          goBackLabel={t('Subheader:overviewEmployees')}
        />
      </div>
      <EmployeeUserSelector />
    </Subheader>
  )
}

export default EditSubheader
