import React, { useState, useEffect, useMemo, memo, useCallback } from "react"
import BootstrapTable from 'react-bootstrap-table-next'
import { useTranslation } from 'react-i18next'
import UniversalTableWrapperTest from '../../../Table/UniversalTableWrapperTest'
import TableProvider, { useTableState } from '../../../Table/TableProvider'
import { Collapse } from "react-bootstrap"
import { StyledCollapseTrigger } from "../formHelper"
import { ArrowBigIcon } from "../../../Icons/ArrowBigIcon"
import { colors } from '../../../../styles/colors'
import CustomDatePicker from "../../../Forms/CustomDatePicker"
import apiClient from "../../../../utils/api/apiClient"
import { APIEndpoints } from "../../../../utils/api/apiConfig"
import { emptyDate } from "../../../../utils/emptyDate"
import { getIsoDate } from "../../../../utils/getIsoDate"

const GroupMemberTableInner = ({
  collapsible,
  accountData,
  setAccountDataByKey }) => {
  const { t } = useTranslation()
  const [loadedGroups, setLoadedGroups] = useState([])
  const [opened, setOpened] = useState(!collapsible)

  const { tableRef } = useTableState()


  const assignedGroups = accountData.assignedGroups

  useEffect(() => {
    loadGroups()
  }, [])

  const loadGroups = async () => {
    let result = await apiClient.getJson(APIEndpoints.groups)
    if (result) {
      setLoadedGroups(result)
    }
  }

  const handleClickCollapse = useCallback(() => {
    setOpened(prev => !prev)
  }, [setOpened])

  const handleIsAssignedCheckbox = useCallback((row, cell) => {
    let newValues = [...assignedGroups]
    let foundIndex = newValues.findIndex(group => group.groupId === row.id)

    if (foundIndex > -1) {
      newValues.splice(foundIndex, 1);
    } else {
      let newGroup = {
        groupId: row.id,
        activePeriod: {
          activeFrom: emptyDate,
          activeTo: emptyDate,
          isAlwaysActive: true
        }
      }
      newValues.push(newGroup)
    }
    setAccountDataByKey("assignedGroups", newValues)
  }, [assignedGroups, setAccountDataByKey])

  const handleAlwaysActiveCheckbox = useCallback((row, cell) => {
    let newValues = [...assignedGroups]
    let foundIndex = newValues.findIndex(group => group.groupId === row.id)
    // here we know that it has to exist in the assignedGroups already, since isAssigned has to be true
    newValues[foundIndex].activePeriod.isAlwaysActive = !newValues[foundIndex].activePeriod.isAlwaysActive

    // set the current date as default for both activeFrom/activeTo if isAlwaysActive is false
    if (!newValues[foundIndex].activePeriod.isAlwaysActive) {
      let currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)
      newValues[foundIndex].activePeriod.activeFrom = getIsoDate(currentDate)
      newValues[foundIndex].activePeriod.activeTo = getIsoDate(currentDate)
    } else {
      newValues[foundIndex].activePeriod.activeFrom = emptyDate
      newValues[foundIndex].activePeriod.activeTo = emptyDate
    }
    setAccountDataByKey("assignedGroups", newValues)
  }, [assignedGroups, setAccountDataByKey])

  const handleChangeDate = useCallback((row, activePeriod) => date => {
    date.setHours(0, 0, 0, 0)
    let newDate = getIsoDate(date);
    let newValues = [...assignedGroups]
    let foundIndex = newValues.findIndex(group => group.groupId === row.id)

    activePeriod === 'activeFrom'
      ? newValues[foundIndex].activePeriod.activeFrom = newDate
      : newValues[foundIndex].activePeriod.activeTo = newDate

    setAccountDataByKey("assignedGroups", newValues)
  }, [assignedGroups, setAccountDataByKey])

  const columns = useMemo(() => ([
    {
      dataField: 'isAssigned',
      text: '-',
      sort: true,
      headerStyle: { width: '50px' },
      formatter: (cell, row, rowIndex, extraProps) => (
        <span>
          <input
            type="checkbox"
            id={`row.id-isAssigned`}
            name="cbIsAssigned"
            checked={cell}
            onChange={() => extraProps.handleIsAssignedCheckbox(row, cell)}
          />
        </span>
      ), formatExtraData: {
        handleIsAssignedCheckbox
      }
    },
    {
      dataField: 'name',
      text: t('EmployeeFormMainData:group'),
      sort: true
    },
    {
      dataField: 'description',
      text: t('EmployeeFormMainData:description'),
      sort: true,
    },
    {
      dataField: 'isAlwaysActive',
      text: t('EmployeeFormMainData:alwaysActive'),
      sort: true,
      formatter: (cell, row, rowIndex, extraProps) => (
        <span>
          <input
            type="checkbox"
            id={`row.id-alwaysActive`}
            name="cbAlwaysActive"
            checked={cell}
            disabled={!row.isAssigned}
            onChange={() => extraProps.handleAlwaysActiveCheckbox(row, cell)}
          />
        </span>
      ), formatExtraData: {
        handleAlwaysActiveCheckbox
      }
    },
    {
      dataField: "activeFrom",
      text: t('EmployeeFormMainData:activeFrom'),
      sort: true,
      formatter: (cell, row, rowIndex, extraProps) => {
        return (
          row.isAssigned && !row.isAlwaysActive ?
            <CustomDatePicker
              dateFormat="dd.MM.yyyy"
              placeholderText="date"
              onChange={handleChangeDate(row, 'activeFrom')}
              showYearDropdown
              disabled={!row.isAssigned || (row.isAssigned && row.isAlwaysActive)}
              selected={cell}  // or getSelectedDate(row.id, 'activeFrom')? (previous solution)
            // isInvalid={!!formErrors['mainData.dateStartEmployment']}
            /> : ''
        );
      },
    },
    {
      dataField: "activeTo",
      text: t('EmployeeFormMainData:activeTo'),
      sort: true,
      formatter: (cell, row, rowIndex, extraProps) => {
        return (
          row.isAssigned && !row.isAlwaysActive ?
            <CustomDatePicker
              dateFormat="dd.MM.yyyy"
              placeholderText="date"
              onChange={handleChangeDate(row, 'activeTo')}
              showYearDropdown
              disabled={!row.isAssigned || (row.isAssigned && row.isAlwaysActive)}
              selected={cell}  // or getSelectedDate(row.id, 'activeTo')? (previous solution)
            // isInvalid={!!formErrors['mainData.dateStartEmployment']}
            /> : ''
        );
      },
    },
  ]), [handleAlwaysActiveCheckbox, handleChangeDate, handleIsAssignedCheckbox, t])

  const isAssignedCheck = useCallback((id) => {
    let isAssigned = !!assignedGroups.find(group => group.groupId === id)
    return isAssigned;
  }, [assignedGroups])

  const isAlwaysActiveCheck = useCallback((id) => {
    let isAlwaysActive = false;
    let foundGroup = assignedGroups.find(group => group.groupId === id)
    if (foundGroup) {
      isAlwaysActive = foundGroup.activePeriod.isAlwaysActive;
    }
    return isAlwaysActive;
  }, [assignedGroups])

  const getSelectedDate = useCallback((id, activePeriod) => {
    let selectedDate = new Date()
    let foundGroup = assignedGroups.find(group => group.groupId === id)
    if (foundGroup) {
      if (activePeriod === 'activeFrom' && foundGroup.activePeriod.activeFrom !== emptyDate) {
        selectedDate = new Date(foundGroup.activePeriod.activeFrom)
      }
      else if (activePeriod === 'activeTo' && foundGroup.activePeriod.activeTo !== emptyDate) {
        selectedDate = new Date(foundGroup.activePeriod.activeTo)
      }
    }
    return selectedDate
  }, [assignedGroups])

  const preparedData = useMemo(() => {
    return loadedGroups.map(item => ({
      ...item,
      isAssigned: isAssignedCheck(item.id),
      isAlwaysActive: isAlwaysActiveCheck(item.id),
      activeFrom: getSelectedDate(item.id, 'activeFrom'),
      activeTo: getSelectedDate(item.id, 'activeTo')
    }))
  }, [getSelectedDate, isAlwaysActiveCheck, isAssignedCheck, loadedGroups])


  return (
    <>
      {collapsible ? (
        <StyledCollapseTrigger onClick={handleClickCollapse}>
          <h2 className="h1"><ArrowBigIcon variant={opened ? 'down' : 'right'} color={colors.textColor} />{t('EmployeeFormMainData:groupMembership')}</h2>
        </StyledCollapseTrigger>
      ) : (
        <h2 className="h1">{t('EmployeeFormMainData:groupMembership')}</h2>
      )}
      <Collapse in={opened}>
        <div className="spacing-down">
          <UniversalTableWrapperTest>
            <BootstrapTable
              ref={tableRef}
              noDataIndication={() => <div className="no-data-placeholder">{t('officeAdmin:noGroupAssignments')}</div>}
              bootstrap4
              keyField="id"
              hover
              data={preparedData}
              columns={columns}
              bordered={false}
              wrapperClasses='group-member-table'
            //wrapperClasses="table-responsive" 
            />
          </UniversalTableWrapperTest>
        </div>
      </Collapse>
    </>
  )
}

const GroupMemberTable = memo(({ ...rest }) => {
  return (
    <TableProvider>
      <GroupMemberTableInner {...rest} />
    </TableProvider>
  )
})

export default GroupMemberTable