import { StyledCircleCheckIconWrapper } from './StyledCircleCheckIconWrapper'
import { CircleCheckIcon } from '../../../Icons/CircleCheckIcon'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { emptyGuid } from '../../../../utils/emptyGuid'

const AccessibleOfficesCheck = ({ accessibleOffices, mainOfficeId }) => {
    const [isValid, setIsValid] = useState(false)
    useEffect(() => {
        let mainOfficeExists = mainOfficeId && (mainOfficeId !== emptyGuid)
        let accessibleOfficesExist = !!accessibleOffices.length
        setIsValid(mainOfficeExists || accessibleOfficesExist)
    }, [accessibleOffices, mainOfficeId])

    return (
        <>
            <StyledCircleCheckIconWrapper>
                <CircleCheckIcon variant={isValid ? 'optional' : 'none'} />
            </StyledCircleCheckIconWrapper>
            <span>{t('administration:officeadministration')}</span>
        </>
    )
}

export default AccessibleOfficesCheck