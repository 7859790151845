import React, { useState, useCallback, useEffect } from 'react'
import { Button, Col, Form, Row, Alert } from 'react-bootstrap'
import CustomDatePicker from '../../../Forms/CustomDatePicker'
import { useTranslation } from 'react-i18next'
import { useEmployeeHealthDataState } from '../../../../stores/EmployeeHealthDataProvider'
import { Spinner } from 'react-bootstrap'
import DeleteModal from '../../../Modals/DeleteModal'
import { StyledValidationMessage, StyledCollapseTrigger } from '../formHelper'
import { ArrowBigIcon } from '../../../Icons/ArrowBigIcon'
import { colors } from '../../../../styles/colors'
import styled from 'styled-components'
import ArraySelect from '../../../Select/ArraySelect'
import { emptyGuid } from '../../../../utils/emptyGuid'
import { getIsoDate } from '../../../../utils/getIsoDate'
import { MdLibraryAdd } from 'react-icons/md'

const StyledCheckUps = styled.div`
//added row center for delete icon in checkups
.delete-row-center {
    padding: 0;
    display: flex;
    justify-content: center;
   
    .delete-div{
      display: inline-block;
    }
  }
  //added row for border in checkups
  .added-row {
    label {
      display: block;
    }
    margin-bottom: 40px;
    border-bottom: 1px solid ${colors.gray2};
  }
  `
const CheckUpsData = () => {

    const [opened, setOpened] = useState(false)
    const { t } = useTranslation()
    const template = {
        checkUpType: {
            displayName: '',
            id: 0
        },
        checkUpResult: {
            displayName: '',
            id: emptyGuid
        },
        comment: '',
        dateOfCheckUp: '',
        displayName: '',
        foreignKey: '' //referenz
    }

    const {
        deleteCheckUp,
        setCheckupsData,
        checkUpsData,
        checkupTypes,
        setFrontendValidation,
        frontendValidation,
        setNextTests,
        resetFrontendValidationByKey,
        nextTests
    } = useEmployeeHealthDataState()

    const addRow = () => {
        addRowHealthCheck()
        addRowFrontendValidation()
        addRowNextTests()
    }
    const addRowHealthCheck = () => {
        const newHealthCheck = [...checkUpsData]
        newHealthCheck.push(template)
        setCheckupsData(newHealthCheck)
    }
    const addRowNextTests = () => {
        setNextTests([...nextTests, ''])
    }
    const addRowFrontendValidation = () => {
        const newData = [...frontendValidation.checkUps]
        newData.push({ checkUpType: '', dateOfCheckUp: '', checkUpResult: '', nextTest: '' })
        setFrontendValidation({ ...frontendValidation, checkUps: newData })
    }
    const deleteRow = (id, index) => {
        deleteCheckUp(id, index)
        deleteRowHealthCheck(index)
        deleteRowNextTests(index)
        deleteRowFrontendValidation(index)
    }
    const deleteRowHealthCheck = (index) => {
        const newHealthCheck = [...checkUpsData]
        newHealthCheck.splice(index, 1)
        setCheckupsData(newHealthCheck)
    }
    const deleteRowNextTests = (index) => {
        const newNextTests = [...nextTests]
        newNextTests.splice(index, 1)
        setNextTests(newNextTests)
    }
    const deleteRowFrontendValidation = (index) => {
        const newData = [...frontendValidation.checkUps]
        newData.splice(index, 1)
        setFrontendValidation({ ...frontendValidation, checkUps: newData })
    }
    const handleChangeCheckUpResult = index => e => {
        const selectedCheckupType = e.target.value
        const selectedCheckupName = e.target.name
        var selected = {
            displayName: '',
            id: selectedCheckupType
        }
        if (selectedCheckupType === emptyGuid) {
            selected = null
        }
        const newHealthCheck = [...checkUpsData]
        newHealthCheck[index][selectedCheckupName] = selected
        setCheckupsData(newHealthCheck)
        resetFrontendValidationByKey('checkUpResult', index)
    }

    const handleChangeCheckupType = index => e => {
        const selectedCheckupType = e.target.value
        const checkUpTypeIndex = checkupTypes.findIndex(obj => obj.checkUpType.id === parseInt(selectedCheckupType))
        var Name = ''
        if (checkUpTypeIndex > 0) {
            Name = checkupTypes[checkUpTypeIndex].checkUpType.displayName
        }
        const selected = {
            displayName: Name,
            id: parseInt(selectedCheckupType)
        }

        //set checkUpType and reset CheckUpResult
        const newHealthCheck = [...checkUpsData]
        newHealthCheck[index]["checkUpType"] = selected
        newHealthCheck[index]["checkUpResult"] = null
        setCheckupsData(newHealthCheck)
        resetFrontendValidationByKey('checkUpType', index)
    }

    const handleChangeDateArray = (index, name) => date => {
        if (date instanceof Date) {
            const newHealthCheck = [...checkUpsData]
            const preparedDate = getIsoDate(date)
            newHealthCheck[index][name] = preparedDate
            setCheckupsData(newHealthCheck)
        }
        resetFrontendValidationByKey('dateOfCheckUp', index)
    }

    const handleChangeNextTest = (index) => date => {
        if (date instanceof Date) {
            const newNextTests = [...nextTests]
            const preparedDate = getIsoDate(date)
            newNextTests[index] = preparedDate
            setNextTests(newNextTests)
        }
        resetFrontendValidationByKey('nextTest', index)
    }

    const handleChange = index => e => {
        const newHealthCheck = [...checkUpsData]
        newHealthCheck[index][e.target.name] = e.target.value
        setCheckupsData(newHealthCheck)
    }

    //first index of check-up from the past
    const getFirstPastIndex = useCallback(() => {
        if (checkUpsData !== null) {
            const index = checkUpsData.findIndex(obj => obj.id && (new Date(obj.dateOfCheckUp) <= new Date()))
            return index >= 0 ? index : checkUpsData.length
        }
        return -1
    }, [checkUpsData])

    const handleClickCollapse = useCallback(() => {
        setOpened(prev => {
            return !prev
        })
    }, [setOpened])

    const errorInPastCheckUps = useCallback(() => {
        if (frontendValidation && frontendValidation.checkUps) {
            var lastErrorIndex = -1
            frontendValidation.checkUps.forEach((obj, index) => {
                if ((obj.checkUpResult || obj.checkUpType || obj.dateOfCheckUp || obj.nextTest) && checkUpsData[index].id) { lastErrorIndex = index }
            })
            if (getFirstPastIndex() >= 0 && lastErrorIndex >= getFirstPastIndex()) { return true }
        }
        return false
    }, [checkUpsData, frontendValidation, getFirstPastIndex])

    useEffect(() => {
        if (errorInPastCheckUps()) {
            setOpened(true)
        }
    }, [errorInPastCheckUps, frontendValidation])

    return (
        <StyledCheckUps>
            <h1>{t('EmployeeFormHealthData:healthChecks')}</h1>
            {checkUpsData === null || checkupTypes === null
                ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
                :
                <>
                    {checkUpsData.length === 0 && (
                        <Alert variant="info">{t('EmployeeFormHealthData:noHealhCheck')}</Alert>
                    )}

                    {checkUpsData.map((item, index) => {
                        return (
                            <div key={index}>
                                {getFirstPastIndex() === index && <StyledCollapseTrigger onClick={handleClickCollapse}>
                                    <h1><ArrowBigIcon variant={opened ? 'down' : 'right'} color={colors.textColor} />{t('EmployeeFormHealthData:pastHealthChecks')}
                                    </h1>
                                </StyledCollapseTrigger>}
                                <Row className="added-row" hidden={index >= getFirstPastIndex() && item.id && !opened}>
                                    <Col sm="11">
                                        <Row>
                                            <Col sm="6" lg="3">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t('EmployeeFormHealthData:healthCheck')}</Form.Label>
                                                    <ArraySelect
                                                        name="checkUpType"
                                                        onChange={handleChangeCheckupType(index)}
                                                        isInvalid={frontendValidation && frontendValidation.checkUps[index] && frontendValidation.checkUps[index].checkUpType}
                                                        value={item.checkUpType.id}
                                                        returnEvent
                                                        defaultOption={{ name: t('pleaseSelect'), value: "0" }}
                                                        options={checkupTypes}
                                                        otherNameKey="checkUpType.displayName"
                                                        otherValueKey="checkUpType.id"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {frontendValidation && frontendValidation.checkUps[index] && frontendValidation.checkUps[index].checkUpType}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="6" lg="3">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t('EmployeeFormHealthData:testDate')}</Form.Label>
                                                    <CustomDatePicker
                                                        dateFormat="dd.MM.yyyy"
                                                        placeholderText={t('date')}
                                                        onChange={handleChangeDateArray(index, "dateOfCheckUp")}
                                                        showYearDropdown
                                                        selected={(item.dateOfCheckUp && new Date(item.dateOfCheckUp)) || ''}
                                                        isInvalid={frontendValidation && frontendValidation.checkUps[index] && frontendValidation.checkUps[index].dateOfCheckUp}
                                                    />
                                                    {frontendValidation && frontendValidation.checkUps[index] && frontendValidation.checkUps[index].dateOfCheckUp ?
                                                        <StyledValidationMessage>
                                                            {frontendValidation && frontendValidation.checkUps[index] && frontendValidation.checkUps[index].dateOfCheckUp}
                                                        </StyledValidationMessage>
                                                        : ""
                                                    }
                                                </Form.Group>
                                            </Col>
                                            <Col sm="6" lg="3">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t('EmployeeFormHealthData:testResult')}</Form.Label>
                                                    <ArraySelect
                                                        name="checkUpResult"
                                                        onChange={handleChangeCheckUpResult(index)}
                                                        isInvalid={frontendValidation && frontendValidation.checkUps[index] && frontendValidation.checkUps[index].checkUpResult}
                                                        value={item.checkUpResult ? item.checkUpResult.id : ''}
                                                        defaultOption={{ name: t('pleaseSelect'), value: emptyGuid }}
                                                        defaultOptionIsSelectable
                                                        options={checkUpsData[index].checkUpType.id > 0
                                                            ? checkupTypes[(checkupTypes.findIndex(obj => obj.checkUpType.id === checkUpsData[index].checkUpType.id))].possibleResults
                                                            : []
                                                        }
                                                        returnEvent
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {frontendValidation && frontendValidation.checkUps[index] && frontendValidation.checkUps[index].checkUpResult}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="6" lg="3">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t('EmployeeFormHealthData:nextTest')}</Form.Label>

                                                    <CustomDatePicker
                                                        dateFormat="dd.MM.yyyy"
                                                        placeholderText={t('date')}
                                                        onChange={handleChangeNextTest(index, 'nextTest')}
                                                        showYearDropdown
                                                        isInvalid={frontendValidation && frontendValidation.checkUps[index] && frontendValidation.checkUps[index].nextTest}
                                                        selected={nextTests[index] ? new Date(nextTests[index]) : ""}
                                                    />
                                                    {frontendValidation && frontendValidation.checkUps[index] && frontendValidation.checkUps[index].nextTest ?
                                                        <StyledValidationMessage>
                                                            {frontendValidation && frontendValidation.checkUps[index] && frontendValidation.checkUps[index].nextTest}
                                                        </StyledValidationMessage>
                                                        : ""
                                                    }
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row >

                                            <Col sm="12" lg="9">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t('comment')}</Form.Label>
                                                    <Form.Control as="textarea" rows="1" name="comment" placeholder={t('comment')} onChange={handleChange(index)} value={item.comment} />
                                                </Form.Group>
                                            </Col>
                                            <Col sm="12" lg="3">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t('EmployeeFormHealthData:reference')}</Form.Label>
                                                    <Form.Control as="input" name="foreignKey" onChange={handleChange(index)} value={item.foreignKey} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col className="delete-row-center" >
                                                <div className="delete-div">

                                                    <Form.Label >&nbsp;</Form.Label>
                                                    <DeleteModal
                                                        deleteFunction={() => deleteRow(item.id, index)}
                                                        ModalTitle="CheckUp Löschen"
                                                        BodyPart1="Wollen Sie den Check-up wirklich löschen?"
                                                        BodyPart2={item.checkUpType.displayName}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })}
                    <Button onClick={addRow}><MdLibraryAdd className="me-3" />{t('EmployeeFormHealthData:addAdditionalHealthCheck')}</Button>
                </>
            }
        </StyledCheckUps>
    )
}
export default CheckUpsData