import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components';
import { ArrowBigIcon } from '../../Icons/ArrowBigIcon'
import { FcOpenedFolder } from 'react-icons/fc'
import { getLanguageValue } from '../../../utils/getLanguageValue'
import { colors } from '../../../styles/colors';
import GroupAmounts from './GroupAmounts';
import { t } from 'i18next';
const StyledGroup = styled.div`
  position:relative;
  padding: 1rem;
  margin:2px;
  background:${colors.gray6};
  ${props => props.autoScrollHere ? `border:3px dashed ${props.theme.primaryColor}` : ''};
  `
const GroupCard = ({ group, groupArticles, open, handleOpen, entitlementId, autoScrollTo }) => {

  const contigentName = getLanguageValue(group.name.values)
  const autoScrollHere = useMemo(() => autoScrollTo && !autoScrollTo.articleId && (group.id === autoScrollTo.groupId), [autoScrollTo, group.id])
  useEffect(() => {
    if (autoScrollHere) {
      let element = document.querySelector(`[data-id="${`${group.id}`}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [autoScrollHere, group.id])
  return (
    <StyledGroup data-id={group.id} autoScrollHere={autoScrollHere} >
      <div className="d-flex align-items-center p-3">
        {groupArticles.length ? <span role="button" className='d-flex' onClick={handleOpen}>
          <ArrowBigIcon variant={open ? 'down' : 'right'} />
        </span>
          : null}
        <FcOpenedFolder size={25} />
        <h4 className="ps-2 mb-0">{group.isVirtualGroup ? t('contingentTemplates:virtualGroup') : contigentName}</h4>
      </div>
      <GroupAmounts
        entitlementId={entitlementId}
        group={group}
      />
    </StyledGroup>
  )
}

export default GroupCard