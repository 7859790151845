export function getErrorMessageFromApiResponse(e) {
  let msg = null
  let errorDetails = []
  if (e.response && e.response.data && e.response.data.errors) {
    e.response.data.errors.forEach(error => {
      errorDetails.push(error.detail)
    })
    if (errorDetails.length) {
      msg = errorDetails.join('\n\n')
    }
  }
  else if (e.response && e.response.data && e.response.data.message) {
    msg = e.response.data.message
  }
  else if (e.response && e.response.data) {
    msg = e.response.data
  }
  else {
    msg = "something went wrong"
  }
  return msg
}

export function setPointerErrorsFromApiResponse(e) {
  let newFormErrors = {}

  if (e.response && e.response.data && e.response.data.errors) {
    const errorsApi = e.response.data.errors
    errorsApi.forEach(item => {
      if (item.source && item.source.pointer) {
        newFormErrors[item.source.pointer.split("/").join("")] = item.detail
      }
      else { console.log("Error:" + item.detail) }
    })
  }
  return newFormErrors

}

export function settingValueToType(obj) {
  if (obj && obj.settingType) {
    let settingType = obj.settingType.toLowerCase()
    if (obj && settingType === 'boolean') {
      if (obj.settingValue.toLowerCase() === 'true') { return true }
      else return false
    }
    if (obj && settingType === 'string') { return obj.settingValue }
    if (obj && settingType === 'integer') { return parseInt(obj.settingValue) }
  }
  return null
}

export function checkLogoOnServer(imageSrc) {
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.onload = () => resolve(true)
    img.onerror = (reject)
    img.src = imageSrc
  })
}
