import React from 'react'
import { Subheader } from '../../Page/logix2020/Subheader'
import { useTranslation } from 'react-i18next'
import { GoBackBtn } from '../../Buttons/GoBackBtn'
import EmployeeUserSelector from '../Subheader/EmployeeUserSelector'

const AddSubheader = () => {
  const { t } = useTranslation()

  return (
    <Subheader className="subHeader-v1">
      <div className="flex-grow-1">
        <div>
          <GoBackBtn color="white" to="/employees">
            {t('Subheader:overviewEmployees')}
          </GoBackBtn>
          <div className="subheader-title-small">{t('Subheader:addEmployee')} </div>
        </div>
      </div>
      <EmployeeUserSelector />
    </Subheader>
  )
}

export default AddSubheader
