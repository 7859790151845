import styled from 'styled-components'
import MenuCollapseIcon from '../../assets/icons/menu_collapse.svg'
import MenuCollapseIconWhite from '../../assets/icons/menu_collapse_white.svg'
import { colors } from '../../styles/colors'

const StyledNav = styled.div`
    height: 50px;
    display: flex;
    align-items: center;

    & > a {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 40px;
      border: none;
      background-color: transparent;
    }

    .nav-pills, .dropdown {
      height: 100%;
    }

    .nav-link {
      color: #000;
      height: 100%;
      display: flex;
      align-items: center;
      border-radius: 0;
     
      &::after {
        flex: 0 0 auto;
        background:  url(${MenuCollapseIcon});
        border: none;
        width: 12px;
        height: 12px;
        display: block;
        margin-left: 10px;
      }
    }

    .nav-link.active {
      background-color: #b2b2b2;
      color: #FFF;
      border-bottom: 1px solid ${props => props.theme.primaryColor};

      &:after {
        background:  url(${MenuCollapseIconWhite});
      }
    }

    .show > .nav-link {
      background: ${colors.navActiveBackground};
      &:after {
        background:  url(${MenuCollapseIconWhite});
      }
    }

    .dropdown-menu{
      background-color: ${colors.navActiveBackground};
      color: #FFF;
      border-radius: 0;
      margin-top: 0;
      padding: 0;
      border-color: ${colors.navActiveBackground};
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .dropdown-item{
        color: #FFF;
        padding: 1.1rem 1.2rem;
        min-width: 235px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        font-size: 14px;
        font-weight: bold;

        &:hover, &:active, &.active {
         background-color: inherit;
         color: inherit;
        }
      }
    }

    .dropdown-header {
      background-color: transparent;
      color: #FFF;
      font-size: 14px;
      font-weight: bold;
    }

    .profile-dropdown {
      height: 100%;
      margin-right: 10px;

      .dropdown-menu .dropdown-item {
        min-width: 185px;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
      }
    }
    .nav-link:hover {
      background-color: ${colors.navActiveBackground};
      color: white;
      
    }
    .nav-link:hover:after {
      background: url(${MenuCollapseIconWhite});
    }
   
`
export default StyledNav
