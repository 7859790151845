import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../styles/colors';


export const StyledFormCol = styled(Col)`
  &.notOrderable::before {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(255,255,255, .8);
    position: absolute;
    z-index: 9;
  }

  &.notOrderable::after {
    content: attr(data-max-mount-reached);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: ${colors.red};
    z-index: 10;
  }
`