import React, { useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from '../../translation/i18n'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import useStores from '../../stores/useStore'
import { useAppointmentsAdministrationState } from '../../stores/Administration/AppointmentsAdministrationProvider'
import OverlayRight, { StyledOverlayInnerWrapper } from '../Page/logix2020/OverlayRight'
import OverlayContainer from '../Page/logix2020/OverlayContainer'
import ModalComponent from '../Modals/ModalComponent'
import { StyledDataList } from '../DataList/dataList'
import { emptyGuid } from '../../utils/emptyGuid'


const CreateNewReportContent = (props) => {
  const {
    appointmentDetails, removeCanceledEvent
  } = useAppointmentsAdministrationState()
  const { profileStore } = useStores()
  const { profile } = profileStore
  const navigate = useNavigate();
  const mountedRef = useRef(true)
  const { t } = useTranslation()
  const [showCancelAppointmentModal, setShowCancelAppointmentModal] = useState(false);

  const formatSelectedDay = (startTime) => {
    let selectedDate = startTime.substring(0, 10);
    let date = new Date(selectedDate).toLocaleDateString(
      i18n.language,
      {
        weekday: 'long',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
    let time = (appointmentDetails.timeSlot.id !== emptyGuid) && `${appointmentDetails.timeSlot.hour < 10 ? "0" + appointmentDetails.timeSlot.hour : appointmentDetails.timeSlot.hour}:${appointmentDetails.timeSlot.minute < 10 ? '0' : ''}${appointmentDetails.timeSlot.minute} Uhr`

    return `${date} - ${time}`;
  };

  const editAppointment = () => {
    //redirect to appointment page
    let path = `/appointment/${profile.id}/${appointmentDetails.id}`;
    navigate(path)
  }

  const cancelAppointment = () => {
    apiClient.deleteJson(APIEndpoints.appointment(appointmentDetails.id)).then(() => {
      if (!mountedRef.current) return null
      removeCanceledEvent()
    })
  }

  return (
    <>
      <StyledDataList className="content">
        <ul className="data-list__data">
          <li className="data-list__name h2">
            {formatSelectedDay(appointmentDetails.startTime)}
          </li>
          <li className='data-list__entry'>
            <div className="data-list__label">{t('cabinTypes:appointmentDuration')}:</div>
            <div className="data-list__value">{appointmentDetails && `${appointmentDetails.startTime.substring(11, 16)} - ${appointmentDetails.endTime.substring(11, 16)} Uhr`}</div>
          </li>
          <li className='data-list__entry'>
            <div className="data-list__label">{t('appointment:bookedBy')}:</div>
            <div className="data-list__value">{appointmentDetails && appointmentDetails.targetBusinesspartner.displayName}</div>
          </li>
          <li className='data-list__entry'>
            <div className="data-list__label">{t('cabinTypes:cabin')}:</div>
            <div className="data-list__value">{appointmentDetails && appointmentDetails.cabin.displayName}</div>
          </li>
          <li className='data-list__entry'>
            <div className="data-list__label">{t('appointmentTypes:appointmentType')}:</div>
            <div className="data-list__value">{appointmentDetails && appointmentDetails.appointmentType.displayName}</div>
          </li>
          <li className='data-list__entry'>
            <div className="data-list__label">{t('appointment:telephone')}:</div>
            <div className="data-list__value">{appointmentDetails && appointmentDetails.phone}</div>
          </li>
          <li className='data-list__entry'>
            <div className="data-list__label">{t('comment')}:</div>
            <div className="data-list__value">{appointmentDetails && appointmentDetails.comment.length > 0 ? appointmentDetails.comment : '-'}</div>
          </li>
        </ul>
      </StyledDataList>

      <StyledDataList className="footer p-4">
        <div className="mb-3">
          <Button className="w-100" type="button" size="sm" variant="secondary" onClick={() => setShowCancelAppointmentModal(true)}>{t('appointment:cancelAppointment')}</Button>
        </div>
        <div className="mb-3">
          <Button className="w-100" type="button" size="sm" variant="secondary"><a className="text-white" href={`mailto:${appointmentDetails.email}`}>{t('appointment:contactParticipant')}</a></Button>
        </div>
        <div className="mb-3">
          <Button className="w-100" type="button" size="sm" variant="secondary" onClick={() => editAppointment()}>{t('appointment:editAppointment')}</Button>
        </div>
      </StyledDataList>

      {showCancelAppointmentModal &&
        <ModalComponent
          ModalTitle={t('appointment:cancelAppointment')}
          functionButton1={() => cancelAppointment()}
          textButton1={t('orderDialog:cancel')}
          variantButton1="danger"
          variantBody="alert-danger"
          BodyPart1={t('appointment:areYouSureCancel')}
          functionCancel={() => setShowCancelAppointmentModal(false)}
        />
      }
    </>
  )
}

const CreateRightOverlay = ({ visible, onClose, ...rest }) => {
  const { t } = useTranslation()
  return (
    <OverlayContainer>
      <OverlayRight visible={visible} onClose={onClose} headerTitle={t('overview')}>
        <StyledOverlayInnerWrapper>
          {visible && <CreateNewReportContent {...rest} />}
        </StyledOverlayInnerWrapper>
      </OverlayRight>
    </OverlayContainer>
  )
}

export default CreateRightOverlay