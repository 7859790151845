import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { Link } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import { checkLogoOnServer } from '../../utils/api/apiUtils'
import useMountEffect from '../../utils/hooks/useMountEffect'
import { inject, observer } from 'mobx-react'

const StyledHomeLink = styled(Link)`
  display: flex;
  flex: 1 1 0;
`

const StyledNavbarBrand = styled(Navbar.Brand)`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0px;
  max-width: 150px;
  margin-right:1.5rem;
  &.login{
      height:75px;
      max-width: ${props => props.theme.userLogin.logoMaxWidth ? props.theme.userLogin.logoMaxWidth : '150px'};
  }
`

const StyledNavBarLogo = styled.img`
  max-height:  ${props => props.theme.userLogin.logoMaxHeight ? props.theme.userLogin.logoMaxHeight : '100%'};
  width: auto;
  max-width: 100%;
`
const BrandLogo = inject('profileStore')(observer((props) => {
  const { classes, checkMandatorImage, profileStore } = props

  const [logo, setLogo] = useState(null)
  const theme = useTheme()

  const checkImg = async () => {
    let apiUrl = process.env.REACT_APP_API_URL
    try {
      let profile = await profileStore.loadProfile()
      let mandatorName = profile?.mandator?.displayName
      let serverLogoUrl = apiUrl.replace(/logix_backend\/$/, '') + `bilder/${mandatorName}.png`;

      await checkLogoOnServer(serverLogoUrl);
      setLogo(serverLogoUrl)
    }
    catch (e) {
      setLogo(theme.logo)
    }
  }

  useMountEffect(() => {
    if (checkMandatorImage) {
      checkImg()
    }
    else setLogo(theme.logo)
  })

  if (!logo) {
    return null
  }

  return (
    <StyledHomeLink to='/'>
      <StyledNavbarBrand className={classes} >
        <StyledNavBarLogo className={classes} src={logo} />
      </StyledNavbarBrand>
    </StyledHomeLink>
  )
}))

export default BrandLogo
