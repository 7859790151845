import React, { useCallback, useMemo, useState } from 'react'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { emptyGuid } from '../utils/emptyGuid'
import { useEmployeesState } from './EmployeesProvider'
import { emptyDate } from '../utils/emptyDate'
import { cloneDeep } from 'lodash';
import { setValueByKey } from '../utils/utils'
import useStores from './useStore'
import { useEffect } from 'react'


const EmployeeDataContext = React.createContext({})
export function useEmployeeDataState() {
  return React.useContext(EmployeeDataContext)
}

const activitiesTemplate = {
  newItems: []
}
const mainDataTemplate = {
  firstName: '',
  lastName: '',
  title: '',
  gender: '',
  personalNr: '',
  personalNr2: '',
  birthDate: '',
  dateStartEmployment: '',
  dateExitEmployment: '',
  language: {
    id: emptyGuid
  },
  costCenter: {
    id: emptyGuid
  },
  office: {
    id: emptyGuid
  },
  orderPhase: {
    id: emptyGuid
  }
}
const communicationDataTemplate = {
  newItems: [
    {
      type: 'email',
      value: '',
      isPrivate: false
    },
    {
      type: 'phone',
      value: '',
      isPrivate: false
    },
    {
      type: 'cellphone',
      value: '',
      isPrivate: false
    },
    {
      type: 'email',
      value: '',
      isPrivate: true
    },
    {
      type: 'phone',
      value: '',
      isPrivate: true
    },
    {
      type: 'cellphone',
      value: '',
      isPrivate: true
    }
  ]
}

const accountDataTemplate = {
  id: emptyGuid,
  login: "",
  password: "",
  accessibleCostcenters: [],
  accessibleOffices: [],
  accessibleCatalogs: [],
  assignedGroups: []
}

const addressesTemplate = {
  newItems: [
    {
      isPrivate: true,
      addressType: 'shipment',
      careOf: '',
      street: '',
      streetNr: '',
      postOfficeBox: '',
      zipCode: '',
      city: '',
      countryIsoCode: '',
      id: emptyGuid
    },
    {
      isPrivate: true,
      addressType: 'billing',
      careOf: '',
      street: '',
      streetNr: '',
      postOfficeBox: '',
      zipCode: '',
      city: '',
      countryIsoCode: '',
      id: emptyGuid
    },
  ]
}

const EmployeeDataProvider = ({ children }) => {
  const { uiStore } = useStores()
  const [showUserData, setShowUserData] = useState(false)
  const [accountId, setAccountId] = useState("")
  const [successSubmit, setSuccessSubmit] = useState(false)
  const [accountData, setAccountData] = useState(cloneDeep(accountDataTemplate))
  const [initialAccountData, setInitialAccountData] = useState(JSON.stringify(accountDataTemplate))


  const [mainData, setMainData] = useState(cloneDeep(mainDataTemplate))
  const [initialMainData, setInitialMainData] = useState('')

  const [addresses, setAddresses] = useState(cloneDeep(addressesTemplate))
  const [initialAddresses, setInitialAddresses] = useState('')

  const [communicationData, setCommunicationData] = useState(cloneDeep(communicationDataTemplate))
  const [initialCommunicationData, setInitialCommunicationData] = useState('')
  const [submittedCommunicationData, setSubmittedCommunicationData] = useState({})

  const [activities, setActivities] = useState(cloneDeep(activitiesTemplate))
  const [initialActivities, setInitialActivities] = useState('')

  const [formErrors, setFormErrors] = useState({})
  const [errorsOccurredOnSubmit, setErrorsOccurredOnSubmit] = useState(false)

  const [loadedEmployeeData, setLoadedEmployeeData] = useState(null)
  const { setForceReloadEmployees } = useEmployeesState()

  const setMainDataByKey = useCallback((key, value) => {
    setMainData(prev => setValueByKey(key, value, prev))
  }, [])

  const setAddressesByKey = useCallback((key, value) => {
    setAddresses(prev => setValueByKey(key, value, prev))
  }, [])

  const setCommunicationDataByKey = useCallback((key, value) => {
    setCommunicationData(prev => setValueByKey(key, value, prev))
  }, [])

  const setAccountDataByKey = useCallback((key, value) => {
    setAccountData(prev => setValueByKey(key, value, prev))
  }, [])

  const setActivitiesByKey = useCallback((key, value) => {
    setActivities(prev => setValueByKey(key, value, prev))
  }, [])

  const resetErrorByFormKey = useCallback(formKey => {
    setFormErrors(prev => {
      if (prev[formKey]) {
        delete prev[formKey]
      }
      return prev
    })
  }, [setFormErrors])

  const loadAccountData = useCallback(async (employeeId) => {
    try {
      let accountResults = await apiClient.getJson(APIEndpoints.businessPartner(employeeId).account)
      if (accountResults) {
        setAccountId(accountResults.id)
      }
      if (!uiStore.allowAccessUserModule) {
        return null
      }
      if (accountResults) {
        setShowUserData(true)

        let calls = ["accessibleCostcenters", "accessibleOffices", "accessibleCatalogs", "assignedGroups"]
        let promises = []
        let loadedData = {
          id: accountResults.id,
          login: accountResults.login
        }

        for (let call of calls) {
          promises.push(apiClient.getJson(APIEndpoints.accounts(accountResults.id)[call]))
        }
        Promise.all(promises).then((responses) => {
          calls.forEach((call, index) => loadedData[call] = responses[index])
          setAccountData(loadedData)
          setInitialAccountData(JSON.stringify(loadedData))
        })

      }
    }
    catch (e) { }
  }, [uiStore.allowAccessUserModule])

  const loadEmployeeData = useCallback(async id => {
    var calls = ["mainData", "communicationData", "addresses", "activities"]
    var promises = []
    var loadedData = {}

    for (let call of calls) {
      promises.push(apiClient.getJson(APIEndpoints.businessPartner(id)[call]))
    }
    Promise.all(promises).then((responses) => {
      calls.forEach((call, index) => loadedData[call] = responses[index])
      setLoadedEmployeeData(loadedData);
      initLoadedEmployeeData(cloneDeep(loadedData));
    })
  }, [])

  const initLoadedEmployeeData = (loadedData) => {
    const loadedCommunicationData = loadedData.communicationData
    const loadedMainData = loadedData.mainData
    const loadedAddresses = loadedData.addresses
    const loadedActivites = loadedData.activities
    const newCommunicationData = cloneDeep(communicationDataTemplate);
    const newAddresses = cloneDeep(addressesTemplate)
    const newActivities = cloneDeep(activitiesTemplate)

    // init mainData fields
    const newMainData = {
      ...loadedMainData,
      costCenter: { id: loadedMainData.costCenter ? loadedMainData.costCenter.id : emptyGuid },
      office: { id: loadedMainData.office ? loadedMainData.office.id : emptyGuid },
      orderPhase: { id: loadedMainData.orderPhase ? loadedMainData.orderPhase.id : emptyGuid }
    };
    setMainData(newMainData)
    setInitialMainData(JSON.stringify(newMainData));
    // init commmunication data fields
    [{ type: 'email', isPrivate: false },
    { type: 'phone', isPrivate: false },
    { type: 'cellphone', isPrivate: false },
    { type: 'email', isPrivate: true },
    { type: 'phone', isPrivate: true },
    { type: 'cellphone', isPrivate: true }].forEach((comField, index) => {
      const foundItem = loadedCommunicationData.find(item => item.type === comField.type && item.isPrivate === comField.isPrivate)
      if (foundItem) {
        foundItem.value = foundItem.value || ''
        newCommunicationData.newItems[index] = {
          ...foundItem
        }
      }
    });
    setCommunicationData(newCommunicationData)
    setInitialCommunicationData(JSON.stringify(newCommunicationData));
    // init addresses
    [{ addressType: 'shipment' },
    { addressType: 'billing' },].forEach((addressField, index) => {
      const foundItem = loadedAddresses.find(item => item.addressType === addressField.addressType)
      if (foundItem) {
        //set null values to empty strings
        ['careOf', 'street', 'streetNr',
          'postOfficeBox', 'zipCode', 'city', 'countryIsoCode'].forEach(key => {
            foundItem[key] = foundItem[key] || ''
          })
        newAddresses.newItems[index] = {
          ...foundItem
        }
      }
    })
    setAddresses(newAddresses)
    setInitialAddresses(JSON.stringify(newAddresses))
    // init jobs/ activities
    //set costcenter having null to id: emptyGuid
    loadedActivites.forEach(item => { item.costCenter = (item.costCenter === null ? { id: emptyGuid } : item.costCenter) })
    newActivities.newItems = loadedActivites
    setActivities(newActivities)
    setInitialActivities(JSON.stringify(newActivities))
  }

  const checkAccountDataChanges = (newData, initialData) => {
    Object.keys(newData).forEach(key => {
      if (key === 'assignedGroups') {
        newData[key].sort((a, b) => a.groupId > b.groupId ? 1 : -1)
        // set the time format as ..Т00:00:00 (and not ..Т00:00:00.000Z - default when user selects date)
        // needed for correct comparison,since that's the format we get from the API call
        newData[key].forEach(group => {
          group.activePeriod.activeFrom = group.activePeriod.activeFrom.split('.')[0]
          group.activePeriod.activeTo = group.activePeriod.activeTo.split('.')[0]
        })
      } else if (Array.isArray(newData[key])) {
        newData[key].sort()
      }
    })
    Object.keys(initialData).forEach(key => {
      if (key === 'assignedGroups') {
        initialData[key].sort((a, b) => a.groupId > b.groupId ? 1 : -1)
        initialData[key].forEach(group => {
          group.activePeriod.activeFrom = group.activePeriod.activeFrom.split('.')[0]
          group.activePeriod.activeTo = group.activePeriod.activeTo.split('.')[0]
        })
      } else if (Array.isArray(initialData[key])) {
        initialData[key].sort()
      }
    })
    return JSON.stringify(newData) !== JSON.stringify(initialData)
  }

  const accountDataHasChanged = useMemo(() => {
    let initialAccountDataParsed = JSON.parse(initialAccountData)
    let isDataChanged = checkAccountDataChanges(accountData, initialAccountDataParsed)
    return isDataChanged
  }, [accountData, initialAccountData])

  const communicationDataHasChanged = useMemo(() => {
    if (initialCommunicationData !== '') {
      const communicationDataString = JSON.stringify(communicationData)
      return communicationDataString !== initialCommunicationData
    }
  }, [communicationData, initialCommunicationData])

  const mainDataHasChanged = useMemo(() => {
    if (initialMainData !== '') {
      const mainDataString = JSON.stringify(mainData)
      return mainDataString !== initialMainData
    }
  }, [mainData, initialMainData])

  const addressesHasChanged = useMemo(() => {
    if (initialAddresses !== '') {
      const addressesString = JSON.stringify(addresses)
      return addressesString !== initialAddresses
    }
  }, [addresses, initialAddresses])

  const activitiesHasChanged = useMemo(() => {
    if (initialActivities !== '') {
      const activitiesString = JSON.stringify(activities)
      return activitiesString !== initialActivities
    }
  }, [activities, initialActivities])

  const formDataHasChanged = useMemo(() => {
    return accountDataHasChanged || communicationDataHasChanged || mainDataHasChanged || addressesHasChanged || activitiesHasChanged
  }, [accountDataHasChanged, communicationDataHasChanged, mainDataHasChanged, addressesHasChanged, activitiesHasChanged])

  const resetFormData = useCallback(() => {
    setMainData(JSON.parse(initialMainData))
    setActivities(JSON.parse(initialActivities))
    setAddresses(JSON.parse(initialAddresses))
    setCommunicationData(JSON.parse(initialCommunicationData))
    setAccountData(JSON.parse(initialAccountData))
    setFormErrors({})
    setErrorsOccurredOnSubmit(false)
  }, [setFormErrors, setErrorsOccurredOnSubmit, initialMainData, initialActivities, initialAddresses, initialCommunicationData, initialAccountData])


  const parseApiFormErrors = useCallback(response => {
    if (response && response.data && response.data.errors) {
      response.data.errors.forEach(error => {
        if (error.source && error.source.pointer) {
          // const errorFormPath = error.source.pointer.replace(/^businesspartner\//, '').replace(/[\/\[]/g, '.').replace(/]/g,'')
          const errorFormPath = `${error.source.pointer}`.replace(/[[]/g, '.').replace(/]/g, '').replace(/[/]/g, '')
          setFormErrors(prev => ({ ...prev, [errorFormPath]: { msg: error.detail, apiError: error } }))
        }
      })
    }
  }, [setFormErrors])

  const accessibleOfficesMustUpdate = useMemo(() => {
    return (mainData.office.id !== emptyGuid) && (!accountData.accessibleOffices.includes(mainData.office.id))
  }, [mainData, accountData])

  const accessibleCostcentersMustUpdate = useMemo(() => {
    return (mainData.costCenter.id !== emptyGuid) && (!accountData.accessibleCostcenters.includes(mainData.costCenter.id))
  }, [mainData, accountData])

  const getPreparedAccountData = useCallback(() => {
    var preparedAccountData = cloneDeep(accountData)
    //add mainCostCenter & mainOffice to accessibleCostcenters & accessibleOffices
    if (accessibleOfficesMustUpdate) {
      preparedAccountData.accessibleOffices.push(mainData.office.id)
    }
    if (accessibleCostcentersMustUpdate) {
      preparedAccountData.accessibleCostcenters.push(mainData.costCenter.id)
    }
    return preparedAccountData
  }, [accessibleCostcentersMustUpdate, accessibleOfficesMustUpdate, accountData, mainData.costCenter.id, mainData.office.id])

  const getPreparedCommunicationData = () => {
    const preparedCommunicationData = cloneDeep(communicationData)
    //remove newItems with empty values
    preparedCommunicationData.newItems = preparedCommunicationData.newItems.filter(item => item.value !== '')
    //move existing items from newItems to changedItems
    preparedCommunicationData.changedItems = preparedCommunicationData.newItems.filter(item => !!item.id && item.id.split('_')[1] !== emptyGuid)
    preparedCommunicationData.newItems = preparedCommunicationData.newItems.filter(item => !preparedCommunicationData.changedItems.includes(item))
    // move loaded Items to removedItems if they are not present in form data
    preparedCommunicationData.removedItems = loadedEmployeeData.communicationData.filter(item => (
      item.id.split('_')[1] !== emptyGuid && !preparedCommunicationData.changedItems.find(foundItem => foundItem.id === item.id)
    )).map(item => item.id)
    setSubmittedCommunicationData(cloneDeep(preparedCommunicationData))
    return preparedCommunicationData
  }

  const getPreparedMainData = () => {
    const preparedMainData = cloneDeep(mainData);
    //prepare dates
    ["birthDate", "dateExitEmployment", "dateStartEmployment"].forEach(dateProp => {
      if (preparedMainData[dateProp] === '') {
        preparedMainData[dateProp] = emptyDate
      }
    })
    return preparedMainData
  }

  const getPreparedAddresses = () => {
    const preparedAddresses = cloneDeep(addresses);
    //remove newItems with empty values
    preparedAddresses.newItems = preparedAddresses.newItems.filter(item => [
      'careOf',
      'street',
      'streetNr',
      'postOfficeBox',
      'zipCode',
      'city',
      'countryIsoCode'
    ].reduce((acc, key) => acc || item[key] !== '', false));
    preparedAddresses.changedItems = preparedAddresses.newItems.filter(item => !!item.id && item.id !== emptyGuid)
    preparedAddresses.newItems = preparedAddresses.newItems.filter(item => !preparedAddresses.changedItems.includes(item))
    preparedAddresses.removedItems = loadedEmployeeData.addresses.filter(item => (
      item.id !== emptyGuid && !preparedAddresses.changedItems.find(foundItem => foundItem.id === item.id)
    )).map(item => item.id)
    return preparedAddresses
  }


  const getPreparedActivities = () => {
    const preparedActivities = cloneDeep(activities)
    preparedActivities.changedItems = preparedActivities.newItems.filter(item => loadedEmployeeData.activities.find(loadedActivity => loadedActivity.id === item.id))
    preparedActivities.newItems = preparedActivities.newItems.filter(item => !preparedActivities.changedItems.includes(item))
    preparedActivities.removedItems = loadedEmployeeData.activities.filter(item => (
      item.id !== emptyGuid && !preparedActivities.changedItems.find(foundItem => foundItem.id === item.id)
    )).map(item => item.id);
    return preparedActivities
  }

  const submitAddEmployee = useCallback(async () => {
    setFormErrors({})
    setErrorsOccurredOnSubmit(false)
    //send accountData if userType is "user"
    var preparedData = { communicationData, mainData, addresses, activities }
    if (showUserData) {
      preparedData.account = getPreparedAccountData()
    }
    setSubmittedCommunicationData(cloneDeep(preparedData.communicationData))
    try {
      const createdUserId = await apiClient.postJson(APIEndpoints.businessPartners, preparedData)
      setSuccessSubmit(true)
      return { createdUserId, personalNr: mainData.personalNr }
    } catch (e) {
      setErrorsOccurredOnSubmit(true)
      if (e.response) {
        parseApiFormErrors(e.response)
      }
    }
    return null
  }, [communicationData, mainData, addresses, activities, showUserData, getPreparedAccountData, parseApiFormErrors])

  const submitEditEmployee = async () => {
    setFormErrors({})
    setErrorsOccurredOnSubmit(false)
    const preparedFormData = {}

    if (accountDataHasChanged) {
      preparedFormData.account = getPreparedAccountData()
    }
    if (communicationDataHasChanged) {
      preparedFormData.communicationData = getPreparedCommunicationData()
    }
    if (mainDataHasChanged) {
      preparedFormData.mainData = getPreparedMainData()
    }
    if (addressesHasChanged) {
      preparedFormData.addresses = getPreparedAddresses()
    }
    if (activitiesHasChanged) {
      preparedFormData.activities = getPreparedActivities()
    }
    try {
      await apiClient.patchJson(APIEndpoints.businessPartner(mainData.id).patch, preparedFormData)
      setSuccessSubmit(true)
      await loadEmployeeData(mainData.id)
      await loadAccountData(mainData.id)
      setForceReloadEmployees(true)
    } catch (e) {
      setErrorsOccurredOnSubmit(true)
      if (e.response) {
        parseApiFormErrors(e.response)
      }
    }
  }
  const [selectableAvtivities, setSelectableAvtivities] = useState([])

  const loadActivities = useCallback(async () => {
    let loadedActivities = []
    try {
      await uiStore.loadBPDataSettings()
      if (uiStore.activitiesDependentOnSelectedOffice) {
        loadedActivities = await fetchActivities(mainData.office.id)
      }
      else {
        loadedActivities = await fetchActivities()
      }
      setSelectableAvtivities(loadedActivities)
    }
    catch (e) { }
  }, [mainData.office.id, uiStore])

  const fetchActivities = async (selectedOfficeId) => {
    let params = {}
    if (selectedOfficeId) {
      params["filters.officeId"] = selectedOfficeId
    }
    return await apiClient.getJson(APIEndpoints.allActivities, params)
  }
  useEffect(() => {
    loadActivities()
  }, [loadActivities])

  const contextValues = {
    selectableAvtivities,
    loadEmployeeData,
    submitAddEmployee,
    submitEditEmployee,
    formErrors, setFormErrors,
    resetErrorByFormKey,
    errorsOccurredOnSubmit,
    formDataHasChanged,
    resetFormData,
    accountId,
    loadAccountData,
    accountData,
    setAccountDataByKey,
    accountDataHasChanged,
    setCommunicationDataByKey,
    communicationData,
    setMainDataByKey,
    mainData,
    setAddressesByKey,
    addresses,
    setActivitiesByKey,
    activities,
    setSuccessSubmit,
    successSubmit,
    loadedEmployeeData,
    showUserData, setShowUserData,
    submittedCommunicationData
  }
  return (
    <EmployeeDataContext.Provider value={contextValues}>
      {children}
    </EmployeeDataContext.Provider>
  )
}

export default EmployeeDataProvider
