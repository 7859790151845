import React, { useState } from 'react'
import { Alert, Form, Spinner, Tab, Tabs } from 'react-bootstrap'
import { t } from 'i18next'

import CKEditorNews from './CKEditorNews'
import FileInput from '../../Forms/FileInput'
import { makeCall } from '../../../utils/api/makeCall'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { emptyGuid } from '../../../utils/emptyGuid'


const EditorModalEditors = ({ errorStates, newsId, currentBody, changeLanguageText, setErrorStates, loadingStates, setLoadingStates, selectedLanguage }) => {
  const [picturePath, setPicturePath] = useState('')

  const uploadPicture = (file) => {
    makeCall('savePicture', async () => {
      const idNews = newsId || emptyGuid
      let result = await apiClient.postFile(APIEndpoints.feedsAdministration(idNews).fileUpload, {}, file)
      if (result && result.fullPath) {
        setPicturePath(result.fullPath)
      }
    }, setErrorStates, setLoadingStates)
  }

  return (
    <Tabs
      defaultActiveKey="html"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="editor" title="Editor" mountOnEnter unmountOnExit>
        <CKEditorNews
          currentBody={currentBody}
          changeLanguageText={changeLanguageText}
          newsId={newsId}
          selectedLanguage={selectedLanguage}
        />
      </Tab>
      <Tab eventKey="html" title="HTML">
        <>
          <Form.Group className="mb-3">
            <Form.Control
              style={{ minHeight: "600px" }}
              as="textarea"
              placeholder=""
              value={currentBody || ""}
              onChange={changeLanguageText}
              name="news.body.values"
              isInvalid={errorStates?.saveNews?.formErrors?.body}
            />
          </Form.Group>
          {loadingStates.savePicture
            ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
            : <FileInput
              maxFiles={1}
              className="mb-3"
              accept={{
                'image/jpeg': [],
                'image/png': []
              }}
              onDrop={(acceptedFiles) => {
                let newFiles = acceptedFiles.map(file => Object.assign(file, {
                  preview: URL.createObjectURL(file)
                }));
                uploadPicture(newFiles)
              }}
              label={t('orderDialog:attachments')}
            />
          }
          {picturePath && <Alert dismissible variant="success">{picturePath}</Alert>}
        </>
      </Tab>
    </Tabs>
  )
}

export default EditorModalEditors