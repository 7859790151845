import React, { useMemo, useState } from 'react'
import { getLanguageValue } from '../../../utils/getLanguageValue';
import { Alert, Button, Modal, Table } from 'react-bootstrap';
import { APIEndpoints } from '../../../utils/api/apiConfig';
import apiClient from '../../../utils/api/apiClient';
import { getErrorMessageFromApiResponse } from '../../../utils/api/apiUtils';
import LoadingOverlay from '../../Loading/LoadingOverlay';
import { t } from 'i18next';
import { ButtonWithLoadingState } from '../../Buttons';

const MoveArticlesModal = ({ setOpenMoveOrCopyModal, getGroupArticles, fromArticle, toGroup }) => {
  const [loading, setLoading] = useState(false)
  const [loadGroupArticles, setLoadGroupArticles] = useState(false)
  const [error, setError] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const articles = fromArticle.selectedArticles
  const currentArticle = articles[currentIndex]
  let title = useMemo(() => `${t('contingentTemplates:addArticles')} ( ${currentIndex + 1} / ${articles.length}) `, [articles.length, currentIndex])

  const postArticle = async (id) => {
    let params = {
      article: { id },
      type: "create",
      value: {}
    }
    return await apiClient.postJson(APIEndpoints.contingentTemplate(toGroup.entitlementId, toGroup.id).groupArticles, params)
  }

  async function handleSave() {
    setError(null)
    setLoading(true)
    try {
      await postArticle(currentArticle.article.id)
      processNextArticle()
    }
    catch (e) {
      setError(getErrorMessageFromApiResponse(e))
    }
    finally { setLoading(false) }
  }

  const handleSaveAll = async () => {
    setError(null)
    setLoading(true)
    for (let i = currentIndex; i < articles.length; i++) {
      try {
        await postArticle(articles[i].article.id)
      }
      catch (e) {
        setError(getErrorMessageFromApiResponse(e))
        setCurrentIndex(i)
        setLoading(false)
        return;
      }
    }
    leaveModal()
  }

  const processNextArticle = async () => {
    setError(null);
    (currentIndex < articles.length - 1)
      ? setCurrentIndex(prev => prev + 1)
      : leaveModal()
  }

  const leaveModal = async () => {
    setLoadGroupArticles(true)
    await getGroupArticles(toGroup.entitlementId, toGroup.id)
    setLoadGroupArticles(false)
    setOpenMoveOrCopyModal(null)
  }
  const handleClose = () => {
    leaveModal()
  }
  return (
    <Modal style={{ textAlign: "center" }} show={true} onHide={handleClose} backdrop={"static"}  >
      <Modal.Header closeButton >
        <Modal.Title >{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body  >
        <LoadingOverlay isLoading={loadGroupArticles}>
          <Table striped bordered   >
            <tbody>
              <tr>
                <td>
                  <b>{t('articles')}:</b>
                </td>
                <td>
                  <div> {currentArticle.article.displayName}</div>
                  <div>  {currentArticle.article.articleNumber}</div>
                </td>
              </tr>
              <tr><td><b>{t('EmployeeFormMainData:group')}:</b></td><td>  {toGroup.isVirtualGroup ? t('contingentTemplates:virtualGroup') : getLanguageValue(toGroup.name.values)} </td></tr>
            </tbody>
          </Table>
        </LoadingOverlay>
        {error ? <Alert variant="danger">{error}</Alert> : null}
      </Modal.Body >
      <Modal.Footer >
        <ButtonWithLoadingState
          disabled={loading || loadGroupArticles}
          onClick={processNextArticle}
          buttonText={t('contingentTemplates:skip')}
        />
        <ButtonWithLoadingState
          disabled={loading || loadGroupArticles}
          onClick={handleSave}
          isLoading={loading}
          buttonText={t('contingentTemplates:assign')}
        />
        <ButtonWithLoadingState
          disabled={loading || loadGroupArticles}
          onClick={handleSaveAll}
          isLoading={loading}
          buttonText={t('contingentTemplates:assignAll')}
        />
        {<Button variant="secondary" onClick={handleClose}>
          {t('appointment:cancel')}
        </Button>}
      </Modal.Footer>
    </Modal >
  )
}

export default MoveArticlesModal