import React, { Component } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import SearchField from '../Forms/SearchField'
import { withTranslation } from 'react-i18next'
import SearchResultAmount from '../Forms/SearchResultAmount'

const StyledSearchField = styled(SearchField)`
margin-bottom:1rem;
`

class ProductSearch extends Component {
  handleChange = e => {
    const { teamOrderStore } = this.props
    teamOrderStore.productsSearchText = e.target.value
  }

  render() {
    const { teamOrderStore, t } = this.props

    return (
      <React.Fragment>
        <StyledSearchField placeholder={t('search')} value={teamOrderStore.productsSearchText} onChange={this.handleChange}
          onClearClick={() => teamOrderStore.productsSearchText = ''} />
        {teamOrderStore.productsSearchText ? <SearchResultAmount count={teamOrderStore.orderableArticlesAmount} />
          : <div className="mb-2" >&nbsp;</div>}
      </React.Fragment>
    )
  }
}

export default withTranslation()(inject('teamOrderStore')(observer(ProductSearch)))
