import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { useTranslation } from 'react-i18next'


const _formData = {
    timeFrom: "--:--",
    timeTo: "--:--",
    breakTime: 0
}

const AddSeriesModal = (props) => {
    const { handleClose, createSeries } = props
    const innerRef = useRef()
    const { t } = useTranslation()

    const [formData, setFormData] = useState({ ..._formData })
    const [validated, setValidated] = useState(false)

    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }

    //autofocus
    useEffect(() => { innerRef.current && innerRef.current.focus() }, []);

    //cleanup
    useEffect(() => {
        return () => clearData()
    }, [])

    const clearData = () => {
        setFormData({ ..._formData })
        setValidated(false)
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        event.stopPropagation()

        if (validated) {
            createSeries(formData)
            handleClose()
        }
    }

    const validateForm = (event) => {
        const form = event.currentTarget
        setValidated(form.checkValidity())
    }

    return (
        <>
            <Modal style={{ textAlign: "center" }} centered show={true} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title >{t('appointment:addTime')}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form
                        id="my-form"
                        validated={validated}
                        onChange={validateForm}
                        onSubmit={handleSubmit}
                    >
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('start')}</Form.Label>
                                    <Form.Control
                                        ref={innerRef}
                                        name="timeFrom"
                                        type="time"
                                        value={formData.timeFrom}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('end')}</Form.Label>
                                    <Form.Control
                                        name="timeTo"
                                        type="time"
                                        value={formData.timeTo}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('appointment:breakBetween')}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            name="breakTime"
                                            type="number"
                                            min="0"
                                            value={formData.breakTime}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <InputGroup.Text id="basic-addon2">{t('minutes')}</InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body >
                <Modal.Footer >
                    <Button
                        form="my-form"
                        variant='primary'
                        disabled={!validated}
                        type="submit"
                    >
                        {t('create')}
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    );
};

export default AddSeriesModal;