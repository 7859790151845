import React from 'react'
import DynamicFormElements from '../Form/DynamicFormElements'

const formConfigTemplate = [
    {
        type: 'text',
        name: 'emailAddress',
        label: 'emailAddress'
    },
    {
        type: 'select',
        name: 'officeAddressID',
        label: 'officeAddressID',
        defaultIsEmptyGuid: true,
        defaultName: 'select',
        options: [],
        isMandatoryField: true
    },
]

const OrderExport = ({ formData, setFormData, formErrors }) => {

    return (
        <>
            <DynamicFormElements formConfigTemplate={formConfigTemplate} setFormData={setFormData} formData={formData} formErrors={formErrors} />
        </>
    )
}
export default OrderExport