import React from 'react'
import styled, { keyframes } from 'styled-components'
import { CartIcon } from '../Icons/CartIcon'
const StyledCenterWrapper = styled.div`
    overflow: hidden;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const moveCart = keyframes`
    0%{
      opacity: 0;
      transform: translateX(-150%);
    }
    40%,60%{
      transform: translateX(0%); 
      opacity:1;
    }
    100%{
      transform: translateX(150%);
      opacity: 0;
    }

`
const moveItems = keyframes`
    0%{
      transform: translateY(-150%);
    }
    40%{
      transform: translateY(-150%);
    }
    60%{
      opacity:1;
      transform: translateY(0%); 
      transform: translateX(0%); 
    }
    100%{
      opacity: 0;
      transform: translateX(150%);
    }
`

const StyledCartIcon = styled(CartIcon)`
width:100%;
max-width:600px;
.cart{
  animation:${moveCart} 2s ease-in-out infinite;
}
.items{
  animation:${moveItems} 2s ease-in-out infinite;
}
`
const AddToBasketAnimation = () => {
  return (
    <StyledCenterWrapper><StyledCartIcon color="white" height="50px" /></StyledCenterWrapper>
  )
}

export default AddToBasketAnimation