import React from "react";
import styled from "styled-components";
import { colors } from "../../../styles/colors";

const SidebarWrapper = styled.div`
  background: ${colors.gray6};
  border-right: 1px solid ${colors.gray2};
  overflow: auto;
  color: ${colors.gray5};
  min-width: ${(props) => (props.open ? "30vw" : "0")};
  padding:  ${(props) => (props.open ? "33px 0px 20px 36px" : "0px 0px 0px 0px")};
  @media screen and (min-width: 1081px) {
    left: 0;
    overflow: visible;
    max-width: 30vh;
  }
`;

const StyledSidebar = styled.div`
@media screen and (min-width: 1080px) {
  position: sticky;
  top: 80px;
  max-height: calc(100vh - 80px);
  overflow-y: scroll;
  &:hover {
    overflow-y: scroll;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
`

export const TestSideBarLeftDash = ({ children, ...rest }) => {
  return (
    <SidebarWrapper {...rest}>
      <StyledSidebar className="sidebarLeftContent">
        {children}
      </StyledSidebar>
    </SidebarWrapper>
  );
};
