import React, { useState } from 'react'
import { Alert, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MdLibraryAdd } from 'react-icons/md'
import { useObserver } from 'mobx-react'

import useStores from '../../stores/useStore'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import AdministrationSidebarNavigation from '../../components/Configuration/AdministrationSidebarNavigation'
import ContingentTemplatesOverview from '../../components/Configuration/ContingentTemplate/ContingentTemplatesOverview'
import { ExcelButton } from '../../components/Buttons/ExcelButton'
import CreateNewExportOverlay from '../../components/Files/CreateNewExportOverlay'
import CreateNewImportOverlay from '../../components/Files/CreateNewImportOverlay'
import { ButtonWithLoadingState } from '../../components/Buttons'
import { BsSortAlphaDown } from 'react-icons/bs'
import { useContingentTemplatesState } from '../../stores/Configuration/ContingentTemplatesProvider'
import DownloadURLAlert from '../../components/Forms/DownloadURLAlert'
import useReport from '../../utils/hooks/useReport'
import ReportSubscriptionModal from '../../components/Files/Form/ReportSubscriptionModal'

const ContingentTemplatesAdministrationPage = () => {
  const { t } = useTranslation()
  const { uiStore } = useStores()
  const { loadingStates, putContingentTemplatesOrderSequence, bigData } = useContingentTemplatesState()
  const [showExportOverlay, setShowExportOverlay] = useState(false)
  const [showImportOverlay, setShowImportOverlay] = useState(false)
  const allowEntitlementAdministration = useObserver(() => uiStore.allowEntitlementAdministration)
  const dashboardIsLoading = useObserver(() => uiStore.dashboardIsLoading)
  const dashboardLoaded = useObserver(() => !!uiStore.dashboard)
  const onCloseOverlayClick = () => {
    setShowExportOverlay(false)
    setShowImportOverlay(false)
  }
  const { createReport, reportIsLoading, reportErrors, reportDownloadUrls, setReportDownloadUrls, reportSubscriptionId, setReportSubscriptionId } = useReport()
  function saveSortedSequence() {
    let sortedSequence = Object.values(bigData).sort((a, b) => (a.displayName.localeCompare(b.displayName))).map(a => a.id)
    putContingentTemplatesOrderSequence(sortedSequence)
  }
  return (
    <>
      <Subheader>
        <div className="subheader-title flex-grow-1">{t('contingentTemplates:manageContingentTemplates')}</div>
        <ExcelButton
          className="subheader__button"
          disabled={reportIsLoading.reportName}
          isLoading={reportIsLoading.reportName}
          buttonText={t('contingentTemplates:statisticEntitlement')}
          variant="secondary"
          onClick={() => createReport("reportName", "statisticEntitlement", {})}
        />

        {uiStore.allowImportEntitlements &&
          <ExcelButton onClick={() => setShowImportOverlay(prev => !prev)} className="subheader__button" variant="secondary" buttonText={t('import')} />
        }
        <ExcelButton onClick={() => setShowExportOverlay(prev => !prev)} className="subheader__button" variant="secondary" buttonText={t('export')} />
        <ButtonWithLoadingState
          variant={'primary'}
          buttonText={t('contingentTemplates:sortAlphabetically')}
          disabled={loadingStates.saveOrderSequence}
          onClick={saveSortedSequence}
          buttonIcon={<BsSortAlphaDown size="23" className="me-3" />}
          className="subheader__button btn btn-primary text-white"
        />
        {allowEntitlementAdministration && <Link to='/contingentTemplatesAdministration/createContingent' className="subheader__button btn btn-primary text-white"><MdLibraryAdd className="me-3" />{t('contingentTemplates:addContingentTemplate')}</Link>}
        {reportDownloadUrls.reportName &&
          <DownloadURLAlert
            downloadUrl={reportDownloadUrls.reportName}
            setDownloadUrl={() => setReportDownloadUrls({})}
            fileName={t("srm:exportButton")}
          />
        }
        {reportErrors.reportName && <Alert className="subheader__alert" variant="danger"  >{reportErrors.reportName.value}</Alert>}
        {reportSubscriptionId && <ReportSubscriptionModal
          reportSubscriptionId={reportSubscriptionId}
          setReportSubscriptionId={setReportSubscriptionId}
          message={t('reportConfig:createPending')}
        />}
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <AdministrationSidebarNavigation active="contingentTemplatesAdministration" />
        </SidebarLeft>
        <ContentArea>
          {/* switch with new right for contingent templates */}
          {allowEntitlementAdministration ?
            <ContingentTemplatesOverview />
            : (dashboardIsLoading || !dashboardLoaded)
              ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
              : <Alert variant='danger'>{t('accessDenied')}</Alert>
          }
        </ContentArea>
      </FlexWrapper>
      <CreateNewExportOverlay exportName="entitlementExport" visible={showExportOverlay} onClose={onCloseOverlayClick} />
      <CreateNewImportOverlay importName="entitlementImport" visible={showImportOverlay} onClose={onCloseOverlayClick} />
    </>
  )
}

export default ContingentTemplatesAdministrationPage