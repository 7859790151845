import React from 'react'
import { ExcelButton } from '../../Buttons/ExcelButton'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { Subheader } from '../../Page/logix2020/Subheader'
import { useTranslation } from 'react-i18next'
import { useSRMGroupTableState } from '../../../stores/Administration/SRMGroupTableProvider'
import { Alert } from 'react-bootstrap'
import DownloadURLAlert from '../../Forms/DownloadURLAlert'
import useReport from '../../../utils/hooks/useReport'
import ReportSubscriptionModal from '../../Files/Form/ReportSubscriptionModal'


const SubheaderSRMGroup = () => {

    const { t } = useTranslation()
    const { getOrderIds, selectedCheckBoxes } = useSRMGroupTableState()
    const { createReport, reportIsLoading, reportErrors, reportDownloadUrls, setReportDownloadUrls, reportSubscriptionId, setReportSubscriptionId } = useReport()
    const createExport = () => {
        getOrderIds().then((orderIDs) => { createReport("reportName", APIEndpoints.srmOrderExport, { orderIDs }) })
    }

    return (
        <Subheader className="subHeader-v1">
            <div className="subheader-title flex-grow-1">{t('srm:srmOrderAdministration')}</div>
            <ExcelButton
                className="subheader__button"
                disabled={selectedCheckBoxes.length === 0 || reportIsLoading.reportName}
                isLoading={reportIsLoading.reportName}
                buttonText={t('srm:exportButton')}
                variant="secondary"
                onClick={() => createExport()}
            />
            {reportDownloadUrls.reportName &&
                <DownloadURLAlert
                    downloadUrl={reportDownloadUrls.reportName}
                    setDownloadUrl={() => setReportDownloadUrls({})}
                    fileName={t("srm:exportButton")}
                />
            }
            {reportSubscriptionId && <ReportSubscriptionModal
                reportSubscriptionId={reportSubscriptionId}
                setReportSubscriptionId={setReportSubscriptionId}
                message={t('export:createPending')}
            />}
            {reportErrors.reportName && <Alert className="subheader__alert" variant="danger"  >{reportErrors.reportName.value}</Alert>}
        </Subheader>
    )
}

export default SubheaderSRMGroup
