import React from 'react'

export const RentalItems = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="rentalItimes" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <g>
        <path className="st0" d="M34.5,54.5c0-0.4-0.3-0.9-0.8-1.2c-0.4-0.1-1,0-1.2,0.4l-6.3,8.9l-9.3-7l13.3-19.9c2.3-5.1,5.9-5.1,8.8-5.1h2.7l8.1,8.1
          c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3l1.6-1.7v8.8h2.2V37l1.6,1.6c0.2,0.2,0.6,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3l8.1-8
          h2.7c2.9,0,6.5,0,9,5l5,7.5H86l-5.7-8.6c-3.1-6.1-7.6-6.1-10.9-6.1h-1.9l-3.7-3.5c-0.1-0.1-0.3-0.3-0.5-0.3H45.1
          c-0.2,0-0.2,0.1-0.4,0.2L41,28.3h-1.9c-3.3,0-7.8,0-10.9,6.1L14.4,55.2c-0.3,0.5-0.2,1.1,0.2,1.6l11.1,8.3c0.2,0.2,0.6,0.2,1,0.2
          c0.3,0,0.5-0.2,0.8-0.5l4.8-6.8v31.4h23v-2.2H34.5V54.5z M64.9,29l-7.2,7.2l-1.9-1.9l7.2-7.2L64.9,29z M60.6,26.4l-6.3,6.3
          l-6.5-6.3H60.6z M45.3,27.1l7.2,7.2l-1.9,1.9L43.4,29L45.3,27.1z"/>
        <path className="st0" d="M77.5,48.1l-0.4-5L64.9,53.4l13.6,8.3l-0.4-5.1c1.3-0.1,2.6,0.1,3.9,0.6c2.5,1.1,4.4,3.3,5,6l0.3,1.3l7.7-4.1l-0.3-0.8
          c-1.6-4.6-5-8.3-9.5-10.2C82.8,48.3,80.1,47.9,77.5,48.1z M92.5,59.3l-3.8,2c-1.1-2.8-3.2-5-5.9-6.1c-1.3-0.5-2.7-0.8-4-0.8
          c-0.7,0-1.3,0.1-2,0.2l-0.9,0.2l0.2,3l-7.6-4.6l6.8-5.8l0.2,3.1l1.1-0.2c2.6-0.4,5.3-0.1,7.7,0.9C88.1,52.7,91,55.7,92.5,59.3z"/>
        <path className="st0" d="M65,70.1c-0.4-2.7,0.6-5.4,2.7-7.3l1-0.9l-7.4-4.7l-0.6,0.7c-3.2,3.6-4.7,8.5-4.1,13.3c0.4,2.6,1.3,5.2,2.8,7.3l-4.1,2.8
          l15,5.4l0.4-15.9l-4.3,2.9C65.7,72.7,65.2,71.4,65,70.1z M68.3,83.7l-8.5-3l2.6-1.8l-0.7-0.9c-1.7-2.1-2.7-4.6-3.1-7.2
          c-0.5-3.9,0.5-7.9,3-11l3.6,2.3c-1.9,2.2-2.8,5.2-2.4,8.1c0.3,2.1,1.2,4,2.5,5.5l0.6,0.7l2.5-1.7L68.3,83.7z"/>
        <path className="st0" d="M96.5,65.4l-14,7.6l4.7,2.2c-0.6,1.2-1.4,2.3-2.4,3.1c-2.2,1.7-5,2.2-7.7,1.3l-1.3-0.4L75.4,88l0.9,0.2
          c1.1,0.2,2.2,0.3,3.3,0.3c3.7,0,7.3-1.2,10.3-3.5c2.1-1.6,3.8-3.7,4.9-6.1l4.5,2.2L96.5,65.4z M93.7,76l-0.4,1
          c-0.9,2.4-2.6,4.6-4.7,6.2c-3.1,2.4-7.1,3.5-11,3l0.2-4.3c2.9,0.5,5.9-0.2,8.2-2c1.6-1.2,2.8-2.9,3.5-4.9l0.3-0.9l-2.7-1.3l7.8-4.3
          l1.6,8.8L93.7,76z"/>
      </g>
    </svg>
  )
}
