import React, { useEffect, useState, useRef } from 'react'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useStores from '../../stores/useStore'

const StyledContainer = styled.div`
  //display: flex;
  //flex-wrap: wrap;
`

const StyledTitle = styled.h2`
  padding-bottom: 8px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${colors.gray2};
  flex-grow: 1;
`

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;

`

const StyledItem = styled.li`
  margin: 10px 15px;
  border-bottom: 1px solid ${colors.borderColorLight};
  padding-bottom: 15px;
  max-width: 215px;
`

const StyledImageWrapper = styled.div`
 //border: 1px solid ${colors.borderColorLight};
 border-radius: 3px;
 box-shadow: 0 0 10px 0 ${colors.borderColorLight};
 overflow: hidden;
 width: 215px;
 margin-bottom: 10px;
 display: flex;
 justify-content: center;

 img {
  max-width: 100%;
  max-height: 230px;
 }
`

const StyledItemTitle = styled.div`
  color: ${colors.secondary};
`

const OrderableAccessories = ({ basketId, articleId }) => {
  const [accessoires, setAccessoires] = useState([])
  const { t } = useTranslation()
  const mountedRef = useRef(true)
  const { productDetailsStore } = useStores()
  let currentLocation = useLocation()

  //Cleanup Function
  useEffect(() => {
    return () => { mountedRef.current = false }
  }, [])

  useEffect(() => {
    apiClient.getJson(APIEndpoints.orderableAccessories(basketId, articleId)).then(response => {
      if (!mountedRef.current) { return null }
      setAccessoires(response)
    })
  }, [articleId, basketId])

  if (accessoires.length === 0) {
    return null
  }

  return (
    <StyledContainer>
      <StyledTitle className="h1">
        {t('OrderableAccessories:matchingProducts')}
      </StyledTitle>
      <StyledList>
        {accessoires.map(article => (
          <StyledItem key={article.id}>
            <Link
              to={productDetailsStore.getProductToUrl(article)} state={{ from: currentLocation.pathname, backLabel: t('backButton') }}>
              <StyledImageWrapper>
                <img src={article.article.image && article.article.image.url} alt="" />
              </StyledImageWrapper>
              <StyledItemTitle>
                {article.article.displayName}
              </StyledItemTitle>
            </Link>
          </StyledItem>
        ))}
      </StyledList>
    </StyledContainer>
  )
}

export default OrderableAccessories
