import React, { useEffect } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import ArticleListItem from './ArticleListItem'
import { useObserver } from 'mobx-react'
import useStores from '../../stores/useStore'
import LazyLoad from '../Forms/LazyLoad'

const CatalogArticles = () => {
  const { inventoryStore } = useStores()

  useEffect(() => {
    inventoryStore.getCatalogArticles({ newOffset: 0, newSearch: inventoryStore.searchText })
    return () => { inventoryStore.showAllArticles = false }
  }, [inventoryStore])

  return useObserver(() => (
    <>
      <Table borderless>
        <tbody>
          {inventoryStore.catalogArticles.map(listItem => <ArticleListItem key={listItem.article.id} item={listItem} />)}
        </tbody>
      </Table>
      {((inventoryStore.catalogArticlesTotalCount > inventoryStore.catalogArticlesOffset) && !inventoryStore.catalogArticlesLoading) ?
        (<LazyLoad
          fetchData={() => inventoryStore.getCatalogArticles()}
        />) : ''}
      {inventoryStore.catalogArticlesLoading &&
        <div className='d-flex justify-content-center p-3' >
          <Spinner animation='border' variant='primary' />
        </div>}
    </>

  ))
}

export default CatalogArticles