import React, { useState, useRef, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import useMousePosition from '../../utils/hooks/useMousePosition'
import useRefSize from '../../utils/hooks/useRefSize'

const StyledCenterWrapper = styled.div`
    overflow: hidden;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:${props => props.zoom ? 'zoom-out' : 'zoom-in'};
`
const StyledImage = styled.img`
cursor: 'zoom-in';
    max-width: 100%;
    object-fit: contain;
    max-height: 90vh;
`

const StyledZoomImage = styled.img.attrs(props => ({
    style: {
        transform: `translate(${props.translateX ? props.translateX : '0'}px , ${props.translateY ? props.translateY : '0'}px) scale(2)`,
    },
}))` ;transition: transform 300ms cubic-bezier(0.1, 0.82, 0.25, 1) 0s`

const StyledPositionDiv = styled.div`
  position:  fixed; 
  left: 0;
  top:0;
  width: 100%; 
  height: 100%; 
`

const ImgZoom = ({ img, zoom, setZoom }) => {

    const [translate, setTranslate] = useState({ x: 0, y: 0 })
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 })
    const positionRef = useRef(null)
    const position = useMousePosition(positionRef)
    const wrapperSize = useRefSize(positionRef)

    const getImageSize = ({ target: img }) => {
        setImageSize({ width: img.naturalWidth, height: img.naturalHeight })
    }

    const isTouchDevice = useMemo(() => {
        return window.matchMedia("(pointer: coarse)").matches
    }, [])

    useEffect(() => {
        const translateX = parseInt((2 * (imageSize.width - (position.x / wrapperSize.width) * imageSize.width)) - imageSize.width)
        const translateY = parseInt((2 * (imageSize.height - (position.y / wrapperSize.height) * imageSize.height)) - imageSize.height)
        translateX && translateY && setTranslate({ x: translateX, y: translateY })
    }, [imageSize.height, imageSize.width, position, wrapperSize.height, wrapperSize.width])


    return (
        <StyledCenterWrapper zoom={zoom} onClick={() => isTouchDevice ? console.log("toutch device") : setZoom(prev => !prev)} >
            {zoom ?
                <StyledZoomImage
                    translateX={zoom && translate.x}
                    translateY={zoom && translate.y}
                    alt=""
                    src={img.url} />
                : <StyledImage
                    onLoad={getImageSize}
                    alt=""
                    src={img.url} />}
            <StyledPositionDiv ref={positionRef} ></StyledPositionDiv>
        </StyledCenterWrapper>
    )
}

export default ImgZoom
