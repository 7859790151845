import React, { useState, useEffect, useMemo } from 'react'
import { Modal, Form, Alert, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { getErrorMessageFromApiResponse, setPointerErrorsFromApiResponse } from '../../../utils/api/apiUtils'
import useReport from '../../../utils/hooks/useReport'
import ArraySelect from '../../Select/ArraySelect'
import useStores from '../../../stores/useStore'
import { ButtonWithLoadingState } from '../../Buttons'
import { PdfButton } from '../../Buttons/PdfButton'
import MandatoryField from '../../MandatoryField/MandatoryField'
import DownloadURLAlert from '../../Forms/DownloadURLAlert'
import ReportSubscriptionModal from '../../Files/Form/ReportSubscriptionModal'


const StyledButtonsWrapper = styled.div`
    .btn {
        margin: 4px;
    }
    display: flex;
    width: 100%;
    justify-content: space-between;
    
    @media screen and (max-width: 767px) {
        flex-direction: column;
        width: 100%;
    }
`


const EditPointsModal = ({ handleClose, points, employeeId, allowReleasePointsChanges }) => {
    const { productsStore } = useStores()
    const { t } = useTranslation()

    const [formData, setFormData] = useState({
        changeReasonId: null,
        currentPoints: points
    })
    const [error, setError] = useState("")
    const [info, setInfo] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [editReasons, setEditReasons] = useState([])
    const [btnSaveDisable, setBtnSaveDisable] = useState(false)
    const [formerrors, setFormerrors] = useState({})
    const { createReport, reportIsLoading, reportErrors, reportDownloadUrls, setReportDownloadUrls, reportSubscriptionId, setReportSubscriptionId } = useReport()

    useEffect(() => {
        apiClient.getJson(APIEndpoints.balanceChangeReasons)
            .then(result => {
                setEditReasons(result)
            })
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault()
        event.stopPropagation()
        setError("")
        setFormerrors({})
        try {
            setIsLoading(true)
            let result = await apiClient.patchJson(APIEndpoints.businessPartner(employeeId).currentPoints, formData)
            if (result.isWaitingForApproval) {
                setInfo(t('employeesPage:infoPointsChange'))
                setBtnSaveDisable(true)
            } else {
                handleClose()
            }
            await productsStore.loadTargetEmployee(employeeId, true)
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            setError(getErrorMessageFromApiResponse(e))
            setFormerrors(setPointerErrorsFromApiResponse(e))
        }
    }

    const handleChange = (event) => {
        const decimalPattern = /^-?\d*\.?\d{0,2}$/
        if (event.target.name === 'currentPoints' && !decimalPattern.test(event.target.value)) {
            return
        }

        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }

    var pointsDifference = useMemo(() => {
        let currentPoints = Number(points)
        let newPoints = Number(formData.currentPoints)
        return newPoints - currentPoints
    }, [formData.currentPoints, points])

    return (
        <Modal style={{ textAlign: "center" }} centered show={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title >{t('OrderSubheader:editPoints')}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form
                    id="my-form"
                    onSubmit={handleSubmit}
                >
                    <Form.Group className="mb-3">
                        <Form.Label>{t('OrderSubheader:changeReason')} *</Form.Label>
                        <ArraySelect
                            options={editReasons}
                            value={formData.changeReasonId}
                            onChange={handleChange}
                            name="changeReasonId"
                            returnEvent
                            isInvalid={!!formerrors.changeReasonId}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('OrderSubheader:newAccountBalance')} *</Form.Label>
                        <InputGroup >
                            <Form.Control
                                min={allowReleasePointsChanges ? 0 : points}
                                type="number"
                                placeholder=""
                                step="0.01"
                                value={formData.currentPoints}
                                onChange={handleChange}
                                name="currentPoints"
                            />
                            <InputGroup.Text className="justify-content-center" style={{ minWidth: "9rem" }} >({pointsDifference >= 0 ? `+${pointsDifference}` : pointsDifference} {t('points')})</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Form>
                {reportErrors.reportName && <Alert variant="danger">{reportErrors.reportName.value}</Alert>}
                {error && <Alert variant="danger">{error}</Alert>}
                {info && <Alert variant="info">{info}</Alert>}
            </Modal.Body >
            <Modal.Footer className="justify-content-start">
                <MandatoryField />
                <StyledButtonsWrapper>
                    <PdfButton
                        variant='secondary'
                        onClick={() => createReport("reportName", APIEndpoints.budgethistory(employeeId), {})}
                        isLoading={reportIsLoading.reportName}
                        buttonText={t('OrderSubheader:accountBallance')}
                    />
                    <ButtonWithLoadingState
                        buttonText={t('saveButton')}
                        loadingText={t('saveButton')}
                        isLoading={isLoading}
                        type="submit"
                        form="my-form"
                        disabled={btnSaveDisable || !formData.changeReasonId || (pointsDifference === 0) || (!allowReleasePointsChanges && (pointsDifference < 0))}
                    />
                </StyledButtonsWrapper>
            </Modal.Footer>
            {reportDownloadUrls.reportName &&
                <DownloadURLAlert
                    downloadUrl={reportDownloadUrls.reportName}
                    setDownloadUrl={() => setReportDownloadUrls({})}
                    fileName={t('employeesPage:accountBallance')}
                />
            }
            {reportSubscriptionId && <ReportSubscriptionModal
                reportSubscriptionId={reportSubscriptionId}
                setReportSubscriptionId={setReportSubscriptionId}
                message={t('reportConfig:createPending')}
            />}
        </Modal>
    )
}

export default EditPointsModal