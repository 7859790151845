import React from 'react'
import styled from 'styled-components'
import Spinner from 'react-bootstrap/Spinner'

const StyledLoadingLayer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    
   &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      background-color: rgba(255,255,255,0.5);
    }
`

export const OverlayLoadingLayer = ({isLoading}) => {
  return isLoading ? <StyledLoadingLayer><Spinner animation='border' variant='primary' /></StyledLoadingLayer> : ''
}
