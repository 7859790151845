import React from 'react'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import AdministrationSidebarNavigation from '../../components/Configuration/AdministrationSidebarNavigation'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import StdPointsCalculation from '../../components/Configuration/ContingentTemplate/StdPointsCalculation'
import { t } from 'i18next'

const StdPointRunAdministrationPage = () => {
  return (<>
    <Subheader>
      <div className="subheader-title flex-grow-1">{t('stdPointRunAdmin:stdPointRunAdmin')}</div>
    </Subheader>
    <FlexWrapper>
      <SidebarLeft>
        <AdministrationSidebarNavigation active="stdPointRunAdministration" />
      </SidebarLeft>
      <ContentArea>
        <StdPointsCalculation />
      </ContentArea>
    </FlexWrapper>
  </>
  )
}

export default StdPointRunAdministrationPage
