import React, { useEffect } from 'react'
import SubheaderInventoryDetail from '../components/Inventory/ArticleDetails/SubheaderInventoryDetail'
import GeneralInfoCard from '../components/Inventory/ArticleDetails/GeneralInfoCard'
import InstancesCard from '../components/Inventory/ArticleDetails/InstancesCard'
import HistoriesCard from '../components/Inventory/ArticleDetails/HistoriesCard'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import { FlexWrapper } from '../components/Page/logix2020/FlexWrapper'
import BigFooter from '../components/Footer/BigFooter'
import { Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'


const InventoryDetailPage = inject('inventoryStore')(observer(props => {
    const { inventoryStore } = props
    const { employeeId, contingentArticleId } = useParams()

    useEffect(() => {
        const initData = async () => {
            await inventoryStore.setEmployeeId(employeeId)
            await inventoryStore.setSelectedContingentArticleId(contingentArticleId)
            inventoryStore.loadContingentArticleDetails()
            inventoryStore.loadContingentArticleHistories()
            inventoryStore.loadContingentArticleInstances()
        }
        initData()
    }, [employeeId, contingentArticleId, inventoryStore])

    return (
        <>
            <SubheaderInventoryDetail />
            <FlexWrapper>
                <ContentArea>
                    <Row>
                        <Col lg={12} xl={6}>
                            <GeneralInfoCard /></Col>
                        <Col lg={12} xl={6}>
                            <HistoriesCard />
                        </Col>
                    </Row>
                    <Row>
                        <Col ><InstancesCard /></Col>
                    </Row>
                </ContentArea>
            </FlexWrapper>
            <BigFooter />
        </>
    )
}))

export default withTranslation()(InventoryDetailPage)
