import React, { useEffect } from 'react'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import { FlexWrapper } from '../components/Page/logix2020/FlexWrapper'
import BigFooter from '../components/Footer/BigFooter'
import { inject, observer } from 'mobx-react'
import SubheaderImageUpload from '../components/Inventory/ImageUpload/SubheaderImageUpload'
import ArticleRow from '../components/Inventory/ImageUpload/ArticleRow'
import { t } from 'i18next'
import { useState } from 'react'
import ModalComponent2 from '../components/Modals/ModalComponent2'
import { useNavigate, useParams } from 'react-router-dom'
import { Alert } from 'react-bootstrap'
import DragAndDropAnimation from '../components/Inventory/ImageUpload/DragAndDropAnimation'

/* let testMissingImagesList = [{
    rfid: "11111",
    id: 1,
    articleVariant: {
      displayName: "blub",
    },
    changeReason: {
      displayName: "bla",
    },
    article: {
      articleNumber: "1234"
    }
  }] */


const InventoryImageUploadPage = inject('inventoryStore', 'profileStore')(observer(props => {
  const { inventoryStore, profileStore } = props
  const { employeeId } = useParams()
  const [openInfoModal, setOpenInfoModal] = useState(true)
  const [success, setSuccess] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
    const initData = async () => {
      await inventoryStore.setEmployeeId(employeeId)
    }
    initData()
    return () => {
    }
  }, [employeeId, inventoryStore])

  const successRedirect = () => {
    setSuccess(true)
    inventoryStore.loadEmployeeContingent(true)
    let path = (inventoryStore.employeeId === profileStore.profile.id) ? '/inventory' : `/inventory/${employeeId}`
    setTimeout(() => { navigate(path) }, 2000)
  }

  return (
    <>
      <SubheaderImageUpload setOpenInfoModal={setOpenInfoModal} />
      <FlexWrapper>
        <ContentArea>
          {
            success ? <Alert variant="success">{t('inventory:inventoryBookingsSuccess')}</Alert> : null
          }
          <div className='container-fluid'>
            {inventoryStore.missingImagesList.map(article => <ArticleRow key={article.id} article={article} successRedirect={successRedirect} />)}
          </div>
        </ContentArea>
      </FlexWrapper>
      {openInfoModal && (<ModalComponent2
        size="xl"
        withoutCloseButton
        ModalTitle={t('inventory:imageUploadAlertHeader')}
        button1Function={() => setOpenInfoModal(false)}
        button1Text={t('closeButton')}
        BodyPart1={t('inventory:imageUploadAlertBody')}
      >
        <DragAndDropAnimation />
      </ModalComponent2>
      )}
      <BigFooter />
    </>
  )
}))

export default InventoryImageUploadPage
