import React from 'react'

export const Headgear = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="headgear" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <path className="st0" d="M98.2,58.3l-22.6-3.1L75.5,55c-4.6-12.8-16.9-20.9-30-20.9c-3.3,0-6.7,0.5-10,1.7l-2.1,0.8
        C18.9,42.7,11.1,58.8,15.3,74c0.1,0.5,0.8,0.9,1.4,0.9c0,0,0,0,0,0c10.2-1.4,20.4-3.7,30.3-6.8l0.1,0l0.1,0c1.8,0.3,3.4,0.6,5,0.8
        c5.6,0.8,11.4,1.2,17.1,1.2c4.9,0.1,9.9-0.4,14.8-1.2c9.3-1.7,14.6-4.8,15.2-9.2C99.3,59,98.8,58.4,98.2,58.3z M40.1,37.1
        c1.8-0.4,3.7-0.6,5.6-0.6h0c4.4,0,8.9,1.1,12.9,3c6.5,3.1,11.6,8.6,14.3,15.3l0.1,0.3l-0.3,0.2c-4.1,2-8.2,3.8-12.2,5.4L60,60.9
        l-0.1-0.4c-2.6-9.1-8.9-16.3-19.9-22.6L39,37.3L40.1,37.1z M17.7,72.4l-0.4,0.1L17.2,72C14.1,57.9,22,43.4,35.6,38.3l0.2-0.1
        l0.2,0.1C48.4,44.9,55.1,52,57.6,61.4l0.1,0.3l-0.3,0.1C44.5,66.9,31.2,70.4,17.7,72.4z M96.1,61.1c-1.6,2.2-5.9,4.1-11.8,5.3l0,0
        l-0.7,0.1c-4.2,0.7-9,1.1-14.2,1.1c-4.9,0-10.2-0.3-15.6-1l-1.6-0.2l7.3-2.7c0.1,0,0.2-0.1,0.3-0.1c4.7-1.8,9.4-3.9,14-6.1l0.1-0.1
        l0.1,0l22.6,3L96.1,61.1z"/>
    </svg>
  )
}
