import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { DeleteIcon } from '../Icons/DeleteIcon'
import { CustomizationIcon } from '../Icons/CustomizationIcon'
import ArraySelect from '../Select/ArraySelect'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'
import AvailabilityBubble from './AvailabilityBubble'
import QuantityStatus from './QuantityStatus'
import { emptyGuid } from '../../utils/emptyGuid'
import { colors } from '../../styles/colors'


const VariantQuantityRow = inject('productDetailsStore', 'teamOrderStore')(observer((props) => {
  const { teamOrderStore, item, index, preparedVariantSizes, openConfigurationModal, withConfigurationButton } = props
  const [availabilityColor, setAvailabilityColor] = useState('')
  const [deliveryInformation, setDeliveryInformation] = useState('')
  const variantQuantity = item.variantQuantity
  const hasConfigurations = item.customConfigurations && Object.values(item.customConfigurations).some(config => config.article && config.article.id && config.article.id !== emptyGuid)

  useEffect(() => {
    if (variantQuantity) {
      const popoverContent = getPopoverContent(variantQuantity)
      setDeliveryInformation(popoverContent)

      switch (variantQuantity.status) {
        case 'isAvailable':
          setAvailabilityColor(colors.green)
          break;
        case 'isNotAvailable':
          setAvailabilityColor(colors.red)
          break;
        case 'isLimited':
          setAvailabilityColor(colors.yellow)
          break;
        default:
          setAvailabilityColor('')
          break;
      }
    }
  }, [variantQuantity])

  const getPopoverContent = (variantQuantity) => {
    return <QuantityStatus variantQuantities={variantQuantity} />
  }

  return (
    <div className="row align-items-baseline" >
      <Form.Group className="col-6 mb-3">
        <ArraySelect
          options={preparedVariantSizes}
          onChange={preparedSize => teamOrderStore.changeVariantSize(index, preparedSize.id)}
          value={item.variantId}
        />
      </Form.Group>
      <Form.Group className="col-3 mb-3 align-self-center">
        <Form.Control
          type="number"
          value={item.quantity}
          onChange={(e) => teamOrderStore.changeVariantQuantity(index, e.target.value)}
          min={1}
          isInvalid={!!teamOrderStore.selectedVariantQuantitiesErros[index]}
        />
      </Form.Group>
      {withConfigurationButton ? <div className="col-1  form-group">
        <div onClick={openConfigurationModal} style={{ cursor: 'pointer' }} title={t('sizeDetection:editCustomization')}>
          <CustomizationIcon color={hasConfigurations ? colors.green : null} />
        </div>
      </div> : null}

      <div className="col-1 form-group">
        <div>
          <AvailabilityBubble color={availabilityColor} popoverContent={deliveryInformation} />
        </div>
      </div>

      <div className="col-1  form-group">
        <div onClick={() => teamOrderStore.removeVariant(index)} style={{ cursor: 'pointer' }} title={t('delete')}>
          <DeleteIcon color="black"></DeleteIcon>
        </div>
      </div>
    </div>
  )
}))

export default VariantQuantityRow
