import React from 'react'
import { useNavigate } from 'react-router'
import { t } from 'i18next'
import ModalComponent2 from '../../Modals/ModalComponent2'
import { Alert, Form } from 'react-bootstrap'
import { useState } from 'react'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import apiClient from '../../../utils/api/apiClient'
import { getErrorMessageFromApiResponse } from '../../../utils/api/apiUtils'
import { BsHourglassSplit } from 'react-icons/bs'
import { colors } from '../../../styles/colors'

const ReportSubscriptionModal = ({ reportSubscriptionId, setReportSubscriptionId, message }) => {
  const [sendSubscriptionIsLoading, setSendSubscriptionIsLoading] = useState(false)
  const [sendSubscriptionSuccess, setSendSubscriptionSuccess] = useState(false)
  const [sendSubscriptionError, setSendSubscriptionError] = useState(false)
  const [email, setEmail] = useState('')
  const navigate = useNavigate()


  const sendSubscription = async () => {
    setSendSubscriptionError(false)
    setSendSubscriptionIsLoading(true)
    try {
      await apiClient.postJson(APIEndpoints.reportJobs(reportSubscriptionId).subscriptions, { emailAddress: email })
      setSendSubscriptionSuccess(true)
    }
    catch (e) {
      setSendSubscriptionError(getErrorMessageFromApiResponse(e))
    }
    finally {
      setSendSubscriptionIsLoading(false)
    }
  }

  return (
    <ModalComponent2
      HeaderIcon={<BsHourglassSplit size={40} color={colors.gray2} />}
      functionCancel={() => setReportSubscriptionId('')}
      button1Text={sendSubscriptionSuccess ? null : t('reportConfig:sendPerMail')}
      button1Function={sendSubscription}
      button1Loading={sendSubscriptionIsLoading}
      button1Disabled={!email && !sendSubscriptionSuccess}
      showButton2
      button2Text={t('reportConfig:goToReports')}
      button2Function={() => { navigate(`/#reports`) }}
    >
      {!sendSubscriptionSuccess
        ? <> <Alert variant="success">{message}</Alert>
          <Form.Group className="mb-3">
            <Form.Label>  {t('reportConfig:emailAddress')}</Form.Label>
            <Form.Control
              type="email"
              name="name"
              value={email}
              onChange={e => { setEmail(e.target.value) }}
            />
          </Form.Group></>
        : null}
      {sendSubscriptionError
        ? < Alert variant="danger">{sendSubscriptionError}</Alert>
        : null}
    </ModalComponent2 >
  )
}

export default ReportSubscriptionModal