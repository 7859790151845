import React, { useState, useEffect, useCallback } from "react";
import { Modal, Spinner, Alert, Button } from "react-bootstrap";
import ReturnAddressForm from "../OrderActions/ReturnDialog/ReturnAddressForm";
import apiClient from "../../utils/api/apiClient";
import { APIEndpoints } from "../../utils/api/apiConfig";
import { t } from 'i18next'
import { getErrorMessageFromApiResponse } from "../../utils/api/apiUtils";
import { ButtonWithLoadingState } from "../Buttons";
import OrderActionResult from "../OrderActions/shared/OrderActionResult";
import DownloadURLAlert from "../Forms/DownloadURLAlert";
import { PdfButton } from "../Buttons/PdfButton";
import useStores from "../../stores/useStore";
import { useLocation, useNavigate } from "react-router";


const ReturnContactDataModal = ({ retourResponse, setOpenDeactivateModal, setReturnResponse }) => {
  const currentLocation = useLocation()
  const [formData, setFormData] = useState(undefined)
  const [formValidated, setFormValidated] = useState(false)
  const [countries, setCountries] = useState([])
  const [contactData, setContactData] = useState({})
  const [dataIsLoading, setDataIsLoading] = useState(true)
  const [submitIsLoading, setSubmitIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [returnsPortalUrl, setReturnsPortalUrl] = useState('')
  const [success, setSuccess] = useState(false)
  //const [redirectToInventoryPage, setRedirectToInventoryPage] = useState(false)
  const [pDFDownloadUrl, setPDFDownloadUrl] = useState('')
  const [pdfIsLoading, setPdfIsLoading] = useState(false)
  const { uiStore } = useStores()
  const navigate = useNavigate()

  const loadContactData = useCallback(async () => {
    return await apiClient.getJson(APIEndpoints.returns(retourResponse.id).contactData)
  }, [retourResponse.id])

  const initData = useCallback(async () => {
    try {
      const promises = await Promise.all([loadCountries(), loadContactData()])
      setCountries(promises[0])
      setContactData(promises[1])
    }
    catch (e) {
      setError(getErrorMessageFromApiResponse(e))
    } finally {
      setDataIsLoading(false)
    }
  }, [loadContactData])

  useEffect(() => {
    initData()
  }, [initData])



  const handleFormChange = useCallback((data, validated) => {
    setFormData(data)
    setFormValidated(validated)
  }, [])
  async function handleSubmit() {
    setError('')
    setSuccess(false)
    setSubmitIsLoading(true)
    const params = getParams()
    try {
      await sendContactData(params)
      setSuccess(true)
      if (retourResponse.returnsPortalUrl) {
        redirectToPortal(retourResponse.returnsPortalUrl)
      }
    }
    catch (e) {
      setError(getErrorMessageFromApiResponse(e))
    } finally {
      setSubmitIsLoading(false)
    }
  }
  async function loadCountries() {
    return await apiClient.getJson(APIEndpoints.countries)
  }

  async function sendContactData(params) {
    return await apiClient.postJson(APIEndpoints.returns(retourResponse.id).contactData, params)
  }
  function getParams() {
    return {
      phone: formData.phone,
      email: formData.email,
      shipmentAddress: {
        officeName: formData.company,
        firstName: formData.firstName,
        lastName: formData.lastName,
        street: formData.street,
        streetNr: formData.houseNumber,
        city: formData.city,
        zipCode: formData.zip,
        countryIsoCode: formData.country,
        careOf: formData.careOf
      }
    }
  }
  const redirectToPortal = (returnsPortalUrl) => {
    const newWindowRef = window.open(returnsPortalUrl, '_blank')
    if (!newWindowRef) {
      setReturnsPortalUrl(returnsPortalUrl)
    }
  }
  function loadPdf() {
    setError('')
    setPdfIsLoading(true)
    setPDFDownloadUrl('')

    apiClient.postJson(APIEndpoints.returns(retourResponse.id).receipt, {}, true, true)
      .then(response => {
        if (response.headers.location) {
          const newWindowRef = window.open(response.headers.location)
          if (!newWindowRef) {
            setPDFDownloadUrl(response.headers.location)
          }
        }
      }).catch(() => {
        setError(t('reportConfig.createError'))
      }).finally(() => {
        setPdfIsLoading(false)
      })
  }

  function onCancel() {
    if (uiStore.allowChangeActivationStatus) {
      setOpenDeactivateModal(true)
      setReturnResponse({})
    }
    else { navigate(currentLocation.state.from) }
  }



  // if (redirectToInventoryPage) {
  //   const { employeeId } = useParams()
  //   let pathToInventoryPage = employeeId ? `/inventory/${employeeId}` : '/inventory'
  //   return <Navigate  to={pathToInventoryPage} />
  // }

  return (
    <Modal style={{ textAlign: "center" }} centered show={true} >
      <Modal.Header >
        <Modal.Title >{t('registrationBox:contactDetails')}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        {dataIsLoading ? <div className='d-flex justify-content-center p-5'><Spinner animation='border' variant='primary' /></div>
          : <>
            {success ?
              <OrderActionResult
                issueId={retourResponse.feedbackNumber}
                issueEmail={formData.email}
                emailWasSent={retourResponse.emailWasSent}
              /> : <ReturnAddressForm
                onChange={handleFormChange}
                countries={countries}
                contactData={contactData}
              />}
          </>
        }
        {error && <Alert variant="danger">{error}</Alert>}
        {returnsPortalUrl &&
          <DownloadURLAlert
            downloadUrl={returnsPortalUrl}
            setDownloadUrl={setReturnsPortalUrl}
            fileName={returnsPortalUrl}
          />}
        {pDFDownloadUrl &&
          <DownloadURLAlert
            downloadUrl={pDFDownloadUrl}
            setDownloadUrl={setPDFDownloadUrl}
            fileName={pDFDownloadUrl}
          />}
      </Modal.Body >
      <Modal.Footer >
        {!success ? <ButtonWithLoadingState
          buttonText={t('submitButton')}
          disabled={!formValidated}
          isLoading={submitIsLoading}
          onClick={handleSubmit}
        />
          :
          <React.Fragment>
            <PdfButton
              variant='secondary'
              onClick={() => loadPdf()}
              isLoading={pdfIsLoading}
              buttonText={t('orderDialog:printReturnForm')}
            />
            <Button
              variant='primary'
              onClick={onCancel}
            >
              {t('closeButton')}
            </Button>
          </React.Fragment>
        }
      </Modal.Footer>
    </Modal >
  )
}

export default ReturnContactDataModal
