import React from 'react'
import EditContentArea from '../../components/Employees/EditAppointments/EditContentArea'
import EditSubheader from '../../components/Employees/EditAppointments/EditSubheader'



const EditAppointmentsPage = () => {

  return (
    <>
      <EditSubheader />
      <EditContentArea />
    </>
  )
}

export default EditAppointmentsPage
