import styled from 'styled-components'
import { colors } from '../../styles/colors'

export const VisualInput = styled.div`
position:relative;
  min-height: 38px;
  border: 1px solid ${colors.borderColor};
  padding: 6px 10px;
  background-color: ${colors.gray2};
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  line-height: 1.5;
  overflow-wrap: break-word;

  &.is-invalid {
    border-color: ${colors.red};
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  }
  &.white-background{
    background:white;
  }
`
