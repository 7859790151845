import React, { useState } from 'react'
import styled from 'styled-components'
import OrdersListItem from './OrdersListItem'
import { colors } from '../../styles/colors'
import { Alert, Spinner } from 'react-bootstrap'
import MediaQuery from 'react-responsive'
import { withTranslation } from 'react-i18next'
import { observer, inject } from 'mobx-react'
import ModalComponent from '../Modals/ModalComponent'
import LazyLoad from '../Forms/LazyLoad'
const StyledTable = styled.table`
  width:100%;
  color: ${colors.gray3};
`

const StyledList = styled.ul`
  color: ${colors.gray3};
  padding: 10px 0;
`

const OrdersList = inject('ordersStore', 'orderStore')(observer(props => {
  const { t, ordersStore, orderStore } = props;
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  if ((ordersStore.incomingOrdersIsLoading && ordersStore.incomingOrders.length === 0) || ordersStore.incomingOrdersCounterIsLoading) {
    return <div className='mt-3 d-flex justify-content-center'>
      <Spinner animation='border' variant='primary' />
    </div>
  }

  if (ordersStore.incomingOrders.length === 0) {
    return <Alert variant='info' className='mt-3'>{t('orders:noOrdersExists')}</Alert>
  }
  const handleDelete = async (orderId, cancellationText) => {
    await orderStore.deleteOrder(orderId, cancellationText)
    ordersStore.loadIncomingOrders()
  }
  return (
    <React.Fragment>
      {ordersStore.incomingOrdersError ? <Alert variant='danger' className='mt-3'>{ordersStore.incomingOrdersError}</Alert> : null}
      <MediaQuery maxWidth={1299}>
        <StyledList>
          {ordersStore.incomingOrders.map(listItem => <OrdersListItem setOpenDeleteModal={setOpenDeleteModal} key={listItem.id} item={listItem} />)}
        </StyledList>
      </MediaQuery>
      <MediaQuery minWidth={1300}>
        <StyledTable>
          <tbody>
            {ordersStore.incomingOrders.map(listItem => <OrdersListItem setOpenDeleteModal={setOpenDeleteModal} key={listItem.id} item={listItem} />)}
          </tbody>
        </StyledTable>
      </MediaQuery>
      {ordersStore.incomingOrdersIsLoading &&
        <div className='mt-3 d-flex justify-content-center'>
          <Spinner animation='border' variant='primary' />
        </div>}
      {((ordersStore.totalCount > ordersStore.offset) && !ordersStore.incomingOrdersIsLoading) ?
        (<LazyLoad
          fetchData={() => ordersStore.loadIncomingOrders({ append: true })}
        />) : ''}
      {openDeleteModal && (<ModalComponent
        ModalTitle={t('orderTemplates:templateOrderDelete')}
        functionButton1={(cancellationText) => handleDelete(openDeleteModal, cancellationText)}
        textButton1={t('delete')}
        variantButton1="danger"
        variantBody="alert-danger"
        withInput
        inputLabel={t('cancelMessage')}
        BodyPart1={t('orderTemplates:areYouSureDelete')}
        functionCancel={() => { setOpenDeleteModal(false) }}
      />)}
    </React.Fragment>
  )

}))

export default withTranslation()(OrdersList)
