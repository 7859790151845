import React, { useState, useCallback, memo, useMemo } from 'react'
import styled from 'styled-components'
import { StyledCollapseTrigger } from '../formHelper'
import { Collapse, Spinner } from 'react-bootstrap'
import { ArrowBigIcon } from '../../../Icons/ArrowBigIcon'
import { colors } from '../../../../styles/colors'
import { useObserver } from 'mobx-react'
import useStores from '../../../../stores/useStore'
import { t } from 'i18next'
import OfficesTree from '../../../Offices/OfficesTree'
import { emptyGuid } from '../../../../utils/emptyGuid'

const StyledOfficeTree = styled.div`
  padding:1rem;
  background: ${colors.gray1};
  margin-bottom:1rem;
  height:400px;
  overflow:auto;
  border: 1px solid ${colors.gray3};
  width:100%;
  @media screen and (min-width: 1080px) {
    max-width: calc(100% - 480px);
    min-width: 600px;
  }
  .checkBox {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: center;  
   }
`

const OfficeMultiSelect = memo(({
    collapsible,
    mainData,
    accountData,
    setAccountDataByKey
}) => {
    const { officeStore } = useStores()
    const partialOffices = useObserver(() => officeStore.loadedOffices)
    const allOfficesLoaded = useObserver(() => officeStore.allLoaded)
    const [opened, setOpened] = useState(!collapsible)

    const selectedOffices = accountData.accessibleOffices

    const mainOfficeId = mainData.office.id

    const handleClickCollapse = useCallback(() => {
        setOpened(prev => !prev)
    }, [setOpened])

    const handleChange = office => e => {
        const childIds = getAllChildIds(office)
        var newValues = [...selectedOffices]
        if (e.target.checked) {
            for (const id of childIds) {
                if (!selectedOffices.includes(id)) {
                    newValues.push(id)
                }
            }
        }
        else {
            for (const id of childIds) {
                let foundIndex = newValues.indexOf(id);
                if (foundIndex > -1) {
                    newValues.splice(foundIndex, 1)
                }
            }
        }
        setAccountDataByKey("accessibleOffices", newValues)
    }

    const getAllChildIds = (office) => {
        const ids = [office.id]
        if (office.hasChildren) {
            const children = getChildOffices(office)
            for (const child of children) {
                ids.push(...getAllChildIds(child))
            }
        }
        return ids
    }

    const getChildOffices = (parentOffice) => {
        return partialOffices.filter(office => office.parentOffice && office.parentOffice.id === parentOffice.id)
    }
    const getParentOffice = useCallback((childOffice) => {
        return partialOffices.find(office => childOffice.parentOffice && childOffice.parentOffice.id === office.id);
    }, [partialOffices])
    const getAllParentIds = useCallback((office, allParentIds) => {
        const ids = [];
        if (office && office.parentOffice) {
            const parent = getParentOffice(office);
            if (!allParentIds.includes(parent.id)) {
                ids.push(office.parentOffice.id, ...getAllParentIds(parent, allParentIds));
            }
        }
        return ids;
    }, [getParentOffice])



    const getOfficeById = useCallback((officeId) => {
        return partialOffices.find(office => office.id === officeId)
    }, [partialOffices])

    const parentFolders = useMemo(() => {
        let allParentIds = []
        if (allOfficesLoaded && partialOffices.length < 2000) {
            for (let officeId of selectedOffices) {
                let office = getOfficeById(officeId)
                let newParents = getAllParentIds(office, allParentIds)
                allParentIds.push(...newParents)
            }
            if (mainOfficeId !== emptyGuid) {
                let office = getOfficeById(mainOfficeId)
                allParentIds.push(getAllParentIds(office, allParentIds))
            }
            return allParentIds
        }
        return allParentIds
    }, [allOfficesLoaded, partialOffices.length, mainOfficeId, selectedOffices, getOfficeById, getAllParentIds])

    const renderExtraItemContent = (office) => {
        let isMainOffice = office.id === mainOfficeId
        return (
            <div className="checkBox">
                <input checked={selectedOffices.includes(office.id) || isMainOffice} onChange={handleChange(office)} disabled={isMainOffice} className="me-4" type="checkbox" />
            </div>
        )
    }

    return (
        <>
            {collapsible ? (
                <StyledCollapseTrigger onClick={handleClickCollapse}>
                    <h2 className="h1"><ArrowBigIcon variant={opened ? 'down' : 'right'} color={colors.textColor} />{t('administration:officeadministration')}
                    </h2>
                </StyledCollapseTrigger>
            ) : (
                <h2 className="h1">{t('administration:officeadministration')}</h2>
            )}
            <Collapse in={opened}>
                <div className="spacing-down">
                    {!allOfficesLoaded
                        ? <div className="d-flex justify-content-center p-3"><Spinner animation='border' variant='primary' /></div>
                        : <>
                            <StyledOfficeTree><OfficesTree
                                renderExtraItemContent={renderExtraItemContent}
                                parentsWithSelectedChildren={parentFolders}
                            /></StyledOfficeTree>
                        </>}
                </div>
            </Collapse>
        </>
    )
})

export default OfficeMultiSelect