export const getIsoDate = (date, asEnding = null) => {
  const preparedDate = date instanceof Date ? new Date(date) : false
  if (preparedDate) {
    if (asEnding === false) {
      preparedDate.setHours(0)
      preparedDate.setMinutes(0)
      preparedDate.setSeconds(0)
      preparedDate.setMilliseconds(0)
    }
    if (asEnding === true) {
      preparedDate.setHours(23)
      preparedDate.setMinutes(59)
      preparedDate.setSeconds(59)
      preparedDate.setMilliseconds(999)
    }

    preparedDate.setMinutes(preparedDate.getMinutes() - preparedDate.getTimezoneOffset())
  }
  return (preparedDate && preparedDate.toISOString()) || ''

}

// function to format the date if there is a problem with the time zone -------------------------------------->
