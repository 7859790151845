import React from 'react'

import styled, { useTheme } from "styled-components";
import { ArrowIconNew, StyledArrowWrapper, StyledDot, StyledIconWrapper, StyledItemWrapper } from "./treeList";
import { CategoryIconDefault, CategoryIcons } from "../Icons/CategoryIcons/CategoryIcons";
import { Spinner } from "react-bootstrap";
import { FolderIcon } from "../Icons/FolderIcon";
import { FolderOpenIcon } from '../Icons/FolderOpenIcon';
import { colors } from '../../styles/colors';

export const StyledItemWrapperDND = styled(StyledItemWrapper)`
  margin-left:${props => `${props.hierarchyLevel}rem`} ;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => props.theme.primaryColor};
    visibility:${(props => (props.articleOverGroup || props.assigned) ? "visible" : "hidden")};
    opacity:0.5;
    pointer-events:none;
  } 
  &:after {
  content: '';
  position: absolute;
  background: transparent;
  width: 100%;
  z-index: 10;
    ${props => props.showBottomLine &&
    `bottom:0px;
      box-shadow: 0 0 5px 1px ${props.theme.primaryColor};`
  };
    ${props => props.showTopLine &&
    `top:0px;
        box-shadow: 0 0 5px 1px ${props.theme.primaryColor};`
  };
}
 
`
const StyledArticleIconWrapper = styled.div`
margin-right:1rem;
width: 28px;
height: 28px;
flex-shrink: 0;
`
const StyledSpinner = styled.div`
width: 30px;
height: 28px;
display: flex;
align-items: center;
justify-content: center;
`

export const ArticleIcon = ({ iconType }) => {
  const theme = useTheme()
  const CategoryIcon = iconType && CategoryIcons[iconType] ? CategoryIcons[iconType] : CategoryIconDefault
  return <StyledArticleIconWrapper>
    < CategoryIcon color={theme.primaryColor} />
  </StyledArticleIconWrapper>
}

export const renderGroupArrow = (isOpen, loaded, hasArticles, handleOpen) => (
  <StyledArrowWrapper onClick={handleOpen} >
    {(isOpen && !loaded)
      ? <StyledSpinner><Spinner animation='border' variant='primary' size="sm" /></StyledSpinner>
      : (isOpen && loaded && !hasArticles)
        ? <StyledDot>•</StyledDot>
        : <ArrowIconNew color={isOpen ? '#9c9c9c' : '#3a3a38'}
          variant={isOpen ? 'down' : 'right'} />}
    <StyledIconWrapper>
      {isOpen ? <FolderOpenIcon color={colors.gray7} />
        : <FolderIcon color={colors.gray7} />
      }
    </StyledIconWrapper>
  </StyledArrowWrapper>)

export const renderTemplateArrow = (isOpen, loaded, handleOpen) => (
  <StyledArrowWrapper onClick={handleOpen} >
    {(isOpen && !loaded)
      ? <StyledSpinner><Spinner animation='border' variant='primary' size="sm" /></StyledSpinner>
      : <ArrowIconNew color={isOpen ? '#9c9c9c' : '#3a3a38'}
        variant={isOpen ? 'down' : 'right'} />}
  </StyledArrowWrapper>)