import React from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useAppointmentState } from '../../../stores/AppointmentProvider'
import { emptyGuid } from '../../../utils/emptyGuid'
import ArraySelect from '../../Select/ArraySelect'
const CabinSelect = () => {
    const { t } = useTranslation()

    const {
        setAppointment,
        appointment,
        cabins,
        getDateSlots

    } = useAppointmentState()

    const handleChange = cabin => {
        console.log("handle change", cabin)
        const resetDay = ""
        const resetTimeSlot = {
            hour: 0,
            minute: 0,
            id: emptyGuid
        }
        let newAppointment = { ...appointment, cabin: cabin, day: resetDay, timeSlot: resetTimeSlot }
        setAppointment(newAppointment)
        let dateNoww = new Date()
        dateNoww.setDate(1)

        getDateSlots(newAppointment, dateNoww)
    }

    return (
        <>
            {appointment &&
                <Form.Group className="mb-3">
                    <Form.Label>{t('appointment:selectCabin')}</Form.Label>
                    <ArraySelect
                        defaultOption={{ value: emptyGuid, name: t('pleaseSelect') }}
                        onChange={handleChange}
                        value={appointment.cabin.id}
                        options={cabins}
                    />
                </Form.Group>
            }

        </>
    )

}

export default CabinSelect
