import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useStores from '../../stores/useStore'
import { useObserver } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import EmployeeDataProvider from '../../stores/EmployeeDataProvider'
import { Alert, Spinner } from 'react-bootstrap'
import { useEmployeeDataState } from '../../stores/EmployeeDataProvider'
import EditSubheader from '../../components/Employees/EditEmployee/EditSubheader'
import Sidebar from '../../components/Employees/EditEmployee/Sidebar'
import EditContentArea from '../../components/Employees/EditEmployee/EditContentArea'
import { StyledJobfunctionContainer } from '../../components/Employees/Form/formHelper'
import styled from 'styled-components'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { TestSidebarRight } from '../../components/Page/logix2020/TestSidebarRight'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'

const StyledContentArea = styled(ContentArea)`
   padding: 20px 40px 20px 36px;
   @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px)  {
    padding: 20px;
    min-height: calc(100vh - 488px);//header+navbar+sidebar=488px
   }
   .added-row {
    label {
      display: block;
    }
  }
`

const EditEmployeePageContent = () => {
  const { employeeId } = useParams()
  const { uiStore, laundryShelfStore } = useStores()
  const { loadEmployeeData, loadAccountData } = useEmployeeDataState()

  useEffect(() => {
    const init = async () => {
      loadEmployeeData(employeeId)
      loadAccountData(employeeId)
      await uiStore.loadBPDataSettings()
      uiStore.laundryShelf_Status !== "hide" && laundryShelfStore.getBPListItem(employeeId)
    }
    init()
    return () => {
      laundryShelfStore.resetLaundryShelfStore()
    }
  }, [employeeId, laundryShelfStore, loadAccountData, loadEmployeeData, uiStore])

  return (
    <>
      <EditSubheader />
      <FlexWrapper>
        <StyledContentArea>
          <EditContentArea />
        </StyledContentArea>
        <TestSidebarRight>
          <Sidebar />
        </TestSidebarRight>
      </FlexWrapper>
    </>
  )
}

const EditEmployeePage = () => {
  const { uiStore } = useStores()
  const { t } = useTranslation()
  const dashboardLoaded = useObserver(() => !!uiStore.dashboard)


  return useObserver(() => (
    <EmployeeDataProvider>
      <StyledJobfunctionContainer>
        {!dashboardLoaded || !(uiStore.employeeAdministration || uiStore.allowAccessUserModule)
          ? (
            <FlexWrapper>
              <StyledContentArea>
                {dashboardLoaded
                  ? <Alert variant="danger">{t('employeesPage:accessDenied')}</Alert>
                  : <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
                }
              </StyledContentArea>
              <TestSidebarRight>
              </TestSidebarRight>
            </FlexWrapper>)
          :
          <EditEmployeePageContent />
        }
        <BigFooter />
      </StyledJobfunctionContainer>
    </EmployeeDataProvider>
  ))
}

export default EditEmployeePage
