import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/colors'

export const StyledOverlayRight = styled.div`
  background: ${colors.gray1};
  //height: calc(100vh - 50px);
  position: fixed;
  bottom: 0;
  right: 0;
  top: 50px;
  //display: flex;
  //flex-direction: column;
  width: 100vw;
  max-width: 100vw;
  box-shadow: -4px 0 9px rgba(0,0,0,0.16);
  transform: translateX(100%);
  transition: transform .3s ease-in-out;
  overflow: auto;
  z-index: ${props => props.overLayZIndex ? props.overLayZIndex : "11"} ;


  @media screen and (min-width: 320px) {
    width: auto;
    min-width: 300px;
  }

  @media screen and (min-width: 480px) {
    width: ${props => props.large ? "600px" : "480px"};
  }

  &.overlay-visible {
    transform: translateX(0);
  }

  header {
    position: sticky;
    top: 0;
    background: ${colors.gray7};
    color: white;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    z-index: 1;
    display: flex;
    align-items: center;

    .header-title {
      font-size: 18px;
      font-weight: normal;
      color: #FFF;
      display: inline-block;
      margin-left: 20px;
    }

    .close-icon {
      width: 18px;
      height: 18px;
      display: inline-block;
      opacity: 1;
      position: relative;
      cursor: pointer;
      margin-top: -3px;

      :hover {
        opacity: .7;
      }

      :before,
      :after {
        content: ' ';
        position: absolute;
       left: calc(50% - 1px);
         height: 100%;
        width: 2px;
        background-color: #fff;
      }
      :before {
        transform: rotate(45deg);
      }
      :after {
        transform: rotate(-45deg);
      } 
    }
  }

  .content {
    flex-grow: 1;
    padding: 0 25px;
  }

    footer {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
    }
`

export const StyledOverlayInnerWrapper = styled.div`
  min-height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  // padding: 28px 20px 0 20px;
`

const OverlayRight = ({ visible, children, className, onClose, headerTitle, overLayZIndex, ...rest }) => {
  return (
    <StyledOverlayRight overLayZIndex={overLayZIndex} className={`${className ? className : ''} ${visible ? 'overlay-visible' : 'overlay-hide'}`} {...rest}>
      <header>
        <span onClick={onClose}><i className="close-icon" /></span>
        {headerTitle && <span className="header-title">{headerTitle}</span>}
      </header>
      {children}
    </StyledOverlayRight>
  )
}

export default OverlayRight
