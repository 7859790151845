import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap'
import { ArrowIcon } from '../Icons/ArrowIcon'

const StyledDropdown = styled.span`
  .dropdown-menu{
    min-width: 100%;
    max-height: 40vh;
    overflow-y: auto;
  }
`

const StyledToggle = styled.span`
  white-space: nowrap;
  cursor: pointer;
  padding-bottom: 2px;
  border-bottom: 3px solid ${props => props.theme.primaryColor};
`
const StyledInactiveLayer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    opacity: 0.7;
  }
`
const CustomToggleClass = ({ innerRef, ...props }) => {
    const handleClick = e => {
        e.preventDefault()
        props.onClick(e)
    }
    return (
        <StyledToggle ref={innerRef} onClick={handleClick}>
            {props.children}
        </StyledToggle>
    )
}

const CustomToggle = React.forwardRef((props, ref) => <CustomToggleClass innerRef={ref} {...props} />)

const CustomDropdown = ({ value, data = [], returnObject, disabled, ...rest }) => {
    const [selected, setSelected] = useState(0)

    const handleChange = selectedIndex => {
        setSelected(selectedIndex)
        returnObject(data[selectedIndex])
    }
    useEffect(() => {
        const index = data.findIndex(obj => obj.value === value)
        if (index >= 0) setSelected(index)
    }, [value, data])

    return (
        <StyledDropdown {...rest}>
            {disabled && <StyledInactiveLayer> </StyledInactiveLayer>}
            {data.length ?
                <Dropdown className='d-inline' onSelect={handleChange}>
                    <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
                        <span className="blublub">{data[selected].name}</span> <span><ArrowIcon variant='down' /></span>
                    </Dropdown.Toggle>
                    {disabled ? null : <Dropdown.Menu>
                        {data.map((obj, i) => <Dropdown.Item key={i} eventKey={i}>{obj.name}</Dropdown.Item>)}
                    </Dropdown.Menu>}
                </Dropdown>
                : null}
        </StyledDropdown>
    )
}

export default CustomDropdown