import React from 'react'
import { Subheader } from '../../components/Page/logix2020/Subheader'

import AdministrationSidebarNavigation from '../../components/Configuration/AdministrationSidebarNavigation'
import { useTranslation } from 'react-i18next'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import { Link } from 'react-router-dom'
import CabinTypesProvider from '../../stores/Configuration/CabinTypesProvider'
import CabinTypesOverview from '../../components/Configuration/CabinType/CabinTypesOverviews'
import useStores from '../../stores/useStore'
import { Alert, Spinner } from 'react-bootstrap'
import { MdLibraryAdd } from 'react-icons/md'
import { useObserver } from 'mobx-react'



const CabinTypesAdministrationPage = () => {
  const { t } = useTranslation()
  const { uiStore } = useStores()
  const fittingAdministration = useObserver(() => uiStore.fittingAdministration)
  const dashboardLoaded = useObserver(() => !!uiStore.dashboard)

  return (
    <>
      <Subheader className="subHeader-v1 justify-content-between">
        <div className="subheader-title flex-grow-1">{t('cabinTypes:cabinAdministration')}</div>
        {fittingAdministration && <Link to='/cabintypes/create' className="subheader__button btn btn-primary text-white"><MdLibraryAdd className="me-3" />{t('cabinTypes:addNewCabin')}</Link>}
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <AdministrationSidebarNavigation active="cabintypes" />
        </SidebarLeft>
        <ContentArea>
          {fittingAdministration ?
            <CabinTypesProvider>
              <CabinTypesOverview />
            </CabinTypesProvider>
            : (!dashboardLoaded)
              ? <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
              : <Alert variant="danger">{t('accessDenied')}</Alert>
          }
        </ContentArea>
      </FlexWrapper>
      <BigFooter />
    </>
  )
}

export default CabinTypesAdministrationPage