import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import OrdersTabs from '../components/Orders/OrdersTabs'
import OrdersSearchField from '../components/Orders/OrdersSearchField'
import CostCenterSearchField from '../components/Orders/CostCenterSearchField'
import OrdersArticleSearchField from '../components/Orders/OrdersArticleSearchField'
import OrdersPartnerSearchField from '../components/Orders/OrdersPartnerSearchField'
import { withTranslation } from 'react-i18next'
import { Subheader } from '../components/Page/logix2020/Subheader'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import BigFooter from '../components/Footer/BigFooter'
import CustomDropdown from '../components/Dropdowns/CustomDropdown'
import { cloneDeep } from 'lodash'
import { ExcelButton } from '../components/Buttons/ExcelButton'
import { SidebarLeft } from '../components/Page/logix2020/SidebarLeft'
import { FlexWrapper } from '../components/Page/logix2020/FlexWrapper'
import { Alert } from 'react-bootstrap'
import DownloadURLAlert from '../components/Forms/DownloadURLAlert'
import withRouter from '../utils/withRouter'
import { t } from 'i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useEffect } from 'react'
import { useCallback } from 'react'
import useReport from '../utils/hooks/useReport'
import { APIEndpoints } from '../utils/api/apiConfig'
import ReportSubscriptionModal from '../components/Files/Form/ReportSubscriptionModal'


const OrdersPage = inject('ordersStore', 'uiStore')(observer(({ ordersStore, uiStore }) => {

  const { employeeId } = useParams()
  const location = useLocation()
  const state = useMemo(() => location?.state || {}, [location])
  const { createReport, reportIsLoading, reportDownloadUrls, reportErrors, setReportDownloadUrls, reportSubscriptionId, setReportSubscriptionId } = useReport()

  const navigate = useNavigate();

  const handleChange = useCallback((name, value) => {
    var newState = location.state ? cloneDeep(location.state) : {}
    newState[name] = value
    navigate(location.pathname, { replace: true, state: newState });
  }, [location.pathname, location.state, navigate])

  const fetchData = useCallback(async () => {
    try {
      await ordersStore.loadIncomingOrdersCounter()
      let selectedStatusWithCount = ordersStore.incomingOrdersCounter.find(status => status.status === state.selectedStatus && status.count)
      let someStatusWithCount = ordersStore.incomingOrdersCounter.find(status => status.count)

      if (!selectedStatusWithCount && someStatusWithCount) {
        handleChange("selectedStatus", someStatusWithCount.status)
      }
      else {
        ordersStore.loadIncomingOrders()
      }
    }
    catch (e) {
      console.log(e)
    }
  }, [handleChange, ordersStore, state.selectedStatus])

  useEffect(() => {
    ordersStore.targetBusinessPartnerId = employeeId
    ordersStore.updateState(state)
    fetchData()
  }, [employeeId, fetchData, ordersStore, state])

  const inputWhen = [
    {
      name: t('orders:lastMonth'),
      value: 1
    },
    {
      name: t('orders:last3month'),
      value: 3
    },
    {
      name: t('orders:last6month'),
      value: 6
    },
    {
      name: t('orders:lastYear'),
      value: 12
    },
    {
      name: t('all'),
      value: 0
    }
  ]

  const inputWho = [
    {
      name: t('orders:title'),
      value: false
    },
    {
      name: t('orders:allOrders'),
      value: true
    }
  ]

  const handleCreateReport = () => {
    const params = {
      'status': ordersStore.selectedStatus,
      'lastMonths': ordersStore.lastMonths,
      'searchText': ordersStore.searchText ? ordersStore.searchText : null,
      'articleSearchKeys': ordersStore.articleSearchKeys.length ? ordersStore.articleSearchKeys : null,
      'showAllOrders': ordersStore.showAllOrders ? ordersStore.showAllOrders : null,
      'targetCostcenterId': ordersStore.targetCostcenterId ? ordersStore.targetCostcenterId : null,
      'partnerSearchKeys': ordersStore.partnerSearchKeys.length ? ordersStore.partnerSearchKeys : null
    }
    if (ordersStore.targetBusinessPartnerId) {
      params['targetBusinesspartnerId'] = ordersStore.targetBusinessPartnerId
    }
    createReport("reportName", APIEndpoints.statisticCostcentersFromOrders, params)
  }


  return (
    <React.Fragment>
      <Subheader className="subHeader-v1">
        <div className="subheader-title flex-grow-1">
          {ordersStore.targetBusinessPartnerId ?
            `${t('orders:ordersOfEmployeeTitle')} ${ordersStore.targetBusinessPartner.firstName || ''} ${ordersStore.targetBusinessPartner.lastName || ''}`
            : t('orders:ordersOverview')
          }
        </div>
        {reportDownloadUrls.reportName &&
          <DownloadURLAlert
            downloadUrl={reportDownloadUrls.reportName}
            setDownloadUrl={() => setReportDownloadUrls({})}
            fileName={t("srm:exportButton")}
          />
        }
        {reportErrors.reportName && <Alert variant="danger">{reportErrors.reportName.value}</Alert>}
        {reportSubscriptionId && <ReportSubscriptionModal
          reportSubscriptionId={reportSubscriptionId}
          setReportSubscriptionId={setReportSubscriptionId}
          message={t('reportConfig:createPending')}

        />}
        <ExcelButton
          className="subheader__button"
          isLoading={reportIsLoading.reportName}
          buttonText={t('reports:orderStatistics')}
          variant="secondary"
          onClick={handleCreateReport}
        />
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          {(uiStore.allowShowAllOrders && !ordersStore.targetBusinessPartnerId) ? <div className="mb-3">
            <CustomDropdown value={ordersStore.showAllOrders} data={inputWho} returnObject={(obj) => handleChange("showAllOrders", obj.value)} />
          </div> : null}
          <div className="mb-3">
            <CustomDropdown value={ordersStore.lastMonths} data={inputWhen} returnObject={(obj) => handleChange("lastMonths", obj.value)} />
          </div>
          <div className="mb-3"><CostCenterSearchField handleChange={(costCenterId) => handleChange("targetCostcenterId", costCenterId)} /></div>
          <div className="mb-3"><OrdersArticleSearchField handleChange={(tags) => handleChange("articleSearchKeys", tags)} /></div>
          <div className="mb-3"><OrdersSearchField placeholder={`${t('orders:orderNumber')}, ${t('orders:shippingNumber')}`} handleChange={(text) => handleChange("searchText", text)} /></div>
          <div className="mb-3"> <OrdersPartnerSearchField handleChange={(tags) => handleChange("partnerSearchKeys", tags)} /></div>
        </SidebarLeft>
        <ContentArea>
          <OrdersTabs />
        </ContentArea>
      </FlexWrapper>
      <BigFooter />
    </React.Fragment >
  )
}))

export default withTranslation()(withRouter(OrdersPage))
