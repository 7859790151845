import React, { Component } from 'react'
import styled from 'styled-components'

import Figure from 'react-bootstrap/Figure'
import tshirt_lens from '../../assets/icons/tshirt_lens.svg'
import edit_adress from '../../assets/icons/edit_adress.svg'
import submit from '../../assets/icons/submit.svg'
import { withTranslation, Trans } from "react-i18next";

const InfographWrapper = styled.div`
  margin: 4rem 0;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (min-width: 1200px) {
    flex-wrap: nowrap;
  }

  @media screen and (min-width: 1200px) {
  }

`
const InfographItemWrapper = styled.div`
  flex-direction:column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`
const InfographItem = styled.div`
  padding: 0 2rem 2rem;
  
  @media screen and (min-width: 1200px) {
    padding: 0 2rem;
    border-left: 0;
  }  
  
  .figure-img {
    max-width: 4.5rem;  
    margin-left: 0.5rem;
  }
`
const InfographHeadline = styled.h1`
  font-weight: 600;
  text-align:center;
  font-size: 2rem;

  @media screen and (min-width: 768px) {
    text-align:left;
  }

  @media screen and (min-width: 1200px) {
    padding: 0 2rem ;
    font-size: 2.5rem;
    border-right: 1px solid #ccc;
  }
`

const StyledFigure = styled(Figure)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

class Infograph extends Component {
  render() {
    const { t } = this.props;

    return (
      <InfographWrapper className='d-flex'>
        <InfographHeadline>
          {t('infograph:3StepsIntro')}
        </InfographHeadline>
        <InfographItemWrapper className='d-flex'>
          <InfographItem>
            <StyledFigure>
              <Figure.Caption>
                <h3>1.</h3><Trans i18nKey='infograph:chooseArticle' />
              </Figure.Caption>
              <Figure.Image
                width={180}
                height={180}
                alt="180x180"
                src={tshirt_lens}
              />
            </StyledFigure>
          </InfographItem>
          <InfographItem>
            <StyledFigure>
              <Figure.Caption>
                <h3>2.</h3><Trans i18nKey='infograph:addAddress' />
              </Figure.Caption>
              <Figure.Image
                width={180}
                height={180}
                alt="180x180"
                src={edit_adress}
              />
            </StyledFigure>
          </InfographItem>
          <InfographItem>
            <StyledFigure>
              <Figure.Caption>
                <h3>3.</h3><Trans i18nKey='infograph:submitOrder' />
              </Figure.Caption>
              <Figure.Image
                width={180}
                height={180}
                alt="180x180"
                src={submit}
              />
            </StyledFigure>
          </InfographItem>
        </InfographItemWrapper>
      </InfographWrapper>
    )
  }
}

export default withTranslation()(Infograph)
