import React from 'react'
import styled from 'styled-components'

const StyledProgress = styled.div`
  display: none;
  max-width: 950px;
  background: none;
  height: auto;

  @media screen and (min-width: 510px) {
    display: block;
  }

  ul {
    position: relative;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 50px;
    align-items: center;
    margin: 0;
    &:before {
      content:"";
      top: 10px;
      height: 3px;
      width: calc(100% - 100px);
      left: 40px;
      position: absolute;
      background: ${props => props.theme.primaryColor};
      display: block;
    }
    li {
      align-items: center;
      display: flex;
      flex-direction: column;
      font-size: 10px;
      font-weight: 600;
      color: #303030;
      padding: 0 10px;

      @media screen and (min-width: 480px) {
        font-size: 12px;
      }

      @media screen and (min-width: 768px) {
        font-size: 16px;
      }

      &:first-child {
        transform: translateX(-50%);
      }

      &:last-child {
        transform: translateX(50%);
      }

      .bubble-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:before {
          content:"";
          height: 1.5rem;
          width: 1.5rem;
          display: block;
          border-radius: 10rem;
          background: #dbdbdb;
          border: 3px solid ${props => props.theme.primaryColor};
          text-align: center;
          z-index: 1;
        }
      }

      &.active .bubble-wrapper {
        &:before {
          background: ${props => props.theme.primaryColor};
        }
      }
    }
  }
`
const ShoppingCartProgress = (props) => {
  return (
    <StyledProgress {...props} className="order-progress-v1" />
  )
}

export default ShoppingCartProgress
