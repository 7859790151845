import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { Subheader } from '../components/Page/logix2020/Subheader'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import BigFooter from '../components/Footer/BigFooter'
import styled from 'styled-components'
import getThemeValue from '../utils/getThemeValue'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { Form, Spinner, Row, Col } from 'react-bootstrap'
import { colors } from '../styles/colors'
import { makeCall } from '../utils/api/makeCall'
import ArraySelect from '../components/Select/ArraySelect'

const StyledContentArea = styled(ContentArea)`
   @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px)  {
    padding: 20px;
    min-height: calc(100vh - 140px);//header+navbar=140px
   }
`
const StyledWrapper = styled.div`
@media screen and (min-width: 1080px) {
  min-width: 600px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
`
const StyledSubHeadline = styled.span`
  display: block;
  color: ${colors.gray4}
  font-weight: bold;
  margin: 15px 0 5px;
`

const OrderOnStockPage = () => {
  const { t } = useTranslation()
  const [selectedCostcenterId, setSelectedCostcenterId] = useState(null)
  const [selectedStorehouseId, setSelectedStorehouseId] = useState(null)
  const [costcenters, setCostcenters] = useState([])
  const [storehouses, setStorehouses] = useState([])
  const [errorStates, setErrorStates] = useState({})
  const [loadingStates, setLoadingStates] = useState({})

  const loadStorehouses = useCallback(async () => {
    makeCall('loadStorehouses', async () => {
      let loadedStorehouses = await apiClient.getJson(APIEndpoints.storehouses())
      setStorehouses(loadedStorehouses)
    }, setErrorStates, setLoadingStates)

  }, [])
  const loadCostcenters = useCallback(async (storehouseId) => {
    await makeCall('loadCostcenters', async () => {
      let loadedCC = await apiClient.getJson(APIEndpoints.storehouses(storehouseId).costcenters)
      
      let preparedCC = loadedCC.map(cc => {
        return { displayName: cc.displayName, id: cc.id }
      })
      setCostcenters(preparedCC)
        }, setErrorStates, setLoadingStates)
    }, [])

  useEffect(() => {
    loadStorehouses()
    }, [loadStorehouses])

  const handleChangeStorehouse = (e) => {
    const storehouse = storehouses.find(sth => sth.id === e.target.value)
    setSelectedCostcenterId(null)
    setCostcenters(null)
    if (storehouse) {
        setSelectedStorehouseId(storehouse.id)
        loadCostcenters(storehouse.id)
    } else {
        setSelectedStorehouseId(null)
    }
 }
 const handleChangeCostCenter = (e) => {
    const costcenter = costcenters.find(cc => cc.id === e.target.value)
    if (costcenter) {
        setSelectedCostcenterId(costcenter.id)
    } else {
        setSelectedCostcenterId(null)
    }
 }
  if (selectedCostcenterId && selectedStorehouseId) {
      return <Navigate to={`/storehouse/${selectedStorehouseId}/${selectedCostcenterId}/products`} />
  }

  if(errorStates.loadStorehouses)console.log('loadStorehouses errorStates:', errorStates.loadStorehouses)
  if(errorStates.loadCostcenters)console.log('loadCostcenters errorStates :', errorStates.loadCostcenters)

  return (
    <React.Fragment>
      <Subheader className="subHeader-v1">
        <div className="subheader-title">{getThemeValue('header', 'storehouseOrder', t('StorehouseOrder:storehouseOrder'))}</div>
      </Subheader>
      <StyledContentArea >
        <h1 className='mt-5 text-center'>{t('StorehouseOrder:pageTitle')}</h1>
        <StyledWrapper>
        {loadingStates.loadStorehouses
          ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
          : <>
            <Form.Label>
            <StyledSubHeadline>{t('StorehouseOrder:storehouse')}</StyledSubHeadline>
            </Form.Label>
            <Row>
                <Form.Group as={Col} >
                    <ArraySelect
                        onChange={handleChangeStorehouse}
                        value={selectedStorehouseId}
                        options={storehouses}
                        returnEvent
                    />
                </Form.Group>
            </Row>
          </>
        }
        {!selectedStorehouseId ? '' :
        loadingStates.loadCostcenters 
          ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
          : <>
            <Form.Label>
            <StyledSubHeadline>{t('StorehouseOrder:costcenter')}</StyledSubHeadline>
            </Form.Label>
            <Row>
                <Form.Group as={Col} >
                    <ArraySelect
                        onChange={handleChangeCostCenter}
                        value={selectedCostcenterId}
                        options={costcenters}
                        returnEvent
                    />
                </Form.Group>
            </Row>
          </>
        }       
        </StyledWrapper>
      </StyledContentArea>
      <BigFooter />
    </React.Fragment>
  )
}

export default OrderOnStockPage
