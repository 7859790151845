import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledDescriptionBlock, StyledTitle, StyledTable } from './ProductDetailStyles'
import styled from 'styled-components'

const StyledShowMore = styled.span`
  color: ${props => props.theme.primaryColor};
  margin-top: 8px;
  cursor: pointer;
`
const AliasesBlock = ({ article, withTitle }) => {
    const { t } = useTranslation()
    const [preparedAliases, setPreparedAliases] = useState([])
    const [showAll, setShowAll] = useState(false)

    useEffect(() => {
        if (showAll) {
            setPreparedAliases(article.aliases)
        }
        else {
            setPreparedAliases(article.aliases.slice(0, 5))
        }
    }, [article, showAll])

    const showMore = () => {
        setShowAll(prev => !prev)
    }

    const showSizeColumn = preparedAliases.some(alias => alias.articleVariant.displayName != null)
    if (!preparedAliases.length) {
        return null
    }
    return (
        <StyledDescriptionBlock>
            {withTitle ? <StyledTitle className="h1">{t('productDetail:articleAliases')}</StyledTitle> : null}
            <StyledTable striped bordered >
                <thead>
                    <tr>
                        <th>{t('productDetail:aliasType')}</th>
                        {showSizeColumn && <th>{t('productDetail:size')}</th>}
                        <th>{t('productDetail:alias')}</th>
                    </tr>
                </thead>
                <tbody>
                    {preparedAliases.map(alias =>
                        alias.displayName.length > 0 ?
                            <tr key={alias.id}>
                                <td>{alias.aliasType || ''}</td>
                                {showSizeColumn && <td>{alias.articleVariant.displayName || ''}</td>}
                                <td>{alias.displayName || ''}</td>
                            </tr> : ''
                    )}
                </tbody>
            </StyledTable>
            {(article.aliases && article.aliases.length > 5) && <div className='d-flex justify-content-center' >
                <StyledShowMore onClick={showMore}>{showAll ? t('dashboard:showLess') : t('dashboard:showMore')}</StyledShowMore>
            </div>}
        </StyledDescriptionBlock>
    )
}

export default AliasesBlock
