import React, { useCallback, useEffect, useState } from 'react'
import { CloseButton, Col, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { t } from 'i18next'

import { useBillsState } from '../../stores/BillsProvider'
import { getIsoDate } from '../../utils/getIsoDate'
import CustomDatePicker, { StyledDatepickerWrapper } from '../Forms/CustomDatePicker'


const StyledFilterWrapper = styled.div`
    max-width: 710px;
    margin: 0 auto;

    .form-label {
        padding-right: 10px;
        display: flex;
        justify-content: space-between;

        button.close {
            outline: none;
        }
    }

    ${StyledDatepickerWrapper} {
        max-width: calc(100% / 2 - 5px);
        position: relative;

        &:before {
            position: absolute;
            top: -15px;
            left: 8px;
            font-size: 12px;
        }

        &:first-of-type {
            margin-right: 10px;
            &:before {
                content: '${() => t('From')}'
            }
        }
        
        &:last-of-type {
            &:before {
                content: '${() => t('To')}';
            }
        }
    }
`

const BillsTableFilter = () => {
    const { setFilter } = useBillsState()
    const [searchText, setSearchText] = useState('')
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')

    useEffect(() => {
        setFilter(prev => ({ ...prev, 'filter.searchText': searchText }))
    }, [setFilter, searchText])

    useEffect(() => {
        setFilter(prev => ({ ...prev, 'filter.creationStartDate': getIsoDate(dateFrom, false) }))
    }, [setFilter, dateFrom])

    useEffect(() => {
        setFilter(prev => ({ ...prev, 'filter.creationEndDate': getIsoDate(dateTo, true) }))
    }, [setFilter, dateTo])

    const handleChangeSearchText = useCallback(e => {
        setSearchText(e.target.value)
    }, [])

    const handleChangeDate = useCallback(setDate => date => {
        setDate(date)
    }, [])

    const resetSearchText = useCallback(() => {
        setSearchText('')
    }, [])

    const resetDates = useCallback(() => {
        setDateFrom('')
        setDateTo('')
    }, [])

    return (
        <StyledFilterWrapper>
            <Row>
                <Form.Group className="mb-3" as={Col} sm="3">
                    <Form.Label>
                        <span className="me-1">{t('search')}</span>
                        {searchText && <CloseButton onClick={resetSearchText} />}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('bills:searchTextPlaceholder')}
                        name="searchText"
                        value={searchText}
                        onChange={handleChangeSearchText}
                    />
                </Form.Group>
                <Form.Group className="mb-3" as={Col}>
                    <Form.Label>
                        <span>{t('creationDate')}</span>
                        {(!!dateFrom || !!dateTo) && <CloseButton onClick={resetDates} />}
                    </Form.Label>
                    <CustomDatePicker
                        dateFormat="dd.MM.yyyy"
                        placeholderText={t('date')}
                        onChange={handleChangeDate(setDateFrom)}
                        showYearDropdown
                        selected={dateFrom}
                    />
                    <CustomDatePicker
                        dateFormat="dd.MM.yyyy"
                        placeholderText={t('date')}
                        onChange={handleChangeDate(setDateTo)}
                        showYearDropdown
                        selected={dateTo}
                    />
                </Form.Group>
            </Row>
        </StyledFilterWrapper>
    )
}

export default BillsTableFilter
