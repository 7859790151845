import React, { useEffect, useMemo, useRef, useState } from 'react'
import { StyledContingentList, StyledListWrapper, StyledProductGroupList, StyledTitleWrapper, moreOptions } from './InventoryTree'
import styled from 'styled-components'
import ArraySelect from '../../Select/ArraySelect'
import { CrossIcon } from '../../Icons/CrossIcon'
import { DeleteIcon } from '../../Icons/DeleteIcon'
import { ButtonWithLoadingState } from '../../Buttons'
import PlusMinusAmount from '../../Forms/PlusMinusAmount'
import { InputGroup, Form } from 'react-bootstrap'
import { observer } from 'mobx-react'
import useStores from '../../../stores/useStore'
import { useParams } from 'react-router'
import { colors } from '../../../styles/colors'
import MoreOptionsButton from '../../Buttons/MoreOptionsButton'
import { ArrowBigIcon } from '../../Icons/ArrowBigIcon'

const StyledProductGroupListItem = styled.li`
  max-width: 350px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  .inputWrapper {
      display: flex;
      flex-basis: 80%;
      align-items: center;
      justify-content: space-between;
  }
`
const PositionList = observer(({ filteredArticle }) => {
  const { actionType } = useParams()
  const { inventoryStore } = useStores()
  const mountedRef = useRef(true)
  const article = inventoryStore.getCurrentArticle(filteredArticle)
  const [showAddVariantDropdown, setShowAddVariantDropdown] = useState(false)
  const [buttonIsLoading, setButtonIsLoading] = useState(false)

  const getSingleSize = (position) => {
    return position.articleVariant ? position.articleVariant.displayName : ""
  }
  const availableVariantOptions = useMemo(() => {
    return inventoryStore.availableVariantOptions(article.variants, article.positions)
  }, [article.positions, article.variants, inventoryStore])

  //Cleanup Function
  useEffect(() => {
    return () => { mountedRef.current = false }
  }, [])

  useEffect(() => {
    if (actionType === "inventoryBookings") {
      setButtonIsLoading(true)
      inventoryStore.loadArticleVariants(article).then(() => {
        if (!mountedRef.current) { return null }
        setButtonIsLoading(false)
      })
    }
  }, [actionType, article, inventoryStore])

  const onChangeSelectedQuantity = position => e => {
    let newAmount = parseInt(e.target.value)
    if ((newAmount >= 0) && (newAmount <= position.returnableQuantity)) {
      inventoryStore.updateRows(article, position.articleVariant, newAmount)
    }
  }

  const onChangeCorrectedQuantity = (position, e, sign = '') => {
    let rowsLength = (position.rows && position.rows.length) ? position.rows.length : 0
    let oldAmount = position.negativeAmount ? position.currentQuantity - rowsLength : position.currentQuantity + rowsLength
    let newAmount = 0
    let newRowsLength = 0
    let newNegativeAmount = false
    if (sign === 'plus') {
      newAmount = oldAmount + 1
    } else if (sign === 'minus') {
      newAmount = oldAmount - 1
      if (newAmount < 0) { return }
    } else if (!!parseInt(e.target.value) && (e.target.value >= 0)) {
      newAmount = e.target.value
    }
    if (newAmount < position.currentQuantity) {
      newRowsLength = position.currentQuantity - newAmount
      newNegativeAmount = true
    }
    else if (newAmount >= position.currentQuantity) {
      newRowsLength = newAmount - position.currentQuantity
    }


    inventoryStore.updateRows(article, position.articleVariant, newRowsLength, newNegativeAmount)
  }

  const displayCorrectedQuantity = (position) => {
    if (position.rows && position.rows.length) {
      return `${position.negativeAmount ? "-" : "+"}${position.rows.length}`
    }
    else { return '' }
  }

  const getFakeAmount = (position) => {
    if (position.rows && position.rows.length) {
      if (position.negativeAmount) {
        return position.currentQuantity - position.rows.length
      }
      else {
        return position.currentQuantity + position.rows.length
      }
    }
    else { return position.currentQuantity }
  }

  return (<StyledListWrapper>
    <StyledProductGroupList>
      {article.positions.map((position, index) => {
        return (
          <StyledProductGroupListItem key={`${position.id}-${index}`}                    >
            <div className="inputWrapper">
              {getSingleSize(position)}
              {(actionType === "returnBookings" || actionType === "articleTransfer")
                ? <InputGroup className="m-0" style={{ width: "200px" }}>
                  <Form.Control
                    name={`${position.id}-${index}`}
                    type="number"
                    min="0"
                    max={position.returnableQuantity}
                    placeholder="Anzahl"
                    value={position.rows && position.rows.length ? position.rows.length : ""}
                    onChange={onChangeSelectedQuantity(position)} />
                  <InputGroup.Text id="basic-addon2">/{position.returnableQuantity}</InputGroup.Text>
                </InputGroup>
                : null
              }
              {actionType === "inventoryBookings"
                ? <PlusMinusAmount
                  placeholder=""
                  name={`${position.id}-${index}`}
                  value={getFakeAmount(position)}
                  onChange={(e) => onChangeCorrectedQuantity(position, e)}
                  onChangePlus={() => onChangeCorrectedQuantity(position, undefined, 'plus')}
                  onChangeMinus={() => onChangeCorrectedQuantity(position, undefined, 'minus')}
                  amountStyle={{ fontSize: "initial" }}
                  disableMinus={(position.negativeAmount || position.isUserAdded) ? (position.rows && position.rows.length ? position.rows.length : 0) === position.currentQuantity : false}
                /> : null}
            </div>
            {position.isUserAdded &&
              <DeleteIcon
                onClick={() => inventoryStore.deleteCorrectionVariant(article, index)}
                role='button'
              />
            }

            {actionType === "inventoryBookings" && <span>{displayCorrectedQuantity(position)}</span>}

          </StyledProductGroupListItem>
        )
      })}

      {showAddVariantDropdown ?
        <StyledProductGroupListItem>
          <div className="inputWrapper">
            <InputGroup>
              <ArraySelect
                name="variant"
                onChange={selectedOption => { inventoryStore.addCorrectionVariant(article, selectedOption); setShowAddVariantDropdown(false) }}
                options={availableVariantOptions}
              />
            </InputGroup>
          </div>
          <CrossIcon color={colors.gray3} onClick={() => setShowAddVariantDropdown(false)} />
        </StyledProductGroupListItem>
        : null
      }
    </StyledProductGroupList>

    {actionType === "inventoryBookings" ?
      <>
        <hr />
        <ButtonWithLoadingState
          buttonText="Andere Größe"
          isLoading={buttonIsLoading}
          loadingText="Lade Größen"
          disabled={showAddVariantDropdown || !availableVariantOptions || availableVariantOptions.length <= 0}
          onClick={() => setShowAddVariantDropdown(true)}
        />
      </>
      : null
    }
  </StyledListWrapper>)
})

const ArticleListItem = ({ article }) => {
  const { actionType } = useParams()
  const [articleOpened, setArticleOpened] = useState(false)
  const { inventoryStore } = useStores()

  const options = moreOptions(article, inventoryStore);

  return (
    <StyledContingentList>
      <li className="mb-4">
        {article.article.displayName !== '' && (
          <StyledTitleWrapper>
            <span className="title" onClick={() => { setArticleOpened(prev => !prev) }}>
              <ArrowBigIcon variant={articleOpened ? 'down' : 'right'} />
              {article.article.displayName}
            </span>
            {(actionType === "returnBookings" || actionType === "articleTransfer") ?
              <div className="moreOptions">
                <MoreOptionsButton options={options} />
              </div> : null}
          </StyledTitleWrapper>
        )}
        {articleOpened ? <PositionList filteredArticle={article} /> : null}
      </li>
    </StyledContingentList>)
}

export default ArticleListItem