import React from 'react'
import DynamicFormElements from '../Form/DynamicFormElements'

const formConfigTemplate = [
    {
        type: 'checkbox',
        name: 'checkImportDataOnly',
        label: 'checkImportDataOnly'
    },
    {
        type: 'text',
        name: 'mailAddress',
        label: 'emailAddress'
    },

]

const OfficeImport = ({ formData, setFormData, formErrors }) => {

    return (
        <>
            <DynamicFormElements formConfigTemplate={formConfigTemplate} setFormData={setFormData} formData={formData} formErrors={formErrors} />
        </>
    )
}
export default OfficeImport