import styled, { css } from 'styled-components'
import { colors } from '../../styles/colors'

export const StyledCalendarWrapper = styled.div`
  max-width: calc(100vw - 40px);

  .react-calendar{
    border: none;

    &__navigation__prev2-button,
    &__navigation__next2-button{
      display: none;
    }

    &__month-view {
      &__weekdays {
        display: grid !important;
        grid-template-columns: repeat(7,1fr);
        grid-gap: 8px;

        &__weekday {
          color: ${colors.textColorSecondary};
          width: 100%;
          max-width: none !important;
          abbr[title]{
            border-bottom: none;
            text-decoration: none;
            cursor: default;
          }
        
        }
      }

      &__days{
        display: grid !important;
        grid-template-columns: repeat(7,1fr);
        grid-gap: 8px;
        justify-items: center;
        
        &:disabled {
          color: ${colors.textColorSecondary} !important;
          
          // background-color: #ededed;
        background-color:red !important;

        }

        &__day{
          background-color: #518600;
          color: #FFF;

          &--weekend{
            // background-color: #ededed;
            background-color:pink;
            color: ${colors.textColorB80};

            &:disabled {
              // color: ${colors.textColorSecondary} !important;
              color: darkgrey !important;

              background-color: #ededed !important;
            // background-color:pink !important;

            }
          }
        }
        &__day--neighboringMonth{
          background-color: #f6f6f6;
          color:  ${colors.textColorSecondary};
          &.react-calendar__tile:disabled {
            background-color: #f6f6f6;
          }
        }
      }

      .react-calendar__tile {
        border: 1px solid ${colors.borderColorLight};
        border-radius: 8px;
        padding: 5px 8px;
        width: 100%;
        max-width: none !important;
        //margin: 2px 4px;
        font-weight: bold;
        display:flex;
        justify-content:center;

        &--now > abbr{
          background-color: white;
          color: grey;
          width: 21px;
          height: 18px;
          padding:0 12px;
          border-radius: 50%;
          display:flex;
          justify-content:center;
          align-items:center;
          padding-top:2px;
        }

        &:disabled {
          // color: ${colors.textColorSecondary};
          color:white;
          background-color: darkgrey; 
          box-shadow:none !important;  
        }
        &:enabled:hover{
          background-color: #a6e144;
        }
        ${props => props.defaultActiveDisabled ? css`
            &--active{
                background-color: #518600;
                box-shadow: none;
            }
            
            `: css`
            &--active{
                background-color: #a6e144;
                box-shadow:0 0 0 4px #518600;
                &:focus {
                background-color: #a6e144;
                }
            }
        `}
        &--custom-active{
            background-color: #a6e144 !important;
            box-shadow:0 0 0 4px #518600 !important;
            &:focus {
            background-color: #a6e144 !important;
            }
        }
      }
    }
  }

  button {
  //color: ${colors.textColorB80};
  }
`
