import React, { useState, useEffect } from "react"
import { Form } from 'react-bootstrap'
import { StyledDataList } from '../DataList/dataList'
import { StyledOverlayInnerWrapper } from '../Page/logix2020/OverlayRight'
import styled from 'styled-components'

import Button from 'react-bootstrap/Button'
import flagDe from "../../assets/flags/ICN_BTN_Flag_DE.svg"
import flagEn from "../../assets/flags/ICN_BTN_Flag_EN.svg"
import flagFr from "../../assets/flags/ICN_BTN_Flag_FR.svg"
import flagEs from "../../assets/flags/ICN_BTN_Flag_ES.svg"
import flagRu from "../../assets/flags/ICN_BTN_Flag_RU.svg"
import flagIt from "../../assets/flags/ICN_BTN_Flag_IT.svg"
import { useTranslation } from 'react-i18next'
import apiClient from "../../utils/api/apiClient"
import { APIEndpoints } from "../../utils/api/apiConfig"

const flagMapping = {
  "DE": flagDe,
  "EN": flagEn,
  "FR": flagFr,
  "ES": flagEs,
  "RU": flagRu,
  "IT": flagIt
}

const StyledFormWrapper = styled.div`
   .form-group{
     display: flex;
     justify-content: space-between;
     margin-right:20px;
   }
`

const LanguageForm = ({ languageArray, onChange, handleCloseOverlay, languageTitle }) => {
  const { t } = useTranslation()

  const [languages, setLanguages] = useState([])

  useEffect(() => {
    apiClient.getJson(APIEndpoints.languages)
      .then(response => {
        setLanguages(response)

      })
      .catch(error => console.log(error))
  }, [])

  const handleOnChange = isoCode => e => {
    const val = e.target.value
    const newObj = JSON.parse(JSON.stringify(languageArray))

    const index = newObj.findIndex(obj => obj.langIsoCode === isoCode)
    if (index >= 0) {
      newObj[index].value = val
      onChange(newObj)
    }
    else {
      const newValue = { langIsoCode: isoCode, value: val }
      onChange([...newObj, newValue])
    }
  }

  const findValue = (isoCode) => {
    return (languageArray.find(obj => obj.langIsoCode === isoCode) || { value: "" }).value
  }

  return (

    <StyledOverlayInnerWrapper>
      <StyledDataList className="content">
        <h2>{languageTitle}</h2>
        <StyledFormWrapper>
          <Form className="p4" >
            {
              languages.map((item) => (
                <Form.Group key={item.isoCode} className="mb-3 p2" >
                  <Form.Label >
                    <img src={flagMapping[item.isoCode]} className="p2" alt="icon" />
                  </Form.Label>
                  <Form.Control
                    value={findValue(item.isoCode) || ""} onChange={handleOnChange(item.isoCode)} placeholder={t('name')} />
                </Form.Group>
              ))
            }
          </Form>
        </StyledFormWrapper>
      </StyledDataList>
      <footer>
        <div className="d-flex flex-column mx-5 mb-5">
          <Button onClick={handleCloseOverlay} variant="info">{t('adopt')}</Button>
        </div>
      </footer>
    </StyledOverlayInnerWrapper>

  )




}

export default LanguageForm;
