import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { Overlay, Popover } from 'react-bootstrap'

const StyledVersionButton = styled.div`
width:30px;
  :hover{
    background-color: ${colors.gray4};
  }
`

const VersionButton = () => {
    const target = React.createRef();
    const [show, setShow] = useState(false);

    return (
        <StyledVersionButton ref={target} onClick={() => setShow(!show)}>
            <Overlay
                show={show}
                target={target}
                placement="left"
                container={target.current}
                containerPadding={20}
            >
                <Popover id="popover-contained">
                    <Popover.Header as="h3">Version: 0.0.344</Popover.Header>
                    <Popover.Body>
                        <a href="https://bitbucket.org/rigilog/logix/src/develop/documentation/Version.md" target="_blank" rel="noopener noreferrer" >BitBucket</a>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </StyledVersionButton>
    )
}

export default VersionButton
