import React, { useState, useEffect, useMemo } from 'react'

import { emptyGuid } from '../../utils/emptyGuid'
import { FormControl } from 'react-bootstrap'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'react-bootstrap'
import useStores from '../../stores/useStore'
import { useObserver } from 'mobx-react'
import { debounce } from 'throttle-debounce'
import OfficeItem from './OfficeItem'
import SearchResultAmount from '../Forms/SearchResultAmount'
import TopLevelTreeItem from './TopLevelTreeItem'


const StyledSearchWrap = styled.div`
  margin-bottom: 2rem;
  
`
const StyledShowMore = styled.span`
color: ${props => props.theme.primaryColor};
  float: right;
  margin-top: 15px;
  cursor: pointer;
`
const OfficesTree = ({ onClick, isClickable, withSearch, renderExtraItemContent = () => null, parentsWithSelectedChildren, getContextMenuOptions, showTopLevel }) => {
  const [filter, setFilter] = useState('')
  const [filterIsLoading, setFilterIsLoading] = useState(false)
  const [filteredOffices, setFilteredOffices] = useState([])
  const [officesActive, setOfficesActive] = useState({})
  const [selectedOfficeId, setSelectedOfficeId] = useState('')
  const { t } = useTranslation()
  const { officeStore } = useStores()
  const partialOffices = useObserver(() => officeStore.loadedOffices)
  const [limit, setLimit] = useState(true)
  const limitLength = 40

  useEffect(() => {
    officeStore.getOffices(emptyGuid)
  }, [officeStore])

  useEffect(() => {
    if (Object.keys(officesActive).length) {
      for (const officeId of Object.keys(officesActive)) {
        if (officesActive[officeId]) {
          officeStore.getOffices(officeId)
        }
      }
    }
  }, [officeStore, officesActive, partialOffices])    // to render in dropdown what is open

  const handleChangeFilter = (e) => {
    const { value } = e.target
    // the search bar function
    setFilter(value)
    loadFilteredOfficesDebounced(value)
  }

  const loadFilteredOfficesDebounced = useMemo(
    () => debounce(500, async (value) => {
      if (!value) {
        officeStore.cancelGetFilteredOffices()
        setLimit(false)
        return
      }
      try {
        setLimit(true)
        setFilteredOffices([])
        setFilterIsLoading(true)
        let loadedOffices = await officeStore.getFilteredOffices(value)
        setFilteredOffices(loadedOffices)
      }
      finally {
        setFilterIsLoading(false)
      }
    }), [officeStore])

  const topLevelOffices = useMemo(() => {   //
    let offices = []
    if (filter) {
      offices = filteredOffices
    }
    else {
      offices = partialOffices.filter(office => !office.parentOffice)
    }
    return offices
  }, [partialOffices, filter, filteredOffices])

  const limitedTopLevelOffices = useMemo(() => {
    if (limit) {
      return topLevelOffices.slice(0, limitLength)
    }
    return topLevelOffices
  }, [limit, topLevelOffices])


  return useObserver(() => (
    <>
      {withSearch && (     // if there are results
        <StyledSearchWrap className="search-wrapper">
          <FormControl type="input" placeholder={t('officeAdmin:searchOrgaUnit')} onChange={handleChangeFilter} value={filter} />
          {filter && <SearchResultAmount count={topLevelOffices.length} />}
        </StyledSearchWrap>
      )}
      {showTopLevel
        ? <TopLevelTreeItem handleClick={onClick} renderExtraItemContent={renderExtraItemContent}>
          {limitedTopLevelOffices.map(office => (
            <OfficeItem
              key={office.id}
              office={office}
              setOfficesActive={setOfficesActive}
              onClick={onClick}
              partialOffices={partialOffices}
              isClickable={isClickable}
              officesActive={officesActive}
              renderExtraItemContent={renderExtraItemContent}
              parentsWithSelectedChildren={parentsWithSelectedChildren}
              selectedOfficeId={selectedOfficeId}
              setSelectedOfficeId={setSelectedOfficeId}
              getContextMenuOptions={getContextMenuOptions}
            />
          ))}
        </TopLevelTreeItem>
        : limitedTopLevelOffices.map(office => (
          <OfficeItem
            key={office.id}
            office={office}
            setOfficesActive={setOfficesActive}
            onClick={onClick}
            partialOffices={partialOffices}
            isClickable={isClickable}
            officesActive={officesActive}
            renderExtraItemContent={renderExtraItemContent}
            parentsWithSelectedChildren={parentsWithSelectedChildren}
            selectedOfficeId={selectedOfficeId}
            setSelectedOfficeId={setSelectedOfficeId}
            getContextMenuOptions={getContextMenuOptions}
          />
        ))}
      {topLevelOffices.length > limitLength && limit && <div className='d-flex justify-content-center' >
        <StyledShowMore className="show-more" onClick={() => { setLimit(false) }}>{t('officeAdmin:showAll')}</StyledShowMore>
      </div>}
      {topLevelOffices.length === 0 && !officeStore.officesLoading && !filterIsLoading && (    // if there is no result from the search bar
        <div className="ps-4 pe-2"><strong>{t('officeAdmin:searchStructureTreeNoresult')}</strong></div>
      )}
      {(officeStore.officesLoading || filterIsLoading) && <div className='d-flex justify-content-center' >
        <Spinner animation='border' variant='primary' />
      </div>}
    </>
  ))
}

export default OfficesTree
