import React, {
  useMemo,
  useRef
} from 'react'


export const AppContext = React.createContext({})

const AppProvider = ({ children }) => {
  const contentAreaRef = useRef()


  const contextValues = useMemo(() => ({
    contentAreaRef,
  }), [contentAreaRef])

  return (
    <AppContext.Provider value={contextValues}>
      {children}
    </AppContext.Provider>
  )
}

export function useAppState() {
  const context = React.useContext(AppContext)
  return context
}

export default AppProvider
