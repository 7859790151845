import React, { memo } from "react";
import { useDragLayer } from "react-dnd";
import { CategoryIconDefault, CategoryIcons } from "../../Icons/CategoryIcons/CategoryIcons";
import styled, { useTheme } from "styled-components";
import { FolderIcon } from "../../Icons/FolderIcon";

const StyledArticleIconWrapper = styled.div`
position: relative;
margin-right:1rem;
width:60px;
height:60px;
z-index:100;
.bubble {
  background: ${props => props.theme.primaryColor};
  font-size: 8px;
  border-radius: 50px;
  height: 13px;
  width: 13px;
  text-align: center;
  line-height: 1.2;
  color: white;
  position: absolute;

  display: flex;
  bottom: 9px;
  right: 1px;
  align-items: center;
  justify-content: center;
}
`

const layerStyles = {
  position: "fixed",
  zIndex: "100",
  left: "10px",
  top: "10px"
};

function getItemStyles(initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none"
    };
  }
  let { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
}


const Item = memo(({ itemType, iconType, amount }) => {
  const ArticleIcon = () => {
    const theme = useTheme()
    const CategoryIcon = iconType && CategoryIcons[iconType] ? CategoryIcons[iconType] : CategoryIconDefault
    return <StyledArticleIconWrapper>
      < CategoryIcon color={theme.primaryColor} />

      {amount && amount > 1 ? <span className="bubble">{amount}</span> : null}
    </StyledArticleIconWrapper>
  }

  switch (itemType) {
    case "article":
      return <ArticleIcon />
    case "multipleArticles":
      return <ArticleIcon />
    case "group":
      return <FolderIcon color="black" height="60px" width="60px" />
    case "dimension":
      return <FolderIcon color="black" height="60px" width="60px" />
    default:
      return null
  }
})



export const DragPrgeview = () => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging()
  }));

  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <Item itemType={item.type} iconType={item.iconType} amount={item.selectedArticles && item.selectedArticles.length} />
      </div>
    </div>
  );
};
