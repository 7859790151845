import React, { useCallback, useEffect, useMemo, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { useTranslation } from 'react-i18next'
import UniversalTableWrapperTest from '../../../Table/UniversalTableWrapperTest'
import apiClient from '../../../../utils/api/apiClient'
import { APIEndpoints } from '../../../../utils/api/apiConfig'
import TableProvider, { useTableState } from '../../../Table/TableProvider'
import { formatedDate } from '../../../../utils/formatedDate'
import { PenIcon } from '../../../Icons/PenIcon'
import { DeleteIcon } from '../../../Icons/DeleteIcon'
import { useParams, useNavigate } from 'react-router-dom'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ModalComponent from '../../../Modals/ModalComponent'
import { getIsoDate } from '../../../../utils/getIsoDate'

const columns = [
  {
    dataField: 'appointmentType.displayName',
    text: 'Name',
    sort: false,
  },
  {
    dataField: 'cabin.displayName',
    text: 'Kabine',
    sort: false,
  },
  {
    dataField: 'day',
    text: 'Tag',
    sort: false,
    formatter: (cell, row, rowIndex, extraProps) => {
      return extraProps.getFormattedDate(row)
    }
  },
  {
    dataField: 'email',
    text: 'E-Mail',
    sort: false,
  },
  {
    dataField: 'phone',
    text: 'TelefonNummer',
    sort: false,
  },
  {
    dataField: '__actions',
    text: '',
    sort: false,
    formatter: (cell, row, rowIndex, extraProps) => {
      return (
        <span>
          <span>
            <DeleteIcon onClick={() => extraProps.setDeleteModalIndex(row.id)} title={"delete"} />
          </span>
          <span>
            <PenIcon onClick={() => extraProps.editRow(row)} title={"edit"} />
          </span>
        </span>
      )
    },
    headerClasses: 'actions-header-cell',
    classes: 'actions-cell'
  }
]

const AppointmentTableInner = () => {
  const { employeeId } = useParams()
  const [deleteModalIndex, setDeleteModalIndex] = useState(null)

  const navigate = useNavigate();
  const { t } = useTranslation()
  const [appointments, setAppointments] = useState(null)
  const [tableIsLoading, setTableIsLoading] = useState(true)
  const { tableRef, pageLimit, setPageLimit, pageOffset, setPageOffset, setTotalCount, setSort, paginationOptions, currentPage, totalPages } = useTableState()

  const fetchReports = useCallback((pageLimit, pageOffset) => {
    const params = {
      'page.limit': pageLimit,
      'page.offset': pageOffset,
      'filter.startDate': getIsoDate(new Date()),
      'filter.targetBusinesspartnerId': employeeId
    }
    setTableIsLoading(true)
    apiClient.getJson(
      APIEndpoints.appointments,
      params,
      true,
      true
    ).then(response => {
      setAppointments(response.data)
      //doesnt work because backend isnt sending x-total-count header
      setTotalCount(response.headers['x-total-count'] ? parseInt(response.headers['x-total-count']) : 0)
    }).finally(() => {
      setTableIsLoading(false)
    })
  }, [employeeId, setTotalCount])

  const deleteRow = (id) => {
    apiClient.deleteJson(APIEndpoints.appointment(id)).then(() => {
      fetchReports(pageLimit, pageOffset)
    })
  }

  const editRow = useCallback((appointment) => {
    //redirect to appointment page
    let path = `/appointment/${employeeId}/${appointment.id}`;
    navigate(path);
  }, [employeeId, navigate])

  useEffect(() => {
    setPageOffset(0)
  }, [setPageOffset])

  useEffect(() => {
    fetchReports(pageLimit, pageOffset)
  }, [fetchReports, pageLimit, pageOffset])


  const handleTableChange = (type, newState) => {
    setPageLimit(newState.sizePerPage)
    setPageOffset(newState.page === 1 ? 0 : newState.sizePerPage * newState.page - newState.sizePerPage)

    if (type === 'sort') {
      const { sortOrder, sortField } = newState;
      setSort(`${sortOrder === 'asc' ? '-' : ''}${sortField}`)
    }
  }

  const getFormattedDate = (appointment) => {
    const Day = formatedDate(appointment.day)
    const Hour = appointment.timeSlot && appointment.timeSlot.hour < 10 ? "0" + appointment.timeSlot.hour : appointment.timeSlot.hour
    const Minute = appointment.timeSlot && appointment.timeSlot.minute < 10 ? "0" + appointment.timeSlot.minute : appointment.timeSlot.minute
    return `${Day} ${Hour}:${Minute}Uhr`
  }

  const preparedColumns = useMemo(() => {
    const tmpColumns = [...columns]
    tmpColumns.forEach(tmpColumn => {
      tmpColumn.text = t(tmpColumn.text)
    })
    tmpColumns.find(col => col.dataField === '__actions').formatExtraData = {
      getFormattedDate,
      setDeleteModalIndex,
      editRow,
    }
    tmpColumns.find(col => col.dataField === 'day').formatExtraData = {
      getFormattedDate
    }
    return tmpColumns
  }, [editRow, t])

  return (
    <UniversalTableWrapperTest tableIsLoading={tableIsLoading} currentPage={currentPage} totalPages={totalPages} >
      <BootstrapTable
        remote
        ref={tableRef}
        loading={tableIsLoading}
        noDataIndication={() => <div className="no-data-placeholder">{t('appointment:noDates')}</div>}
        onTableChange={handleTableChange}
        bootstrap4
        keyField="id"
        hover
        data={appointments || []}
        columns={preparedColumns}
        bordered={false}
        pagination={paginationFactory(paginationOptions)}
      />
      {deleteModalIndex &&
        <ModalComponent
          ModalTitle={t('appointment:deleteAppointment')}
          functionButton1={() => { deleteRow(deleteModalIndex) }}
          BodyPart1={t('appointment:areYouSure')}
          textButton1={t('delete')}
          variantButton1="danger"
          variantButton2="danger"
          variantBody="alert-danger"
          functionCancel={() => { setDeleteModalIndex(null) }}
        />}
    </UniversalTableWrapperTest>
  )
}

const AppointmentTable = () => {
  return (
    <TableProvider>
      <AppointmentTableInner />

    </TableProvider>
  )
}

export default AppointmentTable;
