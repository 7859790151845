import { useRef, useEffect } from 'react';

const useMountEffect = fun => {
  const isInit = useRef(true)
  return useEffect(() => {
    if (!isInit.current) {
      return
    }
    fun()
    isInit.current = false;
  }, [fun])
}

export default useMountEffect

/*
const isInit = useRef(true);
useEffect(() => {
  if (!isInit.current) {
    return;
  }
  initFunction()
  isInit.current = false;
}, [initFunction])
*/

//is equal to

/* 
useMountEffect(()=>{
  initFunction()
})
*/