import React, { useState } from 'react'
import useStores from '../../../stores/useStore'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import apiClient from '../../../utils/api/apiClient'
import CostCenterTree from '../../CostCenter/CostCenterTree'
import { getErrorMessageFromApiResponse } from "../../../utils/api/apiUtils";
import { Alert } from 'react-bootstrap'

const StyledWrapper = styled.div`
.text-note {
  width:15%;
  margin-left: 50px
}
@media screen and (max-width: 1280px){
  .additional {
  flex-direction:column;
 }
 .text-note {
    margin-top:2rem;
    margin-left:0;
    width:50%;
    text-align: center;
  }
}
`
const StyledAlerts = styled.div`
  max-width: 450px;
  margin-top: 20px;
`
const StyledCostCenterTree = styled.div`
  width:100%;
  @media screen and (min-width: 1080px) {
    max-width: calc(100% - 480px);
    min-width: 600px;
  }
`

const InterfaceCostCenterTree = () => {
  const { t } = useTranslation()
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const { costCenterStore } = useStores()

  const onChangeBoxed = (costCenter) => {
    setSuccess("")
    setError("")
    const isChecked = !costCenter.isBlockedForDeletionOrReactivation;
    const params = {
      isBlockedForDeletionOrReactivation: isChecked,
      id: costCenter.id
    }
    apiClient.patchJson(
      APIEndpoints.costCenters(costCenter.id).interfaces, params).then(() => {
        const foundItem = costCenterStore.partialCostCenters.find(item => item.id === costCenter.id)
        foundItem.isBlockedForDeletionOrReactivation = isChecked
        setSuccess(`${t('costCenterAdmin:successUpdate')}`)
      })
      .catch((error) => {
        setError(getErrorMessageFromApiResponse(error))
      })
  }

  const renderExtraItemContent = (costCenter) => {
    return (
      <div className="action-buttons visible">
        <input checked={costCenter.isBlockedForDeletionOrReactivation} onChange={() => { }} className="me-4" type="checkbox" />
      </div>
    )
  }

  return (
    <StyledWrapper>
      <div className="d-flex align-items-center additional">
        <StyledCostCenterTree><CostCenterTree
          withSearch
          onClick={(costCenter) => onChangeBoxed(costCenter)}
          renderExtraItemContent={renderExtraItemContent}
        /></StyledCostCenterTree>

        <div className="text-note">
          <p>{t('costCenterAdmin:messageInterfaces')}</p>
        </div>
      </div>
      <StyledAlerts>
        {error ?
          <Alert variant="danger"> {error} </Alert>
          : ""
        }
        {success ?
          <Alert variant="success"> {success} </Alert>
          : ""
        }
      </StyledAlerts>
    </StyledWrapper>
  )
}

export default InterfaceCostCenterTree
