import React from 'react'

export const ScaleIcon = (props) => {
    const color = props.color ? props.color : '#3c3c3a'

    return (
        <svg id="Layer_1" width='16' height='16' viewBox="0 0 128 128">
            <style type='text/css'>{`.cls-1{fill:${color}`}</style>
            <path className="cls-1" d="M123.36472,61.42554,105.03349,24.84444q1.2894-.06886,2.57463-.13979a3.57249,3.57249,0,0,0,0-7.13345c-6.69738-.38391-13.39477-.603-20.09216-.76363q-11.72981-.27228-23.45964-.25871h-.11265q-11.72983-.00938-23.45964.25871c-6.6974.16066-13.39478.37973-20.09216.76363a3.57259,3.57259,0,0,0,0,7.13345q1.28627.072,2.57463.13979L4.63528,61.42554h-1.836v3.91411A14.50191,14.50191,0,0,0,14.66885,79.60028c.03755.00834.073.01461.10848.02086,4.30218.78032,17.87848.78032,22.18066,0,.03548-.00625.073-.01252.1085-.02086A14.50191,14.50191,0,0,0,48.9361,65.33965V61.42554H47.1L28.90444,25.10942c3.85987.1523,7.71973.265,11.5796.35886q9.054.20968,18.11216.2462L53.44275,95.61142a14.31529,14.31529,0,0,0-13.94767,14.31072v1.52933H88.50492v-1.52933A14.31529,14.31529,0,0,0,74.55725,95.61142L69.4038,25.71447q9.05712-.04069,18.11216-.2462c3.85987-.09389,7.71973-.20655,11.5796-.35886L80.9,61.42554H79.0639v3.91411A14.50191,14.50191,0,0,0,90.93351,79.60028c.03547.00834.073.01461.1085.02086,4.30218.78032,17.87848.78032,22.18066,0,.03547-.00625.07093-.01252.10848-.02086a14.50191,14.50191,0,0,0,11.86961-14.26063V61.42554Zm-81.81244,0H10.18513L25.86872,30.12515Zm44.89543,0,15.68357-31.30038,15.68358,31.30038Z" />
        </svg>
    )
}
