import React, { useEffect, useState, useCallback } from 'react'
import { Observer } from 'mobx-react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import useStores from '../stores/useStore'
import EmployeeSubheader from '../components/Employees/Subheader/EmployeeSubheader'
import { colors } from '../styles/colors'
import { ArrowBigIcon } from '../components/Icons/ArrowBigIcon'
import { useTranslation } from 'react-i18next'
import { ContentArea } from '../components/Page/logix2020/ContentArea'


const StyledName = styled.span`
  color: ${props => props.theme.primaryColor};
`
const StyledH1 = styled.h1`
  margin-top: 50px;
  max-width: 700px;
  text-align: center;

  @media screen and (min-width: 768px) {
    margin-top: 100px;
  }

  @media screen and (min-width: 1080px) {
    margin-top: 100px;
  }

`

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  @media screen and (min-width: 360px) {
    width: 310px;
  }
`

const StyledListItem = styled.li`
  padding: 6px 3px;
  border-bottom: 1px solid ${colors.gray2};
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    border-bottom: 0;
  }
`

const ProductJobfunctionsPage = () => {
  const { productsStore } = useStores()
  const { employeeId } = useParams()
  const { t } = useTranslation()
  const [shoppingActivities, setShoppingActivities] = useState([])
  const navigate = useNavigate()

  const redirectToProductsOverview = useCallback((replace) => {
    navigate(`/products/personal/${employeeId}`, { replace })
  }, [employeeId, navigate])

  const getActivities = useCallback(async () => {
    let loadedActivities = await productsStore.loadActivities()
    if (loadedActivities.length === 1) {
      productsStore.handleChangeActivity(loadedActivities[0].id)
      redirectToProductsOverview(true)
    }
    setShoppingActivities(loadedActivities)
  }, [productsStore, redirectToProductsOverview])

  useEffect(() => {
    productsStore.targetEmployeeId = employeeId;
    getActivities()
  }, [employeeId, getActivities, productsStore])

  const handleClickJob = useCallback(activity => e => {
    productsStore.handleChangeActivity(activity.id)
    redirectToProductsOverview()
  }, [productsStore, redirectToProductsOverview])

  if (shoppingActivities.length === 0) {
    return null
  }

  return (
    <div>
      <EmployeeSubheader />
      <ContentArea fullWidth>
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-column align-items-center">
            <Observer>{() => {
              const targetEmployee = productsStore.targetEmployee
              return (
                <StyledH1>{t('order:pleaseJob')} <StyledName>{targetEmployee.firstName} {targetEmployee.lastName}</StyledName><br />{t('order:choose')} {t('order:wantToOrder')} </StyledH1>
              )
            }}</Observer>
            <StyledList>
              {shoppingActivities.map(item => (
                <StyledListItem key={item.id} onClick={handleClickJob(item)}>{item.displayName}<ArrowBigIcon variant="right" /></StyledListItem>
              ))}
            </StyledList>
          </div>
        </div>
      </ContentArea>
    </div>
  )
}

export default ProductJobfunctionsPage
