import React from 'react'
import { Alert } from 'react-bootstrap'
import { Trans } from 'react-i18next'

const ReturnsExistAlert = ({ returns }) => {
    if (!returns || !returns.length) {
        return null
    }
    return (
        <Alert>
            <Trans
                i18nKey="orderDialog:returnsExist"
                values={{ feedbackNumber: returns[0].feedbackNumber }}
                count={returns.length}
            >
            </Trans>
            {returns.length > 1
                ? <ul className='mt-2'>{returns.map(c => <li key={c.id}>{c.feedbackNumber}</li>)}</ul>
                : null}

        </Alert>
    )
}

export default ReturnsExistAlert