import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import ArticleListItem from './ArticleListItem'
import { useObserver } from 'mobx-react'
import useStores from '../../../stores/useStore'
import LazyLoad from '../../Forms/LazyLoad'

const CatalogArticles = ({ listRef }) => {
  const { inventoryStore } = useStores()

  useEffect(() => {
    inventoryStore.getCatalogArticles({ newOffset: 0, newSearch: inventoryStore.searchText })
    return () => { inventoryStore.showAllArticles = false }
  }, [inventoryStore])

  return useObserver(() => (
    <>
      {inventoryStore.catalogArticles.map(listItem => <ArticleListItem key={listItem.article.id} article={listItem} />)}
      {((inventoryStore.catalogArticlesTotalCount > inventoryStore.catalogArticlesOffset) && !inventoryStore.catalogArticlesLoading) ?
        (<LazyLoad
          fetchData={() => inventoryStore.getCatalogArticles()}
          listRef={listRef}
        />) : ''}
      {inventoryStore.catalogArticlesLoading &&
        <div className='d-flex justify-content-center p-3' >
          <Spinner animation='border' variant='primary' />
        </div>}
    </>
  ))
}

export default CatalogArticles