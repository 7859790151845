import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { Alert } from 'react-bootstrap'
import useStores from '../../stores/useStore'
import AddSubheader from '../../components/Employees/AddEmployee/AddSubheader'
import Sidebar from '../../components/Employees/AddEmployee/Sidebar'
import AddContentArea from '../../components/Employees/AddEmployee/AddContentArea'
import EmployeeDataProvider from '../../stores/EmployeeDataProvider'
import { StyledJobfunctionContainer } from '../../components/Employees/Form/formHelper'
import { useTranslation } from 'react-i18next'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { TestSidebarRight } from '../../components/Page/logix2020/TestSidebarRight'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'

const StyledContentArea = styled(ContentArea)`
   padding: 20px 40px 20px 36px;
   @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px)  {
    padding: 20px;
    min-height: calc(100vh - 488px);//header+navbar+sidebar=488px
   }
   .added-row {
    label {
      display: block;
    }
  }
`

const AddEmployeePage = () => {
  const { uiStore, laundryShelfStore } = useStores()
  const { t } = useTranslation()

  useEffect(() => {
    uiStore.loadBPDataSettings()
    return () => {
      laundryShelfStore.resetLaundryShelfStore()
    }
  }, [laundryShelfStore, uiStore])

  return useObserver(() => (
    <EmployeeDataProvider>
      <StyledJobfunctionContainer>
        {!(uiStore.allowCreateNewEmployee) ? (
          <>
            <StyledContentArea>
              <Alert variant="danger">{t('addEmployeePage:addEmployeeAlert')}</Alert>
            </StyledContentArea>
          </>
        ) : (
          <>
            <AddSubheader />
            <FlexWrapper>
              <StyledContentArea>
                <AddContentArea />
              </StyledContentArea>
              <TestSidebarRight>
                <Sidebar />
              </TestSidebarRight>
            </FlexWrapper>
            <BigFooter />
          </>
        )}
      </StyledJobfunctionContainer>
    </EmployeeDataProvider>

  ))
}

export default AddEmployeePage


