import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import Group from './Group'
import useMountEffect from '../../../utils/hooks/useMountEffect'
import { Spinner } from 'react-bootstrap'

export const StyledArrowWrapper = styled.div`
  display:flex;
  cursor:pointer;
`
const ArticlesGroups = memo(({ contingentTemplateId, groups, autoScrollTo, getGroupArticles, getGroups, groupsLoading }) => {
  const [openGroups, setOpenGroups] = useState({})
  useEffect(() => {
    if (autoScrollTo && autoScrollTo.articleId) {
      setOpenGroups({
        [autoScrollTo.groupId]: true
      })
    }
  }, [autoScrollTo])
  useMountEffect(() => {
    getGroups(contingentTemplateId)
  })

  if (groupsLoading) {
    return <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
  }

  return (
    <>
      {groups.map(group => (
        <Group
          key={group.id}
          group={group}
          entitlementId={contingentTemplateId}
          open={openGroups[group.id]} // own prop
          setOpenGroups={setOpenGroups}
          autoScrollTo={autoScrollTo}
          getGroupArticles={getGroupArticles}
        />
      ))}
    </>
  )
})

export default ArticlesGroups