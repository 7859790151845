import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const ArrowLeftLink = styled(Link)`
 &:before{
  content: '';
  display: inline-block;
  width: 1rem;
  height: 100%;
  min-height: 1rem;
  margin-right: 0.6em;
  margin-top: -3px;
  vertical-align: middle;

  background: url("data:image/svg+xml,${props => encodeURI(
    `<svg xmlns="http://www.w3.org/2000/svg" fill="${encodeURIComponent(props.color? props.color: '#00035c')}" viewBox="0 0 28 28">
      <g>
        <g>
          <path
            d="M18.2 22.2c-.4.4-1 .4-1.4 0L10 15.4c-.1 0-.2-.1-.3-.2-.2-.2-.3-.5-.3-.7 0-.3.1-.5.3-.7.1-.1.2-.1.3-.2l6.8-6.8c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-6.3 6.3 6.3 6.3c.4.4.4 1.1 0 1.4z"
            fill-rule="evenodd" clip-rule="evenodd"/>
        </g>
      </g>
    </svg>
    `)}") no-repeat center center;
 }
`
