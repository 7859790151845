import { t } from 'i18next'
import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import CustomDatePicker from '../../Forms/CustomDatePicker'
import styled from 'styled-components'
import { colors } from '../../../styles/colors'
import ArraySelect from '../../Select/ArraySelect'


const StyledContainer = styled.div`
background:${props => props.active ? colors.gray1 : ''};
padding:2rem;
`
let frequencyOptions = [
  { displayName: "Täglich", id: "daily" },
  { displayName: "Wöchentlich", id: "weekly" },
  { displayName: "Monatlich", id: "monthly" },
  { displayName: "Pro Quartal", id: "quarterly" },
  { displayName: "Jährlich", id: "yearly" }
]
const ContingentPointsCalculation = () => {
  const [regularly, setRegularly] = useState(false)
  const [singleDate, setSingleDate] = useState('')

  const handleChangeRegularly = (bool) => {
    setRegularly(bool)
  }
  return (
    <>
      <StyledContainer active={!regularly} >
        <Form.Check
          className=" mb-4"
          type="radio"
          id="single"
          label="Fester Zeitpunkt einmalig"
          checked={!regularly}
          onChange={() => handleChangeRegularly(false)}
        />
        <Row>
          <Col sm="6" lg="3">
            <Form.Group className="mb-3">
              <Form.Label>Einmalig</Form.Label>
              <CustomDatePicker
                dateFormat="dd.MM.yyyy"
                placeholderText={t('date')}
                showYearDropdown
                selected={singleDate}
                disabled={regularly}
                onChange={(date) => setSingleDate(date)}
              />
            </Form.Group>
          </Col>
        </Row>

      </StyledContainer>

      <StyledContainer active={regularly}>
        <Form.Check
          className=" mb-4"
          type="radio"
          id="regularly"
          label="Regelmäßig"
          checked={regularly}
          onChange={() => handleChangeRegularly(true)}
        />
        <Row>
          <Col sm="6" lg="3">
            <Form.Group className="mb-3">
              <Form.Label>Frequenz</Form.Label>
              <ArraySelect
                noAutoSelect
                options={frequencyOptions}
                value={''}
                onChange={() => { }}
                disabled={!regularly}
              />
            </Form.Group>
          </Col>
          <Col sm="6" lg="3">
            <Form.Group className="mb-3">
              <Form.Label>Erste Fälligkeit</Form.Label>
              <CustomDatePicker
                dateFormat="dd.MM.yyyy"
                placeholderText={t('date')}
                showYearDropdown
                selected={singleDate}
                disabled={!regularly}
                onChange={(date) => setSingleDate(date)}
              />
            </Form.Group>
          </Col>
          <Col sm="6" lg="3">
            <Form.Group className="mb-3">
              <Form.Label>Letzte Fälligkeit</Form.Label>
              <CustomDatePicker
                dateFormat="dd.MM.yyyy"
                placeholderText={t('date')}
                showYearDropdown
                selected={singleDate}
                disabled={!regularly}
                onChange={(date) => setSingleDate(date)}
              />
            </Form.Group>
          </Col>
        </Row>
      </StyledContainer>
    </>


  )
}

export default ContingentPointsCalculation