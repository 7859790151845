import React from 'react'
import { Observer } from 'mobx-react'
import { GoBackBtn } from '../../Buttons/GoBackBtn'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import useStores from '../../../stores/useStore'
import { useTranslation } from 'react-i18next'
import getThemeValue from '../../../utils/getThemeValue'

const StyledWrapper = styled.div`
  align-self: flex-start;
  padding: 10px 0;
`

const SubheaderTitle = ({ title, withBackButton, defaultBackTo, goBackLabel }) => {
  const { productsStore, teamOrderStore } = useStores()
  let currentLocation = useLocation()
  const { t } = useTranslation()
  const backLabel = goBackLabel || t('backButton')
  const processType = teamOrderStore.getCurrentProcessType()
  return (
    <StyledWrapper>
      {withBackButton && (
        <div className="d-flex">
          <GoBackBtn color="white" to={(currentLocation.state && currentLocation.state.from) || defaultBackTo || currentLocation.pathname}>
            {(currentLocation.state && currentLocation.state.backLabel) || backLabel}
          </GoBackBtn>
        </div>
      )}
      {title && (
        <div className="subheader-title">
          {title}
        </div>
      )}
      <div className="subheader-title-small">
        <Observer>{() => {
          const { targetEmployee } = productsStore
          switch(processType)
          {
            case 'storeHouse':
              return <>{getThemeValue('header', 'storehouseOrder', t('StorehouseOrder:storehouseOrder'))}: {productsStore.basket && productsStore.basket.targetStorehouse && productsStore.basket.targetStorehouse.displayName}</>
            case 'costCenter':
              return <>{getThemeValue('header', 'costcenterOrder', t('CostcenterOrder:costcenterOrder'))}: {productsStore.basket && productsStore.basket.targetCostCenter && productsStore.basket.targetCostCenter.displayName}</>
            default:
              return (targetEmployee.firstName && `${targetEmployee.firstName} ${targetEmployee.lastName}`) || null
          }
        }}</Observer>
      </div>
    </StyledWrapper>
  )
}

export default SubheaderTitle
