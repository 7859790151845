import React from 'react'
import { Subheader } from '../../components/Page/logix2020/Subheader'

import AdministrationSidebarNavigation from '../../components/Configuration/AdministrationSidebarNavigation'
import { useTranslation } from 'react-i18next'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import { Link } from 'react-router-dom'
import useStores from '../../stores/useStore'
import { Alert, Spinner } from 'react-bootstrap'
import { MdLibraryAdd } from 'react-icons/md'
import { BsSortAlphaDown } from "react-icons/bs";
import { useObserver } from 'mobx-react'
import ActivitiesOverview from '../../components/Configuration/Activity/ActivitiesOverview'
import { ButtonWithLoadingState } from '../../components/Buttons'
import { useActivitiesState } from '../../stores/Configuration/ActivitiesProvider'


const ActivitiesAdministrationPage = () => {
  const { t } = useTranslation()
  const { uiStore } = useStores()
  const { loadingStates, putActivitiesOrderSequence } = useActivitiesState()
  const allowEntitlementAdministration = useObserver(() => uiStore.allowEntitlementAdministration)
  const dashboardLoaded = useObserver(() => !!uiStore.dashboard)
  return (
    <>
      <Subheader className="subHeader-v1 justify-content-between">
        <div className="subheader-title flex-grow-1">{t('activities:manageActivities')}</div>
        <ButtonWithLoadingState
          variant={'primary'}
          buttonText={t('activities:sortAlphabetically')}
          isLoading={loadingStates.saveOrderSequence}
          onClick={putActivitiesOrderSequence}
          buttonIcon={<BsSortAlphaDown size="23" className="me-3 " />}
          className="subheader__button btn btn-primary text-white"
        />

        {allowEntitlementAdministration && <Link to='/activitiesAdministration/create' className="subheader__button btn btn-primary text-white"><MdLibraryAdd className="me-3" />{t('activities:addActivity')}</Link>}
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <AdministrationSidebarNavigation active="activitiesAdministration" />
        </SidebarLeft>
        <ContentArea>
          {allowEntitlementAdministration ?
            <ActivitiesOverview />
            : (!dashboardLoaded)
              ? <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
              : <Alert variant="danger">{t('accessDenied')}</Alert>
          }
        </ContentArea>
      </FlexWrapper>
      <BigFooter />
    </>
  )
}
export default ActivitiesAdministrationPage