import React from 'react'
import { t } from 'i18next';
import { formatedPriceObject } from '../../utils/formatedPrice';
import { StyledTable } from '../ProductDetail/ProductDetailStyles';
const getFormattedDefaultPrice = (prices) => {
  const defaultPriceObj = prices.find(priceObj => priceObj.isDefault)
  return defaultPriceObj ? formatedPriceObject(defaultPriceObj) : null
}

const PositionDetails = ({ position }) => {

  const Credit = () => {
    let credit = position.bPContingentAction === "toBeUpdated"
      ? t('complaints:toBeUpdated') : position.bPContingentAction === "wasUpdated"
        ? t('complaints:wasUpdated') : position.bPContingentAction === "updateFailed"
          ? t('complaints:updateFailed') : null
    let creditType = position.bPContingentCreditType === "defaultCreditType"
      ? t('complaints:defaultCreditType') : position.bPContingentCreditType === "onlyPoints"
        ? t('complaints:onlyPoints') : position.bPContingentCreditType === "onlyAmounts"
          ? t('complaints:onlyAmounts') : null
    return (credit || creditType)
      ? <tr>
        <td>{t('complaints:credit')}:</td>
        <td>
          <div>{credit}</div>
          <div>{creditType}</div>
        </td>
      </tr>
      : null
  }

  const ReturnReasons = () => {
    const activeReasons = position.reasons.filter(reason => reason.isSelected)
    return activeReasons.length ?
      <tr>
        <td>{t('complaints:returnReasons')}:</td>
        <td>
          {activeReasons.map(reason => {
            const formattedDefaultPrice = getFormattedDefaultPrice(reason.prices)
            return (
              <div key={reason.id}>{reason.displayName} {formattedDefaultPrice ? `(${formattedDefaultPrice})` : null}</div>
            )
          })}
        </td>
      </tr>
      : null
  }

  const CustomPrice = () => {
    const formattedDefaultPrice = getFormattedDefaultPrice(position.customPrice.prices)
    return (position.customPrice.apply && formattedDefaultPrice)
      ? <tr>
        <td>{t('complaints:customCredit')}:</td>
        <td>{formattedDefaultPrice} {position.customPrice.comment ? `(${position.customPrice.comment})` : null}</td>
      </tr>
      : null
  }

  const Defect = () => (
    (position.hasDefects && position.defectsComment)
      ? <tr>
        <td>{t('complaints:qualityDefect')}:</td>
        <td> {position.defectsComment}</td>
      </tr>
      : null
  )

  return (
    <StyledTable striped bordered  >
      <tbody>
        <Credit />
        <CustomPrice />
        <Defect />
        {position.serialKey ? <tr>
          <td>{t('inventory:serialNumber')}:</td>
          <td>{position.serialKey}</td>
        </tr> : null}
        {position.batchKey ? <tr>
          <td>${t('inventory:batchNumber')}:</td>
          <td>{position.batchKey}</td>
        </tr> : null}
        <ReturnReasons />
      </tbody>
    </StyledTable>
  )
}

export default PositionDetails