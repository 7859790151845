import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import Spinner from 'react-bootstrap/Spinner'

const StyledLoading = styled.div`
  align-items: center;
  background: rgba(255,255,255,.7);
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  height: calc(100vH - 237px);
`
const StyledLoadingText = styled.span`
  color: ${props => props.theme.primaryColor};
  font-size: 16px;
  letter-spacing: -1px;
  margin-left: 40px;
  text-transform: uppercase;
`


export const PageLoadingLayer = inject('uiStore')(observer((props) => {
  if (props.isLoading) {
    return <StyledLoading>
      <Spinner animation='border' variant='primary' />
      <StyledLoadingText>{props.loadingText}</StyledLoadingText>
    </StyledLoading>
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}))
