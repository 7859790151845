import React, { useEffect, useMemo, useState } from 'react'
import ExpandedType from './ExpandedType'
import { ArrowIconNew, StyledArrowWrapper, StyledItemWrapper, StyledListItemText } from '../../TreeList/treeList'
import { ContextMenuTrigger } from 'react-contextmenu'
import { StyledContextMenu } from '../../ContextMenu/StyledContextMenu'
import MoreOptionsButton from '../../Buttons/MoreOptionsButton'
import PortalContextMenu from '../../ContextMenu/PortalContextMenu'
import { PenIcon } from '../../Icons/PenIcon'
import { MdLibraryAdd } from 'react-icons/md'
import { DeleteIcon } from '../../Icons/DeleteIcon'
import { t } from 'i18next'
import { emptyGuid } from '../../../utils/emptyGuid'
import { cloneDeep } from 'lodash';
import InformationPopover from '../../InformationPopover/InformationPopover'
import SafelySetInnerHTML from '../../SafelySetInnerHTML'
import { Alert } from 'react-bootstrap'
import MediaQuery from 'react-responsive'

const PlaceholderItem = ({ type, setShowEditTypeModal, setShowDeleteType, setShowDeletePlaceholder, addPlaceholder, allPlaceholders, selectedLanguage }) => {
  const [expandedTypes, setExpandedTypes] = useState({})
  const [placeHolders, setPlaceHolders] = useState([])


  //we only need to work with the placeholders for the current placeholder type
  useEffect(() => {
    let filteredPlaceholders = allPlaceholders.filter(p => p.typeId === type.id)
    setPlaceHolders(filteredPlaceholders)
  }, [allPlaceholders, type.id])

  const handleExpandType = (typeId) => {
    setExpandedTypes(prev => (
      { ...prev, [typeId]: (prev[typeId] ? false : true) }
    ))
  }
  //add props with values in selected language, so that we can use them as table columns (dataField)
  const preparedData = useMemo(() => {
    let placeHolderCopy = cloneDeep(placeHolders)
    return placeHolderCopy.map(p => {
      let translatedText = p.text.values.find(v => v.langIsoCode === selectedLanguage)?.value || ""
      let translatedDescription = p.description.values.find(v => v.langIsoCode === selectedLanguage)?.value || ""
      return ({ ...p, _text: translatedText, _description: translatedDescription })
    })
  }, [selectedLanguage, placeHolders])
  const options = [
    {
      option: 'add',
      optionText: t('placeholder:addPlaceholder'),
      optionIcon: <MdLibraryAdd />,
      disabled: !!placeHolders.find(p => p.id === emptyGuid),
      onOptionSelect: () => { addPlaceholder({ typeId: type.id, placeholder: "[new]", id: emptyGuid, description: { values: [] }, text: { values: [] } }); !expandedTypes[type.id] && handleExpandType(type.id) }
    }, type.id !== emptyGuid ? {
      option: 'edit',
      optionText: t('edit'),
      optionIcon: <PenIcon />,
      onOptionSelect: () => setShowEditTypeModal(type)
    } : null,
    type?.metaProperties?.find(metaProp => (metaProp.pointer === '/id' && metaProp.attributes === 'isDeletable')) ?
      {
        option: 'delete',
        optionText: t('deleteButton'),
        optionIcon: <DeleteIcon />,
        onOptionSelect: () => setShowDeleteType(type)
      } : null
  ]
  return (
    <>
      <StyledContextMenu>
        <ContextMenuTrigger id={`contextId_${type.id}`} holdToDisplay={-1}>
          <StyledItemWrapper    >
            <StyledArrowWrapper onClick={() => handleExpandType(type.id)} >
              <ArrowIconNew color={expandedTypes[type.id] ? '#9c9c9c' : '#3a3a38'}
                variant={expandedTypes[type.id] ? 'down' : 'right'} />
            </StyledArrowWrapper>
            <StyledListItemText >
              {type.name}
            </StyledListItemText>
            <div className="action-buttons">
              {type.id !== emptyGuid && <div onClick={() => setShowEditTypeModal(type)}><InformationPopover header={t('description')} content={!!type.description
                ? <SafelySetInnerHTML className="ck-content">
                  {type.description}
                </SafelySetInnerHTML>
                : <Alert>{t('noData')}</Alert>} />
              </div>}
              <MediaQuery maxWidth={1499}>
                <MoreOptionsButton options={options} />
              </MediaQuery>
            </div>
          </StyledItemWrapper>
        </ContextMenuTrigger>
        <PortalContextMenu contextId={`contextId_${type.id}`} options={options} />
      </StyledContextMenu>
      {expandedTypes[type.id] ?
        <ExpandedType setShowDeletePlaceholder={setShowDeletePlaceholder} preparedData={preparedData} selectedLanguage={selectedLanguage} />
        : null}
    </>
  )
}

export default PlaceholderItem
