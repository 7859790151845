import React from 'react'
import DynamicFormElements from '../Form/DynamicFormElements'

const formConfigTemplate = [
  {
    type: 'text',
    name: 'mailAddress',
    label: 'emailAddress'
  }
]

const EntitlementExport = ({ formData, setFormData, formErrors }) => {

  return (
    <>
      <DynamicFormElements formConfigTemplate={formConfigTemplate} setFormData={setFormData} formData={formData} formErrors={formErrors} />
    </>
  )
}
export default EntitlementExport