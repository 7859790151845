import PdfIcon from '../assets/icons/pdf.svg'
import ExcelIcon from '../assets/icons/excel.svg'
import FileIcon from '../assets/icons/file.svg'
import WordIcon from '../assets/icons/word.svg'
import ImageIcon from '../assets/icons/image.svg'
import styled from 'styled-components'
const StyledIcon = styled.div`
margin-right:10px;
  background: url(${props => props.type}) center center no-repeat;
  background-size: contain;
  height: 30px;
  width: 30px;
`
const types = {
  ExcelIcon: {
    accept: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    icon: ExcelIcon
  },
  PdfIcon: {
    accept: ['application/pdf'],
    icon: PdfIcon
  },
  ImageIcon: {
    accept: ['image/'],
    icon: ImageIcon
  },
  WordIcon: {
    accept: ['.doc', '.docx', '.xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    icon: WordIcon
  },
}
export const getFileTypeIcon = (fileType) => {
  for (const obj of Object.values(types)) {
    if (obj.accept.find(type => fileType.startsWith(type))) {
      return obj.icon
    }
  }
  return FileIcon
}
export const FileTypeIcon = ({ fileType, ...rest }) => {
  let type = getFileTypeIcon(fileType)
  return <StyledIcon type={type} {...rest} />
}