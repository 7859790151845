import React from 'react'
import FileInput from './FileInput';
import { useState } from 'react';
import { FileOutput } from './FileOutput';
import { colors } from '../../styles/colors';
import { cloneDeep } from 'lodash';

const FileInputTest = () => {
  const [files, setFiles] = useState([])
  return (
    <div className="mt-3"><FileInput
      style={{ marginBottom: "1rem" }}
      onDrop={(acceptedFiles) => {
        let newFiles = acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }));
        setFiles(prev => [...prev, ...newFiles])
      }}
    />
      {files.map((file, index) => (
        <FileOutput
          style={{ height: "4rem", background: colors.gray1 }}
          className="mb-2"
          key={`${file.name}_${index}`}
          file={file}
          deleteFile={
            () => setFiles(prev => {
              prev.splice(index, 1)
              return cloneDeep(prev)
            })
          }
        />
      ))}
    </div>
  )
}

export default FileInputTest