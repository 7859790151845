import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'

import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'

import { ButtonWithLoadingState } from '../Buttons'
import { withTranslation } from 'react-i18next'
import SafelySetInnerHTML from '../SafelySetInnerHTML'
import getThemeValue from '../../utils/getThemeValue'
import PasswordInputField from '../Forms/PasswordInputField'
const StyledLoginBoxHeadline = styled.h1`
  font-size: 1.6em;
  font-weight: 600;
  text-align: center;
  color:white;
  @media screen and (min-width: 425px) {
    font-size: 1.8rem;
  }
  @media screen and (min-width: 576px) {
    font-size: 2.5rem;
  }
`

const StyledLoginBoxText = styled.h5`
  margin-bottom: 2rem;
  text-align: center;
  color:white;
`

const StyledLoginButtonWrap = styled.div`
  //display: inline-block;
  margin-top: 0.5rem;
`

class LoginBox extends Component {
  handleOnSubmit = (e) => {
    e.preventDefault()
    this.props.loginStore.handleLoginSubmit()
  }

  componentWillUnmount() {
    this.props.loginStore.setLoginButtonState(false)
  }

  render() {
    const { t, theme, loginStore } = this.props

    const loginErrorMessage = getThemeValue('userLogin', 'loginErrorMessage', '')
    const loginBoxText = getThemeValue('userLogin', 'loginBoxText', '')
    return (
      <div className='styledBox smallBox'>
        <StyledLoginBoxHeadline>{getThemeValue('userLogin', 'loginBoxHeadline', '')}</StyledLoginBoxHeadline>
        {loginBoxText
          ? <>
            <StyledLoginBoxText>{loginBoxText}</StyledLoginBoxText>
            <hr />
          </>
          : null}
        {loginStore.someLoginError
          ? <Alert variant='danger'>
            {loginErrorMessage ? <SafelySetInnerHTML>{loginErrorMessage}</SafelySetInnerHTML> : t('loginBox:checkLoginData')}
          </Alert>
          : ''}
        {loginStore.specificLoginError
          ? <Alert variant='danger'>
            {loginStore.specificLoginError}
          </Alert>
          : ''}
        <Form onSubmit={this.handleOnSubmit} >
          <Form.Group controlId='formBasicEmail' className="mb-3">
            <Form.Label>{getThemeValue('userLogin', 'usernameLabel', '')}</Form.Label>
            <Form.Control
              type={theme.userLogin.type}
              placeholder={getThemeValue('userLogin', 'usernamePlaceholder', '')}
              onChange={e => loginStore.setLoginDataUsername(e.target.value)}
              required />
            <div className='invalid-feedback'>
              {t('loginBox:checkInputData')}
            </div>
          </Form.Group>

          <Form.Group controlId='formBasicPassword' className="mb-3">
            <Form.Label>{getThemeValue('userLogin', 'passwordLabel', '')}</Form.Label>
            <PasswordInputField
              inputKey='formLoginBoxPassword'
              placeholder={getThemeValue('userLogin', 'passwordPlaceholder', '')}
              onChange={e => loginStore.setLoginDataPassword(e.target.value)}
              required
            >
              <div className='invalid-feedback'>
                {t('loginBox:checkPassword')}
              </div>
            </PasswordInputField>
          </Form.Group>

          <div className='d-flex justify-content-between'>
            <Form.Text>
              <Link to='/password-reset'>{t('loginBox:lostPassword')}</Link>
            </Form.Text>
            <StyledLoginButtonWrap className="login-button">
              <ButtonWithLoadingState
                buttonText={t('loginBox:loginButton')}
                isLoading={loginStore.isLoginButtonLoading}
                type='submit'
                variant='primary'
              />
            </StyledLoginButtonWrap>
          </div>

          {getThemeValue('userLogin', 'registrationButton', false) &&
            <Form.Text>
              <Link to='/sign-up'>{t('loginBox:registrationButton')}</Link>
            </Form.Text>
          }
        </Form>
      </div>
    )
  }
}

export default withTheme(withTranslation()(inject('uiStore', 'loginStore')(observer(LoginBox))))
