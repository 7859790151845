import React from 'react'

export const ErrorDocumentIcon = ({ color, title, ...rest }) => {
  const useColor = color ? color : '#3C3C3B'
  return (
    <svg width="28px" height="28px" viewBox="0 0 28 28" {...rest}>
      {title && <title>{title}</title>}
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={useColor}>
          <g
            transform="translate(14.000000, 14.000000) scale(-1, 1) translate(-14.000000, -14.000000) translate(9.000000, 7.000000)">
            <path
              d="M9,0 C9.55228475,0 10,0.44771525 10,1 L10,13 C10,13.5522847 9.55228475,14 9,14 L1,14 C0.44771525,14 -8.2694608e-16,13.5522847 -8.2694608e-16,13 L-8.2694608e-16,3.46305234 C-8.2694608e-16,3.20188073 0.102176217,2.95107279 0.284682953,2.76425228 L2.69086232,0.301199941 C2.87902547,0.10858933 3.1369133,0 3.40617937,0 L9,0 Z M9,1 L3.40617937,1 L3.399,1.007 L3.39945984,2.45098877 C3.39945984,3.00327352 2.95174459,3.45098877 2.39945984,3.45098877 L1.013,3.45 L1,3.46305234 L1,13 L9,13 L9,1 Z M7.5,9 C7.77614237,9 8,9.22385763 8,9.5 C8,9.77614237 7.77614237,10 7.5,10 L7.5,10 L2.5,10 C2.22385763,10 2,9.77614237 2,9.5 C2,9.22385763 2.22385763,9 2.5,9 L2.5,9 Z M7.5,7 C7.77614237,7 8,7.22385763 8,7.5 C8,7.77614237 7.77614237,8 7.5,8 L7.5,8 L2.5,8 C2.22385763,8 2,7.77614237 2,7.5 C2,7.22385763 2.22385763,7 2.5,7 L2.5,7 Z M7.5,5 C7.77614237,5 8,5.22385763 8,5.5 C8,5.77614237 7.77614237,6 7.5,6 L7.5,6 L2.5,6 C2.22385763,6 2,5.77614237 2,5.5 C2,5.22385763 2.22385763,5 2.5,5 L2.5,5 Z M7.5,2 C7.77614237,2 8,2.22385763 8,2.5 L8,2.5 L8,3.5 C8,3.77614237 7.77614237,4 7.5,4 L7.5,4 L6.5,4 C6.22385763,4 6,3.77614237 6,3.5 L6,3.5 L6,2.5 C6,2.22385763 6.22385763,2 6.5,2 L6.5,2 Z"
            />
          </g>
          <path fill="#FF0000"
                d="M14.2928932,6.70710678 C14.845178,6.70710678 15.2928932,7.15482203 15.2928932,7.70710678 L15.2918932,12.7061068 L20.2928932,12.7071068 C20.845178,12.7071068 21.2928932,13.154822 21.2928932,13.7071068 C21.2928932,14.2593915 20.845178,14.7071068 20.2928932,14.7071068 L15.2918932,14.7061068 L15.2928932,19.7071068 C15.2928932,20.2593915 14.845178,20.7071068 14.2928932,20.7071068 C13.7406085,20.7071068 13.2928932,20.2593915 13.2928932,19.7071068 L13.2918932,14.7061068 L8.29289322,14.7071068 C7.74060847,14.7071068 7.29289322,14.2593915 7.29289322,13.7071068 C7.29289322,13.154822 7.74060847,12.7071068 8.29289322,12.7071068 L13.2918932,12.7061068 L13.2928932,7.70710678 C13.2928932,7.15482203 13.7406085,6.70710678 14.2928932,6.70710678 Z"
                transform="translate(14.292893, 13.707107) rotate(-315.000000) translate(-14.292893, -13.707107) "/>
        </g>
      </g>
    </svg>
  )
}
