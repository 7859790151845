import { t } from 'i18next'
import React, { useState, useEffect, useRef } from 'react'
import { Form } from 'react-bootstrap'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import FileInput from '../../Forms/FileInput'
import { ErrorAlerts, makeCall } from '../../../utils/api/makeCall'
import { StyledChatWrapper, StyledInputWrapper, StyledMessage, StyledMessageBox, StyledScrollWrapper } from './StyledChat'
import SendButton from './SendButton'
import MessageHeader from './MessageHeader'
import MessageBody from './MessageBody'
import FilesList from './FilesList'

const ComplaintChat = ({ itemId, loadMessages, messages, isComplaint, isReclamationClosed, reloadComplaint }) => {
  const [complaintMessage, setComplaintMessage] = useState('')
  const bottomRef = useRef(null);
  const [errorStates, setErrorStates] = useState({})
  const [loadingStates, setLoadingStates] = useState({})
  const [files, setFiles] = useState([])

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start"
      });
    }
  }, [messages]);

  const handleMessageChange = (e) => {
    setComplaintMessage(e.target.value)
  }

  const postMessage = () => {
    makeCall('postMessage', async () => {
      await apiClient.postFile(APIEndpoints.complaints(itemId).messages, { message: complaintMessage }, files)
      await loadMessages(true)
      reloadComplaint()
      setComplaintMessage('')
      setFiles([])
    }, setErrorStates, setLoadingStates)
  }

  return (
    <StyledChatWrapper>
      <StyledScrollWrapper className="no-border-radius">
        {messages.map(message => (
          <StyledMessage key={message.id} >
            <MessageHeader message={message} />
            <MessageBody message={message} />
          </StyledMessage>
        ))}
        <div ref={bottomRef} />
      </StyledScrollWrapper>
      {isComplaint
        ?
        <StyledInputWrapper>
          <FileInput
            className="ms-3"
            onDrop={(acceptedFiles) => {
              let newFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
              }));
              setFiles([...files, ...newFiles])
            }}
            isInvalid={errorStates?.postMessage?.formErrors?.file}
          />
          <div className="flex-grow-1" >
            <StyledMessageBox>
              <Form.Group controlId="formMessageText" >
                <Form.Control
                  style={{ paddingRight: "3rem" }}
                  as="textarea"
                  rows="3"
                  placeholder={`${t('complaints:enterMessage')}...`}
                  name="complaintMessage"
                  onChange={handleMessageChange}
                  value={complaintMessage}
                  disabled={isReclamationClosed}
                  isInvalid={errorStates?.postMessage?.formErrors?.message}
                />
              </Form.Group>
              <SendButton
                disabled={isReclamationClosed || loadingStates.postMessage || (!complaintMessage)}
                onClick={postMessage}
                isLoading={loadingStates.postMessage}
              />
            </StyledMessageBox>
            <div className="ps-3 pe-3">
              <ErrorAlerts errorStates={errorStates} />
              <FilesList files={files} setFiles={setFiles} />
            </div>
          </div>
        </StyledInputWrapper>
        : null}
    </StyledChatWrapper>
  )
}

export default ComplaintChat