import React, { useEffect, useState } from 'react'
import * as ReactDOM from 'react-dom'
import Spinner from 'react-bootstrap/Spinner'
import styled from 'styled-components'

export const StyledTableLoadingLayer = styled.div`
  position: absolute;
  background-color: rgba(0,0,0,0.4);
  left: 0;
  top: ${props => props.top};
  width: 100%;
  height: ${props => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
`
const TableLoadingLayer = ({ tableRef, tableIsLoading }) => {
  const [loadingOverlay, setLoadingOverlay] = useState({ top: '69px', height: '100%' })

  useEffect(() => {
    if (tableRef && tableRef.current) {
      const domNode = ReactDOM.findDOMNode(tableRef.current)
      setLoadingOverlay({
        top: `${domNode.querySelector('thead').getBoundingClientRect().height}px`,
        height: `${domNode.querySelector('tbody').getBoundingClientRect().height}px`
      })
    }

  }, [tableRef, tableIsLoading, setLoadingOverlay])

  if (!tableIsLoading) {
    return null
  }

  return (
    <StyledTableLoadingLayer top={loadingOverlay.top} height={loadingOverlay.height}>
      <Spinner animation='border' variant='primary'/>
    </StyledTableLoadingLayer>
  )
}

export default TableLoadingLayer
