import { StyledCircleCheckIconWrapper } from './StyledCircleCheckIconWrapper'
import { CircleCheckIcon } from '../../../Icons/CircleCheckIcon'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { emptyGuid } from '../../../../utils/emptyGuid'

const AccoundDataCheck = ({ login, password, id }) => {
    const [isValid, setIsValid] = useState(false)
    useEffect(() => {
        let userExist = id && (id !== emptyGuid)
        setIsValid((userExist && !!login) || (!userExist && !!login && !!password))
    }, [login, password, id])

    return (
        <>
            <StyledCircleCheckIconWrapper>
                <CircleCheckIcon variant={isValid ? 'done' : 'must'} />
            </StyledCircleCheckIconWrapper>
            <span>{t('username')} / {t('password')}</span>
        </>
    )
}

export default AccoundDataCheck