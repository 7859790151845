import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/colors'
import { useAppState } from '../../../stores/AppProvider'

const StyledContentArea = styled.div`
  background: white;
  padding: 20px;
  width: 100%;
  ${props => props.noPadding && `padding-left: 0;`}
  ${props => props.noPadding && `padding-right: 0;`}
  ${props => props.noPaddingY && `padding-top: 0;`}
  ${props => props.noPaddingY && `padding-bottom: 0;`}
  min-height: calc(100vh - ${props => props.noSubheader ? '50px' : '140px'});
  h2 {
    color: ${colors.textColor};
  }
  .month-title {
    margin-top: 40px;
    margin-bottom: -10px;
  }

  /* @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px)  {
    min-height: 0px;
  } */
`

export const ContentArea = ({ noPadding, noPaddingY, fullWidth, noSubheader, ...rest }) => {
  const className = `${rest.className || ''} `
  const { contentAreaRef } = useAppState()

  return (
    <StyledContentArea
      ref={contentAreaRef}
      noPadding={noPadding}
      noPaddingY={noPaddingY}
      noSubheader={noSubheader}
      {...rest}
      className={className}
    />
  )
}
