import React from 'react'

export const FileIcon = (props) => {
  const color = props.color ? props.color : '#D0CFD0'

  return (

    <svg width='28px' height='28px' viewBox="-9 -9 45 45" fill={color}>
      <path d="M6 0C4.9 0 4 .9 4 2v24c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6.9c0-.5-.2-1-.6-1.4L18.6.6c-.4-.4-.9-.6-1.4-.6H6zm0 2h11.2v2.9c0 1.1.9 2 2 2H22V26H6V2zm3 16c-.6 0-1 .4-1 1s.4 1 1 1h10c.6 0 1-.4 1-1s-.4-1-1-1H9zm0-4c-.6 0-1 .4-1 1s.4 1 1 1h10c.6 0 1-.4 1-1s-.4-1-1-1H9zm0-4c-.6 0-1 .4-1 1s.4 1 1 1h10c.6 0 1-.4 1-1s-.4-1-1-1H9zm0-6c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1h2c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1H9z" />
    </svg>
  )
}


