import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import CustomDatePicker from '../../Forms/CustomDatePicker'
import { t } from 'i18next'
import styled from 'styled-components'
import { colors } from '../../../styles/colors'
import ArraySelect from '../../Select/ArraySelect'
import DateDisplay from './DateDisplay'
import { ErrorAlerts, makeCall } from '../../../utils/api/makeCall'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { getIsoDate } from '../../../utils/getIsoDate'
import { cloneDeep } from 'lodash'
import { emptyDate } from '../../../utils/emptyDate'
import { formatedDate } from '../../../utils/formatedDate'
import { ButtonWithLoadingState } from '../../Buttons'

let frequencyOptions = [
  { displayName: t('stdPointRunAdmin:daily'), id: "daily" },
  { displayName: t('stdPointRunAdmin:weekly'), id: "weekly" },
  { displayName: t('stdPointRunAdmin:monthly'), id: "monthly" },
  { displayName: t('stdPointRunAdmin:quarterly'), id: "quarterly" },
  { displayName: t('stdPointRunAdmin:yearly'), id: "yearly" }
]

const StyledContainer = styled.div`
background:${colors.gray1};
padding:2rem;
margin-bottom:2rem;
`

const StdPointsCalculation = () => {
  const [config, setConfig] = useState(null)
  const [initialConfig, setInitialConfig] = useState('')
  const [errorStates, setErrorStates] = useState({})
  const [loadingStates, setLoadingStates] = useState({})

  const loadConfig = useCallback(async () => {
    makeCall('loadConfig', async () => {
      let loadedConfig = await apiClient.getJson(APIEndpoints.coreservices().pointRunConfig)
      setConfig(loadedConfig)
      setInitialConfig(JSON.stringify(loadedConfig))
    }, setErrorStates, setLoadingStates)

  }, [])

  const cancel = () => {
    setConfig(JSON.parse(initialConfig))
  }
  const submit = () => {
    makeCall('saveConfig', async () => {
      let loadedConfig = await apiClient.patchJson(APIEndpoints.coreservices().pointRunConfig, config.configuration)
      setConfig(loadedConfig)
      setInitialConfig(JSON.stringify(loadedConfig))
    }, setErrorStates, setLoadingStates)
  }

  useEffect(() => {
    loadConfig()
  }, [loadConfig])

  const dataHasChanged = useMemo(() => {
    if (config) {
      return JSON.stringify(config) !== initialConfig
    } else return false
  }, [config, initialConfig])

  const handleChangeDate = (newDate) => {
    let isoDate = getIsoDate(newDate)
    setConfig(prev => {
      prev.configuration.startDate = isoDate
      prev.nextRun = emptyDate
      prev.lastRun = emptyDate
      return cloneDeep(prev)
    })
  }

  const handleChangeFrequency = (e) => {
    const { value } = e.target
    setConfig(prev => {
      if (value === 'none') {
        prev.configuration.startDate = emptyDate
      }
      prev.configuration.frequency = value
      prev.nextRun = emptyDate
      prev.lastRun = emptyDate
      return cloneDeep(prev)
    })
  }

  if (!config) {
    return <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
  }
  return (
    <>
      <StyledContainer   >
        <Row>
          <Col sm="6" lg="3">
            <Form.Group className="mb-3">
              <Form.Label>{t('stdPointRunAdmin:startDate')}</Form.Label>
              <CustomDatePicker
                dateFormat="dd.MM.yyyy"
                placeholderText={t('date')}
                showYearDropdown
                selected={(config.configuration.startDate === emptyDate) ? '' : new Date(config.configuration.startDate)}
                onChange={handleChangeDate}
              />
            </Form.Group>
          </Col>
          <Col sm="6" lg="3">
            <Form.Group className="mb-3">
              <Form.Label>{t('stdPointRunAdmin:frequency')}</Form.Label>
              <ArraySelect
                noAutoSelect
                options={frequencyOptions}
                value={config?.configuration?.frequency || ''}
                onChange={handleChangeFrequency}
                defaultOptionIsSelectable
                defaultOption={{ value: 'none', name: t('pleaseSelect') }}
                returnEvent
              />
            </Form.Group>
          </Col>
          {(config?.lastRun && config.lastRun !== emptyDate) ? <Col sm="6" lg="3">
            <DateDisplay isPrevious date={formatedDate(config.lastRun)} />
          </Col> : null}
          {(config?.nextRun && config.nextRun !== emptyDate) ? <Col sm="6" lg="3">
            <DateDisplay date={formatedDate(config.nextRun)} />
          </Col> : null}
        </Row>
      </StyledContainer>
      <div>
        <ButtonWithLoadingState
          className="my-2 me-2"
          onClick={submit}
          isLoading={loadingStates.saveConfig}
          buttonText={t('saveButton')}
          disabled={!dataHasChanged}
        />
        {dataHasChanged && <Button className="my-2 me-2" variant="secondary" onClick={cancel} >{t('cancelButton')}</Button>}
      </div>
      <ErrorAlerts errorStates={errorStates} />
      {dataHasChanged &&
        <Alert variant="info">
          {t('EmployeeFormHealthData:dataChange')}
        </Alert>
      }
    </>
  )
}

export default StdPointsCalculation