import React from 'react'

export const ClockIcon = ({ color, title, ...rest }) => {
  const useColor = color ? color : '#3C3C3B'
  return (
    <svg width="28px" height="28px" viewBox="0 0 28 28" {...rest}>
      {title && <title>{title}</title>}
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M14,6 C18.418278,6 22,9.581722 22,14 C22,18.418278 18.418278,22 14,22 C9.581722,22 6,18.418278 6,14 C6,9.581722 9.581722,6 14,6 Z M14,19.5 C13.7238576,19.5 13.5,19.7238576 13.5,20 C13.5,20.2761424 13.7238576,20.5 14,20.5 C14.2761424,20.5 14.5,20.2761424 14.5,20 C14.5,19.7238576 14.2761424,19.5 14,19.5 Z M14,8 C13.7238576,8 13.5,8.22385763 13.5,8.5 L13.5,8.5 L13.5,14 C13.5,14.1355143 13.5539108,14.2584371 13.6414555,14.3484912 L13.6414555,14.3484912 L15.7748737,16.4819805 C15.9701359,16.6772427 16.2867184,16.6772427 16.4819805,16.4819805 C16.6772427,16.2867184 16.6772427,15.9701359 16.4819805,15.7748737 L16.4819805,15.7748737 L14.5,13.793 L14.5,8.5 C14.5,8.22385763 14.2761424,8 14,8 Z M8,13.5 C7.72385763,13.5 7.5,13.7238576 7.5,14 C7.5,14.2761424 7.72385763,14.5 8,14.5 C8.27614237,14.5 8.5,14.2761424 8.5,14 C8.5,13.7238576 8.27614237,13.5 8,13.5 Z M20,13.5 C19.7238576,13.5 19.5,13.7238576 19.5,14 C19.5,14.2761424 19.7238576,14.5 20,14.5 C20.2761424,14.5 20.5,14.2761424 20.5,14 C20.5,13.7238576 20.2761424,13.5 20,13.5 Z"
          fill={useColor} />
        <circle fill="#FFFFFF" cx="14" cy="14" r="1"/>
      </g>
    </svg>
  )
}
