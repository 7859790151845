import { Col, Form, Row } from 'react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ArraySelect from '../../../Select/ArraySelect'
import ZipSuggestion from '../../../Forms/ZipSuggestion'
import { getFakeEvent } from '../../../../utils/utils'

const Address = ({ availableCountries, address, onChange }) => {
  const { t } = useTranslation()

  return (
    <>

      <Row>
        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label>CO</Form.Label>
            <Form.Control
              type="text"
              placeholder="CO"
              name="careOf"
              onChange={onChange}
              value={address.careOf}
            />
          </Form.Group>
        </Col>
        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label>{t('EmployeeFormAddress:street')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('EmployeeFormAddress:street')}
              name="street"
              onChange={onChange}
              value={address.street}
            />
          </Form.Group>
        </Col>
        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label>{t('EmployeeFormAddress:houseNumber')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('EmployeeFormAddress:houseNumber')}
              name="streetNr"
              onChange={onChange}
              value={address.streetNr}
            />
          </Form.Group>
        </Col>
        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label>{t('EmployeeFormAddress:mailbox')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('EmployeeFormAddress:mailbox')}
              name="postOfficeBox"
              onChange={onChange}
              value={address.postOfficeBox}
            />
          </Form.Group>
        </Col>
        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label>{t('EmployeeFormAddress:postcode')}</Form.Label>
            <ZipSuggestion
              type="text"
              placeholder={t('EmployeeFormAddress:postcode')}
              name="zipCode"
              onChange={onChange}
              value={address.zipCode}
              onChangeSuggestion={obj => onChange(getFakeEvent("city", obj.cityName, "text"))}
              countryIsoCode={address.countryIsoCode || "de"}
            />
          </Form.Group>
        </Col>
        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label>{t('EmployeeFormAddress:city')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('EmployeeFormAddress:city')}
              name="city"
              onChange={onChange}
              value={address.city}
            />
          </Form.Group>
        </Col>
        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label>{t('EmployeeFormAddress:country')}</Form.Label>
            <ArraySelect
              name="countryIsoCode"
              onChange={onChange}
              value={address.countryIsoCode}
              options={availableCountries}
              otherValueKey={"isoCode"}
              returnEvent
              defaultOptionIsSelectable
              noAutoSelect
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default Address
