import React from 'react'
import styled from 'styled-components'

const StyledOverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;
 overflow: auto;
`
const OverlayWrapper = ({children}) => {
  return (
    <StyledOverlayWrapper>
      {children}
    </StyledOverlayWrapper>
  )
}

export default OverlayWrapper
