import { t } from 'i18next'
import React from 'react'
import { useState } from 'react'
import { Image } from 'react-bootstrap'
import styled from 'styled-components'
import { DeleteIcon } from '../../Icons/DeleteIcon'
import ProductGalleryModal from '../../ProductGallery/ProductGalleryModal'
import { colors } from '../../../styles/colors'

const StyledWrapper = styled.div`
  display:flex;
  align-items: center;
`
const StyledName = styled.div`
  word-break:break-all;
  display:flex;
  align-items: center;
  margin-right: 1rem;
`
const StyledDeleteIcon = styled.div`
cursor: pointer;
`
const StyledThumbnail = styled.div`
  margin-right:1rem;
  >img{
    border:1px solid ${colors.gray3};
    cursor:zoom-in;
  }
`
const ImageOutput = ({ url, deleteFile, ...rest }) => {
  const [openGalleryModal, setOpenGalleryModal] = useState(null)
  return <StyledWrapper {...rest}>
    <StyledName>
      <StyledThumbnail>
        <Image fluid src={url} rounded onClick={() => setOpenGalleryModal({ url })} />
      </StyledThumbnail>
    </StyledName>
    {deleteFile ?
      <StyledDeleteIcon onClick={deleteFile} title={t('deleteButton')}>
        <DeleteIcon />
      </StyledDeleteIcon> : null}
    {openGalleryModal && <ProductGalleryModal handleClose={() => setOpenGalleryModal(null)} detailImages={[openGalleryModal]} />}

  </StyledWrapper>
}
export default ImageOutput