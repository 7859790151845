import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import styled from 'styled-components'
import { t } from 'i18next'
import { useCatalogsState } from '../../../stores/Configuration/CatalogsProvider'
import ModalComponent2 from '../../Modals/ModalComponent2'
import BigDataList from './BigDataList';
import LoadingOverlay from '../../Loading/LoadingOverlay'
import MediaQuery from 'react-responsive'
import ArticleSelect from '../../ArticleSelect/ArticleSelect'
import { cloneDeep } from 'lodash'
import StructureModal from './StructureModal'
import { emptyGuid } from '../../../utils/emptyGuid'
import CopyOrMoveModal from './CopyOrMoveModal'
import MoveArticlesModal from './MoveArticlesModal'
import { DragPrgeview } from '../ContingentTemplate/DragPrgeview'
import useStores from '../../../stores/useStore'
import useReport from '../../../utils/hooks/useReport'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import DownloadURLAlert from '../../Forms/DownloadURLAlert'
import ReportSubscriptionModal from '../../Files/Form/ReportSubscriptionModal'

const StyledArticleSelect = styled.div`
width: 500px;
height: 802px;
overflow:auto;
border:1px solid gray;
margin-left:1rem;
`
const StyledWrapper = styled.div`
  @media screen and (min-width: 1080px) {
    min-width: 600px;
  }
`

const CatalogsOverview = () => {
  const {
    getCatalogs,
    putCatalogsOrderSequence,
    deleteCatalog,
    loadingStates,
    errorStates,
    bigData,
    getCatalogStructure,
    setBigData,
    patchStructureSequence,
    getStructureArticles,
    getCatalogArticles,
    deleteDimension,
    deleteCatalogArticle,
    deleteStructureArticle,
    getArticleAssignments,
    assignmentTree, setAssignmentTree

  } = useCatalogsState()

  const { uiStore } = useStores()
  const [selectedArticles, setSelectedArticles] = useState([])
  const [openDeleteDimensionModal, setOpenDeleteDimensionModal] = useState(null)
  const [openEditDimensionModal, setOpenEditDimensionModal] = useState(null)
  const [openCreateDimensionModal, setOpenCreateDimensionModal] = useState(null)
  const [openDeleteArticle, setOpenDeleteArticle] = useState(null)
  const [openMoveOrCopyModal, setOpenMoveOrCopyModal] = useState(null)
  const [openDeleteCatalog, setOpenDeleteCatalog] = useState(null)
  const [openMoveArticlesModal, setOpenMoveArticlesModal] = useState(null)
  const listRef = useRef(null)

  useEffect(() => {
    getCatalogs()
  }, [getCatalogs])

  const getNewSequence = useCallback((fromId, toId, oldSequence) => {
    let fromIndex = oldSequence.findIndex(id => id === fromId)
    let toIndex = oldSequence.findIndex(id => id === toId)
    let copiedSequence = [...oldSequence]
    const elm = copiedSequence.splice(fromIndex, 1)[0];
    copiedSequence.splice(toIndex, 0, elm);
    return copiedSequence
  }, [])

  const moveCardHandler = useCallback((fromObj, toObj) => {
    if (!fromObj) {
      return
    }
    else if (fromObj.type === toObj.type) {
      if (fromObj.type === "catalog") {
        let oldSequence = Object.keys(bigData)
        const newSequence = getNewSequence(fromObj.id, toObj.id, oldSequence)
        putCatalogsOrderSequence(newSequence)
      }
      if (fromObj.type === "dimension") {
        let oldSequence = Object.values(bigData[fromObj.catalogId].dimensions).filter(d => d.parentId === fromObj.parentId).map(v => v.id)
        const newSequence = getNewSequence(fromObj.id, toObj.id, oldSequence)
        patchStructureSequence(fromObj.catalogId, fromObj.parentId, newSequence)
      }
    }
    else if (fromObj.type === "article") {
      setOpenMoveOrCopyModal({ fromObj, toObj })
    }
    else if (fromObj.type === "multipleArticles") {
      setOpenMoveArticlesModal({ fromObj, toObj })
    }
  }, [bigData, getNewSequence, patchStructureSequence, putCatalogsOrderSequence])

  const changeOpenProp = useCallback((arrayItem) => {
    if (arrayItem.type === "catalog") {
      setBigData(prev => {
        let newData = cloneDeep(prev)
        newData[arrayItem.id].open = !newData[arrayItem.id].open
        return newData
      })
      let structureAlreadyLoaded = bigData[arrayItem.id].dimensionsLoaded
      if (!structureAlreadyLoaded) {
        getCatalogStructure(arrayItem.id)
      }
      let articlesLoaded = bigData[arrayItem.id].articlesLoaded
      if (!articlesLoaded) {
        getCatalogArticles(arrayItem.id)
      }
    }
    if (arrayItem.type === "dimension") {
      setBigData(prev => {
        let newData = cloneDeep(prev)
        newData[arrayItem.catalogId].dimensions[arrayItem.id].open = !newData[arrayItem.catalogId].dimensions[arrayItem.id].open
        return newData
      })
      let articlesLoaded = bigData[arrayItem.catalogId].dimensions[arrayItem.id].articlesLoaded
      if (!articlesLoaded) {
        getStructureArticles(arrayItem.catalogId, arrayItem.id)
      }
    }
  }, [bigData, getCatalogArticles, getCatalogStructure, getStructureArticles, setBigData])
  const { createReport, reportErrors, reportDownloadUrls, setReportDownloadUrls, reportSubscriptionId, setReportSubscriptionId } = useReport()
  const onCreateReport = (catalogId) => {
    createReport("reportName", APIEndpoints.articleBarcodes(catalogId))
  }
  return (
    <>
      <StyledWrapper>
        <DndProvider backend={HTML5Backend}>
          <DragPrgeview />
          <div className="d-flex"  >
            <div style={{ border: "1px solid gray" }}>
              <LoadingOverlay isLoading={loadingStates.saveOrderSequence || loadingStates.articleAssignments || !bigData}>
                <BigDataList
                  bigData={bigData}
                  moveCardHandler={moveCardHandler}
                  changeOpenProp={changeOpenProp}
                  deleteDimension={setOpenDeleteDimensionModal}
                  editDimension={setOpenEditDimensionModal}
                  createDimension={setOpenCreateDimensionModal}
                  deleteArticle={setOpenDeleteArticle}
                  deleteCatalog={setOpenDeleteCatalog}
                  getArticleAssignments={getArticleAssignments}
                  assignmentTree={assignmentTree}
                  deleteAssignments={setAssignmentTree}
                  allowCatalogPrint={uiStore.allowReportArticleCatalogSheet}
                  createReport={onCreateReport}
                />
              </LoadingOverlay>
            </div>
            <MediaQuery minWidth={1500}>
              <StyledArticleSelect ref={listRef}>
                <ArticleSelect selectedArticles={selectedArticles} setSelectedArticles={setSelectedArticles} getArticleAssignments={getArticleAssignments} withDND listRef={listRef} />
              </StyledArticleSelect>
            </MediaQuery ></div>
        </DndProvider>
      </StyledWrapper >
      {openDeleteCatalog && <ModalComponent2
        ModalTitle={t('catalogsAdministration:deleteCatalog')}
        BodyPart1={t('catalogsAdministration:deleteBody')}
        button1Function={() => deleteCatalog(openDeleteCatalog.catalogId).then(() => setOpenDeleteCatalog(null))}
        button1Text={t('delete')}
        button1Loading={loadingStates.deleteCatalog}
        button1Variant="danger"
        bodyStyle="alert alert-danger"
        functionCancel={() => { setOpenDeleteCatalog(null) }}
        error={errorStates?.deleteCatalog?.value}
      />
      }
      {
        openDeleteDimensionModal && <ModalComponent2
          ModalTitle={t('catalogsAdministration:deleteCategory')}
          BodyPart1={t('catalogsAdministration:deleteCategoryBody')}
          button1Function={() => deleteDimension(openDeleteDimensionModal.catalogId, openDeleteDimensionModal.elementId, openDeleteDimensionModal.parentId).then(() => setOpenDeleteDimensionModal(null))}
          button1Text={t('delete')}
          button1Loading={loadingStates.deleteDimension}
          button1Variant="danger"
          bodyStyle="alert alert-danger"
          functionCancel={() => { setOpenDeleteDimensionModal(null) }}
        />
      }
      {
        openEditDimensionModal && <StructureModal
          catalogId={openEditDimensionModal.catalogId}
          elementId={openEditDimensionModal.elementId}
          handleClose={() => setOpenEditDimensionModal(null)}
          parentId={openEditDimensionModal.parentId}
        />
      }
      {
        openCreateDimensionModal && <StructureModal
          catalogId={openCreateDimensionModal.catalogId}
          handleClose={() => setOpenCreateDimensionModal(null)}
          parentId={openCreateDimensionModal.parentId}
        />
      }
      {
        openDeleteArticle && <ModalComponent2
          ModalTitle={t('contingentTemplates:deleteArticle')}
          BodyPart1={t('catalogsAdministration:deleteArticleBody')}
          button1Function={() =>
            openDeleteArticle.elementId === emptyGuid
              ? deleteCatalogArticle(openDeleteArticle.catalogId, openDeleteArticle.articleId).then(() => setOpenDeleteArticle(null))
              : deleteStructureArticle(openDeleteArticle.catalogId, openDeleteArticle.elementId, openDeleteArticle.articleId).then(() => setOpenDeleteArticle(null))
          }
          button1Text={t('delete')}
          button1Loading={loadingStates.deleteArticle}
          button1Variant="danger"
          bodyStyle="alert alert-danger"
          functionCancel={() => { setOpenDeleteArticle(null) }}
        />
      }
      {
        openMoveOrCopyModal && <CopyOrMoveModal
          setOpenMoveOrCopyModal={setOpenMoveOrCopyModal}
          getStructureArticles={getStructureArticles}
          getCatalogArticles={getCatalogArticles}
          fromArticle={openMoveOrCopyModal.fromObj}
          toCatalogOrDimension={openMoveOrCopyModal.toObj}
        />
      }

      {
        openMoveArticlesModal && <MoveArticlesModal
          setOpenMoveOrCopyModal={setOpenMoveArticlesModal}
          getStructureArticles={getStructureArticles}
          getCatalogArticles={getCatalogArticles}
          fromArticle={openMoveArticlesModal.fromObj}
          toCatalogOrDimension={openMoveArticlesModal.toObj}
        />
      }
      {reportDownloadUrls.reportName &&
        <DownloadURLAlert
          downloadUrl={reportDownloadUrls.reportName}
          setDownloadUrl={() => setReportDownloadUrls({})}
          fileName={t("srm:exportButton")}
        />
      }
      {reportSubscriptionId && <ReportSubscriptionModal
        reportSubscriptionId={reportSubscriptionId}
        setReportSubscriptionId={setReportSubscriptionId}
        message={t('reportConfig:createPending')}
      />}
      {reportErrors.reportName && <Alert className="subheader__alert" variant="danger"  >{reportErrors.reportName.value}</Alert>}
    </>
  )
}

export default CatalogsOverview