import React, { useState, useEffect, useRef, useCallback } from "react"
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { getErrorMessageFromApiResponse } from "../../utils/api/apiUtils"
import { useParams } from 'react-router-dom'
import { setPointerErrorsFromApiResponse } from "../../utils/api/apiUtils"
import { useTranslation } from "react-i18next"
import useMountEffect from "../../utils/hooks/useMountEffect"

const cabinTypeTemplate = {
    name: {
        values: [],
    }
}
export const CabinTypesContext = React.createContext({})


const CabinTypesProvider = ({ children }) => {
    const { t } = useTranslation()
    const { cabinTypeId } = useParams()
    const [cabinTypes, setCabinTypes] = useState([])
    const [appointmentTypes, setAppointmentTypes] = useState([])
    const [selectedCabinType, setSelectedCabinType] = useState(JSON.parse(JSON.stringify(cabinTypeTemplate)))
    const mountedRef = useRef(true)
    const [success, setSuccess] = useState("")
    const [assignedAppointmentTypes, setAssignedAppointmentTypes] = useState([])
    const [formErrors, setFormErrors] = useState(null)
    const [error, setError] = useState("")
    const [redirectId, setRedirectId] = useState("")
    const [btnLoading, setBtnLoading] = useState(false)
    const [idCabinType, setIdCabinType] = useState(null)

    //Cleanup Function
    useEffect(() => {
        return () => { mountedRef.current = false }
    }, [])
    useMountEffect(() => {
        reloadCabinData()
    })

    //get CabinTypes, Languages, AppointmentTypes from Backend
    const reloadCabinData = (force) => {
        if (!cabinTypes.length || force) {
            apiClient.getJson(APIEndpoints.cabins()).then(response => {
                if (!mountedRef.current) { return null }
                setCabinTypes(response)
            }).catch((error) => {
                setError(error)
            })
        }
        if (!appointmentTypes.length || force) {
            apiClient.getJson(APIEndpoints.appointmenttypes()).then(response => {
                if (!mountedRef.current) { return null }
                setAppointmentTypes(response)
            }).catch((error) => {
                setError(getErrorMessageFromApiResponse(error))
            })
        }
    }

    //block CabinTypes 
    const blockCabinItem = (cabin) => {
        const params = {
            isBlocked: !cabin.isBlocked,
        }
        apiClient.patchJson(
            APIEndpoints.cabins(cabin.id).details,
            params,
        ).then(() => {
            if (!mountedRef.current) { return null }
            reloadCabinData(true)
        }).catch(error => setError(error))
    }

    const loadCabinData = useCallback(async (cabinTypeId) => {
        try {
            const response = await apiClient.getJson(APIEndpoints.cabins(cabinTypeId).cabinData)
            if (!mountedRef.current) { return null }
            setSelectedCabinType(response)
        }
        catch (e) {
            setError(e)
        }
    }, [])

    const loadAssignedAppointmenttypes = useCallback(async (cabinTypeId) => {
        try {
            const response = await apiClient.getJson(APIEndpoints.cabins(cabinTypeId).assignedAppointmenttypes)
            if (!mountedRef.current) { return null }
            setAssignedAppointmentTypes(response)
        }
        catch (e) {
            setError(e)
        }
    }, [])
    //get selected cabinType and its assignedAppointmenttypes
    useEffect(() => {
        if (cabinTypeId) {
            loadCabinData(cabinTypeId)
            loadAssignedAppointmenttypes(cabinTypeId)
        }
    }, [cabinTypeId, loadAssignedAppointmenttypes, loadCabinData])



    // check if is deletable cabin
    const checkDeletableCabin = async (id) => {
        var result = false
        await apiClient.getJson(APIEndpoints.cabins(id).deletable)
            .then((response) => {
                result = response.isDeletable
            }).catch(error => setError(error))
        return result
    }

    // delete cabin types
    const deleteCabin = (id, cancellationMessage = "") => {
        setBtnLoading(true)
        const params = {
            cancelComment: cancellationMessage,
            cancelAppointments: true,
        }
        apiClient.deleteJson(APIEndpoints.cabins(id).details, null, true, false,
            { data: params })
            .then(() => {
                if (!mountedRef.current) { return null }
                reloadCabinData(true)
                setIdCabinType(null)
            })
            .catch((error) => setError(error))
            .finally(() => setBtnLoading(false))
    }

    const handleSubmit = (mode) => {
        setSuccess('')
        setFormErrors(null)
        switch (mode) {
            case "assignedAppointmentTypes":
                apiClient.putJson(
                    APIEndpoints.cabins(selectedCabinType.id).assignedAppointmenttypes,
                    assignedAppointmentTypes
                ).then(() => {
                    if (!mountedRef.current) { return null }

                    setSuccess(t(`registrationBox:savingSuccess`))
                }).catch((error) => {
                    setFormErrors(setPointerErrorsFromApiResponse(error))
                })
                break;
            case "general":

                if (selectedCabinType.id) {
                    apiClient.patchJson(
                        APIEndpoints.cabins(selectedCabinType.id).details,
                        selectedCabinType
                    ).then(() => {
                        if (!mountedRef.current) { return null }
                        setSuccess(t(`registrationBox:savingSuccess`))
                    }).catch((error) => {
                        setFormErrors(setPointerErrorsFromApiResponse(error))
                    })
                }
                else {
                    apiClient.postJson(
                        APIEndpoints.cabins(),
                        selectedCabinType
                    ).then((response) => {
                        if (!mountedRef.current) { return null }
                        setSuccess(t(`registrationBox:savingSuccess`))
                        setRedirectId(response.id)
                    }).catch((error) => {
                        setFormErrors(setPointerErrorsFromApiResponse(error))
                    })
                }
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        let timer = null
        if (success) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                setSuccess("");
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [setSuccess, success])

    const contextValues = {
        cabinTypes,
        blockCabinItem,
        deleteCabin,
        checkDeletableCabin,
        handleSubmit,
        success, setSuccess,
        selectedCabinType, setSelectedCabinType, formErrors, error,
        assignedAppointmentTypes, setAssignedAppointmentTypes, appointmentTypes, redirectId,
        loadCabinData,
        loadAssignedAppointmenttypes,
        idCabinType, setIdCabinType,
        btnLoading
    }

    return (
        <CabinTypesContext.Provider value={contextValues}>
            {children}
        </CabinTypesContext.Provider>
    )
}

export default CabinTypesProvider
