import React from 'react'
import { useTranslation } from 'react-i18next'
import { Subheader } from '../../Page/logix2020/Subheader'
import SubheaderForm from '../../Page/logix2020/SubheaderForm'


const EditSubheader = () => {

  const { t } = useTranslation()


  return (
    <Subheader className="subHeader-v1">
      <SubheaderForm
        title={t('EmployeeFormHealthData:edidHealthData')}
        goBackLabel={t('Subheader:overviewEmployees')}
      />
    </Subheader>
  )
}

export default EditSubheader
