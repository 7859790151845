import React, { useState } from 'react'
import { PenIcon } from '../Icons/PenIcon';
import { useTranslation } from 'react-i18next'
import ModalComponent from '../Modals/ModalComponent'
import useStores from '../../stores/useStore'
import styled from 'styled-components'
const StyledEditIconWrapper = styled.span`
padding:4px;
cursor:pointer;
`
const OrderTemplateName = ({ order }) => {
    const { orderStore } = useStores();
    const { t } = useTranslation()
    const [showEditNameModal, setShowEditNameModal] = useState(false)
    const editName = async (name) => {
        await orderStore.editOrderName(name)
        orderStore.loadIncomingOrder(true)
    }
    return (
        <>
            <div className="my-3">
                <h1 className='m-0'>
                    {t('orderTemplates:orderTemplate')}: {order.templateName || " - "}
                    <StyledEditIconWrapper onClick={() => setShowEditNameModal(true)}>
                        <PenIcon title={t('edit')} />
                    </StyledEditIconWrapper>
                </h1>
            </div>
            {showEditNameModal && (<ModalComponent
                ModalTitle={t('orderTemplates:changeNameHeader')}
                functionButton1={name => editName(name)}
                textButton1={t('saveButton')}
                functionCancel={() => { setShowEditNameModal(false) }}
                withInput
                inputContent={order.templateName}
                inputType="input"
                inputLabel={t('orderTemplates:changeName')}
            />)}
        </>
    )
}

export default OrderTemplateName
