import React from 'react'

export const Keys = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="keys" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <g>
        <path className="st0" d="M14.3,97.1c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.4,0.8,0.4c0.1,0,0.2,0,0.3,0l18.6-3.2c0.5-0.1,0.9-0.5,0.9-1
          l0.7-7.6l0.1,0l7.5-0.7c0.5-0.1,1-0.5,1-1l0.7-7.6l0.1,0l7.5-0.7c0.3,0,0.5-0.1,0.7-0.3l10.3-10.3l0.1,0c9.7,3.5,20.5,1.1,27.7-6.1
          c10.3-10.3,10.3-27.1,0-37.4c-5-5-11.6-7.7-18.7-7.7c-6.9,0-13.4,2.6-18.4,7.4l-0.2,0.2l-0.1,0.1c-7.2,7.2-9.6,18.1-6.1,27.7l0,0.1
          l-0.1,0.1l-32,32c-0.2,0.2-0.3,0.4-0.3,0.7L14.3,97.1z M16.7,95.7L18,83.7l0,0l32.2-32.2c0.3-0.3,0.4-0.8,0.2-1.2
          c-3.6-9-1.5-19.2,5.3-26.1c4.6-4.6,10.6-7.1,17.1-7.1s12.5,2.5,17.1,7.1c4.6,4.6,7.1,10.6,7.1,17.1c0,6.4-2.5,12.5-7.1,17.1
          c-6.8,6.8-17.1,8.9-26.1,5.3c-0.4-0.2-0.9-0.1-1.2,0.3L52.2,74.3l0,0L44,75c-0.5,0.1-1,0.5-1,1l-0.7,7.6l-0.1,0l-7.5,0.7
          c-0.5,0.1-1,0.5-1,1L32.9,93l-0.1,0l-16.1,2.8L16.7,95.7z"/>
        <path className="st0" d="M78.3,28c-2.1,0-4,0.8-5.4,2.2l0,0c-1.5,1.5-2.3,3.4-2.3,5.5c0,2.1,0.8,4,2.3,5.5c1.5,1.5,3.4,2.3,5.5,2.3
          c2.1,0,4-0.8,5.5-2.3c1.5-1.5,2.3-3.4,2.3-5.5c0-2.1-0.8-4-2.3-5.5C82.3,28.8,80.4,28,78.3,28z M82.2,39.6L82.2,39.6L82.2,39.6
          c-1.1,1.1-2.4,1.6-3.9,1.6c-1.5,0-2.8-0.6-3.9-1.6c-1-1-1.6-2.4-1.6-3.9c0-1.5,0.6-2.8,1.6-3.8c1-1,2.4-1.6,3.9-1.6
          c1.5,0,2.8,0.6,3.9,1.6c1,1,1.6,2.4,1.6,3.8C83.8,37.2,83.2,38.5,82.2,39.6z"/>
      </g>
    </svg>
  )
}
