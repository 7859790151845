import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { CheckIcon } from '../Icons/CheckIcon'
import { Arrow2Icon } from '../Icons/Arrow2Icon'

const StyledCardWrapper = styled.div`
  border-radius: 5px;
  box-shadow: 0 0 12px rgba(0,0,0,0.2);
  cursor: ${props => (props.withCheckbox || props.cursorPointer) ? 'pointer' : 'default'};
  position: relative;
  overflow: hidden;
  min-height : 75px;

  &.with-checkbox {
    &.active {
      color: inherit;
      box-shadow:0 0 12px rgba(0,0,0,0.2), 0 0 0 1px ${props => props.theme.primaryColor};
    }
  }
  &.invalid {
    box-shadow:0 0 12px rgba(0,0,0,0.2), 0 0 0 1px red;
  }
  .edit {
    position:absolute;
    right: 10px;
    top: 10px;
  }
`

const StyledHeadline = styled.div`
  text-transform: uppercase;
  text-align: right;
  margin-right: 48px;
  padding-top: 12px;
  font-size: 12px;
  letter-spacing: 0.7px;
  font-weight: bold;
  color: ${props => props.theme.primaryColor};

  .with-checkbox & {
    color: inherit;
  }

  .with-checkbox.active & {
    color: ${props => props.theme.primaryColor};
  }
`

const StyledHeadlineText = styled.div`

`

const StyledCheckbox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 38px;
  height: 38px;
  color: #fff;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.active ? props.theme.primaryColor : colors.gray2};
`

const StyledBodyTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`

const StyledBody = styled.div`
  padding: 15px;
`

const StyledLinkIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 55px;
  height: 38px;
  color: #fff;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.primaryColor};
`

const StyledArrow2Icon = styled.span`
  width: 24px;
    height: 24px;
  & ${Arrow2Icon} {
    width: 100%;
    height: 100%;
  }
`

export const StyledCard = (props) => {
  return (
    <StyledCardWrapper
      className={`no-border-radius ${props.active ? 'active' : ''} ${props.invalid ? 'invalid' : ''} ${props.withCheckbox ? 'with-checkbox' : ''}`} {...props}>
      {props.withCheckbox &&
        <StyledCheckbox {...props}>
          {props.active ? <CheckIcon color='#ffffff' /> : ''}
        </StyledCheckbox>
      }
      {props.withLinkIcon &&
        <StyledLinkIcon>
          {/*<ArrowIcon color='#FFF'/>*/}
          <StyledArrow2Icon><Arrow2Icon color='#FFF' /></StyledArrow2Icon>
        </StyledLinkIcon>
      }
      {props.children}
    </StyledCardWrapper>
  )
}

StyledCard.Headline = (props) => {
  return (
    <StyledHeadline {...props}>
      <StyledHeadlineText>{props.children}</StyledHeadlineText>
    </StyledHeadline>
  )
}

StyledCard.Body = (props) => {
  return (
    <StyledBody {...props}>
      {props.children}
    </StyledBody>
  )
}

StyledCard.Body.Title = (props) => {
  return (
    <StyledBodyTitle {...props}>
      {props.children}
    </StyledBodyTitle>
  )
}
