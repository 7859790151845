import apiConfig from './apiConfig'
import axios from 'axios'
import { appStore } from '../../stores/stores'
import i18n from '../../translation/i18n';
let requestCaches = {}

const apiClient = {
  httpClientJson: null,
  httpClientFile: null,
  httpClientJsonNoAuth: null,

  initClients: (accesToken) => {
    apiClient.httpClientJson = axios.create({
      baseURL: apiConfig.APIBaseUrlEndpoints,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accesToken}`,
        'Accept-Language': i18n.language
      }
    })

    apiClient.httpClientFile = axios.create({
      baseURL: apiConfig.APIBaseUrlEndpoints,
      headers: {
        'Accept': 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accesToken}`,
        /* 'Accept-Language': i18n.language */
      }
    })
    apiClient.httpClientJsonNoAuth = axios.create({
      baseURL: apiConfig.APIBaseUrlEndpoints,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language
      }
    })
    requestCaches = {}
  },

  requestJson: (method, ressourceName, params = {}, withAuth = true, returnResponseObject = false, config = null) => {
    const httpClientName = withAuth ? 'httpClientJson' : 'httpClientJsonNoAuth'
    if (method === "post" || method === "put" || method === "patch") {
      //patch/put/post(url,data,config)
      return apiClient[httpClientName][method](ressourceName, params, { ...config })
        .then(response => { return returnResponseObject ? response : response.data })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            appStore.loginStore.logoutRedirecting = true
            appStore.loginStore.logout()
            console.log('Redirecting to Loginpage')
          }
          throw error
        })
    }
    else {
      //delete/get(url, config)
      return apiClient[httpClientName][method](ressourceName, { params, ...config })
        .then(response => { return returnResponseObject ? response : response.data })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            appStore.loginStore.logoutRedirecting = true
            appStore.loginStore.logout()
            console.log('Redirecting to Loginpage')
          }
          throw error
        })
    }
  },

  postFile: (ressourceName, params, files = [], returnResponseObject = false, config = null) => {
    const formData = new FormData()
    Object.entries(params).forEach(([key, value]) => {
      formData.append(key, value)
    })
    for (let i = 0; i < files.length; i++) {
      formData.append(files[i].name, files[i])
    }
    //formData.append('body', file)
    return apiClient['httpClientFile']['post'](ressourceName, formData, { ...config })
      .then(response => { return returnResponseObject ? response : response.data })
      .catch(error => {
        throw error
      })
  },

  getJson: (ressourceName, params = {}, withAuth = true, returnResponseObject = false, noCache = true, config) => {
    const cacheName = JSON.stringify({ ressourceName, params, withAuth, returnResponseObject })
    if (noCache === false && requestCaches[cacheName]) {
      return requestCaches[cacheName]
    }
    requestCaches[cacheName] = apiClient.requestJson('get', ressourceName, params, withAuth, returnResponseObject, config)
    return requestCaches[cacheName]
  },

  deleteJson: (ressourceName, params = {}, withAuth = true, returnResponseObject = false, config) => {
    return apiClient.requestJson('delete', ressourceName, params, withAuth, returnResponseObject, config)
  },

  postJson: (ressourceName, params = {}, withAuth = true, returnResponseObject = false, config) => {
    return apiClient.requestJson('post', ressourceName, params, withAuth, returnResponseObject, config)
  },

  patchJson: (ressourceName, params = {}, withAuth = true, returnResponseObject = false, config) => {
    return apiClient.requestJson('patch', ressourceName, params, withAuth, returnResponseObject, config)
  },

  putJson: (ressourceName, params = {}, withAuth = true, returnResponseObject = false, config) => {
    return apiClient.requestJson('put', ressourceName, params, withAuth, returnResponseObject, config)
  }
}


export default apiClient
