import React, { useCallback, useEffect, useState } from 'react'
import { CloseButton, Col, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ReportTypeSelect from './ReportTypeSelect'
import { useReportsJobsState } from '../../stores/Administration/ReportJobsProvider'
import CustomDatePicker, { StyledDatepickerWrapper } from '../Forms/CustomDatePicker'
import i18n from '../../translation/i18n'
import { getIsoDate } from '../../utils/getIsoDate'

import ArraySelect from '../Select/ArraySelect'

const StyledFilterWrapper = styled.div`
 max-width: 710px;
 margin: 0 auto;

 .form-label {
   padding-right: 10px;
   display: flex;
   justify-content: space-between;

   button.close {
    outline: none;
   }
 }

 ${StyledDatepickerWrapper} {
  max-width: calc(100% / 2 - 5px);
  position: relative;

  &:before {
    position: absolute;
    top: -15px;
    left: 8px;
    font-size: 12px;
  }
  &:first-of-type {
    margin-right: 10px;
    &:before {
      content: '${() => i18n.t('from')}';
    }
  }
  &:last-of-type{
    &:before {
     content: '${() => i18n.t('to')}';
    }
  }
 }
`
const ReportsTableFilter = () => {
  const { t } = useTranslation()
  const [reportType, setReportType] = useState('')
  const [createdBy, setCreatedBy] = useState('')
  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')

  const { setFilter } = useReportsJobsState()

  const handleChangeReportType = useCallback(value => {
    setReportType(value)
  }, [])

  const handleChangeCreatedBy = useCallback(e => {
    setCreatedBy(e.target.value)
  }, [])

  const handleChangeDate = useCallback(setDate => date => {
    setDate(date)
  }, [])

  const resetDates = useCallback(() => {
    setDateFrom('')
    setDateTo('')
  }, [])

  useEffect(() => {
    setFilter(prev => ({ ...prev, 'filter.type': reportType }))
  }, [setFilter, reportType])

  useEffect(() => {
    setFilter(prev => ({ ...prev, 'filter.createdBy': createdBy }))
  }, [setFilter, createdBy])

  useEffect(() => {
    setFilter(prev => ({ ...prev, 'filter.startDate': getIsoDate(dateFrom, false) }))
  }, [setFilter, dateFrom])

  useEffect(() => {
    setFilter(prev => ({ ...prev, 'filter.endDate': getIsoDate(dateTo, true) }))
  }, [setFilter, dateTo])

  return (
    <StyledFilterWrapper>
      <Row>
        <Form.Group className="mb-3" as={Col} sm="3">
          <ReportTypeSelect value={reportType} onChange={handleChangeReportType} withResetButton={reportType !== ''} />
        </Form.Group>
        <Form.Group className="mb-3" as={Col} sm="3">
          <Form.Label><span>{t('reports:createdBy')}</span>{!!createdBy && <CloseButton onClick={() => setCreatedBy("")} />}</Form.Label>
          <ArraySelect
            defaultOption={{ value: '', name: t('anyone') }}
            options={[{ id: 'own', displayName: t('own') }]}
            value={createdBy}
            onChange={handleChangeCreatedBy}
            returnEvent
            noAutoSelect
            defaultOptionIsSelectable
          />
        </Form.Group>
        <Form.Group className="mb-3" as={Col}>
          <Form.Label><span>{t('reports:createdFrom')}</span>{(!!dateFrom || !!dateTo) && <CloseButton onClick={resetDates} />}</Form.Label>
          <CustomDatePicker
            dateFormat="dd.MM.yyyy"
            placeholderText={t('date')}
            onChange={handleChangeDate(setDateFrom)}
            showYearDropdown
            selected={dateFrom}
          />

          <CustomDatePicker
            dateFormat="dd.MM.yyyy"
            placeholderText={t('date')}
            onChange={handleChangeDate(setDateTo)}
            showYearDropdown
            selected={dateTo}
          />
        </Form.Group>
      </Row>
    </StyledFilterWrapper>
  )
}

export default ReportsTableFilter
