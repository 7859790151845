import React from 'react'
import { StyledItemWrapper, StyledListItemText } from '../../TreeList/treeList'
import { PenIcon } from '../../Icons/PenIcon'
import { DeleteIcon } from '../../Icons/DeleteIcon'
import { t } from 'i18next'
import { StyledContextMenu } from '../../ContextMenu/StyledContextMenu'
import { ContextMenuTrigger } from 'react-contextmenu'
import PortalContextMenu from '../../ContextMenu/PortalContextMenu'
import MediaQuery from 'react-responsive'
import MoreOptionsButton from '../../Buttons/MoreOptionsButton'

const EntitlementPhaseItem = ({ entitlementPhase, editRow, setDeleteEntitlementPhaseId }) => {
  const options = [
    {
      option: 'edit',
      optionText: t('edit'),
      optionIcon: <PenIcon />,
      onOptionSelect: () => editRow(entitlementPhase.id)
    },
    {
      option: 'delete',
      optionText: t('deleteButton'),
      optionIcon: <DeleteIcon />,
      onOptionSelect: () => setDeleteEntitlementPhaseId(entitlementPhase.id)
    }
  ]
  return (
    <StyledContextMenu>
      <ContextMenuTrigger id={`${entitlementPhase.id}_context`} holdToDisplay={-1}>
        <StyledItemWrapper >
          <StyledListItemText>
            {entitlementPhase.displayName}
          </StyledListItemText>
          <MediaQuery maxWidth={1499}>
            <div className="action-buttons">
              <MoreOptionsButton options={options} />
            </div>
          </MediaQuery>
        </StyledItemWrapper>
      </ContextMenuTrigger>
      <PortalContextMenu contextId={`${entitlementPhase.id}_context`} options={options} />
    </StyledContextMenu>

  )
}

export default EntitlementPhaseItem