import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { t } from 'i18next';
import { useEntitlementPhasesState } from '../../../stores/Configuration/EntitlementPhasesProvider'
import ModalComponent2 from '../../Modals/ModalComponent2';
import useMountEffect from '../../../utils/hooks/useMountEffect';
import EntitlementPhaseItem from './EntitlementPhaseItem';

const StyledWrapper = styled.div`
  @media screen and (min-width: 1080px) {
    max-width: calc(100% - 480px);
    min-width: 600px;
  }
`


const EntitlementPhasesOverview = () => {
  const { entitlementPhases, getEntitlementPhases, deleteEntitlementPhase, loadingStates, errorStates, setSuccessStates, deleteEntitlementPhaseId, setDeleteEntitlementPhaseId } = useEntitlementPhasesState()
  const navigate = useNavigate()

  useMountEffect(() => {
    getEntitlementPhases()
  })

  const editRow = (entitlementPhaseId) => {
    let path = `/entitlementPhasesAdministration/edit/${entitlementPhaseId}`
    navigate(path)
  }

  return (
    <>
      <StyledWrapper>
        {(loadingStates.entitlementPhases && !entitlementPhases.length)
          ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
          : entitlementPhases.map(entitlementPhase => (
            <EntitlementPhaseItem
              key={entitlementPhase.id}
              entitlementPhase={entitlementPhase}
              editRow={editRow}
              setDeleteEntitlementPhaseId={setDeleteEntitlementPhaseId}
            />
          ))}
      </StyledWrapper>
      {deleteEntitlementPhaseId && <ModalComponent2
        ModalTitle={t('entitlementPhases:deleteEntitlementPhase')}
        BodyPart1={t('entitlementPhases:deleteBody')}
        button1Function={() => deleteEntitlementPhase(deleteEntitlementPhaseId)}
        button1Text={t('delete')}
        button1Loading={loadingStates.deleteEntitlementPhase}
        button1Variant="danger"
        bodyStyle="alert alert-danger"
        functionCancel={() => { setDeleteEntitlementPhaseId(''); setSuccessStates({}) }}
        error={errorStates?.deleteEntitlementPhase?.value}
      />}
    </>
  )
}

export default EntitlementPhasesOverview