import React, { useCallback, useState } from 'react'
import { Button, Alert } from 'react-bootstrap'
import styled from 'styled-components'
import { useEmployeeHealthDataState } from '../../../stores/EmployeeHealthDataProvider'
import { ButtonWithLoadingState } from '../../Buttons'
import { useTranslation, Trans } from 'react-i18next'

const StyledContainer = styled.div`
  @media screen and (min-width: 1081px) {
    position: sticky;
    top: 50px;
  }
`

const StyledCancelButton = styled(Button)`
  width: 100%;
  max-width: 250px;
`

const Sidebar = () => {
  const {
    error,
    apiError,
    currentQuarantineStatusHasChanged,
    checkUpsDataHasChanged,
    nextTestsDataHasChanged,
    resetFormData,
    submit
  } = useEmployeeHealthDataState()

  const [successSubmit, setSuccessSubmit] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const handleSubmit = useCallback(async e => {
    setSuccessSubmit(false)
    setIsSaving(true)

    const result = await submit()
    let timeout = null
    if (result) {
      setSuccessSubmit(true)
      timeout = setTimeout(() => { setSuccessSubmit(false) }, 3000)
    }
    setIsSaving(false)

    return () => {
      clearTimeout(timeout)
    }
  }, [submit, setSuccessSubmit, setIsSaving])

  const handleClickCancel = useCallback(() => {
    resetFormData()
  }, [resetFormData])

  const { t } = useTranslation()

  return (
    <StyledContainer>
      <header>
        <div className="h2">{t('EmployeeFormHealthData:sidebarTitle')}</div>
      </header>
      <div className="content">
        <ButtonWithLoadingState
          className="submit-btn mb-3"
          isLoading={isSaving}
          onClick={handleSubmit}
          disabled={isSaving}
          buttonText={t('OverlayProgress:save')}
          loadingText={t('OverlayProgress:save')} />

        {(currentQuarantineStatusHasChanged || checkUpsDataHasChanged || nextTestsDataHasChanged) && <StyledCancelButton className="mb-3" variant="secondary" onClick={handleClickCancel} >{t('cancelButton')}</StyledCancelButton>}
        {(currentQuarantineStatusHasChanged || nextTestsDataHasChanged || checkUpsDataHasChanged) && (
          <div className=" alert alert-info" >{t('EmployeeFormHealthData:dataChange')}</div>
        )}
        {
          apiError && apiError.map((err, index) => (
            <Alert key={index} variant="danger">
              {err}
            </Alert>
          ))}
        {!!error && (
          <Alert variant="danger">
            <Trans i18nKey='EmployeeFormSidebar:errorOccurred' />
          </Alert>
        )}

        {!!successSubmit && (
          <Alert variant="success">
            {t('EmployeeFormSidebar:dataSaved')}
          </Alert>
        )}


      </div>
    </StyledContainer>
  )
}

export default Sidebar
