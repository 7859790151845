import React from 'react'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import { Col, Form, Row } from 'react-bootstrap'
import { StyledFormHeadline, StyledValidationMessage } from './RegistrationStyles'
import ArraySelect from '../Select/ArraySelect'
import ZipSuggestion from '../Forms/ZipSuggestion'
import { getFakeEvent } from '../../utils/utils'

const AddressData = inject('registrationStore', 'uiStore')(observer((props) => {
  const { registrationStore, registrationConfig } = props
  const address = registrationStore.registrationData.address
  return (
    <>
      <StyledFormHeadline>{registrationConfig.addressData.headline}</StyledFormHeadline>
      <Row>
        <Form.Group className="mb-3" as={Col} controlId="formStreet">
          <Form.Label>{t('formularFields:street')} *</Form.Label>
          <Form.Control
            value={address.street}
            type="text"
            placeholder={t('formularFields:street')}
            name="address.street"
            onChange={registrationStore.handleChange2}
            isInvalid={registrationStore.formErrors.addressstreet}
          />
          <StyledValidationMessage type="invalid">
            {registrationStore.formErrors.addressstreet}
          </StyledValidationMessage>
        </Form.Group>
        <Form.Group className="mb-3" as={Col} md="4" controlId="formStreetNr">
          <Form.Label>{t('formularFields:housenumber')} *</Form.Label>
          <Form.Control
            value={address.streetNumber}
            type="text"
            placeholder={t('formularFields:housenumber')}
            name="address.streetNumber"
            onChange={registrationStore.handleChange2}
            isInvalid={registrationStore.formErrors.addressstreetNumber}
          />
          <StyledValidationMessage type="invalid">
            {registrationStore.formErrors.addressstreetNumber}
          </StyledValidationMessage>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className="mb-3" as={Col} md="4" controlId="formZip">
          <Form.Label>{t('formularFields:zip')} *</Form.Label>
          <ZipSuggestion
            type="text"
            placeholder={t('EmployeeFormAddress:postcode')}
            name="address.zIP"
            onChange={registrationStore.handleChange2}
            value={address.zIP}
            onChangeSuggestion={obj => registrationStore.handleChange2(getFakeEvent("address.town", obj.cityName, "text"))}
            countryIsoCode={address.country.isoCode || "de"}
            isInvalid={registrationStore.formErrors.addresszIP}
          >
            <StyledValidationMessage type="invalid" >
              {registrationStore.formErrors.addresszIP}
            </StyledValidationMessage>
          </ZipSuggestion>
        </Form.Group>
        <Form.Group className="mb-3" as={Col} controlId="formCity">
          <Form.Label>{t('formularFields:city')} *</Form.Label>
          <Form.Control
            value={address.town}
            type="text"
            placeholder={t('formularFields:city')}
            name="address.town"
            onChange={registrationStore.handleChange2}
            isInvalid={registrationStore.formErrors.addresstown}
          />
          <StyledValidationMessage type="invalid">
            {registrationStore.formErrors.addresstown}
          </StyledValidationMessage>
        </Form.Group>
      </Row>
      <Form.Group className="mb-3" controlId="formCountry">
        <Form.Label>{t('formularFields:country')} *</Form.Label>
        <ArraySelect
          label={`${t('formularFields:country')}* `}
          options={registrationStore.countries}
          value={address.country.id || ""}
          onChange={registrationStore.handleChangeAddressCountry}
          isInvalid={registrationStore.formErrors.addresscountryid}
          defaultOption={{ value: "", name: t('pleaseSelect') }}
        />
        <StyledValidationMessage type="invalid">
          {registrationStore.formErrors.addresscountryid}
        </StyledValidationMessage>
      </Form.Group>
    </>
  )
}))

export default AddressData