import React from 'react'
import { inject, observer } from 'mobx-react'
import { Form } from 'react-bootstrap'
import { StyledFormHeadline, StyledValidationMessage } from './RegistrationStyles'
import { resolveObj } from '../../utils/utils'

const Additional = inject('registrationStore', 'uiStore')(observer((props) => {
  const { registrationStore, registrationConfig } = props
  return (
    <>
      <StyledFormHeadline>{registrationConfig.additional.headline}</StyledFormHeadline>
      {Object.values(registrationConfig.additional.additionalForms).map(form =>
        <Form.Group className="mb-3" key={form.name}>
          <Form.Label>{form.label}</Form.Label>
          <Form.Control
            min={form.min || null}
            value={resolveObj(form.name, registrationStore.registrationData)}
            type={form.type}
            name={form.name}
            placeholder={form.placeholder}
            onChange={registrationStore.handleChange2}
            isInvalid={registrationStore.formErrors[form.errorId]}
          />
          <StyledValidationMessage type="invalid">
            {registrationStore.formErrors[form.errorId]}
          </StyledValidationMessage>
        </Form.Group>
      )}

    </>
  )
}))

export default Additional
