import React from 'react'
import { Subheader } from '../../Page/logix2020/Subheader'
import SubheaderForm from '../../Page/logix2020/SubheaderForm'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { MdLibraryAdd } from 'react-icons/md'


const EditSubheader = () => {
    const { t } = useTranslation()
    const { employeeId } = useParams()

    return (
        <Subheader className="subHeader-v1">
            <div className="flex-grow-1">
                <SubheaderForm
                    title={'Termine bearbeiten'}
                    goBackLabel={t('backButton')}
                />
            </div>
            <Link className="subheader__button btn btn-primary btn-extra-padding " to={`/appointment/${employeeId}`}><MdLibraryAdd className="me-3" />{t('appointment:newAppointment')}</Link>
        </Subheader>
    )
}

export default EditSubheader
