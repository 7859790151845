import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { makeCall, ErrorAlerts } from '../../../utils/api/makeCall';
import apiClient from '../../../utils/api/apiClient';
import { APIEndpoints } from '../../../utils/api/apiConfig';
import { resolveObj, setValueByKey } from '../../../utils/utils';
import { cloneDeep } from 'lodash';
import CustomDropdown from '../../Dropdowns/CustomDropdown';
import i18n from '../../../translation/i18n';
import { emptyGuid } from '../../../utils/emptyGuid';
import { ButtonWithLoadingState } from '../../Buttons';
import EditorModalSwitches from './EditorModalSwitches';
import EditorModalEditors from './EditorModalEditors';
import EditorModalHeaderButtons from './EditorModalHeaderButtons';

const newsTemplate = {
  news: {
    header: {
      "values": [],
      "id": 0
    },
    body:
    {
      "values": [],
      "id": 0
    },
    isVisibleNews: true,
    id: emptyGuid
  },
  assignedMandators: null
};

const EditorModal = ({ handleClose, newsId, languages, getNews }) => {
  const [switchStates, setSwitchStates] = useState({ isVisibleNews: newsTemplate.news.isVisibleNews })
  const [newsData, setNewsData] = useState(newsTemplate)
  const [errorStates, setErrorStates] = useState({})
  const [loadingStates, setLoadingStates] = useState({})
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language.toUpperCase())
  const [isFullScreen, setFullScreen] = useState(false);
  const handleFullScreen = () => {
    setFullScreen(!isFullScreen);
  };
  const findBodyData = (newsData, selectedLanguage) => (
    (newsData.news.body.values.find(obj => obj.langIsoCode === selectedLanguage) || { value: "" }).value
  )
  const currentBody = useMemo(() => findBodyData(newsData, selectedLanguage), [newsData, selectedLanguage])
  const currentHeader = useMemo(() => (newsData.news.header.values.find(obj => obj.langIsoCode === selectedLanguage) || { value: "" }).value, [newsData, selectedLanguage])

  const changeSwitch = (e) => {
    let { checked, id } = e.target
    setSwitchStates(prev => {
      let newStates = { ...prev }
      newStates[id] = checked
      return newStates
    })
  }

  const loadNews = useCallback(async () => {
    await makeCall('loadNews', async () => {
      let loadedNews = await apiClient.getJson(APIEndpoints.feedsAdministration(newsId).edit)
      setNewsData(loadedNews)
      let { isCollapsed, isVisibleNews, creationDate_IsVisible, creatorName_IsVisible, } = loadedNews.news
      setSwitchStates({ isCollapsed, isVisibleNews, creationDate_IsVisible, creatorName_IsVisible })
    }, setErrorStates, setLoadingStates)
  }, [newsId])

  useEffect(() => {
    if (newsId) {
      loadNews()
    }
  }, [loadNews, newsId])

  const getParams = () => {
    let newNewsData = { ...newsData }
    newNewsData.news = { ...newNewsData.news, ...switchStates }
    return newNewsData
  }

  const handleSave = () => {
    let params = getParams()

    makeCall('saveNews', async () => {
      newsId
        ? await apiClient.patchJson(APIEndpoints.feedsAdministration(newsId).edit, params)
        : await apiClient.postJson(APIEndpoints.feedsAdministration().feeds, params)
      await getNews(true)
      handleClose()
    }, setErrorStates, setLoadingStates)

  }

  const changeLanguageText = e => {
    let { name, value } = e.target
    let clonedNewsData = cloneDeep(newsData)
    let newValues = resolveObj(name, clonedNewsData)
    let findIndex = newValues.findIndex(obj => obj.langIsoCode === selectedLanguage)
    if (findIndex >= 0) {
      newValues[findIndex].value = value
    }
    else {
      newValues.push({ langIsoCode: selectedLanguage, value: value })
    }
    let updatedObj = setValueByKey(name, newValues, clonedNewsData)
    setNewsData(updatedObj)
  }

  return (
    <Modal size="lg" show={true} onHide={handleClose} fullscreen={isFullScreen} enforceFocus={false} scrollable>
      <Modal.Header >
        <Modal.Title>{t('newsAdmin:editNews')}</Modal.Title>
        <EditorModalHeaderButtons
          isFullScreen={isFullScreen}
          handleFullScreen={handleFullScreen}
          handleClose={handleClose}
        />
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <CustomDropdown
            value={selectedLanguage}
            data={languages}
            returnObject={e => setSelectedLanguage(e.value)}
          />
        </Row>
        <EditorModalSwitches
          switchStates={switchStates}
          changeSwitch={changeSwitch}
        />
        {loadingStates.loadNews
          ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
          : <>
            <Form.Group className="mb-3">
              <Form.Label>{t('newsAdmin:header')}</Form.Label>
              <Form.Control
                placeholder=""
                value={currentHeader || ""}
                onChange={changeLanguageText}
                name="news.header.values"
                isInvalid={errorStates?.saveNews?.formErrors?.header}
              />
            </Form.Group>
            <EditorModalEditors
              newsId={newsId}
              currentBody={currentBody}
              changeLanguageText={changeLanguageText}
              setErrorStates={setErrorStates}
              loadingStates={loadingStates}
              setLoadingStates={setLoadingStates}
              selectedLanguage={selectedLanguage}
              errorStates={errorStates}
            />
          </>
        }
        <ErrorAlerts errorStates={errorStates} />
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoadingState
          buttonText={t('saveButton')}
          isLoading={loadingStates.saveNews}
          onClick={handleSave}
        />
        <Button variant="secondary" onClick={handleClose}>
          {t("closeButton")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditorModal