import React from 'react'

export const FolderOpenIconArrow = (props) => {
    const color = props.color ? props.color : '#D0CFD0'

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="folder-icon-svg" viewBox="0 0 28 28" width="30px" height="30px">
            <style type="text/css">
                {`
          .folder-icon-svg {
            fill:${color};
          }
        `}
            </style>
            <g>
                <g id="svg_1">
                    <g id="svg_2">
                        <path id="svg_3" d="m12.3,10l-2.1,0l2.1,-2.1l0,2.1z" clipRule="evenodd" fillRule="evenodd" />
                    </g>
                    <path id="svg_4" clipRule="evenodd" fillRule="evenodd" d="m21.7,12c0.6,0 1,0.4 1,1l0,0.2l-1.5,6c-0.1,0.4 -0.5,0.8 -1,0.8l-12.4,0c-0.5,0 -0.9,-0.3 -1,-0.8l-1.5,-6c-0.1,-0.5 0.2,-1 0.7,-1.2l15.7,0zm-11.6,-5c0.7,0 1.4,0.2 2.1,0.5s1.3,0.5 2,0.5l4.8,0c0.6,0 1,0.4 1,1l0,2l-12,0l0,-3c0,-0.6 0.4,-1 1,-1l1.1,0z" />
                </g>
                <path transform="rotate(10.8839 14.5235 16.4428)" id="svg_7" d="m13.30273,19.34613c-0.28771,-0.16147 -0.24996,-0.25924 0.44955,-1.16436c0.35322,-0.45704 0.64221,-0.84426 0.64221,-0.86049c0,-0.01623 -0.97536,-0.02951 -2.16747,-0.02951l-2.16747,0l0,-0.85598l0,-0.85598l2.18465,0c1.68449,0 2.16584,-0.02579 2.10252,-0.11263c-0.04517,-0.06195 -0.3533,-0.47301 -0.68473,-0.91347c-0.62475,-0.83029 -0.62455,-1.05295 0.00097,-1.12197c0.3113,-0.03435 5.15784,2.60464 5.32442,2.8992c-1.61434,1.05174 -3.54402,2.15265 -5.32158,3.12333c-0.09373,0 -0.25711,-0.04866 -0.36307,-0.10812l0,0z" fill="#fff" />
            </g>
        </svg>
    )
}