import React from 'react'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import { Form } from 'react-bootstrap'
import { StyledFormHeadline } from './RegistrationStyles'

const GenderData = inject('registrationStore', 'uiStore')(observer((props) => {
  const { registrationStore, registrationConfig } = props
  return (
    <>
      <Form.Group className="mb-3">
        <StyledFormHeadline>{registrationConfig.genderData.headline}</StyledFormHeadline>
        <Form.Check
          onChange={registrationStore.setCutMale}
          checked={registrationStore.registrationData.frontendData.cutMale}
          id='check_0'
          className="text-white"
          isInvalid={registrationStore.formErrors.employeegender}
          label={<b className="text-white">{t('male')} *</b>}
        />
        <Form.Check
          onChange={registrationStore.setCutFemale}
          checked={registrationStore.registrationData.frontendData.cutFemale}
          id='check_1'
          className="text-white"
          isInvalid={registrationStore.formErrors.employeegender}
          label={<b className="text-white">{t('female')} *</b>}
        />
      </Form.Group>
    </>
  )
}))

export default GenderData
