import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'

import { useEntitlementPhasesState } from '../../stores/Configuration/EntitlementPhasesProvider'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import SubheaderTitle from '../../components/Page/logix2020/SubheaderTitle'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import BigFooter from '../../components/Footer/BigFooter'
import EntitlementPhaseGeneralForm from '../../components/Configuration/EntitlementPhase/EntitlementPhaseGeneralForm'
import useMountEffect from '../../utils/hooks/useMountEffect'


const EditEntitlementPhasePage = () => {
  const { getEntitlementPhase, successStates, setSuccessStates, resetEntitlementPhasesPage } = useEntitlementPhasesState()
  const { entitlementPhaseId } = useParams()

  useMountEffect(() => {
    resetEntitlementPhasesPage()
    getEntitlementPhase(entitlementPhaseId)
  })

  useEffect(() => {
    let timer = null
    if (successStates.saveEntitlementPhase) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        setSuccessStates({})
      }, 3000)
    }
    return () => clearTimeout(timer)
  }, [setSuccessStates, successStates.saveEntitlementPhase])


  return (
    <div>
      <Subheader className="subHeader-v1">
        <SubheaderTitle
          withBackButton
          smallTitle={t('entitlementPhases:editEntitlementPhase')}
          goBackLabel={t('entitlementPhases:entitlementPhasesOverview')}
          defaultBackTo="/entitlementPhasesAdministration"
        />
      </Subheader>
      <ContentArea>
        <EntitlementPhaseGeneralForm entitlementPhaseId={entitlementPhaseId} />
      </ContentArea>
      <BigFooter />
    </div>
  )
}

export default EditEntitlementPhasePage