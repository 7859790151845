import React, { Suspense, lazy } from 'react'

const mandate = process.env.REACT_APP_MANDATORSET

const allowBigFooter = ["cws", "logix_demo", "kliniken", "sixt", "wf", "edf", "wisag"]

const lazyImportFooter = () => {
    if (allowBigFooter.includes(mandate)) return import(`../../components_overrides/cws/Footer/BigFooter`)
    else return import(`../../components_overrides/EmptyComponent`)
};
const ImportFooter = lazy(lazyImportFooter);

const BigFooter = () => {
    return (
        <Suspense fallback={<></>}>
            <ImportFooter />
        </Suspense>
    )
}

export default BigFooter
