import React, { useCallback, useEffect, useState } from 'react'
import OfficeSelect from '../../Employees/Form/FormComponents/OfficeSelect'

import { useTranslation } from 'react-i18next'

const CreateOfficeSelect = ({ setFormData, name, value, includeDeleted, errorMessage, isInvalid }) => {
    const { t } = useTranslation()
    const [selectedOffice, setSelectedOffice] = useState()
    const handleChangeOffice = useCallback(officeId => {
        setSelectedOffice(officeId)
    }, [])

    useEffect(() => {
        setFormData(prev => {
            const newValues = { ...prev }
            if (!selectedOffice) {
                delete newValues[name]
            } else {
                newValues[name] = selectedOffice
            }
            return newValues
        })
    }, [setFormData, selectedOffice, name])

    return (
        <OfficeSelect id="createOfficeSelectOfficeTree"
            value={value}
            onChange={handleChangeOffice}
            label={t('orgaUnit')}
            withSearch
            includeDeleted={includeDeleted}
            showTopLevel
            errorMessage={errorMessage}
            isInvalid={isInvalid}
        />
    )
}

export default CreateOfficeSelect
