import React, { useCallback, useEffect, useRef, useState } from 'react'
import ModalComponent2 from '../../Modals/ModalComponent2'
import { t } from 'i18next'
import DigitalSignature from '../../DigitalSignature/DigitalSignature'
import { Alert, Button, Spinner } from 'react-bootstrap'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import styled from 'styled-components'
import FileUpload from './FileUpload'


const StyledLink = styled.span`
  color: ${props => props.theme.primaryColor};
  cursor: pointer;
  font-size:large;
  &.active{
    font-weight:600;
  }
`
const SignatureModal = ({ setOpenSignatureModal, shippingOrderId, resetOrderPage }) => {
  const [digitalSignature, setDigitalSignature] = useState('')
  const [oldDigitalSignature, setOldDigitalSignature] = useState(null)
  const [success, setSuccess] = useState(false)
  const [getSignatureLoading, setGetSignatureLoading] = useState(true)
  const [postSignatureLoading, setPostSignatureLoading] = useState(false)
  const [useFileUpload, setUseFileUpload] = useState(false)

  const sigRef = useRef()
  const handleClickReset = useCallback(() => {
    sigRef.current.clear()
    setDigitalSignature('')
  }, [setDigitalSignature])

  const getOldSignature = useCallback(async () => {
    try {
      let signature = await apiClient.getJson(APIEndpoints.shippingOrders(shippingOrderId).getSignature)
      setOldDigitalSignature(signature)
    }
    finally {
      setGetSignatureLoading(false)
    }
  }, [shippingOrderId])

  useEffect(() => {
    getOldSignature()
  }, [getOldSignature])



  async function postNewSignature() {
    setPostSignatureLoading(true)
    try {
      await apiClient.patchJson(APIEndpoints.shippingOrders(shippingOrderId).setSignature, { signature: digitalSignature });
      setSuccess(true)
    }
    finally {
      setOldDigitalSignature(digitalSignature)
      setPostSignatureLoading(false)
    }
  }
  function onCancel() {
    if (success) {
      resetOrderPage()
    }
    setOpenSignatureModal(false)
  }
  return (
    <ModalComponent2
      size="xl"
      ModalTitle={t('order:goodsReceived')}
      functionCancel={onCancel}
      button1Text={oldDigitalSignature || getSignatureLoading ? null : t('submitButton')}
      button1Function={() => postNewSignature()}
      button1Loading={postSignatureLoading}
      button1Disabled={!!!digitalSignature}
      closeButtonText={oldDigitalSignature ? t('closeButton') : t('appointment:cancel')}
    >
      <>
        {
          getSignatureLoading
            ? <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
            : oldDigitalSignature
              ? <div>
                <img src={oldDigitalSignature} alt="" style={{ maxWidth: '100%' }} />
              </div>
              :
              <>
                <div className="m-2">
                  <StyledLink
                    className={useFileUpload ? null : "active"}
                    onClick={() => { setDigitalSignature(''); setUseFileUpload(false) }}>
                    {t('order:sign')}
                  </StyledLink> | <StyledLink
                    className={useFileUpload ? "active" : null}
                    onClick={() => { handleClickReset(); setUseFileUpload(true) }}>
                    {t('order:uploadSignature')}
                  </StyledLink>
                </div>
                {useFileUpload
                  ? <FileUpload setDigitalSignature={setDigitalSignature} />
                  : <>
                    <DigitalSignature sigRef={sigRef} digitalSignature={digitalSignature} setDigitalSignature={setDigitalSignature} />
                    <Button type="button" variant="secondary" onClick={handleClickReset} disabled={!!!digitalSignature}> {t('order:reset')}</Button>
                  </>}
              </>
        }
        {success && <Alert variant="success">{t('registrationBox:savingSuccess')}</Alert>}
      </>

    </ModalComponent2>
  )
}

export default SignatureModal