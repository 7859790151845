import { action, observable, makeObservable } from 'mobx';
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'

class CwsStore {
    menuItems = null;
    menuTitle = null;

    loadMenuItemsIsLoading = false;
    loadFooterItemsIsLoading = false;
    loadContactItemsIsLoading = false;

    copyright = null;
    dataProtectionUrl = null;
    agbUrl = null;
    imprintUrl = null;
    additionalSentense = null;
    websiteName = null;
    websiteLink = null;

    email = null;
    phone = null;
    orderFor = null;
    workingWeekHours = null;
    workingWeekendHours = null;





    footerItemsLoaded = false;
    contactItemsLoaded = false;

    constructor() {
        makeObservable(this, {
            menuItems: observable,
            menuTitle: observable,
            loadMenuItemsIsLoading: observable,
            loadFooterItemsIsLoading: observable,
            loadContactItemsIsLoading: observable,
            copyright: observable,
            dataProtectionUrl: observable,
            agbUrl: observable,
            imprintUrl: observable,
            additionalSentense: observable,
            websiteName: observable,
            websiteLink: observable,
            email: observable,
            phone: observable,
            orderFor: observable,
            workingWeekHours: observable,
            workingWeekendHours: observable,
            footerItemsLoaded: observable,
            contactItemsLoaded: observable,
            loadMenuItems: action,
            loadFooterItems: action,
            loadContactItems: action,
            changeMenuItems: action,
            changeMenuTitle: action
        });
    }
    changeMenuItems(menuItems) {
        this.menuItems = menuItems
    }
    changeMenuTitle(menuTitle) {
        this.menuTitle = menuTitle
    }
    async loadMenuItems(force = false) {
        if (this.menuItems && !force) {
            return this.menuItems
        }
        if (!this.loadMenuItemsIsLoading) {
            this.loadMenuItemsIsLoading = true
            const menu = await apiClient.getJson(APIEndpoints.customHtmlMenu)
            if (menu) {
                this.changeMenuItems(menu.menuItems)
                this.changeMenuTitle(menu.title)
            }
            this.loadMenuItemsIsLoading = false
            return this.menuItems
        }
    }
    async loadFooterItems(force = false) {
        if (this.footerItemsLoaded && !force) {
            return
        }
        if (!this.loadFooterItemsIsLoading) {
            this.loadFooterItemsIsLoading = true
            const footerItems = await apiClient.getJson(APIEndpoints.customFooter)
            if (footerItems) {
                this.copyright = footerItems && (footerItems.find(item => item.name === "CSM_Footer_Copyright") || { value: "" }).value
                this.dataProtectionUrl = footerItems && (footerItems.find(item => item.name === "CSM_Footer_DataProtectionUrl") || { value: "" }).value
                this.agbUrl = footerItems && (footerItems.find(item => item.name === "CSM_Footer_AgbUrl") || { value: "" }).value
                this.imprintUrl = footerItems && (footerItems.find(item => item.name === "CSM_Footer_ImprintUrl") || { value: "" }).value
                this.additionalSentense = footerItems && (footerItems.find(item => item.name === "CSM_Footer_TaxSentence") || { value: "" }).value

            }
            this.loadFooterItemsIsLoading = false
            this.footerItemsLoaded = true
        }
    }
    async loadContactItems(force = false) {
        if (this.contactItemsLoaded && !force) {
            return
        }
        if (!this.loadContactItemsIsLoading) {
            this.loadContactItemsIsLoading = true
            const contactItems = await apiClient.getJson(APIEndpoints.customContact)
            if (contactItems) {
                this.email = contactItems && (contactItems.find(item => item.name === "CSM_Contact_Email") || { value: "" }).value
                this.phone = contactItems && (contactItems.find(item => item.name === "CSM_Contact_Phone") || { value: "" }).value
                this.orderFor = contactItems && (contactItems.find(item => item.name === "CSM_Contact_OrderFor") || { value: "" }).value
                this.websiteName = contactItems && (contactItems.find(item => item.name === "CSM_Contact_WebsiteName") || { value: "" }).value
                this.websiteLink = contactItems && (contactItems.find(item => item.name === "CSM_Contact_WebsiteLink") || { value: "" }).value
                this.workingWeekHours = contactItems && (contactItems.find(item => item.name === "CSM_Contact_WorkingWeekHours") || { value: "" }).value
                this.workingWeekendHours = contactItems && (contactItems.find(item => item.name === "CSM_Contact_WorkingWeekendHours") || { value: "" }).value

            }
            this.loadContactItemsIsLoading = false
            this.contactItemsLoaded = true
        }
    }
}

export default CwsStore
