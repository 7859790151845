import React, { useState, useEffect } from 'react'
import OverlayRight, { StyledOverlayInnerWrapper } from '../../Page/logix2020/OverlayRight'
import OverlayContainer from '../../Page/logix2020/OverlayContainer'
import { useTranslation } from 'react-i18next'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import apiClient from '../../../utils/api/apiClient'
import { StyledDataList } from '../../DataList/dataList'
import { Spinner, Form } from 'react-bootstrap'
import { PenIcon } from '../../Icons/PenIcon'
import { Link } from 'react-router-dom'
import ArraySelect from '../../Select/ArraySelect'
import useStores from '../../../stores/useStore'
import { getLanguageValue } from '../../../utils/getLanguageValue'

const CostCenterDetails = ({ selectedCostCenter, openDeleteModal, openCopyModal, actionSelect }) => {
    const { t } = useTranslation()
    const [costCenterDetail, setCostCenterDetails] = useState("");
    const [languageComment, setLanguageComment] = useState("")
    const [languageName, setLanguageName] = useState("")
    const [groups, setGroups] = useState("")
    const [checkedInterfaces, setCheckedInterfaces] = useState(null);
    const { costCenterStore } = useStores()

    useEffect(() => {
        // if (selectedCostCenter) {
        costCenterStore.loadPartialCostCenters()
            .then(data => {
                // let jsData = toJS(data);
                let checkedInterfacesArray = data.filter(item => item.isBlockedForDeletionOrReactivation);
                checkedInterfacesArray && setCheckedInterfaces(checkedInterfacesArray);
            })
        // }
    }, [costCenterStore])

    useEffect(() => {
        setCostCenterDetails("")
        apiClient.getJson(APIEndpoints.costCenters(selectedCostCenter.id).details)
            .then(response => {
                setCostCenterDetails(response)
                let name = getLanguageValue(response.name.values)
                let comment = getLanguageValue(response.comment.values)
                setLanguageName(name)
                setLanguageComment(comment)
            })
            .catch(error => console.log(error))

        apiClient.getJson(APIEndpoints.costCenters(selectedCostCenter.id).groupAssignments)
            .then(response => {
                setGroups(response)
            })
            .catch(error => console.log(error))
    }, [selectedCostCenter.id])

    const selectAction = (action) => {
        if (action === "delete") {
            openDeleteModal(selectedCostCenter)
        }
        if (action === "copy") {
            openCopyModal(selectedCostCenter)
        }
    }

    if (costCenterDetail === "") {
        return <div className='d-flex justify-content-center' >
            <Spinner animation='border' variant='primary' />
        </div>
    }

    const costCenterDetailArray = [
        { label: `${t('name')}:`, value: languageName || "-" },
        { label: `${t('comment')}:`, value: languageComment || "-" },
        { label: `${t('costCenterAdmin:number')}:`, value: costCenterDetail.number || "-" },
        { label: `${t('costCenterAdmin:foreignKey')}:`, value: costCenterDetail.foreignKey || "-" },
        { label: `${t('costCenterAdmin:accountType')}:`, value: costCenterDetail.type && costCenterDetail.type.displayName ? costCenterDetail.type.displayName : "-" },
        { label: `${t('costCenterAdmin:organizationalUnit')}:`, value: costCenterDetail.office && costCenterDetail.office.displayName ? costCenterDetail.office.displayName : "-" },
        { label: `${t('costCenterAdmin:parentCostCenter')}:`, value: costCenterDetail.parentCostCenter && costCenterDetail.parentCostCenter.displayName ? costCenterDetail.parentCostCenter.displayName : "-" },
    ]



    return (
        <>
            <StyledDataList className="content">
                <div className="data-list__name h2">
                    {languageName || "-"}
                    <Link to={`/costcenteradministration/edit/${selectedCostCenter.id}#general`} >
                        <PenIcon title={t('edit')} />
                    </Link>
                </div>
                <ul className="data-list__data">
                    {costCenterDetailArray.map((listItem, idx) => {
                        return (
                            <li key={`${idx} ${listItem.label}`} className='data-list__entry'>
                                <div className="data-list__label">{listItem.label}</div>
                                <div className="data-list__value">{listItem.value}</div>
                            </li>
                        )
                    })}
                </ul>

                <div className="data-list__name h3 mt-4">
                    {t('costCenterAdmin:accessRights')}
                    <Link to={`/costcenteradministration/edit/${selectedCostCenter.id}#access-rights`} >
                        <PenIcon title={t('edit')} />
                    </Link>
                </div>
                <ul >
                    {
                        groups && groups.map((item) => {
                            return item.isAssigned ?
                                <li className="data-list__value" key={item.group.id} >{item.group.displayName}</li>
                                : ""
                        })
                    }
                </ul>

                <div className="data-list__name h3 mt-4">
                    {t("costCenterAdmin:interfaces")}
                    <Link to={`/costcenteradministration/edit/${selectedCostCenter.id}#interfaces`} >
                        <PenIcon title={t('edit')} />
                    </Link>
                </div>
                {checkedInterfaces &&
                    <ul >
                        {checkedInterfaces.map((listItem, idx) => {
                            return (
                                <li className="data-list__value" key={`${idx} ${listItem.label}`}>
                                    {listItem.displayName}
                                </li>
                            )
                        })}
                    </ul>}
            </StyledDataList>

            <StyledDataList className="footer p-4">
                <Form.Group className="mb-3">
                    <ArraySelect
                        defaultOption={{ value: '', name: t('selectAction') }}
                        options={[{ id: 'delete', displayName: t('delete') }, { id: 'copy', displayName: t('officeAdmin:copy') }]}
                        value={actionSelect}
                        onChange={e => selectAction(e.target.value)}
                        returnEvent
                    />
                </Form.Group>
            </StyledDataList>
        </>
    )
}



const OverlayRightCostCenterInfo = ({ visible, onClose, ...rest }) => {
    const { t } = useTranslation()
    return (
        <OverlayContainer>
            <OverlayRight visible={visible} onClose={onClose} headerTitle={t('details')}>
                <StyledOverlayInnerWrapper>
                    {visible && <CostCenterDetails {...rest} />}
                </StyledOverlayInnerWrapper>
            </OverlayRight>
        </OverlayContainer>
    )
}

export default OverlayRightCostCenterInfo
