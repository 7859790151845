import i18n from '../translation/i18n';



export const addConfigPrices = (variantPriceObj, configPrices = []) => {
  var newTotalObject = JSON.parse(JSON.stringify(variantPriceObj))
  if (newTotalObject.points !== null) {
    newTotalObject.points = priceOrPoints(variantPriceObj)
  }
  if (newTotalObject.price !== null) {
    newTotalObject.price = priceOrPoints(variantPriceObj) + sumPricePerItemArray(configPrices)
  }
  return newTotalObject
}

export const priceOrPoints = ({ price, points }) => {
  const value = price !== null ? price : points
  return value ? value : 0
}

export const formatedCurrencyValue = ({ price, currency }, shortPointsCurrency = false) => {
  const isPrice = price !== null ? true : false
  return currency ? currency : isPrice ? "EUR" : (shortPointsCurrency ? 'P' : i18n.t('points'))
}

export const formatedPriceObject = ({ price, points = null, currency }, shortPointsCurrency = false) => {
  const isPrice = price !== null ? true : false
  const value = priceOrPoints({ price, points })
  const formatedCurrency = formatedCurrencyValue({ price, currency, points }, shortPointsCurrency)

  if (isPrice) {
    return formatedPrice(value, formatedCurrency)
  }

  return `${value} ${formatedCurrency}`
}

export const formatedPrice = (price, formatedCurrency = 'EUR') => {
  try {
    return price.toLocaleString(navigator.language, {
      style: 'currency',
      currency: formatedCurrency,
      currencyDisplay: 'code'
    })
  } catch (e) {

  }
}

export const sumVariantPrices = (variants, priceList) => {
  return variants.reduce((previous, curr) => {
    const price = priceList.find(priceObj => priceObj.articleVariant.id === curr.variantId).pricePerItem.price
    const priceTimesQuantity = curr.quantity * price
    return previous + priceTimesQuantity;
  }, 0);
}

export const sumPricePerItemArray = (pricePerItemArray = []) => {
  return pricePerItemArray.map(pricePerItem => priceOrPoints(pricePerItem)).reduce((previous, curr) => {
    return previous + curr;
  }, 0);
}

export const formatedPoints = (totalValue) => {
  let { points } = totalValue
  return points !== null ? `${points} ${i18n.t('points')}` : null
}