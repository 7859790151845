import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import styled from 'styled-components';
import { FilledCircleIcon } from '../Icons/FilledCircleIcon';


const StyledWarning = styled.div`
    cursor: pointer;
    display: inline-block;
`

const AvailabilityBubble = (props) => {
  const { color, popoverContent} = props

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>{popoverContent}</Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popover}>
      <StyledWarning>
        <FilledCircleIcon color={color} />
      </StyledWarning>
    </OverlayTrigger>
  )
}

export default AvailabilityBubble