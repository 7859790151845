import React, { useState, useMemo, useCallback } from 'react'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { useNavigate } from 'react-router-dom'
import { emptyGuid } from '../utils/emptyGuid'
import { getErrorMessageFromApiResponse } from "../utils/api/apiUtils"
import { useTranslation } from 'react-i18next'
import { getIsoDate } from '../utils/getIsoDate'
import { makeCall } from '../utils/api/makeCall'
import { cloneDeep } from 'lodash'


const AppointmentContext = React.createContext({})
export function useAppointmentState() {
    return React.useContext(AppointmentContext)
}

const appointmentTemplate = {
    appointmentType: {
        displayName: "",
        durationInMinutes: 0,
        id: emptyGuid
    },
    cabin: {
        displayName: "",
        isBlocked: false,
        id: emptyGuid
    },
    targetBusinesspartner: {
        displayName: "",
        id: emptyGuid
    },
    day: "",
    timeSlot: {
        hour: 0,
        minute: 0,
        id: emptyGuid
    },
    comment: "",
    email: "",
    phone: "",
    id: emptyGuid
}

const AppointmentProvider = ({ children }) => {
    const navigate = useNavigate();
    const { t } = useTranslation()

    const [appointmentTypes, setAppointmentTypes] = useState([])
    const [appointment, setAppointment] = useState(cloneDeep(appointmentTemplate))
    const [initialAppointment, setInitialAppointment] = useState(JSON.stringify(appointmentTemplate))
    const [cabins, setCabins] = useState([])
    const [dateSlots, setDateSlots] = useState([])
    const [timeSlots, setTimeSlots] = useState([])
    const [userData, setUserData] = useState(null)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [isSaving, setIsSaving] = useState(false)
    const [errorStates, setErrorStates] = useState({})
    const [loadingStates, setLoadingStates] = useState({})



    const getUserData = useCallback((employeeId) => {
        makeCall('loadCommunicationData', async () => {
            let response = await apiClient.getJson(APIEndpoints.businessPartner(employeeId).communicationData)
            setUserData(response)
        }, setErrorStates, setLoadingStates)
    }, [])

    //get Cabins from Backend for chosen appointmentType
    const getCabins = useCallback((newAppointment) => {
        if (newAppointment && (newAppointment.appointmentType.id !== emptyGuid)) {
            const params = { 'filter.excludeBlocked': true }
            makeCall('loadCabins', async () => {
                let response = await apiClient.getJson(APIEndpoints.appointmenttypes(newAppointment.appointmentType.id).cabins, params)
                setCabins(response)
            }, setErrorStates, setLoadingStates)
        }
    }, [])

    //get TimeSlots from Backend for chosen Date
    const getTimeSlots = useCallback((newAppointment, newInitialAppointment) => {
        setTimeSlots([])
        if (newAppointment && (newAppointment.appointmentType.id !== emptyGuid) && (newAppointment.cabin.id !== emptyGuid) && newAppointment.day) {
            const prepartedDay = newAppointment.day.substr(0, 10)
            makeCall('loadTimeSlots', async () => {
                let response = await apiClient.getJson(APIEndpoints.appointmenttypes(newAppointment.appointmentType.id).bookableTimeSlots(newAppointment.cabin.id, prepartedDay))
                const initialAppointmentObj = JSON.parse(newInitialAppointment)
                if (initialAppointmentObj.id !== emptyGuid) {
                    if (
                        initialAppointmentObj.day.substr(0, 10) === newAppointment.day.substr(0, 10) &&
                        initialAppointmentObj.cabin.id === newAppointment.cabin.id &&
                        initialAppointmentObj.appointmentType.id === newAppointment.appointmentType.id
                    ) {
                        const newTimeSlots = [...response]
                        //add old TimeSlot
                        newTimeSlots.splice(getSortedInsertIndex(initialAppointmentObj.timeSlot, newTimeSlots), 0, initialAppointmentObj.timeSlot)
                        setTimeSlots(newTimeSlots)
                    } else {
                        setTimeSlots(response)
                    }
                } else {
                    setTimeSlots(response)
                }
            }, setErrorStates, setLoadingStates)
        }
        //dateSlots, selectedDateChanged
    }, [])

    //get DateSlots from Backend for chosen Cabin
    const getDateSlots = useCallback((appointment, activeStartDate) => {
        if (appointment && (appointment.appointmentType.id !== emptyGuid) && (appointment.cabin.id !== emptyGuid)) {
            const startDate = getIsoDate(activeStartDate)
            const monthEnd = new Date(activeStartDate)
            monthEnd.setMonth(monthEnd.getMonth() + 1)
            monthEnd.setDate(0)
            const endDate = getIsoDate(monthEnd)
            let params = { 'filter.startDate': startDate, 'filter.endDate': endDate }

            makeCall('loadDateSlots', async () => {
                let response = await apiClient.getJson(APIEndpoints.appointmenttypes(appointment.appointmentType.id).appointmentOverviews(appointment.cabin.id), params)
                setDateSlots(response)
            }, setErrorStates, setLoadingStates)
        }
    }, [])

    const getOldAppointmentData = useCallback((employeeId, appointmentId) => {
        let params = {
            'filter.startDate': getIsoDate(new Date()),
            'filter.targetBusinesspartnerId': employeeId
        }
        makeCall('loadAppointments', async () => {
            let response = await apiClient.getJson(APIEndpoints.appointments, params)
            let appointment = response.find(obj => obj.id === appointmentId)
            if (appointment) {
                let dateNoww = new Date()
                dateNoww.setDate(1)
                //set Values from old Appointment
                setAppointment(appointment)
                let newInitialAppointment = JSON.stringify(appointment)
                setInitialAppointment(newInitialAppointment)
                getCabins(appointment)
                getDateSlots(appointment, dateNoww)
                getTimeSlots(appointment, newInitialAppointment)
            }
        }, setErrorStates, setLoadingStates)
    }, [getCabins, getDateSlots, getTimeSlots])

    //get AppointmentTypes from Backend
    const getAppointmentTypes = useCallback(() => {
        makeCall('loadAppointmenttypes', async () => {
            let response = await apiClient.getJson(APIEndpoints.appointmenttypes())
            setAppointmentTypes(response)
        }, setErrorStates, setLoadingStates)
    }, [])

    const getSortedInsertIndex = (timeSlot, timeSlots) => {
        var insertIndex = 0
        for (const [index, obj] of timeSlots.entries()) {
            if (obj.hour < timeSlot.hour || (obj.hour === timeSlot.hour && obj.minute <= timeSlot.minute)) { insertIndex = index + 1 }
        }
        return insertIndex
    }

    const dataHasChanged = useMemo(() => {
        console.log("initial:", JSON.parse(initialAppointment), "appointment:", appointment)
        const formDataString = appointment
        return JSON.stringify(formDataString) !== initialAppointment
    }, [appointment, initialAppointment])

    const isStep1Done = useMemo(() => {
        if (appointment && appointment.appointmentType.id !== emptyGuid) return true
        else return false
    }, [appointment])

    const isStep2Done = useMemo(() => {
        if (appointment && (appointment.timeSlot.id !== emptyGuid) && appointment.day && (appointment.cabin.id !== emptyGuid)) return true
        else return false
    }, [appointment])

    const isStep3Done = useMemo(() => {
        if (appointment && appointment.email && appointment.phone) return true
        else return false
    }, [appointment])

    const handleClickCancel = (appointmentId) => {
        if (!appointmentId) {
            setCabins([])
            setDateSlots([])
            setTimeSlots([])
        }
        let newAppointment = cloneDeep(JSON.parse(initialAppointment))
        setAppointment(newAppointment)
    }
    const handleSubmit = async (employeeId, appointmentId) => {
        setError("")
        setIsSaving(true)
        //if appointmentId -> delete old appointment, then post new appointment
        if (appointmentId && dataHasChanged) {
            await apiClient.deleteJson(APIEndpoints.appointment(appointmentId)).then(() => {
                apiClient.postJson(
                    APIEndpoints.appointments,
                    appointment
                ).then(() => {
                    setSuccess(`${t('appointment:successEdit')}`);
                    setTimeout(() => {
                        navigate(-1)
                    }, 1500)
                }).catch((error) => {
                    setError(getErrorMessageFromApiResponse(error))
                })
            })
        }
        //else -> post new appointment
        else {
            //add businesspartnerId
            const preparedAppointment = { ...appointment }
            preparedAppointment.targetBusinesspartner.id = employeeId
            await apiClient.postJson(
                APIEndpoints.appointments, // we are calling here what we have registered in the apiConfig.js
                preparedAppointment
            ).then(() => {
                setSuccess(`${t('appointment:successCreate')}`);
                setTimeout(() => {
                    navigate(-1)
                }, 1500)
            }).catch((error) => {
                setError(getErrorMessageFromApiResponse(error))  // helper function
            })
        }
        setIsSaving(false)
    }

    const contextValues = {
        isStep1Done,
        isStep2Done,
        isStep3Done,
        dataHasChanged,
        userData,
        dateSlots,
        setAppointment,
        appointment,
        appointmentTypes,
        cabins,
        timeSlots,
        error,
        success,
        handleClickCancel,
        handleSubmit,
        isSaving,
        getUserData, getOldAppointmentData, getAppointmentTypes, getCabins, getDateSlots, getTimeSlots, loadingStates, setInitialAppointment, initialAppointment,
        errorStates
    }
    return (
        <AppointmentContext.Provider value={contextValues}>
            {children}
        </AppointmentContext.Provider>
    )
}

export default AppointmentProvider