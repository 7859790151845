import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

const QuantityStatus = ({ variantQuantities }) => {
  const { t } = useTranslation()

  if (!variantQuantities) {
    return null
  }

  const status = (() => {
    switch (variantQuantities.status) {
      case 'isLimited':
        return variantQuantities.orderableQuantity
          ? <span className='text-warning'>
            <Trans
              i18nKey="productDetail:statusIsLimitedAmount"
              values={{ amount: variantQuantities.orderableQuantity }}
              count={variantQuantities.orderableQuantity}
            /></span>
          : <span className='text-warning'>{t('productDetail:statusIsLimited')}</span>
      case 'isNotAvailable':
        return <span className='text-danger'>{t('productDetail:statusIsNotAvailable')}</span>
      case 'isAvailable':
        return <span className='text-success'>{t('productDetail:statusIsAvailable')}</span>
      default:
        return null
    }
  })()

  const deliveryWeeks = (() => {
    if (variantQuantities.deliveryWeeks) {
      return <span>{`(${t('productDetail:expectedDelivery')} ${variantQuantities.deliveryWeeks} ${variantQuantities.deliveryWeeks > 1 ? t('weeks') : t('week')})`}</span>
    }
    else return ""
  })()


  if (!status) {
    return null
  }
  return (
    <div>{status}  {deliveryWeeks}</div>
  )
}

export default QuantityStatus
