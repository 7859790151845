import React, { useCallback, useEffect, useState } from 'react'
import { useReportJobsTypeState } from '../../stores/Administration/ReportJobsTypeProvider'
import { colors } from '../../styles/colors'
import styled from 'styled-components'

const StyledReportTypesList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    cursor: pointer;
    padding: 15px 25px;
    border-bottom: 1px solid ${colors.gray2};
  }
`

const ReportTypeList = ({ onClickCreateNewReport }) => {
  const { reportJobTypeGroup, reportJobTypeGroups, reportJobTypes } = useReportJobsTypeState()
  const [reportTypesFiltered, setReportTypesFiltered] = useState([])

  const handleClickCreateNew = useCallback(reportType => () => {
    onClickCreateNewReport(reportType)
  }, [onClickCreateNewReport])

  useEffect(() => {
    if (reportJobTypeGroups && reportJobTypeGroup && reportJobTypes) {
      //reportJobTypeGroups für ausgewähltes Reporttype finden
      const types = reportJobTypeGroups.find((obj) => obj.id === reportJobTypeGroup)
      //falls vorhanden werden alle ReportTypes die dem reportJobTypeGroups.reportTypes entsprechen
      if (types) {
        const typesArray = types.reportTypes
        const filtered = reportJobTypes.filter((obj) => typesArray.includes(obj.id))
        setReportTypesFiltered(filtered)
      }
      //ansonsten werden alle verfügbaren ReportTypes angezeigt
      else {
        const filtered = reportJobTypes.filter((obj) => obj.id)
        setReportTypesFiltered(filtered)
      }
    }
    //ansonsten werden alle verfügbaren ReportTypes angezeigt
    else if (reportJobTypes) {
      const filtered = reportJobTypes.filter((obj) => obj.id)
      setReportTypesFiltered(filtered)
    }
  }, [reportJobTypeGroups, reportJobTypeGroup, reportJobTypes])

  return (
    <StyledReportTypesList>
      {reportTypesFiltered.map(reportType => (
        <li key={reportType.id} onClick={handleClickCreateNew(reportType)}>{reportType.displayName}</li>
      ))}
    </StyledReportTypesList>
  )
}

export default ReportTypeList
