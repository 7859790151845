import React from 'react'
import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'
import { BsThreeDots } from 'react-icons/bs'


const StyledDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background: none;
    border: none;
    padding: 0 5px;
    margin: 0;
    color: gray;
  }

  .dropdown-toggle:after {
    content: none;
  }

  .dropdown-toggle:hover {
    filter: brightness(50%);
  }

  .dropdown-toggle:active, .dropdown-toggle:focus {
    color: gray;
    filter: brightness(50%);
  }

  .dropdown-item {
    svg {
      margin-right: 10px;
    }
  }
`

const MoreOptionsButton = (props) => {
  const { options } = props
  return (
    <StyledDropdown onClick={e => e.stopPropagation()} >
      <Dropdown.Toggle as="span">
        <BsThreeDots title="More options" />
      </Dropdown.Toggle>

      <Dropdown.Menu rootCloseEvent="mousedown">
        {options && options.filter(option => option).map(option =>
          <Dropdown.Item
            disabled={option.disabled}
            key={option.option}
            onClick={option.onOptionSelect}
          >
            <span>{option.optionIcon && option.optionIcon}</span>
            <span>{option.optionText}</span>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </StyledDropdown >
  )
}

export default MoreOptionsButton