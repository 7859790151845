import React, { Component } from 'react'
import { withTheme } from 'styled-components'
import { inject, observer } from 'mobx-react'

import ProductGroupCard from '../components/ProductGroupCard/ProductGroupCard'
import ProductCard from '../components/ProductCard/ProductCard'
import { CategoryIcons } from '../components/Icons/CategoryIcons/CategoryIcons'

class CategoryGroupExample extends Component {
  render () {
    const { theme } = this.props

    return (
      <React.Fragment>
        <div className='container-fluid'>
          <div className='d-flex'>
            <div style={{ width: '320px', height: '10px' }} className='d-none d-md-block' />
            <div style={{ flexGrow: '1' }}>
              <h2 className='mt-5'>Kacheln Beispiele</h2>
              <ProductGroupCard categoryName='Hosen' current='1' max='12' image='https://placeimg.com/575/300/architecture' />
              <div className='row'>
                <ProductCard className='col-md-3 col-sm-4 col-6' name='Hose' subDescription='schwarz' price='10,00 €' current='0' max='3' image='' iconType='outerClothing' />
                <ProductCard className='col-md-3 col-sm-4 col-6' name='T-Shirt' subDescription='' price='15,00 €' current='2' max='5' image='' />
                <ProductCard className='col-md-3 col-sm-4 col-6' name='Jacke' subDescription='blau' price='4' current='1' max='1' image='' showNewBadge />
                <ProductCard className='col-md-3 col-sm-4 col-6' name='Hose' subDescription='schwarz' price='10,00 €' current='3' max='3' image='' isAmountMaxedOut isInactive />
                <ProductCard className='col-md-3 col-sm-4 col-6' name='T-Shirt' subDescription='' price='15,00 €' current='2' max='5' image='' isInactive={<React.Fragment>geht <br /> nicht</React.Fragment>} />
                <ProductCard className='col-md-3 col-sm-4 col-6' name='Jacke' subDescription='blau' price='4' current='1' max='1' image='' showNewBadge isAmountMaxedOut isInactive />
              </div>

              <ProductGroupCard categoryName='Jacken' current='3' max='3' image='' isGroupAmountMaxedOut />
              <div className='row'>
                <ProductCard className='col-md-3 col-sm-4 col-6' name='----' subDescription='----' price='--,-- €' current='0' max='0' image='' />
                <ProductCard className='col-md-3 col-sm-4 col-6' name='----' subDescription='----' price='--,-- €' current='0' max='0' image='' />
                <ProductCard className='col-md-3 col-sm-4 col-6' name='----' subDescription='----' price='--,-- €' current='0' max='0' image='' />
              </div>

              <h2 className='mt-5'>Kategorie Kacheln mit Icons</h2>
              {Object.keys(CategoryIcons).map((key) =>
                <React.Fragment key={key} >
                  <ProductGroupCard color={theme.primaryColor} categoryName={key} current='0' max='3' iconType={key} />
                  <div className='row'>
                    <ProductCard className='col-md-3 col-sm-4 col-6' name={key} subDescription='' price='10,00 €' current='0' max='3' image='' iconType={key} color={theme.primaryColor} />
                  </div>
                </React.Fragment>
              )}

            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTheme(inject('uiStore')(observer(CategoryGroupExample)))
