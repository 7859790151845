export const hoursArray = [
    [], //0
    [], //1
    [], //2
    [], //3
    [], //4
    [], //5
    [], //6
    [], //7
    [], //8
    [], //9
    [], //10
    [], //11
    [], //12
    [], //13
    [], //14
    [], //15
    [], //16
    [], //17
    [], //18
    [], //19
    [], //20
    [], //21
    [], //22
    [] //23
]
//because of js modulo bug for negative numbers 
function mod(n, m) {
    return ((n % m) + m) % m;
}

export const calculateTime = (type, hour, minute, duration) => {
    const intHour = parseInt(hour)
    const intMinute = parseInt(minute)
    const intduration = parseInt(duration)
    var newHour = 0
    var newMinute = 0
    if (type === "plus") {
        newHour = mod(Math.floor((((intHour * 60) + intMinute) + intduration) / 60), 24)
        newMinute = mod((((intHour * 60) + intMinute) + intduration), 60)
    }
    if (type === "minus") {
        newHour = mod(Math.floor((((intHour * 60) + intMinute) - intduration) / 60), 24)
        newMinute = mod((((intHour * 60) + intMinute) - intduration), 60)
    }
    return [newHour, newMinute]
};

export const toTimeString = (hour, minute) => {
    return `${hour < 10 ? "0" + hour : hour}:${minute < 10 ? "0" + minute : minute}`
}

