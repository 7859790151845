import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import { Subheader } from '../../components/Page/logix2020/Subheader'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import AdministrationSidebarNavigation from '../../components/Configuration/AdministrationSidebarNavigation'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { useObserver } from 'mobx-react'
import useStores from '../../stores/useStore'
import { MdLibraryAdd } from 'react-icons/md'
import { Alert, Button, Spinner } from 'react-bootstrap'
import PlaceholdersOverview from '../../components/Configuration/Placeholders/PlaceholdersOverview'
import CreateTypeModal from '../../components/Configuration/Placeholders/CreateTypeModal'
import { usePlaceholdersState } from '../../stores/Configuration/PlaceholdersProvider'
import { emptyGuid } from '../../utils/emptyGuid'
import ModalComponent2 from '../../components/Modals/ModalComponent2'
import { BiGlobe } from 'react-icons/bi'
import PlaceholderAssignmentsModal from '../../components/Configuration/Placeholders/PlaceholderAssignments/PlaceholderAssignmentsModal'


const PlaceholdersAdministrationPage = () => {
  const { t } = useTranslation()
  const { uiStore } = useStores()
  const allowTextPlaceholderAdministration = useObserver(() => uiStore.allowTextPlaceholderAdministration)
  const dashboardIsLoading = useObserver(() => uiStore.dashboardIsLoading)
  const dashboardLoaded = useObserver(() => !!uiStore.dashboard)
  const [showCreateTypeModal, setShowCreateTypeModal] = useState(false)
  const [showEditTypeModal, setShowEditTypeModal] = useState(null)
  const { createType, editType, errorStates, setErrorStates, loadingStates, deleteType, deletePlaceholder, addPlaceholder, allPlaceholders, isMainMandator } = usePlaceholdersState()
  const [showDeleteType, setShowDeleteType] = useState(null)
  const [showDeletePlaceholder, setShowDeletePlaceholder] = useState(null)
  const [showAssignmentsModal, setShowAssignmentsModal] = useState(false)

  const saveCreateTypeModal = (params, type) => {
    (type === 'edit')
      ? editType(params, setShowCreateTypeModal)
      : createType(params, setShowEditTypeModal)
  }
  return (
    <>
      <Subheader>
        <div className="subheader-title flex-grow-1">{t('placeholder:placeholder')}</div>
        <div className="subheader__buttonWrapper">
          {isMainMandator && <Button className="subheader__button" onClick={() => setShowAssignmentsModal(true)}><BiGlobe className="me-3" />{t('placeholder:inheritToSubmandator')}</Button>}
          {allowTextPlaceholderAdministration && <Button className="subheader__button" onClick={() => setShowCreateTypeModal(true)}><MdLibraryAdd className="me-3" />{t('placeholder:addType')}</Button>}
        </div>
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <AdministrationSidebarNavigation active="placeholdersAdministration" />
        </SidebarLeft>
        <ContentArea>
          {allowTextPlaceholderAdministration ?
            <PlaceholdersOverview
              setShowEditTypeModal={setShowEditTypeModal}
              setShowDeleteType={setShowDeleteType}
              setShowDeletePlaceholder={setShowDeletePlaceholder}
              addPlaceholder={addPlaceholder}
              allPlaceholders={allPlaceholders}
            />
            : (dashboardIsLoading || dashboardLoaded)
              ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
              : <Alert variant='danger'>{t('accessDenied')}</Alert>
          }
        </ContentArea>
        {showCreateTypeModal && <CreateTypeModal
          handleClose={() => { setShowCreateTypeModal(false); setErrorStates({}) }}
          handleSave={(params) => saveCreateTypeModal(params, 'create')}
          error={errorStates.createType}
          isLoading={loadingStates.createType}
          initialData={{ id: emptyGuid, name: "", description: "" }}
        />}
        {showEditTypeModal && <CreateTypeModal
          handleClose={() => { setShowEditTypeModal(null); setErrorStates({}) }}
          handleSave={(params) => saveCreateTypeModal(params, 'edit')}
          error={errorStates.createType}
          isLoading={loadingStates.createType}
          initialData={showEditTypeModal}
        />}
        {showDeleteType && <ModalComponent2
          ModalTitle={t('placeholder:deleteType')}
          BodyPart1={showDeleteType.hasPlaceholders ? t('placeholder:deleteTypeAndPlaceholdersConfirmation') : t('placeholder:deleteTypeConfirmation')}
          button1Function={() => deleteType(showDeleteType, setShowDeleteType)}
          button1Text={t('delete')}
          button1Loading={loadingStates.deleteType}
          button1Variant="danger"
          showButton2={!!showDeleteType.hasPlaceholders}
          button2Function={() => deleteType(showDeleteType, setShowDeleteType, false)}
          button2Text={t('placeholder:withoutType')}
          button2Loading={loadingStates.deleteType}
          button2Variant="danger"
          functionCancel={() => { setShowDeleteType(null); setErrorStates({}) }}
          error={errorStates?.deleteType?.value}
        />}
        {showDeletePlaceholder &&
          <ModalComponent2
            ModalTitle={t('placeholder:deletePlaceholder')}
            BodyPart1={t('placeholder:deletePlaceholderConfirmation')}
            button1Function={() => deletePlaceholder(showDeletePlaceholder.id, setShowDeletePlaceholder)}
            button1Text={t('delete')}
            button1Loading={loadingStates.deletePlaceholder}
            button1Variant="danger"
            bodyStyle="alert alert-danger"
            functionCancel={() => { setShowDeletePlaceholder(null); setErrorStates({}) }}
            error={errorStates?.deletePlaceholder?.value}
          />}
        {showAssignmentsModal &&
          <PlaceholderAssignmentsModal handleClose={() => setShowAssignmentsModal(false)} />
        }
      </FlexWrapper>
    </>
  )
}

export default PlaceholdersAdministrationPage