import { action, observable, reaction, makeObservable } from 'mobx';
import { debounce } from 'throttle-debounce'

import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { emptyGuid } from '../utils/emptyGuid'
import { availableAsBillingAddress, availableAsShipmentAddress } from '../utils/availableAsAddress'
import { getErrorMessageFromApiResponse, settingValueToType } from '../utils/api/apiUtils'
import { scrollToDataId, scrollToElement } from '../utils/utils'
import { getEmployee } from '../utils/employeeLoader'

class ProductsStore {
  constructor(appStore) {
    makeObservable(this, {
      activities: observable,
      activity: observable,
      displayName: observable,
      availableAddresses: observable,
      billingAddress: observable,
      activitiesIsLoading: observable,
      basket: observable,
      baskets: observable,
      confirmAddressIsSubmitting: observable,
      confirmOrderIsSubmitting: observable,
      isChecked: observable,
      contingents: observable,
      filteredContingents: observable,
      customBillingAddress: observable,
      customBillingAddressSelected: observable,
      customShipmentAddress: observable,
      customShipmentAddressSelected: observable,
      loadContingentIsLoading: observable,
      loadOrCreateBasketIsLoading: observable,
      variantParts: observable,
      loadAvailableAddressesIsLoading: observable,
      loadShipmentTypesIsLoading: observable,
      removingPositionFromBasket: observable,
      shipmentAddress: observable,
      shipmentTypes: observable,
      shipmentType: observable,
      basketEmail: observable,
      basketPhone: observable,
      scheduledPickUpDate: observable,
      isScheduledPickUpDateRequired: observable,
      customAddressSettings: observable,
      pageErrors: observable,
      pageErrorPointers: observable,
      targetEmployeeId: observable,
      targetEmployee: observable,
      searchText: observable,
      showCatalogArticles: observable,
      requiredFieldsError: observable,
      fieldErrors: observable,
      saveBasketAsTemplateButtonLoading: observable,
      basketAvailabilities: observable,
      selectedContingentId: observable,
      selectedContingentIdNew: observable,
      showSwitchContingentModal: observable,
      showArticleScanModal: observable,
      showOrderInfoModal: observable,
      infoText: observable,
      orderInfoModalTitle: observable,
      initialClothing: observable,
      useEntitlementSelection: observable,
      orderForFree: observable,
      hidePricesAndPoints: observable,
      sendShipmentSMS: observable,
      loadTargetEmployee: action,
      setShipmentType: action,
      setBillingAddress: action,
      setCustomBillingAddress: action,
      setCustomBillingAddressSelected: action,
      setShipmentAddress: action,
      setCustomShipmentAddress: action,
      setCustomShipmentAddressSelected: action,
      resetProductsOverviewPage: action,
      handleChangeActivity: action,
      loadActivities: action,
      refreshActivities: action,
      loadBasket: action,
      loadOrCreateBasket: action,
      initializeDefaultBasketValues: action,
      loadContingent: action,
      updateCategoryQuantity: action,
      loadShoppingBasket: action,
      changePositionQuantity: action,
      removeAllArticlesFromBasket: action,
      removeArticleFromBasket: action,
      setApiBasket: action,
      setShipmentTypeToBasket: action,
      setShipmentAddressToBasket: action,
      setCustomShipmentAddressToBasket: action,
      setContactInfoToBasket: action,
      setBillingAddressToBasket: action,
      setCustomBillingAddressToBasket: action,
      loadAddressSettings: action,
      loadAvailableAddresses: action,
      loadShipmentTypes: action,
      handleChangeCustomShipmentAddress: action,
      handleBasketEmailChange: action,
      handleBasketPhoneChange: action,
      handleScheduledPickUpDateChange: action,
      handleChangeCustomBillingAddress: action,
      handleConfirmAddressSubmit: action,
      validateConfirmAddressPage: action,
      saveBasketAsTemplate: action,
      loadShoppingBasketAvailabilities: action,
      onSwitchContingentHandler: action,
      switchContingentIsLoading: observable,
      onSwitchContingentModalAction: action,
      orderInfo: observable,
      loadOrderInfo: action,
      getMinQuantity: action,
      getSelectedQuantity: action,
      changeBasket: action,
      changeLoadOrCreateBasketIsLoading: action,
      handleSendShipmentSMSChange: action
    });

    this.app = appStore
    reaction(
      () => this.searchText,
      searchText => {
        this.filterArticles()
      }
    )
    reaction(
      () => this.contingents,
      contingents => {
        this.filterArticles()
      }
    )
    reaction(
      () => this.selectedContingentId,
      selectedContingentId => {
        this.filterArticles()
      }
    )
    reaction(
      () => this.basket && this.basket.id,
      async basket => {
        if (!basket) {
          return
        }
        this.loadOrderInfo()
        if (!this.app.teamOrderStore.selectedCostcenterId && !this.app.teamOrderStore.privateOrder) {
          this.contingents = null
          this.loadContingent(true)
        }
        this.resetConfirmAddressPage()
        this.app.uiStore.buildCheckoutLinks()
      }
    )
    reaction(() => this.targetEmployeeId, async targetEmployeeID => {
      if (targetEmployeeID && this.targetEmployee.id !== targetEmployeeID) {
        this.loadTargetEmployee(targetEmployeeID)
        this.loadActivities()
        this.resetProductsOverviewPage()
      }
      else {
        this.targetEmployee = {}
      }
    })
  }

  activities = [];

  activity = {
    id: null,
    displayName: null
  };

  displayName = null;

  availableAddresses = null;
  billingAddress = {};
  activitiesIsLoading = false;
  basket = null;
  baskets = null;
  confirmAddressIsSubmitting = null;
  confirmOrderIsSubmitting = null;
  isChecked = false;
  contingents = null;
  filteredContingents = [];
  _customBillingAddress = {
    officeName: '',
    firstName: '',
    lastName: '',
    street: '',
    streetNr: '',
    careOf: '',
    zipCode: '',
    city: '',
    postOfficeBox: '',
    countryIsoCode: '',
    originAddressId: emptyGuid
  }
  customBillingAddress = { ...this._customBillingAddress };
  customBillingAddressSelected = false;
  _customShipmentAddress = {
    officeName: '',
    firstName: '',
    lastName: '',
    street: '',
    streetNr: '',
    careOf: '',
    zipCode: '',
    city: '',
    postOfficeBox: '',
    countryIsoCode: '',
    originAddressId: emptyGuid,
    updateUserAddress: false
  }
  customShipmentAddress = { ...this._customShipmentAddress };
  customShipmentAddressSelected = false;
  loadContingentIsLoading = false;
  loadOrCreateBasketIsLoading = false;
  variantParts = 1;
  loadAvailableAddressesIsLoading = false;
  loadShipmentTypesIsLoading = false;
  removingPositionFromBasket = {};
  shipmentAddress = {};
  shipmentTypes = null;
  shipmentType = null;
  basketEmail = null;
  basketPhone = null;
  scheduledPickUpDate = null;
  isScheduledPickUpDateRequired = false;
  customAddressSettings = {};

  pageErrors = [];
  pageErrorPointers = {};

  targetEmployeeId = '';
  targetEmployee = {};
  searchText = '';
  showCatalogArticles = false;
  _requiredFieldsError = {
    scheduledPickUpDate: false,
    basketEmail: false,
    baketPhone: false,
    customShipmentAddress: {
      firstName: false,
      lastName: false,
      street: false,
      streetNr: false,
      zipCode: false,
      city: false,
      countryIsoCode: false,
      officeName: false
    },
    customBillingAddress: {
      firstName: false,
      lastName: false,
      street: false,
      streetNr: false,
      zipCode: false,
      city: false,
      countryIsoCode: false,
      officeName: false
    }
  }
  requiredFieldsError = { ...this._requiredFieldsError };

  _fieldErrors = {
    scheduledPickUpDate: false,
    basketEmail: false,
    baketPhone: false,
  }
  fieldErrors = { ...this._fieldErrors };
  saveBasketAsTemplateButtonLoading = false;
  basketAvailabilities = [];
  selectedContingentId = null;
  selectedContingentIdNew = null;
  showSwitchContingentModal = false;
  showArticleScanModal = false;
  showOrderInfoModal = false;
  infoText = '';
  orderInfoModalTitle = '';
  initialClothing = false;
  useEntitlementSelection = false;
  orderForFree = false;
  hidePricesAndPoints = false;
  sendShipmentSMS = false;

  async loadTargetEmployee(targetEmployeeID, force) {
    this.targetEmployee = await getEmployee(targetEmployeeID, force)
    return this.targetEmployee
  }

  filterArticles() {
    const searchText = this.searchText.toLowerCase()
    if (this.contingents === null) {
      return
    }

    const clonedContingents = JSON.parse(JSON.stringify(this.contingents))
    this.filteredContingents = clonedContingents.map((contingent) => {
      if ((this.useEntitlementSelection && contingent.id === this.selectedContingentId) || !this.useEntitlementSelection) {
        contingent.categories = contingent.categories.map(category => {
          category.articles = category.articles.filter(article => {
            const displayName = article.article.displayName.toLowerCase()
            const articleNumber = article.article.articleNumber.toLowerCase()
            return (displayName.indexOf(searchText) !== -1 || articleNumber.indexOf(searchText) !== -1)
          })
          return category
        }).filter(category => category.articles.length > 0)
      }
      else {
        contingent.categories = []
      }

      return contingent
    }).filter(contingent => contingent.categories.length > 0)
  }

  setShipmentType(shipmentType) {
    this.shipmentType = shipmentType

    this.pageErrors = []
    if (this.shipmentType && this.basket.shipmentType && this.shipmentType.id !== this.basket.shipmentType.id) {
      this.setShipmentTypeToBasket(this.shipmentType)
    }
  }

  setBillingAddress(address) {
    this.billingAddress = address
  }

  setCustomBillingAddress(customBillingAddress) {
    this.customBillingAddress = customBillingAddress
  }

  setCustomBillingAddressSelected(customBillingAddressSelected) {
    this.customBillingAddressSelected = customBillingAddressSelected
  }

  setShipmentAddress(address) {
    this.shipmentAddress = address
  }

  setCustomShipmentAddress(customShipmentAddress) {
    this.customShipmentAddress = customShipmentAddress
  }

  setCustomShipmentAddressSelected(customShipmentAddressSelected) {
    this.customShipmentAddressSelected = customShipmentAddressSelected
  }

  resetConfirmAddressPage() {
    this.availableAddresses = null
    this.customBillingAddressSelected = false
    this.setCustomShipmentAddressSelected(false)
    this.billingAddress = {}
    this.shipmentAddress = {}
    this.customBillingAddress = { ...this._customBillingAddress }
    this.customShipmentAddress = { ...this._customShipmentAddress }
    this.shipmentTypes = null
    this.shipmentType = null
  }



  resetProductsOverviewPage() {
    this.contingents = null
    this.selectedContingentId = null
    this.app.productListStore.resetProductList()
    this.app.teamOrderStore.doResetTeamOrderProducts = true
  }


  handleChangeActivity(activityId) {
    if (this.activity && this.activity.id === activityId) {
      return
    }
    let foundActivity = this.activities.find(activity => activity.id === activityId)
    if (foundActivity) {
      this.activity = foundActivity
      this.contingents = null
      this.loadOrCreateBasket(true)
    }
  }

  loadActivities = async () => {
    if (this.activitiesIsLoading) {
      await this.app.uiStore.waitUntilLoaded(100, 'app.productsStore.activitiesIsLoading')
    } else {
      this.activitiesIsLoading = true
      await this.app.profileStore.loadProfile()
      this.activities = await apiClient.getJson(APIEndpoints.businessPartner(this.targetEmployeeId || this.app.profileStore.profile.id).shoppingActivities)
      this.activitiesIsLoading = false
    }
    return this.activities
  };

  async refreshActivities() {
    await this.loadActivities()
    if (!this.activity || !this.activities.find(activity => activity.id === this.activity.id)) {
      this.activity = this.getMainActivity(this.activities)
    }
  }

  getMainActivity(activities) {
    const mainActivity = activities.find(activity => activity.isMainActivity)
    if (mainActivity) {
      return mainActivity
    }
    else if (activities.length) {
      return activities[0]
    }
    else {
      return {
        id: null,
        displayName: null
      }
    }
  }

  changeBasket(basket) {
    this.basket = basket
  }

  changeLoadOrCreateBasketIsLoading(value) {
    this.loadOrCreateBasketIsLoading = value
  }

  async loadBasket(basketId) {
    let basket = await apiClient.getJson(APIEndpoints.shoppingBasket(basketId))
    this.changeBasket(basket)
    this.initializeDefaultBasketValues()
  }

  async loadOrCreateBasket(force = false) {
    const { profileStore, teamOrderStore } = this.app
    const processType = teamOrderStore.getCurrentProcessType()
    if (!force && this.basket && !this.basket.isWaitingForApproval && !this.basket.isReorder) {
      switch(processType)
      {
        case 'private':
        case 'personal':
          if (this.basket.targetBusinesspartner && (this.basket.targetBusinesspartner.id === this.targetEmployeeId)) {
            if (this.basket.targetOrderProcessType === processType) {
              return this.basket
            }
          }
          break;
        case 'costCenter':
        case 'storeHouse':
          if (this.basket.targetCostCenter && (this.basket.targetCostCenter.id === teamOrderStore.selectedCostcenterId)) {
            //return this.basket
            if (this.basket.targetOrderProcessType === processType && processType === 'costCenter') {
              return this.basket
            }
            if (this.basket.targetStorehouse && (this.basket.targetStorehouse.id === teamOrderStore.selectedStorehouseId)) {
              if (this.basket.targetOrderProcessType === processType && processType === 'storeHouse') {
                return this.basket
              }
            }
          }
          break;
        default:
          break;
      }
    }

    if (this.loadOrCreateBasketIsLoading) {
      await this.app.uiStore.waitUntilLoaded(100, 'app.productsStore.loadOrCreateBasketIsLoading')
      return this.basket
    } else {
      this.changeLoadOrCreateBasketIsLoading(true)
      await profileStore.loadProfile()
      if (processType === 'personal') {
        await this.refreshActivities()
      }

      console.log('Productstore processType:', processType)
      const params = processType === 'costCenter' ? {
        'filter.targetCostCenterId': teamOrderStore.selectedCostcenterId,
        'filter.processType': processType
      } : processType === 'storeHouse' ? {
        'filter.targetStorehouseId': teamOrderStore.selectedStorehouseId,
        'filter.targetCostCenterId': teamOrderStore.selectedCostcenterId,
        'filter.processType': processType
      } : processType === 'private' ? {
        'filter.targetBusinesspartnerId': profileStore.profile.id,
        'filter.processType': processType
      } : processType === 'personal' ? {
        'filter.targetBusinesspartnerId': this.targetEmployeeId || profileStore.profile.id,
        'filter.targetActivityId': this.activity.id,
        'filter.processType': processType
      } : {}

      const createParams = processType === 'costCenter' ? {
        'targetOrderProcessType': processType,
        'targetCostCenter': {
          'id': teamOrderStore.selectedCostcenterId
        }
      } : processType === 'storeHouse' ? {
        'targetOrderProcessType': processType,
        'targetStorehouse': {
          'id': teamOrderStore.selectedStorehouseId
        },
        'targetCostCenter': {
          'id': teamOrderStore.selectedCostcenterId
        }
      } : processType === 'private' ? {
        'targetOrderProcessType': processType,
        'targetBusinesspartner': {
          'id': profileStore.profile.id
        }
      } : processType === 'personal' ? {
        'targetOrderProcessType': processType,
        'targetBusinesspartner': {
          'id': this.targetEmployeeId || profileStore.profile.id
        },
        'targetActivity': {
          'id': this.activity.id
        }
      } : {}

      this.baskets = await apiClient.getJson(APIEndpoints.shoppingBaskets, params)
      if (this.baskets.length) {
        this.changeBasket(this.baskets[0])
      } else {
        this.changeBasket(await apiClient.postJson(APIEndpoints.shoppingBaskets, createParams))
      }
      this.initializeDefaultBasketValues()
      this.changeLoadOrCreateBasketIsLoading(false)
      return this.basket
    }
  }



  initializeDefaultBasketValues() {
    //set initial value for activity
    if (this.basket.targetOrderProcessType === "personal") {
      this.activity = this.basket.targetActivity
    }
    //set initial value for basket email
    this.basketEmail = this.basket.email

    //set initial value for basket phone
    this.basketPhone = this.basket.phone

    //set initial value for shipmentType
    this.shipmentType = this.basket.shipmentType

    this.app.checkoutStore.foreignOrderNr = this.basket.foreignOrderNr
    this.app.checkoutStore.orderComment = this.basket.orderComment
    this.app.checkoutStore.articleReturnDate = this.basket.articleReturnDate

    //set initial value for scheduledPickUpDate
    if (this.scheduledPickUpDate === null && this.basket.scheduledPickUpDate) {
      this.scheduledPickUpDate = this.basket.scheduledPickUpDate.slice(0, 10)
    }

    this.isScheduledPickUpDateRequired = this.basket.metaProperties ?
      this.basket.metaProperties.find(prop => prop.pointer === '/scheduledPickUpDate' && prop.attributes === 'isRequired') :
      false

    if (this.basket.targetBusinesspartner && this.basket.targetBusinesspartner.id !== emptyGuid) {
      this.targetEmployeeId = this.basket.targetBusinesspartner.id
    }
    else {
      this.targetEmployeeId = ''
    }

    if (this.basket.targetCostCenter && this.basket.targetCostCenter.id !== emptyGuid) {
      this.showCatalogArticles = true
    }

    // reset sendShipmentSMS checkbox
    this.sendShipmentSMS = false
  }

  getContingentCategorieById(categoryId) {
    const categoryObject = {
      availability: {},
      displayName: null,
      icontType: null,
      id: null,
      imageUrl: null
    }

    if (!this.contingents) {
      return categoryObject
    }

    for (let i in this.contingents) {
      const contingent = this.contingents[i]
      for (let z in contingent.categories) {
        const category = contingent.categories[z]
        if (category.id === categoryId) {
          return {
            availability: category.availability,
            displayName: category.displayName,
            icontType: category.icontType,
            id: category.id,
            imageUrl: category.imageUrl
          }
        }
      }
    }

    return categoryObject
  }

  async loadContingent(force) {
    if (!force) {
      if (this.contingents) {
        return this.contingents
      }
    }
    if (this.loadContingentIsLoading) {
      await this.app.uiStore.waitUntilLoaded(100, 'app.productsStore.loadContingentIsLoading')
      return this.contingents
    } else {
      this.loadContingentIsLoading = true
      try {
        this.contingents = null
        this.contingents = await apiClient.getJson(APIEndpoints.contingent(this.basket.id))
        if (this.useEntitlementSelection) {
          this.initCompetitiveContingent()
        }
      } catch (e) {
        this.contingents = null
      }
      this.loadContingentIsLoading = false
      return this.contingents
    }
  }
  updateCategoryQuantity(categoryAvailability) {
    if (categoryAvailability) {
      const contingentIndex = this.contingents.findIndex(contingent => contingent.categories.some(category => category.id === categoryAvailability.id))
      if (contingentIndex >= 0) {
        const categoryIndex = this.contingents[contingentIndex].categories.findIndex(category => category.id === categoryAvailability.id)
        this.contingents[contingentIndex].categories[categoryIndex].availability = categoryAvailability
        this.filterArticles()
      }
    }
  }

  initCompetitiveContingent() {
    if (this.basket.articles.length && this.basket.articles[0].contingentAvailability) {
      this.selectedContingentId = this.basket.articles[0].contingentAvailability.id
    }
    else if (this.contingents && this.contingents.length) {
      if (this.contingents.length === 1) {
        this.selectedContingentId = this.contingents[0].id
      }
      if (this.selectedContingentId && this.contingents.find((contingent => contingent.id === this.selectedContingentId))) {
        return //will this ever happen?
      }
      //this.selectedContingentId = this.contingents[0].id
    }
  }

  async loadShoppingBasket(shoppingBasketId) {
    if (this.basket && this.basket.id === shoppingBasketId) {
      return this.basket
    }
    this.changeBasket(null)
    this.changeLoadOrCreateBasketIsLoading(true)
    try {
      let basket = await apiClient.getJson(APIEndpoints.shoppingBasket(shoppingBasketId))
      this.changeBasket(basket)
      this.initializeDefaultBasketValues()
    }
    catch (e) {
      console.log(getErrorMessageFromApiResponse(e))
    }
    finally {
      this.changeLoadOrCreateBasketIsLoading(false)
      return this.basket
    }
  }

  async changePositionQuantity(articleBasketId, position, quantity) {
    let params = {
      changedPositions: [{
        id: position.id,
        quantity: quantity
      }]
    }
    // throw new Error('Uh-oh!');
    await apiClient.patchJson(APIEndpoints.shoppingBasketArticle(this.basket.id, articleBasketId), params)
    await this.loadBasket(this.basket.id)

    this.resetProductsOverviewPage()
  }

  async removeAllArticlesFromBasket() {
    this.resetPageErrors()
    try {
      await apiClient.deleteJson(APIEndpoints.shoppingBasket(this.basket.id))
      this.changeBasket(null)
      this.app.uiStore.resetCheckoutLinks()
      this.resetProductsOverviewPage()
      this.app.uiStore.fetchNewShoppingCarts()
    }
    catch (e) {
      this.pageErrors.push(getErrorMessageFromApiResponse(e))
    }
  }

  async removeArticleFromBasket(articleBasketId, positionId) {
    this.resetPageErrors()
    this.removingPositionFromBasket[positionId] = true
    try {
      await apiClient.patchJson(APIEndpoints.shoppingBasketArticle(this.basket.id, articleBasketId), {
        removedPositions: [positionId]
      })
      await this.loadBasket(this.basket.id)
      this.app.uiStore.fetchNewShoppingCarts()
      this.resetProductsOverviewPage()
    } catch (e) {
      this.pageErrors.push(getErrorMessageFromApiResponse(e))
    }
    finally {
      delete this.removingPositionFromBasket[positionId]
    }
  }

  async setApiBasket(basket) {
    const basketResult = await apiClient.patchJson(APIEndpoints.shoppingBasket(this.basket.id), basket)
    this.changeBasket(basketResult)
    return basketResult
  }

  async setShipmentTypeToBasket(shipmentType) {

    //first check if shipmentType is available, otherwise set default shipmentType or first one in set of available shipment types
    const defaultShipmentType = this.shipmentTypes.find(shipType => shipType.isDefault) || this.shipmentTypes[0]
    if (!this.shipmentTypes.find(shipType => shipType.id === shipmentType.id)) {
      shipmentType = defaultShipmentType
    }
    await this.setApiBasket({ shipmentType: shipmentType })
  }

  async setShipmentAddressToBasket() {

    //check if shipmentAddress is still available in set of availableAddresses und use that one, because the values in the address possibly changed
    const shipmentAdressInAvailableAddresses = this.availableAddresses.find(address => address.id === this.shipmentAddress.originAddressId)
    if (shipmentAdressInAvailableAddresses) {
      this.shipmentAddress = shipmentAdressInAvailableAddresses
    }
    await this.setApiBasket({
      shipmentAddress: this.shipmentAddress
    })

  }

  async setCustomShipmentAddressToBasket() {
    await this.setApiBasket({
      shipmentAddress: this.customShipmentAddress
    })
  }

  async setContactInfoToBasket() {
    await this.setApiBasket({
      phone: this.basketPhone,
      email: this.basketEmail,
    })
  }

  async setBillingAddressToBasket() {
    //check if shipmentAddress is still available in set of availableAddresses und use that one, because the values in the address possibly changed
    const billingAddressInAvailableAddresses = this.availableAddresses.find(address => address.id === this.billingAddress.originAddressId)
    if (billingAddressInAvailableAddresses) {
      this.billingAddress = billingAddressInAvailableAddresses
    }

    await this.setApiBasket({
      billingAddress: this.billingAddress
    })
  }

  async setCustomBillingAddressToBasket() {
    await this.setApiBasket({
      billingAddress: this.customBillingAddress
    })
  }

  async loadAddressSettings() {
    if (Object.keys(this.customAddressSettings).length) {
      return
    }
    let weirdSettingStructure = await apiClient.getJson(APIEndpoints.shoppingCartTunnel)
    let betterSettingStucture = {}
    weirdSettingStructure.forEach(setting => { betterSettingStucture[setting.settingName] = settingValueToType(setting) })
    this.customAddressSettings = betterSettingStucture
  }

  async loadAvailableAddresses() {
    //always load data, because someone can edit the adresses and come back
    if (this.loadAvailableAddressesIsLoading) {
      await this.app.uiStore.waitUntilLoaded(100, 'app.productsStore.loadAvailableAddressesIsLoading')
      return this.availableAddresses
    } else {
      this.loadAvailableAddressesIsLoading = true
      this.availableAddresses = await apiClient.getJson(APIEndpoints.availableAddresses(this.basket.id))
      this.loadAvailableAddressesIsLoading = false
      this.initializeBillingAddresses()
      this.initializeShipmentAddresses()
      return this.availableAddresses
    }
  }

  async initializeBillingAddresses() {
    if (!this.app.checkoutStore.isBillingAddressAvailable()) {
      return null
    }
    const basketBillingAddress = this.basket.billingAddress
    const availableBillingAddresses = this.getAvailableBillingAddresses()
    //address was saved into shoppingBasket before as cutomAddress, but customAddresses are not allowed
    const specialCase1 = (basketBillingAddress.originAddressId === emptyGuid) && !this.app.checkoutStore.isCustomBillingAddressAvailable()
    // address was saved from available address but doesnt exist anymore
    const specialCase2 = (basketBillingAddress.originAddressId !== emptyGuid) && !availableBillingAddresses.find(address => address.id === basketBillingAddress.originAddressId)
    //address was saved into shoppingBasket before
    if (basketBillingAddress.id
      && (basketBillingAddress.id !== emptyGuid)
      && !specialCase1
      && !specialCase2
    ) {
      //addres was chosen from available Addresses
      if (basketBillingAddress.originAddressId && basketBillingAddress.originAddressId !== emptyGuid) {
        this.billingAddress = basketBillingAddress
      }
      //address was created from customAddress
      else {
        Object.keys(this.customBillingAddress).forEach(key => {
          if (basketBillingAddress.hasOwnProperty(key)) {
            this.customBillingAddress[key] = basketBillingAddress[key]
          }
        })
        this.customBillingAddressSelected = true
      }

    } else {
      if (availableBillingAddresses.length === 0 && this.app.checkoutStore.isCustomBillingAddressAvailable()) {
        this.customBillingAddressSelected = true
      } else {
        const firstBillingAddress = availableBillingAddresses.length ? availableBillingAddresses[0] : {}
        const defaultBillingAddress = availableBillingAddresses.find(address => address.isDefaultAddress)
        this.setBillingAddress(defaultBillingAddress || firstBillingAddress)
      }
    }
  }

  async initializeShipmentAddresses() {
    if (!this.app.checkoutStore.isShipmentAddressAvailable()) {
      return null
    }
    const basketShipmentAddress = this.basket.shipmentAddress
    const availableShipmentAddresses = this.getAvailableShipmentAddresses()

    //address was saved into shoppingBasket before as cutomAddress, but customAddresses are not allowed anymore 
    const specialCase1 = (basketShipmentAddress.originAddressId === emptyGuid) && !this.app.checkoutStore.isCustomShipmentAddressAvailable()
    // address was saved from available address but doesnt exist anymore
    const specialCase2 = basketShipmentAddress.originAddressId !== emptyGuid && !availableShipmentAddresses.find(address => address.id === basketShipmentAddress.originAddressId)
    //address was saved into shoppingBasket before
    if (basketShipmentAddress.id
      && (basketShipmentAddress.id !== emptyGuid)
      && !specialCase1
      && !specialCase2
    ) {
      //addres was chosen from available Addresses
      if (basketShipmentAddress.originAddressId && (basketShipmentAddress.originAddressId !== emptyGuid)) {
        this.shipmentAddress = basketShipmentAddress
      }
      //address was created from customAddress
      else {
        Object.keys(this.customShipmentAddress).forEach(key => {
          if (basketShipmentAddress.hasOwnProperty(key)) {
            this.customShipmentAddress[key] = basketShipmentAddress[key]
          }
        })
        this.setCustomShipmentAddressSelected(true)
      }
    } else {
      //no available Addresses to select from but custom address is allowed
      if (availableShipmentAddresses.length === 0 && this.app.checkoutStore.isCustomShipmentAddressAvailable()) {
        this.setCustomShipmentAddressSelected(true)
      } else {
        const firstShipmentAddress = availableShipmentAddresses.length ? availableShipmentAddresses[0] : {}
        const defaultShipmentAddress = availableShipmentAddresses.find(address => address.isDefaultAddress)
        this.setShipmentAddress(defaultShipmentAddress || firstShipmentAddress)
      }
    }
  }

  getAvailableBillingAddresses() {
    let availableBillingAddresses = []
    if (!this.availableAddresses) {
      return availableBillingAddresses
    }

    this.availableAddresses.forEach(address => {
      if (availableAsBillingAddress(address.addressType)) {
        availableBillingAddresses.push(address)
      }
    })
    return availableBillingAddresses
  }

  getAvailableShipmentAddresses() {
    let availableShipmentAddresses = []
    if (!this.availableAddresses) {
      return availableShipmentAddresses
    }

    this.availableAddresses.forEach(address => {
      if (availableAsShipmentAddress(address.addressType)) {
        availableShipmentAddresses.push(address)
      }
    })
    return availableShipmentAddresses
  }

  async loadShipmentTypes(force) {
    if (!force) {
      if (this.shipmentTypes) {
        return this.shipmentTypes
      }
    }
    if (this.loadShipmentTypesIsLoading) {
      await this.app.uiStore.waitUntilLoaded(100, 'app.productsStore.loadShipmentTypesIsLoading')
      return this.shipmentTypes
    } else {
      this.loadShipmentTypesIsLoading = true
      try {
        this.shipmentTypes = await apiClient.getJson(APIEndpoints.shipmentTypes(this.basket.id))
        this.loadShipmentTypesIsLoading = false
        //set default shipmentType
        if (this.shipmentType === null || !this.shipmentTypes.find(shipmentType => shipmentType.id !== this.shipmentType.id)) {
          this.setShipmentType(this.shipmentTypes.find(shipmentType => shipmentType.isDefault) || this.shipmentTypes[0])
        }
      }
      catch (error) { this.pageErrors.push(getErrorMessageFromApiResponse(error)) }
      return this.shipmentTypes
    }
  }

  handleChangeCustomShipmentAddress = (e) => {
    const fieldName = e.target.name
    if (this.customShipmentAddress.hasOwnProperty(fieldName)) {
      if (e.target.type === "checkbox") {
        this.customShipmentAddress[fieldName] = e.target.checked
      }
      else {
        this.customShipmentAddress[fieldName] = e.target.value
      }
      if (this.requiredFieldsError.customShipmentAddress.hasOwnProperty(fieldName)) {
        this.requiredFieldsError.customShipmentAddress[fieldName] = false
      }
    }
  };

  handleBasketEmailChange = (e) => {
    this.basketEmail = e.target.value
    this.requiredFieldsError.basketEmail = false
    this.fieldErrors.basketEmail = false
    this.setContactInfoToBasketDebounced()
  };

  handleBasketPhoneChange = (e) => {
    if (!e.target.value) {
      this.sendShipmentSMS = false;
    }

    this.basketPhone = e.target.value
    this.requiredFieldsError.basketPhone = false
    this.fieldErrors.basketPhone = false
    this.setContactInfoToBasketDebounced()
  };

  handleScheduledPickUpDateChange = async (value) => {
    this.scheduledPickUpDate = value
    this.requiredFieldsError.scheduledPickUpDate = false
    this.fieldErrors.scheduledPickUpDate = false

    await this.setApiBasket({
      scheduledPickUpDate: this.scheduledPickUpDate
    })

  };

  setContactInfoToBasketDebounced = debounce(700, () => {
    this.setContactInfoToBasket()
  })

  handleChangeCustomBillingAddress = (e) => {
    const fieldName = e.target.name
    if (this.customBillingAddress.hasOwnProperty(fieldName)) {
      this.customBillingAddress[fieldName] = e.target.value
      if (this.requiredFieldsError.customBillingAddress.hasOwnProperty(fieldName)) {
        this.requiredFieldsError.customBillingAddress[fieldName] = false
      }
    }
  };

  handleConfirmAddressSubmit = navigate => async (e) => {
    this.confirmAddressIsSubmitting = true
    this.resetAllErrors()

    try {
      await this.setContactInfoToBasket()
      if (this.app.checkoutStore.isShipmentAddressAvailable()) {
        if (this.customShipmentAddressSelected) {
          await this.setCustomShipmentAddressToBasket()
        } else {
          await this.setShipmentAddressToBasket()
        }
      }
      if (this.app.checkoutStore.isBillingAddressAvailable()) {
        if (this.customBillingAddressSelected) {
          await this.setCustomBillingAddressToBasket()
        } else {
          await this.setBillingAddressToBasket()
        }
      }

      await this.setShipmentTypeToBasket(this.shipmentType)
    } catch (e) {
      //API Errors
      this.handleConfirmAddressSubmitErrors(e)
    }
    this.confirmAddressIsSubmitting = false
    if (this.validateConfirmAddressPage()) {
      //scroll to first error
      if (this.pageErrors.length) {
        scrollToDataId('pageErrors')
      } else {
        scrollToElement(document.querySelector('[class~="is-invalid"]'))
      }
      return
    }

    navigate(this.app.uiStore.linkToConfirmOrder)
  };

  resetPageErrors() {
    this.pageErrors = []
    this.pageErrorPointers = {}
  }

  handleConfirmOrderSubmit = async (navigate, mandate) => {
    this.resetPageErrors()
    this.confirmOrderIsSubmitting = true
    let returnValue = {}
    let response = {};

    try {
      response = await apiClient.postJson(APIEndpoints.acceptedShoppingBaskets, {
        id: this.basket.id,
        sendShipmentSMS: this.sendShipmentSMS
      }, true, true)

      if (!this.app.teamOrderStore.privateOrder) {
        this.changeBasket(null)
        this.resetProductsOverviewPage()
        this.app.profileStore.profile.id = null
        this.app.profileStore.loadProfile()
      }

    } catch (e) {
      if (e.response && e.response.data && e.response.data.errors) {
        //to mark form fields as invalid
        //filter additionalConditions which are checked
        let newErrors = []
        for (let error of e.response.data.errors) {
          if (error.source && error.source.pointer && this.app.checkoutStore.additionalConditions[error.source.pointer]) continue;
          newErrors.push(error)
        }
        e.response.data.errors = newErrors

        //write pageErrorPointers
        e.response.data.errors.forEach(error => {
          if (error.source && error.source.pointer) {
            this.pageErrorPointers[error.source.pointer] = true
          }
        })

        //show other Checkboxes
        this.app.checkoutStore.showShipmentPriceAccepted = !!e.response.data.errors.find(error => error.source && error.source.pointer === '/shipmentPriceIsAccepted')
        this.app.checkoutStore.showEntitlementExceedanceIsAccepted = !!e.response.data.errors.find(error => error.source && error.source.pointer === '/entitlementExceedanceIsAccepted')
        this.app.checkoutStore.showPointsCreditIsAccepted = !!e.response.data.errors.find(error => error.source && error.source.pointer === '/pointsCreditIsAccepted')
      }
      this.pageErrors.push(getErrorMessageFromApiResponse(e))
    }

    this.confirmOrderIsSubmitting = false
    this.app.uiStore.fetchNewShoppingCarts()

    if (this.pageErrors.length === 0) {
      if (this.app.teamOrderStore.privateOrder) {
        if (response && response.data && response.data.paymentProviderUrl) {
          //open cws modal before redirect
          if (mandate === "cws") {
            returnValue.paymentProviderUrl = response.data.paymentProviderUrl
          }
          else {
            window.open(response.data.paymentProviderUrl, "_self")
          }
        }
        // else { this.pageErrors.push("no paymentProviderUrl") }
        else {
          const orderId = response && response.data && response.data.orderId
          const message = response && response.data && response.data.message
          const keepArticleOnCart = response && response.data && response.data.keepArticleOnCart
          const pushObj = { to: this.app.uiStore.linkToSuccessOrder, state: { orderId, message, keepArticleOnCart } }
          navigate(pushObj.to, { state: pushObj.state })
        }
      }
      else if (response && response.data && response.data.checkoutUrl) {
        window.open(response.data.checkoutUrl, "_self")
      }
      else {
        const orderId = response && response.data && response.data.orderId
        const message = response && response.data && response.data.message
        const keepArticleOnCart = response && response.data && response.data.keepArticleOnCart
        const pushObj = { to: this.app.uiStore.linkToSuccessOrder, state: { orderId, message, keepArticleOnCart } }
        //open sixt modal befor redirect
        if (response && response.data && response.data.keepArticleOnCart && mandate === "sixt") {
          returnValue.keepArticleOnCart = true
          returnValue.pushObj = pushObj
        }
        else {
          navigate(pushObj.to, { state: pushObj.state })
        }
      }
      this.app.teamOrderStore.resetOrderType()
    }

    return returnValue
  }

  resetAllErrors() {
    this.pageErrors = []
    this.fieldErrors = { ...this._fieldErrors }
    this.requiredFieldsError = { ...this._requiredFieldsError }
  }

  handleConfirmAddressSubmitErrors(e) {
    if (e.response && e.response.data && e.response.data.errors) {
      e.response.data.errors.forEach(error => {
        if (error.source) {
          switch (error.source.pointer) {
            case '/email':
              this.fieldErrors.basketEmail = error.detail
              break
            case '/phone':
              this.fieldErrors.basketPhone = error.detail
              break
            case '/scheduledPickUpDate':
              this.fieldErrors.scheduledPickUpDate = error.detail
              break
            default:
              this.pageErrors.push(error.detail)
          }
        }
        else if (error.detail) {
          this.pageErrors.push(error.detail)
        }
      })
    }
  }

  validateConfirmAddressPage() {
    let hasErrors = false

    //validate required fields
    if (this.customShipmentAddressSelected && this.basket.shipmentAddress !== null) {
      for (const field of Object.keys(this.requiredFieldsError.customShipmentAddress)) {
        if ((field === "officeName")
          || (field === "firstName" && this.customAddressSettings.addressHideFirstname)
          || (field === "lastName" && this.customAddressSettings.addressHideLastname)
        ) {
          continue
        }
        else if (!this.customShipmentAddress[field]) {
          hasErrors = true
          this.requiredFieldsError.customShipmentAddress[field] = true
        }
      }
    }

    if (this.customBillingAddressSelected && this.basket.billingAddress !== null) {
      for (const field of Object.keys(this.requiredFieldsError.customBillingAddress)) {
        if ((field === "officeName")
          || (field === "firstName" && this.customAddressSettings.addressHideFirstname)
          || (field === "lastName" && this.customAddressSettings.addressHideLastname)
        ) {
          continue
        }
        else if (!this.customBillingAddress[field]) {
          hasErrors = true
          this.requiredFieldsError.customBillingAddress[field] = true
        }
      }
    }
    //validate fieldErrors
    if (Object.values(this.fieldErrors).some(value => value)) {
      hasErrors = true
    }
    //validate pageErrors
    if (this.pageErrors.length) {
      hasErrors = true
    }
    //validate basket metaProperties
    if (this.basket.metaProperties) {
      this.basket.metaProperties.forEach(prop => {
        if (prop.attributes !== 'isRequired') {
          return
        }

        switch (prop.pointer) {
          case '/email':
            this.requiredFieldsError.basketEmail = !this.basketEmail
            hasErrors = hasErrors || !this.basketEmail
            break
          case '/phone':
            this.requiredFieldsError.basketPhone = !this.basketPhone
            hasErrors = hasErrors || !this.basketPhone
            break
          case '/scheduledPickUpDate':
            this.requiredFieldsError.scheduledPickUpDate = !this.scheduledPickUpDate
            hasErrors = hasErrors || !this.scheduledPickUpDate
            break
          default:
        }
      })
    }
    return hasErrors
  }

  getBasketArticlesAmount() {
    return this.basket && this.basket.totalQuantityOrdered
    // return this.basket && this.basket.articles ? this.basket.articles.reduce((acc, cur) => {return acc + cur.positions.length}, 0) : 0
  }

  fixShoppingBasketStatus = async (navigate) => {
    if (this.basket.isWaitingForPayment) {
      const paymentStatus = await apiClient.postJson(APIEndpoints.paymentStatus(this.basket.id))
      if (paymentStatus && paymentStatus.paymentStatus === "succeeded") {
        navigate('/')
        this.app.uiStore.fetchNewShoppingCarts()
      }
      return paymentStatus
    }
    return null
  }

  saveBasketAsTemplate = async (name, navigate) => {
    this.resetAllErrors()
    try {
      this.saveBasketAsTemplateButtonLoading = true
      const response = await apiClient.postJson(APIEndpoints.orderTemplates(this.basket.id), name, true, true)
      if (response.data && response.data.id) {
        const basket = await this.app.ordersStore.reorder(response.data.id)
        this.app.uiStore.fetchNewShoppingCarts()
        if (basket) {
          navigate(`/costcenter/${this.basket.targetCostCenter.id}/shoppingcart/${basket.id}/confirmaddress/confirmorder`)
        }
      }
    }
    catch (error) { this.pageErrors.push(getErrorMessageFromApiResponse(error)) }
    finally {
      this.saveBasketAsTemplateButtonLoading = false
    }
  };
  async loadShoppingBasketAvailabilities(shoppingBasketId) {
    this.basketAvailabilities = []
    this.basketAvailabilities = await apiClient.getJson(APIEndpoints.shoppingBasketAvailabilities(shoppingBasketId))
  }

  onSwitchContingentHandler(contingentId) {
    let isDifferentSelection = contingentId !== this.selectedContingentId
    if (this.basket && this.basket.articles.length && isDifferentSelection) {
      this.selectedContingentIdNew = contingentId
      this.openSwitchContingentModal()
    } else {
      // only reset when switching contingent, not when scrolling to the selected group
      if (isDifferentSelection) {
        this.app.productListStore.resetProductList()
        window.scrollTo({ top: 0, behaviour: "smooth" })
      }
      this.selectedContingentId = contingentId
    }
  }
  switchContingentIsLoading = false;
  async onSwitchContingentModalAction() {
    this.switchContingentIsLoading = true
    try {
      await this.removeAllArticlesFromBasket()
      await this.loadOrCreateBasket(true)

      this.selectedContingentId = this.selectedContingentIdNew
      this.closeSwitchContingentModal()
    }
    catch (e) { }
    finally {
      this.switchContingentIsLoading = false
    }
  }

  openOrderInfoModal() {
    this.showOrderInfoModal = true
  }
  closeOrderInfoModal() {
    this.showOrderInfoModal = false
  }
  openSwitchContingentModal() {
    this.showSwitchContingentModal = true
  }
  closeSwitchContingentModal() {
    this.showSwitchContingentModal = false
  }
  openArticleScanModal() {
    this.showArticleScanModal = true
  }
  closeArticleScanModal() {
    this.showArticleScanModal = false
  }

  orderInfo = {};
  loadOrderInfo() {
    apiClient.getJson(APIEndpoints.orderInfo(this.basket.id))
      .then(res => {
        this.orderInfo = res
        this.infoText = res.infoText
        this.orderInfoModalTitle = res.type ? res.type.displayName : null
        let firstOrder = !res.orderCounter
        this.useEntitlementSelection = res.phase ? res.phase.useEntitlementSelection : null
        this.initialClothing = res.phase && res.phase.isFirstPhase
        this.orderForFree = res.forFree
        this.hidePricesAndPoints = res.hidePricesAndPoints
        firstOrder && this.infoText && this.openOrderInfoModal()
      }).catch(err => {
        console.log('orderInfo call error', err)
      })
  }

  //KN-165 logic
  getSmartMin = (minQuantitiy, packageSize) => {
    let newMin = minQuantitiy < packageSize ? packageSize : minQuantitiy;
    if (newMin % packageSize) {
      newMin += packageSize - (minQuantitiy % packageSize);
    }
    return newMin;
  }

  getMinQuantity = (existingAmount, packageSize, minimumQuantity) => {
    //once the min amount is already in the basket. the min amount changes.
    if (existingAmount) {
      return packageSize || 1
    }
    else {
      return this.getSmartMin(minimumQuantity, packageSize) || 1 //KN-165 logic
    }
  };

  getSelectedQuantity = (existingAmount, packageSize, minimumQuantity, recommendedAmount) => {
    //once the min amount is already in the basket. the default selected amount changes
    if (existingAmount) {
      if (this.initialClothing) {
        return 0
      }
      else {
        return packageSize || 1
      }
    }
    else {
      if (this.initialClothing) {
        return recommendedAmount || 0
      }
      else {
        return this.getSmartMin(minimumQuantity, packageSize) || 1
      }
    }
  };

  validateBasketArticles = async (navigate) => {
    this.resetPageErrors();

    try {
      const response = await apiClient.postJson(APIEndpoints.validator(this.basket.id), { checkContingentRules: true }, true, true)
      if (response.status === 204) {
        navigate(this.app.uiStore.linkToConfirmAddress)
      }
    } catch (e) {
      this.pageErrors.push(getErrorMessageFromApiResponse(e))
    }
  }

  handleSendShipmentSMSChange = (e) => {
    this.sendShipmentSMS = e.target.checked
  }

}

export default ProductsStore
