import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import CatalogCategoryFilter from './CatalogCategoryFilter'


const StyledOffCanvasContainer = styled.div`
  background: ${colors.gray1}
  border-right: 1px solid ${colors.gray2}
  padding: 40px 0;
  position: relative;
  margin-right: -15px;
  height: 100%;
`

const StyledContainer = styled.div`
  @media screen and (max-width: 992px) {
    &.closed {
      display: none;
    }
  }

  &.open {
    position: absolute;
    height: 100%;
    left: 0;
    width: 80%;
  }
`

const StyledStickyWrapper = styled.div`
  position: sticky;
  top: 15px;
  max-height: 100%;
  overflow: auto;
`

const CatalogCategories = () => {
    return (
        <StyledContainer>
            <StyledOffCanvasContainer className="opened">
                <StyledStickyWrapper>
                    <CatalogCategoryFilter />
                </StyledStickyWrapper>
            </StyledOffCanvasContainer>
        </StyledContainer>
    )
}

export default CatalogCategories
