import React from 'react'
import { colors } from '../../styles/colors'
import styled from 'styled-components'
import { formatedPriceObject, priceOrPoints } from '../../utils/formatedPrice'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import getThemeValue from '../../utils/getThemeValue'
const mandate = process.env.REACT_APP_MANDATORSET
const StyledContainerLabel = styled.div`
  font-size: 26px;
  font-weight: 600;
`
const StyledSummaryBody = styled.div`
  border-top: 1px solid ${colors.gray2};
  border-bottom: 1px solid ${colors.gray2};
  padding: 15px 0;
  margin: 15px 0 25px 0;
`
const PriceSummary = inject('productsStore', 'teamOrderStore')(observer((props) => {
    const { productsStore, teamOrderStore, withShipmentPrice } = props
    const { t } = useTranslation()
    const { targetEmployee } = productsStore
    const basket = productsStore.basket ? productsStore.basket : {}

    const shipmentPriceValue = priceOrPoints((basket && basket.shipmentPrice) || {})
    const sign = !teamOrderStore.selectedCostcenterId && targetEmployee.points != null ? '-' : ''
    const formatedBasketSum = formatedPriceObject((basket && basket.totalPositionsValue) || {})
    const formatedShipmentPrice = formatedPriceObject((basket && basket.shipmentPrice) || {})
    const vATDisplayType = basket.totalValue && basket.totalValue.vATDisplayType
    const sumTotalPositionsVatVAlues = (basket.totalPositionsValue && basket.totalPositionsValue.vATValues && basket.totalPositionsValue.vATValues.reduce((a, b) => +a + +b.amount, 0)) || 0
    const hideShippingInfo = getThemeValue('removeShippingCostInfos', null, false)


    const basketTotalSum = withShipmentPrice ?
        basket.totalValue && formatedPriceObject(basket.totalValue)
        : vATDisplayType === "netVATGross" ?
            basket.totalPositionsValue && formatedPriceObject({ ...basket.totalPositionsValue, price: basket.totalPositionsValue.price + sumTotalPositionsVatVAlues })
            : basket.totalPositionsValue && formatedPriceObject(basket.totalPositionsValue)

    const listNetVatGrossVatValues = () => {
        const total = withShipmentPrice ? "totalValue" : "totalPositionsValue"

        return basket[total] ?
            <>
                {
                    basket[total].vATValues.map(obj => <div key={obj.rate} className='h2 d-flex justify-content-between mb-2'>
                        <div><ul><li>{mandate === "wf"
                            ? t('wf:vat')
                            : t('shoppingCart:vat')
                        }: {obj.rate} %</li></ul></div>
                        <div>{sign ? sign : '+'} {formatedPriceObject({ price: obj.amount, currency: basket.totalValue.currency, points: null })}</div>
                    </div>
                    )
                }
            </> : null
    }

    const listGrossVatVAlues = () => {
        const total = withShipmentPrice ? "totalValue" : "totalPositionsValue"
        return basket[total] ?
            <>
                <div>
                    <small><i>{mandate === "wf" ? t('wf:vatIncluded') : t('shoppingCart:vatIncluded')}</i></small>
                </div>
                {
                    basket[total].vATValues.map(obj => <div key={obj.rate} className=' d-flex justify-content-between mb-2'>
                        <small><i>{obj.rate} %</i></small>
                        <small><i>{formatedPriceObject({ price: obj.amount, currency: basket.totalValue.currency, points: null })}</i></small>
                    </div>
                    )
                }
            </>
            : null
    }
    if(!basket.targetOrderProcessType)
    {
        basket.targetOrderProcessType = teamOrderStore.getCurrentProcessType()
    }
    return (
        <React.Fragment>
            <StyledContainerLabel>{t('shoppingCart:totalTitle')}</StyledContainerLabel>
            <StyledSummaryBody>
                {basket.targetOrderProcessType === 'personal' && targetEmployee && targetEmployee.points ?
                    <div className='d-flex justify-content-between mb-2'>
                        <div>{t('shoppingCart:currentContingent')}</div>
                        <div>{targetEmployee.points} {t('shoppingCart:points')}</div>
                    </div>
                    : ''}
                {(withShipmentPrice && shipmentPriceValue && formatedShipmentPrice) ?
                    <div className='d-flex justify-content-between mb-2'>
                        <div>{t('shoppingCart:shipping')}</div>
                        <div>{sign ? sign : '+'}{formatedShipmentPrice}</div>
                    </div>
                    : ''}
                <div className='h2 d-flex justify-content-between'>
                    <div>{t('shoppingCart:sumShoppingCart')}</div>
                    <div className='text-primary fw-bold'>
                        {(basket.totalPositionsValue && priceOrPoints(basket.totalPositionsValue)) ?
                            <React.Fragment>
                                {sign} {formatedBasketSum}
                            </React.Fragment>
                            : formatedBasketSum}
                    </div>
                </div>

                {(vATDisplayType === "netVATGross") ?
                    listNetVatGrossVatValues() :
                    ''}

                {(!withShipmentPrice && !hideShippingInfo) &&
                    <div>
                        <small><i>{t('shoppingCart:shippingInNextStep')}</i></small>
                    </div>}
            </StyledSummaryBody>

            <div className='h2 d-flex justify-content-between mb-2'>
                <div>{t('shoppingCart:totalSum')}</div>
                <div className='text-primary fw-bold'>
                    {sign}
                    {basketTotalSum}
                </div>
            </div>
            {(vATDisplayType === "net") ?
                <div>
                    <small><i>{getThemeValue('shoppingCart', 'vatNet', t('shoppingCart:vatInclusive'))}</i></small>
                </div> :
                ''}
            {(vATDisplayType === "grossVAT") ?
                listGrossVatVAlues() :
                ''}
            {(basket.targetOrderProcessType === 'personal' && targetEmployee.points != null && basket.totalPositionsValue) &&
                <div className='mt-3 mb-4 text-end'>
                    <small>
                        ({t('shoppingCart:remainingContingent')}: {(targetEmployee.points - basket.totalPositionsValue.points - ((withShipmentPrice && basket.shipmentPrice) ? basket.shipmentPrice.points : 0)).toFixed(2)} {t('shoppingCart:points')})
                    </small>
                </div>}
        </React.Fragment>
    )
}
))
export default PriceSummary
