import styled from 'styled-components'
import { colors } from '../../../styles/colors'

export const StyledCollapseTrigger = styled.div`
  cursor: pointer;

  
  h1 {
    display: flex;
    align-items: flex-start;
  }
  @media screen and (max-width: 1080px) {
    margin-left: 0px;

  }

`
export const StyledValidationMessage = styled.div`
  margin-top: 4px;
  font-size: 80%;
  color: ${colors.red};
`
export const StyledJobfunctionContainer = styled.div`
  background: white;

  .spacing-down {
    margin-bottom: 40px;
  }

  .added-row {
    margin-bottom: 40px;
    border-bottom: 1px solid ${colors.gray2};
  }

  .delete-row {
    padding: 0;
    .delete-btn{
      margin-top: 3px;
      display: inline-block;
      cursor: pointer;
    }

    .form-label {
      display: block;
    }
  }
  .delete-row-center {
    padding: 0;
    display: flex;
  justify-content: center;
   
    .delete-div{
      display: inline-block;
      
     
    }
  }

  .dropdown.btn-group {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .dropdown-toggle-split{
      height: 36px;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .card {
    border: 0;
    &-header {
      background-color: white;
      padding: .75rem 0;
      border-bottom: 0;

      .btn {
        padding: .375rem 0;
      }
    }
  }
`
