import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Form } from 'react-bootstrap'
import { emptyGuid } from '../../../../utils/emptyGuid'
import OverlayContainer from '../../../Page/logix2020/OverlayContainer'
import OverlayRight from '../../../Page/logix2020/OverlayRight'
import OfficesTree from '../../../Offices/OfficesTree'
import StructureIcon from '../../../Icons/StructureIcon'
import styled from 'styled-components'
import { VisualInput } from '../../../Forms/VisualInput'
import { StyledValidationMessage } from '../formHelper'
import { useTranslation } from 'react-i18next'
import useStores from '../../../../stores/useStore'
import { useObserver } from 'mobx-react'

const StyledButton = styled.div`
cursor:pointer;
  border: 0;
  background: none;
  padding: 0;
  margin: 0 0 0 10px;
`

const OfficeSelect = ({
  id,
  label,
  onChange,
  value,
  isInvalid = false,
  withSearch = false,
  noMargin = false,
  errorMessage,
  overLayZIndex,
  showTopLevel
}) => {
  const [officeName, setOfficeName] = useState('')
  const [offices, setOffices] = useState(null)
  const { t } = useTranslation()
  const mountedRef = useRef(true)
  const { officeStore, overlayRightStore, profileStore } = useStores()
  const showOfficeTree = useObserver(() => overlayRightStore.openOverlay === id)

  //Cleanup Function
  useEffect(() => {
    return () => { mountedRef.current = false }
  }, [])

  useEffect(() => {
    officeStore.getOffices(emptyGuid).then(result => {
      if (!mountedRef.current) { return null }
      setOffices(result)
    })
  }, [officeStore])

  const loadOfficeName = useCallback(async (value) => {
    var loadedOfficeName = ''
    try {
      const loadedOffice = await officeStore.getOffice(value)
      loadedOfficeName = loadedOffice.displayName
    } finally {
      setOfficeName(loadedOfficeName)
    }
  }, [officeStore])

  useEffect(() => {
    if (value && offices !== null && value !== emptyGuid) {
      const foundOffice = offices.find(item => item.id === value)
      if (foundOffice) {
        setOfficeName(foundOffice.displayName)
      }
      else {
        loadOfficeName(value)
      }
    }
    else if (showTopLevel && value === emptyGuid) {
      setOfficeName(profileStore.profile.mandator.displayName)
    }
    else {
      setOfficeName('')
    }
  }, [value, offices, loadOfficeName, profileStore.profile.mandator.displayName, showTopLevel])



  const handleClickOffice = (office) => {
    setOfficeName(office.displayName)
    onChange(office.id)
    overlayRightStore.closeAll()
  }

  return (
    <>
      <Form.Group className={noMargin ? 'mb-0' : 'mb-3'}>
        {label && <Form.Label>{label}</Form.Label>}
        <div className="d-flex align-items-start">
          <VisualInput style={{ overflow: "auto" }} className={isInvalid ? 'is-invalid' : ''} onClick={() => overlayRightStore.openOrClose(id)}>{officeName}</VisualInput>
          <StyledButton onClick={() => overlayRightStore.openOrClose(id)}><StructureIcon /></StyledButton>
        </div>
        {errorMessage && <StyledValidationMessage>{errorMessage}</StyledValidationMessage>}
      </Form.Group>
      <OverlayContainer >
        <OverlayRight headerTitle={t('orgaUnit')} overLayZIndex={overLayZIndex} visible={showOfficeTree} onClose={() => { overlayRightStore.closeAll() }}>
          <div className="content">
            {showOfficeTree && (
              <OfficesTree showTopLevel={showTopLevel} onClick={handleClickOffice} isClickable={() => true} withSearch={withSearch} />
            )}
          </div>
        </OverlayRight>
      </OverlayContainer>
    </>
  )
}

export default OfficeSelect
