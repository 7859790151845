import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { PositionItemImageAmount } from '../Orders/Detail/PositionItemImageAmount'
import ComplaintChat from './Chat/ComplaintChat'
import { Accordion } from 'react-bootstrap'
import HeaderIcons from './HeaderIcons'
import PositionDetails from './PositionDetails'
import { useCallback } from 'react'
import useMountEffect from '../../utils/hooks/useMountEffect'


const ComplaintCollapsed = ({ item, isReclamationClosed, loadMessages, messagesLoading, messages, reloadComplaint }) => {

  const [positions, setPositions] = useState([])

  const [positionsLoading, setPositionsLoading] = useState(false)
  const isComplaint = item.type === 'complaint'
  const isRetoure = item.type === 'retoure'
  const itemId = item.id



  const loadPositions = useCallback(async () => {
    try {
      setPositionsLoading(true)
      const positions = await apiClient.getJson(APIEndpoints.returns(itemId).positions)
      setPositions(positions)
    }
    finally {
      setPositionsLoading(false)
    }
  }, [itemId])

  const initData = useCallback(() => {
    loadMessages()
    isRetoure && loadPositions()
  }, [isRetoure, loadMessages, loadPositions])

  useMountEffect(() => {
    initData()
  })

  return (
    <>
      {messagesLoading || (isRetoure && positionsLoading)
        ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
        : <div>
          {isRetoure ? <div className="mb-3">
            {positions.map(position =>
              <Accordion flush key={position.id}>
                <Accordion.Item eventKey={position.id}>
                  <Accordion.Header><PositionItemImageAmount orderPosition={position} quantity={position.quantity} />
                    <HeaderIcons actionToPerform={position.actionToPerform} />
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <PositionDetails position={position} />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
          </div> : null}
          {messages.length ? <ComplaintChat
            itemId={itemId}
            loadMessages={loadMessages}
            messages={messages}
            isComplaint={isComplaint}
            isReclamationClosed={isReclamationClosed}
            reloadComplaint={reloadComplaint}
          /> : null}
        </div>
      }
    </>
  )
}

export default ComplaintCollapsed