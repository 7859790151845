import styled from 'styled-components'

export const ArrowIcon = styled.span`
  display: inline-block;
  width: 17px;
  height: 17px;
  padding: 3px;
  cursor: pointer;
  align-self: center;

  &::before{
    content: '';
    width: 100%;
    height: 100%;
    display: block;    
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${props => encodeURIComponent(props.color? props.color: '#00035c')}' viewBox='0 0 12 12'%3E%3Cpath d='M9.3 11.6c-.6.6-1.6.6-2.2 0L2.8 7.3c-.3-.3-.5-.8-.4-1.2 0-.4.1-.9.4-1.2L7.1.6C7.7 0 8.7 0 9.3.6c.6.6.6 1.6 0 2.2L5.9 6l3.4 3.4c.6.6.6 1.6 0 2.2z' fill-rule='evenodd' clip-rule='evenodd' /%3E%3C/svg%3E") no-repeat center center;
    ${props => {
      switch(props.variant){
        case 'up':
          return 'transform: rotate(90deg);'
        case 'down':
          return 'transform: rotate(-90deg);'
        case 'right':
          return 'transform: rotate(180deg);'
        default:
      }
    }}
  }
`
