import React from 'react'
import { t } from 'i18next'
import { Navigate } from 'react-router'

import { Subheader } from '../../components/Page/logix2020/Subheader'
import SubheaderTitle from '../../components/Page/logix2020/SubheaderTitle'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import SidebarNavigation from '../../components/Page/logix2020/SidebarNavigation'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import BigFooter from '../../components/Footer/BigFooter'
import CatalogBaseForm from '../../components/Configuration/Catalog/CatalogBaseForm'
import i18n from '../../translation/i18n'
import { useCatalogsState } from '../../stores/Configuration/CatalogsProvider'

const navigationLeft = () => [
  {
    key: '#base',
    to: '#base',
    name: i18n.t('catalogsAdministration:base'),
    disabled: false
  },
  {
    key: '#activateArticle',
    to: '#activateArticle',
    name: i18n.t('catalogsAdministration:activateArticleForUnits'),
    disabled: true
  },
  {
    key: 'orderProcesses',
    to: '#orderProcesses',
    name: i18n.t('catalogsAdministration:availableOrderProcesses'),
    disabled: true
  },
  {
    key: 'ociInterface',
    to: '#ociInterface',
    name: i18n.t('catalogsAdministration:ociInterface'),
    disabled: true
  }
]

const CreateCatalogPage = () => {
  const { redirectId } = useCatalogsState()

  // redirect to edit page after new phase was created
  if (redirectId) {
    return <Navigate
      to={`/catalogsAdministration/editCatalog/${redirectId}#base`}
      replace
    />
  }

  return (
    <div>
      <Subheader className="subHeader-v1">
        <SubheaderTitle
          withBackButton
          smallTitle={t('catalogsAdministration:addCatalog')}
          goBackLabel={t('catalogsAdministration:catalogsOverview')}
          defaultBackTo="/catalogsAdministration"
        />
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <SidebarNavigation options={navigationLeft()} active='base' />
        </SidebarLeft>
        <ContentArea>
          <CatalogBaseForm />
        </ContentArea>
      </FlexWrapper>
      <BigFooter />
    </div>
  )
}

export default CreateCatalogPage