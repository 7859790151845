import React from 'react'
import { t } from 'i18next'

import { Subheader } from '../../components/Page/logix2020/Subheader'
import SubheaderTitle from '../../components/Page/logix2020/SubheaderTitle'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import SidebarNavigation from '../../components/Page/logix2020/SidebarNavigation'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import BigFooter from '../../components/Footer/BigFooter'
import ContingentTemplateBaseForm from '../../components/Configuration/ContingentTemplate/ContingentTemplateBaseForm'
import { useContingentTemplatesState } from '../../stores/Configuration/ContingentTemplatesProvider'
import { Navigate } from 'react-router-dom'
import { ErrorAlerts } from '../../utils/api/makeCall'

const navigationLeft = () => [
  {
    key: 'base',
    to: '#base',
    name: t('contingentTemplates:base'),
    disabled: false
  },
  {
    key: 'activities',
    to: '#activities',
    name: t('contingentTemplates:activities'),
    disabled: true
  },
  {
    key: 'attributionPhase',
    to: '#attributionPhase',
    name: t('contingentTemplates:attributionPhase'),
    disabled: true
  },
  {
    key: 'editQuantities',
    to: '#editQuantities',
    name: t('contingentTemplates:quantities'),
    disabled: true
  }
]

const CreateContingentTemplatePage = () => {
  const { redirectId, errorStates } = useContingentTemplatesState()


  // redirect to edit page after new phase was created
  if (redirectId) {
    return <Navigate
      to={`/contingentTemplatesAdministration/editContingent/${redirectId}#base`}
      replace
    />
  }

  return (
    <div>
      <Subheader className="subHeader-v1">
        <SubheaderTitle
          withBackButton
          smallTitle={t('contingentTemplates:addContingentTemplate')}
          goBackLabel={t('contingentTemplates:contingentTemplatesOverview')}
          defaultBackTo="/contingentTemplatesAdministration"
        />
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <SidebarNavigation options={navigationLeft()} active='base' />
        </SidebarLeft>
        <ContentArea>
          <ErrorAlerts errorStates={errorStates} />
          <ContingentTemplateBaseForm />
        </ContentArea>
      </FlexWrapper>
      <BigFooter />
    </div>
  )
}

export default CreateContingentTemplatePage