import styled from "styled-components"
import { colors } from "../../styles/colors"
import { useEffect } from "react"
import { useParams } from "react-router"
import useStores from "../../stores/useStore"
import { useTranslation } from "react-i18next"
import { useObserver } from "mobx-react"
import { Link } from "react-router-dom"
import { ArrowBigIcon } from "../Icons/ArrowBigIcon"
import { emptyGuid } from "../../utils/emptyGuid"

const StyledArticleDescription = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${colors.textColorB80};

  svg {
   margin-top: -2px;
  }
`
const StyledTr = styled.tr`
    height:51px;
    &:hover {
        background-color:rgba(0,0,0,0.075);
        td:last-child> span{
            display:block;
        }
   }
  
    td{ 
        .shipping-sum{
            color: gray;
        }
        padding: 10px;
        vertical-align: middle ;
        @media screen and (min-width: 766px) {
            &:last-child > span {
                display:none;
            }
        }
  }
  border-top:${props => props.borderTop ? '1px solid #e0e0e0' : ''};
`
const ExpandedArticle = ({ item }) => {
  const foreignEmployeeId = useParams().employeeId
  const positions = item.positions || []
  const getSingleSize = (position) => {
    return position.articleVariant ? position.articleVariant.displayName : ""
  }
  const getPendingDeliveryAmount = (position) => {
    let orderedQuantity = position.orderedQuantity || 0
    let shippedQuantity = position.shippedQuantity || 0
    return orderedQuantity - shippedQuantity
  }

  return (
    <>
      {positions.map((position, index) => {
        return (
          <StyledTr hover key={`article-${index}`}  >
            <td></td>
            <td >{position.currentQuantity} <span className='shipping-sum'>&nbsp;{getPendingDeliveryAmount(position) === 0 ? null : `(${getPendingDeliveryAmount(position)}*)`}</span></td>
            <td>{getSingleSize(position)} </td>
            <td >
              <span>
                <Link className="btn btn-sm btn-outline-secondary" to={foreignEmployeeId ? `/inventoryDetails/${foreignEmployeeId}/${item.id}` : `/inventoryDetails/${item.id}`} >
                  details
                </Link>
              </span>
            </td>
          </StyledTr>
        )
      })}
    </>
  );
}

const ArticleListItem = ({ item }) => {
  const foreignEmployeeId = useParams().employeeId
  const articleAvailability = item.articleAvailability || {}
  const positions = item.positions || []
  const { inventoryStore } = useStores();
  const { t } = useTranslation()

  useEffect(() => {
    if (inventoryStore.scrollRef === item.article.id) {
      let element = document.querySelector(`[data-id="article-${item.article.id}"]`);
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      inventoryStore.setScrollRef(null);
    }
  }, [inventoryStore, item.article.id])

  const getSingleSize = () => {
    return positions[0].articleVariant ? positions[0].articleVariant.displayName : ""
  }

  const getPendingDeliverySum = () => {
    let sumFunction = (prop) => positions.reduce(function (a, b) {
      return a + b[prop];
    }, 0);
    let sumOrderedAmount = sumFunction("orderedQuantity")
    let sumShippedAmount = sumFunction("shippedQuantity")
    return sumOrderedAmount - sumShippedAmount
  }

  return useObserver(() => (
    <>
      <StyledTr borderTop hover={true} data-id={`article-${item.article.id}`} >
        <td>
          {positions.length > 1 ?
            <StyledArticleDescription onClick={() => inventoryStore.expandArticles(item.article.id)}>
              <ArrowBigIcon variant={inventoryStore.articlesExpanded.includes(item.article.id) ? 'down' : 'right'} />
              <div >
                <div>{item.article.displayName}</div>
                <small className='text-uppercase'>{t('inventory:articleNr')}: {item.article.articleNumber}</small>
              </div>
            </StyledArticleDescription>
            :
            <div className="pl-2">
              <div>{item.article.displayName}</div>
              <small className='text-uppercase'>{t('inventory:articleNr')}: {item.article.articleNumber}</small>
            </div>
          }
        </td>
        <td  >
          {articleAvailability.currentAmount}  <span className='shipping-sum'>&nbsp;{getPendingDeliverySum() === 0 ? null : `(${getPendingDeliverySum()}*)`}</span>
        </td>
        <td >
          {positions.length === 1 && getSingleSize()}
        </td>
        <td >
          {item.contingentAvailability.id === emptyGuid ? null : <span>
            <Link className="btn btn-sm btn-outline-secondary" to={foreignEmployeeId ? `/inventoryDetails/${foreignEmployeeId}/${item.id}` : `/inventoryDetails/${item.id}`} onClick={() => inventoryStore.setScrollRef(item.article.id)}>
              details
            </Link>
          </span>}
        </td>
      </StyledTr>

      {positions.length > 1 && inventoryStore.articlesExpanded.includes(item.article.id) &&
        <ExpandedArticle item={item} />
      }
    </>
  ))
}
export default ArticleListItem