import styled from 'styled-components'
import { colors } from '../../../styles/colors'

export const TestSidebarRight = styled.div`
background: ${colors.gray6};
border-left: 1px solid ${colors.gray2};
color: ${colors.gray5};
flex-basis: 400px;
flex-grow: 0;
flex-shrink: 0;
@media screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px)  {
  header{ display:none !important;} 
  .content{min-height:300px !important;}
}
header {
    background: ${colors.gray7};
    min-height: 50px;
    line-height: 50px;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;

    .h2 {
      color: white;
      margin-bottom: 0;
      font-size: 18px;
    }
}
.content {
    padding: 36px 20px 20px 36px;
    background: ${colors.gray6};
    color: ${colors.gray5};
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .submit-btn {
      width: 100%;
      max-width: 250px;
    }
    .submit-btn:disabled {
      cursor: no-drop;
    }
}
  

`