import React from 'react'
import { ReactComponent as DragAndDropSVG } from '../../../assets/icons/dragAndDropPicture.svg'
import styled, { keyframes } from 'styled-components'

const fadeInUp = keyframes`
from {
  opacity: 0;
  transform: translateY(20%);
}

to {
  opacity: 1;
  transform: translateY(0);
}`
const move1 = keyframes`
from {
  transform: translate(100%, -100%);
}

to {
  transform: translate(0, 0);
}
`
const move2 = keyframes`
from {
  transform: translateY(0);
}

to {
  transform: translateY(23%);
}
`
const disappear = keyframes`
from {
  transform: scale(1);
  opacity: 1;
}

to {
  transform: scale(.7);
  opacity: 0;
}
`
const scaleDown = keyframes`
from {
  transform: scale(1);
}

to {
  transform: scale(.7);
}
`
const appear = keyframes`
from {
  opacity: 0;
}

to {
  opacity: 1;
}
`
const hoverDroBbox = keyframes`
from {
  stroke: #9F9F9F;
}

to {
  stroke: green;
}
`
const hoverButton = keyframes`
from {
  fill: #AFAFAF;

}

to {
  fill: #5a5a5a;
}
`
const moveRight = keyframes`
from {
  transform: translateX(0);
}

to {
  transform: translateX(100%);
}
`
const StyledDragAndDropSVG = styled(DragAndDropSVG)`
width:100%;
max-width:600px;
.step1 {
  opacity: 0;
  animation: ${fadeInUp} 1s ease-in-out 1s forwards, ${moveRight} 1s ease-in 8.4s forwards;
}
.pointer {
  opacity: 0;
  animation: ${appear} 1s linear 2s forwards, ${move1} 2s ease-in-out 2s, ${move2} 1s ease-in-out 6s forwards;
}
.image {
  opacity: 0;
  animation: ${appear} 1s linear 2s forwards, ${move1} 2s ease-in-out 2s, ${disappear} 1s linear 5s forwards, ${scaleDown} 1s linear 5s forwards;
}
.step2 {
  animation: ${appear} 1s linear 5s forwards;
  opacity: 0;
}
.dropBox_stroke {
  stroke: #9F9F9F;
  animation: ${hoverDroBbox} 2s linear 3s;
}
.button_fill {
  fill: #AFAFAF;
  animation: ${hoverButton} .5s linear 6.5s forwards;
}
.imageSmall {
  animation: ${moveRight} 1s ease-in 8.2s forwards;
}

.button {
  animation: ${moveRight} 1s ease-in 8s forwards;
}
`
const DragAndDropAnimation = () => {
  return (<div  >
    <StyledDragAndDropSVG />
  </div>
  )
}

export default DragAndDropAnimation