import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { t } from 'i18next'

import Variants from './Variants'
import LocalStorehouse from './LocalStorehouse'
import Configurations from './Configurations'
import { formatedDate } from '../../utils/formatedDate'
import { colors } from '../../styles/colors'


const StyledBuyboxBottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-left: -13px;
  margin-right: -13px;

  > div {
    padding: 0 13px;
    color: ${colors.secondary};
  }

  .amount {
    select {
      min-width: 100px;
    }
  }

  .unit {
    p {
      color: ${colors.gray4};
    }
  }

  .form-control.is-invalid {
    background-image: none;
    padding-right: 0.625rem;
  }
`

const StyledLabel = styled.div`
  margin-bottom: 4px;
  font-weight: bold;
  color: ${colors.textColor};
`

const StyledAmountWrapper = styled.div`
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  color: ${colors.secondary};
  > span {
    padding-right: 30px;
    font-weight: 400;
  }
`

const DefaultBuyBox = inject('productDetailsStore',)(observer((props) => {
  const { productDetailsStore } = props
  const shoppingBasketArticle = productDetailsStore.shoppingBasketArticle
  const articleAvailability = shoppingBasketArticle.articleAvailability || {}
  const [invalidQuantity, setInvalidQuantity] = useState(false)

  const handleChangeQuantity = (e) => {
    productDetailsStore.setSelectedQuantity(e.target.value)
    const remainingQuantity = productDetailsStore.maximumQuantity - articleAvailability.amountToOrder
    
    if (productDetailsStore.maximumQuantity !== null && e.target.value > remainingQuantity) {
      setInvalidQuantity(true)
    } else {
      setInvalidQuantity(false)
    }
  }

  return (
    <React.Fragment>
      <Variants />
      <LocalStorehouse />
      <Configurations />
      {productDetailsStore.lastComment !== null ?
        <div className='mt-4 mb-3'>
          <Form.Label>{t('comment')}</Form.Label>
          <Form.Control as='textarea' value={productDetailsStore.customComment}
            onChange={productDetailsStore.handleCustomComment} />
        </div>
        : ''
      }
      <StyledBuyboxBottom>
        <div className="amount">
          <StyledLabel>{t('amount')}</StyledLabel>
          <Form.Control
            className="mb-2"
            type="number"
            placeholder="1"
            value={productDetailsStore.selectedQuantity} onChange={handleChangeQuantity}
            min={productDetailsStore.minimumQuantity}
            max={productDetailsStore.maximumQuantity}
            step={shoppingBasketArticle.article.packageSize ? shoppingBasketArticle.article.packageSize : 1}
            isInvalid={invalidQuantity}
          />
        </div>
        <div className="unit">
          <StyledLabel>{t('unit')}</StyledLabel>
          <p>{productDetailsStore.article.unit && productDetailsStore.article.unit.displayName}</p>
        </div>

        <div>
          <StyledLabel>{t('productDetail:referenceAmount')}</StyledLabel>
          <StyledAmountWrapper>{t('currentAmount')}
            <span>
              {articleAvailability.currentAmount}
              {articleAvailability.amountToOrder ? <span
                className='text-primary'>(+{articleAvailability.amountToOrder})</span> : ''}
            </span>
          </StyledAmountWrapper>
          {articleAvailability.maxAmount && (
            <StyledAmountWrapper>{t('maxAmount')} <span>{articleAvailability.maxAmount}</span></StyledAmountWrapper>
          )}
        </div>

      </StyledBuyboxBottom>
      {shoppingBasketArticle.article.minimumQuantity !== null && <div><span>{t('productDetail:minQuantity')}:</span> {shoppingBasketArticle.article.minimumQuantity}</div>}
      {shoppingBasketArticle.article.maximumQuantity !== null && <div><span>{t('productDetail:maxQuantity')}:</span> {shoppingBasketArticle.article.maximumQuantity}</div>}
      {shoppingBasketArticle.article.packageSize !== null && <div><span>{t('productDetail:packageSize')}:</span> {shoppingBasketArticle.article.packageSize}</div>}
      {(productDetailsStore.shoppingBasketArticleNextExpiries && productDetailsStore.shoppingBasketArticleNextExpiries.length) ? < div >
        <span>
          {t('productDetail:expirationDate')}:{formatedDate(productDetailsStore.shoppingBasketArticleNextExpiries[0].nextExpiryDate)} ({productDetailsStore.shoppingBasketArticleNextExpiries[0].nextExpiryQuantity}x)
        </span>
      </div> : null}
    </React.Fragment >
  )
}))

export default DefaultBuyBox