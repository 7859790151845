import React, { useState } from 'react'
import OfficeSelect from './OfficeSelect'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import ArraySelect from '../../../Select/ArraySelect'
import { Form, Alert } from 'react-bootstrap'
import { APIEndpoints } from '../../../../utils/api/apiConfig'
import apiClient from '../../../../utils/api/apiClient'
import { StyledDisabledContainer } from '../../../Forms/StyledDisabledContainer'
import LaundryShelfTree from '../../../LaundryShelves/LaundryShelfTree'
import {
    StyledImage
} from '../../../TreeList/treeList'
import cube_yellow_delete from '../../../../assets/icons/cube_yellow_delete.png';
import cube_yellow_add from '../../../../assets/icons/cube_yellow_add.png';
import styled from 'styled-components'
import { emptyGuid } from '../../../../utils/emptyGuid'
import LaundryShelfTreeSelectedItems from './LaundryShelfTreeSelectedItems'
import { getErrorMessageFromApiResponse } from '../../../../utils/api/apiUtils'

const StyledWrapper = styled.div`
  .action-buttons {
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: flex-end;
      
   }
 
`
const LaundryShelfTreeSelect = inject('laundryShelfStore')(observer((props) => {
    const { t } = useTranslation()
    const { laundryShelfStore, status, employeeId } = props
    const [selectedOfficeId, setSelectedOfficeId] = useState("")
    const [officeAddresses, setOfficeAddresses] = useState([])
    const [laundryShelfLocations, setLaundryShelfLocations] = useState(null)
    const [selectedOfficeAddressId, setSelectedOfficeAddressId] = useState("")
    const [laundryShelfLocationsError, setLaundryShelfLocationsError] = useState(null)

    const handleChangeOfficeId = (id) => {
        setSelectedOfficeId(id)
        getOfficeAddresses(id)
    }

    const handleChangeOfficeAddressId = (id) => {
        setSelectedOfficeAddressId(id)
        getLaundryShelfLocation(id)
    }

    const getOfficeAddresses = async (selectedOfficeId) => {
        setLaundryShelfLocationsError(null)
        try {
            setSelectedOfficeAddressId("")
            setLaundryShelfLocations(null)
            setOfficeAddresses([])
            const addresses = await apiClient.getJson(APIEndpoints.offices(selectedOfficeId).addresses, { type: "shipment" })
            const preparedAddresses = addresses.map(address => ({ id: address.id, displayName: getAddressString(address) }))
            setOfficeAddresses(preparedAddresses)
        } catch (e) { }
    }

    const getLaundryShelfLocation = async (selectedOfficeAddressId) => {
        setLaundryShelfLocationsError(null)
        try {
            setLaundryShelfLocations(null)
            const newLocation = await apiClient.getJson(APIEndpoints.laundryShelves().locations, { officeId: selectedOfficeId, addressId: selectedOfficeAddressId })
            setLaundryShelfLocations(newLocation)
        } catch (e) {
            setLaundryShelfLocationsError(getErrorMessageFromApiResponse(e))
        }
    }

    const getAddressString = (address) => {
        let displayName = address.useSecondaryOfficeName ? address.secondaryOfficeName : address.displayName
        return `${displayName}, ${address.street} ${address.streetNr}, ${address.countryIsoCode}-${address.zipCode} ${address.city}`
    }

    const changeBPListItem = async (laundryShelf) => {
        //change listitems
        const newSelectedItems = [...laundryShelfStore.selectedItems]
        const existingIndex = newSelectedItems.findIndex(obj => obj.id === laundryShelf.id)
        if (existingIndex >= 0) {
            newSelectedItems.splice(existingIndex, 1)
        }
        else { newSelectedItems.push({ id: laundryShelf.id, displayName: laundryShelf.fullDisplayName }) }
        laundryShelfStore.selectedItems = newSelectedItems
    }

    const renderExtraItemContent = (laundryShelf, onlyDelete = false) => {
        const assignedToId = laundryShelf.assignedToBusinesspartnerId
        const assignedToMe = laundryShelfStore.selectedItems.find(obj => obj.id === laundryShelf.id)
        const laundryImage =
            status === "read" ? null
                : onlyDelete ? cube_yellow_delete
                    : laundryShelf.type === "isGroup" ? null
                        : ((assignedToId === emptyGuid && !assignedToMe) || (assignedToId === employeeId && !assignedToMe)) ? cube_yellow_add
                            : assignedToMe ? cube_yellow_delete
                                : null
        return (
            <>
                <div className="action-buttons" >
                    <StyledImage style={{ cursor: "pointer" }} image={laundryImage} onClick={() => changeBPListItem(laundryShelf)} />
                </div>
            </>
        )
    }

    if (status === "hide") {
        return null
    }

    return (
        <StyledWrapper>
            <LaundryShelfTreeSelectedItems renderExtraItemContent={renderExtraItemContent} />
            <OfficeSelect id="laundrySelfOfficeSelect"
                label={t('costCenterAdmin:organizationalUnit')}
                value={selectedOfficeId}
                onChange={handleChangeOfficeId}
                isInvalid={false}
                errorMessage={''}
                overLayZIndex="1000"
            />
            <StyledDisabledContainer disabled={!selectedOfficeId}>
                <Form.Group className="mb-3" >
                    <Form.Label>Anschrift</Form.Label>
                    <ArraySelect
                        disabled={!selectedOfficeId}
                        options={officeAddresses}
                        value={selectedOfficeAddressId}
                        onChange={obj => handleChangeOfficeAddressId(obj.id)}
                    />
                </Form.Group>
            </StyledDisabledContainer >
            {laundryShelfLocations
                ? <LaundryShelfTree
                    employeeId={employeeId}
                    location={laundryShelfLocations}
                    renderExtraItemContent={renderExtraItemContent}
                />
                : null
            }
            {laundryShelfLocationsError && <Alert variant="danger">{laundryShelfLocationsError}</Alert>}
        </StyledWrapper>
    )
}))

export default LaundryShelfTreeSelect
