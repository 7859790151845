import React, { useMemo } from 'react'
import styled from 'styled-components';
import Article from './Article'
import GroupCard from './GroupCard';

import { cloneDeep } from 'lodash';
import useMountEffect from '../../../utils/hooks/useMountEffect';

const StyledArticles = styled.div`
  list-style: none;
  opacity: 0.8;
`

const Group = ({ entitlementId, group, index, open, setOpenGroups, autoScrollTo, getGroupArticles }) => {
  const groupId = useMemo(() => group.id, [group])
  function handleOpen() {
    setOpenGroups(prev => {
      prev[groupId] = prev[groupId] ? false : true
      return cloneDeep(prev)
    })
  }

  useMountEffect(() => {
    !group.articlesLoaded && getGroupArticles(entitlementId, groupId)
  })

  const groupArticles = useMemo(
    () => Object.values(group.articles)
    , [group.articles])

  return (
    <>
      <GroupCard
        index={index}
        group={group}
        groupArticles={groupArticles}
        open={open}
        handleOpen={handleOpen}
        entitlementId={entitlementId}
        autoScrollTo={autoScrollTo}
      />
      {open &&
        <StyledArticles>
          {
            groupArticles.map((article) => {
              return (
                <Article
                  key={article.id}
                  article={article}
                  entitlementId={entitlementId}
                  groupId={groupId}
                  iconType={group.systemIcon}
                  autoScrollTo={autoScrollTo}
                />
              )
            })
          }
        </StyledArticles>
      }

    </>
  )
}

export default Group