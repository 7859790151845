import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { Form } from 'react-bootstrap'
import { StyledFormHeadline, StyledValidationMessage } from './RegistrationStyles'

const AccountData = inject('registrationStore', 'uiStore')(observer((props) => {
  const { registrationStore, registrationConfig } = props
  return (
    <>
      <StyledFormHeadline>{t('registrationBox:accessData')}</StyledFormHeadline>
      <Form.Group className="mb-3" controlId="formGroupEmail_0">
        <Form.Label>{registrationConfig.accountData.login.label}</Form.Label>
        <Form.Control
          value={registrationStore.registrationData.email.value}
          autoComplete="off"
          name="email.value"
          type="email"
          placeholder="E-Mail"
          onChange={registrationStore.handleChange2}
          isInvalid={registrationStore.formErrors.emailvalue || registrationStore.formErrors.accountlogin}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGroupEmail_1">
        <Form.Control
          value={registrationStore.registrationData.frontendData.emailRepeat}
          autoComplete="off"
          type="email"
          placeholder={t('formularFields:repeatEmail')}
          onChange={registrationStore.setEmailRepeat}
          isInvalid={registrationStore.formErrors.emailvalue || registrationStore.formErrors.accountlogin}
        />
        <StyledValidationMessage type="invalid">
          {registrationStore.formErrors.emailvalue}
          {registrationStore.formErrors.accountlogin}
        </StyledValidationMessage>
      </Form.Group>
      <Form.Group className="mb-3" controlId='formBasicPassword_0'>
        <Form.Label>{registrationConfig.accountData.password.label}</Form.Label>
        <Form.Control
          value={registrationStore.registrationData.account.password}
          autoComplete="new-password"
          name="account.password"
          type='password'
          placeholder={t('loginBox:password')}
          onChange={registrationStore.handleChange2}
          isInvalid={registrationStore.formErrors.accountpassword}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId='formBasicPassword_1'>
        <Form.Control
          value={registrationStore.registrationData.frontendData.passwordRepeat}
          autoComplete="new-password"
          name="frontendData.passwordRepeat"
          type='password'
          placeholder={t('registrationBox:repeatPassword')}
          onChange={registrationStore.setPasswordRepeat}
          isInvalid={registrationStore.formErrors.accountpassword}
        />
        <StyledValidationMessage type="invalid">
          {registrationStore.formErrors.accountpassword}
        </StyledValidationMessage>
      </Form.Group>
    </>
  )
}))

export default AccountData