import React, { useCallback, useEffect, useState } from 'react'
import OverlayRight, { StyledOverlayInnerWrapper } from '../Page/logix2020/OverlayRight'
import OverlayContainer from '../Page/logix2020/OverlayContainer'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { useTranslation } from 'react-i18next'
import { ExcelButton } from '../Buttons/ExcelButton'
import CostCenterStatistics from './Reports/CostCenterStatistics'
import EmployeeListStatistic from './Reports/EmployeeListStatistic'
import EmployeeInventoryStatistic from './Reports/EmployeeInventoryStatistic'
import EmployeeHealthListStatistic from './Reports/EmployeeHealthListStatistic'
import TurnOverStatistics from './Reports/TurnOverStatistics'
import CabinEventStatistic from './Reports/CabinEventStatistic'
import { Alert } from 'react-bootstrap'
import ReportSubscriptionModal from './Form/ReportSubscriptionModal'
import useReport from '../../utils/hooks/useReport'
import DownloadURLAlert from '../Forms/DownloadURLAlert'
import { emptyGuid } from '../../utils/emptyGuid'


const CreateNewReportContent = ({ reportTypeId, reportTypeName, preSelectedData }) => {
  const { t } = useTranslation()
  const [formData, setFormData] = useState({})
  const { createReport, reportIsLoading, setReportIsLoading, reportDownloadUrls, reportErrors, setReportErrors, setReportDownloadUrls, reportIsSendByMail, setReportIsSendByMail, reportSubscriptionId, setReportSubscriptionId } = useReport()

  useEffect(() => {
    //clear form State when reselect
    setReportErrors({})
    setFormData({})
    setReportSubscriptionId("")
    setReportIsLoading({})
    setReportDownloadUrls({})
    setReportIsSendByMail(false)
  }, [reportTypeId, setReportDownloadUrls, setReportErrors, setReportIsLoading, setReportIsSendByMail, setReportSubscriptionId])

  const handleClickCreateReport = useCallback(() => {
    let endpoint = ''
    switch (reportTypeId) {
      case 'costCenterStatistics':
        endpoint = 'statisticCostcenters'
        break
      case 'employeeList':
        endpoint = 'statisticEmployeeList'
        break
      case 'employeeHealthList':
        endpoint = 'statisticEmployeeHealthList'
        break
      case 'turnOverStatisticsOnEntityUnit':
        endpoint = 'turnOverStatisticsOnEntityUnit'
        break
      case 'cabinEventStatistic':
        endpoint = 'cabinEventReport'
        break
      case 'employeeInventory':
        endpoint = 'statisticEmployeeInventory'
        break
      default:
    }

    if (endpoint === '') {
      console.log(`no api endpoint defined for reportTypeId "${reportTypeId}"`)
      return
    }

    createReport("reportName", APIEndpoints[endpoint], formData)
  }, [createReport, formData, reportTypeId])

  useEffect(() => {
    if (formData.targetCostcenterID === emptyGuid) {
      setFormData(prev => ({ ...prev, include_Subordinated_CostCenters: true }))
    }
    if (formData.officeID === emptyGuid) {
      setFormData(prev => ({ ...prev, includeSubordinatedOffices: true }))
    }
  }, [setFormData, formData.targetCostcenterID, formData.officeID])

  return (
    <>
      <div className="content">
        <div className="data-list__name h2">{reportTypeName}</div>

        {reportTypeId === 'costCenterStatistics' && (
          <CostCenterStatistics formData={formData} setFormData={setFormData} formErrors={reportErrors?.reportName?.formErrors} preSelectedData={preSelectedData} />
        )}
        {reportTypeId === 'employeeList' && (
          <EmployeeListStatistic formData={formData} setFormData={setFormData} formErrors={reportErrors?.reportName?.formErrors} />
        )}
        {reportTypeId === 'employeeHealthList' && (
          <EmployeeHealthListStatistic formData={formData} setFormData={setFormData} formErrors={reportErrors?.reportName?.formErrors} />
        )}
        {reportTypeId === 'employeeInventory' && (
          <EmployeeInventoryStatistic formData={formData} setFormData={setFormData} formErrors={reportErrors?.reportName?.formErrors} />
        )}
        {reportTypeId === 'turnOverStatisticsOnEntityUnit' && (
          <TurnOverStatistics formData={formData} setFormData={setFormData} formErrors={reportErrors?.reportName?.formErrors} />
        )}
        {reportTypeId === 'cabinEventStatistic' && (
          <CabinEventStatistic formData={formData} setFormData={setFormData} preSelectedData={preSelectedData} formErrors={reportErrors?.reportName?.formErrors} />
        )}
        {reportErrors.reportName && (
          <Alert variant="danger">
            <strong>{reportErrors.reportName.value}</strong><br />
            {/*{error}*/}
          </Alert>
        )}
        {reportDownloadUrls.reportName && <DownloadURLAlert
          downloadUrl={reportDownloadUrls.reportName}
          setDownloadUrl={() => setReportDownloadUrls({})}
          fileName={reportTypeId}
        />}

        {reportIsSendByMail && (
          <Alert variant="success">
            {t('reportConfig:isSendByMail')}
          </Alert>
        )}

        {reportSubscriptionId && <ReportSubscriptionModal
          reportSubscriptionId={reportSubscriptionId}
          setReportSubscriptionId={setReportSubscriptionId}
          message={t('reportConfig:createPending')}

        />}

      </div>
      <div className="footer p-4" >
        <ExcelButton
          className="w-100"
          isLoading={reportIsLoading.reportName}
          onClick={handleClickCreateReport}
          loadingText={`${t('reports:createExcelReport')}...`}
          buttonText={t('reports:createExcelReport')} />
      </div>
    </>
  )
}

const CreateNewReportOverlay = ({ visible, onClose, ...rest }) => {
  const { t } = useTranslation()

  return (
    <OverlayContainer>
      <OverlayRight visible={visible} onClose={onClose} headerTitle={t('reports:createNewReport')}>
        <StyledOverlayInnerWrapper>
          {visible && <CreateNewReportContent {...rest} />}
        </StyledOverlayInnerWrapper>
      </OverlayRight>
    </OverlayContainer>

  )
}

export default CreateNewReportOverlay
