import { Button } from "react-bootstrap"
import styled from "styled-components"
import ScrollWrapper from "../../Page/logix2020/ScrollWrapper"
import { colors } from "../../../styles/colors"

export const StyledChatWrapper = styled.div`
background: ${colors.gray1};
border: 1px solid ${colors.gray3};
`
export const StyledMessage = styled.div`
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
`
export const StyledMessageHeader = styled.div`
align-items: center;
    display: flex;
    margin-bottom: 0.3rem;
`
export const StyledMessageBody = styled.div`
background:white;
margin-left: 51px;
padding: 1rem;
border-radius: 1rem;
`
export const StyledMessageBox = styled.div`
position:relative;
padding: 1rem;
`
export const StyledSendButton = styled(Button)`
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    right: 25px;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    padding: 0;`

export const StyledScrollWrapper = styled(ScrollWrapper)`
padding:1rem 1rem 0rem 1rem;
height:400px;
`
export const StyledInputWrapper = styled.div`
background-image: linear-gradient(${colors.gray1}, ${colors.gray3});
display: flex !important;
align-items: center;

`
export const StyledDocumentWrapper = styled.a`
min-height:48px;
color: ${colors.gray4};
small{color: ${colors.gray3};}
display: flex;
align-items: center;
margin: 5px 5px 5px 0 ;
cursor:pointer;
`