import React from 'react'
import { AddToBasketButton } from '../Buttons/AddToBasketButton'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import QuantityStatus from './QuantityStatus'
import DefaultBuyBox from './DefaultBuyBox'
import { StyledFormCol } from './ProductBuyBoxStyles'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'



const PersonalBuyBox = inject('productDetailsStore')(observer((props) => {
  const { productDetailsStore } = props
  const shoppingBasketArticle = productDetailsStore.shoppingBasketArticle
  const articleAvailability = shoppingBasketArticle.articleAvailability || {}
  const orderableStatus = articleAvailability.orderableStatus

  return (
    <Row>
      <StyledFormCol as={Col} xs={12}
        className={(!orderableStatus || orderableStatus === 'isOrderable') ? '' : 'notOrderable'}
        data-max-mount-reached={t('productDetail:maxAmountReached')}>
        <DefaultBuyBox />
        <hr />
        <div className='d-flex align-items-md-center flex-column flex-md-row justify-content-end'>
          <div style={{ margin: '8px auto 8px 8px', maxWidth: '175px' }} >
            <QuantityStatus variantQuantities={productDetailsStore.variantQuantities} />
          </div>
          <AddToBasketButton
            buttonText={t('productDetail:addToBasketButton')}
            onClick={productDetailsStore.handleAddToBasket}
            variant={'primary'}
            type={'submit'}
            loadingText={t('productDetail:buttonWaitText')}
            isLoading={productDetailsStore.addToBasketIsLoading}
          />
        </div>
        <hr />
      </StyledFormCol>
    </Row>
  )
}))

export default PersonalBuyBox