import React, { useState } from 'react'
import { Alert, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useContingentTemplatesState } from '../../../stores/Configuration/ContingentTemplatesProvider'
import { ButtonWithLoadingState } from '../../Buttons'
import FileInput from '../../Forms/FileInput'
import ImageOutput from './ImageOutput'
import LanguageForm from '../../Forms/LanguageForm'
import LanguageNameInput from '../../Forms/LanguageNameInput'
import OverlayContainer from '../../Page/logix2020/OverlayContainer'
import OverlayRight from '../../Page/logix2020/OverlayRight'
import useMountEffect from '../../../utils/hooks/useMountEffect'
import { FileOutput } from '../../Forms/FileOutput'
import { cloneDeep } from 'lodash'


const StyledFormWrapper = styled.div`
  max-width: 600px;
  @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px) {
    max-width: none;
  }
`

const ContingentTemplateBaseForm = ({ contingentTemplateId }) => {
  const { errorStates, contingentTemplate, initContingentTemplates, setContingentTemplate, loadingStates, contingentTemplateHasChanged, successStates, patchContingentTemplate, postContingentTemplate, uploadPicture, deletePicture, contingentTemplatePicture } = useContingentTemplatesState()
  const { t } = useTranslation()
  const [overlayVisible, setOverlayVisible] = useState(false)
  const [files, setFiles] = useState([])

  useMountEffect(() => {
    initContingentTemplates(contingentTemplateId)
  })

  const updateNames = (translations) => {
    setContingentTemplate(prev => ({
      ...prev,
      name: {
        ...prev.name,
        values: translations
      }
    }))
  }

  const updateCheckboxProperty = (e) => {
    setContingentTemplate(prev => ({
      ...prev,
      [e.target.id]: e.target.checked
    }))
  }

  const updateNumberProperty = (e) => {
    let value = parseInt(e.target.value)
    setContingentTemplate(prev => ({
      ...prev,
      [e.target.id]: value
    }))
  }

  const handleSubmit = () => {
    contingentTemplateId ? patchContingentTemplate(contingentTemplateId) : postContingentTemplate(files)
  }

  const handleCloseOverlay = () => {
    setOverlayVisible(false)
  }
  return (
    <div>
      <StyledFormWrapper>
        <Form>
          <LanguageNameInput
            openLanguageModal={() => setOverlayVisible(true)}
            languageArray={contingentTemplate.name.values}
            onChange={updateNames}
            label={t('name')}
            isInvalid={errorStates?.saveContingentTemplate?.formErrors?.name}
          />
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>{`${t('contingentTemplates:firstAttributionPoints')} (${t('male')})`}</Form.Label>
                <Form.Control
                  id="firstAttributionPointsMale"
                  type="number"
                  value={contingentTemplate.firstAttributionPointsMale || 0}
                  onChange={updateNumberProperty}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>{`${t('contingentTemplates:additionalAttributionPoints')} (${t('male')})`}</Form.Label>
                <Form.Control
                  id="attributionPointsMale"
                  type="number"
                  value={contingentTemplate.attributionPointsMale || 0}
                  onChange={updateNumberProperty}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{`${t('contingentTemplates:firstAttributionPoints')} (${t('female')})`}</Form.Label>
                <Form.Control
                  id="firstAttributionPointsFemale"
                  type="number"
                  value={contingentTemplate.firstAttributionPointsFemale || 0}
                  onChange={updateNumberProperty}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{`${t('contingentTemplates:additionalAttributionPoints')} (${t('female')})`}</Form.Label>
                <Form.Control
                  id="attributionPointsFemale"
                  type="number"
                  value={contingentTemplate.attributionPointsFemale || 0}
                  onChange={updateNumberProperty}
                />
              </Form.Group>
            </Col>
          </Row>
          {contingentTemplate.pointsCappingMale != null ? <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>{t('stdPointRunAdmin:pointsCapping')}  ({t('male')}) </Form.Label>
                <Form.Control
                  id="pointsCappingMale"
                  type="number"
                  value={(contingentTemplate.pointsCappingMale >= 0) ? contingentTemplate.pointsCappingMale : ''}
                  onChange={updateNumberProperty}
                  placeholder={t('pleaseSelect')}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>{t('stdPointRunAdmin:pointsCapping')}  ({t('female')})</Form.Label>
                <Form.Control
                  id="pointsCappingFemale"
                  type="number"
                  value={(contingentTemplate.pointsCappingFemale >= 0) ? contingentTemplate.pointsCappingFemale : ''}
                  onChange={updateNumberProperty}
                  placeholder={t('pleaseSelect')}
                />
              </Form.Group>
            </Col>
          </Row> : null}
          <Form.Check
            type="checkbox"
            id="isConsideredByPointRun"
            label={t('contingentTemplates:considerContingentCheck')}
            checked={contingentTemplate.isConsideredByPointRun || false}
            onChange={updateCheckboxProperty}
            className="mb-3"
          />
          <Form.Check
            type="checkbox"
            id="resetInventoryHistoryByPointRun"
            label={t('contingentTemplates:resetContingentCheck')}
            checked={contingentTemplate.resetInventoryHistoryByPointRun || false}
            onChange={updateCheckboxProperty}
            className="mb-3"
          />
          <div className="mb-3">
            {loadingStates.savePicture
              ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
              : contingentTemplatePicture && contingentTemplatePicture.url
                ? <ImageOutput url={contingentTemplatePicture.url} deleteFile={() => deletePicture(contingentTemplateId)} />
                : <FileInput
                  maxFiles={1}
                  accept={{
                    'image/jpeg': [],
                    'image/png': []
                  }}
                  onDrop={(acceptedFiles) => {
                    let newFiles = acceptedFiles.map(file => Object.assign(file, {
                      preview: URL.createObjectURL(file)
                    }));
                    contingentTemplateId ? uploadPicture(contingentTemplateId, newFiles) : setFiles(acceptedFiles)
                  }}
                  isInvalid={false}
                />
            }
            {files.map((file, index) => (
              <FileOutput
                style={{ height: "4rem", borderRadius: "1rem", padding: "0.5rem" }}
                className="mb-2"
                key={`${file.name}_${index}`}
                file={file}
                deleteFile={
                  () => setFiles(prev => {
                    prev.splice(index, 1)
                    return cloneDeep(prev)
                  })
                }
              />))}
          </div>
          <ButtonWithLoadingState
            disabled={!contingentTemplateHasChanged}
            buttonText={t('saveButton')}
            isLoading={loadingStates.saveContingentTemplate}
            onClick={handleSubmit}
          />
          <OverlayContainer>
            <OverlayRight visible={overlayVisible} onClose={handleCloseOverlay}>
              <LanguageForm
                languageTitle={t('name')}
                handleCloseOverlay={handleCloseOverlay}
                languageArray={contingentTemplate.name.values}  // contingentTemplate.name.values ?? or check when we have the call
                onChange={updateNames}
              />
            </OverlayRight>
          </OverlayContainer>
          {successStates.saveContingentTemplate &&
            <Alert variant="success" className="my-2">
              {t('registrationBox:savingSuccess')}
            </Alert>
          }

        </Form>
      </StyledFormWrapper>
    </div>
  )
}

export default ContingentTemplateBaseForm