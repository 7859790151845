import React from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import useStores from '../../stores/useStore'
import { useObserver } from 'mobx-react'
import { useTranslation } from 'react-i18next'

const StyledCatalogViewSwitch = styled.div`
  margin-bottom: 25px;
  font-weight: bold;
`
const AllArticlesSwitch = () => {
    const { inventoryStore } = useStores()
    const { t } = useTranslation();

    const showAll = useObserver(() => inventoryStore.showAllArticles)
    return (
        <StyledCatalogViewSwitch>
            <Form.Check
                type="switch"
                label={t('allArticles')}
                onChange={(e) => { inventoryStore.showAllArticles = e.target.checked }}
                id="catalog-view-switch"
                checked={showAll}
            />
        </StyledCatalogViewSwitch>
    )
}

export default AllArticlesSwitch
