import React from 'react'
import { Form } from 'react-bootstrap'
import useStores from '../../stores/useStore'
import { useObserver } from 'mobx-react'
import { useTranslation } from 'react-i18next'

const ProductSearchCheckBox = () => {
    const { teamOrderStore } = useStores()
    const handleChange = e => {
        teamOrderStore.productSearchIncludeSelected = e.target.checked
        teamOrderStore.loadOrderableArticles()
    }
    const { t } = useTranslation()
    return useObserver(() =>
        <Form.Check
            label={t('ProductOverview:searchCheckBoxLabel')}
            onChange={handleChange}
            checked={teamOrderStore.productSearchIncludeSelected}
            id='filter-range'
        />

    )
}

export default ProductSearchCheckBox
