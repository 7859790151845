import React from 'react'

export const PrevOrderIcon = (props) => {
  const color = props.color ? props.color : '#3a3a38'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 28 28">
        <path d="M28 8.8v15.8c0 1.9-1.6 3.5-3.5 3.5h-21C1.6 28 0 26.4 0 24.5V8.8h28zm-17.5 3.4H5.2c-1 0-1.8.8-1.8 1.8v1.8c0 1 .8 1.8 1.8 1.8h5.2c1 0 1.8-.8 1.8-1.8V14c0-1-.7-1.8-1.7-1.8zM19.4 0c.9 0 1.8.4 2.4 1l.2.2L27.1 7H.8L6 1.2C6.6.5 7.4.1 8.3 0H19.4z"
              fill={color}
              fillRule="evenodd"
              clipRule="evenodd"
        />
    </svg>
  )
}
