import logo_cws from '../../../assets/logos/logo_cws.png'
import portal_cws from '../../../assets/img/_portal_cws.jpg'
import portal_bmw from '../../../assets/img/portal_bmw.jpg'
import phone from "../../../assets/icons/handsetCWS.svg"
import mail from "../../../assets/icons/mailCWS.svg"
import contact from "../../../assets/icons/paperCWS.svg"
import favicon_cws from '../../../assets/icons/favicon_cws.ico'
import logo_porsche from '../../../assets/logos/logo_porsche.png'
import portal_porche1 from '../../../assets/img/_portal_porsche.jpg'
import portal_hyundai from '../../../assets/img/portal_hyundai.jpg'
import portal_cws_exclusive from '../../../assets/img/portal_cws_exclusive.jpg'
import logo_hyundai from '../../../assets/logos/logo_hyundai.jpg'
import { bmwRegistration, exclusiveCustomersRegistraion, hyundaiRegistration, porscheRegistration } from './registrationConfig'

const wandoDefault = {
  ids: [],
  primaryColor: 'red',
  favicon: favicon_cws,
  logo: logo_cws,
  userLogin: {
    type: 'text',
    usernamePlaceholder: 'Benutzername eingeben',
    usernamePlaceholder_en: 'Enter username',
    usernamePlaceholder_fr: "Entrez le nom d'utilisateur",
    usernamePlaceholder_es: 'Introduzca el nombre de usuario',
    usernamePlaceholder_it: 'Inserisci il nome utente',
    usernameLabel: 'Benutzername',
    usernameLabel_en: 'Username',
    usernameLabel_fr: "Nom d'utilisateur",
    usernameLabel_es: 'Nombre de usuario',
    usernameLabel_it: 'Nome utente',
    passwordLabel: 'Passwort',
    passwordLabel_en: 'Password',
    passwordLabel_fr: 'Mot de passe',
    passwordLabel_es: 'Contraseña',
    passwordLabel_it: 'Parola d’ordine',
    passwordPlaceholder: 'Passwort eingeben',
    passwordPlaceholder_en: 'Enter password',
    passwordPlaceholder_fr: 'Entrer le mot de passe',
    passwordPlaceholder_es: 'Introduzca la contraseña',
    passwordPlaceholder_it: 'Inserisci la password',
    loginBoxHeadline: 'CWS Corporate Fashion',
    loginBoxText: null,
    logoMaxHeight: '50px',
    logoMaxWidth: '100%',
    backgroundSize: 'cover',
    registrationButton: true,
    phoneIcon: phone,
    mailIcon: mail,
    contactIcon: contact,
  },
  contactInfos: {
    emailContact: "TC-Sales-PCS@cws.com",
    workingWeekHours: "Mo.-Do. 08:00-17:00 CET",
    workingWeekendHours: "Fr. 08:00-14:00 CET",
    websiteName: 'www.cws.com/corporate-fashion',
    websiteLink: 'https://www.cws.com/de-DE/arbeitskleidung/arbeitskleidung-ihrem-design-und-corporate-fashion/corporate-fashion',
    showContactModules: false,
    contactInfosHeadline2: "Wir sprechen deutsch und englisch",
    contactInfosHeadline2_en: "We speak German and English",
    contactInfosHeadline2_fr: "Nous parlons allemand et anglais",
    contactInfosHeadline2_es: "Hablamos alemán e inglés",
    contactInfosHeadline2_it: "Parliamo tedesco e inglese",
  },
  footer: {
    backgroundColor: '#f378c4',
    borderColor: '#eb0045',
    textColor: 'black',
    footerCopyRight: '© CWS Supply GmbH',
    footerAdditionalSentense: 'Alle Preise verstehen sich als Nettopreise zzgl. Versandkosten und werden im Rahmen einer innergemeinschaftlichen Lieferung umsatzsteuerfrei fakturiert (Umsatzsteuergesetz (§4 Nr. 1b UStG i.V.m §6a UstG)).',
    footerAdditionalSentense_en: 'All prices are net prices plus shipping costs and are invoiced VAT-free as part of an intra-community delivery (Sales Tax Act (Section 4 No. 1b UStG in conjunction with Section 6a UStG)).',
    footerAdditionalSentense_fr: 'Tous les prix sont des prix nets plus les frais d\'expédition et sont facturés hors TVA dans le cadre d\'une livraison intracommunautaire (loi sur la taxe de vente (section 4 n° 1b UStG en liaison avec la section 6a UStG)).',
    footerAdditionalSentense_es: 'Todos los precios son precios netos más gastos de envío y se facturan sin IVA como parte de una entrega intracomunitaria (Ley del Impuesto sobre las Ventas (Sección 4 No. 1b UStG en conjunto con la Sección 6a UStG)).',
    footerAdditionalSentense_it: 'Tutti i prezzi sono prezzi netti più le spese di spedizione e sono fatturati senza IVA nell\'ambito di una consegna intracomunitaria (Legge sull\'imposta sulle vendite (Sezione 4 n. 1b UStG in combinato disposto con la Sezione 6a UStG)).',
  },
  header: {
    costcenterOrder: "Produkte",
    costcenterOrder_en: "Products",
    costcenterOrder_fr: 'Produits',
    costcenterOrder_es: 'Productos',
    costcenterOrder_it: 'Prodotti'
  },
  ordersTabs: {
    ordersTabHeader: 'Übertragen an CWS',
    ordersTabHeader_en: 'Transferred to CWS',
    ordersTabHeader_fr: 'Transféré au SCF',
    ordersTabHeader_es: 'Transferido a CWS',
    ordersTabHeader_it: 'Trasferimento a CWS'
  },
  officeAdmin: {
    officeHeader: 'Addressen verwalten',
    officeHeader_en: 'Manage addresses',
    officeHeader_fr: 'Gérer les adresses',
    officeHeader_es: 'Gestionar las direcciones',
    officeHeader_it: 'Gestire gli indirizzi'
  },
  shoppingCart: {
    buttonOrderSubmit: "Jetzt kostenpflichtig bestellen",
    buttonOrderSubmit_en: "Order now with costs",
    buttonOrderSubmit_fr: "Commander maintenant contre paiement",
    buttonOrderSubmit_es: "Pedir ahora sujeto a costes",
    buttonOrderSubmit_it: "Ordina ora a pagamento",
    vatNet: "zzgl. der gesetzlichen Umsatzsteuer und ggf. Versandkosten",
    vatNet_en: "Plus statutory value added VAT and, if applicable, shipping costs",
    vatNet_fr: "Plus la TVA sur la valeur ajoutée légale et, le cas échéant, les frais d’expédition",
    vatNet_es: "Más el IVA por valor añadido legal y, en su caso, los gastos de envío",
    vatNet_it: "Più l'IVA sul valore aggiunto prevista dalla legge e, se applicabile, le spese di spedizione",
  },
  dashboard: {
    hideInfoIcon: true
  }
}

const defaultTheme = {
  ids: [],
  primaryColor: 'red',
  favicon: favicon_cws,
  logo: logo_cws,
  userLogin: {
    type: 'text',
    usernamePlaceholder: 'Benutzername eingeben',
    usernamePlaceholder_en: 'Enter username',
    usernamePlaceholder_fr: "Entrez le nom d'utilisateur",
    usernamePlaceholder_es: 'Introduzca el nombre de usuario',
    usernamePlaceholder_it: 'Inserisci il nome utente',
    usernameLabel: 'Benutzername',
    usernameLabel_en: 'Username',
    usernameLabel_fr: "Nom d'utilisateur",
    usernameLabel_es: 'Nombre de usuario',
    usernameLabel_it: 'Nome utente',
    passwordLabel: 'Passwort',
    passwordLabel_en: 'Password',
    passwordLabel_fr: 'Mot de passe',
    passwordLabel_es: 'Contraseña',
    passwordLabel_it: 'Parola d’ordine',
    passwordPlaceholder: 'Passwort eingeben',
    passwordPlaceholder_en: 'Enter password',
    passwordPlaceholder_fr: 'Entrer le mot de passe',
    passwordPlaceholder_es: 'Introduzca la contraseña',
    passwordPlaceholder_it: 'Inserisci la password',
    loginBoxHeadline: 'CWS Corporate Fashion',
    loginBoxText: 'Shop für Mitarbeiterbekleidung und Ausrüstung',
    loginBoxText_en: 'Shop for employee clothing and equipment',
    loginBoxText_fr: 'Acheter des vêtements et des équipements pour les employés',
    loginBoxText_es: 'Compra de ropa y equipamiento para empleados',
    loginBoxText_it: 'Acquista abbigliamento e attrezzature per i dipendenti',
    logoMaxHeight: '50px',
    logoMaxWidth: '100%',
    backgroundImage: portal_bmw,
    backgroundSize: 'cover',
    registrationButton: false,
    phoneIcon: phone,
    mailIcon: mail,
    contactIcon: contact,
  },
  contactInfos: {
    phoneContact: "",
    emailContact: "cf.service@cws.com",
    workingWeekHours: "Mo.-Do. 08 :00 - 17 :00 CET",
    workingWeekendHours: "Fr. 08 :00 - 15 :00 CET",
    websiteName: 'www.cws.com/corporate-fashion',
    websiteLink: 'https://www.cws.com/de-DE/arbeitskleidung/arbeitskleidung-ihrem-design-und-corporate-fashion/corporate-fashion',
    showContactModules: true
  },
  footer: {
    backgroundColor: '#f378c4',
    borderColor: '#eb0045',
    textColor: 'black',
    footerCopyRight: '© CWS Supply GmbH',
    footerAdditionalSentense: 'Alle Preise verstehen sich als Nettopreise zuzüglich der Mehrwertsteuer.',
    footerAdditionalSentense_en: 'All prices are net prices plus VAT',
    footerAdditionalSentense_fr: 'Tous les prix sont des prix nets plus TVA',
    footerAdditionalSentense_es: 'Todos los precios son netos más IVA',
    footerAdditionalSentense_it: 'Tutti i prezzi sono prezzi netti più IVA.',
    agbUrl: '',
    imprintUrl: 'https://corporate-fashion-portal.cws.com/new/MandatorPDFs/Impressum-CWS-Supply-GmbH-2023-DE.pdf',
    dataProtectionUrl: 'https://corporate-fashion-portal.cws.com/new/MandatorPDFs/Datenschutzinformationen.pdf',

  },
  ordersTabs: {
    ordersTabHeader: 'Übertragen an CWS',
    ordersTabHeader_en: 'Transferred to CWS',
    ordersTabHeader_fr: 'Transféré au CWS',
    ordersTabHeader_es: 'Transferido a CWS',
    ordersTabHeader_it: 'Trasferimento a CWS'
  }
}
const bmw = {
  ...defaultTheme,
  ids: [
    'b36d4018-0b0e-4daf-8bce-c4f3e41c9207'
  ],
  userLogin: {
    ...defaultTheme.userLogin,
    loginErrorMessage: 'Bitte überprüfen Sie Ihre Anmeldedaten.<br/><br/>Bitte überprüfen Sie wie bei der Registrierung vereinbart, ob Sie die Authentifizierung per Ausweisscan und Versand an die E-Mail (<a href= "mailto: bmw-b2c@cws.com) ">bmw-b2c@cws.com</a>) erfolgreich abgeschlossen haben.',
    loginErrorMessage_en: 'Please check your login details.<br/><br/> As agreed during registration, please check whether you have successfully completed the authentication by scanning your ID and sending it to the e-mail (<a href= "mailto: bmw-b2c@cws.com) ">bmw-b2c@cws.com</a>)',
    passwordPlaceholder: 'Passwort eingeben',
    passwordPlaceholder_en: 'Enter password',
    usernamePlaceholder: 'Benutzername (eMail-Adresse) eingeben',
    usernamePlaceholder_en: 'Enter username (eMail-address)',
    usernameLabel: 'Benutzername (eMail-Adresse)',
    usernameLabel_en: 'Username (eMail-address)',
    backgroundImage: portal_cws,
    registrationButton: true,

  },
  contactInfos: {
    ...defaultTheme.contactInfos,
    phoneContact: "",
    emailContact: "bmw-b2c@cws.com",
    workingWeekHours: "Mo.-Fr. 08:00-17:00 CET",
	workingWeekendHours: "",
    orderFor: "Private Bestellung für Mitarbeiter*innen der BMW Group:",
    showContactModules: true

  },
  footer: {
    ...defaultTheme.footer,
    agbUrl: 'https://corporate-fashion-portal.cws.com/new/MandatorPDFs/AGB_bmw_b2c.pdf',
    imprintUrl: 'https://corporate-fashion-portal.cws.com/new/MandatorPDFs/Impressum_bmw_b2c.pdf',
    dataProtectionUrl: 'https://corporate-fashion-portal.cws.com/new/MandatorPDFs/Datenschutzinformationen_bmw_b2c.pdf',
    footerCopyRight: '© CWS Supply GmbH',
    footerAdditionalSentense: 'Alle Preise verstehen sich als Bruttopreise inklusive geltender Umsatzsteuer. Versandkosten berechnen sich anhand der Lieferadresse.',
    footerAdditionalSentense_en: 'All prices are gross prices including applicable sales tax. Shipping costs are calculated based on the delivery address.'
  },
  registrationConfig: bmwRegistration
}
const b2b = {
  ...defaultTheme,

  ids: [
    '92aeb91c-f919-4381-92f2-99f0c6faef4d'
  ],
  userLogin: {
    ...defaultTheme.userLogin,
    backgroundImage: portal_cws,
  },
  contactInfos: {
    ...defaultTheme.contactInfos,
    phoneContact: "",
    emailContact: "BMW-b2b-Kauf@cws.com",
    workingWeekHours: "Mo.-Fr. 08:00-17:00 CET",
	workingWeekendHours: "",
    orderFor: "Geschäftliche Bestellung für BMW Group:",
    showContactModules: true

  },
  confirmOrder: {
    orderCommentLabel: '<span style="font-weight:normal"><b>Bestellkommentar</b><br>Bei Großbestellungen ab 200 Teile bitte die interne Anlieferstelle hier angeben.</span>',
    orderCommentLabel_en: '<span style="font-weight:normal"><b>Order comment</b><br>For bulk orders of 200 or more parts, please specify the internal delivery point here.</span>'
  },
  footer: {
    ...defaultTheme.footer,
    footerCopyRight: '© CWS Workwear Deutschland GmbH & Co. KG',
    agbUrl: 'https://corporate-fashion-portal.cws.com/new/MandatorPDFs/AGB_bmw_b2b.pdf',
    imprintUrl: 'https://corporate-fashion-portal.cws.com/new/MandatorPDFs/Impressum_bmw_b2b.pdf',
    dataProtectionUrl: 'https://corporate-fashion-portal.cws.com/new/MandatorPDFs/Datenschutzinformationen_bmw_b2b.pdf',
  }
}

const porsche = {
  ...wandoDefault,
  ids: [
    '0d3b7152-4417-489b-9744-60d7ba105b6d',//test
    '9b03a868-8eb6-4d51-8309-84322314c8aa',//int
    '0d3b7152-4417-489b-9744-60d7ba105b6d' //prod
  ],
  loginLanguages: ['de', 'en', 'fr', 'es', 'it'],
  primaryColor: '#CB0900',
  logo: logo_porsche,
  userLogin: {
    ...wandoDefault.userLogin,
    loginBoxHeadline: 'Porsche Centre Collection',
    backgroundImage: portal_porche1,
    logoMaxHeight: '100%'
  },
  contactInfos: {
    ...wandoDefault.contactInfos,
    phoneContact: '+49 (0) 30 49988375',
	emailContact: "porsche.sales@cws.com",
    orderFor: "Geschäftliche Bestellung für Porsche",
    orderFor_en: 'Business order for Porsche',
    orderFor_fr: 'Commande commerciale pour Porsche',
    orderFor_es: 'Pedido de negocios para Porsche',
    orderFor_it: 'Ordine commerciale per Porsche',
    showContactModules: false
  },
  footer: {
    ...wandoDefault.footer,
    backgroundColor: '#1b1b1f',
    borderColor: '#1b1b1f',
    login_backgroundColor: '#1b1b1f',
    textColor: 'white',
    login_textColor: 'white',
    agbUrl: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Porsche-Customer-Shop-Terms-and-conditions-DE?v=335e86ea',
    agbUrl_en: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Porsche-Customer-Shop-Terms-and-conditions-EN?v=66b0b55b',
    agbUrl_fr: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Porsche-Customer-Shop-Terms-and-conditions-FR?v=a3a1e881',
    agbUrl_it: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Porsche-Customer-Shop-Terms-and-conditions-IT?v=96921a0e',
    agbUrl_es: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Porsche-Customer-Shop-Terms-and-conditions-ES?v=01e9823d',
    imprintUrl: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Porsche-Customer-Shop-Legal-notice-DE?v=29daec7f',
    imprintUrl_en: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Porsche-Customer-Shop-Legal-notice-EN?v=c43a5450',
    imprintUrl_fr: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Porsche-Customer-Shop-Legal-notice-FR?v=8efb83bf',
    imprintUrl_it: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Porsche-Customer-Shop-Legal-notice-IT?v=d9b149ff',
    imprintUrl_es: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Porsche-Customer-Shop-Legal-notice-ES?v=932afd9a',
    dataProtectionUrl: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Customer-Shop-Data-protection-information-DE?v=105a82d2',
    dataProtectionUrl_en: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Customer-Shop-Data-protection-information-EN?v=ac42e751',
    dataProtectionUrl_fr: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Customer-Shop-Data-protection-information-FR?v=fce70fdd',
    dataProtectionUrl_it: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Customer-Shop-Data-protection-information-IT?v=53e2d465',
    dataProtectionUrl_es: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Customer-Shop-Data-protection-information-ES?v=cc2f5a99',
  },
  registrationConfig: porscheRegistration
}

const hyundai = {
  ...wandoDefault,
  logo: logo_hyundai,
  ids: [
    '05d01850-6700-467e-a1a5-a42596db3197',//test
    '2c216de2-b1d4-42a5-b8cb-2bc1ab85c779',//int
    '05d01850-6700-467e-a1a5-a42596db3197'//prod
  ],
  loginLanguages: ['de', 'en', 'fr', 'es', 'it'],
  userLogin: {
    ...wandoDefault.userLogin,
    backgroundImage: portal_hyundai,
    logoMaxHeight: '100%',
    loginBoxHeadline: 'Hyundai Uniform Program Webshop'
  },
  contactInfos: {
    ...wandoDefault.contactInfos,
	emailContact: "hyundai.sales@cws.com",
    phoneContact: '+49 (0) 511 6157072',
  },
  footer: {
    ...wandoDefault.footer,
    backgroundColor: '#f9e244',
    borderColor: '#f9e244',
    agbUrl: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Hyundai-Customer-Shop-Terms-and-conditions-DE?v=865ff546',
    agbUrl_en: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Hyundai-Customer-Shop-Terms-and-conditions-EN?v=d7428503',
    agbUrl_fr: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Hyundai-Customer-Shop-Terms-and-conditions-FR?v=55d5456e',
    agbUrl_it: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Hyundai-Customer-Shop-Terms-and-conditions-IT?v=c810ce10',
    agbUrl_es: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Hyundai-Customer-Shop-Terms-and-conditions-ES?v=8effdf3a',
    imprintUrl: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Hyundai-Customer-Shop-Legal-notice-DE?v=98bdbef7',
    imprintUrl_en: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Hyundai-Customer-Shop-Legal-notice-EN?v=55f41b30',
    imprintUrl_fr: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Hyundai-Customer-Shop-Legal-notice-FR?v=7af2f8e1',
    imprintUrl_it: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Hyundai-Customer-Shop-Legal-notice-IT?v=81854909',
    imprintUrl_es: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Hyundai-Customer-Shop-Legal-notice-ES?v=282866ab',
    dataProtectionUrl: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Customer-Shop-Data-protection-information-DE?v=105a82d2',
    dataProtectionUrl_en: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Customer-Shop-Data-protection-information-EN?v=ac42e751',
    dataProtectionUrl_fr: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Customer-Shop-Data-protection-information-FR?v=fce70fdd',
    dataProtectionUrl_it: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Customer-Shop-Data-protection-information-IT?v=53e2d465',
    dataProtectionUrl_es: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Customer-Shop-Data-protection-information-ES?v=cc2f5a99',
  },
  registrationConfig: hyundaiRegistration
}

const exclusiveCustomers = {
  ...wandoDefault,
  ids: [
    'c358839a-918d-4181-9fc2-2bd70e23fb53',//test
    '09373d0e-5efa-4300-9590-b31e1a5af83d' //prod
  ],
  loginLanguages: ['de'],
  userLogin: {
    ...wandoDefault.userLogin,
    backgroundImage: portal_cws_exclusive,
    loginBoxHeadline: 'Online-Shop Exklusivkunden',
  },
  contactInfos: {
    ...wandoDefault.contactInfos,
    phoneContact: '+49 (0)6103 / 309 20 20',
    emailContact: 'WM@cws.com'
  },
  footer: {
    ...wandoDefault.footer,
    backgroundColor: '#f9e244',
    borderColor: '#f9e244',
    agbUrl: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-WM-Customer-Shop-AGB-DE?v=14d5a674',
    imprintUrl: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-WM-Customer-Shop-Impressum-DE?v=d3febca0',
    dataProtectionUrl: 'https://delivery.contenthub.cws.com/api/public/content/CWS-Workwear-Customer-Shop-Data-protection-information-DE?v=105a82d2'
  },
  registrationConfig: exclusiveCustomersRegistraion
}

export const config = {
  default: defaultTheme,
  bmw,
  b2b,
  'porsche-centre-collection': porsche,
  'hyundai-uniform-program': hyundai,
  'cws-shop-exclusive': exclusiveCustomers
}
