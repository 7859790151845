import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useObserver } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Nav, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import StyledNav from './StyledNav'
import useStores from '../../stores/useStore'
import styled from 'styled-components'
import MediaQuery from 'react-responsive'
import getThemeValue from '../../utils/getThemeValue'

const StyledNavWrapper = styled.div`
  height: 100%;
  margin-left: 0;
  font-weight: bold;
  font-size: 17px;
  @media screen and (max-width: 767px) {
    position: absolute;
    background: #f6f6f6;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    height: 50px;
    top: 50px;
    left: 0;
    width: 100%;
  }
  .nav{
    flex-wrap:nowrap;
  }
`

const NavigationLeft = () => {
  const { uiStore } = useStores()
  const { t } = useTranslation()
  const currentLocation = useLocation()

  useEffect(() => {
    let activeNav = /^\/(employees$|addemployee|editemployee|editcheckups)/.test(currentLocation.pathname) ? 'accounts' : ''
    activeNav = /^\/(bulkorder|employeesordertable|products|costcenter(\/|$)|storehouse(\/|$)|shoppingcart|orderTemplates)/.test(currentLocation.pathname) ? 'order' : activeNav
    activeNav = /^\/(reports|appointmentsadministration|sizeDetection|srmorderadministration)/.test(currentLocation.pathname) ? 'administration' : activeNav
    activeNav = /^\/(orders|equipment|inventoryHistory|personalSpace)/.test(currentLocation.pathname) ? 'profileNav' : activeNav
    activeNav = /^\/(articleCatalog|custom-html(\/|$))/.test(currentLocation.pathname) ? 'advice-and-services' : activeNav
    activeNav = currentLocation.pathname.charAt(currentLocation.pathname.length - 1) === '/' ? '/' : activeNav
    uiStore.changeActiveNav(activeNav)

    return () => {
      uiStore.changeActiveNav('')
    }
  }, [currentLocation, uiStore])

  const renderDropDown = useObserver(() => [uiStore.employeeOrderAvailable, uiStore.personalOrderAvailable, uiStore.costcenterOrderAvailable, uiStore.allowImportOrders, uiStore.privateOrder, uiStore.allowOrderTemplates, uiStore.storehouseOrderAvailable].filter(Boolean).length > 1)

  const orderNavs = useObserver(() => (
    <>
      {renderDropDown ? (
        <NavDropdown title={t('header:order')} active={uiStore.activeNav === 'order'}>
          {uiStore.personalOrderAvailable &&
            <LinkContainer to="/products/personal"><NavDropdown.Item>{t('header:personalOrder')}</NavDropdown.Item></LinkContainer>}
          {uiStore.employeeOrderAvailable &&
            <LinkContainer to="/employeesordertable"><NavDropdown.Item>{t('header:employeeorder')}</NavDropdown.Item></LinkContainer>}
          {uiStore.costcenterOrderAvailable &&
            <LinkContainer to="/costcenter"><NavDropdown.Item>{getThemeValue('header', 'costcenterOrder', t('header:costcenterOrder'))}</NavDropdown.Item></LinkContainer>}
          {uiStore.storehouseOrderAvailable &&
            <LinkContainer to="/storehouse"><NavDropdown.Item>{getThemeValue('header', 'storehouseOrder', t('header:storehouseOrder'))}</NavDropdown.Item></LinkContainer>}
          {uiStore.allowOrderTemplates &&
            <LinkContainer to={'/orderTemplates'} state={{ selectedStatus: 'template' }}><NavDropdown.Item>{t('orderTemplates:reOrder')}</NavDropdown.Item></LinkContainer>}
          {uiStore.allowImportOrders &&
            <LinkContainer to="/bulkorder"><NavDropdown.Item>{t('header:bulkOrder')}</NavDropdown.Item></LinkContainer>}
          {uiStore.privateOrder &&
            <LinkContainer to="/products/private"><NavDropdown.Item>{t('header:privateOrder')}</NavDropdown.Item></LinkContainer>}
        </NavDropdown>
      ) : (
        <>

          {uiStore.personalOrderAvailable &&
            <Nav.Item><LinkContainer active={uiStore.activeNav === 'order'} to="/products/personal"><Nav.Link>{t('header:order')}</Nav.Link></LinkContainer></Nav.Item>}
          {uiStore.employeeOrderAvailable &&
            <Nav.Item><LinkContainer active={uiStore.activeNav === 'order'} to="/employeesordertable"><Nav.Link>{t('header:employeeorder')}</Nav.Link></LinkContainer></Nav.Item>}
          {uiStore.costcenterOrderAvailable &&
            <Nav.Item><LinkContainer active={uiStore.activeNav === 'order'} to="/costcenter"><Nav.Link>{getThemeValue('header', 'costcenterOrder', t('header:costcenterOrder'))}</Nav.Link></LinkContainer></Nav.Item>}
          {uiStore.storehouseOrderAvailable &&
            <Nav.Item><LinkContainer active={uiStore.activeNav === 'order'} to="/storehouse"><Nav.Link>{getThemeValue('header', 'storehouseOrder', t('header:storehouseOrder'))}</Nav.Link></LinkContainer></Nav.Item>}
          {uiStore.allowOrderTemplates &&
            <Nav.Item><LinkContainer active={uiStore.activeNav === 'order'} to="/orderTemplates"><Nav.Link>{t('orderTemplates:reOrder')}</Nav.Link></LinkContainer></Nav.Item>}
          {uiStore.allowImportOrders &&
            <Nav.Item><LinkContainer active={uiStore.activeNav === 'order'} to="/bulkorder"><Nav.Link>{t('header:bulkOrder')}</Nav.Link></LinkContainer></Nav.Item>}
          {uiStore.privateOrder &&
            <Nav.Item><LinkContainer active={uiStore.activeNav === 'order'} to="/products/private"><Nav.Link>{t('header:privateOrder')}</Nav.Link></LinkContainer></Nav.Item>}
        </>
      )}
    </>
  ))


  const navPills = useObserver(() => (
    <Nav variant="pills">
      <Nav.Item>
        <LinkContainer to="/" active={uiStore.activeNav === '/'} >
          <Nav.Link>Home</Nav.Link>
        </LinkContainer>
      </Nav.Item>
      {(uiStore.employeeAdministration || uiStore.allowAccessUserModule || uiStore.allowSeeEmployeeInventories) && (
        <NavDropdown title={t('header:accounts')} active={uiStore.activeNav === 'accounts'}>
          <LinkContainer
            to="/employees"><NavDropdown.Item>{t('header:employees')}</NavDropdown.Item></LinkContainer>
        </NavDropdown>
      )}
      {orderNavs}

      {(uiStore.reportAdministration || uiStore.fittingAdministration || uiStore.activateSRMOrder || uiStore.allowChangeArticleConfiguration) && (
        <NavDropdown title={t('header:administration')} active={uiStore.activeNav === 'administration'}>
          {uiStore.reportAdministration && <LinkContainer to="/reports"><NavDropdown.Item>{t('reports')}</NavDropdown.Item></LinkContainer>}
          {uiStore.fittingAdministration && <LinkContainer to="/appointmentsadministration"><NavDropdown.Item>{t('appointment:appointmentAdministration')}</NavDropdown.Item></LinkContainer>}
          {uiStore.allowChangeArticleConfiguration && <LinkContainer to="/sizeDetection"><NavDropdown.Item>{t('header:sizeDetection')}</NavDropdown.Item></LinkContainer>}
          {uiStore.activateSRMOrder && <LinkContainer to="/srmorderadministration"><NavDropdown.Item>{t('srm:srmOrder')}</NavDropdown.Item></LinkContainer>}
        </NavDropdown>
      )}
    </Nav>
  ))

  return (
    <>
      <MediaQuery minWidth={993}>
        <StyledNavWrapper>
          <StyledNav>
            {navPills}
          </StyledNav>
        </StyledNavWrapper>
      </MediaQuery>
      <MediaQuery maxWidth={992}>
        {navPills}
      </MediaQuery>
    </>
  )
}

export default NavigationLeft
