import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Spinner } from 'react-bootstrap'
import { updateFavicon } from '../../utils/updateFavicon'
import { themes } from '../../styles/themes'
import i18n from '../../translation/i18n'

const ThemeInit = inject('uiStore')(observer((props) => {

  const { uiStore, children } = props
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const regex = /^\/([^/]*)(\/([^/]*))?/
    let themePathMatch = null

    //the HOMEPAGE part (sometimes /logix/ )
    if (process.env.PUBLIC_URL) {
      themePathMatch = window.location.toString().split(process.env.PUBLIC_URL).pop().match(regex)
    } else {
      themePathMatch = window.location.pathname.match(regex)
    }
    let defaultTheme = 'default'
    let defaultLanguage = 'de'
    let hasThemeAndLanguageProp = themePathMatch && themePathMatch.length && !!themePathMatch[1] && !!themePathMatch[3]
    let paramsThemeIsValide = hasThemeAndLanguageProp && themes.hasOwnProperty(themePathMatch[1])
    let useTheme = paramsThemeIsValide ? themePathMatch[1] : defaultTheme
    let useLanugae = hasThemeAndLanguageProp ? themePathMatch[3] : defaultLanguage
    let basenameMatch = hasThemeAndLanguageProp && !!window.location.pathname.split(themePathMatch[0])[1] //force reload if url is shorter than basename example: https://demo4.rigilog.com/logix/default/de while basename is https://demo4.rigilog.com/logix/default/de/ 

    if (hasThemeAndLanguageProp && paramsThemeIsValide && basenameMatch) {
      i18n.changeLanguage(useLanugae)
      updateFavicon(themes[useTheme].favicon)
      uiStore.language = useLanugae
      uiStore.setTheme(useTheme)
      setLoading(false)
    }
    else {
      window.location = process.env.PUBLIC_URL + '/' + useTheme + '/' + useLanugae + '/';
    }
  }, [uiStore])

  if (loading) {
    return <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
      <Spinner animation='border' variant='primary' />
    </div>
  }

  return <>{children}</>
}))

export default ThemeInit
