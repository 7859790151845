import React, { useState, useRef, useEffect } from 'react'
import { CloseButton, Form, Button } from 'react-bootstrap'
import { colors } from '../../styles/colors'
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next'
import { MagnifyingGlassIcon } from '../Icons/MagnifyingGlassIcon'
import styled, { withTheme } from 'styled-components'

const StyledItem = styled.div`
  display:flex;
  margin: 2px;
  padding: 2px 10px;
  border: 1px solid ${colors.borderColor};
  border-radius: 8px;
  background:white;
  line-break:anywhere;
  .close {
    margin-left: 15px;
  }
`

const StyledWrapper = styled.div`
    position:relative;
    width:100%;
`
const StyledTagsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`
const StyledAddTag = styled.div`
    position:absolute;
    display:flex;
    z-index:1;
    justify-content:space-between;
    background: #2d2d2da1;
    width: 100%;
    border-radius: 5px;
    margin-top: 2px;
    padding: 2px;
`

const StyledMagnifyingGlassIcon = styled.span`
  position: absolute;
  top: 7px;
  right: 8px;
  z-index: 0;
`

const Tags = ({ tags, removeTag }) => (
    <>
        {tags.map(tag => <StyledItem key={tag}>{tag}<CloseButton onClick={() => removeTag(tag)} /></StyledItem>)}
    </>
)

const TagsInput = (props) => {
    const { t } = useTranslation()
    const wrapperRef = useRef(null);
    const {
        theme,
        tags = [],
        setTags,
        className,
        placeholder = "..."
    } = props
    const [value, setValue] = useState('')
    const handleChange = e => {
        setValue(e.target.value)
    }
    const createTag = (e) => {
        e.preventDefault()
        e.stopPropagation()
        value && !tags.includes(value) && setTags([...tags, value])
        setValue('')
    }
    const removeTag = (tag) => {
        const index = tags.indexOf(tag);
        if (index > -1) {
            const newTags = cloneDeep(tags)
            newTags.splice(index, 1)
            setTags(newTags);
        }
    }
    function useOutsideDivClick(ref) {
        useEffect(() => {
            /**
             * do something if clicked outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setValue('')
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideDivClick(wrapperRef);
    return (
        <StyledWrapper className={className} ref={wrapperRef}>
            <Form onSubmit={createTag}>
                <Form.Control className="pe-4" placeholder={placeholder} onChange={handleChange} value={value} />
                <StyledMagnifyingGlassIcon>
                    <MagnifyingGlassIcon color={theme.primaryColor} />
                </StyledMagnifyingGlassIcon>
            </Form>
            {value && <StyledAddTag ><StyledItem >{value}</StyledItem><Button onClick={createTag} size="sm">{t('add')}</Button> </StyledAddTag>}
            <StyledTagsWrapper>
                {tags.length ? <Tags tags={tags} removeTag={removeTag} /> : null}
            </StyledTagsWrapper>
        </StyledWrapper>
    )
}

export default withTheme(TagsInput)
