import React from 'react'

export const ClaimIcon = ({ color, title, ...rest }) => (
	<svg width='30' height='30' viewBox='0 0 113.4 113.4' className='claim-icon-svg' fill={color}
		{...rest}>
		{title && <title>{title}</title>}
		<g>
			<path className='st0' d='M99.2,30.7c0-0.4,0-0.7-0.2-1c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.3-0.4-0.6-0.5C98.2,29,98,29,97.9,28.9
		c-1.2-0.5-2.4-0.9-3.6-1.4c-2.9-1.1-5.8-2.3-8.7-3.4c-3.5-1.4-7-2.8-10.5-4.2c-3-1.2-6.1-2.4-9.1-3.6c-1.4-0.6-2.9-1.2-4.4-1.7
		c-0.6-0.2-1,0-1.5,0.2c-0.8,0.3-1.5,0.6-2.3,0.9c-2.7,1.1-5.5,2.2-8.2,3.2c-3.5,1.4-7,2.8-10.5,4.1c-3.2,1.2-6.3,2.5-9.5,3.7
		c-1.7,0.7-3.4,1.4-5.2,2c-0.1,0-0.1,0-0.2,0.1c-0.2,0-0.3,0.1-0.5,0.2C23.3,29.4,23,30,23,30.6c0,1.3,0,2.7,0,4c0,3.2,0,6.4,0,9.6
		c0,3.2,0,6.3,0,9.5L14.6,62c-0.4,0.3-0.4,0.7-0.4,1v20.1c0,0.3,0.1,0.7,0.4,1l14.3,14.3c0.3,0.3,0.7,0.5,1,0.5H50
		c0.3,0,0.7-0.1,1-0.4l10.1-10.1c0,0,0,0,0,0c0.2,0,0.3,0,0.4-0.1c0.2,0,0.3-0.1,0.5-0.2c1.2-0.5,2.4-1.1,3.6-1.6
		c2.9-1.3,5.8-2.6,8.7-3.8c3.5-1.5,7-3.1,10.5-4.6c3-1.3,6.1-2.7,9.1-4c1.5-0.6,3-1.2,4.4-1.9c0,0,0,0,0.1,0
		c0.4-0.2,0.7-0.6,0.8-1.1c0-0.1,0-0.2,0-0.4c0-1.3,0-2.7,0-4c0-3.2,0-6.4,0-9.6c0-3.9,0-7.7,0-11.6c0-3.3,0-6.7,0-10
		C99.2,33.9,99.2,32.3,99.2,30.7z M57.1,19.6c1-0.4,2-0.8,3-1.2c0.3-0.1,0.7-0.3,1.1-0.4c1.1,0.4,2.1,0.8,3.2,1.2
		c2.9,1.1,5.8,2.3,8.7,3.4c3.5,1.4,7,2.8,10.5,4.1c3,1.2,6.1,2.4,9.1,3.6c0.2,0.1,0.3,0.1,0.5,0.2c0,0,0,0-0.1,0
		c-2.9,1.3-5.8,2.6-8.7,3.8c-1.3,0.6-2.7,1.2-4.1,1.8c-1.1-0.4-2.2-0.9-3.2-1.3c-2.9-1.2-5.8-2.4-8.7-3.6c-3.5-1.4-7-2.9-10.5-4.3
		c-3-1.2-6.1-2.5-9.1-3.7c-0.2-0.1-0.3-0.1-0.5-0.2C51.2,22,54.1,20.8,57.1,19.6z M37.5,27.4c2.1-0.8,4.1-1.6,6.2-2.4
		c1.1,0.5,2.2,0.9,3.3,1.4c2.9,1.2,5.8,2.4,8.7,3.6c3.5,1.4,7,2.9,10.5,4.3c3,1.2,6.1,2.5,9.1,3.7c0.2,0.1,0.4,0.2,0.7,0.3
		c-0.7,0.3-1.4,0.6-2.1,0.9c-3,1.3-6.1,2.7-9.1,4c0,0,0,0,0,0c-1.2,0.5-2.4,1-3.6,1.6c-0.9-0.4-1.8-0.8-2.7-1.2
		c-2.9-1.3-5.8-2.6-8.7-3.8c-3.5-1.5-7-3.1-10.5-4.6c-3-1.3-6.1-2.7-9.1-4c-0.3-0.1-0.6-0.3-0.9-0.4C31.9,29.6,34.7,28.5,37.5,27.4z
		 M26.4,45.5c0-3.3,0-6.7,0-10c0-0.8,0-1.5,0-2.3c0,0,0,0,0.1,0c0.3,0.1,0.6,0.3,1,0.4c2.9,1.3,5.8,2.6,8.7,3.9
		c3.5,1.5,7,3.1,10.5,4.6c3,1.3,6,2.7,9.1,4c0.4,0.2,0.7,0.3,1.1,0.5c0.8,0.4,1.6,0.8,2.5,1.1c0,1,0,1.9,0,2.9c0,1.8,0,3.7,0,5.5
		L51,47.8c-0.3-0.3-0.7-0.4-1-0.4H29.9c-0.3,0-0.7,0.1-1,0.4l-2.5,2.5C26.4,48.7,26.4,47.1,26.4,45.5z M50.1,94
		c-0.7,0.7-1.7,1.1-2.6,1.1H32.4c-1,0-2-0.4-2.7-1.1L19.1,83.3c-0.7-0.6-1.1-1.6-1.1-2.6V65.6c0-1,0.4-2,1.1-2.7l10.6-10.6
		c0.7-0.7,1.7-1.1,2.6-1.1h15.1c1,0,2,0.4,2.7,1.1l10.6,10.6c0.7,0.7,1.1,1.7,1.1,2.6v15.1c0,1-0.4,2-1.1,2.7L50.1,94z M95.8,34.6
		c0,3.2,0,6.4,0,9.6c0,3.9,0,7.7,0,11.6c0,3.3,0,6.7,0,10c0,1.3,0,2.6,0,3.8c-0.9,0.4-1.8,0.8-2.8,1.2c-2.9,1.3-5.8,2.6-8.7,3.8
		c-3.5,1.5-7,3.1-10.5,4.6c-2.7,1.2-5.4,2.4-8.1,3.6V63c0-0.3-0.1-0.7-0.4-1l-2.5-2.5c0-2.7,0-5.3,0-8c0-1.3,0-2.6,0-3.8
		c0.6-0.3,1.3-0.6,1.9-0.8c2.7-1.2,5.5-2.4,8.2-3.6c1.8-0.8,3.7-1.6,5.5-2.4c0,2.4,0,4.7,0,7.1c0,0.5,0,0.9,0,1.4
		c0,0.9,0.8,1.8,1.7,1.7c0.9,0,1.7-0.8,1.7-1.7c0-3.3,0-6.5,0-9.8c0-0.1,0-0.1,0-0.2c0.5-0.2,1-0.5,1.6-0.7c3.2-1.4,6.3-2.8,9.5-4.2
		c0.3-0.2,0.7-0.3,1-0.5c0.6-0.3,1.2-0.5,1.8-0.8C95.8,33.7,95.8,34.1,95.8,34.6z' />
			<path className='st0' d='M39.9,85.4c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9
		C42.9,86.7,41.6,85.4,39.9,85.4z' />
			<path className='st0' d='M39.9,57c-1.2,0-2.3,0.6-3.1,1.6c-0.5,0.7-0.9,1.5-1,2.4c0,0.2,0,0.5,0,0.7l1.7,19.1c0.1,0.6,0.3,1.1,0.7,1.5
		c0.4,0.4,1,0.7,1.7,0.7c0.6,0,1.1-0.2,1.5-0.6c0.5-0.4,0.8-1,0.8-1.6L44,61.6c0-0.2,0-0.5,0-0.7c-0.1-0.8-0.4-1.6-0.9-2.3
		C42.3,57.6,41.2,57,39.9,57z' />
		</g>
	</svg >

)

