import React, { useEffect, useState } from 'react'
import { Subheader } from '../components/Page/logix2020/Subheader'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import { useParams } from 'react-router-dom'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import BigFooter from '../components/Footer/BigFooter'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import useStores from '../stores/useStore'
import { Spinner } from 'react-bootstrap'
import SafelySetInnerHTML from '../components/SafelySetInnerHTML'

const StyledContentArea = styled(ContentArea)`
   @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px)  {
    padding: 20px;
    min-height: calc(100vh - 140px);//header+navbar=140px
   }
`

const CustomHtmlPage = () => {
    const { cwsStore } = useStores()
    const { menuId } = useParams()
    const [contentTitle, setContentTitle] = useState("")
    const [content, setContent] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    cwsStore.loadMenuItems()
    const menuItems = useObserver(() => cwsStore.menuItems)

    useEffect(() => {
        setContent("")
        setIsLoading(true)
        const menuItem = menuItems && menuItems.find(obj => obj.id === menuId)
        if (menuItem) {
            setContentTitle(menuItem.displayName)
            apiClient.getJson(APIEndpoints.customHtmlPage(menuId))
                .then(response => {
                    setContent(response)
                }).catch(error => {
                    setContent("diese Seite wird nicht gepflegt")
                }).finally(() => setIsLoading(false))
        }
    }, [menuId, menuItems])


    return useObserver(() => (
        <>
            <Subheader className="subHeader-v1">
                <div className="subheader-title">{contentTitle}</div>
            </Subheader>
            <StyledContentArea >
                <SafelySetInnerHTML>{content}</SafelySetInnerHTML>
                {isLoading && <div className='d-flex justify-content-center' >
                    <Spinner animation='border' variant='primary' />
                </div>}
            </StyledContentArea>
            <BigFooter />

        </>
    ))
}

export default CustomHtmlPage