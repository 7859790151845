import React, { useState, useEffect } from 'react'
import useStores from '../../stores/useStore'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import { Alert, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import AdministrationSidebarNavigation from '../../components/Configuration/AdministrationSidebarNavigation'
import EditCostCenterTree from '../../components/Configuration/CostCenter/EditCostCenterTree'
import { Link } from 'react-router-dom'
import BigFooter from '../../components/Footer/BigFooter'
import CreateNewExportOverlay from '../../components/Files/CreateNewExportOverlay'
import CreateNewImportOverlay from '../../components/Files/CreateNewImportOverlay'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import { ExcelButton } from '../../components/Buttons/ExcelButton'
import { MdLibraryAdd } from 'react-icons/md'


const CostCenterAdministrationPageContent = () => {
  const { t } = useTranslation()
  const [showExportOverlay, setShowExportOverlay] = useState(false)
  const [showImportOverlay, setShowImportOverlay] = useState(false)
  const { uiStore } = useStores()

  const onCloseOverlayClick = () => {
    setShowExportOverlay(false)
    setShowImportOverlay(false)
  }

  return (
    <div>
      <Subheader className="subHeader-v1">
        <div className="subheader-title flex-grow-1">{t('costCenterAdmin:costCenterHeader')}</div>
        <div className="subheader__buttonWrapper">
          {uiStore.allowImportCostcenters &&
            <ExcelButton onClick={() => setShowImportOverlay(prev => !prev)} className="subheader__button" variant="secondary" buttonText={t('import')} />
          }
          <ExcelButton onClick={() => setShowExportOverlay(prev => !prev)} className="subheader__button" variant="secondary" buttonText={t('export')} />
          <Link to="/costcenteradministration/create#general" className="subheader__button btn btn-primary text-white"><MdLibraryAdd className="me-3" />{t('costCenterAdmin:createNewButton')}</Link>
        </div>
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <AdministrationSidebarNavigation active="costcenteradministration" />
        </SidebarLeft>
        <ContentArea>
          <EditCostCenterTree />
        </ContentArea>
      </FlexWrapper>
      <BigFooter />
      <CreateNewExportOverlay exportName="costcenterExport" visible={showExportOverlay} onClose={onCloseOverlayClick} />
      <CreateNewImportOverlay importName="costcenterImport" visible={showImportOverlay} onClose={onCloseOverlayClick} />

    </div >
  )
}
const CostCenterAdministrationPage = () => {

  const { t } = useTranslation()
  const { uiStore } = useStores()
  const [error, setError] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setError(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {!uiStore.allowImportCostcenters ? (
        <>
          <Subheader className="subHeader-v1 justify-content-between">
            <div className="subheader-title">{t('costCenterAdmin:costCenterHeader')}</div>
          </Subheader>
          <FlexWrapper>
            <SidebarLeft>
              <AdministrationSidebarNavigation active="costcenteradministration" />
            </SidebarLeft>
            <ContentArea>
              {error
                ? <Alert variant="danger">{t('accessDenied')}</Alert>
                : <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
              }
            </ContentArea>
          </FlexWrapper>
          <BigFooter />
        </>
      ) : <CostCenterAdministrationPageContent />
      }
    </>
  )
}
export default CostCenterAdministrationPage
