import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ArraySelect from '../Select/ArraySelect'
import getPreparedVariants from '../../utils/getPreparedVariants'

const VariantSelect = ({ variants, selectedFirstPartVariant, handleChangeFirstPartVariant, selectedVariantError, selectedSecondPartVariant, handleChangeSecondPartVariant }) => {
    const preparedVariants = getPreparedVariants(variants)
    const preparedVariant = preparedVariants.find(v => v.displayName === selectedFirstPartVariant)
    const subVariants = selectedFirstPartVariant && preparedVariant ? preparedVariant.subVariants : []
    const { t } = useTranslation()
    return (
        <Row>
            <Form.Group as={Col} >
                <ArraySelect
                    onChange={handleChangeFirstPartVariant}
                    isInvalid={selectedVariantError}
                    value={selectedFirstPartVariant}
                    options={preparedVariants}
                    returnEvent
                />
                <Form.Control.Feedback type='invalid'>{selectedVariantError && t('productDetail:selectVariantSize')}</Form.Control.Feedback>
            </Form.Group>

            {subVariants.length ?
                <Form.Group as={Col}>
                    <ArraySelect
                        onChange={handleChangeSecondPartVariant}
                        isInvalid={selectedVariantError}
                        value={selectedSecondPartVariant}
                        options={subVariants}
                        returnEvent
                    />
                </Form.Group>
                : ''}
        </Row>
    )
}

export default VariantSelect