import styled from 'styled-components'

/*
* usage example:
*
*  <div className="data-list">
*      <div className="data-list__name h2">Main Title</div>
*      <ul className="data-list__data">
*        <li className="data-list__entry">
*          <div className="data-list__label">Label</div>
*          <div className="data-list__value">Value</div>
*        </li>
*       </ul>
*  </div>
*/

export const StyledDataList = styled.div`

  .data-list{
    //.data-list__name
    &__name {
      margin-bottom: 20px;
      margin-right:20px;
      display: flex;
      justify-content: space-between;

      &.h2 {
       font-size: 20px;
      }

      &.h3 {
        font-size: 16px;
        margin-bottom: 15px;
      }

      .edit-button{
        flex-shrink: 0;
        cursor: pointer;
      }
    }
    &__data {
      list-style: none;
      padding-left: 0;
      font-size: 12px;
      li {
        display: flex;
        @media screen and (max-width: 400px){
          flex-wrap: wrap;
        }

        ul {
          padding-left: 0;
        }
      }
    }
    &__label {
      flex-basis: 100%;
      padding-right: 15px;
      @media screen and (min-width: 400px){
        flex-basis: 45%;
      }
    }
    &__value {
     /*  word-break: break-all; */
      flex-basis: 55%;
      font-weight: 600;
      margin-bottom: 10px;
      font-size: 14px;
    }

  }
`
