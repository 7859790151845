import React, { Component } from 'react'
import { reaction } from 'mobx'
import { inject, observer } from 'mobx-react'
import ReclamationDialog from '../../OrderActions/ReclamationDialog/ReclamationDialog'
import CancellationDialog from '../../OrderActions/CancellationDialog/CancellationDialog'
import ReturnDialog from '../../OrderActions/ReturnDialog/ReturnDialog'
import { emptyGuid } from '../../../utils/emptyGuid'
import { settingValueToType } from '../../../utils/api/apiUtils'

class OrderDialogs extends Component {
  loadDataReactionDispose = null
  forceUpdateReactionDispose = null
  allowReorderReturns = false;

  constructor(props) {
    super(props)
    const { orderDialogStore, costCenterStore, incomingOrderId, orderStore } = this.props
    this.allowReorderReturns = !!settingValueToType(orderStore.orderDetailSettings.find(setting => setting.settingName === 'allowReorderReturns'))

    this.loadDataReactionDispose = reaction(
      () => ['reclamation', 'retouration'].includes(orderDialogStore.activeDialog),
      actionIsReclamationOrRetouration => {
        if (actionIsReclamationOrRetouration) {
          costCenterStore.loadPartialCostCenters(emptyGuid)
          orderDialogStore.loadReturnReasons()

        }

        if (orderDialogStore.activeDialog === 'reclamation') {
          orderDialogStore.loadComplaintsContactData(incomingOrderId)
          orderDialogStore.loadComplaints(incomingOrderId)
        }
        if (orderDialogStore.activeDialog === 'retouration') {
          orderDialogStore.loadReturnsContactData(incomingOrderId)
          orderDialogStore.loadCountries()
          orderDialogStore.loadReturns(incomingOrderId)
        }
      }
    )

    //Update dialog rendering after change on _selectedQuantities
    this.forceUpdateReactionDispose = reaction(
      () => orderDialogStore.groupedOrderPositions.reduce((acc, gp) => { return gp.positions.reduce((accPosition, p) => accPosition + p._selectedQuantity, 0) }, 0),
      gp => this.forceUpdate()
    )
  }

  componentWillUnmount() {
    this.loadDataReactionDispose && this.loadDataReactionDispose()
    this.forceUpdateReactionDispose && this.forceUpdateReactionDispose()
  }

  render() {
    const { orderDialogStore, costCenterStore } = this.props
    const costCenters = costCenterStore.partialCostCenters.filter(costCenter => costCenter.isAllowToOrder)

    return (
      <React.Fragment>
        {orderDialogStore.activeDialog === 'reclamation' && (
          <ReclamationDialog
            costCenters={costCenters}
            defaultEmail={orderDialogStore.contactData && orderDialogStore.contactData.reportEmail}
            onSubmit={(formData, files) => orderDialogStore.submitReclamation(formData, files)}
            onCancel={() => orderDialogStore.closeDialog()}
            loading={orderDialogStore.loading}
            success={orderDialogStore.success}
            issueId={orderDialogStore.complaintResponse.feedbackNumber}
            issueEmail={orderDialogStore.complaintResponse.reportEmail}
            emailWasSent={orderDialogStore.complaintResponse.emailWasSent}
            feedbackId={orderDialogStore.complaintResponse.id}
            contactData={orderDialogStore.contactData}
            dialogError={orderDialogStore.error}
            returnReasons={orderDialogStore.returnReasons}
            complaints={orderDialogStore.complaints}
          />
        )}
        {orderDialogStore.activeDialog === 'cancellation' && (
          <CancellationDialog
            productsLength={orderDialogStore.getPositions().length}
            onSubmit={() => orderDialogStore.submitCancelation()}
            onCancel={() => orderDialogStore.closeDialog()}
            loading={orderDialogStore.loading}
            success={orderDialogStore.success}
            error={orderDialogStore.error}
          />
        )}
        {orderDialogStore.activeDialog === 'retouration' && (
          <ReturnDialog
            onSubmit={(formData) => orderDialogStore.submitRetour(formData)}
            onCancel={() => orderDialogStore.closeDialog()}
            loading={orderDialogStore.loading}
            success={orderDialogStore.success}
            issueId={orderDialogStore.retourResponse.feedbackNumber}
            issueEmail={orderDialogStore.retourResponse.email}
            emailWasSent={orderDialogStore.retourResponse.emailWasSent}
            feedbackId={orderDialogStore.retourResponse.id}
            returnsPortalUrl={orderDialogStore.retourResponse.returnsPortalUrl}
            contactData={orderDialogStore.contactData}
            returnReasons={orderDialogStore.returnReasons}
            countries={orderDialogStore.countries}
            orderDialogError={orderDialogStore.error}
            hideReorderOption={!this.allowReorderReturns}
            returns={orderDialogStore.returns}
          />
        )}
      </React.Fragment>
    )
  }
}

export default inject('orderDialogStore', 'costCenterStore', 'orderStore')(observer(OrderDialogs));
