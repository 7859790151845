import React from 'react'
import DynamicFormElements from '../Form/DynamicFormElements'

const formConfigTemplate = [
    {
        type: 'checkbox',
        name: 'checkImportDataOnly',
        label: 'checkImportDataOnly'
    },
    {
        type: 'text',
        name: 'mailAddress',
        label: 'emailAddress'
    },
    {
        type: 'select',
        name: 'shipmentTypeId',
        label: 'shipmentTypeId',
        defaultIsEmptyGuid: true,
        defaultName: 'select',
        options: []
    },

]

const OrderImport = ({ formData, setFormData, formErrors }) => {

    return (
        <>
            <DynamicFormElements formConfigTemplate={formConfigTemplate} setFormData={setFormData} formData={formData} formErrors={formErrors} />
        </>
    )
}
export default OrderImport