import React, { Suspense, lazy, useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import Form from 'react-bootstrap/Form'
import { withTranslation } from 'react-i18next'
import { VscSaveAs } from 'react-icons/vsc'
import { useNavigate, useLocation } from 'react-router-dom'

import { colors } from '../../styles/colors'
import { ButtonWithLoadingState } from '../Buttons'
import SafelySetInnerHTML from '../SafelySetInnerHTML'
import PriceSummary from './PriceSummary'
import getThemeValue from '../../utils/getThemeValue'
import ModalComponent from '../Modals/ModalComponent'
import CustomDatePicker from '../Forms/CustomDatePicker'
import { emptyDate } from '../../utils/emptyDate'


const mandate = process.env.REACT_APP_MANDATORSET //return mandate=cws

const lazyImportTermsCheck = () => {
  return import(`../../components_overrides/${mandate}/Order/TermsAndConditions`).catch(() => {
    return import(`../../components/ShoppingCart/TermsAndConditions`)
  });
};

const lazyImportCheckoutConfirmationModal = () => {
  return import(`../../components_overrides/${mandate}/Order/CheckoutConfirmationModal`).catch(() => {
    return import(`../../components_overrides/EmptyComponent`)
  });
};
const TermsAndConditions = lazy(lazyImportTermsCheck)
const CheckoutConfirmationModal = lazy(lazyImportCheckoutConfirmationModal)
const StyledGrayContainer = styled.div`
  padding: 30px;

  @media screen and (min-width: 1080px) {
    border-top: none;
    border-bottom: none;
    border-left: 1px solid ${colors.gray2};
    padding-top: 46px;
  }
`

const StyledStickySummary = styled.div`
  position: sticky;
   top: 96px;
 
  @media screen and (min-width: 1080px){
    top: 46px;
  }
 
`
const StyledHeaderButtons = styled.div`

  @media screen and (min-width:992px) {
      margin-top:1.5rem;
      display:flex;
      justify-content: space-between;
  }
  @media only screen and (max-width: 991px), (min-device-width: 320px) and (max-device-width: 991px)  {
    margin-top:1.5rem;
   button{
       width:100%;
       margin-bottom:1rem;
 }
}
`
export const ShoppingCartSummary = inject('uiStore', 'productsStore', 'checkoutStore', 'teamOrderStore')(withTranslation()(observer((props) => {
  const { t, uiStore, productsStore, checkoutStore, teamOrderStore } = props
  const navigate = useNavigate();
  const location = useLocation();


  const basket = productsStore.basket ? productsStore.basket : {}
  const orderCommentLabel = getThemeValue('confirmOrder', 'orderCommentLabel', t('shoppingCart:orderComment'))
  const [submitResult, setSubmitResult] = useState({})
  const [openSaveTemplateModal, setOpenSaveTemplateModal] = useState(false)

  const handleSubmit = async () => {
    const result = await productsStore.handleConfirmOrderSubmit(navigate, mandate)
    setSubmitResult(result)
  }

  const getArticlesString = () => {
    var result = ""
    try {
      result = basket.articles.map(article => article.article.displayName).join(', ')
      if (result.length > 27) {
        result = result.substring(0, 27).concat('...')
      }
    }
    catch (e) {
      console.log("error in getArticlesString function")
    }
    return result
  }
  const orderSubmitButtonDefaultName = getThemeValue('shoppingCart', 'buttonOrderSubmit', t('shoppingCart:buttonOrderSubmit'))

  return (
    <StyledGrayContainer>
      <StyledStickySummary>
        {(location.pathname === uiStore.linkToBasket) &&
          < >
            {!productsStore.hidePricesAndPoints && <PriceSummary />}
            {basket.articles && basket.articles.length ?
              <StyledHeaderButtons>
                <Button variant="danger" onClick={() => productsStore.removeAllArticlesFromBasket()}>{t('shoppingCart:deleteCart')}</Button>
                <Button onClick={() => productsStore.validateBasketArticles(navigate)}>{t('shoppingCart:nextButton')}</Button>
              </StyledHeaderButtons> : ''}
            {
              productsStore.pageErrors.map((error, errorIndex) => <Alert key={errorIndex} className='mt-3 mb-3' variant='danger' style={{ whiteSpace: 'break-spaces' }}>{error}</Alert>)
            }
          </>}
        {(location.pathname === uiStore.linkToConfirmAddress) &&
          <>
            {!productsStore.hidePricesAndPoints && <PriceSummary withShipmentPrice />}
            <div className='d-flex justify-content-end mt-4'>
              <ButtonWithLoadingState className='flex-grow-1 flex-lg-grow-0'
                isLoading={productsStore.confirmAddressIsSubmitting}
                onClick={productsStore.handleConfirmAddressSubmit(navigate)}
                buttonText={t('shoppingCart:nextButton')} />
            </div>
            {
              productsStore.pageErrors.map((error, errorIndex) => <Alert key={errorIndex} className='mt-3 mb-3' variant='danger' style={{ whiteSpace: 'break-spaces' }}>{error}</Alert>)
            }
          </>
        }
        {(location.pathname === uiStore.linkToConfirmOrder) &&
          <>
            {!productsStore.hidePricesAndPoints && <PriceSummary withShipmentPrice />}
            {basket.articleReturnDate ?
              <Form.Group className="mb-3">
                <Form.Label>{t('shoppingCart:returnDateRentalItems')}</Form.Label>
                <CustomDatePicker
                  dateFormat="dd.MM.yyyy"
                  placeholderText={t('date')}
                  onChange={checkoutStore.handleArticleReturnDateChange}
                  showYearDropdown
                  selected={(checkoutStore.articleReturnDate && checkoutStore.articleReturnDate !== emptyDate) ? new Date(checkoutStore.articleReturnDate) : ''}
                  isInvalid={productsStore.pageErrorPointers['/articleReturnDate']}
                />
              </Form.Group> : null}
            <div className="mt-5">
              <SafelySetInnerHTML className='m-3'>{basket.shipmentType && basket.shipmentType.shipmentNotification}</SafelySetInnerHTML>
              {basket.preferSecondhandArticles !== null && (
                <div className="mb-3">
                  <Form.Check
                    id='shoppingBasket-preferSecondhandArticles'
                    onChange={checkoutStore.setPreferSecondhandArticles}
                    checked={checkoutStore.preferSecondhandArticles}
                    label={t('shoppingCart:preferSecondhandArticles')}
                  />

                </div>
              )}
              <div className="cws-terms-order">
                <Suspense fallback={<div>Loading...</div>}>
                  <TermsAndConditions basket={basket} checkoutStore={checkoutStore} agbError={productsStore.pageErrorPointers['/gTCIsAccepted']} />
                </Suspense>
              </div>
            </div>
            {basket.additionalConditions &&
              basket.additionalConditions.map(condition => (
                <Form.Check
                  key={condition.type}
                  id={condition.type}
                  name={condition.type}
                  onChange={checkoutStore.setAdditionalConditionIsAccepted}
                  checked={checkoutStore.additionalConditions[condition.type] || false}
                  isInvalid={productsStore.pageErrorPointers[condition.type]}
                  label={<SafelySetInnerHTML>{condition.text}</SafelySetInnerHTML>}
                  className='my-4'
                />
              ))

            }
            {checkoutStore.showShipmentPriceAccepted &&
              <div className='mt-4'>

                <Form.Check
                  id='shoppingBasket-shipment-price'
                  onChange={checkoutStore.setShipmentPriceIsAccepted}
                  checked={checkoutStore.shipmentPriceIsAccepted}
                  isInvalid={!checkoutStore.shipmentPriceIsAccepted}
                  label={t('shoppingCart:acceptHigherShippingCosts')}
                />
              </div>
            }
            {checkoutStore.showEntitlementExceedanceIsAccepted &&
              <div className='mt-4'>
                <Form.Check
                  id='shoppingBasket-entitlementExceedance'
                  onChange={checkoutStore.setEntitlementExceedanceIsAccepted}
                  checked={checkoutStore.entitlementExceedanceIsAccepted}
                  isInvalid={!checkoutStore.entitlementExceedanceIsAccepted}
                  label={mandate === "cws" ? t('cws:acceptExceedingContingent') : t('shoppingCart:acceptExceedingContingent')}
                />

              </div>
            }
            {checkoutStore.showPointsCreditIsAccepted &&
              <div className='mt-4'>
                <Form.Check
                  id='shoppingBasket-pointsCreditIsAccepted'
                  onChange={checkoutStore.setPointsCreditIsAccepted}
                  checked={checkoutStore.pointsCreditIsAccepted}
                  isInvalid={!checkoutStore.pointsCreditIsAccepted}
                  label={t('shoppingCart:acceptCoveringBudget')}
                />

              </div>
            }
            {basket.foreignOrderNr !== null && <Form.Group className="mb-3" >
              <Form.Label style={{ fontWeight: "normal" }}>{mandate === "cws" ? <SafelySetInnerHTML>{t('cws:ekwNumberAvailable')}</SafelySetInnerHTML> : ""}</Form.Label>
              <Form.Control type="text" placeholder={mandate === "cws" ? t('cws:ekwNumber') : t('shoppingCart:referenceNumber')} onChange={e => checkoutStore.handleForeignOrderNrChange(e.target.value)} value={checkoutStore.foreignOrderNr} />
            </Form.Group>}
            {basket.orderComment !== null && <Form.Group className="mb-3" >
              <Form.Label > <SafelySetInnerHTML>{orderCommentLabel}</SafelySetInnerHTML></Form.Label>
              <Form.Control
                as="textarea"
                placeholder={t('shoppingCart:orderComment')}
                onChange={e => checkoutStore.handleOrderCommentChange(e.target.value)}
                value={checkoutStore.orderComment}
                disabled={checkoutStore.isCustomShipmentAddress}
              />
            </Form.Group>}
            {
              productsStore.pageErrors.map((error, errorIndex) => <Alert key={errorIndex} className='mt-3 mb-3' variant='danger' style={{ whiteSpace: 'break-spaces' }}>{error}</Alert>)
            }
            <div className='d-flex justify-content-end mt-4 mb-2'>
              <ButtonWithLoadingState className='flex-grow-1 flex-lg-grow-0'
                isLoading={productsStore.confirmOrderIsSubmitting}
                onClick={handleSubmit}
                buttonText={uiStore.srmProcessIsActive && (basket.targetOrderProcessType === "costCenter" || basket.targetOrderProcessType === "personal")
                  ? t('shoppingCart:buttonOrderSubmitSRM')
                  : teamOrderStore.privateOrder
                    ? t('shoppingCart:buttonOrderSubmitPrivate')
                    : orderSubmitButtonDefaultName} />
            </div>
            {(uiStore.allowOrderTemplates && teamOrderStore.selectedCostcenterId) ?
              <>
                <div className='d-flex justify-content-end'>
                  <ButtonWithLoadingState
                    isLoading={productsStore.saveBasketAsTemplateButtonLoading}
                    onClick={() => setOpenSaveTemplateModal(true)}
                    size="sm"
                    className='flex-grow-1 flex-lg-grow-0'
                    buttonText={t('shoppingCart:saveTemplate')}
                    buttonIcon={<VscSaveAs className="me-1" />}
                  />
                </div>
              </>
              : null}
          </>}
      </StyledStickySummary>
      <Suspense fallback={<></>}>
        <CheckoutConfirmationModal submitResult={submitResult} setSubmitResult={setSubmitResult} />
      </Suspense>

      {openSaveTemplateModal && (<ModalComponent
        ModalTitle={t('orderTemplates:saveTemplate')}
        functionButton1={name => { productsStore.saveBasketAsTemplate(name, navigate) }}
        inputContent={getArticlesString()}
        textButton1={t('saveButton')}
        functionCancel={() => { setOpenSaveTemplateModal(false) }}
        withInput
        inputType="input"
        inputLabel={t('orderTemplates:changeName')}
      />)}
    </StyledGrayContainer >
  )
})))
