import { emptyDate } from './emptyDate'

export const formatedDate = (dateString, withTime = false) => {
  if (!dateString || dateString === emptyDate) return null
  let formatedString = (new Date(dateString)).toLocaleString('de', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: 'numeric'
  }) + ' Uhr'
  return withTime ? formatedString : formatedString.slice(0, 10)
}
