import React from 'react'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import SubheaderTitle from '../../components/Page/logix2020/SubheaderTitle'
import { useTranslation } from 'react-i18next'
import GeneralForm from '../../components/Configuration/AppointmentType/GeneralForm'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import SidebarNavigation from '../../components/Page/logix2020/SidebarNavigation'
import i18n from '../../translation/i18n'
import AppointmentTypesProvider from '../../stores/Configuration/AppointmentTypesProvider'

const navigationLeft = () => [
    {
        key: 'general',
        to: '#general',
        name: i18n.t("costCenterAdmin:general"),
        disabled: false
    },
    {
        key: 'timesGenerator',
        to: '#timesGenerator',
        name: i18n.t("cabinTypes:timesGenerator"),
        disabled: true
    },
    {
        key: 'assignedCabins',
        to: '#assignedCabins',
        name: i18n.t("Kabinen Zuweisung"),
        disabled: true
    }
]

const CreateNewAppointmentTypePage = () => {
    const { t } = useTranslation()

    return (
        <div>
            <Subheader className="subHeader-v1">
                <SubheaderTitle
                    withBackButton
                    smallTitle={t('appointment:appointmentNewTitle')}
                    goBackLabel={t('appointment:goBackAppointment')}
                    defaultBackTo="/appointmenttypes"
                />
            </Subheader>
            <FlexWrapper>
                <SidebarLeft>
                    <SidebarNavigation options={navigationLeft()} active={"general"} />
                </SidebarLeft>
                <ContentArea>
                    <AppointmentTypesProvider>
                        <GeneralForm />
                    </AppointmentTypesProvider>
                </ContentArea>
            </FlexWrapper>
            <BigFooter />
        </div >
    )
}

export default CreateNewAppointmentTypePage