import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { t } from 'i18next'

import { formatedDate } from '../../utils/formatedDate'

const ComplaintDetails = ({ item }) => {
  const formattedAddressParts = item.targetOfficeAddress?.formattedCompleteAddress?.split('\r\n') || []
  const addressNumber = item.targetOfficeAddress?.addressnumber
  const equalOfficeAndCostcenterName = item.targetOffice.displayName === item.targetCostCenter.displayName

  formattedAddressParts.splice(0, 1)
  if (addressNumber) {
    formattedAddressParts.push(addressNumber.toString())
  }

  return (
    <div className="mb-3">
      <Row>
        <Col xs={12} sm={4}>{t('complaints:referenceNumber')}:</Col>
        <Col xs={12} sm={8}><strong>{item.feedbackNumber}</strong></Col>
      </Row>
      {item.targetOrder ? <Row>
        <Col xs={12} sm={4}>{t('complaints:orderNumber')}:</Col>
        <Col xs={12} sm={8}><strong>{item.targetOrder.orderNr}</strong></Col>
      </Row> : null}
      {formatedDate(item.expectedTimeOfArrival) ? <Row>
        <Col xs={12} sm={4}>{t('complaints:expectedTimeOfArrival')}:</Col>
        <Col xs={12} sm={8}><strong>{formatedDate(item.expectedTimeOfArrival, true)}</strong></Col>
      </Row> : null}
      {item.targetBill ? <Row>
        <Col xs={12} sm={4}>{t('complaints:billNumber')}:</Col>
        <Col xs={12} sm={8}><strong>{item.targetBill.billNumber}</strong></Col>
      </Row> : null}
      {item.targetBusinessPartner ? <Row>
        <Col xs={12} sm={4}>{t('complaints:customer')}:</Col>
        <Col xs={12} sm={8}><strong>{item.targetBusinessPartner.displayName}</strong></Col>
      </Row> : null}
      {item.targetOffice ? <Row>
        <Col xs={12} sm={4}>{t('complaints:orgaUnit')}:</Col>
        <Col xs={12} sm={8}>
          {equalOfficeAndCostcenterName ? '' : <strong>{item.targetOffice.displayName}</strong>}
          <div>
            {formattedAddressParts.map((item, key) => {
              const isLastPart = key === formattedAddressParts.length - 1
              return <span key={key}>{item}{isLastPart ? '' : ', '} </span>
            })}
          </div>
        </Col>
      </Row> : null}
      {item.targetCostCenter ? <Row>
        <Col xs={12} sm={4}>{t('costcenter')}:</Col>
        <Col xs={12} sm={8}><strong>{item.targetCostCenter.displayName}</strong></Col>
      </Row> : null}
      {item.complaintManager ? <Row>
        <Col xs={12} sm={4}>{t('complaints:editor')}:</Col>
        <Col xs={12} sm={8}><strong>{item.complaintManager.displayName}</strong></Col>
      </Row> : null}
    </div >
  )
}

export default ComplaintDetails