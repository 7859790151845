import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'react-bootstrap'
import React, { useEffect, useState, useMemo } from 'react'
import OfficeSelect from '../../Employees/Form/FormComponents/OfficeSelect'
import useOfficeState from '../../../stores/Configuration/useOfficeState'
import { StyledValidationMessage } from '../../Employees/Form/formHelper'
import MandatoryField from '../../MandatoryField/MandatoryField'

import ArraySelect from '../../Select/ArraySelect'
import ModalComponent from '../../Modals/ModalComponent'
import { DeleteIcon } from '../../Icons/DeleteIcon'
import styled from 'styled-components'
import { debounce } from 'throttle-debounce'
import ZipSuggestion from '../../Forms/ZipSuggestion'
import { getFakeEvent } from '../../../utils/utils'
import { emptyGuid } from '../../../utils/emptyGuid'

const StyledDeleteIcon = styled.div`
display:flex;
justify-content:center;
margin-bottom: 1rem;
svg {cursor:pointer;}
`

const Address = ({ index, address }) => {
  const [_address, _setAddress] = useState(null)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const { t } = useTranslation()

  const isNewAddress = _address?.id === emptyGuid

  const {
    availableCountries,
    formErrors,
    getExternOfficeAddress,
    resetFormErrorsByKey,
    officeName,
    selectedOffice,
    setOfficeAddresses,
    deleteAddress
  } = useOfficeState()

  const handleLocalChange = e => {
    const { name, value } = e.target
    _setAddress(prev => ({ ...prev, [name]: value }))
    resetFormErrorsByKey(name, index)
  }

  const handleLocalChangeCheckbox = e => {
    const value = e.target.checked
    const { name } = e.target
    _setAddress(prev => ({ ...prev, [name]: value }))
    resetFormErrorsByKey(name, index)
  }

  useEffect(() => {
    if (JSON.stringify(address) !== JSON.stringify(_address)) {
      _setAddress(address)
    }
    // eslint-disable-next-line
  }, [address])

  const debounceFunc = useMemo(
    () => debounce(350, (_address) => {
      setOfficeAddresses(prev => {
        const newValues = [...prev]
        newValues[index] = _address
        return newValues
      })
    }), [index, setOfficeAddresses])

  useEffect(() => {
    if (_address != null) {
      debounceFunc(_address)
    }
  }, [_address, debounceFunc])



  const handleDelete = () => {
    //backend
    deleteAddress(index)
    //frontend
    setOfficeAddresses(prev => {
      const newValues = [...prev]
      newValues.splice(index, 1)
      return newValues
    })
  }

  const handleLocalChangeOffice = (officeId) => {
    getExternOfficeAddress(index, officeId)
    resetFormErrorsByKey('office', index)
  }
  if (_address == null) {
    return null
  }

  return (
    < >
      <Row className="justify-content-end align-items-center">
        <Col>
          <h1 >{_address.type.displayName}</h1>
        </Col>
        <Col sm="6" lg="3" >
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: 'initial' }}>{t('OfficeAddress:takeAddressFrom')}</Form.Label>
            <OfficeSelect id="addressOfficeTree"
              value={selectedOffice[index]}
              onChange={handleLocalChangeOffice}
              errorMessage={formErrors[index] && formErrors[index].office}
              noMargin
              isInvalid={formErrors[index] && formErrors[index].office}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="align-items-baseline">
        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label >{t('EmployeeFormAddress:company')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('EmployeeFormAddress:company')}
              name="secondaryOfficeName"
              value={officeName}
              disabled
            />
          </Form.Group>
        </Col>
        <Col sm="6" lg="3">
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>{t('formularFields:secondaryName')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('EmployeeFormAddress:company')}
                  name="secondaryOfficeName"
                  onChange={handleLocalChange}
                  value={_address.secondaryOfficeName}
                  isInvalid={formErrors && formErrors[index] && formErrors[index].secondaryOfficeName}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors && formErrors[index] && formErrors[index].secondaryOfficeName}
                </Form.Control.Feedback>
              </Form.Group></Col>
          </Row>
          <Row>
            <Col >
              <Form.Group className="mb-3">
                <Form.Check
                  name="useSecondaryOfficeName"
                  type="checkbox"
                  checked={_address.useSecondaryOfficeName}
                  onChange={handleLocalChangeCheckbox}
                  label="ersetzt Firma"
                  id={`useSecondaryOfficeName${index}`}
                />
                {formErrors && formErrors[index] && formErrors[index].useSecondaryOfficeName &&
                  <StyledValidationMessage>
                    {formErrors && formErrors[index] && formErrors[index].useSecondaryOfficeName}
                  </StyledValidationMessage>
                }
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label>{t('OfficeAddress:addressNumber')}</Form.Label>
            <Form.Control
              placeholder={t('OfficeAddress:addressNumber')}
              value={_address.addressnumber}
              onChange={handleLocalChange}
              name="addressnumber"
              isInvalid={formErrors && formErrors[index] && formErrors[index].addressnumber}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors && formErrors[index] && formErrors[index].addressnumber}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>

        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label>{t('EmployeeFormAddress:street')}*</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('EmployeeFormAddress:street')}
              name="street"
              onChange={handleLocalChange}
              value={_address.street}
              isInvalid={formErrors && formErrors[index] && formErrors[index].street}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors && formErrors[index] && formErrors[index].street}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label>{t('EmployeeFormAddress:houseNumber')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('EmployeeFormAddress:houseNumber')}
              name="streetNr"
              onChange={handleLocalChange}
              value={_address.streetNr}
              isInvalid={formErrors && formErrors[index] && formErrors[index].streetNr}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors && formErrors[index] && formErrors[index].streetNr}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label>{t('formularFields:co')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('formularFields:co')}
              name="careOf"
              onChange={handleLocalChange}
              value={_address.careOf}
              isInvalid={formErrors && formErrors[index] && formErrors[index].careOf}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors && formErrors[index] && formErrors[index].careOf}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label>{t('EmployeeFormAddress:mailbox')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('EmployeeFormAddress:mailbox')}
              name="postOfficeBox"
              onChange={handleLocalChange}
              value={_address.postOfficeBox}
              isInvalid={formErrors && formErrors[index] && formErrors[index].postOfficeBox}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors && formErrors[index] && formErrors[index].postOfficeBox}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label>{t('EmployeeFormAddress:postcode')}*</Form.Label>
            <ZipSuggestion
              type="text"
              placeholder={t('EmployeeFormAddress:postcode')}
              name="zipCode"
              onChange={handleLocalChange}
              value={_address.zipCode}
              isInvalid={formErrors && formErrors[index] && formErrors[index].zipCode}
              onChangeSuggestion={obj => handleLocalChange(getFakeEvent("city", obj.cityName, "text"))}
              countryIsoCode={_address.countryIsoCode || "de"}
            >
              <Form.Control.Feedback type="invalid">
                {formErrors && formErrors[index] && formErrors[index].zipCode}
              </Form.Control.Feedback>
            </ZipSuggestion>
          </Form.Group>
        </Col>
        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label>{t('EmployeeFormAddress:city')}*</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('EmployeeFormAddress:city')}
              name="city"
              onChange={handleLocalChange}
              value={_address.city}
              isInvalid={formErrors && formErrors[index] && formErrors[index].city}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors && formErrors[index] && formErrors[index].city}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm="6" lg="3">
          <Form.Group className="mb-3">
            <Form.Label>{t('EmployeeFormAddress:country')}</Form.Label>
            <ArraySelect
              name="countryIsoCode"
              onChange={handleLocalChange}
              value={_address.countryIsoCode}
              options={availableCountries}
              otherValueKey={"isoCode"}
              returnEvent
            />

            <Form.Control.Feedback type="invalid">
              {formErrors && formErrors[index] && formErrors[index].countryIsoCode}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <div className="d-flex justify-content-between">
        <Form.Group className="mb-3">
          <Form.Check
            name="isDefaultAddress"
            type="checkbox"
            checked={_address.isDefaultAddress}
            onChange={handleLocalChangeCheckbox}
            label={_address.type.type === "shipment" ? t('OfficeAddress:defaultShipping') : t('OfficeAddress:defaultBilling')}
            id={`isDefaultAddress${index}`} />
          {formErrors && formErrors[index] && formErrors[index].isDefaultAddress &&
            <StyledValidationMessage>
              {formErrors && formErrors[index] && formErrors[index].isDefaultAddress}
            </StyledValidationMessage>
          }
        </Form.Group>
        {true &&
          <StyledDeleteIcon onClick={() => isNewAddress ? handleDelete() : setOpenDeleteModal(true)} title={t('deleteButton')}>
            <DeleteIcon />
          </StyledDeleteIcon>
        }
      </div>

      <MandatoryField />
      <hr />

      {openDeleteModal &&
        <ModalComponent
          ModalTitle={t('OfficeAddress:deleteAddress')}
          functionButton1={handleDelete}
          BodyPart1={t('OfficeAddress:areYouSure')}
          BodyPart2={`"${_address.type.displayName}"`}
          textButton1={t('delete')}
          variantButton1="danger"
          variantBody="alert-danger"
          functionCancel={() => { setOpenDeleteModal(false) }}
        />
      }

    </>
  )
}

export default Address
