import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { UploadIcon } from '../Icons/UploadIcon';
import { Form } from 'react-bootstrap';
import { StyledValidationMessage } from '../Employees/Form/formHelper';
import useStores from '../../stores/useStore';
import { useObserver } from 'mobx-react';

const getColor = (props) => {
  if (props.isDragAccept) {
    return colors.green;
  }
  if (props.isDragReject || props.isInvalid) {
    return colors.red;
  }
  return colors.gray2;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  outline: none;
  word-break: break-all;
  transition: border .1s ease-in-out;
  &:hover{
    border-color:${colors.gray3};
    cursor:pointer;
  }
  .placeHolder{
    color: #bdbdbd;
  }
  border-color: ${props => getColor(props)};
`;

const FileInput = ({ onDrop, accept, maxFiles, isInvalid, children, label, error, ...rest }) => {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone(
    {
      maxFiles,
      accept,
      onDrop,
    });

  const { uiStore } = useStores()
  let documentUploadPossible = useObserver(() => uiStore.documentUploadPossible)
  if (!documentUploadPossible) {
    return null
  }
  return (
    <Form.Group {...rest}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Container isInvalid={isInvalid || error} {...getRootProps({ isFocused, isDragAccept, isDragReject })} style={{ height: "100%" }}>
        <input {...getInputProps()} />
        {!children && <UploadIcon color={colors.gray2} width="40px" height="40px" />}
        {children}
      </Container >
      <StyledValidationMessage>
        {error}
      </StyledValidationMessage>
    </Form.Group>
  );
}

export default FileInput
