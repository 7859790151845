import React, { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { usePlaceholdersState } from '../../../stores/Configuration/PlaceholdersProvider';
import { cloneDeep } from 'lodash';
import { OverlaySpinner, OverlaySpinnerWrapper } from '../../Forms/StyledInputLoadingOverlay';
import UniversalTableWrapperTest from '../../Table/UniversalTableWrapperTest';
import { updateTranslationValue } from '../../../utils/utils';
import InformationPopover from '../../InformationPopover/InformationPopover';
import { Alert } from 'react-bootstrap';
import HTMLEditorModal from './HTMLEditorModal';
import apiClient from '../../../utils/api/apiClient';
import { APIEndpoints } from '../../../utils/api/apiConfig';
import { t } from 'i18next';
import { DeleteIcon } from '../../Icons/DeleteIcon';
import { emptyGuid } from '../../../utils/emptyGuid';
import { getErrorMessageFromApiResponse, setPointerErrorsFromApiResponse } from '../../../utils/api/apiUtils';
import SafelySetInnerHTML from '../../SafelySetInnerHTML';
import { ReactComponent as SendIcon } from '../../../assets/icons/sendIcon.svg'
import { BiLogoHtml5 } from "react-icons/bi";
import styled from 'styled-components';

const StyledEditorIcon = styled(BiLogoHtml5)`
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    right: 25px;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0;
    `
const StyledTextareaWrapper = styled.div`
position:relative;
${StyledEditorIcon}{
  visibility: hidden;
}
&:hover {
  ${StyledEditorIcon}{
    visibility: visible;
  }
}`


const ExpandedType = ({ setShowDeletePlaceholder, preparedData, selectedLanguage }) => {
  const { updatePlaceholder, addPlaceholder, removePlaceholder, loadingStates, errorStates, setErrorStates, setLoadingStates } = usePlaceholdersState()
  const [openEditDescriptionModal, setOpenEditDescriptionModal] = useState(false)
  const [openEditTextModal, setOpenEditTextModal] = useState(false)
  const [cellIsLoading, setCellIsLoading] = useState({ '_text': {}, '_description': {}, 'placeholder': {} })
  const [cellHasError, setCellHasError] = useState({ 'text': {}, 'description': {}, 'placeholder': {} })
  const [error, setError] = useState('')

  const removeUnsavedRow = (row) => {
    removePlaceholder(row)
    setCellHasError(prev => {
      delete prev['text'][row.id];
      delete prev['description'][row.id];
      delete prev['placeholder'][row.id];
      return cloneDeep(prev)
    })
    setError('')
  }

  const columns = [{
    dataField: 'placeholder',
    text: 'Platzhalter',
    classes: 'cursor-pointer',
    style: (cellContent, row) => (cellHasError['placeholder'][row.id] ? { backgroundColor: '#ff9ca5' } : null),
    formatter: (cellContent, row, rowIndex, extraProps) => {
      if (extraProps.cellIsLoading[row.id]) {
        return <OverlaySpinnerWrapper>
          <div>{cellContent}</div>
          <OverlaySpinner />
        </OverlaySpinnerWrapper>
      }
      else {
        return cellContent
      }
      //need triggerHelper cause doesnt trigger rerender if only cellIsLoading obj is passed. 
    }, formatExtraData: { cellIsLoading: cellIsLoading['placeholder'], triggerHelper: Object.values(cellIsLoading['placeholder']).length }
  }, {
    dataField: '_text',
    text: 'Text',
    classes: 'cursor-pointer',
    style: (cellContent, row) => (cellHasError['text'][row.id] ? { backgroundColor: '#ff9ca5' } : null),
    editor: {
      type: Type.TEXTAREA
    },
    formatter: (cellContent, row, rowIndex, extraProps) => {
      if (extraProps.cellIsLoading[row.id]) {
        return <OverlaySpinnerWrapper>
          <div>{cellContent}</div>
          <OverlaySpinner />
        </OverlaySpinnerWrapper>
      }
      else {
        return <StyledTextareaWrapper>
          {cellContent ? <div>{cellContent}</div> : <div>&nbsp;</div>}
          <StyledEditorIcon
            onClick={(e) => { e.stopPropagation(); setOpenEditTextModal(row) }}
            title={"HTML"}
          >
            < SendIcon style={{ color: "white" }} />
          </StyledEditorIcon>
        </StyledTextareaWrapper>
      }
      //need triggerHelper cause doesnt trigger rerender if only cellIsLoading obj is passed. 
    }
    , formatExtraData: {
      cellIsLoading: cellIsLoading['_text'],
      triggerHelper: Object.values(cellIsLoading['_text']).length
    }
  }, {
    dataField: '_description',
    text: '',
    headerClasses: 'checkBox-header-cell',
    classes: 'checkBox-cell',
    editable: false,
    style: (cellContent, row) => (cellHasError['description'][row.id] ? { backgroundColor: '#ff9ca5' } : null),
    formatter: (cellContent, row, rowIndex, extraProps) => {
      if (extraProps.cellIsLoading[row.id]) {
        return <OverlaySpinnerWrapper>
          <InformationPopover header={t('description')} content={cellContent ? cellContent : <Alert>{t('noData')}</Alert>} />
          <OverlaySpinner />
        </OverlaySpinnerWrapper >
      }
      else {
        return <span onClick={() => setOpenEditDescriptionModal(row)} >
          <InformationPopover header={t('description')} content={cellContent
            ? <SafelySetInnerHTML className="ck-content">
              {cellContent}
            </SafelySetInnerHTML>
            : <Alert>{t('noData')}</Alert>} />
          <DeleteIcon onClick={() => row.id === emptyGuid ? removeUnsavedRow(row) : setShowDeletePlaceholder(row)} title={t('delete')} />
        </span>
      }
    },
    formatExtraData: {
      cellIsLoading: cellIsLoading['_description'],
      triggerHelper: Object.values(cellIsLoading['_description']).length
    }
  }
  ];

  const getParams = (placeholderId, datafield, value) => {
    let clonedData = cloneDeep(preparedData.find(p => p.id === placeholderId));
    if (datafield === "_text") {
      updateTranslationValue(selectedLanguage, clonedData, 'text.values', value)
    }
    else if (datafield === "_description") {
      updateTranslationValue(selectedLanguage, clonedData, 'description.values', value)
    }
    delete clonedData['_text']
    delete clonedData['_description']
    return clonedData
  }

  const changeColumnProps = (dataField, placeholderId, bool, setState) => {
    if (bool) {
      setState(prev => {
        if (prev[dataField]) prev[dataField][placeholderId] = true;
        return cloneDeep(prev)
      })
    }
    else {
      setState(prev => {
        if (prev[dataField]) delete prev[dataField][placeholderId];
        return cloneDeep(prev)
      })
    }
  }
  const addColumnErrors = (cellsWithErrors, placeholderId) => {
    Object.keys(cellsWithErrors).forEach((dataField) => {
      return changeColumnProps(dataField, placeholderId, true, setCellHasError)
    })
  }

  const resetColumnError = (datafield, placeholderId) => {
    setError('')
    datafield === "_text" ?
      changeColumnProps("text", placeholderId, false, setCellHasError)
      : datafield === "_description" ?
        changeColumnProps("description", placeholderId, false, setCellHasError)
        : datafield === "placeholder" ?
          changeColumnProps("placeholder", placeholderId, false, setCellHasError)
          : console.log("do nothing")
  }

  const patchPlaceholder = async (placeholderId, params) => {
    try {
      await apiClient.patchJson(APIEndpoints.placeholder(placeholderId).placeholder, params)
    }
    catch (e) {
      let cellsWithErrors = setPointerErrorsFromApiResponse(e)
      addColumnErrors(cellsWithErrors, placeholderId)
      setError(getErrorMessageFromApiResponse(e))
    }
  }
  const postPlaceholder = async (params) => {
    try {
      let newPlaceholder = await apiClient.postJson(APIEndpoints.placeholder().placeholders, params)
      //update placeHolders 
      removePlaceholder(params)
      addPlaceholder(newPlaceholder)
    }
    catch (e) {
      let cellsWithErrors = setPointerErrorsFromApiResponse(e)
      addColumnErrors(cellsWithErrors, emptyGuid)
      setError(getErrorMessageFromApiResponse(e))
    }
  }

  const handleSave = async (datafield, placeholderId, value) => {
    resetColumnError(datafield, placeholderId)
    changeColumnProps(datafield, placeholderId, true, setCellIsLoading)
    let params = getParams(placeholderId, datafield, value);
    updatePlaceholder(params)
    placeholderId === emptyGuid ? await postPlaceholder(params) : await patchPlaceholder(placeholderId, params)
    changeColumnProps(datafield, placeholderId, false, setCellIsLoading)
  }
  return (
    (preparedData.length && !loadingStates.allPlaceholders) ?
      <div className="p-2">
        <UniversalTableWrapperTest tableIsLoading={loadingStates.allPlaceholders}>
          <BootstrapTable
            keyField="id"
            data={preparedData}
            columns={columns}
            cellEdit={cellEditFactory({
              mode: 'click',
              blurToSave: true,
              afterSaveCell: (oldValue, newValue, row, column) => { if (oldValue !== newValue) handleSave(column.dataField, row.id, newValue) }
            })}
          />
        </UniversalTableWrapperTest>
        {error ? <Alert className="mt-2" variant="danger">{error}</Alert> : null}
        {openEditDescriptionModal ? <HTMLEditorModal
          title={t('description')}
          handleClose={() => setOpenEditDescriptionModal(null)}
          handleSave={(value) => { setOpenEditDescriptionModal(null); handleSave('_description', openEditDescriptionModal.id, value) }}
          data={openEditDescriptionModal['_description']}
          isLoading={cellIsLoading['_description'][openEditDescriptionModal.id]}
          error={error}
          loadingStates={loadingStates}
          errorStates={errorStates}
          setErrorStates={setErrorStates}
          setLoadingStates={setLoadingStates}
        />
          : null}
        {openEditTextModal ? <HTMLEditorModal
          title={t('text')}
          handleClose={() => setOpenEditTextModal(null)}
          handleSave={(value) => { setOpenEditTextModal(null); handleSave('_text', openEditTextModal.id, value) }}
          data={openEditTextModal['_text']}
          isLoading={cellIsLoading['_text'][openEditTextModal.id]}
          error={error}
          loadingStates={loadingStates}
          errorStates={errorStates}
          setErrorStates={setErrorStates}
          setLoadingStates={setLoadingStates}
        />
          : null}
      </div>
      : null
  )
}

export default ExpandedType