const getPreparedVariants = (variants) => {
    if (!variants) {
        return null
    }
    let preparedVariants = []
    variants.forEach(variant => {
        let preparedVariant = preparedVariants.find(v => variant.separatedDisplayName.length && v.displayName === variant.separatedDisplayName[0])
        if (!preparedVariant) {
            const displayName = variant.separatedDisplayName.length ? variant.separatedDisplayName[0] : variant.displayName
            preparedVariant = {
                id: variant.separatedDisplayName.length > 1 ? displayName : variant.id,
                displayName: displayName,
                subVariants: []
            }
            preparedVariants.push(preparedVariant)
        }
        if (variant.separatedDisplayName.length > 1) {
            preparedVariant.subVariants.push({ displayName: variant.separatedDisplayName[1], id: variant.id })
        }
    })
    return preparedVariants
}
export default getPreparedVariants