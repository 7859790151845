import React from 'react'
import { Form } from 'react-bootstrap'
import MainData from '../Form/FormParts/MainData'
import CommunicationData from '../Form/FormParts/CommunicationData'
import ShippingAddresses from '../Form/FormParts/ShippingAddresses'
import BillingAddress from '../Form/FormParts/BillingAddress'
import JobFunctions from '../Form/FormParts/JobFunctions'
import CostCenterMultiselect from '../Form/FormParts/CostCenterMultiselect'
import OfficeMultiSelect from '../Form/FormParts/OfficeMultiSelect'
import CatalogsTable from '../Form/FormParts/CatalogsTable'
import GroupMemberTable from '../Form/FormParts/GroupMemberTable'
import AccountData from '../Form/FormParts/AccountData'
import { useEmployeeDataState } from '../../../stores/EmployeeDataProvider'

const AddContentArea = () => {
  const {
    communicationData,
    setCommunicationDataByKey,
    formErrors,
    mainData,
    setMainDataByKey,
    resetErrorByFormKey,
    addresses,
    setAddressesByKey,
    activities,
    setActivitiesByKey,
    accountData,
    setAccountDataByKey,
    showUserData,
    selectableAvtivities,
    submittedCommunicationData
  } = useEmployeeDataState()
  const type = "create"
  return (
    <Form>
      <MainData mainData={mainData} setMainDataByKey={setMainDataByKey} formErrors={formErrors} resetErrorByFormKey={resetErrorByFormKey} />
      <CommunicationData communicationData={communicationData} setCommunicationDataByKey={setCommunicationDataByKey} formErrors={formErrors} submittedCommunicationData={submittedCommunicationData} />
      <ShippingAddresses type={type} addresses={addresses} setAddressesByKey={setAddressesByKey} />
      <BillingAddress type={type} addresses={addresses} setAddressesByKey={setAddressesByKey} />
      <JobFunctions selectableAvtivities={selectableAvtivities} activities={activities} setActivitiesByKey={setActivitiesByKey} formErrors={formErrors} resetErrorByFormKey={resetErrorByFormKey} />
      {
        showUserData ? <>
          <AccountData accountData={accountData} setAccountDataByKey={setAccountDataByKey} formErrors={formErrors} resetErrorByFormKey={resetErrorByFormKey} />
          <CostCenterMultiselect mainData={mainData} setMainDataByKey={setMainDataByKey} resetErrorByFormKey={resetErrorByFormKey} accountData={accountData} setAccountDataByKey={setAccountDataByKey} />
          <OfficeMultiSelect mainData={mainData} setMainDataByKey={setMainDataByKey} resetErrorByFormKey={resetErrorByFormKey} accountData={accountData} setAccountDataByKey={setAccountDataByKey} />
          <CatalogsTable accountData={accountData} setAccountDataByKey={setAccountDataByKey} />
          <GroupMemberTable accountData={accountData} setAccountDataByKey={setAccountDataByKey} />
        </>
          : null}
    </Form>
  )
}

export default AddContentArea

