import React, { useState } from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import { t } from 'i18next'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'react-ckeditor5-custom'

import { ButtonWithLoadingState } from '../../Buttons'
import MandatoryField from '../../MandatoryField/MandatoryField'

const CreateTypeModal = ({ handleClose, handleSave, error, isLoading, initialData }) => {
  const [name, setName] = useState(initialData.name)
  const [description, setDescription] = useState(initialData.description)

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header >
        <Modal.Title >
          {initialData.name ? t('placeholder:editType') : t('placeholder:addType')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Form.Group className="mb-3">
          <Form.Label>  {t('name')}*</Form.Label>
          <Form.Control
            name="name"
            onChange={e => setName(e.target.value)}
            value={name}
            isInvalid={error?.formErrors?.name}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>  {t('description')}*</Form.Label>
          <CKEditor
            editor={Editor}
            config={{
              toolbar: [
                'undo', 'redo', '|', 'fontSize', 'fontColor', 'fontBackgroundColor', '|',
                'bold', 'italic', 'underline', 'strikethrough', '|', 'link', 'insertTable', 'blockQuote', '|',
                'bulletedList', 'numberedList', 'outdent', 'indent'
              ]
            }}
            data={description}
            onChange={(event, editor) => {
              const data = editor.getData();
              setDescription(data)
            }}
          />
        </Form.Group>
        {error ? <Alert variant="danger">{error.value}</Alert> : null}
        <MandatoryField />
      </Modal.Body >
      <Modal.Footer >
        <ButtonWithLoadingState
          onClick={() => handleSave({ ...initialData, name, description })}
          isLoading={isLoading}
          buttonText={t('saveButton')}
          disabled={!name || !description}
        />
        <Button variant="secondary" onClick={handleClose}>
          {t('appointment:cancel')}
        </Button>
      </Modal.Footer>
    </Modal >
  )
}

export default CreateTypeModal