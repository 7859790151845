import { Accessoires } from './Accessoires'
import { Advertising } from './Advertising'
import { Consumables } from './Consumables'
import { Footwear } from './Footwear'
import { Gloves } from './Gloves'
import { Headgear } from './Headgear'
import { ItEquip } from './ItEquip'
import { Jackets } from './Jackets'
import { Keys } from './Keys'
import { Mobile } from './Mobile'
import { NameTags } from './NameTags'
import { OuterClothing } from './OuterClothing'
import { Pants } from './Pants'
import { ProtectionEquip } from './ProtectionEquip'
import { RentalItems } from './RentalItems'
import { Tools } from './Tools'
import { Weaponry } from './Weaponry'
import { Default_kln } from './Default_kln'
const mandate = process.env.REACT_APP_MANDATORSET


export const CategoryIcons = {
  'accessoires': Accessoires,
  'advertising': Advertising,
  'consumables': Consumables,
  'footwear': Footwear,
  'gloves': Gloves,
  'headgear': Headgear,
  'itEquip': ItEquip,
  'jackets': Jackets,
  'keys': Keys,
  'mobile': Mobile,
  'nameTags': NameTags,
  'outerClothing': OuterClothing,
  'pants': Pants,
  'protectionEquip': ProtectionEquip,
  'rentalItems': RentalItems,
  'tools': Tools,
  'weaponry': Weaponry,
  'default_kln': Default_kln,
}

export const CategoryIconsOptions = [
  { displayName: "Headgear", id: "headgear" },
  /// Oberbekleidung]
  { displayName: "OuterClothing", id: "outerClothing" },
  /// Jacken]
  { displayName: "Jackets", id: "jackets" },
  /// Accessoires]
  { displayName: "Accessories", id: "accessories" },
  /// Beinkleidung/ Hosen]
  { displayName: "Pants", id: "pants" },
  /// PSA- Persönliche Schutzausrüstung]
  { displayName: "ProtectionEquip", id: "protectionEquip" },
  /// Schuhe]
  { displayName: "Footwear", id: "footwear" },
  /// Handschuhe]
  { displayName: "Gloves", id: "gloves" },
  /// Leihartikel]
  { displayName: "RentalItems", id: "rentalItems" },
  /// Verbrauchsmaterialien]
  { displayName: "Consumables", id: "consumables" },
  /// Schlüssel/ Zutrittskarten]
  { displayName: "Keys", id: "keys" },
  /// Namensschilder]
  { displayName: "NameTags", id: "nameTags" },
  /// Werkzeuge]
  { displayName: "Tools", id: "tools" },
  /// Waffen]
  { displayName: "Weaponry", id: "weaponry" },
  /// IT-Ausstattung / Computer]
  { displayName: "ItEquip", id: "itEquip" },
  /// mobile Technik/Kommunikationsmitte]l
  { displayName: "Mobile", id: "mobile" },
  /// Werbemittel/ Werbeartikel]
  { displayName: "Advertising", id: "advertising" }
]

export const CategoryIconDefault = mandate === "kliniken" ? CategoryIcons.default_kln : CategoryIcons.outerClothing
