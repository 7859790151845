import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useInventoryBookingsState } from '../../../stores/InventoryBookingsTableProvider'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getErrorMessageFromApiResponse } from "../../../utils/api/apiUtils"
import ModalComponent2 from '../../Modals/ModalComponent2'
import InventoryBookingsTable from '../../Inventory/InventoryBookingsTable'
import ProductGalleryModal from '../../ProductGallery/ProductGalleryModal'
import { useObserver } from 'mobx-react'
import useStores from '../../../stores/useStore'

const StyledHeaderButtons = styled.div`

@media screen and (min-width:768px) {
    display:flex;
    justify-content: end;
}
@media only screen and (max-width: 767px), (min-device-width: 320px) and (max-device-width: 767px)  {
    .customDropdown{
        margin-bottom:1rem;
    }
    button{
        width:100%;
        margin-bottom:1rem;
    }
}
`


const InventoryBookingsApproval = () => {
  const { selectedCheckBoxes, setSelectedCheckBoxes, fetchOrders, selectedImg, setSelectedImg } = useInventoryBookingsState()
  const [reject, setReject] = useState(false)
  const [release, setRelease] = useState(false)
  const [remove, setRemove] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const { uiStore } = useStores()
  const allowReleaseInventoryBookingChanges = useObserver(() => uiStore.allowReleaseInventoryBookingChanges)


  const submit = async (type, message) => {
    setError('')
    const endpoint = prepareEndpoint(type)
    const params = prepareParams(message)
    try {
      setIsLoading(true)
      await apiClient.postJson(endpoint, params)
      setSelectedCheckBoxes([])
      fetchOrders()
      cancelActions()
    } catch (e) {
      setError(getErrorMessageFromApiResponse(e))
    } finally {
      setIsLoading(false)
    }
  }

  //array of ids to array of objects [id1,id2] => [{comment:"",id:id1},{comment:"", id:id2}]
  const prepareParams = (message) => {
    const result = selectedCheckBoxes.map(id => {
      var rObj = {}
      rObj.comment = message
      rObj.id = id
      return rObj
    })
    return result
  }

  const prepareEndpoint = (type) => {
    let endpoint = ''
    switch (type) {
      case 'release':
        endpoint = APIEndpoints.inventoryChangeRequest().releases
        break;
      case 'reject':
        endpoint = APIEndpoints.inventoryChangeRequest().rejections
        break;
      case 'remove':
        endpoint = APIEndpoints.inventoryChangeRequest().cancellations
        break;
      default:
      // code block
    }
    return endpoint
  }

  const cancelActions = () => {
    setReject(false)
    setRelease(false)
    setRemove(false)
    setIsLoading(false)
    setError('')
  }

  return (
    <>
      <StyledHeaderButtons>
        {allowReleaseInventoryBookingChanges ? <div>
          <Button
            className="mb-3 me-2"
            disabled={selectedCheckBoxes.length === 0}
            variant="success"
            onClick={() => setRelease(true)}
          >{t('release')}</Button>
          <Button
            className="mb-3"
            disabled={selectedCheckBoxes.length === 0}
            variant="danger"
            onClick={() => setReject(true)}
          >{t('reject')}</Button>
        </div>
          : <Button
            className="mb-3"
            disabled={selectedCheckBoxes.length === 0}
            variant="danger"
            onClick={() => setRemove(true)}
          >{t('delete')}</Button>}
      </StyledHeaderButtons>
      <InventoryBookingsTable />
      {reject && (<ModalComponent2
        ModalTitle={t('inventory:rejectTitle')}
        BodyPart1={t('inventory:rejectBody')}
        button1Function={(comment) => { submit('reject', comment) }}
        button1Text={t('reject')}
        button1Variant="danger"
        button1Loading={isLoading}
        bodyStyle="alert alert-danger"
        functionCancel={cancelActions}
        inputType="textarea"
        inputLabel={t('comment')}
        error={error}
      />)}

      {release && (<ModalComponent2
        ModalTitle={t('inventory:releaseTitle')}
        BodyPart1={t('inventory:releaseBody')}
        button1Function={(comment) => { submit('release', comment) }}
        button1Text={t('release')}
        button1Variant="success"
        button1Loading={isLoading}
        bodyStyle="alert alert-success"
        functionCancel={cancelActions}
        inputType="textarea"
        inputLabel={t('comment')}
        error={error}
      />)}

      {remove && (<ModalComponent2
        ModalTitle={t('inventory:deleteTitle')}
        BodyPart1={t('inventory:deleteBody')}
        button1Function={(comment) => { submit('remove', comment) }}
        button1Text={t('delete')}
        button1Variant="danger"
        button1Loading={isLoading}
        bodyStyle="alert alert-danger"
        functionCancel={cancelActions}
        inputType="textarea"
        inputLabel={t('comment')}
        error={error}
      />)}

      {selectedImg && <ProductGalleryModal handleClose={() => setSelectedImg(null)} detailImages={[selectedImg]} />}

    </>
  )
}

export default InventoryBookingsApproval
