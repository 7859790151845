import React, { useMemo } from 'react'
import { useObserver } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import TableProvider, { useTableState } from '../Table/TableProvider'
import BootstrapTable from 'react-bootstrap-table-next'
import UniversalTableWrapperTest from '../Table/UniversalTableWrapperTest'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { formatedDate } from '../../utils/formatedDate'
import { formatedPriceObject } from '../../utils/formatedPrice'
import styled from 'styled-components'
import { useOrdersTableState } from '../../stores/OrdersTableProvider'
import { Spinner } from 'react-bootstrap'

const StyledReportsTableWrapper = styled.div`
.react-bootstrap-table {background:white}
`
const OrdersTableInner = () => {
    const { t } = useTranslation()
    const { tableRef, paginationOptions, setPageLimit, setPageOffset, currentPage, totalPages } = useTableState()
    const { onRowClick, tableIsLoading, incomingOrders, allSelectedLoading, selectAll, allSelected } = useOrdersTableState()
    const columns = useMemo(() => [
        {
            dataField: 'checkBox',
            text: '',
            headerClasses: 'checkBox-header-cell',
            classes: 'checkBox-cell'
        },
        {
            dataField: '__date',
            text: t('date'),
            formatter: (cell, row) => {
                return (
                    <>
                        <div className='fw-bold'>{formatedDate(row.createdAt)}</div>
                        <div>
                            <small className='text-uppercase'>{t('orders:orderNumber')} {row.orderNr}</small>
                        </div>
                    </>
                )
            }
        },
        {
            dataField: 'totalQuantityOrdered',
            text: t('amount'),
            formatter: (cell) => (<>{t('amount')}: {cell}</>)
        },
        {
            dataField: 'totalValue',
            text: t('price'),
            formatter: (cell) => formatedPriceObject(cell),
            headerClasses: 'fullWidthMobile',
        },
        {
            dataField: 'actions',
            text: '',
            headerClasses: 'checkBox-header-cell',
            classes: 'checkBox-cell'
        }
    ], [t]);

    const handleTableChange = (type, newState) => {
        setPageLimit(newState.sizePerPage)
        setPageOffset(newState.page === 1 ? 0 : newState.sizePerPage * newState.page - newState.sizePerPage)
    }
    const preparedColumns = useMemo(() => {
        const tmpColumns = [...columns]
        tmpColumns.forEach(tmpColumn => {
            tmpColumn.headerFormatter = (column, colIndex, components) => {
                switch (tmpColumn.dataField) {
                    case 'checkBox':
                        return (
                            <span>
                                {allSelectedLoading
                                    ? <Spinner animation='border' variant='primary' size="sm" />
                                    : <input type="checkbox" id='checkbox_header' name="scales"
                                        checked={allSelected} onChange={selectAll}>
                                    </input>}
                            </span>)

                    default:
                        return (
                            <>
                                {column.text}
                            </>
                        )
                }
            }
        });
        return tmpColumns
    }, [columns, allSelectedLoading, allSelected, selectAll])

    return useObserver(() => (
        <StyledReportsTableWrapper>
            <UniversalTableWrapperTest tableIsLoading={tableIsLoading} currentPage={currentPage} totalPages={totalPages} >
                <BootstrapTable
                    ref={tableRef}
                    remote
                    noDataIndication={() => <div className="no-data-placeholder">{t('orders:noOrdersAvailable')}</div>}
                    loading={tableIsLoading}
                    keyField='id'
                    data={incomingOrders}
                    columns={preparedColumns}
                    bootstrap4
                    hover
                    bordered={false}
                    pagination={paginationFactory(paginationOptions)}
                    onTableChange={handleTableChange}
                    rowEvents={{ onClick: onRowClick }}
                />
            </UniversalTableWrapperTest>
        </StyledReportsTableWrapper>
    ))
}

const OrdersTable = () => {
    //communication between the two stores
    const {
        pageOffset,
        setPageOffset,
        pageLimit,
        setPageLimit,
        totalCount,
        setTotalCount
    } = useOrdersTableState()

    const providerSettings = {
        pageOffset,
        setPageOffset,
        pageLimit,
        setPageLimit,
        totalCount,
        setTotalCount
    }
    return (
        <TableProvider {...providerSettings}>
            <OrdersTableInner />
        </TableProvider>
    )
}

export default OrdersTable;