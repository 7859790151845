import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Form } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { StyledSubHeadline } from '../Dropdowns/ArticleConfiguration'
import ConfigurationSelect from '../Forms/ConfigurationSelect'

class Configurations extends Component {
  constructor(props) {
    super(props)
    const { productDetailsStore } = this.props
    productDetailsStore.initCustomConfigurations()
  }

  render() {
    const { t, productDetailsStore } = this.props
    const configurations = productDetailsStore.articleConfiguration
    const customConfigs = productDetailsStore.customConfigurations
    if (!configurations || configurations.length === 0) {
      return null
    }
    //wait for initialisation
    if (Object.keys(customConfigs).length !== configurations.length) {
      return null
    }

    return (
      <>
        <Form.Label>
          <StyledSubHeadline>{t('productDetail:customize')}</StyledSubHeadline>
        </Form.Label>
        {configurations.map(config => {
          const costumConfigurationArticle = (customConfigs[config.id] && customConfigs[config.id].article) || {}
          const customConfigcomment = customConfigs[config.id].comment
          const selectedConfigurationsErrors = productDetailsStore.selectedConfigurationsErrors[config.id]
          const configPrice = productDetailsStore.configPrices[costumConfigurationArticle.id]
          const priceObject = productDetailsStore.shoppingBasketArticle.pricePerItem;
          return (
            <ConfigurationSelect
              key={config.id}
              costumConfigurationArticle={costumConfigurationArticle}
              config={config}
              showConfigPrice={priceObject !== null && priceObject.points === null && priceObject.price !== null}
              customConfigcomment={customConfigcomment}
              configPrice={configPrice}
              selectedConfigurationsErrors={selectedConfigurationsErrors}
              handleRemoveConfig={configId => productDetailsStore.handleRemoveConfiguration(configId)}
              handleAddConfig={(configId, article) => productDetailsStore.handleChangeConfiguration(configId, article)}
              handleAddComment={configId => e => productDetailsStore.handleChangeConfigurationComment(configId, e.target.value)}

            />
          )
        })}
      </>
    )
  }
}

export default withTranslation()(inject('productDetailsStore')(observer(Configurations)))
