import React, { useState, useEffect, useCallback } from 'react'
import { useObserver } from 'mobx-react'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import styled from 'styled-components'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import { useTranslation } from 'react-i18next'
import SummaryList from '../../components/Reports/SummaryList'
import CreateNewReportOverlay from '../../components/Files/CreateNewReportOverlay'
import useStores from '../../stores/useStore'
import ReportTypeList from '../../components/Reports/ReportTypeList'
import ReportJobsTypeProvider from '../../stores/Administration/ReportJobsTypeProvider'
import { Alert, Spinner } from 'react-bootstrap'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'

const StyledSidebarLeft = styled(SidebarLeft)`
  @media screen and (min-width: 1081px) {
    width: 345px;
  }
`

const StyledContentArea = styled(ContentArea)`
  @media screen and (min-width: 1081px) {
    width: calc(100vw - 345px);
  }
`


const ReportsPageContent = () => {
  const { t } = useTranslation()
  const [showCreateOverlay, setShowCreateOverlay] = useState(false)
  const [createNewReportType, setCreateNewReportType] = useState(false)

  const handleClickCreateNewReport = useCallback((reportType) => {
    setCreateNewReportType(reportType)
    setShowCreateOverlay(true)
  }, [])

  const onCloseOverlayClick = () => {
    setShowCreateOverlay(false)
  }

  return (
    <div>
      <Subheader className="subHeader-v1">
        <div className="subheader-title">{t('reports:createNewReport')}</div>
      </Subheader>
      <FlexWrapper>
        <StyledSidebarLeft className="sidebar-v1">
          <SummaryList />
        </StyledSidebarLeft>
        <StyledContentArea>
          <ReportTypeList onClickCreateNewReport={handleClickCreateNewReport} />
        </StyledContentArea>
        <CreateNewReportOverlay visible={showCreateOverlay} onClose={onCloseOverlayClick}
          reportTypeId={createNewReportType.id} reportTypeName={createNewReportType.displayName} />
      </FlexWrapper>
      <BigFooter />
    </div >
  )
}

const ReportsPage = () => {
  const { t } = useTranslation()
  const { uiStore } = useStores()
  const [error, setError] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setError(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return useObserver(() => (
    <>
      {!uiStore.reportAdministration
        ? (
          <div>
            <Subheader>
              <div className="subheader-title">{t('reports:createNewReport')}</div>
            </Subheader>
            <StyledSidebarLeft>
            </StyledSidebarLeft>
            <StyledContentArea>
              {error
                ? <Alert variant="danger">{t('accessDenied')}</Alert>
                : <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
              }
            </StyledContentArea>
          </div>
        )
        : (
          <ReportJobsTypeProvider>
            <ReportsPageContent />
          </ReportJobsTypeProvider>
        )
      }
    </>
  ))
}


export default ReportsPage
