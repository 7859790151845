import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useReportJobsTypeState } from '../../stores/Administration/ReportJobsTypeProvider'

const StyledReportTypesList = styled.ul`
  list-style: none;
  font-size: 16px;
  padding: 0 10px 0 0;
  margin: -16px 0 0 0;

  span {
    font-weight: bold;
  }

  li {
    padding: 16px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &.active {
      color: ${props => props.theme.primaryColor};
    }

    span:last-child {
      margin-left: 16px;
      color: ${props => props.theme.primaryColor};
    }
  }
`
const SummaryList = () => {
  const { t } = useTranslation()

  const { reportJobTypeGroup, setReportJobTypeGroup, reportJobTypeGroups } = useReportJobsTypeState()

  const allReportsCount = useMemo(() => {
    return reportJobTypeGroups.reduce((acc, item) => acc + (item.count || 0), 0)
  }, [reportJobTypeGroups])

  const handleClickReportTypeGroup = useCallback(reportTypeGroup => () => {
    setReportJobTypeGroup(reportTypeGroup)
  }, [setReportJobTypeGroup])

  return (
    <StyledReportTypesList>
      <li onClick={handleClickReportTypeGroup('none')}>
        <span>{t('reports:allReports')}</span>
        <span>{!!allReportsCount && allReportsCount}</span>
      </li>
      {reportJobTypeGroups.map(item => (
        <li key={item.id} onClick={handleClickReportTypeGroup(item.id)}
          className={reportJobTypeGroup === item.id ? 'active' : ''}>
          <span>{item.displayName}</span>
          <span>{item.count}</span>
        </li>
      ))}
    </StyledReportTypesList>
  )
}

export default SummaryList
