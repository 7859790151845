import React from 'react'

export const Jackets = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="jackets" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <g>
        <path className="st0" d="M86.6,87.1l12.7-3.2l0-1.2c-0.1-0.4-7.8-41.1-10.4-49C86.1,25.6,73,21.2,72.1,21l-4.3-3.2
          c-0.1-0.1-0.2-0.1-0.3-0.2c0,0-0.1,0-0.2-0.1c-10.5-2.6-21.1,0-21.1,0c-0.2,0-0.5,0.1-0.6,0.3L41.4,21c-0.9,0.3-14,4.7-16.7,12.8
          c-2.4,7.2-9,42-10.3,48.9l-0.3,1.3l12.7,3.2l8.9-35.7L37.5,65l-2.7,32.1h17l4.9-2.4l4.8,2.4l17.1,0L76,65l1.7-13.7L86.6,87.1z
           M88.6,84l-9.9-39.7c0.1-0.8,0.8-5.9,4.8-14.2c1.5,1.4,2.5,2.9,3,4.4c2.2,6.7,8.7,40.1,10,47.4L88.6,84z M43.4,22.6l2.5-1.8l2,6.6
          l-1.1,0.7l-2.7-1.3L43.4,22.6z M65.4,19.6L58.1,44c-0.6,1.7-1,3.2-1.4,4.4c-0.1-0.4-0.2-0.8-0.4-1.3c-0.3-0.9-0.6-2-0.9-3.2
          l-7.3-24.4C51.1,19.1,58.3,18.1,65.4,19.6z M37.7,94.5L40.1,65l-2.6-20.6c0,0-0.4-5.7-5.3-15.9c3.6-2.5,7.6-4.2,8.8-4.7l0.7,4.2
          c0.1,0.4,0.4,0.8,0.7,0.9l2.1,1.1l-2,1.5l0.2,0.9c0.3,1.1,6.7,18.9,12.6,23.1v37l-4.1,2.2H37.7z M45.5,32.2l3.1-2.2L53,44.8
          c1,2.9,1.5,4.9,1.8,6.3C51.5,46.9,47.8,38.5,45.5,32.2z M69.5,26.9l-2.7,1.3l-1-0.7l2-6.6l2.4,1.8L69.5,26.9z M73.6,64.9L76,94.5
          H62.2l-4.1-2.1V55.3c5.3-3.8,11-18.5,12.7-23l0.3-1.1l-2-1.5l2.1-1c0.3-0.1,0.6-0.4,0.8-0.9l0.7-4.1c2.1,0.8,5.7,2.4,8.8,4.7
          c-5,10.2-5.3,15.8-5.3,15.8L73.6,64.9z M58.8,51.2c0.3-1.4,0.9-3.5,1.8-6.4L65.1,30l3.1,2.2C67.1,34.9,62.9,45.9,58.8,51.2z
           M17.2,82.1c1.3-7.3,7.8-40.8,10-47.4c0.5-1.4,1.4-2.8,3-4.4c3.9,8.3,4.7,13.4,4.8,14.2L25.1,84L17.2,82.1z"/>
        <rect x="61.7" y="76.6" className="st0" width="10.8" height="2.6"/>
        <rect x="41.2" y="76.6" className="st0" width="10.8" height="2.6"/>
      </g>
    </svg>
  )
}
