import { useRef, useEffect, useCallback } from 'react';
import { CancelToken, isCancel } from 'axios';



export const useCancelToken = () => {
    const sourceRef = useRef(null);
    const cancel = useCallback(() => sourceRef.current.cancel(), [])

    const createOrCancelToken = useCallback(() => {
        if (sourceRef.current && sourceRef.current.token) {
            cancel()
        }
        sourceRef.current = CancelToken.source();
    }, [cancel]);


    useEffect(() => () => {
        if (sourceRef.current) cancel();
    }, [cancel]);

    return { createOrCancelToken, sourceRef, cancel, isCancel };
};
