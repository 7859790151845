
function getFaviconEl() {
    return document.getElementById("favicon");
}
export const updateFavicon = (favicon) => {
    if (!favicon) {
        return null
    }
    const faviconEl = getFaviconEl();
    faviconEl.href = favicon
};