import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import styled from 'styled-components'
import { MdLibraryAdd } from 'react-icons/md'
import useStores from '../../stores/useStore'
import { useAppointmentsAdministrationState } from '../../stores/Administration/AppointmentsAdministrationProvider'
import CabinSelect from './CabinSelect'
import DateSelect from './DateSelect'
import DailyAppointmentsOverview from './DailyAppointmentsOverview'
import CreateNewReportOverlay from '../Files/CreateNewReportOverlay'
import CreateRightOverlay from './CreateRightOverlay'
import ModalComponent from '../Modals/ModalComponent'
import DailyAppointmentsCompareModal from './DailyAppointmentsCompareModal'
import { StyledDisabledContainer } from '../Forms/StyledDisabledContainer'
import { ContentArea } from '../Page/logix2020/ContentArea'
import { hoursArray } from '../../utils/hoursAndMinutes'
import { colors } from '../../styles/colors'
import { useObserver } from 'mobx-react'
import { t } from 'i18next'


const StyledContentArea = styled(ContentArea)`
padding-left: 55.47px;
@media screen and (min-width: 992px) {
    max-width: calc(100% - 480px);
    min-width: 600px;
  }
  hr {
    background-color: ${colors.borderColor};
    margin: 35px 0;
  }
`



const actionModalTexts = {
    deleteLocks: {
        modalTitle: t("appointment:deleteLocks"),
        buttonText: t("unlock"),
        bodyText: t("appointment:areYouSureDeleteLocks"),
    },
    deleteAppointments: {
        modalTitle: t('appointment:deleteAppointments'),
        buttonText: t('delete'),
        bodyText: t('appointment:areYouSureDeleteAppointments')
    },
    lockAll: {
        modalTitle: t('appointment:lockAll'),
        buttonText: t('lock'),
        bodyText: t('appointment:areYouSureLockAll')
    },
    deleteGroupReservations: {
        modalTitle: t('appointment:deleteGroupReservations'),
        buttonText: t('delete'),
        bodyText: t('appointment:areYouSureDeleteGroupReservations')
    }
}


const AppointmentsContentArea = () => {
    const [selectedAction, setSelectedAction] = useState('')
    const [selectedDateHoursArray, setSelectedDateHoursArray] = useState(cloneDeep(hoursArray));
    const [modalVariant, setModalVariant] = useState("")
    const { profileStore, overlayRightStore } = useStores()
    const { profile } = profileStore
    const {
        selectedCabin,
        selectedDays,
        lockAll,
        deleteEvent,
        isStep1Done,
        isStep2Done,
        error,
        bookingSlots,
        selectedDate,
        showDailyAppointmentsCompareModal,
    } = useAppointmentsAdministrationState()
    const showOverviewOverlay = useObserver(() => overlayRightStore.openOverlay === "dailyAppointmentsOverview")
    const showCreateReportOverlay = useObserver(() => overlayRightStore.openOverlay === "appointmentAdminReport")

    useEffect(() => {
        if (bookingSlots.length) {
            let filteredBookingSlots = bookingSlots.filter(slot => slot.startTime.includes(selectedDate) && slot.status === 'isAppointment'); // && slot.status === 'isAppointment', add condition later
            let hoursArrayCopy = cloneDeep(hoursArray);
            filteredBookingSlots.forEach(slot => {
                let hour = parseInt(slot.startTime.substring(11, 13));
                hoursArrayCopy[hour].push(slot);
            })

            setSelectedDateHoursArray(hoursArrayCopy);
        }
    }, [selectedDate, bookingSlots])

    const closeAllOverlays = () => {
        setSelectedAction('')
        overlayRightStore.closeAll()
    }

    const selectAction = (action) => {
        setSelectedAction(action)
        switch (action) {
            case "report":
                overlayRightStore.openOrClose("appointmentAdminReport")
                break;
            case "deleteGroupReservations":
            case "deleteAppointments":
            case "deleteLocks":
                setModalVariant("alert-danger");
                break;
            default:
                setModalVariant("alert-info");
        }
    }

    const modalFunction = (type) => {
        switch (type) {
            case "lockAll":
                lockAll()
                break;
            default:
                deleteEvent(type)
                break;
        }
    }

    return (
        <StyledContentArea >
            <h1>{t('appointment:step')} 1</h1>
            <Row>
                <Col lg="5">
                    <CabinSelect />
                </Col>
            </Row>
            <hr></hr>
            <StyledDisabledContainer disabled={!isStep1Done}>
                <Row>
                    <Col >
                        <h1>{t('appointment:step')} 2</h1>
                        <DateSelect />
                        <Link className="mb-3 btn btn-outline-secondary " to={`/appointment/${profile.id}`}>
                            <MdLibraryAdd className="me-3" />
                            {t('appointment:newAppointment')}
                        </Link>
                    </Col>
                    <Col>
                        {selectedDate && bookingSlots &&
                            <DailyAppointmentsOverview
                                date={selectedDate}
                                selectedDateHoursArray={selectedDateHoursArray}
                                setSelectedAction={setSelectedAction}
                            />
                        }
                    </Col>
                </Row>
            </StyledDisabledContainer>
            <hr></hr>
            <StyledDisabledContainer disabled={!isStep2Done}>
                <h1>{t('appointment:step')} 3</h1>
                <Row>
                    <Col lg="5">
                        <Form.Group >
                            <Form.Label>{t('selectAction')}</Form.Label>
                            <Form.Select onChange={e => { selectAction(e.target.value) }} value={selectedAction} >
                                <option disabled hidden value="" >{t('pleaseSelect')}</option>
                                <option value="report">{t('reports:createCabineReport')}</option>
                                <option value="lockAll">{t('appointment:lockAll')}</option>
                                <option value="deleteLocks">{t('appointment:deleteLocks')}</option>
                                <option value="deleteAppointments">{t('appointment:deleteAppointments')}</option>
                                <option value="deleteGroupReservations">{t('appointment:deleteGroupReservations')}</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
            </StyledDisabledContainer>
            <CreateRightOverlay visible={showOverviewOverlay} onClose={closeAllOverlays} />
            <CreateNewReportOverlay visible={showCreateReportOverlay} onClose={closeAllOverlays} reportTypeId={'cabinEventStatistic'}
                preSelectedData={{
                    dateRange: [
                        selectedDays.length ? new Date(selectedDays[0][0]) : null,
                        selectedDays.length ? new Date(selectedDays[selectedDays.length - 1][0]) : null
                    ],
                    cabinID: selectedCabin
                }} />
            {(selectedAction in actionModalTexts) && <ModalComponent
                ModalTitle={actionModalTexts[selectedAction].modalTitle}
                functionButton1={() => modalFunction(selectedAction)}
                textButton1={actionModalTexts[selectedAction].buttonText}
                variantButton1="danger"
                variantBody={modalVariant}
                BodyPart1={actionModalTexts[selectedAction].bodyText}
                functionCancel={() => { setSelectedAction('') }}
            />}
            {error && <Alert variant="danger">{error}</Alert>}

            {showDailyAppointmentsCompareModal &&
                <DailyAppointmentsCompareModal />
            }
        </StyledContentArea>
    )
}
export default AppointmentsContentArea