import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import { Subheader } from '../../components/Page/logix2020/Subheader'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import AdministrationSidebarNavigation from '../../components/Configuration/AdministrationSidebarNavigation'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { Link } from 'react-router-dom'
import { useObserver } from 'mobx-react'
import useStores from '../../stores/useStore'
import { MdLibraryAdd } from 'react-icons/md'
import { Alert, Spinner } from 'react-bootstrap'
import CatalogsOverview from '../../components/Configuration/Catalog/CatalogsOverview'
import { ButtonWithLoadingState } from '../../components/Buttons'
import { useCatalogsState } from '../../stores/Configuration/CatalogsProvider'
import { BsSortAlphaDown } from 'react-icons/bs'
import { ExcelButton } from '../../components/Buttons/ExcelButton'
import CreateNewImportOverlay from '../../components/Files/CreateNewImportOverlay'


const CatalogsAdministrationPage = () => {
  const { t } = useTranslation()
  const { uiStore } = useStores()
  const { loadingStates, putCatalogsOrderSequence, bigData } = useCatalogsState()
  const allowImportCatalogs = useObserver(() => uiStore.allowImportCatalogs)
  const dashboardIsLoading = useObserver(() => uiStore.dashboardIsLoading)
  const dashboardLoaded = useObserver(() => !!uiStore.dashboard)
  const [showImportOverlay, setShowImportOverlay] = useState(false)

  const onCloseOverlayClick = () => {
    setShowImportOverlay(false)
  }
  function saveSortedSequence() {
    let sortedSequence = Object.values(bigData).sort((a, b) => (a.displayName.localeCompare(b.displayName))).map(a => a.id)
    putCatalogsOrderSequence(sortedSequence)
  }
  return (
    <>
      <Subheader>
        <div className="subheader-title flex-grow-1">{t('catalogsAdministration:manageCatalogs')}</div>
        {allowImportCatalogs &&
          <ExcelButton onClick={() => setShowImportOverlay(prev => !prev)} className="subheader__button" variant="secondary" buttonText={t('import')} />
        }
        <ButtonWithLoadingState
          variant={'primary'}
          buttonText={t('catalogsAdministration:sortAlphabetically')}
          isLoading={loadingStates.saveOrderSequence}
          onClick={saveSortedSequence}
          buttonIcon={<BsSortAlphaDown size="23" className="me-3" />}
          className="subheader__button btn btn-primary text-white"
        />
        {allowImportCatalogs && <Link to='/catalogsAdministration/createCatalog#base' className="subheader__button btn btn-primary text-white"><MdLibraryAdd className="me-3" />{t('catalogsAdministration:addCatalog')}</Link>}
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <AdministrationSidebarNavigation active="catalogsAdministration" />
        </SidebarLeft>
        <ContentArea>
          {allowImportCatalogs ?
            <CatalogsOverview />
            : (dashboardIsLoading || dashboardLoaded)
              ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
              : <Alert variant='danger'>{t('accessDenied')}</Alert>
          }
        </ContentArea>
      </FlexWrapper>
      <CreateNewImportOverlay importName="catalogImport" visible={showImportOverlay} onClose={onCloseOverlayClick} />
    </>
  )
}

export default CatalogsAdministrationPage