import React from 'react'

export const Gloves = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="gloves" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <path className="st0" d="M86,32.1L86,32.1c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3-0.1-0.4c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.2-0.4
        c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.3-0.5-0.6-0.8-0.8c-0.9-0.5-1.8-0.7-2.8-0.3c-1.3,0.4-2.7,1.6-4,3.5
        c2.1-11.5,0.7-13.6-1-14.2c-0.7-0.3-1.6-0.2-2.2,0.3c-2.1,1.2-4.1,5.6-5.6,10.2c0-0.2,0-0.5,0-0.7c0.5-11.7-1.1-13.7-2.8-14.1
        c-0.8-0.3-1.8-0.2-2.5,0.3c-1.9,1.3-3.2,5.3-4.1,12.4c-0.5-5.2-1.1-8.5-2.6-9.7c-0.5-0.5-1.2-0.6-1.8-0.5l-0.1,0
        c-2.4,0.4-4,1.2-4,12.9c0.3,0.4,0.6,1,0.8,1.6c-0.5-1.2-1.1-2-1.8-2.5c-0.7-0.5-1.6-0.7-2.5-0.4c-1.7,0.3-3.4,2.2-3.1,14
        c0,0.2,0,0.5,0,0.7c-1.4-4.6-3.3-9.1-5.4-10.3c-0.6-0.4-1.5-0.5-2.2-0.3c-1.7,0.5-3.1,2.6-1.3,14.2c-1.2-2-2.6-3.2-3.9-3.6
        c-0.9-0.3-1.9-0.2-2.8,0.3c-0.3,0.2-0.6,0.4-0.9,0.7c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.1-0.1,0.2-0.2,0.4
        c0,0.1-0.1,0.1,0,0.1c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.3-0.1,0.3l0,0.1c-0.5,2.8,0.5,7.8,2.9,14.9c0.1,0.3,0.2,0.6,0.3,0.9
        c0.1,0.1,0.1,0.3,0.2,0.5c0.2,0.5,0.4,1,0.5,1.5c0,0,0,0.1,0.1,0.1c3.9,11,6.2,22.5,6.9,34.2c0,0.1,0,0.1,0,0.2c0,0.6,0.6,1.1,1.2,1
        c0.5,0,20.3-1.8,20.5-1.9c0.3-0.1,0.6-0.3,0.7-0.5c0.2-0.2,0.3-0.5,0.3-0.8c-1-9-0.7-9.3,1.1-11.5l0.4-0.4c0,0,0,0,0.1-0.1
        c5,0.6,10,1.1,10.2,1.2c0.6,0.1,1.1-0.4,1.2-1c0-0.1,0-0.2,0-0.2c0.9-11.7,3.5-23.1,7.6-34c0,0,0.1-0.1,0.1-0.1
        c0.1-0.5,0.3-1,0.5-1.5c0.1-0.1,0.1-0.3,0.2-0.5c0.2-0.3,0.3-0.6,0.4-0.9C85.3,39.9,86.4,34.9,86,32.1z M52.1,32
        c-0.1-0.2-0.1-0.3-0.2-0.5C52,31.6,52.1,31.8,52.1,32z M40.4,96.7c0-0.8-0.1-2.1-0.3-3.6c1.2-0.1,17.2-1.6,18.2-1.6
        c0.1,1,0.2,2.2,0.3,3.6L40.4,96.7z M60.8,82.7l-0.4,0.4c-1.7,1.9-2.3,2.8-2.2,6.2l-18.3,1.7c-1.1-9.5-3.3-19-6.5-28
        c-5.2-14.5-3.8-17.3-3.6-17.7c0.1,0,0.1-0.1,0.2-0.1c0.2-0.1,0.6-0.1,0.9,0c1.1,0.4,4,2.5,7.1,14.1c0.2,0.6,0.8,0.9,1.4,0.8
        c0.6-0.2,0.9-0.8,0.8-1.3c0,0-1.4-5.9-2.4-11.8c-1.8-11.3-0.3-12.3-0.3-12.3c0,0,0.2,0,0.5,0.1c2.7,1.7,5.9,13.3,7.3,20.3
        c0.1,0.6,0.7,1,1.3,0.9c0.6-0.1,0.9-0.6,0.9-1.2c-1.2-12.1-0.9-23.4,0.6-24.1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.4-0.1,0.5,0.1
        c0.8,0.5,3.1,3.7,3.3,22.3c0,0.6,0.5,1.1,1.1,1.1c0.5,0,0.9-0.3,1.1-0.8c0.6-3.2,1.1-6.3,1.5-9.5c0.5-4.2,1.1-9.5,2.2-10.3
        c0,0,0,0,0.1,0l0.1,0c0.7,0.1,1.1,0.2,1.4,1.8c0.6,2.5,0.6,7.5,0.2,15.4c-1.8,14,0.3,14.7,1,14.9c1.5,0.5,2.8-1.4,3.9-3.5
        c4.2-7.8,5.3-7.6,6.5-7.3c0.5,0.1,0.9,0.3,1.1,0.8c0.4,0.9,0.5,3.8-5.4,12.6c-1.1,1.7-1.5,3.4-1.8,5.2
        C64.7,75.9,64.2,78.8,60.8,82.7z M71.9,82.7l-7.6-0.9c0.8-1.2,1.4-2.4,1.9-3.5c3.1,0.3,5.6,0.6,6.1,0.7C72,80.7,71.9,81.9,71.9,82.7
        z M79.6,49.1c-3.5,9-5.8,18.3-7.2,27.9l-5.6-0.7c0.3-0.9,0.4-1.8,0.6-2.6c0.3-1.6,0.6-3.1,1.5-4.4c4.8-7.3,6.6-12.1,5.6-14.7
        c-0.4-1.1-1.4-1.9-2.7-2.1c-2.8-0.6-4.7,0.4-8.9,8.4c-0.3,0.7-0.7,1.4-1.2,2c-0.4-1.9-0.1-7.4,0.5-12.1l0-0.1
        c0.9-17.9-0.7-19-3.5-19.5l-0.1,0c-0.6-0.1-1.3,0-1.8,0.5c-1.6,1.2-2.2,4.4-2.9,9.6c-0.1-0.8-0.2-1.6-0.3-2.4c0,0,0,0,0,0
        c-0.1-0.8-0.2-1.8-0.3-2.7c-0.2-8,0-13,0.6-15.4c0.4-1.6,0.7-1.7,1.5-1.8l0.1,0c0,0,0,0,0.1,0.1c1.1,0.8,1.6,6.1,2,10.4
        c0,0.6,0.1,1.2,0.2,1.8c0.2,0,0.5,0,0.7,0l0.1,0c1.1,0.2,2,0.5,2.7,1.9c0.9-13.6,2.9-16.2,3.6-16.6c0.1-0.1,0.4-0.1,0.5,0
        c0.1,0.1,0.2,0,0.3,0.1c1.5,0.8,1.6,12.1,0.1,24.1c0,0.5,0.3,1.1,0.9,1.2c0.6,0.1,1.2-0.2,1.3-0.9c1.5-6.9,4.9-18.6,7.7-20.2
        c0.3-0.1,0.4-0.1,0.5-0.1c0,0,1.5,1.1-0.6,12.3C74.5,38.9,73,44.7,73,44.7c-0.2,0.5,0.2,1.1,0.7,1.3c0.6,0.1,1.2-0.2,1.4-0.8
        c3.4-11.5,6.3-13.6,7.4-13.9c0.3-0.1,0.6-0.1,0.9,0c0.1,0.1,0.1,0.1,0.2,0.1C83.8,31.9,85.2,34.7,79.6,49.1z"/>
    </svg>
  )
}
