import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Form, Alert, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AddToBasketButton } from '../Buttons/AddToBasketButton'
import BubbleVariants from './BubbleVariants'
import VariantSelect from '../Forms/VariantSelect'
import ConfigurationSelect from '../Forms/ConfigurationSelect'
import QuantityStatus from '../ProductBuyBox/QuantityStatus'
import useMountEffect from '../../utils/hooks/useMountEffect'
import LocalStorehouseSelect from '../Forms/LocalStorehouseSelect'

const StyledFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;

  >div:first-child {
    flex-grow: 1;
  }
  
  @media screen and (min-width: 1080px) {
    flex-direction: row;
    >div:first-child {
      margin-right: 1rem;
    }
  }
    
  .form-control.is-invalid {
    background-image: none;
    padding-right: 0.625rem;
  }
`
const StyledRow = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  .label {
    text-align: center;
  }
  >div:last-child {
    flex-basis: 60%;
  }

  @media screen and (min-width: 1080px) {
    align-items: baseline;
    flex-direction: row;
    max-width: 700px;
    .label {
      text-align: left;
    }
  } 
`

const ExpandedArticle = inject('productListStore', 'productsStore', 'productDetailsStore')(observer((props) => {
  const { t, productListStore, productsStore, shoppingBasketArticle, productDetailsStore } = props
  const shoppingBasketArticleId = shoppingBasketArticle.id
  const currentProductObj = productListStore.productObj[shoppingBasketArticleId]
  const [invalidQuantity, setInvalidQuantity] = useState(false)

  useMountEffect(() => {
    if (!currentProductObj) {
      (async function () {
        productListStore.initProductObject(shoppingBasketArticle)
        await productListStore.loadOrderableVariants(shoppingBasketArticleId)
        productListStore.loadAllVariantsQuantities(shoppingBasketArticleId)
        productListStore.loadArticlePrices(shoppingBasketArticleId)
        productListStore.loadArticleConfiguration(shoppingBasketArticleId)
        await productListStore.loadLocalStorehouses(shoppingBasketArticleId)
      })()
    }
  })

  const renderLocalStorehouses = () => {
    return (
      < >
        <div className="label">{t('productDetail:localStorehouse')}</div>
        <div>
        <LocalStorehouseSelect
          localStorehouses={currentProductObj.localStorehouses}
          selectedLocalStorehouseId={currentProductObj.selectedLocalStorehouseId}
          handleChangeLocalStorehouse={(e) => productListStore.handleChangeLocalStorehouse(e, shoppingBasketArticleId)}
          selectedLocalStorehouseError={currentProductObj.selectedLocalStorehouseError}
        />
        </div>
      </>
    )
  }

  const renderVariants = () => {
    return (
      < >
        <div className="label">{t('VariantQuantityTable:variantSize')}</div>
        <div>
          <VariantSelect
            variants={currentProductObj.variants}
            selectedFirstPartVariant={currentProductObj.selectedFirstPartVariant}
            handleChangeFirstPartVariant={(e) => productListStore.handleChangeFirstPartVariant(e, shoppingBasketArticleId)}
            selectedVariantError={currentProductObj.selectedVariantError}
            selectedSecondPartVariant={currentProductObj.selectedSecondPartVariant}
            handleChangeSecondPartVariant={(e) => productListStore.handleChangeSecondPartVariant(e, shoppingBasketArticleId)}
          />
        </div>
      </>
    )
  }

  const renderConfigurations = () => {
    const configurations = currentProductObj.articleConfiguration
    const showConfigPrice = shoppingBasketArticle.pricePerItem !== null && shoppingBasketArticle.pricePerItem.points === null && shoppingBasketArticle.pricePerItem.price !== null

    return (
      <>
        <div className="label">{t('productDetail:customize')}</div>
        <div >
          {configurations.map(config => {
            const costumConfigurationArticle = (currentProductObj.customConfigurations[config.id] && currentProductObj.customConfigurations[config.id].article) || {}
            const customConfigcomment = currentProductObj.customConfigurations[config.id] ? currentProductObj.customConfigurations[config.id].comment : ''
            const selectedConfigurationsErrors = currentProductObj.selectedConfigurationsErrors[config.id]
            const configPrice = currentProductObj.configPrices[config.id]
            return (
              <ConfigurationSelect
                key={config.id}
                costumConfigurationArticle={costumConfigurationArticle}
                config={config}
                showConfigPrice={showConfigPrice}
                customConfigcomment={customConfigcomment}
                configPrice={configPrice}
                selectedConfigurationsErrors={selectedConfigurationsErrors}
                handleRemoveConfig={(configId) => productListStore.handleRemoveConfiguration(configId, shoppingBasketArticleId)}
                handleAddConfig={(configId, article) => productListStore.handleAddConfiguration(configId, article, shoppingBasketArticleId)}
                handleAddComment={(configId) => e => productListStore.handleAddConfigurationComment(configId, e.target.value, shoppingBasketArticleId)}
              />
            )
          })}
        </div>
      </>
    )
  }

  const handleChangeQuantity = (e, shoppingBasketArticleId) => {
    productListStore.handleChangeQuantity(e, shoppingBasketArticleId)
    const remainingQuantity = currentProductObj.maximumQuantity - currentProductObj.articleAvailability.amountToOrder

    if (currentProductObj.maximumQuantity !== null && e.target.value > remainingQuantity) {
      setInvalidQuantity(true)
    } else {
      setInvalidQuantity(false)
    }
  }

  return (
    <React.Fragment>
      {currentProductObj && currentProductObj.variants && currentProductObj.articleConfiguration ?
        <>
          <StyledFlexWrapper>
            <div>
              {/* variants or single size*/}
              {currentProductObj.variants.length ?
                <React.Fragment>
                  {currentProductObj.variants.length > productListStore.bubbleVariantBreakAmount
                    ? <StyledRow>{renderVariants()}</StyledRow>
                    : <StyledRow>
                      <div className="label">{t('VariantQuantityTable:variantSize')}</div>
                      <BubbleVariants
                        shoppingBasketArticleId={shoppingBasketArticleId}
                        currentProductObj={currentProductObj}
                        //must be callback function. otherwise store doesnt know "this.".
                        setSelectedVariant={(variantId, shoppingBasketArticleId) => productListStore.setSelectedVariant(variantId, shoppingBasketArticleId)} />
                    </StyledRow>
                  }
                </React.Fragment>
                :
                null}
              {currentProductObj.variantQuantities && (currentProductObj.selectedFirstPartVariant || currentProductObj.selectedSecondPartVariant) ?
                <StyledRow><div></div><QuantityStatus variantQuantities={currentProductObj.variantQuantities} /></StyledRow>
                : null}
              {/* quantity row */}
              <StyledRow>
                <div className="label">{t('amount')}</div>
                <Form.Group >
                  <Form.Control
                    type="number"
                    placeholder="1"
                    value={currentProductObj.selectedQuantity}
                    onChange={(e) => handleChangeQuantity(e, shoppingBasketArticleId)}
                    min={currentProductObj.minimumQuantity}
                    max={currentProductObj.maximumQuantity}
                    step={currentProductObj.packageSize}
                    isInvalid={invalidQuantity}
                  />
                </Form.Group>
              </StyledRow>
              {/* localstorehouses row */}
              {currentProductObj.localStorehouses && currentProductObj.localStorehouses.length ?
                <StyledRow className="label">
                  {renderLocalStorehouses()}
                </StyledRow>
                : ''}
              {/* configurations row */}
              {currentProductObj.articleConfiguration.length ?
                <StyledRow className="label">
                  {renderConfigurations()}
                </StyledRow>
                : ''}
            </div>
            {!productsStore.initialClothing ? <div>
              <AddToBasketButton
                buttonText={t('productDetail:addToBasketButton')}
                onClick={() => productListStore.handleAddToBasket(shoppingBasketArticleId)}
                variant={'primary'}
                type={'submit'}
                className='mb-2 w-100'
                loadingText={t('productDetail:buttonWaitText')}
                isLoading={currentProductObj ? currentProductObj.addToBasketIsLoading : false}
              />
              <Link className="btn btn-outline-secondary mb-2 w-100" style={{ padding: "6px 12px" }} onClick={productDetailsStore.setScrollRef(shoppingBasketArticle.article.id)} to={productDetailsStore.getProductToUrl(shoppingBasketArticle)}>{t("inventory:goToArticle")}</Link>
            </div> : <div>
              <Link className="btn btn-outline-secondary mb-2 mt-1 w-100" style={{ padding: "6px 12px" }} onClick={productDetailsStore.setScrollRef(shoppingBasketArticle.article.id)} to={productDetailsStore.getProductToUrl(shoppingBasketArticle)}>{t("inventory:goToArticle")}</Link>
            </div>}
          </StyledFlexWrapper>
          {/* success message row */}
          {currentProductObj.addToBasketSuccess ?
            <Alert variant='success' >
              {t('productDetail:productAddedSuccesfullyToBasket')}
            </Alert>
            : ''}
          {/* error message rows */}
          {currentProductObj.addToBasketErrors.length ?
            currentProductObj.addToBasketErrors.map((error, errorIndex) =>
              <Alert key={errorIndex} variant='danger' >
                {error.msg}
              </Alert>
            )
            : ''}
        </>
        :
        // show the loading spinner until all variants/configurations are loaded
        <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>}
    </React.Fragment>
  )
}))

export default withTranslation()(ExpandedArticle)