import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import SubheaderTitle from '../../components/Page/logix2020/SubheaderTitle'
import { useTranslation } from 'react-i18next'

import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import i18n from '../../translation/i18n'
import SidebarNavigation from '../../components/Page/logix2020/SidebarNavigation'
import CabinTypesProvider from '../../stores/Configuration/CabinTypesProvider'
import CabinTypesGeneralForm from '../../components/Configuration/CabinType/CabinTypesGeneralForm'
import AssignedAppointmentTypesForm from '../../components/Configuration/CabinType/AssignedAppointmentTypesForm'

const navigationLeft = () => [
    {
        key: 'general',
        to: '#general',
        name: i18n.t("costCenterAdmin:general"),
        disabled: false
    },
    {
        key: 'assignedAppointmentTypes',
        to: '#assignedAppointmentTypes',
        name: i18n.t("cabinTypes:appointmentTypeAssign"),
        disabled: false
    }
]

const EditCabinTypePage = () => {
    const { t } = useTranslation()

    let currentLocation = useLocation()
    const [activeNav, setActiveNav] = useState('')

    useEffect(() => {
        if (currentLocation.hash) {
            setActiveNav(currentLocation.hash.substr(1))
        }
        else { setActiveNav('general') }
    }, [currentLocation.hash])

    return (
        <div>
            <Subheader className="subHeader-v1">
                <SubheaderTitle
                    withBackButton
                    smallTitle={t('cabinTypes:cabinsEditTitle')}
                    goBackLabel={t('cabinTypes:goBackLabel')}
                    defaultBackTo="/cabintypes"
                />
            </Subheader>
            <FlexWrapper>
                <SidebarLeft>
                    <SidebarNavigation options={navigationLeft()} active={activeNav} />
                </SidebarLeft>
                <ContentArea>
                    <CabinTypesProvider>
                        {activeNav === 'general' && <CabinTypesGeneralForm />}
                        {activeNav === 'assignedAppointmentTypes' && <AssignedAppointmentTypesForm />}
                    </CabinTypesProvider>
                </ContentArea>
            </FlexWrapper>
            <BigFooter />
        </div >
    )
}

export default EditCabinTypePage