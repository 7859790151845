import apiClient from "../../../utils/api/apiClient"
import { APIEndpoints } from "../../../utils/api/apiConfig"
import { emptyGuid } from "../../../utils/emptyGuid"

export async function addMissingStructures(catalogIds, newBigData) {
  //step1 load structures 
  let promises = []
  for (let catalogId of catalogIds) {
    //only load if not already loaded
    if (!newBigData[catalogId] || !newBigData[catalogId].dimensionsLoaded) {
      promises.push(apiClient.getJson(APIEndpoints.catalogs(catalogId).structures))
    }
    else {
      promises.push(new Promise((resolve) => { resolve(Object.values(newBigData[catalogId].dimensions)) }))
    }
  }

  await Promise.all(promises).then(responses => {
    let index = 0
    //here catalogId belongs to responses[index]
    for (let catalogId of catalogIds) {
      let newDimensions = {}
      for (let dimension of responses[index]) {
        dimension.type = "dimension"
        dimension.articles = dimension.articles || {}
        dimension.catalogId = catalogId
        dimension.dimensionsLoaded = true
        newDimensions[dimension.id] = dimension
      }
      newBigData[catalogId].dimensions = newDimensions
      newBigData[catalogId].dimensionsLoaded = true
      newBigData[catalogId].open = true

      index++
    }
  })
}

function getParent(dimension, catalogStructure) {
  let parent = Object.values(catalogStructure).find(d => d.id === dimension.parentId)
  return parent
}

function getAllParentIds(dimension, catalogStructure) {
  const ids = [dimension.id];
  if (dimension && dimension.parentId && dimension.parentId !== emptyGuid) {
    const parent = getParent(dimension, catalogStructure);
    ids.push(...getAllParentIds(parent, catalogStructure));
  }
  return ids;
}

export function getassignedTree(catalogIds, newBigData, assignments, articleId) {
  let tree = {}
  //add dimensions
  for (let catalogId of catalogIds) {
    tree[catalogId] = { dimensions: {} }
    let dimensionIdsArray = []
    let assignedNodes = assignments.filter(a => a.catalogId === catalogId && a.elementId !== emptyGuid)
    let catalogStructure = newBigData[catalogId].dimensions
    for (let assignedNode of assignedNodes) {
      let nodeParents = getAllParentIds(catalogStructure[assignedNode.elementId], catalogStructure)
      dimensionIdsArray.push(...nodeParents)
    }
    for (let dimensionId of dimensionIdsArray) {
      tree[catalogId].dimensions[dimensionId] = {}
    }
  }
  //add assignments
  for (let assignment of assignments) {
    if (assignment.elementId !== emptyGuid) {
      tree[assignment.catalogId].dimensions[assignment.elementId].articles = { [articleId]: 42 }
    }
    else {
      tree[assignment.catalogId].articles = { [articleId]: 42 }
    }
  }
  return tree
}

export async function addCatalogArticles(catalogIds, newBigData) {
  let promises = []
  for (let catalogId of catalogIds) {
    //only load if not already loaded
    if (!newBigData[catalogId] || !newBigData[catalogId].articlesLoaded) {
      promises.push(apiClient.getJson(APIEndpoints.catalogs(catalogId).catalogArticles))
    }
    else {
      promises.push(new Promise((resolve) => { resolve(Object.values(newBigData[catalogId].articles)) }))
    }
  }

  await Promise.all(promises).then(responses => {
    let index = 0
    //here catalogId belongs to responses[index]
    for (let catalogId of catalogIds) {
      let newArticles = {}
      for (let article of responses[index]) {
        article.level = 1
        article.type = "article"
        newArticles[article.id] = article
      }
      newBigData[catalogId].articles = newArticles
      newBigData[catalogId].articlesLoaded = true

      index++
    }
  })
}

export async function addAssignedTreeDimensionArticles(assignmentTree, newBigData) {
  let catalogDimensions = []
  for (let [catalogId, catalogData] of Object.entries(assignmentTree)) {
    if (catalogData.dimensions) {
      for (let dimensionId of Object.keys(catalogData.dimensions)) {
        catalogDimensions.push({ catalogId, dimensionId })
      }
    }
  }
  let promises = []
  for (let { catalogId, dimensionId } of catalogDimensions) {
    //only load if not already loaded
    if (!newBigData[catalogId].dimensions[dimensionId].articlesLoaded) {
      promises.push(apiClient.getJson(APIEndpoints.catalogs(catalogId, dimensionId).structureArticles))
    }
    else {
      promises.push(new Promise((resolve) => { resolve(Object.values(newBigData[catalogId].dimensions[dimensionId].articles)) }))
    }
  }

  await Promise.all(promises).then(responses => {
    let index = 0
    //here catalogId belongs to responses[index]
    for (let { catalogId, dimensionId } of catalogDimensions) {
      let newArticles = {}
      for (let article of responses[index]) {
        article.type = "article"
        newArticles[article.id] = article
      }
      newBigData[catalogId].dimensions[dimensionId].open = true
      newBigData[catalogId].dimensions[dimensionId].articles = newArticles
      newBigData[catalogId].dimensions[dimensionId].articlesLoaded = true

      index++
    }
  })
}