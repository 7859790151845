import getThemeValue from '../utils/getThemeValue'
import de from './de.json'
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import it from './it.json'

//Languages supported in Logix
export const resources = { de, en, es, fr, it }

//returns Languages before login
export const resourcesLogin = () => {
  let themeLanguageArray = getThemeValue('loginLanguages', null, [])
  if (themeLanguageArray.length) {
    let resourcesNew = {}
    for (let languageCode of themeLanguageArray) {
      if (resources[languageCode]) {
        resourcesNew[languageCode] = resources[languageCode]
      }
    }
    return resourcesNew
  }
  else {
    //default login languages
    return ({ de, en })
  }
}
