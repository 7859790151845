import React, { useMemo } from 'react'
import { Table } from 'react-bootstrap'
import { inject, observer } from 'mobx-react'
import { Spinner } from 'react-bootstrap'
import { formatedDate } from '../../../utils/formatedDate'
import { StyledCard } from './StyledDetailCard'
import { useTranslation } from 'react-i18next'
import { colors } from '../../../styles/colors'
import styled from 'styled-components'

const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 400px;

  thead th {
      background: ${colors.gray6};
      position: sticky;
      top: 0;
  }
`

const HistoriesCard = inject('inventoryStore')(observer(props => {
    const { inventoryStore } = props
    const { t } = useTranslation()

    const showDateOfReceipt = useMemo(() => (
        inventoryStore.articleHistories && inventoryStore.articleHistories.some(obj => formatedDate(obj.dateOfReceipt))
    ), [inventoryStore.articleHistories])

    const showExpiryDate = useMemo(() => (
        inventoryStore.articleHistories && inventoryStore.articleHistories.some(obj => formatedDate(obj.expiryDate))
    ), [inventoryStore.articleHistories])

    return (
        <StyledCard>
            <h1>{t('inventory:orderHistory')}</h1>
            {(!inventoryStore.articleHistories || inventoryStore.articleHistoriesIsLoading)
                ? <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
                : <TableWrapper>
                    <Table borderless striped>
                        <thead>
                            <tr>
                                <th>
                                    {t('order:orderDate')}
                                </th>
                                {showDateOfReceipt ? <th>
                                    {t('inventory:referenceDate')}
                                </th> : null}
                                <th>
                                    {t('amount')}
                                </th>
                                <th>
                                    {t('size')}
                                </th>
                                {showExpiryDate ? <th>
                                    {t('inventory:wearingTimeExpiration')}
                                </th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {inventoryStore.articleHistories.map((obj, i) => (
                                <tr key={i}>
                                    <td>{formatedDate(obj.createdAt)}</td>
                                    {showDateOfReceipt ? <td>{formatedDate(obj.dateOfReceipt)}</td> : null}
                                    <td>{obj.quantity}</td>
                                    <td>{obj.articleVariant ? obj.articleVariant.displayName : ""}</td>
                                    {showExpiryDate ? <td>{obj.expiryDate && formatedDate(obj.expiryDate)}</td> : null}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </TableWrapper>}
        </StyledCard>
    )
}))

export default HistoriesCard
