import React, { useState } from 'react'
import ModalComponent2 from '../../Modals/ModalComponent2';
import { getLanguageValue } from '../../../utils/getLanguageValue';
import { Table } from 'react-bootstrap';
import { APIEndpoints } from '../../../utils/api/apiConfig';
import apiClient from '../../../utils/api/apiClient';
import { getErrorMessageFromApiResponse } from '../../../utils/api/apiUtils';
import { t } from 'i18next';
import { emptyGuid } from '../../../utils/emptyGuid';

const CopyOrMoveModal = ({ setOpenMoveOrCopyModal, getStructureArticles, getCatalogArticles, fromArticle, toCatalogOrDimension }) => {
  const [loading, setLoading] = useState('')
  const [error, setError] = useState(null)
  let toDimension = toCatalogOrDimension.type === "dimension"
  let fromCatalog = fromArticle.elementId === emptyGuid
  async function handleSave(type) {
    setError(null)
    setLoading(type)
    let params = {
      addArticleId: fromArticle.article.id,
    }
    if (type === "move") {
      params.moveAssignmentId = fromArticle.id
    }
    try {
      //add article Call + load target Catalog/Dimension
      if (toDimension) {
        await apiClient.postJson(APIEndpoints.catalogs(toCatalogOrDimension.catalogId, toCatalogOrDimension.id).structureArticles, params)
        await getStructureArticles(toCatalogOrDimension.catalogId, toCatalogOrDimension.id)
      }
      else {
        console.log("move article to catalog")
        await apiClient.postJson(APIEndpoints.catalogs(toCatalogOrDimension.id).catalogArticles, params)
        await getCatalogArticles(toCatalogOrDimension.id)
      }

      //  load articles from source
      if (type === "move") {
        let loadArticlesFromSource = () => fromCatalog
          ? getCatalogArticles(fromArticle.catalogId)
          : getStructureArticles(fromArticle.catalogId, fromArticle.elementId)
        await loadArticlesFromSource()
      }
      setOpenMoveOrCopyModal(null)
    }
    catch (e) {
      setError(getErrorMessageFromApiResponse(e))
    }
    finally { setLoading('') }
  }
  return (
    <ModalComponent2
      ModalTitle={t('contingentTemplates:copyOrMove')}
      button1Function={() => handleSave("copy")}
      button1Text={t('officeAdmin:copy')}
      button1Loading={loading === "copy"}
      button2Function={() => handleSave("move")}
      button2Text={t('contingentTemplates:move')}
      button2Loading={loading === "move"}
      functionCancel={() => setOpenMoveOrCopyModal(null)}
      error={error}
      showButton2
    >
      <Table striped bordered   >
        <tbody>
          <tr><td><b>{t('articles')}:</b></td><td> {fromArticle.article.displayName} </td></tr>
          {toDimension ? <tr><td><b>{t('catalogsAdministration:category')}:</b></td><td>  {getLanguageValue(toCatalogOrDimension.name.values)} </td></tr>
            : <tr><td><b>{t('catalogsAdministration:catalog')}:</b></td><td> {toCatalogOrDimension.displayName} </td></tr>}
        </tbody>
      </Table>
    </ModalComponent2>
  )
}

export default CopyOrMoveModal