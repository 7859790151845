import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ContentArea } from '../components/Page/logix2020/ContentArea'
import { Subheader } from '../components/Page/logix2020/Subheader'
import ProductGallery from '../components/ProductGallery/ProductGallery'
import ProductDetail from '../components/ProductDetail/ProductDetail'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'
import { Spinner } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { GoBackBtnEasy } from '../components/Buttons/GoBackBtnEasy'
import useStores from '../stores/useStore'
import { useObserver } from 'mobx-react'
import { formatedPriceObject } from '../utils/formatedPrice'
import { useCancelToken } from '../utils/hooks/useCancelToken'

const StyledContentArea = styled(ContentArea)`
  @media only screen and (max-width: 1080px), (min-device-width: 320px) and (max-device-width: 1080px) {
    padding: 20px;
    min-height: calc(100vh - 140px); //header+navbar=140px
  }
`

const StyledContentWrapper = styled.div`
  max-width: 1440px;
  margin-top: 1.5rem;
`


const StyledLoading = styled.div`
  align-items: center;
  background: rgba(255,255,255,.7);
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  height: calc(100vH - 237px);
`

const StyledLoadingText = styled.span`
  color: ${props => props.theme.primaryColor};
  font-size: 16px;
  letter-spacing: -1px;
  margin-left: 40px;
  text-transform: uppercase;
`
const StyledPriceTag = styled.span`
  color: ${props => props.theme.primaryColor};
  display: block;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  margin-top:1rem;
`

const CatalogProductDetailsPage = () => {
  const [article, setArticle] = useState(null)
  const [catalogArticle, setCatalogArticle] = useState(null)
  const { t } = useTranslation()
  const { articleId } = useParams()
  const navigate = useNavigate()
  const { teamOrderStore } = useStores()
  const catalogId = useObserver(() => teamOrderStore.selectedCatalog)
  const { createOrCancelToken, sourceRef, cancel } = useCancelToken();

  useEffect(() => {
    createOrCancelToken();
    let sourceRefCurrent = sourceRef.current
    return () => {
      sourceRefCurrent && cancel()
    }
  }, [cancel, createOrCancelToken, sourceRef])

  const loadArticle = useCallback(async () => {
    try {
      const loadedArticle = await apiClient.getJson(APIEndpoints.article(articleId), {}, true, false, true, { cancelToken: sourceRef.current.token })
      setArticle(loadedArticle)
    }
    catch (e) { }
  }, [articleId, sourceRef])

  const loadCatalogArticle = useCallback(async () => {
    try {
      const loadedCatalogArticle = await apiClient.getJson(APIEndpoints.defaultArticleCatalogArticle(catalogId, articleId), {}, true, false, true, { cancelToken: sourceRef.current.token })
      setCatalogArticle(loadedCatalogArticle)
    }
    catch (e) { }
  }, [articleId, catalogId, sourceRef])

  useEffect(() => {
    loadArticle()
    if (!teamOrderStore.catalogOnly) {
      teamOrderStore.setCatalogOnly()
      teamOrderStore.loadArticleCatalogs()
    }
  }, [loadArticle, teamOrderStore])

  useEffect(() => {
    if (catalogId) {
      loadCatalogArticle()
    }
  }, [catalogId, loadCatalogArticle])


  if (!article) {
    return <StyledLoading>
      <Spinner animation="border" variant="primary" />
      <StyledLoadingText>{t('productDetail:loadingProduct')}</StyledLoadingText>
    </StyledLoading>
  }

  return (
    <div>
      <Subheader className="subHeader-v1 ps-0">
        <GoBackBtnEasy
          className="ms-0"
          color="white"
          onClick={() => navigate(-1)}
        >
          <span>{t("backButton")}</span>
        </GoBackBtnEasy>
        <div className="ms-2">
          <div className="subheader-title"> {article.displayName}</div>
          <div className="subheader-title-small">{t('productDetail:articleNumber')} {article.articleNumber}</div>
        </div>
      </Subheader>
      <StyledContentArea>
        <StyledContentWrapper className="container-fluid flex-grow-1 d-flex flex-column">
          <div className="row">
            <div className="col-md-5" >
              <ProductGallery article={article} />
            </div>
            <div className="col-md-7">
              {catalogArticle ? <StyledPriceTag>
                {t('productDetail:priceStartsWith')} {formatedPriceObject(catalogArticle.pricePerItem)}
              </StyledPriceTag> : null}
              <ProductDetail article={article} />
            </div>
          </div>
        </StyledContentWrapper>
      </StyledContentArea>
    </div >
  )
}

export default CatalogProductDetailsPage