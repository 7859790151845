import React from 'react'

export const ItEquip = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="itEquip" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <g>
        <path className="st0" d="M98,35.8H77.6v-6.6c0-0.6-0.6-1.2-1.2-1.2l-61.2,0c-0.5,0.1-1,0.7-1,1.2v44.2c0,0.6,0.6,1.2,1.2,1.2h20.4V83
          H30c-0.3,0-0.6,0.2-0.8,0.4c-0.2,0.3-0.4,0.6-0.3,0.9c0,0.3,0.2,0.6,0.4,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0,0,0,0,0.1,0l31.5,0
          c0.3,0,0.6-0.1,0.9-0.4c0.2-0.2,0.4-0.6,0.4-0.9c0-0.3-0.1-0.6-0.4-0.9c-0.3-0.3-0.6-0.4-0.9-0.4H56v-8.4h13.3v9.6
          c0,0.6,0.6,1.2,1.2,1.2H98c0.6,0,1.2-0.6,1.2-1.2V37C99.2,36.4,98.6,35.8,98,35.8z M53.5,83H38.3v-8.4h15.2V83z M69.2,72.2H16.6
          v-7.4h52.6V72.2z M70.3,35.8L70.3,35.8c-0.6,0.1-1.1,0.7-1.1,1.2v25.3H16.6V30.4h58.5v5.4H70.3z M96.7,83H71.7V38.3h25.1V83z"/>
        <path className="st0" d="M77.2,48.1C77.3,48.1,77.3,48.1,77.2,48.1l0-0.3L77.2,48.1l13.9,0c0.3,0,0.6-0.1,0.9-0.4
          c0.2-0.2,0.4-0.6,0.4-0.9c0-0.3-0.1-0.6-0.4-0.9c-0.2-0.2-0.6-0.4-0.9-0.4h-14c-0.3,0-0.6,0.2-0.8,0.4c-0.2,0.3-0.4,0.6-0.3,0.9
          c0,0.3,0.2,0.6,0.4,0.8C76.7,48,77,48.1,77.2,48.1z"/>
        <path className="st0" d="M77.2,56.9C77.3,56.9,77.3,56.9,77.2,56.9l0.1-0.3l0,0.2h13.8c0.3,0,0.6-0.1,0.9-0.4c0.2-0.2,0.4-0.6,0.4-0.9
          c0-0.3-0.1-0.6-0.4-0.9c-0.2-0.2-0.6-0.4-0.9-0.4h-14c-0.3,0-0.6,0.2-0.8,0.4c-0.2,0.3-0.4,0.6-0.3,0.9c0,0.3,0.2,0.6,0.4,0.8
          C76.7,56.8,77,56.9,77.2,56.9z"/>
        <path className="st0"
              d="M84.2,71.2c-1.2,0-2.2,1-2.2,2.2c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2C86.4,72.2,85.4,71.2,84.2,71.2z"/>
      </g>
    </svg>
  )
}
