import React from 'react'
import { ClockIcon } from '../Icons/ClockIcon'
import { EmailSendIcon } from '../Icons/EmailSendIcon'
import { ErrorDocumentIcon } from '../Icons/ErrorDocumentIcon'
import { DocumentIcon } from '../Icons/DocumentIcon'
import { DownloadIcon } from '../Icons/DownloadIcon'
import { useTranslation } from 'react-i18next'
import { SettingsIcon } from '../Icons/SettingsIcon'
import styled from 'styled-components'


const StyledStatusLegendWrapper = styled.div`
  display:flex;
  justify-content: center;
  table {
    width: 100%;
  }
  tr {
    background: white;
  }
  tr, td {
    border-bottom: 1px solid #eee;

  }
 tbody tr td {
  display:inline-block;
  padding: 5px;
 
  }
`
const StatusLegend = () => {
  const { t } = useTranslation()

  return (
    <StyledStatusLegendWrapper>
    <table cellPadding={4} >
      <tbody>
      <tr>
        <th colSpan={2}>Legende</th>
      </tr>
      <tr>
        <td><ClockIcon/></td>
        <td>{t('reports:isOpen')}</td>
      </tr>
      <tr>
        <td><EmailSendIcon/></td>
        <td>{t('reports:isSentByEmail')}</td>
      </tr>
      <tr>
        <td><SettingsIcon/></td>
        <td>{t('reports:isStarted')}</td>
      </tr>
      <tr>
        <td><ErrorDocumentIcon/></td>
        <td>{t('reports:hasError')}</td>
      </tr>
      <tr>
        <td><DocumentIcon/></td>
        <td>{t('reports:isFinished')}</td>
      </tr>
      <tr>
        <td><DownloadIcon/></td>
        <td>{t('reports:isPickedUp')}</td>
      </tr>
      </tbody>
    </table>
    </StyledStatusLegendWrapper>
  )
}
export default StatusLegend
