import React, { useCallback, useEffect, useState } from 'react'
import CostCenterSelect from '../../Employees/Form/FormComponents/CostCenterSelect'
import { useTranslation } from 'react-i18next'

const CreateCostCenterSelect = ({ setFormData, name, ...rest }) => {
  const { t } = useTranslation()
  const [selectedCostCenter, setSelectedCostCenter] = useState()
  const handleChangeCostCenter = useCallback(costCenter => {
    setSelectedCostCenter(costCenter.id)
  }, [])

  useEffect(() => {
    setFormData(prev => {
      const newValues = { ...prev }
      if (!selectedCostCenter) {
        delete newValues[name]
      } else {
        newValues[name] = selectedCostCenter
      }
      return newValues
    })
  }, [setFormData, selectedCostCenter, name])

  return (
    <CostCenterSelect id={`createCostSelect-${name}`}
      onChange={handleChangeCostCenter}
      label={t('costcenter')}
      withSearch
      showTopLevel
      {...rest}
    />
  )
}

export default CreateCostCenterSelect
