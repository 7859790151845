import React, { useState, useEffect } from "react"
import { Spinner } from 'react-bootstrap'
import useAppointmentTypesState from '../../../stores/Configuration/useAppointmentTypesState'
import BootstrapTable from 'react-bootstrap-table-next'
import UniversalTableWrapperTest from '../../Table/UniversalTableWrapperTest'
import { useTranslation } from 'react-i18next'

const CabinSelect = () => {
    const { cabinTypes, assignedCabins, setAssignedCabins } = useAppointmentTypesState()
    const [checked, setChecked] = useState([])
    const { t } = useTranslation()

    //[{id:"",checked:bool,displayName:""}]
    useEffect(() => {
        if (assignedCabins && cabinTypes) {
            const newChecked = []
            for (const obj of cabinTypes) {
                newChecked.push({ id: obj.id, checked: assignedCabins.includes(obj.id), displayName: obj.displayName })
            }
            setChecked(newChecked)
        }

    }, [cabinTypes, assignedCabins])

    const handleLocalChangeCheckbox = id => {
        if (assignedCabins.includes(id)) {
            var index = assignedCabins.indexOf(id);
            var newValues = [...assignedCabins]
            newValues.splice(index, 1);
            setAssignedCabins(newValues)
        }
        else {
            setAssignedCabins(prev => [...prev, id])
        }
    }

    const onRowClick = (e, row) => {
        handleLocalChangeCheckbox(row.id)
    }

    const columns = [
        {
            dataField: 'checked',
            text: t('assigned'),
            sort: true,
            formatter: (cell, row) => {
                return <input name="useSecondaryOfficeName" type="checkbox" checked={cell} onChange={() => { }} id={row.id} />
            }
        },
        {
            dataField: 'displayName',
            text: t('cabinTypes:cabin'),
            sort: true
        }
    ];

    return (
        <div>
            {(cabinTypes === null || assignedCabins === null)
                ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
                : <>
                    <UniversalTableWrapperTest>
                        <BootstrapTable
                            keyField='id'
                            data={checked}
                            columns={columns}
                            bootstrap4
                            hover
                            bordered={false}
                            rowEvents={{ onClick: onRowClick }}
                        />
                    </UniversalTableWrapperTest>
                </>
            }
        </div>
    );
}

export default CabinSelect