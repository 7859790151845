import React from 'react'
import { Button, Alert } from 'react-bootstrap'
import styled from 'styled-components'
import { ButtonWithLoadingState } from '../../Buttons'
import { useAppointmentState } from '../../../stores/AppointmentProvider'
import { useTranslation } from 'react-i18next'
import StepsDataCheck from "./StepsDataCheck"
import { useParams } from 'react-router-dom'


const StyledContainer = styled.div`
  @media screen and (min-width: 1081px) {
    position: sticky;
    top: 50px;
  }
`

const StyledList = styled.ul`
  list-style: none;
  margin: 5px 0 0 0;
  padding: 0;
  font-size: 18px;

  li {
    padding: 10px 0;
  }
  `

const StyledCancelButton = styled(Button)`
  width: 100%;
  max-width: 250px;
`


const Sidebar = () => {
    const { t } = useTranslation()
    const { employeeId, appointmentId } = useParams()

    const {
        isStep1Done,
        isStep2Done,
        isStep3Done,
        dataHasChanged,
        handleClickCancel,
        handleSubmit,
        success,
        error,
        isSaving
    } = useAppointmentState()

    return (
        <StyledContainer>
            <header>
                <div className="h2">{t('progress')}</div>
            </header>
            <div className="content">
                <StyledList>
                    <li>
                        <StepsDataCheck label={`${t('appointment:step')} 1`} description={t('appointment:stepOne')} isDone={isStep1Done} />
                    </li>
                    <li>
                        <StepsDataCheck label={`${t('appointment:step')} 2`} description={t('appointment:stepTwo')} isDone={isStep2Done} />
                    </li>
                    <li>
                        <StepsDataCheck label={`${t('appointment:step')} 3`} description={t('appointment:stepThree')} isDone={isStep2Done && isStep3Done} />
                    </li>
                </StyledList>
                <ButtonWithLoadingState
                    className="submit-btn my-3"
                    isLoading={isSaving}

                    onClick={() => { handleSubmit(employeeId, appointmentId) }}
                    disabled={!isStep1Done || !isStep2Done || !isStep3Done || isSaving}
                    buttonText={t('saveButton')}
                    loadingText={t('saveButton')}
                />
                {dataHasChanged && <StyledCancelButton className="my-3" variant="secondary" onClick={() => handleClickCancel(appointmentId)} >{t('cancelButton')}</StyledCancelButton>}
                {success &&
                    <Alert variant="success">
                        {success}
                    </Alert>
                }
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                {dataHasChanged &&
                    <Alert variant="info">
                        {t('EmployeeFormHealthData:dataChange')}
                    </Alert>
                }

            </div>

        </StyledContainer>
    )
}

export default Sidebar
