import { emptyGuid } from "./emptyGuid"

export const addToBasketBuildConfigurations = (customConfigurations) => {
    let configurations = []

    if (customConfigurations && Object.keys(customConfigurations).length) {
        for (const [key, value] of Object.entries(customConfigurations)) {
            if (value.article.id && value.article.id !== emptyGuid) {
                configurations.push({
                    article: { id: value.article.id },
                    articleConfigurationId: key,
                    comment: value.comment
                })
            }
        }
    }
    return configurations
}

export const equalConfigsCheck = (newConfigurations, oldConfigurations) => {
    if ((!newConfigurations || !newConfigurations.length) && (!oldConfigurations || !oldConfigurations.length)) {
        return true
    }
    else if (newConfigurations && oldConfigurations && newConfigurations.length === oldConfigurations.length) {
        return newConfigurations.every(config => oldConfigurations.some(conf => config.comment === conf.comment && config.articleConfigurationId === conf.articleConfigurationId && config.article.id === conf.article.id))
    }
    else return false
}

export const equalPhaseCheck = (orderPhase, positionPhase) => {
    if (positionPhase || orderPhase) {
        return positionPhase && orderPhase && (positionPhase.id === orderPhase.id)
    }
    return true
}
export const equalLocalStorehouseCheck = (positionLocalStorehouse, selectedLocalStorehouseId) => {
    if (positionLocalStorehouse && selectedLocalStorehouseId) {
        return (positionLocalStorehouse.id === selectedLocalStorehouseId)
    }
    if (!positionLocalStorehouse) {
        return (!selectedLocalStorehouseId || selectedLocalStorehouseId === emptyGuid)
    }
    return false
}
