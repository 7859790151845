import React, { useEffect, useMemo, useState } from 'react'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import { t } from 'i18next'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import SidebarNavigation from '../../components/Page/logix2020/SidebarNavigation'
import { useLocation, useParams } from 'react-router-dom'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { useCatalogsState } from '../../stores/Configuration/CatalogsProvider'
import CatalogBaseForm from '../../components/Configuration/Catalog/CatalogBaseForm'
import CatalogActivateArticle from '../../components/Configuration/Catalog/CatalogActivateArticle'
import CatalogOrderProcesses from '../../components/Configuration/Catalog/CatalogOrderProcesses'
import CatalogOciInterface from '../../components/Configuration/Catalog/CatalogOciInterface'
import i18n from '../../translation/i18n'
import useStores from '../../stores/useStore'
import { emptyGuid } from '../../utils/emptyGuid'
import { ButtonWithLoadingState } from '../../components/Buttons'
import styled from 'styled-components'
import { ErrorAlerts } from '../../utils/api/makeCall'
import { getLanguageValue } from '../../utils/getLanguageValue'
import SubheaderTitle from '../../components/Page/logix2020/SubheaderTitle'
import useMountEffect from '../../utils/hooks/useMountEffect'

const navigationLeft = () => [
  {
    key: '#base',
    to: '#base',
    name: i18n.t('catalogsAdministration:base'),
    disabled: false
  },
  {
    key: '#activateArticle',
    to: '#activateArticle',
    name: i18n.t('catalogsAdministration:activateArticleForUnits'),
    disabled: false
  },
  {
    key: '#orderProcesses',
    to: '#orderProcesses',
    name: i18n.t('catalogsAdministration:availableOrderProcesses'),
    disabled: false
  },
  {
    key: '#ociInterface',
    to: '#ociInterface',
    name: i18n.t('catalogsAdministration:ociInterface'),
    disabled: false
  }
]

const StyledButtonsWrapper = styled.div`
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    .btn {
      margin: 3px 0;
    }
  }
`

const EditCatalogPage = () => {
  const currentLocation = useLocation()
  const { catalogBaseObj, getCatalog, resetCatalogsPage, getOrderProcessTypes, getAliasTypes, successStates, setSuccessStates, errorStates,
    saveCatalogOfficeAvailabilities, deleteCatalogOfficeAvailabilities, isLoading, setIsLoading,
    getSupplierMandators, getSuppliers } = useCatalogsState()
  const [activeNav, setActiveNav] = useState('')
  const { catalogId } = useParams()
  const { officeStore } = useStores()
  const [selectedOffices, setSelectedOffices] = useState(null)
  const selectedOfficesHasChanged = useMemo(() => (selectedOffices != null), [selectedOffices])

  const loadData = async () => {
    getCatalog(catalogId)
    getOrderProcessTypes()
    getAliasTypes()
    getSupplierMandators()
    getSuppliers()
  }
  useEffect(() => {
    return () => resetCatalogsPage()
  }, [resetCatalogsPage])

  useMountEffect(() => {
    loadData()
  })

  useEffect(() => {
    let timer = null
    if (successStates.saveCatalog) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        setSuccessStates({});
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [setSuccessStates, successStates.saveCatalog])

  useEffect(() => {
    setActiveNav(currentLocation.hash || '#base')
  }, [currentLocation.hash])

  const selectAllOffices = () => {
    const allOfficeIds = officeStore.loadedOfficeIds.filter((id) => id !== emptyGuid);
    setSelectedOffices(allOfficeIds)
  }

  const deselectAllOffices = () => {
    setSelectedOffices([]);
  }

  const saveOfficeAvailabilities = async () => {
    setIsLoading(true)
    if (selectedOffices.length) {
      await saveCatalogOfficeAvailabilities(catalogId, selectedOffices)
    } else {
      await deleteCatalogOfficeAvailabilities(catalogId)
    }
    setSelectedOffices(null)
  }

  return (
    <div>
      <Subheader className="subHeader-v1 d-flex justify-content-between">
        <SubheaderTitle
          withBackButton
          smallTitle={getLanguageValue(catalogBaseObj.name.values)}
          goBackLabel={t('catalogsAdministration:catalogsOverview')}
          defaultBackTo="/catalogsAdministration"
        />
        {activeNav === '#activateArticle' &&
          <StyledButtonsWrapper className="d-flex justify-content-end">
            <ButtonWithLoadingState
              className="m-2"
              buttonText={t('catalogsAdministration:selectAll')}
              onClick={() => selectAllOffices()}
            />
            <ButtonWithLoadingState
              className="m-2"
              buttonText={t('catalogsAdministration:deSelectAll')}
              onClick={() => deselectAllOffices()}
            />
            <ButtonWithLoadingState
              disabled={!selectedOfficesHasChanged}
              className="m-2"
              buttonText={t('saveButton')}
              isLoading={isLoading}
              onClick={() => saveOfficeAvailabilities()}
            />
          </StyledButtonsWrapper>}
      </Subheader>

      <FlexWrapper>
        <SidebarLeft>
          <SidebarNavigation options={navigationLeft()} active={activeNav} />
        </SidebarLeft>
        <ContentArea>
          <ErrorAlerts errorStates={errorStates} />
          {activeNav === '#base' && <CatalogBaseForm catalogId={catalogId} />}
          {activeNav === '#activateArticle' && <CatalogActivateArticle selectedOffices={selectedOffices} setSelectedOffices={setSelectedOffices} />}
          {activeNav === '#orderProcesses' && <CatalogOrderProcesses />}
          {activeNav === '#ociInterface' && <CatalogOciInterface />}
        </ContentArea>
      </FlexWrapper>
    </div>
  )
}

export default EditCatalogPage 