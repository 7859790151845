import React from 'react'
import { createRoot } from 'react-dom/client';
import { Provider } from 'mobx-react'
import './translation/i18n'
// stores must be loaded before any other styled components or css/scss styles are imported.
// this ensures, that bootstrap css is loading before customized css/ styled components, so we can overwrite bootstrap styles
import { appStore } from './stores/stores'
import './index.css'
import EmployeesProvider from './stores/EmployeesProvider'
import AppProvider from './stores/AppProvider'
import i18n from './translation/i18n'
import ActivitiesProvider from './stores/Configuration/ActivitiesProvider';
import EntitlementPhasesProvider from './stores/Configuration/EntitlementPhasesProvider';
import ContingentTemplatesProvider from './stores/Configuration/ContingentTemplatesProvider';
import LogixRoutes from './routes';
import CatalogsProvider from './stores/Configuration/CatalogsProvider';
import ThemeInit from './components/ThemeInit/ThemeInit'
import SSOLogin from './components/SSOLogin/SSOLogin';
import PlaceholdersProvider from './stores/Configuration/PlaceholdersProvider';

i18n.on('languageChanged', language => {
  document.querySelector('html').setAttribute('lang', language)
})
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider {...appStore}>
    <AppProvider>
      <EmployeesProvider>
        <ActivitiesProvider>
          <EntitlementPhasesProvider>
            <ContingentTemplatesProvider>
              <CatalogsProvider>
                <PlaceholdersProvider>
                  <SSOLogin>
                    <ThemeInit>
                      <LogixRoutes />
                    </ThemeInit>
                  </SSOLogin>
                </PlaceholdersProvider>
              </CatalogsProvider>
            </ContingentTemplatesProvider>
          </EntitlementPhasesProvider>
        </ActivitiesProvider>
      </EmployeesProvider>
    </AppProvider>
  </Provider>)


