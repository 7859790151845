import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'throttle-debounce'

const useRefSize = (ref) => {
    const [size, setSize] = useState({ width: 0, height: 0 });

    const trigerLazyLoadDebounced = useMemo(
        () => debounce(10, () => {
            setSize({ width: ref.current && ref.current.offsetWidth, height: ref.current && ref.current.offsetHeight })
        }), [ref])

    useEffect(() => {
        trigerLazyLoadDebounced()
        window.addEventListener("resize", trigerLazyLoadDebounced)
        return () => {
            window.removeEventListener("resize", trigerLazyLoadDebounced)
        }
    }, [trigerLazyLoadDebounced]);

    return size;
};

export default useRefSize
