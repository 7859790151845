import React, { useState, useRef } from 'react';
import { Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'i18next';
import { useContingentTemplatesState } from '../../../stores/Configuration/ContingentTemplatesProvider';
import ModalComponent2 from '../../Modals/ModalComponent2';
import BigDataList from './BigDataList';
import { cloneDeep } from 'lodash';
import AddArticles from './AddArticles';
import GroupModal from './GroupModal';
import CopyOrMoveModal from './CopyOrMoveModal';
import MoveArticlesModal from './MoveArticlesModal';
import { DragPrgeview } from './DragPrgeview';
import LoadingOverlay from '../../Loading/LoadingOverlay';
import ArticleSelect from '../../ArticleSelect/ArticleSelect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import useMountEffect from '../../../utils/hooks/useMountEffect';
import MediaQuery from 'react-responsive';

// Styled components for responsiveness and layout control
const StyledArticleSelect = styled.div`
  flex: 1;
  height: 802px;
  overflow: auto;
  border: 1px solid gray;
  margin-left: 1rem;

  @media screen and (max-width: 1500px) {
    height: 100px;
    margin-left: 0;
  }
`;

const StyledWrapper = styled.div`
  .article-search {
    display: flex;
    flex-direction: row; /* Default flex direction (row) */
    width: 100%;
    overflow-x: hidden;

    @media screen and (max-width: 1500px) {
      flex-direction: column-reverse; /* On smaller screens, switch to column-reverse */
    }

    @media screen and (max-width: 1422px) and (min-width: 1081px) {
      width: 90%; /* Reduce the width in the 1422px to 1081px range */
      margin: 0 auto; /* Center the content horizontally */
    }
  }

  @media screen and (min-width: 1080px) {
    min-width: 600px;
  }

  /* Prevent horizontal scrolling */
  overflow-x: hidden;
`;

const ContingentTemplatesOverview = () => {
  const {
    bigData,
    setBigData,
    getContingentTemplates,
    deleteContingentTemplate,
    getGroups,
    loadingStates,
    errorStates,
    successStates,
    setSuccessStates,
    putContingentTemplatesOrderSequence,
    getGroupArticles,
    deleteGroup,
    copyContingentTemplate,
    putArticlesOrderSequenceBackground,
    putGroupsOrderSequenceBackground,
    deleteGroupArticle,
    getArticleAssignments,
    assignmentTree,
    setAssignmentTree,
  } = useContingentTemplatesState();

  const [selectedArticles, setSelectedArticles] = useState([]);
  const listRef = useRef(null);

  const [openDeleteTemplate, setOpenDeleteTemplate] = useState('');
  const [openCopyTemplate, setOpenCopyTemplate] = useState(null);
  const [showAddArticlesModal, setShowAddArticlesModal] = useState(null);
  const [openEditGroupModal, setOpenEditGroupModal] = useState(null);
  const [openAddGroupModal, setOpenAddGroupModal] = useState(null);
  const [openDeleteGroupModal, setOpenDeleteGroupModal] = useState(null);
  const [openMoveOrCopyModal, setOpenMoveOrCopyModal] = useState(null);
  const [openDeleteArticleModal, setOpenDeleteArticleModal] = useState(null);
  const [openMoveArticlesModal, setOpenMoveArticlesModal] = useState(null);

  useMountEffect(() => {
    getContingentTemplates();
  });

  if (errorStates.contingentTemplates) {
    return <Alert variant="danger">{errorStates.contingentTemplates.value}</Alert>;
  }

  const getNewSequence = (fromId, toId, oldSequence) => {
    let fromIndex = oldSequence.findIndex((id) => id === fromId);
    let toIndex = oldSequence.findIndex((id) => id === toId);
    let copiedSequence = [...oldSequence];
    const elm = copiedSequence.splice(fromIndex, 1)[0];
    copiedSequence.splice(toIndex, 0, elm);
    return copiedSequence;
  };

  const moveCardHandler = (fromObj, toObj) => {
    if (!fromObj) {
      return;
    } else if (fromObj.type === toObj.type) {
      if (fromObj.type === 'template') {
        let oldSequence = Object.keys(bigData);
        const newSequence = getNewSequence(fromObj.id, toObj.id, oldSequence);
        putContingentTemplatesOrderSequence(newSequence);
      } else if (fromObj.type === 'group') {
        let groups = bigData[fromObj.entitlementId].groups;
        let oldSequence = Object.keys(groups);
        const newSequence = getNewSequence(fromObj.id, toObj.id, oldSequence);
        putGroupsOrderSequenceBackground(fromObj.entitlementId, newSequence);
      } else {
        let oldSequence = Object.keys(bigData[fromObj.entitlementId].groups[fromObj.groupId].articles);
        const newSequence = getNewSequence(fromObj.id, toObj.id, oldSequence);
        putArticlesOrderSequenceBackground(fromObj.entitlementId, fromObj.groupId, newSequence);
      }
    } else if (fromObj.type === 'article' && toObj.type === 'group') {
      setOpenMoveOrCopyModal({ fromObj, toObj });
    } else if (fromObj.type === 'multipleArticles') {
      setOpenMoveArticlesModal({ fromObj, toObj });
    }
  };

  const changeOpenProp = (entitlementId, groupId) => {
    if (groupId) {
      setBigData((prev) => {
        let newData = cloneDeep(prev);
        newData[entitlementId].groups[groupId].open = !newData[entitlementId].groups[groupId].open;
        return newData;
      });

      let alreadyLoaded = bigData[entitlementId].groups[groupId].articlesLoaded;
      if (!alreadyLoaded) {
        getGroupArticles(entitlementId, groupId);
      }
    } else {
      setBigData((prev) => {
        let newData = cloneDeep(prev);
        newData[entitlementId].open = !newData[entitlementId].open;
        return newData;
      });
    }
    let alreadyLoaded = bigData[entitlementId].groupsLoaded;
    if (!alreadyLoaded) {
      getGroups(entitlementId);
    }
  };

  return (
    <>
      <StyledWrapper>
        <DndProvider backend={HTML5Backend}>
          <DragPrgeview />
          <div className="article-search">
            <div style={{ border: '1px solid gray', flex: 2, overflow: 'auto' }}>
              <LoadingOverlay isLoading={loadingStates.saveOrderSequence || loadingStates.articleAssignments || !bigData}>
                <BigDataList
                  changeOpenProp={changeOpenProp}
                  moveCardHandler={moveCardHandler}
                  bigData={bigData}
                  deleteTemplate={setOpenDeleteTemplate}
                  copyTemplate={setOpenCopyTemplate}
                  addArticles={setShowAddArticlesModal}
                  editGroup={setOpenEditGroupModal}
                  addGroup={setOpenAddGroupModal}
                  deleteGroup={setOpenDeleteGroupModal}
                  deleteArticle={setOpenDeleteArticleModal}
                  getArticleAssignments={getArticleAssignments}
                  assignmentTree={assignmentTree}
                  deleteAssignments={setAssignmentTree}
                />
              </LoadingOverlay>
            </div>
            <MediaQuery minWidth={768}>
              <StyledArticleSelect ref={listRef}>
                <ArticleSelect
                  selectedArticles={selectedArticles}
                  setSelectedArticles={setSelectedArticles}
                  getArticleAssignments={getArticleAssignments}
                  withDND
                  listRef={listRef}
                />
              </StyledArticleSelect>
            </MediaQuery>
          </div>
        </DndProvider>
      </StyledWrapper>

      {openDeleteTemplate && (
        <ModalComponent2
          ModalTitle={t('contingentTemplates:deleteContingentTemplate')}
          BodyPart1={t('contingentTemplates:deleteBody')}
          button1Function={() => deleteContingentTemplate(openDeleteTemplate).then(() => setOpenDeleteTemplate(''))}
          button1Text={t('delete')}
          button1Loading={loadingStates.deleteContingentTemplate}
          button1Variant="danger"
          bodyStyle="alert alert-danger"
          functionCancel={() => {
            setOpenDeleteTemplate('');
            setSuccessStates({});
          }}
          error={errorStates?.deleteContingentTemplate?.value}
        />
      )}

      {openCopyTemplate && (
        <ModalComponent2
          ModalTitle={t('contingentTemplates:copyTemplate')}
          button1Function={() => copyContingentTemplate(openCopyTemplate).then(() => setOpenCopyTemplate(''))}
          button1Text={t('officeAdmin:copy')}
          button1Loading={loadingStates.copyContingentTemplate}
          functionCancel={() => {
            setOpenCopyTemplate('');
            setSuccessStates({});
          }}
          error={errorStates?.copyContingentTemplate?.value}
        />
      )}

      {showAddArticlesModal && (
        <AddArticles
          setShowAddArticlesModal={setShowAddArticlesModal}
          entitlementId={showAddArticlesModal.entitlementId}
          groupId={showAddArticlesModal.groupId}
          getGroupArticles={getGroupArticles}
          bigData={bigData}
        />
      )}

      {openEditGroupModal && (
        <GroupModal
          groupId={openEditGroupModal.groupId}
          contingentTemplateId={openEditGroupModal.entitlementId}
          handleClose={() => setOpenEditGroupModal(null)}
          editGroup={bigData[openEditGroupModal.entitlementId].groups[openEditGroupModal.groupId]}
        />
      )}

      {openAddGroupModal && <GroupModal contingentTemplateId={openAddGroupModal.entitlementId} handleClose={() => setOpenAddGroupModal(null)} />}

      {openDeleteGroupModal && (
        <ModalComponent2
          ModalTitle={t('contingentTemplates:deleteGroup')}
          BodyPart1={t('contingentTemplates:deleteGroupBody')}
          button1Function={() => deleteGroup(openDeleteGroupModal.entitlementId, openDeleteGroupModal.groupId).then(() => setOpenDeleteGroupModal(null))}
          button1Text={!successStates.deleteGroup ? t('delete') : null}
          button1Loading={loadingStates.deleteGroup}
          button1Variant="danger"
          bodyStyle="alert alert-danger"
          functionCancel={() => {
            setOpenDeleteGroupModal(null);
          }}
        />
      )}

      {openMoveOrCopyModal && (
        <CopyOrMoveModal
          setOpenMoveOrCopyModal={setOpenMoveOrCopyModal}
          getGroupArticles={getGroupArticles}
          fromArticle={openMoveOrCopyModal.fromObj}
          toGroup={openMoveOrCopyModal.toObj}
          errorStates={errorStates}
        />
      )}

      {openMoveArticlesModal && (
        <MoveArticlesModal
          setOpenMoveOrCopyModal={setOpenMoveArticlesModal}
          getGroupArticles={getGroupArticles}
          fromArticle={openMoveArticlesModal.fromObj}
          toGroup={openMoveArticlesModal.toObj}
          errorStates={errorStates}
        />
      )}

      {openDeleteArticleModal && (
        <ModalComponent2
          ModalTitle={t('contingentTemplates:deleteArticle')}
          BodyPart1={t('contingentTemplates:deleteArticleBody')}
          button1Function={() => deleteGroupArticle(openDeleteArticleModal.entitlementId, openDeleteArticleModal.groupId, openDeleteArticleModal.articleId).then(() => setOpenDeleteArticleModal(null))}
          button1Text={t('delete')}
          button1Loading={loadingStates.deleteArticle}
          button1Variant="danger"
          bodyStyle="alert alert-danger"
          functionCancel={() => {
            setOpenDeleteArticleModal(null);
          }}
        />
      )}
    </>
  );
};

export default ContingentTemplatesOverview;
