import React, { useEffect, useState, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { emptyGuid } from '../../utils/emptyGuid'
import {
    ArrowIconNew,
    StyledDot,
    StyledItemWrapper,
    StyledArrowWrapper,
    StyledListItemText,
    StyledImage,
    StyledTreeSubList
} from '../TreeList/treeList'
import houses from '../../assets/icons/houses.png';
import cube_green from '../../assets/icons/cube_green.png';
import cube_yellow from '../../assets/icons/cube_yellow.png';
import cube_gray from '../../assets/icons/cube_gray.png';
import cube_group from '../../assets/icons/cube_group.png';
import { Spinner } from 'react-bootstrap'

const LaundryShelfTree = inject('laundryShelfStore')(observer((props) => {
    const {
        employeeId,
        location,
        laundryShelfStore,
        showTopLevel = true,
        renderExtraItemContent = (laundryShelf) => null,
    } = props

    const [laundryShelvesActive, setLaundryShelvesActive] = useState({ [emptyGuid]: true })

    useEffect(() => {
        laundryShelfStore.getTree(location.id)
    }, [laundryShelfStore, location])

    const handleClickParent = id => {
        setLaundryShelvesActive({ ...laundryShelvesActive, [id]: !laundryShelvesActive[id] })
    }

    const getChildLaundryShelf = (parentId) => {    // function to find the child offices if there are any
        return laundryShelfStore.treeItems.filter(laundryShelf => laundryShelf.parentId === parentId)
    }

    const topLevelLaundryShelves = useMemo(() => {   //
        if (showTopLevel) return [{
            ...location,
            id: emptyGuid,
            assignedToBusinesspartnerId: emptyGuid,
            hasChildren: true,
            type: "isGroup"
        }]
        return laundryShelfStore.treeItems.filter(laundryShelf => laundryShelf.parentId === emptyGuid)
    }, [laundryShelfStore.treeItems, location, showTopLevel])

    const renderLaundryShelfItem = (laundryShelf) => {
        // helper function that renders one office
        const assignedToId = laundryShelf.assignedToBusinesspartnerId
        const assignedToMe = laundryShelfStore.selectedItems.find(obj => obj.id === laundryShelf.id)
        const laundryImage = laundryShelf.id === emptyGuid ? houses
            : laundryShelf.type === "isGroup" ? cube_group
                : ((assignedToId === emptyGuid && !assignedToMe) || (assignedToId === employeeId && !assignedToMe)) ? cube_green
                    : assignedToMe ? cube_yellow
                        : assignedToId !== employeeId ? cube_gray
                            : null
        if (laundryShelfStore.treeItemsIsLoading) {
            return <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
        }
        return (
            <React.Fragment>
                <StyledItemWrapper isClickable={false}>
                    {laundryShelf.hasChildren &&
                        <React.Fragment>
                            <StyledArrowWrapper onClick={() => handleClickParent(laundryShelf.id)} >
                                <ArrowIconNew color={laundryShelvesActive[laundryShelf.id] ? '#9c9c9c' : '#3a3a38'}
                                    variant={laundryShelvesActive[laundryShelf.id] ? 'down' : 'right'} />
                                <StyledImage image={laundryImage} />
                            </StyledArrowWrapper>
                        </React.Fragment>}
                    <div className='d-flex flex-grow-1' onClick={() => { }}>
                        {!laundryShelf.hasChildren &&
                            <React.Fragment>
                                <StyledDot>•</StyledDot>
                                <StyledImage image={laundryImage} />
                            </React.Fragment>}
                        <StyledListItemText>{laundryShelf.displayName}</StyledListItemText>
                        {renderExtraItemContent(laundryShelf)}
                    </div>
                </StyledItemWrapper>
                {laundryShelf.hasChildren && laundryShelvesActive[laundryShelf.id] &&    // if the office has child and its open
                    <StyledTreeSubList>
                        {getChildLaundryShelf(laundryShelf.id).map(child =>
                            renderLaundryShelfItem(child)
                        )}
                    </StyledTreeSubList>
                }
            </React.Fragment>
        )
    }

    return (
        <div>
            {topLevelLaundryShelves.length ?
                topLevelLaundryShelves.map(laundryShelf => (
                    renderLaundryShelfItem(laundryShelf)
                ))
                : ''
            }
        </div>
    )
}))

export default LaundryShelfTree
