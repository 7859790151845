import React from 'react'

import BillsTable from '../../Bills/BillsTable'
import BillsTableFilter from '../../Bills/BillsTableFilter'


const Bills = () => {
    return (
        <>
            <BillsTableFilter />
            <BillsTable />
        </>
    )
}

export default Bills