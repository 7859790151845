import React from 'react'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import { useTranslation } from 'react-i18next'
import AdministrationSidebarNavigation from '../../components/Configuration/AdministrationSidebarNavigation'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import BigFooter from '../../components/Footer/BigFooter'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'


const ConfigurationPage = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Subheader className="subHeader-v1">
        <div className="subheader-title">{t('administration:configurationHeader')}</div>
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <AdministrationSidebarNavigation active="configuration" />
        </SidebarLeft>
        <ContentArea>
          {/* <ConfigurationContentArea/> */}
          [content]
        </ContentArea>
      </FlexWrapper>
      <BigFooter />
    </div >
  )
}

export default ConfigurationPage
