import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import styled, { withTheme } from 'styled-components'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'

import { ButtonWithLoadingState } from '../Buttons'
import { withTranslation } from 'react-i18next'
import getThemeValue from '../../utils/getThemeValue'


const StyledHeadline = styled.h1`
font-size: 2rem;
font-weight: 600;
text-align: center;
color:white;
@media screen and (min-width: 576px) {
  font-size: 2.5rem;
}
`

const StyledSubline = styled.h5`
margin-bottom: 2rem;
text-align: center;
color:white;
`
const StyledButtons = styled.p`
  margin-top: 2rem;
`

const StyledSuccessWrapper = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  a {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

class PasswordResetBox extends Component {
  componentWillUnmount() {
    this.props.loginStore.passwordReset.isLoginQuestionLoading = false
    this.props.loginStore.passwordReset.isLoginQuestionError = false
    this.props.loginStore.passwordReset.loginQuestion = null
    this.props.loginStore.passwordReset.finished = null
    this.props.loginStore.passwordReset.errorText = ''
  }

  render() {
    const { t, theme, loginStore } = this.props
    const passwordResetSubline = getThemeValue('userLogin', 'passwordResetBoxSubline', t('passwordReset:subText'))
    const usernamePlaceholder = getThemeValue('userLogin', 'usernamePlaceholder', '')
    const usernameLabel = getThemeValue('userLogin', 'usernameLabel', '')
    return (
      <div className="styledBox smallBox">
        {loginStore.passwordReset.finished
          ? <StyledSuccessWrapper>
            <Alert variant='success'>
              {t('passwordReset:successMsg')}
            </Alert>
          </StyledSuccessWrapper>
          : ''
        }

        {loginStore.passwordReset.isLoginQuestionError
          ? <Alert variant='danger'>
            {loginStore.passwordReset.errorText}
          </Alert>
          : ''
        }

        {loginStore.passwordReset.finished ? ''
          : !loginStore.passwordReset.loginQuestion
            ? <Form onSubmit={(e) => loginStore.handleOnUsernameSubmit(e)} >
              <Form.Group controlId='formBasicEmail' className="mb-3" >
                <StyledHeadline>{t('passwordReset:forgotPassword')}</StyledHeadline>
                <StyledSubline>{passwordResetSubline}</StyledSubline>
                <hr />
                <Form.Label>{usernameLabel}</Form.Label>
                <Form.Control
                  key='formBasicEmail'
                  type={theme.userLogin.type}
                  placeholder={usernamePlaceholder}
                  onChange={event => loginStore.handleUsernameOnChange(event)}
                  required />

                <StyledButtons className='d-flex justify-content-between align-items-center'>
                  <Form.Text>
                    <Link to='/login'>{t('loginBox:Login')}</Link>
                  </Form.Text>
                  <ButtonWithLoadingState
                    buttonText={t('nextButton')}
                    isLoading={loginStore.passwordReset.isLoading}
                    type='submit'
                    variant='primary' />
                </StyledButtons>
              </Form.Group>
            </Form>
            : <Form onSubmit={(e) => loginStore.handleOnLoginQuestionSubmit(e)} >
              <Form.Group controlId='loginQuestion' className="mb-3">
                <StyledHeadline>{t('passwordReset:securityQuestion')}</StyledHeadline>
                <StyledSubline>{t('passwordReset:securityQuestionSubText')}</StyledSubline>
                <Form.Label>{loginStore.passwordReset.loginQuestion}</Form.Label>
                <Form.Control
                  key='loginQuestion'
                  type={theme.userLogin.type}
                  placeholder={t('passwordReset:answer')}
                  onChange={event => loginStore.handleSecurityQuestionAnswerOnChange(event)}
                  required
                />

                <StyledButtons className='d-flex justify-content-between align-items-center flex-row-reverse'>
                  <ButtonWithLoadingState
                    buttonText={t('passwordReset:resetPassword')}
                    className='float-end'
                    isLoading={loginStore.passwordReset.isLoading}
                    type='submit'
                    variant='primary'
                  />
                </StyledButtons>
              </Form.Group>
            </Form>
        }
      </div>
    )
  }
}

export default withTheme(withTranslation()(inject('uiStore', 'loginStore')(observer(PasswordResetBox))))
