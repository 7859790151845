import React, { memo, useEffect, useState, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import ContingentTemplateItem from './ContingentTemplateItem';

const BigDataList = memo(({ bigData, ...rest }) => {
  const [bigDataArray, setBigDataArray] = useState([]);
  const [listWidth, setListWidth] = useState(0);
  const containerRef = useRef(null);

  // Function to flatten the bigData object
  function flattenBigData(obj) {
    let templatesArr = Object.values(obj).map((template) => {
      let groups = [];
      if (template.open) {
        groups = Object.values(template.groups).map((group) => {
          let articles = [];
          if (group.open) {
            articles = Object.values(group.articles).map((article) => {
              let preparedArticle = { ...article, type: 'article', entitlementId: template.id, groupId: group.id, iconType: group.systemIcon };
              return preparedArticle;
            });
          }
          let preparedGroup = { ...group, type: 'group', entitlementId: template.id };
          return [preparedGroup, ...articles];
        });
      }
      let preparedTemplate = { ...template, type: 'template' };
      return [preparedTemplate, ...groups.flat()];
    });
    return templatesArr.flat();
  }

  // Flatten bigData whenever it changes
  useEffect(() => {
    if (bigData) {
      setBigDataArray(flattenBigData(bigData));
    }
  }, [bigData]);

  // Handle container resizing
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setListWidth(containerRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(() => handleResize());
    const currentContainer = containerRef.current;
    if (currentContainer) {
      resizeObserver.observe(currentContainer);
    }

    // Set initial width
    handleResize();

    // Clean up observer on component unmount
    return () => {
      if (currentContainer) {
        resizeObserver.unobserve(currentContainer);
      }
    };
  }, []);

  return (
    <div ref={containerRef} style={{ flexGrow: 1, width: '100%', overflow: 'hidden' }}>
      <List
        height={800}
        itemCount={bigDataArray.length}
        itemSize={32}
        width={listWidth} // Set width dynamically based on container
      >
        {({ index, style }) => (
          <div style={style}>
            <ContingentTemplateItem
              key={`${bigDataArray[index].id}_${bigDataArray[index].entitlementId}_${bigDataArray[index].groupId}`}
              index={index}
              arrayItem={bigDataArray[index]}
              {...rest}
            />
          </div>
        )}
      </List>
    </div>
  );
});

export default BigDataList;
