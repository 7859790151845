import React from "react";
import { Table } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import { ContingentRows } from "../../ProductsOverview/Structure";
import SelectedAmountsRow from "./SelectedAmountsRow";
import { t } from "i18next";


const SelectedAmounts = inject("inventoryStore")(
  observer((props) => {
    const { inventoryStore } = props;
    const showContingent = (contingent) => (contingent.categories.some(category => category.articles.some(article => article.positions.some(position => position.rows && position.rows.length))))
    const showCategory = (category) => (category.articles.some(article => article.positions.some(position => position.rows && position.rows.length)))
    const showArticle = (article) => (article.positions.some(position => position.rows && position.rows.length))
    const showPosition = (position) => (position.rows && position.rows.length)
    let fakeCatalogArticlesContingent = { displayName: "Andere", categories: [{ articles: inventoryStore.editCatalogArticles }] }
    return (
      <>
        {inventoryStore.contingents ? (
          <Table borderless>
            <tbody>
              {/* Contingent Articles */}
              {inventoryStore.contingents.map((contingent) => {
                if (!showContingent(contingent)) {
                  return null
                }
                return (<ContingentRows key={contingent.id} contingent={contingent} withoutImg>
                  {contingent.categories.map((category) => {
                    if (!showCategory(category)) {
                      return null
                    }
                    return (<React.Fragment key={category.id}>
                      <tr data-id={`category-${category.id}`}>
                        <td colSpan="5">
                          <h2 className="mt-3">{category.displayName}</h2>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('name')}</th>
                        <th>{t('inventory:articleNr')}</th>
                        <th>{t('size')}</th>
                        <th>{t('quantity')}</th>
                      </tr>
                      {category.articles.map((article) => {
                        if (!showArticle(article)) {
                          return null
                        }
                        return (article.positions.map((position) => {
                          if (!showPosition(position)) {
                            return null
                          }
                          return (<SelectedAmountsRow
                            key={`${article.id}_${position.articleVariant ? position.articleVariant.id : '_'}`}
                            article={article}
                            position={position}
                          />
                          )
                        }))
                      })}
                    </React.Fragment>)
                  }
                  )}
                </ContingentRows>
                )
              }
              )}
              {/* Catalog Articles */}
              {showContingent(fakeCatalogArticlesContingent) ?
                <>
                  <ContingentRows contingent={fakeCatalogArticlesContingent} withoutImg></ContingentRows>
                  {inventoryStore.editCatalogArticles.map(article => (
                    article.positions.map(position => {
                      if (!showPosition(position)) {
                        return null
                      }
                      return <SelectedAmountsRow
                        key={`${article.id}_${position.articleVariant ? position.articleVariant.id : '_'}`}
                        article={article}
                        position={position}
                      />
                    })
                  ))}
                </>
                : null
              }
            </tbody>
          </Table>
        ) : null}

      </>
    );
  })
);

export default SelectedAmounts;
