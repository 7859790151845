import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/colors'

const StyledCard = styled.div`

  .list-card {
    // background: white;
    background: ${colors.gray6};
    border-radius: 5px;
    padding: 10px 15px;
    border: 1px solid ${colors.gray2};
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &__title {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin-bottom: 20px;
      font-size: 22px;

      i {
        margin-right: 14px;
        svg {
          width: 16px;
          height: auto;
          vertical-align: baseline;
        }
      }
    }
   
   
    &__row {
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      border-bottom: 1px solid ${colors.gray2};
      padding-bottom: 5px;
      margin-bottom: 5px;
      &:hover {
        
       }
       @media screen and (min-width: 1080px) {
        flex-direction: column;
      }
       @media screen and (min-width: 1420px) {
        flex-direction: row;
        flex-wrap: nowrap;
      }
      
     

     /* @media screen and (min-width: 1080px) and (max-width: 1300px), (max-width:400px) {
        flex-wrap: wrap;
      }*/

      &:last-child {
        border-bottom: 0;
      }
     
      .date {
        //width: 25%;
      }
      .time {
        //width: 25%;
      }
      .action-buttons {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: center;
        
  
        & > div {
          margin: 0 5px;
        }
      }
      .action-button {
        cursor: pointer;
      }

      

      .date, .title, .time{
        padding-left: 20px;
      }

      .counter {
        color: ${props => props.theme.primaryColor};
        text-align: right;
        font-weight: 600;
        padding-left: 10px;
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
      }
    }

    .tab-content {
      .list-card__row {
        .title {
          order: 0;
        }
        .counter {
          order: 1;
        }
      }
    }

    .nav-tabs {
      border-bottom: 0;
      margin-bottom: 20px;
      .nav-item {
        .nav-link {
        color: ${colors.gray3};
        border-radius: 0;
        border: 0;
        padding: 0 28px 0 0;
        font-size: 16px;

        &:focus {
          outline: 0;
          box-shadow: none;
        }

        &:hover {
          border-color: transparent;
          color: ${props => props.theme.primaryColor};
        }

        &.active {
          position: relative;
          color: ${colors.gray7};
        }
      }
      }
    }

    .show-more {
      color: ${props => props.theme.primaryColor};
      float: right;
      margin-top: 15px;
      cursor: pointer;
    }
    .show-less {
      color: ${props => props.theme.primaryColor};
      float: left;
      margin-top: 15px;
      cursor: pointer;
    }
  }
  
`
const ListCard = ({ children, ...rest }) => {
  return (
    <StyledCard  {...rest}>
      <div className="list-card">
        {children}
      </div>
    </StyledCard>
  )
}

export default ListCard
