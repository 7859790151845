import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { StyledDescriptionBlock, StyledTitle } from './ProductDetailStyles'

const StyledDescription = styled.div`
  margin-bottom: 15px;
`

const StyledModalImg = styled.img`
  max-width: 100%;
`
const SizeDescriptionBlock = ({ article, withTitle }) => {
    const [showSizeTableModal, setShowSizeTableModal] = useState(false)
    const sizeTableImage = article.sizeTable && article.sizeTable.url ? article.sizeTable.url : false
    const showSizeDescription = article.fitInfo || sizeTableImage

    const { t } = useTranslation()

    const handleSizetableButtonClick = article => {
        if (article.sizeTable.type.match(/image/)) {
            setShowSizeTableModal(true)
        } else {
            window.open(article.sizeTable.url)
        }
    }
    if (!showSizeDescription) {
        return null
    }
    return (
        <StyledDescriptionBlock>
            {withTitle ? <StyledTitle className="h1">{t('productDetail:fitInfoTitle')}</StyledTitle> : null}
            <StyledDescription>
                {article.fitInfo}
            </StyledDescription>
            {sizeTableImage ? (
                <Button variant='outline-dark' onClick={() => handleSizetableButtonClick(article)}>{t('productDetail:sizeTable')}</Button>
            ) : ''}
            <Modal aria-labelledby='contained-modal-title-vcenter'
                centered
                size='lg'
                show={showSizeTableModal}
                onHide={() => setShowSizeTableModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('productDetail:sizeTable')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'><StyledModalImg src={sizeTableImage} alt='' /></Modal.Body>
            </Modal>
        </StyledDescriptionBlock>
    )
}

export default SizeDescriptionBlock
