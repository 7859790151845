import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import "react-multi-carousel/lib/styles.css";
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { colors } from '../../styles/colors';
import useStores from '../../stores/useStore';
import { PageLoadingLayer } from '../Loading/PageLoadingLayer'
import { CategoryIconDefault } from '../Icons/CategoryIcons/CategoryIcons'
import ProductDetailBlock from '../ProductDetail/ProductDetailBlock'
import AliasesBlock from '../ProductDetail/AliasesBlock'
import LogisticBlock from '../ProductDetail/LogisticBlock'
import SizeDescriptionBlock from '../ProductDetail/SizeDescriptionBlock'
import { useNavigate } from 'react-router-dom'
import { StyledTitleSmall } from '../ProductDetail/ProductDetailStyles'

const StyledCardWrapper = styled.div`
  padding: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
  border-radius: 5px;
`
const StyledImageWrapper = styled.div`
  height: 10rem;
  display: flex;
  align-items:center;
  justify-content: center;

  svg {
    max-width: 100%;
    max-height: 100%; 
  }
`
const StyledImage = styled.img`
  max-width: 185px;
  max-height: 7rem;
`
const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin:1rem 1rem 2rem 1rem;
`
const StyledPrice = styled.div`
text-align: right;
font-size: 1rem;
font-weight: bold;
color: ${props => props.theme.primaryColor};
margin-bottom: 5px;
`
const StyledName = styled.div`
color: ${colors.gray5};
font-size: 1.2rem;
text-align: center;
font-weight: 600;
`
const StyledNumber = styled.div`
text-align: center;
    font-size: 0.7rem;
    font-weight: 600;
    color: ${colors.gray3};
`
const StyledTopWrapper = styled.div`
min-height:250px;
`
const StyledHeightWrapper = styled.div`
height:200px;
overflow-y:auto;
overflow-x: hidden;`
const ProductsCardCompare = ({ articleId, articleTo, articlePrice }) => {
  const [article, setArticle] = useState({})
  const { productsCompareStore } = useStores();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    productsCompareStore.getArticleInfo(articleId).then(result => setArticle(result))
  }, [articleId, productsCompareStore])

  const goToArticle = () => {
    productsCompareStore.setShowProductsCompareModal(false)
    navigate(articleTo)
  }

  return (
    <>
      {Object.keys(article).length ? <StyledCardWrapper>
        <StyledTopWrapper>
          <StyledImageWrapper>
            {article.image && article.image.url ?
              <StyledImage src={article.image && article.image.url} />
              :
              <CategoryIconDefault color={colors.gray2} className="placeholder-img" />
            }
          </StyledImageWrapper>
          <StyledName>{article.displayName}</StyledName>
          <StyledNumber>{t('productDetail:articleNumber')} {article.articleNumber}</StyledNumber>
        </StyledTopWrapper>
        <StyledPrice>{articlePrice}</StyledPrice>
        <StyledButtonsWrapper>
          <Button onClick={goToArticle} size="sm" className="mb-1">{t('articleComparison:goToArticle')}</Button>
          <Button onClick={() => productsCompareStore.deleteArticle(article.id)} variant="danger" size="sm">{t('articleComparison:removeArticle')}</Button>
        </StyledButtonsWrapper>
        {/* info here */}
        <StyledTitleSmall>{t('productDetail:productDetails')}</StyledTitleSmall>
        <StyledHeightWrapper><ProductDetailBlock article={article} withoutTitle={true} /></StyledHeightWrapper>
        <StyledTitleSmall>{t('productDetail:logistics')}</StyledTitleSmall>
        <StyledHeightWrapper><LogisticBlock article={article} withoutTitle={true} /></StyledHeightWrapper>
        <StyledTitleSmall>{t('productDetail:fitInfoTitle')}</StyledTitleSmall>
        <StyledHeightWrapper> <SizeDescriptionBlock article={article} withoutTitle={true} /></StyledHeightWrapper>
        <StyledTitleSmall>{t('productDetail:articleAliases')}</StyledTitleSmall>
        <StyledHeightWrapper><AliasesBlock article={article} withoutTitle={true} /></StyledHeightWrapper>
      </StyledCardWrapper> : <PageLoadingLayer isLoading={true} />}
    </>
  );
}

export default ProductsCardCompare;