import React, { useCallback, useEffect, useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { t } from 'i18next'

import { colors } from '../../../styles/colors'
import apiClient from '../../../utils/api/apiClient'
import { APIEndpoints } from '../../../utils/api/apiConfig'
import ArraySelect from '../../Select/ArraySelect'
import { StyledRequiredFormLabel, StyledRequiredFormCheckLabel } from '../shared/FormStyles';

const StyledReorderSize = styled.div`
  color: ${colors.gray3};
  margin-left: 1rem;
`

const VariantSelect = ({ positionId, handleChangeReorderVariant, reorderIsMandatory }) => {
  const [variantsLoading, setVariantsLoading] = useState(true)
  const [loadedVariants, setLoadedVariants] = useState([])
  const getVariants = useCallback(async () => {
    try {
      let variants = await apiClient.getJson(APIEndpoints.shippingOrderPositionsReorderableVariants(positionId))
      setLoadedVariants(variants)
    }
    catch (e) { }
    finally {
      setVariantsLoading(false)
    }
  }, [positionId])


  useEffect(() => {
    getVariants()
  }, [getVariants])

  if (variantsLoading || !loadedVariants.length) {
    return <div className='d-flex justify-content-center p-5'><Spinner animation='border' variant='primary' /></div>
  }


  return <Form.Group className="mb-3">
    {
      reorderIsMandatory
      ?
      <StyledRequiredFormLabel>{t('orderDialog:selectSize')}</StyledRequiredFormLabel>
      :
      <Form.Label>{t('orderDialog:selectSize')}</Form.Label>
    }

    <ArraySelect
      onChange={handleChangeReorderVariant}
      value={""}
      options={loadedVariants}   
      isInvalid={reorderIsMandatory}
    />
    <Form.Control.Feedback type='invalid'>{reorderIsMandatory && t('productDetail:selectVariantSize')}</Form.Control.Feedback>
  </Form.Group>
}

const ReorderVariantCheck = ({ positionId, reorderVariant, handleChangeReorderVariant, setShowVariants, disableField, reorderIsMandatory }) => {
  const handleCheck = () => {
    if (!!reorderVariant) {
      handleChangeReorderVariant(null)
    }
    else {
      setShowVariants(true)
    }
  }
  return <div className="d-flex">
    <Form.Check >
      <Form.Check.Input
        type='checkbox'
        id={`reorder-variant-${positionId}`}
        onChange={handleCheck}
        checked={!!reorderVariant}
        disabled={disableField}
      />
      {
        (reorderIsMandatory) 
        ?
        <StyledRequiredFormCheckLabel htmlFor={`reorder-variant-${positionId}`}>
          {t('orderDialog:reorderPosition')}
        </StyledRequiredFormCheckLabel>
        :
        <Form.Check.Label htmlFor={`reorder-variant-${positionId}`}>
          {t('orderDialog:reorderPosition')}
        </Form.Check.Label>

      }
    </Form.Check>
    {reorderVariant ? <StyledReorderSize>{` (${t('size')}: ${reorderVariant.displayName})`}</StyledReorderSize> : null}
  </div>
}

const ReorderVariant = ({ position, reorderVariant, disableField = false, reorderIsMandatory = false}) => {
  const [showVariants, setShowVariants] = useState(false)
  //mutating the positon
  const handleChangeReorderVariant = (variant) => {
    position._reorderVariant = variant
    if (!!variant) {
      setShowVariants(false)
    }
  }
  return (
    <div className="mb-3">
      {showVariants || (reorderIsMandatory && !reorderVariant)
        ? <VariantSelect positionId={position.id} handleChangeReorderVariant={handleChangeReorderVariant} reorderIsMandatory={reorderIsMandatory}/>
        : <ReorderVariantCheck positionId={position.id} handleChangeReorderVariant={handleChangeReorderVariant} reorderVariant={reorderVariant} setShowVariants={setShowVariants} disableField={disableField} reorderIsMandatory={reorderIsMandatory}/>
      }
    </div>

  )
}

export default ReorderVariant