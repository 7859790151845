import styled from 'styled-components'
import Spinner from 'react-bootstrap/Spinner'
import React from 'react'

const StyledSpinnerWrapper = styled.div`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
`
const StyledLoadingOverlay = styled.div`
  position: relative;

    &:after{
      content: '';
      display: ${props => props.isLoading ? 'block' : 'none'};
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${props => props.noBackground ? 'none': 'rgba(0,0,0,0.4)'};
      z-index: 1;
    }
`


const LoadingOverlay = ({children, isLoading, ...rest}) => {
  return (
    <StyledLoadingOverlay isLoading={isLoading} {...rest}>
      {children}
      {isLoading ? <StyledSpinnerWrapper><Spinner animation='border' variant='primary'/></StyledSpinnerWrapper> : ''}
    </StyledLoadingOverlay>
  )
}

export default LoadingOverlay
