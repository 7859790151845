import React, { useState, Suspense, lazy } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Alert } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { ArrowLeftLink } from '../components/ArrowLeftLink/ArrowLeftLink'
import SubheaderTitle from '../components/Employees/Subheader/SubheaderTitle'
import { Subheader } from '../components/Page/logix2020/Subheader'
import ShoppingCartProgress from '../components/ShoppingCart/ShoppingCartProgress'
import { PdfButton } from '../components/Buttons/PdfButton'
import DownloadURLAlert from '../components/Forms/DownloadURLAlert'
import apiClient from '../utils/api/apiClient'
import { APIEndpoints } from '../utils/api/apiConfig'


const mandate = process.env.REACT_APP_MANDATORSET //return mandate=cws

const lazyImportOrderSuccessImage = () => {
  return import(`../components_overrides/${mandate}/Order/OrderSuccess`).catch(() => {
    return import(`../components/OrderSuccess/OrderSuccess`)
  });
};

const OrderSuccessBMW = lazy(lazyImportOrderSuccessImage);

const StyledIconWrapper = styled.div`
  width: 100%;
  max-width: 384px;
`

const OrderSuccessPage = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  let location = useLocation()
  const { state } = location
  const orderId = state && state.orderId
  const message = state && state.message
  const keepArticleOnCart = state && state.keepArticleOnCart
  const showMessage = keepArticleOnCart && message !== null
  const [error, setError] = useState('')
  const [downloadUrl, setDownloadUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const loadPdf = () => {
    setError('')
    setIsLoading(true)
    setDownloadUrl('')
    apiClient.postJson(APIEndpoints.incomingOrders(orderId).receipt, {}, true, true)
      .then(response => {
        const newWindowRef = window.open(response.headers.location)
        if (!newWindowRef) {
          setDownloadUrl(response.headers.location)
        }
      }).catch(() => {
        setError(t('export:createError'));
      }).finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <React.Fragment>
      <Subheader className="subHeader-v1 with-progress">
        <SubheaderTitle />
        <ShoppingCartProgress>
          <ul>
            <li className="active"><div className="bubble-wrapper">{t('SubheaderProgress:shoppingCart')}</div></li>
            <li className="active"><div className="bubble-wrapper">{t('SubheaderProgress:shipping')}</div></li>
            <li className="active"><div className="bubble-wrapper">{t('SubheaderProgress:orderCompletion')}</div></li>
            <li className="active"><div className="bubble-wrapper">{t('SubheaderProgress:done')}</div></li>
          </ul>
        </ShoppingCartProgress>
      </Subheader>
      <div className='container-fluid'>
        {showMessage && <h1 className='mt-5 mb-3 text-center text-primary'>{message}</h1>}
        <h1 className={`${showMessage ? 'mb-4' : 'mt-5 mb-4'} text-center text-primary`}><Trans i18nKey='orderSuccess:title' /></h1>
        <div className='d-flex justify-content-center mb-4 p-4'>
          <StyledIconWrapper>
            {/* <OrderSuccess color={theme.primaryColor} /> */}
            <Suspense fallback={<div>{t('loading')}</div>}>
              <OrderSuccessBMW color={theme.primaryColor} />
            </Suspense>
          </StyledIconWrapper>
        </div>
        <div className='d-flex align-items-center flex-column'>
          {orderId && <PdfButton
            variant="secondary"
            onClick={() => loadPdf()}
            isLoading={isLoading}
            buttonText={t('breadcrumb:orderSuccess')}
            className="mb-3 "
          />}
          <div className='mb-3'>
            <Link className='btn btn-lg btn-primary' to='/orders'>{t('orderSuccess:myOrders')}</Link>
          </div>
          <div className='mb-5'>
            <small><ArrowLeftLink to='/' color={theme.primaryColor}>{t('backToStartpage')}</ArrowLeftLink></small>
          </div>
          {downloadUrl &&
            <DownloadURLAlert
              downloadUrl={downloadUrl}
              setDownloadUrl={setDownloadUrl}
              fileName={t('breadcrumb:orderSuccess')}
            />}
          {error && <Alert variant="danger">{error}</Alert>}
        </div>
      </div>
    </React.Fragment>
  )
}

export default OrderSuccessPage
