import { t } from 'i18next'
import { cloneDeep } from 'lodash'
import React, { useState } from 'react'
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap'
import { useContingentTemplatesState } from '../../../stores/Configuration/ContingentTemplatesProvider'
import { emptyGuid } from '../../../utils/emptyGuid'
import { setValueByKey } from '../../../utils/utils'
import { ButtonWithLoadingState } from '../../Buttons'
import FileInput from '../../Forms/FileInput'
import LanguageForm from '../../Forms/LanguageForm'
import LanguageNameInput from '../../Forms/LanguageNameInput'
import { CategoryIconsOptions } from '../../Icons/CategoryIcons/CategoryIcons'
import OverlayContainer from '../../Page/logix2020/OverlayContainer'
import OverlayRight from '../../Page/logix2020/OverlayRight'
import ArraySelect from '../../Select/ArraySelect'
import ImageOutput from './ImageOutput'
import { FileOutput } from '../../Forms/FileOutput'
import useMountEffect from '../../../utils/hooks/useMountEffect'

const groupTemplateObj = {
  name: {
    values: []
  },
  id: emptyGuid,
  value: {
  }
}
const GroupModal = ({ handleClose, contingentTemplateId, groupId, editGroup }) => {
  const { patchGroup, postGroup, loadingStates, errorStates, initGroup,
    groupPicture, uploadGroupPicture, getGroupPicture, deleteGroupPicture } = useContingentTemplatesState()
  const [overlayVisible, setOverlayVisible] = useState(false)
  const [group, setGroup] = useState(null)
  const [files, setFiles] = useState([])

  useMountEffect(() => {
    initGroup()
    if (groupId) {
      setGroup(editGroup)
      getGroupPicture(contingentTemplateId, groupId)
    }
    else {
      setGroup(cloneDeep(groupTemplateObj))
    }
  })

  const updateNames = (translations) => {
    setGroup(prev => ({
      ...prev,
      name: {
        ...prev.name,
        values: translations
      }
    }))
  }
  const updateItem = e => {
    let key = e.target.name
    let value = null
    if (e.target.type === "checkbox") { value = e.target.checked }
    else { value = e.target.value }
    setGroup(prev => (setValueByKey(key, value, prev)))
  }
  const handleCloseOverlay = () => {
    setOverlayVisible(false)
  }
  const handleSave = () => {
    groupId
      ? patchGroup(contingentTemplateId, groupId, group, handleClose)
      : postGroup(contingentTemplateId, group, files, handleClose)
  }

  return (
    <div>
      {/* enforceFocus for OverlayRight with overLayZIndex with inputs  */}
      <Modal show={true} size="lg" onHide={handleClose} enforceFocus={false}>
        <Modal.Header closeButton>
          <Modal.Title>{groupId ? t('contingentTemplates:editGroup') : t('contingentTemplates:addGroup')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(loadingStates.getGroup || !group)
            ? <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
            : <Form>
              <LanguageNameInput
                openLanguageModal={() => setOverlayVisible(true)}
                languageArray={group.name.values}
                onChange={updateNames}
                label={t('name')}
                isInvalid={errorStates.saveGroup?.formErrors?.name}
              />
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  id="check1"
                  name="hideNameInContingentView"
                  checked={group.hideNameInContingentView || false}
                  label={t('contingentTemplates:nameNotVisible')}
                  onChange={updateItem}
                />
                <Form.Check
                  type="checkbox"
                  id="check2"
                  name="isSpecialGroup"
                  checked={group.isSpecialGroup || false}
                  label={t('contingentTemplates:specialGroup')}
                  onChange={updateItem}
                />
                {/*  <Form.Check type="checkbox" id="check3" checked={group.} label="Für die Artikel dieser Gruppe werden keine Vorbestellungen erstellt." /> */}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t('contingentTemplates:groupIcon')}:</Form.Label>
                <ArraySelect
                  name="systemIcon"
                  onChange={updateItem}
                  returnEvent
                  value={group.systemIcon || ""}
                  options={CategoryIconsOptions}
                  defaultOption={{ value: 0, name: t('pleaseSelect') }}
                  defaultOptionIsSelectable
                />
              </Form.Group>
              <div className="mb-3">
                {loadingStates.saveGroupPicture
                  ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
                  : groupPicture && groupPicture.url
                    ? <ImageOutput url={groupPicture.url} deleteFile={() => deleteGroupPicture(contingentTemplateId, groupId)} />
                    : <FileInput
                      maxFiles={1}
                      accept={{
                        'image/jpeg': [],
                        'image/png': []
                      }}
                      onDrop={(acceptedFiles) => {
                        let newFiles = acceptedFiles.map(file => Object.assign(file, {
                          preview: URL.createObjectURL(file)
                        }));
                        groupId ? uploadGroupPicture(contingentTemplateId, groupId, newFiles) : setFiles(newFiles)
                      }}
                      isInvalid={false}
                    />
                }
              </div>
              {files.map((file, index) => (
                <FileOutput
                  style={{ height: "4rem", borderRadius: "1rem", padding: "0.5rem" }}
                  className="mb-2"
                  key={`${file.name}_${index}`}
                  file={file}
                  deleteFile={
                    () => setFiles(prev => {
                      prev.splice(index, 1)
                      return cloneDeep(prev)
                    })
                  }
                />))}
              {errorStates.saveGroup && <Alert variant="danger">{errorStates.saveGroup.value}</Alert>}
            </Form>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('cancelButton')}
          </Button>
          <ButtonWithLoadingState
            disabled={false}
            buttonText={t('saveButton')}
            isLoading={loadingStates.saveGroup}
            onClick={handleSave}
          />
        </Modal.Footer>
      </Modal>
      <OverlayContainer>
        <OverlayRight visible={overlayVisible} overLayZIndex={1056} onClose={handleCloseOverlay}>
          <LanguageForm
            languageTitle={t('name')}
            handleCloseOverlay={handleCloseOverlay}
            languageArray={group ? group.name.values : []}
            onChange={updateNames}
          />
        </OverlayRight>
      </OverlayContainer>
    </div>
  )
}

export default GroupModal