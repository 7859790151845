import React, { useState } from 'react'
import { Modal, Button, Alert, Spinner } from "react-bootstrap";
import { QrReader } from 'react-qr-reader_multiformatreader';

import { useTranslation } from 'react-i18next'


const ViewFinder = () => (
    <div style={{ top: "0px", left: "0px", zIndex: "1", boxSizing: "border-box", border: "50px solid rgba(0, 0, 0, 0.3)", boxShadow: "rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset", position: "absolute", width: "100%", height: " 100%" }}></div>
)


const QrModal = (props) => {
    const {
        handleResult,
        handleClose,
        qrModalError,
        title,
        isLoading,
        children
    } = props
    const [error, setError] = useState('')

    const { t } = useTranslation()

    return (
        <Modal show={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error ?
                    <Alert variant="danger">{error}</Alert>
                    : isLoading
                        ? <div className="d-flex align-items-center justify-content-center">< Spinner animation='border' variant='primary' /></div >
                        : <QrReader
                            videoId='video'
                            scanDelay={500}
                            ViewFinder={ViewFinder}
                            onResult={(result, error) => {
                                if (result) {
                                    handleResult(result.text);
                                }
                                if (error) {
                                    setError(t('orderTemplates:qrScannerError'))
                                }
                            }}
                        />
                }
                {children}
                {qrModalError ? <Alert variant="danger">{qrModalError}</Alert> : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('closeButton')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default QrModal
