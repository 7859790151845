import { Form } from 'react-bootstrap';
import styled from 'styled-components';


export const StyledRequiredFormLabel = styled(Form.Label)`
  :after {
    color: blue;
    content: '*';
    margin-left: 5px;
  }
`
export const StyledRequiredFormCheckLabel = styled(Form.Check.Label)`
  margin-left: 10px !important;
:after {
  color: blue;
  content: '*';
  margin-left: 5px;
  }
`