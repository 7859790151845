import React from 'react'

export const OuterClothing = (props) => {
  const color = props.color ? props.color : '#020203'
  return (
    <svg className="outerClothing" x="0px" y="0px" viewBox="0 0 113.4 113.4" style={{ enableBackground: 'new 0 0 113.4 113.4' }}>
      <style type="text/css">
        {`
          .st0{fill:${color};}
        `}
      </style>
      <path className="st0" d="M99,56.7l-14.6-22c-3.3-6.5-8.1-6.5-11.6-6.5h-2l-3.9-3.8c-0.1-0.1-0.4-0.4-0.5-0.4H47c-0.2,0-0.2,0.1-0.4,0.2
        l-3.9,3.9h-2c-3.5,0-8.2,0-11.6,6.5l-14.6,22c-0.4,0.5-0.2,1.2,0.3,1.7l11.8,8.8c0.2,0.2,0.6,0.2,1,0.2c0.3,0,0.6-0.2,0.8-0.5
        l5.1-7.2v33.3h46.7V59.7l5.2,7.2c0.2,0.2,0.5,0.5,0.8,0.5c0.4,0,0.7,0,1-0.2l11.8-8.9C99.2,58.1,99.4,57.4,99,56.7z M68.1,29
        l-7.6,7.6l-2-2l7.6-7.6L68.1,29z M63.5,26.2l-6.7,6.7l-6.9-6.7H63.5z M47.3,26.9l7.6,7.6l-2,2L45.2,29L47.3,26.9z M86.5,64.7
        l-6.7-9.4c-0.3-0.5-0.8-0.6-1.3-0.5c-0.5,0.1-0.8,0.6-0.8,1.2v34.8H35.8V56c0-0.5-0.4-1-0.9-1.2c-0.4-0.1-1,0-1.2,0.5l-6.7,9.4
        L17,57.2L31.2,36c2.5-5.4,6.3-5.4,9.4-5.4h2.9l8.7,8.7c0.3,0.3,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.4l1.7-1.8v9.4h2.4v-9.4l1.7,1.7
        c0.2,0.2,0.6,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.4l8.7-8.5h2.9c3.1,0,6.9,0,9.6,5.3l14.2,21.2L86.5,64.7z"/>
    </svg>
  )
}
