import React from 'react'
import { Link } from 'react-router-dom'
import { Alert, Spinner } from 'react-bootstrap'
import { useObserver } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { MdLibraryAdd } from 'react-icons/md'

import useStores from '../../stores/useStore'
import { Subheader } from '../../components/Page/logix2020/Subheader'
import { SidebarLeft } from '../../components/Page/logix2020/SidebarLeft'
import { FlexWrapper } from '../../components/Page/logix2020/FlexWrapper'
import { ContentArea } from '../../components/Page/logix2020/ContentArea'
import AdministrationSidebarNavigation from '../../components/Configuration/AdministrationSidebarNavigation'
import EntitlementPhasesOverview from '../../components/Configuration/EntitlementPhase/EntitlementPhasesOverview'
import BigFooter from '../../components/Footer/BigFooter'


const EntitlementPhasesAdministrationPage = () => {
  const { t } = useTranslation()
  const { uiStore } = useStores()
  const allowEntitlementPhaseAdministration = useObserver(() => uiStore.allowEntitlementPhaseAdministration)
  const dashboardIsLoading = useObserver(() => uiStore.dashboardIsLoading)
  const dashboardLoaded = useObserver(() => !!uiStore.dashboard)

  return (
    <>
      <Subheader className="subHeader-v1 justify-content-between">
        <div className="subheader-title flex-grow-1">{t('entitlementPhases:manageEntitlementPhases')}</div>
        {allowEntitlementPhaseAdministration && <Link to='/entitlementPhasesAdministration/create' className="subheader__button btn btn-primary text-white"><MdLibraryAdd className="me-3" />{t('entitlementPhases:addEntitlementPhase')}</Link>}
      </Subheader>
      <FlexWrapper>
        <SidebarLeft>
          <AdministrationSidebarNavigation active="entitlementPhasesAdministration" />
        </SidebarLeft>
        <ContentArea>
          {allowEntitlementPhaseAdministration ?
            <EntitlementPhasesOverview />
            : (dashboardIsLoading || !dashboardLoaded)
              ? <div className="d-flex align-items-center justify-content-center"><Spinner animation='border' variant='primary' /></div>
              : <Alert variant="danger">{t('accessDenied')}</Alert>
          }
        </ContentArea>
      </FlexWrapper>
      <BigFooter />
    </>
  )
}

export default EntitlementPhasesAdministrationPage