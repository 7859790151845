import React from 'react'
import { useObserver } from 'mobx-react'
import { Form } from 'react-bootstrap'
import ProductSearch from '../ProductSearch/ProductSearch'
import ProductSearchCheckBox from '../ProductSearch/ProductSearchCheckBox'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const StyledWrapper = styled.div`
  margin-bottom: 35px;
`

const CatalogProductsSearch = () => {

    const { t } = useTranslation()
    return useObserver(() => (
        <StyledWrapper>
            <hr></hr>
            <Form.Group className="mb-3">
                <Form.Label>{t('ProductOverview:searchArticle')}</Form.Label>
                <ProductSearch />
                <ProductSearchCheckBox />
            </Form.Group >
            <hr></hr>
        </StyledWrapper >
    ))
}

export default CatalogProductsSearch

