import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import LanguageSwitchModal from './LanguageSwitchModal'
import { colors } from '../../styles/colors'


const StyledWrapper = styled.div`
display:flex;
padding:5px;
margin-right:10px;
  color: #000;
  font-weight: bold;
  font-size:14px;
  cursor:pointer;
  border-radius: 4px;
  background:${colors.gray1};
  border: 1px solid ${colors.gray2};
  &:hover{
    background: ${colors.gray2};
  }
`



const StyledCountryDiv = styled.div`
padding-right:11px;
position:relative;
&::after {
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  top:0;
  right: 5px;
  background-color: ${colors.gray3};
}
`

const LanguageSwitch = inject('uiStore', 'profileStore')(observer(props => {

  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const { uiStore, profileStore } = props;
  const countryIsoCode = profileStore.profile && profileStore.profile.country ? profileStore.profile.country.isoCode : '';
  const countryDisplayName = profileStore.profile && profileStore.profile.country ? profileStore.profile.country.displayName : '';

  return (
    <>
      <StyledWrapper
        className="no-border-radius"
        onClick={() => {
          setShowLanguageModal(true)
        }}
      >
        {uiStore.allowShipmentCountrySelection && <StyledCountryDiv>{countryDisplayName.length < 10 ? countryDisplayName : countryIsoCode}</StyledCountryDiv>}
        <div>{uiStore.language}</div>
      </StyledWrapper>
      {showLanguageModal ? <LanguageSwitchModal
        setShowLanguageModal={setShowLanguageModal}
      /> : null}
    </>
  )
}))

export default LanguageSwitch
