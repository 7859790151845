import React, { useEffect, useState } from 'react'
import QrModal from '../Modals/QrModal'
import useStores from '../../stores/useStore'
import apiClient from '../../utils/api/apiClient'
import { APIEndpoints } from '../../utils/api/apiConfig'
import { Alert } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { getErrorMessageFromApiResponse } from '../../utils/api/apiUtils'
import { t } from 'i18next'
import { playScanErrorSound, playScanOkSound } from '../../utils/playSounds'


const ArticleScan = ({ basketId, getArticleUrl }) => {
  const { productsStore, uiStore } = useStores()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const navigate = useNavigate();

  const handleResult = async (result) => {
    playScanOkSound()
    setLoading(true)
    try {
      let scanResponse = await apiClient.patchJson(APIEndpoints.shoppingBasketScan(basketId, result))
      //success scan continue with next
      if (scanResponse.orderSucceeded) {
        setSuccess(true)
        productsStore.loadBasket(basketId)
        uiStore.fetchNewShoppingCarts()
        productsStore.loadContingent(true)
      }

      else {
        //redirect to Article
        let presetVariant = scanResponse?.scanInfo?.articleVariant
        navigate(getArticleUrl({ id: scanResponse.shoppingBasketArticleId }), { state: { presetVariant } })
      }
    }
    catch (e) {
      setError(getErrorMessageFromApiResponse(e))
      playScanErrorSound()
    }
    finally {
      setLoading(false)
    }
  }

  //auto remove success message after couple sec.
  useEffect(() => {
    let timeout = null
    if (success || error) {
      timeout = setTimeout(() => {
        setSuccess(null)
        setError(null)
      }, 3000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [success, error])

  return (
    <div>
      <QrModal
        title="Artikel Scan"
        handleClose={() => productsStore.closeArticleScanModal()}
        qrModalError={error}
        handleResult={(r) => handleResult(r)}
        isLoading={loading}
      >
        {success && <Alert variant="success">{t('productDetail:productAddedSuccesfullyToBasket')}</Alert>}
      </QrModal>
    </div>
  )
}

export default ArticleScan